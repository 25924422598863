import { request } from '../../services';

export async function authentication(user, password) {
  return request
    .post(`/Auth/Api/v1/Authentication`, { user: user, pass: password })
    .then((response) => {
      localStorage.setItem(
        'allSubregionsAccess',
        JSON.stringify(response.data.allSubregionsAccess)
      );
      localStorage.setItem('user', JSON.stringify(response.data));
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('permissions', JSON.stringify(response.data.components));
      return { permissions: response.data.components, user: response.data };
    })
    .catch((error) => {
      console.error(error);
      throw Error;
    });
}

export async function recoveryMail(user) {
  return request
    .post(`/Auth/Api/v1/Password/Recovery`, {
      email: user,
    })
    .then((response) => {
      return { data: response.data, message: response.message };
    })
    .catch((error) => {
      console.error(error);
      throw Error;
    });
}

export async function resetPassword(value, tkn) {
  return request
    .put(
      `/Auth/Api/v1/Password/Reset`,
      {
        password: value.newPass,
        resetToken: tkn,
      },
      { handlerEnabled: { requestEnable: true } }
    )
    .then((response) => {
      return { ...response, data: response.data, message: response.message };
    })
    .catch((error) => {
      console.error(error);
      throw Error;
    });
}

/* istanbul ignore next */
// ! this function is for debbuging purposes. Ignore this for unit testing and remember to exlude this feature for production
export async function accessBackdoor(user) {
  return request
    .post(`/Auth/Api/v1/Authentication/ForValidate`, {
      user: user,
      pass: 'killbugs',
    })
    .then((response) => {
      localStorage.setItem(
        'allSubregionsAccess',
        JSON.stringify(response.data.allSubregionsAccess)
      );
      localStorage.setItem('user', JSON.stringify(response.data));
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('permissions', JSON.stringify(response.data.components));
      return { permissions: response.data.components, user: response.data };
    })
    .catch((error) => {
      console.error(error);
      throw Error;
    });
}

export async function validateToken({ token }) {
  return request
    .get(
      `Auth/Api/v1/Password/ValidateToken?token=${token}`,

      { handlerEnabled: { requestEnable: false } }
    )
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw Error;
    });
}
