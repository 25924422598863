/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Form, Button, Select } from 'antd';
import { BSelect, ResetButton, BSearch } from 'common/components';
import { consultRulesRequest, consultRulesClean } from 'TradeAgreements/redux/actions/rules';
import getTradeAgreementsModule from 'TradeAgreements/tradeModule';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getRootModule from 'RootModule';
import { SubRegion, Segmentation, BusinessLine, BusinessLine2, Clients } from 'RootModule/catalogs';
import { layoutContext } from 'context';
import './styles.css';
import { ruleTypeEnum } from 'common/models/enums';
import RuleTypeTable from 'TradeAgreements/components/RuleTypeTable';
import { Link, withRouter } from 'react-router-dom';
import { RoleAccessControl } from 'common/components/accessControllers';
import { getPopupContainer } from 'SalesChallengeRefactor/helpers/helperForms';

const layoutFilters = {
  xs: 24,
  sm: 12,
  md: 6,
  lg: 4,
  xlg: 5,
};

export const getFiltersByRuleType = (
  ruleType,
  enumObj,
  setselectSubTipo,
  selectSubTipo,
  form,
  setdataBR,
  dataBR
) => {
  const { Option } = Select;
  const handleChangeClient = (e) => {
    resetSubInputsClient();
    const data = form.getFieldValue();
    setdataBR(data);
  };
  const resetSubInputsClient = () => {
    form.resetFields(['subregionId']);
  };

  function onChangeSubTipo(value) {
    setselectSubTipo(value);
    form.resetFields(['subregionId', 'bussinessLinesIds', 'soldTo']);
  }
  let Filters;
  switch (ruleType) {
    case enumObj.PRODUCTOS_FUERA_DE_DISPONIBILIDAD:
      Filters = (
        <>
          <Col
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}
            {...layoutFilters}
          >
            <BSearch
              className="consultRules"
              placeholder="Número o nombre del producto"
              name="wildcard"
            />
          </Col>
        </>
      );
      break;
    case enumObj.PRODUCTOS_FUERA_DE_POLITICA:
      Filters = (
        <>
          {/* NUEVO INPUT CLIENTE */}
          <Col {...layoutFilters}>
            <Form.Item name="subTipo" label="Subtipo:">
              <Select
                defaultValue="estructura"
                placeholder="Selccionar"
                optionFilterProp="children"
                getPopupContainer={getPopupContainer}
                onChange={onChangeSubTipo}
              >
                <Option value="cliente">Cliente</Option>
                <Option value="estructura">Estructura comercial</Option>
              </Select>
            </Form.Item>
          </Col>

          {selectSubTipo === 'estructura' ? (
            <>
              <Col {...layoutFilters}>
                <BusinessLine
                  mode="multiple"
                  aria-label="Línea de negocio"
                  label="Línea de negocio"
                  placeholder="Línea de negocio"
                  name="bussinessLinesIds"
                  sourceId="Agreements"
                />
              </Col>
              <Col {...layoutFilters}>
                <SubRegion
                  mode="multiple"
                  aria-label="Subregión"
                  label="Subregión"
                  placeholder="Subregión"
                  name="subRegionsIds"
                  sourceId="Agreements"
                />
              </Col>
            </>
          ) : (
            <>
              {/*   Linea de negocio CLIENTE  */}
              <Col {...layoutFilters}>
                <BusinessLine2
                  mode="multiple"
                  label="Línea de negocio"
                  name="bussinessLinesIds"
                  className="form-add-user-input"
                  sourceId="Catalogs"
                  askFromService={true}
                  onChange={handleChangeClient}
                />
              </Col>{' '}
              {/* CLIENTES */}
              <Col span={7}>
                <Clients
                  mode="multiple"
                  label="Cliente"
                  optionKey="soldTo"
                  valueKey="soldTo"
                  labelKey="name"
                  name="soldTo"
                  className="form-add-user-input"
                  sourceId="BySoldTo"
                  // disabled={dataBR.bussinessLinesIds ? false : true}
                  requestParams={
                    dataBR.bussinessLinesIds > 0
                      ? { businessLines: dataBR.bussinessLinesIds }
                      : null
                  }
                  maxTagCount={1}
                />
              </Col>
            </>
          )}
          <Col
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}
            {...layoutFilters}
          >
            <BSearch
              className="consultRules"
              placeholder="Número, nombre o familia del producto"
              name="wildcard"
            />
          </Col>
        </>
      );
      break;
    case enumObj.PROGRAMA_DE_INCENTIVOS:
      Filters = (
        <>
          <Col span={5}>
            <BusinessLine
              aria-label="Línea de negocio"
              label="Línea de negocio"
              placeholder="Línea de negocio"
              name="bussinessLinesIds"
              mode="multiple"
              sourceId="Agreements"
            />
          </Col>
          <Col span={5}>
            <SubRegion
              aria-label="Subregión"
              label="Subregión"
              placeholder="Subregión"
              name="subRegionsIds"
              mode="multiple"
              sourceId="Agreements"
            />
          </Col>
          <Col span={5}>
            <Segmentation
              aria-label="Segmentación"
              label="Segmentación"
              placeholder="Segmentación"
              name="segmentationId"
            />
          </Col>
        </>
      );
      break;
    default:
      Filters = 'No selecciono ningun filtro';
      break;
  }
  return Filters;
};

function ConsultRulesUnConnected(props) {
  const [selectSubTipo, setselectSubTipo] = useState('estructura');
  const [dataBR, setdataBR] = useState([]);
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const {
    rules,
    tradeAgreementRuleType,
    tradeAgreementRuleSubType,
    consultRulesRequest: consultRulesReq,
    consultRulesClean: cleanConsult,
  } = props;
  const [ruleType, setRuleType] = useState(null);
  useEffect(() => {
    form.resetFields(['bussinessLinesIds', 'subregionId', 'lineId', 'clientId']);
  }, [selectSubTipo]);
  useEffect(() => {
    if (props?.location?.state?.ruleType > 0) {
      let value = props?.location?.state?.ruleType;
      setRuleType(value);
      form.setFieldsValue({
        ruleType: value,
      });
      handleOnValuesChange({
        changedValues: { ruleType: value },
        allValues: {
          bussinessLinesIds: undefined,
          ruleType: value,
          subRegionsIds: undefined,
          wildcard: undefined,
        },
      });
    } else {
      setRuleType(1);
    }
    // eslint-disable-next-line react/destructuring-assignment
  }, [props?.location?.state?.ruleType]);

  const handleRuleTypeOnChange = (value) => {
    setRuleType(value);
    setselectSubTipo('estructura');
    form.setFieldsValue({
      subTipo: 'estructura',
    });
  };

  const handleOnValuesChange = ({ changedValues, allValues }) => {
    const changedValueKey = Object.keys(changedValues)[0];
    if (changedValueKey !== 'subTipo') {
      switch (changedValues?.ruleType) {
        case ruleTypeEnum.PRODUCTOS_FUERA_DE_DISPONIBILIDAD:
          form.setFieldsValue({
            wildcard: undefined,
          });
          break;
        case ruleTypeEnum.PRODUCTOS_FUERA_DE_POLITICA:
          form.setFieldsValue({
            bussinessLinesIds: undefined,
            subRegionsIds: undefined,
            wildcard: undefined,
          });
          break;
        case ruleTypeEnum.PROGRAMA_DE_INCENTIVOS:
          form.setFieldsValue({
            bussinessLinesIds: undefined,
            subRegionsIds: undefined,
            segmentationId: undefined,
          });
          break;
        default:
          console.warn('Selected Filter wasnt correctly reseted');
          break;
      }
    }
    onChangeActiveComponent('businessRules');

    const data = { ...form.getFieldsValue() };
    delete data.ruleType;
    delete data.subTipo;
    cleanConsult();
    if (changedValueKey !== 'subTipo' && changedValueKey !== 'ruleType') {
      if (ruleType === 2 && selectSubTipo === 'cliente') {
        consultRulesReq({
          endpoint: 'cliente',
          requestParams: { ...data },
        });
      } else {
        consultRulesReq({
          endpoint: ruleType,
          requestParams: { ...data },
        });
      }
    }
  };
  useEffect(() => {
    form.setFieldsValue({ ruleType: ruleType, subTipo: selectSubTipo });
    consultRulesReqFunction();
  }, [ruleType, selectSubTipo]);

  useEffect(() => {
    if (tradeAgreementRuleType !== undefined && tradeAgreementRuleType !== null) {
      form.setFieldsValue({ ruleType: tradeAgreementRuleType });
      setRuleType(tradeAgreementRuleType);
      setselectSubTipo(
        tradeAgreementRuleSubType !== undefined && tradeAgreementRuleSubType !== null
          ? tradeAgreementRuleSubType
          : null
      );
      if (tradeAgreementRuleSubType === 2) {
        setselectSubTipo('cliente');
      } else {
        setselectSubTipo('estructura');
      }
      consultRulesReqFunction();
    }
  }, [tradeAgreementRuleType, tradeAgreementRuleSubType]);

  const consultRulesReqFunction = () => {
    cleanConsult();
    if (ruleType === 2 && selectSubTipo === 'cliente') {
      consultRulesReq({
        endpoint: 'cliente',
        requestParams: {},
      });
    } else {
      consultRulesReq({
        endpoint: ruleType,
        requestParams: {},
      });
    }
  };
  const cleanFilters = () => {
    form.resetFields();
    setRuleType(1);
  };

  return (
    <>
      <div style={{ padding: '25px' }}>
        <Form
          onValuesChange={(changedValues, allValues) =>
            handleOnValuesChange({ changedValues, allValues })
          }
          form={form}
          layout="vertical"
        >
          <Row gutter={8}>
            <Col {...layoutFilters}>
              <BSelect
                allowClear={false}
                aria-label="Tipo de regla de negocio"
                label="Tipo de regla de negocio"
                name="ruleType"
                placeholder="Tipo de regla de negocio"
                options={[
                  {
                    id: 1,
                    name: 'Productos fuera de disponibilidad',
                    value: 1,
                  },
                  { id: 2, name: 'Productos fuera de política', value: 2 },
                  { id: 3, name: 'Programa de incentivos', value: 3 },
                ]}
                valueKey="value"
                optionKey="id"
                labelKey="name"
                onChange={(value) => handleRuleTypeOnChange(value)}
              />
            </Col>

            {ruleType === 1 ? (
              <Col>
                <RoleAccessControl
                  moduleKey="catalog"
                  page="businessRules"
                  action="update_businessRules/:id"
                >
                  <Link
                    to={{
                      pathname: `/catalog/businessRules/update/availability`,
                    }}
                  >
                    <Button
                      style={{
                        marginTop: '2.4em',
                        background: '#004a96',
                        color: 'white',
                        outline: 'none',
                        border: 'none',
                      }}
                    >
                      {' '}
                      Actualizar{' '}
                    </Button>
                  </Link>
                </RoleAccessControl>
              </Col>
            ) : null}

            {getFiltersByRuleType(
              ruleType,
              ruleTypeEnum,
              setselectSubTipo,
              selectSubTipo,
              form,
              setdataBR,
              dataBR
            )}

            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <ResetButton onClick={cleanFilters} style={{ topMargin: 2 }} />
            </Col>
          </Row>

          <div style={{ position: 'absolute', top: 115, right: 0 }} />
          <Row justify="center">
            <Col span={24}>
              <RuleTypeTable
                ruleType={ruleType}
                dataSource={rules?.data ?? []}
                loading={rules?.isFetching}
                selectSubTipo={selectSubTipo}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    rules: state.TRADE_AGREEMENTS?.consultRules?.rules,
    tradeAgreementRuleType: state.TRADE_AGREEMENTS?.consultRules?.tradeAgreementRuleType,
    tradeAgreementRuleSubType: state.TRADE_AGREEMENTS?.consultRules?.tradeAgreementRuleSubType,
  };
}

const ConsultRulesUnWrapped = withRouter(
  connect(mapStateToProps, { consultRulesRequest, consultRulesClean })(ConsultRulesUnConnected)
);

const ConsultRules = () => (
  <DynamicModuleLoader
    modules={[getTradeAgreementsModule(), getRootModule() /* , bussinesRulesModule() */]}
  >
    <ConsultRulesUnWrapped />
  </DynamicModuleLoader>
);

export default ConsultRules;
