import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  POG_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  POG_MASSIVE_UPLOAD_REQUEST_VERIFY,
  POG_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  POGMassiveUploadSetAllowUpload,
  POGMassiveUploadClearAllowUpload,
  POGMassiveUploadSetVerify,
  POGMassiveUploadClearVerify,
  POGMassiveUploadClearUpload,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';

import { POGMassiveUpload } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workPOGMassiveUploadSetAllowUpload(action) {
  try {
    yield POGMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield POGMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield POGMassiveUploadClearAllowUpload();
    }
  }
}

function* workPOGMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de POG debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(POGMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(POGMassiveUploadSetVerify(data));
      yield call(summaryModal, message, false);
    } else {
      yield put(POGMassiveUploadClearVerify());
    }
  } catch (error) {
    yield POGMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield POGMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workPOGMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, overwrite } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(POGMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite,
      },
    });
    if (responseOK(response)) {
      yield call(summaryEndModal, response.data, history, '/historic-information/pog');
    } else {
      yield put(POGMassiveUploadClearUpload());
    }
  } catch (error) {
    yield POGMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield POGMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchPOGMassiveUploadSetAllowUpload() {
  yield all([takeEvery(POG_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD, workPOGMassiveUploadSetAllowUpload)]);
}

export function* watchPOGMassiveUploadRequestVerify() {
  yield all([takeEvery(POG_MASSIVE_UPLOAD_REQUEST_VERIFY, workPOGMassiveUploadRequestVerify)]);
}

export function* watchPOGMassiveUploadRequestUpload() {
  yield all([takeEvery(POG_MASSIVE_UPLOAD_REQUEST_UPLOAD, workPOGMassiveUploadRequestUpload)]);
}
