/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Form, Modal } from 'antd';
import { ActionButtons, BDivider, openNotification } from 'common';
import { validaForm } from '../helpers';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import moment from 'moment';
import {
  getTypeClientRequest,
  getBusinessLinesRequest,
  getSegmentationRequest,
  getCompanyRequest,
  getTerritoresRequest,
  getPogsRequest,
  getCurrenciesRequest,
  getPlacementsRequest,
  DynamicElementsRequest,
  getClientIdRequest,
  getDetailDynamicElementsRequest,
  getSalesIncentivePeriodRequest,
  statusRequest,
} from '../redux-saga/actions';
import getRootModule from 'RootModule';
import {
  SectionInitial,
  SectionPog,
  ResumeIncentiveSection,
  Client,
  ShopByFamilySection,
  SectionShop,
  LineCreditOccupancySection,
  OthersSection,
  ContactAccountStatus,
} from '../components';

import UpdateModule from '../UpdateModule';

import { useHistory, useParams } from 'react-router-dom';
import { layoutContext } from 'context';

const RegisterDynamicModules = (props) => {
  const {
    getClientData,
    isLoading,
    creditLineSection,
    creditNotesSection,
    familySalesSection,
    initialSection,
    otherSection,
    pogSection,
    salesSection,
    staticFields,
    getPlacementsRequest: getPlacements,
    getTypeClientRequest: getTypesClient,
    getBusinessLinesRequest: getBussinesLines,
    getSegmentationRequest: getSegmentation,
    getCompanyRequest: getCompany,
    getTerritoresRequest: getTerritories,
    getPogsRequest: getPogs,
    getCurrenciesRequest: getCurrencies,
    DynamicElementsRequest: registerDynamicElements,
    getDetailDynamicElementsRequest: getDetail,
    getClientIdRequest: getClientId,
    getSalesIncentivePeriodRequest: getSalesIncentive,
    statusRequest: sendStatus,
    clientInfo,
  } = props;

  const { onChangeActiveComponent } = useContext(layoutContext);
  const [form] = Form.useForm();
  const [isActiveRegister, setIsActiveRegister] = useState(false);

  const [isNacional, setIsNacional] = useState(false);
  const [crop, setCrop] = useState('');
  const history = useHistory();
  const { lineId } = history.location.state;
  const param = useParams();
  const clientId = param.id;
  const [statusSwitch, setStatusSwitch] = useState({
    //disabled
    checked: true,
    disabled: false,
  });
  useEffect(() => {
    getDetail({ clientId, lineId });
    getClientId(clientId);
  }, [clientId]);

  useEffect(() => {
    getTypesClient();
    getBussinesLines();
    getSegmentation();
    getCompany();
    getPogs();
    getCurrencies();
    getPlacements();
    getSalesIncentive();
    onChangeActiveComponent('Users');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formatDate = (date) => {
    if (date !== undefined) {
      let newDate = date.split(' ')[0];
      let valueDate = newDate.split('/');
      return `${valueDate[2]}-${valueDate[1]}-${valueDate[0]}`;
    } else return null;
  };

  useEffect(() => {
    const { detail } = getClientData;
    setCrop(staticFields?.line);
    let params = {
      SubRegions: parseInt(staticFields?.subReg),
      BusinessLines: parseInt(staticFields?.line),
      IncludeAll: true,
    };
    let contactAccountStatement = {};
    getTerritories({ params });
    if (parseInt(staticFields?.presc) === 3) {
      setIsNacional(true);
    } else {
      setIsNacional(false);
    }

    setStatusSwitch({
      disabled: false, // clientInfo?.editable,
      checked: !detail?.staticFields.enabled,
    });

    if (detail?.contactAccountStatement?.length > 0) {
      // eslint-disable-next-line array-callback-return
      detail.contactAccountStatement.map((d, index) => {
        contactAccountStatement[`contact${index + 1}`] = d.name;
        contactAccountStatement[`email${index + 1}`] = d.email;
      });
      form.setFieldsValue(contactAccountStatement);
    }
    setIsActiveRegister(!detail?.staticFields.enabled);
    form.setFieldsValue({
      soldTo: clientInfo?.soldTo ?? null,
      name: clientInfo?.name ?? null,
      subregionId: parseInt(staticFields?.subReg) ?? null,
      segmentationId: parseInt(staticFields?.seg) ?? null,
      lineId: parseInt(staticFields?.line) ?? null,
      placementId: parseInt(staticFields?.presc) ?? null,
      periodoVentasId: staticFields?.periodSales ?? null,
      periodoPOGId: staticFields?.periodPOG ?? null,
      tradeAgreementCurrency: staticFields?.currency ?? null,
      header: initialSection?.header,
      greet1: initialSection?.greeting1,
      greet2: initialSection?.greeting2,
      greet3: initialSection?.greeting3,
      introBuy: salesSection?.intoduction,
      subt1: salesSection?.subtitle1,
      noteBuy: salesSection?.note,
      objectExt: salesSection?.objectiveExtra,
      subt2: salesSection?.subtitle2,
      introPog: pogSection?.introduction,
      subt3: pogSection?.subtitle3,
      notePog: pogSection?.note,
      noteNCInc: creditNotesSection?.notes,
      date: creditLineSection?.date !== null ? moment(formatDate(creditLineSection?.date)) : null,
      introOccupa: creditLineSection?.introduction,
      creditLine: creditLineSection?.creditLine,
      balance: creditLineSection?.validAmount,
      notBalance: creditLineSection?.expiredAmount,
      denomination: creditLineSection?.currencyName,
      noteOcc: creditLineSection?.note,
      period: familySalesSection?.period,
      comment: otherSection?.comments,
      headOther: otherSection?.header,
    });
  }, [getClientData.detail, clientInfo]);

  useEffect(() => {
    if (getClientData.territories !== null) {
      let keyTerritories = getClientData?.territories
        // eslint-disable-next-line array-callback-return
        ?.filter((d) => {
          if (staticFields?.territory?.includes(d.value)) return d;
        })
        .map((d) => d.key);
      form.setFieldsValue({
        territories: keyTerritories,
      });
    }
  }, [getClientData.territories]);

  const onSubmit = () => {
    const allValues = form.getFieldsValue();
    allValues.name = clientInfo.name;
    allValues.clientId = clientId;
    if (Array.isArray(allValues.territories) === true) {
      let territory = allValues.territories.map((d) => ({ territoryId: d }));
      allValues.territories = territory;
    } else {
      allValues.territories = [{ territoryId: allValues.territories }];
    }
    allValues.contactAccountStatement = {
      emailAS1: allValues.email1 === '' ? undefined : allValues.email1,
      emailAS2: allValues.email2 === '' ? undefined : allValues.email2,
      emailAS3: allValues.email3 === '' ? undefined : allValues.email3,
      emailAS4: allValues.email4 === '' ? undefined : allValues.email4,
      emailAS5: allValues.email5 === '' ? undefined : allValues.email5,
      nameAS1: allValues.contact1 === '' ? undefined : allValues.contact1,
      nameAS2: allValues.contact2 === '' ? undefined : allValues.contact2,
      nameAS3: allValues.contact3 === '' ? undefined : allValues.contact3,
      nameAS4: allValues.contact4 === '' ? undefined : allValues.contact4,
      nameAS5: allValues.contact5 === '' ? undefined : allValues.contact5,
    };
    let parameters = allValues;
    registerDynamicElements({ parameters, history, clientId });
  };
  const onCancel = () => {
    history.push('/account-status/dynamic-items/consult');
  };

  const onValuesChange = (changedValues) => {
    const allValues = form.getFieldsValue();
    const currentChange = Object.keys(changedValues)[0];
    if (currentChange === 'placementId') {
      if (allValues.placementId === 3) {
        setIsNacional(true);
        form.setFieldsValue({
          territories: undefined,
        });
      } else {
        if (Array.isArray(allValues.territories) && isNacional === true) {
          form.setFieldsValue({
            territories: undefined,
          });
        }
        setIsNacional(false);
      }
    }
    if (currentChange === 'subregionId') {
      form.setFieldsValue({
        territories: undefined,
      });
    }
    validaForm(form, setIsActiveRegister);
  };

  const handleStatusUpdate = (val) => {
    const onSuccess = (msg) => {
      openNotification('success', msg);
    };
    const errorMessage = (msg) => {
      openNotification('error', msg);
    };
    const actions = {
      true: 'desactivar',
      false: 'activar',
    };
    const message = {
      default: `¿Está seguro que desea ${actions[val]} el registro del elemento dinámico de estado de cuenta?`,
      representative: `¿Está seguro que desea ${actions[val]} el registro del elemento dinámico de estado de cuenta?`,
    };

    Modal.confirm({
      title: 'Confirmar acción',
      content: val === true ? message.representative : message.default,
      onOk() {
        const { line, subReg } = staticFields;
        if (statusSwitch.checked) {
          sendStatus({
            clientId,
            status: true,
            lineId: parseInt(line),
            subRegionId: parseInt(subReg),
            setStatusSwitch,
            onSuccess,
            errorMessage,
            history,
          });
          setIsActiveRegister(false);
        } else {
          sendStatus({
            clientId,
            status: false,
            lineId: parseInt(line),
            subRegionId: parseInt(subReg),
            setStatusSwitch,
            onSuccess,
            errorMessage,
            history,
          });
          setIsActiveRegister(true);
        }
      },
    });
  };

  return (
    <>
      <div className={'module-card'}>
        <Form form={form} onValuesChange={onValuesChange}>
          <BDivider title={'Información de cliente'} />
          <Client
            getClientData={getClientData}
            form={form}
            getTerritories={getTerritories}
            isNacional={isNacional}
            crop={crop}
            setCrop={setCrop}
            statusSwitch={statusSwitch.checked}
          />
          <BDivider title={'Sección inicial'} />
          <SectionInitial statusSwitch={statusSwitch.checked} />
          <BDivider title={'Sección de compras'} />
          <SectionShop statusSwitch={statusSwitch.checked} />
          <BDivider title={'Sección de POG'} />
          <SectionPog statusSwitch={statusSwitch.checked} />
          <BDivider title={'Sección de resumen de incentivos'} />
          <ResumeIncentiveSection statusSwitch={statusSwitch.checked} />
          <BDivider title={'Sección de ocupación de línea de crédito'} />
          <LineCreditOccupancySection form={form} statusSwitch={statusSwitch.checked} />
          <BDivider title={'Sección de compras por familia'} />
          <ShopByFamilySection statusSwitch={statusSwitch.checked} />
          <BDivider title={'Sección de otros'} />
          <OthersSection statusSwitch={statusSwitch.checked} />
          <BDivider title={'Información de contactos de estado de cuenta'} />
          <ContactAccountStatus statusSwitch={statusSwitch.checked} />
        </Form>
      </div>
      <ActionButtons
        onCancel={onCancel}
        onSubmit={onSubmit}
        statusSwitch={statusSwitch}
        statusChange={handleStatusUpdate}
        isLoading={isLoading}
        disabled={isActiveRegister}
        buttons={['cancel', 'submit']}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    clientData: state?.UPDATEELEMENT?.DynamicElements?.data,
    getClientData: state.UPDATEELEMENT.DynamicElements,
    isLoading: state.UPDATEELEMENT.DynamicElements?.isLoading,
    creditLineSection: state.UPDATEELEMENT.DynamicElements.detail?.creditLineSection,
    creditNotesSection: state.UPDATEELEMENT.DynamicElements?.detail?.creditNotesSection,
    familySalesSection: state.UPDATEELEMENT.DynamicElements?.detail?.familySalesSection,
    initialSection: state.UPDATEELEMENT.DynamicElements?.detail?.initialSection,
    otherSection: state.UPDATEELEMENT.DynamicElements?.detail?.otherSection,
    pogSection: state.UPDATEELEMENT.DynamicElements?.detail?.pogSection,
    salesSection: state.UPDATEELEMENT.DynamicElements?.detail?.salesSection,
    staticFields: state.UPDATEELEMENT.DynamicElements?.detail?.staticFields,
    isValid: state.REGISTERDELEMENT?.DynamicElements?.isValid ?? false,
    clientInfo: state.UPDATEELEMENT?.DynamicElements?.client,
  };
}

const RegisterDynamicModulesForm = connect(mapStateToProps, {
  getTypeClientRequest,
  getBusinessLinesRequest,
  getSegmentationRequest,
  getCompanyRequest,
  getTerritoresRequest,
  getPogsRequest,
  getCurrenciesRequest,
  getPlacementsRequest,
  DynamicElementsRequest,
  getClientIdRequest,
  getSalesIncentivePeriodRequest,
  getDetailDynamicElementsRequest,
  statusRequest,
})(RegisterDynamicModules);

const UpdateDynamicElements = () => (
  <DynamicModuleLoader modules={[getRootModule(), UpdateModule()]}>
    <RegisterDynamicModulesForm />
  </DynamicModuleLoader>
);

export default UpdateDynamicElements;
