const downloadExcel = (state = {}, action) => {
  const { type, payload } = action;

  const REDUCER = {
    // GET DOWNLOAD_EXCEL
    DOWNLOAD_EXCEL_REQUEST: {
      loading: true,
    },
    DOWNLOAD_EXCEL_SET: {
      data: payload,
    },
    DOWNLOAD_EXCEL_CLEAN: {},
  };

  return { ...state, loading: false, ...REDUCER[type] };
};

export default downloadExcel;
