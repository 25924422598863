import { takeLatest, all, call, put, cancelled } from 'redux-saga/effects';
import { responseOK } from 'SalesChallengeRefactor/utils';
import { getDownloadExcel } from '../../services/getExcel';
import instance from 'services/request';
import { messageError, message001 } from 'utils/messages';
import { getDMYDateString } from 'Dashboards/Comparative/components/DownloadPDFButton';

//Actions
import {
  DOWNLOAD_EXCEL_REQUEST,
  downloadExcelCLEAN,
  // downloadExcelSet,
} from '../actions/downloadExcel';
//Services

// DOWNLOAD EXCEL REQUEST
function* workgetDownloadExcel(action) {
  const { urlExcel, docName } = action.payload;
  const { CancelToken } = instance;
  const source = CancelToken.source();

  try {
    const response = yield call(getDownloadExcel, {
      cancelToken: source.token,
      urlExcel,
    });

    if (responseOK(response)) {
      if (response?.data) {
        const base64 = response.data;
        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${base64}`;
        link.download = `${docName} ${getDMYDateString()}.xlsx`;
        link.click();
        message001(`La descarga de ${docName}`);
      }
    } else {
      yield call(messageError, response.message);
    }
  } catch (error) {
    console.error('SAGAS ERROR', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
    yield put(downloadExcelCLEAN());
  }
}

export function* watchgetDownloadExcel() {
  yield all([takeLatest(DOWNLOAD_EXCEL_REQUEST, workgetDownloadExcel)]);
}
