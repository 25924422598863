import { Col } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const FilterLink = (props) => {
  const { uri, label, floatToRight } = props;
  const colStyle = { marginRight: '10px' };
  const linkStyle = {
    outline: 'none',
    height: '30px',
    marginTop: '1.62em',
  };

  if (floatToRight) {
    colStyle.marginLeft = 'auto';
  }
  return (
    <Col style={colStyle}>
      <Link to={uri} className="ant-btn ant-btn-info" style={linkStyle}>
        {label}
      </Link>
    </Col>
  );
};

export default FilterLink;
