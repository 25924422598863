export const BUSINESS_LINES_REQUEST = 'BUSINESS_LINES_REQUEST';
export const BUSINESS_LINES_SET = 'BUSINESS_LINES_SET';
export const BUSINESS_LINES_CLEAN = 'BUSINESS_LINES_CLEAN';

export const SUBREGIONS_REQUEST = 'SUBREGIONS_REQUEST';
export const SUBREGIONS_SET = 'SUBREGIONS_SET';
export const SUBREGIONS_CLEAN = 'SUBREGIONS_CLEAN';

export const MONTH_REQUEST = 'MONTH_REQUEST';
export const MONTH_SET = 'MONTH_SET';
export const MONTH_CLEAN = 'MONTH_CLEAN';

export const PERIOD_REQUEST = 'PERIOD_REQUEST';
export const PERIOD_SET = 'PERIOD_SET';
export const PERIOD_CLEAN = 'PERIOD_CLEAN';

export const CLIENT_REQUEST = 'CLIENT_REQUEST';
export const CLIENT_SET = 'CLIENT_SET';
export const CLIENT_CLEAN = 'CLIENT_CLEAN';

export const STATUSDATA_REQUEST = 'STATUSDATA_REQUEST';
export const STATUSDATA_SET = 'STATUSDATA_SET';
export const STATUSDATA_CLEAN = 'STATUSDATA_CLEAN';

export const FREQUENCY_REQUEST = 'FREQUENCY_REQUEST';
export const FREQUENCY_SET = 'FREQUENCY_SET';
export const FREQUENCY_CLEAN = 'FREQUENCY_CLEAN';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SET = 'REGISTER_SET';
export const REGISTER_CLEAN = 'REGISTER_CLEAN';

export const CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST';
export const CHANGE_STATUS_SET = 'CHANGE_STATUS_SET';
export const CHANGE_STATUS_CLEAN = 'CHANGE_STATUS_CLEAN';

export const DELETE_PERIOD_REQUEST = 'DELETE_PERIOD_REQUEST';
export const DELETE_PERIOD_SET = 'DELETE_PERIOD_SET';
export const DELETE_PERIOD_CLEAN = 'DELETE_PERIOD_CLEAN';

export const STATUS_REQUEST = 'STATUS_REQUEST';
export const STATUS_SET = 'STATUS_SET';
export const STATUS_CLEAN = 'STATUS_CLEAN';

export function statusDynamicsElementsRequest(payload) {
  return {
    type: STATUS_REQUEST,
    payload: payload,
  };
}

export function statusDynamicsElementsSet(payload) {
  return {
    type: STATUS_SET,
    payload: payload,
  };
}

export function statusDynamicsElementsClean(payload) {
  return {
    type: STATUS_CLEAN,
    payload: payload,
  };
}

export function deletePeriodRequest(payload) {
  return {
    type: DELETE_PERIOD_REQUEST,
    payload: payload,
  };
}

export function deletePeriodSet(payload) {
  return {
    type: DELETE_PERIOD_SET,
    payload: payload,
  };
}

export function deletePeriodClean(payload) {
  return {
    type: DELETE_PERIOD_CLEAN,
    payload: payload,
  };
}

export function changeStatusRequest(payload) {
  return {
    type: CHANGE_STATUS_REQUEST,
    payload: payload,
  };
}

export function changeStatusSet(payload) {
  return {
    type: CHANGE_STATUS_SET,
    payload: payload,
  };
}

export function changeStatusClean(payload) {
  return {
    type: CHANGE_STATUS_CLEAN,
    payload: payload,
  };
}

export function frequencyRequest(payload) {
  return {
    type: FREQUENCY_REQUEST,
    payload: payload,
  };
}

export function frequencySet(payload) {
  return {
    type: FREQUENCY_SET,
    payload: payload,
  };
}

export function frequencyClean(payload) {
  return {
    type: FREQUENCY_CLEAN,
    payload: payload,
  };
}

export function registerRequest(payload) {
  return {
    type: REGISTER_REQUEST,
    payload: payload,
  };
}

export function registerSet(payload) {
  return {
    type: REGISTER_SET,
    payload: payload,
  };
}

export function registerClean(payload) {
  return {
    type: REGISTER_CLEAN,
    payload: payload,
  };
}

export function statusDataRequest(payload) {
  return {
    type: STATUSDATA_REQUEST,
    payload: payload,
  };
}

export function statusDataSet(payload) {
  return {
    type: STATUSDATA_SET,
    payload: payload,
  };
}

export function statusDataClean(payload) {
  return {
    type: STATUSDATA_CLEAN,
    payload: payload,
  };
}

export function clientRequest(payload) {
  return {
    type: CLIENT_REQUEST,
    payload: payload,
  };
}

export function clientSet(payload) {
  return {
    type: CLIENT_SET,
    payload: payload,
  };
}

export function clientClean(payload) {
  return {
    type: CLIENT_CLEAN,
    payload: payload,
  };
}

export function periodRequest(payload) {
  return {
    type: PERIOD_REQUEST,
    payload: payload,
  };
}

export function periodSet(payload) {
  return {
    type: PERIOD_SET,
    payload: payload,
  };
}

export function periodClean(payload) {
  return {
    type: PERIOD_CLEAN,
    payload: payload,
  };
}

export function monthRequest(payload) {
  return {
    type: MONTH_REQUEST,
    payload: payload,
  };
}

export function monthSet(payload) {
  return {
    type: MONTH_SET,
    payload: payload,
  };
}

export function monthClean(payload) {
  return {
    type: MONTH_CLEAN,
    payload: payload,
  };
}

export function businessLinesRequest(payload) {
  return {
    type: BUSINESS_LINES_REQUEST,
    payload: payload,
  };
}

export function businessLinesSet(payload) {
  return {
    type: BUSINESS_LINES_SET,
    payload: payload,
  };
}

export function businessLinesClean(payload) {
  return {
    type: BUSINESS_LINES_CLEAN,
    payload: payload,
  };
}

export function subRegionsRequest(payload) {
  return {
    type: SUBREGIONS_REQUEST,
    payload: payload,
  };
}

export function subRegionsSet(payload) {
  return {
    type: SUBREGIONS_SET,
    payload: payload,
  };
}

export function subRegionsClean(payload) {
  return {
    type: SUBREGIONS_CLEAN,
    payload: payload,
  };
}
