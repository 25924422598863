const getDatesNumber = (firstDateString, secondDateString) => {
  if (!firstDateString || !secondDateString) {
    return false;
  }
  let firstDateSplit = firstDateString.split('/');
  let secondDateSplit = secondDateString.split('/');
  let firstDate = new Date(
    parseInt(firstDateSplit[2]),
    parseInt(firstDateSplit[1]) - 1,
    parseInt(firstDateSplit[0])
  );
  let secondDate = new Date(
    parseInt(secondDateSplit[2]),
    parseInt(secondDateSplit[1]) - 1,
    parseInt(secondDateSplit[0])
  );

  return { firstDate: firstDate.getTime(), secondDate: secondDate.getTime() };
};

export default getDatesNumber;
