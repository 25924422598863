/* eslint-disable react/require-default-props */
import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

export default function StatusTag({ status, label, color }) {
  if (!label && !color) {
    label = status ? 'Activo' : 'Inactivo';
    color = status ? 'green' : '#ff0000';
  }

  return (
    <span>
      <Tag color={color}>{label}</Tag>
    </span>
  );
}
StatusTag.propTypes = {
  status: PropTypes.bool,
  label: PropTypes.string,
  color: PropTypes.string,
};
StatusTag.defaultProps = {
  status: false,
};
