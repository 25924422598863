import reducer from './redux-saga/reducers/';

import {
  watchAccountStatusTradeAgreementGoalsRequestConsultTable,
  watchAccountStatusTradeAgreementGoalsRequestDataExistance,
} from './redux-saga/sagas';

export default function getAccountStatusTradeAgreementGoalsModule() {
  return {
    id: 'ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS',
    reducerMap: {
      ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS: reducer,
    },
    sagas: [
      watchAccountStatusTradeAgreementGoalsRequestConsultTable,
      watchAccountStatusTradeAgreementGoalsRequestDataExistance,
    ],
  };
}
