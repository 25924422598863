import React from 'react';

function DaysLeft({ daysLeft }) {
  return (
    <h4>
      Quedan{' '}
      <span style={{ color: 'red', fontWeight: 'bold', fontSize: '16px' }}>{daysLeft ?? '?'} </span>{' '}
      días{' '}
    </h4>
  );
}

export default DaysLeft;
