import React, { useEffect, useContext } from 'react';
import { Table, Result } from 'antd';
import TableSchema from '../Schemas/tableSchema';
import { layoutContext } from 'context';

const CreditNotesTable = ({ creditNotesData, noResultMessage }) => {
  const { onChangeActiveComponent } = useContext(layoutContext);

  useEffect(() => {
    onChangeActiveComponent('activo');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Table
        dataSource={creditNotesData}
        columns={TableSchema}
        locale={{ emptyText: <Result status="500" title={`${noResultMessage}`} /> }}
      />
    </>
  );
};

export default CreditNotesTable;
