import React from 'react';
import { Transfer as AntdTransfer, Empty, Skeleton } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const Transfer = ({ dataSource, targetKeys, onChange, listStyle, isLoadingTransfer }) => (
  <>
    <div className="modal-header">
      <InfoCircleOutlined className="modal-icon" />
      <p className="modal-title-blue">Asignar estructura comercial</p>
    </div>
    <p className="modal-subtitle">Seleccione los países que desea asignar a la zona</p>

    {isLoadingTransfer ? (
      <div className="modal-skeleton-container">
        <Skeleton.Input className="modal-skeleton" active />
        <Skeleton.Input className="modal-skeleton" active />
      </div>
    ) : (
      <AntdTransfer
        locale={{
          notFoundContent: (
            <Empty description="No hay artículos" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ),
        }}
        dataSource={dataSource}
        targetKeys={targetKeys}
        render={(item) => item.value}
        onChange={onChange}
        listStyle={listStyle}
      />
    )}
  </>
);

export default Transfer;
