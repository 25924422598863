import reducer from './redux-saga/reducers';

import {
  watchSalesMassiveUploadSetAllowUpload,
  watchSalesMassiveUploadRequestVerify,
  watchSalesMassiveUploadRequestUpload,
} from './redux-saga/sagas';

export default function zgetSalesMassiveUploadModule() {
  return {
    id: 'SALES_MASSIVE_UPLOAD',
    reducerMap: {
      SALES_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchSalesMassiveUploadSetAllowUpload,
      watchSalesMassiveUploadRequestVerify,
      watchSalesMassiveUploadRequestUpload,
    ],
  };
}
