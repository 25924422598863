import React, { useState, useEffect } from 'react';
import { getAreas } from '../services';
import { Select, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;

function AreaSelect({ value, disabled, onChange, placeholder }) {
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(undefined);
  const { t } = useTranslation(['users']);

  useEffect(() => {
    if (!areas || !areas.length) {
      getAreas().then((res) => {
        setAreas(res);
        if (value) {
          let matchArea = res.find((el) => el.name === value.replace(/['"]+/g, ''));
          setSelectedArea(JSON.stringify(matchArea));
        }
      });
    }
  }, [value, areas]);
  useEffect(() => {
    setSelectedArea(undefined);
  }, [disabled]);

  const handleProfileSelect = (profile) => {
    let profileObject = JSON.parse(profile);
    onChange(profileObject.id);
    setSelectedArea(profile);
  };

  return (
    <>
      <Col style={{ marginTop: '15px' }} id="areaSelect">
        <p style={{ margin: '0px', color: '#21A0D2' }}>*{t('add.area')}:</p>
        <Select
          style={{ width: '200px' }}
          placeholder={placeholder}
          value={selectedArea}
          onChange={handleProfileSelect}
          disabled={disabled}
        >
          {areas &&
            areas.length > 0 &&
            areas.map((area, key) => (
              <Option value={JSON.stringify(area)} key={key}>
                {area.name}
              </Option>
            ))}
        </Select>
      </Col>
    </>
  );
}

AreaSelect.propTypes = {
  placeholder: PropTypes.string,
};

AreaSelect.defaultProps = {
  placeholder: 'Seleccionar',
};

export default AreaSelect;
