import {
  /* family */
  CONSULT_FILTERS_FAMILY_REQUEST_OPTIONS,
  CONSULT_FILTERS_FAMILY_SET_OPTIONS,
  CONSULT_FILTERS_FAMILY_CLEAR_OPTIONS,
  CONSULT_FILTERS_FAMILY_SET_VALUE,
  CONSULT_FILTERS_FAMILY_CLEAR_VALUE,
  /* business line*/
  CONSULT_FILTERS_BUSINESS_LINE_REQUEST_OPTIONS,
  CONSULT_FILTERS_BUSINESS_LINE_SET_OPTIONS,
  CONSULT_FILTERS_BUSINESS_LINE_CLEAR_OPTIONS,
  CONSULT_FILTERS_BUSINESS_LINE_SET_VALUE,
  CONSULT_FILTERS_BUSINESS_LINE_CLEAR_VALUE,
  /* subregion */
  CONSULT_FILTERS_SUBREGION_REQUEST_OPTIONS,
  CONSULT_FILTERS_SUBREGION_SET_OPTIONS,
  CONSULT_FILTERS_SUBREGION_CLEAR_OPTIONS,
  CONSULT_FILTERS_SUBREGION_SET_VALUE,
  CONSULT_FILTERS_SUBREGION_CLEAR_VALUE,
  /*segmentation*/
  CONSULT_FILTERS_SEGMENTATION_REQUEST_OPTIONS,
  CONSULT_FILTERS_SEGMENTATION_SET_OPTIONS,
  CONSULT_FILTERS_SEGMENTATION_CLEAR_OPTIONS,
  CONSULT_FILTERS_SEGMENTATION_SET_VALUE,
  CONSULT_FILTERS_SEGMENTATION_CLEAR_VALUE,
  /*placement*/
  CONSULT_FILTERS_PLACEMENT_REQUEST_OPTIONS,
  CONSULT_FILTERS_PLACEMENT_SET_OPTIONS,
  CONSULT_FILTERS_PLACEMENT_CLEAR_OPTIONS,
  CONSULT_FILTERS_PLACEMENT_SET_VALUE,
  CONSULT_FILTERS_PLACEMENT_CLEAR_VALUE,
  /* year */
  CONSULT_FILTERS_YEAR_REQUEST_OPTIONS,
  CONSULT_FILTERS_YEAR_SET_OPTIONS,
  CONSULT_FILTERS_YEAR_CLEAR_OPTIONS,
  CONSULT_FILTERS_YEAR_SET_VALUE,
  CONSULT_FILTERS_YEAR_CLEAR_VALUE,
  /*pog year */
  CONSULT_FILTERS_POGYEAR_REQUEST_OPTIONS,
  CONSULT_FILTERS_POGYEAR_SET_OPTIONS,
  CONSULT_FILTERS_POGYEAR_CLEAR_OPTIONS,
  CONSULT_FILTERS_POGYEAR_SET_VALUE,
  CONSULT_FILTERS_POGYEAR_CLEAR_VALUE,
  /* Pog Periodicity */
  CONSULT_FILTERS_POG_PERIODICITY_REQUEST_OPTIONS,
  CONSULT_FILTERS_POG_PERIODICITY_SET_OPTIONS,
  CONSULT_FILTERS_POG_PERIODICITY_CLEAR_OPTIONS,
  CONSULT_FILTERS_POG_PERIODICITY_SET_VALUE,
  CONSULT_FILTERS_POG_PERIODICITY_CLEAR_VALUE,
  /* Sales Periodicity */
  CONSULT_FILTERS_SALES_PERIODICITY_REQUEST_OPTIONS,
  CONSULT_FILTERS_SALES_PERIODICITY_SET_OPTIONS,
  CONSULT_FILTERS_SALES_PERIODICITY_CLEAR_OPTIONS,
  CONSULT_FILTERS_SALES_PERIODICITY_SET_VALUE,
  CONSULT_FILTERS_SALES_PERIODICITY_CLEAR_VALUE,
  /* Pog Period */
  CONSULT_FILTERS_POG_PERIOD_REQUEST_OPTIONS,
  CONSULT_FILTERS_POG_PERIOD_SET_OPTIONS,
  CONSULT_FILTERS_POG_PERIOD_CLEAR_OPTIONS,
  CONSULT_FILTERS_POG_PERIOD_SET_VALUE,
  CONSULT_FILTERS_POG_PERIOD_CLEAR_VALUE,
  /* Sales Period */
  CONSULT_FILTERS_SALES_PERIOD_REQUEST_OPTIONS,
  CONSULT_FILTERS_SALES_PERIOD_SET_OPTIONS,
  CONSULT_FILTERS_SALES_PERIOD_CLEAR_OPTIONS,
  CONSULT_FILTERS_SALES_PERIOD_SET_VALUE,
  CONSULT_FILTERS_SALES_PERIOD_CLEAR_VALUE,
  /* Territories */
  CONSULT_FILTERS_TERRITORIES_REQUEST_OPTIONS,
  CONSULT_FILTERS_TERRITORIES_SET_OPTIONS,
  CONSULT_FILTERS_TERRITORIES_CLEAR_OPTIONS,
  CONSULT_FILTERS_TERRITORIES_SET_VALUE,
  CONSULT_FILTERS_TERRITORIES_CLEAR_VALUE,
  /* Months */
  CONSULT_FILTERS_MONTHS_REQUEST_OPTIONS,
  CONSULT_FILTERS_MONTHS_SET_OPTIONS,
  CONSULT_FILTERS_MONTHS_CLEAR_OPTIONS,
  CONSULT_FILTERS_MONTHS_SET_VALUE,
  CONSULT_FILTERS_MONTHS_CLEAR_VALUE,
  /* Date Range import*/
  CONSULT_FILTERS_DATE_RANGE_REQUEST_OPTIONS,
  CONSULT_FILTERS_DATE_RANGE_SET_OPTIONS,
  CONSULT_FILTERS_DATE_RANGE_CLEAR_OPTIONS,
  CONSULT_FILTERS_DATE_RANGE_SET_VALUE,
  CONSULT_FILTERS_DATE_RANGE_CLEAR_VALUE,
  /* Objectives And Incentives import*/
  CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_REQUEST_OPTIONS,
  CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_SET_OPTIONS,
  CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_CLEAR_OPTIONS,
  CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_SET_VALUE,
  CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_CLEAR_VALUE,
  /* search input */
  CONSULT_FILTERS_SEARCH_INPUT_SET_VALUE,
  CONSULT_FILTERS_SEARCH_INPUT_CLEAR_VALUE,
} from '../actions/consultFilters';

const consultFilters = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    /* family */
    case CONSULT_FILTERS_FAMILY_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        family: { ...state.family, options: null },
      };

    case CONSULT_FILTERS_FAMILY_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        family: { ...state.family, options: action.payload },
      };

    case CONSULT_FILTERS_FAMILY_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        family: { ...state.family, options: null },
      };

    case CONSULT_FILTERS_FAMILY_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        family: { ...state.family, value: action.payload },
      };

    case CONSULT_FILTERS_FAMILY_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        family: { ...state.family, value: [] },
      };
    /* business line*/
    case CONSULT_FILTERS_BUSINESS_LINE_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        businessLine: { ...state.businessLine, options: null },
      };

    case CONSULT_FILTERS_BUSINESS_LINE_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        businessLine: { ...state.businessLine, options: action.payload },
      };

    case CONSULT_FILTERS_BUSINESS_LINE_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        businessLine: { ...state.businessLine, options: null },
      };

    case CONSULT_FILTERS_BUSINESS_LINE_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        businessLine: { ...state.businessLine, value: action.payload },
      };

    case CONSULT_FILTERS_BUSINESS_LINE_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        businessLine: { ...state.businessLine, value: [] },
      };

    /* subregion */
    case CONSULT_FILTERS_SUBREGION_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        subregion: { ...state.subregion, options: null },
      };

    case CONSULT_FILTERS_SUBREGION_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        subregion: { ...state.subregion, options: action.payload },
      };

    case CONSULT_FILTERS_SUBREGION_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        subregion: { ...state.subregion, options: null },
      };

    case CONSULT_FILTERS_SUBREGION_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        subregion: { ...state.subregion, value: action.payload },
      };

    case CONSULT_FILTERS_SUBREGION_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        subregion: { ...state.subregion, value: [] },
      };
    /*segmentation */
    case CONSULT_FILTERS_SEGMENTATION_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        segmentation: { ...state.segmentation, options: null },
      };

    case CONSULT_FILTERS_SEGMENTATION_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        segmentation: { ...state.segmentation, options: action.payload },
      };

    case CONSULT_FILTERS_SEGMENTATION_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        segmentation: { ...state.segmentation, options: null },
      };

    case CONSULT_FILTERS_SEGMENTATION_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        segmentation: { ...state.segmentation, value: action.payload },
      };

    case CONSULT_FILTERS_SEGMENTATION_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        segmentation: { ...state.segmentation, value: [] },
      };
    /* placement */

    case CONSULT_FILTERS_PLACEMENT_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        options: null,
      };

    case CONSULT_FILTERS_PLACEMENT_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        placement: { ...state.placement, options: action.payload },
      };

    case CONSULT_FILTERS_PLACEMENT_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        placement: { ...state.placement, options: null },
      };

    case CONSULT_FILTERS_PLACEMENT_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        placement: { ...state.placement, value: action.payload },
      };

    case CONSULT_FILTERS_PLACEMENT_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        placement: { ...state.placement, value: [] },
      };
    /*search input*/

    case CONSULT_FILTERS_SEARCH_INPUT_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        searchInput: { ...state.searchInput, value: action.payload },
      };

    case CONSULT_FILTERS_SEARCH_INPUT_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        searchInput: { ...state.searchInput, value: '' },
      };
    /*Pog Year */
    case CONSULT_FILTERS_POGYEAR_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        pogyear: { ...state.pogyear, options: null },
      };

    case CONSULT_FILTERS_POGYEAR_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        pogyear: { ...state.pogyear, options: action.payload },
      };

    case CONSULT_FILTERS_POGYEAR_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        pogyear: { ...state.pogyear, options: null },
      };

    case CONSULT_FILTERS_POGYEAR_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        pogyear: { ...state.pogyear, value: action.payload },
      };

    case CONSULT_FILTERS_POGYEAR_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        pogyear: { ...state.pogyear, value: [] },
      };

    /* Year */
    case CONSULT_FILTERS_YEAR_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        year: { ...state.year, options: null },
      };

    case CONSULT_FILTERS_YEAR_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        year: { ...state.year, options: action.payload },
      };

    case CONSULT_FILTERS_YEAR_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        year: { ...state.year, options: null },
      };

    case CONSULT_FILTERS_YEAR_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        year: { ...state.year, value: action.payload },
      };

    case CONSULT_FILTERS_YEAR_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        year: { ...state.year, value: [] },
      };

    /* Pog Periodicity */
    case CONSULT_FILTERS_POG_PERIODICITY_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        pogPeriodicity: { ...state.pogPeriodicity, options: null },
      };

    case CONSULT_FILTERS_POG_PERIODICITY_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        pogPeriodicity: { ...state.pogPeriodicity, options: action.payload },
      };

    case CONSULT_FILTERS_POG_PERIODICITY_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        pogPeriodicity: { ...state.pogPeriodicity, options: null },
      };

    case CONSULT_FILTERS_POG_PERIODICITY_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        pogPeriodicity: { ...state.pogPeriodicity, value: action.payload },
      };

    case CONSULT_FILTERS_POG_PERIODICITY_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        pogPeriodicity: { ...state.pogPeriodicity, value: [] },
      };

    /* Pog Period */
    case CONSULT_FILTERS_POG_PERIOD_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        pogPeriod: { ...state.pogPeriod, options: null },
      };

    case CONSULT_FILTERS_POG_PERIOD_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        pogPeriod: { ...state.pogPeriod, options: action.payload },
      };

    case CONSULT_FILTERS_POG_PERIOD_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        pogPeriod: { ...state.pogPeriod, options: null },
      };

    case CONSULT_FILTERS_POG_PERIOD_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        pogPeriod: { ...state.pogPeriod, value: action.payload },
      };

    case CONSULT_FILTERS_POG_PERIOD_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        pogPeriod: { ...state.pogPeriod, value: [] },
      };

    /* Sales Periodicity */
    case CONSULT_FILTERS_SALES_PERIODICITY_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        salesPeriodicity: { ...state.salesPeriodicity, options: null },
      };

    case CONSULT_FILTERS_SALES_PERIODICITY_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        salesPeriodicity: { ...state.salesPeriodicity, options: action.payload },
      };

    case CONSULT_FILTERS_SALES_PERIODICITY_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        salesPeriodicity: { ...state.salesPeriodicity, options: null },
      };

    case CONSULT_FILTERS_SALES_PERIODICITY_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        salesPeriodicity: { ...state.salesPeriodicity, value: action.payload },
      };

    case CONSULT_FILTERS_SALES_PERIODICITY_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        salesPeriodicity: { ...state.salesPeriodicity, value: [] },
      };

    /* Sales Period */
    case CONSULT_FILTERS_SALES_PERIOD_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        salesPeriod: { ...state.salesPeriod, options: null },
      };

    case CONSULT_FILTERS_SALES_PERIOD_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        salesPeriod: { ...state.salesPeriod, options: action.payload },
      };

    case CONSULT_FILTERS_SALES_PERIOD_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        salesPeriod: { ...state.salesPeriod, options: null },
      };

    case CONSULT_FILTERS_SALES_PERIOD_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        salesPeriod: { ...state.salesPeriod, value: action.payload },
      };

    case CONSULT_FILTERS_SALES_PERIOD_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        salesPeriod: { ...state.salesPeriod, value: [] },
      };

    /* Territories */
    case CONSULT_FILTERS_TERRITORIES_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        territories: { ...state.territories, options: null },
      };

    case CONSULT_FILTERS_TERRITORIES_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        territories: { ...state.territories, options: action.payload },
      };

    case CONSULT_FILTERS_TERRITORIES_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        territories: { ...state.territories, options: null },
      };

    case CONSULT_FILTERS_TERRITORIES_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        territories: { ...state.territories, value: action.payload },
      };

    case CONSULT_FILTERS_TERRITORIES_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        territories: { ...state.territories, value: [] },
      };

    /* Months */
    case CONSULT_FILTERS_MONTHS_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        months: { ...state.months, options: null },
      };

    case CONSULT_FILTERS_MONTHS_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        months: { ...state.months, options: action.payload },
      };

    case CONSULT_FILTERS_MONTHS_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        months: { ...state.months, options: null },
      };

    case CONSULT_FILTERS_MONTHS_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        months: { ...state.months, value: action.payload },
      };

    case CONSULT_FILTERS_MONTHS_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        months: { ...state.months, value: [] },
      };

    /* Date Range */
    case CONSULT_FILTERS_DATE_RANGE_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        dateRange: { ...state.dateRange, options: null },
      };

    case CONSULT_FILTERS_DATE_RANGE_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        dateRange: { ...state.dateRange, options: action.payload },
      };

    case CONSULT_FILTERS_DATE_RANGE_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        dateRange: { ...state.dateRange, options: null },
      };

    case CONSULT_FILTERS_DATE_RANGE_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        dateRange: { ...state.dateRange, value: action.payload },
      };

    case CONSULT_FILTERS_DATE_RANGE_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        dateRange: { ...state.dateRange, value: [] },
      };

    /* Objectives And Incentives */
    case CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_REQUEST_OPTIONS:
      return {
        ...state,
        isLoading: true,
        objectivesAndIncentives: { ...state.objectivesAndIncentives, options: null },
      };

    case CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_SET_OPTIONS:
      return {
        ...state,
        isLoading: false,
        objectivesAndIncentives: { ...state.objectivesAndIncentives, options: action.payload },
      };

    case CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_CLEAR_OPTIONS:
      return {
        ...state,
        isLoading: false,
        objectivesAndIncentives: { ...state.objectivesAndIncentives, options: null },
      };

    case CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_SET_VALUE:
      return {
        ...state,
        isLoading: false,
        objectivesAndIncentives: { ...state.objectivesAndIncentives, value: action.payload },
      };

    case CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_CLEAR_VALUE:
      return {
        ...state,
        isLoading: false,
        objectivesAndIncentives: { ...state.objectivesAndIncentives, value: undefined },
      };

    default:
      return state;
  }
};

export default consultFilters;
