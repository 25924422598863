import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '../../global';
import { ActionButtons, openNotification } from 'common';
import { connect } from 'react-redux';
import { CatalogComponentsMap } from './componentsMap';
import { Row, Col, Button, Modal } from 'antd';
import getRootModule from 'RootModule';
import getGoalsModule from './goalsModule';
import { layoutContext } from '../../context';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { readOneGoalsRequest, updateGoalsRequest, deleteGoalsRequest } from './redux-saga/actions';

import { useCatalogScope, applyDefaultValuesAndReadOnly, applyDefaultValues } from '../utils';
import './catalog.css';
const serviceValues = {
  region: 'test',
  regionDirector: 'alan.estrada@mail.com',
};
function CatalogForm(props) {
  let history = useHistory();
  const {
    match,
    readOneGoalsRequest: readOneGoalstReq,
    updateGoalsRequest: updateGoals,
    deleteGoalsRequest: deleteGoals,
  } = props;
  const { update, zoneId, clientId, productId, reference, productNumber } = history.location.state;

  const catalogScope = useCatalogScope(match);
  const [elements, setElements] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState(false);
  const [dataAp, setDataAp] = useState(null);
  const { onChangeActiveComponent } = useContext(layoutContext);

  const params = {
    ZoneId: zoneId,
    ClientId: clientId,
    ProductId: productId,
    ProductNumber: productNumber,
    Year: parseInt(reference),
  };

  useEffect(() => {
    onChangeActiveComponent('sales-challenge');
    readOneGoalstReq(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getCSS = async () => {
      await import(`../schemas/${catalogScope.name}/${catalogScope.name}.css`);
    };
    getCSS();
    import(`../schemas/${catalogScope.name}`)
      .then((schema) => {
        switch (catalogScope.action) {
          // TODO: Refactor setServiceOptions to ONE method
          case 'register':
            if (schema.setServiceOptions) {
              schema.setServiceOptions(schema.elements).then((res) => setElements(res));
            } else {
              setElements(schema.elements);
            }
            break;
          case 'update':
            // Get values and add elements values
            setFormValues(serviceValues);
            if (schema.setServiceOptions) {
              setElements(setElements(applyDefaultValues(schema.elements, serviceValues)));
            } else {
              setElements(schema.elements);
            }
            break;
          case 'detail':
            // Get values and apply read only
            setFormValues(serviceValues);
            setElements(applyDefaultValuesAndReadOnly(schema.elements, serviceValues));
            break;
          default:
            setElements(schema.elements);
        }
      })
      .catch(() => history.push('/'));
  }, [catalogScope.name, catalogScope.action, history]);

  const handleFormChange = (formid, values) => {
    setFormValues(values);
  };

  const handleErrors = (errors) => {
    setFormErrors(errors);
  };

  useEffect(() => {
    import(`../schemas/${catalogScope.name}`)
      .then((schema) => {
        setDisabled(schema.disabledValidation(formValues, formErrors));
      })
      .catch(() => history.push('/'));
  }, [formValues, formErrors, catalogScope.name, history]);

  const handleCancel = () => {
    onChangeActiveComponent('sales-challenge');
    history.push('/catalog/selling-goals');
  };

  const handleSubmit = () => {
    const months = props.goalsData.dataUpdate.map((month) => {
      if (month.volume == null) month.volume = 0;
      if (month.usdAmount == null) month.usdAmount = 0;
      return month;
    });
    const onSuccess = (message) => {
      openNotification('success', message);
      setTimeout(() => {
        history.push('/catalog/selling-goals');
      }, 3000);
    };
    const errorMessage = (message) => {
      openNotification('error', message);
    };
    // eslint-disable-next-line no-shadow
    const { lineId, zoneId, soldTo, productNumber, crop, year } = props.goalsData.data;
    // eslint-disable-next-line no-shadow
    const params = {
      lineId: lineId,
      zoneId: zoneId,
      soldTo: soldTo,
      productNumber: productNumber,
      crop: dataAp !== null ? dataAp : crop,
      saleMonth: months,
      year: year,
    };
    updateGoals({ params, onSuccess, errorMessage });
  };
  const goUpdate = () => {
    const { state } = history.location;
    state.update = true;
    history.push({
      pathname: `/catalog/selling-goals/update/${state.clientId}`,
      state: state,
    });
  };

  const onClickDeleteGoals = () => {
    const onSuccess = (message) => {
      openNotification('success', message);
      setTimeout(() => {
        history.push('/catalog/selling-goals');
      }, 3000);
    };
    const errorMessage = (message) => {
      openNotification('error', message);
    };
    // eslint-disable-next-line no-shadow
    const { lineId, zoneId, soldTo, productNumber, year } = props.goalsData.data;
    // eslint-disable-next-line no-shadow
    const params = {
      lineId: lineId,
      zoneId: zoneId,
      soldTo: soldTo,
      productNumber: productNumber,
      year: year,
    };

    Modal.confirm({
      title: 'Eliminar meta de venta',
      content: `¿Está seguro que desea eliminar la meta de venta?`,
      onOk() {
        deleteGoals({ params, onSuccess, errorMessage });
      },
    });
  };
  let user = JSON.parse(localStorage.getItem('user'));
  let { roleId } = user.role;

  let button = ['cancel', update ? 'submit' : 'goToEditPage'];
  if (roleId === 6 || roleId === 7) {
    button = ['cancel'];
  }

  const handleChangeAp = (e) => {
    setDataAp(e.target.value);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Form
            componentsMap={CatalogComponentsMap}
            inputClassName={'form-catalog-input'}
            formId={`form-${catalogScope.name}`}
            elements={elements}
            handleChangeAp={handleChangeAp}
            defaultValues={formValues}
            handleValidations={handleErrors}
            handleChange={handleFormChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {update && (
            <Button type="primary" className="deleteButton" onClick={onClickDeleteGoals}>
              Eliminar
            </Button>
          )}

          <ActionButtons
            onCancel={handleCancel}
            onSubmit={update ? handleSubmit : null}
            goToEditPage={goUpdate}
            buttons={button}
            disabled={disabled}
          />
        </Col>
      </Row>
    </>
  );
}

function mapStateToProps(state) {
  return { goalsData: state.GOALS.Goals };
}

const ConnectedCatalogForm = connect(mapStateToProps, {
  readOneGoalsRequest,
  updateGoalsRequest,
  deleteGoalsRequest,
})(CatalogForm);

const Catalog = ({ match }) => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getGoalsModule()]}>
      <ConnectedCatalogForm match={match} />
    </DynamicModuleLoader>
  );
};

export default Catalog;
