export let yearOptions = [];

var yearMax = new Date().getFullYear();

for (var i = yearMax; i >= 2018; i--) {
  yearOptions.push({
    value: `${i}`,
    key: i,
  });
}
