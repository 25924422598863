/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, Button } from 'antd';
import { Maps, TypeElement } from '../Components';
// import { map } from 'leaflet';
import './index.css';

const ModalRegister = (props) => {
  const { colorPicker, setcolorPicker, FileMap } = props;
  const {
    getExcel,
    getTypeElement,
    getTypeElementClean,
    postTypeElement,
    postDisplayExcel,
    putTypeElement,
    deleteTypeElement,
    mapsState,
    stateModal,
    setstateModal,
    openModal,
    setVisibleModal,
    fetchBusinessStructure,
    postValidateFiles,
  } = props;

  const [fileRegister, setFileRegister] = useState([]);
  const [enabledButton, setEnabledButton] = useState(true);
  const [nameFileExcel, setNameFileExcel] = useState([]);

  useEffect(() => {
    if (stateModal.modal === 'Consultar') {
      getTypeElement({ TypeElement: stateModal.type, params: stateModal.id });
    }
  }, [stateModal]);

  const handleCancel = () => {
    setEnabledButton(true);
    setVisibleModal(false);
    getTypeElementClean();
    setFileRegister([]);
    setcolorPicker({ hex: '#00499a' });
  };

  const handleError = () => {
    setEnabledButton(true);
    setFileRegister([]);
  };

  const handleOk = () => {
    if (stateModal.modal === 'Registrar') {
      postTypeElement({
        handleCancel,
        fetchBusinessStructure,
        TypeElement: stateModal.type,
        structureId: stateModal.id,
        color: colorPicker.hex,
        csvFile: fileRegister,
        namesCsvFiles: nameFileExcel,
      });
    } else if (stateModal.modal === 'Consultar') {
      handleCancel();
    } else if (stateModal.modal === 'Modificar') {
      putTypeElement({
        handleCancel,
        fetchBusinessStructure,
        TypeElement: stateModal.type,
        structureId: stateModal.id,
        color: colorPicker.hex,
        csvFile: fileRegister,
        namesCsvFiles: nameFileExcel,
      });
    }
  };
  const handleActualizar = () => {
    setstateModal({ ...stateModal, modal: 'Modificar' });
    setcolorPicker({ hex: mapsState?.TypeElement?.data?.color });
  };
  const handleCancelModif = () => {
    setstateModal({ ...stateModal, modal: 'Consultar' });
    getTypeElementClean();
    setcolorPicker({ hex: '' });
  };

  const saveEnabledButton = (e) => {
    setEnabledButton(e);
  };

  return (
    <Modal
      title={`${stateModal.modal} mapa`}
      style={{ color: 'red' }}
      visible={openModal}
      width={1000}
      maskClosable={false}
      onCancel={handleCancel}
      footer={[
        /* BOTON CANCELAR */
        stateModal.modal === 'Registrar' ? (
          <Button
            key="back"
            style={{ backgroundColor: '#0078b3', color: 'white' }}
            onClick={handleCancel}
            // loading={loading}
          >
            Cancelar
          </Button>
        ) : null,
        /* BOTON CANCELAR EN MODIFICAR */
        stateModal.modal === 'Modificar' ? (
          <Button
            key="back"
            style={{ backgroundColor: '#0078b3', color: 'white' }}
            onClick={handleCancelModif}
            // loading={loading}
          >
            Cancelar
          </Button>
        ) : null,

        /* update button */
        stateModal.modal === 'Consultar' ? (
          <Button
            key="back"
            style={{ backgroundColor: '#0078b3', color: 'white' }}
            onClick={handleActualizar}
            disabled={!stateModal.enabled}
            className={!stateModal.enabled && 'button-disabled'}
            // loading={loading}
          >
            Actualizar
          </Button>
        ) : null,

        /* BOTON ACEPTAR */
        stateModal.modal !== 'Consultar' && (
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            // loading={loading}
            disabled={enabledButton}
          >
            Aceptar
          </Button>
        ),
      ]}
    >
      <Row>
        <Col xs={11} md={11}>
          <Maps
            color={colorPicker}
            excelCoordinates={FileMap}
            type={stateModal.modal}
            mapsState={mapsState}
          />
        </Col>
        <Col xs={7} md={7} offset={6}>
          <TypeElement
            mapsState={mapsState}
            stateModal={stateModal}
            setcolorPicker={setcolorPicker}
            colorPicker={colorPicker}
            setFileRegister={setFileRegister}
            postDisplayExcel={postDisplayExcel}
            fileRegister={fileRegister}
            deleteTypeElement={deleteTypeElement}
            getTypeElementClean={getTypeElementClean}
            getExcel={getExcel}
            handleCancel={handleCancel}
            handleError={handleError}
            fetchBusinessStructure={fetchBusinessStructure}
            enabledButton={saveEnabledButton}
            postValidateFiles={postValidateFiles}
            setNameFileExcel={setNameFileExcel}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalRegister;
