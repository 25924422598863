import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

/**
 * BButton is an encapsulation of Ant Desing Button.
 */

function BButton(props) {
  const { label, shape, type, disable, ...other } = props;

  return (
    <Button
      shape={shape}
      disabled={disable}
      type={type}
      className={other.icon ? 'ant-btn-icon-only' : ''}
      {...other}
    >
      {label}
    </Button>
  );
}

BButton.propTypes = {
  label: PropTypes.string,
  shape: PropTypes.oneOf(['round', 'circle', 'square']),
  type: PropTypes.oneOf(['primary', 'ghost', 'dashed', 'link', 'text', 'default']),
  disable: PropTypes.bool,
};

BButton.defaultProps = {
  label: '',
  shape: 'square',
  type: 'default',
  disable: false,
};

export default BButton;
