import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { openNotification } from 'common';
import ValidationMessage from 'AccountStatus/ManageImages/utils/validationMessage';
import validations from './utils/validations';
import SectionImages from '../SectionImages';
import columns from './utils/columns';
import './tableComponent.css';
import { useHistory } from 'react-router-dom';

// Imports Redux
import getRootModule from 'RootModule';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getManageImagesModule from '../../Redux-saga/getModule';
import { DrawerAddActions, TableActions, DrawerEditActions } from '../../Redux-saga/actions';

const { InputImagesSet } = DrawerAddActions;
const { PUTImageLineRequest } = DrawerEditActions;
const { RowClickSet, InputFilesCameraSet, ConsultManageImagesRequestTable, GETListImagesRequest } =
  TableActions;

const TableImagesComponent = (props) => {
  const {
    //Get Table Data, Comerial Structure
    ConsultManageImagesRequestTable: getTableData,
    dataTable,
    isLoading,
    // Imagenes de DrawerAdd en base64
    InputImagesSet: setInputImages,
    // Row Click
    RowClickSet: setrowClick,
    rowClick,
    //Input Files Camera
    InputFilesCameraSet: setinputFiles,
    inputFiles,
    // Change Image data
    changeImageData,
    //PUT Image Line
    PUTImageLineRequest: PUTImageLine,
    // Obtiene imagenes por estructura comercial
    GETListImagesRequest: GETListImages,
    multiplesFiles,
  } = props;
  //tamaño tabla
  // const heigthWindow = window.screen.height;
  // const heigthTable = heigthWindow - heigthWindow / 3;
  const [rowsOpen, setrowsOpen] = useState([]);
  const history = useHistory();
  //Al ingresar archivos se validan
  useEffect(() => {
    //Add images
    if (multiplesFiles === true) {
      if (inputFiles?.length > 5) {
        setinputFiles([]);
        setInputImages([]);
        openNotification(
          'error',
          'Solo se permite cargar máximo 5 imágenes por nivel de estructura comercial o cliente'
        );
        return;
      }
      if (inputFiles?.length >= 1 && inputFiles?.length <= 5) {
        functionValidations();
      }
    }

    // Change one image
    if (multiplesFiles === false) {
      if (inputFiles?.length === 1) {
        functionValidations();
      } else if (inputFiles?.length > 1) {
        setinputFiles([]);
        ValidationMessage(inputFiles);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFiles]);

  // Función de validaciones de archivos
  const functionValidations = async () => {
    await validations({
      inputFiles,
      setInputImages,
      setinputFiles,
      multiplesFiles,
      rowClick,
      changeImageData,
      GETListImages,
      PUTImageLine,
    });
  };

  //Get Table Data, Comerial Structure
  useEffect(() => {
    getTableData({ history });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sectionImages = (record) => {
    return <SectionImages record={record} />;
  };
  const changeRowsOpen = (open, record) => {
    if (open === false) {
      // Si es de Linea cierra todas las rows
      if (record.key.slice(0, 1) === 'L') {
        setrowsOpen([]);
        return;
      }
      // Cierra sus Rows hijas
      const newRowsOpen = rowsOpen.filter((key) => !key.includes(record.key.slice(0, 2)));
      setrowsOpen(newRowsOpen);
      return;
    }

    // Abre una Row
    setrowsOpen([...rowsOpen, record.key]);
  };
  return (
    <Table
      columns={columns}
      dataSource={dataTable}
      pagination={false}
      loading={isLoading}
      indentSize={15}
      expandable={{
        expandedRowKeys: rowsOpen,
        onExpand: (open, record) => {
          changeRowsOpen(open, record);
        },
        expandedRowRender: (record) => {
          return sectionImages(record);
        },
        rowExpandable: (record) => record?.containsImages === true || record?.children?.length > 0,
      }}
      onRow={(record) => {
        return {
          onClick: () => {
            setrowClick(record);
          },
        };
      }}
      // scroll={{ y: heigthTable }}
      // checkStrictly={false}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    rowClick: state?.MANAGE_IMAGES?.Table?.rowClick,
    inputFiles: state?.MANAGE_IMAGES?.Table?.inputFilesCamera,
    dataTable: state?.MANAGE_IMAGES?.Table?.consultTable?.lines,
    isLoading: state?.MANAGE_IMAGES?.Table?.isLoading,
    changeImageData: state?.MANAGE_IMAGES?.Table?.changeImage,
    multiplesFiles: state?.MANAGE_IMAGES?.Table?.areMultipleFiles,
  };
};

const ConnectedManageImages = connect(mapStateToProps, {
  InputImagesSet,
  RowClickSet,
  InputFilesCameraSet,
  PUTImageLineRequest,
  ConsultManageImagesRequestTable,
  GETListImagesRequest,
})(TableImagesComponent);

const TableImages = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getManageImagesModule()]}>
      <ConnectedManageImages />
    </DynamicModuleLoader>
  );
};
export default TableImages;
