import {
  EDIT_MANAGE_IMAGES_POSITION_REQUEST,
  EDIT_MANAGE_IMAGES_POSITION_SET,
  EDIT_MANAGE_IMAGES_POSITION_CLEAN,
} from '../../actions/PositionImage';

function ManageImagesReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case EDIT_MANAGE_IMAGES_POSITION_REQUEST:
      return {
        ...state,
        isLoading: true,
        editPosition: null,
        dataSection: payload,
      };

    case EDIT_MANAGE_IMAGES_POSITION_SET:
      return {
        ...state,
        isLoading: false,
        editPosition: payload,
      };

    case EDIT_MANAGE_IMAGES_POSITION_CLEAN:
      return {
        ...state,
        isLoading: false,
        editPosition: null,
      };

    default:
      return state;
  }
}

export default ManageImagesReducer;
