import React from 'react';
import { Modal } from 'antd';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';
import BButton from 'common/components/BButton';
import BTable from 'common/components/BTable';
import { defaultColumns } from 'SalesChallengeRefactor/models/tables/defaultHistory';
import './style.css';

const IconDialog = () => (
  <svg
    t="1603854905397"
    viewBox="0 0 1092 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="23px"
    height="23px"
  >
    <path
      d="M412.603733 781.243733l-230.536533 182.0672v-182.0672H60.689067V53.111467H1031.509333v728.132266H412.603733z m-24.234666-60.6208h582.519466v-606.890666H121.378133v606.890666h121.378134v115.234134l145.6128-115.234134z"
      fill="#fff"
    />
    <path
      d="M242.756267 477.866667h606.754133v60.689066H242.756267V477.866667z m0-182.0672h606.754133v60.757333H242.756267v-60.757333z"
      fill="#fff"
    />
  </svg>
);

const DialogIcon = (props) => <Icon component={IconDialog} {...props} />;

function BModalHistory(props) {
  const { dataSource, columns, setIsOpen, isOpen } = props;
  return (
    <>
      <BButton
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 34,
          width: 34,
        }}
        icon={<DialogIcon />}
        className="dialog-button"
        title="Historial de validaciones "
        onClick={() => setIsOpen(!isOpen)}
      />

      <Modal
        visible={isOpen}
        width={window.screen.width - 500}
        className="history-modal"
        title="Historial de validaciones"
        onCancel={() => setIsOpen(!isOpen)}
        onOk={() => setIsOpen(!isOpen)}
      >
        <BTable columns={columns} dataSource={dataSource} />
      </Modal>
    </>
  );
}

export default BModalHistory;

BModalHistory.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
};

BModalHistory.defaultProps = {
  columns: defaultColumns,
  dataSource: [],
};
