import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Switch } from 'antd';
import BButton from 'common/components/BButton';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './ActionButtons.css';

/* Styles for buttons */
export const actionButtonsStyle = {
  margin: '16px',
  marginTop: '10px',
  color: 'white',
  borderRadius: '5px',
  height: '35px',
  width: '220px',
  fontSize: '1.1em',
  border: 'none',
  fontWeight: '600',
};

const cancelButton = {
  ...actionButtonsStyle,
  backgroundColor: '#247ba9',
};

const submitButton = {
  ...actionButtonsStyle,
  backgroundColor: '#004a96',
};

const deleteButton = {
  ...actionButtonsStyle,
  backgroundColor: '#004a96',
};

/* Default Button props */
const defaultAction = (a) => {
  return;
};

const DEFAULTS = (button, props) => {
  switch (button) {
    case 'cancel':
      return {
        type: 'cancel',
        buttonStyle: { ...cancelButton, ...props?.cancelStyle },
        action: props.onCancel ? props.onCancel : defaultAction,
        disabled: false,
        isLoading: props.isLoading,
      };
    case 'previous':
      return {
        type: 'previous',
        buttonStyle: { ...cancelButton, ...props?.cancelStyle },
        action: props.onPrevious ? props.onPrevious : defaultAction,
        disabled: false,
        isLoading: props.isLoading,
      };
    case 'submit':
      return {
        type: 'submit',
        buttonStyle: { ...submitButton, ...props?.submitStyle },
        buttonClassName: 'btn-add-user',
        disabled: props.disabled,
        buttonHtmlType: 'submit',
        action: props.onSubmit ? props.onSubmit : defaultAction,
        isLoading: props.isLoading,
      };
    case 'next':
      return {
        type: 'next',
        buttonStyle: { ...submitButton, ...props?.submitStyle },
        buttonClassName: 'btn-add-user',
        disabled: props.disabled,
        buttonHtmlType: 'submit',
        action: props.onNext ? props.onNext : defaultAction,
        isLoading: props.isLoading,
      };
    case 'goToEditPage':
      return {
        type: 'goToEditPage',
        buttonStyle: submitButton,
        disabled: props.disabled,
        action: props.goToEditPage ? props.goToEditPage : defaultAction,
      };
    case 'update':
      return {
        type: 'update',
        buttonStyle: submitButton,
        buttonClassName: 'btn-add-user',
        disabled: props.disabled,
        buttonHtmlType: 'submit',
        action: props.onUpdate ? props.onUpdate : defaultAction,
        isLoading: props.isLoading,
      };
    case 'delete':
      return {
        type: 'delete',
        buttonStyle: deleteButton,
        buttonClassName: 'btn-add-user',
        disabled: props.disabled,
        buttonHtmlType: 'delete',
        action: props.onDelete ? props.onDelete : defaultAction,
        isDeleteting: props.isDeleteting,
        buttonLabel: 'Borrar',
      };
    default:
      break;
  }
};

function ActionButtons(props) {
  const { style, buttons, statusSwitch, switchNeither } = props;
  const { t } = useTranslation(['common']);
  const [buttonsArray, setButtonsArray] = useState();

  /**
   * Set default buttons to prevent compatibilty issues
   * Set on effect so it can be dynamic
   **/
  useEffect(() => {
    if (!buttons) {
      setButtonsArray(['cancel', 'submit']);
    } else {
      setButtonsArray(buttons);
    }
  }, [buttons]);

  return (
    <>
      <Row justify={statusSwitch ? 'space-between' : 'end'} style={{ margin: '10px 0px 0px 0px' }}>
        {statusSwitch && (
          <Col>
            <Switch
              checkedChildren="Desactivado"
              unCheckedChildren="Activado"
              className={`estatus-switch ${switchNeither ? 'half-switch' : ''}`}
              checked={statusSwitch?.checked}
              disabled={statusSwitch?.disabled}
              onChange={(value) => props?.statusChange(value)}
              style={{ backgroundColor: `${statusSwitch?.checked ? 'red' : 'green'}` }}
            />
          </Col>
        )}
        <Col>
          <Row justify="end">
            {buttonsArray &&
              buttonsArray.map((button, key) => {
                const BUTTON = typeof button === 'string' ? DEFAULTS(button, props) : button;
                const {
                  type,
                  buttonStyle,
                  buttonClassName,
                  action,
                  disabled,
                  buttonHtmlType,
                  isLoading,
                  buttonLabel,
                } = BUTTON;
                return (
                  <Fragment key={key}>
                    <Col>
                      <BButton
                        block
                        data-testid={`${type}-button`}
                        style={{ ...buttonStyle, ...style }}
                        className={`ant-btn btn-action-button ${buttonClassName}`}
                        onClick={action}
                        disabled={type !== 'cancel' && disabled}
                        htmlType={buttonHtmlType}
                        loading={isLoading}
                        label={buttonLabel ? buttonLabel : t(`buttons.${type}`)}
                        key={`btn${buttonLabel}`}
                      />
                    </Col>
                  </Fragment>
                );
              })}
          </Row>
        </Col>
      </Row>
    </>
  );
}
export default ActionButtons;

ActionButtons.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  isLoading: PropTypes.bool,

  switchNeither: PropTypes.bool,
};

ActionButtons.defaultProps = {
  isLoading: false,

  switchNeither: false,
};
