import React from 'react';
import { Col } from 'antd';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { FaPlusCircle, FaList, FaFileUpload } from 'react-icons/fa';
import Styles from './catalog-card.module.css';

function CatalogCard({ title, add, upload, consult, span = 8 }) {
  return (
    <Col span={span}>
      <div className={Styles[`catalog-Card`]}>
        <div className={Styles[`catalog-card-body`]}>
          <h2 className={Styles[`catalog-card-title`]}>{title}</h2>
        </div>
        <div className={Styles[`catalog-card-footer`]}>
          <IconContext.Provider value={{ color: 'white' }}>
            {!consult && !upload && !add ? (
              <div className={Styles[`catalog-card-footer-action`]}>
                <Link>
                  <span>
                    <FaList style={{ color: 'transparent' }} />
                  </span>
                </Link>
              </div>
            ) : (
              <>
                <div className={Styles[`catalog-card-footer-action`]}>
                  {add && (
                    <Link to={add}>
                      <span>
                        <FaPlusCircle />
                      </span>
                    </Link>
                  )}
                </div>
                <div className={Styles[`catalog-card-footer-action`]}>
                  {upload && (
                    <Link to={upload}>
                      <span>
                        <FaFileUpload />
                      </span>
                    </Link>
                  )}
                </div>
                <div className={Styles[`catalog-card-footer-action`]}>
                  {consult && (
                    <Link to={consult}>
                      <span>
                        <FaList />
                      </span>
                    </Link>
                  )}
                </div>
              </>
            )}
          </IconContext.Provider>
        </div>
      </div>
    </Col>
  );
}
export default CatalogCard;
