import React, { useState } from 'react';
import { Modal } from 'antd';
import { BSelect } from 'common/components';
import { useHistory } from 'react-router-dom';
import { IconsMap } from 'Layout/utils';
//App Common Data
import { ruleTypeEnum } from 'common/models/enums';
const ExclamationIcon = IconsMap.ExclamationCircleOutlined;
const options = [
  {
    id: 1,
    name: 'Productos fuera de disponibilidad',
    value: ruleTypeEnum.PRODUCTOS_FUERA_DE_DISPONIBILIDAD,
  },
  { id: 2, name: 'Productos fuera de política', value: ruleTypeEnum.PRODUCTOS_FUERA_DE_POLITICA },
  { id: 3, name: 'Programa de incentivos', value: ruleTypeEnum.PROGRAMA_DE_INCENTIVOS },
];

const subTypeOptions = [
  {
    id: 1,
    name: 'Estructura comercial',
    value: 1,
  },
  {
    id: 2,
    name: 'Cliente',
    value: 2,
  },
];

const RuleSelector = ({ onChange, setSelectedSubType, setSelectedRule, handleSubRuleChange }) => {
  const [okIsDisabled, setOkIsDisabled] = useState(true);
  const [modalIsVisible, setModalIsVisible] = useState(true);
  const [selectedOption, setSelectedOption] = useState('');
  const { push } = useHistory();

  const handleSelectOnChange = (value) => {
    setOkIsDisabled(value !== 2 && value !== undefined && value !== null ? false : true);
    if (onChange) onChange(value);
    setSelectedOption(value);
  };

  const handleModalCancel = () => {
    setModalIsVisible(false);
    setOkIsDisabled(true);
    push('/catalog');
  };

  const handleModalOnOK = () => {
    setModalIsVisible(false);
  };

  return (
    <Modal
      closable={false}
      visible={modalIsVisible}
      destroyOnClose
      okButtonProps={{ disabled: okIsDisabled }}
      onCancel={handleModalCancel}
      maskClosable={false}
      onOk={handleModalOnOK}
    >
      <div className="header-modal">
        <ExclamationIcon style={{ fontSize: '1.5rem', alignSelf: 'center' }} />
        <p>Seleccionar tipo de regla de negocio</p>
      </div>
      <p>Antes de realizar la carga masiva favor de seleccionar el tipo de regla de negocio</p>
      <BSelect
        label="Tipo de regla de negocio"
        options={options}
        valueKey="value"
        optionKey="id"
        labelKey="name"
        onChange={handleSelectOnChange}
      />

      {selectedOption === 2 ? (
        <BSelect
          label="Subtipo"
          options={subTypeOptions}
          valueKey="value"
          optionKey="id"
          labelKey="name"
          onChange={(value) => {
            setSelectedSubType(value);
            handleSubRuleChange(value);
            setOkIsDisabled(value !== undefined && value !== null ? false : true);
            if (value === 2) {
              setSelectedRule('cliente');
            }
          }}
        />
      ) : null}
    </Modal>
  );
};

export default RuleSelector;
