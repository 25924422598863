import { takeEvery, all, call, put, cancelled, takeLatest } from 'redux-saga/effects';

import {
  Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY,
  Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  CreditNotesMassiveUploadSetAllowUpload,
  CreditNotesMassiveUploadClearAllowUpload,
  CreditNotesMassiveUploadSetVerify,
  CreditNotesMassiveUploadClearVerify,
  CreditNotesMassiveUploadClearUpload,
  validateElementsSet,
  validateElementsFail,
  VALIDATE_ELEMENTS_REQUEST,
  GET_VALIDATE_ELEMENTS,
  VALIDATE_ELEMENTS_FAIL,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';

import { CreditNotesMassiveUpload, requestElementsExistence } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';
import { openNotification } from 'common';

function* workCreditNotesMassiveUploadSetAllowUpload(action) {
  try {
    yield CreditNotesMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield CreditNotesMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield CreditNotesMassiveUploadClearAllowUpload();
    }
  }
}

function* workCreditNotesMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de notas de crédito debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(CreditNotesMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(CreditNotesMassiveUploadSetVerify(data));
      yield call(summaryModal, message, false);
    } else {
      yield put(CreditNotesMassiveUploadClearVerify());
    }
  } catch (error) {
    yield CreditNotesMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield CreditNotesMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workCreditNotesMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, overwrite } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(CreditNotesMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite,
      },
    });
    if (responseOK(response)) {
      yield call(
        summaryEndModal,
        response.data,
        history,
        '/account-status/base-information/credit-notes/consult'
      );
    } else {
      yield put(CreditNotesMassiveUploadClearUpload());
    }
  } catch (error) {
    yield CreditNotesMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield CreditNotesMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchCreditNotesMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD, workCreditNotesMassiveUploadSetAllowUpload),
  ]);
}

export function* watchCreditNotesMassiveUploadRequestVerify() {
  yield all([
    takeEvery(Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY, workCreditNotesMassiveUploadRequestVerify),
  ]);
}

export function* watchCreditNotesMassiveUploadRequestUpload() {
  yield all([
    takeEvery(Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD, workCreditNotesMassiveUploadRequestUpload),
  ]);
}

function* reqValidateElements(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { history } = payload;
  try {
    const response = yield call(requestElementsExistence, { cancelToken: source.token });
    if (responseOK(response)) {
      yield put(validateElementsSet(response.data));
      if (response.data === false) {
        setTimeout(() => {
          openNotification(
            'error',
            response.message
              ? response.mesage
              : 'Es necesario registrar la información correspondiente a elementos dinámicos en el sistema, antes de realizar el registro de Carga Masiva de Notas de Crédito. Realice el registro de elementos dinámicos de estado de cuenta para continuar con la operación'
          );
          history.push('/account-status');
        }, 2000);
      }
    } else {
      yield put(validateElementsFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(validateElementsFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaRequestSectionData() {
  yield takeLatest(VALIDATE_ELEMENTS_REQUEST, reqValidateElements);
}

export function* SagaGetSectionData() {
  yield takeLatest(GET_VALIDATE_ELEMENTS, validateElementsSet);
}

export function* SagaSectionDataFail() {
  yield takeLatest(VALIDATE_ELEMENTS_FAIL, validateElementsFail);
}
