import React, { useState, forwardRef, useEffect } from 'react';
import { Modal, Form, Space } from 'antd';
import BButton from '../BButton';
import { IconsMap } from '../../../Layout/utils';
import PropTypes from 'prop-types';
import Products from 'RootModule/catalogs/AddProducts';
import './AddProductModal.css';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
const ExclamationIcon = IconsMap.ExclamationCircleOutlined;
const AddProductModal = forwardRef((props, ref) => {
  const {
    onOk,
    onCancel,
    visible,
    zoneId,
    // territoryId,
    clientId,
    okIsLoading,
    okIsDisabled,
    onOneElementIsValid,
    selectedProduct,
    hasTerritoryId: hasTerritory,
    sourceId,
  } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ products: [0] });
  }, [form]);

  const [showAdd, setShowAdd] = useState(false);

  //TODO: Use a regular expression to make this  better
  const chekcIfIsAValidUUID = (uuid = 'na') => {
    return uuid?.length === 36 ?? false;
  };

  //TODO USE only one count to avoid repeted code
  const onValuesChange = ({ changedValues }) => {
    const changedProduct = changedValues.products;
    if (changedProduct?.length) {
      const undefinedElementsCount = changedProduct.filter((element, index) => {
        let elementIsValid;
        if (element) {
          elementIsValid = chekcIfIsAValidUUID(element[Object.keys(element)[0]]);
        }
        return typeof element === 'undefined' || !elementIsValid;
      }).length;
      setShowAdd(undefinedElementsCount === 0);
      const definedElementsCount = changedProduct.filter((element, index) => {
        let elementIsValid;
        if (element) elementIsValid = chekcIfIsAValidUUID(element[`product${index}`]);
        return elementIsValid;
      }).length;
      if (definedElementsCount >= 1) onOneElementIsValid();
    }
  };

  const hasTerritoryId = () => {
    if (hasTerritory) {
      return hasTerritory();
    } else {
      return {};
    }
  };

  return (
    <Modal
      className="modal-add-product"
      onOk={onOk}
      onCancel={onCancel}
      visible={visible}
      okButtonProps={{ loading: okIsLoading, disabled: okIsDisabled }}
      width={600}
      destroyOnClose={true}
    >
      <div className="header-modal">
        <ExclamationIcon style={{ fontSize: '1.5rem', alignSelf: 'center' }} />
        <p>Agregar otro producto</p>
      </div>

      <Form
        ref={ref}
        form={form}
        autoComplete="off"
        onValuesChange={(changedValues, allValues) => onValuesChange({ allValues, changedValues })}
      >
        <Form.List name="products">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => {
                return (
                  <Space key={field.key} style={{ display: 'flex' }}>
                    <Form.Item {...field} name={[field.name, `product${field.key}`]}>
                      <Products
                        name={`product${field.key}`}
                        productId={field.key}
                        requestParams={{
                          zoneId,
                          clientId,
                          ...hasTerritoryId(),
                        }}
                        sourceId={sourceId}
                        onChange={(value) => {
                          selectedProduct(value);
                        }}
                      />
                    </Form.Item>
                    {fields.length > 1 && field.key !== 0 && (
                      <MinusCircleOutlined
                        className="remove-element"
                        onClick={() => remove(field.name)}
                      />
                    )}
                  </Space>
                );
              })}

              {fields.length <= 4 && showAdd && (
                <Form.Item>
                  <BButton
                    label="Agregar otro producto"
                    type="link"
                    block
                    style={{ textAlign: 'left' }}
                    onClick={() => {
                      add();
                    }}
                    icon={<PlusOutlined />}
                  />
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
});

AddProductModal.displayName = 'AddProductModal';

AddProductModal.propTypes = {
  sourceId: PropTypes.oneOf(['Products/Agreements', 'Products']),
};

AddProductModal.defaultProps = {
  sourceId: 'Products',
};

export default AddProductModal;
