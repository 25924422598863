export const EXCHANGE_RATE_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD =
  'USERS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const EXCHANGE_RATE_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'USERS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const EXCHANGE_RATE_MASSIVE_UPLOAD_REQUEST_VERIFY = 'USERS_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const EXCHANGE_RATE_MASSIVE_UPLOAD_SET_VERIFY = 'USERS_MASSIVE_UPLOAD_SET_VERIFY';
export const EXCHANGE_RATE_MASSIVE_UPLOAD_CLEAR_VERIFY = 'USERS_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const EXCHANGE_RATE_MASSIVE_UPLOAD_REQUEST_UPLOAD = 'USERS_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const EXCHANGE_RATE_MASSIVE_UPLOAD_SET_UPLOAD = 'USERS_MASSIVE_UPLOAD_SET_UPLOAD';
export const EXCHANGE_RATE_MASSIVE_UPLOAD_CLEAR_UPLOAD = 'USERS_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function exchangeRateMassiveUploadSetAllowUpload(payload) {
  return {
    type: EXCHANGE_RATE_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function exchangeRateMassiveUploadClearAllowUpload(payload) {
  return {
    type: EXCHANGE_RATE_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function exchangeRateMassiveUploadRequestVerify(payload) {
  return {
    type: EXCHANGE_RATE_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function exchangeRateMassiveUploadSetVerify(payload) {
  return {
    type: EXCHANGE_RATE_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function exchangeRateMassiveUploadClearVerify(payload) {
  return {
    type: EXCHANGE_RATE_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function exchangeRateMassiveUploadRequestUpload(payload) {
  return {
    type: EXCHANGE_RATE_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function exchangeRateMassiveUploadSetUpload(payload) {
  return {
    type: EXCHANGE_RATE_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function exchangeRateMassiveUploadClearUpload(payload) {
  return {
    type: EXCHANGE_RATE_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
