export const CLEAN_INDICATORS = 'CLEAN_INDICATORS/SALES-CHALLENGE';

export const INDICATORS_REQUEST = 'INDICATORS_REQUEST/SALES-CHALLENGE';
export const INDICATORS_SUCCESS = 'INDICATORS_SUCCESS/SALES-CHALLENGE';
export const INDICATORS_FAIL = 'INDICATORS_FAIL/SALES-CHALLENGE';
export const INDICATORS_SET = 'INDICATORS_SET/SALES-CHALLENGE';

export const KAM_INDICATORS_REQUEST = 'KAM_INDICATORS_REQUEST/SALES-CHALLENGE';
export const KAM_INDICATORS_SUCCESS = 'KAM_INDICATORS_SUCCESS/SALES-CHALLENGE';
export const KAM_INDICATORS_FAIL = 'KAM_INDICATORS_FAIL/SALES-CHALLENGE';

export const DAYS_LEFT_REQUEST = 'DAYS_LEFT_REQUEST/SALES-CHALLENGE';
export const DAYS_LEFT_SUCCESS = 'DAYS_LEFT_SUCCESS/SALES-CHALLENGE';
export const DAYS_LEFT_FAIL = 'DAYS_LEFT_FAIL/SALES-CHALLENGE';

export const TERRITORY_DATA = 'TERRITORY_DATA/SALES-CHALLENGE';
export const ZONE_DATA = 'ZONE_DATA/SALES-CHALLENGE';

export const DAYS_LEFT_TYPES_REQUEST = 'DAYS_LEFT_TYPES_REQUEST';
export const DAYS_LEFT_TYPES_SUCCESS = 'DAYS_LEFT_TYPES_SUCCESS';
export const DAYS_LEFT_TYPES_FAIL = 'DAYS_LEFT_TYPES_FAIL';

export const GET_CLIENT_SUMMARY_REQUEST = 'GET_CLIENT_SUMMARY_REQUEST';
export const GET_CLIENT_SUMMARY_SUCCESS = 'GET_CLIENT_SUMMARY_SUCCESS';
export const GET_CLIENT_SUMMARY_FAIL = 'GET_CLIENT_SUMMARY_FAIL';

export function daysLeftTypesRequest(payload) {
  return {
    type: DAYS_LEFT_TYPES_REQUEST,
    payload,
  };
}

export function daysLeftTypesSuccess(payload) {
  return {
    type: DAYS_LEFT_TYPES_SUCCESS,
    payload,
  };
}

export function daysLeftTypesFail(payload) {
  return {
    type: DAYS_LEFT_TYPES_FAIL,
    payload: payload,
  };
}

export function daysLeftRequest(payload) {
  return {
    type: DAYS_LEFT_REQUEST,
    payload,
  };
}

export function daysLeftSuccess(payload) {
  return {
    type: DAYS_LEFT_SUCCESS,
    payload,
  };
}

export function daysLeftFail(payload) {
  return {
    type: DAYS_LEFT_FAIL,
    payload: payload,
  };
}

export function indicatorsRequest(payload) {
  return {
    type: INDICATORS_REQUEST,
    payload: payload,
  };
}

export function indicatorsSuccess(payload) {
  return {
    type: INDICATORS_SUCCESS,
    payload,
  };
}

export function indicatorsFail() {
  return {
    type: INDICATORS_FAIL,
  };
}

export function kamIndicatorsSuccess(payload) {
  return {
    type: KAM_INDICATORS_SUCCESS,
    payload: payload,
  };
}

export function kamIndicatorsFail() {
  return {
    type: KAM_INDICATORS_FAIL,
  };
}

export function indicatorsSet(payload) {
  return {
    type: INDICATORS_SET,
    payload,
  };
}

export function territoryData(payload) {
  return {
    type: TERRITORY_DATA,
    payload: payload,
  };
}

export function zoneData(payload) {
  return {
    type: ZONE_DATA,
    payload: payload,
  };
}

export function cleanIndicators() {
  return {
    type: CLEAN_INDICATORS,
  };
}

export function getClientSummaryRequest(payload) {
  return {
    type: GET_CLIENT_SUMMARY_REQUEST,
    payload,
  };
}

export function getClientSummarySuccess(payload) {
  return {
    type: GET_CLIENT_SUMMARY_SUCCESS,
    payload,
  };
}

export function getClientSummaryFail() {
  return {
    type: GET_CLIENT_SUMMARY_FAIL,
  };
}
