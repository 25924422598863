import {
  SAVE_VOLUME_REQUEST,
  SAVE_VOLUME_SUCCESS,
  CLEAN_TABLE,
  VALIDATE_TABLE_COMPLETION_REQUEST,
} from '../actions/table';

const query = (state = {}, action) => {
  switch (action.type) {
    case SAVE_VOLUME_REQUEST:
      if (state.data) {
        let current = state.data.find((el) => el.rowKey === 7);
        let exclude = state.data.filter((el) => el.rowKey !== 7);
        if (current) {
          let replace = { [action.payload.data.key]: action.payload.data.value };
          current = { ...current, ...replace };

          return {
            ...state,
            data: [...exclude, current],
          };
        }
      } else {
        let tableIs = { tableIsValid: { ...state.tableIsValid } };
        return { ...action.payload, ...tableIs };
      }
      break;

    case SAVE_VOLUME_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case CLEAN_TABLE:
      return {
        tableIsValid: { ...state.tableIsValid },
      };
    case VALIDATE_TABLE_COMPLETION_REQUEST:
      return {
        ...state,
        tableIsValid: { ...state.tableIsValid, ...action.payload },
      };

    default:
      return state;
  }
};
export default query;
