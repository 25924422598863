import {
  CLEAN_REGISTER_TRADE,
  GET_DAYS_LEFT_REQUEST,
  GET_DAYS_LEFT_FAIL,
  GET_DAYS_LEFT_SUCCESS,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_PRICES_REQUEST,
  GET_PRODUCTS_PRICES_SUCCESS,
  GET_PRODUCTS_PRICES_FAIL,
  GET_AGREEMENT_REQUEST,
  GET_AGREEMENT_FAIL,
  GET_AGREEMENT_SUCCESS,
  GET_REACH_REQUEST,
  GET_REACH_SUCCESS,
  GET_REACH_FAIL,
  ARE_VOLUMES_VALIDS,
  SEND_TO_VALIDATE_REQUEST,
  SEND_TO_VALIDATE_SUCCESS,
  SEND_TO_VALIDATE_FAIL,
  SAVE_AGREEMENT_REQUEST,
  SAVE_AGREEMENT_SUCCESS,
  SAVE_AGREEMENT_FAIL,
  UPDATE_CURRENT_AGREEMENT,
  UPDATE_REACH,
  POST_AGREEMENT_REQUEST,
  POST_AGREEMENT_FAIL,
  POST_AGREEMENT_SUCCESS,
  TRADE_COMMENTS_REQUEST,
  TRADE_COMMENTS_SUCCESS,
  TRADE_COMMENTS_FAIL,
} from '../actions/registerTrade';
import {
  UPDATE_TABLE_INPUTS,
  UPDATE_TABLE_DISPLAYS,
  CREATE_TABLE,
  SET_MULTIPLE_TABLE_DISPLAYS,
} from '../actions/tableActions';
const registerTrade = (state = {}, action) => {
  switch (action.type) {
    case SET_MULTIPLE_TABLE_DISPLAYS:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state?.table?.display,
            ...action.payload,
          },
        },
      };

    case UPDATE_TABLE_DISPLAYS:
      return {
        ...state,
        table: {
          ...state.table,
          display: {
            ...state?.table?.display,
            [action.payload.key]: action.payload.value,
          },
        },
      };

    case CREATE_TABLE:
      return {
        ...state,
        table: {
          ...state?.table,
          inputs: {
            ...action.payload,
          },
        },
      };

    case UPDATE_TABLE_INPUTS:
      return {
        ...state,
        table: {
          ...state.table,
          inputs: {
            ...state?.table?.inputs,
            [action.payload.key]: action.payload.value,
          },
        },
      };

    case CLEAN_REGISTER_TRADE:
      return {
        table: {
          ...state.table,
        },
        daysLeft: {
          ...state.daysLeft,
        },
      };
    case GET_DAYS_LEFT_REQUEST:
      return {
        ...state,
        daysLeft: { ...state.daysLeft },
      };
    case GET_DAYS_LEFT_SUCCESS:
      return {
        ...state,
        daysLeft: {
          isLoading: false,
          data: {
            ...state?.daysLeft?.data,
            ...action.payload,
          },
        },
      };
    case GET_DAYS_LEFT_FAIL:
      return {
        ...state,
        daysLeft: {
          isLoading: false,
          data: null,
        },
      };
    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        productInfo: {
          isLoading: true,
          data: null,
        },
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        productInfo: {
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        productInfo: {
          isLoading: false,
          data: null,
        },
      };
    case GET_PRODUCTS_PRICES_REQUEST:
      return {
        ...state,
        priceInfo: {
          isLoading: true,
          data: null,
        },
      };
    case GET_PRODUCTS_PRICES_SUCCESS:
      return {
        ...state,
        priceInfo: {
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_PRODUCTS_PRICES_FAIL:
      return {
        ...state,
        priceInfo: {
          isLoading: false,
          data: null,
        },
      };
    case GET_AGREEMENT_REQUEST:
      return {
        ...state,
        currentAgreement: {
          isLoading: true,
        },
      };
    case GET_AGREEMENT_SUCCESS:
      return {
        ...state,
        currentAgreement: {
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_AGREEMENT_FAIL:
      return {
        ...state,
        currentAgreement: {
          isLoading: false,
        },
      };

    case GET_REACH_REQUEST:
      return {
        ...state,
        reach: {
          isLoading: true,
        },
      };
    case GET_REACH_SUCCESS:
      return {
        ...state,
        reach: {
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_REACH_FAIL:
      return {
        ...state,
        reach: {
          isLoading: false,
        },
      };
    case ARE_VOLUMES_VALIDS:
      return {
        ...state,
        volumesValidsToSave: action.payload,
      };

    case SEND_TO_VALIDATE_REQUEST:
      return {
        ...state,
        validation: {
          sendingToValidation: true,
        },
      };

    case SEND_TO_VALIDATE_SUCCESS:
    case SEND_TO_VALIDATE_FAIL:
      return {
        ...state,
        validation: {
          sendingToValidation: false,
        },
      };
    case SAVE_AGREEMENT_REQUEST:
      return {
        ...state,
        saveAgreement: {
          isLoading: true,
        },
      };
    case SAVE_AGREEMENT_SUCCESS:
      return {
        ...state,
        saveAgreement: {
          isLoading: false,
          data: action.payload,
        },
      };
    case SAVE_AGREEMENT_FAIL:
      return {
        ...state,
        saveAgreement: {
          isLoading: false,
        },
      };
    case UPDATE_CURRENT_AGREEMENT:
      return {
        ...state,
        currentAgreement: {
          ...state?.currentAgreement,
          data: {
            ...state?.currentAgreement?.data,
            ...action.payload,
          },
        },
      };
    case UPDATE_REACH:
      return {
        ...state,
        reach: {
          ...state.reach,
          data: action.payload,
        },
      };

    case POST_AGREEMENT_REQUEST:
      return {
        ...state,
        postSaveAgreement: {
          isLoading: true,
        },
      };

    case POST_AGREEMENT_SUCCESS:
      return {
        ...state,
        postSaveAgreement: {
          isLoading: false,
        },
      };
    case POST_AGREEMENT_FAIL:
      return {
        ...state,
        postSaveAgreement: {
          isLoading: false,
        },
      };
    case TRADE_COMMENTS_REQUEST:
      return {
        ...state,
        historyTrade: {
          isLoading: true,
        },
      };

    case TRADE_COMMENTS_SUCCESS:
      return {
        ...state,
        historyTrade: {
          isLoading: false,
          data: action.payload,
        },
      };
    case TRADE_COMMENTS_FAIL:
      return {
        ...state,
        historyTrade: {
          isLoading: false,
        },
      };

    default:
      return state;
  }
};
export default registerTrade;

//TODO: agregar productInfo y prices
