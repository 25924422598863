/* eslint-disable react/self-closing-comp */
import React, { useContext, useEffect, useState } from 'react';
import { Row, Table } from 'antd';
import { layoutContext } from '../context';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Loader } from '../global';
import { UploadXls, ActionButtons, summaryModal, duplicatedModal } from '../common';
import { filterValidRows, buildDuplicatedObjects } from './utils';
import { getUpload } from './services';

export const buildPayload = (values, catalogName) => {
  let payload = [];
  return import(`./schemas/${catalogName}`).then((module) => {
    values.rows.slice(2, values.rows.length).forEach((row, key) => {
      let response = module.uploadSchema(row, key);
      payload.push(response);
    });
    return payload;
  });
};

export const handleUpload = async (
  values,
  catalogName,
  templateLiterals,
  overwrite,
  onChangeActiveComponent,
  setUploadState,
  setPayloadRows,
  // eslint-disable-next-line no-shadow
  summaryModal,
  setOverwrite
) => {
  onChangeActiveComponent('catalog');
  setUploadState('Loading');
  let payload = [];
  if (values.rows.length > 0) {
    payload = await buildPayload(values, catalogName);
  }
  getUpload(catalogName, false, payload)
    .then((response) => {
      let responseErrors = response.errorList;
      let duplicatedRows = buildDuplicatedObjects(response.duplicated, payload, catalogName);
      setPayloadRows(filterValidRows(payload, responseErrors));
      summaryModal(payload.length, response.totalErrors, () =>
        handleSummaryOk(catalogName, duplicatedRows, templateLiterals, overwrite, setOverwrite)
      );
      if (payload.length === response.totalErrors) {
        setUploadState(undefined);
      } else {
        setUploadState('Result Data');
      }
    })
    .catch((err) => {
      setUploadState(undefined);
      setPayloadRows([]);
      setOverwrite([]);
      onChangeActiveComponent(undefined);
    });
};
export const handleSummaryOk = (
  catalogName,
  duplicatedRows,
  templateLiterals,
  overwrite,
  setOverwrite
) => {
  if (duplicatedRows.length > 0)
    duplicatedModal(
      duplicatedRows,
      templateLiterals.title,
      templateLiterals.param,
      templateLiterals.paramName,
      templateLiterals.paramName2,
      catalogName === 'prices' ? 27 : undefined,
      (key) => handleSetOverWrites(key, overwrite, setOverwrite),
      () => {
        return;
      }
    );
};
export const handleSetOverWrites = (key, overwrite, setOverwrite) => {
  let overwrites = overwrite;
  overwrites.push(key);
  setOverwrite(overwrites);
};

function Upload() {
  let location = useLocation();
  const history = useHistory();
  const { t } = useTranslation(['catalogs']);
  const { onChangeActiveComponent } = useContext(layoutContext);
  const originalRouter = location.pathname;
  let catalogName = originalRouter.split('/')[2];
  const templateLiterals = {
    title: t(`${catalogName}.duplicated.title`),
    param: t(`${catalogName}.duplicated.param`),
    paramName: t(`${catalogName}.duplicated.paramName`),
    paramName2: t(`${catalogName}.duplicated.paramName2`),
  };

  const [uploadState, setUploadState] = useState(undefined);
  const [payloadRows, setPayloadRows] = useState([]);
  const [columns, setColumns] = useState(undefined);
  const [overwrite, setOverwrite] = useState([]);

  useEffect(() => {
    import(`./schemas/${catalogName}`).then((schema) => setColumns(schema.uploadColumns));
  }, [catalogName]);
  const handleSubmit = () => {
    let payload = payloadRows;
    if (overwrite.length > 0) {
      overwrite.forEach((overwriteKey) => {
        payload.forEach((row) => {
          if (row.rowNumber === overwriteKey) {
            row.overwrite = true;
          }
        });
      });
    }
    getUpload(catalogName, true, payload).then((res) => {
      onChangeActiveComponent(undefined);
      history.push(`/catalog/${catalogName}`);
    });
  };
  const handleCancel = () => {
    setUploadState(undefined);
    setPayloadRows([]);
    setOverwrite([]);
    onChangeActiveComponent(undefined);
    history.push(`/catalog`);
  };

  return (
    <>
      <Row justify="end" style={{ width: '100%' }}>
        <UploadXls
          name={t(`catalogs.${catalogName}`)}
          disabled={uploadState}
          onChange={(values) =>
            handleUpload(
              values,
              catalogName,
              templateLiterals,
              overwrite,
              onChangeActiveComponent,
              setUploadState,
              setPayloadRows,
              summaryModal,
              setOverwrite
            )
          }
          onLoading={() => setUploadState('Loading')}
        />
      </Row>
      <div className="module-card-transparent" data-testid="upload">
        {!uploadState && <div style={{ height: '70vh' }}></div>}
        {uploadState === 'Loading' && <Loader />}
        {uploadState === 'Result Data' && (
          <>
            <Table
              scroll={{ x: 'max-content' }}
              columnWidth={'100px'}
              dataSource={payloadRows}
              columns={columns}
            />
          </>
        )}
      </div>
      <ActionButtons
        disabled={payloadRows.length <= 0}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  );
}

export default Upload;
