/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useCallback } from 'react';
import { IconsMap } from '../../Layout/utils';
import { Form, Input, Button } from 'antd';
import Styles from './restore.module.css';
import { LockOutlined } from '@ant-design/icons';
import { validateToken } from '../services';
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const { Password } = Input;
const ExclamationIcon = IconsMap.ExclamationCircleOutlined;

const Restore = ({ handleRestore }) => {
  const history = useHistory();
  let location = useLocation();
  const params = useParams();

  let path = location.pathname;
  const idTkn = path.split('/')[2];
  const [newForm, setNewForm] = useState({});
  const [minMax, setMinMax] = useState('red');
  const [capital, setCapital] = useState('red');
  const [number, setNumber] = useState('red');
  const [special, setSpecial] = useState('red');
  const [equal, setEqual] = useState('red');
  const [disabledButton, setDisabledButton] = useState(true);
  const [requestReset, setRequestReset] = useState(false);
  const PasswordRegexValidation = (value) => {
    // CAPITAL LETTER
    let regExpCapital = new RegExp(/(?:[A-Z])/).test(value);
    // ONE NUMBER
    let regExpNumber = new RegExp(/(?=.*\d)/).test(value);
    // SPECIAL CHAR
    let regExpSpecial = new RegExp(/(?=.*[!@\-#$%&])/).test(value);
    //MIN MAX LENGTH
    let regExpMinMax = new RegExp(/^.{8,16}$/).test(value);
    if (regExpCapital) {
      setCapital('green');
    } else {
      setCapital('red');
    }
    if (regExpNumber) {
      setNumber('green');
    } else {
      setNumber('red');
    }
    if (regExpSpecial) {
      setSpecial('green');
    } else {
      setSpecial('red');
    }
    if (regExpMinMax) {
      setMinMax('green');
    } else {
      setMinMax('red');
    }
    if (regExpCapital && regExpNumber && regExpSpecial && regExpMinMax) {
      return true;
    } else {
      return false;
    }
  };

  const onChangePass = (e) => {
    setNewForm({ ...newForm, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    let validations = false;
    if (newForm.newPass) {
      validations = PasswordRegexValidation(newForm.newPass);
    } else {
      setCapital('red');
      setNumber('red');
      setSpecial('red');
      setMinMax('red');
      setEqual('red');
    }
    if (newForm.newPass && newForm.confirmPass && newForm.newPass === newForm.confirmPass) {
      if (validations) {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
      setEqual('green');
    } else {
      setEqual('red');
      setDisabledButton(true);
    }
  }, [newForm]);

  const validateIfExistToken = useCallback(async () => {
    if (params?.token) {
      let response = await validateToken({ token: params.token });
      if (response.status === 400) {
        setTimeout(() => {
          history.push('/');
        }, 300);
      }
    }
  }, []);

  useEffect(() => {
    validateIfExistToken();
  }, []);

  return (
    <>
      <div className={Styles['container-modal']}>
        <div className={Styles['container-modal-title']}>
          <ExclamationIcon style={{ fontSize: '23px' }} />
          <p>Reestablecer contraseña</p>
        </div>
        <div className={Styles['container-modal-text']}>
          <div className={Styles['container-text']}>
            <p
              className={Styles['container-paragraph']}
              style={{ marginBottom: '25px', marginTop: '10px' }}
            >
              La contraseña debe contener las siguientes características:
            </p>
            <p className={Styles['container-paragraph']} style={{ color: minMax }}>
              • 8 a 16 caracteres alfanuméricos
            </p>
            <p className={Styles['container-paragraph']} style={{ color: capital }}>
              • Al menos una letra mayúscula{' '}
            </p>
            <p className={Styles['container-paragraph']} style={{ color: number }}>
              • Al menos un número
            </p>
            <p className={Styles['container-paragraph']} style={{ color: special }}>
              • Al menos un caracter especial (!, @, -, #, $, %, &)
            </p>
            <p className={Styles['container-paragraph']} style={{ color: equal }}>
              • El campo "Nueva contraseña" y "Confirmar contraseña" deben coincidir
            </p>
          </div>
          <div className={Styles['container-form']}>
            <Form name="register" scrollToFirstError style={{ width: '100%' }}>
              <Password
                name="newPass"
                className={Styles['container-pass']}
                placeholder="Nueva contraseña"
                prefix={<LockOutlined />}
                onChange={(e) => onChangePass(e)}
              />
              <Password
                name="confirmPass"
                className={Styles['container-pass']}
                placeholder="Confirmar constraseña"
                prefix={<LockOutlined />}
                onChange={(e) => onChangePass(e)}
              />
              <div className="container-modal-buttons" style={{ marginLeft: '40px' }}>
                <Button onClick={() => history.push('/login')}>Cancelar</Button>
                <div style={{ marginLeft: '10px' }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={disabledButton}
                    loading={requestReset}
                    onClick={(e) => {
                      e.preventDefault();
                      setRequestReset(true);
                      handleRestore(newForm, idTkn, setRequestReset);
                      // setTimeout(() => {
                      //   history.push('/login');
                      // }, 700);
                    }}
                  >
                    Aceptar
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Restore;
