import { combineReducers } from 'redux';
import downloadSalesChallengeReducer from './downloadSalesChallengeReport';
import downloadTradeAgreementsReducer from './downloadTradeAgreements';
import filters from './filters';
import validationFieldsReducer from './validationFields';
import addSalesChallengeValidatorReducer from './addSalesChallengeValidator';
import deleteSalesChallengeValidatorReducer from './deleteSalesChallengeValidator';
import validatorList from './validatorList';
import deleteTradeAgreementValidator from './deleteTradeAgreementValidator';
import addTradeAgreementValidator from './addTradeAgreementValidator';
import daysLeft from './daysLeft';
import salesChallengeValidatorConfirm from './salesChallengeValidatorConfirm';
import salesChallengeTeritoryValidator from './salesChallengeTerritoryValidator';

export default combineReducers({
  downloadSalesChallengeReducer,
  downloadTradeAgreementsReducer,
  validationFieldsReducer,
  filters,
  addSalesChallengeValidatorReducer,
  deleteSalesChallengeValidatorReducer,
  addTradeAgreementValidator,
  deleteTradeAgreementValidator,
  validatorList,
  daysLeft,
  salesChallengeValidatorConfirm,
  salesChallengeTeritoryValidator,
});
