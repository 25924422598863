import request from 'services/request';
const serviceURL = '/Catalogs/Api/v1/Clients/Detail/';
const clientID = '/AccountStatement/Api/v1/Catalogs/GetClientList';
const catalogURI = '/Catalogs/Api/v1/FixedCatalogs/';
const registerURI = '/AccountStatement/Api/v1/Statements';
const statusUrl = '/AccountStatement/Api/v1/Catalogs/Enabled/';
const urlIncentivePeriod = '/AccountStatement/Api/v1/Catalogs/GetSalesIncentivesList';
const validateClientUrl = '/AccountStatement/Api/v1/Statements/CheckExistence';
const getDetailURL = '/AccountStatement/Api/v1/Statements/Detail';
//valida client
export async function validateClient({ cancelToken, soldTo }) {
  return request(`${validateClientUrl}/${soldTo}`, {
    method: 'GET',
    cancelToken,
  });
}
//DetailDynamicElements
export async function getDetailDynamicElements({ cancelToken, clientId, lineId }) {
  //devaccountstatementservice.agroplanap.com/AccountStatement/Api/v1/Statements/Detail?ClientId=C7868187-AF14-45DC-BCDD-04AF2BE1C403&TerritoryId=2
  return request(`${getDetailURL}?ClientId=${clientId}&LineId=${lineId}`, {
    method: 'GET',
    cancelToken,
  });
}

//get data incentive period
export async function getIncentivePeriod({ cancelToken }) {
  return request(`${urlIncentivePeriod}`, {
    method: 'GET',
    cancelToken,
  });
}
//get id cliente
export async function getClient({ cancelToken }) {
  return request(`${clientID}`, {
    method: 'GET',
    cancelToken,
  });
}
export async function getOneClient({ cancelToken, clientId }) {
  return request(`${serviceURL}${clientId}`, {
    method: 'GET',
    cancelToken,
  });
}

export async function getTerritoriesClient({ cancelToken, params }) {
  return request(`${catalogURI}Territories`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
//get data clients
export async function getOptionsClient({ cancelToken, name }) {
  return request(`${catalogURI}${name}`, {
    method: 'GET',
    cancelToken,
  });
}
//Update DynamicElements
export async function RegisterDynamicElements({ cancelToken, data, id }) {
  return request(`${registerURI}/${id}`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

export async function changeStatus({ cancelToken, clientId, status, lineId, subRegionId }) {
  return request(`${statusUrl}${clientId}/${status}/${lineId}/${subRegionId}`, {
    method: 'PUT',
    cancelToken,
  });
}
