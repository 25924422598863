/**reducrs */
import { combineReducers } from 'redux';
import {
  GET_PROCESS_DATES_REQUEST,
  GET_PROCESS_DATES_SUCCESS,
  GET_PROCESS_DATES_FAIL,
  REGISTER_PROCESS_DATES_REQUEST,
  REGISTER_PROCESS_DATES_SUCCESS,
  REGISTER_PROCESS_DATES_FAIL,
  EDIT_PRODUCT_STATUS,
} from '../actions/catalogs';

const catalogs = (state = { editedProduct: null }, action) => {
  switch (action.type) {
    case GET_PROCESS_DATES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROCESS_DATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case GET_PROCESS_DATES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case REGISTER_PROCESS_DATES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case REGISTER_PROCESS_DATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case REGISTER_PROCESS_DATES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case EDIT_PRODUCT_STATUS:
      return {
        ...state,
        editedProduct: action.payload,
      };

    default:
      return state;
  }
};

const appReducer = combineReducers({
  processDates: catalogs,
});

export default appReducer;
