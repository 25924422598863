import React from 'react';
import { Loader } from 'global';
import { Table } from 'antd';
import { AllCorrectSign } from './index';

const MassiveUploadTable = ({ loading, data, columns, allCorrectMessage }) => {
  return (
    <div className="module-card-transparent" data-testid="upload" style={{ marginTop: '1.5em' }}>
      {(!data || data.length === 0) && (
        <div style={{ minHeight: '42vh' }}>
          {allCorrectMessage && <AllCorrectSign message={allCorrectMessage} />}
        </div>
      )}
      {loading && <Loader />}
      {data && data.length > 0 && (
        <>
          <Table
            scroll={{ x: 'max-content' }}
            columnWidth={'100px'}
            dataSource={data}
            columns={columns}
          />
        </>
      )}
    </div>
  );
};

export default MassiveUploadTable;
