import rootReducer from './reducers';

import rootSagasss from './sagas';

export default function getManageImagesModule() {
  return {
    id: 'MANAGE_IMAGES',
    reducerMap: {
      MANAGE_IMAGES: rootReducer,
    },
    sagas: [rootSagasss],
  };
}
