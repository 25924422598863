import { registerData } from '../../actions';

const { REGISTER_REQUEST, REGISTER_SET, REGISTER_CLEAN } = registerData;

const registerDataReducers = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_REQUEST:
      return {
        ...state,
        pogsubregionData: null,
      };
    case REGISTER_SET:
      return {
        ...state,
        pogsubregionData: payload,
      };
    case REGISTER_CLEAN:
      return {
        ...state,
        pogsubregionData: [],
      };

    default:
      return state;
  }
};

export default registerDataReducers;
