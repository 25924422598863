export const CLEAN_INFO = 'TRADE-AGREEMENTS/CLEAN_INFO';

export const INFO_REQUEST = 'TRADE-AGREEMENTS/INFO_REQUEST';
export const INFO_SUCCESS = 'TRADE-AGREEMENTS/INFO_SUCCESS';
export const INFO_FAIL = 'TRADE-AGREEMENTS/INFO_FAIL';

export function infoRequest(payload) {
  return {
    type: INFO_REQUEST,
    payload: payload,
  };
}

export function infoSuccess(payload) {
  return {
    type: INFO_SUCCESS,
    payload: payload,
  };
}

export function infoFail() {
  return {
    type: INFO_SUCCESS,
  };
}

export function cleanInfo() {
  return {
    type: CLEAN_INFO,
  };
}
