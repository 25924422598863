import React, { useState, useEffect } from 'react';

import useClients from '../../hooks/useClients';
import useSubRegions from '../../hooks/useSubRegions';
import { Row, Col } from 'antd';
import '../../css/styles.css';
import SectionTransfer from '../../commons/SectionTransfer';

const CreditNotesSection = ({
  incentiveCreditNotesData,
  incSubregionsData,
  sendParams,
  updateSendParams,
}) => {
  const [activeClients, inactiveClients, setInactiveClients] = useClients(incentiveCreditNotesData);
  const [activeSubRegions, inactiveSubRegions, setInactiveSubRegions] =
    useSubRegions(incSubregionsData);

  const [disabledClients, setDisabledClients] = useState(false);
  const [disabledSubRegions, setDisabledSubRegions] = useState(false);

  useEffect(() => {
    setDisabledClients(inactiveSubRegions.length > 0 ? true : false);
    setDisabledSubRegions(inactiveClients.length > 0 ? true : false);
  }, [inactiveClients, inactiveSubRegions]);

  return (
    <>
      <Row>
        <h4> Por estructura comercial </h4>
      </Row>

      <Row>
        <Col span={24}>
          <SectionTransfer
            type={1}
            disabled={disabledSubRegions}
            dataSource={activeSubRegions}
            targetKeys={inactiveSubRegions}
            onChange={(nextTargetKeys) => {
              setInactiveSubRegions(nextTargetKeys);
              let inactiveSubregions = activeSubRegions
                .filter((d, index) => nextTargetKeys.includes(index))
                .map((d) => ({ lineIdInact: d.lineId, subRegionIdInact: d.subregionId }));
              let activeSubregions = activeSubRegions
                .filter((d, index) => !nextTargetKeys.includes(index))
                .map((d) => ({ lineIdAct: d.lineId, subRegionIdAct: d.subregionId }));
              updateSendParams({
                ...sendParams,
                creditNotesSections: {
                  ...sendParams.creditNotesSections,

                  activeSubRegions: activeSubregions,
                  inactiveSubRegions: inactiveSubregions,
                },
              });
            }}
            showSearch={true}
            pagination={true}
            leftColumns={[
              {
                dataIndex: 'title',
                title: 'Subregiones',
                className: 'firstColumn',
              },
              {
                dataIndex: 'lineName',
                title: '',
              },
            ]}
            rightColumns={[
              {
                dataIndex: 'title',
                title: 'Subregiones',
                className: 'firstColumn',
              },
              {
                dataIndex: 'lineName',
                title: '',
              },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <h4> Por clientes </h4>
      </Row>

      <Row>
        <Col span={24}>
          <SectionTransfer
            dataSource={activeClients}
            disabled={disabledClients}
            targetKeys={inactiveClients}
            onChange={(nextTargetKeys) => {
              setInactiveClients(nextTargetKeys);
              let inactiveClient = activeClients
                .filter((d, index) => nextTargetKeys.includes(index))
                .map((d) => ({ lineIdCInact: d.lineId, clientIdInact: d.clientId }));
              let activeClient = activeClients
                .filter((d, index) => !nextTargetKeys.includes(index))
                .map((d) => ({ lineIdCAct: d.lineId, clientIdAct: d.clientId }));
              updateSendParams({
                ...sendParams,
                creditNotesSections: {
                  ...sendParams.creditNotesSections,
                  inactiveClients: inactiveClient,
                  activeClients: activeClient,
                },
              });
            }}
            showSearch={true}
            pagination={true}
            leftColumns={[
              {
                dataIndex: 'title',
                title: 'Clientes',
              },
              {
                dataIndex: 'lineName',
                title: '',
              },
            ]}
            rightColumns={[
              {
                dataIndex: 'title',
                title: 'Clientes',
              },
              {
                dataIndex: 'lineName',
                title: '',
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default CreditNotesSection;
