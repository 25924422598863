function ManageImagesReducer(state = {}, action) {
  const { type } = action;

  const REDUCER = {
    POST_IMAGE_LINE_CLEAN: {},
  };

  return { ...state, isLoading: false, ...REDUCER[type] };
}

export default ManageImagesReducer;
