import React from 'react';
import ErrorColumnTitle from '../../../common/components/ErrorColumnTitle';

const messageFaseOne = (
  <>
    <p>
      Registros para los cuales se omitió ingresar campos obligatorios, se ingresó un tipo de dato
      que no corresponde al solicitado en el campo o se excedió la longitud máxima.
    </p>
  </>
);
const messageFaseTwo = (
  <>
    <p>
      Registro de fechas de tipo de cambio en los que la fecha de inicio de vigencia es posterior a
      la fecha fin de vigencia.
    </p>
  </>
);
const messageFaseThree = (
  <>
    <p>Registros que se encuentran repetidos dentro del archivo de carga masiva.</p>
  </>
);
const errorMessages = [
  {
    fase: 'Fase 1',
    message: messageFaseOne,
  },
  {
    fase: 'Fase 2',
    message: messageFaseTwo,
  },
  {
    fase: 'Fase 3',
    message: messageFaseThree,
  },
];

const tableColumns = [
  {
    key: 'errorPhase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'errorPhase',
    className: 'title-masive',
  },
  {
    title: 'Compañía',
    dataIndex: 'company',
    key: 'company',
  },
  {
    title: 'Referencia',
    dataIndex: 'reference',
    key: 'reference',
  },

  {
    title: 'Valor',
    dataIndex: 'exchangeRateValue',
    key: 'exchangeRateValue',
  },

  {
    title: 'Vigencia inicio',
    dataIndex: 'startValidityDate',
    key: 'startValidityDate',
  },

  {
    title: 'Vigencia fin',
    dataIndex: 'endValidityDate',
    key: 'endValidityDate',
  },
];
export default tableColumns;
