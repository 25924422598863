import { ERROR_TAG_STYLE } from '../form.module.styles';

export const INPUT_ADDON = {
  /* padding: '6px 12px', */
  fontSize: '1em',
  fontWeight: '400',
  lineHeight: '1',
  color: '#555',
  textAlign: 'center',
  backgroundColor: '#eee',
  border: '1px solid #ccc',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
};
export const FIELDSET_STYLE = {
  width: '100%',
  position: 'relative',
  display: 'flex',
  borderCollapse: 'separate',
};
export const INPUT_STYLE = {
  position: 'relative',
  zIndex: '1',
  float: 'left',
  width: '100%',
  marginBottom: '0',
  padding: '4px 12px',
  border: '1px solid #ccc',
  margin: '0',
  outline: 'none',
};
const PREFIX_STYLE = {
  ...INPUT_ADDON,
};
const SUFFIX_STYLE = {
  ...INPUT_ADDON,
};
const EMPTY_INPUT = (value) => {
  return value ? {} : { margin: '1.5em' };
};
export const STYLES = {
  INPUT_STYLE,
  ERROR_TAG_STYLE,
  INPUT_ADDON,
  FIELDSET_STYLE,
  PREFIX_STYLE,
  SUFFIX_STYLE,
  EMPTY_INPUT,
};
