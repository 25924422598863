export const CLEAN_FILTERS = 'CLEAN_FILTERS/SALES-CHALLENGE';

export const YEARS_REQUEST = 'YEARS_REQUEST/SALES-CHALLENGE';
export const YEARS_SUCCESS = 'YEARS_SUCCESS/SALES-CHALLENGE';
export const YEARS_FAIL = 'YEARS_FAIL/SALES-CHALLENGE';

export function yearsRequest(payload) {
  return {
    type: YEARS_REQUEST,
    payload: payload,
  };
}

export function yearsSuccess(payload) {
  return {
    type: YEARS_SUCCESS,
    payload: payload,
  };
}

export function yearsFail() {
  return {
    type: YEARS_FAIL,
  };
}

export const REGIONS_REQUEST = 'REGIONS_REQUEST/SALES-CHALLENGE';
export const REGIONS_SUCCESS = 'REGIONS_SUCCESS/SALES-CHALLENGE';
export const REGIONS_FAIL = 'REGIONS_FAIL/SALES-CHALLENGE';

export function regionRequest(payload) {
  return {
    type: REGIONS_REQUEST,
    payload: payload,
  };
}

export function regionSuccess(payload) {
  return {
    type: REGIONS_SUCCESS,
    payload: payload,
  };
}

export function regionFail() {
  return {
    type: REGIONS_FAIL,
  };
}

export const BUSINESSLINE_REQUEST = 'BUSINESSLINE_REQUEST/SALES-CHALLENGE';
export const BUSINESSLINE_SUCCESS = 'BUSINESSLINE_SUCCESS/SALES-CHALLENGE';
export const BUSINESSLINE_FAIL = 'BUSINESSLINE_FAIL/SALES-CHALLENGE';

export function businessLineRequest(payload) {
  return {
    type: BUSINESSLINE_REQUEST,
    payload: payload,
  };
}

export function businessLineSuccess(payload) {
  return {
    type: BUSINESSLINE_SUCCESS,
    payload: payload,
  };
}

export function businessLineFail() {
  return {
    type: BUSINESSLINE_FAIL,
  };
}

export const SUBREGIONS_REQUEST = 'SUBREGIONS_REQUEST/SALES-CHALLENGE';
export const SUBREGIONS_SUCCESS = 'SUBREGIONS_SUCCESS/SALES-CHALLENGE';
export const SUBREGIONS_FAIL = 'SUBREGIONS__FAIL/SALES-CHALLENGE';

export function subregionsRequest(payload) {
  return {
    type: SUBREGIONS_REQUEST,
    payload: payload,
  };
}

export function subregionsSuccess(payload) {
  return {
    type: SUBREGIONS_SUCCESS,
    payload: payload,
  };
}

export function subregionsFail(payload) {
  return {
    type: SUBREGIONS_FAIL,
    payload: payload,
  };
}

export const TERRITORY_REQUEST = 'TERRITORY_REQUEST/SALES-CHALLENGE';
export const TERRITORY_SUCCESS = 'TERRITORY_SUCCESS/SALES-CHALLENGE';
export const TERRITORY_FAIL = 'TERRITORY_FAIL/SALES-CHALLENGE';

export function territoryRequest(payload) {
  return {
    type: TERRITORY_REQUEST,
    payload: payload,
  };
}

export function territorySuccess(payload) {
  return {
    type: TERRITORY_SUCCESS,
    payload: payload,
  };
}

export function territoryFail() {
  return {
    type: TERRITORY_FAIL,
  };
}

export const ZONES_REQUEST = 'ZONES_REQUEST/SALES-CHALLENGE';
export const ZONES_SUCCESS = 'ZONES_SUCCESS/SALES-CHALLENGE';
export const ZONES_FAIL = 'ZONES_FAIL/SALES-CHALLENGE';

export function zonesRequest(payload) {
  return {
    type: ZONES_REQUEST,
    payload: payload,
  };
}

export function zonesSuccess(payload) {
  return {
    type: ZONES_SUCCESS,
    payload: payload,
  };
}

export function zonesFail() {
  return {
    type: ZONES_FAIL,
  };
}

export const CLIENTS_REQUEST = 'CLIENTS_REQUEST/SALES-CHALLENGE';
export const CLIENTS_SUCCESS = 'CLIENTS_SUCCESS/SALES-CHALLENGE';
export const CLIENTS_FAIL = 'CLIENTS_FAIL/SALES-CHALLENGE';

export function clientsRequest(payload) {
  return {
    type: CLIENTS_REQUEST,
    payload: payload,
  };
}

export function clientsSuccess(payload) {
  return {
    type: CLIENTS_SUCCESS,
    payload: payload,
  };
}

export function clientsFail() {
  return {
    type: CLIENTS_FAIL,
  };
}

export const FAMILY_REQUEST = 'FAMILY_REQUEST/SALES-CHALLENGE';
export const FAMILY_SUCCESS = 'FAMILY_SUCCESS/SALES-CHALLENGE';
export const FAMILY_FAIL = 'FAMILY_FAIL/SALES-CHALLENGE';

export function familyRequest(payload) {
  return {
    type: FAMILY_REQUEST,
    payload: payload,
  };
}

export function familySuccess(payload) {
  return {
    type: FAMILY_SUCCESS,
    payload: payload,
  };
}

export function familyFail() {
  return {
    type: FAMILY_FAIL,
  };
}

export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST/SALES-CHALLENGE';
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS/SALES-CHALLENGE';
export const PRODUCTS_FAIL = 'PRODUCTS_FAIL/SALES-CHALLENGE';

export function productsRequest(payload) {
  return {
    type: PRODUCTS_REQUEST,
    payload: payload,
  };
}

export function productsSuccess(payload) {
  return {
    type: PRODUCTS_SUCCESS,
    payload: payload,
  };
}

export function productsFail() {
  return {
    type: PRODUCTS_FAIL,
  };
}

export const SEGMENTS_REQUEST = 'SEGMENTS_REQUEST/SALES-CHALLENGE';
export const SEGMENTS_SUCCESS = 'SEGMENTS_SUCCESS/SALES-CHALLENGE';
export const SEGMENTS_FAIL = 'SEGMENTS_FAIL/SALES-CHALLENGE';

export function segmentsRequest(payload) {
  return {
    type: SEGMENTS_REQUEST,
    payload: payload,
  };
}

export function segmentsSuccess(payload) {
  return {
    type: SEGMENTS_SUCCESS,
    payload: payload,
  };
}

export function segmentsFail() {
  return {
    type: SEGMENTS_FAIL,
  };
}

export const BUSINESSRULES_REQUEST = 'BUSINESSRULES_REQUEST/SALES-CHALLENGE';
export const BUSINESSRULES_SUCCESS = 'BUSINESSRULES_SUCCESS/SALES-CHALLENGE';
export const BUSINESSRULES_FAIL = 'BUSINESSRULES_FAIL/SALES-CHALLENGE';

export function businessRulesRequest(payload) {
  return {
    type: BUSINESSRULES_REQUEST,
    payload: payload,
  };
}

export function businessRulesSuccess(payload) {
  return {
    type: BUSINESSRULES_SUCCESS,
    payload: payload,
  };
}

export function businessRulesFail() {
  return {
    type: BUSINESSRULES_FAIL,
  };
}

export function cleanTopFilters() {
  return {
    type: CLEAN_FILTERS,
  };
}
