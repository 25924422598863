import React from 'react';
import { DatePicker, ConfigProvider } from 'antd';
import { PROCESS_DATES_FORMAT } from '../containers/ConsultAndUpdate';
import moment from 'moment';
import 'moment/locale/es-mx';

import esES from 'antd/lib/locale/es_ES';

const DateUpdater = (props) => {
  const { currentDate, active, onChange, dateToChange } = props;

  return active ? (
    <ConfigProvider locale={esES}>
      <DatePicker
        value={dateToChange ? moment(dateToChange, PROCESS_DATES_FORMAT) : null}
        format={PROCESS_DATES_FORMAT}
        onChange={onChange}
      />
    </ConfigProvider>
  ) : (
    <>{currentDate}</>
  );
};

export default DateUpdater;
