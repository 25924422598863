//TODO: Rename the name file to FormTitleDivider to ensure default export and
//file name are the same as welll to indicate this is a component
import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import './formTitleDivider.css';

const FormTitleDivider = ({ title, style }) => (
  <Row style={style}>
    <Col span={24}>
      <h3
        style={{
          textAlign: 'left',
          fontSize: '18px',
          color: 'rgb(33,160,210)',
          fontWeight: '500',
          height: '25px',
        }}
      >
        {title}
      </h3>
    </Col>
  </Row>
);

FormTitleDivider.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
};

FormTitleDivider.defaultProps = {
  title: '',
  style: {},
};

export default FormTitleDivider;
