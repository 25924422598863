/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import { useHistory } from 'react-router-dom';
import { Territories, BusinessLine, Months, PogYear } from 'RootModule/consultFilters/';
import ConsultTable from 'common/consults/components/Table';
import FilterWrapper from 'common/consults/components/FilterWrapper';
import SearchInput from 'common/consults/components/SearchInput';
import ResetButton from 'common/consults/components/ResetButton';
import getConsultInfoBaseModule from '../getConsultInfoBaseModule';
import { consultInfoBaseRequestTable } from '../redux-saga/actions';
import {
  consultFiltersBusinessLineClearValue,
  consultFiltersTerritoriesClearValue,
  consultFiltersMonthsClearValue,
  consultFiltersPogYearClearValue,
  consultFiltersSearchInputClearValue,
} from 'RootModule/redux/actions/consultFilters';
import consultTableSchema from '../schemas/consultTableSchema';
import { layoutContext } from 'context';

const ConsultInfoBasePog = (props) => {
  const {
    consultInfoBaseRequestTable: requestConsultTable,
    consultFiltersBusinessLineClearValue: clearBusinessLines,
    consultFiltersTerritoriesClearValue: clearTerritories,
    consultFiltersMonthsClearValue: clearMonths,
    consultFiltersPogYearClearValue: clearPogYear,
    consultFiltersSearchInputClearValue: clearSearchInput,
    consultTable,
    isLoading,
    BusinessLinesValue,
    TerritoriesValue,
    MonthsValue,
    PogYearValue,
    SearchInputValue,
  } = props;
  const { onChangeActiveComponent } = useContext(layoutContext);
  const history = useHistory();
  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, []);

  useEffect(() => {
    let consultTableParams = {};
    onChangeActiveComponent('BusinessStructureMassiveUpload');
    if (BusinessLinesValue?.length > 0) {
      consultTableParams.Line = BusinessLinesValue;
    }
    if (TerritoriesValue?.length > 0) {
      consultTableParams.Territory = TerritoriesValue;
    }
    if (MonthsValue?.length > 0) {
      consultTableParams.Month = MonthsValue;
    }
    if (PogYearValue?.length > 0) {
      consultTableParams.Year = PogYearValue;
    }
    if (SearchInputValue?.length > 0) {
      consultTableParams.Wildcard = [SearchInputValue];
    }
    requestConsultTable({ params: consultTableParams, history });
  }, [BusinessLinesValue, TerritoriesValue, MonthsValue, PogYearValue, SearchInputValue]);

  const clearFilters = () => {
    clearBusinessLines();
    clearTerritories();
    clearMonths();
    clearPogYear();
    clearSearchInput();
  };

  return (
    <div>
      <FilterWrapper>
        <BusinessLine />
        <Territories />
        <PogYear />
        <Months />

        <SearchInput floatToRight />
        <ResetButton clearFilters={clearFilters} />
      </FilterWrapper>
      <ConsultTable
        data={consultTable}
        columns={consultTableSchema}
        loading={isLoading}
        noResultsMessage={
          'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados.'
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    consultTable: state?.CONSULT_INFO_BASE?.consultTable,
    isLoading: state?.CONSULT_INFO_BASE?.isLoading,
    BusinessLinesValue: state?.APP?.consultFilters.businessLine?.value,
    TerritoriesValue: state?.APP?.consultFilters?.territories?.value,
    MonthsValue: state?.APP?.consultFilters?.months?.value,
    PogYearValue: state?.APP?.consultFilters?.pogyear?.value,
    SearchInputValue: state?.APP?.consultFilters?.searchInput?.value,
  };
};

const ConnectedConsultInfoBase = connect(mapStateToProps, {
  consultInfoBaseRequestTable,
  consultFiltersBusinessLineClearValue,
  consultFiltersTerritoriesClearValue,
  consultFiltersMonthsClearValue,
  consultFiltersPogYearClearValue,
  consultFiltersSearchInputClearValue,
})(ConsultInfoBasePog);

const consultBaseInfo = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getConsultInfoBaseModule()]}>
      <ConnectedConsultInfoBase />
    </DynamicModuleLoader>
  );
};
export default consultBaseInfo;
