import React from 'react';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const ErrorColumnTitle = ({ errorMessages = [] }) => {
  const content = errorMessages.map((msg) => (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '100px 1fr',
        width: '100%',
        maxWidth: '600px',
        gridGap: '0.5em',
      }}
      key={msg}
    >
      <p>{msg.fase}</p>
      <p>{msg.message}</p>
    </div>
  ));

  return (
    <>
      Error
      <span style={{ width: '10px', marginLeft: '5px' }}> </span>
      <Popover content={content} placement="bottomRight" style={{ width: '18px' }}>
        <QuestionCircleOutlined style={{ fontSize: '18px' }} />
      </Popover>
    </>
  );
};

export default ErrorColumnTitle;
