import React, { useEffect, useState } from 'react';
import { getBe } from '../services';
import BussinesEstructureSelect from './bussinesEstructureSelect';

function LobSelect({ regionsIds, required, multiple, onChange }) {
  const [lobs, setLobs] = useState([]);
  useEffect(() => {
    if (regionsIds && (regionsIds.length > 0 || regionsIds > 0)) {
      getBe({ element: 'lobs', region: regionsIds }).then((res) => setLobs(res));
    } else {
      setLobs([]);
    }
  }, [regionsIds]);

  return (
    <BussinesEstructureSelect
      tag={'lobs'}
      label={'lob'}
      required={required}
      options={lobs}
      disabled={lobs.length <= 0}
      multiple={multiple}
      onChange={onChange}
    />
  );
}
export default LobSelect;
