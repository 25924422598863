import { takeLatest, all, call, put, cancelled } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  CONSULT_INFO_BASE_REQUEST,
  consultInfoBaseSetTable,
  consultInfoBaseCleanTable,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';
import { AccountStatusConsult, checkExistDataPog } from '../../services';
import { messageError } from 'utils/messages';

function* workConsultInfoBaseTable(action) {
  const { params, history } = action.payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  let mustRedirectToHome = false;
  try {
    const response = yield call(AccountStatusConsult, {
      cancelToken: source.token,
      params,
    });

    const responseIfData = yield call(checkExistDataPog, {
      cancelToken: source.token,
    });

    if (responseOK(responseIfData)) {
      if (responseIfData.data === false) {
        yield call(notification.destroy);
        yield call(messageError, 'No hay registros de POG en el sistema');
        setTimeout(() => {
          history.push('/account-status/base-information');
        }, 300);
      }
    }

    if (responseOK(response)) {
      yield put(consultInfoBaseSetTable(response.data));
    } else {
      yield put(consultInfoBaseCleanTable());
    }
  } catch (error) {
    yield consultInfoBaseCleanTable();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield consultInfoBaseCleanTable();
      source.cancel('cancelled Request');
    }
    if (mustRedirectToHome) {
      yield call(notification.destroy);
      yield call(messageError, 'No hay registros de POG en el sistema');
      yield call(history.push, '/account-status/base-information');
    }
  }
}

export function* watchConsultInfoBaseTable() {
  yield all([takeLatest(CONSULT_INFO_BASE_REQUEST, workConsultInfoBaseTable)]);
}
