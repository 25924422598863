import request from '../../services/request';
const URL_SALESCHALLENGE = '/Sales/Api/v1/Challenge/';

export async function getChallengeReach({ zoneId, clientId }, cancelToken) {
  return request(`${URL_SALESCHALLENGE}Reach/${zoneId}/${clientId}`, {
    method: 'GET',
    cancelToken,
  });
}

export async function sendToValidate({ params, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}SendToValidate`, {
    method: 'PUT',
    cancelToken,
    params,
  });
}

export async function getChallenge({ params, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}`, {
    method: 'GET',
    cancelToken,
    params,
  });
}

export async function validate({ name, data, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}${name}`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

export async function getChallengeComments({ params, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}ChallengeComments`, {
    method: 'GET',
    cancelToken,
    params,
  });
}

export async function addProduct({ params, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}Products/Add`, {
    method: 'POST',
    cancelToken,
    data: params,
  });
}

export async function deleteProduct({ params, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}Products/Delete`, {
    method: 'DELETE',
    cancelToken,
    data: params,
  });
}
