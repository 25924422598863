import React, { useEffect, useState, useContext } from 'react';
import { Form, Col, Row } from 'antd';
import { BInput } from 'common/components';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getRootModule from 'RootModule';
import getUserModule from '../userModule';
import { readOneUserRequest, putOneUserRequest, toggleOneUserRequest } from '../redux-saga/actions';
import {
  Region,
  SubRegion,
  Territory,
  Zones,
  Roles,
  BusinessLine,
  Areas,
  Clients,
} from 'RootModule/catalogs';
import { layoutContext } from '../../context';

import { FormTitleDivider } from '../../common';
import { ActionButtons } from 'common';
import { useHistory, useParams } from 'react-router-dom';

function UserForm(props) {
  const { userData, readOneUserRequest: getUserData } = props;
  const { onChangeActiveComponent } = useContext(layoutContext);
  const params = useParams();
  const history = useHistory();
  const userId = params.id ? params.id.slice(1) : '';
  const [completed, setIsCompleted] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(false);
  const [multipleSelect, setMultipleSelect] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    getUserData({ userId });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChangeActiveComponent('UpdateER');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [requestParamsSubscription, setRequestParamsSubscription] = useState({
    areaId: undefined,
    businessLines: {},
    subRegion: {},
    territories: {},
    zones: {},
    clientsKAM: {},
  });

  const [statusSwitch, setStatusSwitch] = useState({
    //disabled
    checked: false,
    disabled: false,
  });

  const [allowedRequests, setAllowedRequest] = useState({
    areaId: false,
    roleId: false,
    region: true,
    businessLines: false,
    subRegion: false,
    territories: false,
    zones: false,
  });

  useEffect(() => {
    if (userData) {
      setStatusSwitch({
        disabled: true,
        checked: !userData?.enabled,
      });
      setSelectedProfile(userData?.roleId);
      setMultipleSelect(userData?.roleId === 5 ? false : true);
      const region = userData?.region.length ? userData?.region[0].id : undefined;
      const subRegions = userData?.subRegion.map((sub) => sub.id);
      const businessLines = userData?.bussinesLines.map((line) => line.id);
      const territories = userData?.territories.map((territory) => territory.id);
      const zones = userData?.zones.map((zone) => zone.id);
      const clients = userData?.clients.map((client) => client.clientId);
      setRequestParamsSubscription({
        areaId: userData?.roleId,
        businessLines: {
          regions: region,
        },
        subRegion: {
          regions: region,
          businessLines: businessLines,
        },
        territories: {
          subRegions: subRegions,
          businessLines: businessLines,
        },
        zones: {
          territories: territories,
        },
        clientsKAM: {
          businessLines: businessLines,
          placementId: 3,
        },
      });

      setAllowedRequest({
        areaId: true,
        roleId: true,
        region: true,
        businessLines: true,
        subRegion: true,
        territories: true,
        zones: true,
        clientsKAM: true,
      });

      //Load Initial Safe Values
      form.setFieldsValue({
        email: userData?.email,
        emailLeader: userData?.emailLeader,
        lastName: userData?.lastName,
        name: userData?.name,
        surName: userData?.surName,
        roleId: userData?.roleId,
        region: userData?.region[0]?.id,
        businessLines,
        territories,
        subRegion: subRegions,
        areaId: userData?.areaId,
        zones: zones,
        clientsKAM: clients,
      });
      setIsCompleted(checkIfIsCompleted({ ...userData, businessLines: userData?.bussinesLines }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, form]);

  const checkIfIsCompleted = (values) => {
    const checkMutableValue = (value) => {
      let result;
      if (values?.roleId === 5) {
        result = Boolean(value);
      } else {
        result = Boolean(value?.length > 0);
      }
      return result;
    };
    const completedObject = {
      name: Boolean(values?.name),
      surName: Boolean(values?.surName),
      email: Boolean(values?.email),
      emailLeader: Boolean(values?.emailLeader),
      roleId: Boolean(values?.roleId),
      areaId: values?.roleId === 2 ? Boolean(values?.areaId) : true,
      businessLines: checkMutableValue(values?.businessLines),
      subRegion: checkMutableValue(values?.subRegion),
      territories: checkMutableValue(values?.territories),
      zones: Boolean(values?.zones?.length > 0),
      clientsKAM:
        requestParamsSubscription?.areaId === 8 ? Boolean(values?.clientsKAM?.length > 0) : true,
    };
    const isNotCompleted = Object.values(completedObject).includes(false);
    return !isNotCompleted;
  };

  const onSubmit = () => {
    history.push(`/users/consult/edit/:${userId}`);
  };

  const onCancel = () => {
    history.push('/users');
  };

  return (
    <>
      <div className={'module-card'}>
        <Form form={form} onValuesChange>
          <Row>
            <FormTitleDivider title={'Información del usuario'} />
          </Row>
          <Row>
            <Col span={7}>
              <BInput
                disabled
                isRequired
                className="form-add-user-input"
                name="name"
                label="Nombre(s)"
              />
            </Col>
            <Col span={7}>
              <BInput
                disabled
                isRequired
                className="form-add-user-input"
                name="surName"
                label="Primer apellido"
              />
            </Col>

            <Col span={7}>
              <BInput
                disabled
                className="form-add-user-input"
                name="lastName"
                label="Segundo apellido"
              />
            </Col>

            <Col span={7}>
              <BInput
                isRequired
                disabled
                className="form-add-user-input"
                name="email"
                label="Correo electrónico:"
                rules={[{ type: 'email', message: 'usuario@dominio.extensión' }]}
              />
            </Col>

            <Col span={7}>
              <BInput
                disabled
                isRequired
                className="form-add-user-input"
                name="emailLeader"
                label="Correo electrónico de líder:"
                rules={[{ type: 'email', message: 'usuario@dominio.extensión' }]}
              />
            </Col>

            <Col span={7}>
              <Roles
                disabled
                isRequired
                className="form-add-user-input"
                name="roleId"
                label="Perfil"
                allowClear={false}
                placeholder=""
              />
            </Col>

            <Col span={7}>
              <Areas
                isRequired
                className="form-add-user-input"
                name="areaId"
                label="Área"
                disabled
                requestParams={requestParamsSubscription?.areaId}
                placeholder=""
              />
            </Col>
          </Row>
          <Row>
            <FormTitleDivider title={'Estructura comercial'} />
          </Row>
          <Row>
            <Col span={7}>
              <Region
                isRequired
                disabled
                name="region"
                requestParams={requestParamsSubscription.regions}
                askFromService={allowedRequests.region}
                sourceId="Fixed"
                allowClear={false}
                placeholder=""
              />
            </Col>

            <Col span={7}>
              <BusinessLine
                isRequired
                disabled
                name="businessLines"
                label="Línea de negocio"
                mode={multipleSelect && 'multiple'}
                requestMode="multiple"
                requestParams={requestParamsSubscription.businessLines}
                askFromService={allowedRequests.businessLines}
                sourceId="Fixed"
                allowClear={false}
                maxTagCount={1}
                selectAll={multipleSelect}
                placeholder=""
              />
            </Col>

            <Col span={7}>
              <SubRegion
                disabled
                isRequired
                name="subRegion"
                mode={multipleSelect ? 'multiple' : ''}
                requestMode="multiple"
                requestParams={requestParamsSubscription.subRegion}
                askFromService={allowedRequests.subRegion}
                sourceId="Fixed"
                allowClear={false}
                maxTagCount={1}
                selectAll={multipleSelect}
                placeholder=""
              />
            </Col>

            <Col span={7}>
              <Territory
                disabled
                isRequired
                mode={multipleSelect ? 'multiple' : ''}
                requestMode="multiple"
                requestParams={requestParamsSubscription.territories}
                askFromService={allowedRequests.territories}
                sourceId="Fixed"
                name="territories"
                allowClear={false}
                maxTagCount={1}
                selectAll={multipleSelect}
                placeholder=""
              />
            </Col>

            <Col span={7}>
              <Zones
                disabled
                isRequired
                mode="multiple"
                requestParams={requestParamsSubscription.zones}
                askFromService={allowedRequests.zones}
                sourceId="Fixed"
                name="zones"
                allowClear={false}
                maxTagCount={1}
                selectAll
                placeholder=""
              />
            </Col>
          </Row>

          <Row>
            <FormTitleDivider title="Cliente" />
          </Row>

          <Row style={{ justifyContent: 'space-between' }}>
            <Col span={7}>
              <Clients
                disabled
                sourceId="Placement"
                isRequired
                name="clientsKAM"
                label="Cliente(s)"
                mode="multiple"
                askFromService={selectedProfile === 8}
                optionKey="clienteId"
                labelKey="name"
                valueKey="clienteId"
                selectAll
                maxTagCount={1}
                requestParams={requestParamsSubscription.clientsKAM}
                placeholder=""
              />
            </Col>
          </Row>
        </Form>
      </div>
      <ActionButtons
        onCancel={onCancel}
        goToEditPage={onSubmit}
        statusSwitch={statusSwitch}
        disabled={!completed}
        buttons={['cancel', 'goToEditPage']}
      />
    </>
  );
}

function mapStateToProps(state) {
  return { userData: state?.USER?.users?.data };
}

const ConnectedUserForm = connect(mapStateToProps, {
  readOneUserRequest,
  putOneUserRequest,
  toggleOneUserRequest,
})(UserForm);

const UpdateUser = () => (
  <DynamicModuleLoader modules={[getRootModule(), getUserModule()]}>
    <ConnectedUserForm />
  </DynamicModuleLoader>
);
export default UpdateUser;
