export const PRODUCTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD = 'PRODUCTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const PRODUCTS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'PRODUCTS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const PRODUCTS_MASSIVE_UPLOAD_REQUEST_VERIFY = 'PRODUCTS_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const PRODUCTS_MASSIVE_UPLOAD_SET_VERIFY = 'PRODUCTS_MASSIVE_UPLOAD_SET_VERIFY';
export const PROCESS__DATES_MASSIVE_UPLOAD_CLEAR_VERIFY =
  'PROCESS__DATES_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const PRODUCTS_MASSIVE_UPLOAD_REQUEST_UPLOAD = 'PRODUCTS_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const PRODUCTS_MASSIVE_UPLOAD_SET_UPLOAD = 'PRODUCTS_MASSIVE_UPLOAD_SET_UPLOAD';
export const PRODUCTS_MASSIVE_UPLOAD_CLEAR_UPLOAD = 'PRODUCTS_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function productsMassiveUploadSetAllowUpload(payload) {
  return {
    type: PRODUCTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function productsMassiveUploadClearAllowUpload(payload) {
  return {
    type: PRODUCTS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function productsMassiveUploadRequestVerify(payload) {
  return {
    type: PRODUCTS_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function productsMassiveUploadSetVerify(payload) {
  return {
    type: PRODUCTS_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function productsMassiveUploadClearVerify(payload) {
  return {
    type: PROCESS__DATES_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function productsMassiveUploadRequestUpload(payload) {
  return {
    type: PRODUCTS_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function processDatesMassiveUploadSetUpload(payload) {
  return {
    type: PRODUCTS_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function productsMassiveUploadClearUpload(payload) {
  return {
    type: PRODUCTS_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
