function BaseInformationMassiveUploadReducer(state = {}, action) {
  const { type, payload } = action;

  const REDUCER = {
    Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD: {
      allowUpload: payload,
    },

    Dynamic_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD: {
      allowUpload: null,
    },

    Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY: {
      isLoading: true,
      verify: null,
    },

    Dynamic_MASSIVE_UPLOAD_SET_VERIFY: {
      verify: payload,
    },

    Dynamic_MASSIVE_UPLOAD_CLEAR_VERIFY: {
      verify: null,
    },

    Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD: {
      isLoading: true,
      upload: null,
    },

    Dynamic_MASSIVE_UPLOAD_SET_UPLOAD: {
      upload: payload,
    },

    Dynamic_MASSIVE_UPLOAD_CLEAR_UPLOAD: {
      upload: null,
    },
    EXIST_STATEMENTS: {
      ExistsStatements: null,
    },
  };

  return { ...state, isLoading: false, ...REDUCER[type] };
}

export default BaseInformationMassiveUploadReducer;
