import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import getDynamicMassiveUploadModule from '../getDynamicMassiveUploadModule';
import { layoutContext } from 'context';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import {
  MassiveUploadTable,
  UploadExcelButton,
  ActionButtonsExcelUpload,
} from 'common/MassiveUploads/components';
import { triggerModalForDuplicates } from 'common/MassiveUploads/utils';
//cambiar sagas
import {
  DynamicMassiveUploadSetAllowUpload,
  DynamicMassiveUploadRequestVerify,
  DynamicMassiveUploadRequestUpload,
  DynamicMassiveUploadClearUpload,
  DynamicMassiveUploadClearVerify,
  DynamicMassiveUploadClearAllowUpload,
} from '../redux-saga/actions';
import tableColumns from '../schemas/massiveUploadTableColumns'; //cambiar columna

const DynamicMassiveUpload = (props) => {
  const {
    DynamicMassiveUploadSetAllowUpload: setAllowUpload,
    DynamicMassiveUploadRequestVerify: requestVerify,
    DynamicMassiveUploadRequestUpload: requestUpload,
    DynamicMassiveUploadClearUpload: clearUpload,
    DynamicMassiveUploadClearVerify: clearVerify,
    DynamicMassiveUploadClearAllowUpload: clearAllowUpload,
    allowUpload,
    verify,
  } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [fileString, setFileString] = useState('');
  const correct = verify?.correct;
  const entriesList = verify?.objectList;
  const totalDuplicatedEntries = verify?.replaced?.length;
  const allCorrectMessage = verify?.allCorrectMessage;

  useEffect(() => {
    clearAllowUpload();
    return () => {
      clearAllowUpload();
      clearUpload();
      clearVerify();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    onChangeActiveComponent('activo');
    if (correct > 0) {
      setAllowUpload(true);
    } else {
      setAllowUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correct]);

  function customRequest({ file }) {
    requestVerify({ file, setFileString });
  }
  function requestReplace() {
    requestUpload({ history, fileString, overwrite: true });
  }
  function requestKeep() {
    requestUpload({ history, fileString, overwrite: false });
  }
  function onSubmit() {
    if (totalDuplicatedEntries > 0) {
      triggerModalForDuplicates(
        totalDuplicatedEntries,
        requestReplace,
        requestKeep,
        'elementos dinámicos'
      );
      return;
    }

    requestReplace();
  }
  function onCancel() {
    history.push(`/account-status`);
  }

  return (
    <Form form={form}>
      <UploadExcelButton customRequest={customRequest} />
      <MassiveUploadTable
        data={entriesList}
        columns={tableColumns}
        allCorrectMessage={allCorrectMessage}
      />
      <ActionButtonsExcelUpload
        onSubmit={onSubmit}
        onCancel={onCancel}
        disabled={!allowUpload}
        buttons={['cancel', 'submit']}
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    allowUpload: state?.Dynamic_MASSIVE_UPLOAD?.allowUpload,
    verify: state?.Dynamic_MASSIVE_UPLOAD?.verify,
  };
};

const ConnectedDynamicMassiveUpload = connect(mapStateToProps, {
  DynamicMassiveUploadSetAllowUpload,
  DynamicMassiveUploadRequestVerify,
  DynamicMassiveUploadRequestUpload,
  DynamicMassiveUploadClearUpload,
  DynamicMassiveUploadClearVerify,
  DynamicMassiveUploadClearAllowUpload,
})(DynamicMassiveUpload);

const DynamicMassiveUploadWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getDynamicMassiveUploadModule()]}>
      <ConnectedDynamicMassiveUpload />
    </DynamicModuleLoader>
  );
};
export default DynamicMassiveUploadWithModules;
