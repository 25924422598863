import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BButton from '../BButton';
import './ReviewActions.css';
import { IconsMap } from '../../../Layout/utils';
import { Modal, Button } from 'antd';

const CloseIcon = IconsMap.CloseOutlined;
const CheckIcon = IconsMap.CheckOutlined;
const ExclamationIcon = IconsMap.ExclamationCircleOutlined;

function ReviewActions({
  onRejected,
  onApprove,
  actions,
  showLabels,
  templateLiteralsAprove,
  templateLiteralsReject,
  className,
}) {
  const [stateComment, setStateComment] = useState('');
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [approved, setApproved] = useState(undefined);

  const showModal = (approve) => {
    setShowModalConfirm(true);
    setApproved(approve);
  };

  const hideModal = () => {
    setShowModalConfirm(false);
  };

  return (
    <>
      <Modal visible={showModalConfirm} footer={null} onCancel={hideModal} width={416}>
        {approved === false ? (
          <>
            <div className="container-modal-title">
              <ExclamationIcon />
              <p>
                {approved === false
                  ? templateLiteralsReject.modal.title
                  : templateLiteralsAprove.modal.title}
              </p>
            </div>
            <div className="container-modal-text">
              <p>{templateLiteralsReject.modal.content}</p>
              <textarea
                maxLength="300"
                style={{ width: '100%' }}
                onChange={(e) => {
                  setStateComment(e.currentTarget.value);
                }}
              />
              <div className="container-modal-buttons">
                <Button onClick={() => hideModal()}>Cancelar</Button>
                <div style={{ marginLeft: '10px' }}>
                  <Button
                    disabled={stateComment.length <= 0}
                    type="primary"
                    onClick={() => {
                      onRejected(stateComment);
                      hideModal();
                    }}
                  >
                    Aceptar
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="container-modal-title">
              <ExclamationIcon />
              <p>
                {approved === false
                  ? templateLiteralsReject.modal.title
                  : templateLiteralsAprove.modal.title}
              </p>
            </div>
            <div className="container-modal-text">
              <p style={{ marginBottom: '30px' }}>{templateLiteralsAprove.modal.content}</p>
              <div className="container-modal-buttons">
                <Button onClick={() => hideModal()}>Cancelar</Button>
                <div style={{ marginLeft: '10px' }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      onApprove();
                      hideModal();
                    }}
                  >
                    Aceptar
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>

      <div className={`actions-container ${className}`}>
        {actions &&
          actions.length > 0 &&
          actions.map((action) => {
            let label = action === 'approve' ? 'Aprobar' : 'Rechazar';
            let icon = action === 'approve' ? <CheckIcon /> : <CloseIcon />;
            let actionParam = action === 'approve' ? true : false;
            return (
              <div className={`action-container ${action}`} key={action}>
                {showLabels && <p>{label}</p>}
                <BButton
                  type="primary"
                  shape="circle"
                  icon={icon}
                  size={!showLabels && 'small'}
                  onClick={() => showModal(actionParam)}
                />
              </div>
            );
          })}
      </div>
    </>
  );
}

ReviewActions.propTypes = {
  onRejected: PropTypes.func,
  onApprove: PropTypes.func,
  actions: PropTypes.array,
  showLabels: PropTypes.bool,
  className: PropTypes.string,
};

ReviewActions.defaultProps = {
  actions: [],
  onRejected: () => alert('No has pasado una función para rechazar'),
  onApprove: () => alert('No has pasado una función para aprobar'),
  showLabels: true,
  className: 'review-challenge',
};

export default ReviewActions;
