import request from 'services/request';

//Edit Position image
export async function ManageImagesPutPositionImage({ cancelToken, data, endpoint }) {
  let uri = endpoint;
  return request(uri, {
    method: 'POST',
    data,
    cancelToken,
  });
}
