import moment from 'moment';

const renderAmount = (amount) => {
  let newAmount = '';
  if (amount !== undefined && amount !== null && amount !== '') {
    newAmount = `$${Number(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  }
  return newAmount;
};

const TableSchema = [
  {
    title: 'Sold To',
    key: 'soldTo',
    dataIndex: 'soldTo',
    className: 'data-column',
  },
  {
    title: 'Cliente',
    key: 'clientName',
    dataIndex: 'clientName',
    className: 'data-column',
  },
  {
    title: 'Línea de negocio',
    key: 'line',
    dataIndex: 'line',
    className: 'data-column',
  },
  {
    title: 'Subregión',
    key: 'subregion',
    dataIndex: 'subregion',
    className: 'data-column',
  },
  {
    title: 'Incentivo',
    key: 'incentive',
    dataIndex: 'incentive',
    className: 'data-column',
  },
  {
    title: 'Periodicidad',
    key: 'periodicity',
    dataIndex: 'periodicity',
    className: 'data-column',
  },
  {
    title: 'Periodo',
    key: 'period',
    dataIndex: 'period',
    className: 'data-column',
  },

  {
    title: 'Año',
    key: 'yaear',
    dataIndex: 'year',
    className: 'data-column',
  },
  {
    title: 'Monto Rebate',
    key: 'rebateAmount',
    dataIndex: 'rebateAmount',
    render: (rebateAmount) => renderAmount(rebateAmount),
    className: 'data-column',
  },
  {
    title: 'Comentarios',
    key: 'comment',
    dataIndex: 'comment',
    className: 'data-column',
  },
  {
    title: 'Fecha',
    key: 'date',
    dataIndex: 'date',
    className: 'data-column',
    render: (date) =>
      date !== null && date !== undefined && date !== '' ? moment(date).format('L') : '',
  },
];

export default TableSchema;
