import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function getProperSize({ offsetHeight, offsetWidth }) {
  let width = offsetWidth / 2;
  let heigth = offsetHeight / 2;
  if (width > 420) {
    width = 420;
  }
  return { width, heigth };
}

async function downloadPDFFromHtml(name) {
  const canvasOptions = {};
  let chartCanvas = document.getElementById('bar');
  let chartImg;
  let tableElement = document.getElementById('periodicity-table');
  let { offsetHeight } = tableElement;
  let tableCanvas;
  let tableImg;
  const documentHeigth = 210 + offsetHeight / 1.5;
  const pdf = new jsPDF('p', 'px', [documentHeigth < 500 ? 500 : documentHeigth, 450]);

  let tableSize = getProperSize(tableElement);

  chartImg = chartCanvas.toDataURL('image/png');
  tableCanvas = await html2canvas(tableElement, canvasOptions);
  tableImg = tableCanvas.toDataURL('image/png');

  pdf.addImage(chartImg, 'png', 10, 10, 430, 215);

  pdf.addImage(tableImg, 'png', 10, 240, tableSize.width, tableSize.heigth);

  pdf.save(`${name}.pdf`);
}

export default downloadPDFFromHtml;
