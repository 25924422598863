import React from 'react';
import { Button } from 'antd';
import { downloadPDFFromHtml } from '../utils';
import { message001 } from 'utils/messages';

const buttonStyle = {
  width: '100%',
  fontSize: '1.2em',
  height: 'calc(16px + 1.2em)',
  fontWeight: 600,
  marginTop: '15px',
};

function parseTwoDigits(digits) {
  let string = `${digits}`;
  if (string.length < 2) {
    return `0${string}`;
  }
  return string;
}

export function getDMYDateString() {
  const date = new Date();
  const year = date.getFullYear();
  const month = parseTwoDigits(date.getMonth() + 1);
  const day = parseTwoDigits(date.getDate());

  return `${day}_${month}_${year}`;
}

const DownloadPDFButton = (props) => {
  function printClick() {
    let todayDateString = getDMYDateString();
    downloadPDFFromHtml(`Dashboard Comparativo ${todayDateString}`);
    message001('La descarga de información del dashboard comparativo');
  }

  const { cssFile } = props;
  return (
    <>
      <link rel="stylesheet" type="text/css" href={cssFile} />
      <Button style={buttonStyle} type="primary" onClick={printClick}>
        Imprimir PDF
      </Button>
    </>
  );
};

export default DownloadPDFButton;
