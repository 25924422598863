import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Tooltip, Polygon } from 'react-leaflet';
import './Maps.css';
import 'leaflet/dist/leaflet.css';
import { addCommasToFloat, reduceDecimalsOfFloat } from 'utils';

function Maps(props) {
  const { mapsState, filters } = props;
  const [mapCoordinates, setMapCoordinates] = useState([]);
  const [acuerdoDesafio, setacuerdoDesafio] = useState(0);

  useEffect(() => {
    const { get: coordinates } = mapsState;
    if (coordinates) {
      const allCoordinates = coordinates.map((coordinate) => coordinate);
      setMapCoordinates([...allCoordinates]);
    }
  }, [mapsState]);

  useEffect(() => {
    if (filters) {
      if (filters.agreementChallenge === 1) {
        setacuerdoDesafio('agreement');
      } else if (filters.agreementChallenge === 2) {
        setacuerdoDesafio('challenge');
      }
    }
  }, [filters]);

  return (
    <div id="map123">
      <MapContainer
        className="leaflet-container"
        center={{ lat: '20', lng: '-95' }}
        zoom={4}
        preferCanvas={true}
        id="map"
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {mapCoordinates.map((coordinates, index) => (
          <Polygon
            stroke={true}
            key={index}
            pathOptions={{ color: coordinates.color }}
            positions={coordinates.map}
          >
            <Tooltip sticky>
              {coordinates.label}:{' '}
              {`$${addCommasToFloat(reduceDecimalsOfFloat(coordinates[acuerdoDesafio]))}`}
            </Tooltip>
          </Polygon>
        ))}
      </MapContainer>
    </div>
  );
}

export default Maps;
