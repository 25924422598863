import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import getSalesMassiveUploadModule from '../getSalesMassiveUploadModule';
import { layoutContext } from 'context';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import {
  MassiveUploadTable,
  UploadExcelButton,
  ActionButtonsExcelUpload,
} from 'common/MassiveUploads/components';
import { triggerModalForDuplicates } from 'common/MassiveUploads/utils';

import {
  SalesMassiveUploadSetAllowUpload,
  SalesMassiveUploadRequestVerify,
  SalesMassiveUploadRequestUpload,
  SalesMassiveUploadClearUpload,
  SalesMassiveUploadClearVerify,
  SalesMassiveUploadClearAllowUpload,
} from '../redux-saga/actions';
import tableColumns from '../schemas/massiveUploadTableColumns'; //cambiar columna

const SalesMassiveUpload = (props) => {
  const {
    SalesMassiveUploadSetAllowUpload: setAllowUpload,
    SalesMassiveUploadRequestVerify: requestVerify,
    SalesMassiveUploadRequestUpload: requestUpload,
    SalesMassiveUploadClearUpload: clearUpload,
    SalesMassiveUploadClearVerify: clearVerify,
    SalesMassiveUploadClearAllowUpload: clearAllowUpload,
    allowUpload,
    verify,
  } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [fileString, setFileString] = useState('');

  const correct = verify?.correct;
  const entriesList = verify?.objectList;
  const totalDuplicatedEntries = verify?.replaced?.length;
  const allCorrectMessage = verify?.allCorrectMessage;

  useEffect(() => {
    clearAllowUpload();
    return () => {
      clearAllowUpload();
      clearUpload();
      clearVerify();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    onChangeActiveComponent('activo');
    if (correct > 0) {
      setAllowUpload(true);
    } else {
      setAllowUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correct]);

  function customRequest({ file }) {
    requestVerify({ file, setFileString });
  }

  function requestReplace() {
    requestUpload({ history, fileString, overwrite: true });
  }
  function requestKeep() {
    requestUpload({ history, fileString, overwrite: false });
  }
  function onSubmit() {
    if (totalDuplicatedEntries > 0) {
      triggerModalForDuplicates(totalDuplicatedEntries, requestReplace, requestKeep, 'Ventas');
      return;
    }

    requestReplace();
  }
  function onCancel() {
    history.push(`/historic-information`);
  }

  return (
    <Form form={form}>
      <UploadExcelButton customRequest={customRequest} />
      <MassiveUploadTable
        data={entriesList}
        columns={tableColumns}
        allCorrectMessage={allCorrectMessage}
      />
      <ActionButtonsExcelUpload
        onSubmit={onSubmit}
        onCancel={onCancel}
        disabled={!allowUpload}
        buttons={['cancel', 'submit']}
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    allowUpload: state?.SALES_MASSIVE_UPLOAD?.allowUpload,
    verify: state?.SALES_MASSIVE_UPLOAD?.verify,
  };
};

const ConnectedSalesMassiveUpload = connect(mapStateToProps, {
  SalesMassiveUploadSetAllowUpload,
  SalesMassiveUploadRequestVerify,
  SalesMassiveUploadRequestUpload,
  SalesMassiveUploadClearUpload,
  SalesMassiveUploadClearVerify,
  SalesMassiveUploadClearAllowUpload,
})(SalesMassiveUpload);

const SalesMassiveUploadWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getSalesMassiveUploadModule()]}>
      <ConnectedSalesMassiveUpload />
    </DynamicModuleLoader>
  );
};
export default SalesMassiveUploadWithModules;
