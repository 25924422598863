import React, { useEffect, useState, useCallback } from 'react';
import { Col, Select, DatePicker, Button } from 'antd';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { FaTrashAlt } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { BusinessEstructureFilters } from '..';
import { processDatesQuery } from '../../global/utils';
import SearchInput from './SearchInput';
import { useLocation, Link } from 'react-router-dom';
import './filtersRow.css';
const { Option } = Select;
const { RangePicker } = DatePicker;

function BSFiltersRow({
  search,
  yearSearch,
  beFilters,
  dateRange,
  selectors,
  multipeYears,
  onChange,
  toggleShowModal,
  toggleDownloadCatalogModal,
  name,
  deleteIsLoading,
}) {
  const [filterQuery, setFilterQuery] = useState({});
  const [year, setYear] = useState(undefined);
  const [dateRanges, setDateRanges] = useState([]);
  const [cleared, setCleared] = useState(false);

  let location = useLocation();
  let path = location.pathname;
  let updateRoute = '/catalog/business-estructure';
  const clearFilters = () => {
    setFilterQuery({});
    setYear(undefined);
    setDateRanges([]);
    setCleared(true);
  };
  const onChangeCallback = useCallback(
    (filterQuery2) => {
      onChange(filterQuery2);
    },
    [onChange]
  );

  useEffect(() => {
    onChangeCallback(filterQuery);
  }, [filterQuery, onChangeCallback]);

  return (
    <div id="businessEstructureFilters">
      {beFilters && (
        <Col style={{ width: '100%' }}>
          <BusinessEstructureFilters
            selectors={selectors}
            requiredSelectors={[]}
            parentTag="archive"
            value={filterQuery}
            onChange={(value) => {
              setFilterQuery({
                ...filterQuery,
                beSelects: value,
              });
            }}
            multipleSelectors={[
              'regions',
              'lobs',
              'subregions',
              'countries',
              'territories',
              'zones',
            ]}
          />
        </Col>
      )}
      {dateRange && (
        <Col style={{ marginRight: '15px', marginTop: '20px' }}>
          <RangePicker
            value={dateRanges}
            // locale={locale}
            inputReadOnly={false}
            onChange={(value) => {
              setFilterQuery({ ...filterQuery, datesSearch: processDatesQuery(value) });
              setCleared(false);
              setDateRanges(value);
            }}
          />
        </Col>
      )}
      {yearSearch && (
        <Col style={{ marginRight: '15px' }}>
          <p style={{ margin: '0px', color: '#21A0D2' }}>Año:</p>
          <Select
            style={{ width: '130px' }}
            placeholder="Seleccionar"
            mode={multipeYears && 'multiple'}
            value={year}
            showSearch={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(value) => {
              setCleared(false);
              setFilterQuery({
                ...filterQuery,
                yearReference: value,
              });
              setYear(value);
            }}
          >
            {yearSearch.length > 0 &&
              yearSearch.map((option, key) => (
                <Option value={option} key={key}>
                  {option}
                </Option>
              ))}
          </Select>
        </Col>
      )}

      {(name === 'products' || name === 'clients' || name === 'prices') && (
        <Col style={{ width: 'auto', marginTop: '1.5em', marginRight: '10px' }}>
          <button
            className="ant-btn ant-btn-info"
            onClick={() => {
              toggleDownloadCatalogModal();
            }}
          >
            {' '}
            Descargar catálogo{' '}
          </button>
        </Col>
      )}

      {search && (
        <Col style={{ width: 'auto', marginTop: '1.5em', marginRight: '10px' }}>
          <SearchInput
            onChange={(value) => {
              setCleared(false);
              setFilterQuery({
                ...filterQuery,
                searchInput: value,
              });
            }}
            filterQuery={filterQuery}
            clear={cleared}
          />
        </Col>
      )}
      {path === updateRoute && (
        <Col style={{ marginRight: '10px' }}>
          <Link
            to="/catalog/business-estructure/update/"
            className="ant-btn ant-btn-info"
            style={{
              outline: 'none',
              height: '30px',
              marginTop: '1.5em',
            }}
          >
            Actualizar
          </Link>
        </Col>
      )}
      <Col style={{ marginRight: '10px' }}>
        <button
          className="ant-btn ant-btn-info"
          onClick={clearFilters}
          style={{
            outline: 'none',
            height: '30px',
            marginTop: '1.5em',
          }}
        >
          <IconContext.Provider value={{ size: '1em', color: '#fbfbfb' }}>
            <BsArrowCounterclockwise />
          </IconContext.Provider>
        </button>
      </Col>

      {(name === 'products' || name === 'clients' || name === 'prices') && (
        <Col style={{ marginRight: '10px' }}>
          <Button
            className="ant-btn delete-catalog__button"
            onClick={() => {
              toggleShowModal();
            }}
            style={{
              outline: 'none',
              height: '30px',
              marginTop: '1em',
            }}
            disabled={deleteIsLoading}
            loading={deleteIsLoading}
          >
            <IconContext.Provider
              value={{
                size: '1em',
                color: !deleteIsLoading ? '#00458c' : '#00458c6b',
                //'#fbfbfb'
              }}
            >
              <FaTrashAlt />
            </IconContext.Provider>
          </Button>
        </Col>
      )}
    </div>
  );
}
export default BSFiltersRow;
