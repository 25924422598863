import { call, put, cancelled, takeLatest, select } from 'redux-saga/effects';
import { notification } from 'antd';
import {
  ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_REQUEST_CONSULT_TABLE,
  ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_REQUEST_DATA_EXISTANCE,
  accountStatusTradeAgreementGoalsSetConsultTable,
  accountStatusTradeAgreementGoalsClearConsultTable,
  accountStatusTradeAgreementGoalsSetDataExistance,
  accountStatusTradeAgreementGoalsClearDataExistance,
  accountStatusTradeAgreementGoalsRequestDataExistance,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';
import {
  choseObjectivesAndIncentivesService,
  annualConsult,
  salesConsult,
  pogConsult,
} from '../../services';
import { messageError } from 'utils/messages';

function* workAccountStatusTradeAgreementGoalsRequestConsultTable(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { params, typeOfService, history } = action.payload;
  const chosenService = choseObjectivesAndIncentivesService(typeOfService);

  let state = yield select();
  let { dataExistance } = state.ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS;

  try {
    const response = yield call(chosenService, {
      cancelToken: source.token,
      params,
    });
    if (responseOK(response)) {
      yield put(accountStatusTradeAgreementGoalsSetConsultTable(response.data));
    } else {
      yield put(accountStatusTradeAgreementGoalsClearConsultTable());
    }
  } catch (error) {
    yield accountStatusTradeAgreementGoalsClearConsultTable();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield accountStatusTradeAgreementGoalsClearConsultTable();
      source.cancel('cancelled Request');
    }
    if (dataExistance === undefined) {
      yield put(accountStatusTradeAgreementGoalsRequestDataExistance(history));
    }
  }
}

export function* watchAccountStatusTradeAgreementGoalsRequestConsultTable() {
  yield takeLatest(
    ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_REQUEST_CONSULT_TABLE,
    workAccountStatusTradeAgreementGoalsRequestConsultTable
  );
}

function* workAccountStatusTradeAgreementGoalsRequestDataExistance(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const history = action.payload;

  let dataExistance = true;
  try {
    const annualResponse = yield call(annualConsult, {
      cancelToken: source.token,
    });
    const salesResponse = yield call(salesConsult, {
      cancelToken: source.token,
    });
    const pogResponse = yield call(pogConsult, {
      cancelToken: source.token,
    });
    if (responseOK(annualResponse) && responseOK(salesResponse) && responseOK(pogResponse)) {
      dataExistance =
        annualResponse.existsObjectiveIncentive &&
        salesResponse.existsObjectiveIncentive &&
        pogResponse.existsObjectiveIncentive;

      yield put(accountStatusTradeAgreementGoalsSetDataExistance(dataExistance));
    } else {
      yield put(accountStatusTradeAgreementGoalsClearDataExistance());
    }
  } catch (error) {
    yield accountStatusTradeAgreementGoalsClearDataExistance();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield accountStatusTradeAgreementGoalsClearDataExistance();
      source.cancel('cancelled Request');
    }
    if (dataExistance === false) {
      yield call(notification.destroy);
      yield call(messageError, 'No hay registros de objetivos e incentivos en el sistema');
      yield call(history.push, '/account-status/base-information');
    }
  }
}

export function* watchAccountStatusTradeAgreementGoalsRequestDataExistance() {
  yield takeLatest(
    ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_REQUEST_DATA_EXISTANCE,
    workAccountStatusTradeAgreementGoalsRequestDataExistance
  );
}
