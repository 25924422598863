import React from 'react';
import Styles from './box-component.module.css';

const BoxComponent = ({
  title,
  backgroundColorTitle,
  children,
  width,
  backgroundColor,
  padding,
  borderLine,
  height,
}) => {
  const style = {
    backgroundColor: `${backgroundColor}`,
    padding: `${padding}`,
    borderRight: borderLine === true ? `3px solid ${backgroundColorTitle}` : '',
    borderLeft: borderLine === true ? `3px solid ${backgroundColorTitle}` : '',
    borderBottom: borderLine === true ? `3px solid ${backgroundColorTitle}` : '',
  };
  return (
    <div className={Styles[`box-container`]} style={{ width: `${width}%`, height: `${height}px` }}>
      <div
        className={Styles[`title-container`]}
        style={{ backgroundColor: `${backgroundColorTitle}` }}
      >
        {title}
      </div>
      <div className={Styles[`children-container`]} style={style}>
        {children}
      </div>
    </div>
  );
};

export default BoxComponent;
