export const SET_VALIDATORS_QUERY = 'SET_VALIDATORS_QUERY';

export const setValidatorsQuery = (payload) => {
  return {
    type: SET_VALIDATORS_QUERY,
    payload: payload,
  };
};

export const REQUEST_VALIDATORS_DISABLED_DEPENDING = 'REQUEST_VALIDATORS_DISABLED_DEPENDING';
export const SET_VALIDATORS_DISABLED_DEPENDING = 'SET_VALIDATORS_DISABLED_DEPENDING';

export const requestValidatorsDisabledDepending = (payload) => ({
  type: REQUEST_VALIDATORS_DISABLED_DEPENDING,
  payload: payload,
});

export const setValidatorsDisabledDepending = (payload) => ({
  type: SET_VALIDATORS_DISABLED_DEPENDING,
  payload: payload,
});
