export const SET_VALIDATION_FIELDS_REQUEST = 'SET_VALIDATION_FIELDS_REQUEST';
export const SET_VALIDATION_FIELDS_SUCCESS = 'SET_VALIDATION_FIELDS_SUCCESS';
export const SET_VALIDATION_FIELDS_FAIL = 'SET_VALIDATION_FIELDS_FAIL';

export function setValidationsFieldsRequest(payload) {
  return { type: SET_VALIDATION_FIELDS_REQUEST, payload };
}

export function setValidationsFieldsSuccess(payload) {
  return { type: SET_VALIDATION_FIELDS_SUCCESS, payload };
}

export function setValidationsFieldsFail(payload) {
  return { type: SET_VALIDATION_FIELDS_FAIL, payload };
}
