import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Radio, Space, Drawer, Spin } from 'antd';
import { ActionButtons, openNotification } from 'common';
import ChildrenDrawer from 'AccountStatus/ManageImages/utils/ChildrenDrawer';
import './drawer.css';
import getRootModule from 'RootModule';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getManageImagesModule from '../../Redux-saga/getModule';
import { DrawerAddActions, TableActions } from '../../Redux-saga/actions';
const { InputImagesSet, POSTImageLineRequest } = DrawerAddActions;
const { InputFilesCameraSet, GETListImagesRequest, ConsultManageImagesRequestTable } = TableActions;

const DrawerAddComponent = (props) => {
  const {
    //Loading Back
    isLoading,
    // Imagenes Input en base64
    InputImagesSet: setInputImages,
    inputImages,
    //Input Files Camera
    InputFilesCameraSet: setinputFiles,
    inputFiles,
    //POST Image Line
    POSTImageLineRequest: POSTImageLine,
    //Datos de la fila que se le hizo Click
    rowClickData,
    // Obtiene imagenes por estructura comercial
    GETListImagesRequest: GETListImages,
    // Obtiene datos de Tabla
    ConsultManageImagesRequestTable: getTableData,
  } = props;

  const [valueMonth, setvalueMonth] = useState([]);
  const [valuePosition, setvaluePosition] = useState(0);
  const [valueDescription, setvalueDescription] = useState('');
  const [stateBtns, setstateBtns] = useState(['cancel', 'next']);
  const [keyImage, setkeyImage] = useState(0);
  const [btnDisable, setbtnDisable] = useState(true);
  const [keysImagesChoised, setkeysImagesChoised] = useState([]);
  const history = useHistory();

  const comercialStructure = {
    LineId: { LineId: rowClickData.lineId, Type: 1 },
    SubRegionId: { LineId: rowClickData.lineId, SubregionId: rowClickData.subregionId, Type: 2 },
    ClientId: {
      ClientId: rowClickData.clientId,
      SubregionId: rowClickData.subRegionId,
      LineId: rowClickData.lineId,
      Type: 4,
    },
    TerritoryId: {
      TerritoryId: rowClickData.territoryId,
      LineId: rowClickData.lineId,
      SubregionId: rowClickData.subRegionId,
      Type: 3,
    },
  };
  const endpoints = {
    LineId: {
      endpoint: '/AccountStatement/Api/v1/Files/ImageLine',
    },
    SubRegionId: {
      endpoint: '/AccountStatement/Api/v1/Files/ImageSubregion',
    },
    ClientId: {
      endpoint: '/AccountStatement/Api/v1/Files/ImageClient',
    },
    TerritoryId: {
      endpoint: '/AccountStatement/Api/v1/Files/ImageTerritory',
    },
  };

  // Establece botones y positions iniciales
  useEffect(() => {
    if (inputImages.length) {
      keyNumberImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputImages]);

  //Valida boton disabled
  useEffect(() => {
    if (valueMonth.length && valuePosition) {
      setbtnDisable(false);
    } else {
      setbtnDisable(true);
    }
  }, [valueMonth, valuePosition]);

  // Determina los botones de acuerdo al flujo
  useEffect(() => {
    let numImages = inputImages.length;
    if (numImages === 1) {
      setstateBtns(['cancel', 'submit']);
    }
    if (keyImage === numImages - 1) {
      setstateBtns(['cancel', 'submit']);
    }
    if (keyImage === 0 && numImages > 1) {
      setstateBtns(['cancel', 'next']);
    }
    if (keyImage > 0 && keyImage < numImages - 1) {
      setstateBtns(['previous', 'next']);
    }
    if (keyImage === numImages - 1 && numImages > 1) {
      setstateBtns(['previous', 'submit']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyImage]);

  const keyNumberImage = () => {
    let numImages = inputImages.length;
    if (numImages === 1) {
      setstateBtns(['cancel', 'submit']);
      setvaluePosition(1);
    }
  };
  const onChangeMonth = (list) => {
    setvalueMonth(list);
  };
  const onChangePosition = (e) => {
    setvaluePosition(e.target.value);
  };
  const onChangeTextArea = (e) => {
    setvalueDescription(e.target.value);
  };
  const isRadioDisabled = (key) => {
    if (keysImagesChoised.includes(key + 1)) {
      return true;
    }
    return false;
  };

  //Si el POST se realiza correctamente
  const okPost = async () => {
    setkeyImage(keyImage + 1);
    setvalueMonth([]);
    setvaluePosition(0);
    setvalueDescription('');
    setkeysImagesChoised([...keysImagesChoised, valuePosition]);
    let numImages = inputImages.length;
    if (keyImage === numImages - 1) {
      // Recargar imagenes de Estructura comercial especifica
      if (rowClickData?.containsImages === false) {
        openNotification(
          'success',
          `La carga de las imágenes de estado de cuenta se realizó correctamente.`
        );
        await getTableData({ history });
      } else {
        openNotification(
          'success',
          `El reemplazo de las imágenes de estado de cuenta se realizó correctamente.`
        );
      }
      let dataGet = { listImages: comercialStructure[rowClickData.type] };
      await GETListImages(dataGet);
      setInputImages([]);
    }
  };
  // Section Position
  const selectPosition = () => (
    <div style={{ margin: '0px 35px' }} key={'drawerAddDivOne'}>
      <h4
        style={{ textAlign: 'left', color: valuePosition > 0 ? '#00499a' : 'red' }}
        key={'drawerAddh4select'}
      >
        *Seleccionar posición
      </h4>
      <Radio.Group onChange={onChangePosition} value={valuePosition} key={'RadioDrawerAddRadio'}>
        <Space style={{ marginLeft: '20px' }} key={'spaceDrawerAddRadio'}>
          {inputImages?.map((item, key) => (
            <Radio value={key + 1} disabled={isRadioDisabled(key)} key={`${key}Radio`}>
              <span style={{ color: valuePosition > 0 ? 'black' : 'red' }}>{key + 1}</span>
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </div>
  );
  //##Acciones de Botones##
  const onNext = () => {
    let dataEndpoint = endpoints[rowClickData.type];
    let imageBase64 = inputImages[keyImage].substr(23);
    let replaced = rowClickData.containsImages === true ? 1 : 0;
    let firstReplaced = keyImage === 0 ? 1 : 0;

    let imageInfo = {
      image: imageBase64,
      fileName: inputFiles[keyImage].name,
      month: valueMonth,
      period: valuePosition,
      description: valueDescription,
      replaced,
      firstReplaced,
      ...comercialStructure[rowClickData.type],
    };

    POSTImageLine({ imageInfo, dataEndpoint, okPost });
  };

  const onCancel = () => {
    setInputImages([]);
    setinputFiles([]);
    setvalueMonth([]);
    setvaluePosition(0);
    setvalueDescription('');
  };

  const onPrevious = () => {
    setkeyImage(keyImage - 1);
    let array = keysImagesChoised;
    array.pop();
    setkeysImagesChoised(array);
    setvalueMonth([]);
    setvaluePosition(0);
    setvalueDescription('');
  };
  return (
    <Drawer
      title={
        <div
          style={{ color: '#00499a', display: 'flex', height: '25px' }}
          key={'divAdregarDrawerAdd'}
        >
          <InfoCircleOutlined
            style={{ fontSize: '22px', margin: '0 15px 0 0' }}
            key={'InfoCircleOutlinedDrawerAdd'}
          />

          <h3 style={{ color: '#00499a', margin: 0 }} key={'h3AdregarDrawerAdd'}>
            Agregar imagen
          </h3>
        </div>
      }
      placement="right"
      closable={false}
      visible={inputImages.length}
      className="drawerM"
      id="drawerManageImages"
      key={'DRAWERAgregarDrawerAdd'}
    >
      <Spin size="large" spinning={isLoading} key={'drawerAddh4select'}>
        <ChildrenDrawer
          onChangeMonth={onChangeMonth}
          valueMonth={valueMonth}
          valueDescription={valueDescription}
          onChangeTextArea={onChangeTextArea}
          selectPosition={selectPosition}
        />
      </Spin>
      <ActionButtons
        buttons={stateBtns}
        onCancel={onCancel}
        onPrevious={onPrevious}
        onNext={onNext}
        onSubmit={onNext}
        disabled={btnDisable}
        isLoading={isLoading}
        style={{ width: '120px', marginTop: 0 }}
      />

      <img
        src={inputImages[keyImage]}
        alt="Images Upload"
        className="imgDrawerManageImage"
        key={'imageDrawerAdd'}
      />
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  return {
    inputImages: state?.MANAGE_IMAGES?.DrawerAdd?.inputImages,
    inputFiles: state?.MANAGE_IMAGES?.Table?.inputFilesCamera,
    rowClickData: state?.MANAGE_IMAGES?.Table?.rowClick,
    isLoading: state?.MANAGE_IMAGES?.DrawerAdd?.isLoading,
  };
};

const ConnectedManageImages = connect(mapStateToProps, {
  InputImagesSet,
  InputFilesCameraSet,
  POSTImageLineRequest,
  GETListImagesRequest,
  ConsultManageImagesRequestTable,
})(DrawerAddComponent);
const DrawerAdd = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getManageImagesModule()]}>
      <ConnectedManageImages />
    </DynamicModuleLoader>
  );
};
export default DrawerAdd;
