import React from 'react';
import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
const tableColumns = [
  {
    key: 'phase',
    title: (
      <>
        <ErrorColumnTitle
          errorMessages={[
            {
              fase: 'Fase 1',
              message:
                'Registros para los cuales se omitió ingresar campos obligatorios, se ingresó un tipo de dato que no corresponde al solicitado en el campo o se excedió la longitud máxima.',
            },
            {
              fase: 'Fase 2',
              message:
                'Registros de metas de venta para los cuales se ingresó información de elementos con estatus "Inactivo", que no se encuentran registrados en el sistema, se ingresó información de estructura comercial que no está relacionada entre sí o con el resto de elementos.',
            },
            {
              fase: 'Fase 3',
              message: 'Registros que se encuentran repetidos dentro del archivo de carga masiva.',
            },
          ]}
        />
      </>
    ),
    dataIndex: 'phase',
  },
  {
    title: 'Territorio',
    dataIndex: 'territory',
  },
  {
    title: 'Zona',
    dataIndex: 'zone',
  },
  {
    title: 'AP',
    dataIndex: 'crop',
  },
  {
    title: 'Sold to',
    dataIndex: 'clientSoldTo',
  },
  {
    key: 'clientLine',
    title: 'Línea de negocio',
    dataIndex: 'clientLine',
  },
  {
    title: 'Cliente',
    dataIndex: 'clientName',
  },
  {
    title: 'Número',
    dataIndex: 'productNumber',
  },
  {
    title: 'Producto',
    dataIndex: 'productName',
  },

  {
    title: 'Mes',
    dataIndex: 'month',
  },
  {
    title: 'Meta en volumen (Kg/l)',
    dataIndex: 'volumeAmount',
    render: (value) =>
      `${Number(value).toLocaleString('en-US', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      })}`,
  },
  {
    title: 'Meta en valor (USD)',
    dataIndex: 'usdAmount',
    render: (value) =>
      `$${Number(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
  },
];
export default tableColumns;
