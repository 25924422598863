import {
  POST_ONE_DETAIL_PRICES_REQUEST,
  POST_ONE_DETAIL_PRICES_SUCCESS,
  POST_ONE_DETAIL_PRICES_CLEAN,
  GET_DETAIL_PRICES_REQUEST,
  GET_DETAIL_PRICES_SUCCESS,
  GET_DETAIL_PRICES_CLEAN,
} from '../actions';

function oneCase(state = {}, action) {
  const { type } = action;

  switch (type) {
    //GET_DETAIL_PRICES_REQUEST
    case GET_DETAIL_PRICES_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case GET_DETAIL_PRICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case GET_DETAIL_PRICES_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //POST_ONE_DETAIL_PRICES_REQUEST
    case POST_ONE_DETAIL_PRICES_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case POST_ONE_DETAIL_PRICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case POST_ONE_DETAIL_PRICES_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}

export default oneCase;
