export const REPORT_REQUEST = 'TRADE-AGREEMENTS/REPORT_REQUEST';
export const REPORT_SUCCESS = 'TRADE-AGREEMENTS/REPORT_SUCCESS';
export const REPORT_FAIL = 'TRADE-AGREEMENTS/REPORT_FAIL';

export function reportRequest(payload) {
  return {
    type: REPORT_REQUEST,
    payload,
  };
}

export function reportSuccess(payload) {
  return {
    type: REPORT_SUCCESS,
    payload,
  };
}

export function reportFail(payload) {
  return {
    type: REPORT_FAIL,
    payload,
  };
}
