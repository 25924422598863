import React from 'react';

import BSelect from 'common/components/BSelect/BSelect';

const PeriodType = (props) => {
  const { periodType, month, handleChange } = props;
  let type;
  switch (periodType) {
    case 4: //month
      type = 'Meses';

      break;
    case 3: //trimestrer
      type = 'Trimestres';

      break;
    case 2: //semester
      type = 'Semestres';

      break;
    case 1: //year
    default:
      return <></>;
  }

  return (
    <>
      <p>{type}</p>
      <BSelect
        mode={'multiple'}
        name="month"
        options={month}
        label={''}
        selectAll={true}
        onChange={(data) => handleChange(data, 'month', month)}
        maxTagCount={3}
        allowClear={false}
      />
    </>
  );
};

export default PeriodType;
