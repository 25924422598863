import React from 'react';
import { STYLES } from './input.module.styles';

function Input({
  label,
  tag,
  style,
  prefix,
  suffix,
  type,
  disabled,
  errorTag,
  value,
  readOnly,
  className,
  error_tag_style,
  ...restProps
}) {
  return (
    <div className={`${className}`}>
      <label htmlFor={tag} style={{ color: '#21A0D2' }}>
        {label}
      </label>
      <div style={STYLES.FIELDSET_STYLE}>
        {prefix && <span style={STYLES.PREFIX_STYLE}>{prefix}</span>}
        <input
          style={{ ...STYLES.INPUT_STYLE, ...style }}
          type={type}
          value={value}
          disabled={disabled || readOnly ? true : false}
          {...restProps}
        />
        {suffix && <span style={STYLES.SUFFIX_STYLE}>{suffix}</span>}
      </div>
      {errorTag && (
        <span style={{ ...STYLES.ERROR_TAG_STYLE, ...error_tag_style }}>{errorTag}</span>
      )}
    </div>
  );
}
export default Input;
