export const QUERY_SAVE = 'TRADE-AGREEMENTS/QUERY_SAVE';
export const QUERY_CLEAN = 'TRADE-AGREEMENTS/QUERY_CLEAN';

export function queryClean(payload) {
  return {
    type: QUERY_CLEAN,
    payload,
  };
}

export function querySave(payload) {
  return {
    type: QUERY_SAVE,
    payload,
  };
}

export const TOGGLE_TYPE_VALUE = 'TRADE-AGREEMENTS/TOGGLE_TYPE_VALUE';

export function toggleTypeValue(payload) {
  return {
    type: TOGGLE_TYPE_VALUE,
    payload: payload,
  };
}
