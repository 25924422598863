import { takeLatest, put, cancelled, call } from 'redux-saga/effects';
import instance from 'services/request';
import { responseOK } from 'SalesChallengeRefactor/utils';
import { PogSectionActions } from '../../actions';
import { requestSectionData } from '../../../services';

const {
  getPogSection,
  pogSectionFail,
  cleanPogSection,
  POG_SECTION_REQUEST,
  GET_POG_SECTION,
  POG_SECTION_FAIL,
  CLEAN_POG_SECTION,
  POG_SUBREGIONS_REQUEST,
  pogSubregionsSet,
  pogSubregionsClean,
} = PogSectionActions;

function* reqPogSectionData(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSectionData, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getPogSection(response.data ?? []));
    } else {
      yield put(pogSectionFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(pogSectionFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}
function* reqPogSubregionsData(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSectionData, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(pogSubregionsSet(response.data ?? []));
    } else {
      yield put(pogSubregionsClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(pogSubregionsClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaRequestPogSubregionsData() {
  yield takeLatest(POG_SUBREGIONS_REQUEST, reqPogSubregionsData);
}

export function* SagaRequestPogSectionData() {
  yield takeLatest(POG_SECTION_REQUEST, reqPogSectionData);
}

export function* SagaGetPogSectionData() {
  yield takeLatest(GET_POG_SECTION, getPogSection);
}

export function* SagaPogSectionDataFail() {
  yield takeLatest(POG_SECTION_FAIL, pogSectionFail);
}

export function* SagaSectionDataClean() {
  yield takeLatest(CLEAN_POG_SECTION, cleanPogSection);
}
