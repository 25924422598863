import React from 'react';
import { Row, Col, Input, Form } from 'antd';
import { BInput } from 'common/components';
const SectionShop = ({ statusSwitch }) => {
  return (
    <Row>
      <Col span={24}>
        <Form.Item name="introBuy" label="Introducción:">
          <Input.TextArea
            disabled={statusSwitch}
            placeholder="Escribe Introducción"
            maxLength={750}
            autoSize
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <BInput
          disabled={statusSwitch}
          placeholder="Escribe Subtítulo 1"
          maxLength={250}
          name="subt1"
          label="Subtítulo 1:"
        />
      </Col>
      <Col span={24}>
        <Form.Item name="noteBuy" label="Nota:">
          <Input.TextArea
            disabled={statusSwitch}
            placeholder="Escribe  Nota"
            maxLength={750}
            autoSize
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="objectExt" label="Objetivo extra:">
          <Input.TextArea
            disabled={statusSwitch}
            placeholder="Escribe Objetivo extra"
            maxLength={500}
            autoSize
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <BInput
          disabled={statusSwitch}
          placeholder="Escribe Subtítulo 2"
          maxLength={250}
          name="subt2"
          label="Subtítulo 2:"
        />
      </Col>
    </Row>
  );
};

export default SectionShop;
