/* eslint-disable no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col } from 'antd';

import { consultFiltersDateRangeSetValue } from 'RootModule/redux/actions/consultFilters';

import RangePicker from 'common/consults/components/RangePicker';
import moment from 'moment';

const DateRange = (props) => {
  const { consultFiltersDateRangeSetValue: setValue, value, floatToRight } = props;
  const momentFormat = 'YYYY-MM-DD';

  const colStyle = { marginRight: '10px', marginTop: '23px' };

  const mapDateMomentToString = (momentArray) => {
    if (!momentArray) {
      setValue([]);
      return;
    }

    let startDate = moment(momentArray[0]).format(momentFormat);
    let endDate = moment(momentArray[1]).format(momentFormat);

    setValue([startDate, endDate]);
  };
  const mapDateStringToMoment = () => {
    if (!value || value.length === 0) return [];

    let startDateMoment = moment(value[0], momentFormat);
    let endDateMoment = moment(value[1], momentFormat);

    return [startDateMoment, endDateMoment];
  };

  if (floatToRight) {
    colStyle.marginLeft = 'auto';
  }
  return (
    <Col style={colStyle}>
      <RangePicker name={'name'} value={mapDateStringToMoment()} onChange={mapDateMomentToString} />
    </Col>
  );
};

const mapStateToProps = (state) => {
  return {
    value: state?.APP?.consultFilters.dateRange?.value,
  };
};

DateRange.propTypes = {
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.array,
};

DateRange.defaultProps = {
  options: [],
};

export default connect(mapStateToProps, {
  consultFiltersDateRangeSetValue,
})(DateRange);
