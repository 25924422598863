import { takeLatest, put, cancelled, call } from 'redux-saga/effects';
import instance from 'services/request';
import {
  requestSubRegions,
  requestBusinessLines,
  requestYears,
  requestPeriodicity,
  requestIncentives,
  requestCreditNotes,
} from '../../services/services';
import { responseOK } from 'SalesChallengeRefactor/utils';
import * as mainActions from '../actions';
import { messageError } from 'utils/messages';

import checkIfNoElements from 'utils/checkIfConsultHasNoElements';

const {
  SUB_REGIONS_REQUEST,
  GET_SUB_REGIONS,
  BUSINESS_LINES_REQUEST,
  GET_BUSINESS_LINES,
  YEARS_REQUEST,
  GET_YEARS,
  PERIODICITY_REQUEST,
  GET_PERIODICITY,
  INCENTIVES_REQUEST,
  GET_INCENTIVES,
  CREDIT_NOTES_REQUEST,
  GET_CREDIT_NOTES,
  getSubRegions,
  getBusinessLines,
  getYears,
  getPeriodicity,
  getIncentives,
  getCreditNotes,
  subRegionsFail,
  BusinessLinesFail,
  yearsFail,
  periodicityFail,
  incentivesFail,
  cleanCreditNotes,
} = mainActions;

function* ReqSubRegions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSubRegions, {
      cancelToken: source.token,
      payload,
    });
    if (responseOK(response)) {
      yield put(getSubRegions(response.data ?? []));
    } else {
      yield put(subRegionsFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(subRegionsFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* ReqBusinessLines(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestBusinessLines, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getBusinessLines(response?.data ?? []));
    } else {
      yield put(BusinessLinesFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(BusinessLinesFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* ReqYears(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestYears, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getYears(response?.data ?? []));
    } else {
      yield put(yearsFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(yearsFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* ReqPeriodicity(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestPeriodicity, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getPeriodicity(response?.data ?? []));
    } else {
      yield put(periodicityFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(periodicityFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* ReqIncentives(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestIncentives, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getIncentives(response?.data ?? []));
    } else {
      yield put(incentivesFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(incentivesFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* ReqCreditNotes(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { history, params } = payload;
  let mustRedirectToHome = false;
  try {
    const response = yield call(requestCreditNotes, {
      cancelToken: source.token,
      data: payload,
    });
    if (responseOK(response)) {
      mustRedirectToHome = checkIfNoElements(params, response.data);
      yield put(getCreditNotes(response?.data ?? []));
    } else {
      yield put(cleanCreditNotes());
    }

    if (mustRedirectToHome === true) {
      yield call(messageError, 'No hay registros de Notas de Crédito en el sistema');
      setTimeout(() => {
        history.push('/account-status/base-information');
      }, 100);
    }
  } catch (e) {
    yield cleanCreditNotes();
  } finally {
    if (yield cancelled()) {
      yield cleanCreditNotes();
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaRequestSubRegions() {
  yield takeLatest(SUB_REGIONS_REQUEST, ReqSubRegions);
}

export function* SagaGetSubRegions() {
  yield takeLatest(GET_SUB_REGIONS, getSubRegions);
}

export function* SagaRequestBusinessLines() {
  yield takeLatest(BUSINESS_LINES_REQUEST, ReqBusinessLines);
}

export function* SagaGetBusinessLines() {
  yield takeLatest(GET_BUSINESS_LINES, getBusinessLines);
}

export function* SagaRequestYears() {
  yield takeLatest(YEARS_REQUEST, ReqYears);
}

export function* SagaGetYears() {
  yield takeLatest(GET_YEARS, getYears);
}

export function* SagaRequestPeriodicity() {
  yield takeLatest(PERIODICITY_REQUEST, ReqPeriodicity);
}

export function* SagaGetPeriodicity() {
  yield takeLatest(GET_PERIODICITY, getPeriodicity);
}

export function* SagaRequestIncentives() {
  yield takeLatest(INCENTIVES_REQUEST, ReqIncentives);
}

export function* SagaGetIncentives() {
  yield takeLatest(GET_INCENTIVES, getIncentives);
}

export function* SagaRequestCreditNotes() {
  yield takeLatest(CREDIT_NOTES_REQUEST, ReqCreditNotes);
}

export function* SagaGetCreditNotes() {
  yield takeLatest(GET_CREDIT_NOTES, getCreditNotes);
}
