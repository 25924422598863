export const SALESGOALS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD =
  'SALESGOALS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const SALESGOALS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'SALESGOALS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const SALESGOALS_MASSIVE_UPLOAD_REQUEST_VERIFY = 'SALESGOALS_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const SALESGOALS_MASSIVE_UPLOAD_SET_VERIFY = 'SALESGOALS_MASSIVE_UPLOAD_SET_VERIFY';
export const SALESGOALS_MASSIVE_UPLOAD_CLEAR_VERIFY = 'SALESGOALS_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const SALESGOALS_MASSIVE_UPLOAD_REQUEST_UPLOAD = 'SALESGOALS_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const SALESGOALS_MASSIVE_UPLOAD_SET_UPLOAD = 'SALESGOALS_MASSIVE_UPLOAD_SET_UPLOAD';
export const SALESGOALS_MASSIVE_UPLOAD_CLEAR_UPLOAD = 'SALESGOALS_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function salesGoalsMassiveUploadSetAllowUpload(payload) {
  return {
    type: SALESGOALS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function salesGoalsMassiveUploadClearAllowUpload(payload) {
  return {
    type: SALESGOALS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function salesGoalsMassiveUploadRequestVerify(payload) {
  return {
    type: SALESGOALS_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function salesGoalsMassiveUploadSetVerify(payload) {
  return {
    type: SALESGOALS_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function salesGoalsMassiveUploadClearVerify(payload) {
  return {
    type: SALESGOALS_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function salesGoalsMassiveUploadRequestUpload(payload) {
  return {
    type: SALESGOALS_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function salesGoalsMassiveUploadSetUpload(payload) {
  return {
    type: SALESGOALS_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function salesGoalsMassiveUploadClearUpload(payload) {
  return {
    type: SALESGOALS_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
