import request from 'services/request';

export async function SalesMassiveUpload({ cancelToken, data }) {
  let uri = `/Catalogs/Api/v1/sales/MassLoadExcel`;
  return request(uri, {
    method: 'POST',
    cancelToken,

    data,
  });
}
