import React from 'react';
import {
  // EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { MdModeEdit } from 'react-icons/md';
const COLOR = {
  BLUE: '#00499A',
  LIGHTBLUE: '#0078B3',

  GRAY: '#aaa',
  UGLY_BLUE: 'blue',
};

const CURSOR = {
  POINTER: 'pointer',
  NOT_ALLOWED: 'not-allowed',
};

export default function EditEntry({
  active,
  allow,

  columnActivation,
  cancelUpdate,
  proceedUpdate,
}) {
  const {
      GRAY,
      BLUE,
      LIGHTBLUE,

      UGLY_BLUE,
    } = COLOR,
    { POINTER, NOT_ALLOWED } = CURSOR;
  return (
    <div
      style={{
        minWidth: '3em',
        textAlign: 'center',
        fontSize: '1.5em',
      }}
    >
      {active ? (
        <>
          <CloseCircleOutlined style={{ color: LIGHTBLUE }} onClick={cancelUpdate} />
          &nbsp;
          <CheckCircleOutlined
            style={allow ? { color: BLUE, cursor: POINTER } : { color: GRAY, cursor: NOT_ALLOWED }}
            onClick={() => {
              if (allow) {
                proceedUpdate();
              }
            }}
          />
        </>
      ) : (
        <MdModeEdit style={{ color: UGLY_BLUE }} onClick={columnActivation} />
      )}
    </div>
  );
}
