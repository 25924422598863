import { SET_PERSISTENT_TERRITORY, SET_PERSISTENT_LINE } from '../actions/persistentValues';

// make reducer for persistent values
export default function persistentValues(state = {}, action) {
  switch (action.type) {
    case SET_PERSISTENT_TERRITORY:
      return {
        ...state,
        territory: action.payload,
      };
    case SET_PERSISTENT_LINE:
      return {
        ...state,
        line: action.payload,
      };
    default:
      return state;
  }
}
