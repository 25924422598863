export const PRICES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD = 'PRICES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const PRICES_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD = 'PRICES_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const PRICES_MASSIVE_UPLOAD_REQUEST_VERIFY = 'PRICES_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const PRICES_MASSIVE_UPLOAD_SET_VERIFY = 'PRICES_MASSIVE_UPLOAD_SET_VERIFY';
export const PRICES_MASSIVE_UPLOAD_CLEAR_VERIFY = 'PRICES_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const PRICES_MASSIVE_UPLOAD_REQUEST_UPLOAD = 'PRICES_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const PRICES_MASSIVE_UPLOAD_SET_UPLOAD = 'PRICES_MASSIVE_UPLOAD_SET_UPLOAD';
export const PRICES_MASSIVE_UPLOAD_CLEAR_UPLOAD = 'PRICES_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function pricesMassiveUploadSetAllowUpload(payload) {
  return {
    type: PRICES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function pricesMassiveUploadClearAllowUpload(payload) {
  return {
    type: PRICES_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function pricesMassiveUploadRequestVerify(payload) {
  return {
    type: PRICES_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function pricesMassiveUploadSetVerify(payload) {
  return {
    type: PRICES_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function pricesMassiveUploadClearVerify(payload) {
  return {
    type: PRICES_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function pricesMassiveUploadRequestUpload(payload) {
  return {
    type: PRICES_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function pricesMassiveUploadSetUpload(payload) {
  return {
    type: PRICES_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function pricesMassiveUploadClearUpload(payload) {
  return {
    type: PRICES_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
