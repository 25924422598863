import React from 'react';
import { Col, Row } from 'antd';
import TopFilterToRegister from '../TopFiltersGroupRegister';
import ColumnLeft from './ColumnLeft';
import MainTable from './MainTable';
import { twoColumns } from 'models/layout';
import IndicatorsSection from './IndicatorsSection';
import FooterActions from './FooterActions';
import { getProductsRequest } from 'SalesChallengeRefactor/redux/actions/registerSale';
import { connect } from 'react-redux';
import { deleteProductRequest } from 'SalesChallengeRefactor/redux/actions/deleteProduct';
const { leftColumn, rightColumn } = twoColumns;

function LayoutSalesChallengeRegister(props) {
  const { onChangeActiveComponent, pageType, product } = props;
  return (
    <>
      <Row gutter={{ xs: 8, sm: 10, md: 16, lg: 18 }}>
        <Col {...{ sm: 24, xs: 24, lg: 14, md: 24 }}>
          <TopFilterToRegister
            onChangeActiveComponent={onChangeActiveComponent}
            pageType={pageType}
          />
        </Col>

        <Col {...{ sm: 24, xs: 24, lg: 10, md: 24 }}>
          <IndicatorsSection pageType={pageType} />
        </Col>
      </Row>

      <>
        <Row gutter={{ xs: 8, sm: 10, md: 16, lg: 18 }}>
          <Col {...leftColumn}>{product && <ColumnLeft />}</Col>
          <Col {...rightColumn}>{product && <MainTable />}</Col>
        </Row>
        <Row justify="end" style={{ marginTop: '2rem' }}>
          <Col {...{ md: 12, sm: 16, xs: 24 }}>
            {product && <FooterActions pageType={pageType} />}
          </Col>
        </Row>
      </>
    </>
  );
}

function mapStateToProps(state) {
  return {
    product: state?.SALES_CHALLENGE?.query?.product ?? false,
  };
}

export default connect(mapStateToProps, { getProductsRequest, deleteProductRequest })(
  LayoutSalesChallengeRegister
);
