import {
  CONSULT_INFO_BASE_REQUEST,
  CONSULT_INFO_BASE_SET,
  CONSULT_INFO_BASE_CLEAN,
  SEND_ACCOUNT_STATEMENT_REQUEST,
  SEND_ACCOUNT_STATEMENT_SET,
  SEND_ACCOUNT_STATEMENT_CLEAN,
  CHECK_EXISTENCES_STATEMENT_REQUEST,
  CHECK_EXISTENCES_STATEMENT_SET,
  CHECK_EXISTENCES_STATEMENT_CLEAN,
  REPORT_REQUEST,
  REPORT_SET,
  REPORT_CLEAN,
} from '../actions';

function accountStatusDynamicElementsReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case REPORT_REQUEST:
      return {
        ...state,
        dataReport: action.payload,
      };
    case REPORT_SET:
      return {
        ...state,
        dataReport: action.payload,
      };

    case REPORT_CLEAN:
      return {
        ...state,
        dataReport: null,
      };
    case SEND_ACCOUNT_STATEMENT_REQUEST:
      return {
        ...state,
        dataAccount: action.payload,
      };
    case SEND_ACCOUNT_STATEMENT_SET:
      return {
        ...state,
        dataAccount: action.payload,
      };

    case SEND_ACCOUNT_STATEMENT_CLEAN:
      return {
        ...state,
        dataAccount: [],
      };

    case CONSULT_INFO_BASE_REQUEST:
      return {
        ...state,
        isLoading: true,
        consultTable: null,
      };

    case CONSULT_INFO_BASE_SET:
      return {
        ...state,
        isLoading: false,
        consultTable: action.payload,
      };

    case CONSULT_INFO_BASE_CLEAN:
      return {
        ...state,
        isLoading: false,
        consultTable: null,
      };
    case CHECK_EXISTENCES_STATEMENT_REQUEST:
      return {
        ...state,
        accountStatementExistences: null,
        showExistencesMessages: false,
      };
    case CHECK_EXISTENCES_STATEMENT_SET:
      return {
        ...state,
        accountStatementExistences: action.payload,
        showExistencesMessages: true,
      };
    case CHECK_EXISTENCES_STATEMENT_CLEAN:
      return {
        ...state,
        accountStatementExistences: null,
        showExistencesMessages: false,
      };
    default:
      return state;
  }
}

export default accountStatusDynamicElementsReducer;
