import React, { useState, useContext, useEffect } from 'react';
import { appContext } from 'context';

export default function RenderPermissions({ permission, components }) {
  const { modules } = useContext(appContext);
  const [access, setaccess] = useState(false);

  useEffect(() => {
    if (modules.includes(permission)) {
      setaccess(true);
    } else {
      setaccess(false);
    }
  }, [modules, permission]);

  return <>{access && components}</>;
}
