import reducer from './redux-saga/reducers';

import {
  watchConsultInfoBaseTable,
  watchSendAccountStatus,
  watchConsultReport,
  watchCheckExistences,
} from './redux-saga/sagas';

export default function getConsultInfoBaseModule() {
  return {
    id: 'CONSULT_INFO_BASE',
    reducerMap: {
      CONSULT_INFO_BASE: reducer,
    },
    sagas: [
      watchConsultInfoBaseTable,
      watchSendAccountStatus,
      watchConsultReport,
      watchCheckExistences,
    ],
  };
}
