import { DAYS_LEFT_REQUEST, DAYS_LEFT_SUCCESS, DAYS_LEFT_FAILURE } from '../actions/daysLeft';

const defaultState = {
  total: 0,
  loading: false,
};

const daysLeft = (state = defaultState, action) => {
  switch (action.type) {
    case DAYS_LEFT_REQUEST:
      return {
        ...state,
        total: 0,
        loading: true,
      };
    case DAYS_LEFT_SUCCESS:
      return {
        ...state,
        total: action.payload,
        loading: false,
      };
    case DAYS_LEFT_FAILURE:
      return {
        ...state,
        total: 0,
        loading: false,
      };
    default:
      return state;
  }
};

export default daysLeft;
