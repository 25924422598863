import { takeLatest, put, cancelled, call } from 'redux-saga/effects';
import {
  GET_SYSTEM_USER_LOG,
  getSystemUserLog,
  GET_LOG_FAIL,
  LOG_REQUEST,
  getLogFail,
  LOG_ENTITY_REQUEST,
  getLogEntity,
  GET_LOG_ENTITY,
  LOG_TYPE_REQUEST,
  GET_LOG_TYPES,
  getLogTypes,
  LOG_USERS_REQUEST,
  GET_LOG_USERS,
  getLogUsers,
  getLogsPages,
  getLogTotal,
} from '../actions';
import instance from 'services/request';
import {
  getLogs,
  requestLogEntities,
  requestLogTypes,
  requestLogUsers,
} from '../../services/apiCalls';
import { responseOK } from 'SalesChallengeRefactor/utils';

function* getReadLog(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(getLogs, {
      cancelToken: source.token,
      data: payload,
    });
    if (responseOK(response)) {
      yield put(getSystemUserLog(response?.data?.items ?? []));
      yield put(getLogsPages(response?.data?.pages ?? ''));
      yield put(getLogTotal(response?.data?.totalRows ?? ''));
    } else {
      yield put(getLogFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(getLogFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* reqLogEntities(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestLogEntities, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getLogEntity(response?.data ?? []));
    } else {
      yield put(getLogFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(getLogFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* reqLogTypes(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestLogTypes, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getLogTypes(response?.data ?? []));
    } else {
      yield put(getLogFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(getLogFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* reqLogUsers(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestLogUsers, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getLogUsers(response?.data ?? []));
    } else {
      yield put(getLogFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(getLogFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaConsultLog() {
  yield takeLatest(LOG_REQUEST, getReadLog);
}

export function* SagaGetLog() {
  yield takeLatest(GET_SYSTEM_USER_LOG, getSystemUserLog);
}

export function* SagaRequestLogEntities() {
  yield takeLatest(LOG_ENTITY_REQUEST, reqLogEntities);
}

export function* SagaGetLogEntities() {
  yield takeLatest(GET_LOG_ENTITY, getLogEntity);
}

export function* SagaRequestLogTypes() {
  yield takeLatest(LOG_TYPE_REQUEST, reqLogTypes);
}

export function* SagaGetLogTypes() {
  yield takeLatest(GET_LOG_TYPES, getLogTypes);
}

export function* SagaErrorMessage() {
  yield takeLatest(GET_LOG_FAIL, getLogFail);
}

export function* SagaRequestLogUsers() {
  yield takeLatest(LOG_USERS_REQUEST, reqLogUsers);
}

export function* SagaGetLogUsers() {
  yield takeLatest(GET_LOG_USERS, getLogUsers);
}
