import request from 'services/request';
const serviceURL = '/Catalogs/Api/v1/Clients/Detail/';
const clientID = '/AccountStatement/Api/v1/Catalogs/GetClientList';
const catalogURI = '/Catalogs/Api/v1/FixedCatalogs/';
const registerURI = '/AccountStatement/Api/v1/Statements/';
const urlIncentivePeriod = '/AccountStatement/Api/v1/Catalogs/GetSalesIncentivesList';
const validateClientUrl = '/AccountStatement/Api/v1/Statements/CheckExistence';

//valida client
export async function validateClient({ cancelToken, soldTo, lineId }) {
  return request(`${validateClientUrl}/${soldTo}/${lineId}`, {
    method: 'GET',
    cancelToken,
  });
}

//get data incentive period
export async function getIncentivePeriod({ cancelToken }) {
  return request(`${urlIncentivePeriod}`, {
    method: 'GET',
    cancelToken,
  });
}
//get id cliente
export async function getClient({ cancelToken }) {
  return request(`${clientID}`, {
    method: 'GET',
    cancelToken,
  });
}
export async function getOneClient({ cancelToken, clientId }) {
  return request(`${serviceURL}${clientId}`, {
    method: 'GET',
    cancelToken,
  });
}

export async function getTerritoriesClient({ cancelToken, params }) {
  return request(`${catalogURI}Territories`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
export async function getLinesClient({ cancelToken, params }) {
  return request(`${catalogURI}BusinessLines`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
//get data clients
export async function getOptionsClient({ cancelToken, name }) {
  return request(`${catalogURI}${name}`, {
    method: 'GET',
    cancelToken,
  });
}
//Register DynamicElements
export async function RegisterDynamicElements({ cancelToken, data }) {
  return request(registerURI, {
    method: 'POST',
    cancelToken,
    data,
  });
}
