import { SET_CURRENT_VIEW, CLEAN_VIEWS } from '../actions/views';
import {
  CLEAN_INDICATORS,
  INDICATORS_REQUEST,
  INDICATORS_SUCCESS,
  INDICATORS_FAIL,
  INDICATORS_SET,
} from '../actions/indicators';
import { CLEAN_INFO, INFO_REQUEST, INFO_SUCCESS, INFO_FAIL } from '../actions/info';

const filters = (state = {}, action) => {
  switch (action.type) {
    case SET_CURRENT_VIEW:
      return {
        ...state,
        current: action.payload,
      };

    case CLEAN_INDICATORS:
    case CLEAN_INFO:
      return {
        ...state,
        current: undefined,
        indicators: undefined,
        info: undefined,
      };

    case INDICATORS_REQUEST:
      return {
        ...state,
        indicators: {
          isLoading: true,
          data: undefined,
        },
      };

    case INDICATORS_SUCCESS:
      return {
        ...state,
        indicators: {
          isLoading: false,
          data: action.payload,
        },
      };

    case INDICATORS_FAIL:
      return {
        ...state,
        indicators: {
          isLoading: false,
          data: undefined,
        },
      };

    case INFO_REQUEST:
      return {
        ...state,
        info: {
          isLoading: true,
          data: undefined,
        },
      };

    case INFO_SUCCESS:
      return {
        ...state,
        info: {
          isLoading: false,
          data: action.payload,
        },
      };

    case INFO_FAIL:
      return {
        ...state,
        info: {
          isLoading: false,
          data: undefined,
        },
      };

    case INDICATORS_SET: {
      return {
        ...state,
        indicators: {
          ...state.indicators,
          ...action.payload,
        },
      };
    }

    case CLEAN_VIEWS:
      return {};
    default:
      return state;
  }
};

export default filters;
