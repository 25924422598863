import request from 'services/request';
const serviceURL = '/Users/Api/v1/Notifications';

export async function getOneNotifications({ cancelToken }) {
  return request(`${serviceURL}?Read=false&PageNum=1&PageSize=5`, {
    method: 'GET',
    cancelToken,
  });
}

//False
export async function getOneNotificationsFalse({ cancelToken, page }) {
  return request(`${serviceURL}?Read=false&PageNum=${page}&PageSize=5`, {
    method: 'GET',
    cancelToken,
  });
}

//True
export async function getOneNotificationsTrue({ cancelToken, page }) {
  return request(`${serviceURL}?Read=true&PageNum=${page}&PageSize=5`, {
    method: 'GET',
    cancelToken,
  });
}

export async function putAllExchangeRead({ data }) {
  return request(serviceURL, {
    method: 'PUT',
    data,
  });
}
