import React from 'react';
import { BInput, FormTitleDivider, BInputNumber } from 'common';
import { Row, Col } from 'antd';
import { NumberInput } from '.';

const ProductInformation = ({ gutterValue, onFocusNumber, onBlurNumber, numberHandleChange }) => {
  const onFocusNum =
    typeof onFocusNumber === 'function'
      ? onFocusNumber
      : () => {
          return;
        };
  const onBlurNum =
    typeof onBlurNumber === 'function'
      ? onBlurNumber
      : () => {
          return;
        };
  const numHandleChange =
    typeof numberHandleChange === 'function'
      ? numberHandleChange
      : () => {
          return;
        };
  return (
    <>
      <FormTitleDivider title={'Información de Producto'} />
      <Row gutter={gutterValue}>
        <Col xs={24} md={6}>
          <BInputNumber
            label="Número"
            name="productNumber"
            max={9999999999}
            min={0}
            isRequired={true}
            disabled={false}
            onFocus={onFocusNum}
            onBlur={onBlurNum}
            onChange={numHandleChange}
          />
        </Col>
        <Col xs={24} md={12}>
          <BInput label="Nombre" name="name" isRequired disabled={false} maxLength={100} />
        </Col>
        <Col xs={24} md={6}>
          <BInput label="Familia" name="family" isRequired disabled={false} maxLength={100} />
        </Col>
        <Col xs={24} md={6}>
          <BInput
            label="Clasificación"
            name="clasification"
            isRequired
            disabled={false}
            maxLength={100}
          />
        </Col>
        <Col xs={24} md={6}>
          <BInput
            label="Presentación"
            name="presentation"
            isRequired
            disabled={false}
            maxLength={100}
          />
        </Col>
        <Col xs={24} md={6}>
          <BInput
            label="Unidad de medida"
            name="measurementUnit"
            isRequired
            disabled={false}
            maxLength={20}
          />
        </Col>
        <Col xs={24} md={6}>
          <BInput
            label="Unidades por empaque"
            name="unitsPerPack"
            isRequired
            disabled={false}
            maxLength={20}
          />
        </Col>
        <Col xs={24} md={6}>
          <NumberInput
            label="Pedido mínimo requerido"
            name="minimumOrderRequired"
            max={9999999999.999}
            min={0}
            step="0.001"
            isRequired={true}
          />

          {/* <BInput
            step="0.001"
            type="number"
            label="Pedido mínimo requerido"
            name="minimumOrderRequired"
            isRequired
            min={0}
            onFocus={onFocusNum}
            onBlur={onBlurNum}
          /> */}
        </Col>
        <Col xs={24} md={6}>
          <NumberInput
            step="0.001"
            label="MOQ"
            name="moq"
            max={9999999999.999}
            min={0}
            isRequired={true}
          />

          {/* <BInput
            step="0.001"
            type="number"
            label="MOQ"
            name="moq"
            isRequired
            min={0}
            onFocus={onFocusNum}
            onBlur={onBlurNum}
          /> */}
        </Col>
      </Row>
    </>
  );
};

export default ProductInformation;
