import React, { useContext, useEffect, useState } from 'react';
import { ContextDashboards } from '../ContextDashboards';
import { layoutContext } from 'context';
import '../SalesChallenge/style.css';
import { nameFiles, tableRef } from '../Modal/modal';
import { ReportTableAgreementComponent } from './Components/ReportTableAgreementComponent';
import StatusTableComponent from './Components/StatusTableComponent';
import {
  getCountComercialAgreement,
  getReportResumeComercialAgreement,
} from './Services/ComercialAgrementDashService';
import { getExistance } from '../../services';
import { messageError } from '../../utils/messages';
import { ErrorMessages } from './Components/Messages';
import { useHistory } from 'react-router-dom';

export const CommercialAgreementComponent = () => {
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [loading, setLoading] = useState(true);
  const [processYearL, setProcessYear] = useState(2021);
  const [filters, setFilters] = useState({});
  const [querySOriginal, setQuerySOriginal] = useState('');
  const [queryS, setQueryS] = useState('');
  const [columns, setColumns] = useState([]);
  const [reportTable, setReportTable] = useState([]);
  const [statusTable, setStatusTable] = useState([]);
  const history = useHistory();

  const cleanQuery = (query) => {
    if (query?.queryString?.length >= 1 && query.queryString.includes('challengeData')) {
      query.queryString.replace('?challengeData=1', '');
      query.queryString.replace('?challengeData=2', '');
      query.queryString.replace('challengeData=1', '');
      query.queryString.replace('challengeData=2', '');
      return query;
    } else {
      return query;
    }
  };

  const getExistances = () => {
    const messages = {
      0: (dataResponse) => {
        if (dataResponse === false) {
          messageError(ErrorMessages['MSG-124']);
        }
      },
      1: (dataResponse) => {
        if (dataResponse === false) {
          messageError(ErrorMessages['MSG-125']);
        }
      },
      2: (dataResponse) => {
        if (dataResponse === false) {
          messageError(ErrorMessages['MSG-128']);
        }
      },
    };
    Promise.all([
      getExistance('SalesExists'),
      getExistance('ChallengeExists'),
      getExistance('AgreementExists'),
    ]).then((r) => {
      r.forEach((response, index) => {
        messages[index.toString()](response);
        if (response === false) {
          history.push('/dashboards');
        }
      });
    });
  };

  const changeFilters = async ({ queryString, challengeData, currentFilters }) => {
    setLoading(true);
    setReportTable([]);
    setStatusTable([]);
    setFilters({});
    setQuerySOriginal(queryString);
    setQueryS(cleanQuery(queryString));
    const { processYear, rows } = await getReportResumeComercialAgreement(queryString);
    setProcessYear(processYear);
    setReportTable(rows);
    setFilters(currentFilters);
    setStatusTable(await getCountComercialAgreement(queryString));

    let termination = challengeData === '1' ? 'Amount' : 'Volume';
    setColumns([
      {
        title: currentFilters.View === '1' ? 'Cliente' : 'Estructura Comercial',
        dataIndex: 'structure',
        key: 'structure',
      },
      {
        title: 'Acuerdo comercial ',
        dataIndex: 'agreement' + termination,
        key: 'agreement' + termination,
      },
      {
        title: 'Desafío de ventas ',
        dataIndex: 'salesChallenge' + termination,
        key: 'salesChallenge' + termination,
      },
      {
        title: 'Acuerdo vs Desafío',
        dataIndex:
          termination === 'Amount'
            ? `agreementSalesChall${termination}`
            : 'agreeementSalesChallVolume',
        key:
          termination === 'Amount'
            ? `agreementSalesChall${termination}`
            : 'agreeementSalesChallVolume',
      },
      {
        title: '%',
        dataIndex: `percAgreeementSalesChall${termination}`,
        key: `percAgreeementSalesChall${termination}`,
      },
      {
        title: 'Ventas Históricas ',
        dataIndex: 'sales' + termination,
        key: 'sales' + termination,
      },
      {
        title: 'Acuerdo vs Ventas Históricas',
        dataIndex: termination === 'Amount' ? 'agreeementSalesAmount' : 'agreementSalesVolume',
        key: termination === 'Amount' ? 'agreeementSalesAmount' : 'agreementSalesVolume',
      },
      {
        title: '%',
        dataIndex: 'percAgreeementSales' + termination,
        key: 'percAgreeementSales' + termination,
      },
    ]);
    setLoading(false);
  };

  useEffect(() => {
    getExistances();
    onChangeActiveComponent(ContextDashboards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ReportTableAgreementComponent
        loading={loading}
        processYear={processYearL}
        tableId={tableRef.agreementReportTable}
        changeFiltersFn={changeFilters}
        dataSource={reportTable}
        query={queryS}
        queryFormPDF={querySOriginal}
        filters={filters}
        fileName={nameFiles.commercialAgreement}
        columns={columns}
      />
      <StatusTableComponent
        tableId={tableRef.agreementStatusTAble}
        reportsLenght={reportTable.length}
        loading={loading}
        query={querySOriginal}
        dataSource={statusTable}
        fileName={nameFiles.commercialAgreement}
        tableTitle={'Estatus de acuerdo comercial'}
      />
    </>
  );
};
