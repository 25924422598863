function ManageImagesReducer(state = {}, action) {
  const { type, payload } = action;

  const REDUCER = {
    // Array imagenes Base64
    INPUT_IMAGES_SET: {
      inputImages: payload,
    },
    // POST Image Line
    POST_IMAGE_LINE_REQUEST: {
      isLoading: true,
    },
    POST_IMAGE_LINE_SET: {
      POSTImageLine: payload,
    },
    POST_IMAGE_LINE_CLEAN: {},
  };

  return { ...state, isLoading: false, ...REDUCER[type] };
}

export default ManageImagesReducer;
