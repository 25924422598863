import request from 'services/request';
// import serviceMakerFunction from 'services/serviceMakerFunction';
import { paramsSerializer } from 'RootModule/services/catalogs';

const soldToUrl = '/Dashboards/Api/v1/Catalogs/ClientsSoldTo';
const productsPolicyUrl = '/Catalogs/Api/v1/FixedCatalogs/ProductPolicy';
const productAvailabilityUrl = '/Catalogs/Api/v1/FixedCatalogs/ProductAvailability';
const productFamilyUlr = '/Catalogs/Api/v1/Products/ByFamily';
const productListUrl = '/Catalogs/Api/v1/Products/AllProductNumber';

//get clients By Sold To
export async function getClientSoldTo({ cancelToken, params = {} }) {
  return request(soldToUrl, {
    method: 'GET',
    cancelToken,
    params,
    paramsSerializer,
  });
}

export async function getProductsPolicy({ cancelToken }) {
  return request(productsPolicyUrl, {
    method: 'GET',
    cancelToken,
  });
}

export async function getProductsAvailability({ cancelToken }) {
  return request(productAvailabilityUrl, {
    method: 'GET',
    cancelToken,
  });
}

export async function getProductFamily({ cancelToken }) {
  return request(productFamilyUlr, {
    method: 'GET',
    cancelToken,
  });
}

export async function getProductList({ cancelToken, params }) {
  return request(productListUrl, {
    method: 'GET',
    cancelToken,
    params,
    paramsSerializer,
  });
}
