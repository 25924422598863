/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import { useHistory } from 'react-router-dom';

import { DateRange } from 'RootModule/consultFilters/';

import ConsultTable from 'common/consults/components/Table';
import FilterWrapper from 'common/consults/components/FilterWrapper';

import ResetButton from 'common/consults/components/ResetButton';

import getProcessDatesConsultModule from '../getProcessDatesConsultModule';

import {
  processDatesConsultRequestConsult,
  processDatesConsultSetDateId,
  processDatesConsultSetStartDate,
  processDatesConsultSetEndDate,
  processDatesConsultRequestUpdate,
} from '../redux-saga/actions';
import { consultFiltersDateRangeClearValue } from 'RootModule/redux/actions/consultFilters';
import getConsultTableSchema from '../schemas/getConsultTableSchema';

import { layoutContext } from 'context';
import { checkIfAllowUpdateByDate, checkIfSendMessageByDate } from '../utils';
import { message094 } from 'utils/messages';

export const PROCESS_DATES_FORMAT = 'DD/MM/YYYY';
// import RenderPermissions from 'common/components/RenderPermissions/RenderPermissions';

const ConsultAndUpdateProcessDates = (props) => {
  const {
    processDatesConsultRequestConsult: requestConsult,
    processDatesConsultSetDateId: setDateId,
    processDatesConsultSetStartDate: setStartDate,
    processDatesConsultSetEndDate: setEndDate,
    processDatesConsultRequestUpdate: requestUpdate,
    consult,
    dateId,
    startDate,
    endDate,
    update,

    consultFiltersDateRangeClearValue: clearDateRange,

    isLoading,

    DateRangeValue,
  } = props;

  const { onChangeActiveComponent } = useContext(layoutContext);
  const history = useHistory();
  const columnActivation = (idToActivate, startProcessDate, endProcessDate) => {
    setStartDate(startProcessDate);
    setEndDate(endProcessDate);
    setDateId(idToActivate);
  };
  const cancelUpdate = () => {
    setDateId(-1);
  };
  const proceedUpdate = () => {
    requestUpdate({
      processId: dateId,
      startDate,
      endDate,
    });
    setDateId(-1);
  };
  const startDateChange = (momentDate, startDateString) => {
    if (checkIfSendMessageByDate(startDateString, endDate)) {
      message094();
    }
    setStartDate(startDateString);
  };
  const endDateChange = (momentDate, endDateString) => {
    if (checkIfSendMessageByDate(startDate, endDateString)) {
      message094();
    }

    setEndDate(endDateString);
  };

  const columns = getConsultTableSchema({
    processDatesList: consult,
    dateId,
    columnActivation,
    cancelUpdate,
    proceedUpdate,
    startDateChange,
    endDateChange,
    startDate,
    endDate,
    allowUpdate: checkIfAllowUpdateByDate(endDate, startDate),
  });

  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, []);

  useEffect(() => {
    let consultTableParams = {};

    onChangeActiveComponent('ProcessDatesConsultAndUpdate');

    if (DateRangeValue?.length > 0) {
      let filterStartDate = DateRangeValue[0];
      let filterEndDate = DateRangeValue[1];
      consultTableParams.StartDate = filterStartDate;
      consultTableParams.EndDate = filterEndDate;
    }

    // Wildcard: '',

    requestConsult({ params: consultTableParams, history });
  }, [DateRangeValue, update]);

  const clearFilters = () => {
    clearDateRange();
  };

  return (
    <div>
      <FilterWrapper>
        <DateRange floatToRight />

        <ResetButton clearFilters={clearFilters} />
      </FilterWrapper>
      <ConsultTable
        data={consult}
        columns={columns}
        loading={isLoading}
        pagination={{ pageSize: 20, hideOnSinglePage: true }}
        noResultsMessage={
          'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados.'
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    consult: state?.PROCESS_DATES_CONSULT?.consult,
    dateId: state?.PROCESS_DATES_CONSULT?.dateId ?? -1,
    startDate: state?.PROCESS_DATES_CONSULT?.startDate,
    endDate: state?.PROCESS_DATES_CONSULT?.endDate,
    update: state?.PROCESS_DATES_CONSULT?.update,
    isLoading: state?.PROCESS_DATES_CONSULT?.isLoading,

    DateRangeValue: state?.APP?.consultFilters?.dateRange?.value,
  };
};

const ConnectedConsultAndUpdateProcessDates = connect(mapStateToProps, {
  processDatesConsultRequestConsult,
  processDatesConsultSetDateId,
  processDatesConsultSetStartDate,
  processDatesConsultSetEndDate,
  processDatesConsultRequestUpdate,
  consultFiltersDateRangeClearValue,
})(ConsultAndUpdateProcessDates);

const ConsultAndUpdateProcessDatesWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getProcessDatesConsultModule()]}>
      <ConnectedConsultAndUpdateProcessDates />
    </DynamicModuleLoader>
  );
};
export default ConsultAndUpdateProcessDatesWithModules;
