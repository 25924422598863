export default async function fileTo64String(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const indexOfComma = reader.result.indexOf(',');
      const base64String = reader.result.substring(indexOfComma + 1);
      return resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
}
