export const POG_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD = 'POG_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const POG_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD = 'POG_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const POG_MASSIVE_UPLOAD_REQUEST_VERIFY = 'POG_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const POG_MASSIVE_UPLOAD_SET_VERIFY = 'POG_MASSIVE_UPLOAD_SET_VERIFY';
export const POG_MASSIVE_UPLOAD_CLEAR_VERIFY = 'POG_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const POG_MASSIVE_UPLOAD_REQUEST_UPLOAD = 'POG_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const POG_MASSIVE_UPLOAD_SET_UPLOAD = 'POG_MASSIVE_UPLOAD_SET_UPLOAD';
export const POG_MASSIVE_UPLOAD_CLEAR_UPLOAD = 'POG_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function POGMassiveUploadSetAllowUpload(payload) {
  return {
    type: POG_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function POGMassiveUploadClearAllowUpload(payload) {
  return {
    type: POG_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function POGMassiveUploadRequestVerify(payload) {
  return {
    type: POG_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function POGMassiveUploadSetVerify(payload) {
  return {
    type: POG_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function POGMassiveUploadClearVerify(payload) {
  return {
    type: POG_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function POGMassiveUploadRequestUpload(payload) {
  return {
    type: POG_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function POGMassiveUploadSetUpload(payload) {
  return {
    type: POG_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function POGMassiveUploadClearUpload(payload) {
  return {
    type: POG_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
