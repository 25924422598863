export const UPLOAD_TRADE_REQUEST = 'UPLOAD_AGREEMENT_REQUEST/TRADE';
export const UPLOAD_TRADE_SUCCESS = 'UPLOAD_AGREEMENT_SUCCESS/TRADE';
export const UPLOAD_TRADE_FAIL = 'UPLOAD_AGREEMENT_FAIL/TRADE';

export const GET_FILES_REQUEST = 'GET_FILES_REQUEST/TRADE';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS/TRADE';
export const GET_FILES_FAIL = 'GET_FILES_SUCCESS/TRADE';

export const REMOVE_FILE_REQUEST = 'REMOVE_FILE_REQUEST';
export const REMOVE_FILE_SUCCESS = 'REMOVE_FILE_SUCCESS';
export const REMOVE_FILE_FAIL = 'REMOVE_FILE_FAIL';

export const DOWNLOAD_FILE_REQUEST = 'DOWNLOAD_FILE_REQUEST';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAIL = 'DOWNLOAD_FILE_FAIL';

export const SEND_TRADE_AGREEMENT_REQUEST = 'SEND_TRADE_AGREEMENT_REQUEST';
export const SEND_TRADE_AGREEMENT_SUCCESS = 'SEND_TRADE_AGREEMENT_SUCCESS';
export const SEND_TRADE_AGREEMENT_FAIL = 'SEND_TRADE_AGREEMENT_FAIL';

export function sendTradeRequest(payload) {
  return {
    type: SEND_TRADE_AGREEMENT_REQUEST,
    payload,
  };
}

export function sendTradeSuccess(payload) {
  return {
    type: SEND_TRADE_AGREEMENT_SUCCESS,
    payload,
  };
}

export function sendTradeFail(payload) {
  return {
    type: SEND_TRADE_AGREEMENT_FAIL,
    payload,
  };
}

export function uploadTradeRequest(payload) {
  return {
    type: UPLOAD_TRADE_REQUEST,
    payload,
  };
}

export function uploadTradeSuccess(payload) {
  return {
    type: UPLOAD_TRADE_SUCCESS,
    payload,
  };
}

export function uploadTradeFail(payload) {
  return {
    type: UPLOAD_TRADE_FAIL,
    payload,
  };
}

export function getFilesRequest(payload) {
  return {
    type: GET_FILES_REQUEST,
    payload,
  };
}

export function getFilesSuccess(payload) {
  return {
    type: GET_FILES_SUCCESS,
    payload,
  };
}

export function getFilesFail(payload) {
  return {
    type: GET_FILES_FAIL,
    payload,
  };
}

export function removeFileRequest(payload) {
  return {
    type: REMOVE_FILE_REQUEST,
    payload,
  };
}

export function removeFileSuccess(payload) {
  return {
    type: REMOVE_FILE_SUCCESS,
    payload,
  };
}

export function removeFileFail(payload) {
  return {
    type: REMOVE_FILE_FAIL,
    payload,
  };
}

export function downloadFileRequest(payload) {
  return {
    type: DOWNLOAD_FILE_REQUEST,
    payload,
  };
}

export function downloadFileSuccess(payload) {
  return {
    type: DOWNLOAD_FILE_SUCCESS,
    payload,
  };
}

export function downloadFileFail(payload) {
  return {
    type: DOWNLOAD_FILE_FAIL,
    payload,
  };
}
