import reducer from './redux-saga/reducers';

import {
  watchPOGMassiveUploadSetAllowUpload,
  watchPOGMassiveUploadRequestVerify,
  watchPOGMassiveUploadRequestUpload,
} from './redux-saga/sagas';

export default function getPOGMassiveUploadModule() {
  return {
    id: 'POG_MASSIVE_UPLOAD',
    reducerMap: {
      POG_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchPOGMassiveUploadSetAllowUpload,
      watchPOGMassiveUploadRequestVerify,
      watchPOGMassiveUploadRequestUpload,
    ],
  };
}
