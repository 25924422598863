import React, { useEffect, useState, useContext } from 'react';
import { Form, Row } from 'antd';
import { FormTitleDivider, ActionButtons } from 'common';
import { CommercialStructure, Client, Product, Prices } from '../Components';
import { connect } from 'react-redux';
import { getDetailPricesRequest } from '../redux-saga/actions';
import getDetailPricesModule from '../detailPricesModule';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { layoutContext } from '../../../context';
import getRootModule from 'RootModule';
import { useHistory, useParams } from 'react-router-dom';

const DetailPricesForm = (props) => {
  const { getDetailPricesRequest: getDetailPrices, detailPricesData } = props;
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const history = useHistory();
  const [statusSwitch, setStatusSwitch] = useState({
    //disabled
    checked: false,
    disabled: false,
  });

  const params = useParams();
  const priceId = params.id;

  useEffect(() => {
    getDetailPrices({ priceId: priceId });
    onChangeActiveComponent('Users');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (detailPricesData) {
      setStatusSwitch({
        disabled: true,
        checked: !detailPricesData.status,
      });
      form.setFieldsValue({
        lineId: detailPricesData.lineId,
        subregionId: detailPricesData.subregionId,
        territoryId: detailPricesData.territoryId,
        zoneId: detailPricesData.zoneId,
        countryId: detailPricesData.countryId,
        companyId: detailPricesData.companyId,
        clientName: detailPricesData.client,
        soldTo: detailPricesData.soldTo,
        type: detailPricesData.type,
        productNumber: detailPricesData.productNumber,
        ProductsName: detailPricesData.productName,
        ap: detailPricesData.ap,
        list: detailPricesData.list,
        net: detailPricesData.net,
        brute: detailPricesData.brute,
        suggested: detailPricesData.suggested,
        taxes: detailPricesData.taxes,
        clientLineId: detailPricesData.clientLineId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailPricesData]);

  const goToEditPage = () => {
    history.push(`/catalog/prices/update/${priceId}`);
  };
  const onCancel = () => {
    history.push('/catalog/prices');
  };

  const handlerOnKeyDown = (e) => {
    if (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 109) {
      e.preventDefault();
    }
  };
  return (
    <>
      <div className={'module-card'}>
        <Form form={form}>
          <Row>
            <FormTitleDivider title={'Estructura comercial'} />
          </Row>
          <CommercialStructure form={form} />
          <Row>
            <FormTitleDivider title={'Información de cliente'} />
          </Row>
          <Client form={form} detailPricesData={detailPricesData} />
          <Row>
            <FormTitleDivider title={'Información de producto'} />
          </Row>
          <Product form={form} />
          <Row>
            <FormTitleDivider title={'Información de precios'} />
          </Row>
          <Prices handlerOnKeyDown={handlerOnKeyDown} />
        </Form>
      </div>
      <ActionButtons
        onCancel={onCancel}
        goToEditPage={goToEditPage}
        disabled={false}
        statusSwitch={statusSwitch}
        buttons={['cancel', 'goToEditPage']}
      />
    </>
  );
};
function mapStateToProps(state) {
  return { detailPricesData: state?.DETAILPRICES?.detailPrices?.data };
}
const ConnectedDetailPricesForm = connect(mapStateToProps, {
  getDetailPricesRequest,
})(DetailPricesForm);

const DetailPrices = () => (
  <DynamicModuleLoader modules={[getRootModule(), getDetailPricesModule()]}>
    <ConnectedDetailPricesForm />
  </DynamicModuleLoader>
);

export default DetailPrices;
