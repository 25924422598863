/* eslint-disable no-empty-function */
import React from 'react';
import { Modal, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import './modal.css';
import { openNotification } from 'common';
// Redux-Saga
import getRootModule from 'RootModule';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getManageImagesModule from '../../Redux-saga/getModule';
import { DeleteImageActions, TableActions } from '../../Redux-saga/actions';
const { DeleteManageImages } = DeleteImageActions;
const { ConsultManageImagesRequestTable } = TableActions;

const ModalDeleteComponent = (props) => {
  const {
    propsFather,
    DeleteManageImages: DeleteImageData,
    isLoading,
    // Obtiene datos de Tabla
    ConsultManageImagesRequestTable: getTableData,
  } = props;
  const {
    visibleModal,
    setVisibleModal,
    modalImageData,
    modalFile,
    GETListImages,
    setimagesGET,
    imagesGET,
  } = propsFather;
  const history = useHistory();
  //Get structureId
  const comercialStructure = {
    LineId: { LineId: modalImageData.lineId, Type: 1 },
    SubRegionId: {
      LineId: modalImageData.lineId,
      SubregionId: modalImageData.subregionId,
      Type: 2,
    },
    ClientId: {
      ClientId: modalImageData.clientId,
      SubregionId: modalImageData.subRegionId,
      LineId: modalImageData.lineId,
      Type: 4,
    },
    TerritoryId: {
      TerritoryId: modalImageData.territoryId,
      LineId: modalImageData.lineId,
      SubregionId: modalImageData.subRegionId,
      Type: 3,
    },
  };
  //Get endpoint
  let endpoints = {
    LineId: {
      endpoint: '/AccountStatement/Api/v1/Files/Line',
    },
    SubRegionId: {
      endpoint: '/AccountStatement/Api/v1/Files/SubRegion',
    },
    ClientId: {
      endpoint: '/AccountStatement/Api/v1/Files/Client',
    },
    TerritoryId: {
      endpoint: '/AccountStatement/Api/v1/Files/Territory',
    },
  };

  const onReset = () => {
    setVisibleModal(false);
  };
  const okDelete = async () => {
    //Cambiar texto
    openNotification(
      'success',
      `La eliminación de la imagen de estado de cuenta se realizó correctamente.`
    );

    setVisibleModal(false);
    //Si es la ultima imagen la quita de la tabla
    if (imagesGET.length === 1) {
      setimagesGET([]);
      //Obtener datos de tabla actualizados
      await getTableData({ history });
      return;
    }
    // Recargar imagenes de Estructura comercial especifica
    let dataGet = { listImages: comercialStructure[modalImageData.type] };
    await GETListImages(dataGet);
  };

  const onOk = () => {
    const { fileId } = modalFile;
    let dataEndpoint = endpoints[modalImageData.type];
    let params = {
      ...comercialStructure[modalImageData.type],
      FileId: fileId,
    };
    DeleteImageData({ params, dataEndpoint, okDelete });
  };

  return (
    <Modal
      width={500}
      footer={[
        <Button
          style={{ backgroundColor: 'rgb(36, 123, 169)', color: 'white' }}
          key="1"
          onClick={onReset}
          loading={isLoading}
        >
          Cancelar
        </Button>,
        <Button key="2" type="primary" onClick={() => onOk()} loading={isLoading}>
          Aceptar
        </Button>,
      ]}
      visible={visibleModal}
      closable={false}
    >
      <>
        <div className="modal-header">
          <InfoCircleOutlined className="modal-icon" />
          <p className="modal-title-blue">Eliminar imagen</p>
        </div>
        <p className="modal-subtitle">
          ¿Esta seguro que desea eliminar la imagen del estado de cuenta?
        </p>
      </>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state?.MANAGE_IMAGES?.DeleteImage?.isLoading,
  };
};

const ConnectedModalDelete = connect(mapStateToProps, {
  DeleteManageImages,
  ConsultManageImagesRequestTable,
})(ModalDeleteComponent);

const ModalDelete = (propsFather) => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getManageImagesModule()]}>
      <ConnectedModalDelete propsFather={propsFather} />
    </DynamicModuleLoader>
  );
};
export default ModalDelete;
