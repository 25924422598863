import React from 'react';
import { Row, Col } from 'antd';
import { BInput } from 'common/components';

const SalesInfo = () => {
  return (
    <div>
      <Row>
        <Col span={6}>
          <BInput
            disabled
            className="form-add-user-input"
            name="branchesCount"
            label="Número de sucursales:"
            style={{ align: 'right' }}
          />
        </Col>

        <Col span={6}>
          <BInput
            disabled
            className="form-add-user-input"
            name="clientsCount"
            label="Número de clientes:"
          />
        </Col>

        <Col span={6}>
          <BInput
            disabled
            className="form-add-user-input"
            name="salesForceCount"
            label="Número de representantes de venta:"
          />
        </Col>

        <Col span={6}>
          <BInput
            disabled
            className="form-add-user-input"
            name="techRepCount"
            label="Número de representantes técnicos:"
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="totalYearIncome"
            label="Facturación total de agroquímicos del año anterior:"
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="invoicingCurrency"
            label="Moneda de facturación:"
          />
        </Col>

        <Col span={6}>
          <BInput
            disabled
            className="form-add-user-input"
            name="incoterm"
            label="Tipo de incoterm:"
          />
        </Col>

        <Col span={6}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="pogType"
            label="Tipo de selección de POG:"
          />
        </Col>

        <Col span={6}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="agreementCurrency"
            label="Moneda de acuerdo comercial:"
          />
        </Col>
      </Row>
    </div>
  );
};

export default SalesInfo;
