import React from 'react';
import BCard from '../../../common/BCards';
import BLoading from 'common/components/BLoading';
import './style.css';

function FilterSelected({ title, isLoading, ...other }) {
  const generateWithChild = (child) => {
    return (
      <BCard
        title={child}
        className="bcard-agreements  bg-single-title"
        {...other}
        align="center"
      />
    );
  };

  if (typeof isLoading !== 'undefined') {
    return generateWithChild(
      isLoading ? (
        <BLoading
          size={'small'}
          className="white-loader"
          style={{
            textAlign: 'center',
            color: '#FFF!important',
            fontSize: '1em',
            marginLeft: '3rem',
            background: 'var(--main-theme-color)',
          }}
        />
      ) : (
        title
      )
    );
  } else {
    return generateWithChild(title);
  }
}

export default FilterSelected;
