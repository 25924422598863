import React from 'react';
import ErrorColumnTitle from '../../../../../common/components/ErrorColumnTitle';
import './massiveUploadTableColumns.css';

const messageFaseOne = (
  <>
    <p>
      Registros para los cuales se omitió ingresar campos obligatorios,
      <br />
      se ingresó un tipo de dato que no corresponde al solicitado en el <br />
      campo o se excedió la longitud máxima.
    </p>
  </>
);
const messageFaseTwo = (
  <>
    <p>
      Registros de ventas para las cuales se ingresó información
      <br />
      de elementos con estatus &quot;inactivo&quot;, que no están registrados
      <br />
      en el sistema o se ingresó información de estructura comercial que no esta relacionada con el
      producto.
    </p>
  </>
);
const messageFaseThree = (
  <>
    <p>Registros que se encuentran repetidos dentro del archivo de carga masiva.</p>
  </>
);
const errorMessages = [
  {
    fase: 'Fase 1',
    message: messageFaseOne,
  },
  {
    fase: 'Fase 2',
    message: messageFaseTwo,
  },
  {
    fase: 'Fase 3',
    message: messageFaseThree,
  },
];

const tableColumns = [
  {
    key: 'phase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'phase',
    className: 'title-masive',
  },
  {
    key: 'zone',
    title: 'Zona',
    dataIndex: 'zone',
    className: 'title-masive',
  },
  {
    key: 'soldTo',
    title: 'Sold to',
    dataIndex: 'soldTo',
    className: 'title-masive',
  },
  {
    key: 'clientLine',
    title: 'Linea de negocio',
    dataIndex: 'clientLine',
    className: 'title-masive',
  },
  {
    key: 'clientName',
    title: 'Cliente',
    dataIndex: 'clientName',
    className: 'title-masive',
  },
  {
    key: 'productNumber',
    title: 'Número',
    dataIndex: 'productNumber',
    className: 'title-masive',
  },
  {
    key: 'productName',
    title: 'Producto',
    dataIndex: 'productName',
    className: 'title-masive',
  },
  {
    key: 'productFamily',
    title: 'Familia',
    dataIndex: 'productFamily',
    className: 'title-masive',
  },
  {
    key: 'year',
    title: 'Año',
    dataIndex: 'year',
    className: 'title-masive',
  },
  {
    key: 'month',
    title: 'Mes',
    dataIndex: 'month',
    className: 'title-masive',
  },
  {
    key: 'volume',
    title: 'Volumen',
    dataIndex: 'volume',
    className: 'title-masive',
  },
  {
    key: 'usdBruteAmount',
    title: 'Valor bruto USD',
    dataIndex: 'usdBruteAmount',
    className: 'title-masive',
  },
  {
    key: 'usdBruteLocalAmount',
    title: 'Valor bruto moneda local',
    dataIndex: 'usdBruteLocalAmount',
    className: 'title-masive',
  },
  {
    key: 'usdNetAmount',
    title: 'Valor neto USD',
    dataIndex: 'usdNetAmount',
    className: 'title-masive',
  },
  {
    key: 'usdNetLocalAmount',
    title: 'Valor neto moneda local',
    dataIndex: 'usdNetLocalAmount',
    className: 'title-masive',
  },
];
export default tableColumns;
