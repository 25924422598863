import request from '../../services/request';
import { openNotification } from '../../common';
const catalogURI = '/Catalogs/Api/v1/';
const usersURI = '/Users/Api/v1/';

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export async function uploadCatalog64(file, catalog, overwrite, save = false) {
  let file64 = await toBase64(file);

  let uri = `${catalogURI}${catalog}/MassLoadExcel/`;
  if (catalog === 'selling-goals') {
    uri = `${catalogURI}SalesGoals/MassLoadExcel`;
  }
  if (catalog === 'process-dates') {
    uri = `${catalogURI}ProcessDates/MassLoadExcel`;
  }
  if (catalog === 'exchange-rate') {
    uri = `${catalogURI}ExchangeRate/MassLoadExcel`;
  }
  if (catalog === 'users') {
    uri = `${usersURI}Users/MassLoadExcel`;
  }

  return request
    .post(
      uri,
      {
        content: file64.split(',')[1],
        overwrite: overwrite,
        save: save,
      },
      { timeout: 300000 }
    )
    .then((response) => {
      if (save === true) {
        openNotification('success', `${response.message ? response.message : ''}`);
      }

      return response.data;
    });
}
