import React, { useCallback } from 'react';
import { Form } from 'antd';
import { FormTitleDivider, ActionButtons } from 'common';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PhaseItem from 'Catalog/common/PhaseItem';
import { registerProcessDatesRequest } from 'Catalog/redux/actions/catalogs';
import BLoading from 'common/components/BLoading';

function FormRegisterProcessDates(props) {
  const { phases, registerProcessDatesRequest: registerProcessDates, isLoading } = props;
  const history = useHistory();
  const [form] = Form.useForm();

  const handleOnCancel = useCallback(() => {
    history.push('/catalog/process-dates');
  }, [history]);

  const handleOnSubmit = useCallback(
    (values) => {
      registerProcessDates({
        params: values,
      });
    },
    [registerProcessDates]
  );

  return (
    <Form form={form} name="create-trade-agreement" onFinish={handleOnSubmit}>
      <FormTitleDivider title="Información de fechas de proceso" />
      <h4 className="text-left">*Etapas</h4>
      {phases && (
        <>
          {phases.map((phase) => {
            return <PhaseItem name={phase?.stepId} key={phase?.stepId} label={phase?.step} />;
          })}
        </>
      )}
      {isLoading && (
        <BLoading className="" style={{ width: '100%', textAlign: 'center', fontSize: '48px' }} />
      )}
      <ActionButtons
        disabled={
          form.isFieldsTouched(false) &&
          form.getFieldsError().filter(({ errors }) => errors.length).length > 0
        }
        onCancel={handleOnCancel}
      />
    </Form>
  );
}
function mapStateToProps(state) {
  return {
    phases: state?.CATALOGS?.processDates?.data ?? false,
    isLoading: state?.CATALOGS?.processDates?.isLoading ?? false,
  };
}
export default connect(mapStateToProps, { registerProcessDatesRequest })(FormRegisterProcessDates);
