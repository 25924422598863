const routesUsers = (route, setModule, t, mainRoute, location) => {
  if (route[3] === 'detail') {
    setModule(t(`breadcrumbs./users/detail`));
  } else if (route[3] === 'edit') {
    setModule(t(`breadcrumbs./users/edit`));
  } else if (route[3] === 'consult') {
    setModule(t(`breadcrumbs./users`));
  } else if (route[2] === 'add') {
    setModule(t(`breadcrumbs.${location.pathname}`));
  } else {
    mainRoute();
  }
};
const routesSalesChallenge = (route, setParentModule, setParentRoute, t, setModule) => {
  let child = route[2]; // action
  if (route[1] === 'sales-challenge' && route[2] === 'register') {
    setParentModule(t(`breadcrumbs.sales-challenge.name`));
    setParentRoute(`/sales-challenge`);
  } else if (route[1] === 'sales-challenge' && route[2] === 'update') {
    parentSalesChallenge(setParentModule, setParentRoute, t);
  } else if (route[1] === 'sales-challenge' && route[2] === 'adjusted') {
    parentSalesChallenge(setParentModule, setParentRoute, t);
  } else {
    setParentModule(t(`breadcrumbs.sales-challenge.name`));
    setParentRoute(`/`);
  }

  if (route[3] && route[3] === 'sales-challenge') {
    setModule(t(`breadcrumbs.sales-challenge.${'masiveUpload'}`));
  } else {
    setModule(t(`breadcrumbs.sales-challenge.${child ? child : 'consult'}`));
  }
};

const routesAccountStatus = (
  route,
  location,
  setParentModule,
  setParentRoute,
  setModule,
  t,
  mainRoute
) => {
  if (location.pathname.includes('account-status/base-information/')) {
    let parent = route[2];
    let child = route[3];
    let action = route[4];
    setParentModule(t(`breadcrumbs./${parent}`));
    setParentRoute(`/account-status/base-information`);
    setModule(t(`breadcrumbs.${child}_${action ? action : ''}`));
  } else if (location.pathname.includes('account-status/')) {
    let parent = route[1];
    let child = route[2];
    let action = route[3];
    setParentModule(t(`breadcrumbs./${parent}`));
    setParentRoute(`/${parent}`);
    setModule(t(`breadcrumbs.${child}_${action ? action : ''}`));
  } else if (location.pathname.includes('/account-status')) {
    mainRoute();
  }
};

const parentSalesChallenge = (setParentModule, setParentRoute, t) => {
  setParentModule(t(`breadcrumbs.sales-challenge.name`));
  setParentRoute(`/sales-challenge`);
};
const routesPdfViewer = (t, setModule) => {
  setModule(t(`breadcrumbs./pdf-viewer`));
};

const routesValidators = (t, setModule) => {
  setModule(t(`breadcrumbs./validators`));
};

const routesTradeAgreements = (location, route, setParentModule, setParentRoute, t, setModule) => {
  let parent = route[1];
  let child = route[2]; //si no existe child es la base, si tiene update es actualizar, si tiene register es registrar
  let action = route[3]; //si existe action es carga masiva
  setModule(t(`breadcrumbs./${parent}`));
  setParentModule(t(`breadcrumbs./${parent}/`));
  setParentRoute(`/${parent}`);

  if (!location.pathname.includes('/trade-agreements')) {
    return;
  }
  if (!child) {
    setParentModule(null);
    return;
  }

  if (action) {
    setModule(t(`breadcrumbs./${parent}/${child}/${action}/`));
    return;
  }

  switch (child) {
    case 'update':
      setModule('Actualizar');
      break;
    case 'register':
      setModule('Registrar');
      break;
    case 'adjusted':
    default:
      setModule('Ajustar');
      break;
  }
};

export {
  routesAccountStatus,
  routesSalesChallenge,
  routesUsers,
  routesPdfViewer,
  routesTradeAgreements,
  routesValidators,
};
