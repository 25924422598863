import React from 'react';
import { Loader } from 'global';
import { Table } from 'antd';
import { AllCorrectSign } from 'common/MassiveUploads/components/index.js';
import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import errorMessages from './errorMessages';
import './massiveUploadTableColumns.css';

const MassiveUploadTable = ({ loading, data, allCorrectMessage }) => {
  const { Column } = Table;

  return (
    <div className="module-card-transparent" data-testid="upload">
      {(!data || data.length === 0) && (
        <div style={{ minHeight: '42vh' }}>
          <br />
          {allCorrectMessage && <AllCorrectSign message={allCorrectMessage} />}
        </div>
      )}
      {loading && <Loader />}
      {data && data.length > 0 && (
        <Table
          scroll={{ x: 'max-content' }}
          columnWidth={'100px'}
          dataSource={data}
          className="TableMassiveU"
        >
          <Column
            title={
              <>
                <ErrorColumnTitle errorMessages={errorMessages} />
              </>
            }
            dataIndex="phase"
            key="phase"
            className="title-masive"
          />

          <Column title="Línea de negocio" dataIndex="line" key="line" />
          <Column title="Sold to" dataIndex="soldTo" key="soldTo" />
          <Column title="Cliente" dataIndex="client" key="client" />
          <Column title="Incentivo" dataIndex="incentive" key="incentive" />
          <Column title="Período" dataIndex="period" key="period" />
          <Column title="Año" dataIndex="year" key="year" />
          <Column title="Monto rebate " dataIndex="rebateAmount" key="rebateAmount" />
          <Column title="Comentarios" dataIndex="comments" key="comments" />
          <Column title="Fecha" dataIndex="date" key="date" />
        </Table>
      )}
    </div>
  );
};

export default MassiveUploadTable;
