import reducer from './redux-saga/reducers/';

import {
  watchProductRegistrationSetBusinessLineId,
  watchProductRegistrationSetSubregionId,
  watchProductRegistrationSetCountryId,
  watchProductRegistrationSetProductNumber,
  watchProductRegistrationSetName,
  watchProductRegistrationSetFamily,
  watchProductRegistrationSetClasification,
  watchProductRegistrationSetPresentation,
  watchProductRegistrationSetMeasurementUnit,
  watchProductRegistrationSetUnitsPerPack,
  watchProductRegistrationSetMinimumOrderRequired,
  watchProductRegistrationSetMoq,
  watchProductRegistrationSetCurrency,
  watchProductRegistrationSetIva,
  watchProductRegistrationSetIeps,
  watchProductRegistrationRequestProductExistence,
  watchProductRegistrationRequestRegistration,
} from './redux-saga/sagas';

export default function getProductRegistrationModule() {
  return {
    id: 'PRODUCT_REGISTRATION',
    reducerMap: {
      PRODUCT_REGISTRATION: reducer,
    },
    sagas: [
      watchProductRegistrationSetBusinessLineId,
      watchProductRegistrationSetSubregionId,
      watchProductRegistrationSetCountryId,
      watchProductRegistrationSetProductNumber,
      watchProductRegistrationSetName,
      watchProductRegistrationSetFamily,
      watchProductRegistrationSetClasification,
      watchProductRegistrationSetPresentation,
      watchProductRegistrationSetMeasurementUnit,
      watchProductRegistrationSetUnitsPerPack,
      watchProductRegistrationSetMinimumOrderRequired,
      watchProductRegistrationSetMoq,
      watchProductRegistrationSetCurrency,
      watchProductRegistrationSetIva,
      watchProductRegistrationSetIeps,
      watchProductRegistrationRequestProductExistence,
      watchProductRegistrationRequestRegistration,
    ],
  };
}
