/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import { useHistory } from 'react-router-dom';

import { BusinessLine, Territories, Year, Months } from 'RootModule/consultFilters/';

import ConsultTable from 'common/consults/components/Table';
import FilterWrapper from 'common/consults/components/FilterWrapper';
import SearchInput from 'common/consults/components/SearchInput';
import ResetButton from 'common/consults/components/ResetButton';

import getAccountStatusBaseInformationShoppingModule from '../getAccountStatusBaseInformationShoppingModule';

import { accountStatusBaseInformationShoppingRequestConsult } from '../redux-saga/actions';
import {
  consultFiltersBusinessLineClearValue,
  consultFiltersTerritoriesClearValue,
  consultFiltersYearClearValue,
  consultFiltersMonthsClearValue,
  consultFiltersSearchInputClearValue,
} from 'RootModule/redux/actions/consultFilters';
import consultTableSchema from '../schemas/consultTableSchema';

import { layoutContext } from 'context';

const AccountStatusDynamicElements = (props) => {
  const {
    accountStatusBaseInformationShoppingRequestConsult: requestConsult,
    consultFiltersBusinessLineClearValue: clearBusinessLines,
    consultFiltersTerritoriesClearValue: clearTerritories,
    consultFiltersYearClearValue: clearYear,
    consultFiltersMonthsClearValue: clearMonths,
    consultFiltersSearchInputClearValue: clearSearchInput,

    consult,
    isLoading,

    BusinessLinesValue,
    TerritoriesValue,
    YearValue,
    MonthsValue,

    SearchInputValue,
  } = props;

  const { onChangeActiveComponent } = useContext(layoutContext);
  const history = useHistory();

  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, []);

  useEffect(() => {
    let consultTableParams = {};

    onChangeActiveComponent('BusinessStructureMassiveUpload');

    if (BusinessLinesValue?.length > 0) {
      consultTableParams.Lines = BusinessLinesValue;
    }
    if (TerritoriesValue?.length > 0) {
      consultTableParams.Territories = TerritoriesValue;
    }
    if (YearValue?.length > 0) {
      consultTableParams.Years = YearValue;
    }
    if (MonthsValue?.length > 0) {
      consultTableParams.Months = MonthsValue;
    }
    if (SearchInputValue?.length > 0) {
      consultTableParams.Wildcard = [SearchInputValue];
    }

    requestConsult({ params: consultTableParams, history });
  }, [BusinessLinesValue, TerritoriesValue, YearValue, MonthsValue, SearchInputValue]);

  const clearFilters = () => {
    clearBusinessLines();
    clearTerritories();
    clearYear();
    clearMonths();
    clearSearchInput();
  };

  return (
    <div>
      <FilterWrapper>
        <BusinessLine />
        <Territories />
        <Year serviceName="PurchasesYears" />
        <Months />

        <SearchInput floatToRight />
        <ResetButton clearFilters={clearFilters} />
      </FilterWrapper>
      <ConsultTable
        data={consult}
        columns={consultTableSchema}
        loading={isLoading}
        noResultsMessage={
          'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados.'
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    consult: state?.ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING?.consult,
    isLoading: state?.ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING?.isLoading,
    BusinessLinesValue: state?.APP?.consultFilters.businessLine?.value,
    TerritoriesValue: state?.APP?.consultFilters?.territories?.value,
    YearValue: state?.APP?.consultFilters?.year?.value,
    MonthsValue: state?.APP?.consultFilters?.months?.value,

    SearchInputValue: state?.APP?.consultFilters?.searchInput?.value,
  };
};

const ConnectedAccountStatusDynamicElements = connect(mapStateToProps, {
  accountStatusBaseInformationShoppingRequestConsult,
  consultFiltersBusinessLineClearValue,
  consultFiltersTerritoriesClearValue,
  consultFiltersYearClearValue,
  consultFiltersMonthsClearValue,
  consultFiltersSearchInputClearValue,
})(AccountStatusDynamicElements);

const AccountStatusDynamicElementsWithModules = () => {
  return (
    <DynamicModuleLoader
      modules={[getRootModule(), getAccountStatusBaseInformationShoppingModule()]}
    >
      <ConnectedAccountStatusDynamicElements />
    </DynamicModuleLoader>
  );
};
export default AccountStatusDynamicElementsWithModules;
