import reducer from './reducers';

import { watchConsultShoppingByFamilyTable } from './sagas';

export default function getConsultShoppingByFamilyModule() {
  return {
    id: 'CONSULT_SHOPPING_BY_FAMILY',
    reducerMap: {
      CONSULT_SHOPPING_BY_FAMILY: reducer,
    },
    sagas: [watchConsultShoppingByFamilyTable],
  };
}
