import React, { useEffect, useState } from 'react';
import { getBe } from '../services';
import BussinesEstructureSelect from './bussinesEstructureSelect';

function TerritorySelect({ lobsIds, required, subregionsIds, multiple, onChange, defaultValue }) {
  const [territories, setTerritories] = useState([]);

  useEffect(() => {
    if (subregionsIds && (subregionsIds.length > 0 || subregionsIds > 0)) {
      getBe({
        element: 'territories',
        subregions: subregionsIds,
        lobs: lobsIds,
      }).then((res) => setTerritories(res));
    } else {
      setTerritories([]);
    }
  }, [lobsIds, subregionsIds]);

  return (
    <BussinesEstructureSelect
      tag={'territories'}
      label={'territory'}
      required={required}
      defaultValue={defaultValue}
      options={territories}
      disabled={territories.length <= 0}
      multiple={multiple}
      onChange={onChange}
    />
  );
}
export default TerritorySelect;
