import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import React from 'react';
import './massiveUploadTableColumns.css';

const messageFaseOne = (
  <>
    <p>
      Registros para los cuales se omitió ingresar campos obligatorios,
      <br />
      se ingresó un tipo de dato que no corresponde al solicitado
      <br />
      en el campo o se excedió la longitud máxima.
    </p>
  </>
);
const messageFaseTwo = (
  <>
    <p>
      Registros de precios para los cuales se ingresó información
      <br />
      de elementos con estatus &quot;Inactivo&quot;, que no están registrados
      <br />
      en el sistema, se ingresó información de estructura comercial
      <br />
      que no está relacionada entre sí o se ingresó un número de producto
      <br />
      cuya estructura comercial asociada no está relacionada con la
      <br /> información de estructura comercial ingresada.
    </p>
  </>
);
const messageFaseThree = (
  <>
    <p>
      Registros que se encuentran repetidos dentro del archivo
      <br />
      de carga masiva.
    </p>
  </>
);
const errorMessages = [
  {
    fase: 'Fase 1',
    message: messageFaseOne,
  },
  {
    fase: 'Fase 2',
    message: messageFaseTwo,
  },
  {
    fase: 'Fase 3',
    message: messageFaseThree,
  },
];
const tableColumns = [
  {
    key: 'phase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'phase',
    className: 'title-masive',
  },
  {
    key: 'businessLine',
    title: 'Línea de negocio',
    dataIndex: 'businessLine',
  },
  {
    title: 'Subregión',
    dataIndex: 'subRegion',
  },
  {
    title: 'País',
    dataIndex: 'country',
  },
  {
    title: 'Territorio',
    dataIndex: 'territory',
  },
  {
    title: 'Zona',
    dataIndex: 'zone',
  },

  {
    title: 'Compañía',
    dataIndex: 'company',
  },
  {
    key: 'soldTo',
    title: 'Sold to',
    dataIndex: 'soldTo',
  },
  {
    key: 'clientLine',
    title: 'Línea de negocio',
    dataIndex: 'clientLine',
  },
  {
    key: 'clientName',
    title: 'Nombre',
    dataIndex: 'clientName',
  },
  {
    key: 'type',
    title: 'Tipo',
    dataIndex: 'type',
  },

  {
    key: 'productName',
    title: 'Producto',
    dataIndex: 'productName',
  },
  {
    key: 'productId',
    title: 'Número de producto',
    dataIndex: 'productId',
  },
  {
    key: 'crop',
    title: 'AP',
    dataIndex: 'crop',
  },
  {
    key: 'priceList',
    title: 'Precio de lista',
    dataIndex: 'priceList',
    render: (value) =>
      `$${Number(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
  },
  {
    key: 'priceBrute',
    title: 'Precio Bruto',
    dataIndex: 'priceBrute',
    render: (value) =>
      `$${Number(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
  },
  {
    key: 'priceNet',
    title: 'Precio Neto',
    dataIndex: 'priceNet',
    render: (value) =>
      `$${Number(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
  },
  {
    key: 'priceSugested',
    title: 'Precio sugerido',
    dataIndex: 'priceSugested',
    render: (value) =>
      `$${Number(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
  },
  {
    key: 'priceWithTax',
    title: 'Precio con impuestos',
    dataIndex: 'priceWithTax',
    render: (value) =>
      `$${Number(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
  },
];
export default tableColumns;
