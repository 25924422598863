/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getPdfViewerModule from '../../getPdfViewerModule';
import { pdfViewerRequestGet, pdfViewerSetPage } from '../../redux-saga/actions';

import PdfViewer from 'common/PdfViewer';
import { isMobile, isBrowser } from 'react-device-detect';

import styles from '../../css/public.module.css';
import { DownloadButtonAnchor } from 'PdfViewer/components';

const checkIfItsOdd = (num) => num % 2 === 1;

const PrivatePdfViewer = (props) => {
  const { pdfViewerRequestGet: requestGet } = props;
  const initialPage = 0;
  const totalColumns = 2;
  const totalPagesToShow = 2;
  const [file, setFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(0);

  const history = useHistory();
  const fileId = history.location.pathname.split('/')[2];

  const btnClasses = [
    styles.btn,
    isMobile ? styles.mobileBtn : styles.browserBtn,
    'ant-btn',
    'ant-btn-info',
  ].join(' ');

  const pdfClasses = [styles.pdfStyle, file && styles.activePdfBackground].join(' ');

  const proceedToDownload = () => {
    if (!file) return;

    const downloadAnchor = document.createElement('a');

    downloadAnchor.download = 'account-status-' + fileId + '.pdf';

    downloadAnchor.click();
    downloadAnchor.remove();
  };

  useEffect(() => {
    requestGet({
      setFile,
      params: {
        requestId: fileId,
      },
    });
  }, []);
  useEffect(() => {
    if (isMobile && file) {
      proceedToDownload();
    }
  }, [file]);

  const isClickingRight = (event) => {
    const rect = event.currentTarget.parentElement.getBoundingClientRect();
    const { left, right } = rect;
    const middle = (right - left) / 2 + left;
    const clickPosition = event.clientX;

    return clickPosition > middle;
  };
  const nextPages = () => {
    if (currentPage >= totalPages || currentPage + totalColumns > totalPages) {
      setCurrentPage(initialPage);
    } else {
      setCurrentPage(currentPage + totalColumns);
    }
  };
  const previousPages = () => {
    const lastPage = checkIfItsOdd(totalPages) ? totalPages - 1 : totalPages;
    if (currentPage <= 1) {
      setCurrentPage(lastPage);
    } else {
      setCurrentPage(currentPage - totalColumns);
    }
  };

  const containerClick = (event) => {
    event.preventDefault();
    let clickOnRight = isClickingRight(event);
    clickOnRight ? nextPages() : previousPages();
  };
  const onLoadSuccess = (PDFDocumentProxy) => {
    let proxy = { ...PDFDocumentProxy };
    setTotalPages(proxy._pdfInfo.numPages);
  };
  const date = new Date();

  let dateRegistered =
    date.getDate().toString().padStart(2, '0') +
    '' +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    '' +
    date.getFullYear();

  return (
    <>
      <div className={styles.containerStyle}>
        <DownloadButtonAnchor
          mustShow={file}
          btnClasses={btnClasses}
          iconStyle={styles.icon}
          href={file}
          download={'Estado de cuenta' + fileId + '.pdf'}
          dateRegistered={dateRegistered}
        />

        {isBrowser && file && (
          <PdfViewer
            pdf={file}
            className={pdfClasses}
            pages={totalPagesToShow}
            columns={totalColumns}
            showPage={currentPage}
            loading={'cargando Pdf'}
            onClick={containerClick}
            onLoadSuccess={onLoadSuccess}
          />
        )}
      </div>
      <br />
    </>
  );
};

const mapStateToProps = (state) => {
  return { get: state?.PDF_VIEWER?.get };
};

const ConnectedPrivatePdfViewer = connect(mapStateToProps, {
  pdfViewerRequestGet,
  pdfViewerSetPage,
})(PrivatePdfViewer);

const PrivatePdfViewerWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getPdfViewerModule()]}>
      <ConnectedPrivatePdfViewer />
    </DynamicModuleLoader>
  );
};

export default PrivatePdfViewerWithModules;
