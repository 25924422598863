import React, { useState, useContext } from 'react';
import { Form, Col, Row } from 'antd';
import { BInput } from 'common/components';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getRootModule from 'RootModule';
import getUserModule from '../userModule';
import { postOneUserRequest } from '../redux-saga/actions';
import {
  Region,
  SubRegion,
  Territory,
  Zones,
  Roles,
  BusinessLine,
  Areas,
  Clients,
} from 'RootModule/catalogs';
import { FormTitleDivider } from '../../common';
import { ActionButtons } from 'common';
import { useHistory } from 'react-router-dom';

import { layoutContext } from '../../context';

function UserForm(props) {
  const { postOneUserRequest: postOneUserReq } = props;

  const history = useHistory();

  const [completed, setIsCompleted] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(false);

  const { onChangeActiveComponent } = useContext(layoutContext);

  const [multipleSelect, setMultipleSelect] = useState(true);

  const [requestParamsSubscription, setRequestParamsSubscription] = useState({
    areaId: undefined,
    businessLines: {},
    subRegion: {},
    territories: {},
    zones: {},
    clientsKAM: { placementId: 3 },
  });

  const [disabledFields, setDisabledFields] = useState({
    region: false,
    businessLines: true,
    subRegion: true,
    territories: true,
    zones: true,
    clientsKAM: false,
  });

  const [allowedRequests, setAllowedRequest] = useState({
    areaId: false,
    roleId: false,
    region: true,
    businessLines: false,
    subRegion: false,
    territories: false,
    zones: false,
    clientsKAM: false,
  });

  const [form] = Form.useForm();

  const resetForRoleChange = () => {
    setRequestParamsSubscription({
      ...requestParamsSubscription,
      subRegion: {},
      territories: {},
      zones: {},
      clientsKAM: { placementId: 3 },
    });
    setAllowedRequest({
      ...allowedRequests,
      businessLines: true,
      subRegion: false,
      territories: false,
      zones: false,
      clientsKAM: false,
    });
    form.setFieldsValue({
      businessLines: undefined,
      subRegion: undefined,
      territories: undefined,
      zones: undefined,
      clientsKAM: undefined,
    });
  };

  const handleRoleChange = (value) => {
    setSelectedProfile(value);
    setDisabledFields({
      region: false,
      businessLines: true,
      subRegion: true,
      territories: true,
      zones: true,
      clientsKAM: true,
    });
    if (value === 5) {
      resetForRoleChange();
      setMultipleSelect(false);
      //! Cosmetic
      form.setFieldsValue({
        territories: 'Selecciona una opción',
        subRegion: 'Selecciona una opción',
      });
    } else {
      resetForRoleChange();
      setMultipleSelect(true);
    }
    setIsCompleted(checkIfIsCompleted(form.getFieldsValue()));
  };

  const checkIfIsCompleted = (values) => {
    const checkMutableValue = (value) => {
      let result;
      if (values?.roleId === 5) {
        result = Boolean(value);
      } else {
        result = Boolean(value?.length > 0);
      }
      return result;
    };
    const completedObject = {
      name: Boolean(values?.name),
      surName: Boolean(values?.surName),
      email: Boolean(values?.email),
      emailLeader: Boolean(values?.emailLeader),
      roleId: Boolean(values?.roleId),
      areaId: values?.roleId === 2 ? Boolean(values?.areaId) : true,
      businessLines: checkMutableValue(values?.businessLines),
      subRegion: checkMutableValue(values?.subRegion),
      territories: checkMutableValue(values?.territories),
      zones: Boolean(values?.zones?.length > 0),
      clientsKAM: selectedProfile === 8 ? Boolean(values?.clientsKAM?.length > 0) : true,
    };

    const isNotCompleted = Object.values(completedObject).includes(false);

    return !isNotCompleted;
  };

  const handleOnFieldsChange = (changedValues) => {
    const allValues = form.getFieldsValue();

    const currentChange = Object.keys(changedValues)[0];
    orchestrateFieldChange(currentChange, allValues);
    setIsCompleted(checkIfIsCompleted(form.getFieldsValue()));
  };

  const orchestrateFieldChange = (currentChange, allValues) => {
    onChangeActiveComponent('Users');
    switch (currentChange) {
      case 'roleId':
        form.setFieldsValue({
          region: undefined,
          businessLines: undefined,
          subRegion: undefined,
          territories: undefined,
          zones: undefined,
          clientsKAM: undefined,
        });
        form.setFieldsValue({
          areaId: undefined,
        });
        setRequestParamsSubscription({
          ...requestParamsSubscription,
          areaId: allValues?.roleId,
        });
        setAllowedRequest({
          ...allowedRequests,
          areaId: true,
        });

        break;
      case 'region':
        setDisabledFields({
          region: false,
          businessLines: false,
          subRegion: true,
          territories: true,
          zones: true,
          clientsKAM: true,
        });
        if (allValues?.region?.length > 0 || typeof allValues?.region === 'number') {
          form.setFieldsValue({
            businessLines: undefined,
            subRegion: undefined,
            territories: undefined,
            zones: undefined,
            clientsKAM: undefined,
          });
          setRequestParamsSubscription({
            ...requestParamsSubscription,
            businessLines: {
              ...requestParamsSubscription.businessLines,
              regions: allValues?.region,
            },
            subRegion: {
              ...requestParamsSubscription.subregion,
              regions: allValues?.region,
            },
            territories: {
              ...requestParamsSubscription.territories,
              regions: allValues?.region,
            },
          });
          setAllowedRequest({
            ...allowedRequests,
            region: false,
            businessLines: true,
            subRegion: false,
            territories: false,
            zones: false,
            clientsKAM: false,
          });
        }

        break;

      case 'businessLines':
        form.setFieldsValue({
          subRegion: undefined,
          territories: undefined,
          zones: undefined,
          clientsKAM: undefined,
        });
        if (allValues?.businessLines?.length > 0 || typeof allValues?.businessLines === 'number') {
          setRequestParamsSubscription({
            ...requestParamsSubscription,
            subRegion: {
              ...requestParamsSubscription.subRegion,
              businessLines: cleanAllOption(allValues.businessLines),
            },
            territories: {
              ...requestParamsSubscription.territories,
              businessLines: cleanAllOption(allValues?.businessLines),
            },
            clientsKAM: {
              ...requestParamsSubscription.clientsKAM,
              businessLines: cleanAllOption(allValues?.businessLines),
            },
          });
          setAllowedRequest({
            ...allowedRequests,
            region: false,
            businessLines: false,
            subRegion: true,
            territories: false,
            zones: false,
            clientsKAM: false,
          });
          setDisabledFields({
            region: false,
            businessLines: false,
            subRegion: false,
            territories: true,
            zones: true,
            clientsKAM: true,
          });
        } else {
          setDisabledFields({
            region: false,
            businessLines: false,
            subRegion: true,
            territories: true,
            zones: true,
            clientsKAM: true,
          });
        }
        break;

      case 'subRegion':
        form.setFieldsValue({
          territories: undefined,
          zones: undefined,
          clientsKAM: undefined,
        });
        if (allValues?.subRegion?.length > 0 || typeof allValues?.subRegion === 'number') {
          setRequestParamsSubscription({
            ...requestParamsSubscription,
            territories: {
              ...requestParamsSubscription.territories,
              subRegions: cleanAllOption(allValues.subRegion),
            },
          });
          setAllowedRequest({
            ...allowedRequests,
            region: false,
            businessLines: false,
            subRegion: false,
            territories: true,
            zones: false,
            clientsKAM: false,
          });
          setDisabledFields({
            region: false,
            businessLines: false,
            subRegion: false,
            territories: false,
            zones: true,
            clientsKAM: true,
          });
        } else {
          setDisabledFields({
            region: false,
            businessLines: false,
            subRegion: false,
            territories: true,
            zones: true,
            clientsKAM: true,
          });
        }

        break;

      case 'territories':
        form.setFieldsValue({
          zones: undefined,
          clientsKAM: undefined,
        });

        if (allValues?.territories?.length > 0 || typeof allValues?.territories === 'number') {
          setRequestParamsSubscription({
            ...requestParamsSubscription,
            zones: {
              ...requestParamsSubscription.zones,
              territories: cleanAllOption(allValues.territories),
            },
          });
          setAllowedRequest({
            ...allowedRequests,
            region: false,
            businessLines: false,
            subRegion: false,
            territories: false,
            zones: true,
            clientsKAM: false,
          });
          setDisabledFields({
            region: false,
            businessLines: false,
            subRegion: false,
            territories: false,
            zones: false,
            clientsKAM: true,
          });
        } else {
          setDisabledFields({
            region: false,
            businessLines: false,
            subRegion: false,
            territories: false,
            zones: true,
            clientsKAM: true,
          });
        }

        break;

      case 'zones':
        form.setFieldsValue({
          clientsKAM: undefined,
        });
        if (allValues?.zones?.length > 0 || typeof allValues?.zones === 'number') {
          setAllowedRequest({
            ...allowedRequests,
            region: false,
            businessLines: false,
            subRegion: false,
            territories: false,
            zones: false,
            clientsKAM: true,
          });
          setDisabledFields({
            region: false,
            businessLines: false,
            subRegion: false,
            territories: false,
            zones: false,
            clientsKAM: false,
          });
        }
        break;

      default:
        break;
    }
  };

  const cleanAllOption = (array = []) => {
    if (array?.length) {
      return array.filter((item) => item !== 'ALL');
    }
    return [array];
  };

  const onSubmit = () => {
    const values = form.getFieldsValue();
    postOneUserReq({
      values: { ...values, clientsKAM: values?.clientsKAM ?? [] },
      history,
    });
  };

  const onCancel = () => {
    history.push('/users');
  };

  const handleSelectChange = (value, { name, options }) => {
    if (value?.length) {
      if (value.includes('ALL')) {
        if (value.length === options.length + 1) {
          form.setFieldsValue({ [name]: [] });
        } else {
          form.setFieldsValue({ [name]: options.map((option) => option.key) });
        }

        orchestrateFieldChange(name, form.getFieldsValue());
      }
    }
  };

  const handleSelectChangeClients = (value, { name, options }) => {
    if (value.includes('ALL')) {
      form.setFieldsValue({ [name]: options.map((option) => option.clienteId) });
    }
  };

  return (
    <>
      <div className={'module-card'}>
        <Form form={form} onValuesChange={handleOnFieldsChange}>
          <Row>
            <FormTitleDivider title={'Información del usuario'} />
          </Row>
          <Row>
            <Col span={7}>
              <BInput
                isRequired
                className="form-add-user-input"
                name="name"
                label="Nombre(s)"
                placeholder="Escribir primer nombres(s)"
              />
            </Col>
            <Col span={7}>
              <BInput
                isRequired
                className="form-add-user-input"
                name="surName"
                label="Primer apellido"
                placeholder="Escribir primer apellido"
              />
            </Col>

            <Col span={7}>
              <BInput
                className="form-add-user-input"
                name="lastName"
                label="Segundo apellido"
                placeholder="Escribir segundo apellido"
              />
            </Col>

            <Col span={7}>
              <BInput
                isRequired
                className="form-add-user-input"
                name="email"
                label="Correo electrónico:"
                rules={[{ type: 'email', message: 'usuario@dominio.extensión' }]}
                placeholder="Escribir correo"
              />
            </Col>

            <Col span={7}>
              <BInput
                isRequired
                className="form-add-user-input"
                name="emailLeader"
                label="Correo electrónico de líder:"
                rules={[{ type: 'email', message: 'usuario@dominio.extensión' }]}
                placeholder="Escribir correo del líder"
              />
            </Col>

            <Col span={7}>
              <Roles
                isRequired
                className="form-add-user-input"
                name="roleId"
                label="Perfil"
                onChange={handleRoleChange}
                allowClear={false}
              />
            </Col>

            <Col span={7}>
              <Areas
                isRequired
                className="form-add-user-input"
                name="areaId"
                label="Área"
                disabled={selectedProfile !== 2}
                requestParams={requestParamsSubscription?.areaId}
              />
            </Col>
          </Row>
          <Row>
            <FormTitleDivider title={'Estructura comercial'} />
          </Row>
          <Row>
            <Col span={7}>
              <Region
                isRequired
                disabled={disabledFields.region}
                name="region"
                requestParams={requestParamsSubscription.regions}
                askFromService={allowedRequests.region}
                sourceId="Fixed"
                allowClear={false}
              />
            </Col>

            <Col span={7}>
              <BusinessLine
                isRequired
                disabled={disabledFields.businessLines}
                name="businessLines"
                label="Línea de negocio"
                mode={multipleSelect && 'multiple'}
                requestMode="multiple"
                requestParams={requestParamsSubscription.businessLines}
                askFromService={allowedRequests.businessLines}
                sourceId="Fixed"
                allowClear={false}
                maxTagCount={1}
                selectAll={multipleSelect}
                onChange={handleSelectChange}
              />
            </Col>

            <Col span={7}>
              <SubRegion
                disabled={disabledFields.subRegion}
                isRequired
                name="subRegion"
                mode={multipleSelect ? 'multiple' : ''}
                requestMode="multiple"
                requestParams={requestParamsSubscription.subRegion}
                askFromService={allowedRequests.subRegion}
                sourceId="Fixed"
                allowClear={false}
                maxTagCount={1}
                selectAll={multipleSelect}
                onChange={handleSelectChange}
              />
            </Col>

            <Col span={7}>
              <Territory
                disabled={disabledFields.territories}
                isRequired
                mode={multipleSelect ? 'multiple' : ''}
                requestMode="multiple"
                requestParams={requestParamsSubscription.territories}
                askFromService={allowedRequests.territories}
                sourceId="Fixed"
                name="territories"
                allowClear={false}
                maxTagCount={1}
                selectAll={multipleSelect}
                onChange={handleSelectChange}
              />
            </Col>

            <Col span={7}>
              <Zones
                disabled={disabledFields.zones}
                isRequired
                mode="multiple"
                requestParams={requestParamsSubscription.zones}
                askFromService={allowedRequests.zones}
                sourceId="Fixed"
                name="zones"
                allowClear={false}
                maxTagCount={1}
                selectAll
                onChange={handleSelectChange}
              />
            </Col>
          </Row>

          <Row>
            <FormTitleDivider title="Cliente" />
          </Row>

          <Row style={{ justifyContent: 'space-between' }}>
            <Col span={7}>
              <Clients
                disabled={selectedProfile !== 8 || disabledFields.clientsKAM}
                sourceId="Placement"
                isRequired
                name="clientsKAM"
                label="Cliente(s)"
                mode="multiple"
                askFromService={selectedProfile === 8 || allowedRequests.clientsKAM}
                optionKey="clienteId"
                labelKey="name"
                valueKey="clienteId"
                selectAll
                onChange={handleSelectChangeClients}
                maxTagCount={1}
                requestParams={requestParamsSubscription.clientsKAM}
              />
            </Col>
          </Row>
        </Form>
      </div>
      <ActionButtons onCancel={onCancel} onSubmit={onSubmit} disabled={!completed} />
    </>
  );
}

function mapStateToProps(state) {
  return { userData: state?.USER?.users?.data };
}

const ConnectedUserForm = connect(mapStateToProps, {
  postOneUserRequest,
})(UserForm);

const UpdateUser = () => (
  <DynamicModuleLoader modules={[getRootModule(), getUserModule()]}>
    <ConnectedUserForm />
  </DynamicModuleLoader>
);
export default UpdateUser;
