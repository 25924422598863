export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SET = 'REGISTER_SET';
export const REGISTER_CLEAN = 'REGISTER_CLEAN';

export function registerRequest(payload) {
  return { type: REGISTER_REQUEST, payload: payload };
}

export function registerSet(payload) {
  return {
    type: REGISTER_SET,
    payload: payload,
  };
}

export function registerClean(payload) {
  return {
    type: REGISTER_CLEAN,
    payload: payload,
  };
}
