import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BSelect } from 'common/components';
import { regionRequest } from 'RootModule/redux/actions/catalogs';
import { connect } from 'react-redux';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';
function Region(props) {
  const {
    regions,
    regionRequest: regionReq,
    askFromService,
    label,
    name,
    requestParams,
    sourceId,
    onOptionsChange,
    onSuccess,
    ...other
  } = props;

  useEffect(() => {
    if (askFromService) {
      regionReq({ requestParams: requestParams, sourceId, onSuccess });
    }
  }, [regionReq, askFromService, requestParams, sourceId, onSuccess]);

  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange(regions.data ?? []);
    }
  }, [regions, onOptionsChange]);

  return (
    <BSelect
      isLoading={regions.isLoading}
      label={label}
      name={name}
      options={regions.data}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...other}
    />
  );
}

Region.propTypes = {
  regionRequest: PropTypes.func,
  regions: PropTypes.object,
  askFromService: PropTypes.bool,
  requestParams: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  sourceId: PropTypes.oneOf(['Agreements', 'Sales', 'Fixed']),
};

Region.defaultProps = {
  regionRequest: null,
  regions: { data: [], isLoading: false },
  askFromService: true,
  requestParams: {},
  sourceId: 'Sales',
  label: filtersEnum.region.label,
  name: filtersEnum.region.name,
};

function mapStateToProps(state) {
  return {
    regions: state.APP?.catalogs?.regions,
  };
}

export default connect(mapStateToProps, { regionRequest })(Region);
