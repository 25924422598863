import React from 'react';
import { Row, Col } from 'antd';
import BInput from 'common/components/BInput';
import BRangePicker from 'common/components/BRangePicker';
import './styles.css';
function PhaseItem(props) {
  const { label, name } = props;

  return (
    <Row key={name}>
      <Col md={6}>
        <BInput name={`input-${name}`} placeholder={label} disabled={true} />
      </Col>
      <Col md={7}>
        <BRangePicker name={`processStep-${name}`} label={label} isRequired={true} />
      </Col>
      <Col md={6}>
        <div className="text-help">Se añadirá texto definido por el cliente</div>
      </Col>
    </Row>
  );
}

export default PhaseItem;
