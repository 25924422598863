import React from 'react';
import { Table } from 'antd';
import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import errorMessages from './errorMessages';

const ColumShopping = ({ data }) => {
  const { Column } = Table;
  return (
    <Table
      scroll={{ x: 'max-content' }}
      columnWidth={'100px'}
      dataSource={data}
      className="TableMassiveU"
    >
      <Column
        title={
          <>
            <ErrorColumnTitle errorMessages={errorMessages} />
          </>
        }
        dataIndex="phase"
        key="phase"
        className="title-masive"
      />
      <Column title="Línea de negocio" dataIndex="line" key="line" style={{ color: 'blue' }} />
      <Column title="Sold to" dataIndex="soldTo" key="soldTo" />
      <Column title="Cliente" dataIndex="client" key="client" />
      <Column title="Segmentación" dataIndex="segmentation" key="segmentation" />
      <Column title="Año" dataIndex="year" key="year" />
      <Column title="Periodo" dataIndex="period" key="period" />
      <Column title="Monto de ventas objetivo" dataIndex="purchaseAmount" key="purchaseAmount" />
      <Column title="Real" dataIndex="real" key="real" />
      <Column title="% Alcance" dataIndex="percentageReach" key="percentageReach" />
      <Column title="% Incentivo" dataIndex="percentageIncentive" key="percentageIncentive" />
      <Column title="Incentivo ganado " dataIndex="incentive" key="incentive" />
      <Column title="Periodo de corte" dataIndex="cutPeriod" key="cutPeriod" />
    </Table>
  );
};

export default ColumShopping;
