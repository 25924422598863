/* Common Components */
import { FormTitleDivider } from '../../common';
/* Custom */
import { SalesGoalsTables, ResponsableInformation, BusinessForm } from './catalogComponents';

export const CatalogComponentsMap = {
  SalesGoalsTable: SalesGoalsTables,
  ResponsableInformation: ResponsableInformation,
  BusinessForm: BusinessForm,
  BASFFormTitleDivider: FormTitleDivider,
};
