import rootReducer from './redux/reducers';
import rootSagas from './redux/sagas';

export default function getTradeAgreementsModule() {
  return {
    id: 'trade-agreements',
    reducerMap: {
      TRADE_AGREEMENTS: rootReducer,
    },
    sagas: [rootSagas],
  };
}
