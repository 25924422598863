import {
  SUB_REGIONS_REQUEST,
  GET_SUB_REGIONS,
  CLEAN_SUB_REGIONS,
  BUSINESS_LINES_REQUEST,
  GET_BUSINESS_LINES,
  CLEAN_BUSINESS_LINES,
  SUB_REGIONS_FAIL,
  BUSINESS_LINES_FAIL,
  YEARS_REQUEST,
  GET_YEARS,
  CLEAN_YEARS,
  YEARS_FAIL,
  PERIODICITY_REQUEST,
  GET_PERIODICITY,
  CLEAN_PERIODICITY,
  PERIODICITY_FAIL,
  INCENTIVES_REQUEST,
  GET_INCENTIVES,
  CREDIT_NOTES_REQUEST,
  GET_CREDIT_NOTES,
  CLEAN_INCENTIVES,
  INCENTIVES_FAIL,
  CREDIT_NOTES_FAIL,
  CLEAN_CREDIT_NOTES,
} from '../actions';

const CreditNotesReducers = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SUB_REGIONS_REQUEST:
      return {
        ...state,
        creditNotesSubRegions: payload ?? [],
      };
    case GET_SUB_REGIONS:
      return {
        ...state,
        creditNotesSubRegions: payload ?? [],
      };
    case CLEAN_SUB_REGIONS:
      return {
        ...state,
        creditNotesSubRegions: [],
      };
    case SUB_REGIONS_FAIL:
      return {
        creditNotesSubRegionsMsg: payload ?? '',
      };
    case BUSINESS_LINES_REQUEST:
      return {
        ...state,
        creditNotesBusinessLines: payload ?? [],
      };
    case GET_BUSINESS_LINES:
      return {
        ...state,
        creditNotesBusinessLines: payload ?? [],
      };
    case CLEAN_BUSINESS_LINES:
      return {
        ...state,
        creditNotesBusinessLines: [],
      };

    case BUSINESS_LINES_FAIL:
      return {
        ...state,
        creditNotesBusinessLinesErrorMsg: payload ?? '',
      };
    case YEARS_REQUEST:
      return {
        ...state,
        creditNotesYears: payload ?? [],
      };
    case GET_YEARS:
      return {
        ...state,
        creditNotesYears: payload ?? [],
      };
    case CLEAN_YEARS:
      return {
        ...state,
        creditNotesYears: [],
      };
    case YEARS_FAIL:
      return {
        ...state,
        creditNotesYearsErrorMsg: payload ?? '',
      };
    case PERIODICITY_REQUEST:
      return {
        ...state,
        creditNotesPeriodicity: payload ?? [],
      };
    case GET_PERIODICITY:
      return {
        ...state,
        creditNotesPeriodicity: payload ?? [],
      };
    case CLEAN_PERIODICITY:
      return {
        ...state,
        creditNotesPeriodicity: [],
      };
    case PERIODICITY_FAIL:
      return {
        ...state,
        creditNotesPeriodicityErrorMsg: payload ?? '',
      };

    case INCENTIVES_REQUEST:
      return {
        ...state,
        creditNotesIncentives: payload ?? [],
      };
    case GET_INCENTIVES:
      return {
        ...state,
        creditNotesIncentives: payload ?? [],
      };
    case CLEAN_INCENTIVES:
      return {
        ...state,
        creditNotesIncentives: [],
      };
    case INCENTIVES_FAIL:
      return {
        ...state,
        creditNotesIncentivesErrorMsg: payload ?? '',
      };
    case CREDIT_NOTES_REQUEST:
      return {
        ...state,
        creditNotes: null,
      };
    case GET_CREDIT_NOTES:
      return {
        ...state,
        creditNotes: payload ?? [],
      };
    case CLEAN_CREDIT_NOTES:
      return {
        ...state,
        creditNotes: null,
      };
    case CREDIT_NOTES_FAIL:
      return {
        ...state,
        creditNotesErrorMsg: payload ?? '',
      };
    default:
      return state;
  }
};

export default CreditNotesReducers;
