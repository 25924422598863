import {
  PDF_VIEWER_REQUEST_GET,
  PDF_VIEWER_SET_GET,
  PDF_VIEWER_CLEAR_GET,
  PDF_VIEWER_SET_PAGE,
  PDF_VIEWER_CLEAR_PAGE,
} from '../actions';

function pdfViewerReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case PDF_VIEWER_REQUEST_GET:
      return {
        ...state,
        isLoading: true,
        get: null,
      };

    case PDF_VIEWER_SET_GET:
      return {
        ...state,
        isLoading: false,
        get: action.payload,
      };

    case PDF_VIEWER_CLEAR_GET:
      return {
        ...state,
        isLoading: false,
        get: null,
      };

    case PDF_VIEWER_SET_PAGE:
      return {
        ...state,
        isLoading: false,
        page: action.payload,
      };

    case PDF_VIEWER_CLEAR_PAGE:
      return {
        ...state,
        isLoading: false,
        page: null,
      };

    default:
      return state;
  }
}

export default pdfViewerReducer;
