import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import React from 'react';
import './massiveUploadTableColumns.css';

const messageFaseOne = (
  <>
    <p>
      Registros para los cuales se omitió ingresar campos obligatorios,
      <br />
      se ingresó un tipo de dato que no corresponde al solicitado en el
      <br />
      campo o se excedió la longitud máxima.
    </p>
  </>
);
const messageFaseTwo = (
  <>
    <p>
      Registros de POG para los cuales se ingresó información de
      <br />
      elementos con estatus &quot;Inactivo&quot;, que no están registrados
      <br />
      en el sistema o se ingresó un número de producto cuya
      <br />
      estructura comercial asociada no está relacionada
      <br />
      con la zona ingresada.
    </p>
  </>
);
const messageFaseThree = (
  <>
    <p>
      Registros que se encuentran repetidos dentro del archivo
      <br />
      de carga masiva.
    </p>
  </>
);
const errorMessages = [
  {
    fase: 'Fase 1',
    message: messageFaseOne,
  },
  {
    fase: 'Fase 2',
    message: messageFaseTwo,
  },
  {
    fase: 'Fase 3',
    message: messageFaseThree,
  },
];
const tableColumns = [
  {
    key: 'phase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'phase',
    className: 'title-masive',
  },
  {
    key: 'zone',
    title: 'Zona',
    dataIndex: 'zone',
  },
  {
    key: 'soldTo',
    title: 'Sold To',
    dataIndex: 'soldTo',
  },
  {
    key: 'clientLine',
    title: 'Línea de negocio',
    dataIndex: 'clientLine',
  },
  {
    key: 'clientName',
    title: 'Cliente',
    dataIndex: 'clientName',
  },
  {
    key: 'productNumber',
    title: 'Número',
    dataIndex: 'productNumber',
  },
  {
    key: 'productName',
    title: 'Producto',
    dataIndex: 'productName',
  },
  {
    key: 'productFamily',
    title: 'Familia',
    dataIndex: 'productFamily',
  },
  {
    key: 'year',
    title: 'Año',
    dataIndex: 'year',
  },
  {
    key: 'month',
    title: 'Mes',
    dataIndex: 'month',
  },
  {
    key: 'volume',
    title: 'Volumen',
    dataIndex: 'volume',
  },
  {
    key: 'usdAmount',
    title: 'Valor (Dolares)',
    dataIndex: 'usdAmount',
  },
];
export default tableColumns;
