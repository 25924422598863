import reducer from './redux-saga/reducers/';

import {
  watchAccountStatusPreviewRequestConsult,
  watchAccountStatusPreviewRequestPeriodList,
  watchAccountStatusPreviewRequestRegisterIntoLog,
} from './redux-saga/sagas';

export default function getAccountStatusPreviewModule() {
  return {
    id: 'ACCOUNT_STATUS_PREVIEW',
    reducerMap: {
      ACCOUNT_STATUS_PREVIEW: reducer,
    },
    sagas: [
      watchAccountStatusPreviewRequestConsult,
      watchAccountStatusPreviewRequestPeriodList,
      watchAccountStatusPreviewRequestRegisterIntoLog,
    ],
  };
}
