import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'common/BASF-Logo_white.svg';
import styles from './style.module.css';

const ExternalPageWrapper = (props) => {
  const { title, children } = props;
  return (
    <div>
      <div data-testid="navbar" className={styles.externalTopBar + ' navbar'}>
        <Link
          to={{
            pathname: `/`,
          }}
        >
          <div className="navbar-logo">
            <Logo className="navbar-logo-short" />
          </div>
        </Link>
        {title && <h2 className={styles.externalTitle}>{title}</h2>}
      </div>
      <div className={styles.externalContentBox}>{children}</div>
    </div>
  );
};

export default ExternalPageWrapper;
