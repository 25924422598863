import React, { useEffect, useState } from 'react';
import { getBe } from '../services';
import BussinesEstructureSelect from './bussinesEstructureSelect';

function RegionSelect({ query, required, multiple, onChange }) {
  const [regions, setRegions] = useState([]);
  useEffect(() => {
    if (query && Object.keys(query) <= 0) {
      getBe({ element: 'regions' }).then((res) => setRegions(res));
    }
  }, [query]);

  return (
    <BussinesEstructureSelect
      tag={'regions'}
      label={'region'}
      required={required}
      options={regions}
      disabled={regions.length <= 0}
      multiple={multiple}
      onChange={onChange}
    />
  );
}
export default RegionSelect;
