import reducer from './redux-saga/reducers/';

import {
  watchClientsMassiveUploadSetAllowUpload,
  watchClientsMassiveUploadRequestVerify,
  watchClientsMassiveUploadRequestUpload,
} from './redux-saga/sagas';

export default function getClientsMassiveUploadModule() {
  return {
    id: 'CLIENTS_MASSIVE_UPLOAD',
    reducerMap: {
      CLIENTS_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchClientsMassiveUploadSetAllowUpload,
      watchClientsMassiveUploadRequestVerify,
      watchClientsMassiveUploadRequestUpload,
    ],
  };
}
