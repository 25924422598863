import reducer from './redux-saga/reducers';

import {
  watchPricesMassiveUploadSetAllowUpload,
  watchPricesMassiveUploadRequestVerify,
  watchPricesMassiveUploadRequestUpload,
} from './redux-saga/sagas';

export default function getPricesMassiveUploadModule() {
  return {
    id: 'PRICES_MASSIVE_UPLOAD',
    reducerMap: {
      PRICES_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchPricesMassiveUploadSetAllowUpload,
      watchPricesMassiveUploadRequestVerify,
      watchPricesMassiveUploadRequestUpload,
    ],
  };
}
