export const PDF_VIEWER_REQUEST_GET = 'PDF_VIEWER_REQUEST_GET';
export const PDF_VIEWER_SET_GET = 'PDF_VIEWER_SET_GET';
export const PDF_VIEWER_CLEAR_GET = 'PDF_VIEWER_CLEAR_GET';

export const PDF_VIEWER_SET_PAGE = 'PDF_VIEWER_SET_PAGE';
export const PDF_VIEWER_CLEAR_PAGE = 'PDF_VIEWER_CLEAR_PAGE';

export function pdfViewerRequestGet(payload) {
  return {
    type: PDF_VIEWER_REQUEST_GET,
    payload: payload,
  };
}

export function pdfViewerSetGet(payload) {
  return {
    type: PDF_VIEWER_SET_GET,
    payload: payload,
  };
}

export function pdfViewerClearGet(payload) {
  return {
    type: PDF_VIEWER_CLEAR_GET,
  };
}

export function pdfViewerSetPage(payload) {
  return {
    type: PDF_VIEWER_SET_PAGE,
    payload: payload,
  };
}

export function pdfViewerClearPage(payload) {
  return {
    type: PDF_VIEWER_CLEAR_PAGE,
  };
}
