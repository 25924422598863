import request from 'services/request';

export async function discountsMassiveUpload({ cancelToken, data }) {
  let uri = '/Catalogs/Api/v1/BaseInformationAgreement/MassLoadDiscountExcel';
  return request(uri, {
    method: 'POST',
    cancelToken,
    data,
  });
}
