export const Inventories_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD =
  'Inventories_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const Inventories_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'Inventories_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const Inventories_MASSIVE_UPLOAD_REQUEST_VERIFY =
  'Inventories_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const Inventories_MASSIVE_UPLOAD_SET_VERIFY = 'Inventories_MASSIVE_UPLOAD_SET_VERIFY';
export const Inventories_MASSIVE_UPLOAD_CLEAR_VERIFY = 'Inventories_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const Inventories_MASSIVE_UPLOAD_REQUEST_UPLOAD =
  'Inventories_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const Inventories_MASSIVE_UPLOAD_SET_UPLOAD = 'Inventories_MASSIVE_UPLOAD_SET_UPLOAD';
export const Inventories_MASSIVE_UPLOAD_CLEAR_UPLOAD = 'Inventories_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function InventoriesMassiveUploadSetAllowUpload(payload) {
  return {
    type: Inventories_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function InventoriesMassiveUploadClearAllowUpload(payload) {
  return {
    type: Inventories_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function InventoriesMassiveUploadRequestVerify(payload) {
  return {
    type: Inventories_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function InventoriesMassiveUploadSetVerify(payload) {
  return {
    type: Inventories_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function InventoriesMassiveUploadClearVerify(payload) {
  return {
    type: Inventories_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function InventoriesMassiveUploadRequestUpload(payload) {
  return {
    type: Inventories_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function InventoriesMassiveUploadSetUpload(payload) {
  return {
    type: Inventories_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function InventoriesMassiveUploadClearUpload(payload) {
  return {
    type: Inventories_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
