export const VALIDATE_ELEMENTS_REQUEST = 'VALIDATE_ELEMENTS_REQUEST';
export const GET_VALIDATE_ELEMENTS = 'GET_VALIDATE_ELEMENTS';
export const VALIDATE_ELEMENTS_FAIL = 'VALIDATE_ELEMENTS_FAIL';
export const CLEAN_VALIDATE_ELEMENTS = 'CLEAN_VALIDATE_ELEMENTS';

export function validateElementsRequest(payload) {
  return {
    type: VALIDATE_ELEMENTS_REQUEST,
    payload: payload,
  };
}

export function validateElementsSet(payload) {
  return {
    type: GET_VALIDATE_ELEMENTS,
    payload: payload,
  };
}

export function validateElementsClean(payload) {
  return {
    type: CLEAN_VALIDATE_ELEMENTS,
    payload: payload,
  };
}

export function validateElementsFail(payload) {
  return {
    type: VALIDATE_ELEMENTS_FAIL,
    payload: payload,
  };
}
