import React from 'react';
import { Button, Modal, Select, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const PeriodSelectModal = ({
  mustShow,
  isLoading,
  hideSelectionModal,
  periodList,
  triggerRequestPdf,

  period,
  setPeriod,
}) => {
  const modalTitleStyle = {
    paddingTop: '4px',
    paddingBottom: '4px',
    color: '#00499a',
    fontSize: '18px',
    fontWeigth: 'bold',
  };
  const modalIconStyle = {
    fontSize: '22px',
    color: 'rgb(0,120,179)',
    marginRight: '17px',
  };
  const modalContentStyle = {
    paddingLeft: '40px',
  };
  const selectStyle = { width: '100%' };
  const selectLabelStyle = { color: 'rgb(33, 160, 210)', marginTop: '15px', marginBottom: '5px' };
  const spinStyle = { marginTop: '15px', display: 'block', paddingRight: '42px' };
  const cancelButtonStyle = { backgroundColor: 'rgb(36, 123, 169)', color: 'white' };

  const mapPeriodList = (periodOptions, index) => {
    const periodLabel = periodOptions?.period;

    return (
      <Option key={'key' + index + periodLabel} value={index}>
        {periodLabel}
      </Option>
    );
  };

  const selectChange = (val) => {
    setPeriod(val);
  };

  return (
    <Modal
      visible={mustShow}
      maskClosable={false}
      onCancel={hideSelectionModal}
      footer={[
        <Button style={cancelButtonStyle} key="1" onClick={hideSelectionModal}>
          Cancelar
        </Button>,
        <Button key="2" type="primary" disabled={isLoading} onClick={triggerRequestPdf}>
          Aceptar
        </Button>,
      ]}
    >
      <div>
        <div style={modalTitleStyle}>
          <ExclamationCircleOutlined style={modalIconStyle} />
          Seleccionar periodo
        </div>
        <div style={modalContentStyle}>
          Antes de previsualizar el estado de cuenta favor de seleccionar el periodo
          <div>
            {isLoading ? (
              <Spin style={spinStyle} size="large" />
            ) : (
              <>
                <p style={selectLabelStyle}>Periodo:</p>
                <Select style={selectStyle} value={period} onChange={selectChange}>
                  {periodList.map(mapPeriodList)}
                </Select>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PeriodSelectModal;
