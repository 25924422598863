/**api catalogs */
import request from 'services/request';

const URL_CATALOGS = '/Catalogs/Api/v1';

export async function getProcessDates({ params, cancelToken }) {
  return request(`${URL_CATALOGS}/ProcessDates/`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function registerProcessDates({ params, cancelToken }) {
  return request(`${URL_CATALOGS}/ProcessDates/`, {
    method: 'POST',
    params,
    cancelToken,
  });
}
