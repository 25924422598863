import {
  SALES_CHALLENGE_VALIDATORS_CATALOG_REQUEST,
  SALES_CHALLENGE_VALIDATORS_CATALOG_SUCCESS,
  SALES_CHALLENGE_VALIDATORS_CATALOG_FAIL,
} from '../actions/salesChallengeValidators';

let initialState = {};
const salesChallengevalidatorsCatalog = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SALES_CHALLENGE_VALIDATORS_CATALOG_REQUEST:
      return {
        ...state,
        salesChallengeValidators: {
          isLoading: true,
          data: [],
        },
      };

    case SALES_CHALLENGE_VALIDATORS_CATALOG_SUCCESS:
      return {
        ...state,
        salesChallengeValidators: {
          isLoading: false,
          data: payload,
        },
      };

    case SALES_CHALLENGE_VALIDATORS_CATALOG_FAIL:
      return {
        ...state,
        salesChallengeValidators: {
          isLoading: false,
          data: [],
        },
      };
    default:
      return state;
  }
};

export default salesChallengevalidatorsCatalog;
