///Catalogs/Api/v1/ProcessDates/DaysLeft

export const DAYS_LEFT_REQUEST = 'DAYS_LEFT_REQUEST/VALIDATORS';
export const DAYS_LEFT_SUCCESS = 'DAYS_LEFT_SUCCESS/VALIDATORS';
export const DAYS_LEFT_FAILURE = 'DAYS_LEFT_FAILURE/VALIDATORS';

export const daysLeftRequest = (payload) => ({
  type: DAYS_LEFT_REQUEST,
  payload: payload,
});

export const daysLeftSuccess = (payload) => ({
  type: DAYS_LEFT_SUCCESS,
  payload: payload,
});

export const daysLeftFailure = () => ({
  type: DAYS_LEFT_FAILURE,
});
