export const ACCOUNT_STATUS_PREVIEW_REQUEST_CONSULT = 'ACCOUNT_STATUS_PREVIEW_REQUEST_CONSULT';
export const ACCOUNT_STATUS_PREVIEW_SET_CONSULT = 'ACCOUNT_STATUS_PREVIEW_SET_CONSULT';
export const ACCOUNT_STATUS_PREVIEW_CLEAR_CONSULT = 'ACCOUNT_STATUS_PREVIEW_CLEAR_CONSULT';

export const ACCOUNT_STATUS_PREVIEW_REQUEST_PDF = 'ACCOUNT_STATUS_PREVIEW_REQUEST_PDF';
export const ACCOUNT_STATUS_PREVIEW_SET_PDF = 'ACCOUNT_STATUS_PREVIEW_SET_PDF';
export const ACCOUNT_STATUS_PREVIEW_CLEAR_PDF = 'ACCOUNT_STATUS_PREVIEW_CLEAR_PDF';

export const ACCOUNT_STATUS_PREVIEW_REQUEST_PERIOD_LIST =
  'ACCOUNT_STATUS_PREVIEW_REQUEST_PERIOD_LIST';
export const ACCOUNT_STATUS_PREVIEW_SET_PERIOD_LIST = 'ACCOUNT_STATUS_PREVIEW_SET_PERIOD_LIST';
export const ACCOUNT_STATUS_PREVIEW_CLEAR_PERIOD_LIST = 'ACCOUNT_STATUS_PREVIEW_CLEAR_PERIOD_LIST';

export const ACCOUNT_STATUS_PREVIEW_SET_PDF_PAGE = 'ACCOUNT_STATUS_PREVIEW_SET_PDF_PAGE';
export const ACCOUNT_STATUS_PREVIEW_CLEAR_PDF_PAGE = 'ACCOUNT_STATUS_PREVIEW_CLEAR_PDF_PAGE';

export const ACCOUNT_STATUS_PREVIEW_SET_PERIOD_VALUE = 'ACCOUNT_STATUS_PREVIEW_SET_PERIOD_VALUE';
export const ACCOUNT_STATUS_PREVIEW_CLEAR_PERIOD_VALUE =
  'ACCOUNT_STATUS_PREVIEW_CLEAR_PERIOD_VALUE';

export const ACCOUNT_STATUS_PREVIEW_REQUEST_GET_EXISTANCES =
  'ACCOUNT_STATUS_PREVIEW_REQUEST_GET_EXISTANCES';
export const ACCOUNT_STATUS_PREVIEW_SET_GET_EXISTANCES =
  'ACCOUNT_STATUS_PREVIEW_SET_GET_EXISTANCES';
export const ACCOUNT_STATUS_PREVIEW_CLEAR_GET_EXISTANCES =
  'ACCOUNT_STATUS_PREVIEW_CLEAR_GET_EXISTANCES';

export const ACCOUNT_STATUS_PREVIEW_SET_CURRENT_CLIENT_DATA =
  'ACCOUNT_STATUS_PREVIEW_SET_CURRENT_CLIENT_DATA';
export const ACCOUNT_STATUS_PREVIEW_CLEAR_CURRENT_CLIENT_DATA =
  'ACCOUNT_STATUS_PREVIEW_CLEAR_CURRENT_CLIENT_DATA';

export const ACCOUNT_STATUS_PREVIEW_REQUEST_REGISTER_INTO_LOG =
  'ACCOUNT_STATUS_PREVIEW_REQUEST_REGISTER_INTO_LOG';
export const ACCOUNT_STATUS_PREVIEW_SET_REGISTER_INTO_LOG =
  'ACCOUNT_STATUS_PREVIEW_SET_REGISTER_INTO_LOG';
export const ACCOUNT_STATUS_PREVIEW_CLEAR_REGISTER_INTO_LOG =
  'ACCOUNT_STATUS_PREVIEW_CLEAR_REGISTER_INTO_LOG';

export function accountStatusPreviewRequestConsult(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_REQUEST_CONSULT,
    payload: payload,
  };
}

export function accountStatusPreviewSetConsult(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_SET_CONSULT,
    payload: payload,
  };
}

export function accountStatusPreviewClearConsult(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_CLEAR_CONSULT,
  };
}

export function accountStatusPreviewRequestPdf(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_REQUEST_PDF,
    payload: payload,
  };
}

export function accountStatusPreviewSetPdf(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_SET_PDF,
    payload: payload,
  };
}

export function accountStatusPreviewClearPdf(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_CLEAR_PDF,
  };
}

export function accountStatusPreviewRequestPeriodList(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_REQUEST_PERIOD_LIST,
    payload: payload,
  };
}

export function accountStatusPreviewSetPeriodList(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_SET_PERIOD_LIST,
    payload: payload,
  };
}

export function accountStatusPreviewClearPeriodList(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_CLEAR_PERIOD_LIST,
  };
}

export function accountStatusPreviewSetPdfPage(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_SET_PDF_PAGE,
    payload: payload,
  };
}

export function accountStatusPreviewClearPdfPage(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_CLEAR_PDF_PAGE,
  };
}

export function accountStatusPreviewSetPeriodValue(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_SET_PERIOD_VALUE,
    payload: payload,
  };
}

export function accountStatusPreviewClearPeriodValue(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_CLEAR_PERIOD_VALUE,
  };
}

export function accountStatusPreviewRequestGetExistances(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_REQUEST_GET_EXISTANCES,
    payload: payload,
  };
}

export function accountStatusPreviewSetGetExistances(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_SET_GET_EXISTANCES,
    payload: payload,
  };
}

export function accountStatusPreviewClearGetExistances(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_CLEAR_GET_EXISTANCES,
  };
}

export function accountStatusPreviewSetCurrentClientData(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_SET_CURRENT_CLIENT_DATA,
    payload: payload,
  };
}

export function accountStatusPreviewClearCurrentClientData(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_CLEAR_CURRENT_CLIENT_DATA,
  };
}

export function accountStatusPreviewRequestRegisterIntoLog(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_REQUEST_REGISTER_INTO_LOG,
    payload: payload,
  };
}

export function accountStatusPreviewSetRegisterIntoLog(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_SET_REGISTER_INTO_LOG,
    payload: payload,
  };
}

export function accountStatusPreviewClearRegisterIntoLog(payload) {
  return {
    type: ACCOUNT_STATUS_PREVIEW_CLEAR_REGISTER_INTO_LOG,
  };
}
