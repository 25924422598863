import rootReducer from './redux/reducers/notifications';
import rootSagas from './redux/sagas/rootSagas';
export default function getNotificationsModule() {
  return {
    id: 'NOTIFICATIONS',
    reducerMap: {
      NOTIFICATIONS: rootReducer,
    },
    sagas: [rootSagas],
  };
}
