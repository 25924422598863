import request from 'services/request';
import { paramsSerializer } from 'RootModule/services/catalogs';

const url = '/Catalogs/Api/v1/FixedCatalogs/Regions';
const urlFixedCatalogs = '/Catalogs/Api/v1/FixedCatalogs/';
const urlDashboardCatalogs = '/Dashboards/Api/v1/Catalogs/';
const urlCommercialStructure = '/Dashboards​/Api​/v1​/Catalogs​/';
const comparativeDataUrl = '/Dashboards/Api/v1/Comparative/Resume/';
const urlGetExcel = '/Dashboards/Api/v1/Comparative/FileResume/';

//get region
export async function getRegion({ cancelToken }) {
  return request(url, {
    method: 'GET',
    cancelToken,
  });
}
//get type client
export async function getFixedCatalogs({ cancelToken, name, params, mode }) {
  return request(`${urlFixedCatalogs}${name}`, {
    method: 'GET',
    cancelToken,
    paramsSerializer: mode === 'multiple' ? paramsSerializer : undefined,
    params: params ? params : {},
  });
}

export async function getCustomFixedCatalogs({ cancelToken, name, params, mode }) {
  const catalogUrl = `${urlDashboardCatalogs}${name}`;

  return request(catalogUrl, {
    method: 'GET',
    cancelToken,
    paramsSerializer: mode === 'multiple' ? paramsSerializer : undefined,
    params: params ? params : {},
  });
}

export async function commercialStructure({ cancelToken, name }) {
  return request(`${urlCommercialStructure}${name}`, {
    method: 'GET',
    cancelToken,
  });
}
export async function getTimelapseDetail({ cancelToken, timelapseType }) {
  return request(`${urlFixedCatalogs}TimelapseDetail/${timelapseType}`, {
    method: 'GET',
    cancelToken,
  });
}

//get region
export async function getComparativeData({ cancelToken, params }) {
  return request(comparativeDataUrl, {
    method: 'GET',
    params,
    paramsSerializer,
    cancelToken,
  });
}

export async function getExcel({ cancelToken, params }) {
  return request(`${urlGetExcel}`, {
    method: 'GET',
    cancelToken,
    paramsSerializer: paramsSerializer,
    params: params,
  });
}
