import reducer from './redux-saga/reducers/';

import {
  watchSalesGoalsMassiveUploadSetAllowUpload,
  watchSalesGoalsMassiveUploadRequestVerify,
  watchSalesGoalsMassiveUploadRequestUpload,
} from './redux-saga/sagas';

export default function getSalesGoalsMassiveUploadModule() {
  return {
    id: 'SALESGOALS_MASSIVE_UPLOAD',
    reducerMap: {
      SALESGOALS_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchSalesGoalsMassiveUploadSetAllowUpload,
      watchSalesGoalsMassiveUploadRequestVerify,
      watchSalesGoalsMassiveUploadRequestUpload,
    ],
  };
}
