import { takeLatest, cancelled, put, select, call } from 'redux-saga/effects';
import {
  GET_PRODUCTS_REQUEST,
  getProductsFail,
  getProductsSuccess,
  getProductsPricesSuccess,
  getProductsPricesFail,
  GET_CHALLENGE_POG_REQUEST,
  GET_PRODUCTS_PRICES_REQUEST,
  getChallengePogSuccess,
  getChallengePogFail,
  saveChallengeSuccess,
  areVolumesValids,
  GET_DAYS_LEFT_REQUEST,
  getDayLeftSuccess,
  getDayLeftFail,
} from '../actions/registerSale';
import { saveVolumeSuccess, validateTableRequest } from '../actions/table';
import {
  getProductsInfo,
  getProductsPrices,
  getChallengePog,
  postChallenge,
} from '../../services/registerSales';
import { generateRowsSalesChallenge as processDataToTable } from '../../helpers/helperEditable';
import { checkIfVolumesAreValids } from './table';
import { salesGoalSuccess } from '../actions/graph';
import { reachSuccess } from '../actions/challenge';
import { responseOK, orderArrayObjects } from '../../utils';
import instance from '../../../services/request';
import { openNotification } from 'common/misc/openNotification';
import { getDaysLeft } from 'SalesChallengeRefactor/services/catalogs';

function* workDaysLeftRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params } = payload;

    const response = yield call(getDaysLeft, { cancelToken: source.token, params: params });

    if (responseOK(response)) {
      yield put(getDayLeftSuccess(response?.data));
    } else {
      yield put(getDayLeftFail());
    }
  } catch (e) {
    yield put(getDayLeftFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('Cancelled request');
    }
  }
}

export function* watchDaysLeftRequest() {
  yield takeLatest(GET_DAYS_LEFT_REQUEST, workDaysLeftRequest);
}

function* workGetProductsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params } = payload;
    const response = yield call(getProductsInfo, { cancelToken: source.token, params: params });

    if (responseOK(response)) {
      yield put(getProductsSuccess(response.data));
    } else {
      yield put(getProductsFail());
    }
  } catch (e) {
    yield put(getProductsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('Cancelled Request');
    }
  }
}

function* workGetProductsPricesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, onSuccess, origin } = payload;
    const response = yield call(getProductsPrices, { cancelToken: source.token, params: params });

    if (responseOK(response)) {
      //actualizar precios y tabla
      if (onSuccess) {
        yield onSuccess(response.data);
      }
      yield put(getProductsPricesSuccess(response.data));
      const query = yield select((state) => state?.SALES_CHALLENGE?.query);
      const pogData = yield select(
        (state) => state?.SALES_CHALLENGE?.registerSales?.challengePog?.data
      );

      if (Object.keys(query).length > 0) {
        //procesar datos
        let dataToTable = yield processDataToTable({
          year: query.years,
          data: pogData,
          isEditable: true,
          moq: response.data.moq,
          price: response.data.netPrice,
        });
        let lastVolumes = dataToTable.find((row) => row.rowKey === 7);
        let totalValids = checkIfVolumesAreValids(lastVolumes, response.data.moq);
        let allAreValids = totalValids.length === 12;

        yield put(areVolumesValids(allAreValids));

        if (origin) {
          if (totalValids.length === 0) {
            yield put(validateTableRequest({ state: 'initial', wasSend: false }));
          } else {
            yield put(validateTableRequest({ state: allAreValids, wasSend: false }));
          }
        } else {
          yield put(validateTableRequest({ state: allAreValids, wasSend: false }));
        }

        //ordenar
        let orderTable = dataToTable.sort(orderArrayObjects('rowKey'));
        yield put(saveVolumeSuccess(orderTable));
        //en el cambio de pías guarda únicamente sí los 12 son válidos
        if (allAreValids) {
          let paramsToSave = {
            zoneId: query.zone,
            clientId: query.client,
            priceId: response.data.priceId,
            productId: query.product,
            volumes: totalValids,
          };
          const responsePost = yield call(postChallenge, {
            cancelToken: source.token,
            data: paramsToSave,
          });

          if (responseOK(responsePost) && responsePost.data) {
            if (responsePost.data.goalCoverage)
              yield put(salesGoalSuccess(responsePost.data.goalCoverage));
            if (responsePost.data.reach) yield put(reachSuccess(responsePost.data.reach));
          } else {
            openNotification('error', responsePost.message);
          }
          yield put(saveChallengeSuccess());
        }
      }
    } else {
      yield put(getProductsPricesFail());
    }
  } catch (error) {
    console.error('error :>> ', error);
    //yield put(getProductsPricesFail());
    yield put(saveChallengeSuccess());
  } finally {
    if (yield cancelled()) {
      source.cancel('Cancelled Request');
    }
  }
}

export function* watchGetProductsPricesRequest() {
  yield takeLatest(GET_PRODUCTS_PRICES_REQUEST, workGetProductsPricesRequest);
}

export function* watchGetProductsRequest() {
  yield takeLatest(GET_PRODUCTS_REQUEST, workGetProductsRequest);
}

function* workGetChallengeRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, isConsult } = payload;
    let dataToTable = [];
    const response = yield call(getChallengePog, { cancelToken: source.token, params });

    if (responseOK(response)) {
      yield put(getChallengePogSuccess(response.data));
      const query = yield select((state) => state?.SALES_CHALLENGE?.query);
      if (Object.keys(query).length > 0) {
        //procesar datos
        if (isConsult) {
          dataToTable = yield processDataToTable({ year: query.year, data: response.data });
        } else {
          dataToTable = yield processDataToTable({ year: query.years, data: response.data });
        }

        yield put(saveVolumeSuccess(dataToTable));
      }
    } else {
      yield put(getChallengePogFail());
    }
  } catch (e) {
    yield put(getChallengePogFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}
export function* watchGetChallengeRequest() {
  yield takeLatest(GET_CHALLENGE_POG_REQUEST, workGetChallengeRequest);
}
