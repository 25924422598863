import request from 'services/request';
const serviceURL = '/Catalogs/Api/v1/Clients/Detail/';
const catalogURI = '/Catalogs/Api/v1/FixedCatalogs/';
const updateClientURI = '/Catalogs/Api/v1/Clients/';
const responsableUrl = '/Users/Api/v1/Users/GetUsersByRoleId/';
const validateClientUrl = '/Catalogs/Api/v1/Clients/CheckExistence/';
const statusClientURI = '/Catalogs/Api/v1/Clients/';
//change status clients
export async function statusClient({ cancelToken, clientId, enable }) {
  return request(`${statusClientURI}${clientId}/${enable}`, {
    method: 'PUT',
    cancelToken,
  });
}

//valida client
export async function validateClient({ cancelToken, soldTo, lineId }) {
  return request(`${validateClientUrl}${soldTo}/${lineId}`, {
    method: 'GET',
    cancelToken,
  });
}

//get data client
export async function getOneClient({ cancelToken, clientId }) {
  return request(`${serviceURL}${clientId}`, {
    method: 'GET',
    cancelToken,
  });
}

//get data clients
/*export async function getTerritoriesClient({ cancelToken, data }) {
  return request(`${catalogURI}Territories`, {
    method: 'GET',
    cancelToken,
    data,
  });
}
*/
export async function getTerritoriesClient({ cancelToken, payload }) {
  return request(`${catalogURI}Territories?BusinessLines=${payload}`, {
    method: 'GET',
    cancelToken,
  });
}
//get data clients
export async function getOptionsClient({ cancelToken, name }) {
  return request(`${catalogURI}${name}`, {
    method: 'GET',
    cancelToken,
  });
}
//Register clients
export async function updateClient({ cancelToken, data, clientId }) {
  return request(`${updateClientURI}${clientId}`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

//get responsable
/*export async function getResponsableClient({ cancelToken, name }) {
  return request(`${responsableUrl}${name}`, {
    method: 'GET',
    cancelToken,
  });
}*/

//get responsable
export async function getResponsableClient({ cancelToken, roleId, territoryId }) {
  return request(`${responsableUrl}${roleId}/${territoryId}`, {
    method: 'GET',
    cancelToken,
  });
}
