import request from 'services/request';
const serviceURL = '/Catalogs/Api/v1/Products';

// export async function getProcessDates({ params, cancelToken }) {
//   return request(serviceURL, {
//     method: 'GET',
//     params,
//     cancelToken,
//   });
// }

// export async function modifyProcessDate({ data, cancelToken }) {
//   return request(serviceURL, {
//     method: 'PUT',
//     data,
//     cancelToken,
//   });
// }

export function getByProductId({ id, cancelToken }) {
  return request(`${serviceURL}/Detail/${id}`, {
    method: 'GET',

    cancelToken,
  });
}

export function updateProductStatus({ data, cancelToken }) {
  /* /Catalogs/Api/v1/Products/Enable */
  return request(`${serviceURL}/Enable`, {
    method: 'PUT',
    data,
    cancelToken,
  });
}
export function updateProduct({ data, cancelToken }) {
  /* /Catalogs/Api/v1/Products/Enable */
  return request(`${serviceURL}`, {
    method: 'POST',
    data,
    cancelToken,
  });
}
