import request from 'services/request';
import { paramsSerializer } from 'RootModule/services/catalogs';

export async function pogConsult({ cancelToken, params }) {
  let uri = '/AccountStatement/Api/v1/ObjectivesIncentives/ObjectivesIncentivesPog';
  return request(uri, {
    method: 'GET',
    cancelToken,
    params,
    paramsSerializer,
  });
}
export async function salesConsult({ cancelToken, params }) {
  let uri = '/AccountStatement/Api/v1/ObjectivesIncentives/ObjectivesIncentivesPurchases';
  return request(uri, {
    method: 'GET',
    cancelToken,
    params,
    paramsSerializer,
  });
}
export async function annualConsult({ cancelToken, params }) {
  let uri = '/AccountStatement/Api/v1/ObjectivesIncentives/ObjectivesIncentivesAnual';
  return request(uri, {
    method: 'GET',
    cancelToken,
    params,
    paramsSerializer,
  });
}
export async function checkIfConsultHasElements({ cancelToken }) {
  let uri = '/AccountStatement/Api/v1/Purchases/ExistInfoPurchases';
  return request(uri, {
    method: 'GET',
    cancelToken,
  });
}

export const choseObjectivesAndIncentivesService = (serviceType) => {
  switch (serviceType) {
    case 'pog':
      return pogConsult;

    case 'sales':
      return salesConsult;

    case 'annual':
    default:
      return annualConsult;
  }
};
