import React, { useState } from 'react';
import { Modal } from 'antd';
import { IconsMap } from 'Layout/utils';

import { useHistory } from 'react-router-dom';
import { SubRegion } from 'RootModule/catalogs';

//App Common Data
const ExclamationIcon = IconsMap.ExclamationCircleOutlined;

const ModalSelector = ({ onChange }) => {
  const { push } = useHistory();
  const [okIsDisabled, setOkIsDisabled] = useState(true);
  const [modalIsVisible, setModalIsVisible] = useState(true);

  const handleModalCancel = () => {
    push('/catalog/trade-agreement-info');
    setOkIsDisabled(true);
  };

  const handleModalOnOK = () => {
    setModalIsVisible(false);
  };

  const changeSubRegion = (e, i) => {
    onChange(e, i);
    setOkIsDisabled(e !== 9 && e !== undefined && e !== null ? false : true);
  };

  return (
    <Modal
      closable={false}
      visible={modalIsVisible}
      destroyOnClose
      okButtonProps={{ disabled: okIsDisabled }}
      onCancel={handleModalCancel}
      maskClosable={false}
      onOk={handleModalOnOK}
    >
      <div className="header-modal">
        <ExclamationIcon style={{ fontSize: '1.5rem', alignSelf: 'center' }} />
        <p>Seleccionar subregión</p>
      </div>
      <p>Antes de realizar la carga masiva favor de seleccionar la subregión</p>
      <SubRegion isRequired name="subregionId" onChange={(e, i) => changeSubRegion(e, i)} />
    </Modal>
  );
};

export default ModalSelector;
