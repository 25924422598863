import React from 'react';
import { connect } from 'react-redux';
import { consultFiltersSearchInputSetValue } from 'RootModule/redux/actions/consultFilters';
import { Col } from 'antd';
import { Input } from 'global';
import { AiOutlineSearch } from 'react-icons/ai';

const SearchInput = (props) => {
  const {
    consultFiltersSearchInputSetValue: setValue,
    value,
    name,
    className,
    floatToRight,
    style,
    span,
  } = props;
  const colStyle = { marginRight: '10px', marginTop: '1.55em' };
  const inputStyle = { width: '133px' };
  const buttonStyle = {
    outline: 'none',
    height: '30px',
    border: 'none',
  };
  if (floatToRight) {
    colStyle.marginLeft = 'auto';
  }
  return (
    <Col style={{ ...colStyle }} span={span}>
      <Input
        style={{ ...inputStyle, ...style }}
        type="text"
        name={name}
        autoComplete={'off'}
        className={className}
        value={value}
        suffix={
          <button style={buttonStyle}>
            <AiOutlineSearch />
          </button>
        }
        onChange={(e) => setValue(e.target.value)}
      />
    </Col>
  );
};

const mapStateToProps = (state) => {
  return {
    value: state?.APP?.consultFilters.searchInput?.value ?? '',
  };
};

export default connect(mapStateToProps, {
  consultFiltersSearchInputSetValue,
})(SearchInput);
