import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import 'Catalog/HistoricInformation/Sales/MassiveUpload/schemas/massiveUploadTableColumns.css';

import React from 'react';
const tableColumns = [
  {
    key: 'phase',
    dataIndex: 'phase',
    title: (
      <ErrorColumnTitle
        errorMessages={[
          {
            fase: 'Fase 1',
            message: (
              <>
                <p>
                  Registros para los cuales se omitió ingresar campos obligatorios, se ingresó un
                  tipo de dato que no corresponde al solicitado en el campo o se excedió la longitud
                  máxima.
                </p>
              </>
            ),
          },
          //   {
          //     fase: 'Fase 2',
          //     message: (
          //       <>
          //         <p>Fase 2 msg.</p>
          //       </>
          //     ),
          //   },
          {
            fase: 'Fase 3',
            message: (
              <>
                <p>Registros que se encuentran repetidos dentro del archivo de carga masiva.</p>
              </>
            ),
          },
        ]}
      />
    ),
  },
  {
    key: 'idProduct',
    dataIndex: 'idProduct',
    title: 'ID',
  },

  {
    key: 'productName',
    dataIndex: 'productName',
    title: 'Producto',
  },
  {
    key: 'desc1LK',
    dataIndex: 'desc1LK',
    title: 'Descuento 1 (L/K)',
  },
  {
    key: 'desc1Porc',
    dataIndex: 'desc1Porc',
    title: 'Descuento 1 (%)',
  },
  {
    key: 'desc2LK',
    dataIndex: 'desc2LK',
    title: 'Descuento 2 (L/K)',
  },
  {
    key: 'desc2Porc',
    dataIndex: 'desc2Porc',
    title: 'Descuento 2 (%)',
  },
  {
    key: 'desc3LK',
    dataIndex: 'desc3LK',
    title: 'Descuento 3 (L/K)',
  },
  {
    key: 'desc3Porc',
    dataIndex: 'desc3Porc',
    title: 'Descuento 3 (%)',
  },
];
export default tableColumns;
