import {
  PROCESS_DATES_CONSULT_REQUEST_CONSULT,
  PROCESS_DATES_CONSULT_SET_CONSULT,
  PROCESS_DATES_CONSULT_CLEAR_CONSULT,
  PROCESS_DATES_CONSULT_SET_DATE_ID,
  PROCESS_DATES_CONSULT_CLEAR_DATE_ID,
  PROCESS_DATES_CONSULT_SET_START_DATE,
  PROCESS_DATES_CONSULT_CLEAR_START_DATE,
  PROCESS_DATES_CONSULT_SET_END_DATE,
  PROCESS_DATES_CONSULT_CLEAR_END_DATE,
  PROCESS_DATES_CONSULT_REQUEST_UPDATE,
  PROCESS_DATES_CONSULT_SET_UPDATE,
  PROCESS_DATES_CONSULT_CLEAR_UPDATE,
} from '../actions';

function processDatesConsultReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case PROCESS_DATES_CONSULT_REQUEST_CONSULT:
      return {
        ...state,
        isLoading: true,
        consult: null,
      };

    case PROCESS_DATES_CONSULT_SET_CONSULT:
      return {
        ...state,
        isLoading: false,
        consult: action.payload,
      };

    case PROCESS_DATES_CONSULT_CLEAR_CONSULT:
      return {
        ...state,
        isLoading: false,
        consult: null,
      };

    case PROCESS_DATES_CONSULT_SET_DATE_ID:
      return {
        ...state,
        isLoading: false,
        dateId: action.payload,
      };

    case PROCESS_DATES_CONSULT_CLEAR_DATE_ID:
      return {
        ...state,
        isLoading: false,
        dateId: null,
      };

    case PROCESS_DATES_CONSULT_SET_START_DATE:
      return {
        ...state,
        isLoading: false,
        startDate: action.payload,
      };

    case PROCESS_DATES_CONSULT_CLEAR_START_DATE:
      return {
        ...state,
        isLoading: false,
        startDate: null,
      };

    case PROCESS_DATES_CONSULT_SET_END_DATE:
      return {
        ...state,
        isLoading: false,
        endDate: action.payload,
      };

    case PROCESS_DATES_CONSULT_CLEAR_END_DATE:
      return {
        ...state,
        isLoading: false,
        endDate: null,
      };

    case PROCESS_DATES_CONSULT_REQUEST_UPDATE:
      return {
        ...state,
        isLoading: true,
        update: null,
      };

    case PROCESS_DATES_CONSULT_SET_UPDATE:
      return {
        ...state,
        isLoading: false,
        update: action.payload,
      };

    case PROCESS_DATES_CONSULT_CLEAR_UPDATE:
      return {
        ...state,
        isLoading: false,
        update: null,
      };

    default:
      return state;
  }
}

export default processDatesConsultReducer;
