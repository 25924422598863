const useForm = (form, getCreditNotes) => {
  const hadleChange = () => {
    const allValues = form.getFieldsValue();
    const params = {};
    if (allValues?.Incentive && allValues.Incentive.length > 0) {
      params.Incentive = allValues.Incentive;
    }

    if (allValues?.Line && allValues.Line.length > 0) {
      params.Line = allValues.Line;
    }

    if (allValues?.Period && allValues.Period.length > 0) {
      params.Period = allValues.Period;
    }

    if (allValues?.Periodicity && allValues.Periodicity.length > 0) {
      params.Periodicity = allValues.Periodicity;
    }

    if (allValues?.Subregion && allValues.Subregion.length > 0) {
      params.Subregion = allValues.Subregion;
    }

    if (allValues?.Wildcard && allValues.Wildcard.length > 0) {
      params.Wildcard = [allValues.Wildcard];
    }

    if (allValues?.Year && allValues.Year.length > 0) {
      params.Year = allValues.Year;
    }

    getCreditNotes({ params, history: {} });
  };
  const handleReset = () => {
    form.resetFields();
    form.setFieldsValue({
      Line: null,
      Incentive: null,
      Period: undefined,
      Periodicity: null,
      Wildcard: null,
      Subregion: null,
      Year: null,
      periodsSelect: [],
    });
    getCreditNotes({ params: {}, history: {} });
  };
  return [handleReset, hadleChange];
};

export default useForm;
