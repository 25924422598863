import {
  DELETE_SALES_CHALLENGE_VALIDATOR_REQUEST,
  DELETE_SALES_CHALLENGE_VALIDATOR_SUCCESS,
  DELETE_SALES_CHALLENGE_VALIDATOR_FAIL,
} from '../actions/deleteSalesChallengeValidator';

const initialState = {
  loading: false,
};

const deleteSalesChallengeValidatorReducer = (state = initialState, action) => {
  const { type } = action;
  //const { validators, counter } = state;
  switch (type) {
    case DELETE_SALES_CHALLENGE_VALIDATOR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DELETE_SALES_CHALLENGE_VALIDATOR_SUCCESS:
    case DELETE_SALES_CHALLENGE_VALIDATOR_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default deleteSalesChallengeValidatorReducer;
