import { PogSectionActions } from '../../actions';

const {
  POG_SECTION_REQUEST,
  GET_POG_SECTION,
  POG_SECTION_FAIL,
  CLEAN_POG_SECTION,
  POG_SUBREGIONS_REQUEST,
  POG_SUBREGIONS_SET,
  POG_SUBREGIONS_CLEAN,
} = PogSectionActions;

const pogSectionDataReducers = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case POG_SUBREGIONS_REQUEST:
      return {
        ...state,
        pogsubregionData: null,
      };
    case POG_SUBREGIONS_SET:
      return {
        ...state,
        pogsubregionData: payload,
      };
    case POG_SUBREGIONS_CLEAN:
      return {
        ...state,
        pogsubregionData: [],
      };
    case POG_SECTION_REQUEST:
      return {
        ...state,
        pogSectionData: null,
      };
    case GET_POG_SECTION:
      return {
        ...state,
        pogSectionData: payload ?? [],
      };
    case POG_SECTION_FAIL:
      return {
        ...state,
        pogSectionErrorMsg: payload ?? [],
      };
    case CLEAN_POG_SECTION:
      return {
        ...state,
        pogSectionData: [],
      };
    default:
      return state;
  }
};

export default pogSectionDataReducers;
