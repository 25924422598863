/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/display-name */
import React from 'react';
import { Button } from 'antd';
import { BsFillEyeFill } from 'react-icons/bs';

const getConsultSchema = ({ showSelectionModal }) => {
  return [
    {
      title: 'Vista Previa',
      key: 'preview',
      dataIndex: 'preview',
      displayName: 'preview',
      render: (entry, record) => {
        const { idClient, lineId, subRegionId, soldTo, client, line } = record;
        const clientData = {
          idClient,
          lineId,
          subRegionId,
          soldTo,
          client,
          line,
        };
        // <EyeOutlined /> antd
        return (
          <Button
            className="sendBtn"
            key={'client' + idClient}
            onClick={() => {
              showSelectionModal({ clientData });
            }}
          >
            <BsFillEyeFill style={{ fontSize: '1.5em' }} />
          </Button>
        );
      },
    },
    {
      title: 'Sold to',
      key: 'soldTo',
      dataIndex: 'soldTo',
    },
    {
      title: 'Cliente',
      key: 'client',
      dataIndex: 'client',
    },
    {
      title: 'Línea de negocio',
      key: 'line',
      dataIndex: 'line',
    },
    {
      title: 'Subregión',
      key: 'subRegion',
      dataIndex: 'subRegion',
    },
    {
      title: 'Segmentación',
      key: 'segmentation',
      dataIndex: 'segmentation',
    },
    {
      title: 'Presencia',
      key: 'presence',
      dataIndex: 'presence',
    },
  ];
};
export default getConsultSchema;
