/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addAllBtnOnOptions, handleAllBtnClick } from 'common/consults/utils';

import {
  consultFiltersSegmentationRequestOptions,
  consultFiltersSegmentationSetValue,
} from 'RootModule/redux/actions/consultFilters';

import Filter from 'common/consults/components/Filter';

const Segmentation = (props) => {
  const {
    consultFiltersSegmentationRequestOptions: requestOptions,
    consultFiltersSegmentationSetValue: setValue,
    options,
    value,
    required,
    disableSelectAll,
  } = props;

  let mappedOptions = [...options];

  useEffect(() => {
    requestOptions();
  }, []);

  const selectChange = (selected) => {
    selected = handleAllBtnClick(selected, options);
    setValue(selected);
  };

  if (!disableSelectAll) {
    mappedOptions = addAllBtnOnOptions(options);
  }

  return (
    <Filter
      name={'Segmentación'}
      required={required}
      value={value}
      selectChange={selectChange}
      options={mappedOptions}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    options: state?.APP?.consultFilters.segmentation?.options ?? [],
    value: state?.APP?.consultFilters.segmentation?.value,
  };
};

export default connect(mapStateToProps, {
  consultFiltersSegmentationRequestOptions,
  consultFiltersSegmentationSetValue,
})(Segmentation);
