/* eslint-disable react/display-name */
import { formatCommas } from 'utils';

const consultTableSchema = [
  {
    key: 'soldTo',
    title: 'Sold To',
    dataIndex: 'soldTo',
  },
  {
    key: 'clientName',
    title: 'Cliente',
    dataIndex: 'clientName',
  },
  {
    key: 'line',
    dataIndex: 'line',
    title: 'Línea de negocio',
  },
  {
    key: 'subregion',
    dataIndex: 'subregion',
    title: 'Subregión',
  },
  {
    key: 'family',
    dataIndex: 'family',
    title: 'Familia',
  },
  {
    key: 'volumeAgreement',
    dataIndex: 'volumeAgreement',
    title: 'Volumen acuerdo',
    render: (value) => formatCommas(value),
  },
  {
    key: 'brutePurchase',
    dataIndex: 'brutePurchase',
    title: 'Compras brutas',
    render: (value) => formatCommas(value),
  },
  {
    key: 'percentagePort',
    dataIndex: 'percentagePort',
    title: '% Portafolio',
    render: (value) => formatCommas(value),
  },
  {
    key: 'volumePurchase',
    dataIndex: 'volumePurchase',
    title: 'Volumen de compra',
    render: (value) => formatCommas(value),
  },
  {
    key: 'percentageAcVolume',
    dataIndex: 'percentageAcVolume',
    title: '% Acumulado volumen',
    render: (value) => formatCommas(value),
  },
];
export default consultTableSchema;
