import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY,
  Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  DynamicMassiveUploadSetAllowUpload,
  DynamicMassiveUploadClearAllowUpload,
  DynamicMassiveUploadSetVerify,
  DynamicMassiveUploadClearVerify,
  DynamicMassiveUploadClearUpload,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';

import { DynamicMassiveUpload } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workDynamicMassiveUploadSetAllowUpload(action) {
  try {
    yield DynamicMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield DynamicMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield DynamicMassiveUploadClearAllowUpload();
    }
  }
}

function* workDynamicMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de elementos dinámicos debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(DynamicMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(DynamicMassiveUploadSetVerify(data));
      yield call(summaryModal, message, false);
    } else {
      yield put(DynamicMassiveUploadClearVerify());
    }
  } catch (error) {
    yield DynamicMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield DynamicMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workDynamicMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, overwrite } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(DynamicMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite,
      },
    });
    if (responseOK(response)) {
      yield call(summaryEndModal, response.data, history, '/account-status/dynamic-items/consult');
    } else {
      yield put(DynamicMassiveUploadClearUpload());
    }
  } catch (error) {
    yield DynamicMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield DynamicMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchDynamicMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD, workDynamicMassiveUploadSetAllowUpload),
  ]);
}

export function* watchDynamicMassiveUploadRequestVerify() {
  yield all([
    takeEvery(Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY, workDynamicMassiveUploadRequestVerify),
  ]);
}

export function* watchDynamicMassiveUploadRequestUpload() {
  yield all([
    takeEvery(Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD, workDynamicMassiveUploadRequestUpload),
  ]);
}
