import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Button } from 'antd';
import { DownloadPDFButton, ExportModal } from '../../components';
import { DownloadOutlined } from '@ant-design/icons';
import { downloadDomElementAsPng } from '../../utils';
import { getDMYDateString } from 'Dashboards/Comparative/components/DownloadPDFButton';
import { comparativeDashboardRequestExcel } from 'Dashboards/Comparative/redux-saga/actions';

const buttonStyle = {
  width: '100%',
  fontSize: '1.2em',
  height: 'calc(16px + 1.2em)',
  fontWeight: 600,
  marginTop: '15px',
};

const PDFExportButtons = (props) => {
  const { comparativeDashboardRequestExcel: requestExcel } = props;
  const [showExportModal, setShowExportModal] = useState(false);

  function downloadPng() {
    downloadDomElementAsPng('periodicity-table', `Dashboard Comparativo ${getDMYDateString()}`);
    setShowExportModal(false);
  }
  function downloadExcel() {
    requestExcel();
    setShowExportModal(false);
  }
  return (
    <>
      <Row>
        <Col xs={24} md={12}>
          <Row>
            <Col xs={24} md={8}>
              <DownloadPDFButton cssFile={'css/comparativeDashboardPrintPDF.css'} />
            </Col>
            <Col xs={24} md={8} />
            <Col xs={24} md={8}>
              <Button
                style={buttonStyle}
                type="primary"
                onClick={() => {
                  setShowExportModal(true);
                }}
              >
                <DownloadOutlined style={{ fontSize: '1.2em' }} />
                Exportar
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ExportModal
        visible={showExportModal}
        onCancel={() => {
          setShowExportModal(false);
        }}
        downloadPng={downloadPng}
        downloadExcel={downloadExcel}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    comparativeState: state.COMPARATIVE,
  };
};

export default connect(mapStateToProps, { comparativeDashboardRequestExcel })(PDFExportButtons);
