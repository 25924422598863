/* eslint-disable no-shadow */
const DataChart = (array, filter, typeinfo) => {
  const filtersToCompare = [
    filter[1]?.indicator1 ?? -1,
    filter[2]?.indicator2 ?? -1,
    filter[3]?.indicator3 ?? -1,
  ];

  const typeInfo = filter[4]?.typeinformation ?? '';

  const chartLabels = (array) => {
    let dataLabels = [];
    array.forEach((element) => {
      dataLabels.push(element.structure);
    });
    return dataLabels;
  };

  const salesGoalsData = (array) => {
    let salesGoals = [];
    array.forEach((element) => {
      salesGoals.push(typeInfo === 1 ? element.salesGoals : element.salesGoalsVolume);
    });
    return salesGoals;
  };

  const salesData = (array) => {
    let salesArray = [];
    array.forEach((element) => {
      salesArray.push(typeInfo === 1 ? element.sales : element.salesVolume);
    });
    return salesArray;
  };

  const pogData = (array) => {
    let pogArray = [];
    array.forEach((element) => {
      pogArray.push(typeInfo === 1 ? element.pog : element.pogVolume);
    });
    return pogArray;
  };

  const challengeData = (array) => {
    let salesChallenge = [];
    array.forEach((element) => {
      const challengeAmount =
        typeInfo === 1 ? element.salesChallenge : element.salesChallengeVolume;
      salesChallenge.push(challengeAmount);
    });
    return salesChallenge;
  };

  const agreementData = (array) => {
    let agreementsArray = [];
    array.forEach((element) => {
      agreementsArray.push(typeInfo === 1 ? element.agreement : element.agreementVolume);
    });
    return agreementsArray;
  };
  const dataSetsArray = [];

  filtersToCompare.forEach((item, index) => {
    if (item !== -1) {
      const comparativeDataSet = {
        label: '',
        data: [],
        backgroundColor: '',
        maxBarThickness: '80',
      };

      switch (item) {
        case 'salesChallenge':
          comparativeDataSet.label = 'Desafío de ventas';
          comparativeDataSet.data = challengeData(array);
          break;
        case 'agreement':
          comparativeDataSet.label = 'Acuerdo Comercial';
          comparativeDataSet.data = agreementData(array);
          break;
        case 'pog':
          comparativeDataSet.label = 'POG';
          comparativeDataSet.data = pogData(array);
          break;
        case 'salesGoals':
          comparativeDataSet.label = 'Metas de ventas';
          comparativeDataSet.data = salesGoalsData(array);
          break;
        case 'sales':
          comparativeDataSet.label = 'Ventas históricas';
          comparativeDataSet.data = salesData(array);
          break;
        default:
          comparativeDataSet.label = '-';
          break;
      }

      switch (index) {
        case 0:
          comparativeDataSet.backgroundColor = 'transparent';
          comparativeDataSet.borderColor = '#FF9600';
          comparativeDataSet.type = 'line';
          comparativeDataSet.zIndex = '100';
          comparativeDataSet.pointBackgroundColor = '#FF9600';

          break;
        case 1:
          comparativeDataSet.backgroundColor = '#0078B3';

          break;
        case 2:
          comparativeDataSet.backgroundColor = '#A1C7E2';
          break;
        default:
          break;
      }

      dataSetsArray.push(comparativeDataSet);
    }

    /* dataSetsArray[0].backgroundColor = dataSetsArray[0]?.backgroundColor? 'transparent' : null;
      dataSetsArray[0].borderColor = dataSetsArray[0]?.borderColor? '#FF9600' : null;
      dataSetsArray[1].backgroundColor = dataSetsArray[1]?.backgroundColor? '#A1C7E2' : null;
      dataSetsArray[2].backgroundColor = dataSetsArray[2]?.backgroundColor? '#0078B3' : null; */
  });

  const data = {
    labels: chartLabels(array),
    datasets: dataSetsArray,
  };

  return data;
};

export default DataChart;
