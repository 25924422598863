import {
  TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  TRADE_AGREEMENTS_MASSIVE_UPLOAD_REQUEST_VERIFY,
  TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_VERIFY,
  TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_VERIFY,
  TRADE_AGREEMENTS_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_UPLOAD,
  TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_UPLOAD,
} from '../actions';

function tradeAgreementsUploadReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD:
      return {
        ...state,
        isLoading: false,
        allowUpload: action.payload,
      };

    case TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD:
      return {
        ...state,
        isLoading: false,
        allowUpload: null,
      };

    case TRADE_AGREEMENTS_MASSIVE_UPLOAD_REQUEST_VERIFY:
      return {
        ...state,
        isLoading: true,
        verify: null,
      };

    case TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_VERIFY:
      return {
        ...state,
        isLoading: false,
        verify: action.payload,
      };

    case TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_VERIFY:
      return {
        ...state,
        isLoading: false,
        verify: null,
      };

    case TRADE_AGREEMENTS_MASSIVE_UPLOAD_REQUEST_UPLOAD:
      return {
        ...state,
        isLoading: true,
        upload: null,
      };

    case TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_UPLOAD:
      return {
        ...state,
        isLoading: false,
        upload: action.payload,
      };

    case TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_UPLOAD:
      return {
        ...state,
        isLoading: false,
        upload: null,
      };

    default:
      return state;
  }
}

export default tradeAgreementsUploadReducer;
