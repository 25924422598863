export { useCatalogScope } from './hooks';
export * from './formUtils';
export const catalogPermissions = (userModules, catalogName) => {
  return userModules.includes(catalogName);
};
export const getCatalogsObjects = (catalogs, modules, type, uploadEnabled) => {
  let modulesCards = [];
  catalogs &&
    catalogs.forEach((catalog) => {
      let upload = true;
      let add = true;
      if (catalog.uri === 'sales' || catalog.uri === 'pog' || catalog.uri === 'selling-goals') {
        upload = uploadEnabled;
        add = uploadEnabled;
      }
      modulesCards.push({
        name: catalog.uri,
        add:
          add && catalogPermissions(modules, `register_${catalog.uri}`)
            ? `/${type}/${catalog.uri}/register`
            : false,
        upload:
          upload && catalogPermissions(modules, `upload_${catalog.uri}`)
            ? `/${type}/${catalog.uri}/upload`
            : false,
        consult: catalogPermissions(modules, `consult_${catalog.uri}`)
          ? `/${type}/${catalog.uri}`
          : false,
        url: catalogPermissions(modules, `${catalog.uri}`) ? `/${type}/${catalog.uri}` : false,
      });
    });
  return modulesCards;
};
export const filterValidRows = (rows, errors) => {
  let errorsKeys = errors ? errors : [];
  let validRows =
    rows &&
    rows.length > 0 &&
    rows.filter(function (e) {
      return this.indexOf(e.rowNumber) < 0;
    }, errorsKeys);
  return validRows;
};
export const buildDuplicatedObjects = (duplicatedIds, data, catalogName) => {
  let duplicatedObjs = [];
  duplicatedIds &&
    duplicatedIds.length > 0 &&
    duplicatedIds.forEach((id) => {
      let object = data.find((obj) => obj.rowNumber === id);
      duplicatedObjs.push(object);
    });
  return duplicatedObjs;
};
export const getFilters = (type, data) => {
  let filters = data.reduce((acc, val) => {
    let values = acc.some((filter) => filter.text === val[type]);
    val[type] !== null &&
      val[type] !== '' &&
      values === false &&
      acc.push({ text: val[type], value: val[type] === '' ? 'placeholder' : val[type] });
    return acc;
  }, []);

  return filters;
};
