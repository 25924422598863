import React from 'react';
import { Row, Col } from 'antd';
import { Products } from 'RootModule/catalogs';

const Product = ({ form }) => {
  const handleLinkedSelect = (linked, value) => {
    if (typeof value !== 'object') {
      form.setFieldsValue({ [linked]: value });
    }
  };
  return (
    <div>
      <Row>
        <Col span={8}>
          <Products
            isRequired
            label="Número"
            name="productNumber"
            allowClear={false}
            sourceId="ByProductNumber"
            onChange={(value) => handleLinkedSelect('ProductsName', value)}
            optionKey="productNumber"
            valueKey="productNumber"
            labelKey="productNumber"
            disabled
          />
        </Col>
        <Col span={8}>
          <Products
            isRequired
            label="Nombre"
            name="ProductsName"
            allowClear={false}
            sourceId="ByProductNumber"
            onChange={(value) => handleLinkedSelect('productNumber', value)}
            optionKey="productNumber"
            valueKey="productNumber"
            labelKey="name"
            disabled
          />
        </Col>

        {/* ################ */}
      </Row>
    </div>
  );
};

export default Product;
