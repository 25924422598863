import {
  TRADE_AGREEMENTS_VALIDATORS_CATALOG_REQUEST,
  TRADE_AGREEMENTS_VALIDATORS_CATALOG_SUCCESS,
  TRADE_AGREEMENTS_VALIDATORS_CATALOG_FAIL,
} from '../actions/tradeAgreementsValidators';

let initialState = {};
const tradeAgreementsValidatorsCatalog = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TRADE_AGREEMENTS_VALIDATORS_CATALOG_REQUEST:
      return {
        ...state,
        tradeAgreementsValidators: {
          isLoading: true,
          data: [],
        },
      };

    case TRADE_AGREEMENTS_VALIDATORS_CATALOG_SUCCESS:
      return {
        ...state,
        tradeAgreementsValidators: {
          isLoading: false,
          data: payload,
        },
      };

    case TRADE_AGREEMENTS_VALIDATORS_CATALOG_FAIL:
      return {
        ...state,
        tradeAgreementsValidators: {
          isLoading: false,
          data: [],
        },
      };
    default:
      return state;
  }
};

export default tradeAgreementsValidatorsCatalog;
