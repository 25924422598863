import reducer from './redux-saga/reducers/';

import {
  watchExchangeRateMassiveUploadSetAllowUpload,
  watchExchangeRateMassiveUploadRequestVerify,
  watchExchangeRateMassiveUploadRequestUpload,
} from './redux-saga/sagas';

export default function getExchangeRateMassiveUploadModule() {
  return {
    id: 'EXCHANGE_RATE_MASSIVE_UPLOAD',
    reducerMap: {
      EXCHANGE_RATE_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchExchangeRateMassiveUploadSetAllowUpload,
      watchExchangeRateMassiveUploadRequestVerify,
      watchExchangeRateMassiveUploadRequestUpload,
    ],
  };
}
