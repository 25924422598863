import React, { useContext, useEffect, useState } from 'react';
import getInfoPOGMassiveUploadModule from '../getInfoPOGMassiveUploadModule';
import { triggerModalForDuplicates } from 'common/MassiveUploads/utils';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import { useHistory } from 'react-router-dom';
import { layoutContext } from 'context';
import getRootModule from 'RootModule';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
  MassiveUploadTable,
  UploadExcelButton,
  ActionButtonsExcelUpload,
} from 'common/MassiveUploads/components';
//cambiar sagas
import {
  InfoPOGMassiveUploadSetAllowUpload,
  InfoPOGMassiveUploadRequestVerify,
  InfoPOGMassiveUploadRequestUpload,
  InfoPOGMassiveUploadClearUpload,
  InfoPOGMassiveUploadClearVerify,
  InfoPOGMassiveUploadClearAllowUpload,
  ExistsStatements,
} from '../redux-saga/actions';
import tableColumns from '../schemas/massiveUploadTableColumns'; //cambiar columna

const InfoPOGMassiveUpload = (props) => {
  const {
    InfoPOGMassiveUploadSetAllowUpload: setAllowUpload,
    InfoPOGMassiveUploadRequestVerify: requestVerify,
    InfoPOGMassiveUploadRequestUpload: requestUpload,
    InfoPOGMassiveUploadClearUpload: clearUpload,
    InfoPOGMassiveUploadClearVerify: clearVerify,
    InfoPOGMassiveUploadClearAllowUpload: clearAllowUpload,
    ExistsStatements: ExistsStatementsGet,
    allowUpload,
    verify,
  } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [fileString, setFileString] = useState('');
  const correct = verify?.correct;
  const entriesList = verify?.objectList;
  const totalDuplicatedEntries = verify?.replaced?.length;
  const allCorrectMessage = verify?.allCorrectMessage;

  useEffect(() => {
    clearAllowUpload();
    ExistsStatementsGet({ history });

    return () => {
      clearAllowUpload();
      clearUpload();
      clearVerify();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    onChangeActiveComponent('activo');
    if (correct > 0) {
      setAllowUpload(true);
    } else {
      setAllowUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correct]);

  function customRequest({ file }) {
    requestVerify({ file, setFileString });
  }
  function requestReplace() {
    requestUpload({ history, fileString, overwrite: true });
  }
  function requestKeep() {
    requestUpload({ history, fileString, overwrite: false });
  }
  function onSubmit() {
    if (totalDuplicatedEntries > 0) {
      triggerModalForDuplicates(totalDuplicatedEntries, requestReplace, requestKeep, 'POG');
      return;
    }

    requestReplace();
  }
  function onCancel() {
    history.push(`/account-status/base-information`);
  }

  return (
    <Form form={form}>
      <UploadExcelButton customRequest={customRequest} />
      <MassiveUploadTable
        data={entriesList}
        columns={tableColumns}
        allCorrectMessage={allCorrectMessage}
      />
      <ActionButtonsExcelUpload
        onSubmit={onSubmit}
        onCancel={onCancel}
        disabled={!allowUpload}
        buttons={['cancel', 'submit']}
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    allowUpload: state?.InfoPOG_MASSIVE_UPLOAD?.allowUpload,
    verify: state?.InfoPOG_MASSIVE_UPLOAD?.verify,
  };
};

const ConnectedInfoPOGMassiveUpload = connect(mapStateToProps, {
  InfoPOGMassiveUploadSetAllowUpload,
  InfoPOGMassiveUploadRequestVerify,
  InfoPOGMassiveUploadRequestUpload,
  InfoPOGMassiveUploadClearUpload,
  InfoPOGMassiveUploadClearVerify,
  InfoPOGMassiveUploadClearAllowUpload,
  ExistsStatements,
})(InfoPOGMassiveUpload);

const InfoPOGMassiveUploadWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getInfoPOGMassiveUploadModule()]}>
      <ConnectedInfoPOGMassiveUpload />
    </DynamicModuleLoader>
  );
};
export default InfoPOGMassiveUploadWithModules;
