/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useContext, useState } from 'react';
import NavItem from './navItem';
import './layout.css';
import { appContext } from '../context';
import { navItems, IconsMap } from './utils';
import { Modal } from 'antd';
import { MenuFoldOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

function Sidenav({ collapsed, setCollapse, onChange }) {
  let history = useHistory();
  const { onLogout, timeLogout } = useContext(appContext);
  const { modules } = useContext(appContext);
  const Logout = () => {
    Modal.confirm({
      title: 'Cerrar sesión',
      content: '¿Esta seguro que desea cerrar sesión?',
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      centered: true,
      okButtonProps: { type: 'default', style: { backgroundColor: '#003d81', color: '#ffffff' } },
      onOk() {
        onLogout();
        history.push('/login');
      },
      onCancel() {
        return;
      },
    });
  };

  let Icon = IconsMap['LogoutOutlined'];

  const validateModule = (item) => modules.some((module) => module === item.module.toLowerCase());

  const [subMenu, setSubMenu] = useState(false);

  const collapseSubMenu = () => {
    if (subMenu && !collapsed) setSubMenu(false);
  };

  return (
    <>
      <div
        className={!collapsed ? 'sidenav-wrapper' : 'sidenav-wrapper-collapsed'}
        onClick={() => timeLogout()}
      >
        <div className="sidenav-header">
          <button
            className={collapsed ? 'collapsed-collapse-button' : 'collapse-button'}
            onClick={() => {
              onChange();
              collapseSubMenu();
            }}
          >
            <MenuFoldOutlined />{' '}
          </button>
        </div>
        <div className="sidenav-body">
          {navItems.map(
            (item, key) =>
              modules &&
              validateModule(item) && (
                <Fragment key={key}>
                  <NavItem
                    collapsed={collapsed}
                    modules={modules}
                    item={item}
                    setCollapse={setCollapse}
                    subMenu={subMenu}
                    setSubMenu={setSubMenu}
                  />
                </Fragment>
              )
          )}
        </div>
        <div className={!collapsed ? 'sidenav-footer' : 'sidenav-footer-collapsed'}>
          <p style={{ color: '#ffffff' }} className="logout-button" onClick={() => Logout()}>
            <span>
              <Icon />
              {!collapsed ? <span style={{ marginLeft: '5px' }}>Salir</span> : ''}
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
export default Sidenav;
