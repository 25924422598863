import request from 'services/request';
import apiUrls from '../utils/apiConstants';
import { paramsSerializer } from 'RootModule/services/catalogs';

export async function requestSubRegions({ cancelToken }) {
  const { subRegions } = apiUrls;
  return request(subRegions, {
    method: 'GET',
    cancelToken,
  });
}

export async function requestBusinessLines({ cancelToken }) {
  const { businessLines } = apiUrls;
  return request(businessLines, {
    method: 'GET',
    cancelToken,
  });
}

export async function requestYears({ cancelToken }) {
  const { years } = apiUrls;
  return request(years, {
    method: 'GET',
    cancelToken,
  });
}

export async function requestPeriodicity({ cancelToken }) {
  const { periodicity } = apiUrls;
  return request(periodicity, {
    method: 'GET',
    cancelToken,
  });
}

export async function requestIncentives({ cancelToken }) {
  const { incentives } = apiUrls;
  return request(incentives, {
    method: 'GET',
    cancelToken,
  });
}

export async function requestCreditNotes({ cancelToken, data = {} }) {
  const { creditNotes } = apiUrls;
  const { params } = data;
  return request(creditNotes, {
    method: 'GET',
    cancelToken,
    params,
    paramsSerializer,
  });
}
