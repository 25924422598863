import request from 'services/request';
import { paramsSerializer } from 'RootModule/services/catalogs';

export async function ShoppingConsult({ cancelToken, params }) {
  let uri = '/AccountStatement/Api/v1/Purchases/GetPurchases';
  return request(uri, {
    method: 'GET',
    cancelToken,
    params,
    paramsSerializer,
  });
}
export async function checkIfConsultHasElements({ cancelToken }) {
  let uri = '/AccountStatement/Api/v1/Purchases/ExistInfoPurchases';
  return request(uri, {
    method: 'GET',
    cancelToken,
  });
}
