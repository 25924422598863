import {
  POST_ONE_SEGMENTATION_REQUEST,
  POST_ONE_SEGMENTATION_SUCCESS,
  POST_ONE_SEGMENTATION_CLEAN,
  DELETE_ONE_SEGMENTATION_REQUEST,
  DELETE_ONE_SEGMENTATION_SUCCESS,
  DELETE_ONE_SEGMENTATION_CLEAN,
  PUT_ONE_SEGMENTATION_REQUEST,
  PUT_ONE_SEGMENTATION_SUCCESS,
  PUT_ONE_SEGMENTATION_CLEAN,
  GET_IN_POLICIY_PRODUCTS_REQUEST,
  GET_IN_POLICIY_PRODUCTS_SUCCESS,
  GET_IN_POLICIY_PRODUCTS_CLEAN,
  GET_ACTIVE_CLIENTS_REQUEST,
  GET_ACTIVE_CLIENTS_SUCCESS,
  GET_ACTIVE_CLIENTS_CLEAN,
  POST_PRODUCT_CLIENT_REQUEST,
  POST_PRODUCT_CLIENT_SUCCESS,
  POST_PRODUCT_CLIENT_CLEAN,
  POST_PRODUCT_BL_REQUEST,
  POST_PRODUCT_BL_SUCCESS,
  POST_PRODUCT_BL_CLEAN,
  POST_INCENTIVES_RULES_REQUEST,
  POST_INCENTIVES_RULES_SUCCESS,
  POST_INCENTIVES_RULES_CLEAN,
  EXISTENCE_INCENTIVES_REQUEST,
  EXISTENCE_INCENTIVES_SUCCESS,
  EXISTENCE_INCENTIVES_CLEAN,
  GET_ClientSingleId_REQUEST,
  GET_ClientSingleId_SUCCESS,
  GET_ClientSingleId_CLEAN,
} from '../actions';

function oneCase(state = {}, action) {
  const { type } = action;

  switch (type) {
    //Valida_INCENTIVES
    case EXISTENCE_INCENTIVES_REQUEST:
      return {
        ...state,
        isValid: true,
      };
    case EXISTENCE_INCENTIVES_SUCCESS:
      return {
        ...state,
        isValid: false,
        data: action.payload,
      };
    case EXISTENCE_INCENTIVES_CLEAN:
      return {
        ...state,
        isValid: false,
      };
    //GET_ClientSingleId
    case GET_ClientSingleId_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case GET_ClientSingleId_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case GET_ClientSingleId_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //POST_INCENTIVES_RULES
    case POST_INCENTIVES_RULES_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case POST_INCENTIVES_RULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case POST_INCENTIVES_RULES_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //POST_ONE_SEGMENTATION
    case POST_ONE_SEGMENTATION_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case POST_ONE_SEGMENTATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case POST_ONE_SEGMENTATION_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //DELETE_ONE_SEGMENTATION
    case DELETE_ONE_SEGMENTATION_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case DELETE_ONE_SEGMENTATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case DELETE_ONE_SEGMENTATION_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //PUT_ONE_SEGMENTATION
    case PUT_ONE_SEGMENTATION_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case PUT_ONE_SEGMENTATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case PUT_ONE_SEGMENTATION_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //GET_IN_POLICIY_PRODUCTS
    case GET_IN_POLICIY_PRODUCTS_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case GET_IN_POLICIY_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productInformation: action.payload,
      };
    case GET_IN_POLICIY_PRODUCTS_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //GET_ACTIVE_CLIENTS
    case GET_ACTIVE_CLIENTS_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case GET_ACTIVE_CLIENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case GET_ACTIVE_CLIENTS_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //POST_PRODUCT_CLIENT
    case POST_PRODUCT_CLIENT_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case POST_PRODUCT_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case POST_PRODUCT_CLIENT_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //POST_PRODUCT_BL
    case POST_PRODUCT_BL_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case POST_PRODUCT_BL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case POST_PRODUCT_BL_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}

export default oneCase;
