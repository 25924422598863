import React from 'react';
import { Input, Form } from 'antd';
import PropTypes from 'prop-types';
import { setRuleRequired } from 'TradeAgreements/utils/validations';
const { Search } = Input;

/**
 * SSearch is an encapsulation of Ant Desing Input: Search
 *  🧪 Ccmponent with 100% coverage
 */

function BSearch(props) {
  const {
    placeholder,
    initialValue,
    name,
    onSearch,
    loading,
    inputKey,
    label,
    isRequired,
    minRequired,
    ...other
  } = props;
  const initial = initialValue === null ? null : { initialValue: initialValue };
  const requiredRule = isRequired ? [setRuleRequired(label)] : [];

  return (
    <Form.Item
      label={label}
      {...loading}
      name={name}
      rules={[...requiredRule]}
      {...initial}
      {...other}
    >
      <Search
        autoComplete="off"
        onSearch={onSearch}
        placeholder={placeholder}
        loading={loading}
        {...other}
      />
    </Form.Item>
  );
}

BSearch.propTypes = {
  inputKey: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  minRequired: PropTypes.number,
};

BSearch.defaultProps = {
  inputKey: 'Wildcard',
  isRequired: false,
  label: '',
  loading: false,
  onSearch: null,
  placeholder: 'Buscar',
  minRequired: 2,
};

export default BSearch;
