/* eslint-disable no-fallthrough */
export const getDisabledFiltersAndDependingFilter = (query, baseArray, validationType) => {
  const disabledFiltersAndDependingFilter = { disabled: [], depending: '' };

  const queryEntries = Object.entries(query ?? {});
  const filteredEntries = queryEntries.filter((entry) => entry[1] !== undefined);
  const filtersOrderArray = Object.keys(baseArray ?? []);
  let positionOfLastFilter = 0;

  if (!query || !baseArray) {
    return disabledFiltersAndDependingFilter;
  }

  filteredEntries.forEach((entry) => {
    const [filterName] = entry;
    const filterIndex = filtersOrderArray.indexOf(filterName);

    if (filterIndex > positionOfLastFilter) {
      positionOfLastFilter = filterIndex;
    }
  });

  if (positionOfLastFilter === filtersOrderArray.length - 1) {
    return disabledFiltersAndDependingFilter;
  }

  disabledFiltersAndDependingFilter.depending = filtersOrderArray[positionOfLastFilter + 1];

  if (validationType === 'tradeAgreement' && query.clientId !== undefined) {
    disabledFiltersAndDependingFilter.disabled = [];
    return disabledFiltersAndDependingFilter;
  }

  disabledFiltersAndDependingFilter.disabled = filtersOrderArray.slice(positionOfLastFilter + 2);

  return disabledFiltersAndDependingFilter;
};

export const getSalesChallengeParentFilters = (name, query) => {
  switch (name) {
    case 'lineId':
      return { regions: query.regionId };
    case 'subRegionId':
      return { regions: query.regionId, businessLines: query.lineId };
    case 'territoryId':
      return { businessLines: query.lineId, subRegions: query.subRegionId, IsChallenge: true };
    case 'zoneId':
      return {
        territories: query.territoryId,
        //year: query.year
      };
    case 'clientId':
      return {
        lineId: query.lineId,
        subRegionId: query.subRegionId,
        territoryId: query.territoryId,
      };
    default:
      return {};
  }
};
export const getTradeAgreementParentFilters = (name, query) => {
  switch (name) {
    case 'lineId':
      return {};
    case 'subRegionId':
      return { regions: query.regionId, businessLines: query.lineId };
    case 'territoryId':
      return { businessLines: query.lineId, subRegions: query.subRegionId, IsChallenge: false };
    case 'zoneId':
      return {};
    case 'clientId':
      return { lineId: query.lineId };
    default:
      return {};
  }
};
export const getAllParentFilters = (name, query) => {
  return getSalesChallengeParentFilters(name, query);
};

export const getParentFiltersQuery = (name, query, validatorType) => {
  //validatorType = "salesChallenge" || "tradeAgreement" || "ALL"

  switch (validatorType) {
    case 'salesChallenge':
      return getSalesChallengeParentFilters(name, query);
    case 'tradeAgreement':
      return getTradeAgreementParentFilters(name, query);
    case 'All':
      return getAllParentFilters(name, query);
    default:
      return {};
  }
};
