import React from 'react';
import { Row, Col, Select, Form, InputNumber } from 'antd';
import { getPopupContainer } from 'SalesChallengeRefactor/helpers/helperForms';
import { BInput } from 'common/components';
const Client = ({ getClientData }) => {
  const { Option } = Select;
  const parserFunc = (value) => {
    let clean = value.replace(/\$\s?|(,*)/g, '');
    if (clean && clean.length > 11) {
      let result = '';
      let decimal = clean.indexOf('.');
      if (decimal > 0 && clean.length <= 18) {
        result = clean.substring(0, 18);
      } else {
        result = clean.substring(0, 11);
      }

      return result;
    } else {
      return clean;
    }
  };
  const keyBlockSolTo = (e) => {
    if (e.shiftKey || (e.shiftKey && e.which === 51) || e.key === 'Dead') {
      e.preventDefault();
    }
    if (
      e.keyCode === 69 ||
      e.keyCode === 73 ||
      e.keyCode === 186 ||
      e.keyCode === 187 ||
      e.keyCode === 189 ||
      e.keyCode === 40 ||
      e.keyCode === 107 ||
      e.keyCode === 109 ||
      e.keyCode === 191 ||
      e.keyCode === 192 ||
      e.keyCode === 219 ||
      e.keyCode === 220 ||
      e.keyCode === 221 ||
      e.keyCode === 222 ||
      e.keyCode === 38 ||
      e.keyCode === 40 ||
      e.key === '{' ||
      e.key === '}' ||
      e.key === '+' ||
      e.key === '*' ||
      e.key === '[' ||
      e.key === '.' ||
      e.key === ']' ||
      e.key === '´' ||
      e.key === '/' ||
      e.key === '<' ||
      e.key === '+' ||
      e.key === '´´' ||
      e.key === 'ArrowLeft' ||
      e.key === 'BracketLeft' ||
      e.key === 'BracketRight' ||
      e.key === 'Quote' ||
      e.key === 'Shift' ||
      e.key === 'Dead' ||
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      e.shiftKey ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowUp'
    ) {
      e.preventDefault();
    }
  };
  const keyBlock = (e) => {
    if (e.shiftKey || (e.shiftKey && e.which === 51) || e.key === 'Dead') {
      e.preventDefault();
    }
    if (
      e.keyCode === 69 ||
      e.keyCode === 73 ||
      e.keyCode === 186 ||
      e.keyCode === 187 ||
      e.keyCode === 189 ||
      e.keyCode === 40 ||
      e.keyCode === 107 ||
      e.keyCode === 109 ||
      e.keyCode === 191 ||
      e.keyCode === 192 ||
      e.keyCode === 219 ||
      e.keyCode === 220 ||
      e.keyCode === 221 ||
      e.keyCode === 222 ||
      e.keyCode === 38 ||
      e.keyCode === 40 ||
      e.key === '{' ||
      e.key === '}' ||
      e.key === '+' ||
      e.key === '*' ||
      e.key === '[' ||
      e.key === ']' ||
      e.key === '´' ||
      e.key === '/' ||
      e.key === '<' ||
      e.key === '+' ||
      e.key === '´´' ||
      e.key === 'ArrowLeft' ||
      e.key === 'BracketLeft' ||
      e.key === 'BracketRight' ||
      e.key === 'Quote' ||
      e.key === 'Shift' ||
      e.key === 'Dead' ||
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      e.shiftKey ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowUp'
    ) {
      e.preventDefault();
    }
  };

  const formatFuncVolume = (valueNumber) => {
    let value = valueNumber.toString();

    let format = '';
    let decimal = value.indexOf('.');

    if (decimal > 0) {
      let a = new RegExp(/(^([0-9]*\.[0-9][0-9])$)/gi);

      let onlyTwoDecimals = value.match(a, '$1');

      if (!onlyTwoDecimals) {
        let splitDecimals = value.split('.');
        if (splitDecimals[1]) {
          let sub = splitDecimals[1].substring(0, 2);
          value = `${splitDecimals[0]}.${sub}`;
        }
      }
      if (value.length <= 18) {
        format = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        let val = value.substring(0, 18);
        format = `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    } else {
      if (value.length <= 11) {
        format = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        let val = value.substring(0, 11);
        format = `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
    return format;
  };
  return (
    <div>
      <Row>
        <Col span={8}>
          <Form.Item name="soldTo" label="*Sold to" style={{ height: 20 }}>
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              bordered={false}
              onKeyDown={keyBlockSolTo}
              placeholder="Escribe Sold To"
              max={2147483647}
              maxLength={10}
              min={0}
              stringMode
            />
          </Form.Item>
          {/*<BInput
            isRequired
            type="number"
            placeholder="Escribe Sold To"
            maxLength={9999999999}
            min={0}
            name="soldTo"
            label="Sold To"
          /> */}
        </Col>
        <Col span={8}>
          <BInput placeholder="Escribe nombre" maxLength={100} name="name" label="*Nombre" />
        </Col>

        <Col span={8}>
          <BInput
            placeholder="Escribe plazo"
            maxLength={50}
            className="form-add-user-input"
            name="termTime"
            label="*Plazo en el sistema"
          />
        </Col>

        <Col span={8}>
          <Form.Item name="creditLimit" label="*Límite de crédito" style={{ height: 20 }}>
            <InputNumber
              formatter={formatFuncVolume}
              parser={parserFunc}
              onKeyDown={keyBlock}
              style={{ border: '1px solid #d9d9d9' }}
              bordered={false}
              placeholder="Escribe límite"
              max={99999999999.99}
              maxLength={18}
              min={0}
              step="0.01"
              stringMode
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="placementId" label="*Presencia" style={{ height: 20 }}>
            <Select
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.placements &&
                getClientData.placements.map((d) => {
                  return (
                    <Option key={d.key} value={d.key}>
                      {d.value}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="typeId" label="Tipo" style={{ height: 20 }}>
            <Select
              allowClear
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.typeClient &&
                getClientData.typeClient.map((d) => {
                  return (
                    <Option key={d.key} value={d.key}>
                      {d.value}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col span={8}>
          <Form.Item name="lineId" label="*Línea de negocio" style={{ height: 20 }}>
            <Select
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.businessLine &&
                getClientData.businessLine.map((d) => {
                  return (
                    <Option key={d.key} value={d.key}>
                      {d.value}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="segmentationId" label="Segmentación" style={{ height: 20 }}>
            <Select
              allowClear
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.segmentation &&
                getClientData.segmentation.map((d) => {
                  return (
                    <Option key={d.key} value={d.key}>
                      {d.value}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="tradeAgreement"
            label="*¿Requiere acuerdo comercial?"
            style={{ height: 20 }}
          >
            <Select
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={true}>Si</Option>

              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col span={8}>
          <BInput
            placeholder="Escribe Dirección"
            maxLength={100}
            className="form-add-user-input"
            name="address"
            label="*Dirección:"
          />
        </Col>

        <Col span={8}>
          <Form.Item name="companyId" label="*Compañía:" style={{ height: 20 }}>
            <Select
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.company &&
                getClientData.company.map((d) => {
                  return (
                    <Option key={d.key} value={d.key}>
                      {d.value}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Client;
