import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_CHALLENGE_POG_FAIL,
  GET_CHALLENGE_POG_SUCCESS,
  GET_CHALLENGE_POG_REQUEST,
  GET_PRODUCTS_PRICES_REQUEST,
  GET_PRODUCTS_PRICES_SUCCESS,
  GET_PRODUCTS_PRICES_FAIL,
  ARE_VOLUMES_VALIDS,
  SAVE_CHALLENGE_REQUEST,
  SAVE_CHALLENGE_FAIL,
  SAVE_CHALLENGE_SUCCESS,
  CLEAN_REGISTER_SALE,
  GET_DAYS_LEFT_REQUEST,
  GET_DAYS_LEFT_SUCCESS,
  GET_DAYS_LEFT_FAIL,
} from '../actions/registerSale';
const filters = (state = {}, action) => {
  switch (action.type) {
    case GET_DAYS_LEFT_REQUEST:
      return {
        ...state,
        daysLeft: {
          isLoading: true,
        },
      };
    case GET_DAYS_LEFT_SUCCESS:
      return {
        ...state,
        daysLeft: {
          isLoading: true,
          data: action.payload,
        },
      };
    case GET_DAYS_LEFT_FAIL:
      return {
        ...state,
        daysLeft: {
          isLoading: false,
        },
      };
    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        productInfo: {
          isLoading: true,
        },
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        productInfo: {
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        productInfo: {
          isLoading: false,
        },
      };
    case GET_PRODUCTS_PRICES_REQUEST:
      return {
        ...state,
        productPrices: {
          isLoading: true,
        },
      };
    case GET_PRODUCTS_PRICES_SUCCESS:
      return {
        ...state,
        productPrices: {
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_PRODUCTS_PRICES_FAIL:
      return {
        ...state,
        productPrices: {
          isLoading: false,
        },
      };
    case GET_CHALLENGE_POG_REQUEST:
      return {
        ...state,
        challengePog: {
          isLoading: true,
        },
      };
    case GET_CHALLENGE_POG_SUCCESS:
      return {
        ...state,
        challengePog: {
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_CHALLENGE_POG_FAIL:
      return {
        ...state,
        challengePog: {
          isLoading: false,
        },
      };
    case ARE_VOLUMES_VALIDS:
      return {
        ...state,
        volumesValidsToSave: action.payload,
      };

    case SAVE_CHALLENGE_REQUEST:
      return {
        ...state,
        savingChallenge: true,
      };
    case SAVE_CHALLENGE_SUCCESS:
      return {
        ...state,
        savingChallenge: false,
      };
    case SAVE_CHALLENGE_FAIL:
      return {
        ...state,
        savingChallenge: false,
      };
    case CLEAN_REGISTER_SALE:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};

export default filters;
