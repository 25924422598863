export const SALES_CHALLENGE_VALIDATORS_CATALOG_REQUEST =
  'APP/SALES_CHALLENGE_VALIDATORS_CATALOG_REQUEST';
export const SALES_CHALLENGE_VALIDATORS_CATALOG_SUCCESS =
  'APP/SALES_CHALLENGE_VALIDATORS_CATALOG_SUCCESS';
export const SALES_CHALLENGE_VALIDATORS_CATALOG_FAIL =
  'APP/SALES_CHALLENGE_VALIDATORS_CATALOG_FAIL';

export function salesChallengeValidatorsCatalogRequest(payload) {
  return {
    type: SALES_CHALLENGE_VALIDATORS_CATALOG_REQUEST,
    payload: payload,
  };
}

export function salesChallengeValidatorsCatalogSuccess(payload) {
  return {
    type: SALES_CHALLENGE_VALIDATORS_CATALOG_SUCCESS,
    payload: payload,
  };
}

export function salesChallengeValidatorsCatalogFail(payload) {
  return {
    type: SALES_CHALLENGE_VALIDATORS_CATALOG_FAIL,
    payload: payload,
  };
}
