import { request } from '../../../services';

export async function changePassword(data) {
  return request
    .put(`/Auth/Api/v1/Password/Change`, {
      password: data.newPass,
      currentPassword: data.actualPass,
    })
    .then((response) => {
      return { data: response.data, message: response.message };
    })
    .catch((error) => {
      console.error(error);
      throw Error;
    });
}
