import React, { useEffect, useState } from 'react';
import { getBe } from '../services';
import BussinesEstructureSelect from './bussinesEstructureSelect';

function SubregionSelect({ regionsIds, required, lobsIds, multiple, onChange }) {
  const [subregions, setSubregions] = useState([]);

  useEffect(() => {
    if (lobsIds && (lobsIds.length > 0 || lobsIds > 0)) {
      getBe({
        element: 'subregions',
        region: regionsIds,
        lobs: lobsIds,
      }).then((res) => setSubregions(res));
    } else {
      setSubregions([]);
    }
  }, [regionsIds, lobsIds]);

  return (
    <BussinesEstructureSelect
      tag={'subregions'}
      label={'subregion'}
      required={required}
      options={subregions}
      disabled={subregions.length <= 0}
      multiple={multiple}
      onChange={onChange}
    />
  );
}
export default SubregionSelect;
