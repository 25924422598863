import { takeEvery, put, call, all, cancelled } from 'redux-saga/effects';
import {
  POST_ONE_DETAIL_PRICES_REQUEST,
  postOneDetailPricesClean,
  GET_DETAIL_PRICES_REQUEST,
  getCheckExistenceSuccess,
  getCheckExistenceClean,
} from '../actions';
import { postOneDetailPrices, getCheckExistence } from '../../services/detailPrices';
import { responseOK } from 'SalesChallengeRefactor/utils';
import instance from 'services/request';
import { openNotification } from 'common';

function* workPostOneDetailPricesRequest(action) {
  try {
    const { payload } = action;
    const { values, history } = payload;
    const {
      zoneId,
      lineId,
      subregionId,
      countryId,
      productNumber,
      soldTo,
      clientLineId,
      companyId,
      ap,
      list,
      net,
      brute,
      suggested,
      taxes,
    } = values;
    const response = yield call(postOneDetailPrices, {
      data: {
        zoneId,
        lineId,
        subregionId,
        countryId,
        productNumber,
        soldTo,
        clientLineId,
        companyId,
        ap,
        list,
        net,
        brute,
        suggested,
        taxes,
      },
    });
    if (responseOK(response)) {
      yield openNotification('success', response.message);
      setTimeout(() => {
        if (history) history.push('/catalog');
      }, 300);
    } else {
      console.error('[BASF[REDUX-SAGA]##');
    }
  } catch (error) {
    yield put(postOneDetailPricesClean());

    console.error('[BASF[REDUX-SAGA]', error);
  }
}

function* workGetCheckExistenceRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { priceId } = payload;
    const response = yield call(getCheckExistence, {
      priceId,
    });
    if (responseOK(response)) {
      yield put(getCheckExistenceSuccess(response.data));
      if (response.data === true) {
        yield openNotification('error', response.message);
      }
    } else {
      yield put(getCheckExistenceClean());
    }
  } catch (error) {
    yield put(getCheckExistenceClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}
export function* watchPostOneDetailPricesRequest() {
  yield all([takeEvery(POST_ONE_DETAIL_PRICES_REQUEST, workPostOneDetailPricesRequest)]);
}
export function* watchCreateOneCaseRequest() {
  yield all([takeEvery(GET_DETAIL_PRICES_REQUEST, workGetCheckExistenceRequest)]);
}
