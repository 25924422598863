import request from 'services/request';

export async function salesChallengeMassiveUpload({ cancelToken, data }) {
  let uri = `/Sales/Api/v1/Challenge/ChallengeMassLoad/MassLoadExcel`;
  return request(uri, {
    method: 'POST',
    cancelToken,
    data,
  });
}
