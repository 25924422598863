import { takeLatest, all, call, put, cancelled } from 'redux-saga/effects';
import { notification } from 'antd';
import { openNotification } from 'common';
import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';
import { TableServices } from '../../../services';

import { messageError } from 'utils/messages';
import { TableActions } from '../../actions';

const {
  CONSULT_MANAGE_IMAGES_REQUEST,
  ManageImagesCleanTable,
  ConsultManageImagesSetTable,
  // Obtiene la lista de imagenes por estructura comercial
  GET_LIST_IMAGES_REQUEST,
  GETListImagesClean,
  GETListImagesSet,
  //Obtiene imagen HD
  GET_IMAGE_HD_REQUEST,
  GETImageHDClean,
  GETImageHDSet,
} = TableActions;
const { ManageImagesGetTableData, GETListImages, GETImageHD } = TableServices;

//Obtiene imagen HD
function* workGETImageHD(action) {
  const { FileId } = action.payload;
  const { CancelToken } = instance;
  const source = CancelToken.source();
  let mustRedirectToHome = false;
  try {
    const response = yield call(GETImageHD, {
      cancelToken: source.token,
      params: { FileId: FileId },
    });
    if (responseOK(response)) {
      yield put(GETImageHDSet(response.data));
    } else {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield GETImageHDClean();
    console.error('SAGAS ERROR', error);
  } finally {
    if (yield cancelled()) {
      yield GETImageHDClean();
      source.cancel('cancelled Request');
    }
    if (mustRedirectToHome) {
      yield call(notification.destroy);
      yield call(messageError, 'No hay registros de ######## en el sistema');
    }
  }
}
export function* watchGETImageHD() {
  yield all([takeLatest(GET_IMAGE_HD_REQUEST, workGETImageHD)]);
}

// Obtiene la lista de imagenes por estructura comercial
function* workGETListImages(action) {
  const { listImages } = action.payload;

  const { CancelToken } = instance;
  const source = CancelToken.source();
  let mustRedirectToHome = false;
  try {
    const response = yield call(GETListImages, {
      cancelToken: source.token,
      params: listImages,
    });
    if (responseOK(response)) {
      let newInfo = [];
      for (let images of response.data) {
        newInfo.push({ ...images, ...listImages });
      }
      yield put(GETListImagesSet(newInfo));
    } else {
      yield put(GETListImagesClean());
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(GETListImagesClean());
    console.error('SAGAS ERROR', error);
  } finally {
    if (yield cancelled()) {
      yield put(GETListImagesClean());
      source.cancel('cancelled Request');
    }
    if (mustRedirectToHome) {
      yield call(notification.destroy);
      yield call(messageError, 'No hay registros de ######## en el sistema');
    }
  }
}
export function* watchGETListImages() {
  yield all([takeLatest(GET_LIST_IMAGES_REQUEST, workGETListImages)]);
}

// Obtiene La lista de estructura comercial
function* workManageImagesTable(action) {
  const { history } = action.payload;
  const { CancelToken } = instance;
  const source = CancelToken.source();
  let mustRedirectToHome = false;
  try {
    const response = yield call(ManageImagesGetTableData, {
      cancelToken: source.token,
    });
    if (responseOK(response)) {
      yield put(ConsultManageImagesSetTable(response.data));
    } else {
      yield openNotification('error', response.message);
      setTimeout(() => {
        history.push('/account-status');
      }, 500);
    }
  } catch (error) {
    yield ManageImagesCleanTable();
    console.error('SAGAS ERROR', error);
  } finally {
    if (yield cancelled()) {
      yield ManageImagesCleanTable();
      source.cancel('cancelled Request');
    }
    if (mustRedirectToHome) {
      yield call(notification.destroy);
      yield call(messageError, 'No hay registros de ######## en el sistema');
    }
  }
}
export function* watchManageImagesTable() {
  yield all([takeLatest(CONSULT_MANAGE_IMAGES_REQUEST, workManageImagesTable)]);
}
