import React from 'react';
import { Modal } from 'antd';
const actionButtonsStyle = {
  margin: '30px -30% 16px',
  color: 'white',
  borderRadius: '5px',
  width: '220px',
  height: '35px',
  fontSize: '1.1em',
  border: 'none',
  fontWeight: '600',
};

function summaryModal(message) {
  Modal.info({
    title: 'Resumen de registros leídos',
    className: 'summaryUploadModal',
    content: (
      <div>
        <p>{message}</p>
      </div>
    ),
    okText: 'Aceptar',
    okButtonProps: { size: 'middle', type: 'primary', style: actionButtonsStyle },
  });
}
export default summaryModal;
