import { combineReducers } from 'redux';

import {
  MARK_ALL_AS_READ_REQUEST,
  MARK_ALL_AS_READ_SUCCESS,
  MARK_AS_READ_REQUEST,
  MARK_AS_READ_SUCCESS,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  GET_NOTIFICATION_PAGE,
  GET_NOTIFICATION_TRUE,
  GET_NOTIFICATION_SUCCESS_TRUE,
  GET_NOTIFICATION_FAIL_TRUE,
  MARK_AS_READ_FAIL,
} from '../actions/notifications';

const notifications = (state = {}, action) => {
  switch (action.type) {
    case MARK_ALL_AS_READ_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MARK_ALL_AS_READ_SUCCESS:
      return {
        ...state,
        notificationsFalse: {
          isLoading: false,
          current: action.payload,
        },
      };

    case MARK_AS_READ_REQUEST:
      return {
        ...state,
      };
    case MARK_AS_READ_SUCCESS:
      return {
        ...state,
        notificationsFalse: {
          isLoading: false,
          current: action.payload,
          total: action.total,
        },
      };

    case MARK_AS_READ_FAIL:
      return state;

    //FALSE NOTIFICATIONS INITIAL
    case GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        notificationsFalse: {
          isLoading: true,
          current: action.payload,
        },
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationsFalse: {
          isLoading: false,
          current: action.payload,
          total: action.total,
          notif: 'success',
        },
      };

    case GET_NOTIFICATION_FAIL:
      return state;

    //FALSE NOTIFICATIONS
    case GET_NOTIFICATION_PAGE:
      return {
        ...state,
        notificationsFalse: {
          isLoading: true,
          current: [],
        },
      };

    //TRUE NOTIFICATIONS

    case GET_NOTIFICATION_TRUE:
      return {
        ...state,
        notificationsTrue: {
          isLoading: true,
          data: [],
        },
      };
    case GET_NOTIFICATION_SUCCESS_TRUE:
      return {
        ...state,
        notificationsTrue: {
          isLoading: false,
          data: action.payload,
          total: action.total,
        },
      };

    case GET_NOTIFICATION_FAIL_TRUE:
      return {
        ...state,
        notificationsTrue: {
          isLoading: false,
          data: [],
        },
      };

    default:
      return state;
  }
};

const appReducer = combineReducers({
  notifications,
});

export default appReducer;
