import {
  SET_CLIENT_KEY,
  CLEAN_CLIENT_KEY,
  SET_CURRENT_CLIENT_DATA,
  CLEAN_CURRENT_CLIENT_DATA,
} from '../actions/clientKey';

const clientKey = (state = {}, action) => {
  switch (action.type) {
    case SET_CLIENT_KEY:
      return {
        ...state,
        clientKey: action.payload,
      };
    case CLEAN_CLIENT_KEY:
      return {
        ...state,
        clientKey: undefined,
      };
    // Current Client Data
    case SET_CURRENT_CLIENT_DATA:
      return {
        ...state,
        currentClientData: action.payload,
      };
    case CLEAN_CURRENT_CLIENT_DATA:
      return {
        ...state,
        currentClientData: {},
      };
    default:
      return state;
  }
};

export default clientKey;
