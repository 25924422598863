import { takeLatest, put, cancelled, call } from 'redux-saga/effects';
import instance from 'services/request';
import { responseOK } from 'SalesChallengeRefactor/utils';
import { ShopSectionActions } from '../../actions';
import { requestSectionData } from '../../../services';

const {
  getShopSection,
  shopSectionFail,

  getShopSubRegions,
  shopSectionSubRegionsFail,

  SHOP_SECTION_REQUEST,

  SHOP_SECTION_REQUEST_SUB_REGIONS,
} = ShopSectionActions;

function* reqShopSectionData(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSectionData, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getShopSection(response.data));
    } else {
      yield put(shopSectionFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(shopSectionFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaRequestShopSectionData() {
  yield takeLatest(SHOP_SECTION_REQUEST, reqShopSectionData);
}

function* reqShopSubRegions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSectionData, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getShopSubRegions(response.data));
    } else {
      yield put(shopSectionSubRegionsFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(shopSectionSubRegionsFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaRequestShopSubRegions() {
  yield takeLatest(SHOP_SECTION_REQUEST_SUB_REGIONS, reqShopSubRegions);
}
