import {
  SET_VALIDATORS_QUERY,
  REQUEST_VALIDATORS_DISABLED_DEPENDING,
  SET_VALIDATORS_DISABLED_DEPENDING,
} from '../actions/filters';
import {
  validatorsFiltersSalesChallengeOrder,
  // validatorsFiltersTradeAgreementOrder,
} from 'Validations/containers/Filters';
import { getDisabledFiltersAndDependingFilter } from 'Validations/utils/filters';

const { disabled, depending } = getDisabledFiltersAndDependingFilter(
  { year: new Date().getFullYear() },
  validatorsFiltersSalesChallengeOrder
);

const defaultState = {
  disabledDepending: {
    disabled,
    depending,
  },
  query: {},
};

const filters = (state = defaultState, action) => {
  switch (action.type) {
    case SET_VALIDATORS_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case REQUEST_VALIDATORS_DISABLED_DEPENDING:
      return {
        ...state,
      };
    case SET_VALIDATORS_DISABLED_DEPENDING:
      return {
        ...state,
        disabledDepending: action.payload,
      };
    default:
      return state;
  }
};

export default filters;
