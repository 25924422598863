export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST/SALES-CHALLENGE';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS/SALES-CHALLENGE';
export const ADD_PRODUCT_FAIL = 'ADD_PRODUCT_FAIL/SALES-CHALLENGE';
export const PRODUCTS_SELECTED = 'PRODUCTS_SELECTED/SALES-CHALLENGE';
export const PRODUCTS_SELECTED_CLEAN = 'PRODUCTS_SELECTED_CLEAN';
export const PRODUCT_REMOVE_SELECTED = 'PRODUCT_REMOVE_SELECTED';

export function addProductRequest(payload) {
  return {
    type: ADD_PRODUCT_REQUEST,
    payload,
  };
}

export function addProductSuccess(payload) {
  return {
    type: ADD_PRODUCT_SUCCESS,
    payload,
  };
}

export function addProductFail() {
  return {
    type: ADD_PRODUCT_FAIL,
  };
}

export function productsSelected(payload) {
  return {
    type: PRODUCTS_SELECTED,
    payload,
  };
}

export function productSelectedClean() {
  return {
    type: PRODUCTS_SELECTED_CLEAN,
  };
}

export function productRemoveSelected(payload) {
  return {
    type: PRODUCT_REMOVE_SELECTED,
    payload,
  };
}
