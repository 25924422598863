import {
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_MODAL_OPEN,
  DELETE_PRODUCT_MODAL_CLOSE,
} from '../actions/deleteProduct';

const initialState = {
  showModal: false,
  productIdToDelete: '',
  mustResetFilters: false,
};

const deleteProduct = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_MODAL_OPEN: {
      return {
        ...state,
        showModal: true,
        productIdToDelete: action.payload,
        mustResetFilters: false,
      };
    }
    case DELETE_PRODUCT_MODAL_CLOSE: {
      return {
        ...state,
        showModal: false,
        productIdToDelete: ``,
      };
    }
    case DELETE_PRODUCT_REQUEST: {
      return {
        ...state,
        deleteProduct: {
          loading: true,
        },
        mustResetFilters: false,
      };
    }

    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        deleteProduct: {
          loading: false,
        },
        mustResetFilters: false,
      };
    case DELETE_PRODUCT_SUCCESS: {
      return {
        ...state,
        deleteProduct: {
          loading: false,
        },
        mustResetFilters: true,
      };
    }

    default:
      return state;
  }
};

export default deleteProduct;
