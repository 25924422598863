//Types clients
export const TYPE_CLIENT_REQUEST = 'TYPE_CLIENT_REQUEST';
export const TYPE_CLIENT_SUCCESS = 'TYPE_CLIENT_SUCCESS';
export const TYPE_CLIENT_CLEAN = 'TYPE_CLIENT_CLEAN';
//Types bussinesLines
export const BUSINESS_LINES_REQUEST = 'BUSINESS_LINES_REQUEST';
export const BUSINESS_LINES_SUCCESS = 'BUSINESS_LINES_SUCCESS';
export const BUSINESS_LINES_CLEAN = 'BUSINESS_LINES_CLEAN';
//Types segatation
export const SEGMENTATION_REQUEST = 'SEGMENTATION_REQUEST';
export const SEGMENTATION_SUCCESS = 'SEGMENTATION_SUCCESS';
export const SEGMENTATION_CLEAN = 'SEGMENTATION_CLEAN';
//Types company
export const COMPANY_REQUEST = 'COMPANY_REQUEST';
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';
export const COMPANY_CLEAN = 'COMPANY_CLEAN';
//Types territoiores
export const TERRITORIES_REQUEST = 'TERRITORIES_REQUEST';
export const TERRITORIES_SUCCESS = 'TERRITORIES_SUCCESS';
export const TERRITORIES_CLEAN = 'TERRITORIES_CLEAN';
//Types pogs
export const POGS_REQUEST = 'POGS_REQUEST';
export const POGS_SUCCESS = 'POGS_SUCCESS';
export const POGS_CLEAN = 'POGS_CLEAN';
//Types Currencies
export const CURRENCIES_REQUEST = 'CURRENCIES_REQUEST';
export const CURRENCIES_SUCCESS = 'CURRENCIES_SUCCESS';
export const CURRENCIES_CLEAN = 'CURRENCIES_CLEAN';
//types placements
export const PlACEMENTS_REQUEST = 'PlACEMENTS_REQUEST';
export const PlACEMENTS_SUCCESS = 'PlACEMENTS_SUCCESS';
export const PlACEMENTS_CLEAN = 'PlACEMENTS_CLEAN';
//Register clients
export const REGISTER_CLIENT_REQUEST = 'REGISTER_CLIENT_REQUEST';
export const REGISTER_CLIENT_SUCCESS = 'REGISTER_CLIENT_SUCCESS';
export const REGISTER_CLIENT_CLEAN = 'REGISTER_CLIENT_CLEAN';
//types responsable de la cuenta
export const RESPONSABLE_REQUEST = 'RESPONSABLE_REQUEST';
export const RESPONSABLE_SUCCESS = 'RESPONSABLE_SUCCESS';
export const RESPONSABLE_CLEAN = 'RESPONSABLE_CLEAN';
//Types get data client
export const READ_ONE_CLIENT_REQUEST = 'READ_ONE_CLIENT_REQUEST';
export const READ_ONE_CLIENT_SUCCESS = 'READ_ONE_CLIENT_SUCCESS';
export const READ_ONE_CLIENT_CLEAN = 'READ_ONE_CLIENT_CLEAN';
//validadte cliente
export const VALIDATE_CLIENT_REQUEST = 'VALIDATE_CLIENT_REQUEST';
export const VALIDATE_CLIENT_SUCCESS = 'VALIDATE_CLIENT_SUCCESS';
export const VALIDATE_CLIENT_CLEAN = 'VALIDATE_CLIENT_CLEAN';
// types responsable position de la cuenta
export const RESPONSABLE_POSITION_REQUEST = 'RESPONSABLE_POSITION_REQUEST';
export const RESPONSABLE_POSITION_SUCCESS = 'RESPONSABLE_POSITION_SUCCESS';
export const RESPONSABLE_POSITION_CLEAN = 'RESPONSABLE_POSITION_CLEAN';
export const STATUS_CLIENT_REQUEST = 'STATUS_CLIENT_REQUEST';
export const STATUS_CLIENT_SUCCESS = 'STATUS_CLIENT_SUCCESS';
export const STATUS_CLIENT_CLEAN = 'STATUS_CLIENT_CLEAN';
//validate client
export function statusClientRequest(payload) {
  return {
    type: STATUS_CLIENT_REQUEST,
    payload: payload,
  };
}

export function statusClientSuccess(payload) {
  return {
    type: STATUS_CLIENT_SUCCESS,
    payload: payload,
  };
}

export function statusClientClean() {
  return {
    type: STATUS_CLIENT_CLEAN,
    payload: {},
  };
}
//validate client
export function validateClientRequest(payload) {
  return {
    type: VALIDATE_CLIENT_REQUEST,
    payload: payload,
  };
}

export function validateClientSuccess(payload) {
  return {
    type: VALIDATE_CLIENT_SUCCESS,
    payload: payload,
  };
}

export function validateClientClean() {
  return {
    type: VALIDATE_CLIENT_CLEAN,
    payload: {},
  };
}

//get one client
export function readOneClientRequest(payload) {
  return {
    type: READ_ONE_CLIENT_REQUEST,
    payload: payload,
  };
}

export function readOneClientSuccess(payload) {
  return {
    type: READ_ONE_CLIENT_SUCCESS,
    payload: payload,
  };
}

export function readOneClientClean() {
  return {
    type: READ_ONE_CLIENT_CLEAN,
    payload: {},
  };
}

//get responsable position cuenta
export function getResponsablePositionRequest(payload) {
  return {
    type: RESPONSABLE_POSITION_REQUEST,
    payload: payload,
  };
}
export function getResponsablePositionSuccess(payload) {
  return {
    type: RESPONSABLE_POSITION_SUCCESS,
    payload: payload,
  };
}
export function getResponsablePositionClean() {
  return {
    type: RESPONSABLE_POSITION_CLEAN,
  };
}

//get responsable cuenta
export function getResponsableRequest(payload) {
  return {
    type: RESPONSABLE_REQUEST,
    payload: payload,
  };
}
export function getResponsableSuccess(payload) {
  return {
    type: RESPONSABLE_SUCCESS,
    payload: payload,
  };
}
export function getResponsableClean() {
  return {
    type: RESPONSABLE_CLEAN,
  };
}

//get Placements
export function getPlacementsRequest() {
  return {
    type: PlACEMENTS_REQUEST,
  };
}
export function getPlacementsSuccess(payload) {
  return {
    type: PlACEMENTS_SUCCESS,
    payload: payload,
  };
}
export function getPlacementsClean() {
  return {
    type: PlACEMENTS_CLEAN,
  };
}

//get tipo clientes
export function getTypeClientRequest() {
  return {
    type: TYPE_CLIENT_REQUEST,
  };
}
export function getTypeClientSuccess(payload) {
  return {
    type: TYPE_CLIENT_SUCCESS,
    payload: payload,
  };
}
export function getTypeClientClean() {
  return {
    type: TYPE_CLIENT_CLEAN,
  };
}

//get tipo clientes
export function getBusinessLinesRequest() {
  return {
    type: BUSINESS_LINES_REQUEST,
  };
}
export function getBusinessLinesSuccess(payload) {
  return {
    type: BUSINESS_LINES_SUCCESS,
    payload: payload,
  };
}
export function getBusinessLinesClean() {
  return {
    type: BUSINESS_LINES_CLEAN,
  };
}

//get tipo clientes
export function getSegmentationRequest() {
  return {
    type: SEGMENTATION_REQUEST,
  };
}
export function getSegmentationSuccess(payload) {
  return {
    type: SEGMENTATION_SUCCESS,
    payload: payload,
  };
}
export function getSegmentationClean() {
  return {
    type: SEGMENTATION_CLEAN,
  };
}

//get compañia
export function getCompanyRequest() {
  return {
    type: COMPANY_REQUEST,
  };
}
export function getCompanySuccess(payload) {
  return {
    type: COMPANY_SUCCESS,
    payload: payload,
  };
}
export function getCompanyClean() {
  return {
    type: COMPANY_CLEAN,
  };
}
//get territorios
export function getTerritoresRequest(payload) {
  return {
    type: TERRITORIES_REQUEST,
    payload: payload,
  };
}
export function getTerritoriesSuccess(payload) {
  return {
    type: TERRITORIES_SUCCESS,
    payload: payload,
  };
}
export function getTerritoriesClean() {
  return {
    type: TERRITORIES_CLEAN,
  };
}
//get pogs
export function getPogsRequest() {
  return {
    type: POGS_REQUEST,
  };
}
export function getPogsSuccess(payload) {
  return {
    type: POGS_SUCCESS,
    payload: payload,
  };
}
export function getPogsClean() {
  return {
    type: POGS_CLEAN,
  };
}
//get currencies
export function getCurrenciesRequest() {
  return {
    type: CURRENCIES_REQUEST,
  };
}
export function getCurrenciesSuccess(payload) {
  return {
    type: CURRENCIES_SUCCESS,
    payload: payload,
  };
}
export function getCurrenciesClean() {
  return {
    type: CURRENCIES_CLEAN,
  };
}

//Register Client
export function postClientRequest(payload) {
  return {
    type: REGISTER_CLIENT_REQUEST,
    payload: payload,
  };
}
export function postClientSuccess(payload) {
  return {
    type: REGISTER_CLIENT_SUCCESS,
    payload: payload,
  };
}
export function postClientClean(payload) {
  return {
    type: REGISTER_CLIENT_CLEAN,
    payload: payload,
  };
}
