import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import './i18n';
import './index.css';
import './assets/css/theme.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';

const token = localStorage.token;
render(
  // <React.StrictMode>
  <ConfigProvider locale={esES}>
    <App token={token} />
  </ConfigProvider>,
  // </React.StrictMode >
  document.getElementById('root')
);
serviceWorker.unregister();
