/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Row } from 'antd';
import RegionSelect from './regionSelect';
import LobSelect from './lobSelect';
import SubregionSelect from './subregionSelect';
import CountrySelect from './countrySelect';
import TerritorySelect from './territorySelect';
import ZoneSelect from './zoneSelect';
import { createBusinessEstructureObject } from './utils';
import { useHistory } from 'react-router-dom';
function BusinessEstructureFilters({
  value,
  parentTag,
  onChange,
  selectors,
  multipleSelectors,
  requiredSelectors,
}) {
  const [bussinesEstructure, setBussinesEstructure] = useState({});
  const handleChange = (item) => {
    onChange(createBusinessEstructureObject(bussinesEstructure, item));
  };
  const history = useHistory();

  const { location } = history;

  useEffect(() => {
    let selectsValue =
      value === undefined
        ? {}
        : value.beSelects !== undefined
        ? value.beSelects
        : value.regions !== undefined
        ? value
        : {};
    setBussinesEstructure(selectsValue);
  }, [value]);
  return (
    <Row id={`${parentTag}BussinesEstructureSelect`} align="top" justify="left">
      {(!selectors || selectors.some((selector) => selector === 'region')) && (
        <RegionSelect
          query={bussinesEstructure}
          multiple={multipleSelectors.includes('regions')}
          required={requiredSelectors.includes('region')}
          onChange={handleChange}
        />
      )}
      {(!selectors || selectors.some((selector) => selector === 'lob')) && (
        <LobSelect
          regionsIds={bussinesEstructure.regions}
          multiple={multipleSelectors.includes('lobs')}
          required={requiredSelectors.includes('lob')}
          onChange={handleChange}
        />
      )}
      {(!selectors || selectors.some((selector) => selector === 'subregion')) && (
        <SubregionSelect
          regionsIds={bussinesEstructure.regions}
          lobsIds={bussinesEstructure.lobs}
          multiple={multipleSelectors.includes('subregions')}
          required={requiredSelectors.includes('subregion')}
          onChange={handleChange}
        />
      )}
      {(!selectors || selectors.some((selector) => selector === 'territory')) && (
        <TerritorySelect
          subregionsIds={bussinesEstructure.subregions}
          lobsIds={bussinesEstructure.lobs}
          defaultValue={location?.state !== undefined ? location?.state?.territory : undefined}
          multiple={multipleSelectors.includes('territories')}
          required={requiredSelectors.includes('territory')}
          onChange={handleChange}
        />
      )}
      {(!selectors || selectors.some((selector) => selector === 'zone')) && (
        <ZoneSelect
          territoriesIds={bussinesEstructure.territories}
          multiple={multipleSelectors.includes('zones')}
          defaultValue={location?.state !== undefined ? location?.state.zone : undefined}
          required={requiredSelectors.includes('zone')}
          onChange={handleChange}
        />
      )}
      {location?.pathname !== '/catalog/selling-goals' &&
        (!selectors || selectors.some((selector) => selector === 'country')) && (
          <CountrySelect
            zonesIds={bussinesEstructure.zones}
            multiple={multipleSelectors.includes('countries')}
            required={requiredSelectors.includes('country')}
            onChange={handleChange}
          />
        )}
    </Row>
  );
}
export default BusinessEstructureFilters;
