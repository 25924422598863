import { takeLatest, all, call, put, cancelled } from 'redux-saga/effects';
import { notification } from 'antd';
import { openNotification } from 'common';
import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';
import { PositionImageServices } from '../../../services';

import { messageError } from 'utils/messages';
import { PositionImageActions } from '../../actions';

const {
  EDIT_MANAGE_IMAGES_POSITION_REQUEST,
  EditManageImagesCleanPosition,
  EditManageImagesSetPosition,
} = PositionImageActions;
const { ManageImagesPutPositionImage } = PositionImageServices;

function* workManageImagesPosition(action) {
  const { newPositionFile, dataEndpoint, okPUT } = action.payload;

  const { CancelToken } = instance;
  const source = CancelToken.source();
  let mustRedirectToHome = false;
  try {
    const response = yield call(ManageImagesPutPositionImage, {
      cancelToken: source.token,
      data: newPositionFile,
      endpoint: dataEndpoint.endpoint,
    });
    if (responseOK(response)) {
      yield put(EditManageImagesSetPosition(response.data));
      okPUT();
    } else {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield EditManageImagesCleanPosition();
    console.error('SAGAS ERROR', error);
  } finally {
    if (yield cancelled()) {
      yield EditManageImagesCleanPosition();
      source.cancel('cancelled Request');
    }
    if (mustRedirectToHome) {
      yield call(notification.destroy);
      yield call(messageError, 'No hay registros de ######## en el sistema');
    }
  }
}

export function* watchManageImagesPosition() {
  yield all([takeLatest(EDIT_MANAGE_IMAGES_POSITION_REQUEST, workManageImagesPosition)]);
}
