import reducer from './redux-saga/reducers/';

import { watchAccountStatusBaseInformationShoppingRequestConsult } from './redux-saga/sagas';

export default function getAccountStatusBaseInformationShoppingModule() {
  return {
    id: 'ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING',
    reducerMap: {
      ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING: reducer,
    },
    sagas: [watchAccountStatusBaseInformationShoppingRequestConsult],
  };
}
