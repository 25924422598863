import React, { useEffect, useState, Fragment, useContext } from 'react';
import BCard from './Cards';
import { Row } from 'antd';
import { appContext, layoutContext } from 'context';
import { getCatalogsObjects } from './utils';
import { getRemainingDays } from './services';
import { Link } from 'react-router-dom';

const ModulesTradeAgreementInfo = () => {
  const { onChangeActiveComponent } = useContext(layoutContext);
  const { modules, accountStatusPermissions } = useContext(appContext);
  const [catalog, setcatalog] = useState([]);

  useEffect(() => {
    setcatalog(accountStatusPermissions);
  }, [accountStatusPermissions]);

  const [modulesCards, setModules] = useState([]);

  let type = 'account-status';

  useEffect(() => {
    if (modulesCards.length === 0) {
      getRemainingDays(type).then((res) => {
        let uploadEnabled = res.daysLeft >= 0;
        let newModules = getCatalogsObjects(catalog, modules, type, uploadEnabled);
        if (newModules.length) {
          setModules(newModules);
        }
      });
      onChangeActiveComponent(undefined);
    }
  }, [modules, catalog, type, modulesCards, onChangeActiveComponent]);

  return (
    <>
      <Row gutter={[16, 16]} style={{ width: '100%' }}>
        {modulesCards &&
          modulesCards.map((module, key) => {
            if (module.empty || module.url === '/account-status/base-information') {
              return (
                <Fragment key={key}>
                  <Link
                    to={{
                      pathname: module.url,
                    }}
                    style={{ width: '33.33%' }}
                  >
                    <BCard title={module.name} span={24} empty={module.empty} url={module.url} />
                  </Link>
                </Fragment>
              );
            } else if (
              module.add ||
              module.upload ||
              module.consult ||
              module.clock ||
              module.folder
            ) {
              return (
                <Fragment key={key}>
                  <BCard
                    title={module.name}
                    add={module.add}
                    upload={module.upload}
                    consult={module.consult}
                    empty={module.empty}
                    clock={module.clock}
                    folder={module.folder}
                  />
                </Fragment>
              );
            } else {
              return null;
            }
          })}
      </Row>
    </>
  );
};

export default ModulesTradeAgreementInfo;
