import reducer from './redux-saga/reducers/';

import {
  watchUsersMassiveUploadSetAllowUpload,
  watchUsersMassiveUploadRequestVerify,
  watchUsersMassiveUploadRequestUpload,
} from './redux-saga/sagas';

export default function getUsersMassiveUploadModule() {
  return {
    id: 'USERS_MASSIVE_UPLOAD',
    reducerMap: {
      USERS_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchUsersMassiveUploadSetAllowUpload,
      watchUsersMassiveUploadRequestVerify,
      watchUsersMassiveUploadRequestUpload,
    ],
  };
}
