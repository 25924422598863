import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  ShoppingByFamily_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  ShoppingByFamily_MASSIVE_UPLOAD_REQUEST_VERIFY,
  ShoppingByFamily_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  ShoppingByFamilyMassiveUploadSetAllowUpload,
  ShoppingByFamilyMassiveUploadClearAllowUpload,
  ShoppingByFamilyMassiveUploadSetVerify,
  ShoppingByFamilyMassiveUploadClearVerify,
  ShoppingByFamilyMassiveUploadClearUpload,
  ShoppingByFamily_EXIST_STATEMENTS,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';

import { ShoppingByFamilyMassiveUpload, ExistsStatements } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workShoppingByFamilyMassiveUploadSetAllowUpload(action) {
  try {
    yield ShoppingByFamilyMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield ShoppingByFamilyMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield ShoppingByFamilyMassiveUploadClearAllowUpload();
    }
  }
}

function* workShoppingByFamilyMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de Compras por familia debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(ShoppingByFamilyMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(ShoppingByFamilyMassiveUploadSetVerify(data));
      yield call(summaryModal, message, false);
    } else {
      yield put(ShoppingByFamilyMassiveUploadClearVerify());
    }
  } catch (error) {
    yield ShoppingByFamilyMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield ShoppingByFamilyMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workShoppingByFamilyMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, overwrite } = payload;

  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(ShoppingByFamilyMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite,
      },
    });

    if (responseOK(response)) {
      yield call(
        summaryEndModal,
        response.data,
        history,
        '/account-status/base-information/shopping-by-family/consult'
      );
    } else {
      yield put(ShoppingByFamilyMassiveUploadClearUpload());
    }
  } catch (error) {
    yield ShoppingByFamilyMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield ShoppingByFamilyMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}

function* workExistsStatements({ payload }) {
  const { history } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(ExistsStatements, {
      cancelToken: source.token,
    });

    if (responseOK(response)) {
      if (response.data === false) {
        messageError(
          'Es necesario registrar la información correspondiente a elementos dinámicos en el sistema, antes de realizar el registro de POG. Realice el registro de elementos dinámicos de estado de cuenta para continuar con la operación.'
        );
        setTimeout(() => {
          history.push(`/account-status/base-information`);
        }, 100);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchExistsStatements() {
  yield all([takeEvery(ShoppingByFamily_EXIST_STATEMENTS, workExistsStatements)]);
}

export function* watchShoppingByFamilyMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(
      ShoppingByFamily_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
      workShoppingByFamilyMassiveUploadSetAllowUpload
    ),
  ]);
}

export function* watchShoppingByFamilyMassiveUploadRequestVerify() {
  yield all([
    takeEvery(
      ShoppingByFamily_MASSIVE_UPLOAD_REQUEST_VERIFY,
      workShoppingByFamilyMassiveUploadRequestVerify
    ),
  ]);
}

export function* watchShoppingByFamilyMassiveUploadRequestUpload() {
  yield all([
    takeEvery(
      ShoppingByFamily_MASSIVE_UPLOAD_REQUEST_UPLOAD,
      workShoppingByFamilyMassiveUploadRequestUpload
    ),
  ]);
}
