import {
  DAYS_LEFT_REQUEST,
  DAYS_LEFT_SUCCESS,
  DAYS_LEFT_FAIL,
  DAYS_LEFT_TYPES_REQUEST,
  DAYS_LEFT_TYPES_SUCCESS,
  DAYS_LEFT_TYPES_FAIL,
  CLEAN_INDICATORS,
  GET_CLIENT_SUMMARY_REQUEST,
  GET_CLIENT_SUMMARY_SUCCESS,
  GET_CLIENT_SUMMARY_FAIL,
  TERRITORY_DATA,
  ZONE_DATA,
} from '../actions/indicators';
const indicators = (state = {}, action) => {
  switch (action.type) {
    case DAYS_LEFT_REQUEST:
      return {
        ...state,
        daysLeft: {
          isLoading: true,
        },
      };
    case DAYS_LEFT_SUCCESS:
      return {
        ...state,
        daysLeft: {
          isLoading: true,
          data: action.payload,
        },
      };
    case DAYS_LEFT_FAIL:
      return {
        ...state,
        daysLeft: {
          isLoading: false,
        },
      };
    case CLEAN_INDICATORS:
      return {};
    case GET_CLIENT_SUMMARY_REQUEST:
      return {
        ...state,
        clientSummary: {
          isLoading: true,
        },
      };
    case GET_CLIENT_SUMMARY_SUCCESS:
      return {
        ...state,
        clientSummary: {
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_CLIENT_SUMMARY_FAIL:
      return {
        ...state,
        clientSummary: {
          isLoading: true,
        },
      };
    case TERRITORY_DATA:
      return {
        ...state,
        territoryData: {
          ...action.payload,
        },
      };
    case ZONE_DATA:
      return {
        ...state,
        zoneData: {
          ...action.payload,
        },
      };
    case DAYS_LEFT_TYPES_REQUEST:
      return {
        ...state,
        processTypes: {
          isLoading: true,
          data: state?.processTypes?.data ?? null,
        },
      };
    case DAYS_LEFT_TYPES_SUCCESS:
      return {
        ...state,
        processTypes: {
          isLoading: true,
          data: { ...state.processTypes.data, [action.payload.type]: action.payload },
        },
      };
    case DAYS_LEFT_TYPES_FAIL:
      return {
        ...state,
        processTypes: {
          isLoading: false,
          data: state.processTypes.data,
        },
      };
    default:
      return state;
  }
};
export default indicators;
