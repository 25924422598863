export const SET_PERSISTENT_TERRITORY = 'SET_PERSISTENT_TERRITORY/SC';

export const SET_PERSISTENT_LINE = 'SET_PERSISTENT_LINE/SC';

export const setPersistentTerritory = (territory) => ({
  type: SET_PERSISTENT_TERRITORY,
  payload: territory,
});

export const setPersistentLine = (line) => ({
  type: SET_PERSISTENT_LINE,
  payload: line,
});
