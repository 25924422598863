import React from 'react';
import PropTypes from 'prop-types';
import './titleIconCard.css';
import { Col, Row } from 'antd';
import { IconsMap } from '../../../Layout/utils';
import { Link } from 'react-router-dom';

const TitleIconCard = ({ title, icon, linkTo }) => {
  let Icon = IconsMap[icon];
  return (
    <>
      <div className={'title-icon-card'}>
        <Link to={linkTo}>
          <Row>
            <Col span={8} className={'icon'}>
              <Icon />
            </Col>
            <Col span={16} className={'title'}>
              {title}
            </Col>
          </Row>
        </Link>
      </div>
    </>
  );
};

TitleIconCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};

export default TitleIconCard;
