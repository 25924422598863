export const SALES_CHALLENGE_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD =
  'SALES_CHALLENGE_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const SALES_CHALLENGE_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'SALES_CHALLENGE_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const SALES_CHALLENGE_MASSIVE_UPLOAD_REQUEST_VERIFY =
  'SALES_CHALLENGE_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const SALES_CHALLENGE_MASSIVE_UPLOAD_SET_VERIFY =
  'SALES_CHALLENGE_MASSIVE_UPLOAD_SET_VERIFY';
export const SALES_CHALLENGE_MASSIVE_UPLOAD_CLEAR_VERIFY =
  'SALES_CHALLENGE_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const SALES_CHALLENGE_MASSIVE_UPLOAD_REQUEST_UPLOAD =
  'SALES_CHALLENGE_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const SALES_CHALLENGE_MASSIVE_UPLOAD_SET_UPLOAD =
  'SALES_CHALLENGE_MASSIVE_UPLOAD_SET_UPLOAD';
export const SALES_CHALLENGE_MASSIVE_UPLOAD_CLEAR_UPLOAD =
  'SALES_CHALLENGE_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function salesChallengeMassiveUploadSetAllowUpload(payload) {
  return {
    type: SALES_CHALLENGE_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function salesChallengeMassiveUploadClearAllowUpload(payload) {
  return {
    type: SALES_CHALLENGE_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function salesChallengeMassiveUploadRequestVerify(payload) {
  return {
    type: SALES_CHALLENGE_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function salesChallengeMassiveUploadSetVerify(payload) {
  return {
    type: SALES_CHALLENGE_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function salesChallengeMassiveUploadClearVerify(payload) {
  return {
    type: SALES_CHALLENGE_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function salesChallengeMassiveUploadRequestUpload(payload) {
  return {
    type: SALES_CHALLENGE_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function salesChallengeMassiveUploadSetUpload(payload) {
  return {
    type: SALES_CHALLENGE_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function salesChallengeMassiveUploadClearUpload(payload) {
  return {
    type: SALES_CHALLENGE_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
