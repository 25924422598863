/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { DynamicModuleLoader } from 'redux-dynamic-modules';
import dynamicSectionsModule from '../index';
import { connect } from 'react-redux';
import {
  IncentiveResumeSectionActions,
  ShopSectionActions,
  PogSectionActions,
  IncentiveCreditNotesActions,
  salesByFamilySectionActions,
  othersSectionActions,
  ocupationCreditLines,
  registerData,
  validateElementsActions,
} from '../redux-saga/actions';

import ResumeIncentiveSection from '../components/ResumeIncentivesSection';
import ShopsSection from '../components/ShopsSection';
import PogSection from '../components/PogSection/index';
import CreditNotesSection from '../components/IncentiveCreditNotes';
import SalesByFamilySection from '../components/salesByFamilySection';
import OthersSectionDynamic from '../components/othersSection';
import CreditLinesSection from '../components/CreditLineOcupation';
import { stateInitialParams, setStateParams } from '../utils/helpers';

import '../css/styles.css';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ActionButtons, BDivider } from 'common';
import { Row, Modal } from 'antd';
import { useHistory } from 'react-router-dom';

const { registerRequest } = registerData;

const { incentiveResumeRequest, cleanIncentiveResume, irSubRegionsRequest } =
  IncentiveResumeSectionActions;

const { shopSectionRequest, shopSubRegionsRequest } = ShopSectionActions;

const { pogSectionRequest, pogSubregionsRequest } = PogSectionActions;

const { icnClientsRequest, incSubregionsRequest } = IncentiveCreditNotesActions;

const { salesByFamilyClientRequest, salesByFamilySubgregionsRequest } = salesByFamilySectionActions;

const { othersClientsRequest, othersSubregionsReguest } = othersSectionActions;

const { creditLinesClientsRequest, creditLinesSubregionsRequest } = ocupationCreditLines;

const { validateElementsRequest, validateElementsClean } = validateElementsActions;

const DynamicSections = ({
  incentiveResumeRequest: getData,
  cleanIncentiveResume: cleanData,
  irSubRegionsRequest: getIrSubRegions,
  incentiveResumeData,
  incentiveResumeSubRegions,

  shopSectionRequest: getShgetShopSectionData,
  shopSectionData,
  shopSubRegionsRequest: getShopSubRegions,
  shopSectionSubRegions,

  pogSectionRequest: getPogSectionData,
  pogSectionData,
  pogSubregionsRequest: getPogSubregionData,
  pogsubregionData,

  icnClientsRequest: getIncentiveCreditNotesClients,
  incentiveCreditNotesData,
  incSubregionsRequest: getIncentiveCreditNotesSubregions,
  incSubregionsData,

  salesByFamilyClientRequest: getsalesByFamilyClient,
  salesByFamilyClient,
  salesByFamilySubgregionsRequest: getsalesByFamilySubregions,
  salesByFamilySubregions,

  othersSubregionsReguest: getOthersSubregionsData,
  othersClientsRequest: getOthersClientsData,
  othersSectionClients,
  othersSectionSubRegions,

  creditLinesClientsRequest: getIncentiveCreditLinesClients,
  oclClientsData,
  creditLinesSubregionsRequest: getCreditLinesSubregions,
  oclSubregionsData,
  registerRequest: postRegister,
  validateElementsRequest: validateElements,
  validateElementsClean: cleanElementsValidation,
}) => {
  const [sendParams, updateSendParams] = useState(stateInitialParams);

  let arrayDependecy = [
    incentiveResumeData,
    incentiveResumeSubRegions,
    shopSectionData,
    shopSectionSubRegions,
    pogSectionData,
    pogsubregionData,
    incentiveCreditNotesData,
    incSubregionsData,
    salesByFamilyClient,
    salesByFamilySubregions,
    oclClientsData,
    oclSubregionsData,
    othersSectionClients,
    othersSectionSubRegions,
  ];

  useEffect(() => {
    setStateParams(updateSendParams, sendParams, arrayDependecy);
  }, [...arrayDependecy]);

  const history = useHistory();

  useEffect(() => {
    validateElements({ history });
    getData({ SectionId: 4, ConsultBy: 1 });
    getIrSubRegions({ SectionId: 4, ConsultBy: 2 });

    getShgetShopSectionData({ SectionId: 2, ConsultBy: 1 });
    getShopSubRegions({ SectionId: 2, ConsultBy: 2 });

    getPogSectionData({ SectionId: 3, ConsultBy: 1 });
    getPogSubregionData({ SectionId: 3, ConsultBy: 2 });
    getIncentiveCreditNotesClients({ ConsultBy: 3 });
    getIncentiveCreditNotesSubregions({ ConsultBy: 4 });
    getsalesByFamilyClient({ SectionId: 6, ConsultBy: 1 });
    getsalesByFamilySubregions({ SectionId: 6, ConsultBy: 2 });
    getOthersClientsData({ SectionId: 7, ConsultBy: 1 });
    getOthersSubregionsData({ SectionId: 7, ConsultBy: 2 });

    getIncentiveCreditLinesClients({ SectionId: 5, ConsultBy: 1 });
    getCreditLinesSubregions({ SectionId: 5, ConsultBy: 2 });
    return () => {
      cleanData();
      cleanElementsValidation();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = () => {
    history.push('/account-status/dynamic-items/consult');
  };

  const Confirm = () => {
    Modal.confirm({
      title: 'Guardar Cambios',
      icon: <ExclamationCircleOutlined />,
      content: (
        <p>
          {' '}
          ¿Está seguro que desea guardar los cambios a la administración de secciones de estado de
          cuenta?{' '}
        </p>
      ),
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      onOk: () => {
        let paramsArray = [];
        let keySections = Object.keys(sendParams);
        keySections.forEach((d) => {
          paramsArray.push(sendParams[d]);
        });

        postRegister({
          history: history,
          paramsArray: paramsArray,
        });
      },
    });
  };

  return (
    <>
      <div className="componentsHolder">
        <BDivider title={'Sección de resumen de incentivos'} />
        <ResumeIncentiveSection
          incentiveResumeData={incentiveResumeData}
          incentiveResumeSubRegions={incentiveResumeSubRegions}
          sendParams={sendParams}
          updateSendParams={updateSendParams}
        />

        <BDivider title={'Sección de ocupación de línea de crédito'} />
        <CreditLinesSection
          oclClientsData={oclClientsData}
          oclSubregionsData={oclSubregionsData}
          sendParams={sendParams}
          updateSendParams={updateSendParams}
        />

        <BDivider title={'Sección de compras'} />
        <ShopsSection
          shopSectionData={shopSectionData}
          shopSectionSubRegions={shopSectionSubRegions}
          sendParams={sendParams}
          updateSendParams={updateSendParams}
        />

        <BDivider title={'Sección de POG'} />
        <PogSection
          pogSectionData={pogSectionData}
          pogsubregionData={pogsubregionData}
          sendParams={sendParams}
          updateSendParams={updateSendParams}
        />

        <BDivider title={'Sección de notas de crédito de incentivos'} />
        <CreditNotesSection
          incentiveCreditNotesData={incentiveCreditNotesData}
          incSubregionsData={incSubregionsData}
          sendParams={sendParams}
          updateSendParams={updateSendParams}
        />

        <BDivider title={'Sección de compras por familia'} />
        <SalesByFamilySection
          salesByFamilyClient={salesByFamilyClient}
          salesByFamilySubregions={salesByFamilySubregions}
          sendParams={sendParams}
          updateSendParams={updateSendParams}
        />

        <BDivider title={'Sección de otros'} />
        <OthersSectionDynamic
          othersSectionClients={othersSectionClients}
          othersSectionSubRegions={othersSectionSubRegions}
          sendParams={sendParams}
          updateSendParams={updateSendParams}
        />

        <Row>
          <section className="buttonsFooter">
            <ActionButtons onCancel={onCancel} onSubmit={Confirm} buttons={['cancel', 'submit']} />
          </section>
        </Row>
      </div>
    </>
  );
};

const firstProps = (state) => ({
  incentiveResumeData:
    state?.DYNAMICSECTIONSLOG?.incentiveResumeDataReducers?.IncentiveResumeData ?? [],
  incentiveResumeSubRegions:
    state?.DYNAMICSECTIONSLOG?.incentiveResumeDataReducers?.IrSubregionsData ?? [],
  adminSectionsSubRegionsErrorMsg: state?.DYNAMICSECTIONSLOG?.adminSectionsSubRegionsErrorMsg ?? '',

  shopSectionData: state?.DYNAMICSECTIONSLOG?.shopSectionDataReducers?.shopSectionData ?? [],
  shopSectionSubRegions:
    state?.DYNAMICSECTIONSLOG?.shopSectionDataReducers?.shopSectionSubRegions ?? [],
  pogSectionData: state?.DYNAMICSECTIONSLOG?.pogSectionDataReducers?.pogSectionData ?? [],
  pogsubregionData: state?.DYNAMICSECTIONSLOG?.pogSectionDataReducers?.pogsubregionData ?? [],
});

const mapStateToProps = (state) => {
  return {
    ...firstProps(state),
    incentiveCreditNotesData:
      state?.DYNAMICSECTIONSLOG?.incentiveCreditNotesReducers?.IncentiveCreditNotesData ?? [],
    incSubregionsData:
      state?.DYNAMICSECTIONSLOG?.incentiveCreditNotesReducers?.incSubregionsData ?? [],

    salesByFamilyClient:
      state?.DYNAMICSECTIONSLOG?.salesByFamilySectionDataReducers?.salesByFamilyClient ?? [],
    salesByFamilySubregions:
      state?.DYNAMICSECTIONSLOG?.salesByFamilySectionDataReducers?.salesByFamilySubregions ?? [],

    othersSectionSubRegions:
      state?.DYNAMICSECTIONSLOG?.othersSectionDataReducers?.othersSubRegionsData ?? [],
    othersSectionClients:
      state?.DYNAMICSECTIONSLOG?.othersSectionDataReducers?.othersClientsData ?? [],
    othersSectionSubRegionsErrorMsg:
      state?.DYNAMICSECTIONSLOG?.othersSectionSubRegionsErrorMsg ?? '',
    othersSectionClientsErrorMsg: state?.DYNAMICSECTIONSLOG?.othersSectionClientsErrorMsg ?? '',

    oclClientsData: state?.DYNAMICSECTIONSLOG?.ocupationCreditLinesReducers?.oclClientsData ?? [],
    oclSubregionsData:
      state?.DYNAMICSECTIONSLOG?.ocupationCreditLinesReducers?.oclSubregionsData ?? [],
  };
};

const LogWrapped = connect(mapStateToProps, {
  incentiveResumeRequest,
  cleanIncentiveResume,
  irSubRegionsRequest,

  shopSectionRequest,
  shopSubRegionsRequest,

  pogSectionRequest,
  pogSubregionsRequest,

  icnClientsRequest,
  incSubregionsRequest,

  salesByFamilyClientRequest,
  salesByFamilySubgregionsRequest,

  othersSubregionsReguest,
  othersClientsRequest,

  creditLinesClientsRequest,
  creditLinesSubregionsRequest,
  registerRequest,
  validateElementsRequest,
  validateElementsClean,
})(DynamicSections);

const DynamicSectionsLogs = () => (
  <DynamicModuleLoader modules={[dynamicSectionsModule()]}>
    <LogWrapped />
  </DynamicModuleLoader>
);

export default DynamicSectionsLogs;
