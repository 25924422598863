import React from 'react';
import { FormTitleDivider } from 'common';
import { Row, Col } from 'antd';
import { BusinessLine, SubRegion, Country } from 'RootModule/catalogs';

const CommercialStructure = (props) => {
  const {
    gutterValue,
    businessLineId,
    subregionId,
    countryHandleChange,
    countryOnFocus,
    countryDisabled,
    form,
  } = props;

  return (
    <>
      <FormTitleDivider title={'Estructura comercial'} />
      <Row gutter={gutterValue}>
        <Col xs={24} md={8}>
          <BusinessLine
            isRequired
            name="lineId"
            className="form-add-user-input"
            label="Línea de negocio"
            askFromService={true}
            sourceId={'Catalogs'}
            disabled={true}
          />
        </Col>
        <Col xs={24} md={8}>
          <SubRegion
            isRequired
            disabled={true}
            name="subregionId"
            className="form-add-user-input"
            label={'Subregión'}
            sourceId={'Catalogs'}
            requestParams={businessLineId ? { businessLines: [businessLineId] } : null}
          />
        </Col>
        <Col xs={24} md={8}>
          <Country
            mode="multiple"
            name="countries"
            className="form-add-user-input"
            label={'País'}
            sourceId={'Subregion'}
            requestParams={
              businessLineId && subregionId
                ? { subregions: subregionId, lines: businessLineId }
                : null
            }
            disabled={countryDisabled}
            selectAll={true}
            onChange={countryHandleChange}
            onFocus={countryOnFocus}
            updateTrigger={subregionId}
            form={form}
          />
        </Col>
      </Row>
    </>
  );
};

export default CommercialStructure;
