/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { IconsMap } from '../../utils';
import { Modal, Form, Input, Button } from 'antd';
import Styles from './modal-update-password.module.css';
import { LockOutlined } from '@ant-design/icons';
import { Loader } from '../../../global';
const { Password } = Input;
const ExclamationIcon = IconsMap.ExclamationCircleOutlined;

const ModalUpdatePassword = (props) => {
  const { showModalUpdatePassword, hideModal, loaderState, requiredPrevPass } = props;
  const [newForm, setNewForm] = useState({});
  const [minMax, setMinMax] = useState('red');
  const [capital, setCapital] = useState('red');
  const [number, setNumber] = useState('red');
  const [special, setSpecial] = useState('red');
  const [equal, setEqual] = useState('red');
  const [disabledButton, setDisabledButton] = useState(true);

  const PasswordRegexValidation = (value) => {
    // CAPITAL LETTER
    let regExpCapital = new RegExp(/(?:[A-Z])/).test(value);
    // ONE NUMBER
    let regExpNumber = new RegExp(/(?=.*\d)/).test(value);
    // SPECIAL CHAR
    let regExpSpecial = new RegExp(/(?=.*[!@\-#$%&])/).test(value);
    //MIN MAX LENGTH
    let regExpMinMax = new RegExp(/^.{8,16}$/).test(value);
    if (regExpCapital) {
      setCapital('green');
    } else {
      setCapital('red');
    }
    if (regExpNumber) {
      setNumber('green');
    } else {
      setNumber('red');
    }
    if (regExpSpecial) {
      setSpecial('green');
    } else {
      setSpecial('red');
    }
    if (regExpMinMax) {
      setMinMax('green');
    } else {
      setMinMax('red');
    }
    if (regExpCapital && regExpNumber && regExpSpecial && regExpMinMax) {
      return true;
    } else {
      return false;
    }
  };

  const onChangePass = (e) => {
    setNewForm({ ...newForm, [e.target.name]: e.target.value });
  };

  const destroyAll = () => {
    Modal.destroyAll();
    setNewForm({});
    setDisabledButton(true);
    setCapital('red');
    setNumber('red');
    setSpecial('red');
    setMinMax('red');
    setEqual('red');
  };

  useEffect(() => {
    let validations = false;
    if (newForm.newPass) {
      validations = PasswordRegexValidation(newForm.newPass);
    } else {
      setCapital('red');
      setNumber('red');
      setSpecial('red');
      setMinMax('red');
      setEqual('red');
    }
    if (newForm.newPass && newForm.confirmPass && newForm.newPass === newForm.confirmPass) {
      if ((validations && newForm.actualPass) || (validations && !requiredPrevPass)) {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
      setEqual('green');
    } else {
      setEqual('red');
      setDisabledButton(true);
    }
  }, [newForm]);

  return (
    <Modal
      destroyOnClose
      visible={showModalUpdatePassword}
      footer={null}
      onCancel={hideModal}
      width={600}
      closable={false}
      maskClosable={false}
      keyboard={false}
    >
      {loaderState ? (
        <div className={Styles['container-modal']}>
          <Loader color="#00499a" size="medium" />
        </div>
      ) : (
        <div className={Styles['container-modal']}>
          <div className={Styles['container-modal-title']}>
            <ExclamationIcon />
            <p>Actualizar contraseña</p>
          </div>
          <div className={Styles['container-modal-text']}>
            <div className={Styles['container-text']}>
              <p style={{ marginBottom: '25px' }}>
                La contraseña debe contener las siguiente características:
              </p>
              <p className={Styles['container-paragraph']} style={{ color: minMax }}>
                • 8 a 16 caracteres alfanuméricos
              </p>
              <p className={Styles['container-paragraph']} style={{ color: capital }}>
                • Al menos una letra mayúscula{' '}
              </p>
              <p className={Styles['container-paragraph']} style={{ color: number }}>
                • Al menos un número
              </p>
              <p className={Styles['container-paragraph']} style={{ color: special }}>
                • Al menos un caracter especial (!, @, -, #, $, %, &)
              </p>
              <p className={Styles['container-paragraph']} style={{ color: equal }}>
                • El campo "Nueva contraseña" y "Confirmar contraseña" deben coincidir
              </p>
            </div>
            <div className={Styles['container-form']}>
              <Form name="register" scrollToFirstError style={{ width: '100%' }}>
                {requiredPrevPass && (
                  <Password
                    name="actualPass"
                    className={Styles['container-pass']}
                    placeholder="Contraseña actual"
                    prefix={<LockOutlined />}
                    onChange={(e) => onChangePass(e)}
                  />
                )}
                <Password
                  name="newPass"
                  className={Styles['container-pass']}
                  placeholder="Nueva contraseña"
                  prefix={<LockOutlined />}
                  onChange={(e) => onChangePass(e)}
                />
                <Password
                  name="confirmPass"
                  className={Styles['container-pass']}
                  placeholder="Confirmar constraseña"
                  prefix={<LockOutlined />}
                  onChange={(e) => onChangePass(e)}
                />
                <div className="container-modal-buttons" style={{ marginLeft: '40px' }}>
                  <Button
                    onClick={() => {
                      hideModal();
                      destroyAll();
                    }}
                  >
                    Cancelar
                  </Button>
                  <div style={{ marginLeft: '10px' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={disabledButton}
                      onClick={() => {
                        hideModal(newForm);
                        destroyAll();
                      }}
                    >
                      Aceptar
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ModalUpdatePassword;
