/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';
import { IconsMap } from './utils';
import { NavLink, useHistory } from 'react-router-dom';
import { layoutContext } from '../context';
import { Modal } from 'antd';
import './layout.css';

function NavItem({ collapsed, setCollapse, modules, item, buttom, setSubMenu, subMenu }) {
  const { activeComponent, onChangeActiveComponent } = useContext(layoutContext);
  const history = useHistory();
  let Icon = IconsMap[`${item.icon}`];
  let Arrow = subMenu ? IconsMap['CaretUpOutlined'] : IconsMap['CaretDownOutlined'];
  let itemLink = item.link ? item.link : '';

  const handleClick = (e, element) => {
    e.preventDefault();
    if (!collapsed) {
      setCollapse(!collapsed);
      if (element.children && element.children.length > 0) {
        setCollapse(false);
        setSubMenu(!subMenu);
      } else {
        setSubMenu(false);
      }
    } else {
      if (element.children && element.children.length > 0) {
        setSubMenu(!subMenu);
      } else {
        setSubMenu(false);
      }
    }

    if (element.link) {
      let toRoute = element.link;
      let componentName = e.currentTarget.name;
      if (
        (activeComponent !== componentName && activeComponent !== undefined) ||
        activeComponent === componentName
      ) {
        Modal.confirm({
          title: 'Abandonar operación de sistema',
          content:
            '¿Está seguro que desea abandonar la operación? La información que no haya sido almacenada se perderá.',
          okText: 'Aceptar',
          cancelText: 'Cancelar',
          centered: true,
          okButtonProps: { type: 'info', style: { backgroundColor: '#003d81', color: '#ffffff' } },
          cancelButtonProps: {
            type: 'default',
            style: { backgroundColor: '#0078B3', color: '#ffffff' },
          },
          onOk() {
            for (let i = 0; i < 20; i++) {
              localStorage.removeItem(i);
            }
            onChangeActiveComponent(undefined);
            history.push(`${toRoute}`);
          },
        });
      } else {
        history.push(`${toRoute}`);
      }
    }
  };
  return (
    <div className={!collapsed ? 'nav-item' : 'nav-item-collapse'}>
      <NavLink
        name={item.module}
        onClick={(e) => handleClick(e, item)}
        to={itemLink}
        route={itemLink}
        activeClassName="module-selected"
        className="module-nav"
      >
        <p className={buttom && 'buttom'} onClick={() => setSubMenu(!subMenu)}>
          <Icon />
          {!collapsed && <span style={{ marginLeft: '5px' }}>{item.label}</span>}
          {!collapsed && item.children && item.children.length > 0 ? (
            <Arrow style={{ position: 'absolute', right: '20px' }} />
          ) : null}
        </p>
      </NavLink>
      {item.children && item.children.length > 0 && subMenu && (
        <div className={!collapsed ? 'nav-container-subitem' : 'nav-subitem-collapsed'}>
          {item.children.map((subItem, key) => {
            if (modules.some((module) => module === subItem.component.toLowerCase())) {
              // eslint-disable-next-line no-shadow
              let Icon = IconsMap[`${subItem.icon}`];
              return (
                <NavLink
                  name={subItem.component}
                  onClick={(e) => handleClick(e, subItem)}
                  key={key}
                  to={subItem.link}
                  route={subItem.link}
                >
                  <p>
                    <Icon />
                    <span style={{ marginLeft: '5px' }}>{subItem.label}</span>
                  </p>
                </NavLink>
              );
            } else return null;
          })}
        </div>
      )}
    </div>
  );
}
export default NavItem;
