import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import React from 'react';
import errorMessages from './errorMessages';
import './massiveUploadTableColumns.css';

const columnsMxCrop = [
  {
    key: 'phase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'phase',
    className: 'title-masive',
  },
  //Titulos de columas y nombres en Back
  {
    key: 'clasification',
    title: 'Clasificación',
    dataIndex: 'clasification',
  },
  {
    key: 'productName',
    title: 'Nombre de producto',
    dataIndex: 'productName',
  },
  {
    key: 'presentation',
    title: 'Presentación',
    dataIndex: 'presentation',
  },
  {
    key: 'packageUnits',
    title: 'Unidades por empaque',
    dataIndex: 'packageUnits',
  },
  {
    key: 'ieps',
    title: 'IEPS%',
    dataIndex: 'ieps',
  },
  {
    key: 'minPrice',
    title: 'Pedido mínimo sugerido a usuario final',
    dataIndex: 'minPrice',
  },
  {
    key: 'measurement',
    title: 'Unidad',
    dataIndex: 'measurement',
  },
];
export { columnsMxCrop };
