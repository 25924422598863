export const APPROVE_TRADE_AGREEMENT_REQUEST = 'APPROVE_TRADE_AGREEMENT_REQUEST';
export const APPROVE_TRADE_AGREEMENT_SUCCESS = 'APPROVE_TRADE_AGREEMENT_SUCCESS';
export const APPROVE_TRADE_AGREEMENT_FAIL = 'APPROVE_TRADE_AGREEMENT_FAIL';

export const REJECT_TRADE_AGREEMENT_REQUEST = 'REJECT_TRADE_AGREEMENT_REQUEST';
export const REJECT_TRADE_AGREEMENT_SUCCESS = 'REJECT_TRADE_AGREEMENT_SUCCESS';
export const REJECT_TRADE_AGREEMENT_FAIL = 'REJECT_TRADE_AGREEMENT_FAIL';

export const APPROVE_TRADE_AGREEMENT_KAM_REQUEST = 'APPROVE_TRADE_AGREEMENT_KAM_REQUEST';
export const APPROVE_TRADE_AGREEMENT_KAM_SUCCESS = 'APPROVE_TRADE_AGREEMENT_KAM_SUCCESS';
export const APPROVE_TRADE_AGREEMENT_KAM_FAIL = 'APPROVE_TRADE_AGREEMENT_KAM_FAIL';

export const REJECT_TRADE_AGREEMENT_KAM_REQUEST = 'REJECT_TRADE_AGREEMENT_KAM_REQUEST';
export const REJECT_TRADE_AGREEMENT_KAM_SUCCESS = 'REJECT_TRADE_AGREEMENT_KAM_SUCCESS';
export const REJECT_TRADE_AGREEMENT_KAM_FAIL = 'REJECT_TRADE_AGREEMENT_KAM_FAIL';

export const GENERATE_TRADE_AGREEMENT_REQUEST = 'GENERATE_TRADE_AGREEMENT_REQUEST';
export const GENERATE_TRADE_AGREEMENT_SUCCESS = 'GENERATE_TRADE_AGREEMENT_SUCCESS';
export const GENERATE_TRADE_AGREEMENT_FAIL = 'GENERATE_TRADE_AGREEMENT_FAIL';

export function approveTradeAgreementRequest(payload) {
  return {
    type: APPROVE_TRADE_AGREEMENT_REQUEST,
    payload,
  };
}

export function approveTradeAgreementSuccess(payload) {
  return {
    type: APPROVE_TRADE_AGREEMENT_SUCCESS,
    payload,
  };
}

export function approveTradeAgreementAgreementFail() {
  return {
    type: APPROVE_TRADE_AGREEMENT_FAIL,
  };
}

export function rejectTradeAgreementRequest(payload) {
  return {
    type: REJECT_TRADE_AGREEMENT_REQUEST,
    payload,
  };
}

export function rejectTradeAgreementSuccess(payload) {
  return {
    type: REJECT_TRADE_AGREEMENT_SUCCESS,
    payload,
  };
}

export function rejectTradeAgreementegreementFail() {
  return {
    type: REJECT_TRADE_AGREEMENT_FAIL,
  };
}

export function approveTradeAgreementRequestByKAM(payload) {
  return {
    type: APPROVE_TRADE_AGREEMENT_KAM_REQUEST,
    payload,
  };
}

export function approveTradeAgreementSuccessByKAM(payload) {
  return {
    type: APPROVE_TRADE_AGREEMENT_KAM_SUCCESS,
    payload,
  };
}

export function approveTradeAgreementAgreementFailByKAM() {
  return {
    type: APPROVE_TRADE_AGREEMENT_KAM_FAIL,
  };
}

export function rejectTradeAgreementRequestByKAM(payload) {
  return {
    type: REJECT_TRADE_AGREEMENT_KAM_REQUEST,
    payload,
  };
}

export function rejectTradeAgreementSuccessByKAM(payload) {
  return {
    type: REJECT_TRADE_AGREEMENT_KAM_SUCCESS,
    payload,
  };
}

export function rejectTradeAgreementegreementFailByKAM() {
  return {
    type: REJECT_TRADE_AGREEMENT_KAM_FAIL,
  };
}

export function generateTradeAgreementRequest(payload) {
  return {
    type: GENERATE_TRADE_AGREEMENT_REQUEST,
    payload,
  };
}

export function generateradeAgreementSuccess(payload) {
  return {
    type: GENERATE_TRADE_AGREEMENT_SUCCESS,
    payload,
  };
}

export function generateTradeAgreementAgreementFail() {
  return {
    type: GENERATE_TRADE_AGREEMENT_FAIL,
  };
}
