export const FILTERS = {
  regions: 'regions',
  bussinesLine: 'bussinesLine',
  subregions: 'subregions',
  zones: 'zones',
  areas: 'areas',
  yearOptions: 'yearOptions',
  agreementChallenge: 'agreementChallenge',
  all: 'ALL',
};
