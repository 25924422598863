import { numberToArray } from './addCommasToFloat';

const reduceDecimalsOfFloat = (flo) => {
  if (flo) {
    let fixed = flo.toFixed(2);
    const floatString = `${fixed}`;
    const indexOfDot = floatString.indexOf('.');
    const decimalsDesired = 2;
    const numberParts = numberToArray(floatString);
    if (indexOfDot === -1) {
      return `${floatString}.00`;
    }
    if (numberParts[1] && numberParts[1].length > decimalsDesired) {
      numberParts[1] = numberParts[1].substring(0, decimalsDesired);
      // if (numberParts[1] < 10) {
      //   numberParts[1] = numberParts[1] + 10;
      // }
    }
    return numberParts.join('.');
  } else {
    return 0;
  }
};

export default reduceDecimalsOfFloat;
