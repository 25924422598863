/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  consultFiltersTerritoriesRequestOptions,
  consultFiltersTerritoriesSetValue,
} from 'RootModule/redux/actions/consultFilters';
import { addAllBtnOnOptions, handleAllBtnClick } from 'common/consults/utils';

import Filter from 'common/consults/components/Filter';

const Territories = (props) => {
  const {
    consultFiltersTerritoriesRequestOptions: requestOptions,
    consultFiltersTerritoriesSetValue: setValue,
    value,
    required,
    options,
    params,
    disableSelectAll,
  } = props;

  let mappedOptions = [...options];

  let jsonParams = JSON.stringify(params);

  useEffect(() => {
    let sentParams = {};
    if (params) {
      sentParams = { ...params };
    }
    requestOptions(sentParams);
  }, [jsonParams]);

  const selectChange = (selected) => {
    selected = handleAllBtnClick(selected, options);
    setValue(selected);
  };
  if (!disableSelectAll) {
    mappedOptions = addAllBtnOnOptions(options);
  }
  return (
    <Filter
      name={'Territorio'}
      required={required}
      value={value}
      selectChange={selectChange}
      options={mappedOptions}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    options: state?.APP?.consultFilters.territories?.options ?? [],
    value: state?.APP?.consultFilters.territories?.value,
  };
};

Territories.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  required: PropTypes.bool,
  options: PropTypes.array,
  params: PropTypes.object,
  disableSelectAll: PropTypes.bool,
};

Territories.defaultProps = {
  value: [],
  required: false,
  options: [],
  params: {},
  disableSelectAll: false,
};

export default connect(mapStateToProps, {
  consultFiltersTerritoriesRequestOptions,
  consultFiltersTerritoriesSetValue,
})(Territories);
