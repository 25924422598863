import { salesByFamilySectionActions } from '../../actions';

const {
  SALESBYFAMILY_CLIENTS_REQUEST,
  SALESBYFAMILY_CLIENTS_SET,
  SALESBYFAMILY_CLIENTS_CLEAN,
  SALESBYFAMILY_SUBREGIONS_REQUEST,
  SALESBYFAMILY_SUBREGIONS_SET,
  SALESBYFAMILY_SUBREGIONS_CLEAN,
} = salesByFamilySectionActions;

const salesByFamilySectionDataReducers = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SALESBYFAMILY_SUBREGIONS_REQUEST:
      return {
        ...state,
        salesByFamilySubregions: null,
      };
    case SALESBYFAMILY_SUBREGIONS_SET:
      return {
        ...state,
        salesByFamilySubregions: payload,
      };
    case SALESBYFAMILY_SUBREGIONS_CLEAN:
      return {
        ...state,
        salesByFamilySubregions: [],
      };
    case SALESBYFAMILY_CLIENTS_REQUEST:
      return {
        ...state,
        salesByFamilyClient: null,
      };
    case SALESBYFAMILY_CLIENTS_SET:
      return {
        ...state,
        salesByFamilyClient: payload,
      };
    case SALESBYFAMILY_CLIENTS_CLEAN:
      return {
        ...state,
        salesByFamilyClient: [],
      };

    default:
      return state;
  }
};

export default salesByFamilySectionDataReducers;
