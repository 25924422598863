/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Card } from 'antd';

const CardText = () => {
  return (
    <Card style={{ borderWidth: 1, borderColor: ' #e6001d' }}>
      <p className="card-text-color">
        Recuerde que la información que coloque en la columna "Periodo de corte" se visualizará en
        el archivo de estado de cuenta del cliente y se sustituirá en las cargas de Objetivos e
        incentivos previas para la combinación de Cliente,Línea de negocio y Año
      </p>
    </Card>
  );
};

export default CardText;
