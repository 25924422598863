import React, { useEffect, useState } from 'react';
import { getBe } from '../services';
import BussinesEstructureSelect from './bussinesEstructureSelect';

function CountrySelect({ zonesIds, required, multiple, onChange }) {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (zonesIds && (zonesIds.length > 0 || zonesIds > 0)) {
      getBe({ element: 'countries', zones: zonesIds }).then((res) => setCountries(res));
    } else {
      setCountries([]);
    }
  }, [zonesIds]);

  return (
    <BussinesEstructureSelect
      tag={'countries'}
      label={'country'}
      required={required}
      options={countries}
      disabled={countries.length <= 0}
      multiple={multiple}
      onChange={onChange}
    />
  );
}
export default CountrySelect;
