export const aproveClientModal = {
  modal: {
    title: 'Validar acuerdo comercial',
    content: '¿Está seguro que desea aprobar el acuerdo comercial para el cliente?',
  },
};

export const rejectClientModal = {
  modal: {
    title: 'Validar acuerdo comercial',
    content: '¿Está seguro que desea rechazar el acuerdo comercial para el cliente?',
  },
};
