/**actions */
export const GET_PROCESS_DATES_REQUEST = 'GET_PROCESS_DATES_REQUEST';
export const GET_PROCESS_DATES_SUCCESS = 'GET_PROCESS_DATES_SUCCESS';
export const GET_PROCESS_DATES_FAIL = 'GET_PROCESS_DATES_FAIL';

export const REGISTER_PROCESS_DATES_REQUEST = 'REGISTER_PROCESS_DATES_REQUEST';
export const REGISTER_PROCESS_DATES_SUCCESS = 'REGISTER_PROCESS_DATES_SUCCESS';
export const REGISTER_PROCESS_DATES_FAIL = 'REGISTER_PROCESS_DATES_FAIL';

export const EDIT_PRODUCT_STATUS = 'EDIT_PRODUCT_STATUS';

export function getProcessDatesRequest(payload) {
  return {
    type: GET_PROCESS_DATES_REQUEST,
    payload,
  };
}

export function getProcessDatesSuccess(payload) {
  return {
    type: GET_PROCESS_DATES_SUCCESS,
    payload,
  };
}

export function getProcessDatesFail(payload) {
  return {
    type: GET_PROCESS_DATES_FAIL,
    payload,
  };
}

export function registerProcessDatesRequest(payload) {
  return {
    type: REGISTER_PROCESS_DATES_REQUEST,
    payload,
  };
}

export function registerProcessDatesSuccess(payload) {
  return {
    type: REGISTER_PROCESS_DATES_SUCCESS,
    payload,
  };
}

export function registerProcessDatesFail(payload) {
  return {
    type: REGISTER_PROCESS_DATES_FAIL,
    payload,
  };
}

export function editProductStatus(payload) {
  return {
    type: EDIT_PRODUCT_STATUS,
    payload,
  };
}
