import React, { useEffect, useState, useContext } from 'react';
import { Form, Row } from 'antd';
import { FormTitleDivider, ActionButtons } from 'common';
import { CommercialStructure, Prices } from '../Components';
import { connect } from 'react-redux';
import integerFloat from '../integerFloat';
import { postOneRegisterPricesRequest, getCheckExistenceRequest } from '../redux-saga/actions';
import getRegisterPricesModule from '../registerPricesModule';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { layoutContext } from '../../../context';
import getRootModule from 'RootModule';
import { useHistory } from 'react-router-dom';

const RegisterPricesForm = (props) => {
  const {
    postOneRegisterPricesRequest: postOneRPR,
    getCheckExistenceRequest: getCheckExist,
    // regPricesData,
  } = props;
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [btnTF, setbtnTF] = useState(true);
  const history = useHistory();
  const [dataProd, setdataProd] = useState(null);

  const intFloat = (e) => {
    try {
      let { keyId, valueIF } = integerFloat(e);

      if (keyId === 'taxes') {
        form.setFieldsValue({ taxes: valueIF });
      } else if (keyId === 'suggested') {
        form.setFieldsValue({ suggested: valueIF });
      } else if (keyId === 'brute') {
        form.setFieldsValue({ brute: valueIF });
      } else if (keyId === 'net') {
        form.setFieldsValue({ net: valueIF });
      } else if (keyId === 'list') {
        form.setFieldsValue({ list: valueIF });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    onChangeActiveComponent('Users');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const OnValuesChange = (e) => {
    intFloat(e);
    let completeForm = form.getFieldsValue();
    switchBtn();
    setdataProd(completeForm);
  };
  //Verificar Precio duplicado
  const checkExistence = (value) => {
    let dataForm = form.getFieldsValue();
    let prodNum = value;
    if (
      dataForm.soldTo &&
      dataForm.companyId &&
      dataForm.lineId &&
      dataForm.subregionId &&
      dataForm.zoneId &&
      dataForm.countryId &&
      dataForm.clientLineId &&
      prodNum
    ) {
      getCheckExist(dataForm);
    }
  };
  //Btn True or False
  const switchBtn = () => {
    let dataForm = form.getFieldsValue();
    if (
      dataForm.zoneId &&
      dataForm.lineId &&
      dataForm.subregionId &&
      dataForm.countryId &&
      dataForm.productNumber &&
      dataForm.soldTo &&
      dataForm.clientLineId &&
      dataForm.companyId &&
      dataForm.list &&
      dataForm.net &&
      dataForm.brute &&
      dataForm.suggested >= 0 &&
      dataForm.suggested !== undefined &&
      dataForm.suggested !== '' &&
      dataForm.suggested !== null
    ) {
      setbtnTF(false);
    } else {
      setbtnTF(true);
    }
  };

  //Verificar si se encuentra registrado para un precio
  //sold to, número de producto, compañía, línea de negocio, subregión, territorio, zona y país

  const onSubmit = () => {
    const values = form.getFieldsValue();
    postOneRPR({
      values: { ...values },
      history,
    });
  };
  const onCancel = () => {
    history.push('/catalog');
  };

  const handlerOnKeyUp = (e) => {
    let taxes = form.getFieldsValue().taxes;
    if (!taxes) {
      form.setFieldsValue({ taxes: 0 });
    }
  };
  return (
    <>
      <div className={'module-card'}>
        <Form form={form} onValuesChange={OnValuesChange}>
          <Row>
            <FormTitleDivider title={'Estructura comercial'} />
          </Row>
          <CommercialStructure form={form} dataForm={dataProd} checkExistence={checkExistence} />

          <Row>
            <FormTitleDivider title={'Información de precios'} />
          </Row>
          <Prices handlerOnKeyUp={handlerOnKeyUp} form={form} switchBtn={switchBtn} />
        </Form>
      </div>
      <ActionButtons
        onCancel={onCancel}
        onSubmit={onSubmit}
        disabled={btnTF}
        buttons={['cancel', 'submit']}
      />
    </>
  );
};
function mapStateToProps(state) {
  return { regPricesData: state?.REGPRICES?.registerPrices?.data };
}
const ConnectedRegisterPricesForm = connect(mapStateToProps, {
  postOneRegisterPricesRequest,
  getCheckExistenceRequest,
})(RegisterPricesForm);

const RegisterPrices = () => (
  <DynamicModuleLoader modules={[getRootModule(), getRegisterPricesModule()]}>
    <ConnectedRegisterPricesForm />
  </DynamicModuleLoader>
);

export default RegisterPrices;
