/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import { notification } from 'antd';
import getRootModule from 'RootModule';
import { useHistory } from 'react-router-dom';
import { Subregion, BusinessLine, Placement, Segmentation } from 'RootModule/consultFilters/';
import ConsultTable from 'common/consults/components/Table';
import FilterWrapper from 'common/consults/components/FilterWrapper';
import SearchInput from 'common/consults/components/SearchInput';
import ResetButton from 'common/consults/components/ResetButton';
import getConsultInfoBaseModule from '../getConsultInfoBaseModule';
import { consultInfoBaseRequestTable } from '../redux-saga/actions';
import {
  consultFiltersBusinessLineClearValue,
  consultFiltersSubregionClearValue,
  consultFiltersSegmentationClearValue,
  consultFiltersPlacementClearValue,
  consultFiltersSearchInputClearValue,
} from 'RootModule/redux/actions/consultFilters';

import { layoutContext } from 'context';
import consultTableSchema from '../schemas/consultTableSchema';

const ConsultInfoBasePog = (props) => {
  const {
    consultInfoBaseRequestTable: requestConsultTable,
    consultFiltersBusinessLineClearValue: clearBusinessLines,
    consultFiltersSubregionClearValue: clearSubregions,
    consultFiltersSegmentationClearValue: clearSegmentation,
    consultFiltersPlacementClearValue: clearPlacement,
    consultFiltersSearchInputClearValue: clearSearchInput,
    consultTable,
    isLoading,
    BusinessLinesValue,
    SubregionValue,
    PlacementValue,
    SegmentationValue,
    SearchInputValue,
    accountStatementExistences,
    showExistencesMessages,
    AccountStatement,
  } = props;
  const { onChangeActiveComponent } = useContext(layoutContext);
  const history = useHistory();
  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, []);

  useEffect(() => {
    let consultTableParams = {};
    onChangeActiveComponent('BusinessStructureMassiveUpload');
    if (BusinessLinesValue?.length > 0) {
      consultTableParams.Line = BusinessLinesValue;
    }
    if (SubregionValue?.length > 0) {
      consultTableParams.Subregion = SubregionValue;
    }
    if (SegmentationValue?.length > 0) {
      consultTableParams.Segmentation = SegmentationValue;
    }
    if (PlacementValue?.length > 0) {
      consultTableParams.Presence = PlacementValue;
    }
    if (SearchInputValue?.length > 0) {
      consultTableParams.Wildcard = [SearchInputValue];
    }

    requestConsultTable({ params: consultTableParams, history });
  }, [BusinessLinesValue, SegmentationValue, SubregionValue, PlacementValue, SearchInputValue]);

  const clearFilters = () => {
    clearBusinessLines();
    clearPlacement();
    clearSubregions();
    clearSegmentation();
    clearSearchInput();
  };

  const showMessage = (mess) => {
    notification.error({
      message: mess,
    });
  };

  const handleShowMsg = () => {
    const filteredItems = accountStatementExistences.filter((mess) => mess.value === true);
    let contador = 0;
    const contadorf = () => {
      if (contador < filteredItems.length) showMessage(filteredItems[contador].message);
      contador++;
    };
    setInterval(() => contadorf(), 2000);
  };

  useEffect(() => {
    if (AccountStatement && showExistencesMessages === true) {
      handleShowMsg();
    }
  }, [AccountStatement, showExistencesMessages]);

  return (
    <div>
      <FilterWrapper>
        <BusinessLine />
        <Subregion />
        <Segmentation />
        <Placement />
        <SearchInput floatToRight />
        <ResetButton clearFilters={clearFilters} />
      </FilterWrapper>
      <ConsultTable
        data={consultTable}
        columns={consultTableSchema}
        loading={isLoading}
        noResultsMessage={
          'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados.'
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    consultTable: state?.CONSULT_INFO_BASE?.consultTable,
    isLoading: state?.CONSULT_INFO_BASE?.isLoading,
    accountStatementExistences: state?.CONSULT_INFO_BASE?.accountStatementExistences,
    showExistencesMessages: state?.CONSULT_INFO_BASE?.showExistencesMessages,
    AccountStatement: state?.CONSULT_INFO_BASE?.dataReport?.AccountStatement,
    BusinessLinesValue: state?.APP?.consultFilters.businessLine?.value,
    PlacementValue: state?.APP?.consultFilters.placement?.value,
    SegmentationValue: state?.APP?.consultFilters.segmentation?.value,
    SubregionValue: state?.APP?.consultFilters.subregion?.value,
    SearchInputValue: state?.APP?.consultFilters?.searchInput?.value,
  };
};

const ConnectedConsultInfoBase = connect(mapStateToProps, {
  consultInfoBaseRequestTable,
  consultFiltersBusinessLineClearValue,
  consultFiltersSegmentationClearValue,
  consultFiltersPlacementClearValue,
  consultFiltersSubregionClearValue,
  consultFiltersSearchInputClearValue,
})(ConsultInfoBasePog);

const ManualSendingStatement = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getConsultInfoBaseModule()]}>
      <ConnectedConsultInfoBase />
    </DynamicModuleLoader>
  );
};
export default ManualSendingStatement;
