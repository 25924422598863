/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addAllBtnOnOptions, handleAllBtnClick } from 'common/consults/utils';

import {
  consultFiltersPlacementRequestOptions,
  consultFiltersPlacementSetValue,
} from 'RootModule/redux/actions/consultFilters';

import Filter from 'common/consults/components/Filter';

const Placement = (props) => {
  const {
    consultFiltersPlacementRequestOptions: requestOptions,
    consultFiltersPlacementSetValue: setValue,
    options,
    value,
    required,
    disableSelectAll,
  } = props;
  let mappedOptions = [...options];

  useEffect(() => {
    requestOptions();
  }, []);

  const selectChange = (selected) => {
    selected = handleAllBtnClick(selected, options);
    setValue(selected);
  };

  if (!disableSelectAll) {
    mappedOptions = addAllBtnOnOptions(options);
  }

  return (
    <Filter
      name={'Presencia'}
      required={required}
      value={value}
      selectChange={selectChange}
      options={mappedOptions}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    options: state?.APP?.consultFilters.placement?.options ?? [],
    value: state?.APP?.consultFilters.placement?.value,
  };
};

export default connect(mapStateToProps, {
  consultFiltersPlacementRequestOptions,
  consultFiltersPlacementSetValue,
})(Placement);
