import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import getPricesMassiveUploadModule from '../getPricesMassiveUploadModule';
import { layoutContext } from 'context';
import { useHistory } from 'react-router-dom';
import ModalSelector from './ModalSelector/ModalSelector';
import { Form } from 'antd';
import {
  MassiveUploadTable,
  UploadExcelButton,
  ActionButtonsExcelUpload,
} from 'common/MassiveUploads/components';
import { triggerModalForDuplicates } from 'common/MassiveUploads/utils';
//cambiar sagas
import {
  pricesMassiveUploadSetAllowUpload,
  pricesMassiveUploadRequestVerify,
  pricesMassiveUploadRequestUpload,
  pricesMassiveUploadClearUpload,
  pricesMassiveUploadClearVerify,
  pricesMassiveUploadClearAllowUpload,
} from '../redux-saga/actions';

import { tableColumnsCAC, tableColumnsMX } from '../schemas/massiveUploadTableColumns';

const PricesMassiveUpload = (props) => {
  const {
    pricesMassiveUploadSetAllowUpload: setAllowUpload,
    pricesMassiveUploadRequestVerify: requestVerify,
    pricesMassiveUploadRequestUpload: requestUpload,
    pricesMassiveUploadClearUpload: clearUpload,
    pricesMassiveUploadClearVerify: clearVerify,
    pricesMassiveUploadClearAllowUpload: clearAllowUpload,
    allowUpload,
    verify,
  } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [fileString, setFileString] = useState('');
  const [selectSubRegion, setSelectSubRegion] = useState('');
  const correct = verify?.correct;
  const entriesList = verify?.objectList;
  const totalDuplicatedEntries = verify?.replaced?.length;
  const allCorrectMessage = verify?.allCorrectMessage;

  useEffect(() => {
    clearAllowUpload();
    return () => {
      clearAllowUpload();
      clearUpload();
      clearVerify();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    onChangeActiveComponent('activo');
    if (correct > 0) {
      setAllowUpload(true);
    } else {
      setAllowUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correct]);

  let columnSelect;

  if (selectSubRegion === 'CACExcel') {
    columnSelect = tableColumnsCAC;
  } else if (selectSubRegion === 'MXExcel') {
    columnSelect = tableColumnsMX;
  } else {
    columnSelect = [];
  }

  function customRequest({ file }) {
    requestVerify({ file, setFileString, selectSubRegion });
  }
  function requestReplace() {
    requestUpload({ history, fileString, overwrite: true, selectSubRegion });
  }
  function requestKeep() {
    requestUpload({ history, fileString, overwrite: false, selectSubRegion });
  }
  function onSubmit() {
    if (totalDuplicatedEntries > 0) {
      triggerModalForDuplicates(
        totalDuplicatedEntries,
        requestReplace,
        requestKeep,
        'Lista de Precios'
      );
      return;
    }

    requestReplace();
  }
  function onCancel() {
    history.push(`/catalog/trade-agreement-info`);
  }

  const handleSelectSubRegion = (key, value) => {
    let subRegion = '';
    let positionArray = key - 1;
    if (value.options[positionArray].value === 'CA&C') {
      subRegion = 'CACExcel';
    } else if (value.options[positionArray].value === 'MX') {
      subRegion = 'MXExcel';
    } else {
      subRegion = '';
    }

    setSelectSubRegion(subRegion);
  };

  return (
    <>
      <ModalSelector onChange={handleSelectSubRegion} />
      <Form form={form}>
        <UploadExcelButton customRequest={customRequest} />
        <MassiveUploadTable
          data={entriesList}
          columns={columnSelect}
          allCorrectMessage={allCorrectMessage}
        />
        <ActionButtonsExcelUpload
          onSubmit={onSubmit}
          onCancel={onCancel}
          disabled={!allowUpload}
          buttons={['cancel', 'submit']}
        />
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allowUpload: state?.PRICES_MASSIVE_UPLOAD?.allowUpload,
    verify: state?.PRICES_MASSIVE_UPLOAD?.verify,
  };
};

const ConnectedPricesMassiveUpload = connect(mapStateToProps, {
  pricesMassiveUploadSetAllowUpload,
  pricesMassiveUploadRequestVerify,
  pricesMassiveUploadRequestUpload,
  pricesMassiveUploadClearUpload,
  pricesMassiveUploadClearVerify,
  pricesMassiveUploadClearAllowUpload,
})(PricesMassiveUpload);

const PricesMassiveUploadWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getPricesMassiveUploadModule()]}>
      <ConnectedPricesMassiveUpload />
    </DynamicModuleLoader>
  );
};
export default PricesMassiveUploadWithModules;
