import React, { useState, useEffect } from 'react';
import { getRoles } from '../services';
import { Select, Col } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

function ProfileSelect({ disabled, value, onChange }) {
  const { t } = useTranslation(['users']);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(undefined);

  useEffect(() => {
    if (!roles || !roles.length) {
      getRoles().then((res) => {
        setRoles(res);
      });
    }
    if (value) {
      setSelectedRole(value);
    }
  }, [value, roles]);

  const handleProfileSelect = (profile) => {
    onChange(profile);
    setSelectedRole(profile);
  };
  return (
    <>
      <Col style={{ marginTop: '15px' }} id="profileSelect">
        <p style={{ margin: '0px', color: '#21A0D2' }}>*{t('add.profile')}:</p>
        <Select
          style={{ width: '200px' }}
          placeholder="Seleccionar"
          value={selectedRole}
          disabled={disabled}
          onChange={handleProfileSelect}
        >
          {roles &&
            roles.length > 0 &&
            roles.map((role, key) => (
              <Option value={role.id} key={key}>
                {role.name}
              </Option>
            ))}
        </Select>
      </Col>
    </>
  );
}
export default ProfileSelect;
