import request from 'services/request';
const serviceURL = '/Catalogs/Api/v1/Clients/Detail/';
const catalogURI = '/Catalogs/Api/v1/FixedCatalogs/';
const registerClientURI = '/Catalogs/Api/v1/Clients/';
const responsableUrl = '/Users/Api/v1/Users/GetUsersByRoleId/';
const validateClientUrl = '/Catalogs/Api/v1/Clients/CheckExistence/';
const getDetailURL = '/AccountStatement/Api/v1/Statements/Detail';
const getPogSales = 'Catalogs/Api/v1/FixedCatalogs/Pogs';
const getPlacements = 'Catalogs/Api/v1/FixedCatalogs/Placements';

//valida client
export async function validateClient({ cancelToken, soldTo, lineId }) {
  return request(`${validateClientUrl}${soldTo}/${lineId}`, {
    method: 'GET',
    cancelToken,
  });
}

//DetailDynamicElements
export async function getDetailDynamicElements({ cancelToken, clientId, lineId }) {
  return request(`${getDetailURL}?ClientId=${clientId}&LineId=${lineId}`, {
    method: 'GET',
    cancelToken,
  });
}

//Get one detail client
export async function getOneClient({ cancelToken, clientId }) {
  return request(`${serviceURL}${clientId}`, {
    method: 'GET',
    cancelToken,
  });
}

//Get POG and sales

export async function getPOG({ cancelToken, clientId }) {
  return request(`${getPogSales}`, {
    method: 'GET',
    cancelToken,
  });
}

//Get Placments

export async function getPresence({ cancelToken, clientId }) {
  return request(`${getPlacements}`, {
    method: 'GET',
    cancelToken,
  });
}

export async function getTerritoriesClient({ cancelToken, payload }) {
  return request(`${catalogURI}Territories?BusinessLines=${payload}`, {
    method: 'GET',
    cancelToken,
  });
}
//get data clients
export async function getOptionsClient({ cancelToken, name }) {
  return request(`${catalogURI}${name}`, {
    method: 'GET',
    cancelToken,
  });
}
//Register clients
export async function RegisterClient({ cancelToken, data }) {
  return request(registerClientURI, {
    method: 'POST',
    cancelToken,
    data,
  });
}

//get responsable
export async function getResponsableClient({ cancelToken, roleId, territoryId }) {
  return request(`${responsableUrl}${roleId}/${territoryId}`, {
    method: 'GET',
    cancelToken,
  });
}
