import React, { useEffect, useState, useContext } from 'react';
import { Form } from 'antd';
import { ActionButtons, BDivider } from 'common';
import { validaForm } from '../helpers';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import {
  getTypeClientRequest,
  getBusinessLinesRequest,
  getLinesRequest,
  getSegmentationRequest,
  getCompanyRequest,
  getTerritoresRequest,
  getPogsRequest,
  getCurrenciesRequest,
  getPlacementsRequest,
  DynamicElementsRequest,
  getClientIdRequest,
  validateClientRequest,
  getSalesIncentivePeriodRequest,
} from '../redux-saga/actions';
import getRootModule from 'RootModule';
import {
  SectionInitial,
  SectionPog,
  ResumeIncentiveSection,
  Client,
  ShopByFamilySection,
  SectionShop,
  LineCreditOccupancySection,
  OthersSection,
  ContactAccountStatus,
} from '../components';

import RegisterModule from '../RegisterModule';

import { useHistory } from 'react-router-dom';
import { layoutContext } from 'context';

const RegisterDynamicModules = (props) => {
  const {
    getClientData,
    isLoading,
    isValid,
    getPlacementsRequest: getPlacements,
    getTypeClientRequest: getTypesClient,
    getBusinessLinesRequest: getBussinesLines,
    getLinesRequest: getLines,
    getSegmentationRequest: getSegmentation,
    getCompanyRequest: getCompany,
    getTerritoresRequest: getTerritories,
    getPogsRequest: getPogs,
    getCurrenciesRequest: getCurrencies,
    DynamicElementsRequest: registerDynamicElements,
    getClientIdRequest: getClientId,
    validateClientRequest: validateClient,
    getSalesIncentivePeriodRequest: getSalesIncentive,
  } = props;

  const { onChangeActiveComponent } = useContext(layoutContext);
  const [form] = Form.useForm();
  const [isActiveRegister, setIsActiveRegister] = useState(true);
  const [isNacional, setIsNacional] = useState(false);
  const [nameClient, setNameClient] = useState('');
  const [crop, setCrop] = useState('');

  const history = useHistory();
  useEffect(() => {
    getTypesClient();
    getBussinesLines();
    getSegmentation();
    getCompany();
    getPogs();
    getCurrencies();
    getPlacements();
    getClientId();
    getSalesIncentive();
    onChangeActiveComponent('Users');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    const allValues = form.getFieldsValue();
    allValues.name = nameClient;
    let clienId = getClientData.client.filter((d) => d.soldTo === allValues.soldTo)[0].clientId;
    allValues.clientId = clienId;
    if (Array.isArray(allValues.territories) === true) {
      let territory = allValues.territories.map((d) => ({ territoryId: d }));
      allValues.territories = territory;
    } else {
      allValues.territories = [{ territoryId: allValues.territories }];
    }
    allValues.contactAccountStatement = {
      emailAS1: allValues.email1 === '' ? undefined : allValues.email1,
      emailAS2: allValues.email2 === '' ? undefined : allValues.email2,
      emailAS3: allValues.email3 === '' ? undefined : allValues.email3,
      emailAS4: allValues.email4 === '' ? undefined : allValues.email4,
      emailAS5: allValues.email5 === '' ? undefined : allValues.email5,
      nameAS1: allValues.contact1 === '' ? undefined : allValues.contact1,
      nameAS2: allValues.contact2 === '' ? undefined : allValues.contact2,
      nameAS3: allValues.contact3 === '' ? undefined : allValues.contact3,
      nameAS4: allValues.contact4 === '' ? undefined : allValues.contact4,
      nameAS5: allValues.contact5 === '' ? undefined : allValues.contact5,
    };
    let parameters = allValues;
    registerDynamicElements({ parameters, history });
  };
  const onCancel = () => {
    history.push('/account-status');
  };

  const onValuesChange = (changedValues) => {
    const allValues = form.getFieldsValue();
    const currentChange = Object.keys(changedValues)[0];

    if (currentChange === 'placementId') {
      if (allValues.placementId === 3) {
        setIsNacional(true);
        form.setFieldsValue({
          territories: undefined,
        });
      } else {
        if (Array.isArray(allValues.territories)) {
          form.setFieldsValue({
            territories: undefined,
          });
        }
        setIsNacional(false);
      }
    }
    if (currentChange === 'lineId' && allValues.soldTo && allValues.lineId) {
      form.setFieldsValue({
        territories: undefined,
        subregionId: undefined,
      });
      setCrop(allValues.lineId);
    }
    if (currentChange === 'subregionId') {
      form.setFieldsValue({
        territories: undefined,
      });
    }
    validaForm(form, setIsActiveRegister);
  };

  return (
    <>
      <div className={'module-card'}>
        <Form form={form} onValuesChange={onValuesChange}>
          <BDivider title={'Información de cliente'} />
          <Client
            getClientData={getClientData}
            form={form}
            setIsActiveRegister={setIsActiveRegister}
            setNameClient={setNameClient}
            validateClient={validateClient}
            isNacional={isNacional}
            crop={crop}
            setCrop={setCrop}
            getTerritories={getTerritories}
            getLines={getLines}
          />
          <BDivider title={'Sección inicial'} />
          <SectionInitial />
          <BDivider title={'Sección de compras'} />
          <SectionShop />
          <BDivider title={'Sección de POG'} />
          <SectionPog />
          <BDivider title={'Sección de resumen de incentivos'} />
          <ResumeIncentiveSection />
          <BDivider title={'Sección de ocupación de línea de crédito'} />
          <LineCreditOccupancySection form={form} />
          <BDivider title={'Sección de compras por familia'} />
          <ShopByFamilySection />
          <BDivider title={'Sección de otros'} />
          <OthersSection />
          <BDivider title={'Información de contactos de estado de cuenta'} />
          <ContactAccountStatus />
        </Form>
      </div>
      <ActionButtons
        onCancel={onCancel}
        onSubmit={onSubmit}
        isLoading={isLoading}
        disabled={isValid === true ? true : isActiveRegister}
        buttons={['cancel', 'submit']}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    clientData: state?.REGISTERDELEMENT?.DynamicElements?.data,
    getClientData: state.REGISTERDELEMENT.DynamicElements,
    isLoading: state.REGISTERDELEMENT.DynamicElements?.isLoading,
    isValid: state.REGISTERDELEMENT.DynamicElements?.isValid,
  };
}

const RegisterDynamicModulesForm = connect(mapStateToProps, {
  getTypeClientRequest,
  getBusinessLinesRequest,
  getSegmentationRequest,
  getCompanyRequest,
  getTerritoresRequest,
  getPogsRequest,
  getCurrenciesRequest,
  getPlacementsRequest,
  DynamicElementsRequest,
  validateClientRequest,
  getClientIdRequest,
  getSalesIncentivePeriodRequest,
  getLinesRequest,
})(RegisterDynamicModules);

const RegisterDynamicElements = () => (
  <DynamicModuleLoader modules={[getRootModule(), RegisterModule()]}>
    <RegisterDynamicModulesForm />
  </DynamicModuleLoader>
);

export default RegisterDynamicElements;
