import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import DownloadButton from 'common/components/DownloadButton';
import { reportRequest } from '../../../redux/actions/reports';
import { openNotification } from 'common/misc/openNotification';
import moment from 'moment';
import { roleIdEnum, salesChallengeStatus } from 'common/models/enums';
import './css/DownloadReport.css';

function DownloadSalesReport(props) {
  const { reportRequest: generateReport, current, report, query, zoneStatus, ...other } = props;

  const localData = JSON.parse(localStorage.getItem('user'));
  const { role } = localData;

  const handleGenerateReport = useCallback(() => {
    let reportName = '';

    const {
      regionId,
      lineId,
      subRegionId,
      family,
      clientId,
      productId,
      territoryId,
      // businessRule: policyTypeId,
      year,
      zoneId,
    } = query;

    let name = moment(new Date()).format('DD-MM-YYYY');
    let params = { year, name };

    switch (current) {
      case 'regionId':
        reportName = 'region';
        params = { ...params, regionId };
        break;
      case 'lineId':
        reportName = 'businessLine';
        params = { ...params, regionId, lineId };
        break;
      case 'subRegionId':
        reportName = 'subRegion';
        params = { ...params, regionId, lineId, subRegionId };
        break;
      case 'territoryId':
        reportName = 'territory';
        params = { ...params, regionId, lineId, subRegionId, territoryId };
        break;
      case 'zoneId':
        reportName = 'zone';
        params = { ...params, regionId, lineId, subRegionId, territoryId, zoneId };
        break;
      case 'clientId':
        reportName = 'client';
        params = { ...params, regionId, lineId, subRegionId, territoryId, zoneId, clientId };
        break;
      case 'family':
        reportName = 'family';
        params = {
          ...params,
          regionId,
          lineId,
          subRegionId,
          territoryId,
          zoneId,
          clientId,
          family,
        };
        break;
      case 'productId':
        reportName = 'product';
        params = {
          ...params,
          regionId,
          lineId,
          subRegionId,
          territoryId,
          zoneId,
          clientId,
          family,
          productId,
        };
        break;
      default:
        break;
    }

    generateReport({
      sourceId: reportName,
      params,
      onSuccess: () => openNotification('success', 'Reporte generado correctamente'),
    });
  }, [query, generateReport, current]);

  if (
    query &&
    query.year &&
    role.roleId !== roleIdEnum.SALES_AGENT &&
    (query.regionId || query.subRegionId || query.territoryId || query.clientId || query.zoneId) &&
    Object.keys(query).length > 1
  ) {
    return (
      <DownloadButton
        onClick={handleGenerateReport}
        loading={report?.isDownloading}
        //loading={true}
        className={'descargar-reporte__boton'}
        label={report?.isDownloading ? 'Generando reporte' : 'Generar reporte'}
        {...other}
        style={{ marginTop: '36px', marginLeft: '10px' }}
      />
    );
  } else if (
    query &&
    query.year &&
    role.roleId === roleIdEnum.SALES_AGENT &&
    zoneStatus === salesChallengeStatus.APPROVED &&
    (query.regionId || query.subRegionId || query.territoryId || query.clientId || query.zoneId) &&
    Object.keys(query).length > 1
  ) {
    return (
      <DownloadButton
        onClick={handleGenerateReport}
        loading={report?.isDownloading}
        className={'descargar-reporte__boton'}
        label={report?.isDownloading ? 'Generando reporte ' : 'Generar reporte'}
        {...other}
        style={{ marginTop: '36px', marginLeft: '10px' }}
      />
    );
  } else {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    current: state?.SALES_CHALLENGE?.views?.current,
    report: state?.SALES_CHALLENGE?.report,
    query: state?.SALES_CHALLENGE?.query,
    zoneStatus: state?.SALES_CHALLENGE?.indicators?.zoneData?.statusId,
  };
}

export default connect(mapStateToProps, { reportRequest })(DownloadSalesReport);
