import React from 'react';
import ErrorColumnTitle from '../../../common/components/ErrorColumnTitle';
import './massiveUploadTableColumns.css';

const messageFaseOne = (
  <>
    Registros para los cuales se omitió ingresar campos obligatorios,
    <br />
    se ingresó un tipo de dato que no corresponde al solicitado en el campo. <br />
  </>
);
const messageFaseTwo = (
  <>
    Registro de fechas de proceso en los que la fecha de inicio
    <br />
    de vigencia es posterior a la fecha fin de vigencia. <br />
  </>
);
const messageFaseThree = (
  <>
    <p>Registros que se encuentran repetidos dentro del archivo de carga masiva.</p>
  </>
);
const errorMessages = [
  {
    fase: 'Fase 1',
    message: messageFaseOne,
  },
  {
    fase: 'Fase 2',
    message: messageFaseTwo,
  },
  {
    fase: 'Fase 3',
    message: messageFaseThree,
  },
];

const tableColumns = [
  {
    key: 'errorPhase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'errorPhase',
    className: 'title-masive',
  },
  {
    key: 'processStep',
    title: 'Etapa',
    dataIndex: 'processStep',
    className: 'title-masive',
  },
  {
    key: 'startDate',
    title: 'Fecha inicio',
    dataIndex: 'startProcessDate',
    className: 'title-masive',
  },
  {
    key: 'endDate',
    title: 'Fecha fin',
    dataIndex: 'endProcessDate',
    className: 'title-masive',
  },
];
export default tableColumns;
