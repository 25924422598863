import { fork, all } from 'redux-saga/effects';
import * as sectionDataSagas from './SectionDataSagas/incentiveResumeSagas';
import * as shopSectionSagas from './SectionDataSagas/shopSectionSagas';
import * as pogSectionSagas from './SectionDataSagas/pogSectionSagas';
import * as incentiveCreditNotesSagas from './SectionDataSagas/incentiveCreditNotesSagas';
import * as salesByFamilySagas from './SectionDataSagas/saleByFamilySectionSagas';
import * as otherSectionSagas from './SectionDataSagas/otherSectionSagas';
import * as creditLinesSagas from './SectionDataSagas/ocupationCreditLinesSectionSagas';
import * as registerDataSagas from './SectionDataSagas/registerDataSagas';
import * as validateElementsSagas from './validateElementsSagas/validateElementsSagas';
export default function* rootSaga() {
  yield all([
    ...Object.values(validateElementsSagas).map(fork),
    ...Object.values(sectionDataSagas).map(fork),
    ...Object.values(shopSectionSagas).map(fork),
    ...Object.values(pogSectionSagas).map(fork),
    ...Object.values(incentiveCreditNotesSagas).map(fork),
    ...Object.values(salesByFamilySagas).map(fork),
    ...Object.values(otherSectionSagas).map(fork),
    ...Object.values(creditLinesSagas).map(fork),
    ...Object.values(registerDataSagas).map(fork),
  ]);
}
