import { takeLatest, put, cancelled, call } from 'redux-saga/effects';
import instance from 'services/request';
import { responseOK } from 'SalesChallengeRefactor/utils';
import { salesByFamilySectionActions } from '../../actions';
import { requestSectionData } from '../../../services';

const {
  salesByFamilyClientSet,
  salesByFamilyClientClean,
  SALESBYFAMILY_CLIENTS_REQUEST,
  SALESBYFAMILY_SUBREGIONS_REQUEST,
  salesByFamilySubregionsSet,
  salesByFamilySubregionsClean,
} = salesByFamilySectionActions;

function* reqSalesByFamilySectionData(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSectionData, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(salesByFamilyClientSet(response.data ?? []));
    } else {
      yield put(salesByFamilyClientClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(salesByFamilyClientClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* reqSalesByFamilySubregionsData(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSectionData, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(salesByFamilySubregionsSet(response.data ?? []));
    } else {
      yield put(salesByFamilySubregionsClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(salesByFamilySubregionsClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaRequestSaleByFamilySectionData() {
  yield takeLatest(SALESBYFAMILY_CLIENTS_REQUEST, reqSalesByFamilySectionData);
}

export function* SagaRequestSaleByFamilySubregionsData() {
  yield takeLatest(SALESBYFAMILY_SUBREGIONS_REQUEST, reqSalesByFamilySubregionsData);
}
