import React from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import { Upload, Button } from 'antd';
import { openNotification } from '..';

export const getFileMetadata = (file, onChange, name, onLoading, getXlsData) => {
  let xls = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  let size = file.size / 1024 / 1024 < 20; //File size minor to 2MB
  if (!xls || !size) {
    openNotification(
      'error',
      `El formato del archivo de carga masiva de ${name} debe ser .xlsx y tener un tamaño máximo de 20 MB.`
    );
  } else {
    getXlsData(file, onChange);
    onLoading(true);
    return false;
  }
};
export const getXlsData = (file, onChange) => {
  ExcelRenderer(file, (err, res) => {
    if (err) {
      openNotification('error', 'Existe un error en la carga');
    } else {
      let filteredRows = [];
      res.rows.forEach((row) => {
        if (row.length > 0) {
          filteredRows.push(row);
        }
      });
      let fileObject = { cols: res.cols, rows: filteredRows };
      onChange(fileObject);
    }
  });
};

function UploadXls({ onChange, name, onLoading, disabled }) {
  const fieldProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => getFileMetadata(file, onChange, name, onLoading, getXlsData),
  };

  return (
    <Upload {...fieldProps}>
      <Button data-testid="upload-button" disabled={disabled} className="ant-btn btn-upload">
        Cargar
      </Button>
    </Upload>
  );
}

export default UploadXls;
