import reducer from './redux-saga/reducers/';

import { watchPdfViewerRequestGet } from './redux-saga/sagas';

export default function getPdfViewerModule() {
  return {
    id: 'PDF_VIEWER',
    reducerMap: {
      PDF_VIEWER: reducer,
    },
    sagas: [watchPdfViewerRequestGet],
  };
}
