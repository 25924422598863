export const READ_ONE_GOALS_REQUEST = 'READ_ONE_GOALS_REQUEST';
export const READ_ONE_GOALS_SUCCESS = 'READ_ONE_GOALS_SUCCESS';
export const READ_ONE_GOALS_CLEAN = 'READ_ONE_GOALS_CLEAN';
export const UPDATE_GOALS_DATA = 'UPDATE_GOALS_DATA';
export const SAVE_GOALS_DATA = 'SAVE_GOALS_DATA';
export const UPDATE_GOALS_REQUEST = 'UPDATE_GOALS_REQUEST';
export const UPDATE_GOALS_SUCCESS = 'UPDATE_GOALS_SUCCESS';
export const UPDATE_GOALS_CLEAN = 'UPDATE_GOALS_CLEAN';
export const DELETE_GOALS_REQUEST = 'DELETE_GOALS_REQUEST';
export const DELETE_GOALS_SUCCESS = 'DELETE_GOALS_SUCCESS';
export const DELETE_GOALS_CLEAN = 'DELETE_GOALS_CLEAN';
//READ
export function readOneGoalsRequest(payload) {
  return {
    type: READ_ONE_GOALS_REQUEST,
    payload: payload,
  };
}

export function readOneGoalsSuccess(payload) {
  return {
    type: READ_ONE_GOALS_SUCCESS,
    payload: payload,
  };
}

export function readOneGoalsClean() {
  return {
    type: READ_ONE_GOALS_CLEAN,
    payload: {},
  };
}

export function updateGoalsRequest(payload) {
  return {
    type: UPDATE_GOALS_REQUEST,
    payload: payload,
  };
}

export function updateGoalsSuccess(payload) {
  return {
    type: UPDATE_GOALS_SUCCESS,
    payload: payload,
  };
}

export function updateGoalsClean() {
  return {
    type: UPDATE_GOALS_CLEAN,
    payload: {},
  };
}

//UPDATE DATA
export function updateDataGoals(payload) {
  return {
    type: UPDATE_GOALS_DATA,
    payload: payload,
  };
}

export function saveDataGoals(payload) {
  return {
    type: SAVE_GOALS_DATA,
    payload: payload,
  };
}

//delete

export function deleteGoalsRequest(payload) {
  return {
    type: DELETE_GOALS_REQUEST,
    payload: payload,
  };
}

export function deleteGoalsSuccess(payload) {
  return {
    type: DELETE_GOALS_SUCCESS,
    payload: payload,
  };
}

export function deleteGoalsClean() {
  return {
    type: DELETE_GOALS_CLEAN,
    payload: {},
  };
}
