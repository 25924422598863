export const POST_ONE_SEGMENTATION_REQUEST = 'POST_ONE_SEGMENTATION_REQUEST';
export const POST_ONE_SEGMENTATION_SUCCESS = 'POST_ONE_SEGMENTATION_SUCCESS';
export const POST_ONE_SEGMENTATION_CLEAN = 'POST_ONE_SEGMENTATION_CLEAN';

export const DELETE_ONE_SEGMENTATION_REQUEST = 'DELETE_ONE_SEGMENTATION_REQUEST';
export const DELETE_ONE_SEGMENTATION_SUCCESS = 'DELETE_ONE_SEGMENTATION_SUCCESS';
export const DELETE_ONE_SEGMENTATION_CLEAN = 'DELETE_ONE_SEGMENTATION_CLEAN';

export const PUT_ONE_SEGMENTATION_REQUEST = 'PUT_ONE_SEGMENTATION_REQUEST';
export const PUT_ONE_SEGMENTATION_SUCCESS = 'PUT_ONE_SEGMENTATION_SUCCESS';
export const PUT_ONE_SEGMENTATION_CLEAN = 'PUT_ONE_SEGMENTATION_CLEAN';

export const GET_IN_POLICIY_PRODUCTS_REQUEST = 'GET_IN_POLICIY_PRODUCTS_REQUEST';
export const GET_IN_POLICIY_PRODUCTS_SUCCESS = 'GET_IN_POLICIY_PRODUCTS_SUCCESS';
export const GET_IN_POLICIY_PRODUCTS_CLEAN = 'GET_IN_POLICIY_PRODUCTS_CLEAN';

export const GET_ACTIVE_CLIENTS_REQUEST = 'GET_ACTIVE_CLIENTS_REQUEST';
export const GET_ACTIVE_CLIENTS_SUCCESS = 'GET_ACTIVE_CLIENTS_SUCCESS';
export const GET_ACTIVE_CLIENTS_CLEAN = 'GET_ACTIVE_CLIENTS_CLEAN';

export const POST_PRODUCT_CLIENT_REQUEST = 'POST_PRODUCT_CLIENT_REQUEST';
export const POST_PRODUCT_CLIENT_SUCCESS = 'POST_PRODUCT_CLIENT_SUCCESS';
export const POST_PRODUCT_CLIENT_CLEAN = 'POST_PRODUCT_CLIENT_CLEAN';

export const POST_PRODUCT_BL_REQUEST = 'POST_PRODUCT_BL_REQUEST';
export const POST_PRODUCT_BL_SUCCESS = 'POST_PRODUCT_BL_SUCCESS';
export const POST_PRODUCT_BL_CLEAN = 'POST_PRODUCT_BL_CLEAN';

export const POST_INCENTIVES_RULES_REQUEST = 'POST_INCENTIVES_RULES_REQUEST';
export const POST_INCENTIVES_RULES_SUCCESS = 'POST_INCENTIVES_RULES_SUCCESS';
export const POST_INCENTIVES_RULES_CLEAN = 'POST_INCENTIVES_RULES_CLEAN';

export const GET_INCENTIVES_REQUEST = 'GET_INCENTIVES_REQUEST';
export const GET_INCENTIVES_SUCCESS = 'GET_INCENTIVES_SUCCESS';
export const GET_INCENTIVES_CLEAN = 'GET_INCENTIVES_CLEAN';

export const GET_OutPolicyBL_REQUEST = 'GET_OutPolicyBL_REQUEST';
export const GET_OutPolicyBL_SUCCESS = 'GET_OutPolicyBL_SUCCESS';
export const GET_OutPolicyBL_CLEAN = 'GET_OutPolicyBL_CLEAN';

export const GET_OutPolicyClient_REQUEST = 'GET_OutPolicyClient_REQUEST';
export const GET_OutPolicyClient_SUCCESS = 'GET_OutPolicyClient_SUCCESS';
export const GET_OutPolicyClient_CLEAN = 'GET_OutPolicyClient_CLEAN';

export const GET_InPoliciyProducts_REQUEST = 'GET_InPoliciyProducts_REQUEST';
export const GET_InPoliciyProducts_SUCCESS = 'GET_InPoliciyProducts_SUCCESS';
export const GET_InPoliciyProducts_CLEAN = 'GET_InPoliciyProducts_CLEAN';

export const GET_OutPoliciyProducts_REQUEST = 'GET_OutPoliciyProducts_REQUEST';
export const GET_OutPoliciyProducts_SUCCESS = 'GET_OutPoliciyProducts_SUCCESS';
export const GET_OutPoliciyProducts_CLEAN = 'GET_OutPoliciyProducts_CLEAN';

export const GET_OutAvailability_REQUEST = 'GET_OutAvailability_REQUEST';
export const GET_OutAvailability_SUCCESS = 'GET_OutAvailability_SUCCESS';
export const GET_OutAvailability_CLEAN = 'GET_OutAvailability_CLEAN';

export const GET_InAvailability_REQUEST = 'GET_InAvailability_REQUEST';
export const GET_InAvailability_SUCCESS = 'GET_InAvailability_SUCCESS';
export const GET_InAvailability_CLEAN = 'GET_InAvailability_CLEAN';

export const GET_ClientSingleId_REQUEST = 'GET_ClientSingleId_REQUEST';
export const GET_ClientSingleId_SUCCESS = 'GET_ClientSingleId_SUCCESS';
export const GET_ClientSingleId_CLEAN = 'GET_ClientSingleId_CLEAN';

export const PUT_OutPolicyProductClient_REQUEST = 'PUT_OutPolicyProductClient_REQUEST';
export const PUT_OutPolicyProductClient_SUCCESS = 'PUT_OutPolicyProductClient_SUCCESS';
export const PUT_OutPolicyProductClient_CLEAN = 'PUT_OutPolicyProductClient_CLEAN';

export const PUT_IncentivesRules_REQUEST = 'PUT_IncentivesRules_REQUEST';
export const PUT_IncentivesRules_SUCCESS = 'PUT_IncentivesRules_SUCCESS';
export const PUT_IncentivesRules_CLEAN = 'PUT_IncentivesRules_CLEAN';

export const PUT_OutPolicyProductBL_REQUEST = 'PUT_OutPolicyProductBL_REQUEST';
export const PUT_OutPolicyProductBL_SUCCESS = 'PUT_OutPolicyProductBL_SUCCESS';
export const PUT_OutPolicyProductBL_CLEAN = 'PUT_OutPolicyProductBL_CLEAN';

export const PUT_AvailabilityProducts_REQUEST = 'PUT_AvailabilityProducts_REQUEST';
export const PUT_AvailabilityProducts_SUCCESS = 'PUT_AvailabilityProducts_SUCCESS';
export const PUT_AvailabilityProducts_CLEAN = 'PUT_AvailabilityProducts_CLEAN';

export const EXISTENCE_INCENTIVES_REQUEST = 'EXISTENCE_INCENTIVES_REQUEST';
export const EXISTENCE_INCENTIVES_SUCCESS = 'EXISTENCE_INCENTIVES_SUCCESS';
export const EXISTENCE_INCENTIVES_CLEAN = 'EXISTENCE_INCENTIVES_CLEAN';

//EXISTENCE_INCENTIVES_REQUEST
export function checkExistanceIncentivesRequest(payload) {
  return {
    type: EXISTENCE_INCENTIVES_REQUEST,
    payload: payload,
  };
}
export function checkExistanceIncentivesSuccess(payload) {
  return {
    type: EXISTENCE_INCENTIVES_SUCCESS,
    payload: payload,
  };
}

export function checkExistanceIncentivesClean() {
  return {
    type: EXISTENCE_INCENTIVES_CLEAN,
  };
}

//PUT_AvailabilityProducts_REQUEST
export function putAvailabilityProductsRequest(payload) {
  return {
    type: PUT_AvailabilityProducts_REQUEST,
    payload: payload,
  };
}
export function putAvailabilityProductsSuccess(payload) {
  return {
    type: PUT_AvailabilityProducts_SUCCESS,
    payload: payload,
  };
}

export function putAvailabilityProductsClean() {
  return {
    type: PUT_AvailabilityProducts_CLEAN,
  };
}

//PUT_OutPolicyProductBL_REQUEST
export function putOutPolicyProductBLRequest(payload) {
  return {
    type: PUT_OutPolicyProductBL_REQUEST,
    payload: payload,
  };
}
export function putOutPolicyProductBLSuccess(payload) {
  return {
    type: PUT_OutPolicyProductBL_SUCCESS,
    payload: payload,
  };
}

export function putOutPolicyProductBLClean() {
  return {
    type: PUT_OutPolicyProductBL_CLEAN,
  };
}

//PUT_IncentivesRules_REQUEST
export function putIncentivesRulesRequest(payload) {
  return {
    type: PUT_IncentivesRules_REQUEST,
    payload: payload,
  };
}
export function putIncentivesRulesSuccess(payload) {
  return {
    type: PUT_IncentivesRules_SUCCESS,
    payload: payload,
  };
}

export function putIncentivesRulesClean() {
  return {
    type: PUT_IncentivesRules_CLEAN,
  };
}

//PUT_OutPolicyProductClient_REQUEST
export function putOutPolicyProductClientRequest(payload) {
  return {
    type: PUT_OutPolicyProductClient_REQUEST,
    payload: payload,
  };
}
export function putOutPolicyProductClientSuccess(payload) {
  return {
    type: PUT_OutPolicyProductClient_SUCCESS,
    payload: payload,
  };
}

export function putOutPolicyProductClientClean() {
  return {
    type: PUT_OutPolicyProductClient_CLEAN,
  };
}

//GET_ClientSingleId_REQUEST
export function getClientSingleIdRequest(payload) {
  return {
    type: GET_ClientSingleId_REQUEST,
    payload: payload,
  };
}
export function getClientSingleIdSuccess(payload) {
  return {
    type: GET_ClientSingleId_SUCCESS,
    payload: payload,
  };
}
export function getClientSingleIdClean() {
  return {
    type: GET_ClientSingleId_CLEAN,
  };
}

//GET_InAvailability_REQUEST
export function getInAvailabilityRequest(payload) {
  return {
    type: GET_InAvailability_REQUEST,
    payload: payload,
  };
}
export function getInAvailabilitySuccess(payload) {
  return {
    type: GET_InAvailability_SUCCESS,
    payload: payload,
  };
}

export function getInAvailabilityClean() {
  return {
    type: GET_InAvailability_CLEAN,
  };
}

//GET_OutAvailability_REQUEST
export function getOutAvailabilityRequest(payload) {
  return {
    type: GET_OutAvailability_REQUEST,
    payload: payload,
  };
}
export function getOutAvailabilitySuccess(payload) {
  return {
    type: GET_OutAvailability_SUCCESS,
    payload: payload,
  };
}

export function getOutAvailabilityClean() {
  return {
    type: GET_OutAvailability_CLEAN,
  };
}

//GET_OutPoliciyProducts_REQUEST
export function getOutPoliciyProductsRequest(payload) {
  return {
    type: GET_OutPoliciyProducts_REQUEST,
    payload: payload,
  };
}
export function getOutPoliciyProductsSuccess(payload) {
  return {
    type: GET_OutPoliciyProducts_SUCCESS,
    payload: payload,
  };
}

export function getOutPoliciyProductsClean() {
  return {
    type: GET_OutPoliciyProducts_CLEAN,
  };
}

//GET_InPoliciyProducts_REQUEST
export function getInPoliciyProductsRequest(payload) {
  return {
    type: GET_InPoliciyProducts_REQUEST,
    payload: payload,
  };
}
export function getInPoliciyProductsSuccess(payload) {
  return {
    type: GET_InPoliciyProducts_SUCCESS,
    payload: payload,
  };
}

export function getInPoliciyProductsClean() {
  return {
    type: GET_InPoliciyProducts_CLEAN,
  };
}

//GET_OutPolicyClient_REQUEST
export function getOutPolicyClientRequest(payload) {
  return {
    type: GET_OutPolicyClient_REQUEST,
    payload: payload,
  };
}
export function getOutPolicyClientSuccess(payload) {
  return {
    type: GET_OutPolicyClient_SUCCESS,
    payload: payload,
  };
}

export function getOutPolicyClientClean() {
  return {
    type: GET_OutPolicyClient_CLEAN,
  };
}

//GET_OutPolicyBL_REQUEST
export function getOutPolicyBLRequest(payload) {
  return {
    type: GET_OutPolicyBL_REQUEST,
    payload: payload,
  };
}
export function getOutPolicyBLSuccess(payload) {
  return {
    type: GET_OutPolicyBL_SUCCESS,
    payload: payload,
  };
}

export function getOutPolicyBLClean() {
  return {
    type: GET_OutPolicyBL_CLEAN,
  };
}

//GET_INCENTIVES_REQUEST
export function getIncentivesRequest(payload) {
  return {
    type: GET_INCENTIVES_REQUEST,
    payload: payload,
  };
}
export function getIncentivesSuccess(payload) {
  return {
    type: GET_INCENTIVES_SUCCESS,
    payload: payload,
  };
}

export function getIncentivesClean() {
  return {
    type: GET_INCENTIVES_CLEAN,
  };
}

//POST_INCENTIVES_RULES_REQUEST
export function postIncentivesRulesRequest(payload) {
  return {
    type: POST_INCENTIVES_RULES_REQUEST,
    payload: payload,
  };
}
export function postIncentivesRulesSuccess(payload) {
  return {
    type: POST_INCENTIVES_RULES_SUCCESS,
    payload: payload,
  };
}

export function postIncentivesRulesClean() {
  return {
    type: POST_INCENTIVES_RULES_CLEAN,
  };
}

//POST_PRODUCT_BL_REQUEST
export function postProductBLRequest(payload) {
  return {
    type: POST_PRODUCT_BL_REQUEST,
    payload: payload,
  };
}
export function postProductBLSuccess(payload) {
  return {
    type: POST_PRODUCT_BL_SUCCESS,
    payload: payload,
  };
}

export function postProductBLClean() {
  return {
    type: POST_PRODUCT_BL_CLEAN,
  };
}

//POST_PRODUCT_CLIENT_REQUEST
export function postProductClientRequest(payload) {
  return {
    type: POST_PRODUCT_CLIENT_REQUEST,
    payload: payload,
  };
}
export function postProductClientSuccess(payload) {
  return {
    type: POST_PRODUCT_CLIENT_SUCCESS,
    payload: payload,
  };
}

export function postProductClientClean() {
  return {
    type: POST_PRODUCT_CLIENT_CLEAN,
  };
}

//GET_ACTIVE_CLIENTS_REQUEST
export function getActiveClientsRequest(payload) {
  return {
    type: GET_ACTIVE_CLIENTS_REQUEST,
    payload: payload,
  };
}
export function getActiveClientsSuccess(payload) {
  return {
    type: GET_ACTIVE_CLIENTS_SUCCESS,
    payload: payload,
  };
}
export function getActiveClientsClean() {
  return {
    type: GET_ACTIVE_CLIENTS_CLEAN,
  };
}

//PUT_ONE_SEGMENTATION_REQUEST
export function putOneSegmentationRequest(payload) {
  return {
    type: PUT_ONE_SEGMENTATION_REQUEST,
    payload: payload,
  };
}
export function putOneSegmentationSuccess(payload) {
  return {
    type: PUT_ONE_SEGMENTATION_SUCCESS,
    payload: payload,
  };
}

export function putOneSegmentationClean() {
  return {
    type: PUT_ONE_SEGMENTATION_CLEAN,
  };
}

//DELETE_ONE_SEGMENTATION_REQUEST
export function deleteOneSegmentationRequest(payload) {
  return {
    type: DELETE_ONE_SEGMENTATION_REQUEST,
    payload: payload,
  };
}
export function deleteOneSegmentationSuccess(payload) {
  return {
    type: DELETE_ONE_SEGMENTATION_SUCCESS,
    payload: payload,
  };
}

export function deleteOneSegmentationClean() {
  return {
    type: DELETE_ONE_SEGMENTATION_CLEAN,
  };
}

//POST_ONE_SEGMENTATION_REQUEST
export function postOneSegmentationRequest(payload) {
  return {
    type: POST_ONE_SEGMENTATION_REQUEST,
    payload: payload,
  };
}
export function postOneSegmentationSuccess(payload) {
  return {
    type: POST_ONE_SEGMENTATION_SUCCESS,
    payload: payload,
  };
}

export function postOneSegmentationClean() {
  return {
    type: POST_ONE_SEGMENTATION_CLEAN,
  };
}
