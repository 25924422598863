import { useState, useEffect } from 'react';
export const MailRegexValidation = (value) => {
  let regExp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi
  );
  return regExp.test(value);
};

export const useHandleInputValue = (defaultValue) => {
  const [value, setValue] = useState(undefined);
  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);
  return value;
};
