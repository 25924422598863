import React from 'react';
import { IconContext } from 'react-icons';
import { BsArrowCounterclockwise } from 'react-icons/bs';

function ResetButton({ style, ...other }) {
  return (
    <button
      className="ant-btn ant-btn-info"
      style={{
        outline: 'none',
        height: '32px',
        ...style,
      }}
      {...other}
    >
      <IconContext.Provider value={{ size: '1em', color: '#fbfbfb' }}>
        <BsArrowCounterclockwise />
      </IconContext.Provider>
    </button>
  );
}

export default ResetButton;
