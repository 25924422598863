import { useState, useEffect, useRef } from 'react';

function useUser() {
  const [user, setUser] = useState({});
  const wasRendered = useRef(false);

  useEffect(() => {
    const getRolFilters = async () => {
      try {
        let userLocal = localStorage.getItem('user');
        userLocal = JSON.parse(userLocal);
        setUser(userLocal);
      } catch (error) {
        if (wasRendered.current) setUser({});
      }
    };
    if (!wasRendered.current) getRolFilters();
    return () => {
      wasRendered.current = false;
    };
  }, []);
  return user;
}

export default useUser;
