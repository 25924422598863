export const TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD =
  'TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const TRADE_AGREEMENTS_MASSIVE_UPLOAD_REQUEST_VERIFY =
  'TRADE_AGREEMENTS_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_VERIFY =
  'TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_VERIFY';
export const TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_VERIFY =
  'TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const TRADE_AGREEMENTS_MASSIVE_UPLOAD_REQUEST_UPLOAD =
  'TRADE_AGREEMENTS_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_UPLOAD =
  'TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_UPLOAD';
export const TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_UPLOAD =
  'TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function tradeAgreementsMassiveUploadSetAllowUpload(payload) {
  return {
    type: TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function tradeAgreementsMassiveUploadClearAllowUpload(payload) {
  return {
    type: TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function tradeAgreementsMassiveUploadRequestVerify(payload) {
  return {
    type: TRADE_AGREEMENTS_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function tradeAgreementsMassiveUploadSetVerify(payload) {
  return {
    type: TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function tradeAgreementsMassiveUploadClearVerify(payload) {
  return {
    type: TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function tradeAgreementsMassiveUploadRequestUpload(payload) {
  return {
    type: TRADE_AGREEMENTS_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function tradeAgreementsMassiveUploadSetUpload(payload) {
  return {
    type: TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function tradeAgreementsMassiveUploadClearUpload(payload) {
  return {
    type: TRADE_AGREEMENTS_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
