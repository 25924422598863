import React from 'react';
import Styles from './client-passed-failed.module.css';

export const ClientPassedFailed = ({ passed, failed }) => {
  return (
    <div className={Styles[`container-passed-failed`]}>
      <div className={Styles[`container-title-passed`]}>
        <p>Aprobados</p>
        <span>{passed} clientes</span>
      </div>
      <div className={Styles[`container-title-failed`]}>
        <p>Rechazados</p>
        <span>{failed} clientes</span>
      </div>
    </div>
  );
};
