/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import getProductRegistrationModule from '../getProductRegistrationModule';
import { layoutContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import {
  productRegistrationSetBusinessLineId,
  productRegistrationSetSubregionId,
  productRegistrationClearSubregionId,
  productRegistrationSetCountryId,
  productRegistrationClearCountryId,
  productRegistrationSetProductNumber,
  productRegistrationSetName,
  productRegistrationSetFamily,
  productRegistrationSetClasification,
  productRegistrationSetPresentation,
  productRegistrationSetMeasurementUnit,
  productRegistrationSetUnitsPerPack,
  productRegistrationSetMinimumOrderRequired,
  productRegistrationSetMoq,
  productRegistrationSetCurrency,
  productRegistrationSetIva,
  productRegistrationSetIeps,
  productRegistrationRequestProductExistence,
  productRegistrationRequestRegistration,
} from '../redux-saga/actions';

import { Form } from 'antd';
import { ActionButtons } from 'common';

import { CommercialStructure, ProductInformation, ProductTaxes } from '../components';
import { message046 } from 'utils/messages';

import integerFloatTwoTwo from '../utils/integerFloatTwoTwo';

import preventKeysOnNumberInput from '../utils/preventKeysOnNumberInput';
import preventDotOnInput from '../utils/preventDotOnInput';
import checkForChangedValuesOnForm from '../utils/checkForChangedValuesOnForm';

const ProductRegistration = (props) => {
  const {
    productRegistrationSetBusinessLineId: setBusinessLineId,
    productRegistrationSetSubregionId: setSubregionId,
    productRegistrationClearSubregionId: clearSubregionId,
    productRegistrationSetCountryId: setCountryId,
    productRegistrationClearCountryId: clearCountryId,
    productRegistrationSetProductNumber: setProductNumber,
    productRegistrationSetName: setName,
    productRegistrationSetFamily: setFamily,
    productRegistrationSetClasification: setClasification,
    productRegistrationSetPresentation: setPresentation,
    productRegistrationSetMeasurementUnit: setMeasurementUnit,
    productRegistrationSetUnitsPerPack: setUnitsPerPack,
    productRegistrationSetMinimumOrderRequired: setMinimumOrderRequired,
    productRegistrationSetMoq: setMoq,
    productRegistrationSetCurrency: setCurrency,
    productRegistrationSetIva: setIva,
    productRegistrationSetIeps: setIeps,
    productRegistrationRequestProductExistence: requestProductExistence,
    productRegistrationRequestRegistration: register,
    businessLineId,
    subregionId,

    productExistence,
  } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const gutterValue = [34, 40];

  const values = form.getFieldsValue();
  const fieldsForValidation = [
    values.businessLineId,
    values.subregionId,
    values.productNumber,
    values.name,
    values.family,
    values.clasification,
    values.presentation,
    values.measurementUnit,
    values.unitsPerPack,
    values.minimumOrderRequired,
    values.moq,
    values.currency,
  ];

  const valid = fieldsForValidation.filter((item) => {
    return !item;
  });

  const intFloat_2_2 = (e) => {
    try {
      let { keyId, valueIF } = integerFloatTwoTwo(e);
      if (keyId === 'iva') {
        form.setFieldsValue({ iva: valueIF });
      } else if (keyId === 'ieps') {
        form.setFieldsValue({ ieps: valueIF });
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  function formChangeSwitch(elementData, allData) {
    intFloat_2_2(elementData);

    const key = Object.keys(elementData)[0];
    const value = elementData[key];
    switch (key) {
      case 'businessLineId':
        setBusinessLineId(value);
        break;
      case 'subregionId':
        setSubregionId(value);
        break;
      case 'countryId':
        setCountryId(value);
        break;
      case 'productNumber':
        setProductNumber(value);
        form.setFieldsValue({ productNumber: value });

        break;
      case 'name':
        setName(value);
        break;
      case 'family':
        setFamily(value);
        break;
      case 'clasification':
        setClasification(value);
        break;
      case 'presentation':
        setPresentation(value);
        break;
      case 'measurementUnit':
        setMeasurementUnit(value);
        break;
      case 'unitsPerPack':
        setUnitsPerPack(value);
        break;

      //
      case 'minimumOrderRequired':
        setMinimumOrderRequired(value);
        break;
      case 'moq':
        setMoq(value);
        break;
      //
      case 'currency':
        setCurrency(value);
        break;
      case 'iva':
        setIva(value);
        break;
      case 'ieps':
        setIeps(value);
        break;

      default:
        console.error('Key Invalida Form', key);
    }
    getDisabledSubmit();
  }

  function resetSubregion() {
    form.resetFields(['subregionId', 'countryId']);
    form.setFieldsValue({ subregionId: null });
    clearSubregionId();
    clearCountryId();
  }

  function resetCountryId() {
    form.resetFields(['countryId']);
    clearCountryId();
  }

  useEffect(() => {
    if (productExistence) {
      message046();
    }
  }, [productExistence]);

  useEffect(() => {
    resetSubregion();
  }, [businessLineId]);
  useEffect(() => {
    resetCountryId();
  }, [subregionId]);

  useEffect(() => {
    onChangeActiveComponent(
      valid.length !== fieldsForValidation.length ? 'Product Registration' : undefined
    );
  }, fieldsForValidation);

  function checkForProductExistence() {
    const formValues = form.getFieldsValue();
    const {
      countryId: countries,
      productNumber: number,
      subregionId: subregion,
      businessLineId: line,
    } = formValues;
    if (number && subregion && line) {
      requestProductExistence({
        productNumber: number,
        lineId: line,
        subregionId: subregion,
        countries: countries,
      });
    }
  }

  function formCancel() {
    history.push('/catalog');
  }

  function onSubmit(e) {
    const formValues = form.getFieldsValue();
    const {
      countryId: countries,
      productNumber: number,
      name: productName,
      family: productFamily,
      clasification: productClasification,
      presentation: packaging,
      measurementUnit: productMeasurementUnit,
      unitsPerPack: unitsPerPackage,
      minimumOrderRequired: minimum,
      moq: productMoq,
      currency: currencyId,
      iva: productIva,
      ieps: productIeps,
    } = formValues;

    const data = {
      subRegionId: subregionId,
      lineId: businessLineId,
      countries: countries.filter((coun) => coun !== 99999),
      productNumber: parseInt(number),
      name: productName,
      family: productFamily,
      clasification: productClasification,
      packaging: packaging,
      measurementUnit: productMeasurementUnit,
      unitsPerPackage: unitsPerPackage,
      minimum: parseFloat(minimum),
      moq: parseFloat(productMoq),
      currencyId: currencyId,
      iva: parseFloat(productIva),
      ieps: parseFloat(productIeps),
    };

    register({ data, history });
  }

  function getDisabledSubmit() {
    const formValues = form.getFieldsValue();
    const validEntries = [];
    var emptyValidEntries = [];

    const formValuesEntries = Object.entries(formValues);
    formValuesEntries.forEach((formValue) => {
      switch (formValue[0]) {
        case 'businessLineId':
        case 'subregionId':
        case 'productNumber':
        case 'name':
        case 'family':
        case 'clasification':
        case 'presentation':
        case 'measurementUnit':
        case 'unitsPerPack':
        case 'minimumOrderRequired':
        case 'moq':
        case 'currency':
          validEntries.push(formValue[1]);
          break;
        default:
          break;
      }
    });

    emptyValidEntries = validEntries.filter((item) => {
      return item === undefined || item === null || item === '';
    });
    setDisabledSubmit(emptyValidEntries.length > 0);
  }

  function handleFocusNumberInput(e) {
    window.addEventListener('keydown', preventDotOnInput);
  }
  function handleBlurNumberInput(e) {
    window.removeEventListener('keydown', preventDotOnInput);
  }

  function handleFocusFloatInput(e) {
    window.addEventListener('keydown', preventKeysOnNumberInput);
  }
  function handleBlurFloatInput(e) {
    window.removeEventListener('keydown', preventKeysOnNumberInput);
  }

  function countryHandleChange(current, { options }) {
    const allActived = current.find((val) => val === 'ALL');
    if (allActived && current.length < options.length + 1) {
      form.setFieldsValue({
        countryId: options.map(({ key }) => key),
      });
    } else if (allActived && current.length === options.length + 1) {
      form.setFieldsValue({
        countryId: [],
      });
    }
    checkForProductExistence();
  }

  function getCurrentOptions(options) {
    const formValues = form.getFieldsValue();
    let ids = options.map((op) => op.key);

    if (
      options?.length > 0 &&
      subregionId &&
      formValues.subregionId &&
      formValues.countryId === undefined &&
      !Array.isArray(formValues.countryId) &&
      checkForChangedValuesOnForm(form)
    ) {
      form.setFieldsValue({
        countryId: [...ids],
      });
    }
  }

  return (
    <Form form={form} onValuesChange={formChangeSwitch}>
      <CommercialStructure
        gutter={gutterValue}
        businessLineId={businessLineId}
        subregionId={subregionId}
        countryHandleChange={countryHandleChange}
        getCurrentOptions={getCurrentOptions}
        businessLineHandleChange={checkForProductExistence}
        subregionHandleChange={checkForProductExistence}
      />
      <ProductInformation
        gutter={gutterValue}
        onFocusNumber={handleFocusNumberInput}
        onBlurNumber={handleBlurNumberInput}
        numberHandleChange={checkForProductExistence}
      />
      <ProductTaxes
        gutter={gutterValue}
        onFocusNumber={handleFocusFloatInput}
        onBlurNumber={handleBlurFloatInput}
      />
      <ActionButtons
        onCancel={formCancel}
        onSubmit={onSubmit}
        disabled={disabledSubmit}
        buttons={['cancel', 'submit']}
      />
    </Form>
  );
};

/*Comentario */

const mapStateToProps = (state) => {
  return {
    businessLineId: state?.PRODUCT_REGISTRATION?.businessLineId,
    subregionId: state?.PRODUCT_REGISTRATION?.subregionId,
  };
};

const ConnectedProductRegistration = connect(mapStateToProps, {
  productRegistrationSetBusinessLineId,
  productRegistrationSetSubregionId,
  productRegistrationClearSubregionId,
  productRegistrationSetCountryId,
  productRegistrationClearCountryId,
  productRegistrationSetProductNumber,
  productRegistrationSetName,
  productRegistrationSetFamily,
  productRegistrationSetClasification,
  productRegistrationSetPresentation,
  productRegistrationSetMeasurementUnit,
  productRegistrationSetUnitsPerPack,
  productRegistrationSetMinimumOrderRequired,
  productRegistrationSetMoq,
  productRegistrationSetCurrency,
  productRegistrationSetIva,
  productRegistrationSetIeps,
  productRegistrationRequestProductExistence,
  productRegistrationRequestRegistration,
})(ProductRegistration);

const ProductRegistrationWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getProductRegistrationModule()]}>
      <ConnectedProductRegistration />
    </DynamicModuleLoader>
  );
};

export default ProductRegistrationWithModules;
