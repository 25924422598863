export const MARK_AS_READ_REQUEST = 'APP/MARK_AS_READ_REQUEST';
export const MARK_AS_READ_SUCCESS = 'APP/MARK_AS_READ_SUCCESS';
export const MARK_AS_READ_FAIL = 'APP/MARK_AS_READ_FAIL';

export const MARK_ALL_AS_READ_REQUEST = 'APP/REGIONS_ALL_AS_READ_REQUEST';
export const MARK_ALL_AS_READ_SUCCESS = 'APP/MARK_ALL_AS_READ_SUCCESS';
export const MARK_ALL_AS_READ_FAIL = 'APP/MARK_ALL_AS_READ_FAIL';

//PUT
export function markAsReadRequest(payload) {
  return {
    type: MARK_AS_READ_REQUEST,
    payload,
  };
}

export function markAsReadSuccess(payload, total) {
  return {
    type: MARK_AS_READ_SUCCESS,
    payload,
    total,
  };
}

export function markAsReadFail(payload) {
  return {
    type: MARK_AS_READ_FAIL,
    payload,
  };
}

export function markAllAsReadRequest(payload) {
  return {
    type: MARK_ALL_AS_READ_REQUEST,
    payload,
  };
}

export function markAllAsReadSuccess(payload) {
  return {
    type: MARK_ALL_AS_READ_SUCCESS,
    payload,
  };
}

export function markAllAsReadFail(payload) {
  return {
    type: MARK_ALL_AS_READ_FAIL,
    payload,
  };
}

//FALSE NOTIFICATIONS NAV
export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'APP/GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAIL = 'APP/GET_NOTIFICATION_FAIL';
export const GET_NOTIFICATION_PAGE = 'GET_NOTIFICATION_PAGE';

export function getNotificationRequest(payload) {
  return {
    type: GET_NOTIFICATION_REQUEST,
    payload,
  };
}

export function getNotificationSuccess(payload, total) {
  return {
    type: GET_NOTIFICATION_SUCCESS,
    payload,
    total,
  };
}

export function getNotificationFail(payload) {
  return {
    type: GET_NOTIFICATION_FAIL,
    payload,
  };
}

/// FALSE NOTIFICATIONS
export function handlePageRequest(payload) {
  return {
    type: GET_NOTIFICATION_PAGE,
    payload,
  };
}

//TRUE NOTIFICATIONS
export const GET_NOTIFICATION_TRUE = 'APP/GET_NOTIFICATION_TRUE';
export const GET_NOTIFICATION_SUCCESS_TRUE = 'APP/GET_NOTIFICATION_TRUE_SUCCESS';
export const GET_NOTIFICATION_FAIL_TRUE = 'APP/GET_NOTIFICATION_TRUE_FAIL';

export function handlePageTrueRequest(payload) {
  return {
    type: GET_NOTIFICATION_TRUE,
    payload,
  };
}

export function getNotificationSuccessRead(payload, total) {
  return {
    type: GET_NOTIFICATION_SUCCESS_TRUE,
    payload,
    total,
  };
}

export function getNotificationFailRead(payload) {
  return {
    type: GET_NOTIFICATION_FAIL_TRUE,
    payload,
  };
}
