import React from 'react';
import { Row, Col, Form, Input } from 'antd';

const SectionInitial = () => {
  return (
    <Row>
      <Col span={24}>
        <Form.Item name="header" label="*Encabezado:">
          <Input.TextArea placeholder="Escribe Encabezado" maxLength={150} autoSize />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="greet1" label="Saludo 1:">
          <Input.TextArea placeholder="Escribe Saludo 1" maxLength={250} autoSize />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="greet2" label="Saludo 2:">
          <Input.TextArea placeholder="Escribe Saludo 2" maxLength={250} autoSize />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="greet3" label="Saludo 3:">
          <Input.TextArea placeholder="Escribe Saludo 3" maxLength={250} autoSize />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SectionInitial;
