//Imagenes en base64
export const INPUT_EDIT_IMAGES_SET = 'INPUT_EDIT_IMAGES_SET';

export function InputEditImagesSet(payload) {
  return {
    type: INPUT_EDIT_IMAGES_SET,
    payload: payload,
  };
}

// Datos previos de la tabla al Drawer Edit
export const DATA_DRAWER_EDIT_SET = 'DATA_DRAWER_EDIT_SET';

export function DataDrawerEditSet(payload) {
  return {
    type: DATA_DRAWER_EDIT_SET,
    payload: payload,
  };
}
//PUT ImageLine
export const PUT_IMAGE_LINE_REQUEST = 'PUT_IMAGE_LINE_REQUEST';
export const PUT_IMAGE_LINE_SET = 'PUT_IMAGE_LINE_SET';
export const PUT_IMAGE_LINE_CLEAN = 'PUT_IMAGE_LINE_CLEAN';

export function PUTImageLineRequest(payload) {
  return {
    type: PUT_IMAGE_LINE_REQUEST,
    payload: payload,
  };
}
export function PUTImageLineSet(payload) {
  return {
    type: PUT_IMAGE_LINE_SET,
    payload: payload,
  };
}
export function PUTImageLineClean(payload) {
  return {
    type: PUT_IMAGE_LINE_CLEAN,
  };
}
