/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  consultFiltersYearRequestOptions,
  consultFiltersYearSetValue,
} from 'RootModule/redux/actions/consultFilters';
import { addAllBtnOnOptions, handleAllBtnClick } from 'common/consults/utils';

import Filter from 'common/consults/components/Filter';

const Year = (props) => {
  const {
    consultFiltersYearRequestOptions: requestOptions,
    consultFiltersYearSetValue: setValue,
    value,
    required,
    options,
    params,
    defaultValue,
    disableSelectAll,
    serviceName,
  } = props;

  let mappedOptions = [...options];

  let jsonParams = JSON.stringify(params);

  useEffect(() => {
    let sentParams = {};
    if (params) {
      sentParams = { ...params };
    }
    requestOptions({ params: sentParams, serviceName });
  }, [jsonParams]);

  const selectChange = (selected) => {
    selected = handleAllBtnClick(selected, options);
    setValue(selected);
  };
  if (!disableSelectAll) {
    mappedOptions = addAllBtnOnOptions(options);
  }
  return (
    <Filter
      name={'Año'}
      required={required}
      value={value}
      selectChange={selectChange}
      options={mappedOptions}
      defaultValue={defaultValue}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    options: state?.APP?.consultFilters.year?.options ?? [],
    value: state?.APP?.consultFilters.year?.value,
  };
};

Year.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  required: PropTypes.bool,
  options: PropTypes.array,
  params: PropTypes.object,
  disableSelectAll: PropTypes.bool,
  serviceName: PropTypes.oneOf(['ProcessYears', 'PurchasesYears', 'ObjectivesIncentivesYears']),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
};

Year.defaultProps = {
  value: [],
  required: false,
  options: [],
  params: {},
  disableSelectAll: false,
  serviceName: 'ProcessYears',
  defaultValue: [],
};

export default connect(mapStateToProps, {
  consultFiltersYearRequestOptions,
  consultFiltersYearSetValue,
})(Year);
