import React, { useState, useEffect } from 'react';
import { Input } from '.';

function TextInput({
  label,
  tag,
  clear,
  placeholder,
  handleAction,
  defaultValue,
  readOnly,
  style,
  ...restProps
}) {
  const [value, setValue] = useState(undefined);

  const regexReplace = (event) => {
    let regexValue = event.currentTarget.value
      .replace(/[^a-z óáúéíñÁÉÍÓÚÜÑÜü]/gi, '')
      .replace(/\s /, '');
    setValue(regexValue);
    handleAction({ name: tag, value: regexValue });
  };

  useEffect(() => {
    if (!value && defaultValue) {
      setValue(defaultValue);
    }
  }, [value, defaultValue]);

  useEffect(() => {
    setValue('');
  }, [clear]);
  return (
    <>
      <Input
        label={label}
        tag={tag}
        type="text"
        value={value}
        name={tag}
        style={style}
        placeholder={placeholder}
        onChange={(e) => regexReplace(e)}
        readOnly={readOnly}
        {...restProps}
      />
    </>
  );
}
export default TextInput;
