export const responseOK = (response) => {
  return typeof response !== 'undefined' && typeof response.data !== 'undefined';
};

export const filterObject = (object, fnFilter) => {
  if (typeof object !== 'undefined') {
    let objReturn = {};
    Object.keys(object).forEach((key) => {
      if (fnFilter(object[key])) {
        objReturn = {
          ...objReturn,
          [key]: object[key],
        };
      }
    });
    return objReturn;
  }
};

export const capitalizeString = (string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatToPrice = (value, cash) => {
  let format = '';
  // const exp = new RegExp(/(\d)(?=(\d\d\d)+(?!\d))/gi);
  if (typeof value !== 'undefined' && value !== null) {
    format = `${cash ? '$' : ''} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return format;
};

export const formatNumber = (value) => {
  let format = '';
  const exp = new RegExp(/(\d)(?=(\d\d\d)+(?!\d))/gi);
  if (typeof value !== 'undefined' && value !== null) {
    format = `${value}`.replace(exp, '$1,');
  }
  return format;
};

export const formatPriceWithDecimals = (number) => {
  if (typeof number !== 'undefined' && typeof number === 'number' && !isNaN(number)) {
    return formatToPrice(number.toFixed(2)).toLocaleString();
  }
  return false;
};

export const addZeros = (num) => {
  const dec = num.split('.')[1];
  const len = dec && dec.length > 2 ? dec.length : 2;
  return Number(num).toFixed(len);
};

export const formatPriceToNumber = (string) => {
  if (typeof string === 'string' && string.length > 0) {
    let cleanValue = string.replace(/[\s$ ]/g, '');
    cleanValue = cleanValue.replace(/\$\s?|(,*)?|([$ ])/g, '');
    if (cleanValue.length > 0) {
      //tiene decimales
      // if (cleanValue.indexOf('.') > 0) {
      return parseFloat(cleanValue);
      // } else {
      //   return parseInt(cleanValue);
      // }
    }
  }
  return '';
};

export { default as getFloatParser } from './getFloatParser';
export { default as addCommasToFloat } from './addCommasToFloat';
export { default as reduceDecimalsOfFloat } from './reduceDecimalsOfFloat';
export { default as getToken } from './getToken';
export { default as userIs } from './userIs';
export { default as monthNumberToText } from './monthNumberToText';
export { default as mapQueryParams } from './mapQueryParams';
export { default as checkIfConsultHasNoElements } from './checkIfConsultHasNoElements';
export { default as formatCurrency } from './formatCurrency';
export { default as formatCommas } from './formatCommas';
