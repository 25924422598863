import userIs from '../../../../utils/userIs';

import {
  COMPARATIVE_SET_TYPE_OF_VIEW,
  COMPARATIVE_CLEAR_TYPE_OF_VIEW,
  FILTER_DATA,
  RESET_FILTER,
  REGION_REQUEST,
  REGION_SUCCESS,
  REGION_CLEAN,
  TYPE_CLIENT_REQUEST,
  TYPE_CLIENT_SUCCESS,
  TYPE_CLIENT_CLEAN,
  SEGMENTATION_REQUEST,
  SEGMENTATION_SUCCESS,
  SEGMENTATION_CLEAN,
  BUSINESS_LINES_REQUEST,
  BUSINESS_LINES_SUCCESS,
  BUSINESS_LINE_CLEAN,
  SUBREGION_REQUEST,
  SUBREGION_SUCCESS,
  SUBREGION_CLEAN,
  TERRITORIES_REQUEST,
  TERRITORIES_SUCCESS,
  TERRITORIES_CLEAN,
  ZONES_REQUEST,
  ZONES_SUCCESS,
  ZONES_CLEAN,
  INDICATOR_REQUEST,
  INDICATOR_SUCCESS,
  INDICATOR_CLEAN,
  TYPEINFO_REQUEST,
  TYPEINFO_SUCCESS,
  TYPEINFO_CLEAN,
  TYPEUNIDAD_REQUEST,
  TYPEUNIDAD_SUCCESS,
  TYPEUNIDAD_CLEAN,
  PERIODO_REQUEST,
  PERIODO_SUCCESS,
  PERIODO_CLEAN,
  YEAR_REQUEST,
  YEAR_SUCCESS,
  YEAR_CLEAN,
  MONTH_REQUEST,
  MONTH_SUCCESS,
  MONTH_CLEAN,
  CLIENT_REQUEST,
  GET_CLIENT_BY_SOLD_TO,
  CLIENT_SUCCESS,
  CLIENT_CLEAN,
  POLITICSPRODUCT_REQUEST,
  POLITICSPRODUCT_SUCCESS,
  POLITICSPRODUCT_CLEAN,
  DIPONIBILIDADPRODUCT_REQUEST,
  DISPONIBILIDADPRODUCT_SUCCESS,
  DISPONIBILIDADPRODUCT_CLEAN,
  FAMILYPRODUCT_REQUEST,
  FAMILYPRODUCT_SUCCESS,
  FAMILYPRODUCT_CLEAN,
  PRODUCT_REQUEST,
  PRODUCT_SUCCESS,
  PRODUCT_CLEAN,
  COMPARATIVE_DASHBOARD_REQUEST_MAIN_API,
  COMPARATIVE_DASHBOARD_SET_MAIN_API,
  COMPARATIVE_DASHBOARD_CLEAR_MAIN_API,
  COMPARATIVE_DASHBOARD_REQUEST_CATALOGS_LOAD,
  COMPARATIVE_DASHBOARD_SET_CATALOGS_LOAD,
  COMPARATIVE_DASHBOARD_CLEAR_CATALOGS_LOAD,
  COMPARATIVE_DASHBOARD_REQUEST_EXCEL,
  COMPARATIVE_DASHBOARD_SET_EXCEL,
  COMPARATIVE_DASHBOARD_CLEAR_EXCEL,
  COMPARATIVE_DASHBOARD_REQUEST_INFORMATION_EXISTANCES,
  COMPARATIVE_DASHBOARD_SET_INFORMATION_EXISTANCES,
  COMPARATIVE_DASHBOARD_CLEAR_INFORMATION_EXISTANCES,
} from '../actions';

const INITIAL_STATUS = {
  // initialize the filterStatus state's property
  filter: [
    { typeview: 1 },
    { indicator1: 'salesChallenge' },
    { indicator2: 'agreement' },
    { indicator3: undefined },
    { typeinformation: 1 },
    { typeunidad: 0 },
    { periodo: 1 },
    { year: [] },
    { month: [] },
    { regions: [] },
    { bussinesline: [] },
    { subregions: [] },
    { territory: [] },
    { zones: [] },
    { typeclient: [] },
    { client: [] },
    { clientList: [] },
    { segmentation: [] },
    { politicscp: [] },
    { availavilityproduct: [] },
    { family: [] },
    { product: [] },
    { typevalue: 0 },
  ],
  stateProduct: false,
  stateClient: false,
  datadasboard: [
    { indicator: 176, nombre: 'nombre1', indicator2: 175, periodo: 177 },
    { indicator: 177, nombre: 'nombre1', indicator2: 175, periodo: 176 },
    { indicator: 177, nombre: 'nombre1', indicator2: 175, periodo: 175 },
    { indicator: 177, nombre: 'nombre1', indicator2: 175, periodo: 178 },
    { indicator: 177, nombre: 'nombre1', indicator2: 175, periodo: 179 },
    { indicator: 177, nombre: 'nombre1', indicator2: 175, periodo: 174 },
    { indicator: 175, nombre: 'nombre2', indicator2: 175, periodo: 167 },
    { indicator: 178, nombre: 'nombre3', indicator2: 175, periodo: 176 },
    { indicator: 172, nombre: 'nombre4', indicator2: 175, periodo: 178 },
    { indicator: 170, nombre: 'nombre5', indicator2: 175, periodo: 179 },
  ],
  total: 0,
};

function comparativeReducer(state = INITIAL_STATUS, action) {
  const { type } = action;
  let typeUserIs = userIs('Gerente de ventas', 'Representante de ventas');
  let typeUserToTerritores = userIs('Representante de ventas');

  switch (type) {
    //request information existances
    case COMPARATIVE_DASHBOARD_REQUEST_INFORMATION_EXISTANCES:
      return {
        ...state,
        isLoading: true,
        informationExistances: null,
      };

    case COMPARATIVE_DASHBOARD_SET_INFORMATION_EXISTANCES:
      return {
        ...state,
        isLoading: false,
        informationExistances: action.payload,
      };

    case COMPARATIVE_DASHBOARD_CLEAR_INFORMATION_EXISTANCES:
      return {
        ...state,
        isLoading: false,
        informationExistances: null,
      };

    //get excel

    case COMPARATIVE_DASHBOARD_REQUEST_EXCEL:
      return {
        ...state,
        isLoading: true,
        excel: null,
      };

    case COMPARATIVE_DASHBOARD_SET_EXCEL:
      return {
        ...state,
        isLoading: false,
        excel: action.payload,
      };

    case COMPARATIVE_DASHBOARD_CLEAR_EXCEL:
      return {
        ...state,
        isLoading: false,
        excel: null,
      };

    //request catalogs

    case COMPARATIVE_DASHBOARD_REQUEST_CATALOGS_LOAD:
      return {
        ...state,
        isLoading: true,
        catalogsLoad: null,
      };

    case COMPARATIVE_DASHBOARD_SET_CATALOGS_LOAD:
      return {
        ...state,
        isLoading: false,
        catalogsLoad: true,
      };

    case COMPARATIVE_DASHBOARD_CLEAR_CATALOGS_LOAD:
      return {
        ...state,
        isLoading: false,
        catalogsLoad: null,
      };

    //main api

    case COMPARATIVE_DASHBOARD_REQUEST_MAIN_API:
      return {
        ...state,
        isLoading: true,
        mainApi: null,
      };

    case COMPARATIVE_DASHBOARD_SET_MAIN_API:
      return {
        ...state,
        isLoading: false,
        mainApi: action.payload,
      };

    case COMPARATIVE_DASHBOARD_CLEAR_MAIN_API:
      return {
        ...state,
        isLoading: false,
        mainApi: null,
      };

    //get product
    case PRODUCT_REQUEST:
      return {
        ...state,
        product: action.payload,
        stateProduct: false,
      };

    case PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload,
        total: state.total + 1,
        stateProduct: true,
        filter: state.filter.map((d) => {
          return Object.keys(d)[0] === 'product'
            ? { product: action.payload.map((product) => product.key) }
            : d;
        }),
      };

    case PRODUCT_CLEAN:
      return {
        ...state,
        product: action.payload,
      };

    //get family
    case FAMILYPRODUCT_REQUEST:
      return {
        ...state,
        familyproduct: action.payload,
      };

    case FAMILYPRODUCT_SUCCESS:
      return {
        ...state,
        familyproduct: action.payload,
        total: state.total + 1,
        filter: state.filter.map((d) => {
          return Object.keys(d)[0] === 'family'
            ? { family: action.payload.map((family) => family.key) }
            : d;
        }),
      };

    case FAMILYPRODUCT_CLEAN:
      return {
        ...state,
        familyproduct: action.payload,
      };
    //get disponibilidad
    case DIPONIBILIDADPRODUCT_REQUEST:
      return {
        ...state,
        disponibilidadproduct: action.payload,
      };

    case DISPONIBILIDADPRODUCT_SUCCESS:
      return {
        ...state,
        disponibilidadproduct: action.payload,
        total: state.total + 1,
        filter: state.filter.map((d) => {
          return Object.keys(d)[0] === 'availavilityproduct'
            ? {
                availavilityproduct: action.payload.map(
                  (disponibilidadproduct) => disponibilidadproduct.key
                ),
              }
            : d;
        }),
      };

    case DISPONIBILIDADPRODUCT_CLEAN:
      return {
        ...state,
        disponibilidadproduct: action.payload,
      };
    //get politics
    case POLITICSPRODUCT_REQUEST:
      return {
        ...state,
        politicsproduct: action.payload,
      };

    case POLITICSPRODUCT_SUCCESS:
      return {
        ...state,
        politicsproduct: action.payload,
        total: state.total + 1,
        filter: state.filter.map((d) => {
          return Object.keys(d)[0] === 'politicscp' ? { politicscp: null } : d;
        }),
      };

    case POLITICSPRODUCT_CLEAN:
      return {
        ...state,
        politicsproduct: action.payload,
      };
    //get client
    case CLIENT_REQUEST:
      return {
        ...state,
        stateClient: false,
        client: action.payload,
      };
    case GET_CLIENT_BY_SOLD_TO:
      return {
        ...state,
        clientList: action.payload,
        stateClient: false,
      };
    case CLIENT_SUCCESS:
      return {
        ...state,
        client: action.payload,
        total: state.total + 1,
        stateClient: true,
        filter: state.filter.map((d) => {
          return Object.keys(d)[0] === 'client'
            ? { client: action.payload.map((client) => client.key) }
            : d;
        }),
      };

    case CLIENT_CLEAN:
      return {
        ...state,
        client: action.payload,
      };
    //get month
    case MONTH_REQUEST:
      return {
        ...state,
        month: action.payload,
      };

    case MONTH_SUCCESS:
      return {
        ...state,
        month: action.payload,
        total: state.total + 1,
      };

    case MONTH_CLEAN:
      return {
        ...state,
        month: action.payload,
      };
    //get YEAR
    case YEAR_REQUEST:
      return {
        ...state,
        year: action.payload,
      };

    case YEAR_SUCCESS:
      return {
        ...state,
        year: action.payload,
        total: state.total + 1,
        filter: state.filter.map((d) => {
          return Object.keys(d)[0] === 'year' ? { year: action.payload[0].key } : d;
        }),
      };

    case YEAR_CLEAN:
      return {
        ...state,
        year: action.payload,
      };
    //get periodo
    case PERIODO_REQUEST:
      return {
        ...state,
        periodo: action.payload,
      };

    case PERIODO_SUCCESS:
      return {
        ...state,
        periodo: action.payload,
        total: state.total + 1,
      };

    case PERIODO_CLEAN:
      return {
        ...state,
        periodo: action.payload,
      };
    //get type unidad
    case TYPEUNIDAD_REQUEST:
      return {
        ...state,
        typeUnidad: action.payload,
      };

    case TYPEUNIDAD_SUCCESS:
      return {
        ...state,
        typeUnidad: action.payload,
        total: state.total + 1,
      };

    case TYPEUNIDAD_CLEAN:
      return {
        ...state,
        typeUnidad: action.payload,
      };
    //get TYPEINFO_REQUEST
    case TYPEINFO_REQUEST:
      return {
        ...state,
        typeinfo: action.payload,
      };

    case TYPEINFO_SUCCESS:
      return {
        ...state,
        typeinfo: action.payload,
        total: state.total + 1,
      };

    case TYPEINFO_CLEAN:
      return {
        ...state,
        typeinfo: action.payload,
      };
    //get teritories
    case INDICATOR_REQUEST:
      return {
        ...state,
        indicator: action.payload,
      };

    case INDICATOR_SUCCESS:
      return {
        ...state,
        indicator: action.payload,
        total: state.total + 1,
      };

    case INDICATOR_CLEAN:
      return {
        ...state,
        indicator: action.payload,
      };
    //get teritories
    case ZONES_REQUEST:
      return {
        ...state,
        zone: action.payload,
      };

    case ZONES_SUCCESS:
      return {
        ...state,
        zone: action.payload,
        total: state.total + 1,
      };

    case ZONES_CLEAN:
      return {
        ...state,
        zone: action.payload,
      };
    //get teritories
    case TERRITORIES_REQUEST:
      return {
        ...state,
        territory: action.payload,
      };

    case TERRITORIES_SUCCESS:
      return {
        ...state,
        territory: action.payload,
        filter: state.filter.map((d) => {
          return Object.keys(d)[0] === 'territory'
            ? {
                territory: typeUserToTerritores
                  ? action.payload.map((territory) => territory.key)
                  : [],
              }
            : d;
        }),
        total: state.total + 1,
      };

    case TERRITORIES_CLEAN:
      return {
        ...state,
        territory: action.payload,
      };
    //get subregion
    case SUBREGION_REQUEST:
      return {
        ...state,
        subregion: action.payload,
      };

    case SUBREGION_SUCCESS:
      return {
        ...state,
        subregion: action.payload,
        filter: state.filter.map((d) => {
          return Object.keys(d)[0] === 'subregions'
            ? {
                subregions: typeUserIs ? action.payload.map((subregions) => subregions.key) : [],
              }
            : d;
        }),
        total: state.total + 1,
      };

    case SUBREGION_CLEAN:
      return {
        ...state,
        subregion: action.payload,
      };
    //get data Business line
    case BUSINESS_LINES_REQUEST:
      return {
        ...state,
        businessline: action.payload,
      };

    case BUSINESS_LINES_SUCCESS:
      return {
        ...state,
        businessline: action.payload,
        filter: state.filter.map((d) => {
          return Object.keys(d)[0] === 'bussinesline'
            ? { bussinesline: action.payload.map((bussinesline) => bussinesline.key) }
            : d;
        }),
        total: state.total + 1,
      };

    case BUSINESS_LINE_CLEAN:
      return {
        ...state,
        businessline: action.payload,
      };
    //get data segmentation
    case SEGMENTATION_REQUEST:
      return {
        ...state,
        segmentation: action.payload,
      };

    case SEGMENTATION_SUCCESS:
      return {
        ...state,
        segmentation: action.payload,
        total: state.total + 1,
        /*
        filter: state.filter.map((d) => {
          return Object.keys(d)[0] === 'segmentation'
            ? { segmentation: action.payload.map((family) => family.key) }
            : d;
        }),*/
      };

    case SEGMENTATION_CLEAN:
      return {
        ...state,
        segmentation: action.payload,
      };
    //get data cliente
    case TYPE_CLIENT_REQUEST:
      return {
        ...state,
        typeclient: action.payload,
      };

    case TYPE_CLIENT_SUCCESS:
      return {
        ...state,
        typeclient: action.payload,
        total: state.total + 1,
        filter: state.filter.map((d) => {
          return Object.keys(d)[0] === 'typeclient'
            ? { typeclient: action.payload.map((typeclient) => typeclient.key) }
            : d;
        }),
      };

    case TYPE_CLIENT_CLEAN:
      return {
        ...state,
        typeclient: action.payload,
      };
    //get data region
    case REGION_REQUEST:
      return {
        ...state,
        region: action.payload,
      };

    case REGION_SUCCESS:
      return {
        ...state,
        region: action.payload,
        filter: state.filter.map((d) => {
          return Object.keys(d)[0] === 'regions'
            ? { regions: action.payload.map((region) => region.key) }
            : d;
        }),
        total: state.total + 1,
      };

    case REGION_CLEAN:
      return {
        ...state,
        region: action.payload,
      };
    //get data cliente
    case FILTER_DATA:
      return {
        ...state,
        filter: state.filter.map((d) => {
          return Object.keys(d)[0] === Object.keys(action.payload)[0] ? action.payload : d;
        }),
      };

    case RESET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    /*
    case FILTER_DATA_CLEAN:
      return {
        ...state,
        filter: action.payload,
      };
*/
    case COMPARATIVE_SET_TYPE_OF_VIEW:
      return {
        ...state,
        isLoading: false,
        typeOfView: action.payload,
      };

    case COMPARATIVE_CLEAR_TYPE_OF_VIEW:
      return {
        ...state,
        isLoading: false,
        typeOfView: null,
      };

    default:
      return state;
  }
}

export default comparativeReducer;
