import React from 'react';
import { STYLES } from './select.module.styles';
import { Select } from 'antd';
const { Option } = Select;
function AntdSelect({ options, defaultValues, handleAction, ...restProps }) {
  const { tag, label, className, errorTag, multiple, placeholder } = { ...restProps };
  const handleChange = (value) => {
    handleAction({ name: tag, value: value });
  };
  return (
    <div className={`${className}`}>
      <label htmlFor={tag} style={{ color: '#21A0D2' }}>
        {label}
      </label>
      <Select
        mode={multiple}
        style={{ width: '100%' }}
        placeholder={placeholder}
        defaultValue={defaultValues}
        onChange={handleChange}
        disabled={!options || !options.length}
        {...restProps}
      >
        {options &&
          options.map((item, key) => {
            return (
              <Option key={key} value={item.key}>
                {item.value}
              </Option>
            );
          })}
      </Select>
      {errorTag && <span style={STYLES.ERROR_TAG_STYLE}>{errorTag}</span>}
    </div>
  );
}
export default AntdSelect;
