import useGetPermissions from './useGetPermissions';
import { capitalizeString } from '../utils';
import { salesChallengeFilters } from '../models/enumFilters';
import useUser from 'hooks/useUser';
import { roleIdEnum } from 'common/models/enums';

function useGetFilters() {
  const userCurrent = useGetPermissions('sales-challenge', 'consult');
  let user = useUser();
  if (userCurrent && userCurrent.permissions) {
    let findFilters = userCurrent.permissions;
    let filtersArray = [];
    if (findFilters) {
      let filters = findFilters.find((p) => p.uri === 'topFilters');
      if (filters && filters?.components?.length > 0) {
        const { roleId } = user?.role;
        const allSubRegionAccess = JSON.parse(localStorage.getItem('allSubregionsAccess'));
        let filtersAllowed = filters.components.map((f) => f.uri);
        let excludeFilters = ['typeValue'];
        if (roleId === roleIdEnum.SALES_MANAGER && allSubRegionAccess === false) {
          excludeFilters = [...excludeFilters, salesChallengeFilters.subRegion.filterName];
        } else if (roleId === roleIdEnum.SALES_MANAGER && allSubRegionAccess) {
          excludeFilters = [...excludeFilters, salesChallengeFilters.products.filterName];
        }
        filtersAllowed.map((filter) => {
          if (!excludeFilters.includes(filter)) {
            let nameComponent = capitalizeString(filter);
            filtersArray = [
              ...filtersArray,
              {
                key: filter,
                component: nameComponent,
                enum: salesChallengeFilters[filter],
              },
            ];
          }
          return null;
        });
        //console.info('useGetFilters: filtros permitidos [', filters.components.length, ']');
      } else {
        console.warn('useGetFilters: No tiene ningún filtro permitido ');
      }
    } else {
      console.warn('useGetFilters: No tiene el permiso topFilters ,agregalo ');
    }
    return filtersArray;
  }
}

export default useGetFilters;
