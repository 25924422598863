export const CONSULT_SHOPPING_BY_FAMILY_REQUEST = 'CONSULT_SHOPPING_BY_FAMILY_REQUEST';
export const CONSULT_SHOPPING_BY_FAMILY_SET = 'CONSULT_SHOPPING_BY_FAMILY_SET';
export const CONSULT_SHOPPING_BY_FAMILY_CLEAN = 'CONSULT_SHOPPING_BY_FAMILY_CLEAN';

export function ConsultShoppingByFamilyRequestTable(payload) {
  return {
    type: CONSULT_SHOPPING_BY_FAMILY_REQUEST,
    payload: payload,
  };
}
export function ConsultShoppingByFamilySetTable(payload) {
  return {
    type: CONSULT_SHOPPING_BY_FAMILY_SET,
    payload: payload,
  };
}
export function ConsultShoppingByFamilyCleanTable(payload) {
  return {
    type: CONSULT_SHOPPING_BY_FAMILY_CLEAN,
  };
}
