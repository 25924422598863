import { request } from '../../services';
import { addKeyToResponse, openNotification, queryBuilder } from '../../common';

//import { Territories } from 'RootModule/consultFilters';

export async function getSellingGoalsYears() {
  const catalogURI = '/Catalogs/Api/v1/';
  return request.get(`${catalogURI}SalesGoals/SalesGoalsYears`).then((res) => {
    return res.data;
  });
}

export async function getPogYears() {
  const catalogURI = '/Catalogs/Api/v1/';
  return request.get(`${catalogURI}Pog/PogYears`).then((res) => {
    return res.data.sort();
  });
}

export async function getSalesYears() {
  const catalogURI = '/Catalogs/Api/v1/';
  return request.get(`${catalogURI}Sales/years`).then((resposne) => {
    return resposne.data.sort();
  });
}

export async function getCatalog(catalogName, query) {
  const catalogURI = '/Catalogs/Api/v1/';
  let queryString = query === undefined ? '' : queryBuilder(query);
  if (catalogName === 'business-estructure') {
    return request.get(`${catalogURI}BusinessStructure/ToList${queryString}`).then((response) => {
      let keyObjects = addKeyToResponse(response).sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.subRegion > b.subRegion ? 1 : b.subRegion > a.subRegion ? -1 : 0
      );
      return { data: keyObjects };
    });
  } else if (catalogName === 'selling-goals') {
    return request.get(`${catalogURI}SalesGoals${queryString}`).then(async (response) => {
      let sum = 0;
      let sumValue = 0;
      let years;
      let keyObjects = addKeyToResponse(response).sort((a, b) => {
        if (a.zone.toLowerCase() > b.zone.toLowerCase()) {
          return 1;
        }
        if (b.zone.toLowerCase() > a.zone.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      await getSellingGoalsYears().then((res) => {
        years = res;
      });
      if (response.data.length > 0) {
        response.data.forEach((element) => {
          sum = sum + element.total;
          sumValue = sumValue + element.totalUSD;
        });
      }
      let responsObj = {
        data: keyObjects,
        years: years,
        sum: `${sum.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        sumValue: `${sumValue.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      };
      return responsObj;
    });
  } else if (catalogName === 'sales') {
    return request.get(`${catalogURI}Sales${queryString}`).then(async (response) => {
      let years;
      let keyObjects = addKeyToResponse(response);
      await getSalesYears().then((res) => {
        years = res;
      });
      let responsObj = {
        data: keyObjects,
        years: years,
      };
      return responsObj;
    });
  } else if (catalogName === 'pog') {
    return request.get(`${catalogURI}Pog${queryString}`).then(async (response) => {
      let years;
      let keyObjects = addKeyToResponse(response);
      await getPogYears().then((res) => {
        years = res;
      });
      let responsObj = {
        data: keyObjects,
        years: years,
      };
      return responsObj;
    });
  } else if (catalogName === 'exchange-rate') {
    return request.get(`${catalogURI}ExchangeRate${queryString}`).then((response) => {
      return { data: addKeyToResponse(response) };
    });
  } else if (catalogName === 'process-dates') {
    return request.get(`${catalogURI}ProcessDates${queryString}`).then((response) => {
      return {
        data: addKeyToResponse(response),
      };
    });
  } else {
    return request.get(`${catalogURI}${catalogName}${queryString}`).then((response) => {
      return { data: addKeyToResponse(response) };
    });
  }
}

export async function getRemainingDays(type) {
  if (type === 'historic-information') {
    return request
      .get(`/Catalogs/Api/v1/ProcessDates/DaysLeft?processId=1`)
      .then((res) => res.data);
  } else {
    return request
      .get(`/Catalogs/Api/v1/ProcessDates/DaysLeft?processId=2`)
      .then((res) => res.data);
  }
}

function convertFile(downloadUrl, queryStr, catalogURI, archiveName, successMsg) {
  const AUTH_API_URL = process.env.REACT_APP_API_GATEWAY;
  const url = `${AUTH_API_URL}${catalogURI}${downloadUrl}${queryStr}`;
  const authHeader = `Bearer ${localStorage.getItem('token')}`;
  const options = {
    headers: {
      Authorization: authHeader,
    },
  };

  fetch(url, options)
    .then((res) => res.blob())
    .then((blob) => {
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        var base64data = reader.result;
        const link = document.createElement('a');
        link.href = base64data;
        link.download = `${archiveName}.xlsx`;
        link.click();
      };
      openNotification('success', successMsg);
    });
}

export async function DownloadCatalog(query, catalogName) {
  const catalogURI = 'Catalogs/Api/v1/';
  let queryString = query === undefined ? '' : queryBuilder(query);
  let reportUrl = '';
  let archiveName = '';
  let successMsg = '';
  if (catalogName === 'products') {
    reportUrl = 'Reports/Products';
    archiveName = 'MASM_Agroplan_AN-140_Catálogo de productos';
    successMsg = 'La descarga del catálogo de productos se realizó correctamente.';
  } else if (catalogName === 'prices') {
    reportUrl = 'Reports/Prices';
    archiveName = 'MASM_Agroplan_AN-140_Catálogo de precios';
    successMsg = 'La descarga del catálogo de precios se realizó correctamente.';
  } else if (catalogName === 'clients') {
    reportUrl = 'Reports/Clients';
    archiveName = 'MASM_Agroplan_AN-140_Catálogo de clientes';
    successMsg = 'La descarga del catálogo de clientes se realizó correctamente.';
  }
  convertFile(reportUrl, queryString, catalogURI, archiveName, successMsg);
}
