import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import React from 'react';
import './massiveUploadTableColumns.css';

const messageFaseOne = (
  <>
    <p>
      Registros para los cuales se omitió ingresar campos obligatorios,
      <br />
      se ingresó un tipo de dato que no corresponde al solicitado en el
      <br />
      campo o se excedió la longitud máxima.
    </p>
  </>
);
const messageFaseTwo = (
  <>
    <p>
      Registros de clientes para los cuales se registro información de
      <br />
      elementos con estatus &quot;Inactivo&quot;, se ingresó información de
      <br />
      estructura comercial que no esta relacionada con el cliente o
      <br />
      relacionada entre si, se realizó el registro de mas de un Territorio
      <br />
      para un cliente con presencia distinta a &quot;Nacional&quot; o se registró
      <br />
      una segmentación inexistente en el sistema.
      <br />
    </p>
  </>
);
const messageFaseThree = (
  <>
    <p>
      Registros que se encuentran repetidos dentro del archivo
      <br />
      de carga masiva.
    </p>
  </>
);
const errorMessages = [
  {
    fase: 'Fase 1',
    message: messageFaseOne,
  },
  {
    fase: 'Fase 2',
    message: messageFaseTwo,
  },
  {
    fase: 'Fase 3',
    message: messageFaseThree,
  },
];
const tableColumns = [
  {
    key: 'phase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'phase',
    className: 'title-masive',
  },
  {
    key: 'line',
    title: 'Línea de Negocio',
    dataIndex: 'line',
  },
  {
    key: 'subregion',
    title: 'Subregión',
    dataIndex: 'subregion',
  },

  {
    key: 'territory',
    title: 'Territorios',
    dataIndex: 'territory',
  },

  {
    key: 'soldTo',
    title: 'Sold to',
    dataIndex: 'soldTo',
  },
  {
    key: 'client',
    title: 'Cliente',
    dataIndex: 'client',
  },
  {
    key: 'segmentation',
    title: 'Segmentación',
    dataIndex: 'segmentation',
  },
  {
    key: 'presence',
    title: 'Presencia',
    dataIndex: 'presence',
  },
  {
    key: 'saleIncentive',
    title: 'Período de Incentivo de ventas',
    dataIndex: 'saleIncentive',
  },
  {
    key: 'pogIncentive',
    title: 'Período de Incentivo de POG',
    dataIndex: 'pogIncentive',
  },
  {
    key: 'currency',
    title: 'Moneda de acuerdo comercial',
    dataIndex: 'currency',
  },
  {
    key: 'isHeader',
    title: 'Encabezado',
    dataIndex: 'isHeader',
  },
  {
    key: 'isGreeting1',
    title: 'Saludo 1',
    dataIndex: 'isGreeting1',
  },
  {
    key: 'isGreeting2',
    title: 'Saludo 2',
    dataIndex: 'isGreeting2',
  },
  {
    key: 'isGreeting3',
    title: 'Saludo 3',
    dataIndex: 'isGreeting3',
  },
  {
    key: 'bsIntroduction',
    title: 'Introducción',
    dataIndex: 'bsIntroduction',
  },
  {
    key: 'bsSubtitle1',
    title: 'Subtítulo 1',
    dataIndex: 'bsSubtitle1',
  },
  {
    key: 'bsNote',
    title: 'Nota',
    dataIndex: 'bsNote',
  },
  {
    key: 'bsExtraObjective',
    title: 'Objetivo extra',
    dataIndex: 'bsExtraObjective',
  },
  {
    key: 'bsSubtitle2',
    title: 'Subtítulo 2',
    dataIndex: 'bsSubtitle2',
  },
  {
    key: 'pogIntroduction',
    title: 'Introducción',
    dataIndex: 'pogIntroduction',
  },
  {
    key: 'pogSubtitle',
    title: 'Subtítulo 3',
    dataIndex: 'pogSubtitle',
  },
  {
    key: 'pogNote',
    title: 'Nota',
    dataIndex: 'pogNote',
  },
  {
    key: 'cisNote',
    title: 'Nota',
    dataIndex: 'cisNote',
  },
  {
    key: 'clsDate',
    title: 'Fecha',
    dataIndex: 'clsDate',
  },
  {
    key: 'clsIntroduction',
    title: 'Introducción',
    dataIndex: 'clsIntroduction',
  },
  {
    key: 'clsCreditLine',
    title: 'Línea de crédito',
    dataIndex: 'clsCreditLine',
  },
  {
    key: 'clsExpiredAmount',
    title: 'Saldo vencido',
    dataIndex: 'clsExpiredAmount',
  },
  {
    key: 'clsValidAmount',
    title: 'Saldo no vencido',
    dataIndex: 'clsValidAmount',
  },
  {
    key: 'clsDenomination',
    title: 'Denominación',
    dataIndex: 'clsDenomination',
  },
  {
    key: 'clsNote',
    title: 'Nota',
    dataIndex: 'clsNote',
  },
  {
    key: 'fsPeriod',
    title: 'Período',
    dataIndex: 'fsPeriod',
  },
  {
    key: 'osHeader',
    title: 'Encabezado',
    dataIndex: 'osHeader',
  },
  {
    key: 'osComents',
    title: 'Comentarios',
    dataIndex: 'osComents',
  },
  {
    key: 'asContact1',
    title: 'Contacto',
    dataIndex: 'asContact1',
  },
  {
    key: 'asEmail1',
    title: 'Correo electrónico',
    dataIndex: 'asEmail1',
  },
  {
    key: 'asContact2',
    title: 'Contacto',
    dataIndex: 'asContact2',
  },
  {
    key: 'asEmail2',
    title: 'Correo electrónico',
    dataIndex: 'asEmail2',
  },
  {
    key: 'asContact3',
    title: 'Contacto',
    dataIndex: 'asContact3',
  },
  {
    key: 'asEmail3',
    title: 'Correo electrónico',
    dataIndex: 'asEmail3',
  },
  {
    key: 'asContact4',
    title: 'Contacto',
    dataIndex: 'asContact4',
  },
  {
    key: 'asEmail4',
    title: 'Correo electrónico',
    dataIndex: 'asEmail4',
  },
  {
    key: 'asContact5',
    title: 'Contacto',
    dataIndex: 'asContact5',
  },
  {
    key: 'asEmail5',
    title: 'Correo electrónico',
    dataIndex: 'asEmail5',
  },
];
export default tableColumns;
