import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  PROCESS_DATES_CONSULT_REQUEST_CONSULT,
  PROCESS_DATES_CONSULT_SET_DATE_ID,
  PROCESS_DATES_CONSULT_SET_START_DATE,
  PROCESS_DATES_CONSULT_SET_END_DATE,
  PROCESS_DATES_CONSULT_REQUEST_UPDATE,
  processDatesConsultSetConsult,
  processDatesConsultClearConsult,
  processDatesConsultSetDateId,
  processDatesConsultClearDateId,
  processDatesConsultSetStartDate,
  processDatesConsultClearStartDate,
  processDatesConsultSetEndDate,
  processDatesConsultClearEndDate,
  processDatesConsultSetUpdate,
  processDatesConsultClearUpdate,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';
import { consultProcessDates, updateProcessDate } from '../../services';
import { datePickerStringToServerString } from '../../utils';
import { message001, message002 } from 'utils/messages';

function* workProcessDatesConsultRequestConsult(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { params } = action.payload;
  try {
    const response = yield call(consultProcessDates, {
      cancelToken: source.token,
      params,
    });
    if (responseOK(response)) {
      yield put(processDatesConsultSetConsult(response.data));
    } else {
      yield put(processDatesConsultClearConsult());
    }
  } catch (error) {
    yield processDatesConsultClearConsult();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield processDatesConsultClearConsult();
      source.cancel('cancelled Request');
    }
  }
}

function* workProcessDatesConsultSetDateId(action) {
  try {
    yield processDatesConsultSetDateId(action.payload);
  } catch (error) {
    yield processDatesConsultClearDateId();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield processDatesConsultClearDateId();
    }
  }
}

function* workProcessDatesConsultSetStartDate(action) {
  try {
    yield processDatesConsultSetStartDate(action.payload);
  } catch (error) {
    yield processDatesConsultClearStartDate();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield processDatesConsultClearStartDate();
    }
  }
}

function* workProcessDatesConsultSetEndDate(action) {
  try {
    yield processDatesConsultSetEndDate(action.payload);
  } catch (error) {
    yield processDatesConsultClearEndDate();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield processDatesConsultClearEndDate();
    }
  }
}

function* workProcessDatesConsultRequestUpdate(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  let { processId, startDate, endDate } = action.payload;
  startDate = datePickerStringToServerString(startDate);
  endDate = datePickerStringToServerString(endDate);

  try {
    const response = yield call(updateProcessDate, {
      cancelToken: source.token,
      data: { processId, startDate, endDate },
    });
    if (responseOK(response)) {
      yield put(processDatesConsultSetUpdate(response.data));
      yield call(message001, 'La actualización de las fechas de proceso');
    } else {
      yield put(processDatesConsultClearUpdate());
      message002();
    }
  } catch (error) {
    yield processDatesConsultClearUpdate();
    message002();
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      yield processDatesConsultClearUpdate();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchProcessDatesConsultRequestConsult() {
  yield all([
    takeEvery(PROCESS_DATES_CONSULT_REQUEST_CONSULT, workProcessDatesConsultRequestConsult),
  ]);
}

export function* watchProcessDatesConsultSetDateId() {
  yield all([takeEvery(PROCESS_DATES_CONSULT_SET_DATE_ID, workProcessDatesConsultSetDateId)]);
}

export function* watchProcessDatesConsultSetStartDate() {
  yield all([takeEvery(PROCESS_DATES_CONSULT_SET_START_DATE, workProcessDatesConsultSetStartDate)]);
}

export function* watchProcessDatesConsultSetEndDate() {
  yield all([takeEvery(PROCESS_DATES_CONSULT_SET_END_DATE, workProcessDatesConsultSetEndDate)]);
}

export function* watchProcessDatesConsultRequestUpdate() {
  yield all([
    takeEvery(PROCESS_DATES_CONSULT_REQUEST_UPDATE, workProcessDatesConsultRequestUpdate),
  ]);
}
