import {
  READ_ONE_CLIENT_REQUEST,
  READ_ONE_CLIENT_SUCCESS,
  READ_ONE_CLIENT_CLEAN,
} from '../actions';

function clientReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case READ_ONE_CLIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case READ_ONE_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };

    case READ_ONE_CLIENT_CLEAN:
      return {
        ...state,
        isLoading: false,
        data: {},
      };

    default:
      return state;
  }
}

export default clientReducer;
