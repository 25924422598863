/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { Image, Row, Col, Upload, Spin } from 'antd';
import { SelectEdit, ModalDelete } from '../index';
import { IconContext } from 'react-icons';
import { MdModeEdit, MdDelete, MdSwapHoriz } from 'react-icons/md';

// Redux-Saga
import getRootModule from 'RootModule';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getManageImagesModule from '../../Redux-saga/getModule';
import { DrawerEditActions, TableActions } from '../../Redux-saga/actions';

const { DataDrawerEditSet } = DrawerEditActions;
const {
  RowClickSet,
  InputFilesCameraSet,
  GETListImagesRequest,
  GETListImagesClean,
  GETImageHDRequest,
  ChangeImageSet,
  GETImageHDClean,
  AreMultipleFiles,
} = TableActions;

const SectionImagesComponent = (props) => {
  const {
    // Datos previos de la tabla al Drawer Edit
    DataDrawerEditSet: setdataDrawerEdit,
    // Row Click
    RowClickSet: setrowClick,
    //Input Files Camera
    InputFilesCameraSet: setinputFiles,
    // Obtiene imagenes por estructura comercial
    GETListImagesRequest: GETListImages,
    listImagesGET,
    GETListImagesClean: ListImagesClean,
    //Obtiene imagen HD
    GETImageHDRequest: GETImageHD,
    // imageHD,
    loadingImage,
    // Change Image data
    ChangeImageSet: setChangeImage,
    // Multiple Files
    AreMultipleFiles: setmultiplesFiles,
    //loadingd data
    loadingPositionImage,
    typePosition,
    loadingChangeImage,
    typeChangeImage,
    typeImagesRequest,
    loadingImageHD,
    rowClick,
    // Props de TableImages
    propsFather,
  } = props;
  const { record } = propsFather;
  const { type, containsImages } = record;

  const [modalImageData, setModalImageData] = useState(null);
  const [modalFile, setModalFile] = useState(null);
  const [imagesGET, setimagesGET] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const comercialStructure = {
    LineId: { LineId: record.lineId, Type: 1 },
    SubRegionId: { SubregionId: record.subregionId, LineId: record.lineId, Type: 2 },
    ClientId: {
      ClientId: record.clientId,
      SubregionId: record.subRegionId,
      LineId: record.lineId,
      Type: 4,
    },
    TerritoryId: {
      TerritoryId: record.territoryId,
      SubregionId: record.subRegionId,
      LineId: record.lineId,
      Type: 3,
    },
  };
  // Al iniciar obtiene las imagenes de la estructura comercial
  useEffect(() => {
    if (type && comercialStructure[type].Type > 0 && containsImages === true) {
      let dataGet = { listImages: comercialStructure[type] };
      setisLoading(true);
      GETListImages(dataGet);
    }
  }, [type]);

  // Empareja las las imagenes obtenidas con su estructura comercial y SectionImage especifica
  useEffect(() => {
    findSectionImageChange();
  }, [listImagesGET]);

  const findSectionImageChange = () => {
    if (listImagesGET?.length > 0) {
      // ID de las imagenes
      let LineIdGET = listImagesGET[0].lineId;
      let SubregionIdGET = listImagesGET[0].SubregionId;
      let TerritoryIdGET = listImagesGET[0].TerritoryId;
      let ClientIdGET = listImagesGET[0].ClientId;

      // ID de esta SectionImage
      let LineIdSection = comercialStructure[type].LineId;
      let SubregionIdSection = comercialStructure[type].SubregionId;
      let TerritoryIdSection = comercialStructure[type].TerritoryId;
      let ClientIdSection = comercialStructure[type].ClientId;

      // Line
      if (
        listImagesGET[0].type === 'LineId' &&
        LineIdGET > 0 &&
        LineIdSection > 0 &&
        SubregionIdGET === undefined &&
        SubregionIdSection === undefined &&
        TerritoryIdGET === undefined &&
        TerritoryIdSection === undefined &&
        ClientIdSection === undefined &&
        ClientIdGET === undefined
      ) {
        if (LineIdGET === LineIdSection) {
          setimagesGET(listImagesGET);
          ListImagesClean();
          setisLoading(false);
        }
      }

      // Subregion
      if (
        listImagesGET[0].type === 'SubRegionId' &&
        LineIdGET > 0 &&
        LineIdSection > 0 &&
        SubregionIdGET > 0 &&
        SubregionIdSection > 0 &&
        TerritoryIdGET === undefined &&
        TerritoryIdSection === undefined &&
        ClientIdGET === undefined &&
        ClientIdSection === undefined
      ) {
        if (LineIdGET === LineIdSection && SubregionIdGET === SubregionIdSection) {
          setimagesGET(listImagesGET);
          ListImagesClean();
          setisLoading(false);
        }
      }

      // Territory
      if (
        listImagesGET[0].type === 'TerritoryId' &&
        LineIdGET > 0 &&
        LineIdSection > 0 &&
        SubregionIdGET > 0 &&
        SubregionIdSection > 0 &&
        TerritoryIdGET > 0 &&
        TerritoryIdSection > 0 &&
        ClientIdGET === undefined &&
        ClientIdSection === undefined
      ) {
        if (
          LineIdGET === LineIdSection &&
          SubregionIdGET === SubregionIdSection &&
          TerritoryIdGET === TerritoryIdSection
        ) {
          setimagesGET(listImagesGET);
          ListImagesClean();
          setisLoading(false);
        }
      }

      // Client
      if (
        listImagesGET[0].type === 'ClientId' &&
        LineIdGET > 0 &&
        LineIdSection > 0 &&
        SubregionIdGET > 0 &&
        SubregionIdSection > 0 &&
        ClientIdGET?.length > 0 &&
        ClientIdSection?.length > 0 &&
        TerritoryIdGET === undefined &&
        TerritoryIdSection === undefined
      ) {
        if (
          LineIdGET === LineIdSection &&
          SubregionIdGET === SubregionIdSection &&
          ClientIdGET === ClientIdSection
        ) {
          setimagesGET(listImagesGET);
          ListImagesClean();
          setisLoading(false);
        }
      }
    }
  };

  //Obtiene los archivos y los pasa a "validations"
  let fileList = [];
  let filesImg = [];
  const propsOneFile = {
    beforeUpload: (file) => {
      filesImg.push(file);
      setinputFiles(filesImg);

      return false;
    },
    fileList,
  };
  return (
    <>
      <Spin
        size="large"
        spinning={
          // First Get
          (loadingImage === true && isLoading === true && containsImages === true) ||
          //  Change position
          (loadingPositionImage === true &&
            typePosition[type] === comercialStructure[type][type] &&
            typePosition.Type === comercialStructure[type].Type) ||
          //  Change one image
          (loadingChangeImage === true &&
            typeChangeImage[type] === comercialStructure[type][type] &&
            typeChangeImage.Type === comercialStructure[type].Type) ||
          // Delete
          (loadingImage === true &&
            typeImagesRequest[type] === comercialStructure[type][type] &&
            typeImagesRequest.Type === comercialStructure[type].Type) ||
          // Get ImageHD
          (loadingImageHD === true && rowClick.type === type)
        }
      >
        <Row
          key={`${imagesGET}`}
          onClick={() => {
            setrowClick(record);
          }}
        >
          {imagesGET?.map((item, position) => {
            return (
              <Col style={{ width: '20%' }} key={`Col${item.key}`}>
                <Row justify="center" align="middle" style={{ maxHeight: '105px' }}>
                  <Col span={20} style={{ textAlign: 'center' }}>
                    <Image
                      src={`data:image/jpeg;base64,${item?.image}`}
                      // Evitar distorción de imsagen
                      style={{
                        width: ' auto',
                        maxWidth: '100%',
                        height: ' auto',
                        maxHeight: '100px',
                      }}
                      key={`imagen${item.key}`}
                    />
                  </Col>
                  <Col span={4}>
                    <Row
                      className="manita"
                      onClick={() => {
                        setModalImageData(record);
                        setModalFile(item);
                        setVisibleModal(true);
                      }}
                    >
                      <IconContext.Provider
                        style={{ textAlign: 'center' }}
                        value={{ color: '#00499A' }}
                        key={`iconPDelete${item.key}`}
                      >
                        <MdDelete className="size-icon" />
                      </IconContext.Provider>
                    </Row>
                    <Row
                      className="manita"
                      onClick={() => {
                        setdataDrawerEdit([item]);
                        GETImageHD({
                          FileId: item.fileId,
                        });
                      }}
                    >
                      <IconContext.Provider
                        style={{ textAlign: 'center' }}
                        value={{ color: '#00499A' }}
                        key={`iconPEdit${item.key}`}
                      >
                        <MdModeEdit className="size-icon" />
                      </IconContext.Provider>
                    </Row>
                    <Row
                      className="manita"
                      onClick={() => {
                        setmultiplesFiles(false);
                      }}
                    >
                      <Upload
                        listType="picture"
                        {...propsOneFile}
                        maxCount={1}
                        accept=".jpg"
                        onClick={() => setChangeImage(item)}
                      >
                        <IconContext.Provider
                          style={{ textAlign: 'center' }}
                          value={{ color: '#00499A' }}
                        >
                          <MdSwapHoriz className="size-icon" />
                        </IconContext.Provider>
                      </Upload>
                    </Row>
                  </Col>
                </Row>
                <Row justify="start" align="bottom">
                  <Col span={20} style={{ textAlign: 'center' }}>
                    <SelectEdit
                      file={item}
                      dataRow={record}
                      GETListImages={GETListImages}
                      imagesGET={imagesGET}
                    />
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </Spin>
      {visibleModal === true && (
        <ModalDelete
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          modalImageData={modalImageData}
          modalFile={modalFile}
          GETListImages={GETListImages}
          setimagesGET={setimagesGET}
          imagesGET={imagesGET}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    rowClick: state?.MANAGE_IMAGES?.Table?.rowClick,
    inputFiles: state?.MANAGE_IMAGES?.Table?.inputFilesCamera,
    listImagesGET: state?.MANAGE_IMAGES?.Table?.listImagesGET,
    imageHD: state?.MANAGE_IMAGES?.Table?.imageHD,
    typePosition: state?.MANAGE_IMAGES?.PositionImage?.dataSection?.Type,
    loadingImage: state?.MANAGE_IMAGES?.Table?.loadingImage,
    loadingImageHD: state?.MANAGE_IMAGES?.Table?.loadImageHD,
    loadingPositionImage: state?.MANAGE_IMAGES?.PositionImage?.isLoading,
    loadingChangeImage: state?.MANAGE_IMAGES?.DrawerEdit?.isLoading,
    typeChangeImage: state?.MANAGE_IMAGES?.DrawerEdit?.Type?.imageInfo,
    typeImagesRequest: state?.MANAGE_IMAGES?.Table?.dataSection?.listImages,
  };
};

const ConnectSectionImages = connect(mapStateToProps, {
  RowClickSet,
  InputFilesCameraSet,
  DataDrawerEditSet,
  GETListImagesRequest,
  GETListImagesClean,
  GETImageHDRequest,
  ChangeImageSet,
  GETImageHDClean,
  AreMultipleFiles,
})(SectionImagesComponent);

const SectionImages = (propsFather) => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getManageImagesModule()]}>
      <ConnectSectionImages propsFather={propsFather} />
    </DynamicModuleLoader>
  );
};

const memoSectionImages = memo(SectionImages);
export default memoSectionImages;
