import { IncentiveCreditNotesActions } from '../../actions';

const {
  ICN_CLIENTS_REQUEST,
  GET_ICN_CLIENTS,
  ICN_CLIENTS_FAIL,
  CLEAN_ICN_CLIENTS,
  INC_SUBREGIONS_REQUEST,
  INC_SUBREGIONS_SET,
  INC_SUBREGIONS_CLEAN,
} = IncentiveCreditNotesActions;

const incentiveCreditNotesReducers = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case INC_SUBREGIONS_REQUEST:
      return {
        ...state,
        incSubregionsData: null,
      };
    case INC_SUBREGIONS_SET:
      return {
        ...state,
        incSubregionsData: payload,
      };
    case INC_SUBREGIONS_CLEAN:
      return {
        ...state,
        incSubregionsData: [],
      };

    case ICN_CLIENTS_REQUEST:
      return {
        ...state,
        IncentiveCreditNotesData: null,
      };
    case GET_ICN_CLIENTS:
      return {
        ...state,
        IncentiveCreditNotesData: payload ?? [],
      };
    case ICN_CLIENTS_FAIL:
      return {
        ...state,
        IncentiveCreditNotesErrorMsg: payload ?? [],
      };
    case CLEAN_ICN_CLIENTS:
      return {
        ...state,
        IncentiveCreditNotesData: [],
      };
    default:
      return state;
  }
};

export default incentiveCreditNotesReducers;
