import {
  GRAPH_REQUEST,
  GRAPH_SUCCESS,
  GRAPH_FAIL,
  SALES_GOAL_REQUEST,
  SALES_GOAL_SUCCESS,
  SALES_GOAL_FAIL,
  CLEAN_GRAPH,
} from '../actions/graph';

const graph = (state = {}, action) => {
  switch (action.type) {
    case CLEAN_GRAPH:
      return {};

    case GRAPH_REQUEST: {
      return {
        ...state,
        graph: {
          isLoading: true,
          data: [],
        },
      };
    }

    case GRAPH_SUCCESS: {
      return {
        ...state,
        graph: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case GRAPH_FAIL: {
      return {
        ...state,
        graph: {
          isLoading: false,
          data: [],
        },
      };
    }

    case SALES_GOAL_REQUEST: {
      return {
        ...state,
        salesGoal: {
          isLoading: true,
          data: [],
        },
      };
    }

    case SALES_GOAL_SUCCESS: {
      return {
        ...state,
        salesGoal: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case SALES_GOAL_FAIL: {
      return {
        ...state,
        salesGoal: {
          isLoading: false,
          data: [],
        },
      };
    }

    default:
      return state;
  }
};

export default graph;
