import React from 'react';
import { Row, Col, Table as AntdTable, Result } from 'antd';
import { Input } from '../../global';

function Table({
  columns,
  loading,
  columnSum,
  columnSumValue,
  noResultsMessage,
  data,
  onChange,
  pagination = true,
  id,
}) {
  return (
    <>
      <Row style={{ marginTop: '20px' }} data-testid={'archiveTable'}>
        <Col span={24}>
          <AntdTable
            id={id}
            getPopupContainer={(trigger) => trigger.parentElement}
            locale={{ emptyText: <Result status="500" title={`${noResultsMessage}`} /> }}
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={pagination}
            style={{ minHeight: '300px' }}
            onChange={onChange}
          />
          <div style={{ display: 'flex' }}>
            {columnSum && (columnSum !== undefined || columnSum !== null) && (
              <div data-testid={'columnSum'} style={{ width: '170px' }}>
                <p style={{ color: '#7AB0D6', margin: '0' }}> Sumatoria de volumen: </p>
                <Input value={columnSum} disabled={true} style={{ backgroundColor: 'white' }} />
              </div>
            )}
            {columnSumValue && (columnSumValue !== undefined || columnSumValue !== null) && (
              <div data-testid={'columnSumValue'} style={{ width: '170px', marginLeft: '20px' }}>
                <p style={{ color: '#7AB0D6', margin: '0' }}> Sumatoria de valor: </p>
                <Input
                  value={columnSumValue}
                  disabled={true}
                  style={{ backgroundColor: 'white' }}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Table;
