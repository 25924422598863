import { request } from '../../services';
import { createParamString } from '../../common';

export async function getClientsByPlacement(lobs) {
  if (lobs && lobs.length > 0) {
    let params = createParamString(lobs, 'BusinessLines');
    return request
      .get(`/Catalogs/Api/v1/Clients/ByPlacement?PlacementId=3&${params}`)
      .then((response) => {
        let data = response.data;
        return data.map((client) => ({ clientId: client.clienteId, name: client.name }));
      });
  }
}
