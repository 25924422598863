import React, { useState, useEffect, useContext } from 'react';
import { Archive } from '../common';
import { userTableHeader } from './utils';
import { getUsersData } from './services';
import { layoutContext } from '../context';
import { useHistory } from 'react-router-dom';

function Users() {
  const history = useHistory();
  const { onChangeActiveComponent } = useContext(layoutContext);

  const [query, setQuery] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState(
    `No hay registros de usuarios en el sistema`
  );

  useEffect(() => {
    setLoading(true);
    getUsersData(query)
      .then((response) => {
        setRoles(response.roles ? response.roles : []);
        setData(response.users ? response.users : []);
        setLoading(false);
      })
      .catch((res) => history.push('/'));
  }, [query, history]);

  const handleFiltering = (value) => {
    if (Object.keys(value).length > 0) {
      onChangeActiveComponent('Users');
      setNoResultsMessage(
        'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados'
      );
    }
    setQuery(value);
  };

  const FilterHeaderChange = (pagination, filters, sorter, extra) => {
    if (extra.currentDataSource.length < data.length) {
      onChangeActiveComponent('Users');
    } else {
      onChangeActiveComponent(undefined);
    }
  };

  const onConsult = (userId) => {
    history.push(`/users/consult/detail/:${userId}`);
  };

  const onUpdate = (userId) => {
    history.push(`/users/consult/edit/:${userId}`);
  };

  return (
    <Archive
      onChange={handleFiltering}
      beFilters={true}
      searchInput={true}
      noResultsMessage={noResultsMessage}
      data={data}
      loading={loading}
      columns={userTableHeader(roles, onUpdate, onConsult)}
      onFilterHeaderChange={FilterHeaderChange}
    />
  );
}
export default Users;
