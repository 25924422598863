import React from 'react';
import { Row, Col, DatePicker, InputNumber, Form, Input } from 'antd';
import { BInput } from 'common/components';
import { formatFunc, parserFunc, keyBlock } from '../helpers/';
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
const LineCreditOccupancySection = ({ form }) => {
  return (
    <div>
      <Row>
        <Col span={12}>
          <Form.Item name="date" label="Fecha">
            <DatePicker
              className="form-add-user-input"
              value={null}
              label={'Fecha'}
              format={dateFormatList}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="introOccupa" label="Introducción:">
            <Input.TextArea maxLength={750} placeholder="Escribe una introducción" autoSize />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Form.Item name="creditLine" label="Línea de crédito:">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              className="form-add-user-input"
              max={99999999999999.99}
              min={0}
              step="0.01"
              maxLength={23}
              placeholder="0.00"
              formatter={formatFunc}
              parser={parserFunc}
              onKeyDown={keyBlock}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="notBalance" label="Saldo Vencido">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              className="form-add-user-input"
              max={99999999999999.99}
              min={0}
              step="0.01"
              maxLength={23}
              placeholder="0.00"
              formatter={formatFunc}
              parser={parserFunc}
              onKeyDown={keyBlock}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="balance" label="Saldo no vencido:">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              className="form-add-user-input"
              max={99999999999999.99}
              min={0}
              step="0.01"
              maxLength={23}
              placeholder="0.00"
              formatter={formatFunc}
              parser={parserFunc}
              onKeyDown={keyBlock}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <BInput
            className="form-add-user-input"
            name="denomination"
            maxLength={30}
            placeholder="Denominación"
            label="Denominación:"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="noteOcc" label="Nota:">
            <Input.TextArea maxLength={500} placeholder="Escribe una Nota:" autoSize />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default LineCreditOccupancySection;
