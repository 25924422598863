/* eslint-disable react/display-name */

import React from 'react';

import { getFilters } from 'Catalog/utils';
import { StatusTag } from 'Catalog/common';
import { EditEntry, DateUpdater } from '../components';
import RenderPermissions from 'common/components/RenderPermissions/RenderPermissions';

const STATUS_COLORS = {
  Registrada: 'BLUE',
  'En proceso': 'green',
  Terminada: 'GREEN',
  Inactivo: '#cc7a00',
};

const getConsultTableSchema = ({
  processDatesList,
  dateId,
  columnActivation,
  cancelUpdate,
  proceedUpdate,
  startDateChange,
  endDateChange,
  startDate,
  endDate,
  allowUpdate,
}) => {
  return [
    {
      title: 'Etapa',
      dataIndex: 'stepId',
      key: 'stepId',
      colSpan: 2,
      filters: processDatesList ? getFilters('step', processDatesList) : false,
      onFilter: (value, record) => record.step.indexOf(value) === 0,
      render: (value, record) => {
        const { stepId, startProcessDate, endProcessDate } = record;
        const active = dateId === record.stepId;
        return (
          <RenderPermissions
            permission={`update_process-dates`}
            components={
              <EditEntry
                active={active}
                allow={allowUpdate}
                columnActivation={() => columnActivation(stepId, startProcessDate, endProcessDate)}
                cancelUpdate={cancelUpdate}
                proceedUpdate={proceedUpdate}
              />
            }
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'step',
      key: 'step',
      colSpan: 0,
    },
    {
      title: 'Fecha Inicio',
      dataIndex: 'startProcessDate',
      key: 'startProcessDate',
      width: 300,
      render: (value, record) => {
        const active = dateId === record.stepId;
        return (
          <DateUpdater
            currentDate={value}
            active={active}
            dateToChange={startDate}
            onChange={startDateChange}
          />
        );
      },
    },
    {
      title: 'Fecha Fin',
      dataIndex: 'endProcessDate',
      key: 'endProcessDate',
      width: 300,
      render: (value, record) => {
        const active = dateId === record.stepId;
        return (
          <DateUpdater
            currentDate={value}
            active={active}
            dateToChange={endDate}
            onChange={endDateChange}
          />
        );
      },
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Registrada', value: 'Registrada' },
        { text: 'En proceso', value: 'En proceso' },
        { text: 'Terminada', value: 'Terminada' },
      ],
      onFilter: (value, record) => record.status === value,
      // eslint-disable-next-line react/display-name
      render: (status) => <StatusTag label={status} color={STATUS_COLORS[status]} />,
    },
  ];
};

export default getConsultTableSchema;
