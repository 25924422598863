// import { TableTransferDisponibilidad } from './index';
import { getInPoliciyProductsRequest } from '../redux-saga/actions';
import React, { useState, useEffect } from 'react';
import { FormTitleDivider } from 'common';
import { connect } from 'react-redux';
import { Row, Transfer, Table } from 'antd';
import difference from 'lodash/difference';
import tableTransferFilter from '../utils/tableTransferFilterDisp';

const leftTableColumns = [
  {
    dataIndex: 'title',
    title: 'Productos con disponibilidad',
  },
];

const rightTableColumns = [
  {
    dataIndex: 'title',
    title: 'Productos fuera de disponibilidad',
  },
];

const ProductosSelect = (props) => {
  const { form, selectSubTipo, setproductsPolicy, BRData } = props;
  const [availableProducts, setAvailableProducts] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  // const [selectedKeys, setSelectedKeys] = useState([]);

  let mockData = [];

  const getTransData = (availableProds, unavailable) => {
    // eslint-disable-next-line array-callback-return
    availableProds.map((prod) => {
      mockData.push({
        key: prod.productNumber,
        title: prod.productName,
        description: prod.productNumber,
      });
    });
    // eslint-disable-next-line array-callback-return
    unavailable.map((unv) => {
      mockData.push({
        key: unv.productNumber,
        title: unv.productName,
        description: unv.productNumber,
        chosen: true,
      });
    });

    const defTargetKeys = mockData
      .filter((item) => {
        return item.chosen === true;
      })
      .map((item) => {
        return item.key;
      });
    setAvailableProducts(mockData);
    setTargetKeys(defTargetKeys);
    setproductsPolicy(defTargetKeys);
  };

  useEffect(() => {
    if (BRData !== undefined && BRData !== null) {
      const available = BRData?.InAvailability?.data ? BRData.InAvailability.data : [];
      const unavailable = BRData?.OutAvailability?.data ? BRData.OutAvailability.data : [];
      getTransData(available, unavailable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BRData]);

  const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    <Transfer
      {...restProps}
      showSelectAll={true}
      listStyle={{
        width: '40vw',
      }}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;

        const rowSelection = {
          getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter((item) => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
          />
        );
      }}
    </Transfer>
  );

  //Detectar limpieza de Inputs
  useEffect(() => {
    resetInputsLine();
    setproductsPolicy([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectSubTipo]);

  //Vaciar todos los Inputs
  const resetInputsLine = () => {
    form.resetFields(['lineId', 'lineIdClient', 'clientId', 'subregionId']);
  };

  const onChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys);
    setproductsPolicy(nextTargetKeys);
  };

  // const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
  //   setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  //   setproductsPolicy(targetSelectedKeys);
  // };

  return (
    <div>
      {/* Tabla de tranferencia */}
      <Row>
        <FormTitleDivider title={'Información de producto'} />
      </Row>
      {/*transfers()*/}
      <Row>
        <TableTransfer
          dataSource={availableProducts}
          targetKeys={targetKeys}
          showSearch={true}
          // selectedKeys={selectedKeys}
          onChange={onChange}
          filterOption={tableTransferFilter}
          // onSelectChange={onSelectChange}
          // render={(item) => item.title}
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
        />
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    IPPRData: state?.UPDATE_BUSSINES_RULES?.updateBussinesRules?.data,
  };
}
export default connect(mapStateToProps, { getInPoliciyProductsRequest })(ProductosSelect);
