import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BSelect } from 'common/components';
import { connect } from 'react-redux';
import { companiesRequest } from 'RootModule/redux/actions/catalogs';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';

function Companies(props) {
  const {
    companies,
    companiesRequest: getCompanies,
    askFromService,
    name,
    requestParams,
    sourceId,
    onOptionsChange,
    label,
    onSuccess,
    requestMode,
    onChange,
    ...other
  } = props;

  useEffect(() => {
    if (askFromService) {
      getCompanies({ requestParams, sourceId, onSuccess, mode: requestMode });
    }
  }, [getCompanies, askFromService, requestParams, sourceId, onSuccess, requestMode]);

  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange(companies.data ?? []);
    }
  }, [companies, onOptionsChange]);

  const handleOnChange = (value) => {
    if (onChange) onChange(value, { options: companies?.data, name: props.name });
  };

  return (
    <BSelect
      isLoading={companies.isLoading}
      disabled={companies.isLoading}
      name={name}
      options={companies.data}
      label={label}
      getPopupContainer={(trigger) => trigger.parentNode}
      onChange={handleOnChange}
      {...other}
    />
  );
}

Companies.propTypes = {
  companiesRequest: PropTypes.func,
  companies: PropTypes.object,
  askFromService: PropTypes.bool,
  requestParams: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  sourceId: PropTypes.oneOf(['Agreements', 'Sales', 'Catalogs']),
};

Companies.defaultProps = {
  companiesRequest: null,
  companies: { data: [], isLoading: false },
  askFromService: true,
  label: filtersEnum.subRegion.label,
  name: filtersEnum.subRegion.name,
  requestParams: {},
  sourceId: 'Sales',
};

function mapStateToProps(state) {
  return {
    companies: state.APP?.catalogs?.companies ?? [],
  };
}

export default connect(mapStateToProps, { companiesRequest })(Companies);
