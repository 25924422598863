import React from 'react';
import { Input, Form } from 'antd';
import { setRuleRequired } from 'utils/validations';

function BInput(props) {
  const { name, isRequired, label, rules = [], ...other } = props;
  const requiredRule = isRequired ? [setRuleRequired(label)] : [];

  return (
    <Form.Item rules={[...requiredRule, ...rules]} name={name} label={label}>
      <Input {...other} />
    </Form.Item>
  );
}
BInput.displayName = 'BInput';
export default BInput;
