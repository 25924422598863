import {
  Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  Dynamic_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY,
  Dynamic_MASSIVE_UPLOAD_SET_VERIFY,
  Dynamic_MASSIVE_UPLOAD_CLEAR_VERIFY,
  Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  Dynamic_MASSIVE_UPLOAD_SET_UPLOAD,
  Dynamic_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  VALIDATE_ELEMENTS_REQUEST,
  GET_VALIDATE_ELEMENTS,
  VALIDATE_ELEMENTS_FAIL,
  CLEAN_VALIDATE_ELEMENTS,
} from '../actions';

function CreditNotesMassiveUploadReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD:
      return {
        ...state,
        isLoading: false,
        allowUpload: action.payload,
      };

    case Dynamic_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD:
      return {
        ...state,
        isLoading: false,
        allowUpload: null,
      };

    case Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY:
      return {
        ...state,
        isLoading: true,
        verify: null,
      };

    case Dynamic_MASSIVE_UPLOAD_SET_VERIFY:
      return {
        ...state,
        isLoading: false,
        verify: action.payload,
      };

    case Dynamic_MASSIVE_UPLOAD_CLEAR_VERIFY:
      return {
        ...state,
        isLoading: false,
        verify: null,
      };

    case Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD:
      return {
        ...state,
        isLoading: true,
        upload: null,
      };

    case Dynamic_MASSIVE_UPLOAD_SET_UPLOAD:
      return {
        ...state,
        isLoading: false,
        upload: action.payload,
      };

    case Dynamic_MASSIVE_UPLOAD_CLEAR_UPLOAD:
      return {
        ...state,
        isLoading: false,
        upload: null,
      };
    case VALIDATE_ELEMENTS_REQUEST:
      return {
        ...state,
        elementsValidation: null,
      };
    case GET_VALIDATE_ELEMENTS:
      return {
        ...state,
        elementsValidation: payload,
      };
    case CLEAN_VALIDATE_ELEMENTS:
      return {
        ...state,
        elementsValidation: false,
      };
    case VALIDATE_ELEMENTS_FAIL:
      return {
        ...state,
        validateElementsErrorMsg: payload ?? [],
      };
    default:
      return state;
  }
}

export default CreditNotesMassiveUploadReducer;
