import React from 'react';
import { Row, Col, Input, Form } from 'antd';

const SectionShop = ({ getSalesSection }) => {
  const { TextArea } = Input;
  return (
    <Row>
      <Col span={24}>
        <Form.Item name="introduction" label="Introducción:">
          <TextArea
            placeholder={getSalesSection?.intoduction}
            defaultValue={getSalesSection?.intoduction}
            disabled={true}
            autoSize
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="Subsection1" label="Subtítulo 1:">
          <TextArea
            placeholder={getSalesSection?.subtitle1}
            defaultValue={getSalesSection?.subtitle1}
            disabled={true}
            autoSize
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="nota" label="Nota:">
          <TextArea
            placeholder={getSalesSection?.note}
            defaultValue={getSalesSection?.note}
            disabled={true}
            autoSize
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="objetivoExtra" label="Objetivo extra:">
          <TextArea
            placeholder={getSalesSection?.objectiveExtra}
            defaultValue={getSalesSection?.objectiveExtra}
            disabled={true}
            autoSize
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="Subsection2" label="Subtítulo 2:">
          <TextArea
            placeholder={getSalesSection?.subtitle2}
            defaultValue={getSalesSection?.subtitle2}
            disabled={true}
            autoSize
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SectionShop;
