/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Tag } from 'antd';
import React from 'react';
import { IconContext } from 'react-icons';
import { MdModeEdit } from 'react-icons/md';
export const userTableHeader = (roles, onUpdate, onConsult) => [
  {
    title: ' ',
    dataIndex: ' ',
    width: '10px',
    // eslint-disable-next-line react/display-name
    render: (value, record) => (
      <IconContext.Provider value={{ color: 'blue' }}>
        <div onClick={() => onUpdate(record.userId)} style={{ cursor: 'pointer' }}>
          <MdModeEdit />
        </div>
      </IconContext.Provider>
    ),
  },
  {
    title: 'Perfil',
    dataIndex: 'profile',
    filters: roles,
    onFilter: (value, record) => value === record.profile,
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (a.role > b.role) {
        return -1;
      }
      if (b.role > a.role) {
        return 1;
      }
      return 0;
    },
    // eslint-disable-next-line react/display-name
    render: (value, record) => (
      <div onClick={() => onConsult(record.userId)} style={{ cursor: 'pointer' }}>
        <p>{value}</p>
      </div>
    ),
  },
  {
    title: 'Nombre completo',
    dataIndex: 'name',
    render: (value, r) =>
      `${r.name} ${r.surName !== null ? r.surName : ''} ${r.lastName !== null ? r.lastName : ''}`,
  },
  {
    title: 'Correo electrónico',
    dataIndex: 'email',
  },
  {
    title: 'Correo electrónico líder',
    dataIndex: 'emailLeader',
  },
  {
    title: 'Estatus',
    dataIndex: 'enabled',
    filters: [
      { text: 'Activo', value: true },
      { text: 'Inactivo', value: false },
    ],
    onFilter: (value, record) => record.enabled === value,
    // eslint-disable-next-line react/display-name
    render: (enabled) => (
      <span>
        <Tag color={enabled ? '#3A7359' : 'rgba(217, 46, 30, .8)'}>
          {enabled ? 'Activo' : 'Inactivo'}
        </Tag>
      </span>
    ),
  },
];
