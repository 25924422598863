export const PROCESS_DATES_CONSULT_REQUEST_CONSULT = 'PROCESS_DATES_CONSULT_REQUEST_CONSULT';
export const PROCESS_DATES_CONSULT_SET_CONSULT = 'PROCESS_DATES_CONSULT_SET_CONSULT';
export const PROCESS_DATES_CONSULT_CLEAR_CONSULT = 'PROCESS_DATES_CONSULT_CLEAR_CONSULT';

export const PROCESS_DATES_CONSULT_SET_DATE_ID = 'PROCESS_DATES_CONSULT_SET_DATE_ID';
export const PROCESS_DATES_CONSULT_CLEAR_DATE_ID = 'PROCESS_DATES_CONSULT_CLEAR_DATE_ID';

export const PROCESS_DATES_CONSULT_SET_START_DATE = 'PROCESS_DATES_CONSULT_SET_START_DATE';
export const PROCESS_DATES_CONSULT_CLEAR_START_DATE = 'PROCESS_DATES_CONSULT_CLEAR_START_DATE';

export const PROCESS_DATES_CONSULT_SET_END_DATE = 'PROCESS_DATES_CONSULT_SET_END_DATE';
export const PROCESS_DATES_CONSULT_CLEAR_END_DATE = 'PROCESS_DATES_CONSULT_CLEAR_END_DATE';

export const PROCESS_DATES_CONSULT_REQUEST_UPDATE = 'PROCESS_DATES_CONSULT_REQUEST_UPDATE';
export const PROCESS_DATES_CONSULT_SET_UPDATE = 'PROCESS_DATES_CONSULT_SET_UPDATE';
export const PROCESS_DATES_CONSULT_CLEAR_UPDATE = 'PROCESS_DATES_CONSULT_CLEAR_UPDATE';

export function processDatesConsultRequestConsult(payload) {
  return {
    type: PROCESS_DATES_CONSULT_REQUEST_CONSULT,
    payload: payload,
  };
}

export function processDatesConsultSetConsult(payload) {
  return {
    type: PROCESS_DATES_CONSULT_SET_CONSULT,
    payload: payload,
  };
}

export function processDatesConsultClearConsult(payload) {
  return {
    type: PROCESS_DATES_CONSULT_CLEAR_CONSULT,
  };
}

export function processDatesConsultSetDateId(payload) {
  return {
    type: PROCESS_DATES_CONSULT_SET_DATE_ID,
    payload: payload,
  };
}

export function processDatesConsultClearDateId(payload) {
  return {
    type: PROCESS_DATES_CONSULT_CLEAR_DATE_ID,
  };
}

export function processDatesConsultSetStartDate(payload) {
  return {
    type: PROCESS_DATES_CONSULT_SET_START_DATE,
    payload: payload,
  };
}

export function processDatesConsultClearStartDate(payload) {
  return {
    type: PROCESS_DATES_CONSULT_CLEAR_START_DATE,
  };
}

export function processDatesConsultSetEndDate(payload) {
  return {
    type: PROCESS_DATES_CONSULT_SET_END_DATE,
    payload: payload,
  };
}

export function processDatesConsultClearEndDate(payload) {
  return {
    type: PROCESS_DATES_CONSULT_CLEAR_END_DATE,
  };
}

export function processDatesConsultRequestUpdate(payload) {
  return {
    type: PROCESS_DATES_CONSULT_REQUEST_UPDATE,
    payload: payload,
  };
}

export function processDatesConsultSetUpdate(payload) {
  return {
    type: PROCESS_DATES_CONSULT_SET_UPDATE,
    payload: payload,
  };
}

export function processDatesConsultClearUpdate(payload) {
  return {
    type: PROCESS_DATES_CONSULT_CLEAR_UPDATE,
  };
}
