import React, { useState } from 'react';
import { Modal } from 'antd';
import { IconsMap } from 'Layout/utils';
import { useHistory } from 'react-router-dom';
import { BSelect } from 'common/components';
const ExclamationIcon = IconsMap.ExclamationCircleOutlined;

const ModalSelector = ({ setUploadType, selectUploadType }) => {
  const history = useHistory();
  const [modalIsVisible, setModalIsVisible] = useState(true);
  let dataSelect = [
    { key: 1, value: 'Anuales' },
    { key: 2, value: 'Compras' },
    { key: 3, value: 'POG' },
  ];
  const handleModalOnOK = () => {
    setModalIsVisible(false);
  };

  // #Cambiar URL
  const cancelModal = () => {
    history.push('/account-status/base-information');
  };

  const changeUploadType = (value) => {
    setUploadType(value);
  };
  return (
    <Modal
      closable={() => cancelModal()}
      visible={modalIsVisible}
      destroyOnClose
      okButtonProps={{ disabled: !selectUploadType }}
      onCancel={() => cancelModal()}
      maskClosable={false}
      onOk={handleModalOnOK}
    >
      <div className="header-modal">
        <ExclamationIcon style={{ fontSize: '1.5rem', alignSelf: 'center' }} />
        <p>Seleccionar tipo de carga</p>
      </div>
      <p> Seleccionar el tipo de carga de objetivos e incentivos que deseas realizar:</p>
      <BSelect
        isRequired
        label="Objetivos e incentivos"
        options={dataSelect}
        onChange={changeUploadType}
      />
    </Modal>
  );
};

export { ModalSelector };
