import {
  CONSULT_SHOPPING_BY_FAMILY_REQUEST,
  CONSULT_SHOPPING_BY_FAMILY_SET,
  CONSULT_SHOPPING_BY_FAMILY_CLEAN,
} from '../actions';

function accountStatusDynamicElementsReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case CONSULT_SHOPPING_BY_FAMILY_REQUEST:
      return {
        ...state,
        isLoading: true,
        consultTable: null,
      };

    case CONSULT_SHOPPING_BY_FAMILY_SET:
      return {
        ...state,
        isLoading: false,
        consultTable: action.payload,
      };

    case CONSULT_SHOPPING_BY_FAMILY_CLEAN:
      return {
        ...state,
        isLoading: false,
        consultTable: null,
      };

    default:
      return state;
  }
}

export default accountStatusDynamicElementsReducer;
