/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Row, InputNumber, Col, Form } from 'antd';
import { BInput } from 'common/components';
import { updateDataGoals } from '../redux-saga/actions';
import { useHistory } from 'react-router-dom';
import '../catalog.css';
function SalesGoalsTable() {
  const dataState = useSelector((state) => state.GOALS.Goals.data?.saleMonth);
  const dataMonth = useSelector((state) => state.GOALS.Goals.dataUpdate);
  const year = useSelector((state) => state.GOALS.Goals.data?.year);
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { update } = history.location.state;

  const parserFunc = (value) => {
    let clean = value.replace(/\$\s?|(,*)/g, '');
    if (clean && clean.length > 12) {
      let result = '';
      let decimal = clean.indexOf('.');
      if (decimal > 0 && clean.length <= 20) {
        result = clean.substring(0, 20);
      } else {
        result = clean.substring(0, 12);
      }

      return result;
    } else {
      return clean;
    }
  };
  const keyBlock = (e) => {
    if (e.shiftKey || (e.shiftKey && e.which === 51) || e.key === 'Dead') {
      e.preventDefault();
    }
    if (
      e.keyCode === 69 ||
      e.keyCode === 73 ||
      e.keyCode === 186 ||
      e.keyCode === 187 ||
      e.keyCode === 189 ||
      e.keyCode === 40 ||
      e.keyCode === 107 ||
      e.keyCode === 109 ||
      e.keyCode === 191 ||
      e.keyCode === 192 ||
      e.keyCode === 219 ||
      e.keyCode === 220 ||
      e.keyCode === 221 ||
      e.keyCode === 222 ||
      e.keyCode === 38 ||
      e.keyCode === 40 ||
      e.key === '{' ||
      e.key === '}' ||
      e.key === '+' ||
      e.key === '*' ||
      e.key === '[' ||
      e.key === ']' ||
      e.key === '´' ||
      e.key === '/' ||
      e.key === '<' ||
      e.key === '+' ||
      e.key === '´´' ||
      e.key === 'ArrowLeft' ||
      e.key === 'BracketLeft' ||
      e.key === 'BracketRight' ||
      e.key === 'Quote' ||
      e.key === 'Shift' ||
      e.key === 'Dead' ||
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      e.shiftKey ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowUp'
    ) {
      e.preventDefault();
    }
  };

  const handleMonths = (value, type, month) => {
    let dataUpdate = { value, type, month };
    dispatch(updateDataGoals(dataUpdate));
  };

  let sumVolume = 0;
  let sumValue = 0;
  if (dataMonth !== undefined) {
    dataMonth.map((value) => {
      if (value !== null) {
        sumValue += value.usdAmount;
      }
    });
    dataMonth.map((value) => {
      if (value !== null) {
        sumVolume += value.volume;
      }
    });
  }

  const formatFunc = (valueNumber) => {
    let value = valueNumber.toString();

    let format = '';
    let decimal = value.indexOf('.');

    if (decimal > 0) {
      let a = new RegExp(/(^([0-9]*\.[0-9][0-9])$)/gi);
      let onlyTwoDecimals = value.match(a, '$1');

      if (!onlyTwoDecimals) {
        let splitDecimals = value.split('.');
        if (splitDecimals[1]) {
          let sub = splitDecimals[1].substring(0, 2);
          value = `${splitDecimals[0]}.${sub}`;
        }
      }
      if (value.length <= 19) {
        format = `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        let val = value.substring(0, 19);
        format = `$${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    } else {
      if (value.length <= 12) {
        format = `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        let val = value.substring(0, 12);
        format = `$${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
    if (value === '') return '';
    else return format;
  };

  const formatFuncVolume = (valueNumber) => {
    let value = valueNumber.toString();

    let format = '';
    let decimal = value.indexOf('.');

    if (decimal > 0) {
      let a = new RegExp(/(^([0-9]*\.[0-9][0-9])$)/gi);

      let onlyTwoDecimals = value.match(a, '$1');

      if (!onlyTwoDecimals) {
        let splitDecimals = value.split('.');
        if (splitDecimals[1]) {
          let sub = splitDecimals[1].substring(0, 2);
          value = `${splitDecimals[0]}.${sub}`;
        }
      }
      if (value.length <= 18) {
        format = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        let val = value.substring(0, 18);
        format = `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    } else {
      if (value.length <= 12) {
        format = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        let val = value.substring(0, 12);
        format = `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
    return format;
  };

  const firstSemesterColumns = [
    {
      title: 'Meses',
      dataIndex: 'metas',
      key: 'meses',
    },
    {
      title: 'Ene',
      dataIndex: 'Ene',
      key: 'Ene',
      render: (type, d) => {
        if (dataState !== undefined) {
          let styleValue = null;
          let signo = '';
          if (d.key === '1') {
            if (dataState[0]?.volume && dataState[0].volume > 0) {
              styleValue = '#707070';
            }
          } else {
            if (dataState[0]?.usdAmount && dataState[0].usdAmount > 0) {
              styleValue = '#707070';
            }
            if (dataState[0]?.usdAmount >= 0 && dataState[0]?.usdAmount !== null) {
              signo = '$';
            }
          }

          if (update) {
            styleValue = 'black';
            return (
              <Form.Item name={d.key === '1' ? 'EneV' : 'EneUsd'} style={{ height: 20 }}>
                <InputNumber
                  formatter={d.key === '1' ? formatFuncVolume : formatFunc}
                  style={{ color: styleValue }}
                  parser={parserFunc}
                  onKeyDown={keyBlock}
                  onChange={(e) => {
                    handleMonths(e, d.type, '1');
                  }}
                  max={999999999999.99}
                  maxLength={d.key === '1' ? 18 : 19}
                  min={0}
                  step="0.01"
                  stringMode
                />
              </Form.Item>
            );
          } else {
            return (
              <InputNumber
                bordered={false}
                disabled
                style={{ color: styleValue }}
                formatter={(value) =>
                  `${d.key === '2' ? signo : ''}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                value={d.key === '1' ? dataState[0]?.volume : dataState[0]?.usdAmount}
              />
            );
          }
        }
      },
    },
    {
      title: 'Feb',
      dataIndex: 'Feb',
      key: 'Feb',
      render: (type, d) => {
        if (dataState !== undefined) {
          let styleValue = null;
          let signo = '';
          if (d.key === '1') {
            if (dataState[1]?.volume && dataState[1].volume > 0) {
              styleValue = '#707070';
            }
          } else {
            if (dataState[1]?.usdAmount && dataState[1].usdAmount > 0) {
              styleValue = '#707070';
            }
            if (dataState[1]?.usdAmount >= 0 && dataState[1]?.usdAmount !== null) {
              signo = '$';
            }
          }

          if (update) {
            styleValue = 'black';
            return (
              <Form.Item name={d.key === '1' ? 'FebV' : 'FebUsd'} style={{ height: 20 }}>
                <InputNumber
                  formatter={d.key === '1' ? formatFuncVolume : formatFunc}
                  style={{ color: styleValue }}
                  parser={parserFunc}
                  onKeyDown={keyBlock}
                  onChange={(e) => {
                    handleMonths(e, d.type, '2');
                  }}
                  max={999999999999.99}
                  maxLength={d.key === '1' ? 18 : 19}
                  min={0}
                  step="0.01"
                  stringMode
                />
              </Form.Item>
            );
          } else
            return (
              <InputNumber
                bordered={false}
                disabled
                style={{ color: styleValue }}
                formatter={(value) =>
                  `${d.key === '2' ? signo : ''}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                value={d.key === '1' ? dataState[1]?.volume : dataState[1]?.usdAmount}
              />
            );
        }
      },
    },
    {
      title: 'Mar',
      dataIndex: 'Mar',
      key: 'Mar',
      render: (type, d) => {
        if (dataState !== undefined) {
          let styleValue = null;
          let signo = '';
          if (d.key === '1') {
            if (dataState[2]?.volume && dataState[2].volume > 0) {
              styleValue = '#707070';
            }
          } else {
            if (dataState[2]?.usdAmount && dataState[2].usdAmount > 0) {
              styleValue = '#707070';
            }
            if (dataState[2]?.usdAmount >= 0 && dataState[2]?.usdAmount !== null) {
              signo = '$';
            }
          }

          if (update) {
            styleValue = 'black';
            return (
              <Form.Item name={d.key === '1' ? 'MarV' : 'MarUsd'} style={{ height: 20 }}>
                <InputNumber
                  formatter={d.key === '1' ? formatFuncVolume : formatFunc}
                  style={{ color: styleValue }}
                  parser={parserFunc}
                  onKeyDown={keyBlock}
                  onChange={(e) => {
                    handleMonths(e, d.type, '3');
                  }}
                  max={999999999999.99}
                  maxLength={d.key === '1' ? 18 : 19}
                  min={0}
                  step="0.01"
                  stringMode
                />
              </Form.Item>
            );
          } else
            return (
              <InputNumber
                bordered={false}
                disabled
                style={{ color: styleValue }}
                formatter={(value) =>
                  ` ${d.key === '2' ? signo : ''}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                value={d.key === '1' ? dataState[2]?.volume : dataState[2]?.usdAmount}
              />
            );
        }
      },
    },
    {
      title: 'Abr',
      dataIndex: 'Abr',
      key: 'Abr',
      render: (type, d) => {
        if (dataState !== undefined) {
          let styleValue = null;
          let signo = '';

          if (d.key === '1') {
            if (dataState[3]?.volume && dataState[3].volume > 0) {
              styleValue = '#707070';
            }
          } else {
            if (dataState[3]?.usdAmount && dataState[3].usdAmount > 0) {
              styleValue = '#707070';
            }
            if (dataState[3]?.usdAmount >= 0 && dataState[3]?.usdAmount !== null) {
              signo = '$';
            }
          }

          if (update) {
            styleValue = 'black';
            return (
              <Form.Item name={d.key === '1' ? 'AbrV' : 'AbrUsd'} style={{ height: 20 }}>
                <InputNumber
                  formatter={d.key === '1' ? formatFuncVolume : formatFunc}
                  style={{ color: styleValue }}
                  parser={parserFunc}
                  onKeyDown={keyBlock}
                  onChange={(e) => {
                    handleMonths(e, d.type, '4');
                  }}
                  max={999999999999.99}
                  maxLength={d.key === '1' ? 18 : 19}
                  min={0}
                  step="0.01"
                  stringMode
                />
              </Form.Item>
            );
          } else
            return (
              <InputNumber
                bordered={false}
                disabled
                style={{ color: styleValue }}
                formatter={(value) =>
                  `${d.key === '2' ? signo : ''}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                value={d.key === '1' ? dataState[3]?.volume : dataState[3]?.usdAmount}
              />
            );
        }
      },
    },
    {
      title: 'May',
      dataIndex: 'May',
      key: 'May',
      render: (type, d) => {
        if (dataState !== undefined) {
          let styleValue = null;
          let signo = '';
          if (d.key === '1') {
            if (dataState[4]?.volume && dataState[4].volume > 0) {
              styleValue = '#707070';
            }
          } else {
            if (dataState[4]?.usdAmount && dataState[4].usdAmount > 0) {
              styleValue = '#707070';
            }
            if (dataState[4]?.usdAmount >= 0 && dataState[4]?.usdAmount !== null) {
              signo = '$';
            }
          }

          if (update) {
            styleValue = 'black';
            return (
              <Form.Item name={d.key === '1' ? 'MayV' : 'MayUsd'} style={{ height: 20 }}>
                <InputNumber
                  formatter={d.key === '1' ? formatFuncVolume : formatFunc}
                  style={{ color: styleValue }}
                  parser={parserFunc}
                  onKeyDown={keyBlock}
                  onChange={(e) => {
                    handleMonths(e, d.type, '5');
                  }}
                  max={999999999999.99}
                  maxLength={d.key === '1' ? 18 : 19}
                  min={0}
                  step="0.01"
                  stringMode
                />
              </Form.Item>
            );
          } else
            return (
              <InputNumber
                bordered={false}
                disabled
                style={{ color: styleValue }}
                formatter={(value) =>
                  `${d.key === '2' ? signo : ''}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                value={d.key === '1' ? dataState[4]?.volume : dataState[4]?.usdAmount}
              />
            );
        }
      },
    },
    {
      title: 'Jun',
      dataIndex: 'Jun',
      key: 'Jun',
      render: (type, d) => {
        if (dataState !== undefined) {
          let styleValue = null;
          let signo = '';
          if (d.key === '1') {
            if (dataState[5]?.volume && dataState[5].volume > 0) {
              styleValue = '#707070';
            }
          } else {
            if (dataState[5]?.usdAmount && dataState[5].usdAmount > 0) {
              styleValue = '#707070';
            }
            if (dataState[5]?.usdAmount >= 0 && dataState[5]?.usdAmount !== null) {
              signo = '$';
            }
          }

          if (update) {
            styleValue = 'black';
            return (
              <Form.Item name={d.key === '1' ? 'JunV' : 'JunUsd'} style={{ height: 20 }}>
                <InputNumber
                  formatter={d.key === '1' ? formatFuncVolume : formatFunc}
                  style={{ color: styleValue }}
                  parser={parserFunc}
                  onKeyDown={keyBlock}
                  onChange={(e) => {
                    handleMonths(e, d.type, '6');
                  }}
                  max={999999999999.99}
                  maxLength={d.key === '1' ? 18 : 19}
                  min={0}
                  step="0.01"
                  stringMode
                />
              </Form.Item>
            );
          } else
            return (
              <InputNumber
                bordered={false}
                disabled
                style={{ color: styleValue }}
                formatter={(value) =>
                  ` ${d.key === '2' ? signo : ''}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                value={d.key === '1' ? dataState[5]?.volume : dataState[5]?.usdAmount}
              />
            );
        }
      },
    },
  ];

  const secondSemesterColumns = [
    {
      title: 'Meses',
      dataIndex: 'metas',
      key: 'meses',
    },
    {
      title: 'Jul',
      dataIndex: 'Jul',
      key: 'Jul',
      render: (type, d) => {
        if (dataState !== undefined) {
          let styleValue = null;
          let signo = '';
          if (d.key === '1') {
            if (dataState[6]?.volume && dataState[6].volume > 0) {
              styleValue = '#707070';
            }
          } else {
            if (dataState[6]?.usdAmount && dataState[6].usdAmount > 0) {
              styleValue = '#707070';
            }
            if (dataState[6]?.usdAmount >= 0 && dataState[6]?.usdAmount !== null) {
              signo = '$';
            }
          }

          if (update) {
            styleValue = 'black';
            return (
              <Form.Item name={d.key === '1' ? 'JulV' : 'JulUsd'} style={{ height: 20 }}>
                <InputNumber
                  formatter={d.key === '1' ? formatFuncVolume : formatFunc}
                  style={{ color: styleValue }}
                  parser={parserFunc}
                  onKeyDown={keyBlock}
                  onChange={(e) => {
                    handleMonths(e, d.type, '7');
                  }}
                  max={999999999999.99}
                  maxLength={d.key === '1' ? 18 : 19}
                  min={0}
                  step="0.01"
                  stringMode
                />
              </Form.Item>
            );
          } else
            return (
              <InputNumber
                bordered={false}
                disabled
                style={{ color: styleValue }}
                formatter={(value) =>
                  `${d.key === '2' ? signo : ''}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                value={d.key === '1' ? dataState[6]?.volume : dataState[6]?.usdAmount}
              />
            );
        }
      },
    },
    {
      title: 'Ago',
      dataIndex: 'Ago',
      key: 'Ago',
      render: (type, d) => {
        if (dataState !== undefined) {
          let styleValue = null;
          let signo = '';
          if (d.key === '1') {
            if (dataState[7]?.volume && dataState[7].volume > 0) {
              styleValue = '#707070';
            }
          } else {
            if (dataState[7]?.usdAmount && dataState[7].usdAmount > 0) {
              styleValue = '#707070';
            }
            if (dataState[7]?.usdAmount >= 0 && dataState[7]?.usdAmount !== null) {
              signo = '$';
            }
          }

          if (update) {
            styleValue = 'black';
            return (
              <Form.Item name={d.key === '1' ? 'AgoV' : 'AgoUsd'} style={{ height: 20 }}>
                <InputNumber
                  formatter={d.key === '1' ? formatFuncVolume : formatFunc}
                  style={{ color: styleValue }}
                  parser={parserFunc}
                  onKeyDown={keyBlock}
                  onChange={(e) => {
                    handleMonths(e, d.type, '8');
                  }}
                  max={999999999999.99}
                  maxLength={d.key === '1' ? 18 : 19}
                  min={0}
                  step="0.01"
                  stringMode
                />
              </Form.Item>
            );
          } else
            return (
              <InputNumber
                bordered={false}
                disabled
                style={{ color: styleValue }}
                formatter={(value) =>
                  ` ${d.key === '2' ? signo : ''}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                value={d.key === '1' ? dataState[7]?.volume : dataState[7]?.usdAmount}
              />
            );
        }
      },
    },
    {
      title: 'Sep',
      dataIndex: 'Sep',
      key: 'Sep',
      render: (type, d) => {
        if (dataState !== undefined) {
          let styleValue = null;
          let signo = '';
          if (d.key === '1') {
            if (dataState[8]?.volume && dataState[8].volume > 0) {
              styleValue = '#707070';
            }
          } else {
            if (dataState[8]?.usdAmount && dataState[8].usdAmount > 0) {
              styleValue = '#707070';
            }
            if (dataState[8]?.usdAmount >= 0 && dataState[8]?.usdAmount !== null) {
              signo = '$';
            }
          }

          if (update) {
            styleValue = 'black';
            return (
              <Form.Item name={d.key === '1' ? 'SepV' : 'SepUsd'} style={{ height: 20 }}>
                <InputNumber
                  formatter={d.key === '1' ? formatFuncVolume : formatFunc}
                  style={{ color: styleValue }}
                  parser={parserFunc}
                  onKeyDown={keyBlock}
                  onChange={(e) => {
                    handleMonths(e, d.type, '9');
                  }}
                  max={999999999999.99}
                  maxLength={d.key === '1' ? 18 : 19}
                  min={0}
                  step="0.01"
                  stringMode
                />
              </Form.Item>
            );
          } else
            return (
              <InputNumber
                bordered={false}
                disabled
                style={{ color: styleValue }}
                formatter={(value) =>
                  ` ${d.key === '2' ? signo : ''}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                value={d.key === '1' ? dataState[8]?.volume : dataState[8]?.usdAmount}
              />
            );
        }
      },
    },
    {
      title: 'Oct',
      dataIndex: 'Oct',
      key: 'Oct',
      render: (type, d) => {
        if (dataState !== undefined) {
          let styleValue = null;
          let signo = '';
          if (d.key === '1') {
            if (dataState[9]?.volume && dataState[9].volume > 0) {
              styleValue = '#707070';
            }
          } else {
            if (dataState[9]?.usdAmount && dataState[9].usdAmount > 0) {
              styleValue = '#707070';
            }
            if (dataState[9]?.usdAmount >= 0 && dataState[9]?.usdAmount !== null) {
              signo = '$';
            }
          }

          if (update) {
            styleValue = 'black';
            return (
              <Form.Item name={d.key === '1' ? 'OctV' : 'OctUsd'} style={{ height: 20 }}>
                <InputNumber
                  formatter={d.key === '1' ? formatFuncVolume : formatFunc}
                  style={{ color: styleValue }}
                  parser={parserFunc}
                  onKeyDown={keyBlock}
                  onChange={(e) => {
                    handleMonths(e, d.type, '10');
                  }}
                  max={999999999999.99}
                  maxLength={d.key === '1' ? 18 : 19}
                  min={0}
                  step="0.01"
                  stringMode
                />
              </Form.Item>
            );
          } else
            return (
              <InputNumber
                bordered={false}
                disabled
                style={{ color: styleValue }}
                formatter={(value) =>
                  `${d.key === '2' ? signo : ''}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                value={d.key === '1' ? dataState[9]?.volume : dataState[9]?.usdAmount}
              />
            );
        }
      },
    },
    {
      title: 'Nov',
      dataIndex: 'Nov',
      key: 'Nov',
      render: (type, d) => {
        if (dataState !== undefined) {
          let styleValue = null;
          let signo = '';
          if (d.key === '1') {
            if (dataState[10]?.volume && dataState[10].volume > 0) {
              styleValue = '#707070';
            }
          } else {
            if (dataState[10]?.usdAmount && dataState[10].usdAmount > 0) {
              styleValue = '#707070';
            }
            if (dataState[10]?.usdAmount >= 0 && dataState[10]?.usdAmount !== null) {
              signo = '$';
            }
          }

          if (update) {
            styleValue = 'black';
            return (
              <Form.Item name={d.key === '1' ? 'NovV' : 'NovUsd'} style={{ height: 20 }}>
                <InputNumber
                  formatter={d.key === '1' ? formatFuncVolume : formatFunc}
                  style={{ color: styleValue }}
                  parser={parserFunc}
                  onKeyDown={keyBlock}
                  onChange={(e) => {
                    handleMonths(e, d.type, '11');
                  }}
                  max={999999999999.99}
                  maxLength={d.key === '1' ? 18 : 19}
                  min={0}
                  step="0.01"
                  stringMode
                />
              </Form.Item>
            );
          } else
            return (
              <InputNumber
                bordered={false}
                disabled
                style={{ color: styleValue }}
                formatter={(value) =>
                  ` ${d.key === '2' ? signo : ''}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                value={d.key === '1' ? dataState[10]?.volume : dataState[10]?.usdAmount}
              />
            );
        }
      },
    },
    {
      title: 'Dic',
      dataIndex: 'Dic',
      key: 'Dic',
      render: (type, d) => {
        if (dataState !== undefined) {
          let styleValue = null;
          let signo = '';
          if (d.key === '1') {
            if (dataState[11]?.volume && dataState[11].volume > 0) {
              styleValue = '#707070';
            }
          } else {
            if (dataState[11]?.usdAmount && dataState[11].usdAmount > 0) {
              styleValue = '#707070';
            }
            if (dataState[11]?.usdAmount >= 0 && dataState[11]?.usdAmount !== null) {
              signo = '$';
            }
          }

          if (update) {
            styleValue = 'black';
            return (
              <Form.Item name={d.key === '1' ? 'DicV' : 'DicUsd'} style={{ height: 20 }}>
                <InputNumber
                  formatter={d.key === '1' ? formatFuncVolume : formatFunc}
                  style={{ color: styleValue }}
                  parser={parserFunc}
                  onKeyDown={keyBlock}
                  onChange={(e) => {
                    handleMonths(e, d.type, '12');
                  }}
                  max={999999999999.99}
                  maxLength={d.key === '1' ? 18 : 19}
                  min={0}
                  step="0.01"
                  stringMode
                />
              </Form.Item>
            );
          } else
            return (
              <InputNumber
                bordered={false}
                disabled
                style={{ color: styleValue }}
                formatter={(value) =>
                  `${d.key === '2' ? signo : ''}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                value={d.key === '1' ? dataState[11]?.volume : dataState[11]?.usdAmount}
              />
            );
        }
      },
    },
  ];

  const data = [
    {
      key: '1',
      metas: 'Meta en volumen',
      type: 'volume',
    },
    {
      key: '2',
      metas: 'Meta en valor (USD)',
      type: 'usdAmount',
    },
  ];
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  useEffect(() => {
    if (dataState !== undefined)
      form.setFieldsValue({
        EneV: dataState[0]?.volume,
        EneUsd: dataState[0]?.usdAmount,
        FebV: dataState[1]?.volume,
        FebUsd: dataState[1]?.usdAmount,
        MarV: dataState[2]?.volume,
        MarUsd: dataState[2]?.usdAmount,
        AbrV: dataState[3]?.volume,
        AbrUsd: dataState[3]?.usdAmount,
        MayV: dataState[4]?.volume,
        MayUsd: dataState[4]?.usdAmount,
        JunV: dataState[5]?.volume,
        JunUsd: dataState[5]?.usdAmount,
        JulV: dataState[6]?.volume,
        JulUsd: dataState[6]?.usdAmount,
        AgoV: dataState[7]?.volume,
        AgoUsd: dataState[7]?.usdAmount,
        SepV: dataState[8]?.volume,
        SepUsd: dataState[8]?.usdAmount,
        OctV: dataState[9]?.volume,
        OctUsd: dataState[9]?.usdAmount,
        NovV: dataState[10]?.volume,
        NovUsd: dataState[10]?.usdAmount,
        DicV: dataState[11]?.volume,
        DicUsd: dataState[11]?.usdAmount,
      });
  }, [dataState, form]);

  const OnValuesChange = () => {
    return;
  };

  return (
    <>
      {' '}
      <Form form={form} onValuesChange={OnValuesChange}>
        <Row align="top" justify="left">
          <Table
            columns={firstSemesterColumns}
            dataSource={data}
            pagination={false}
            bordered={true}
            scroll={{ x: 1500, y: 200 }}
            width={'100px'}
          />
          <Table
            columns={secondSemesterColumns}
            dataSource={data}
            pagination={false}
            bordered={true}
            scroll={{ x: 1500, y: 200 }}
          />
        </Row>
        <Row>
          <Col lg={4} xlg={4}>
            <BInput
              label="Sumatoria de volumen (Kg/L)"
              disabled={true}
              style={{ color: 'black' }}
              value={`${formatter.format(sumVolume).substring(1)} Kg/L`}
            />
          </Col>
          <Col lg={4} xlg={4}>
            <BInput
              label="Sumatoria de valor (USD)"
              style={{ color: 'black' }}
              disabled={true}
              value={`${formatter.format(sumValue)}`}
            />
          </Col>
          <Col lg={4} xlg={4}>
            <BInput label="Año" disabled={true} value={year} />
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default SalesGoalsTable;
