export const SECOND_VALIDATION_VALID_USER_REQUEST =
  'SECOND_VALIDATION_VALID_USER_REQUEST/TRADE_AGREEMENT';
export const SECOND_VALIDATION_VALID_USER_SUCCESS =
  'SECOND_VALIDATION_VALID_USER_SUCCESS/TRADE_AGREEMENT';
export const SECOND_VALIDATION_VALID_USER_FAIL =
  'SECOND_VALIDATION_VALID_USER_FAIL/TRADE_AGREEMENT';

export function secondValidationValidUserRequest(payload) {
  return {
    type: SECOND_VALIDATION_VALID_USER_REQUEST,
    payload,
  };
}
export function secondValidationValidUserSuccess(payload) {
  return {
    type: SECOND_VALIDATION_VALID_USER_SUCCESS,
    payload,
  };
}
export function secondValidationValidUserFail() {
  return {
    type: SECOND_VALIDATION_VALID_USER_FAIL,
  };
}
