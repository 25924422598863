export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW/TRADE-AGREEMENTS';
export const CLEAN_VIEWS = 'CLEAN_VIEWS/TRADE-AGREEMENTS';

export function setCurrentView(payload) {
  return {
    type: SET_CURRENT_VIEW,
    payload,
  };
}

export function cleanViewsRequest() {
  return {
    type: CLEAN_VIEWS,
  };
}
