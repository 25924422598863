import request from 'services/request';

export async function ProductMasterMassiveUpload({ urlEndpoint, cancelToken, data }) {
  let uri = urlEndpoint;
  return request(uri, {
    method: 'POST',
    cancelToken,
    data,
  });
}
