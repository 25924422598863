import React from 'react';
import { Row, Col } from 'antd';
import { BInput } from 'common/components';

const SignerBASF = ({ statusSwitch }) => {
  return (
    <>
      {[1, 2, 3, 4].map((d) => {
        return (
          <Row key={d}>
            <Col span={8}>
              <BInput
                disabled={statusSwitch}
                isRequired={d < 3 ? true : false}
                className="form-add-user-input"
                placeholder="Escribe nombre"
                maxLength={100}
                name={`fullName${d}`}
                label="Nombre completo del firmante:"
              />
            </Col>

            <Col span={8}>
              <BInput
                disabled={statusSwitch}
                isRequired={d < 3 ? true : false}
                className="form-add-user-input"
                maxLength={100}
                placeholder="Escribe posición"
                name={`position${d}`}
                label="Posición:"
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default SignerBASF;
