import { request } from '../../services';
import { openNotification } from '../../common';

export async function updateUser(userPayload, userId) {
  let splittedUserId = userId.split(':');
  let payload = {
    name: userPayload?.name,
    surName: userPayload?.surName,
    lastName: userPayload?.lastName,
    email: userPayload?.email,
    emailLeader: userPayload?.emailLeader,
    roleId: userPayload?.profile,
    clientsKAM: userPayload?.client,
    areaId: userPayload?.areaId,
    zones: userPayload?.zones.map((zone) => zone.id),
  };
  return request(`/Users/Api/v1/Users/${splittedUserId[1]}`, {
    method: 'PUT',
    data: payload,
  }).then((response) => {
    openNotification('success', `${response.message ? response.message : ''}`);
    return response.message;
  });
}

export async function updateUserPassword(newPass, userId) {
  return request
    .put(`/Users/Api/v1/Users/UpdatePass/${userId}`, { pass: newPass.newPass })
    .then((response) => {
      return response.message;
    });
}

export async function updateStatus(userId, status) {
  let splitedUserId = userId.split(':');
  return request
    .put(`/Users/Api/v1/Users/Enable/${splitedUserId[1]}/${status}`)
    .then((response) => {
      openNotification('success', `${response.message ? response.message : ''}`);
      return response.message;
    });
}
