export const DOWNLOAD_EXCEL_REQUEST = 'DOWNLOAD_EXCEL_REQUEST';
export const DOWNLOAD_EXCEL_SET = 'DOWNLOAD_EXCEL_SET';
export const DOWNLOAD_EXCEL_CLEAN = 'DOWNLOAD_EXCEL_CLEAN';

export function downloadExcelRequest(payload) {
  return {
    type: DOWNLOAD_EXCEL_REQUEST,
    payload: payload,
  };
}
export function downloadExcelSet(payload) {
  return {
    type: DOWNLOAD_EXCEL_SET,
    payload: payload,
  };
}

export function downloadExcelCLEAN() {
  return {
    type: DOWNLOAD_EXCEL_CLEAN,
  };
}
