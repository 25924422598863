/* eslint-disable react/display-name */
import { formatCommas } from 'utils';

const consultTableSchema = [
  {
    title: 'Sold to',
    key: 'soldTo',
    dataIndex: 'soldTo',
  },
  {
    title: 'Cliente',
    key: 'clientName',
    dataIndex: 'clientName',
  },
  {
    title: 'Línea de negocio',
    key: 'line',
    dataIndex: 'line',
  },
  {
    title: 'Territorio',
    key: 'territory',
    dataIndex: 'territory',
  },
  {
    title: 'Año',
    key: 'year',
    dataIndex: 'year',
  },
  {
    title: 'Mes',
    key: 'month',
    dataIndex: 'month',
  },
  {
    title: 'Compras brutas de productos dentro de política',
    key: 'productInPolicy',
    dataIndex: 'productInPolicy',
    render: (value) => formatCommas(value),
  },

  {
    title: 'Compras brutas de productos fuera de política',
    key: 'productOutPolicy',
    dataIndex: 'productOutPolicy',
    render: (value) => formatCommas(value),
  },
  {
    title: 'Meta',
    key: 'goal',
    dataIndex: 'goal',
    render: (value) => formatCommas(value),
  },
  {
    title: 'Periodo',
    key: 'period',
    dataIndex: 'period',
  },
];
export default consultTableSchema;
