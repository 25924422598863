export const CONSULT_MANAGE_IMAGES_REQUEST = 'CONSULT_MANAGE_IMAGES_REQUEST';
export const CONSULT_MANAGE_IMAGES_SET = 'CONSULT_MANAGE_IMAGES_SET';
export const CONSULT_MANAGE_IMAGES_CLEAN = 'CONSULT_MANAGE_IMAGES_CLEAN';

export function ConsultManageImagesRequestTable(payload) {
  return {
    type: CONSULT_MANAGE_IMAGES_REQUEST,
    payload: payload,
  };
}
export function ConsultManageImagesSetTable(payload) {
  return {
    type: CONSULT_MANAGE_IMAGES_SET,
    payload: payload,
  };
}
export function ConsultManageImagesCleanTable(payload) {
  return {
    type: CONSULT_MANAGE_IMAGES_CLEAN,
  };
}

// ROW CLICK
export const ROW_CLICK_SET = 'ROW_CLICK_SET';
export function RowClickSet(payload) {
  return {
    type: ROW_CLICK_SET,
    payload: payload,
  };
}

// INPUT FILES CAMERA
export const INPUT_FILES_CAMERA_SET = 'INPUT_FILES_CAMERA_SET';
export function InputFilesCameraSet(payload) {
  return {
    type: INPUT_FILES_CAMERA_SET,
    payload: payload,
  };
}

//Obtiene la lista de imagenes por estructura comercial
export const GET_LIST_IMAGES_REQUEST = 'GET_LIST_IMAGES_REQUEST';
export const GET_LIST_IMAGES_SET = 'GET_LIST_IMAGES_SET';
export const GET_LIST_IMAGES_CLEAN = 'GET_LIST_IMAGES_CLEAN';

export function GETListImagesRequest(payload) {
  return {
    type: GET_LIST_IMAGES_REQUEST,
    payload: payload,
  };
}
export function GETListImagesSet(payload) {
  return {
    type: GET_LIST_IMAGES_SET,
    payload: payload,
  };
}
export function GETListImagesClean(payload) {
  return {
    type: GET_LIST_IMAGES_CLEAN,
  };
}
//Obtiene la imagen HD
export const GET_IMAGE_HD_REQUEST = 'GET_IMAGE_HD_REQUEST';
export const GET_IMAGE_HD_SET = 'GET_IMAGE_HD_SET';
export const GET_IMAGE_HD_CLEAN = 'GET_IMAGE_HD_CLEAN';

export function GETImageHDRequest(payload) {
  return {
    type: GET_IMAGE_HD_REQUEST,
    payload: payload,
  };
}
export function GETImageHDSet(payload) {
  return {
    type: GET_IMAGE_HD_SET,
    payload: payload,
  };
}
export function GETImageHDClean(payload) {
  return {
    type: GET_IMAGE_HD_CLEAN,
  };
}

// Change Image data
export const CHANGE_IMAGE_SET = 'CHANGE_IMAGE_SET';
export function ChangeImageSet(payload) {
  return {
    type: CHANGE_IMAGE_SET,
    payload: payload,
  };
}
// Are multiple Files
export const ARE_MULTIPLE_FILES_SET = 'ARE_MULTIPLE_FILES_SET';
export function AreMultipleFiles(payload) {
  return {
    type: ARE_MULTIPLE_FILES_SET,
    payload: payload,
  };
}
