import request from '../../services/request';
//import { paramsSerializer } from 'RootModule/services/catalogs';
const CONFIRM_VALIDATOR_API = '/Sales/Api/v1/Validators/UserCanValidateTerritoy/';

export async function SalesChallengeTerritoryValidator(territoryId, cancelToken) {
  return request(`${CONFIRM_VALIDATOR_API}${territoryId}`, {
    method: 'GET',
    cancelToken,
  });
}
