import {
  GET_FAMILY_INFORMATION_REQUEST,
  GET_FAMILY_INFORMATION_SUCCESS,
  GET_FAMILY_INFORMATION_FAIL,
} from '../actions/getFamilyInformation';

const getFamilyInformation = (state = {}, action) => {
  switch (action.type) {
    case GET_FAMILY_INFORMATION_REQUEST: {
      return {
        ...state,
        loading: true,
        validProducts: 0,
      };
    }

    case GET_FAMILY_INFORMATION_FAIL:
    case GET_FAMILY_INFORMATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        validProducts: action.payload,
      };
    }

    default:
      return state;
  }
};

export default getFamilyInformation;
