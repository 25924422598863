import {
  ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_REQUEST_CONSULT,
  ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_SET_CONSULT,
  ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_CLEAR_CONSULT,
} from '../actions';

function accountStatusBaseInformationShoppingReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_REQUEST_CONSULT:
      return {
        ...state,
        isLoading: true,
        consult: null,
      };

    case ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_SET_CONSULT:
      return {
        ...state,
        isLoading: false,
        consult: action.payload,
      };

    case ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_CLEAR_CONSULT:
      return {
        ...state,
        isLoading: false,
        consult: null,
      };

    default:
      return state;
  }
}

export default accountStatusBaseInformationShoppingReducer;
