import React from 'react';

import { Row, Upload } from 'antd';
import { AiFillCamera } from 'react-icons/ai';
import { TableActions } from '../../../Redux-saga/actions';

// Redux
import getRootModule from 'RootModule';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getManageImagesModule from '../../../Redux-saga/getModule';
const { InputFilesCameraSet, AreMultipleFiles } = TableActions;

const BtnCameraComponent = (props) => {
  const { InputFilesCameraSet: setinputFiles, AreMultipleFiles: setmultiplesFiles, record } = props;
  let whithFiles = record?.containsImages;
  let fileList = [];
  let filesImg = [];
  const propsUpload = {
    beforeUpload: (file) => {
      filesImg.push(file);
      setinputFiles(filesImg);
      return false;
    },
    fileList,
  };
  const cleanArrayFiles = () => {
    filesImg = [];
  };
  return (
    <>
      <Row className="manita" onClick={() => cleanArrayFiles()}>
        <Upload listType="picture" {...propsUpload} maxCount={1} multiple accept=".jpg">
          <AiFillCamera
            style={{ fontSize: '20px', color: whithFiles ? 'black' : 'gray' }}
            className="manita"
            onClick={() => setmultiplesFiles(true)}
          />
        </Upload>
      </Row>
    </>
  );
};
const mapStateToProps = (state) => {
  return {};
};

const ConnectedBtnCamera = connect(mapStateToProps, {
  InputFilesCameraSet,
  AreMultipleFiles,
})(BtnCameraComponent);

const BtnCamera = (record) => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getManageImagesModule()]}>
      <ConnectedBtnCamera record={record} />
    </DynamicModuleLoader>
  );
};

const columns = [
  {
    width: '4%',
    className: 'bg-account',
    render: (_text, record) => {
      return BtnCamera(record);
    },
  },
  {
    width: '24%',
    title: 'Línea de negocio',
    dataIndex: 'line',
    className: 'bg-account',
    key: 'line',
  },
  {
    width: '24%',
    title: 'Subregión',
    dataIndex: 'subregion',
    className: 'bg-account',
    key: 'subregion',
  },
  {
    width: '24%',
    title: 'Cliente nacional',
    dataIndex: 'clientName',
    className: 'bg-account',
    key: 'clientName',
  },
  {
    width: '24%',
    title: 'Territorio',
    dataIndex: 'territory',
    className: 'bg-account',
    key: 'territory',
  },
];

export default columns;
