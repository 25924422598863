export const ADD_SALES_CHALLENGE_VALIDATOR_REQUEST = 'ADD_SALES_CHALLENGE_VALIDATOR_REQUEST';
export const ADD_SALES_CHALLENGE_VALIDATOR_SUCCESS = 'ADD_VALIDATOR_SUCCESS';
export const ADD_SALES_CHALLENGE_VALIDATOR_FAIL = 'ADD_VALIDATOR_FAIL';

export function addSalesChallengeValidatorRequest(payload) {
  return {
    type: ADD_SALES_CHALLENGE_VALIDATOR_REQUEST,
    payload,
  };
}

export function addSalesChallengeValidatorSuccess(payload) {
  return { type: ADD_SALES_CHALLENGE_VALIDATOR_SUCCESS, payload };
}

export function addSalesChallengeValidatorFail(payload) {
  return { type: ADD_SALES_CHALLENGE_VALIDATOR_FAIL, payload };
}
