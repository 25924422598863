import request from 'services/request';

const serviceURL = '/Catalogs/Api/v1/Prices';

export async function postOneRegisterPrices({ cancelToken, data }) {
  return request(`${serviceURL}`, {
    method: 'POST',
    cancelToken,
    data,
  });
}

export async function getCheckExistence({ cancelToken, params }) {
  return request(`/Catalogs/Api/v1/Prices/CheckExistence`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
