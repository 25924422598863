import React from 'react';
import { connect } from 'react-redux';
import { getSignersRequest } from 'TradeAgreements/redux/actions/generateReport';
import GenerateFileForm from 'TradeAgreements/components/LayoutTradeAgreementsGenerate/GenerateFileForm';

function LayoutTradeAgreementsGenerate(props) {
  const { territoryId, clientInfo } = props;
  return (
    <>
      <GenerateFileForm territoryId={territoryId} clientInfo={clientInfo} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    territoryId: state?.TRADE_AGREEMENTS?.infoForAgreement?.territoryInfo?.territoryId ?? false,
    clientInfo: state?.TRADE_AGREEMENTS?.infoForAgreement?.clientInfo ?? false,
    year: state?.TRADE_AGREEMENTS?.query?.year,
    lineId: state?.TRADE_AGREEMENTS?.query?.lineId,
  };
}

export default connect(mapStateToProps, { getSignersRequest })(LayoutTradeAgreementsGenerate);
