import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_es from './common/translations/common.json';
import authntication_es from './Authentication/translations/es.json';
import users_es from './Users/translations/es.json';
import catalogs_es from './Catalog/translations/es.json';
import layout_es from './Layout/translations/es.json';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  es: {
    common: common_es,
    authentication: authntication_es,
    users: users_es,
    catalogs: catalogs_es,
    layout: layout_es,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'es',
  debug: false,
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
