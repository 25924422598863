import { takeLatest, put, call, select } from 'redux-saga/effects';
import instance from '../../../services/request';
import {
  DELETE_SALES_CHALLENGE_VALIDATOR_REQUEST,
  deleteSalesChallengeValidatorSuccess,
  deleteSalesChallengeValidatorFail,
} from '../actions/deleteSalesChallengeValidator';
import { deleteSalesChallengeValidator } from '../../services/deleteSalesChallengeValidator';
import { validatorsListSalesChallengeRequest } from '../actions/validatorList';
import { openNotification } from 'common/misc/openNotification';
//import { zonesRequest } from 'RootModule/redux/actions/catalogs';
import { responseOK } from 'utils';
import { salesChallengeValidatorsCatalogRequest } from 'RootModule/redux/actions/salesChallengeValidators';

function* workDeleteSalesChallengeValidator(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const query = yield select((state) => state?.VALIDATIONS?.filters?.query);
  const { territoryId } = query;
  const paramsToGetValidators = { Territories: territoryId };
  const paramsToGetCatalog = { Territories: territoryId };

  try {
    const params = action.payload;
    const response = yield call(deleteSalesChallengeValidator, params, source.token);
    /* const requestParams = { territories: territoryId };
    let sourceId = 'Validators'; */
    //console.log('params en workDeleteSalesChallengeValidator', params);
    if (responseOK(response)) {
      yield put(deleteSalesChallengeValidatorSuccess());
      openNotification(
        'success',
        response.message
          ? response.message
          : 'La eliminación del usuario validador se realizó correctamente.'
      );
      yield put(salesChallengeValidatorsCatalogRequest({ params: paramsToGetCatalog }));
      yield put(validatorsListSalesChallengeRequest({ params: paramsToGetValidators }));
      //yield put(zonesRequest({ requestParams, sourceId }));
    } else {
      openNotification(
        'error',
        response.message ? response.message : 'No se pudo realizar la operación'
      );
    }
  } catch (error) {
    yield put(deleteSalesChallengeValidatorFail());
    console.error(error);
  }
}

export function* watchDeleteSalesChallengeValidator() {
  yield takeLatest(DELETE_SALES_CHALLENGE_VALIDATOR_REQUEST, workDeleteSalesChallengeValidator);
}
