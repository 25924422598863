import {
  REJECT_REQUEST,
  rejectSuccess,
  rejectFail,
} from 'SalesChallengeRefactor/redux/actions/rejectSalesChallenge';
import { takeLatest, cancelled, put, call, select } from 'redux-saga/effects';
import { responseOK } from 'SalesChallengeRefactor/utils';
import instance from 'services/request';
import { openNotification } from 'common/misc/openNotification';
import { rejectSalesChallengeByAdmin } from 'SalesChallengeRefactor/services/rejectSalesChallenge';
import { indicatorsRequest, territoryData } from '../actions/indicators';
import { infoRequest } from 'SalesChallengeRefactor/redux/actions/info';

function* workRejectRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const state = yield select();
  const { zoneId, year } = state?.SALES_CHALLENGE?.query;

  const indicatorsParams = {
    year,
    zoneId,
  };

  /* infoParams: {
    zoneId: zone,
    year: selectedYear,
  }, */

  try {
    const { payload } = action;
    const { params, afterDeleting } = payload;
    const response = yield call(rejectSalesChallengeByAdmin, { params, cancelToken: source.token });
    const scope = 'Zone';
    if (responseOK(response)) {
      setTimeout(() => {
        openNotification('success', response.message);
      }, 500);
      /* yield put(indicatorsRequest({ name: scope, params: { ...infoParams } }));
      yield put(territoryData({ name: scope, params: { ...infoParams } })); */
      yield put(
        infoRequest({
          name: scope,
          params: indicatorsParams,
        })
      );

      yield put(indicatorsRequest({ params: indicatorsParams, name: 'Zone' }));
      yield put(territoryData({ name: 'Zone', params: indicatorsParams }));
      yield put(rejectSuccess(response.data));
    } else {
      yield put(rejectFail());
    }

    if (afterDeleting) {
      afterDeleting();
    }
  } catch (error) {
    yield put(rejectFail());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchDeleteProductRequest() {
  yield takeLatest(REJECT_REQUEST, workRejectRequest);
}
