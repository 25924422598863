import React from 'react';
import { Row, Col, Input, Form } from 'antd';
const OthersSection = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item name="headOther" label="Encabezado:">
            <Input.TextArea placeholder="Escribe un encabezado" maxLength={150} autoSize />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="comment" label="Comentarios:">
            <Input.TextArea placeholder="Escribe comentarios" maxLength={750} autoSize />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default OthersSection;
