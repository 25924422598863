export const PRODUCT_REGISTRATION_SET_BUSINESS_LINE_ID =
  'PRODUCT_REGISTRATION_SET_BUSINESS_LINE_ID';
export const PRODUCT_REGISTRATION_CLEAR_BUSINESS_LINE_ID =
  'PRODUCT_REGISTRATION_CLEAR_BUSINESS_LINE_ID';

export const PRODUCT_REGISTRATION_SET_SUBREGION_ID = 'PRODUCT_REGISTRATION_SET_SUBREGION_ID';
export const PRODUCT_REGISTRATION_CLEAR_SUBREGION_ID = 'PRODUCT_REGISTRATION_CLEAR_SUBREGION_ID';

export const PRODUCT_REGISTRATION_SET_COUNTRY_ID = 'PRODUCT_REGISTRATION_SET_COUNTRY_ID';
export const PRODUCT_REGISTRATION_CLEAR_COUNTRY_ID = 'PRODUCT_REGISTRATION_CLEAR_COUNTRY_ID';

export const PRODUCT_REGISTRATION_SET_PRODUCT_NUMBER = 'PRODUCT_REGISTRATION_SET_PRODUCT_NUMBER';
export const PRODUCT_REGISTRATION_CLEAR_PRODUCT_NUMBER =
  'PRODUCT_REGISTRATION_CLEAR_PRODUCT_NUMBER';

export const PRODUCT_REGISTRATION_SET_NAME = 'PRODUCT_REGISTRATION_SET_NAME';
export const PRODUCT_REGISTRATION_CLEAR_NAME = 'PRODUCT_REGISTRATION_CLEAR_NAME';

export const PRODUCT_REGISTRATION_SET_FAMILY = 'PRODUCT_REGISTRATION_SET_FAMILY';
export const PRODUCT_REGISTRATION_CLEAR_FAMILY = 'PRODUCT_REGISTRATION_CLEAR_FAMILY';

export const PRODUCT_REGISTRATION_SET_CLASIFICATION = 'PRODUCT_REGISTRATION_SET_CLASIFICATION';
export const PRODUCT_REGISTRATION_CLEAR_CLASIFICATION = 'PRODUCT_REGISTRATION_CLEAR_CLASIFICATION';

export const PRODUCT_REGISTRATION_SET_PRESENTATION = 'PRODUCT_REGISTRATION_SET_PRESENTATION';
export const PRODUCT_REGISTRATION_CLEAR_PRESENTATION = 'PRODUCT_REGISTRATION_CLEAR_PRESENTATION';

export const PRODUCT_REGISTRATION_SET_MEASUREMENT_UNIT =
  'PRODUCT_REGISTRATION_SET_MEASUREMENT_UNIT';
export const PRODUCT_REGISTRATION_CLEAR_MEASUREMENT_UNIT =
  'PRODUCT_REGISTRATION_CLEAR_MEASUREMENT_UNIT';

export const PRODUCT_REGISTRATION_SET_UNITS_PER_PACK = 'PRODUCT_REGISTRATION_SET_UNITS_PER_PACK';
export const PRODUCT_REGISTRATION_CLEAR_UNITS_PER_PACK =
  'PRODUCT_REGISTRATION_CLEAR_UNITS_PER_PACK';

export const PRODUCT_REGISTRATION_SET_MINIMUM_ORDER_REQUIRED =
  'PRODUCT_REGISTRATION_SET_MINIMUM_ORDER_REQUIRED';
export const PRODUCT_REGISTRATION_CLEAR_MINIMUM_ORDER_REQUIRED =
  'PRODUCT_REGISTRATION_CLEAR_MINIMUM_ORDER_REQUIRED';

export const PRODUCT_REGISTRATION_SET_MOQ = 'PRODUCT_REGISTRATION_SET_MOQ';
export const PRODUCT_REGISTRATION_CLEAR_MOQ = 'PRODUCT_REGISTRATION_CLEAR_MOQ';

export const PRODUCT_REGISTRATION_SET_CURRENCY = 'PRODUCT_REGISTRATION_SET_CURRENCY';
export const PRODUCT_REGISTRATION_CLEAR_CURRENCY = 'PRODUCT_REGISTRATION_CLEAR_CURRENCY';

export const PRODUCT_REGISTRATION_SET_IVA = 'PRODUCT_REGISTRATION_SET_IVA';
export const PRODUCT_REGISTRATION_CLEAR_IVA = 'PRODUCT_REGISTRATION_CLEAR_IVA';

export const PRODUCT_REGISTRATION_SET_IEPS = 'PRODUCT_REGISTRATION_SET_IEPS';
export const PRODUCT_REGISTRATION_CLEAR_IEPS = 'PRODUCT_REGISTRATION_CLEAR_IEPS';

export const PRODUCT_REGISTRATION_REQUEST_PRODUCT_EXISTENCE =
  'PRODUCT_REGISTRATION_REQUEST_PRODUCT_EXISTENCE';
export const PRODUCT_REGISTRATION_SET_PRODUCT_EXISTENCE =
  'PRODUCT_REGISTRATION_SET_PRODUCT_EXISTENCE';
export const PRODUCT_REGISTRATION_CLEAR_PRODUCT_EXISTENCE =
  'PRODUCT_REGISTRATION_CLEAR_PRODUCT_EXISTENCE';

export const PRODUCT_REGISTRATION_REQUEST_REGISTRATION =
  'PRODUCT_REGISTRATION_REQUEST_REGISTRATION';
export const PRODUCT_REGISTRATION_SET_REGISTRATION = 'PRODUCT_REGISTRATION_SET_REGISTRATION';
export const PRODUCT_REGISTRATION_CLEAR_REGISTRATION = 'PRODUCT_REGISTRATION_CLEAR_REGISTRATION';

export function productRegistrationSetBusinessLineId(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_BUSINESS_LINE_ID,
    payload: payload,
  };
}

export function productRegistrationClearBusinessLineId(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_BUSINESS_LINE_ID,
  };
}

export function productRegistrationSetSubregionId(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_SUBREGION_ID,
    payload: payload,
  };
}

export function productRegistrationClearSubregionId(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_SUBREGION_ID,
  };
}

export function productRegistrationSetCountryId(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_COUNTRY_ID,
    payload: payload,
  };
}

export function productRegistrationClearCountryId(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_COUNTRY_ID,
  };
}

export function productRegistrationSetProductNumber(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_PRODUCT_NUMBER,
    payload: payload,
  };
}

export function productRegistrationClearProductNumber(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_PRODUCT_NUMBER,
  };
}

export function productRegistrationSetName(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_NAME,
    payload: payload,
  };
}

export function productRegistrationClearName(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_NAME,
  };
}

export function productRegistrationSetFamily(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_FAMILY,
    payload: payload,
  };
}

export function productRegistrationClearFamily(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_FAMILY,
  };
}

export function productRegistrationSetClasification(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_CLASIFICATION,
    payload: payload,
  };
}

export function productRegistrationClearClasification(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_CLASIFICATION,
  };
}

export function productRegistrationSetPresentation(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_PRESENTATION,
    payload: payload,
  };
}

export function productRegistrationClearPresentation(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_PRESENTATION,
  };
}

export function productRegistrationSetMeasurementUnit(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_MEASUREMENT_UNIT,
    payload: payload,
  };
}

export function productRegistrationClearMeasurementUnit(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_MEASUREMENT_UNIT,
  };
}

export function productRegistrationSetUnitsPerPack(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_UNITS_PER_PACK,
    payload: payload,
  };
}

export function productRegistrationClearUnitsPerPack(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_UNITS_PER_PACK,
  };
}

export function productRegistrationSetMinimumOrderRequired(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_MINIMUM_ORDER_REQUIRED,
    payload: payload,
  };
}

export function productRegistrationClearMinimumOrderRequired(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_MINIMUM_ORDER_REQUIRED,
  };
}

export function productRegistrationSetMoq(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_MOQ,
    payload: payload,
  };
}

export function productRegistrationClearMoq(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_MOQ,
  };
}

export function productRegistrationSetCurrency(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_CURRENCY,
    payload: payload,
  };
}

export function productRegistrationClearCurrency(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_CURRENCY,
  };
}

export function productRegistrationSetIva(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_IVA,
    payload: payload,
  };
}

export function productRegistrationClearIva(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_IVA,
  };
}

export function productRegistrationSetIeps(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_IEPS,
    payload: payload,
  };
}

export function productRegistrationClearIeps(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_IEPS,
  };
}

export function productRegistrationRequestProductExistence(payload) {
  return {
    type: PRODUCT_REGISTRATION_REQUEST_PRODUCT_EXISTENCE,
    payload: payload,
  };
}

export function productRegistrationSetProductExistence(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_PRODUCT_EXISTENCE,
    payload: payload,
  };
}

export function productRegistrationClearProductExistence(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_PRODUCT_EXISTENCE,
  };
}
//
export function productRegistrationRequestRegistration(payload) {
  return {
    type: PRODUCT_REGISTRATION_REQUEST_REGISTRATION,
    payload: payload,
  };
}

export function productRegistrationSetRegistration(payload) {
  return {
    type: PRODUCT_REGISTRATION_SET_REGISTRATION,
    payload: payload,
  };
}

export function productRegistrationClearRegistration(payload) {
  return {
    type: PRODUCT_REGISTRATION_CLEAR_REGISTRATION,
  };
}
