import request from 'services/request';
import apiUrls from '../utils/apiConstants';
import { paramsSerializer } from 'RootModule/services/catalogs';

export async function requestSectionData({ cancelToken, payload }) {
  const { getAdminSections } = apiUrls;
  const params = payload;
  return request(getAdminSections, {
    method: 'GET',
    cancelToken,
    params,
    paramsSerializer,
  });
}

export async function requestRegisterData({ cancelToken, payload }) {
  const { register } = apiUrls;
  const data = payload;
  return request(register, {
    method: 'POST',
    cancelToken,
    data,
  });
}

export async function requestElementsExistence({ cancelToken, payload }) {
  const { validateDinamicElements } = apiUrls;
  const data = payload;
  return request(validateDinamicElements, {
    method: 'GET',
    cancelToken,
    data,
  });
}
