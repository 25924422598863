import { Modal, Switch } from 'antd';
import React, { useState } from 'react';

import { connect } from 'react-redux';

import { tableComponentsChangingSwitchRequestSetStatus } from 'RootModule/redux/actions/tableComponents';

const ActiveSwitch = (props) => {
  const {
    tableComponentsChangingSwitchRequestSetStatus: requestChangeStatus,

    id,
    extraParams,

    originalStatus,
  } = props;

  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [changed, setChanged] = useState(false);

  const determineCurrentStatus = () => {
    if (changed) {
      return status;
    }
    return originalStatus;
  };

  const enableLoading = () => {
    setIsLoading(true);
  };
  const disableLoading = () => {
    setIsLoading(false);
  };

  const handleRequestChange = () => {
    const currentStatus = determineCurrentStatus();
    requestChangeStatus({
      enableLoading,
      disableLoading,
      setStatus,
      clientId: id,
      extraParams,
      status: !currentStatus,
    });

    setChanged(true);
  };

  const openConfirmationModal = () => {
    const currentStatus = determineCurrentStatus();
    const message = `¿Está seguro que desea ${
      currentStatus ? 'desactivar' : 'activar'
    } el registro del elemento dinámico de estado de cuenta?`;

    Modal.confirm({
      title: 'Confirmar acción',
      content: message,
      onOk: handleRequestChange,
    });
  };

  const LabelDiv = ({ children }) => {
    const labelStyle = {
      minWidth: '70px',
      textAlign: 'center',
    };
    return <div style={labelStyle}>{children}</div>;
  };

  const getSwitchStyle = () => {
    return { backgroundColor: `${determineCurrentStatus() ? 'green' : 'red'}` };
  };

  return (
    <Switch
      onClick={openConfirmationModal}
      style={getSwitchStyle()}
      checkedChildren={<LabelDiv>Desactivado</LabelDiv>}
      unCheckedChildren={<LabelDiv>Activado</LabelDiv>}
      checked={!determineCurrentStatus()}
      disabled={isLoading}
      key={id}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,

    isLoading: state?.APP?.tableComponents?.changingSwitch ?? {},
  };
};

const ConnectedActiveSwitch = connect(mapStateToProps, {
  tableComponentsChangingSwitchRequestSetStatus,
})(ActiveSwitch);

export default ConnectedActiveSwitch;
