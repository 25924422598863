export const READ_ONE_USER_REQUEST = 'READ_ONE_USER_REQUEST';
export const READ_ONE_USER_SUCCESS = 'READ_ONE_USER_SUCCESS';
export const READ_ONE_USER_CLEAN = 'READ_ONE_USER_CLEAN';

export const PUT_ONE_USER_REQUEST = 'PUT_ONE_USER_REQUEST';
export const PUT_ONE_USER_SUCCESS = 'PUT_ONE_USER_SUCCESS';
export const PUT_ONE_USER_CLEAN = 'PUT_ONE_USER_CLEAN';

export const TOGGLE_ONE_USER_REQUEST = 'TOGGLE_ONE_USER_REQUEST';
export const TOGGLE_ONE_USER_SUCCESS = 'TOGGLE_ONE_USER_SUCCESS';
export const TOGGLE_ONE_USER_FAIL = 'TOGGLE_ONE_USER_FAIL';

export const POST_ONE_USER_REQUEST = 'POST_ONE_USER_REQUEST';
export const SET_IS_POSTING = 'SET_IS_POSTING';

export function postOneUserRequest(payload) {
  return {
    type: POST_ONE_USER_REQUEST,
    payload: payload,
  };
}

export function setIsPosting(payload) {
  return {
    type: SET_IS_POSTING,
    payload: payload,
  };
}

export function toggleOneUserRequest(payload) {
  return {
    type: TOGGLE_ONE_USER_REQUEST,
    payload: payload,
  };
}

export function toggleOneUserSuccess(payload) {
  return {
    type: TOGGLE_ONE_USER_SUCCESS,
    payload: payload,
  };
}

export function toggleOneUserFail() {
  return {
    type: TOGGLE_ONE_USER_FAIL,
  };
}

export function putOneUserRequest(payload) {
  return {
    type: PUT_ONE_USER_REQUEST,
    payload: payload,
  };
}

export function putOneUserSuccess(payload) {
  return {
    type: PUT_ONE_USER_SUCCESS,
    payload: payload,
  };
}

export function putOneUserClean() {
  return {
    type: PUT_ONE_USER_CLEAN,
  };
}

export function readOneUserRequest(payload) {
  return {
    type: READ_ONE_USER_REQUEST,
    payload: payload,
  };
}

export function readOneUserSuccess(payload) {
  return {
    type: READ_ONE_USER_SUCCESS,
    payload: payload,
  };
}

export function readOneUserClean() {
  return {
    type: READ_ONE_USER_CLEAN,
  };
}
