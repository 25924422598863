import request from 'services/request';
import { paramsSerializer } from 'RootModule/services/catalogs';
import ApiConstants from '../utils/ApiConstants';

export async function ReportConsult({ cancelToken, params }) {
  const { urlReport } = ApiConstants;
  return request(urlReport, {
    method: 'GET',
    cancelToken,
    params,
  });
}

export async function requestExistences({ cancelToken, params }) {
  const { CheckExistences } = ApiConstants;
  return request(CheckExistences, {
    method: 'GET',
    cancelToken,
    params,
    paramsSerializer,
  });
}

export async function AccountStatusConsult({ cancelToken, params }) {
  const { GetListManualStatement } = ApiConstants;
  return request(GetListManualStatement, {
    method: 'GET',
    cancelToken,
    params,
    paramsSerializer,
  });
}

export async function checkExistDataPog({ cancelToken }) {
  let uri = '/AccountStatement/Api/v1/Pog/ExistInfoPOG';
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
  });
}

export async function AccountStatusSend({ cancelToken, params = {} }) {
  const { sendAccountStatement } = ApiConstants;
  return request(sendAccountStatement, {
    method: 'GET',
    cancelToken,
    params,
  });
}
