import { request } from '../../services';
import { queryBuilder } from '../../common';

export async function getUsersData(query) {
  let queryString = query === undefined ? '' : queryBuilder(query);
  return request
    .get(`/Users/Api/v1/Users${queryString}`)
    .then((response) => {
      let roles = [];
      let users = [];
      response.data.forEach((user, key) => {
        let userProfile =
          user.area === undefined || user.area === null || user.area === ''
            ? user.role
            : `Administrador de ${user.area}`;
        users.push({
          key: key,
          profile: userProfile,
          ...user,
        });
        if (!roles.some((role) => role.text === userProfile)) {
          roles.push({ text: userProfile, value: userProfile });
        }
      });
      return { roles: roles, users: users };
    })
    .catch((err) => {
      console.error(err);
    });
}
