export const salesVolume = [
  {
    value: 'Ventas',
    key: '1',
  },
  {
    value: 'Volumen',
    key: '2',
  },
];

export const viewCommercialAgreement = [
  {
    value: 'Estructura comercial',
    key: '0',
  },
  {
    value: 'Clientes',
    key: '1',
  },
];

/* marca productos fuera de política y productos dentro de política */
export const policyTypesOps = [
  {
    value: 'Dentro de política',
    key: '1',
  },
  {
    value: 'Fuera de política',
    key: '2',
  },
];

export const valueTypeOps = [
  {
    value: 'Bruto',
    key: '0',
  },
  {
    value: 'Neto',
    key: '1',
  },
];
