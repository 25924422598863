import React from 'react';
import { List, Row } from 'antd';
import Signer from './Signer';
import './style.css';

const SignersList = ({ dataSource, rowProps, title }) => {
  const renderItem = (item) => {
    return <Signer name={item.name} jobTitle={item.position} {...rowProps} />;
  };

  return (
    <>
      <Row>
        <List dataSource={dataSource} renderItem={renderItem} />{' '}
      </Row>
    </>
  );
};

export default SignersList;
