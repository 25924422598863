import React from 'react';
import { Col } from 'antd';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { FaPlusCircle, FaList, FaFileUpload } from 'react-icons/fa';
import { MdFolderShared } from 'react-icons/md';
import { AiFillClockCircle } from 'react-icons/ai';
import Styles from './cards.module.css';

function BCard({ title, add, upload, consult, folder, clock, empty, span = 8, url }) {
  const typeCard = () => {
    // Card Vacio
    if (empty || url) {
      return (
        <div className={Styles[`catalog-card-footer-action`]}>
          <span>
            <FaList style={{ color: 'transparent' }} />
          </span>
        </div>
      );
    } else if (add || upload || consult) {
      //  CARD COMMON
      return (
        <>
          <div className={Styles[`catalog-card-footer-action`]}>
            {add && (
              <Link to={add}>
                <span>
                  <FaPlusCircle />
                </span>
              </Link>
            )}
          </div>
          <div className={Styles[`catalog-card-footer-action`]}>
            {upload && (
              <Link to={upload}>
                <span>
                  <FaFileUpload />
                </span>
              </Link>
            )}
          </div>
          <div className={Styles[`catalog-card-footer-action`]}>
            {consult && (
              <Link to={consult}>
                <span>
                  <FaList />
                </span>
              </Link>
            )}
          </div>
        </>
      );
    } else if (folder || clock) {
      //  CARD FOLDER, CLOCK
      return (
        <>
          <div className={Styles[`catalog-card-footer-action`]}>
            {add && (
              <Link to={add}>
                <span>
                  <FaPlusCircle />
                </span>
              </Link>
            )}
          </div>
          <div className={Styles[`catalog-card-footer-action`]}>
            {folder && (
              <Link to={folder}>
                <span>
                  <MdFolderShared />
                </span>
              </Link>
            )}
          </div>
          <div className={Styles[`catalog-card-footer-action`]}>
            {clock && (
              <Link to={clock}>
                <span>
                  <AiFillClockCircle />
                </span>
              </Link>
            )}
          </div>
        </>
      );
    }
  };
  return (
    <Col span={span}>
      <div className={Styles[`catalog-Card`]}>
        <div className={Styles[`catalog-card-body`]}>
          <h2 className={Styles[`catalog-card-title`]}>{title}</h2>
        </div>
        <div className={Styles[`catalog-card-footer`]}>
          <IconContext.Provider value={{ color: 'white' }}>{typeCard()}</IconContext.Provider>
        </div>
      </div>
    </Col>
  );
}
export default BCard;
