import {
  CLEAN_FILTERS,
  REGIONS_REQUEST,
  REGIONS_SUCCESS,
  REGIONS_FAIL,
  SUBREGIONS_REQUEST,
  SUBREGIONS_SUCCESS,
  SUBREGIONS_FAIL,
  SEGMENTS_REQUEST,
  SEGMENTS_SUCCESS,
  SEGMENTS_FAIL,
  BUSINESSLINE_REQUEST,
  BUSINESSLINE_SUCCESS,
  BUSINESSLINE_FAIL,
  TERRITORY_REQUEST,
  TERRITORY_SUCCESS,
  TERRITORY_FAIL,
  ZONES_REQUEST,
  ZONES_SUCCESS,
  ZONES_FAIL,
  CLIENTS_REQUEST,
  CLIENTS_SUCCESS,
  CLIENTS_FAIL,
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCTS_FAIL,
  FAMILY_REQUEST,
  FAMILY_SUCCESS,
  FAMILY_FAIL,
  BUSINESSRULES_REQUEST,
  BUSINESSRULES_SUCCESS,
  BUSINESSRULES_FAIL,
  YEARS_REQUEST,
  YEARS_SUCCESS,
  YEARS_FAIL,
} from '../actions/filters';

const filters = (state = {}, action) => {
  switch (action.type) {
    case CLEAN_FILTERS:
      return {};

    case REGIONS_REQUEST:
      return {
        ...state,
        regions: {
          isLoading: true,
          data: [],
        },
      };

    case REGIONS_SUCCESS:
      return {
        ...state,
        regions: {
          isLoading: false,
          data: action.payload,
        },
      };

    case REGIONS_FAIL:
      return {
        ...state,
        regions: {
          isLoading: false,
          data: [],
        },
      };

    case BUSINESSLINE_REQUEST:
      return {
        ...state,
        businessLines: {
          isLoading: true,
          data: [],
        },
      };

    case BUSINESSLINE_SUCCESS:
      return {
        ...state,
        businessLines: {
          isLoading: false,
          data: action.payload,
        },
      };

    case BUSINESSLINE_FAIL:
      return {
        ...state,
        businessLines: {
          isLoading: false,
          data: [],
        },
      };

    case SEGMENTS_REQUEST:
      return {
        ...state,
        segmentation: {
          isLoading: true,
          data: [],
        },
      };

    case SEGMENTS_SUCCESS:
      return {
        ...state,
        segmentation: {
          isLoading: false,
          data: action.payload,
        },
      };

    case SEGMENTS_FAIL:
      return {
        ...state,
        segmentation: {
          isLoading: false,
          data: [],
        },
      };

    case SUBREGIONS_REQUEST:
      return {
        ...state,
        subregions: {
          isLoading: true,
          data: [],
        },
      };

    case SUBREGIONS_SUCCESS:
      return {
        ...state,
        subregions: {
          isLoading: false,
          data: action.payload,
        },
      };

    case SUBREGIONS_FAIL:
      return {
        ...state,
        subregions: {
          isLoading: false,
          data: [],
        },
      };

    case TERRITORY_REQUEST: {
      return {
        ...state,
        territory: {
          isLoading: true,
          data: [],
        },
      };
    }

    case TERRITORY_SUCCESS: {
      return {
        ...state,
        territory: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case TERRITORY_FAIL: {
      return {
        ...state,
        territory: {
          isLoading: false,
          data: [],
        },
      };
    }

    case ZONES_REQUEST: {
      return {
        ...state,
        zones: {
          isLoading: true,
          data: [],
        },
      };
    }

    case ZONES_SUCCESS: {
      return {
        ...state,
        zones: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case ZONES_FAIL: {
      return {
        ...state,
        zones: {
          isLoading: false,
          data: [],
        },
      };
    }

    case CLIENTS_REQUEST: {
      return {
        ...state,
        clients: {
          isLoading: true,
          data: [],
        },
      };
    }

    case CLIENTS_SUCCESS: {
      return {
        ...state,
        clients: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case CLIENTS_FAIL: {
      return {
        ...state,
        clients: {
          isLoading: false,
          data: [],
        },
      };
    }

    case PRODUCTS_REQUEST: {
      return {
        ...state,
        products: {
          isLoading: true,
          data: [],
        },
      };
    }

    case PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case PRODUCTS_FAIL: {
      return {
        ...state,
        products: {
          isLoading: false,
          data: [],
        },
      };
    }

    case FAMILY_REQUEST: {
      return {
        ...state,
        families: {
          isLoading: true,
          data: [],
        },
      };
    }

    case FAMILY_SUCCESS: {
      return {
        ...state,
        families: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case FAMILY_FAIL: {
      return {
        ...state,
        families: {
          isLoading: false,
          data: [],
        },
      };
    }

    case BUSINESSRULES_REQUEST: {
      return {
        ...state,
        businessRules: {
          isLoading: true,
          data: [],
        },
      };
    }

    case BUSINESSRULES_SUCCESS: {
      return {
        ...state,
        businessRules: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case BUSINESSRULES_FAIL: {
      return {
        ...state,
        businessRules: {
          isLoading: false,
          data: [],
        },
      };
    }

    case YEARS_REQUEST: {
      return {
        ...state,
        years: {
          isLoading: true,
          data: [],
        },
      };
    }

    case YEARS_SUCCESS: {
      return {
        ...state,
        years: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case YEARS_FAIL: {
      return {
        ...state,
        years: {
          isLoading: false,
          data: [],
        },
      };
    }

    default:
      return state;
  }
};

export default filters;
