import {
  PUT_ONE_UPDATE_PRICES_REQUEST,
  PUT_ONE_UPDATE_PRICES_SUCCESS,
  PUT_ONE_UPDATE_PRICES_CLEAN,
  GET_UPDATE_PRICES_REQUEST,
  GET_UPDATE_PRICES_SUCCESS,
  GET_UPDATE_PRICES_CLEAN,
  PUT_ONE_STATUS_REQUEST,
  PUT_ONE_STATUS_SUCCESS,
  PUT_ONE_STATUS_CLEAN,
} from '../actions';

function oneCase(state = {}, action) {
  const { type } = action;

  switch (type) {
    //GET_UPDATE_PRICES_REQUEST
    case GET_UPDATE_PRICES_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case GET_UPDATE_PRICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case GET_UPDATE_PRICES_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //PUT_ONE_UPDATE_PRICES_REQUEST
    case PUT_ONE_UPDATE_PRICES_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case PUT_ONE_UPDATE_PRICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case PUT_ONE_UPDATE_PRICES_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    //STATUS
    case PUT_ONE_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PUT_ONE_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case PUT_ONE_STATUS_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}

export default oneCase;
