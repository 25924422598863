/* Custom */
import { Input, TextInput, MailInput } from '../input';
import { Divider } from '../misc';
import { AntdDateRange } from '../dates';
import { AntdSelect } from '../select';

const ComponentsMap = {
  Divider: Divider,
  Input: Input,
  TextInput: TextInput,
  MailInput: MailInput,
  AntdSelect: AntdSelect,
  AntdDateRange: AntdDateRange,
};
export default ComponentsMap;
