import request from 'services/request';

//GET Table Data
//Changed URL and parameters
export async function ManageImagesGetTableData({ cancelToken }) {
  let uri = '/AccountStatement/Api/v1/Files/ManagementImage';
  return request(uri, {
    method: 'GET',
    cancelToken,
  });
}

//Obtiene la lista de imagenes por estructura comercial
export async function GETListImages({ cancelToken, params }) {
  let uri = '/AccountStatement/Api/v1/Files/ListImages';
  return request(uri, {
    method: 'GET',
    cancelToken,
    params,
  });
}

//Obtiene la imagen HD
export async function GETImageHD({ cancelToken, params }) {
  let uri = '/AccountStatement/Api/v1/Files/PreviewImage';
  return request(uri, {
    method: 'GET',
    cancelToken,
    params,
  });
}
