import React from 'react';
import { Row, Col, Form, Select, InputNumber } from 'antd';
import { BInput } from 'common/components';
import { getPopupContainer } from 'SalesChallengeRefactor/helpers/helperForms';

const SalesInfo = ({ getClientData, isTradeAgreement, statusSwitch, form }) => {
  const { Option } = Select;

  const parserFunc = (value) => {
    let clean = value.replace(/\$\s?|(,*)/g, '');
    if (clean && clean.length > 10) {
      let result = '';
      let decimal = clean.indexOf('.');
      if (decimal > 0 && clean.length <= 20) {
        result = clean.substring(0, 20);
      } else {
        result = clean.substring(0, 10);
      }

      return result;
    } else {
      return clean;
    }
  };

  const keyBlockNumber = (e) => {
    if (e.shiftKey || (e.shiftKey && e.which === 51) || e.key === 'Dead') {
      e.preventDefault();
    }
    if (
      e.keyCode === 69 ||
      e.keyCode === 73 ||
      e.keyCode === 186 ||
      e.keyCode === 187 ||
      e.keyCode === 189 ||
      e.keyCode === 40 ||
      e.keyCode === 107 ||
      e.keyCode === 109 ||
      e.keyCode === 191 ||
      e.keyCode === 192 ||
      e.keyCode === 219 ||
      e.keyCode === 220 ||
      e.keyCode === 221 ||
      e.keyCode === 222 ||
      e.keyCode === 38 ||
      e.keyCode === 40 ||
      e.key === '{' ||
      e.key === '}' ||
      e.key === '+' ||
      e.key === '*' ||
      e.key === '[' ||
      e.key === ']' ||
      e.key === '.' ||
      e.key === '´' ||
      e.key === '/' ||
      e.key === '<' ||
      e.key === '+' ||
      e.key === '´´' ||
      e.key === 'ArrowLeft' ||
      e.key === 'BracketLeft' ||
      e.key === 'BracketRight' ||
      e.key === 'Quote' ||
      e.key === 'Shift' ||
      e.key === 'Dead' ||
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      e.shiftKey ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowUp'
    ) {
      e.preventDefault();
    }
  };
  const keyBlock = (e) => {
    if (e.shiftKey || (e.shiftKey && e.which === 51) || e.key === 'Dead') {
      e.preventDefault();
    }
    if (
      e.keyCode === 69 ||
      e.keyCode === 73 ||
      e.keyCode === 186 ||
      e.keyCode === 187 ||
      e.keyCode === 189 ||
      e.keyCode === 40 ||
      e.keyCode === 107 ||
      e.keyCode === 109 ||
      e.keyCode === 191 ||
      e.keyCode === 192 ||
      e.keyCode === 219 ||
      e.keyCode === 220 ||
      e.keyCode === 221 ||
      e.keyCode === 222 ||
      e.keyCode === 38 ||
      e.keyCode === 40 ||
      e.key === '{' ||
      e.key === '}' ||
      e.key === '+' ||
      e.key === '*' ||
      e.key === '[' ||
      e.key === ']' ||
      e.key === '´' ||
      e.key === '/' ||
      e.key === '<' ||
      e.key === '+' ||
      e.key === '´´' ||
      e.key === 'ArrowLeft' ||
      e.key === 'BracketLeft' ||
      e.key === 'BracketRight' ||
      e.key === 'Quote' ||
      e.key === 'Shift' ||
      e.key === 'Dead' ||
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      e.shiftKey ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowUp'
    ) {
      e.preventDefault();
    }
  };

  const formatFuncVolume = (valueNumber) => {
    let value = valueNumber.toString();

    let format = '';
    let decimal = value.indexOf('.');

    if (decimal > 0) {
      let a = new RegExp(/(^([0-9]*\.[0-9][0-9][0-9])$)/gi);

      let onlyTwoDecimals = value.match(a, '$1');

      if (!onlyTwoDecimals) {
        let splitDecimals = value.split('.');
        if (splitDecimals[1]) {
          let sub = splitDecimals[1].substring(0, 3);
          value = `${splitDecimals[0]}.${sub}`;
        }
      }
      if (value.length <= 20) {
        format = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        let val = value.substring(0, 20);
        format = `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    } else {
      if (value.length <= 10) {
        format = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        let val = value.substring(0, 10);
        format = `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
    return format;
  };

  const formatoNumber = (value, attr) => {
    if (value > 2147483647) {
      form.setFieldsValue({
        [attr]: undefined,
      });
    } else return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  return (
    <div>
      <Row>
        <Col span={6}>
          <Form.Item name="branches" label="Número de sucursales:" style={{ height: 20 }}>
            <InputNumber
              disabled={statusSwitch}
              style={{ border: '1px solid #d9d9d9' }}
              bordered={false}
              onKeyDown={keyBlockNumber}
              formatter={(value) => formatoNumber(value, 'branches')}
              placeholder="0"
              //max={2147483647}
              maxLength={12}
              min={0}
              stringMode
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="clients" label="Número de clientes:" style={{ height: 20 }}>
            <InputNumber
              disabled={statusSwitch}
              style={{ border: '1px solid #d9d9d9' }}
              bordered={false}
              onKeyDown={keyBlockNumber}
              formatter={(value) => formatoNumber(value, 'clients')}
              //formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              placeholder="0"
              //max={2147483647}
              maxLength={12}
              min={0}
              stringMode
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name="salesForce"
            label="Número de representantes de venta:"
            style={{ height: 20 }}
          >
            <InputNumber
              disabled={statusSwitch}
              style={{ border: '1px solid #d9d9d9' }}
              bordered={false}
              onKeyDown={keyBlockNumber}
              formatter={(value) => formatoNumber(value, 'salesForce')}
              placeholder="0"
              //max={2147483647}
              maxLength={12}
              min={0}
              stringMode
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name="technicalRepresentatives"
            label="Número de representantes técnicos:"
            style={{ height: 20 }}
          >
            <InputNumber
              disabled={statusSwitch}
              style={{ border: '1px solid #d9d9d9' }}
              bordered={false}
              onKeyDown={keyBlockNumber}
              formatter={(value) => formatoNumber(value, 'technicalRepresentatives')}
              //formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              placeholder="0"
              //max={2147483647}
              maxLength={12}
              min={0}
              stringMode
            />
          </Form.Item>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col span={6}>
          <Form.Item
            name="totalInvoicingPreviousYear"
            label="* Facturación total de agroquímicos del año anterior:"
            style={{ height: 20 }}
          >
            <InputNumber
              disabled={statusSwitch}
              formatter={formatFuncVolume}
              parser={parserFunc}
              onKeyDown={keyBlock}
              style={{ border: '1px solid #d9d9d9' }}
              bordered={false}
              placeholder="0"
              max={9999999999.999}
              maxLength={17}
              min={0}
              step="0.001"
              stringMode
            />
          </Form.Item>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col span={6}>
          <Form.Item
            name="billingCurrency"
            label={isTradeAgreement ? 'Moneda de facturación:' : '* Moneda de facturación:'}
            style={{ height: 20 }}
          >
            <Select
              allowClear
              getPopupContainer={getPopupContainer}
              disabled={statusSwitch}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.currencies &&
                getClientData.currencies.map((d, index) => {
                  return (
                    <Option key={index} value={d.id}>
                      {d.value}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <BInput
            disabled={statusSwitch}
            className="form-add-user-input"
            name="icotermType"
            max={50}
            placeholder="Escribe Tipo"
            label="Tipo de incoterm:"
          />
        </Col>

        <Col span={6}>
          <Form.Item
            name="pogId"
            label={isTradeAgreement ? 'Tipo de selección de POG:' : '* Tipo de selección de POG:'}
            style={{ height: 20 }}
          >
            <Select
              allowClear
              getPopupContainer={getPopupContainer}
              disabled={statusSwitch}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.pogs &&
                getClientData.pogs.map((d) => {
                  return (
                    <Option key={d.key} value={d.key}>
                      {d.value}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name="tradeAgreementCurrency"
            label={
              isTradeAgreement ? 'Moneda de acuerdo comercial:' : '* Moneda de acuerdo comercial:'
            }
            style={{ height: 20 }}
          >
            <Select
              allowClear
              getPopupContainer={getPopupContainer}
              disabled={statusSwitch}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.currencies &&
                getClientData.currencies.map((d, index) => {
                  return (
                    <Option key={index} value={d.id}>
                      {d.value}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default SalesInfo;
