/**USE ENUMS AS MUCH AS POSSIBLE  */

export const salesChallengeStatus = Object.freeze({
  ON_PROCESS: 1,
  ON_VALIDATION: 2,
  ON_SECOND_VALIDATION: 3,
  APPROVED: 4,
  REJECTED: 5,
  PARTIALLY_REJECTED: 6,
  NULL: null,
});

export const semaphoreTradeAgreement = Object.freeze({
  RED: '#e6001d',
  YELLOW: '#ff9600',
  GREEN: '#00c14f',
  BLUE: '#0078b3',
});

export const actionChallengeEnum = Object.freeze({
  UPDATE: { label: 'Actualizar', key: 'update', path: `/sales-challenge/update` },
  REGISTER: { label: 'Registrar', key: 'register', path: `/sales-challenge/register` },
  ADJUSTED: { label: 'Ajustar', key: 'adjusted', path: `/sales-challenge/adjusted` },
});

export const months = Object.freeze({
  ene: 'ene',
  feb: 'feb',
  mar: 'mar',
  apr: 'apr',
  may: 'may',
  jun: 'jun',
  jul: 'jul',
  ago: 'ago',
  sep: 'sep',
  oct: 'oct',
  nov: 'nov',
  dic: 'dic',
});

export const monthsEnum = Object.freeze({
  ene: 1,
  feb: 2,
  mar: 3,
  apr: 4,
  may: 5,
  jun: 6,
  jul: 7,
  ago: 8,
  sep: 9,
  oct: 10,
  nov: 11,
  dic: 12,
});

export const whoSaveChallenge = Object.freeze({
  CELL: 'CELL',
  ON_CHANGE_PRICES: 'ON_CHANGE_PRICES',
  SAVE_BUTTON: 'SAVE_BUTTON',
});
