import React from 'react';
import { Row, Col } from 'antd';
import { BInput } from 'common/components';

const Client = () => {
  return (
    <div>
      <Row>
        <Col span={8}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="soldTo"
            label="Sold to"
          />
        </Col>
        <Col span={8}>
          <BInput disabled isRequired className="form-add-user-input" name="name" label="Nombre" />
        </Col>

        <Col span={8}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="timeLimit"
            label="Plazo en el sistema"
          />
        </Col>

        <Col span={8}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="creditLimit"
            label="Límite de crédito"
          />
        </Col>

        <Col span={8}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="presence"
            label="Presencia:"
          />
        </Col>

        <Col span={8}>
          <BInput disabled className="form-add-user-input" name="type" label="Tipo:" />
        </Col>

        <Col span={8}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="line"
            label="Línea de negocio:"
          />
        </Col>

        <Col span={8}>
          <BInput
            disabled
            className="form-add-user-input"
            name="segmentation"
            label="Segmentación:"
          />
        </Col>

        <Col span={8}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="tradeAgreementRequired"
            label="¿Requiere acuerdo comercial?"
          />
        </Col>

        <Col span={8}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="address"
            label="Dirección:"
          />
        </Col>

        <Col span={8}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="company"
            label="Compañía:"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Client;
