const triggerParent = (trigger) => trigger.parentNode;

export const getPopupContainer = triggerParent;

export const getCalendarContainer = triggerParent;

export const handleOptionsProps = (array, optionsProps, ruleToClassName) => {
  if (Object.keys(optionsProps)?.length === 0 && array) {
    let aux = {};
    if (array && array?.data?.length > 0) {
      array.data.map((el) => {
        aux = { ...aux, [el.key]: ruleToClassName(el) };
        return null;
      });

      return aux;
    } else {
      return optionsProps;
    }
  }

  return optionsProps;
};
