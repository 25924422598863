export { useCatalogScope } from './hooks';
export * from './formUtils';
export const catalogPermissions = (userModules, catalogName) => {
  return userModules.includes(catalogName);
};
export const getCatalogsObjects = (catalogs, modules, type, uploadEnabled) => {
  let modulesCards = [];
  catalogs &&
    catalogs.forEach((catalog) => {
      let add = true;
      if (catalog.uri === 'sales' || catalog.uri === 'pog' || catalog.uri === 'selling-goals') {
        add = uploadEnabled;
      }
      modulesCards.push({
        name: catalog.text,
        url: catalogPermissions(modules, `${catalog.uri}`) ? `/${type}/${catalog.uri}` : false,

        add: add && getUrl(modules, 'register', catalog.uri, type),
        upload: add && getUrl(modules, 'upload', catalog.uri, type),
        consult: getUrl(modules, 'consult', catalog.uri, type),
        empty: getUrl(modules, 'empty', catalog.uri, type),
        clock: getUrl(modules, 'clock', catalog.uri, type),
        folder: getUrl(modules, 'folder', catalog.uri, type),
      });
    });
  return modulesCards;
};
const getUrl = (modules, prefix, uri, type) => {
  let uri2 = `/${prefix}`;
  if (prefix === 'empty') {
    uri2 = '';
  }
  let url = catalogPermissions(modules, `${prefix}_${uri}`);
  if (url) {
    return `/${type}/${uri}${uri2}`;
  }
  return false;
};

export const filterValidRows = (rows, errors) => {
  let errorsKeys = errors ? errors : [];
  let validRows =
    rows &&
    rows.length > 0 &&
    rows.filter(function (e) {
      return this.indexOf(e.rowNumber) < 0;
    }, errorsKeys);
  return validRows;
};
export const buildDuplicatedObjects = (duplicatedIds, data, catalogName) => {
  let duplicatedObjs = [];
  duplicatedIds &&
    duplicatedIds.length > 0 &&
    duplicatedIds.forEach((id) => {
      let object = data.find((obj) => obj.rowNumber === id);
      duplicatedObjs.push(object);
    });
  return duplicatedObjs;
};
export const getFilters = (type, data) => {
  let filters = data.reduce((acc, val) => {
    let values = acc.some((filter) => filter.text === val[type]);
    val[type] !== null &&
      val[type] !== '' &&
      values === false &&
      acc.push({ text: val[type], value: val[type] === '' ? 'placeholder' : val[type] });
    return acc;
  }, []);

  return filters;
};
