import React from 'react';
import { DatePicker, ConfigProvider } from 'antd';

import 'moment/locale/es-mx';

import esES from 'antd/lib/locale/es_ES';
const { RangePicker } = DatePicker;

export const DATE_FORMAT = 'DD/MM/YYYY';

function RangePickerSpanish(props) {
  const { name, value, ...other } = props;

  return (
    <ConfigProvider locale={esES}>
      <RangePicker name={name} format={DATE_FORMAT} value={value} {...other} />
    </ConfigProvider>
  );
}

export default RangePickerSpanish;
