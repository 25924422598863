export const SHOP_SECTION_REQUEST = 'SHOP_SECTION_REQUEST';
export const GET_SHOP_SECTION = 'GET_SHOP_SECTION';
export const SHOP_SECTION_FAIL = 'SHOP_SECTION_FAIL';
export const CLEAN_SHOP_SECTION = 'CLEAN_SHOP_SECTION';

export const SHOP_SECTION_REQUEST_SUB_REGIONS = 'SHOP_SECTION_REQUEST_SUB_REGIONS';
export const GET_SHOP_SUB_REGIONS = 'GET_SHOP_SECTION_SUB_REGIONS';
export const SHOP_FAIL_SUB_REGIONS = 'SHOP_FAIL_SUB_REGIONS';
export const CLEAN_SHOP_SUB_REGIONS = 'CLEAN_SHOP_SUB_REGIONS';

export function shopSectionRequest(payload) {
  return {
    type: SHOP_SECTION_REQUEST,
    payload: payload,
  };
}

export function shopSubRegionsRequest(payload) {
  return {
    type: SHOP_SECTION_REQUEST_SUB_REGIONS,
    payload: payload,
  };
}

export function getShopSection(payload) {
  return {
    type: GET_SHOP_SECTION,
    payload: payload,
  };
}

export function getShopSubRegions(payload) {
  return {
    type: GET_SHOP_SUB_REGIONS,
    payload: payload,
  };
}

export function shopSectionFail(payload) {
  return {
    type: SHOP_SECTION_FAIL,
    payload: payload,
  };
}

export function shopSectionSubRegionsFail(payload) {
  return {
    type: SHOP_FAIL_SUB_REGIONS,
    payload: payload,
  };
}

export function cleanShopSection(payload) {
  return {
    type: CLEAN_SHOP_SECTION,
    payload: payload,
  };
}

export function cleanShopSubRegions(payload) {
  return {
    type: CLEAN_SHOP_SUB_REGIONS,
    payload: payload,
  };
}
