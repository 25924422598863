import React from 'react';
import { Table } from 'antd';

import {
  productsOutOfAvailabilityTable,
  productsOutOfAvailabilityTableRegister,
  incentiveProgramTable,
  incentiveProgramTableRegister,
  productsOutOfPolicyTableRegister,
  productsOutOfPolicyTable,
  productsOutOfPolicyClientTableRegister,
  productsOutOfPolicyClientTable,
} from 'TradeAgreements/models/consultRulesTablesModels';
import { ruleTypeEnum } from 'common/models/enums';
import PropTypes from 'prop-types';
import './styles.css';
import { AllCorrectSign } from 'common/MassiveUploads/components';
export const getTableModelsByRuleType = (
  ruleType,
  enumObj,
  isRegister = false,
  selectSubTipo,
  selectedSubType
) => {
  let model;
  if (ruleType === enumObj.PRODUCTOS_FUERA_DE_DISPONIBILIDAD && !isRegister) {
    model = productsOutOfAvailabilityTable;
  } else if (ruleType === enumObj.PRODUCTOS_FUERA_DE_DISPONIBILIDAD && isRegister) {
    model = productsOutOfAvailabilityTableRegister;
  } else if (
    ruleType === enumObj.PRODUCTOS_FUERA_DE_POLITICA &&
    selectSubTipo === 'cliente' &&
    !isRegister
  ) {
    /* Client */
    model = productsOutOfPolicyClientTable;
  } else if (ruleType === 'cliente' && isRegister === true) {
    model = productsOutOfPolicyClientTableRegister;
  } else if (ruleType === enumObj.PRODUCTOS_FUERA_DE_POLITICA && !isRegister) {
    model = productsOutOfPolicyTable;
  } else if (ruleType === enumObj.PRODUCTOS_FUERA_DE_POLITICA && isRegister) {
    model = productsOutOfPolicyTableRegister;
  } else if (ruleType === enumObj.PROGRAMA_DE_INCENTIVOS && !isRegister) {
    model = incentiveProgramTable;
  } else if (ruleType === enumObj.PROGRAMA_DE_INCENTIVOS && isRegister) {
    model = incentiveProgramTableRegister;
  } else {
    model = [];
  }
  return model;
};

const RuleTypeTable = ({
  ruleType,
  dataSource,
  loading,
  isRegister,
  selectSubTipo,
  selectedSubType,
  uploadMessage,
}) => {
  const columns = getTableModelsByRuleType(
    ruleType,
    ruleTypeEnum,
    isRegister,
    selectSubTipo,
    selectedSubType
  );

  return (
    <>
      {(!dataSource || dataSource?.length === 0) && (
        <div style={{ minHeight: '42vh' }}>
          {uploadMessage && <AllCorrectSign message={uploadMessage} />}
        </div>
      )}

      {dataSource.length > 0 ? (
        <Table
          dataSource={dataSource}
          pagination={true}
          className={'businessRules'}
          scroll={{ x: 'max-content' }}
          loading={loading}
          columns={columns}
        />
      ) : null}
    </>
  );
};

RuleTypeTable.propTypes = {
  ruleType: PropTypes.oneOf([1, 2, 3]).isRequired,
};

export default RuleTypeTable;
