import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import React from 'react';
import './massiveUploadTableColumns.css';

const messageFaseOne = (
  <>
    <p>
      Registros para los cuales se omitió ingresar campos obligatorios, se ingresó un tipo de dato
      que no corresponde al solicitado en el campo o se excedió la longitud máxima.
    </p>
  </>
);
const messageFaseTwo = (
  <>
    <p>
      Registros de inventarios iniciales para los cuales se ingresó información de elementos con
      estatus &quot;Inactivo&quot;, que no se encuentran registrados en el sistema, se ingresó
      información de estructura comercial que no está relacionada entre sí o con el resto de los
      elementos.
    </p>
  </>
);
const messageFaseThree = (
  <>
    <p>Registros que se encuentran repetidos dentro del archivo de carga masiva.</p>
  </>
);
const errorMessages = [
  {
    fase: 'Fase 1',
    message: messageFaseOne,
  },
  {
    fase: 'Fase 2',
    message: messageFaseTwo,
  },
  {
    fase: 'Fase 3',
    message: messageFaseThree,
  },
];
const tableColumns = [
  {
    key: 'phase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'phase',
    className: 'title-masive',
  },
  {
    key: 'soldTo',
    title: 'Sold To',
    dataIndex: 'soldTo',
  },

  {
    key: 'clientName',
    title: 'Cliente',
    dataIndex: 'clientName',
  },
  {
    key: 'line',
    title: 'Línea de negocio',
    dataIndex: 'line',
  },
  {
    key: 'productName',
    title: 'Nombre de producto',
    dataIndex: 'productName',
  },
  {
    key: 'presentation',
    title: 'Presentación',
    dataIndex: 'presentation',
  },
  {
    key: 'volume',
    title: 'Volumen',
    dataIndex: 'volume',
  },
];
export default tableColumns;
