export const SAVE_TABLE = 'SAVE_TABLE/TRADE-AGREEMENTS';
export const CLEAN_TABLE = 'CLEAN_TABLE/TRADE-AGREEMENTS';
export const CHANGE_CELL = 'CHANGE_CELL/TRADE-AGREEMENTS';

export const VALIDATE_TABLE_COMPLETION_REQUEST =
  'VALIDATE_TABLE_COMPLETION_REQUEST/SALES-CHALLENGE';

export function cleanTable(payload) {
  return {
    type: CLEAN_TABLE,
    payload,
  };
}

export function saveTable(payload) {
  return {
    type: SAVE_TABLE,
    payload,
  };
}

export function changeCell(payload) {
  return {
    type: CHANGE_CELL,
    payload,
  };
}

export function validateTableRequest(payload) {
  return {
    type: VALIDATE_TABLE_COMPLETION_REQUEST,
    payload,
  };
}
