import React from 'react';
import { Loader } from 'global';
import { AllCorrectSign } from 'common/MassiveUploads/components/index.js';
import { ModalSelector } from './ModalSelector/ModalSelector';
import ColumnPOGPeriod from './ColumnPOGPeriod';
import ColumnShopping from './ColumnShopping';
import ColumnShoppingPeriod from './ColumnShoppingPeriod';
import CardText from './CardText';
import './massiveUploadTableColumns.css';

const MassiveUploadTable = ({
  loading,
  data,
  allCorrectMessage,
  setSelectUploadType,
  selectUploadType,
}) => {
  const handleSelectUploadType = (key) => {
    let uploadType = '';
    if (key === 1) {
      uploadType = 'Annual';
    }
    if (key === 2) {
      uploadType = 'Purchase';
    }
    if (key === 3) {
      uploadType = 'POG';
    }

    setSelectUploadType(uploadType);
  };
  const AddColumn = () => {
    if (selectUploadType === 'Purchase') {
      return <ColumnShoppingPeriod data={data} />;
    }
    if (selectUploadType === 'Annual') {
      return <ColumnShopping data={data} />;
    }
    if (selectUploadType === 'POG') {
      return <ColumnPOGPeriod data={data} />;
    }
  };
  return (
    <>
      <ModalSelector
        selectUploadType={selectUploadType}
        setUploadType={(key) => handleSelectUploadType(key)}
      />
      <div className="module-card-transparent" data-testid="upload">
        {(!data || data.length === 0) && (
          <div style={{ minHeight: '42vh' }}>
            {allCorrectMessage && <AllCorrectSign message={allCorrectMessage} />}
          </div>
        )}
        {loading && <Loader />}
        {data && data.length > 0 && AddColumn()}
        {data?.length > 0 || allCorrectMessage ? <CardText /> : null}
      </div>
    </>
  );
};

export default MassiveUploadTable;
