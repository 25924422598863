import request from 'services/request';

export async function ShoppingByFamilyMassiveUpload({ cancelToken, data }) {
  let uri = `AccountStatement/Api/v1/Purchases/ByFamily/MassLoadExcel`;
  return request(uri, {
    method: 'POST',
    cancelToken,
    data,
  });
}

export async function ExistsStatements({ cancelToken }) {
  const uri = `AccountStatement/Api/v1/Statements/ExistsStatements`;
  return request(uri, {
    method: 'GET',
    cancelToken,
  });
}
