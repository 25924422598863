import {
  CLIENT_COMPLETE,
  TERRITORY_COMPLETE,
  SET_AGREEMENT_TYPE,
  CLEAN_INFO_FOR_AGREEMENT,
  CLEAN_CLIENT,
} from '../actions/infoForAgreement';
const infoForAgreement = (state = {}, action) => {
  switch (action.type) {
    case CLIENT_COMPLETE:
      return {
        ...state,
        clientInfo: action.payload,
      };
    case CLEAN_CLIENT:
      return {
        ...state,
        clientInfo: {},
      };
    case TERRITORY_COMPLETE:
      return {
        ...state,
        territoryInfo: action.payload,
      };
    case SET_AGREEMENT_TYPE:
      return {
        ...state,
        agreementType: action.payload,
      };
    case CLEAN_INFO_FOR_AGREEMENT:
      return {};
    default:
      return state;
  }
};

export default infoForAgreement;
