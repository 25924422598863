import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  TRADE_AGREEMENTS_MASSIVE_UPLOAD_REQUEST_VERIFY,
  TRADE_AGREEMENTS_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  tradeAgreementsMassiveUploadSetAllowUpload,
  tradeAgreementsMassiveUploadClearAllowUpload,
  tradeAgreementsMassiveUploadSetVerify,
  tradeAgreementsMassiveUploadClearVerify,
  tradeAgreementsMassiveUploadClearUpload,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';

import { tradeAgreementsMassiveUpload } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  triggerModalForDuplicates,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workTradeAgreementsMassiveUploadSetAllowUpload(action) {
  try {
    yield tradeAgreementsMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield tradeAgreementsMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield tradeAgreementsMassiveUploadClearAllowUpload();
    }
  }
}

function* workTradeAgreementsMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de Tipos de Cambio debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(tradeAgreementsMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
        verify: false,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(tradeAgreementsMassiveUploadSetVerify(data));
      yield call(summaryModal, message);
    } else {
      yield put(tradeAgreementsMassiveUploadClearVerify());
    }
  } catch (error) {
    yield tradeAgreementsMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield tradeAgreementsMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workTradeAgreementsMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, requestKeep, requestReplace, openModal, replace, keep } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const regularParams = {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: false,
        overwrite: false,
        verify: true,
      },
    };

    const replaceParams = {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite: true,
        verify: true,
      },
    };

    const keepParams = {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite: false,
        verify: true,
      },
    };

    let response;
    let paramsToSubmit = regularParams;

    if (keep) {
      paramsToSubmit = keepParams;
    }

    if (replace) {
      paramsToSubmit = replaceParams;
    }

    response = yield call(tradeAgreementsMassiveUpload, paramsToSubmit);

    if (responseOK(response)) {
      let { data, message } = response;
      let { duplicatedSystem } = data;
      //yield put(tradeAgreementsMassiveUploadSetVerify(response.data));
      if (duplicatedSystem.length > 0 && openModal) {
        triggerModalForDuplicates(
          duplicatedSystem.length,
          requestReplace,
          requestKeep,
          'acuerdos comerciales'
        );
      } else {
        yield call(summaryEndModal, response.data, history, '/trade-agreements', message);
      }
    } else {
      yield put(tradeAgreementsMassiveUploadClearUpload());
    }
  } catch (error) {
    yield tradeAgreementsMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield tradeAgreementsMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchTradeAgreementsMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(
      TRADE_AGREEMENTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
      workTradeAgreementsMassiveUploadSetAllowUpload
    ),
  ]);
}

export function* watchTradeAgreementsMassiveUploadRequestVerify() {
  yield all([
    takeEvery(
      TRADE_AGREEMENTS_MASSIVE_UPLOAD_REQUEST_VERIFY,
      workTradeAgreementsMassiveUploadRequestVerify
    ),
  ]);
}

export function* watchTradeAgreementsMassiveUploadRequestUpload() {
  yield all([
    takeEvery(
      TRADE_AGREEMENTS_MASSIVE_UPLOAD_REQUEST_UPLOAD,
      workTradeAgreementsMassiveUploadRequestUpload
    ),
  ]);
}
