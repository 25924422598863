import {
  CONSULT_RULES_REQUEST,
  CONSULT_RULES_SUCCESS,
  CONSULT_RULES_FAIL,
  CONSULT_RULES_CLEAN,
  MASSIVE_UPLOAD_RULES_REQUEST,
  MASSIVE_UPLOAD_RULES_SUCCESS,
  MASSIVE_UPLOAD_RULES_FAIL,
  MASSIVE_UPLOAD_RULES_CLEAN,
  SET_RULE_TYPE,
  CLEAN_RULE_TYPE,
  SET_RULE_SUB_TYPE,
  CLEAN_RULE_SUB_TYPE,
} from '../actions/rules';

const consultRules = (state, action) => {
  switch (action.type) {
    case CONSULT_RULES_REQUEST:
      return {
        ...state,
        rules: {
          isFetching: true,
        },
      };
    case CONSULT_RULES_SUCCESS:
      return {
        ...state,
        rules: {
          isFetching: false,
          data: action.payload,
        },
      };
    case CONSULT_RULES_FAIL:
    case CONSULT_RULES_CLEAN:
      return {
        ...state,
        rules: {
          isFetching: true,
        },
      };
    case MASSIVE_UPLOAD_RULES_REQUEST:
      return {
        ...state,
        massiveUpload: {
          isLoading: true,
          data: [],
          allCorrectMessage: '',
          correctRegisters: 0,
          totalErrors: 0,
        },
      };
    case MASSIVE_UPLOAD_RULES_SUCCESS:
    case MASSIVE_UPLOAD_RULES_FAIL:
      return {
        ...state,
        massiveUpload: {
          isLoading: false,
          data: action.payload ?? [],
          uploadMessage: action.uploadMessage ?? '',
          correctRegisters: action.correctRegisters ?? 0,
          totalErrors: action.totalErrors ?? 0,
        },
      };

    case MASSIVE_UPLOAD_RULES_CLEAN:
      return {
        ...state,
        massiveUpload: {
          isLoading: false,
          data: [],
          uploadMessage: '',
          correctRegisters: 0,
        },
      };
    case SET_RULE_TYPE:
      return {
        ...state,
        tradeAgreementRuleType: action.payload ?? null,
      };
    case CLEAN_RULE_TYPE:
      return {
        ...state,
        tradeAgreementRuleType: null,
      };
    case SET_RULE_SUB_TYPE:
      return {
        ...state,
        tradeAgreementRuleSubType: action.payload ?? null,
      };
    case CLEAN_RULE_SUB_TYPE:
      return {
        ...state,
        tradeAgreementRuleSubType: null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default consultRules;
