export const CLIENTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD = 'CLIENTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const CLIENTS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'CLIENTS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const CLIENTS_MASSIVE_UPLOAD_REQUEST_VERIFY = 'CLIENTS_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const CLIENTS_MASSIVE_UPLOAD_SET_VERIFY = 'CLIENTS_MASSIVE_UPLOAD_SET_VERIFY';
export const CLIENTS_MASSIVE_UPLOAD_CLEAR_VERIFY = 'CLIENTS_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const CLIENTS_MASSIVE_UPLOAD_REQUEST_UPLOAD = 'CLIENTS_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const CLIENTS_MASSIVE_UPLOAD_SET_UPLOAD = 'CLIENTS_MASSIVE_UPLOAD_SET_UPLOAD';
export const CLIENTS_MASSIVE_UPLOAD_CLEAR_UPLOAD = 'CLIENTS_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function clientsMassiveUploadSetAllowUpload(payload) {
  return {
    type: CLIENTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function clientsMassiveUploadClearAllowUpload(payload) {
  return {
    type: CLIENTS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function clientsMassiveUploadRequestVerify(payload) {
  return {
    type: CLIENTS_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function clientsMassiveUploadSetVerify(payload) {
  return {
    type: CLIENTS_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function clientsMassiveUploadClearVerify(payload) {
  return {
    type: CLIENTS_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function clientsMassiveUploadRequestUpload(payload) {
  return {
    type: CLIENTS_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function clientsMassiveUploadSetUpload(payload) {
  return {
    type: CLIENTS_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function clientsMassiveUploadClearUpload(payload) {
  return {
    type: CLIENTS_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
