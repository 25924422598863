// import { formatFunc, parserFunc, keyBlock } from '../utils';
import { Row, Form, Button, Modal } from 'antd';
import React, { useState } from 'react';
import { BInput } from 'common/components';
import { CompactPicker } from 'react-color';
import { messageError } from 'utils/messages';
import './index.css';
import { openNotification } from 'common';
import { InfoCircleTwoTone } from '@ant-design/icons';

const TypeElement = (props) => {
  const {
    stateModal,
    colorPicker,
    setcolorPicker,
    setFileRegister,
    setNameFileExcel,
    deleteTypeElement,
    mapsState,
    postDisplayExcel,
    handleCancel,
    fetchBusinessStructure,
    // getTypeElementClean,
    enabledButton,
    handleError,
    getExcel,
  } = props;
  const [modalModificar, setmodalModificar] = useState({ visible: false, name: '' });
  let { isLoading } = mapsState;

  const handleChangeColor = (color) => {
    setcolorPicker(color);
    if (stateModal.modal !== 'Registrar') {
      enabledButton(false);
    }
  };

  const saveDisabledButton = () => {
    enabledButton(true);
  };

  function readFileAsbase64(file) {
    return new Promise(function (resolve, reject) {
      let fr = new FileReader();

      fr.onload = function () {
        const indexOfComma = fr.result.indexOf(',');
        const base64String = fr.result.substring(indexOfComma + 1);
        return resolve(base64String);
      };

      fr.onerror = function () {
        reject(fr);
      };

      fr.readAsDataURL(file);
    });
  }

  //handleInputExcel

  const onFilesAdded = () => {
    enabledButton(false);

    let inputFile = document.getElementById('fileExcel');
    let files = inputFile.files;
    let filesName = [];

    // NameFile
    for (var i = 0; i < files.length; i++) {
      let fileName = files[i].name;
      const extensionPosition = fileName.indexOf('.csv');
      const fileWithNoExtension = fileName.substr(0, extensionPosition);
      filesName.push(fileWithNoExtension);
    }
    setNameFileExcel(filesName);

    //Validate File
    const isValidSizeAndExtension = verifyFilesExtensionAndSize(files);
    if (!isValidSizeAndExtension) {
      enabledButton(true);
      openNotification(
        'warning',
        'El formato del archivo debe ser .csv y tener un tamaño máximo de 1 MB'
      );
      enabledButton(true);
      return false;
    }
    if (files.length >= 6) {
      enabledButton(true);
      messageError('El número máximo de archivos de coordenadas que se puedan cargar son 5');
      enabledButton(true);
      return;
    } else {
      let readers = [];
      for (let x = 0; x < files.length; x++) {
        readers.push(readFileAsbase64(files[x]));
      }

      Promise.all(readers).then((values) => {
        setFileRegister(values);

        postDisplayExcel({
          files,
          saveDisabledButton,
          structureId: stateModal.id,
          color: colorPicker.hex,
          csvFile: values,
          namesCsvFiles: filesName,
          handleError,
        });
      });
    }
  };

  const handleClickFiles = () => {
    let inputFile = document.getElementById('fileExcel');
    inputFile.value = null;
    setmodalModificar({ visible: false, name: '' });
  };

  const verifyFilesExtensionAndSize = (files) => {
    for (const file of files) {
      const size = parseFloat((file.size / 1048576).toFixed(2));
      const extension = file.name.split('.').pop().toLowerCase();
      if (size < 1 && extension === 'csv') {
        return true;
      }
      return false;
    }
  };

  const handleDelete = () => {
    Modal.confirm({
      title: 'Eliminar mapa',
      content: (
        <>
          <br />
          ¿Esta seguro que desea eliminar el archivo?
          <br />
          <br />
          Al realizar esta acción el área seleccionada del mapa no se visualizará para esta sección
          de la estructura comercial y los archivos con la información de las coordenadas
          geográficas se eliminarán del sistema.
        </>
      ),
      onOk() {
        deleteTypeElement({
          fetchBusinessStructure,
          TypeElement: stateModal.type,
          params: stateModal.id,
          handleCancel: handleCancel,
        });
      },
    });
  };

  const handleExcel = () => {
    getExcel({ TypeElement: stateModal.type, params: stateModal.id, element: stateModal.element });
  };

  return (
    <>
      <BInput label={'Tipo de elemento'} disabled value={stateModal.typeElement} />
      <BInput label={'Elemento'} disabled value={stateModal.element} />
      {/* BOTON CARGAR */}
      {stateModal.modal === 'Registrar' ? (
        <>
          <div
            className={`file-select ${!stateModal.enabled ? 'icon-disabled' : ''}`}
            id="src-file1"
          >
            <input
              name="src-file1"
              onChange={() => onFilesAdded()}
              multiple
              type="file"
              accept=".csv"
              id="fileExcel"
              onClick={handleClickFiles}
              disabled={!stateModal.enabled}
            />
          </div>
        </>
      ) : null}
      {/* BOTON CARGAR EN Modificar*/}
      {stateModal.modal === 'Modificar' ? (
        <>
          <Button
            style={isLoading ? null : { backgroundColor: '#0078b3', color: 'white' }}
            block
            onClick={() => setmodalModificar({ visible: true, name: '' })}
            loading={isLoading}
          >
            Modificar archivo
          </Button>
        </>
      ) : null}
      {/* BOTON ELIMINAR */}
      {stateModal.modal === 'Consultar' ? (
        <Button
          style={isLoading ? null : { backgroundColor: '#0078b3', color: 'white' }}
          block
          onClick={handleExcel}
          loading={isLoading}
        >
          Descargar coordenadas
        </Button>
      ) : null}
      {/* BOTON ELIMINAR */}
      {stateModal.modal === 'Modificar' ? (
        <Button
          style={
            isLoading ? null : { backgroundColor: '#0078b3', color: 'white', marginTop: '10px' }
          }
          block
          onClick={handleDelete}
          loading={isLoading}
        >
          Eliminar archivos
        </Button>
      ) : null}
      {/* SELECCIONAR COLOR */}
      {stateModal.modal !== 'Consultar' ? (
        <>
          <Row>
            <Form.Item label="Color" style={{ marginTop: '15px' }} />
            <div
              style={{
                height: '35px',
                width: '35px',
                backgroundColor: colorPicker?.hex,
                marginTop: '15px',
              }}
            />
          </Row>
          <CompactPicker
            color={colorPicker}
            onChangeComplete={handleChangeColor}
            disabled={!stateModal.enabled}
          />
        </>
      ) : null}
      <Modal
        title={
          <div style={{ fontSize: '16px', color: '#00499a' }}>
            <InfoCircleTwoTone style={{ fontSize: '22px' }} />
            {`    Modificar mapa`}
          </div>
        }
        visible={modalModificar.visible}
        okButtonProps={{ visible: false }}
        // onOk={putItem}
        onCancel={() => setmodalModificar({ visible: false, name: '' })}
        footer={[
          <Row key="rowModal" justify="end">
            <Button
              key="btnCancelExcel"
              onClick={() => setmodalModificar({ visible: false, name: '' })}
              style={{ marginTop: '-3px' }}
            >
              Cancelar
            </Button>

            <div className="file-select2" id="src-file2" key="btnModifExcel">
              <input
                className="input-excel"
                name="src-file2"
                onChange={(e) => onFilesAdded(e)}
                multiple
                type="file"
                accept=".csv"
                id="fileExcel"
                onClick={() => handleClickFiles()}
              />
            </div>
          </Row>,
        ]}
      >
        <>
          ¿Está seguro que desea actualizar los archivos con las coordenadas geográficas del
          elemento de la estructura comercial?
          <br />
          <br />
          Al realizar esta acción los archivos previamente cargados se eliminarán del sistema.
        </>
      </Modal>
    </>
  );
};

export default TypeElement;
