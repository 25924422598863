/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// ant desing
import { Row, Col, Drawer, Button, Collapse } from 'antd';
import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';

// components
import BSelect from 'common/components/BSelect/BSelect';
import { yearOptions } from './YearOptions';
import { acuerdoDesafio } from './acuerdoDesafio';

// css
import './styles.css';

// service
import {
  getRegions,
  getBusinessLines,
  getSubregions,
  getZones,
  getTerritories,
} from 'services/index';
const FILTERS = {
  regions: 'regions',
  bussinesLine: 'bussinesLine',
  subregions: 'subregions',
  zones: 'zones',
  areas: 'areas',
  yearOptions: 'yearOptions',
  agreementChallenge: 'agreementChallenge',
  all: 'ALL',
};

function Filterbar(props) {
  const { form, mapsState, onChange, setfilters, onClear, getGet } = props;
  const [visible, setVisible] = useState(true);
  const [regions, setRegions] = useState([]);
  const [businessLines, setBusinessLines] = useState([]);
  const [subregions, setSubregions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [zones, setZones] = useState([]);
  const [initFlag, setInitFlag] = useState(false);
  const [role, setRole] = useState({});
  const [yearProcess, setYearProcess] = useState(null);
  const [isFilterZoneBlocked, setIsFilerZoneBlocked] = useState(false);

  // loading states
  const [regionsLoaded, setRegionsLoaded] = useState(true);
  const [businessLinesLoaded, setBusinessLinesLoaded] = useState(true);
  const [subregionsLoaded, setSubregionsLoaded] = useState(true);
  const [areasLoaded, setAreasLoaded] = useState(true);
  const [zonesLoaded, setZonesLoaded] = useState(true);

  let history = useHistory();

  const nameRoles = {
    admin: 'Administrador',
    areaAdmin: 'Administrador de área',
    salesManager: 'Gerente de ventas',
    apLeader: 'Liderazgo AP',
    consultantUser: 'Usuario consultor',
    kam: 'KAM',
    salesRepresentative: 'Representante de ventas',
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const hideDrawer = () => {
    setVisible(false);
  };

  const resetForm = () => {
    form.resetFields();
    setSidebarData();
  };

  const { Panel } = Collapse;

  const setSidebarData = () => {
    if (
      regions.length > 0 &&
      businessLines.length > 0 &&
      subregions.length > 0 &&
      areas.length > 0
    ) {
      onChange(
        role.roleName === nameRoles.admin ||
          role.roleName === nameRoles.salesManager ||
          role.roleName === nameRoles.salesRepresentative ||
          role.roleName === nameRoles.kam ||
          role.roleName === nameRoles.apLeader ||
          role.roleName === nameRoles.consultantUser ||
          role.roleName === nameRoles.areaAdmin
          ? [FILTERS.all]
          : [],
        regions,
        FILTERS.regions
      );
      onChange(
        role.roleName === nameRoles.admin ||
          role.roleName === nameRoles.salesManager ||
          role.roleName === nameRoles.salesRepresentative ||
          role.roleName === nameRoles.kam ||
          role.roleName === nameRoles.areaAdmin ||
          role.roleName === nameRoles.apLeader ||
          role.roleName === nameRoles.consultantUser
          ? [FILTERS.all]
          : [],
        businessLines,
        FILTERS.bussinesLine
      );
      onChange(
        role.roleName === nameRoles.salesManager || role.roleName === nameRoles.salesRepresentative
          ? [FILTERS.all]
          : [],
        subregions,
        FILTERS.subregions
      );

      onChange(
        role.roleName === nameRoles.salesRepresentative ? [FILTERS.all] : [],
        areas,
        FILTERS.areas
      );
      const allYears = mapsState?.getFilterYear?.data?.years;
      const currentYear = allYears.find((year) => year.key === yearProcess);
      const salesChallenge = acuerdoDesafio[1];
      onChange([FILTERS.all], [currentYear], FILTERS.yearOptions);
      onChange([FILTERS.all], salesChallenge, FILTERS.agreementChallenge);
      setInitFlag(true);
    }
  };

  useEffect(() => {
    if (initFlag === true) {
      return;
    }
    setSidebarData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regions, businessLines, subregions, areas]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setRole(user.role);
  }, []);

  useEffect(() => {
    if (mapsState) {
      setYearProcess(mapsState?.getFilterYear?.data?.yearProcess);
    }
  }, [mapsState]);

  const getCatalogData = async () => {
    setRegions(await getRegions());
    setRegionsLoaded(false);

    setBusinessLines(await getBusinessLines());
    setBusinessLinesLoaded(false);

    setSubregions(await getSubregions());
    setSubregionsLoaded(false);

    setAreas(await getTerritories());
    setAreasLoaded(false);

    setZones(await getZones());
    setZonesLoaded(false);
  };

  const renderSelectOptions = (arr) => {
    const allOptions = arr.map((current) => {
      const option = {
        value: current.value,
        key: current.key,
      };
      return option;
    });
    return allOptions;
  };

  useEffect(() => {
    getCatalogData();
    setIsFilerZoneBlocked(true);
  }, []);
  const onChangeAD = (data) => {
    if (data === 1) {
      setIsFilerZoneBlocked(true);
      form.setFieldsValue({ zones: undefined, agreementChallenge: data });
      const currentFiltersValues = form.getFieldsValue();
      setfilters(currentFiltersValues);
    } else {
      setIsFilerZoneBlocked(false);
      setfilters({ agreementChallenge: data });
    }

    const {
      regions: regionsFilter,
      bussinesLine: bussinesLineFilter,
      subregions: subregionsFilter,
      areas: areasFilter,
      zones: zonesFilter,
    } = form.getFieldsValue();
    if (
      regionsFilter?.length === 0 &&
      bussinesLineFilter?.length === 0 &&
      (!subregionsFilter || subregionsFilter?.length === 0) &&
      (!areasFilter || areasFilter?.length === 0) &&
      (!zonesFilter || zonesFilter?.length === 0)
    ) {
      getGet({ Regions: [], Year: 2021, history });
    }
  };
  return (
    <>
      <div className={'sidebar-mc-btn'}>
        <Button data-html2canvas-ignore={true} type="primary" onClick={showDrawer}>
          <FilterOutlined />
        </Button>
      </div>
      <div>
        <Drawer closable={false} mask={false} onClose={onClose} visible={visible}>
          <Row className="drawer-header">
            <Col span={1}>
              <FilterOutlined className="hand-cursor" onClick={hideDrawer} />
            </Col>
            <Col span={6} offset={4}>
              <p>Filtros</p>
            </Col>
            <Col span={1} offset={12}>
              <ReloadOutlined className="hand-cursor" onClick={resetForm} />
            </Col>
          </Row>
          <Collapse defaultActiveKey={['1', '2']}>
            {/* Estructura coComercial */}
            <Panel header="Estructura comercial" key="1">
              <p>Región</p>
              <BSelect
                mode={'multiple'}
                name={FILTERS['regions']}
                options={renderSelectOptions(regions)}
                label={''}
                loading={regionsLoaded}
                selectAll
                onChange={(data) => onChange(data, regions, 'regions')}
                maxTagCount={2}
              />

              <p>Línea de negocio</p>
              <BSelect
                mode={'multiple'}
                name={FILTERS['bussinesLine']}
                options={renderSelectOptions(businessLines)}
                label={''}
                loading={businessLinesLoaded}
                selectAll
                onChange={(data) => onChange(data, businessLines, 'bussinesLine')}
                maxTagCount={2}
              />

              <p>Subregión</p>
              <BSelect
                mode={'multiple'}
                name={FILTERS['subregions']}
                options={renderSelectOptions(subregions)}
                label={''}
                loading={subregionsLoaded}
                selectAll
                onChange={(data) => onChange(data, subregions, 'subregions')}
                maxTagCount={2}
              />

              <p>Territorio</p>
              <BSelect
                mode={'multiple'}
                name={FILTERS['areas']}
                options={renderSelectOptions(areas)}
                label={''}
                loading={areasLoaded}
                selectAll
                onChange={(data) => onChange(data, areas, 'areas')}
                maxTagCount={2}
                onClear={() => onClear(role, areas)}
              />

              <p>Zona</p>
              <BSelect
                mode={'multiple'}
                name={FILTERS['zones']}
                options={renderSelectOptions(zones)}
                label={''}
                loading={zonesLoaded}
                selectAll
                onChange={(data) => onChange(data, zones, 'zones')}
                maxTagCount={2}
                disabled={isFilterZoneBlocked}
              />
              <p
                id="zoneMessage"
                style={{
                  display: isFilterZoneBlocked ? 'block' : 'none',
                }}
              >
                El valor del filtro zona no aplica porque se seleccionó el indicador &quot;Acuerdo
                comercial&quot;
              </p>
            </Panel>

            {/* Mapa */}
            <Panel header="Mapa" key="2">
              <p>Año</p>
              <BSelect
                mode={'multiple'}
                name={FILTERS['yearOptions']}
                options={mapsState?.getFilterYear?.data?.years}
                onChange={(data) => onChange(data, yearOptions, 'yearOptions')}
                label={''}
                allowClear={false}
              />
              <p>Acuerdo/Desafío</p>
              <BSelect
                name={FILTERS['agreementChallenge']}
                options={renderSelectOptions(acuerdoDesafio)}
                label={''}
                onChange={(data) => onChangeAD(data)}
                allowClear={false}
              />
            </Panel>
          </Collapse>
        </Drawer>
      </div>
    </>
  );
}
export default Filterbar;
