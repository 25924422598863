import reducer from './redux-saga/reducers';

import {
  watchProductsMassiveUploadSetAllowUpload,
  watchProductsMassiveUploadRequestVerify,
  watchProductsMassiveUploadRequestUpload,
} from './redux-saga/sagas';

export default function getProductsMassiveUploadModule() {
  return {
    id: 'PRODUCTS_MASSIVE_UPLOAD',
    reducerMap: {
      PRODUCTS_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchProductsMassiveUploadSetAllowUpload,
      watchProductsMassiveUploadRequestVerify,
      watchProductsMassiveUploadRequestUpload,
    ],
  };
}
