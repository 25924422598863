export const CONSULT_INFO_BASE_REQUEST = 'CONSULT_INFO_BASE_REQUEST';
export const CONSULT_INFO_BASE_SET = 'CONSULT_INFO_BASE_SET';
export const CONSULT_INFO_BASE_CLEAN = 'CONSULT_INFO_BASE_CLEAN';

export function consultInfoBaseRequestTable(payload) {
  return {
    type: CONSULT_INFO_BASE_REQUEST,
    payload: payload,
  };
}
export function consultInfoBaseSetTable(payload) {
  return {
    type: CONSULT_INFO_BASE_SET,
    payload: payload,
  };
}
export function consultInfoBaseCleanTable(payload) {
  return {
    type: CONSULT_INFO_BASE_CLEAN,
  };
}
