export const PRODUCT_UPDATE_SET_FORM_VALUES = 'PRODUCT_UPDATE_SET_FORM_VALUES';
export const PRODUCT_UPDATE_CLEAR_FORM_VALUES = 'PRODUCT_UPDATE_CLEAR_FORM_VALUES';

export const PRODUCT_UPDATE_REQUEST_PRODUCT_DATA = 'PRODUCT_UPDATE_REQUEST_PRODUCT_DATA';
export const PRODUCT_UPDATE_SET_PRODUCT_DATA = 'PRODUCT_UPDATE_SET_PRODUCT_DATA';
export const PRODUCT_UPDATE_CLEAR_PRODUCT_DATA = 'PRODUCT_UPDATE_CLEAR_PRODUCT_DATA';

export const PRODUCT_UPDATE_REQUEST_STATUS_UPDATE = 'PRODUCT_UPDATE_REQUEST_STATUS_UPDATE';
export const PRODUCT_UPDATE_SET_STATUS_UPDATE = 'PRODUCT_UPDATE_SET_STATUS_UPDATE';
export const PRODUCT_UPDATE_CLEAR_STATUS_UPDATE = 'PRODUCT_UPDATE_CLEAR_STATUS_UPDATE';

export const PRODUCT_UPDATE_REQUEST_PRODUCT_UPDATE = 'PRODUCT_UPDATE_REQUEST_PRODUCT_UPDATE';
export const PRODUCT_UPDATE_SET_PRODUCT_UPDATE = 'PRODUCT_UPDATE_SET_PRODUCT_UPDATE';
export const PRODUCT_UPDATE_CLEAR_PRODUCT_UPDATE = 'PRODUCT_UPDATE_CLEAR_PRODUCT_UPDATE';

export function productUpdateSetFormValues(payload) {
  return {
    type: PRODUCT_UPDATE_SET_FORM_VALUES,
    payload: payload,
  };
}

export function productUpdateClearFormValues(payload) {
  return {
    type: PRODUCT_UPDATE_CLEAR_FORM_VALUES,
  };
}

export function productUpdateRequestProductData(payload) {
  return {
    type: PRODUCT_UPDATE_REQUEST_PRODUCT_DATA,
    payload: payload,
  };
}

export function productUpdateSetProductData(payload) {
  return {
    type: PRODUCT_UPDATE_SET_PRODUCT_DATA,
    payload: payload,
  };
}

export function productUpdateClearProductData(payload) {
  return {
    type: PRODUCT_UPDATE_CLEAR_PRODUCT_DATA,
  };
}

export function productUpdateRequestStatusUpdate(payload) {
  return {
    type: PRODUCT_UPDATE_REQUEST_STATUS_UPDATE,
    payload: payload,
  };
}

export function productUpdateSetStatusUpdate(payload) {
  return {
    type: PRODUCT_UPDATE_SET_STATUS_UPDATE,
    payload: payload,
  };
}

export function productUpdateClearStatusUpdate(payload) {
  return {
    type: PRODUCT_UPDATE_CLEAR_STATUS_UPDATE,
  };
}

export function productUpdateRequestProductUpdate(payload) {
  return {
    type: PRODUCT_UPDATE_REQUEST_PRODUCT_UPDATE,
    payload: payload,
  };
}

export function productUpdateSetProductUpdate(payload) {
  return {
    type: PRODUCT_UPDATE_SET_PRODUCT_UPDATE,
    payload: payload,
  };
}

export function productUpdateClearProductUpdate(payload) {
  return {
    type: PRODUCT_UPDATE_CLEAR_PRODUCT_UPDATE,
  };
}
