import request from 'services/request';
import { paramsSerializer } from 'RootModule/services/catalogs';

const API_REPORTS = process.env.REACT_APP_API_REPORTS;

const accountStatementService = 'AccountStatement/Api/v1/ManualStatementSend/GetListViewStatement';
const accountStatementPdfService = API_REPORTS + 'Reports/Api/v1/AccountStatement/AccountStatement';
const checkExistanceService = '/AccountStatement/Api/v1/ManualStatementSend/CheckExistence';
const checkDynamicElementsExistanceService = '/AccountStatement/Api/v1/Statements';
// const requestPeriodListService = '/AccountStatement/Api/v1/ManualStatementSend/GetPeriodsStatement';
const requestPeriodListService = '/AccountStatement/Api/v1/ManualStatementSend/GetPeriodsStatement';
const requestRegisterIntoLogService =
  '/AccountStatement/Api/v1/ManualStatementSend/SaveDownloadBinnacle';

export async function getAccountStatement({ params, cancelToken }) {
  return request(accountStatementService, {
    method: 'GET',
    params,
    cancelToken,
    paramsSerializer,
  });
}

export async function getAccountStatementPdf({ params, cancelToken }) {
  return request(accountStatementPdfService, {
    method: 'GET',
    params,
    cancelToken,
  });
}
export async function postAccountStatementPdf({ data, cancelToken }) {
  return request(accountStatementPdfService, {
    method: 'POST',
    data,
    cancelToken,
  });
}

export async function getExistances({ params, cancelToken }) {
  return request(checkExistanceService, {
    method: 'GET',
    params,
    cancelToken,
  });
}
export async function getDynamicElementsExistances({ params, cancelToken }) {
  return request(checkDynamicElementsExistanceService, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function getAccountStatementPeriodList({ params, cancelToken }) {
  return request(requestPeriodListService, {
    method: 'GET',
    params,
    cancelToken,
  });
}
export async function registerIntoLog({ params, cancelToken }) {
  return request(requestRegisterIntoLogService, {
    method: 'POST',
    params,
    cancelToken,
  });
}
