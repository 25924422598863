export const LOG_REQUEST = 'LOG_REQUEST';
export const GET_SYSTEM_USER_LOG = 'GET_SYSTEM_USER_LOG';
export const GET_LOG_FAIL = 'GET_LOG_FAIL';
export const LOG_ENTITY_REQUEST = 'LOG_ENTITY_REQUEST';
export const GET_LOG_ENTITY = 'GET_LOG_ENTITY';
export const LOG_TYPE_REQUEST = 'LOG_TYPE_REQUEST';
export const GET_LOG_TYPES = 'GET_LOG_TYPES';
export const LOG_USERS_REQUEST = 'LOG_USERS_REQUEST';
export const GET_LOG_USERS = 'GET_LOG_USERS';
export const GET_LOG_PAGES = 'GET_LOG_PAGES';
export const GET_LOG_TOTAL = 'GET_LOG_TOTAL';
export function logRequest(payload) {
  return {
    type: LOG_REQUEST,
    payload: payload,
  };
}

export function getSystemUserLog(payload) {
  return {
    type: GET_SYSTEM_USER_LOG,
    payload: payload,
  };
}

export function getLogsPages(payload) {
  return {
    type: GET_LOG_PAGES,
    payload: payload,
  };
}

export function getLogFail(payload) {
  return {
    type: GET_LOG_FAIL,
    payload: payload,
  };
}

export function logEntityRequest(payload) {
  return {
    type: LOG_ENTITY_REQUEST,
    payload: payload,
  };
}

export function getLogEntity(payload) {
  return {
    type: GET_LOG_ENTITY,
    payload: payload,
  };
}

export function logTypesRequest(payload) {
  return {
    type: LOG_TYPE_REQUEST,
    payload: payload,
  };
}

export function getLogTypes(payload) {
  return {
    type: GET_LOG_TYPES,
    payload: payload,
  };
}

export function logUsersRequest(payload) {
  return {
    type: LOG_USERS_REQUEST,
    payload: payload,
  };
}

export function getLogUsers(payload) {
  return {
    type: GET_LOG_USERS,
    payload: payload,
  };
}

export function getLogTotal(payload) {
  return {
    type: GET_LOG_TOTAL,
    payload: payload,
  };
}
