import request from 'services/request';

//

export async function deleteTradeAgreementValidator(params, cancelToken) {
  //console.log('params', params);
  return request('/Agreements/Api/v1/Validators/Delete', {
    method: 'DELETE',
    cancelToken,
    data: params,
  });
}
