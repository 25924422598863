import React from 'react';
import { Login } from '../Authentication';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { PublicPdfViewer } from 'PdfViewer';

function PublicRoutes() {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/reset-password/:token" component={Login} />
        <Route key="pdfViewer" exact={true} path={'/pdf-viewer/:id'} component={PublicPdfViewer} />

        <Route path="/*" component={Login} />
      </Switch>
    </Router>
  );
}
export default PublicRoutes;
