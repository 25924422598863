import {
  GET_SIGNERS_REQUEST,
  GET_SIGNERS_SUCCESS,
  GET_SIGNERS_FAIL,
  GET_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAIL,
} from '../actions/generateReport';

import { GET_MASSIVE_UPLOAD_REQUEST } from '../actions/getMassiveUpload';

function generateReport(state = {}, action) {
  switch (action.type) {
    /* 
TRADE_AGREEMENTS.generateReport.getReport
TRADE_AGREEMENTS.generateReport.getReport.isLoading
} */
    case GET_MASSIVE_UPLOAD_REQUEST:
      return {
        ...state,
        getReport: {
          isLoading: true,
        },
      };
    case GET_SIGNERS_REQUEST:
      return {
        ...state,
        signers: {
          loading: true,
          data: [],
        },
      };

    case GET_SIGNERS_SUCCESS: {
      return {
        ...state,
        signers: {
          loading: false,
          data: action.payload,
        },
      };
    }

    case GET_SIGNERS_FAIL:
      return {
        ...state,
        signers: {
          loading: false,
          data: [],
        },
      };

    case GET_REPORT_REQUEST:
      return {
        ...state,
        getReport: {
          isLoading: true,
        },
      };

    case GET_REPORT_SUCCESS:
      return {
        ...state,
        getReport: {
          isLoading: false,
        },
      };
    case GET_REPORT_FAIL:
      return {
        ...state,
        getReport: {
          isLoading: false,
        },
      };

    default:
      return state;
  }
}

export default generateReport;
