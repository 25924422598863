/* eslint-disable no-empty-function */
import request from '../../services/request';
const AGREEMENTS_API = '/Agreements/Api/v1/';
const AGREEMENTS_REPORT = `${AGREEMENTS_API}Reports/`;

const SALES_CHALLENGE_API = '/Sales/Api/v1/';
const SALES_CHALLENGE_REPORT = `${SALES_CHALLENGE_API}Reports/`;
export const getTradeAgreementsValidatorsReport = (cancelToken) => {
  return request(`${AGREEMENTS_REPORT}HistoryValidation`, {
    method: 'GET',
    cancelToken,
    responseType: 'blob',
    handlerEnabled: false,
  });
};

export function getSalesChallengeValidatorsReport({ cancelToken }) {
  return request(`${SALES_CHALLENGE_REPORT}HistorySalesChallengeValidation`, {
    method: 'GET',
    responseType: 'blob',
    cancelToken,
    handlerEnabled: false,
  });
}

export const getGenerateFile = (data, cancelToken) => {
  const customBaseURL = `${process.env.REACT_APP_API_REPORTS}Reports/Api/v1/Agreements`;

  return request(``, {
    method: 'POST',
    data,
    customBaseURL,
    cancelToken,
  });
};
