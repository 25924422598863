import { useState, useEffect, useRef } from 'react';
import useUser from 'hooks/useUser';
import {
  initialAllowRequest as defaultAllowRequest,
  initialDisabledFilters as defaultDisabledFilters,
  initialRequestParamsSubscription as defaultRequestParamSubscription,
  orchestrateFiltersChange as defaultOrchestrateFiltersChange,
  orchestrateFiltersReset as defaultOrchestrateFiltersReset,
} from '../defaultSettings';

import {
  initialAllowRequest as adminAreaAllowRequest,
  initialDisabledFilters as adminAreaDisabledFilters,
  initialRequestParamsSubscription as adminAreaRequestParamsSubscription,
  orchestrateFiltersChange as adminAreaOrchestrateFiltersChange,
  orchestrateFiltersReset as adminAreaOrchestrateFiltersReset,
} from '../adminAreaSettings';

const initialState = {
  orchestrateFiltersChange: null,
  orchestrateFiltersReset: null,
  initialDisabledFilters: null,
  initialAllowRequest: null,
  initialRequestParamsSubscription: null,
};

function useFiltersGroup() {
  let user = useUser();
  const wasRendered = useRef(false);
  const [stateModel, setStateModel] = useState(initialState);
  useEffect(() => {
    const getRolFilters = async () => {
      try {
        if (Object.keys(user).length > 0 && user.role) {
          const { roleId } = user?.role;
          if (roleId === 2) {
            setStateModel({
              orchestrateFiltersChange: adminAreaOrchestrateFiltersChange,
              orchestrateFiltersReset: adminAreaOrchestrateFiltersReset,
              initialAllowRequest: adminAreaAllowRequest,
              initialDisabledFilters: adminAreaDisabledFilters,
              initialRequestParamsSubscription: adminAreaRequestParamsSubscription,
            });
          } else {
            setStateModel({
              orchestrateFiltersChange: defaultOrchestrateFiltersChange,
              orchestrateFiltersReset: defaultOrchestrateFiltersReset,
              initialAllowRequest: defaultAllowRequest,
              initialDisabledFilters: defaultDisabledFilters,
              initialRequestParamsSubscription: defaultRequestParamSubscription,
            });
          }
        }
      } catch (error) {
        if (wasRendered.current) setStateModel(initialState);
      }
    };
    if (!wasRendered.current) getRolFilters();
    return () => {
      wasRendered.current = false;
    };
  }, [user]);
  return stateModel;
}

export default useFiltersGroup;
