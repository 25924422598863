import {
  PRODUCT_REGISTRATION_SET_BUSINESS_LINE_ID,
  PRODUCT_REGISTRATION_CLEAR_BUSINESS_LINE_ID,
  PRODUCT_REGISTRATION_SET_SUBREGION_ID,
  PRODUCT_REGISTRATION_CLEAR_SUBREGION_ID,
  PRODUCT_REGISTRATION_SET_COUNTRY_ID,
  PRODUCT_REGISTRATION_CLEAR_COUNTRY_ID,
  PRODUCT_REGISTRATION_SET_PRODUCT_NUMBER,
  PRODUCT_REGISTRATION_CLEAR_PRODUCT_NUMBER,
  PRODUCT_REGISTRATION_SET_NAME,
  PRODUCT_REGISTRATION_CLEAR_NAME,
  PRODUCT_REGISTRATION_SET_FAMILY,
  PRODUCT_REGISTRATION_CLEAR_FAMILY,
  PRODUCT_REGISTRATION_SET_CLASIFICATION,
  PRODUCT_REGISTRATION_CLEAR_CLASIFICATION,
  PRODUCT_REGISTRATION_SET_PRESENTATION,
  PRODUCT_REGISTRATION_CLEAR_PRESENTATION,
  PRODUCT_REGISTRATION_SET_MEASUREMENT_UNIT,
  PRODUCT_REGISTRATION_CLEAR_MEASUREMENT_UNIT,
  PRODUCT_REGISTRATION_SET_UNITS_PER_PACK,
  PRODUCT_REGISTRATION_CLEAR_UNITS_PER_PACK,
  PRODUCT_REGISTRATION_SET_MINIMUM_ORDER_REQUIRED,
  PRODUCT_REGISTRATION_CLEAR_MINIMUM_ORDER_REQUIRED,
  PRODUCT_REGISTRATION_SET_MOQ,
  PRODUCT_REGISTRATION_CLEAR_MOQ,
  PRODUCT_REGISTRATION_SET_CURRENCY,
  PRODUCT_REGISTRATION_CLEAR_CURRENCY,
  PRODUCT_REGISTRATION_SET_IVA,
  PRODUCT_REGISTRATION_CLEAR_IVA,
  PRODUCT_REGISTRATION_SET_IEPS,
  PRODUCT_REGISTRATION_CLEAR_IEPS,
  PRODUCT_REGISTRATION_REQUEST_PRODUCT_EXISTENCE,
  PRODUCT_REGISTRATION_SET_PRODUCT_EXISTENCE,
  PRODUCT_REGISTRATION_CLEAR_PRODUCT_EXISTENCE,
  PRODUCT_REGISTRATION_REQUEST_REGISTRATION,
  PRODUCT_REGISTRATION_SET_REGISTRATION,
  PRODUCT_REGISTRATION_CLEAR_REGISTRATION,
} from '../actions';

function productRegistrationReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case PRODUCT_REGISTRATION_SET_BUSINESS_LINE_ID:
      return {
        ...state,
        isLoading: false,
        businessLineId: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_BUSINESS_LINE_ID:
      return {
        ...state,
        isLoading: false,
        businessLineId: null,
      };

    case PRODUCT_REGISTRATION_SET_SUBREGION_ID:
      return {
        ...state,
        isLoading: false,
        subregionId: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_SUBREGION_ID:
      return {
        ...state,
        isLoading: false,
        subregionId: null,
      };

    case PRODUCT_REGISTRATION_SET_COUNTRY_ID:
      return {
        ...state,
        isLoading: false,
        countryId: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_COUNTRY_ID:
      return {
        ...state,
        isLoading: false,
        countryId: null,
      };

    case PRODUCT_REGISTRATION_SET_PRODUCT_NUMBER:
      return {
        ...state,
        isLoading: false,
        productNumber: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_PRODUCT_NUMBER:
      return {
        ...state,
        isLoading: false,
        productNumber: null,
      };

    case PRODUCT_REGISTRATION_SET_NAME:
      return {
        ...state,
        isLoading: false,
        name: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_NAME:
      return {
        ...state,
        isLoading: false,
        name: null,
      };

    case PRODUCT_REGISTRATION_SET_FAMILY:
      return {
        ...state,
        isLoading: false,
        family: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_FAMILY:
      return {
        ...state,
        isLoading: false,
        family: null,
      };

    case PRODUCT_REGISTRATION_SET_CLASIFICATION:
      return {
        ...state,
        isLoading: false,
        clasification: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_CLASIFICATION:
      return {
        ...state,
        isLoading: false,
        clasification: null,
      };

    case PRODUCT_REGISTRATION_SET_PRESENTATION:
      return {
        ...state,
        isLoading: false,
        presentation: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_PRESENTATION:
      return {
        ...state,
        isLoading: false,
        presentation: null,
      };

    case PRODUCT_REGISTRATION_SET_MEASUREMENT_UNIT:
      return {
        ...state,
        isLoading: false,
        measurementUnit: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_MEASUREMENT_UNIT:
      return {
        ...state,
        isLoading: false,
        measurementUnit: null,
      };

    case PRODUCT_REGISTRATION_SET_UNITS_PER_PACK:
      return {
        ...state,
        isLoading: false,
        unitsPerPack: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_UNITS_PER_PACK:
      return {
        ...state,
        isLoading: false,
        unitsPerPack: null,
      };

    case PRODUCT_REGISTRATION_SET_MINIMUM_ORDER_REQUIRED:
      return {
        ...state,
        isLoading: false,
        minimumOrderRequired: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_MINIMUM_ORDER_REQUIRED:
      return {
        ...state,
        isLoading: false,
        minimumOrderRequired: null,
      };

    case PRODUCT_REGISTRATION_SET_MOQ:
      return {
        ...state,
        isLoading: false,
        moq: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_MOQ:
      return {
        ...state,
        isLoading: false,
        moq: null,
      };

    case PRODUCT_REGISTRATION_SET_CURRENCY:
      return {
        ...state,
        isLoading: false,
        currency: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_CURRENCY:
      return {
        ...state,
        isLoading: false,
        currency: null,
      };

    case PRODUCT_REGISTRATION_SET_IVA:
      return {
        ...state,
        isLoading: false,
        iva: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_IVA:
      return {
        ...state,
        isLoading: false,
        iva: null,
      };

    case PRODUCT_REGISTRATION_SET_IEPS:
      return {
        ...state,
        isLoading: false,
        ieps: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_IEPS:
      return {
        ...state,
        isLoading: false,
        ieps: null,
      };

    case PRODUCT_REGISTRATION_REQUEST_PRODUCT_EXISTENCE:
      return {
        ...state,
        isLoading: true,
        productExistence: null,
      };

    case PRODUCT_REGISTRATION_SET_PRODUCT_EXISTENCE:
      return {
        ...state,
        isLoading: false,
        productExistence: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_PRODUCT_EXISTENCE:
      return {
        ...state,
        isLoading: false,
        productExistence: null,
      };

    case PRODUCT_REGISTRATION_REQUEST_REGISTRATION:
      return {
        ...state,
        isLoading: true,
        registration: null,
      };

    case PRODUCT_REGISTRATION_SET_REGISTRATION:
      return {
        ...state,
        isLoading: false,
        registration: action.payload,
      };

    case PRODUCT_REGISTRATION_CLEAR_REGISTRATION:
      return {
        ...state,
        isLoading: false,
        registration: null,
      };

    default:
      return state;
  }
}

export default productRegistrationReducer;
