/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Modal, Button, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined, LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import PdfViewer from 'common/PdfViewer';
import {
  sendAccountStatementRequest,
  reportRequest,
  checkExistencesStatementRequest,
  checkExistencesStatementClean,
} from '../redux-saga/actions';
import '../css/style.css';
import Recurso50 from '../assets/img/recurso50.png';

const PdfReader = ({ pdf, data }) => {
  const initialPage = 1;
  const totalColumns = 1;
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [visibleModal, setVisibleModal] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [disabledPrev, setDisabledPrev] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const dataPdf = useSelector((state) => state.CONSULT_INFO_BASE?.dataReport?.AccountStatement);

  const reportPdf = useSelector((state) => state.CONSULT_INFO_BASE?.dataReport);

  const handleVibleModal = () => {
    setVisibleModal(true);
    dispatch(
      reportRequest({
        params: {
          IsInOut: true,
          clientId: data.idClient,
          lineId: data.lineId,
          subregionId: data.subRegionId,
        },
      })
    );

    dispatch(
      checkExistencesStatementRequest({
        params: {
          Line: data.lineId,
          SoldTo: data.soldTo,
        },
      })
    );
  };

  const isClickingRight = (event) => {
    const rect = event.currentTarget.parentElement.getBoundingClientRect();
    const { left, right } = rect;
    const middle = (right - left) / 2 + left;
    const clickPosition = event.clientX;

    return clickPosition > middle;
  };
  const nextPages = () => {
    if (currentPage >= totalPages || currentPage + totalColumns > totalPages) {
      setCurrentPage(initialPage);
    } else {
      setCurrentPage(currentPage + totalColumns);
    }
  };
  const previousPages = () => {
    if (currentPage <= 0) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(currentPage - totalColumns);
    }
  };
  const containerClick = (event) => {
    event.preventDefault();
    let clickOnRight = isClickingRight(event);
    clickOnRight ? nextPages() : previousPages();
  };

  const onLoadSuccess = (PDFDocumentProxy) => {
    let proxy = { ...PDFDocumentProxy };
    setTotalPages(proxy._pdfInfo.numPages);
  };

  const handleConfirmSend = () => {
    setVisibleModal(false);
    setCurrentPage(1);
    const params = {
      ClientId: data.idClient,
      lineID: data.lineId,
      subregionId: data.subRegionId,
    };
    dispatch(sendAccountStatementRequest({ params, history }));
    dispatch(checkExistencesStatementClean());
  };

  const handleConfirmModal = () => {
    setVisibleModal(false);
    Modal.confirm({
      title: 'Envio de estado de cuenta manual',
      icon: <ExclamationCircleOutlined />,
      content: '¿Esta seguro que desea enviar el estado de cuenta?',
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      onOk() {
        handleConfirmSend();
      },
      onCancel() {
        setVisibleModal(false);
        setCurrentPage(1);
        dispatch(checkExistencesStatementClean());
      },
    });
  };

  useEffect(() => {
    setDisabledPrev(currentPage <= 1 ? true : false);
    setDisabledNext(currentPage === totalPages ? true : false);
  }, [currentPage, totalPages]);

  useEffect(() => {
    if (reportPdf === null) {
      setTimeout(() => {
        setVisibleModal(false);
      }, 5000);
    }
  }, [reportPdf]);

  return (
    <>
      <Button className="sendBtn" onClick={handleVibleModal}>
        <img width="15" src={Recurso50} alt="pdf" />
      </Button>
      <Modal
        width={700}
        height={600}
        visible={visibleModal}
        className="pdfModal"
        maskClosable={false}
        closable={dataPdf ? true : false}
        onCancel={() => {
          setVisibleModal(false);
          setCurrentPage(1);
        }}
        style={{ background: 'transparent', top: 20 }}
        footer={[
          dataPdf && (
            <Button key="send" className="sendAccountBtn" onClick={handleConfirmModal}>
              Envio manual
            </Button>
          ),
        ]}
      >
        {dataPdf ? (
          <>
            <div className="pdfContainer">
              <PdfViewer
                style={{ width: '100%' }}
                pages={1}
                columns={1}
                onClick={containerClick}
                showPage={currentPage}
                onLoadSuccess={onLoadSuccess}
                pdf={'data:application/pdf;base64,' + dataPdf}
              />

              <button
                disabled={disabledPrev}
                className="pdfBtn prev"
                onClick={() => previousPages()}
              >
                <LeftCircleFilled />
              </button>

              <button disabled={disabledNext} className="pdfBtn next" onClick={() => nextPages()}>
                <RightCircleFilled />
              </button>
            </div>
          </>
        ) : (
          <Spin size="large" className="spinnerPdf" />
        )}
      </Modal>
    </>
  );
};

export default PdfReader;
