export const getIdsOfArrayOfStrings = (arr) => arr.map((str) => JSON.parse(str).id);
export const createBusinessEstructureObject = (businessEstructure, item) => {
  let name = Object.keys(item)[0];
  let value = item[name];
  switch (name) {
    case 'regions':
      return { [name]: value };
    case 'lobs':
      return {
        regions: businessEstructure.regions,
        [name]: value,
      };
    case 'subregions':
      return {
        regions: businessEstructure.regions,
        lobs: businessEstructure.lobs,
        [name]: value,
      };
    case 'territories':
      return {
        regions: businessEstructure.regions,
        lobs: businessEstructure.lobs,
        subregions: businessEstructure.subregions,
        [name]: value,
      };
    case 'zones':
      return {
        regions: businessEstructure.regions,
        lobs: businessEstructure.lobs,
        subregions: businessEstructure.subregions,
        territories: businessEstructure.territories,
        [name]: value,
      };
    case 'countries':
      return {
        regions: businessEstructure.regions,
        lobs: businessEstructure.lobs,
        subregions: businessEstructure.subregions,
        territories: businessEstructure.territories,
        zones: businessEstructure.zones,
        [name]: value,
      };
    default:
      return {};
  }
};
