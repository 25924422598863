import { SAVE_TABLE, CLEAN_TABLE, VALIDATE_TABLE_COMPLETION_REQUEST } from '../actions/table';

const query = (state = {}, action) => {
  switch (action.type) {
    case SAVE_TABLE:
      return {
        ...state,
        data: action.payload,
      };

    case CLEAN_TABLE:
      return {
        tableIsValid: { ...state.tableIsValid },
      };
    case VALIDATE_TABLE_COMPLETION_REQUEST:
      return {
        ...state,
        tableIsValid: { ...state.tableIsValid, ...action.payload },
      };

    default:
      return state;
  }
};
export default query;
