import { takeLatest, all, call, put, cancelled } from 'redux-saga/effects';
// Imports -
// import { notification } from 'antd';
// import { checkIfNoElements } from '../../utils';

import {
  CONSULT_SHOPPING_BY_FAMILY_REQUEST,
  ConsultShoppingByFamilySetTable,
  ConsultShoppingByFamilyCleanTable,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';
import { AccountStatusConsult } from '../../services';
import { messageError } from 'utils/messages';

function* workConsultShoppingByFamilyTable(action) {
  const { params, history } = action.payload;
  const { CancelToken } = instance;
  const source = CancelToken.source();

  try {
    const response = yield call(AccountStatusConsult, {
      cancelToken: source.token,
      params,
    });
    if (responseOK(response)) {
      yield put(ConsultShoppingByFamilySetTable(response.data));
      if (Object.keys(params).length === 0 && response.data.data.length === 0) {
        yield call(messageError, 'No hay registros de Compras por familia en el sistema');
        setTimeout(() => {
          history.push('/account-status/base-information');
        }, 500);
      }
    } else {
      yield put(ConsultShoppingByFamilyCleanTable());
    }
  } catch (error) {
    yield ConsultShoppingByFamilyCleanTable();
    console.error('SAGAS ERROR', error);
  } finally {
    if (yield cancelled()) {
      yield ConsultShoppingByFamilyCleanTable();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchConsultShoppingByFamilyTable() {
  yield all([takeLatest(CONSULT_SHOPPING_BY_FAMILY_REQUEST, workConsultShoppingByFamilyTable)]);
}
