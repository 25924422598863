import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Input, Button } from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import useForm from '../hooks/useForm';
import creditNotesModule from '../index';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { BSelect } from 'common';
import Pog from 'RootModule/catalogs/Pog';
import { connect } from 'react-redux';
import {
  subRegionsRequest,
  businessLinesRequest,
  yearsRequest,
  PeriodicityRequest,
  IncentivesRequest,
  creditNotesRequest,
  cleanBusinessLines,
  cleanIncentives,
  cleanYears,
  cleanSubRegions,
  cleanPeriodicity,
  cleanCreditNotes,
} from '../redux-saga/actions';
import '../css/style.css';

const TopForm = ({
  subRegionsRequest: getSubRegions,
  businessLinesRequest: getBusinessLines,
  yearsRequest: getYears,
  PeriodicityRequest: getPeriodicity,
  IncentivesRequest: getIncentives,
  creditNotesRequest: getCreditNotes,
  creditNotesSubRegions,
  creditNotesBusinessLines,
  creditNotesYears,
  creditNotesPeriodicity,
  creditNotesIncentives,
}) => {
  const [subRegionsArray, setSubRegionsArray] = useState([]);
  const [businessLinesArray, setBusinessLinesArray] = useState([]);
  const [yearsArray, setYearsArray] = useState([]);
  const [periodicityArray, setPeriodicityArray] = useState([]);
  const [periodsArray, setPeriodsArray] = useState([]);
  const [incentivesArray, setIncentivesArray] = useState([]);
  const [disabledField, setDisabledField] = useState(true);

  const { Item } = Form;
  const { Search } = Input;
  const [form] = Form.useForm();

  const [handleReset, hadleChange] = useForm(form, getCreditNotes);

  useEffect(() => {
    getSubRegions();
    getBusinessLines();
    getYears();
    getPeriodicity();
    getIncentives();

    return () => {
      cleanBusinessLines();
      cleanIncentives();
      cleanYears();
      cleanSubRegions();
      cleanPeriodicity();
      cleanCreditNotes();
      setDisabledField(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubRegionsArray(creditNotesSubRegions);
    setBusinessLinesArray(creditNotesBusinessLines);
    setYearsArray(creditNotesYears);
    periodicitySelectOptions();
    incentiveOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    creditNotesSubRegions,
    creditNotesBusinessLines,
    creditNotesYears,
    creditNotesPeriodicity,
    creditNotesIncentives,
  ]);

  const incentiveOptions = () => {
    let newArray2 = [];
    // eslint-disable-next-line array-callback-return
    creditNotesIncentives.map((item) => {
      newArray2.push({
        key: item.incentiveCN_Id,
        value: item.name,
      });
    });
    setIncentivesArray(newArray2);
  };

  const periodicitySelectOptions = () => {
    let defaultArray = creditNotesPeriodicity.map((item) => ({
      key: item.key,
      value: item.value,
    }));
    setPeriodicityArray(defaultArray);
  };

  let periodictyOpcion = {
    ALL: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    1: [1],
    2: [1, 2],
    3: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    4: [1, 2, 3, 4, 5, 6],
    5: [1, 2, 3, 4],
    6: [1, 2, 3],
  };
  const handleDefaultValue = (value, name, nametwo, options) => {
    const filteredOptions = options.filter((item) => item.value !== undefined);
    if (value?.length) {
      if (value.includes('ALL')) {
        if (value.length === filteredOptions.length + 1) {
          form.setFieldsValue({ [name]: [], [nametwo]: [] });
        } else {
          value = filteredOptions.map((option) => {
            return option.key;
          });

          form.setFieldsValue({
            [name]: value,
            [nametwo]: value,
          });
        }
      }
    } else {
      setDisabledField(true);
      form.setFieldsValue({
        periodsSelect: [],
      });
    }

    if (name === 'Periodicity') {
      let array = [];
      if (value.length > 0) {
        // eslint-disable-next-line array-callback-return
        value.map((d) => {
          array = array.concat(periodictyOpcion[d]);
        });
      }
      let optionsPeriodo = [...new Set(array)];
      let newOptions = optionsPeriodo.map((option) => {
        return { key: option, value: option };
      });
      setPeriodsArray(newOptions);
    }
  };

  return (
    <>
      <Form form={form}>
        <Row>
          <Col span={3}>
            <Item name="Line" label="L. de negocio">
              <BSelect
                mode={'multiple'}
                options={businessLinesArray}
                name={'lineSelect'}
                label={''}
                onChange={(value) => {
                  handleDefaultValue(value, 'Line', 'lineSelect', businessLinesArray);
                  hadleChange();
                }}
                selectAll={false}
                maxTagCount={3}
                allowClear={false}
              />
            </Item>
          </Col>
          <Col span={3}>
            <Item name="Subregion" label="Subregión">
              <BSelect
                mode={'multiple'}
                options={subRegionsArray}
                name={'subregionSelect'}
                label={''}
                selectAll={true}
                maxTagCount={3}
                onChange={(value) => {
                  handleDefaultValue(value, 'Subregion', 'subregionSelect', subRegionsArray);
                  hadleChange();
                }}
                allowClear={false}
              />
            </Item>
          </Col>
          <Col span={3}>
            <Item name="Incentive" label="Incentivo">
              <BSelect
                mode={'multiple'}
                name={'incentiveSelect'}
                options={incentivesArray}
                label={''}
                selectAll={true}
                onChange={(value) => {
                  handleDefaultValue(value, 'Incentive', 'incentiveSelect', incentivesArray);
                  hadleChange();
                }}
                maxTagCount={3}
                allowClear={false}
              />
            </Item>
          </Col>
          <Col span={3}>
            <Item name="Periodicity" label="Periodicidad">
              <BSelect
                mode={'multiple'}
                name={'periodicitySelect'}
                options={periodicityArray}
                label={''}
                selectAll={true}
                maxTagCount={3}
                allowClear={true}
                onChange={(value) => {
                  setDisabledField(false);
                  handleDefaultValue(value, 'Periodicity', 'periodicitySelect', periodsArray);
                  hadleChange();
                }}
              />
            </Item>
          </Col>
          <Col span={3}>
            <Item name="Period" label="Periodo">
              <Pog
                mode={'multiple'}
                name={'periodsSelect'}
                options={periodsArray}
                disabled={disabledField}
                label={''}
                selectAll={true}
                onChange={(value) => {
                  handleDefaultValue(value, 'Period', 'periodsSelect', periodsArray);
                  hadleChange();
                }}
                maxTagCount={3}
                allowClear={false}
              />
            </Item>
          </Col>
          <Col span={3}>
            <Item name="Year" label="Año">
              <BSelect
                mode={'multiple'}
                name={'yearsSelect'}
                options={yearsArray}
                label={''}
                selectAll={true}
                onChange={(value) => {
                  handleDefaultValue(value, 'Year', 'yearsSelect', yearsArray);
                  hadleChange();
                }}
                maxTagCount={3}
                allowClear={false}
              />
            </Item>
          </Col>
          <Col span={5}>
            <Item name="Wildcard">
              <span style={{ width: '100%', height: '36px', display: 'block' }} />
              <Search
                allowClear
                style={{ width: '100%' }}
                onSearch={(value) => {
                  let newVal = value.replace(/^["'](.+(?=["']$))["']$/, '$1');
                  form.setFieldsValue({ Wildcard: newVal });
                  hadleChange();
                }}
                onKeyUp={(e) => {
                  const inputVal = e.target.value;
                  let newVal = inputVal.replace(/^["'](.+(?=["']$))["']$/, '$1');
                  form.setFieldsValue({ Wildcard: newVal });
                  hadleChange();
                }}
              />
            </Item>
          </Col>

          <Button
            icon={<UndoOutlined />}
            className="reset-btn"
            onClick={() => {
              handleReset();
            }}
          />
        </Row>
      </Form>
    </>
  );
};

function mapStateToProps(state) {
  return {
    creditNotesSubRegions: state?.CREDITNOTESSECTION?.creditNotesSubRegions ?? [],
    creditNotesBusinessLines: state?.CREDITNOTESSECTION?.creditNotesBusinessLines ?? [],
    creditNotesYears: state?.CREDITNOTESSECTION?.creditNotesYears ?? [],
    creditNotesPeriodicity: state?.CREDITNOTESSECTION?.creditNotesPeriodicity ?? [],
    creditNotesIncentives: state?.CREDITNOTESSECTION?.creditNotesIncentives ?? [],
  };
}

const LogWrapped = connect(mapStateToProps, {
  subRegionsRequest,
  businessLinesRequest,
  yearsRequest,
  PeriodicityRequest,
  IncentivesRequest,
  creditNotesRequest,
  cleanBusinessLines,
  cleanIncentives,
  cleanYears,
  cleanSubRegions,
  cleanPeriodicity,
  cleanCreditNotes,
})(TopForm);

const CreditNotesLogs = () => (
  <DynamicModuleLoader modules={[creditNotesModule()]}>
    <LogWrapped />
  </DynamicModuleLoader>
);

export default CreditNotesLogs;
