import {
  GET_SIGNERS_REQUEST,
  getSignersSuccess,
  getSignersFail,
} from 'TradeAgreements/redux/actions/generateReport';
import { takeLatest, cancelled, put, call } from 'redux-saga/effects';
import { responseOK } from 'SalesChallengeRefactor/utils';
import instance from 'services/request';
import { getCatalogReportByName } from 'TradeAgreements/services/reports';

export function* getSignersRequestWork(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params } = payload;

    const response = yield call(getCatalogReportByName, {
      params,
      cancelToken: source.token,
      reportName: 'Signers',
    });

    if (responseOK(response)) {
      yield put(getSignersSuccess(response.data));
    } else {
      yield put(getSignersFail());
    }
  } catch (error) {
    yield put(getSignersFail());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetSingersRequest() {
  yield takeLatest(GET_SIGNERS_REQUEST, getSignersRequestWork);
}
