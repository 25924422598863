import React from 'react';
import { Checkbox, Space, Input } from 'antd';

export default function ChildrenDrawer({
  onChangeMonth,
  valueMonth,
  valueDescription,
  onChangeTextArea,
  selectPosition,
}) {
  const { TextArea } = Input;

  return (
    <div key={'divChildrenDrawerFragment'}>
      <div style={{ margin: '10px 35px' }} id="spaceManageImageDiv" key={'divDrawerTitle'}>
        <h4
          style={{ textAlign: 'left', color: valueMonth.length === 0 ? 'red' : '#00499a' }}
          key={'h4DrawerTitle'}
        >
          *Seleccionar meses
        </h4>

        <Checkbox.Group
          onChange={onChangeMonth}
          value={valueMonth}
          style={{ color: 'black !important' }}
          key={'CheckboxDrawer'}
        >
          <Space direction="vertical" id="spaceManageImage" key={'spaceVerticalDrawer'}>
            <Checkbox value={1} key={'Enero'} className="red">
              <span style={{ color: valueMonth.length === 0 ? 'red' : 'black' }}>Enero</span>
            </Checkbox>
            <Checkbox value={2} key={'Febrero'}>
              <span style={{ color: valueMonth.length === 0 ? 'red' : 'black' }}>Febrero</span>
            </Checkbox>
            <Checkbox value={3} key={'Marzo'}>
              <span style={{ color: valueMonth.length === 0 ? 'red' : 'black' }}>Marzo</span>
            </Checkbox>
            <Checkbox value={4} key={'Abril'}>
              <span style={{ color: valueMonth.length === 0 ? 'red' : 'black' }}>Abril</span>
            </Checkbox>
            <Checkbox value={5} key={'Mayo'}>
              <span style={{ color: valueMonth.length === 0 ? 'red' : 'black' }}>Mayo</span>
            </Checkbox>
            <Checkbox value={6} key={'Junio'}>
              <span style={{ color: valueMonth.length === 0 ? 'red' : 'black' }}>Junio</span>
            </Checkbox>
            <Checkbox value={7} key={'Julio'}>
              <span style={{ color: valueMonth.length === 0 ? 'red' : 'black' }}>Julio</span>
            </Checkbox>
            <Checkbox value={8} key={'Agosto'}>
              <span style={{ color: valueMonth.length === 0 ? 'red' : 'black' }}>Agosto</span>
            </Checkbox>
            <Checkbox value={9} key={'Septiembre'}>
              <span style={{ color: valueMonth.length === 0 ? 'red' : 'black' }}>Septiembre</span>
            </Checkbox>
            <Checkbox value={10} key={'Octubre'}>
              <span style={{ color: valueMonth.length === 0 ? 'red' : 'black' }}>Octubre</span>
            </Checkbox>
            <Checkbox value={11} key={'Noviembre'}>
              <span style={{ color: valueMonth.length === 0 ? 'red' : 'black' }}>Noviembre</span>
            </Checkbox>
            <Checkbox value={12} key={'Diciembre'}>
              <span style={{ color: valueMonth.length === 0 ? 'red' : 'black' }}>Diciembre</span>
            </Checkbox>
          </Space>
        </Checkbox.Group>
      </div>
      {selectPosition ? selectPosition() : null}
      <div style={{ margin: '10px 35px' }} key={'divDrawerDescription'}>
        <h4 style={{ textAlign: 'left', color: '#00499a' }} key={'h4DrawerDescription'}>
          Descripción:
        </h4>
        <TextArea
          rows={3}
          placeholder="Escribe Descripción"
          value={valueDescription}
          onChange={onChangeTextArea}
          maxLength={100}
          key={'textAreaDrawerDescription'}
        />
      </div>
    </div>
  );
}
