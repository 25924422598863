/* eslint-disable react/display-name */
import React, { useContext } from 'react';
import { Table, Row, InputNumber } from 'antd';
import './table.css';
import { layoutContext } from '../context';
function SalesGoalsTable(props) {
  const { setForm, form, monthsArray, validateForm, setDisabledSubmit, setMonthsArray } = props;
  const { onChangeActiveComponent } = useContext(layoutContext);

  //formato

  const formatFunc = (value) => {
    if (value && typeof value === 'string') {
      let format = '';
      let decimal = value.indexOf('.');

      if (decimal > 0) {
        let a = new RegExp(/(^([0-9]*\.[0-9][0-9])$)/gi);
        let onlyTwoDecimals = value.match(a, '$1');

        if (!onlyTwoDecimals) {
          let splitDecimals = value.split('.');
          if (splitDecimals[1]) {
            let sub = splitDecimals[1].substring(0, 2);
            value = `${splitDecimals[0]}.${sub}`;
          }
        }
        if (value.length <= 20) {
          format = `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
          let val = value.substring(0, 20);
          format = `$${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      } else {
        if (value.length <= 12) {
          format = `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
          let val = value.substring(0, 12);
          format = `$${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      }

      return format;
    }
  };
  const formatFuncVolume = (value) => {
    if (value && typeof value === 'string') {
      let format = '';
      let decimal = value.indexOf('.');

      if (decimal > 0) {
        let a = new RegExp(/(^([0-9]*\.[0-9][0-9])$)/gi);

        let onlyTwoDecimals = value.match(a, '$1');

        if (!onlyTwoDecimals) {
          let splitDecimals = value.split('.');
          if (splitDecimals[1]) {
            let sub = splitDecimals[1].substring(0, 2);
            value = `${splitDecimals[0]}.${sub}`;
          }
        }
        if (value.length <= 20) {
          format = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
          let val = value.substring(0, 20);
          format = `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      } else {
        if (value.length <= 12) {
          format = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
          let val = value.substring(0, 12);
          format = `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      }

      return format;
    }
  };
  const parserFunc = (value) => {
    let clean = value.replace(/\$\s?|(,*)/g, '');
    if (clean && clean.length > 12) {
      let result = '';
      let decimal = clean.indexOf('.');
      if (decimal > 0 && clean.length <= 20) {
        result = clean.substring(0, 20);
      } else {
        result = clean.substring(0, 12);
      }

      return result;
    } else {
      return clean;
    }
  };
  const keyBlock = (e) => {
    if (e.shiftKey || (e.shiftKey && e.which === 51) || e.key === 'Dead') {
      e.preventDefault();
    }
    if (
      e.keyCode === 69 ||
      e.keyCode === 73 ||
      e.keyCode === 186 ||
      e.keyCode === 187 ||
      e.keyCode === 189 ||
      e.keyCode === 40 ||
      e.keyCode === 107 ||
      e.keyCode === 109 ||
      e.keyCode === 191 ||
      e.keyCode === 192 ||
      e.keyCode === 219 ||
      e.keyCode === 220 ||
      e.keyCode === 221 ||
      e.keyCode === 222 ||
      e.keyCode === 38 ||
      e.keyCode === 40 ||
      e.key === '{' ||
      e.key === '}' ||
      e.key === '+' ||
      e.key === '*' ||
      e.key === '[' ||
      e.key === ']' ||
      e.key === '´' ||
      e.key === '/' ||
      e.key === '<' ||
      e.key === '+' ||
      e.key === '´´' ||
      e.key === 'ArrowLeft' ||
      e.key === 'BracketLeft' ||
      e.key === 'BracketRight' ||
      e.key === 'Quote' ||
      e.key === 'Shift' ||
      e.key === 'Dead' ||
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      e.shiftKey ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowUp'
    ) {
      e.preventDefault();
    }
  };

  const handleMonths = (value, type, month) => {
    updateVolume(value, month, type);
  };
  const updateVolume = (volume, month, collection = 'volume') => {
    let newMonthsArray = monthsArray.map((element, index) => {
      if (element.month === month) {
        return {
          ...element,
          [collection]: volume,
          //crop: props.ap,
        };
      }
      return element;
    });
    let accumnulatorUsd = 0;
    let accumnulatorVolumen = 0;

    monthsArray.forEach((value) => {
      if (value.usdAmount) {
        accumnulatorUsd += value.usdAmount;
      }
    });
    newMonthsArray.forEach((value) => {
      if (value.volume) {
        accumnulatorVolumen += value.volume;
      }
    });
    if (accumnulatorVolumen > 0 || accumnulatorUsd > 0) {
      onChangeActiveComponent('sales-challenge');
    } else {
      onChangeActiveComponent(undefined);
    }
    if (accumnulatorVolumen > 0) {
      validateForm();
    } else {
      setDisabledSubmit(true);
    }

    setMonthsArray(newMonthsArray);
    form.setFieldsValue({ saleMonth: newMonthsArray });
    setForm({ ...form, saleMonth: newMonthsArray });
  };

  const firstSemesterColumns = [
    {
      title: 'Meses',
      dataIndex: 'metas',
      key: 'meses',
    },
    {
      title: 'Ene',
      dataIndex: 'Ene',
      key: '1',
      render: (data, key) => {
        return (
          <InputNumber
            width="100%"
            name={`Ene`}
            formatter={key.key === '1' ? formatFuncVolume : formatFunc}
            parser={parserFunc}
            max={999999999999.99}
            maxLength={key.key === '1' ? 18 : 19}
            min={0}
            step="0.01"
            stringMode
            id={1}
            bordered={false}
            placeholder="0"
            onKeyDown={keyBlock}
            onChange={(e) => {
              handleMonths(e, key.type, '1');
            }}
          />
        );
      },
    },
    {
      title: 'Feb',
      dataIndex: 'Feb',
      key: 'Feb',
      render: (data, key) => (
        <InputNumber
          name={`Ene/${key.type}`}
          formatter={key.key === '1' ? formatFuncVolume : formatFunc}
          parser={parserFunc}
          max={999999999999.99}
          maxLength={key.key === '1' ? 18 : 19}
          min={0}
          step="0.01"
          stringMode
          id={2}
          bordered={false}
          placeholder="0"
          onKeyDown={keyBlock}
          onChange={(e) => {
            handleMonths(e, key.type, '2');
          }}
        />
      ),
    },
    {
      title: 'Mar',
      dataIndex: 'Mar',
      key: 'Mar',
      render: (data, key) => (
        <InputNumber
          name="Mar"
          formatter={key.key === '1' ? formatFuncVolume : formatFunc}
          parser={parserFunc}
          max={999999999999.99}
          maxLength={key.key === '1' ? 18 : 19}
          min={0}
          step="0.01"
          stringMode
          id={3}
          bordered={false}
          placeholder="0"
          onKeyDown={keyBlock}
          onChange={(e) => {
            handleMonths(e, key.type, '3');
          }}
        />
      ),
    },
    {
      title: 'Abr',
      dataIndex: 'Abr',
      key: 'Abr',
      render: (data, key) => (
        <InputNumber
          name="Abr"
          formatter={key.key === '1' ? formatFuncVolume : formatFunc}
          parser={parserFunc}
          max={999999999999.99}
          maxLength={key.key === '1' ? 18 : 19}
          min={0}
          step="0.01"
          stringMode
          id={4}
          bordered={false}
          placeholder="0"
          onKeyDown={keyBlock}
          onChange={(e) => {
            handleMonths(e, key.type, '4');
          }}
        />
      ),
    },
    {
      title: 'May',
      dataIndex: 'May',
      key: 'May',
      render: (data, key) => (
        <InputNumber
          name="May"
          formatter={key.key === '1' ? formatFuncVolume : formatFunc}
          parser={parserFunc}
          max={999999999999.99}
          maxLength={key.key === '1' ? 18 : 19}
          min={0}
          step="0.01"
          stringMode
          id={5}
          bordered={false}
          placeholder="0"
          onKeyDown={keyBlock}
          onChange={(e) => {
            handleMonths(e, key.type, '5');
          }}
        />
      ),
    },
    {
      title: 'Jun',
      dataIndex: 'Jun',
      key: 'Jun',
      render: (data, key) => (
        <InputNumber
          name="Jun"
          formatter={key.key === '1' ? formatFuncVolume : formatFunc}
          parser={parserFunc}
          max={999999999999.99}
          maxLength={key.key === '1' ? 18 : 19}
          min={0}
          step="0.01"
          stringMode
          id={6}
          bordered={false}
          placeholder="0"
          onKeyDown={keyBlock}
          onChange={(e) => {
            handleMonths(e, key.type, '6');
          }}
        />
      ),
    },
  ];

  const secondSemesterColumns = [
    {
      title: 'Meses',
      dataIndex: 'metas',
      key: 'meses',
    },
    {
      title: 'Jul',
      dataIndex: 'Jul',
      key: 'Jul',
      render: (data, key) => (
        <InputNumber
          name="Jul"
          formatter={key.key === '1' ? formatFuncVolume : formatFunc}
          parser={parserFunc}
          max={999999999999.99}
          maxLength={key.key === '1' ? 18 : 19}
          min={0}
          step="0.01"
          stringMode
          id={7}
          bordered={false}
          placeholder="0"
          onKeyDown={keyBlock}
          onChange={(e) => {
            handleMonths(e, key.type, '7');
          }}
        />
      ),
    },
    {
      title: 'Ago',
      dataIndex: 'Ago',
      key: 'Ago',
      render: (data, key) => (
        <InputNumber
          name="Ago"
          formatter={key.key === '1' ? formatFuncVolume : formatFunc}
          parser={parserFunc}
          max={999999999999.99}
          maxLength={key.key === '1' ? 18 : 19}
          min={0}
          step="0.01"
          stringMode
          id={8}
          bordered={false}
          placeholder="0"
          onKeyDown={keyBlock}
          onChange={(e) => {
            handleMonths(e, key.type, '8');
          }}
        />
      ),
    },
    {
      title: 'Sep',
      dataIndex: 'Sep',
      key: 'Sep',
      render: (data, key) => (
        <InputNumber
          name="Sep"
          formatter={key.key === '1' ? formatFuncVolume : formatFunc}
          parser={parserFunc}
          max={999999999999.99}
          maxLength={key.key === '1' ? 18 : 19}
          min={0}
          step="0.01"
          stringMode
          id={9}
          bordered={false}
          placeholder="0"
          onKeyDown={keyBlock}
          onChange={(e) => {
            handleMonths(e, key.type, '9');
          }}
        />
      ),
    },
    {
      title: 'Oct',
      dataIndex: 'Oct',
      key: 'Oct',
      render: (data, key) => (
        <InputNumber
          name="Oct"
          formatter={key.key === '1' ? formatFuncVolume : formatFunc}
          parser={parserFunc}
          max={999999999999.99}
          maxLength={key.key === '1' ? 18 : 19}
          min={0}
          step="0.01"
          stringMode
          id={10}
          bordered={false}
          placeholder="0"
          onKeyDown={keyBlock}
          onChange={(e) => {
            handleMonths(e, key.type, '10');
          }}
        />
      ),
    },
    {
      title: 'Nov',
      dataIndex: 'Nov',
      key: 'Nov',
      render: (data, key) => (
        <InputNumber
          name="Nov"
          formatter={key.key === '1' ? formatFuncVolume : formatFunc}
          parser={parserFunc}
          max={999999999999.99}
          maxLength={key.key === '1' ? 18 : 19}
          min={0}
          step="0.01"
          stringMode
          id={11}
          bordered={false}
          placeholder="0"
          onKeyDown={keyBlock}
          onChange={(e) => {
            handleMonths(e, key.type, '11');
          }}
        />
      ),
    },
    {
      title: 'Dic',
      dataIndex: 'Dic',
      key: 'Dic',
      render: (data, key) => (
        <InputNumber
          name="Dic"
          formatter={key.key === '1' ? formatFuncVolume : formatFunc}
          parser={parserFunc}
          max={999999999999.99}
          maxLength={key.key === '1' ? 18 : 19}
          min={0}
          step="0.01"
          stringMode
          id={12}
          bordered={false}
          placeholder="0"
          onKeyDown={keyBlock}
          onChange={(e) => {
            handleMonths(e, key.type, '12');
          }}
        />
      ),
    },
  ];

  const data = [
    {
      key: '1',
      metas: 'Meta en volumen',
      type: 'volume',
    },
    {
      key: '2',
      metas: 'Meta en valor (USD)',
      type: 'usdAmount',
    },
  ];

  return (
    <Row align="top" justify="left">
      <Table
        columns={firstSemesterColumns}
        dataSource={data}
        pagination={false}
        bordered={true}
        scroll={{ x: 1500, y: 200 }}
        width={'100px'}
      />
      <Table
        columns={secondSemesterColumns}
        dataSource={data}
        pagination={false}
        bordered={true}
        scroll={{ x: 1500, y: 200 }}
      />
    </Row>
  );
}

export default SalesGoalsTable;
