/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  consultFiltersFamilyRequestOptions,
  consultFiltersFamilySetValue,
} from 'RootModule/redux/actions/consultFilters';
import { addAllBtnOnOptions, handleAllBtnClick } from 'common/consults/utils';
import Filter from 'common/consults/components/Filter';

const Family = (props) => {
  const {
    consultFiltersFamilyRequestOptions: requestOptions,
    consultFiltersFamilySetValue: setValue,
    value,
    required,
    options,
    params,
    disableSelectAll,
    span,
  } = props;

  let mappedOptions = [...options];

  let jsonParams = JSON.stringify(params);

  useEffect(() => {
    let sentParams = {};
    if (params) {
      sentParams = { ...params };
    }
    requestOptions(sentParams);
  }, [jsonParams]);

  const selectChange = (selected) => {
    selected = handleAllBtnClick(selected, options);
    setValue(selected);
  };
  if (!disableSelectAll) {
    mappedOptions = addAllBtnOnOptions(options);
  }
  return (
    <Filter
      name={'Familia'}
      required={required}
      value={value}
      selectChange={selectChange}
      options={mappedOptions}
      span={span}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    options: state?.APP?.consultFilters.family?.options ?? [],
    value: state?.APP?.consultFilters.family?.value,
  };
};

Family.propTypes = {
  consultFiltersFamilyRequestOptions: PropTypes.func,
  consultFiltersFamilySetValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  required: PropTypes.bool,
  options: PropTypes.array,
  params: PropTypes.object,
  disableSelectAll: PropTypes.bool,
};

Family.defaultProps = {
  // eslint-disable-next-line no-empty-function
  consultFiltersFamilyRequestOptions: () => {},
  // eslint-disable-next-line no-empty-function
  consultFiltersFamilySetValue: () => {},
  value: [],
  required: false,
  options: [],
  params: {},
  disableSelectAll: false,
};

export default connect(mapStateToProps, {
  consultFiltersFamilyRequestOptions,
  consultFiltersFamilySetValue,
})(Family);
