import {
  SET_VALIDATION_FIELDS_REQUEST,
  SET_VALIDATION_FIELDS_SUCCESS,
  SET_VALIDATION_FIELDS_FAIL,
} from '../actions/validationFields';

const initialState = { validationFields: 'salesChallenge' };

const validationFieldsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_VALIDATION_FIELDS_REQUEST:
      return {
        ...state,
        validationFields: payload,
      };
    case SET_VALIDATION_FIELDS_SUCCESS:
      return {
        ...state,
        validationFields: payload,
      };
    case SET_VALIDATION_FIELDS_FAIL:
      return {
        ...state,
        validationFields: '',
      };
    default:
      return state;
  }
};

export default validationFieldsReducer;
