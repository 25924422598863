import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form } from 'antd';
import PropTypes from 'prop-types';
import { Clients, Territory, Zones, Family, Products, Years } from 'RootModule/catalogs';
import { connect } from 'react-redux';
import { querySave, queryClean } from 'SalesChallengeRefactor/redux/actions/query';
import { setCurrentView } from 'SalesChallengeRefactor/redux/actions/views';
import { ResetButton } from '../../common';
import { cleanTopFilters } from '../../redux/actions/filters';
import useWasRendered from '../../hooks/useWasRendered';
import { cleanInfo } from '../../redux/actions/info';
import { cleanIndicators } from 'SalesChallengeRefactor/redux/actions/indicators';
import { addMessageValue } from 'SalesChallengeRefactor/redux/actions/messages';
import {
  getProductsRequest,
  getChallengePogRequest,
} from 'SalesChallengeRefactor/redux/actions/registerSale';
import DownloadButton from 'common/components/DownloadButton';
import { reportRequest } from 'SalesChallengeRefactor/redux/actions/reports';
import { openNotification } from 'common/misc/openNotification';
import moment from 'moment';

const layoutFilters = {
  xs: 8,
  sm: 8,
  md: 8,
  lg: 4,
  xl: 4,
};

const reportSources = Object.freeze({
  CLIENT: 'Kam',
  TERRITORY: 'Territory',
  ZONE: 'Client',
  FAMILY: 'Family',
  PRODUCT: 'Product',
});

function TopFiltersGroupKAM(props) {
  const {
    querySave: saveQuery,
    setCurrentView: setView,
    queryClean: cleanQuery,
    cleanTopFilters: cleanFilters,
    cleanInfo: infoClean,
    cleanIndicators: indicatorsClean,
    addMessageValue: saveLabelToStore,
    getChallengePogRequest: getPog,
    getProductsRequest: getProduct,
    reportRequest: reportReq,
    isLoadingReport,
    onChangeActiveComponent,
  } = props;

  const { wasRendered, setWasRendered } = useWasRendered();

  const [form] = Form.useForm();

  const initialDisabledFilters = {
    year: false,
    clientId: false,
    territoryId: true,
    zoneId: true,
    family: true,
    productId: true,
  };

  const initialAllowedRequests = {
    year: true,
    clientId: true,
    territoryId: false,
    zoneId: false,
    family: false,
    productId: false,
  };

  const initialSubscriptions = {
    year: {},
    clientId: { forRegister: false },
    territoryId: {},
    zoneId: {},
    family: { forRegister: false },
    productId: { forRegister: false },
    report: { scopeHasReport: false },
  };

  const [disabledFilters, setDisabledFilters] = useState({
    ...initialDisabledFilters,
  });

  const [allowRequest, setAllowRequests] = useState({
    ...initialAllowedRequests,
  });

  const [subscriptions, setSubcriptions] = useState({
    ...initialSubscriptions,
  });

  const filtersNameEnum = {
    YEAR: 'year',
    CLIENT: 'clientId',
    TERRITORY: 'territoryId',
    FAMILY: 'family',
    PRODUCT: 'productId',
    ZONE: 'zoneId',
  };

  const cleanAllFilters = useCallback(() => {
    cleanFilters();
  }, [cleanFilters]);

  useEffect(() => {
    if (!wasRendered.current) {
      saveQuery({ state: 'idle', showBruteValue: true });
      cleanAllFilters();
      setWasRendered();
    }
  }, [saveQuery, wasRendered, setWasRendered, cleanAllFilters]);

  const handleOnValuesChange = ({ changedValues, allValues }) => {
    const changedValueKey = Object.keys(changedValues)[0];
    const { ZONE, CLIENT, FAMILY, PRODUCT, YEAR, TERRITORY } = filtersNameEnum;
    const { setFieldsValue } = form;
    onChangeActiveComponent('sales-challenge');
    switch (changedValueKey) {
      case YEAR:
        setDisabledFilters({
          year: false,
          clientId: false,
          territoryId: true,
          zoneId: true,
          family: true,
          productId: true,
        });
        setFieldsValue({
          clientId: undefined,
          territoryId: undefined,
          zoneId: undefined,
          family: undefined,
          productId: undefined,
        });
        setAllowRequests({
          year: false,
          clientId: true,
          territoryId: false,
          zoneId: false,
          family: false,
          productId: false,
        });
        setSubcriptions({
          ...subscriptions,
          territoryId: { ...subscriptions.territoryId, year: allValues[YEAR] },
          zoneId: { ...subscriptions.zoneId, year: allValues[YEAR] },
          clientId: { ...subscriptions.clientId, year: allValues[YEAR] },
          report: { scopeHasReport: false },
        });
        break;

      case CLIENT:
        setDisabledFilters({
          clientId: false,
          territoryId: false,
          zoneId: true,
          family: true,
          productId: true,
        });
        setFieldsValue({
          territoryId: undefined,
          zoneId: undefined,
          family: undefined,
          productId: undefined,
        });
        setAllowRequests({
          year: false,
          clientId: false,
          territoryId: true,
          zoneId: false,
          family: false,
          productId: false,
        });
        setSubcriptions({
          ...subscriptions,
          territoryId: { clientId: allValues[CLIENT], year: allValues[YEAR] },
          report: {
            ...subscriptions.report,
            scopeHasReport: true,
            sourceId: reportSources.CLIENT,
            year: allValues[YEAR],
            clientId: allValues[CLIENT],
          },
        });
        break;

      case TERRITORY:
        setDisabledFilters({
          year: false,
          clientId: false,
          territoryId: false,
          zoneId: false,
          family: true,
          productId: true,
        });
        setFieldsValue({
          zoneId: undefined,
          family: undefined,
          productId: undefined,
        });
        setAllowRequests({
          year: false,
          clientId: false,
          territoryId: false,
          zoneId: true,
          family: false,
          productId: false,
        });
        setSubcriptions({
          ...subscriptions,
          zoneId: {
            territoryId: allValues[TERRITORY],
            clientId: allValues[CLIENT],
            year: allValues[YEAR],
          },
          report: {
            ...subscriptions.report,
            sourceId: reportSources.TERRITORY,
            territoryId: allValues[TERRITORY],
            year: allValues[YEAR],
            clientId: allValues[CLIENT],
          },
        });
        break;

      case ZONE:
        setDisabledFilters({
          year: false,
          clientId: false,
          territoryId: false,
          zoneId: false,
          family: false,
          productId: true,
        });
        setFieldsValue({
          family: undefined,
          productId: undefined,
        });
        setAllowRequests({
          year: false,
          clientId: false,
          territoryId: false,
          zoneId: false,
          family: true,
          productId: false,
        });
        setSubcriptions({
          ...subscriptions,
          family: { ...subscriptions.family, clientId: allValues[CLIENT], zoneId: allValues[ZONE] },
          report: {
            ...subscriptions.report,
            sourceId: reportSources.ZONE,
            clientId: allValues[CLIENT],
            zoneId: allValues[ZONE],
            year: allValues[YEAR],
          },
        });
        break;

      case FAMILY:
        setDisabledFilters({
          year: false,
          clientId: false,
          territoryId: false,
          zoneId: false,
          family: false,
          productId: false,
        });
        setFieldsValue({
          productId: undefined,
        });
        setAllowRequests({
          year: false,
          clientId: false,
          territoryId: false,
          zoneId: false,
          family: true,
          productId: true,
        });
        setSubcriptions({
          ...subscriptions,
          productId: {
            ...subscriptions.productId,
            clientId: allValues[CLIENT],
            territoryId: allValues[TERRITORY],
            zoneId: allValues[ZONE],
            family: allValues[FAMILY],
            year: allValues[YEAR],
          },
          report: {
            ...subscriptions.report,
            sourceId: reportSources.FAMILY,
            year: allValues[YEAR],
            clientId: allValues[CLIENT],
            family: allValues[FAMILY],
          },
        });
        break;

      case PRODUCT:
        setSubcriptions({
          ...subscriptions,
          report: {
            ...subscriptions.report,
            sourceId: reportSources.PRODUCT,
            clientId: allValues[CLIENT],
            productId: allValues[PRODUCT],
          },
        });

        getProduct({
          params: {
            zoneId: allValues[ZONE],
            clientId: allValues[CLIENT],
            productId: allValues[PRODUCT],
          },
        });

        getPog({
          params: {
            zoneId: allValues[ZONE],
            clientId: allValues[CLIENT],
            productId: allValues[PRODUCT],
            year: allValues[YEAR],
          },
          isConsult: true,
        });
        break;

      default:
        console.warn('[Agroplan] No actions for this filter >>', changedValueKey);
        break;
    }
    setView(changedValueKey);
    //Sahring this part of the query with other components
    const queryValues = form.getFieldsValue();

    saveQuery({ ...queryValues, state: 'changed' });
  };

  const commonFilterProps = {
    allowClear: false,
  };

  const handleResetButton = () => {
    form.resetFields();
    setDisabledFilters({
      ...initialDisabledFilters,
    });

    setAllowRequests({
      ...initialAllowedRequests,
    });

    setSubcriptions({
      ...initialSubscriptions,
    });
    const formValues = form.getFieldsValue();
    cleanQuery({ state: 'idle', year: formValues.year, showBruteValue: true });
    cleanAllFilters();
    infoClean();
    indicatorsClean();
  };

  const setAutomaticallyFirstOption = (options, filterName = 'nonde') => {
    if (options?.length > 0) {
      form.setFieldsValue({
        [filterName]: options[0]?.key,
      });
      const allValues = form.getFieldsValue();
      const changedValues = { [filterName]: options[0]?.key };
      handleOnValuesChange({ changedValues, allValues });
    }
  };

  const saveLabel = (_, option, labelName = 'bSelect') => {
    saveLabelToStore({ [labelName]: option?.children ?? '' });
  };

  const handleDownloadReport = () => {
    const reportName = moment(new Date()).format('DD-MM-YYYY');
    const requestParamsOfReport = {
      ...subscriptions.report,
      name: reportName,
    };
    reportReq({
      sourceId: subscriptions?.report?.sourceId,
      params: requestParamsOfReport,
      onSuccess: () => openNotification('success', 'Reporte generado correctamente'),
    });
  };

  return (
    <>
      <Form
        form={form}
        onValuesChange={(changedValues, allValues) =>
          handleOnValuesChange({ changedValues, allValues })
        }
        layout="horizontal"
        style={{ display: 'flex', justifyContent: 'start' }}
      >
        <Row>
          <Col {...layoutFilters}>
            <Years
              disabled={disabledFilters.year}
              askFromService={allowRequest.year}
              sourceId="Sales"
              requestParams={subscriptions.year}
              onSuccess={(options) => setAutomaticallyFirstOption(options, 'year')}
              {...commonFilterProps}
            />
          </Col>
          <Col {...layoutFilters}>
            <Clients
              disabled={disabledFilters.clientId}
              askFromService={allowRequest.clientId}
              sourceId="KAM"
              requestParams={subscriptions.clientId}
              onChange={(value, option) => saveLabel(value, option, filtersNameEnum.CLIENT)}
              {...commonFilterProps}
            />
          </Col>
          <Col {...layoutFilters}>
            <Territory
              disabled={disabledFilters.territoryId}
              askFromService={allowRequest.territoryId}
              sourceId="KAM"
              requestParams={subscriptions.territoryId}
              onChange={(value, option) => saveLabel(value, option, filtersNameEnum.TERRITORY)}
              {...commonFilterProps}
            />
          </Col>
          <Col {...layoutFilters}>
            <Zones
              disabled={disabledFilters.zoneId}
              askFromService={allowRequest.zoneId}
              sourceId="KAM"
              requestParams={subscriptions.zoneId}
              onChange={(value, option) => saveLabel(value, option, filtersNameEnum.ZONE)}
              {...commonFilterProps}
            />
          </Col>
          <Col {...layoutFilters}>
            <Family
              disabled={disabledFilters.family}
              askFromService={allowRequest.family}
              sourceId="Sales"
              requestParams={subscriptions.family}
              onChange={(value, option) => saveLabel(value, option, filtersNameEnum.FAMILY)}
              {...commonFilterProps}
            />
          </Col>

          <Col {...layoutFilters}>
            <Products
              disabled={disabledFilters.productId}
              askFromService={allowRequest.productId}
              sourceId="Sales"
              requestParams={subscriptions.productId}
              {...commonFilterProps}
            />
          </Col>
        </Row>
      </Form>
      <Row gutter={32} justify="end">
        <Col>
          <ResetButton onClick={handleResetButton} />
        </Col>

        {subscriptions?.report?.scopeHasReport ? (
          <Col>
            <DownloadButton loading={isLoadingReport} onClick={handleDownloadReport} />
          </Col>
        ) : null}
      </Row>
    </>
  );
}

TopFiltersGroupKAM.propTypes = {
  intialValues: PropTypes.object,
};

TopFiltersGroupKAM.defaultProps = {
  intialValues: {},
};

function mapStateToProps(state) {
  return {
    isLoadingReport: state?.SALES_CHALLENGE?.report?.isDownloading,
  };
}

export default connect(mapStateToProps, {
  querySave,
  setCurrentView,
  queryClean,
  cleanTopFilters,
  cleanInfo,
  cleanIndicators,
  addMessageValue,
  getProductsRequest,
  getChallengePogRequest,
  reportRequest,
})(TopFiltersGroupKAM);
