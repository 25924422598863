/* istanbul ignore file */
import React, { useState, useContext } from 'react';
import { Form, Modal, Input } from 'antd';
import { accessBackdoor } from '../services';
import { appContext } from '../../context';

function SessionKiller() {
  const { onLogin } = useContext(appContext);
  const [visible, setVisible] = useState(false);
  const [isAccessingBackDoor, setIsAccessingBackDoor] = useState(false);
  const [form] = Form.useForm();

  document.addEventListener('keydown', function (event) {
    if (event.ctrlKey && event.altKey && event.key === 'k') {
      setVisible(true);
    }
  });

  const openBackDoor = (formvalues) => {
    setIsAccessingBackDoor(true);
    accessBackdoor(formvalues.user)
      .then((value) => {
        setVisible(false);
        setIsAccessingBackDoor(false);
        onLogin(value.permissions, value.user);
      })
      .catch((err) => {
        setIsAccessingBackDoor(false);
        console.error(err);
      });
  };

  const handleOnOK = () => {
    form
      .validateFields()
      .then((values) => {
        openBackDoor(values);
      })
      .catch((_info) => {
        return;
      });
  };

  const handleOnCancel = () => {
    setVisible(false);
  };
  // Cambio de prueba

  return (
    <Modal
      title="Solo personal autorizado"
      okText="OK"
      cancelText="Cancelar"
      visible={visible}
      onOk={handleOnOK}
      onCancel={handleOnCancel}
      confirmLoading={isAccessingBackDoor}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="user"
          label="Usuario"
          rules={[
            {
              required: true,
              message: 'Por favor anexe un correo',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default SessionKiller;
