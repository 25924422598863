function DawerEdit(state = {}, action) {
  const { type, payload } = action;

  const REDUCER = {
    // Array imagenes Base64
    INPUT_EDIT_IMAGES_SET: {
      inputEditImages: payload,
    },
    // Datos previos de la tabla al Drawer Edit
    DATA_DRAWER_EDIT_SET: {
      dataDrawerEdit: payload,
    },
    // PUT Image Line
    PUT_IMAGE_LINE_REQUEST: {
      isLoading: true,
      Type: payload,
    },
    PUT_IMAGE_LINE_SET: {
      PUTImageLine: payload,
    },
    PUT_IMAGE_LINE_CLEAN: {},
  };

  return { ...state, isLoading: false, ...REDUCER[type] };
}

export default DawerEdit;
