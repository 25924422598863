import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Territory, SubRegion, Zones, BusinessLine, Country, Companies } from 'RootModule/catalogs';

const CommercialStructure = ({ form }) => {
  const [dataCE, setdataCE] = useState([]);

  //Delete sub Inputs
  const fieldsLineId = () => {
    if (dataCE.subregionId) {
      form.setFieldsValue({
        subregionId: undefined,
        territoryId: undefined,
        zoneId: undefined,
        countryId: undefined,
      });
    }
  };
  const fieldsSubRegionId = () => {
    if (dataCE.territoryId) {
      form.setFieldsValue({
        territoryId: undefined,
        zoneId: undefined,
        countryId: undefined,
      });
    }
  };
  const fieldsTerritoryId = () => {
    if (dataCE.territoryId) {
      form.setFieldsValue({
        zoneId: undefined,
        countryId: undefined,
      });
    }
  };
  const fieldsZoneId = () => {
    if (dataCE.zoneId) {
      form.setFieldsValue({
        countryId: undefined,
      });
    }
  };

  const deleteInputs = (data) => {
    if (!data.lineId) {
      fieldsLineId();
    }
    if (!data.subregionId) {
      fieldsSubRegionId();
    }
    if (!data.territoryId) {
      fieldsTerritoryId();
    }
  };

  const handleChange = (e) => {
    let data = form.getFieldValue();
    deleteInputs(data);
    setdataCE(data);
  };

  return (
    <div>
      <Row>
        <Col span={8}>
          <BusinessLine
            isRequired
            className="form-add-user-input"
            label="Línea de negocio"
            askFromService={true}
            onChange={handleChange}
            onSelect={fieldsLineId}
            disabled={true}
          />
        </Col>

        <Col span={8}>
          <SubRegion
            isRequired
            name="subregionId"
            onChange={handleChange}
            onSelect={fieldsSubRegionId}
            requestParams={null}
            disabled={true}
          />
        </Col>

        <Col span={8}>
          <Territory
            isRequired
            onChange={handleChange}
            onSelect={fieldsTerritoryId}
            requestParams={null}
            disabled={true}
          />
        </Col>

        <Col span={8}>
          <Zones
            isRequired
            onChange={handleChange}
            onSelect={fieldsZoneId}
            requestParams={null}
            disabled={true}
          />
        </Col>

        <Col span={8}>
          <Country
            isRequired
            onChange={handleChange}
            onSelect={fieldsZoneId}
            requestParams={null}
            disabled={true}
          />
        </Col>

        <Col span={8}>
          <Companies
            name="companyId"
            label="Compañía"
            isRequired
            onChange={handleChange}
            requestParams={null}
            disabled={true}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CommercialStructure;
