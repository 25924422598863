import { request } from '../../services';

export async function getAreas() {
  return request.get(`/Catalogs/Api/v1/FixedCatalogs/Areas`).then((response) => {
    return response.data.map((element) => {
      return {
        id: element.key,
        name: element.value,
      };
    });
  });
}
