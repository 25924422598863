import React, { useEffect, useState } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import BoxComponent from '../BoxComponent/BoxComponent';
import { connect } from 'react-redux';
import { roleIdEnum, salesChallengeStatus } from 'common/models/enums';
//import { getMonthSales } from '../Services'

function MonthsByZone(props) {
  const { graphData, zoneStatus } = props;
  const localData = JSON.parse(localStorage.getItem('user'));
  const { role } = localData;
  const [months, setMonths] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  let data = {
    labels: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Novimebre',
      'Diciembre',
    ],
    datasets: [
      {
        backgroundColor: '#003d81',
        hoverBackgroundColor: '#003d81',
        data: months,
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    dataset: {
      barPercentage: 0.4,
      gridLines: {
        display: false,
      },
      ticks: {
        mirror: true,
        padding: 80,
      },
    },
    layout: {
      padding: {
        left: 80,
      },
    },
    width: 100,
    tooltips: {
      callbacks: {
        title: function () {
          return null;
        },
        label: function (tooltipItems /*, data */) {
          if (
            (role.roleId === roleIdEnum.SALES_AGENT &&
              zoneStatus === salesChallengeStatus.APPROVED) ||
            role.roleId !== roleIdEnum.SALES_AGENT
          ) {
            return `$ ${tooltipItems.xLabel.toLocaleString('es-MX', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`;
          }
        },
      },
    },
    scales: {
      xAxes: [
        {
          display: false,
        },
      ],
    },
  };

  useEffect(() => {
    setMonths(graphData);
  }, [graphData]);

  return (
    <BoxComponent
      title="Total venta neta mensual (USD)"
      width={100}
      backgroundColorTitle="#0078B3"
      backgroundColor="#ffffff"
    >
      <HorizontalBar width={100} height={50} data={data} options={options} />
    </BoxComponent>
  );
}

function mapStateToProps(state) {
  return {
    zoneStatus: state.SALES_CHALLENGE?.indicators?.zoneData?.statusId,
  };
}

export default connect(mapStateToProps, {})(MonthsByZone);
