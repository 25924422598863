/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Select } from 'antd';
const { Option } = Select;

const DummyTerritories = [
  { key: 1, value: 'Territorio 1' },
  { key: 2, value: 'Territorio 2' },
  { key: 3, value: 'Territorio 3' },
];
const DummyResponsable = [
  { key: 1, value: 'Responsable 1' },
  { key: 2, value: 'Responsable 2' },
];
const newTerritory = {
  territoryId: undefined,
  responsable: undefined,
};

const Territory = ({ territoryId, responsableId, onChange }) => {
  const [territories, setTerritories] = useState(DummyTerritories);
  const [responsables, setResponsables] = useState(DummyResponsable);
  const [value, setValue] = useState(undefined);
  const handleChange = (selected) => {
    setValue({ ...value, ...selected });
    onChange({ ...value, ...selected });
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'start' }}>
      <Select
        value={territoryId}
        onChange={(e) => handleChange({ territoryId: e })}
        style={{ width: '200px' }}
      >
        {territories &&
          territories.map((territory) => <Option value={territory.key}>{territory.value}</Option>)}
      </Select>
      <Select
        value={responsableId}
        onChange={(e) => handleChange({ responsableId: e })}
        style={{ width: '200px' }}
      >
        {responsables &&
          responsables.map((responsable) => (
            <Option value={responsable.key}>{responsable.value}</Option>
          ))}
      </Select>
    </div>
  );
};
function ResponsableInformation({ formValues, handleAction }) {
  const [territories, setTerritories] = useState([newTerritory]);
  const handleAdd = () => {
    setTerritories([...territories, newTerritory]);
  };
  const handleOnChange = (key, value) => {
    let items = [...territories];
    items[key] = value;
    setTerritories(items);
    handleAction({ name: 'territories', value: items });
  };
  return (
    <div style={{ display: 'block' }}>
      {territories?.length >= 0 &&
        territories.map((territory, key) => (
          <Territory
            territoryId={territory.territoryId}
            responsableId={territory.responsable}
            onChange={(e) => handleOnChange(key, e)}
          />
        ))}
      <button onClick={handleAdd}>+</button>
    </div>
  );
}

export default ResponsableInformation;
