import { ocupationCreditLines } from '../../actions';

const {
  OCL_SUBREGIONS_REQUEST,
  OCL_SUBREGIONS_SET,
  OCL_SUBREGIONS_CLEAN,
  OCL_CLIENTS_REQUEST,
  OCL_CLIENTS_SET,
  OCL_CLIENTS_CLEAN,
} = ocupationCreditLines;

const ocupationCreditLinesReducers = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case OCL_SUBREGIONS_REQUEST:
      return {
        ...state,
        oclSubregionsData: null,
      };
    case OCL_SUBREGIONS_SET:
      return {
        ...state,
        oclSubregionsData: payload,
      };
    case OCL_SUBREGIONS_CLEAN:
      return {
        ...state,
        oclSubregionsData: [],
      };
    case OCL_CLIENTS_REQUEST:
      return {
        ...state,
        oclClientsData: null,
      };
    case OCL_CLIENTS_SET:
      return {
        ...state,
        oclClientsData: payload,
      };
    case OCL_CLIENTS_CLEAN:
      return {
        ...state,
        oclClientsData: [],
      };

    default:
      return state;
  }
};

export default ocupationCreditLinesReducers;
