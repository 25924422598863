import reducer from './redux-saga/reducers';

import {
  watchInfoPOGMassiveUploadSetAllowUpload,
  watchInfoPOGMassiveUploadRequestVerify,
  watchInfoPOGMassiveUploadRequestUpload,
  watchExistsStatements,
} from './redux-saga/sagas';

export default function getInfoPOGMassiveUploadModule() {
  return {
    id: 'InfoPOG_MASSIVE_UPLOAD',
    reducerMap: {
      InfoPOG_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchInfoPOGMassiveUploadSetAllowUpload,
      watchInfoPOGMassiveUploadRequestVerify,
      watchInfoPOGMassiveUploadRequestUpload,
      watchExistsStatements,
    ],
  };
}
