import React from 'react';
import { Modal, Spin } from 'antd';
import { IconsMap } from '../../../Layout/utils';
import PropTypes from 'prop-types';
import './DeleteProductModal.css';
import { connect } from 'react-redux';
const ExclamationIcon = IconsMap.ExclamationCircleOutlined;
const DeleteProductModal = (props) => {
  const {
    onOk,
    onCancel,
    visible,
    label,

    okIsLoading,
    okIsDisabled,
    validProductsForDeleting,
    loadingValidProducts,
  } = props;

  //const [form] = Form.useForm();

  const message = `¿Está seguro que desea eliminar el producto del ${label}?`;
  const messageaddOn =
    validProductsForDeleting === 1
      ? 'Considere que si este producto es el único asociado al acuerdo comercial, el registro del acuerdo comercial se eliminará también del sistema.'
      : '';
  const modalMessageContent = loadingValidProducts ? (
    <Spin />
  ) : (
    <p>
      {message}
      <br />
      <br />
      <b>{messageaddOn}</b>
    </p>
  );
  // useEffect(() => {
  //   form.setFieldsValue({ products: [0] });
  // }, [form]);

  return (
    <Modal
      className="modal-add-product"
      onOk={onOk}
      onCancel={onCancel}
      visible={visible}
      okButtonProps={{
        loading: okIsLoading || loadingValidProducts,
        disabled: okIsDisabled || loadingValidProducts,
      }}
      width={600}
      destroyOnClose={true}
    >
      <div className="header-modal">
        <ExclamationIcon style={{ fontSize: '1.5rem', alignSelf: 'center' }} />
        <p>Eliminar producto de {label} </p>
      </div>

      <div className="body-modal">{modalMessageContent}</div>
    </Modal>
  );
};

DeleteProductModal.displayName = 'DeleteProductModal';

DeleteProductModal.propTypes = {
  label: PropTypes.oneOf(['acuerdo comercial', 'desafío de ventas']),
};

DeleteProductModal.defaultProps = {
  label: 'acuerdo comercial',
};
//TRADE_AGREEMENTS.validProductsForDeleting.total
const mapStateToProps = (state) => {
  return {
    validProductsForDeleting: state?.TRADE_AGREEMENTS?.validProductsForDeleting?.total ?? 0,
    loadingValidProducts: state?.TRADE_AGREEMENTS?.validProductsForDeleting?.loading ?? false,
  };
};
const ConnectedDeleteProductModal = connect(mapStateToProps, {})(DeleteProductModal);

export default ConnectedDeleteProductModal;
