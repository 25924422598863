/* eslint-disable react/display-name */
import React from 'react';
import { formatCommas } from 'utils';
import { formatPercentage } from '../utils';

const annualSchema = [
  {
    title: 'Sold to',
    key: 'soldTo',
    dataIndex: 'soldTo',
  },
  {
    title: 'Cliente',
    key: 'client',
    dataIndex: 'client',
  },
  {
    title: 'Línea de negocio',
    key: 'line',
    dataIndex: 'line',
  },
  {
    title: 'Segmentación',
    key: 'segmentation',
    dataIndex: 'segmentation',
  },
  {
    title: 'Año',
    key: 'year',
    dataIndex: 'year',
  },
  {
    title: 'Objetivo anual',
    key: 'amount',
    dataIndex: 'amount',
    render: (amount) => <>{amount ? formatCommas(amount) : ''}</>,
  },

  {
    title: 'Real',
    key: 'real',
    dataIndex: 'real',
    render: (real) => <>{real ? formatCommas(real) : ''}</>,
  },
  {
    title: '% Alcance',
    key: 'reach',
    dataIndex: 'reach',
    render: (reach) => <>{reach ? formatPercentage(reach) : ''}</>,
  },
  {
    title: '% Incentivo',
    key: 'incentive',
    dataIndex: 'incentive',
    render: (incentive) => <>{incentive ? formatPercentage(incentive) : ''}</>,
  },
  {
    title: 'Incentivo ganado',
    key: 'winIncentive',
    dataIndex: 'winIncentive',
    render: (winIncentive) => <>{winIncentive ? formatCommas(winIncentive) : ''}</>,
  },
  {
    title: 'Periodo de corte',
    key: 'cutPeriod',
    dataIndex: 'cutPeriod',
  },
];
export default annualSchema;
