import reducer from './redux-saga/reducers';

import {
  watchProcessDatesMassiveUploadSetAllowUpload,
  watchProcessDatesMassiveUploadRequestVerify,
  watchProcessDatesMassiveUploadRequestUpload,
} from './redux-saga/sagas';

export default function getProcessDatesMassiveUploadModule() {
  return {
    id: 'PROCESS_DATES_MASSIVE_UPLOAD',
    reducerMap: {
      PROCESS_DATES_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchProcessDatesMassiveUploadSetAllowUpload,
      watchProcessDatesMassiveUploadRequestVerify,
      watchProcessDatesMassiveUploadRequestUpload,
    ],
  };
}
