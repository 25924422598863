import { put, call, cancelled, takeEvery } from 'redux-saga/effects';
import instance from '../../../services/request';
import {
  SALES_CHALLENGE_TERRITORY_VALIDATOR_REQUEST,
  salesChallengeTerritoryValidatorSuccess,
  salesChallengeTerritoryValidatorFail,
} from '../actions/salesChallengeTerritoryValidator';
import { SalesChallengeTerritoryValidator } from '../../services/salesChallengeTerritoryValidator';
import { openNotification } from 'common/misc/openNotification';
import { responseOK } from 'utils';

function* workSalesChallengeTerritoryValidator(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { territoryId, setIsValidator } = payload;
    const response = yield call(SalesChallengeTerritoryValidator, territoryId, source.token);
    if (responseOK(response)) {
      if (typeof setIsValidator === 'function') setIsValidator(response.data);
      yield put(salesChallengeTerritoryValidatorSuccess(response.data));
      //obtener validadores
    } else {
      openNotification(
        'error',
        response.message ? response.message : 'No se pudo realizar la operación'
      );
    }
  } catch (error) {
    console.error('[Redux-Saga-Basf]', error);
    yield put(salesChallengeTerritoryValidatorFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchSalesChallengeValidatorConfirm() {
  yield takeEvery(
    SALES_CHALLENGE_TERRITORY_VALIDATOR_REQUEST,
    workSalesChallengeTerritoryValidator
  );
}
