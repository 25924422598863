import { useState, useEffect } from 'react';

const useClients = (data) => {
  const renderClientsTable = (array) => {
    const newArray = [];
    array.forEach((item, index) => {
      newArray.push({
        key: index,
        clientId: item.clientId,
        soldTo: item.soldTo,
        lineId: item.lineId.toString(),
        lineName: item?.line ? item.line : '',
        active: item.active,
        title: item.clientName,
        description: item.clientName,
      });
    });
    return newArray;
  };

  const [clientsData, setClientsData] = useState([]);
  const [activeClients, setActiveClients] = useState([]);
  const [inactiveClients, setInactiveClients] = useState([]);

  useEffect(() => {
    setClientsData(data ? renderClientsTable(data) : []);
  }, [data]);

  useEffect(() => {
    setActiveClients(clientsData);
    const clientsInact = clientsData.filter((item) => item.active === 0);
    setInactiveClients(clientsInact.map((item) => item.key));
  }, [clientsData]);

  return [activeClients, inactiveClients, setInactiveClients];
};

export default useClients;
