import { SET_SUBSCRIPTION_VALUE_4_REGISTER } from '../actions/subscriptions';

const query = (state = {}, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTION_VALUE_4_REGISTER:
      return {
        ...state,
        register: {
          ...state.register,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};
export default query;
