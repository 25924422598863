import {
  DOWNLOAD_SALES_CHALLENGE_REPORT_REQUEST,
  DOWNLOAD_SALES_CHALLENGE_REPORT_SUCCESS,
  DOWNLOAD_SALES_CHALLENGE_REPORT_FAIL,
} from '../actions/downloadSalesChallengeReport';

const initialState = {};

const downloadSalesChallengeReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_SALES_CHALLENGE_REPORT_REQUEST:
      return {
        ...state,
        getSalesChallengeReport: {
          isLoading: true,
        },
      };

    case DOWNLOAD_SALES_CHALLENGE_REPORT_SUCCESS:
      return {
        ...state,
        getSalesChallengeReport: {
          isLoading: false,
        },
      };
    case DOWNLOAD_SALES_CHALLENGE_REPORT_FAIL:
      return {
        ...state,
        getSalesChallengeReport: {
          isLoading: false,
        },
      };

    default:
      return state;
  }
};

export default downloadSalesChallengeReducer;
