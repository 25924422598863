import { takeLatest, all, call, put, cancelled } from 'redux-saga/effects';
import { notification } from 'antd';

import {
  SEND_ACCOUNT_STATEMENT_REQUEST,
  sendAccountStatementSet,
  sendAccountStatementClean,
  CONSULT_INFO_BASE_REQUEST,
  consultInfoBaseSetTable,
  consultInfoBaseCleanTable,
  REPORT_REQUEST,
  reportSet,
  reportClean,
  CHECK_EXISTENCES_STATEMENT_REQUEST,
  checkExistencesStatementSet,
  checkExistencesStatementClean,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';
import {
  AccountStatusConsult,
  checkExistDataPog,
  AccountStatusSend,
  ReportConsult,
  requestExistences,
} from '../../services';
import { messageError } from 'utils/messages';
import { openNotification } from 'common';

function* workConsultReport(action) {
  const { params } = action.payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const response = yield call(ReportConsult, {
      cancelToken: source.token,
      params,
    });

    if (response.Data) {
      if (response.Data.length === 0) {
        yield call(
          messageError,
          'El envío del estado de cuenta no puede realizarse porque el cliente seleccionado no cuenta con contactos de estado de cuenta registrados'
        );
        yield put(reportClean());
      } else yield put(reportSet(response.Data[0]));
    }
  } catch (error) {
    yield put(reportClean());
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield put(reportClean());
      source.cancel('cancelled Request');
    }
  }
}

export function* watchConsultReport() {
  yield all([takeLatest(REPORT_REQUEST, workConsultReport)]);
}

function* workConsultInfoBaseTable(action) {
  const { params, history } = action.payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  let mustRedirectToHome = false;
  try {
    const response = yield call(AccountStatusConsult, {
      cancelToken: source.token,
      params,
    });

    const responseIfData = yield call(checkExistDataPog, {
      cancelToken: source.token,
    });

    if (responseOK(responseIfData)) {
      if (responseIfData.data === false) {
        yield call(notification.destroy);
        yield call(messageError, 'No hay registros de POG en el sistema');
        setTimeout(() => {
          history.push('/account-status/base-information');
        }, 300);
      }
    }

    if (responseOK(response)) {
      yield put(consultInfoBaseSetTable(response.data));
    } else {
      yield put(consultInfoBaseCleanTable());
    }
  } catch (error) {
    yield consultInfoBaseCleanTable();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield consultInfoBaseCleanTable();
      source.cancel('cancelled Request');
    }
    if (mustRedirectToHome) {
      yield call(notification.destroy);
      yield call(messageError, 'No hay registros de POG en el sistema');
      yield call(history.push, '/account-status/base-information');
    }
  }
}

export function* watchConsultInfoBaseTable() {
  yield all([takeLatest(CONSULT_INFO_BASE_REQUEST, workConsultInfoBaseTable)]);
}

function* workSendAccountStatus(action) {
  const { params, history } = action.payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  let mustRedirectToHome = false;
  try {
    const response = yield call(AccountStatusSend, {
      cancelToken: source.token,
      params,
    });
    if (responseOK(response)) {
      yield put(sendAccountStatementSet(response.data));
      let messageType = response.data.value === true ? 'success' : 'error';
      openNotification(messageType, response.message);
    } else {
      yield put(sendAccountStatementClean());
    }
  } catch (error) {
    yield sendAccountStatementClean();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield sendAccountStatementClean();
      source.cancel('cancelled Request');
    }
    if (mustRedirectToHome) {
      yield call(notification.destroy);
      yield call(messageError, 'No hay registros de POG en el sistema');
      yield call(history.push, '/account-status/base-information');
    }
  }
}

export function* watchSendAccountStatus() {
  yield all([takeLatest(SEND_ACCOUNT_STATEMENT_REQUEST, workSendAccountStatus)]);
}

function* workCheckExistences(action) {
  const { params } = action.payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  let mustRedirectToHome = false;
  try {
    const response = yield call(requestExistences, {
      cancelToken: source.token,
      params,
    });
    if (responseOK(response)) {
      yield put(checkExistencesStatementSet(response.data));
    } else {
      yield put(checkExistencesStatementClean());
    }
  } catch (error) {
    yield checkExistencesStatementClean();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield checkExistencesStatementClean();
      source.cancel('cancelled Request');
    }
    if (mustRedirectToHome) {
      yield call(notification.destroy);
    }
  }
}

export function* watchCheckExistences() {
  yield all([takeLatest(CHECK_EXISTENCES_STATEMENT_REQUEST, workCheckExistences)]);
}
