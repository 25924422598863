/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import {
  BusinessLine,
  Year,
  Segmentation,
  PogPeriodicity,
  PogPeriod,
  SalesPeriodicity,
  SalesPeriod,
  ObjectivesAndIncentives,
} from 'RootModule/consultFilters/';

import ConsultTable from 'common/consults/components/Table';
import FilterWrapper from 'common/consults/components/FilterWrapper';
import SearchInput from 'common/consults/components/SearchInput';
import ResetButton from 'common/consults/components/ResetButton';

import getAccountStatusTradeAgreementGoalsModule from '../getAccountStatusTradeAgreementGoalsModule';

import {
  accountStatusTradeAgreementGoalsRequestConsultTable,
  accountStatusTradeAgreementGoalsRequestDataExistance,
} from '../redux-saga/actions';

import {
  consultFiltersBusinessLineClearValue,
  consultFiltersYearSetValue,
  consultFiltersPogPeriodicityClearValue,
  consultFiltersPogPeriodClearValue,
  consultFiltersSalesPeriodicityClearValue,
  consultFiltersSalesPeriodClearValue,
  consultFiltersSegmentationClearValue,
  consultFiltersSearchInputClearValue,
  consultFiltersObjectivesAndIncentivesSetValue,
} from 'RootModule/redux/actions/consultFilters';
import getSchema from '../schemas';

import { layoutContext } from 'context';
import { mapQueryParams } from 'utils';

const AccountStatusTradeAgreementGoalsConsult = (props) => {
  const {
    accountStatusTradeAgreementGoalsRequestConsultTable: requestConsultTable,
    consultFiltersObjectivesAndIncentivesSetValue: setObjectivesAndIncentives,
    consultFiltersBusinessLineClearValue: clearBusinessLines,
    consultFiltersYearSetValue: setYear,

    consultFiltersPogPeriodicityClearValue: clearPogPeriodicity,
    consultFiltersPogPeriodClearValue: clearPogPeriod,
    consultFiltersSalesPeriodicityClearValue: clearSalesPeriodicity,
    consultFiltersSalesPeriodClearValue: clearSalesPeriod,
    consultFiltersSegmentationClearValue: clearSegmentation,
    consultFiltersSearchInputClearValue: clearSearchInput,

    consultTable,

    isLoading,
    ObjectivesAndIncentivesValue,
    BusinessLinesValue,
    YearValue,
    PogPeriodicityValue,
    PogPeriodValue,
    SalesPeriodicityValue,
    SalesPeriodValue,
    SegmentationValue,
    SearchInputValue,
  } = props;
  const history = useHistory();

  const isPogSelected = ObjectivesAndIncentivesValue === 'pog';
  const isSalesSelected = ObjectivesAndIncentivesValue === 'sales';

  const { search } = useLocation();

  const { onChangeActiveComponent } = useContext(layoutContext);
  useEffect(() => {
    clearFilters();
  }, []);

  useEffect(() => {
    let consultTableParams = {};

    onChangeActiveComponent('BusinessStructureMassiveUpload');

    if (BusinessLinesValue?.length > 0) {
      consultTableParams.Lines = BusinessLinesValue;
    }
    if (SegmentationValue?.length > 0) {
      consultTableParams.Segmentation = SegmentationValue;
    }
    if (YearValue?.length > 0) {
      consultTableParams.Years = YearValue;
    }
    if (SearchInputValue?.length > 0) {
      consultTableParams.Wildcard = [SearchInputValue];
    }

    if (isPogSelected) {
      if (PogPeriodicityValue?.length > 0) {
        consultTableParams.Periodicity = PogPeriodicityValue;
      }
      if (PogPeriodValue?.length > 0) {
        consultTableParams.Period = PogPeriodValue;
      }
    }

    if (isSalesSelected) {
      if (SalesPeriodicityValue?.length > 0) {
        consultTableParams.Periodicity = SalesPeriodicityValue;
      }
      if (SalesPeriodValue?.length > 0) {
        consultTableParams.Period = SalesPeriodValue;
      }
    }

    requestConsultTable({
      params: consultTableParams,
      typeOfService: ObjectivesAndIncentivesValue,
      history,
    });
  }, [
    ObjectivesAndIncentivesValue,
    BusinessLinesValue,
    YearValue,
    PogPeriodicityValue,
    PogPeriodValue,
    SalesPeriodicityValue,
    SalesPeriodValue,
    SegmentationValue,
    SearchInputValue,
  ]);

  const mapMassiveUploadParam = (paramString) => {
    switch (paramString) {
      case 'Purchase':
        return 'sales';
      case 'POG':
        return 'pog';
      case 'Annual':
      default:
        return 'annual';
    }
  };
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  const clearFilters = () => {
    let queryParams = mapQueryParams(search);
    let initialObjectivesAndIncentives = 'annual';

    if (queryParams.objectiveIncentive) {
      initialObjectivesAndIncentives = mapMassiveUploadParam(queryParams.objectiveIncentive);
    }

    setObjectivesAndIncentives(initialObjectivesAndIncentives);
    clearBusinessLines();
    setYear([getCurrentYear()]);
    clearPogPeriodicity();
    clearPogPeriod();
    clearSalesPeriodicity();
    clearSalesPeriod();
    clearSegmentation();
    clearSearchInput();
  };

  return (
    <div>
      <FilterWrapper>
        <ObjectivesAndIncentives />
        <BusinessLine />
        <Segmentation />
        <Year serviceName={'ObjectivesIncentivesYears'} />
        {isPogSelected && (
          <>
            <PogPeriodicity />
            <PogPeriod />
          </>
        )}
        {isSalesSelected && (
          <>
            <SalesPeriodicity />
            <SalesPeriod />
          </>
        )}

        <SearchInput floatToRight={true} />
        <ResetButton clearFilters={clearFilters} />
      </FilterWrapper>
      <ConsultTable
        data={consultTable}
        columns={getSchema(ObjectivesAndIncentivesValue)}
        loading={isLoading}
        noResultsMessage={
          'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados.'
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    consultTable: state?.ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS?.consultTable,
    dataExistance: state?.ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS?.dataExistance,
    isLoading: state?.ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS?.isLoading,
    ObjectivesAndIncentivesValue: state?.APP?.consultFilters.objectivesAndIncentives?.value,
    BusinessLinesValue: state?.APP?.consultFilters.businessLine?.value,
    YearValue: state?.APP?.consultFilters?.year?.value,
    PogPeriodicityValue: state?.APP?.consultFilters?.pogPeriodicity?.value,
    PogPeriodValue: state?.APP?.consultFilters?.pogPeriod?.value,
    SalesPeriodicityValue: state?.APP?.consultFilters?.salesPeriodicity?.value,
    SalesPeriodValue: state?.APP?.consultFilters?.salesPeriod?.value,
    SegmentationValue: state?.APP?.consultFilters?.segmentation?.value,
    SearchInputValue: state?.APP?.consultFilters?.searchInput?.value,
  };
};

const ConnectedAccountStatusAccountStatusTradeAgreementGoals = connect(mapStateToProps, {
  accountStatusTradeAgreementGoalsRequestConsultTable,
  accountStatusTradeAgreementGoalsRequestDataExistance,
  consultFiltersBusinessLineClearValue,
  consultFiltersYearSetValue,

  consultFiltersPogPeriodicityClearValue,
  consultFiltersPogPeriodClearValue,
  consultFiltersSalesPeriodicityClearValue,
  consultFiltersSalesPeriodClearValue,

  consultFiltersSegmentationClearValue,

  consultFiltersSearchInputClearValue,
  consultFiltersObjectivesAndIncentivesSetValue,
})(AccountStatusTradeAgreementGoalsConsult);

const AccountStatusAccountStatusTradeAgreementGoalsWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getAccountStatusTradeAgreementGoalsModule()]}>
      <ConnectedAccountStatusAccountStatusTradeAgreementGoals />
    </DynamicModuleLoader>
  );
};
export default AccountStatusAccountStatusTradeAgreementGoalsWithModules;
