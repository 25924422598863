function ReducersTable(state = {}, action) {
  const { type, payload } = action;

  const REDUCER = {
    // Borrar Image
    DELETE_MANAGE_IMAGES_REQUEST: {
      isLoading: true,
    },
    DELETE_MANAGE_IMAGES_SET: {
      deleteImage: payload,
    },
    DELETE_MANAGE_IMAGES_CLEAN: {},
  };

  return { ...state, isLoading: false, ...REDUCER[type] };
}
export default ReducersTable;
