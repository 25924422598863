export const BUSINESS_STRUCTURE_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD =
  'BUSINESS_STRUCTURE_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const BUSINESS_STRUCTURE_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'BUSINESS_STRUCTURE_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const BUSINESS_STRUCTURE_MASSIVE_UPLOAD_REQUEST_VERIFY =
  'BUSINESS_STRUCTURE_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const BUSINESS_STRUCTURE_MASSIVE_UPLOAD_SET_VERIFY =
  'BUSINESS_STRUCTURE_MASSIVE_UPLOAD_SET_VERIFY';
export const BUSINESS_STRUCTURE_MASSIVE_UPLOAD_CLEAR_VERIFY =
  'BUSINESS_STRUCTURE_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const BUSINESS_STRUCTURE_MASSIVE_UPLOAD_REQUEST_UPLOAD =
  'BUSINESS_STRUCTURE_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const BUSINESS_STRUCTURE_MASSIVE_UPLOAD_SET_UPLOAD =
  'BUSINESS_STRUCTURE_MASSIVE_UPLOAD_SET_UPLOAD';
export const BUSINESS_STRUCTURE_MASSIVE_UPLOAD_CLEAR_UPLOAD =
  'BUSINESS_STRUCTURE_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function businessStructureMassiveUploadSetAllowUpload(payload) {
  return {
    type: BUSINESS_STRUCTURE_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function businessStructureMassiveUploadClearAllowUpload(payload) {
  return {
    type: BUSINESS_STRUCTURE_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function businessStructureMassiveUploadRequestVerify(payload) {
  return {
    type: BUSINESS_STRUCTURE_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function businessStructureMassiveUploadSetVerify(payload) {
  return {
    type: BUSINESS_STRUCTURE_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function businessStructureMassiveUploadClearVerify(payload) {
  return {
    type: BUSINESS_STRUCTURE_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function businessStructureMassiveUploadRequestUpload(payload) {
  return {
    type: BUSINESS_STRUCTURE_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function businessStructureMassiveUploadSetUpload(payload) {
  return {
    type: BUSINESS_STRUCTURE_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function businessStructureMassiveUploadClearUpload(payload) {
  return {
    type: BUSINESS_STRUCTURE_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
