import { connect } from 'react-redux';
import { putOneUpdateERRequest, putOneStatusRequest } from './redux-saga/actions';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getRootModule from 'RootModule';
import UpdateERModule from './UpdateERModule';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Form, Row, Col, DatePicker, Modal } from 'antd';
import integerFloat from './function/integerFloat';
import { layoutContext } from '../../context';
import moment from 'moment';
import { BInput } from 'common/components';
import { FormTitleDivider } from '../../common';
import { ActionButtons } from 'common';
import { useHistory, withRouter } from 'react-router-dom';

const UpdateER = (props) => {
  const { putOneUpdateERRequest: putOneUpdateER, putOneStatusRequest: putOneStatus } = props;
  const { onChangeActiveComponent } = useContext(layoutContext);
  const { location } = props;
  const { exchangeData } = location.state;
  const [btnStatus, setbtnStatus] = useState(true);
  const { RangePicker } = DatePicker;
  const dateFormat = 'DD-MM-YYYY';

  const [dateInput, setDateInput] = useState([
    moment(exchangeData?.startValidityDate, dateFormat),
    moment(exchangeData?.endValidityDate, dateFormat),
  ]);
  const [datePUT, setDatePUT] = useState(null);
  /*   const formatearFecha = (date) => moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'); */

  const [form] = Form.useForm();
  const [statusSwitch, setStatusSwitch] = useState({
    //disabled
    disabled: false,
    checked: false,
  });
  const history = useHistory();
  const watchBtn = useCallback(() => {
    const data = form.getFieldsValue();
    if (!data.exchangeRateValue || dateInput == null || statusSwitch.checked) {
      //Bloquear btn
      setbtnStatus(true);
    } else {
      //Desbloquear btn
      setbtnStatus(false);
    }
  }, [dateInput, form, statusSwitch.checked]);
  useEffect(() => {
    if (exchangeData) {
      setStatusSwitch({
        disabled: false,
        checked: !exchangeData?.status,
      });
      //Load Initial Safe Values
      form.setFieldsValue({
        company: exchangeData?.company,
        reference: exchangeData?.reference,
        exchangeRateValue: financialAux(exchangeData?.exchangeRateValue),
      });
    }
  }, [exchangeData, form]);

  useEffect(() => {
    onChangeActiveComponent('UpdateER');
  }, [onChangeActiveComponent]);

  const handleStatusChange = (value) => {
    const userId = exchangeData?.exchangeRateId;
    const actions = {
      true: 'desactivar',
      false: 'activar',
    };
    const message = {
      default: `¿Está seguro que desea ${actions[value]} el tipo de cambio?`,
      representative: `¿Está seguro que desea ${actions[value]} el tipo de cambio?`,
    };

    Modal.confirm({
      title: 'Confirmar acción',
      content: value === true ? message.representative : message.default,
      onOk() {
        putOneStatus({ userId, enabled: statusSwitch.checked });
        const onOff = statusSwitch.checked;
        setStatusSwitch({
          disabled: false,
          checked: !onOff,
        });
        watchBtn();
      },
    });
  };

  useEffect(() => {
    watchBtn();
  }, [statusSwitch, dateInput, watchBtn]);

  //Activar desactivar Btn Aceptar

  //EN CAMBIO DE FECHA
  const formatDate = (date) => {
    if (date[0] === '') {
      setDateInput(null);
    } else {
      setDateInput([moment(date[0], dateFormat), moment(date[1], dateFormat)]);
      setDatePUT([
        moment(date[0], dateFormat).format('YYYY-MM-DD'),
        moment(date[1], dateFormat).format('YYYY-MM-DD'),
      ]);
    }
    watchBtn();
  };

  //ENVIO DE DATOS
  const onUpdate = async () => {
    if (btnStatus) {
      history.push('/catalog/exchange-rate');
      return;
    }
    if (datePUT === null) {
      let dates = [
        { _i: moment(dateInput[0]._i, 'DD-MM-YYYY').format('YYYY-MM-DD') },
        { _i: moment(dateInput[1]._i, 'DD-MM-YYYY').format('YYYY-MM-DD') },
      ];
      putOneUpdateER({ ...form.getFieldsValue(), exchangeData, dates, history });
    } else {
      putOneUpdateER({ ...form.getFieldsValue(), exchangeData, datePUT, history });
    }
  };

  const onCancel = () => {
    history.push('/catalog/exchange-rate');
  };

  const rangeConfig = {
    rules: [
      {
        type: 'array',
        required: true,
        message: 'Please select time!',
      },
    ],
  };
  function financialAux(x) {
    return Number.parseFloat(x).toFixed(2);
  }
  const OnValuesChange = (e) => {
    let { keyId, valueIF } = integerFloat(e);

    if (keyId === 'exchangeRateValue') {
      form.setFieldsValue({ exchangeRateValue: valueIF });
    }

    formatValue();
    watchBtn();
  };
  const handlerOnKeyDown = (e) => {
    let maxChar = 0;

    maxChar = form.getFieldsValue().exchangeRateValue.toString().length;

    if (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 109) {
      e.preventDefault();
    } else if (maxChar < 10 || e.keyCode === 86) {
      formatValue();
    }
  };
  const handlerOnKeyUp = (e) => {
    let valueInput = form.getFieldValue().exchangeRateValue;
    if (valueInput > 1000000000 && valueInput < 9999999999.99) {
      let value1 = form.getFieldValue().exchangeRateValue.toString();

      let value2 = value1.substr(0, 13);

      form.setFieldsValue({ exchangeRateValue: value2 });
    } else if (valueInput > 9999999999.99) {
      let value1 = form.getFieldValue().exchangeRateValue.toString();

      let value2 = value1.substr(0, 10);

      form.setFieldsValue({ exchangeRateValue: value2 });
    }
  };

  const formatValue = () => {
    let value = form.getFieldValue().exchangeRateValue;
    let valueString = 0;
    let result = null;

    valueString = form.getFieldValue().exchangeRateValue.toString();
    let pattern = /[A-z]/g;
    result = valueString.match(pattern);

    if (result) {
      form.setFieldsValue({ exchangeRateValue: null });
    } else {
      let valueV = parseFloat(financialAux(value));
      form.setFieldsValue({ exchangeRateValue: valueV });
    }
  };
  return (
    <Form form={form} onValuesChange={OnValuesChange}>
      <div className={'module-card'}>
        <Row>
          <FormTitleDivider title={'Información de compañía'} />
        </Row>
        <Col span={8}>
          <BInput disabled className="form-add-user-input" name="company" label="Compañía" />
        </Col>
        <Row>
          <FormTitleDivider title={'Información de tipo de cambio'} />
        </Row>
        <Row>
          <Col span={8}>
            <BInput disabled className="form-add-user-input" name="reference" label="Referencia" />
          </Col>
          <Col span={8}>
            <BInput
              disabled={statusSwitch.checked}
              isRequired
              className="form-add-user-input"
              name="exchangeRateValue"
              type="number"
              min={0}
              max={99999999999999}
              step="0.01"
              onKeyDown={handlerOnKeyDown}
              onKeyUp={handlerOnKeyUp}
              label="Valor"
            />
          </Col>

          <Form.Item label="*Vigencia (DD/MM/AAAA)" span={8} {...rangeConfig}>
            <RangePicker
              format={'DD/MM/YYYY'}
              disabled={statusSwitch.checked}
              value={dateInput}
              onChange={(value, defaultPickerValue) => {
                formatDate(defaultPickerValue);
              }}
            />
          </Form.Item>
        </Row>
      </div>
      <ActionButtons
        onCancel={onCancel}
        onUpdate={onUpdate}
        statusChange={handleStatusChange}
        statusSwitch={statusSwitch}
        disabled={false}
        buttons={['cancel', 'update']}
      />
    </Form>
  );
};

function mapStateToProps(state) {
  return { userData: state?.UPDATEER?.updateer?.data };
}

const ConnectedUpdateERR = withRouter(
  connect(mapStateToProps, {
    putOneUpdateERRequest,
    putOneStatusRequest,
  })(UpdateER)
);

const DetailUpdateERR = () => (
  <DynamicModuleLoader modules={[getRootModule(), UpdateERModule()]}>
    <ConnectedUpdateERR />
  </DynamicModuleLoader>
);

export default DetailUpdateERR;
