/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import getProductMasterMassiveUploadModule from '../getProductMasterMassiveUploadModule';
import { layoutContext } from 'context';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import {
  MassiveUploadTable,
  UploadExcelButton,
  ActionButtonsExcelUpload,
} from 'common/MassiveUploads/components';
import { triggerModalForDuplicates } from 'common/MassiveUploads/utils';
//cambiar sagas
import {
  ProductMasterMassiveUploadSetAllowUpload,
  ProductMasterMassiveUploadRequestVerify,
  ProductMasterMassiveUploadRequestUpload,
  ProductMasterMassiveUploadClearUpload,
  ProductMasterMassiveUploadClearVerify,
  ProductMasterMassiveUploadClearAllowUpload,
} from '../redux-saga/actions';
import { ModalSelector } from '../Components';
import { columnsCAC, columnsMxCrop, columnsMxPss } from '../schemas'; //cambiar columna

const ProductMasterMassiveUpload = (props) => {
  const {
    ProductMasterMassiveUploadSetAllowUpload: setAllowUpload,
    ProductMasterMassiveUploadRequestVerify: requestVerify,
    ProductMasterMassiveUploadRequestUpload: requestUpload,
    ProductMasterMassiveUploadClearUpload: clearUpload,
    ProductMasterMassiveUploadClearVerify: clearVerify,
    ProductMasterMassiveUploadClearAllowUpload: clearAllowUpload,
    allowUpload,
    verify,
  } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [fileString, setFileString] = useState('');
  const [selectSubregion, setselectSubregion] = useState(0);
  const [selectBusinessLine, setselectBusinessLine] = useState(0);
  const [tableColumns, settableColumns] = useState([]);
  const [urlEndpoint, seturlEndpoint] = useState('');
  const correct = verify?.correct;
  const entriesList = verify?.objectList;
  const totalDuplicatedEntries = verify?.replaced?.length;
  const allCorrectMessage = verify?.allCorrectMessage;

  useEffect(() => {
    selectEndpoint();
  }, [selectSubregion, selectBusinessLine]);

  const selectEndpoint = () => {
    if (selectSubregion.name === 'CA&C') {
      settableColumns(columnsCAC);
      seturlEndpoint('/Catalogs/Api/v1/BaseInformationAgreement/MassLoadMasterProductsCACExcel');
      console.log('CAC@@@@@');
    }
    if (selectSubregion.name === 'MX' && selectBusinessLine.name === 'CROP') {
      settableColumns(columnsMxCrop);
      seturlEndpoint('/Catalogs/Api/v1/BaseInformationAgreement/MassLoadMasterProductsCROPMxExcel');
      console.log('MX CROP @@@@@');
    } else if (selectSubregion.name === 'MX') {
      settableColumns(columnsMxPss);
      seturlEndpoint('/Catalogs/Api/v1/BaseInformationAgreement/MassLoadMasterProductsPSSMxExcel');
      console.log('MX PSS @@@@@');
    }
  };

  useEffect(() => {
    clearAllowUpload();
    return () => {
      clearAllowUpload();
      clearUpload();
      clearVerify();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    onChangeActiveComponent('activo');
    if (correct > 0) {
      setAllowUpload(true);
    } else {
      setAllowUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correct]);

  function customRequest({ file }) {
    requestVerify({
      file,
      setFileString,
      subregion: selectSubregion.key,
      line: selectBusinessLine.key,
      urlEndpoint: urlEndpoint,
    });
  }
  function requestReplace() {
    requestUpload({
      history,
      fileString,
      overwrite: true,
      subregion: selectSubregion.key,
      line: selectBusinessLine.key,
      urlEndpoint: urlEndpoint,
    });
  }
  function requestKeep() {
    selectEndpoint();

    requestUpload({
      history,
      fileString,
      overwrite: false,
      subregion: selectSubregion.key,
      line: selectBusinessLine.key,
      urlEndpoint: urlEndpoint,
    });
  }
  function onSubmit() {
    if (totalDuplicatedEntries > 0) {
      triggerModalForDuplicates(totalDuplicatedEntries, requestReplace, requestKeep, 'Ventas');
      return;
    }

    requestReplace();
  }
  function onCancel() {
    history.push(`/catalog/trade-agreement-info`);
  }

  return (
    <>
      <ModalSelector
        setselectSubregion={setselectSubregion}
        selectSubregion={selectSubregion}
        setselectBusinessLine={setselectBusinessLine}
        selectBusinessLine={selectBusinessLine}
        selectEndpoint={selectEndpoint}
      />
      <Form form={form}>
        <UploadExcelButton customRequest={customRequest} />
        <MassiveUploadTable
          data={entriesList}
          columns={tableColumns}
          allCorrectMessage={allCorrectMessage}
        />
        <ActionButtonsExcelUpload
          onSubmit={onSubmit}
          onCancel={onCancel}
          disabled={!allowUpload}
          buttons={['cancel', 'submit']}
        />
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allowUpload: state?.ProductMaster_MASSIVE_UPLOAD?.allowUpload,
    verify: state?.ProductMaster_MASSIVE_UPLOAD?.verify,
  };
};

const ConnectedProductMasterMassiveUpload = connect(mapStateToProps, {
  ProductMasterMassiveUploadSetAllowUpload,
  ProductMasterMassiveUploadRequestVerify,
  ProductMasterMassiveUploadRequestUpload,
  ProductMasterMassiveUploadClearUpload,
  ProductMasterMassiveUploadClearVerify,
  ProductMasterMassiveUploadClearAllowUpload,
})(ProductMasterMassiveUpload);

const ProductMasterMassiveUploadWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getProductMasterMassiveUploadModule()]}>
      <ConnectedProductMasterMassiveUpload />
    </DynamicModuleLoader>
  );
};
export default ProductMasterMassiveUploadWithModules;
