import {
  GET_MAPS_REQUEST,
  GET_MAPS_SUCCESS,
  GET_MAPS_CLEAN,
  GET_GET_REQUEST,
  GET_GET_SUCCESS,
  GET_GET_CLEAN,
  GET_getFilterYear_REQUEST,
  GET_getFilterYear_SUCCESS,
  GET_getFilterYear_CLEAN,
  GET_file_REQUEST,
  GET_file_SUCCESS,
  GET_file_CLEAN,
  GET_DaysLeft_REQUEST,
  GET_DaysLeft_SUCCESS,
  GET_DaysLeft_CLEAN,
  GET_ChallengeExists_REQUEST,
  GET_ChallengeExists_SUCCESS,
  GET_ChallengeExists_CLEAN,
  GET_AgreementExists_REQUEST,
  GET_AgreementExists_SUCCESS,
  GET_AgreementExists_CLEAN,
} from '../actions';

function oneCase(state = {}, action) {
  const { type } = action;

  switch (type) {
    //GET_AgreementExists
    case GET_AgreementExists_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_AgreementExists_SUCCESS:
      return {
        ...state,
        isLoading: false,
        AgreementExists: action.payload,
      };
    case GET_AgreementExists_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    //GET_ChallengeExists
    case GET_ChallengeExists_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ChallengeExists_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ChallengeExists: action.payload,
      };
    case GET_ChallengeExists_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    //GET_DaysLeft
    case GET_DaysLeft_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DaysLeft_SUCCESS:
      return {
        ...state,
        isLoading: false,
        DaysLeft: action.payload,
      };
    case GET_DaysLeft_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    //GET_file
    case GET_file_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_file_SUCCESS:
      return {
        ...state,
        isLoading: false,
        file: action.payload,
      };
    case GET_file_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //GET_getFilterYear
    case GET_getFilterYear_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_getFilterYear_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getFilterYear: action.payload,
      };
    case GET_getFilterYear_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //GET_GET
    case GET_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        get: action.payload,
      };
    case GET_GET_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //GET_MAPS
    case GET_MAPS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MAPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        maps: action.payload,
      };
    case GET_MAPS_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}

export default oneCase;
