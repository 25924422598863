import request from 'services/request';
import ApiConstants from '../utils/ApiConstants';
import { paramsSerializer } from 'RootModule/services/catalogs';
const {
  subRegions,
  businessLines,
  urlMonth,
  urlFrequency,
  urlPeriodDelivery,
  urlStatus,
  urlRegister,
  urlClient,
  urlChangeStatus,
  urlDeletePeriod,
  urlStatusDynamicsElment,
} = ApiConstants;

export async function requestSubRegions({ cancelToken }) {
  return request(subRegions, {
    method: 'GET',
    cancelToken,
  });
}
export async function requestBusinessLines({ cancelToken }) {
  return request(businessLines, {
    method: 'GET',
    cancelToken,
  });
}

export async function requestMonth({ cancelToken }) {
  return request(urlMonth, {
    method: 'GET',
    cancelToken,
  });
}

export async function requestFrequency({ cancelToken }) {
  return request(urlFrequency, {
    method: 'GET',
    cancelToken,
  });
}

export async function requestPeriod({ cancelToken }) {
  return request(urlPeriodDelivery, {
    method: 'GET',
    cancelToken,
  });
}

export async function requestStatus({ cancelToken }) {
  return request(urlStatus, {
    method: 'GET',
    cancelToken,
  });
}

export async function requestClient({ cancelToken, params }) {
  return request(urlClient, {
    method: 'GET',
    cancelToken,
    params,
    paramsSerializer,
  });
}
export async function requestRegister({ cancelToken, params }) {
  const data = params;
  return request(urlRegister, {
    method: 'POST',
    cancelToken,
    data,
  });
}

export async function requestChageStatus({ cancelToken, params }) {
  const data = params;
  return request(urlChangeStatus, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

export async function requestDeletePeriod({ cancelToken, params }) {
  const data = params;
  return request(urlDeletePeriod, {
    method: 'DELETE',
    cancelToken,
    data,
  });
}

export async function requestStatusDynamicsElement({ cancelToken }) {
  return request(urlStatusDynamicsElment, {
    method: 'GET',
    cancelToken,
  });
}
