import { takeEvery, put, call, all, cancelled } from 'redux-saga/effects';
import {
  GET_MAPS_REQUEST,
  getMapsSuccess,
  getMapsClean,
  GET_GET_REQUEST,
  getGetSuccess,
  getGetClean,
  GET_getFilterYear_REQUEST,
  getFilterYearSuccess,
  getFilterYearClean,
  GET_file_REQUEST,
  getfileSuccess,
  getfileClean,
  GET_DaysLeft_REQUEST,
  getDaysLeftSuccess,
  getDaysLeftClean,
  GET_AgreementExists_REQUEST,
  getAgreementExistsClean,
  GET_ChallengeExists_REQUEST,
  getChallengeExistsClean,
} from '../actions';
import instance from 'services/request';
import {
  getMaps,
  getGet,
  getFile,
  getDaysLeft,
  getFilterYear,
  getAgreementExists,
  getChallengeExists,
} from '../../services/MapsServices';
import { responseOK } from 'SalesChallengeRefactor/utils';
import { openNotification } from 'common';

///GET_MAPS
function* workGetMapsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    let response = yield call(getMaps, { params: 'Params' });

    if (responseOK(response)) {
      yield put(getMapsSuccess(response));
    } else {
      yield put(getMapsClean());
    }
  } catch (error) {
    yield put(getMapsClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetMapsRequest() {
  yield all([takeEvery(GET_MAPS_REQUEST, workGetMapsRequest)]);
}

///GET_GET
function* workGetGetRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;

  const { Year, Regions, Lines, Subregions, Territories, Zones, history } = payload;
  try {
    let response = yield call(getGet, {
      params: { Year, Regions, Lines, Subregions, Territories, Zones },
    });

    if (responseOK(response)) {
      if (response.data.length === 0) {
        yield openNotification('error', response.message);
      }
      yield put(getGetSuccess(response.data));
    } else {
      yield put(getGetClean());
    }
  } catch (error) {
    yield put(getGetClean());
    console.error('[BASF[REDUX-SAGA]', error);
    yield openNotification('error', error);
    setTimeout(() => {
      history.push('/dashboards');
    }, 300);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetGetRequest() {
  yield all([takeEvery(GET_GET_REQUEST, workGetGetRequest)]);
}

///GET_getFilterYear
function* workGetFilterYearRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    let response = yield call(getFilterYear, {});

    if (responseOK(response)) {
      yield put(getFilterYearSuccess(response));
    } else {
      yield put(getFilterYearClean());
    }
  } catch (error) {
    yield put(getFilterYearClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetFilterYearRequest() {
  yield all([takeEvery(GET_getFilterYear_REQUEST, workGetFilterYearRequest)]);
}

///GET_FILE
function* workfileRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;

  const { Values, Column1, Column2 } = payload;
  const values = JSON.stringify(Values);
  try {
    let response = yield call(getFile, {
      params: {
        Values: values,
        Column1,
        Column2,
      },
    });

    if (responseOK(response)) {
      const linkSource = `${'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'},${
        response.data
      }`;

      const downloadLink = document.createElement('a');
      const date = new Date();
      let dateFormatted =
        date.getDate().toString().padStart(2, '0') +
        '/' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '/' +
        date.getFullYear();

      const fileName = `Dashboard Mapa ${dateFormatted}.xlsx`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      openNotification(
        'success',
        'La descarga de información del dashboard mapa se realizo correctamente'
      );
      yield put(getfileSuccess(response));
    } else {
      yield put(getfileClean());
    }
  } catch (error) {
    yield put(getfileClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchfileRequest() {
  yield all([takeEvery(GET_file_REQUEST, workfileRequest)]);
}

/////GET_DaysLeft_
function* workDaysLeftRequest() {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    let acuerdo = false;
    /* ACUERDO COMERCIAL */
    let response7 = yield call(getDaysLeft, {
      params: {
        processId: 7,
      },
    });
    if (responseOK(response7)) {
      if (response7.data.start && response7.data.daysLeft >= 0) {
        acuerdo = true;
      } else {
        let response8 = yield call(getDaysLeft, {
          params: {
            processId: 8,
          },
        });
        if (responseOK(response8)) {
          if (response8.data.start && response8.data.daysLeft >= 0) {
            acuerdo = true;
          } else {
            let response9 = yield call(getDaysLeft, {
              params: {
                processId: 9,
              },
            });
            if (responseOK(response9)) {
              if (response9.data.start && response9.data.daysLeft >= 0) {
                acuerdo = true;
              } else {
                yield put(getDaysLeftSuccess({ desafio: false }));
              }
            }
          }
        }
      }
    }

    /* DESAFIO DE VENTAS */
    let response3 = yield call(getDaysLeft, {
      params: {
        processId: 3,
      },
    });
    if (responseOK(response3)) {
      if (response3.data.start && response3.data.daysLeft >= 0) {
        if (acuerdo === true) {
          yield put(getDaysLeftSuccess({ desafio: false, response3 }));
        } else {
          yield put(getDaysLeftSuccess({ desafio: true, response3 }));
        }
      } else {
        let response4 = yield call(getDaysLeft, {
          params: {
            processId: 4,
          },
        });
        if (responseOK(response4)) {
          if (response4.data.start && response4.data.daysLeft >= 0) {
            if (acuerdo === true) {
              yield put(getDaysLeftSuccess({ desafio: false, response4 }));
            } else {
              yield put(getDaysLeftSuccess({ desafio: true, response4 }));
            }
          } else {
            let response5 = yield call(getDaysLeft, {
              params: {
                processId: 5,
              },
            });
            if (responseOK(response5)) {
              if (response5.data.start && response5.data.daysLeft >= 0) {
                if (acuerdo === true) {
                  yield put(getDaysLeftSuccess({ desafio: false, response5 }));
                } else {
                  yield put(getDaysLeftSuccess({ desafio: true, response5 }));
                }
              } else {
                yield put(getDaysLeftSuccess({ desafio: false }));
              }
            }
          }
        }
      }
    }
  } catch (error) {
    yield put(getDaysLeftClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchDaysLeftRequest() {
  yield all([takeEvery(GET_DaysLeft_REQUEST, workDaysLeftRequest)]);
}

/* Validaciones HU-760: 2.1, 2.2 */

///GET_getAgreementExists
function* workGetAgreementExistsRequest(action) {
  const { CancelToken } = instance;
  const source = CancelToken.source();
  let { payload } = action;
  let { history } = payload;
  let redirect = false;
  try {
    let response = yield call(getAgreementExists, {});

    if (responseOK(response)) {
      if (response.data === false) {
        redirect = true;
        yield openNotification('error', response.message);
      }
    }
  } catch (error) {
    yield put(getAgreementExistsClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
    if (redirect === true) {
      yield call(history.push, '/dashboards');
    }
  }
}

export function* watchGetAgreementExistsRequest() {
  yield all([takeEvery(GET_AgreementExists_REQUEST, workGetAgreementExistsRequest)]);
}

///GET_getChallengeExists
function* workGetChallengeExistsRequest(action) {
  const { CancelToken } = instance;
  const source = CancelToken.source();
  let { payload } = action;
  let { history } = payload;
  let redirect = false;
  try {
    let response = yield call(getChallengeExists, {});

    if (responseOK(response)) {
      if (response.data === false) {
        redirect = true;
        yield openNotification('error', response.message);
      }
    }
  } catch (error) {
    yield put(getChallengeExistsClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
    if (redirect === true) {
      yield call(history.push, '/dashboards');
    }
  }
}

export function* watchGetChallengeExistsRequest() {
  yield all([takeEvery(GET_ChallengeExists_REQUEST, workGetChallengeExistsRequest)]);
}
