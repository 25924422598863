/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';
import { Skeleton } from 'antd';
import './Maps.css';
import 'leaflet/dist/leaflet.css';

function Maps(props) {
  const { mapsState, type, excelCoordinates, color } = props;
  const [mapCoordinates, setMapCoordinates] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [excelMapCoordinates, setExcelMapCoordinates] = useState([]);
  // const [colorCoordinates, setColorCoordinates] = useState(color);
  const [updateColor, setupdateColor] = useState(false);
  useEffect(() => {
    if (mapsState) {
      setMapCoordinates(mapsState?.TypeElement?.data);
      setIsLoading(mapsState?.isLoading);
    }
  }, [mapsState]);

  useEffect(() => {
    if (excelCoordinates) {
      const newExcelCoordinates = excelCoordinates.map((mapCoordinate) => {
        return mapCoordinate.filter((coordinate) => coordinate.length > 1);
      });
      setExcelMapCoordinates(newExcelCoordinates);
    } else {
      setExcelMapCoordinates([]);
    }
  }, [excelCoordinates]);
  useEffect(() => {
    if (color.rgb) {
      setupdateColor(true);
    } else {
      setupdateColor(false);
    }
  }, [color]);
  return (
    <div id="map123">
      {isLoading ? (
        <Skeleton active />
      ) : (
        <MapContainer
          className="leaflet-container"
          center={{ lat: '20', lng: '-95' }}
          zoom={4}
          preferCanvas={true}
          style={{ maxWidth: '600px' }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {type !== 'Registrar' &&
            (excelMapCoordinates.length > 0
              ? excelMapCoordinates.length > 0 &&
                excelMapCoordinates.map((coordinates, index) => {
                  return (
                    <Polygon
                      stroke={false}
                      fillOpacity={1}
                      key={index}
                      pathOptions={{ color: color.hex }}
                      positions={coordinates}
                    />
                  );
                })
              : mapCoordinates && (
                  <Polygon
                    fillOpacity={1}
                    stroke={false}
                    pathOptions={
                      updateColor ? { color: color.hex } : { color: mapCoordinates.color }
                    }
                    positions={mapCoordinates.coordinates}
                  />
                ))}

          {/* MODAL REGISTRAR */}
          {type === 'Registrar' &&
            excelMapCoordinates.length > 0 &&
            excelMapCoordinates.map((coordinates, index) => {
              return (
                <Polygon
                  stroke={false}
                  fillOpacity={1}
                  key={index}
                  pathOptions={{ color: color.hex }}
                  positions={coordinates}
                />
              );
            })}
        </MapContainer>
      )}
    </div>
  );
}

export default Maps;
