/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Styles from './breadcrumbs.module.css';
import { Modal } from 'antd';

const BreadCrumbs = ({ parentModule, handleBack, module }) => {
  const parentClick = () => {
    Modal.confirm({
      title: 'Abandonar operación de sistema',
      content:
        '¿Está seguro que desea abandonar la operación? La información que no haya sido almacenada se perderá.',
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      centered: true,
      okButtonProps: { type: 'info', style: { backgroundColor: '#003d81', color: '#ffffff' } },
      cancelButtonProps: {
        type: 'default',
        style: { backgroundColor: '#0078B3', color: '#ffffff' },
      },
      onOk() {
        handleBack();
      },
    });
  };

  return (
    <div className={Styles['breadcrumbs']}>
      {!parentModule ? (
        <p>
          <b>{module}</b>
        </p>
      ) : (
        <p>
          <span style={{ cursor: 'pointer' }} onClick={() => parentClick()}>
            <b> {parentModule}</b>
          </span>
          {' - '}
          {module}
        </p>
      )}
    </div>
  );
};

export default BreadCrumbs;
