export const addAllBtnOnOptions = (arr, customProps) => {
  let keyProp = 'key';
  let valueProp = 'value';

  if (customProps) {
    keyProp = customProps.keyProp;
    valueProp = customProps.valueProp;
  }

  return [{ [keyProp]: 99999, [valueProp]: 'Todos' }, ...arr];
};

export const handleAllBtnClick = (selected, options, customProps) => {
  let includesAll = selected.includes(99999);
  let optionsLength = options.length + 1;
  let valuesLength = selected.length;
  let keyProp = 'key';

  if (customProps) {
    keyProp = customProps.keyProp;
  }

  if (includesAll) {
    if (valuesLength === optionsLength) {
      return [];
    }
    return options.map((option) => {
      return option[keyProp];
    });
  }

  return selected;
};
