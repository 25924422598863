import React, { useEffect, useContext, useState } from 'react';
import { Form } from 'antd';
import { ActionButtons, BDivider } from 'common';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import {
  getDetailDynamicElementsRequest,
  getOneClientRequest,
  getPOGRequest,
  getPresenceRequest,
} from '../redux-saga/actions';

import getRootModule from 'RootModule';
import {
  SectionInitial,
  SectionPog,
  ResumeIncentiveSection,
  Client,
  ShopByFamilySection,
  SectionShop,
  LineCreditOccupancySection,
  OthersSections,
  SectionContacts,
} from '../components';

import clientModule from '../clientModule';
import { layoutContext } from 'context';

const DetailDynamicModules = (props) => {
  const { onChangeActiveComponent } = useContext(layoutContext);
  const {
    getInitialSection,
    getPogSection,
    getOtherSection,
    getArrayContact,
    getCreditLineSection,
    getFamilySalesSection,
    getSalesSection,
    getStaticFiels,
    getOneClient,
    getArrayPOG,
    getArrayPresence,
    getCreditNotesSection,
    getDetailDynamicElementsRequest: getDetail,
    getOneClientRequest: getClient,
    getPOGRequest: getPOG,
    getPresenceRequest: getPresence,
  } = props;

  const [form] = Form.useForm();
  const history = useHistory();

  const params = useParams();
  const clientId = params.id;
  const [statusSwitch, setStatusSwitch] = useState({
    //disabled
    checked: true,
    disabled: false,
  });
  let { location } = props;
  let { state } = location;
  let { lineId } = state;
  if (lineId === undefined) {
    history.push('account-status/dynamic-items/consult');
  }

  useEffect(() => {
    getDetail({ clientId, lineId });
    getClient(clientId);
    onChangeActiveComponent('Dynamic');
    getPOG();
    getPresence();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    setStatusSwitch({
      disabled: true, // clientInfo?.editable,
      checked: !getStaticFiels?.enabled,
    });
  }, [getStaticFiels]);

  const onCancel = () => {
    history.push('/account-status/dynamic-items/consult');
  };

  const goToEditPage = () => {
    history.push({
      pathname: `/account-status/dynamic-items/update/${clientId}`,
      state,
    });
  };

  return (
    <>
      <div className={'module-card'}>
        <Form form={form}>
          <BDivider title={'Información de cliente'} />
          <Client
            getStaticFiels={getStaticFiels}
            getOneClient={getOneClient}
            getArrayPOG={getArrayPOG}
            getArrayPresence={getArrayPresence}
            getPOG={getPOG}
            form={form}
          />
          <BDivider title={'Sección inicial'} />
          <SectionInitial getInitialSection={getInitialSection} />
          <BDivider title={'Sección de compras'} />
          <SectionShop getSalesSection={getSalesSection} />
          <BDivider title={'Sección de POG'} />
          <SectionPog getPogSection={getPogSection} />
          <BDivider title={'Sección de resumen de incentivos'} />
          <ResumeIncentiveSection getCreditNotesSection={getCreditNotesSection} />
          <BDivider title={'Sección de ocupación de línea de crédito'} />
          <LineCreditOccupancySection getCreditLineSection={getCreditLineSection} />
          <BDivider title={'Sección de compras por familia'} />
          <ShopByFamilySection getFamilySalesSection={getFamilySalesSection} />
          <BDivider title={'Sección de otros'} />
          <OthersSections getOtherSection={getOtherSection} />
          <BDivider title={'Sección de información de contactos'} />
          <SectionContacts getArrayContact={getArrayContact} />
        </Form>
      </div>
      <ActionButtons
        onCancel={onCancel}
        statusSwitch={statusSwitch}
        goToEditPage={goToEditPage}
        disabled={false}
        buttons={['cancel', 'goToEditPage']}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    getCreditLineSection: state.GETCLIENT.client.detail?.creditLineSection,
    getCreditNotesSection: state.GETCLIENT.client.detail?.creditNotesSection,
    getFamilySalesSection: state.GETCLIENT.client.detail?.familySalesSection,
    getInitialSection: state.GETCLIENT.client.detail?.initialSection,
    getOtherSection: state.GETCLIENT.client.detail?.otherSection,
    getPogSection: state.GETCLIENT.client.detail?.pogSection,
    getSalesSection: state.GETCLIENT.client.detail?.salesSection,
    getStaticFiels: state.GETCLIENT.client.detail?.staticFields,
    getOneClient: state.GETCLIENT.client?.oneClient,
    getArrayPOG: state.GETCLIENT.client?.pog,
    getArrayPresence: state.GETCLIENT.client?.presence,
    getArrayContact: state.GETCLIENT.client.detail?.contactAccountStatement,
  };
}

const DetailDynamicModulesForm = withRouter(
  connect(mapStateToProps, {
    getDetailDynamicElementsRequest,
    getOneClientRequest,
    getPOGRequest,
    getPresenceRequest,
  })(DetailDynamicModules)
);

const DetailDynamicElements = () => (
  <DynamicModuleLoader modules={[getRootModule(), clientModule()]}>
    <DetailDynamicModulesForm />
  </DynamicModuleLoader>
);

export default DetailDynamicElements;
