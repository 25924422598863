export const ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_REQUEST_CONSULT_TABLE =
  'ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_REQUEST_CONSULT_TABLE';
export const ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_SET_CONSULT_TABLE =
  'ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_SET_CONSULT_TABLE';
export const ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_CLEAR_CONSULT_TABLE =
  'ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_CLEAR_CONSULT_TABLE';

export const ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_REQUEST_DATA_EXISTANCE =
  'ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_REQUEST_DATA_EXISTANCE';
export const ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_SET_DATA_EXISTANCE =
  'ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_SET_DATA_EXISTANCE';
export const ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_CLEAR_DATA_EXISTANCE =
  'ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_CLEAR_DATA_EXISTANCE';

export function accountStatusTradeAgreementGoalsRequestConsultTable(payload) {
  return {
    type: ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_REQUEST_CONSULT_TABLE,
    payload: payload,
  };
}

export function accountStatusTradeAgreementGoalsSetConsultTable(payload) {
  return {
    type: ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_SET_CONSULT_TABLE,
    payload: payload,
  };
}

export function accountStatusTradeAgreementGoalsClearConsultTable(payload) {
  return {
    type: ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_CLEAR_CONSULT_TABLE,
  };
}

export function accountStatusTradeAgreementGoalsRequestDataExistance(payload) {
  return {
    type: ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_REQUEST_DATA_EXISTANCE,
    payload: payload,
  };
}

export function accountStatusTradeAgreementGoalsSetDataExistance(payload) {
  return {
    type: ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_SET_DATA_EXISTANCE,
    payload: payload,
  };
}

export function accountStatusTradeAgreementGoalsClearDataExistance(payload) {
  return {
    type: ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_CLEAR_DATA_EXISTANCE,
  };
}
