export const SALES_CHALLENGE_TERRITORY_VALIDATOR_REQUEST =
  'SALES_CHALLENGE_TERRITORY_VALIDATOR_REQUEST';
export const SALES_CHALLENGE_TERRITORY_VALIDATOR_SUCCESS =
  'SALES_CHALLENGE_TERRITORY_VALIDATOR_SUCCESS';
export const SALES_CHALLENGE_TERRITORY_VALIDATOR_FAIL = 'SALES_CHALLENGE_TERRITORY_VALIDATOR_FAIL';

export function salesChallengeTerritoryValidatorRequest(payload) {
  return {
    type: SALES_CHALLENGE_TERRITORY_VALIDATOR_REQUEST,
    payload,
  };
}

export function salesChallengeTerritoryValidatorSuccess(payload) {
  return { type: SALES_CHALLENGE_TERRITORY_VALIDATOR_SUCCESS, payload };
}

export function salesChallengeTerritoryValidatorFail(payload) {
  return { type: SALES_CHALLENGE_TERRITORY_VALIDATOR_FAIL, payload };
}
