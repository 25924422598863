export const TABLE_COMPONENTS_CHANGING_SWITCH_REQUEST_SET_STATUS =
  'TABLE_COMPONENTS_CHANGING_SWITCH_REQUEST_SET_STATUS';
export const TABLE_COMPONENTS_CHANGING_SWITCH_SET_SET_STATUS =
  'TABLE_COMPONENTS_CHANGING_SWITCH_SET_SET_STATUS';
export const TABLE_COMPONENTS_CHANGING_SWITCH_CLEAR_SET_STATUS =
  'TABLE_COMPONENTS_CHANGING_SWITCH_CLEAR_SET_STATUS';

export function tableComponentsChangingSwitchRequestSetStatus(payload) {
  return {
    type: TABLE_COMPONENTS_CHANGING_SWITCH_REQUEST_SET_STATUS,
    payload: payload,
  };
}

export function tableComponentsChangingSwitchSetSetStatus(payload) {
  return {
    type: TABLE_COMPONENTS_CHANGING_SWITCH_SET_SET_STATUS,
    payload: payload,
  };
}

export function tableComponentsChangingSwitchClearSetStatus(payload) {
  return {
    type: TABLE_COMPONENTS_CHANGING_SWITCH_CLEAR_SET_STATUS,
    payload: payload,
  };
}
