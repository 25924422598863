import { call, put, cancelled, takeLatest } from 'redux-saga/effects';

import {
  ACCOUNT_STATUS_PREVIEW_REQUEST_CONSULT,
  ACCOUNT_STATUS_PREVIEW_REQUEST_PERIOD_LIST,
  ACCOUNT_STATUS_PREVIEW_REQUEST_REGISTER_INTO_LOG,
  accountStatusPreviewSetConsult,
  accountStatusPreviewClearConsult,
  accountStatusPreviewSetPeriodList,
  accountStatusPreviewClearPeriodList,
  accountStatusPreviewSetGetExistances,
  accountStatusPreviewClearGetExistances,
  accountStatusPreviewSetRegisterIntoLog,
  accountStatusPreviewSetCurrentClientData,
  accountStatusPreviewClearRegisterIntoLog,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';

import {
  getAccountStatement,
  getAccountStatementPeriodList,
  getDynamicElementsExistances,
  getExistances,
  registerIntoLog,
} from '../../services';
import checkIfNoElements from 'utils/checkIfConsultHasNoElements';
import { message001, message002, messageError } from 'utils/messages';

function* workAccountStatusPreviewRequestConsult(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { params, history } = action.payload;
  let mustRedirect = false;
  try {
    const dynamicElementsExistanceResponse = yield call(getDynamicElementsExistances, {
      params: {},
    });
    if (responseOK(dynamicElementsExistanceResponse)) {
      mustRedirect = checkIfNoElements({}, dynamicElementsExistanceResponse.data);
    }

    const response = yield call(getAccountStatement, {
      cancelToken: source.token,
      params,
    });
    if (responseOK(response)) {
      yield put(accountStatusPreviewSetConsult(response.data));
    } else {
      yield put(accountStatusPreviewClearConsult());
    }
  } catch (error) {
    yield accountStatusPreviewClearConsult();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield accountStatusPreviewClearConsult();
      source.cancel('cancelled Request');
    }

    if (mustRedirect) {
      yield call(
        setTimeout,
        () => {
          messageError('No hay registros de elementos dinámicos en el sistema');
        },
        2
      );
      yield call(history.push, '/account-status/');
    }
  }
}

function* workAccountStatusPreviewRequestPeriodList(action) {
  const { clientData, hideSelectionModal } = action.payload;
  const accountStatementParams = {
    ClientId: clientData.idClient,
    lineId: clientData.lineId,
    subregionId: clientData.subRegionId,
  };
  const existanceParams = {
    SoldTo: clientData.soldTo,
    Line: clientData.lineId,
  };
  //de momento voy a necesitar 3 parametros, el id de cliente, id de linea y el periodo que seleccionaron en el combo
  const logClientData = {
    ClientId: clientData.idClient,
    LineId: clientData.lineId,
    ClientName: clientData.client,
    LineName: clientData.line,
  };
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const response = yield call(getAccountStatementPeriodList, {
      cancelToken: source.token,
      params: accountStatementParams,
    });
    const existancesResponse = yield call(getExistances, {
      cancelToken: source.token,
      params: existanceParams,
    });

    if (responseOK(response)) {
      const { data } = response;

      yield put(accountStatusPreviewSetPeriodList(data));
      yield put(accountStatusPreviewSetCurrentClientData(logClientData));
    } else {
      hideSelectionModal();
      yield put(accountStatusPreviewClearPeriodList());
    }

    if (responseOK(existancesResponse)) {
      yield put(accountStatusPreviewSetGetExistances(existancesResponse.data));
    } else {
      yield put(accountStatusPreviewClearGetExistances());
    }
  } catch (error) {
    hideSelectionModal();
    yield put(accountStatusPreviewClearPeriodList());
    message002();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      hideSelectionModal();
      yield put(accountStatusPreviewClearPeriodList());
      source.cancel('cancelled Request');
    }
  }
}

function* workAccountStatusPreviewRequestRegisterIntoLog(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { params, downloadPdf } = action.payload;
  let shouldDownloadPdf = false;

  try {
    const response = yield call(registerIntoLog, {
      cancelToken: source.token,
      params,
    });
    if (responseOK(response)) {
      if (response.data) {
        shouldDownloadPdf = true;
        message001('La descarga del Estado de Cuenta');
      }
      yield put(accountStatusPreviewSetRegisterIntoLog(response.data));
    } else {
      message002();
      yield put(accountStatusPreviewClearRegisterIntoLog());
    }
  } catch (error) {
    yield accountStatusPreviewClearRegisterIntoLog();
    message002();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield accountStatusPreviewClearRegisterIntoLog();
      source.cancel('cancelled Request');
    }
    if (shouldDownloadPdf) {
      yield call(downloadPdf);
    }
  }
}

export function* watchAccountStatusPreviewRequestConsult() {
  yield takeLatest(ACCOUNT_STATUS_PREVIEW_REQUEST_CONSULT, workAccountStatusPreviewRequestConsult);
}

export function* watchAccountStatusPreviewRequestPeriodList() {
  yield takeLatest(
    ACCOUNT_STATUS_PREVIEW_REQUEST_PERIOD_LIST,
    workAccountStatusPreviewRequestPeriodList
  );
}

export function* watchAccountStatusPreviewRequestRegisterIntoLog() {
  yield takeLatest(
    ACCOUNT_STATUS_PREVIEW_REQUEST_REGISTER_INTO_LOG,
    workAccountStatusPreviewRequestRegisterIntoLog
  );
}
