import React from 'react';
import { Row, Col, Table, Result } from 'antd';

function ConsultTable({
  columns,
  loading,
  noResultsMessage,
  data,
  onChange,
  pagination = true,
  id,
  className,
  footer,
  summary,
}) {
  return (
    <>
      <Row style={{ marginTop: '20px' }} data-testid={'archiveTable'}>
        <Col span={24}>
          <Table
            id={id}
            getPopupContainer={() => document.getElementsByClassName('ant-table-wrapper')[0]}
            locale={{ emptyText: <Result status="500" title={`${noResultsMessage}`} /> }}
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={pagination}
            style={{ minHeight: '300px' }}
            onChange={onChange}
            footer={footer}
            summary={summary}
          />
        </Col>
      </Row>
    </>
  );
}

export default ConsultTable;
