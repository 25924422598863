export const TRADE_AGREEMENTS_VALIDATORS_CATALOG_REQUEST =
  'APP/TRADE_AGREEMENTS_VALIDATORS_CATALOG_REQUEST';
export const TRADE_AGREEMENTS_VALIDATORS_CATALOG_SUCCESS =
  'APP/TRADE_AGREEMENTS_VALIDATORS_CATALOG_SUCCESS';
export const TRADE_AGREEMENTS_VALIDATORS_CATALOG_FAIL =
  'APP/TRADE_AGREEMENTS_VALIDATORS_CATALOG_FAIL';

export function tradeAgreementsValidatorsCatalogRequest(payload) {
  return {
    type: TRADE_AGREEMENTS_VALIDATORS_CATALOG_REQUEST,
    payload: payload,
  };
}

export function tradeAgreementsValidatorsCatalogSuccess(payload) {
  return {
    type: TRADE_AGREEMENTS_VALIDATORS_CATALOG_SUCCESS,
    payload: payload,
  };
}

export function tradeAgreementsValidatorsCatalogFail(payload) {
  return {
    type: TRADE_AGREEMENTS_VALIDATORS_CATALOG_FAIL,
    payload: payload,
  };
}
