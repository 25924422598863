import React, { useEffect } from 'react';
import { Stadistic } from 'SalesChallengeRefactor/components/indicators';
import { getClientSummaryRequest } from 'SalesChallengeRefactor/redux/actions/indicators';
import useWasRendered from 'SalesChallengeRefactor/hooks/useWasRendered';
import { connect } from 'react-redux';
import { roleIdEnum, salesChallengeStatus } from 'common/models/enums';

function ClientSummary(props) {
  const {
    clientSummary,
    zoneId,
    clientId,
    year,
    zoneStatus,
    clientStatus,
    role,
    getClientSummaryRequest: getSummary,
  } = props;

  const { wasRendered, setWasRendered } = useWasRendered();
  useEffect(() => {
    if (!wasRendered.current) {
      if (zoneId && clientId && year) {
        getSummary({ params: { zoneId, clientId, year } });
      }
      setWasRendered(true);
    }
  }, [zoneId, clientId, year, setWasRendered, wasRendered, getSummary]);
  return (
    <>
      <div className="stadistics-root">
        {role === roleIdEnum.SALES_AGENT && clientStatus === salesChallengeStatus.APPROVED && (
          <Stadistic label="Total cliente (USD)" value={clientSummary.zoneAmount} />
        )}
        {role === roleIdEnum.SALES_AGENT && zoneStatus === salesChallengeStatus.APPROVED && (
          <Stadistic label="Total zona (USD)" value={clientSummary.clientAmount} />
        )}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    clientSummary: state?.SALES_CHALLENGE?.indicators?.clientSummary?.data ?? {
      zoneAmount: 0,
      clientAmount: 0,
    },
    zoneId: state?.SALES_CHALLENGE?.query?.zoneId ?? false,
    clientId: state?.SALES_CHALLENGE?.query?.clientId ?? false,
    year: state?.SALES_CHALLENGE?.query?.year ?? false,
  };
}

export default connect(mapStateToProps, { getClientSummaryRequest })(ClientSummary);
