import reducer from './redux-saga/reducers';

import {
  watchBaseInformationMassiveUploadSetAllowUpload,
  watchBaseInformationMassiveUploadRequestVerify,
  watchBaseInformationMassiveUploadRequestUpload,
  watchExistsStatements,
} from './redux-saga/sagas';

export default function getBaseInformationMassiveUploadModule() {
  return {
    id: 'Dynamic_MASSIVE_UPLOAD',
    reducerMap: {
      Dynamic_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchBaseInformationMassiveUploadSetAllowUpload,
      watchBaseInformationMassiveUploadRequestVerify,
      watchBaseInformationMassiveUploadRequestUpload,
      watchExistsStatements,
    ],
  };
}
