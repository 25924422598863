import request from '../../services/request';

export const secondValidationValidUser = (params, token) => {
  ///Agreements/Api/v1/Validators/UserIsValidator/{territoryId}/{clientId}
  return request(
    `/Agreements/Api/v1/Validators/UserIsValidator/${params.territoryId ? params.territoryId : 0}/${
      params.clientId
    }`,
    {
      method: 'GET',
      cancelToken: token,
    }
  );
};
