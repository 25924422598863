import PropTypes from 'prop-types';

/**
 * Role Access Control to display or not components based on a black or white list of status
 */

function StatusAccessControl(props) {
  const {
    children: protectedComponent,
    onNoAccessRender,
    accessType,
    controlStatusList,
    status,
  } = props;

  const checkPermissions = () => {
    let allowAccess;
    if (typeof status === 'number') {
      const statusIsInStatusList = controlStatusList.includes(status);
      if (accessType === 'include') {
        allowAccess = statusIsInStatusList;
      } else if (accessType === 'exclude') {
        allowAccess = !statusIsInStatusList;
      } else {
        allowAccess = false;
      }
    } else {
      allowAccess = false;
    }

    return allowAccess;
  };

  return checkPermissions() ? protectedComponent : onNoAccessRender;
}

StatusAccessControl.propTypes = {
  accessType: PropTypes.oneOf(['include', 'exclude']),
  onNoAccessRender: PropTypes.node,
  status: PropTypes.number,
  controlStatusList: PropTypes.arrayOf(PropTypes.number),
};

StatusAccessControl.defaultProps = {
  accessType: 'include',
  onNoAccessRender: null,
  status: undefined,
  controlStatusList: [],
};

export default StatusAccessControl;
