import React, { useContext, useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getSalesChallengeModule from '../../salesChallengeModule';
import getRootModule from 'RootModule';
import getValidationsModule from 'Validations/getValidationsModule';
import TopFiltersGroupKam from '../../components/TopFiltersGroupKam';
import TopFiltersGroup from '../../components/TopFiltersGroup';
import DeleteProductModal from 'SalesChallengeRefactor/components/DeleteProductModal/DeleteProductModal';
import { layoutContext } from '../../../context';
import { setCurrentModule } from '../../redux/actions/module';
import { cleanInfo } from '../../redux/actions/info';
import { queryClean, querySave } from '../../redux/actions/query';
import ViewsManagment from '../../components/LayoutSalesChallenge/ViewsManagment';
import useUser from 'hooks/useUser';
import { roleIdEnum } from 'common/models/enums';
import './style.css';

function SalesChallenge({ zoneId }) {
  const { onChangeActiveComponent } = useContext(layoutContext);
  const dispatch = useDispatch();
  const user = useUser();
  const { KAM } = roleIdEnum;
  const filterChanged = useCallback(
    (view) => {
      onChangeActiveComponent(view);
      dispatch(cleanInfo());
      dispatch(queryClean());
      dispatch(querySave({ state: 'idle', showBruteValue: true }));
    },
    [dispatch, onChangeActiveComponent]
  );

  useEffect(() => {
    dispatch(cleanInfo());
  }, [dispatch]);

  return (
    <div style={{ padding: '2px 0px 0px 8px' }}>
      <DeleteProductModal />
      {user && (
        <>
          {user?.role?.roleId === KAM && (
            <TopFiltersGroupKam onChangeActiveComponent={filterChanged} />
          )}
          {user?.role?.roleId !== KAM && (
            <TopFiltersGroup onChangeActiveComponent={filterChanged} />
          )}
        </>
      )}
      <div style={{ marginTop: '1rem' }}>
        <ViewsManagment />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    verify: state?.SALES_CHALLENGE_MASSIVE_UPLOAD?.verify,
    zoneId: state?.SALES_CHALLENGE?.query?.zoneId ?? null,
  };
};

const ConnectedSaleChallenge = connect(mapStateToProps, {
  setCurrentModule,
})(SalesChallenge);
const DynamicModule = () => (
  <DynamicModuleLoader
    modules={[getRootModule(), getSalesChallengeModule(), getValidationsModule()]}
  >
    <ConnectedSaleChallenge />
  </DynamicModuleLoader>
);

export default DynamicModule;
