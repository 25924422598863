import { formatCommas } from 'utils';

const formatPercentage = (percentageString) => {
  let numberStr = '';
  let percentCharPosition = percentageString.indexOf('%');
  let hasPercentChar = percentCharPosition > -1;
  if (hasPercentChar) {
    let slicedString = percentageString.substring(0, percentCharPosition);
    numberStr = formatCommas(slicedString);
  }
  if (!hasPercentChar) {
    numberStr = formatCommas(percentageString);
  }
  return numberStr + '%';
};

export default formatPercentage;
