export const CLEAN_REGISTER_SALE = 'CLEAN_REGISTER_SALE/SALES-CHALLENGE';
export function cleanRegisterSale(payload) {
  return {
    type: CLEAN_REGISTER_SALE,
    payload,
  };
}

export const GET_DAYS_LEFT_REQUEST = 'GET_DAYS_LEFT_REQUEST/SALES-CHALLENGE';
export const GET_DAYS_LEFT_SUCCESS = 'GET_DAYS_LEFT_SUCCESS/SALES-CHALLENGE';
export const GET_DAYS_LEFT_FAIL = 'GET_DAYS_LEFT_FAIL/SALES-CHALLENGE';
export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST/SALES-CHALLENGE';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS/SALES-CHALLENGE';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL/SALES-CHALLENGE';
export const GET_PRODUCTS_PRICES_REQUEST = 'GET_PRODUCTS_PRICES_REQUEST/SALES-CHALLENGE';
export const GET_PRODUCTS_PRICES_SUCCESS = 'GET_PRODUCTS_PRICES_SUCCESS/SALES-CHALLENGE';
export const GET_PRODUCTS_PRICES_FAIL = 'GET_PRODUCTS_PRICES_FAIL/SALES-CHALLENGE';
export const GET_CHALLENGE_POG_REQUEST = 'GET_CHALLENGE_POG_REQUEST/SALES-CHALLENGE';
export const GET_CHALLENGE_POG_SUCCESS = 'GET_CHALLENGE_POG_SUCCESS/SALES-CHALLENGE';
export const GET_CHALLENGE_POG_FAIL = 'GET_CHALLENGE_POG_FAIL/SALES-CHALLENGE';
export const ARE_VOLUMES_VALIDS = 'ARE_VOLUMES_VALIDS';
export const SAVE_CHALLENGE_REQUEST = 'SAVE_CHALLENGE_REQUEST/SALES-CHALLENGE';
export const SAVE_CHALLENGE_SUCCESS = 'SAVE_CHALLENGE_SUCCESS/SALES-CHALLENGE';
export const SAVE_CHALLENGE_FAIL = 'SAVE_CHALLENGE_FAIL/SALES-CHALLENGE';

export function areVolumesValids(payload) {
  return {
    type: ARE_VOLUMES_VALIDS,
    payload,
  };
}

export function getDaysLeftRequest(payload) {
  return {
    type: GET_DAYS_LEFT_REQUEST,
    payload,
  };
}

export function getDayLeftSuccess(payload) {
  return {
    type: GET_DAYS_LEFT_SUCCESS,
    payload,
  };
}

export function getDayLeftFail() {
  return {
    type: GET_DAYS_LEFT_FAIL,
  };
}

export function getProductsRequest(payload) {
  return {
    type: GET_PRODUCTS_REQUEST,
    payload,
  };
}

export function getProductsSuccess(payload) {
  return {
    type: GET_PRODUCTS_SUCCESS,
    payload,
  };
}

export function getProductsFail() {
  return {
    type: GET_PRODUCTS_FAIL,
  };
}

export function getProductsPricesRequest(payload) {
  return {
    type: GET_PRODUCTS_PRICES_REQUEST,
    payload,
  };
}

export function getProductsPricesSuccess(payload) {
  return {
    type: GET_PRODUCTS_PRICES_SUCCESS,
    payload,
  };
}

export function getProductsPricesFail() {
  return {
    type: GET_PRODUCTS_PRICES_FAIL,
  };
}

export function getChallengePogRequest(payload) {
  return {
    type: GET_CHALLENGE_POG_REQUEST,
    payload,
  };
}

export function getChallengePogSuccess(payload) {
  return {
    type: GET_CHALLENGE_POG_SUCCESS,
    payload,
  };
}

export function getChallengePogFail() {
  return {
    type: GET_CHALLENGE_POG_FAIL,
  };
}

export function saveChallengeRequest(payload) {
  return {
    type: SAVE_CHALLENGE_REQUEST,
    payload,
  };
}

export function saveChallengeSuccess(payload) {
  return {
    type: SAVE_CHALLENGE_SUCCESS,
    payload,
  };
}

export function saveChallengeFail() {
  return {
    type: SAVE_CHALLENGE_FAIL,
  };
}
