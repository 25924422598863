import React, { useState, useEffect } from 'react';
import { getClientsByPlacement } from '../services';
import { Select, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;

export const getIdsOfArrayOfStrings = (array) =>
  array.map((string) => JSON.parse(string).clienteId);

export const handleClientSelect = (option, clients, onChange, setSelectedClient) => {
  let selection = option;
  if (option.includes('"select all"')) {
    let ids;
    if (option.length > 1) {
      ids = [];
      selection = [];
    } else {
      let stringOptions = clients.map((element) => JSON.stringify(element));
      ids = getIdsOfArrayOfStrings(stringOptions);
      selection = stringOptions;
    }
    onChange({ client: ids });
  } else {
    onChange({ client: getIdsOfArrayOfStrings(option) });
  }
  setSelectedClient(selection);
};

function ClientSelect({ disabled, onChange, be, client, placeholder }) {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(undefined);
  const { t } = useTranslation(['common']);
  useEffect(() => {
    let ids = be.lobs;
    if (be.lobs && typeof be.lobs[0] === 'object') {
      ids = be.lobs.map((c) => c.id);
    }
    getClientsByPlacement(ids).then((res) => {
      setClients(res);
      if (client) {
        client && setSelectedClient(client.map((el) => JSON.stringify(el)));
      }
    });
  }, [be, client]);
  useEffect(() => {
    setSelectedClient(undefined);
  }, [disabled]);

  return (
    <>
      <Col id="clientSelect">
        <p style={{ margin: '0px', color: '#21A0D2' }}>*{t('select.clients')}:</p>
        <Select
          virtual={false}
          style={{ width: '200px' }}
          placeholder={placeholder}
          value={selectedClient}
          mode="multiple"
          onChange={(option) => handleClientSelect(option, clients, onChange, setSelectedClient)}
          disabled={disabled}
          showSearch={false}
          getPopupContainer={(trigger) => trigger.parentNode}
          maxTagCount={1}
          maxTagTextLength={6}
        >
          {clients && clients.length > 0 && (
            <Option value={JSON.stringify('select all')} key={99999}>
              {t('selectAll')}
            </Option>
          )}
          {clients &&
            clients.length > 0 &&
            clients.map((customer, key) => (
              <Option value={JSON.stringify(customer)} key={key}>
                {customer.name}
              </Option>
            ))}
        </Select>
      </Col>
    </>
  );
}

ClientSelect.propTypes = {
  placeholder: PropTypes.string,
};

ClientSelect.defaultProps = {
  placeholder: 'Seleccionar',
};

export default ClientSelect;
