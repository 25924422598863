import request from 'services/request';
import { paramsSerializer } from 'RootModule/services/catalogs';

export async function AccountStatusConsult({ cancelToken, params }) {
  let uri = '/AccountStatement/Api/v1/Pog';
  return request(uri, {
    method: 'GET',
    cancelToken,
    params,
    paramsSerializer,
  });
}

export async function checkExistDataPog({ cancelToken }) {
  let uri = '/AccountStatement/Api/v1/Pog/ExistInfoPOG';
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
  });
}
