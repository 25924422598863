import {
  DOWNLOAD_TRADE_AGREEMENTS_REPORT_REQUEST,
  DOWNLOAD_TRADE_AGREEMENTS_REPORT_SUCCESS,
  DOWNLOAD_TRADE_AGREEMENTS_REPORT_FAIL,
} from '../actions/downloadTradeAgreement';

const initialState = {};

const downloadTradeAgreementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_TRADE_AGREEMENTS_REPORT_REQUEST:
      return {
        ...state,
        getTradeAgreementsReport: {
          isLoading: true,
        },
      };

    case DOWNLOAD_TRADE_AGREEMENTS_REPORT_SUCCESS:
      return {
        ...state,
        getTradeAgreementsReport: {
          isLoading: false,
        },
      };
    case DOWNLOAD_TRADE_AGREEMENTS_REPORT_FAIL:
      return {
        ...state,
        getTradeAgreementsReport: {
          isLoading: false,
        },
      };

    default:
      return state;
  }
};

export default downloadTradeAgreementsReducer;
