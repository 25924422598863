import React, { useState } from 'react';

// antd
import { Modal, Button } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';

//Styles
import './ExportModalMaps.css';

//Icons
import { IconsMap } from 'Layout/utils';

import html2canvas from 'html2canvas';
import { openNotification } from 'common';
const buttonStyle = {
  width: '24%',
  fontSize: '1.2em',
  height: 'calc(16px + 1.2em)',
  fontWeight: 600,
  marginTop: '15px',
};

const ExportModalMaps = ({ doExport }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const doCapture = () => {
    const mapCapture = document.getElementById('map');
    const tableCapture = document.getElementById('table');
    /* Maps screemshot */
    html2canvas(mapCapture, {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      const date = new Date();
      let formatted_date =
        date.getDate().toString().padStart(2, '0') +
        '/' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '/' +
        date.getFullYear();
      const a = document.createElement('a');

      a.href = canvas.toDataURL();
      a.download = `Dashboard Mapa ${formatted_date}.jpg`;
      a.click();
      openNotification(
        'success',
        'La descarga de información del dashboard mapa se realizo correctamente'
      );
      a.onclose = setIsModalVisible(false);
    });

    /* Table screemshot */
    html2canvas(tableCapture, {
      allowTaint: true,
      useCORS: true,
      imageTimeout: 0,
    }).then((canvas) => {
      const date = new Date();
      let formatted_date =
        date.getDate().toString().padStart(2, '0') +
        '/' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '/' +
        date.getFullYear();
      const a = document.createElement('a');

      a.href = canvas.toDataURL();
      a.download = `Dashboard Mapa ${formatted_date}.jpg`;
      a.click();
      a.remove();
    });
  };

  const Icon = IconsMap['ExclamationCircleOutlined'];

  return (
    <>
      <Button
        data-html2canvas-ignore={true}
        style={buttonStyle}
        type="primary"
        onClick={showModal}
        icon={<ArrowDownOutlined />}
      >
        Exportar
      </Button>
      <Modal
        title={[
          // eslint-disable-next-line react/jsx-key
          <div className="center-title">
            <Icon className="center-title-Ic" />
            Formato de exportación
          </div>,
        ]}
        maskClosable={true}
        centered
        visible={isModalVisible}
        className="center"
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancel}
        cancelButtonProps={{
          type: 'default',
          style: { backgroundColor: '#0078B3', color: '#ffffff' },
        }}
      >
        <p className="center">Seleccione el formato en el que desea exportar el archivo</p>

        <div className="modal-dash-Btn-c">
          <Button className="img-Btn" onClick={doCapture}>
            Imagen
          </Button>

          <Button className="excel-Btn" onClick={() => doExport()}>
            Excel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export const tableRef = {
  salesChallenge: 'table1',
  salesStatus: 'table2',
};

export default ExportModalMaps;
