/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import { layoutContext } from '../../../context';
import getRootModule from 'RootModule';
import getProductUpdateModule from '../getProductUpdateModule';
import { Form, Modal } from 'antd';
import { CommercialStructure, ProductInformation, ProductTaxes, BothModal } from '../components';
import preventKeysOnNumberInput from 'Products/ProductRegistration/utils/preventKeysOnNumberInput';
import preventDotOnInput from 'Products/ProductRegistration/utils/preventDotOnInput';

import {
  productUpdateSetFormValues,
  productUpdateRequestProductData,
  productUpdateRequestStatusUpdate,
  productUpdateRequestProductUpdate,
} from '../redux-saga/actions';
import { ActionButtons } from 'common';
import { message119 } from 'utils/messages';

const ProductUpdate = (props) => {
  const {
    productUpdateSetFormValues: setFormValues,
    productUpdateRequestProductData: requestProductData,
    productUpdateRequestStatusUpdate: requestStatusUpdate,
    productUpdateRequestProductUpdate: requestProductUpdate,
    formValues,
    productData,
    statusUpdate,
  } = props;
  const { id } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const { onChangeActiveComponent } = useContext(layoutContext);

  const enabled = productData?.enabled;
  const inUse = productData?.inUse;

  const [orangeSwitch, setOrangeSwitch] = useState(false);
  const [visibleBothModal, setVisibleBothModal] = useState(false);

  const [statusSwitch, setStatusSwitch] = useState({
    checked: false,
    disabled: true,
  });
  const [nameHasChanged, setNameHasChanged] = useState(false);

  useEffect(() => {
    requestProductData(id);
    onChangeActiveComponent('ProductUpdate');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (statusUpdate) {
      requestProductData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusUpdate]);

  useEffect(() => {
    if (productData) {
      const {
        lineId,
        subregionId,
        countries,
        productNumber,
        name,
        family,
        clasification,
        packaging,
        measurementUnit,
        unitsPerPackage,
        minimum,
        moq,
        currencyId,
        taxes,
      } = productData;
      let iva, ieps;

      taxes.forEach((element) => {
        const { tax, value } = element;
        if (tax === 'IVA') {
          iva = value;
        }
        // eslint-disable-next-line default-case
        switch (tax) {
          case 'IVA':
            iva = value;
            break;
          case 'IEPS':
            ieps = value;
            break;
        }
      });

      form.setFieldsValue({
        lineId,
        subregionId,
        countries: countries.map(({ countryId }) => countryId),
        productNumber,
        name,
        family,
        clasification,
        packaging,
        measurementUnit,
        unitsPerPackage,
        minimum,
        moq,
        currencyId,
        iva,
        ieps,
      });

      setStatusSwitch({
        checked: getChecked(),
        disabled: getDisabled(),
      });
      setOrangeSwitch(enabled === null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData, enabled, form, inUse]);

  function getChecked() {
    return enabled !== null ? !enabled : false;
  }

  function getDisabled() {
    if (enabled === null) {
      return false;
    }

    return inUse;
  }

  const handleFormValuesChange = (single, all) => {
    setFormValues(form.getFieldsValue(true));
  };

  useEffect(() => {
    if (nameHasChanged) {
      message119();
    }
  }, [nameHasChanged]);

  function onChangeName(e) {
    const originalName = productData.name;
    const changedName = e.target.value;
    if (originalName === changedName) {
      return setNameHasChanged(false);
    }
    setNameHasChanged(true);
  }

  function handleFocusNumberInput(e) {
    window.addEventListener('keydown', preventDotOnInput);
  }
  function handleBlurNumberInput(e) {
    window.removeEventListener('keydown', preventDotOnInput);
  }

  function handleFocusFloatInput(e) {
    window.addEventListener('keydown', preventKeysOnNumberInput);
  }
  function handleBlurFloatInput(e) {
    window.removeEventListener('keydown', preventKeysOnNumberInput);
  }

  function onSubmit() {
    requestProductUpdate({ data: getSubmitParams(), history });
  }
  function getSubmitParams() {
    const { lineId, subregionId, productNumber } = productData;
    const values = form.getFieldsValue(true);
    const {
      countries,
      name,
      family,
      clasification,
      packaging,
      measurementUnit,
      unitsPerPackage,
      minimum,
      moq,
      currencyId,
      iva,
      ieps,
    } = values;

    return {
      subRegionId: subregionId,
      lineId,
      productNumber,
      countries,
      name,
      family,
      clasification,
      packaging,
      measurementUnit,
      unitsPerPackage,
      minimum,
      moq,
      currencyId,
      iva,
      ieps,
    };
  }

  function getDisabledSubmit() {
    let name,
      family,
      clasification,
      packaging,
      measurementUnit,
      unitsPerPackage,
      minimum,
      moq,
      currencyId;
    if (formValues) {
      name = formValues.name;
      family = formValues.family;
      clasification = formValues.clasification;
      packaging = formValues.packaging;
      measurementUnit = formValues.measurementUnit;
      unitsPerPackage = formValues.unitsPerPackage;
      minimum = formValues.minimum;
      moq = formValues.moq;
      currencyId = formValues.currencyId;
    }

    const required = [
      name,
      family,
      clasification,
      packaging,
      measurementUnit,
      unitsPerPackage,
      minimum,
      moq,
      currencyId,
    ];

    if (
      !checkForCurrency() ||
      // eslint-disable-next-line no-extra-boolean-cast
      required.filter((item) => {
        if (item === null || item === '') {
          return true;
        }
        return false;
      }).length > 0
    ) {
      return true;
    }
    if (enabled === null) {
      return false;
    }
    return !enabled;
  }

  function checkForCurrency() {
    const values = form.getFieldsValue('currencyId');
    const formCurrencyId = values?.currencyId;
    return formCurrencyId !== undefined;
  }
  function getDisabledForm() {
    if (enabled === true || enabled === null) {
      return false;
    }
    return true;
  }

  function countryHandleChange(current, { options }) {
    const allActived = current.find((val) => val === 'ALL');

    const defaultCountries = productData.countries.map(({ countryId }) => countryId);

    if (allActived && current.length < options.length + 1) {
      form.setFieldsValue({
        countries: options.map(({ key }) => key),
      });
    } else if (allActived && current.length === options.length + 1) {
      form.setFieldsValue({
        countries: defaultCountries,
      });
    } else {
      form.setFieldsValue({
        countries: Array.from(new Set([...defaultCountries, ...current])),
      });
    }
  }

  function formCancel() {
    history.push('/catalog/products');
  }
  /**/
  function activationDeactivationtModal(action) {
    const message = action
      ? `¿Está seguro que desea activar el producto para todos los países?`
      : `¿Está seguro que desea desactivar el producto para todos los países?`;
    Modal.confirm({
      title: 'Confirmar acción',
      content: message,
      onOk() {
        changeProductActivation(action);
      },
    });
  }

  function openInUseModal() {
    setVisibleBothModal(true);
  }

  const statusChange = () => {
    if (enabled === null) {
      openInUseModal();
      return;
    }
    activationDeactivationtModal(!enabled);
  };

  function inUseModalCancel() {
    setVisibleBothModal(false);
  }

  function changeProductActivation(bool) {
    setVisibleBothModal(false);
    requestStatusUpdate({
      data: {
        subRegionId: productData.subregionId,
        lineId: productData.lineId,
        productNumber: productData.productNumber,
        enabled: bool,
      },
      history,
    });
  }

  function inUseModalchangeActivation(bool) {
    setVisibleBothModal(false);
    activationDeactivationtModal(bool);
  }
  /* */
  return (
    <>
      <Form form={form} onValuesChange={handleFormValuesChange}>
        <CommercialStructure
          businessLineId={productData?.lineId}
          subregionId={productData?.subregionId}
          countryDisabled={getDisabledForm()}
          countryHandleChange={countryHandleChange}
        />
        <ProductInformation
          disabled={getDisabledForm()}
          onFocusNumber={handleFocusNumberInput}
          onBlurNumber={handleBlurNumberInput}
          onChangeName={onChangeName}
        />

        <ProductTaxes
          disabled={getDisabledForm()}
          onFocusNumber={handleFocusFloatInput}
          onBlurNumber={handleBlurFloatInput}
        />
        <BothModal
          visible={visibleBothModal}
          onCancel={inUseModalCancel}
          activate={() => inUseModalchangeActivation(true)}
          deactivate={() => inUseModalchangeActivation(false)}
        />
        <ActionButtons
          onCancel={formCancel}
          onSubmit={onSubmit}
          disabled={getDisabledSubmit()}
          statusSwitch={statusSwitch}
          switchNeither={orangeSwitch}
          statusChange={statusChange}
          buttons={['cancel', 'submit']}
        />
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formValues: state?.PRODUCT_UPDATE?.formValues,
    productData: state?.PRODUCT_UPDATE?.productData,
    statusUpdate: state?.PRODUCT_UPDATE?.statusUpdate ?? false,
  };
};

const ConnectedProductUpdate = connect(mapStateToProps, {
  productUpdateSetFormValues,
  productUpdateRequestProductData,
  productUpdateRequestStatusUpdate,
  productUpdateRequestProductUpdate,
})(ProductUpdate);

const ProductUpdateWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getProductUpdateModule()]}>
      <ConnectedProductUpdate />
    </DynamicModuleLoader>
  );
};
export default ProductUpdateWithModules;
