import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import BModalHistory from 'common/components/BModalHistory';
import { tradeCommentsRequest } from 'TradeAgreements/redux/actions/registerTrade';

function HistoryAction(props) {
  const {
    tradeCommentsRequest: getHistory,
    tradeHistory,
    year,
    clientId,
    territoryId,
    statusClientId,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (year && clientId) {
      getHistory({ params: { year, clientId, territoryId } });
    }
  }, [getHistory, year, clientId, territoryId, isOpen, statusClientId]);
  return (
    <>
      {tradeHistory && tradeHistory.length > 0 && (
        <BModalHistory dataSource={tradeHistory} setIsOpen={setIsOpen} isOpen={isOpen} />
      )}
      {(!tradeHistory || tradeHistory.length === 0) && null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    tradeHistory: state?.TRADE_AGREEMENTS?.registerTrade?.historyTrade?.data ?? false,
    year: state?.TRADE_AGREEMENTS?.query?.year,
    clientId: state?.TRADE_AGREEMENTS?.query?.clientId,
    territoryId: state?.TRADE_AGREEMENTS?.query?.territoryId ?? '',
    statusClientId: state.TRADE_AGREEMENTS?.infoForAgreement?.clientInfo?.statusId,
  };
}

export default connect(mapStateToProps, { tradeCommentsRequest })(HistoryAction);
