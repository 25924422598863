import request from 'services/request';

export async function pricesMassiveUpload({ cancelToken, subregion, data }) {
  let uri = `/Catalogs/Api/v1/BaseInformationAgreement/MassLoadPriceList${subregion}`;
  return request(uri, {
    method: 'POST',
    cancelToken,
    data,
  });
}
