import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  PRODUCT_REGISTRATION_SET_BUSINESS_LINE_ID,
  PRODUCT_REGISTRATION_SET_SUBREGION_ID,
  PRODUCT_REGISTRATION_SET_COUNTRY_ID,
  PRODUCT_REGISTRATION_SET_PRODUCT_NUMBER,
  PRODUCT_REGISTRATION_SET_NAME,
  PRODUCT_REGISTRATION_SET_FAMILY,
  PRODUCT_REGISTRATION_SET_CLASIFICATION,
  PRODUCT_REGISTRATION_SET_PRESENTATION,
  PRODUCT_REGISTRATION_SET_MEASUREMENT_UNIT,
  PRODUCT_REGISTRATION_SET_UNITS_PER_PACK,
  PRODUCT_REGISTRATION_SET_MINIMUM_ORDER_REQUIRED,
  PRODUCT_REGISTRATION_SET_MOQ,
  PRODUCT_REGISTRATION_SET_CURRENCY,
  PRODUCT_REGISTRATION_SET_IVA,
  PRODUCT_REGISTRATION_SET_IEPS,
  PRODUCT_REGISTRATION_REQUEST_PRODUCT_EXISTENCE,
  PRODUCT_REGISTRATION_REQUEST_REGISTRATION,
  productRegistrationSetBusinessLineId,
  productRegistrationClearBusinessLineId,
  productRegistrationSetSubregionId,
  productRegistrationClearSubregionId,
  productRegistrationSetCountryId,
  productRegistrationClearCountryId,
  productRegistrationSetProductNumber,
  productRegistrationClearProductNumber,
  productRegistrationSetName,
  productRegistrationClearName,
  productRegistrationSetFamily,
  productRegistrationClearFamily,
  productRegistrationSetClasification,
  productRegistrationClearClasification,
  productRegistrationSetPresentation,
  productRegistrationClearPresentation,
  productRegistrationSetMeasurementUnit,
  productRegistrationClearMeasurementUnit,
  productRegistrationSetUnitsPerPack,
  productRegistrationClearUnitsPerPack,
  productRegistrationSetMinimumOrderRequired,
  productRegistrationClearMinimumOrderRequired,
  productRegistrationSetMoq,
  productRegistrationClearMoq,
  productRegistrationSetCurrency,
  productRegistrationClearCurrency,
  productRegistrationSetIva,
  productRegistrationClearIva,
  productRegistrationSetIeps,
  productRegistrationClearIeps,
  productRegistrationSetProductExistence,
  productRegistrationClearProductExistence,
  productRegistrationSetRegistration,
  productRegistrationClearRegistration,
} from '../actions';

import instance from 'services/request';
import { responseOK } from 'SalesChallengeRefactor/utils';

import { messageSuccess, message046 } from 'utils/messages';

import { getProductExistence, registerProduct } from 'Products/ProductRegistration/services';

function* workProductRegistrationSetBusinessLineId(action) {
  try {
    yield productRegistrationSetBusinessLineId(action.payload);
  } catch (error) {
    yield productRegistrationClearBusinessLineId();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearBusinessLineId();
    }
  }
}

function* workProductRegistrationSetSubregionId(action) {
  try {
    yield productRegistrationSetSubregionId(action.payload);
  } catch (error) {
    yield productRegistrationClearSubregionId();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearSubregionId();
    }
  }
}

function* workProductRegistrationSetCountryId(action) {
  try {
    yield productRegistrationSetCountryId(action.payload);
  } catch (error) {
    yield productRegistrationClearCountryId();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearCountryId();
    }
  }
}

function* workProductRegistrationSetProductNumber(action) {
  try {
    yield productRegistrationSetProductNumber(action.payload);
  } catch (error) {
    yield productRegistrationClearProductNumber();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearProductNumber();
    }
  }
}

function* workProductRegistrationSetName(action) {
  try {
    yield productRegistrationSetName(action.payload);
  } catch (error) {
    yield productRegistrationClearName();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearName();
    }
  }
}

function* workProductRegistrationSetFamily(action) {
  try {
    yield productRegistrationSetFamily(action.payload);
  } catch (error) {
    yield productRegistrationClearFamily();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearFamily();
    }
  }
}

function* workProductRegistrationSetClasification(action) {
  try {
    yield productRegistrationSetClasification(action.payload);
  } catch (error) {
    yield productRegistrationClearClasification();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearClasification();
    }
  }
}

function* workProductRegistrationSetPresentation(action) {
  try {
    yield productRegistrationSetPresentation(action.payload);
  } catch (error) {
    yield productRegistrationClearPresentation();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearPresentation();
    }
  }
}

function* workProductRegistrationSetMeasurementUnit(action) {
  try {
    yield productRegistrationSetMeasurementUnit(action.payload);
  } catch (error) {
    yield productRegistrationClearMeasurementUnit();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearMeasurementUnit();
    }
  }
}

function* workProductRegistrationSetUnitsPerPack(action) {
  try {
    yield productRegistrationSetUnitsPerPack(action.payload);
  } catch (error) {
    yield productRegistrationClearUnitsPerPack();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearUnitsPerPack();
    }
  }
}

function* workProductRegistrationSetMinimumOrderRequired(action) {
  try {
    yield productRegistrationSetMinimumOrderRequired(action.payload);
  } catch (error) {
    yield productRegistrationClearMinimumOrderRequired();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearMinimumOrderRequired();
    }
  }
}

function* workProductRegistrationSetMoq(action) {
  try {
    yield productRegistrationSetMoq(action.payload);
  } catch (error) {
    yield productRegistrationClearMoq();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearMoq();
    }
  }
}

function* workProductRegistrationSetCurrency(action) {
  try {
    yield productRegistrationSetCurrency(action.payload);
  } catch (error) {
    yield productRegistrationClearCurrency();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearCurrency();
    }
  }
}

function* workProductRegistrationSetIva(action) {
  try {
    yield productRegistrationSetIva(action.payload);
  } catch (error) {
    yield productRegistrationClearIva();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearIva();
    }
  }
}

function* workProductRegistrationSetIeps(action) {
  try {
    yield productRegistrationSetIeps(action.payload);
  } catch (error) {
    yield productRegistrationClearIeps();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearIeps();
    }
  }
}

function* workProductRegistrationRequestProductExistence(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getProductExistence, {
      cancelToken: source.token,
      params: action.payload,
      mode: 'multiple',
    });
    if (responseOK(response)) {
      yield put(productRegistrationSetProductExistence(response.data));
      if (response.data === true) {
        yield call(message046);
      }
    } else {
      yield put(productRegistrationClearProductExistence());
    }
  } catch (error) {
    yield productRegistrationClearProductExistence();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearProductExistence();
      source.cancel('cancelled Request');
    }
  }
}
/** */
function* workProductRegistrationRequestRegistration({ payload }) {
  const { data, history } = payload;
  // {
  //   productNumber: productNumber,
  //   lineId: businessLineId,
  //   subregionId: subregionId,
  //   countries: countryId,
  // }
  const { productNumber, lineId, subRegionId, countries } = data;

  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const responseExistence = yield call(getProductExistence, {
      cancelToken: source.token,
      params: {
        productNumber: productNumber,
        lineId: lineId,
        subregionId: subRegionId,
        countries: countries,
      },
      mode: 'multiple',
    });
    if (responseOK(responseExistence)) {
      if (!responseExistence.data) {
        const responseRegister = yield call(registerProduct, {
          cancelToken: source.token,
          data,
        });
        if (responseOK(responseRegister)) {
          messageSuccess(responseRegister.message);
          yield put(productRegistrationSetRegistration(responseRegister.data));
          setTimeout(() => {
            if (history) history.push('/catalog/products');
          }, 500);
        } else {
          yield put(productRegistrationClearRegistration());
        }
      } else {
        message046();
        yield productRegistrationClearRegistration();
      }
    } else {
      yield productRegistrationClearRegistration();
    }
  } catch (error) {
    yield productRegistrationClearRegistration();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productRegistrationClearRegistration();
      source.cancel('cancelled Request');
    }
  }
}
// function* workProductRegistrationRequestRegistration({ payload }) {
//   const { data, history } = payload;
//   const CancelToken = instance.CancelToken;
//   const source = CancelToken.source();
//   try {
//     const response = yield call(registerProduct, {
//       cancelToken: source.token,
//       data,
//     });
//     if (responseOK(response)) {
//       message001('Registro de producto');
//       yield put(productRegistrationSetRegistration(response.data));
//       setTimeout(() => {
//         if (history) history.push('/catalog/products');
//       }, 500);
//     } else {
//       yield put(productRegistrationClearRegistration());
//     }
//   } catch (error) {
//     yield productRegistrationClearRegistration();
//     console.error(error);
//   } finally {
//     if (yield cancelled()) {
//       yield productRegistrationClearRegistration();
//       source.cancel('cancelled Request');
//     }
//   }
// }

/** */
export function* watchProductRegistrationSetBusinessLineId() {
  yield all([
    takeEvery(PRODUCT_REGISTRATION_SET_BUSINESS_LINE_ID, workProductRegistrationSetBusinessLineId),
  ]);
}

export function* watchProductRegistrationSetSubregionId() {
  yield all([
    takeEvery(PRODUCT_REGISTRATION_SET_SUBREGION_ID, workProductRegistrationSetSubregionId),
  ]);
}

export function* watchProductRegistrationSetCountryId() {
  yield all([takeEvery(PRODUCT_REGISTRATION_SET_COUNTRY_ID, workProductRegistrationSetCountryId)]);
}

export function* watchProductRegistrationSetProductNumber() {
  yield all([
    takeEvery(PRODUCT_REGISTRATION_SET_PRODUCT_NUMBER, workProductRegistrationSetProductNumber),
  ]);
}

export function* watchProductRegistrationSetName() {
  yield all([takeEvery(PRODUCT_REGISTRATION_SET_NAME, workProductRegistrationSetName)]);
}

export function* watchProductRegistrationSetFamily() {
  yield all([takeEvery(PRODUCT_REGISTRATION_SET_FAMILY, workProductRegistrationSetFamily)]);
}

export function* watchProductRegistrationSetClasification() {
  yield all([
    takeEvery(PRODUCT_REGISTRATION_SET_CLASIFICATION, workProductRegistrationSetClasification),
  ]);
}

export function* watchProductRegistrationSetPresentation() {
  yield all([
    takeEvery(PRODUCT_REGISTRATION_SET_PRESENTATION, workProductRegistrationSetPresentation),
  ]);
}

export function* watchProductRegistrationSetMeasurementUnit() {
  yield all([
    takeEvery(PRODUCT_REGISTRATION_SET_MEASUREMENT_UNIT, workProductRegistrationSetMeasurementUnit),
  ]);
}

export function* watchProductRegistrationSetUnitsPerPack() {
  yield all([
    takeEvery(PRODUCT_REGISTRATION_SET_UNITS_PER_PACK, workProductRegistrationSetUnitsPerPack),
  ]);
}

export function* watchProductRegistrationSetMinimumOrderRequired() {
  yield all([
    takeEvery(
      PRODUCT_REGISTRATION_SET_MINIMUM_ORDER_REQUIRED,
      workProductRegistrationSetMinimumOrderRequired
    ),
  ]);
}

export function* watchProductRegistrationSetMoq() {
  yield all([takeEvery(PRODUCT_REGISTRATION_SET_MOQ, workProductRegistrationSetMoq)]);
}

export function* watchProductRegistrationSetCurrency() {
  yield all([takeEvery(PRODUCT_REGISTRATION_SET_CURRENCY, workProductRegistrationSetCurrency)]);
}

export function* watchProductRegistrationSetIva() {
  yield all([takeEvery(PRODUCT_REGISTRATION_SET_IVA, workProductRegistrationSetIva)]);
}

export function* watchProductRegistrationSetIeps() {
  yield all([takeEvery(PRODUCT_REGISTRATION_SET_IEPS, workProductRegistrationSetIeps)]);
}

export function* watchProductRegistrationRequestProductExistence() {
  yield all([
    takeEvery(
      PRODUCT_REGISTRATION_REQUEST_PRODUCT_EXISTENCE,
      workProductRegistrationRequestProductExistence
    ),
  ]);
}
export function* watchProductRegistrationRequestRegistration() {
  yield all([
    takeEvery(
      PRODUCT_REGISTRATION_REQUEST_REGISTRATION,
      workProductRegistrationRequestRegistration
    ),
  ]);
}
