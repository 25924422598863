import { Modal, Row, Col, Button } from 'antd';
import React from 'react';
import { IconsMap } from 'Layout/utils';
import 'Dashboards/Modal/modal.css';

const ExportModal = (props) => {
  const { visible, onCancel, downloadPng, downloadExcel } = props;
  const Icon = IconsMap['ExclamationCircleOutlined'];
  return (
    <Modal
      title={
        <div className="center-title">
          <Icon className="center-title-Ic" />
          Formato de exportación
        </div>
      }
      visible={visible}
      okButtonProps={{ style: { display: 'none' } }}
      onCancel={onCancel}
      cancelButtonProps={{ type: 'default' }}
    >
      <Row>
        <Col span={24}>
          {' '}
          <p className="center">Seleccione el formato en el que desea exportar el archivo</p>
        </Col>
        <Col span={24}>
          <div className="modal-dash-Btn-c">
            <Button className="excel-Btn" onClick={downloadExcel}>
              Excel
            </Button>
            <Button className="img-Btn" onClick={downloadPng}>
              Imagen
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default ExportModal;
