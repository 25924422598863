import {
  PRODUCT_UPDATE_SET_FORM_VALUES,
  PRODUCT_UPDATE_CLEAR_FORM_VALUES,
  PRODUCT_UPDATE_REQUEST_PRODUCT_DATA,
  PRODUCT_UPDATE_SET_PRODUCT_DATA,
  PRODUCT_UPDATE_CLEAR_PRODUCT_DATA,
  PRODUCT_UPDATE_REQUEST_STATUS_UPDATE,
  PRODUCT_UPDATE_SET_STATUS_UPDATE,
  PRODUCT_UPDATE_CLEAR_STATUS_UPDATE,
  PRODUCT_UPDATE_REQUEST_PRODUCT_UPDATE,
  PRODUCT_UPDATE_SET_PRODUCT_UPDATE,
  PRODUCT_UPDATE_CLEAR_PRODUCT_UPDATE,
} from '../actions';

function productUpdateReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case PRODUCT_UPDATE_SET_FORM_VALUES:
      return {
        ...state,
        isLoading: false,
        formValues: action.payload,
      };

    case PRODUCT_UPDATE_CLEAR_FORM_VALUES:
      return {
        ...state,
        isLoading: false,
        formValues: null,
      };

    case PRODUCT_UPDATE_REQUEST_PRODUCT_DATA:
      return {
        ...state,
        isLoading: true,
        productData: null,
      };

    case PRODUCT_UPDATE_SET_PRODUCT_DATA:
      return {
        ...state,
        isLoading: false,
        productData: action.payload,
      };

    case PRODUCT_UPDATE_CLEAR_PRODUCT_DATA:
      return {
        ...state,
        isLoading: false,
        productData: null,
      };
    case PRODUCT_UPDATE_REQUEST_STATUS_UPDATE:
      return {
        ...state,
        isLoading: true,
        statusUpdate: null,
      };

    case PRODUCT_UPDATE_SET_STATUS_UPDATE:
      return {
        ...state,
        isLoading: false,
        statusUpdate: action.payload,
      };

    case PRODUCT_UPDATE_CLEAR_STATUS_UPDATE:
      return {
        ...state,
        isLoading: false,
        statusUpdate: null,
      };

    case PRODUCT_UPDATE_REQUEST_PRODUCT_UPDATE:
      return {
        ...state,
        isLoading: true,
        productUpdate: null,
      };

    case PRODUCT_UPDATE_SET_PRODUCT_UPDATE:
      return {
        ...state,
        isLoading: false,
        productUpdate: action.payload,
      };

    case PRODUCT_UPDATE_CLEAR_PRODUCT_UPDATE:
      return {
        ...state,
        isLoading: false,
        productUpdate: null,
      };

    default:
      return state;
  }
}

export default productUpdateReducer;
