/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { GrDocumentPdf } from 'react-icons/gr';

const DownloadButtonAnchor = ({
  mustShow,
  btnClasses,
  iconStyle,
  href,
  fileId,
  dateRegistered,
}) => {
  const download = (url) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function (e) {
      if (this.status === 200) {
        var myblob = this.response;
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(myblob);
        link.download = 'Estado de cuenta_' + dateRegistered + '.pdf';
        link.click();
      }
    };
    xhr.send();
  };
  return (
    <>
      {mustShow && (
        <div
          className={btnClasses}
          onClick={() => {
            download(href);
          }}
        >
          <GrDocumentPdf className={iconStyle} />
          Descargar
        </div>
      )}
    </>
  );
};

export default DownloadButtonAnchor;
