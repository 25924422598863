export const POST_ONE_DETAIL_PRICES_REQUEST = 'POST_ONE_DETAIL_PRICES_REQUEST';
export const POST_ONE_DETAIL_PRICES_SUCCESS = 'POST_ONE_DETAIL_PRICES_SUCCESS';
export const POST_ONE_DETAIL_PRICES_CLEAN = 'POST_ONE_DETAIL_PRICES_CLEAN';

export const GET_DETAIL_PRICES_REQUEST = 'GET_DETAIL_PRICES_REQUEST';
export const GET_DETAIL_PRICES_SUCCESS = 'GET_DETAIL_PRICES_SUCCESS';
export const GET_DETAIL_PRICES_CLEAN = 'GET_DETAIL_PRICES_CLEAN';

//POST_ONE_DETAIL_PRICES_REQUEST
export function postOneDetailPricesRequest(payload) {
  return {
    type: POST_ONE_DETAIL_PRICES_REQUEST,
    payload: payload,
  };
}
export function postOneDetailPricesSuccess(payload) {
  return {
    type: POST_ONE_DETAIL_PRICES_SUCCESS,
    payload: payload,
  };
}

export function postOneDetailPricesClean() {
  return {
    type: POST_ONE_DETAIL_PRICES_CLEAN,
  };
}

//GET_DETAIL_PRICES_REQUEST
export function getDetailPricesRequest(payload) {
  return {
    type: GET_DETAIL_PRICES_REQUEST,
    payload: payload,
  };
}

export function getCheckExistenceSuccess(payload) {
  return {
    type: GET_DETAIL_PRICES_SUCCESS,
    payload: payload,
  };
}

export function getCheckExistenceClean() {
  return {
    type: GET_DETAIL_PRICES_CLEAN,
  };
}
