import React from 'react';
import { Spin } from 'antd';
import Styles from './loading.module.css';

export const Loading = ({ loading }) => {
  return (
    <>
      {loading === true && (
        <div className={Styles[`container-loading`]}>
          <Spin />
        </div>
      )}
    </>
  );
};
