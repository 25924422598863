import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '../../../common';
import Modal from '../../Modal/modal';
import { Result } from 'antd';
import SidebarComercialAgreement from './SidebarComercialAgreement';
import { borderClasses } from '../../SalesChallenge/salesChallenge';

export const ReportTableAgreementComponent = ({
  loading,
  columns,
  dataSource,
  filters,
  query,
  tableId,
  changeFiltersFn,
  processYear,
  fileName,
  queryFormPDF,
}) => {
  const renderTitleDate = (title) => {
    if (
      title === 'Acuerdo comercial ' ||
      title === 'Desafío de ventas ' ||
      title === 'Ventas Históricas '
    ) {
      if (title.includes('Históricas')) {
        return title + (processYear?.toString() ? (processYear - 1)?.toString() : '');
      }
      return title + (processYear?.toString() ? processYear?.toString() : '');
    } else {
      return title;
    }
  };

  return (
    <div className={'sales-challenge-mc'}>
      <Loading loading={loading} />
      <div>
        <div className="width2">
          <table className={'salesC-sales-table agreement-table'} id={tableId}>
            <tbody>
              <tr>
                {columns.map((item) => {
                  return <th key={item.key}>{renderTitleDate(item.title)}</th>;
                })}
              </tr>
              {dataSource?.length <= 1 && (
                <tr>
                  <td colSpan={'100%'}>
                    <Result
                      className={'result-svg'}
                      status="500"
                      title={
                        'No se encontraron resultados ' +
                        'que coincidan con los ' +
                        'criterios de búsqueda ingresados'
                      }
                    />
                  </td>
                </tr>
              )}
              {dataSource?.length > 1 &&
                dataSource.map((item) => {
                  return (
                    <tr key={item.structure} className={item.structure.toLowerCase()}>
                      {columns &&
                        columns.map((col, index) => {
                          return (
                            <td
                              style={{ position: 'relative' }}
                              key={index + 'd'}
                              className={
                                col.dataIndex.includes('perc')
                                  ? borderClasses(item[col.dataIndex])
                                  : ''
                              }
                            >
                              {col.dataIndex === 'structure' && item.status && (
                                <div
                                  className={
                                    item.status.toLowerCase().replace(/ /g, '') + ' flex-centered'
                                  }
                                />
                              )}
                              <span>{item[col.dataIndex]}</span>
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <br />
          <div className={'square'}>
            <div className={'red'} />
            {'< 80%'}
            <div className={'yellow'} />
            {'80% a < 95%'}
            <div className={'green'} />
            {'95% a 100%'}
            <div className={'blue'} />
            {'> 100%'}
          </div>
          <br />
          <Modal
            tableId={tableId}
            filters={filters}
            query={query}
            queryForPDF={queryFormPDF}
            nameFile={fileName}
          />
        </div>
        <SidebarComercialAgreement onFiltersChanged={changeFiltersFn} isLoading={loading} />
      </div>
    </div>
  );
};

ReportTableAgreementComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.array.isRequired,
  tableId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  filters: PropTypes.object,
  query: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  queryFormPDF: PropTypes.string,
  changeFiltersFn: PropTypes.func.isRequired,
  processYear: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
};
