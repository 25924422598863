import reducer from './redux-saga/reducer/';

import {
  watchProductUpdateSetFormValues,
  watchProductUpdateRequestProductData,
  watchProductUpdateRequestStatusUpdate,
  watchProductUpdateRequestProductUpdate,
} from './redux-saga/sagas';

export default function getProductUpdateModule() {
  return {
    id: 'PRODUCT_UPDATE',
    reducerMap: {
      PRODUCT_UPDATE: reducer,
    },
    sagas: [
      watchProductUpdateSetFormValues,
      watchProductUpdateRequestProductData,
      watchProductUpdateRequestStatusUpdate,
      watchProductUpdateRequestProductUpdate,
    ],
  };
}
