import React from 'react';
import { Row, Col } from 'antd';
import { BInput } from 'common/components';

const ShopByFamilySection = () => {
  return (
    <div>
      <Row>
        <Col span={24}>
          <BInput
            className="form-add-user-input"
            name="period"
            maxLength={100}
            placeholder="Escribe un periodo"
            label="Periodo:"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ShopByFamilySection;
