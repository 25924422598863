import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import getBaseInformationMassiveUploadModule from '../getBaseInformationMassiveUploadModule';
import { layoutContext } from 'context';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import { UploadExcelButton, ActionButtonsExcelUpload } from 'common/MassiveUploads/components';
import MassiveUploadTable from '../components/MassiveUploadTable';
import { triggerModalForDuplicates } from 'common/MassiveUploads/utils';
import './DynamicMassiveUpload.css';
//cambiar sagas
import {
  BaseInformationMassiveUploadSetAllowUpload,
  BaseInformationMassiveUploadRequestVerify,
  BaseInformationMassiveUploadRequestUpload,
  BaseInformationMassiveUploadClearUpload,
  BaseInformationMassiveUploadClearVerify,
  BaseInformationMassiveUploadClearAllowUpload,
  ExistsStatements,
} from '../redux-saga/actions';

const BaseInformationMassiveUpload = (props) => {
  const {
    BaseInformationMassiveUploadSetAllowUpload: setAllowUpload,
    BaseInformationMassiveUploadRequestVerify: requestVerify,
    BaseInformationMassiveUploadRequestUpload: requestUpload,
    BaseInformationMassiveUploadClearUpload: clearUpload,
    BaseInformationMassiveUploadClearVerify: clearVerify,
    BaseInformationMassiveUploadClearAllowUpload: clearAllowUpload,
    ExistsStatements: ExistsStatementsGet,
    allowUpload,
    verify,
  } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [fileString, setFileString] = useState('');
  const correct = verify?.correct;
  const entriesList = verify?.objectList;
  const totalDuplicatedEntries = verify?.replaced?.length;
  const allCorrectMessage = verify?.allCorrectMessage;
  const [selectUploadType, setSelectUploadType] = useState();

  useEffect(() => {
    clearAllowUpload();
    ExistsStatementsGet({ history });
    return () => {
      clearAllowUpload();
      clearUpload();
      clearVerify();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    onChangeActiveComponent('activo');
    if (correct > 0) {
      setAllowUpload(true);
    } else {
      setAllowUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correct]);

  function customRequest({ file }) {
    requestVerify({ file, setFileString, selectUploadType });
  }
  function requestReplace() {
    requestUpload({ history, fileString, overwrite: true, selectUploadType });
  }
  function requestKeep() {
    requestUpload({ history, fileString, overwrite: false, selectUploadType });
  }
  function onSubmit() {
    if (totalDuplicatedEntries > 0) {
      triggerModalForDuplicates(totalDuplicatedEntries, requestReplace, requestKeep, title);
      return;
    }

    requestReplace();
  }
  function onCancel() {
    history.push(`/account-status/base-information`);
  }

  const titleTable = () => {
    if (selectUploadType === 'Purchase') {
      return 'Compras';
    }
    if (selectUploadType === 'Annual') {
      return 'Anuales';
    }
    if (selectUploadType === 'POG') {
      return 'POG';
    }
  };

  const title = titleTable();
  return (
    <Form form={form}>
      <UploadExcelButton titleUpload={title} customRequest={customRequest} />
      <MassiveUploadTable
        data={entriesList}
        allCorrectMessage={allCorrectMessage}
        setSelectUploadType={setSelectUploadType}
        selectUploadType={selectUploadType}
      />
      ,
      <ActionButtonsExcelUpload
        onSubmit={onSubmit}
        onCancel={onCancel}
        disabled={!allowUpload}
        buttons={['cancel', 'submit']}
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    allowUpload: state?.Dynamic_MASSIVE_UPLOAD?.allowUpload,
    verify: state?.Dynamic_MASSIVE_UPLOAD?.verify,
  };
};

const ConnectedBaseInformationMassiveUpload = connect(mapStateToProps, {
  BaseInformationMassiveUploadSetAllowUpload,
  BaseInformationMassiveUploadRequestVerify,
  BaseInformationMassiveUploadRequestUpload,
  BaseInformationMassiveUploadClearUpload,
  BaseInformationMassiveUploadClearVerify,
  BaseInformationMassiveUploadClearAllowUpload,
  ExistsStatements,
})(BaseInformationMassiveUpload);

const BaseInformationMassiveUploadWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getBaseInformationMassiveUploadModule()]}>
      <ConnectedBaseInformationMassiveUpload />
    </DynamicModuleLoader>
  );
};
export default BaseInformationMassiveUploadWithModules;
