import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY,
  Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  BaseInformationMassiveUploadSetAllowUpload,
  BaseInformationMassiveUploadClearAllowUpload,
  BaseInformationMassiveUploadSetVerify,
  BaseInformationMassiveUploadClearVerify,
  BaseInformationMassiveUploadClearUpload,
  SHOPPING_EXIST_STATEMENTS,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';

import { BaseInformationMassiveUpload, ExistsStatements } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workBaseInformationMassiveUploadSetAllowUpload(action) {
  try {
    yield BaseInformationMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield BaseInformationMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield BaseInformationMassiveUploadClearAllowUpload();
    }
  }
}

function* workBaseInformationMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de compras debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(BaseInformationMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(BaseInformationMassiveUploadSetVerify(data));
      yield call(summaryModal, message, false);
    } else {
      yield put(BaseInformationMassiveUploadClearVerify());
    }
  } catch (error) {
    yield BaseInformationMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield BaseInformationMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workBaseInformationMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, overwrite } = payload;

  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(BaseInformationMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite,
      },
    });
    if (responseOK(response)) {
      yield call(
        summaryEndModal,
        response.data,
        history,
        '/account-status/base-information/shopping/consult'
      );
    } else {
      yield put(BaseInformationMassiveUploadClearUpload());
    }
  } catch (error) {
    yield BaseInformationMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield BaseInformationMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}
function* workExistsStatements({ payload }) {
  const { history } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(ExistsStatements, {
      cancelToken: source.token,
    });

    if (responseOK(response)) {
      if (response.data === false) {
        messageError(
          'Es necesario registrar la información correspondiente a elementos dinámicos en el sistema, antes de realizar el registro de Compras. Realice el registro de elementos dinámicos de estado de cuenta para continuar con la operación.'
        );
        setTimeout(() => {
          history.push(`/account-status/base-information`);
        }, 100);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchBaseInformationMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(
      Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
      workBaseInformationMassiveUploadSetAllowUpload
    ),
  ]);
}

export function* watchBaseInformationMassiveUploadRequestVerify() {
  yield all([
    takeEvery(Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY, workBaseInformationMassiveUploadRequestVerify),
  ]);
}

export function* watchBaseInformationMassiveUploadRequestUpload() {
  yield all([
    takeEvery(Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD, workBaseInformationMassiveUploadRequestUpload),
  ]);
}

export function* watchExistsStatements() {
  yield all([takeEvery(SHOPPING_EXIST_STATEMENTS, workExistsStatements)]);
}
