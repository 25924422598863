import {
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SHOW_MODAL_SET,
  deleteProductSuccess,
  deleteProductFail,
} from 'SalesChallengeRefactor/redux/actions/deleteProduct';
import { takeLatest, cancelled, put, call, select } from 'redux-saga/effects';
import { responseOK } from 'SalesChallengeRefactor/utils';
import instance from 'services/request';
import { deleteProduct } from 'SalesChallengeRefactor/services/challenge';
import { querySave } from 'SalesChallengeRefactor/redux/actions/query';
import { openNotification } from 'common/misc/openNotification';
import { getFamilyInformationRequest } from 'SalesChallengeRefactor/redux/actions/getFamilyInformation';

function* workDeleteProductRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const productToDelete = yield select((state) => {
    return state.SALES_CHALLENGE.deleteProduct.productToDelete;
  });
  try {
    const { payload } = action;
    const { params, afterDeleting } = payload;
    /*{
    "state": "changed",
    "showBruteValue": true,
    "year": 2024,
    "regionId": 1,
    "lineId": 1,
    "subRegionId": 1,
    "territoryId": 2,
    "zoneId": 9,
    "clientId": "6030ea5c-fe3f-401e-83a7-4560c7105df2",
    "family": "CONVEY 336 SC"
    }*/
    params.productId = productToDelete;
    const response = yield call(deleteProduct, { params, cancelToken: source.token });

    if (responseOK(response)) {
      setTimeout(() => {
        openNotification('success', response.message);
      }, 1000);
      yield put(querySave({ product: undefined }));

      yield put(deleteProductSuccess(response.data));
    } else {
      yield put(deleteProductFail());
    }

    if (afterDeleting) {
      afterDeleting();
    }
  } catch (error) {
    yield put(deleteProductFail());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

function* workDeleteProductOpenModal(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const query = yield select((state) => {
    return state?.SALES_CHALLENGE?.query;
  });
  try {
    const { clientId, zoneId, year, family } = query;
    const params = { clientId, zoneId, year, family };
    yield put(getFamilyInformationRequest({ params }));
  } catch (error) {
    yield put(deleteProductFail());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchDeleteProductRequest() {
  yield takeLatest(DELETE_PRODUCT_REQUEST, workDeleteProductRequest);
}

export function* watchDeleteProductOpenModal() {
  yield takeLatest(DELETE_PRODUCT_SHOW_MODAL_SET, workDeleteProductOpenModal);
}
