import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BSelect from 'common/components/BSelect';
import { connect } from 'react-redux';
import { pogRequest } from 'RootModule/redux/actions/catalogs';

function Pogs(props) {
  const { askFromService, onSuccess, pogs, pogRequest: pogReq, sourceId, ...other } = props;

  useEffect(() => {
    if (askFromService) {
      pogReq({ sourceId, onSuccess });
    }
  }, [pogReq, askFromService, sourceId, onSuccess]);

  return (
    <BSelect
      isLoading={pogs.isLoading}
      disabled={pogs.isLoading}
      options={pogs.data ?? []}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...other}
    />
  );
}

Pogs.propTypes = {
  pogsRequest: PropTypes.func,
  pogs: PropTypes.object,
  askFromService: PropTypes.bool,
  sourceId: PropTypes.oneOf(['Sales', 'Agreements', 'pogs']),
  name: PropTypes.string,
  label: PropTypes.string,
};

Pogs.defaultProps = {
  pogsRequest: null,
  pogs: { data: [], isLoading: false },
  askFromService: true,
  sourceId: 'Sales',
  label: 'POG',
  name: 'pogTypeId',
};

function mapStateToProps(state) {
  return {
    pogs: state.APP?.catalogs?.pogs,
  };
}

export default connect(mapStateToProps, { pogRequest })(Pogs);
