import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  BUSINESS_STRUCTURE_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  BUSINESS_STRUCTURE_MASSIVE_UPLOAD_REQUEST_VERIFY,
  BUSINESS_STRUCTURE_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  businessStructureMassiveUploadSetAllowUpload,
  businessStructureMassiveUploadClearAllowUpload,
  businessStructureMassiveUploadSetVerify,
  businessStructureMassiveUploadClearVerify,
  businessStructureMassiveUploadClearUpload,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'utils';
import { businessStructureMassiveUpload } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workBusinessStructureMassiveUploadSetAllowUpload(action) {
  try {
    yield businessStructureMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield businessStructureMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield businessStructureMassiveUploadClearAllowUpload();
    }
  }
}

function* workBusinessStructureMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de Estructura Comercial debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(businessStructureMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(businessStructureMassiveUploadSetVerify(data));
      yield call(summaryModal, message);
    } else {
      yield put(businessStructureMassiveUploadClearVerify());
    }
  } catch (error) {
    yield businessStructureMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield businessStructureMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workBusinessStructureMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, overwrite } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(businessStructureMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite,
      },
    });
    if (responseOK(response)) {
      yield call(summaryEndModal, response.data, history, '/catalog/business-estructure');
    } else {
      yield put(businessStructureMassiveUploadClearUpload());
    }
  } catch (error) {
    yield businessStructureMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield businessStructureMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchBusinessStructureMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(
      BUSINESS_STRUCTURE_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
      workBusinessStructureMassiveUploadSetAllowUpload
    ),
  ]);
}

export function* watchBusinessStructureMassiveUploadRequestVerify() {
  yield all([
    takeEvery(
      BUSINESS_STRUCTURE_MASSIVE_UPLOAD_REQUEST_VERIFY,

      workBusinessStructureMassiveUploadRequestVerify
    ),
  ]);
}

export function* watchBusinessStructureMassiveUploadRequestUpload() {
  yield all([
    takeEvery(
      BUSINESS_STRUCTURE_MASSIVE_UPLOAD_REQUEST_UPLOAD,
      workBusinessStructureMassiveUploadRequestUpload
    ),
  ]);
}
