import React, { useContext } from 'react';

// Redux-Saga
import getRootModule from 'RootModule';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getManageImagesModule from '../Redux-saga/getModule';
import TableImages from '../Components/TableImages/TableImages';
import DrawerAdd from '../Components/DrawerAdd';
import { layoutContext } from 'context';

import { DrawerEdit } from '../Components';
const ImagesComponent = (props) => {
  const {
    // Datos previos de la tabla al Drawer Edit
    dataDrawerEdit,
    // Imagenes de DrawerAdd en base64
    inputImages,
  } = props;
  const { onChangeActiveComponent } = useContext(layoutContext);

  onChangeActiveComponent('activo');
  return (
    <>
      <TableImages />

      {
        //Drawer Editar imagenes
        dataDrawerEdit?.length > 0 && <DrawerEdit />
      }
      {
        //Drawer Agregar imagenes
        inputImages?.length > 0 && <DrawerAdd />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dataDrawerEdit: state?.MANAGE_IMAGES?.DrawerEdit?.dataDrawerEdit,
    inputImages: state?.MANAGE_IMAGES?.DrawerAdd?.inputImages,
  };
};

const ConnectedManageImages = connect(mapStateToProps, {})(ImagesComponent);

const ManageImages = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getManageImagesModule()]}>
      <ConnectedManageImages />
    </DynamicModuleLoader>
  );
};
export default ManageImages;
