import React from 'react';
import PropTypes from 'prop-types';
import BButton from 'common/components/BButton';
import { CheckCircleOutlined } from '@ant-design/icons';
import { List, Skeleton, Pagination, Col, Row } from 'antd';
import './styles.css';

function NotificationList(props) {
  const {
    loading,
    dataSource,
    markAsRead,
    handleChangePageFalse,
    totalFalse,
    handleChangePageTrue,
  } = props;

  let dataSourceReadFalse = dataSource;
  return (
    <>
      {loading && (
        <div className="notifications-list notification-loading">
          <Skeleton className="notification-content" loading={loading} active avatar>
            <List.Item.Meta title={<a href="aa">a</a>} description={'dsdkds'} />
          </Skeleton>
          <Skeleton className="notification-content" loading={loading} active avatar>
            <List.Item.Meta title={<a href="aa">a</a>} description={'dsdkds'} />
          </Skeleton>
          <Skeleton className="notification-content" loading={loading} active avatar>
            <List.Item.Meta title={<a href="aa">a</a>} description={'dsdkds'} />
          </Skeleton>
          <Skeleton className="notification-content" loading={loading} active avatar>
            <List.Item.Meta title={<a href="aa">a</a>} description={'dsdkds'} />
          </Skeleton>
        </div>
      )}

      {!loading && (
        <List
          itemLayout="vertical"
          size="large"
          tabIndex="0"
          id="removeScroll"
          className="notifications-list notifications-one"
          dataSource={dataSourceReadFalse}
          renderItem={(item, index) => (
            <List.Item key={item.title}>
              {!loading && (
                <div className="notification-content">
                  <Row>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <span className="notification-actions">
                        <BButton
                          onClick={() => {
                            markAsRead({ key: item.id });
                            handleChangePageTrue(1);
                          }}
                          icon={<CheckCircleOutlined />}
                          key="list-loadmore-more"
                          type="link"
                        />
                      </span>
                    </Col>
                    <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                      <a className="link-style" href={item.link}>
                        <span>{item.message}</span>
                      </a>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <div className="notification-date">{item.registered}</div>
                      <div className="notification-date">{item.registredHour}</div>
                    </Col>
                  </Row>
                </div>
              )}
            </List.Item>
          )}
        />
      )}

      {!loading && (
        <List
          itemLayout="vertical"
          size="large"
          tabIndex="0"
          pagination={false}
          id="removeScroll"
          className="notifications-list notifications-two"
          dataSource={dataSourceReadFalse}
          renderItem={(item, index) => (
            <List.Item key={item.title}>
              {!loading && (
                <div className="notification-content">
                  <Row>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                      <span className="notification-actions-two">
                        <BButton
                          onClick={() => {
                            markAsRead({ key: item.id });
                            handleChangePageTrue(1);
                          }}
                          icon={<CheckCircleOutlined />}
                          key="list-loadmore-more"
                          type="link"
                        />
                      </span>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <div className="notification-date-two">{item.registredComplete}</div>
                      <div className="notification-date-two">{item.registredHour}</div>
                    </Col>
                    <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                      <a className="link-style-content" href={item.link}>
                        <span>{item.message}</span>
                      </a>
                    </Col>
                  </Row>
                </div>
              )}
            </List.Item>
          )}
        />
      )}
      <div>
        <Pagination
          className="pagination-button"
          pageSize={5}
          onChange={handleChangePageFalse}
          total={totalFalse}
          defaultCurrent={1}
          style={{ bottom: '0px' }}
        />
      </div>
    </>
  );
}

export default NotificationList;

NotificationList.propTypes = {
  loading: PropTypes.bool,
};

NotificationList.defaultProps = {
  loading: false,
};
