import reducer from './redux-saga/reducers';

import {
  watchDynamicMassiveUploadSetAllowUpload,
  watchDynamicMassiveUploadRequestVerify,
  watchDynamicMassiveUploadRequestUpload,
} from './redux-saga/sagas';

export default function getDynamicMassiveUploadModule() {
  return {
    id: 'Dynamic_MASSIVE_UPLOAD',
    reducerMap: {
      Dynamic_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchDynamicMassiveUploadSetAllowUpload,
      watchDynamicMassiveUploadRequestVerify,
      watchDynamicMassiveUploadRequestUpload,
    ],
  };
}
