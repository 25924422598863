export const DELETE_SALES_CHALLENGE_VALIDATOR_REQUEST = 'DELETE_SALES_CHALLENGE_VALIDATOR_REQUEST';
export const DELETE_SALES_CHALLENGE_VALIDATOR_SUCCESS = 'DELETE_SALES_CHALLENGE_VALIDATOR_SUCCESS';
export const DELETE_SALES_CHALLENGE_VALIDATOR_FAIL = 'DELETE_SALES_CHALLENGE_VALIDATOR_FAIL';

export function deleteSalesChallengeValidatorRequest(payload) {
  return {
    type: DELETE_SALES_CHALLENGE_VALIDATOR_REQUEST,
    payload,
  };
}

export function deleteSalesChallengeValidatorSuccess(payload) {
  return { type: DELETE_SALES_CHALLENGE_VALIDATOR_SUCCESS, payload };
}

export function deleteSalesChallengeValidatorFail(payload) {
  return { type: DELETE_SALES_CHALLENGE_VALIDATOR_FAIL, payload };
}
