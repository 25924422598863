export const PROCESS__DATES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD =
  'PROCESS__DATES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const PROCESS__DATES_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'PROCESS__DATES_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const PROCESS__DATES_MASSIVE_UPLOAD_REQUEST_VERIFY =
  'PROCESS__DATES_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const PROCESS__DATES_MASSIVE_UPLOAD_SET_VERIFY = 'PROCESS__DATES_MASSIVE_UPLOAD_SET_VERIFY';
export const PROCESS__DATES_MASSIVE_UPLOAD_CLEAR_VERIFY =
  'PROCESS__DATES_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const PROCESS__DATES_MASSIVE_UPLOAD_REQUEST_UPLOAD =
  'PROCESS__DATES_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const PROCESS__DATES_MASSIVE_UPLOAD_SET_UPLOAD = 'PROCESS__DATES_MASSIVE_UPLOAD_SET_UPLOAD';
export const PROCESS__DATES_MASSIVE_UPLOAD_CLEAR_UPLOAD =
  'PROCESS__DATES_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function processDatesMassiveUploadSetAllowUpload(payload) {
  return {
    type: PROCESS__DATES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function processDatesMassiveUploadClearAllowUpload(payload) {
  return {
    type: PROCESS__DATES_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function processDatesMassiveUploadRequestVerify(payload) {
  return {
    type: PROCESS__DATES_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function processDatesMassiveUploadSetVerify(payload) {
  return {
    type: PROCESS__DATES_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function processDatesMassiveUploadClearVerify(payload) {
  return {
    type: PROCESS__DATES_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function processDatesMassiveUploadRequestUpload(payload) {
  return {
    type: PROCESS__DATES_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function processDatesMassiveUploadSetUpload(payload) {
  return {
    type: PROCESS__DATES_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function processDatesMassiveUploadClearUpload(payload) {
  return {
    type: PROCESS__DATES_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
