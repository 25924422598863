export const numberToArray = (numString) => {
  let numberString = `${numString}`;
  let numberParts = [];
  const indexOfDot = numberString.indexOf('.');
  if (indexOfDot > -1) {
    numberParts = numberString.split('.');
  } else {
    numberParts.push(numberString);
  }
  return numberParts;
};

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
const addCommasToFloat = (num) => {
  const numberParts = numberToArray(num);
  const integer = numberParts[0];

  numberParts[0] = numberWithCommas(integer);
  return numberParts.join('.');
};

export default addCommasToFloat;
