import request from '../../services/request';
import { paramsSerializer } from 'RootModule/services/catalogs';

//const URL_API = `/Catalogs/Api/v1/Validators/UserValByTerritory/`;
const URL_API = `/Catalogs/Api/v1/Validators/UserAgreementByTerritory`;

export async function getTradeAgreementsValidatorsCatalog({ cancelToken, params }) {
  return request(`${URL_API}`, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
    params,
  });
}
