import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BSelect } from 'common/components';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';
import { businessRulesRequest } from 'RootModule/redux/actions/catalogs';

function BusinessRules(props) {
  const {
    businessRules,
    businessRulesRequest: getBusinessRules,
    onOptionsChange,
    sourceId,
    requestParams,
    askFromService,
    onSuccess,
    ...other
  } = props;

  useEffect(() => {
    //getBusinessRules();
  }, [getBusinessRules]);

  useEffect(() => {
    if (askFromService) {
      getBusinessRules({ requestParams, sourceId, onSuccess });
    }
  }, [getBusinessRules, requestParams, askFromService, sourceId, onSuccess]);

  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange(getBusinessRules.data ?? []);
    }
  }, [getBusinessRules, onOptionsChange]);

  return (
    <BSelect
      isLoading={businessRules?.isLoading}
      disabled={businessRules?.isLoading}
      options={businessRules?.data}
      {...other}
    />
  );
}

BusinessRules.propTypes = {
  businessRulesRequest: PropTypes.func,
  initialValue: PropTypes.any,
  requestParams: PropTypes.object,
  sourceId: PropTypes.oneOf(['Agreements', 'Sales']),
  askFromService: PropTypes.bool,
  onOptionsChange: PropTypes.func,
};

BusinessRules.defaultProps = {
  businessRulesRequest: null,
  label: filtersEnum.businessRules.label,
  name: filtersEnum.businessRules.name,
  initialValue: undefined,
  requestParams: {},
  sourceId: 'Sales',
  askFromService: true,
  onOptionsChange: null,
};

function mapStateToProps(state) {
  return {
    businessRules: state.APP?.catalogs?.businessRules,
  };
}

export default connect(mapStateToProps, { businessRulesRequest })(BusinessRules);
