export const PUT_ONE_UPDATE_PRICES_REQUEST = 'PUT_ONE_UPDATE_PRICES_REQUEST';
export const PUT_ONE_UPDATE_PRICES_SUCCESS = 'PUT_ONE_UPDATE_PRICES_SUCCESS';
export const PUT_ONE_UPDATE_PRICES_CLEAN = 'PUT_ONE_UPDATE_PRICES_CLEAN';

export const GET_UPDATE_PRICES_REQUEST = 'GET_UPDATE_PRICES_REQUEST';
export const GET_UPDATE_PRICES_SUCCESS = 'GET_UPDATE_PRICES_SUCCESS';
export const GET_UPDATE_PRICES_CLEAN = 'GET_UPDATE_PRICES_CLEAN';

export const PUT_ONE_STATUS_REQUEST = 'PUT_ONE_STATUS_REQUEST';
export const PUT_ONE_STATUS_SUCCESS = 'PUT_ONE_STATUS_SUCCESS';
export const PUT_ONE_STATUS_CLEAN = 'PUT_ONE_STATUS_CLEAN';

//PUT_ONE_UPDATE_PRICES_REQUEST
export function putOneUpdatePricesRequest(payload) {
  return {
    type: PUT_ONE_UPDATE_PRICES_REQUEST,
    payload: payload,
  };
}
export function putOneUpdatePricesSuccess(payload) {
  return {
    type: PUT_ONE_UPDATE_PRICES_SUCCESS,
    payload: payload,
  };
}
export function putOneUpdatePricesClean() {
  return {
    type: PUT_ONE_UPDATE_PRICES_CLEAN,
  };
}

//GET_UPDATE_PRICES_REQUEST
export function getUpdatePricesRequest(payload) {
  return {
    type: GET_UPDATE_PRICES_REQUEST,
    payload: payload,
  };
}
export function getUpdatePricesSuccess(payload) {
  return {
    type: GET_UPDATE_PRICES_SUCCESS,
    payload: payload,
  };
}
export function getUpdatePricesClean() {
  return {
    type: GET_UPDATE_PRICES_CLEAN,
  };
}

//PUT
export function putOneStatusRequest(payload) {
  return {
    type: PUT_ONE_STATUS_REQUEST,
    payload: payload,
  };
}
export function putOneStatusSuccess(payload) {
  return {
    type: PUT_ONE_STATUS_SUCCESS,
    payload: payload,
  };
}
export function putOneStatusClean() {
  return {
    type: PUT_ONE_STATUS_CLEAN,
  };
}
