export const InfoPOG_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD = 'InfoPOG_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const InfoPOG_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'InfoPOG_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const InfoPOG_MASSIVE_UPLOAD_REQUEST_VERIFY = 'InfoPOG_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const InfoPOG_MASSIVE_UPLOAD_SET_VERIFY = 'InfoPOG_MASSIVE_UPLOAD_SET_VERIFY';
export const InfoPOG_MASSIVE_UPLOAD_CLEAR_VERIFY = 'InfoPOG_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const InfoPOG_MASSIVE_UPLOAD_REQUEST_UPLOAD = 'InfoPOG_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const InfoPOG_MASSIVE_UPLOAD_SET_UPLOAD = 'InfoPOG_MASSIVE_UPLOAD_SET_UPLOAD';
export const InfoPOG_MASSIVE_UPLOAD_CLEAR_UPLOAD = 'InfoPOG_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export const EXIST_STATEMENTS = 'EXIST_STATEMENTS';

export function ExistsStatements(payload) {
  return {
    type: EXIST_STATEMENTS,
    payload: payload,
  };
}

export function InfoPOGMassiveUploadSetAllowUpload(payload) {
  return {
    type: InfoPOG_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function InfoPOGMassiveUploadClearAllowUpload(payload) {
  return {
    type: InfoPOG_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function InfoPOGMassiveUploadRequestVerify(payload) {
  return {
    type: InfoPOG_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function InfoPOGMassiveUploadSetVerify(payload) {
  return {
    type: InfoPOG_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function InfoPOGMassiveUploadClearVerify(payload) {
  return {
    type: InfoPOG_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function InfoPOGMassiveUploadRequestUpload(payload) {
  return {
    type: InfoPOG_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function InfoPOGMassiveUploadSetUpload(payload) {
  return {
    type: InfoPOG_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function InfoPOGMassiveUploadClearUpload(payload) {
  return {
    type: InfoPOG_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
