/* eslint-disable no-console */
import { takeEvery, put, call, all, cancelled } from 'redux-saga/effects';
import {
  READ_ONE_GOALS_REQUEST,
  saveDataGoals,
  UPDATE_GOALS_DATA,
  UPDATE_GOALS_REQUEST,
  readOneGoalsSuccess,
  readOneGoalsClean,
  updateGoalsSuccess,
  updateGoalsClean,
  deleteGoalsSuccess,
  deleteGoalsClean,
  DELETE_GOALS_REQUEST,
} from '../actions';
import { getOneGoals, updateOneGoals, deleteOneGoals } from '../../services/index';
import instance from 'services/request';
import { responseOK } from 'SalesChallengeRefactor/utils';
/* import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'; */

function* workReadOneGoalsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;

    const response = yield call(getOneGoals, {
      payload,
    });

    if (responseOK(response)) {
      const dataToSave = {
        ...response.data,
      };
      yield put(readOneGoalsSuccess(dataToSave));
    } else {
      yield put(readOneGoalsClean());
    }
  } catch (error) {
    yield put(readOneGoalsClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchReadOneGoalsRequest() {
  yield all([takeEvery(READ_ONE_GOALS_REQUEST, workReadOneGoalsRequest)]);
}

export function* workReadOneGoalsUpdate(action) {
  const { payload } = action;
  yield put(saveDataGoals(payload));
}

export function* watchReadOneGoalsUpdate() {
  yield all([takeEvery(UPDATE_GOALS_DATA, workReadOneGoalsUpdate)]);
}

function* updateGoalsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { params, onSuccess, errorMessage } = payload;
  try {
    const response = yield call(updateOneGoals, {
      cancelToken: source.token,
      data: params,
    });

    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess(response.message);
      }
      const dataToSave = {
        ...response.data,
      };
      yield put(updateGoalsSuccess(dataToSave));
    } else {
      if (errorMessage) {
        yield errorMessage(response.message);
      }
      yield put(updateGoalsClean());
    }
  } catch (error) {
    if (errorMessage) {
      yield errorMessage(error.message);
    }
    yield put(updateGoalsClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGoalsUpdate() {
  yield all([takeEvery(UPDATE_GOALS_REQUEST, updateGoalsRequest)]);
}

//delete Goals
function* deleteGoalsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { params, onSuccess, errorMessage } = payload;

  try {
    const response = yield call(deleteOneGoals, {
      cancelToken: source.token,
      data: params,
    });

    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess(response.message);
      }
      const dataToSave = {
        ...response.data,
      };

      yield put(deleteGoalsSuccess(dataToSave));
    } else {
      if (errorMessage) {
        yield errorMessage(response.message);
      }
      yield put(deleteGoalsClean());
    }
  } catch (error) {
    if (errorMessage) {
      yield errorMessage(error.message);
    }
    yield put(deleteGoalsClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchDeleteGoalsUpdate() {
  yield all([takeEvery(DELETE_GOALS_REQUEST, deleteGoalsRequest)]);
}
