import { annualSchema, pogSchema, salesSchema } from '.';

const getSchema = (typeOfSchema) => {
  let currentSchema = [];

  switch (typeOfSchema) {
    case 'pog':
      currentSchema = pogSchema;
      break;
    case 'sales':
      currentSchema = salesSchema;
      break;
    default:
      currentSchema = annualSchema;
      break;
  }

  return currentSchema;
};

export default getSchema;
