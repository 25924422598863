import React, { useState, useEffect } from 'react';
import { ComponentsMap } from './utils';
import { notEmpty } from '../utils';
import { Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
const inputGroupStyle = {
  position: 'relative',
  display: 'table',
  borderCollapse: 'separate',
};

function Form({
  formId,
  onClear,
  elements,
  inputClassName,
  handleChange,
  handleChangeAp,
  handleValidations,
  defaultValues,
  componentsMap,
}) {
  const MAP = notEmpty(componentsMap) ? { ...componentsMap, ...ComponentsMap } : ComponentsMap;

  const [formValues, setFormValues] = useState(undefined);
  const [errors, setErrors] = useState({});
  let history = useHistory();
  const { location } = history;
  const dataState = useSelector((state) => state.GOALS.Goals.data);

  useEffect(() => {
    if (!formValues && defaultValues) {
      setFormValues(defaultValues);
    }
  }, [formValues, defaultValues, dataState]);

  const handleAction = (element, event) => {
    let name = element.name;
    let value = element.value;

    let newFormValues = {
      ...formValues,
      [name]: value,
    };

    setFormValues(newFormValues);
    handleChange(formId, newFormValues);
  };
  const handleError = (err) => {
    let name = err.name;
    let value = err.value;
    let newErrors = {
      ...errors,
      [name]: value,
    };
    setErrors({
      ...errors,
      [name]: value,
    });
    handleValidations(Object.values(newErrors).some((error) => error === true));
  };

  useEffect(() => {
    setFormValues(undefined);
  }, [onClear]);

  return (
    <form id={formId}>
      {elements.map((element, key) => {
        let Tagname = MAP[`${element.type}`];

        if (dataState) {
          if (element.tag === 'soldto') {
            element.placeholder = dataState.soldTo;
          }
          if (element.tag === 'client') {
            element.placeholder = dataState.clientName;
          }
          if (element.tag === 'productnumber') {
            element.placeholder = dataState.productNumber;
          }
          if (element.tag === 'product') {
            element.placeholder = dataState.productName;
          }
          if (element.tag === 'lob') {
            element.placeholder = dataState.line;
          }
        }

        return (
          <div id={element.tag} key={key} className={inputClassName} style={inputGroupStyle}>
            {element?.tag === 'ap' ? (
              // eslint-disable-next-line react/jsx-no-comment-textnodes
              <>
                <label htmlFor="ap">AP:</label>
                <Input
                  id="ap"
                  disabled={!location.state.update}
                  defaultValue={location.state.crop}
                  onChange={(e) => handleChangeAp(e)}
                />
              </>
            ) : (
              <Tagname
                // defaultValue={element?.tag === 'ap' ? dataState?.crop : null}
                {...element}
                formValues={formValues}
                handleAction={(event, value) => handleAction(event, value)}
                handleError={handleError}
              />
            )}
          </div>
        );
      })}
    </form>
  );
}
export default Form;
