import rootReducer from './redux-saga/reducers/rootReducer';
import rootSagas from './redux-saga/sagas/rootSagas';

export default function getRootModule() {
  return {
    id: 'UPDATEELEMENT',
    reducerMap: {
      UPDATEELEMENT: rootReducer,
    },
    sagas: [rootSagas],
  };
}
