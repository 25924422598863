import AdminSectionReducer from './redux-saga/reducers';
import rootSagas from './redux-saga/sagas/rootSagas';
export default function dynamicSectionsModule() {
  return {
    id: 'DYNAMICSECTIONSLOG',
    reducerMap: {
      DYNAMICSECTIONSLOG: AdminSectionReducer,
    },
    sagas: [rootSagas],
  };
}
