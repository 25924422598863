import React, { useState, useEffect } from 'react';
import { Input } from '.';
import { MailRegexValidation } from './utils';

function MailInput({
  label,
  clear,
  tag,
  placeholder,
  autoComplete,
  handleAction,
  handleError,
  defaultValue,
  readOnly,
  ...restProps
}) {
  const [value, setValue] = useState(undefined);
  const [errorTag, setErrorTag] = useState(false);

  useEffect(() => {
    if (!value && defaultValue) {
      setValue(defaultValue);
    }
  }, [value, defaultValue]);

  useEffect(() => {
    setValue('');
  }, [clear]);

  const validateEmail = (event) => {
    let name = event.currentTarget.name;
    let inputValue = event.currentTarget.value;
    setValue(inputValue);
    !MailRegexValidation(inputValue) ? setErrorTag('usuario@dominio.extensión') : setErrorTag(null);
    handleAction({ name: name, value: inputValue });
    handleError({ name: name, value: !MailRegexValidation(inputValue) });
  };

  return (
    <>
      <Input
        label={label}
        tag={tag}
        type="email"
        name={tag}
        value={value}
        readOnly={readOnly}
        autoComplete={autoComplete ? autoComplete : 'off'}
        errorTag={errorTag}
        placeholder={placeholder}
        onChange={(e) => validateEmail(e)}
        {...restProps}
      />
    </>
  );
}
export default MailInput;
