import { segmentsRequest } from 'RootModule/redux/actions/catalogs';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Binput } from './index';

function BSegmentation(props) {
  const {
    form,
    segmentation,
    segmentsRequest: getSegments,
    deleteSegment,
    putSegment,
    postOneSegmentation,
    askFromService,
    label,
    name,
    onSuccess,
    segmentationReload,
    ...other
  } = props;

  useEffect(() => {
    if (askFromService) getSegments({ onSuccess });
  }, [getSegments, askFromService, onSuccess]);

  return (
    <Binput
      isLoading={segmentation.isLoading}
      name={name}
      label={label}
      options={segmentation.data}
      {...other}
      postOneSegmentation={postOneSegmentation}
      deleteSegment={deleteSegment}
      putSegment={putSegment}
      getSegments={getSegments}
      onSuccess={onSuccess}
      form={form}
      segmentationReload={segmentationReload}
    />
  );
}

BSegmentation.propTypes = {
  label: PropTypes.string,
  segmentsRequest: PropTypes.func,
  segmentation: PropTypes.object,
  askFromService: PropTypes.bool,
  onSuccess: PropTypes.func,
};

BSegmentation.defaultProps = {
  label: 'Segmentación',
  segmentsRequest: null,
  segmentation: { data: [], isLoading: false },
  askFromService: true,
  onSuccess: null,
};

function mapStateToProps(state) {
  return {
    segmentation: state.APP?.catalogs?.segmentation,
  };
}

export default connect(mapStateToProps, { segmentsRequest })(BSegmentation);
