import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import React from 'react';
import errorMessages from './errorMessages';
import './massiveUploadTableColumns.css';

const columnsMxPss = [
  {
    key: 'phase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'phase',
    className: 'title-masive',
  },
  {
    key: 'productName',
    title: 'Nombre de producto',
    dataIndex: 'productName',
  },
  {
    key: 'presentation',
    title: 'Presentación',
    dataIndex: 'presentation',
  },
  {
    key: 'packageUnits',
    title: 'Unidad de medida',
    dataIndex: 'packageUnits',
  },
  {
    key: 'measurement',
    title: 'Unidades por empaque',
    dataIndex: 'measurement',
  },
];
export { columnsMxPss };
