import request from '../../services/request';

export async function getDaysLeft(cancelToken) {
  return request('/Catalogs/Api/v1/ProcessDates/DaysLeft', {
    method: 'GET',
    cancelToken,
    params: {
      processId: 12,
    },
  });
}
