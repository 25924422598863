//Types clients
export const TYPE_CLIENT_REQUEST = 'TYPE_CLIENT_REQUEST';
export const TYPE_CLIENT_SUCCESS = 'TYPE_CLIENT_SUCCESS';
export const TYPE_CLIENT_CLEAN = 'TYPE_CLIENT_CLEAN';
//Types bussinesLines
export const BUSINESS_LINES_REQUEST = 'BUSINESS_LINES_REQUEST';
export const BUSINESS_LINES_SUCCESS = 'BUSINESS_LINES_SUCCESS';
export const BUSINESS_LINES_CLEAN = 'BUSINESS_LINES_CLEAN';
//Types segatation
export const SEGMENTATION_REQUEST = 'SEGMENTATION_REQUEST';
export const SEGMENTATION_SUCCESS = 'SEGMENTATION_SUCCESS';
export const SEGMENTATION_CLEAN = 'SEGMENTATION_CLEAN';
//Types company
export const COMPANY_REQUEST = 'COMPANY_REQUEST';
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';
export const COMPANY_CLEAN = 'COMPANY_CLEAN';
//Types territoiores
export const TERRITORIES_REQUEST = 'TERRITORIES_REQUEST';
export const TERRITORIES_SUCCESS = 'TERRITORIES_SUCCESS';
export const TERRITORIES_CLEAN = 'TERRITORIES_CLEAN';
//types incentive period
export const INCENTIVE_PERIOD_REQUEST = 'INCENTIVE_PERIOD_REQUEST';
export const INCENTIVE_PERIOD_SUCCESS = 'INCENTIVE_PERIOD_SUCCESS';
export const INCENTIVE_PERIOD_CLEAN = 'INCENTIVE_PERIOD_CLEAN';
//Types pogs
export const POGS_REQUEST = 'POGS_REQUEST';
export const POGS_SUCCESS = 'POGS_SUCCESS';
export const POGS_CLEAN = 'POGS_CLEAN';
//Types Currencies
export const CURRENCIES_REQUEST = 'CURRENCIES_REQUEST';
export const CURRENCIES_SUCCESS = 'CURRENCIES_SUCCESS';
export const CURRENCIES_CLEAN = 'CURRENCIES_CLEAN';
//types placements
export const CLIENTID_REQUEST = 'CLIENTID_REQUEST';
export const CLIENTID_SUCCESS = 'CLIENTID_SUCCESS';
export const CLIENTID_CLEAN = 'CLIENTID_CLEAN';
//types placements
export const PlACEMENTS_REQUEST = 'PlACEMENTS_REQUEST';
export const PlACEMENTS_SUCCESS = 'PlACEMENTS_SUCCESS';
export const PlACEMENTS_CLEAN = 'PlACEMENTS_CLEAN';
//Register clients
export const REGISTER_DYNAMIC_ELEMENT_REQUEST = 'REGISTER_DYNAMIC_ELEMENT_REQUEST';
export const REGISTER_DYNAMIC_ELEMENT_SUCCESS = 'REGISTER_DYNAMIC_ELEMENT_SUCCESS';
export const REGISTER_DYNAMIC_ELEMENT_CLEAN = 'REGISTER_DYNAMIC_ELEMENT_CLEAN';

//validadte cliente
export const VALIDATE_CLIENT_REQUEST = 'VALIDATE_CLIENT_REQUEST';
export const VALIDATE_CLIENT_SUCCESS = 'VALIDATE_CLIENT_SUCCESS';
export const VALIDATE_CLIENT_CLEAN = 'VALIDATE_CLIENT_CLEAN';
//detail dynamic elements
export const DETAIL_DYNAMIC_ELEMENTS_REQUEST = 'DETAIL_DYNAMIC_ELEMENTS_REQUEST';
export const DETAIL_DYNAMIC_ELEMENTS_SUCCESS = 'DETAIL_DYNAMIC_ELEMENTS_SUCCESS';
export const DETAIL_DYNAMIC_ELEMENTS_CLEAN = 'DETAIL_DYNAMIC_ELEMENTS_CLEAN';

export const STATUS_REQUEST = 'STATUS_REQUEST';
export const STATUS_SUCCESS = 'STATUS_SUCCESS';
export const STATUS_CLEAN = 'STATUS_CLEAN';
//status dynamic elements
export function statusRequest(payload) {
  return {
    type: STATUS_REQUEST,
    payload: payload,
  };
}

export function statusSuccess(payload) {
  return {
    type: STATUS_SUCCESS,
    payload: payload,
  };
}

export function statusClean() {
  return {
    type: STATUS_CLEAN,
    payload: {},
  };
}

// get details dynamic elements

export function getDetailDynamicElementsRequest(payload, id) {
  return {
    type: DETAIL_DYNAMIC_ELEMENTS_REQUEST,
    payload: payload,
    id: id,
  };
}

export function getDetailDynamicElementsSuccess(payload) {
  return {
    type: DETAIL_DYNAMIC_ELEMENTS_SUCCESS,
    payload: payload,
  };
}
export function getDetailDynamicElementsClean() {
  return {
    type: DETAIL_DYNAMIC_ELEMENTS_CLEAN,
  };
}

//validate client
export function validateClientRequest(payload) {
  return {
    type: VALIDATE_CLIENT_REQUEST,
    payload: payload,
  };
}

export function validateClientSuccess(payload) {
  return {
    type: VALIDATE_CLIENT_SUCCESS,
    payload: payload,
  };
}

export function validateClientClean() {
  return {
    type: VALIDATE_CLIENT_CLEAN,
    payload: {},
  };
}
//get ClientId
export function getClientIdRequest(payload) {
  return {
    type: CLIENTID_REQUEST,
    payload: payload,
  };
}
export function getClientIdSuccess(payload) {
  return {
    type: CLIENTID_SUCCESS,
    payload: payload,
  };
}
export function getClientIdClean(payload) {
  return {
    type: CLIENTID_CLEAN,
    payload: payload,
  };
}
//get Placements
export function getPlacementsRequest() {
  return {
    type: PlACEMENTS_REQUEST,
  };
}
export function getPlacementsSuccess(payload) {
  return {
    type: PlACEMENTS_SUCCESS,
    payload: payload,
  };
}
export function getPlacementsClean() {
  return {
    type: PlACEMENTS_CLEAN,
  };
}

//get tipo clientes
export function getTypeClientRequest() {
  return {
    type: TYPE_CLIENT_REQUEST,
  };
}
export function getTypeClientSuccess(payload) {
  return {
    type: TYPE_CLIENT_SUCCESS,
    payload: payload,
  };
}
export function getTypeClientClean() {
  return {
    type: TYPE_CLIENT_CLEAN,
  };
}

//get tipo clientes
export function getBusinessLinesRequest() {
  return {
    type: BUSINESS_LINES_REQUEST,
  };
}
export function getBusinessLinesSuccess(payload) {
  return {
    type: BUSINESS_LINES_SUCCESS,
    payload: payload,
  };
}
export function getBusinessLinesClean() {
  return {
    type: BUSINESS_LINES_CLEAN,
  };
}

//get tipo clientes
export function getSegmentationRequest() {
  return {
    type: SEGMENTATION_REQUEST,
  };
}
export function getSegmentationSuccess(payload) {
  return {
    type: SEGMENTATION_SUCCESS,
    payload: payload,
  };
}
export function getSegmentationClean() {
  return {
    type: SEGMENTATION_CLEAN,
  };
}

//get compañia
export function getCompanyRequest() {
  return {
    type: COMPANY_REQUEST,
  };
}
export function getCompanySuccess(payload) {
  return {
    type: COMPANY_SUCCESS,
    payload: payload,
  };
}
export function getCompanyClean() {
  return {
    type: COMPANY_CLEAN,
  };
}
//get territorios
export function getTerritoresRequest(payload) {
  return {
    type: TERRITORIES_REQUEST,
    payload: payload,
  };
}
export function getTerritoriesSuccess(payload) {
  return {
    type: TERRITORIES_SUCCESS,
    payload: payload,
  };
}
export function getTerritoriesClean() {
  return {
    type: TERRITORIES_CLEAN,
  };
}
//get periodo inventivo de ventas
export function getSalesIncentivePeriodRequest() {
  return {
    type: INCENTIVE_PERIOD_REQUEST,
  };
}
export function getSalesIncentivePeriodSuccess(payload) {
  return {
    type: INCENTIVE_PERIOD_SUCCESS,
    payload: payload,
  };
}
export function getSalesIncentivePeriodClean() {
  return {
    type: INCENTIVE_PERIOD_CLEAN,
  };
}
//get pogs
export function getPogsRequest() {
  return {
    type: POGS_REQUEST,
  };
}
export function getPogsSuccess(payload) {
  return {
    type: POGS_SUCCESS,
    payload: payload,
  };
}
export function getPogsClean() {
  return {
    type: POGS_CLEAN,
  };
}
//get currencies
export function getCurrenciesRequest() {
  return {
    type: CURRENCIES_REQUEST,
  };
}
export function getCurrenciesSuccess(payload) {
  return {
    type: CURRENCIES_SUCCESS,
    payload: payload,
  };
}
export function getCurrenciesClean() {
  return {
    type: CURRENCIES_CLEAN,
  };
}

//Register Client
export function DynamicElementsRequest(payload) {
  return {
    type: REGISTER_DYNAMIC_ELEMENT_REQUEST,
    payload: payload,
  };
}
export function DynamicElementsSuccess(payload) {
  return {
    type: REGISTER_DYNAMIC_ELEMENT_SUCCESS,
    payload: payload,
  };
}
export function DynamicElementsClean(payload) {
  return {
    type: REGISTER_DYNAMIC_ELEMENT_CLEAN,
    payload: payload,
  };
}
