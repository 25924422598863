import reducer from './redux-saga/reducers';

import {
  watchShoppingByFamilyMassiveUploadSetAllowUpload,
  watchShoppingByFamilyMassiveUploadRequestVerify,
  watchShoppingByFamilyMassiveUploadRequestUpload,
  watchExistsStatements,
} from './redux-saga/sagas';

export default function getShoppingByFamilyMassiveUploadModule() {
  return {
    id: 'ShoppingByFamily_MASSIVE_UPLOAD',
    reducerMap: {
      ShoppingByFamily_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchShoppingByFamilyMassiveUploadSetAllowUpload,
      watchShoppingByFamilyMassiveUploadRequestVerify,
      watchShoppingByFamilyMassiveUploadRequestUpload,
      watchExistsStatements,
    ],
  };
}
