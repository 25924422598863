const mapQueryParams = (queryString) => {
  '?objectiveIncentive=Annual&as=df';

  let string = queryString.slice(1);
  let stringArray = string.split('&');
  let respObject = {};

  stringArray.forEach((item) => {
    let eachItemList = item.split('=');
    let name = eachItemList[0];
    let value = eachItemList[1];
    respObject[name] = value;
  });

  return respObject;
};

export default mapQueryParams;
