import React from 'react';
import { Row, Col, Input, Form } from 'antd';
import { BInput } from 'common/components';

const SectionPog = ({ statusSwitch }) => {
  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item name="introPog" label="Introducción:">
            <Input.TextArea
              disabled={statusSwitch}
              placeholder="Escribe Introducción"
              maxLength={750}
              autoSize
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <BInput
            disabled={statusSwitch}
            className="form-add-user-input"
            name="subt3"
            maxLength={250}
            placeholder="Escribe Subtítulo 3"
            label="Subtítulo 3:"
          />
        </Col>

        <Col span={24}>
          <Form.Item name="notePog" label="Nota:">
            <Input.TextArea
              disabled={statusSwitch}
              maxLength={500}
              placeholder="Escribe Nota"
              autoSize
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default SectionPog;
