import { takeEvery, put, call, all, cancelled } from 'redux-saga/effects';
import {
  PlACEMENTS_REQUEST,
  TYPE_CLIENT_REQUEST,
  BUSINESS_LINES_REQUEST,
  SEGMENTATION_REQUEST,
  COMPANY_REQUEST,
  POGS_REQUEST,
  TERRITORIES_REQUEST,
  CURRENCIES_REQUEST,
  REGISTER_CLIENT_REQUEST,
  RESPONSABLE_REQUEST,
  READ_ONE_CLIENT_REQUEST,
  VALIDATE_CLIENT_REQUEST,
  RESPONSABLE_POSITION_REQUEST,
  STATUS_CLIENT_REQUEST,
  getPlacementsSuccess,
  getPlacementsClean,
  getTypeClientSuccess,
  getTypeClientClean,
  getBusinessLinesSuccess,
  getBusinessLinesClean,
  getSegmentationSuccess,
  getSegmentationClean,
  getCompanyClean,
  getCompanySuccess,
  getTerritoriesSuccess,
  getTerritoriesClean,
  getPogsClean,
  getPogsSuccess,
  getCurrenciesSuccess,
  getCurrenciesClean,
  postClientClean,
  postClientSuccess,
  getResponsableSuccess,
  getResponsableClean,
  readOneClientSuccess,
  readOneClientClean,
  validateClientSuccess,
  validateClientClean,
  getResponsablePositionSuccess,
  getResponsablePositionClean,
  statusClientSuccess,
  statusClientClean,
} from '../actions';
import {
  getOptionsClient,
  updateClient,
  getResponsableClient,
  getTerritoriesClient,
  getOneClient,
  validateClient,
  statusClient,
} from '../../services/index';
import instance from 'services/request';
import { responseOK } from 'SalesChallengeRefactor/utils';

/* import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'; */
//change status client
function* statusClientRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const { clientId, enable, setStatusSwitch, onSuccess, errorMessage, history } = payload;
    const response = yield call(statusClient, {
      clientId,
      enable,
    });

    if (responseOK(response)) {
      yield onSuccess(response.message);
      yield put(statusClientSuccess(response.data));
      yield setStatusSwitch({
        disabled: false,
        checked: !enable,
      });
      if (enable === false) {
        setTimeout(() => {
          history.push('/catalog/clients');
        }, 300);
      }
    } else {
      yield errorMessage(response.message);
      yield put(statusClientClean(response.data));
    }
  } catch (error) {
    yield put(statusClientClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchStatusClientRequest() {
  yield all([takeEvery(STATUS_CLIENT_REQUEST, statusClientRequest)]);
}
//get data cliente
function* validateClientRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { soldTo, lineId } = payload;

    const response = yield call(validateClient, {
      soldTo,
      lineId,
    });

    if (responseOK(response)) {
      yield put(validateClientSuccess(response.data));
    } else {
      yield put(validateClientClean(response.data));
    }
  } catch (error) {
    yield put(validateClientClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchValidateClientRequest() {
  yield all([takeEvery(VALIDATE_CLIENT_REQUEST, validateClientRequest)]);
}
//validate lciente
function* workReadOneClientRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { clientId } = payload;

    const response = yield call(getOneClient, {
      clientId,
    });

    if (responseOK(response)) {
      const dataToSave = {
        ...response.data,
        clientManagers: response.data.clientManagers?.map((manager, index) => {
          return { ...manager, id: index };
        }),
        clientSigners: response.data.clientSigners.map((signer, index) => {
          return { ...signer, id: index };
        }),
      };
      yield put(readOneClientSuccess(dataToSave));
    } else {
      yield put(readOneClientClean());
    }
  } catch (error) {
    yield put(readOneClientClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchReadOneClientRequest() {
  yield all([takeEvery(READ_ONE_CLIENT_REQUEST, workReadOneClientRequest)]);
}
//position responsable
function* workGetResponsablePositionRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  const { payload } = action;
  const { TerritoryId, position } = payload;

  try {
    const response = yield call(getResponsableClient, {
      cancelToken: source.token,
      roleId: 5,
      territoryId: TerritoryId,
    });

    if (responseOK(response)) {
      yield put(getResponsablePositionSuccess({ data: response.data, position }));
    } else {
      yield put(getResponsablePositionClean());
    }
  } catch (error) {
    yield put(getResponsablePositionClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetResponsablePositionRequest() {
  yield all([takeEvery(RESPONSABLE_POSITION_REQUEST, workGetResponsablePositionRequest)]);
}

//get responsable

function* workGetResponsableRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(getResponsableClient, {
      cancelToken: source.token,
      roleId: 5,
      territoryId: payload.TerritoryId,
    });

    if (responseOK(response)) {
      yield put(getResponsableSuccess(response.data));
    } else {
      yield put(getResponsableClean());
    }
  } catch (error) {
    yield put(getResponsableClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetResponsableRequest() {
  yield all([takeEvery(RESPONSABLE_REQUEST, workGetResponsableRequest)]);
}

//get placements
function* workGetPlacementsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOptionsClient, {
      cancelToken: source.token,
      name: 'Placements',
    });

    if (responseOK(response)) {
      yield put(getPlacementsSuccess(response.data));
    } else {
      yield put(getPlacementsClean());
    }
  } catch (error) {
    yield put(getPlacementsClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetPlacementsRequest() {
  yield all([takeEvery(PlACEMENTS_REQUEST, workGetPlacementsRequest)]);
}

//get tipo

function* workGetTypeClientRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOptionsClient, {
      cancelToken: source.token,
      name: 'ClientTypes',
    });

    if (responseOK(response)) {
      yield put(getTypeClientSuccess(response.data));
    } else {
      yield put(getTypeClientClean());
    }
  } catch (error) {
    yield put(getTypeClientClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetTypeClientRequest() {
  yield all([takeEvery(TYPE_CLIENT_REQUEST, workGetTypeClientRequest)]);
}
//get busines lines

function* workGetBussinesLinesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOptionsClient, {
      cancelToken: source.token,
      name: 'BusinessLines',
    });

    if (responseOK(response)) {
      yield put(getBusinessLinesSuccess(response.data));
    } else {
      yield put(getBusinessLinesClean());
    }
  } catch (error) {
    yield put(getBusinessLinesClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetBussinesLinesRequest() {
  yield all([takeEvery(BUSINESS_LINES_REQUEST, workGetBussinesLinesRequest)]);
}

//get segmantation

function* workGetSegmentactionRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOptionsClient, {
      cancelToken: source.token,
      name: 'Segmentation',
    });

    if (responseOK(response)) {
      yield put(getSegmentationSuccess(response.data));
    } else {
      yield put(getSegmentationClean());
    }
  } catch (error) {
    yield put(getSegmentationClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetSegmentationRequest() {
  yield all([takeEvery(SEGMENTATION_REQUEST, workGetSegmentactionRequest)]);
}
//get company

function* workGetCompanyRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOptionsClient, {
      cancelToken: source.token,
      name: 'Companies',
    });

    if (responseOK(response)) {
      yield put(getCompanySuccess(response.data));
    } else {
      yield put(getCompanyClean());
    }
  } catch (error) {
    yield put(getCompanyClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetCompanyRequest() {
  yield all([takeEvery(COMPANY_REQUEST, workGetCompanyRequest)]);
}
//get Territories

function* workGetTerritoriesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(getTerritoriesClient, {
      cancelToken: source.token,
      payload,
    });

    if (responseOK(response)) {
      yield put(getTerritoriesSuccess(response.data));
    } else {
      yield put(getTerritoriesClean());
    }
  } catch (error) {
    yield put(getTerritoriesClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetTerritoriesRequest() {
  yield all([takeEvery(TERRITORIES_REQUEST, workGetTerritoriesRequest)]);
}

//get pogs

function* workGetPogsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOptionsClient, {
      cancelToken: source.token,
      name: 'Pogs',
    });

    if (responseOK(response)) {
      yield put(getPogsSuccess(response.data));
    } else {
      yield put(getPogsClean());
    }
  } catch (error) {
    yield put(getPogsClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetPogsRequest() {
  yield all([takeEvery(POGS_REQUEST, workGetPogsRequest)]);
}

//get currencies

function* workGetCurrenciesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOptionsClient, {
      cancelToken: source.token,
      name: 'Currencies',
    });

    if (responseOK(response)) {
      yield put(getCurrenciesSuccess(response.data));
    } else {
      yield put(getCurrenciesClean());
    }
  } catch (error) {
    yield put(getCurrenciesClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetCurrenciesRequest() {
  yield all([takeEvery(CURRENCIES_REQUEST, workGetCurrenciesRequest)]);
}

//Register clients

function* workRegisterClientRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { parameters, onSuccess, errorMessage, clientId } = payload;
  try {
    const response = yield call(updateClient, {
      cancelToken: source.token,
      data: parameters,
      clientId,
    });

    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess('La actualización del cliente se realizó correctamente');
      }
      yield put(postClientSuccess(response.data));
    } else {
      if (errorMessage) {
        yield errorMessage(response.message);
      }
      yield put(postClientClean());
    }
  } catch (error) {
    yield put(postClientClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchRegisterClientRequest() {
  yield all([takeEvery(REGISTER_CLIENT_REQUEST, workRegisterClientRequest)]);
}
