import React from 'react';
import Styles from './container-profile.module.css';

const ContainerProfile = ({
  hideUpdatePassword,
  showUpdatePassword,
  userProfile,
  updatePassword,
  showModal,
}) => {
  return (
    <div className={Styles['container-profile']} onMouseLeave={hideUpdatePassword}>
      <div className={Styles['profile']} onMouseEnter={showUpdatePassword}>
        <p>{userProfile}</p>
      </div>
      {updatePassword && (
        <button className={Styles['continer-update-password']} onClick={showModal}>
          <p>Actualizar contraseña</p>
        </button>
      )}
    </div>
  );
};

export default ContainerProfile;
