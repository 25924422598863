import { SET_CURRENT_VIEW } from '../actions/views';
import {
  CLEAN_INDICATORS,
  INDICATORS_REQUEST,
  INDICATORS_SUCCESS,
  INDICATORS_FAIL,
  INDICATORS_SET,
  KAM_INDICATORS_REQUEST,
  KAM_INDICATORS_SUCCESS,
  KAM_INDICATORS_FAIL,
} from '../actions/indicators';
import {
  CLEAN_INFO,
  INFO_REQUEST,
  INFO_SUCCESS,
  INFO_FAIL,
  KAM_INFO_REQUEST,
  KAM_INFO_SUCCESS,
  KAM_INFO_FAIL,
} from '../actions/info';

const filters = (state = {}, action) => {
  switch (action.type) {
    case SET_CURRENT_VIEW:
      return {
        ...state,
        current: action.payload,
      };
    case CLEAN_INDICATORS:
      return {
        ...state,
        current: undefined,
        indicators: undefined,
        info: undefined,
      };
    case INDICATORS_REQUEST:
      return {
        ...state,
        indicators: {
          isLoading: true,
          data: undefined,
        },
      };
    case INDICATORS_SUCCESS:
      return {
        ...state,
        indicators: {
          isLoading: false,
          data: action.payload,
        },
      };
    case INDICATORS_FAIL:
      return {
        ...state,
        indicators: {
          isLoading: false,
          data: undefined,
        },
      };
    case KAM_INDICATORS_REQUEST:
      return {
        ...state,
        kamIndicators: {
          isLoading: true,
          data: undefined,
        },
      };
    case KAM_INDICATORS_SUCCESS:
      return {
        ...state,
        kamIndicators: {
          isLoading: false,
          data: action.payload,
        },
      };
    case KAM_INDICATORS_FAIL:
      return {
        ...state,
        kamIndicators: {
          isLoading: false,
          data: undefined,
        },
      };
    case CLEAN_INFO:
      return {
        ...state,
        current: undefined,
        indicators: undefined,
        kamIndicators: undefined,
        info: undefined,
        kamInfo: undefined,
      };
    case INFO_REQUEST:
      return {
        ...state,
        info: {
          isLoading: true,
          data: undefined,
        },
      };
    case INFO_SUCCESS:
      return {
        ...state,
        info: {
          isLoading: false,
          data: action.payload,
        },
      };
    case INFO_FAIL:
      return {
        ...state,
        info: {
          isLoading: false,
          data: undefined,
        },
      };
    case KAM_INFO_REQUEST:
      return {
        ...state,
        kamInfo: {
          isLoading: true,
          data: undefined,
        },
      };
    case KAM_INFO_SUCCESS:
      return {
        ...state,
        kamInfo: {
          isLoading: false,
          data: action.payload,
        },
      };
    case KAM_INFO_FAIL:
      return {
        ...state,
        kamInfo: {
          isLoading: false,
          data: undefined,
        },
      };
    case INDICATORS_SET: {
      return {
        ...state,
        indicators: {
          ...state.indicators,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default filters;
