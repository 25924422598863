import React from 'react';
import './massiveUploadTableColumns.css';

const messageFaseOne = (
  <>
    <p>
      Registros para los cuales se omitió ingresar campos obligatorios, se ingresó un tipo de dato
      que no corresponde al solicitado en el campo o se excedió la longitud máxima.
    </p>
  </>
);
const messageFaseThree = (
  <>
    <p>Registros que se encuentran repetidos dentro del archivo de carga masiva.</p>
  </>
);
const errorMessages = [
  {
    fase: 'Fase 1',
    message: messageFaseOne,
  },
  // {
  //   fase: 'Fase 2',
  //   message: messageFaseTwo,
  // },
  {
    fase: 'Fase 3',
    message: messageFaseThree,
  },
];

export default errorMessages;
