import request from '../../services/request';
//import { paramsSerializer } from 'RootModule/services/catalogs';
const CONFIRM_VALIDATOR_API = '/Sales/Api/v1/Validators/UserIsValidator/';

export async function SalesChallengeValidatorConfirm(zoneId, cancelToken) {
  return request(`${CONFIRM_VALIDATOR_API}${zoneId}`, {
    method: 'GET',
    cancelToken,
  });
}
