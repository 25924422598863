export const POG_SECTION_REQUEST = 'POG_SECTION_REQUEST';
export const GET_POG_SECTION = 'GET_POG_SECTION';
export const POG_SECTION_FAIL = 'POG_SECTION_FAIL';
export const CLEAN_POG_SECTION = 'CLEAN_POG_SECTION';
export const POG_SUBREGIONS_REQUEST = 'POG_SUBREGIONS_REQUEST';
export const POG_SUBREGIONS_SET = 'POG_SUBREGIONS_SET';
export const POG_SUBREGIONS_CLEAN = 'POG_SUBREGIONS_CLEAN';
export function pogSubregionsRequest(payload) {
  return {
    type: POG_SUBREGIONS_REQUEST,
    payload: payload,
  };
}

export function pogSubregionsSet(payload) {
  return {
    type: POG_SUBREGIONS_SET,
    payload: payload,
  };
}

export function pogSubregionsClean(payload) {
  return {
    type: POG_SUBREGIONS_CLEAN,
    payload: payload,
  };
}

export function pogSectionRequest(payload) {
  return {
    type: POG_SECTION_REQUEST,
    payload: payload,
  };
}

export function getPogSection(payload) {
  return {
    type: GET_POG_SECTION,
    payload: payload,
  };
}

export function pogSectionFail(payload) {
  return {
    type: POG_SECTION_FAIL,
    payload: payload,
  };
}

export function cleanPogSection(payload) {
  return {
    type: CLEAN_POG_SECTION,
    payload: payload,
  };
}
