export default function excelParameters(filter) {
  const params = {};

  function mapIndicator(name) {
    switch (name) {
      case 'salesChallenge':
        return 0;

      case 'agreement':
        return 1;

      case 'sales':
        return 2;

      case 'pog':
        return 3;

      case 'salesGoals':
        return 4;

      default:
        break;
    }
  }
  filter.forEach((singleFilter) => {
    const filterKey = Object.keys(singleFilter)[0];
    const filterValue = Object.values(singleFilter)[0];

    if (filterValue !== undefined) {
      switch (filterKey) {
        case 'indicator1':
          params.FirstIndicator = mapIndicator(filterValue);
          break;
        case 'indicator2':
          params.SecondIndicator = mapIndicator(filterValue);
          break;
        case 'indicator3':
          params.ThirdIndicator = mapIndicator(filterValue);
          break;
        case 'typeinformation':
          params.IsVolume = filterValue === 2;
          break;

        default:
          break;
      }
    }
  });

  return params;
}
