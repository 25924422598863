import {
  SALES_CHALLENGE_TERRITORY_VALIDATOR_REQUEST,
  SALES_CHALLENGE_TERRITORY_VALIDATOR_SUCCESS,
  SALES_CHALLENGE_TERRITORY_VALIDATOR_FAIL,
} from '../actions/salesChallengeTerritoryValidator';

const initialState = {
  isSalesChallengeValidator: false,
  isLoading: false,
};

const salesChallengeTeritoryValidator = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SALES_CHALLENGE_TERRITORY_VALIDATOR_REQUEST:
      return {
        ...state,
        isSalesChallengeTerritoryValidator: false,
        isLoading: true,
      };

    case SALES_CHALLENGE_TERRITORY_VALIDATOR_SUCCESS:
      return {
        ...state,
        isSalesChallengeTerritoryValidator: payload,
        isLoading: false,
      };
    case SALES_CHALLENGE_TERRITORY_VALIDATOR_FAIL:
      return {
        ...state,
        isSalesChallengeTerritoryValidator: false,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default salesChallengeTeritoryValidator;
