/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  consultFiltersBusinessLineRequestOptions,
  consultFiltersBusinessLineSetValue,
} from 'RootModule/redux/actions/consultFilters';
import { addAllBtnOnOptions, handleAllBtnClick } from 'common/consults/utils';

import Filter from 'common/consults/components/Filter';

const BusinessLine = (props) => {
  const {
    consultFiltersBusinessLineRequestOptions: requestOptions,
    consultFiltersBusinessLineSetValue: setValue,
    value,
    required,
    options,
    params,
    disableSelectAll,
    span,
  } = props;

  let mappedOptions = [...options];

  let jsonParams = JSON.stringify(params);

  useEffect(() => {
    let sentParams = {};
    if (params) {
      sentParams = { ...params };
    }
    requestOptions(sentParams);
  }, [jsonParams]);

  const selectChange = (selected) => {
    selected = handleAllBtnClick(selected, options);
    setValue(selected);
  };
  if (!disableSelectAll) {
    mappedOptions = addAllBtnOnOptions(options);
  }
  return (
    <Filter
      name={'L. de Negocio'}
      required={required}
      value={value}
      selectChange={selectChange}
      options={mappedOptions}
      span={span}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    options: state?.APP?.consultFilters.businessLine?.options ?? [],
    value: state?.APP?.consultFilters.businessLine?.value,
  };
};

BusinessLine.propTypes = {
  consultFiltersBusinessLineRequestOptions: PropTypes.func,
  consultFiltersBusinessLineSetValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  required: PropTypes.bool,
  options: PropTypes.array,
  params: PropTypes.object,
  disableSelectAll: PropTypes.bool,
};

BusinessLine.defaultProps = {
  consultFiltersBusinessLineRequestOptions: () => {
    return;
  },
  consultFiltersBusinessLineSetValue: () => {
    return;
  },
  value: [],
  required: false,
  options: [],
  params: {},
  disableSelectAll: false,
};

export default connect(mapStateToProps, {
  consultFiltersBusinessLineRequestOptions,
  consultFiltersBusinessLineSetValue,
})(BusinessLine);
