export const ProductMaster_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD =
  'ProductMaster_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const ProductMaster_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'ProductMaster_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const ProductMaster_MASSIVE_UPLOAD_REQUEST_VERIFY =
  'ProductMaster_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const ProductMaster_MASSIVE_UPLOAD_SET_VERIFY = 'ProductMaster_MASSIVE_UPLOAD_SET_VERIFY';
export const ProductMaster_MASSIVE_UPLOAD_CLEAR_VERIFY =
  'ProductMaster_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const ProductMaster_MASSIVE_UPLOAD_REQUEST_UPLOAD =
  'ProductMaster_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const ProductMaster_MASSIVE_UPLOAD_SET_UPLOAD = 'ProductMaster_MASSIVE_UPLOAD_SET_UPLOAD';
export const ProductMaster_MASSIVE_UPLOAD_CLEAR_UPLOAD =
  'ProductMaster_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function ProductMasterMassiveUploadSetAllowUpload(payload) {
  return {
    type: ProductMaster_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function ProductMasterMassiveUploadClearAllowUpload(payload) {
  return {
    type: ProductMaster_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function ProductMasterMassiveUploadRequestVerify(payload) {
  return {
    type: ProductMaster_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function ProductMasterMassiveUploadSetVerify(payload) {
  return {
    type: ProductMaster_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function ProductMasterMassiveUploadClearVerify(payload) {
  return {
    type: ProductMaster_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function ProductMasterMassiveUploadRequestUpload(payload) {
  return {
    type: ProductMaster_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function ProductMasterMassiveUploadSetUpload(payload) {
  return {
    type: ProductMaster_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function ProductMasterMassiveUploadClearUpload(payload) {
  return {
    type: ProductMaster_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
