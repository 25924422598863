export const GET_TypeElement_REQUEST = 'GET_TypeElement_REQUEST';
export const GET_TypeElement_SUCCESS = 'GET_TypeElement_SUCCESS';
export const GET_TypeElement_CLEAN = 'GET_TypeElement_CLEAN';

export const GET_EXCEL_REQUEST = 'GET_EXCEL_REQUEST';
export const GET_EXCEL_SUCCESS = 'GET_EXCEL_SUCCESS';
export const GET_EXCEL_CLEAN = 'GET_EXCEL_CLEAN';

export const DELETE_TypeElement_REQUEST = 'DELETE_TypeElement_REQUEST';
export const DELETE_TypeElement_SUCCESS = 'DELETE_TypeElement_SUCCESS';
export const DELETE_TypeElement_CLEAN = 'DELETE_TypeElement_CLEAN';

export const PUT_TypeElement_REQUEST = 'PUT_TypeElement_REQUEST';
export const PUT_TypeElement_SUCCESS = 'PUT_TypeElement_SUCCESS';
export const PUT_TypeElement_CLEAN = 'PUT_TypeElement_CLEAN';

export const POST_DisplayExcel_REQUEST = 'POST_DisplayExcel_REQUEST';
export const POST_DisplayExcel_Data_REQUEST = 'POST_DisplayExcel_Data_REQUEST';

export const POST_TypeElement_REQUEST = 'POST_TypeElement_REQUEST';
export const POST_TypeElement_SUCCESS = 'POST_TypeElement_SUCCESS';
export const POST_TypeElement_CLEAN = 'POST_TypeElement_CLEAN';

export const POST_ValidateFiles_REQUEST = 'POST_ValidateFiles_REQUEST';
export const POST_ValidateFiles_SUCCESS = 'POST_ValidateFiles_SUCCESS';
export const POST_ValidateFiles_CLEAN = 'POST_ValidateFiles_CLEAN';

//POST Region, Line, Subregion, Territory, Zone
export function postValidateFilesRequest(payload) {
  return {
    type: POST_ValidateFiles_REQUEST,
    payload: payload,
  };
}

export function postValidateFilesSuccess(payload) {
  return {
    type: POST_ValidateFiles_SUCCESS,
    payload: payload,
  };
}
export function postValidateFilesClean() {
  return {
    type: POST_ValidateFiles_CLEAN,
  };
}

//GET Region, Line, Subregion, Territory, Zone
export function getExcelRequest(payload) {
  return {
    type: GET_EXCEL_REQUEST,
    payload: payload,
  };
}
export function getExcelSuccess(payload) {
  return {
    type: GET_EXCEL_SUCCESS,
    payload: payload,
  };
}
export function getExcelClean() {
  return {
    type: GET_EXCEL_CLEAN,
  };
}

//DELETE Region, Line, Subregion, Territory, Zone
export function deleteTypeElementRequest(payload) {
  return {
    type: DELETE_TypeElement_REQUEST,
    payload: payload,
  };
}
export function deleteTypeElementSuccess(payload) {
  return {
    type: DELETE_TypeElement_SUCCESS,
    payload: payload,
  };
}
export function deleteTypeElementClean() {
  return {
    type: DELETE_TypeElement_CLEAN,
  };
}

//POST Region, Line, Subregion, Territory, Zone
export function postTypeElementRequest(payload) {
  return {
    type: POST_TypeElement_REQUEST,
    payload: payload,
  };
}

export function postTypeElementSuccess(payload) {
  return {
    type: POST_TypeElement_SUCCESS,
    payload: payload,
  };
}
export function postTypeElementClean() {
  return {
    type: POST_TypeElement_CLEAN,
  };
}

//DisplayMap
export function postDisplayExcelRequest(payload) {
  return {
    type: POST_DisplayExcel_REQUEST,
    payload: payload,
  };
}
//POST Coordinates
export function postDisplayExcelDataRequest(payload) {
  return {
    type: POST_DisplayExcel_Data_REQUEST,
    payload: payload,
  };
}

//PUT Region, Line, Subregion, Territory, Zone
export function putTypeElementRequest(payload) {
  return {
    type: PUT_TypeElement_REQUEST,
    payload: payload,
  };
}
export function putTypeElementSuccess(payload) {
  return {
    type: PUT_TypeElement_SUCCESS,
    payload: payload,
  };
}
export function putTypeElementClean() {
  return {
    type: PUT_TypeElement_CLEAN,
  };
}

//GET Region, Line, Subregion, Territory, Zone
export function getTypeElementRequest(payload) {
  return {
    type: GET_TypeElement_REQUEST,
    payload: payload,
  };
}
export function getTypeElementSuccess(payload) {
  return {
    type: GET_TypeElement_SUCCESS,
    payload: payload,
  };
}
export function getTypeElementClean() {
  return {
    type: GET_TypeElement_CLEAN,
  };
}
