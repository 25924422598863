import request from 'services/request';
const baseUrl = `/Catalogs/Api/v1/Maps/`;

//GET Region, Line, Subregion, Territory, Zone
export async function getTypeElement({ cancelToken, TypeElement, params }) {
  return request(`${baseUrl}${TypeElement}/${params}`, {
    method: 'GET',
    cancelToken,
  });
}

//GET EXCEL Region, Line, Subregion, Territory, Zone
export async function getExcel({ cancelToken, TypeElement, params }) {
  return request(`${baseUrl}${TypeElement}/Download/${params}`, {
    method: 'GET',
    cancelToken,
  });
}

//DELETE Region, Line, Subregion, Territory, Zone
export async function deleteTypeElement({ cancelToken, TypeElement, params }) {
  return request(`${baseUrl}${TypeElement}/${params}`, {
    method: 'DELETE',
    cancelToken,
  });
}

//POST Region, Line, Subregion, Territory, Zone
export async function postTypeElement({ cancelToken, TypeElement, data }) {
  return request(`${baseUrl}${TypeElement}`, {
    method: 'POST',
    data,
    cancelToken,
  });
}

//PUT Region, Line, Subregion, Territory, Zone
export async function putTypeElement({ cancelToken, TypeElement, data }) {
  return request(`${baseUrl}${TypeElement}`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

//POST /Catalogs/Api/v1/Maps/ValidateFiles
export async function postValidateFiles({ cancelToken, data }) {
  return request(`/Catalogs/Api/v1/Maps/ValidateFiles`, {
    method: 'POST',
    data,
    cancelToken,
  });
}
