import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Second import
// import useGetPermission from '../../../hooks/useGetPermissions';

/**
 * Role Access Control to display or not components based on a black or white list of roleNames
 */

function RoleAccessControl(props) {
  const {
    children: protectedComponent,
    onNoAccessRender,
    moduleKey,
    page,
    // action,
    permission,
    action: actions,
  } = props;
  // chenage refactor
  //   const checkPermissions = () => {
  //     let userRolName = `${user.groupName}${user.publicName}`;
  //     let allowAccess;
  //     const userIncludesRoleName = roles.includes(userRolName);
  //     if (accessType === "include") {
  //       allowAccess = userIncludesRoleName;
  //     } else if (accessType === "exclude") {
  //       allowAccess = !userIncludesRoleName;
  //     } else {
  //       allowAccess = false;
  //     }
  //     return allowAccess;
  //   };

  const checkPermissions = () => {
    try {
      let permissions = localStorage.getItem('permissions');

      if (permissions && permissions.length > 0) {
        permissions = JSON.parse(permissions);

        let moduleFound = permissions.filter((m) => m.uri === moduleKey);
        /**TODO:REFACTOR  */
        if (moduleFound.length > 0) {
          if (page) {
            let subFound = moduleFound[0].components.filter((sub) => sub.uri === page);

            if (subFound.length > 0) {
              if (actions) {
                let hasAccess = subFound[0].components.filter((p) => p.uri === actions);

                if (hasAccess.length > 0) {
                  if (permission) {
                    let has = hasAccess[0].components.filter((p) => p.uri === permission);

                    return has.length > 0;
                  } else {
                    return hasAccess;
                  }
                }
              } else {
                return subFound;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      }
    } catch (error) {
      console.error('ERROR trying to read permissions ', error);
    }
  };

  return checkPermissions() ? protectedComponent : onNoAccessRender;
}

function mapStateToProps(state) {
  return {
    user: state?.user?.info,
  };
}

RoleAccessControl.propTypes = {
  /* include: Solo tienen accesso los  que esten incluidos en el arreglo roles, 
    exclude: Tienen  accesso todos menos los que esten en el arreglo */
  accessType: PropTypes.oneOf(['include', 'exclude']),
  onNoAccessRender: PropTypes.node,
  roles: PropTypes.array,
};

RoleAccessControl.defaultProps = {
  accessType: 'include',
  onNoAccessRender: null,
  roles: [],
};

export default connect(mapStateToProps, {})(RoleAccessControl);
