import moment from 'moment';
export const getDaysInMonth = (month, year) =>
  new Array(31)
    .fill('')
    .map((v, i) => new Date(year, month - 1, i + 1))
    .filter((v) => v.getMonth() === month - 1)
    .map((d) => {
      let day = d.toString().split(' ')[2];
      return { key: parseInt(day), value: parseInt(day) };
    });

export const getValuesToSend = (val, options) => {
  if (val[0] === 'ALL') {
    return options.map((op) => op.key);
  } else if (val.includes('ALL') && val.length - 1 === options.length) {
    return [];
  } else {
    if (!val.includes('ALL')) {
      return val;
    } else return options.map((op) => op.key);
  }
};
//validations
export const onValuesChange = (
  changedValues,
  form,
  businessLines,
  subRegions,
  setParamsFilter,
  setFormValues,
  value,
  setIsActiveRegister
) => {
  const allValues = form.getFieldsValue();
  const currentChange = Object.keys(changedValues)[0];
  let params;
  if (currentChange === 'businessLine' || currentChange === 'subregion') {
    params = {
      LineId: getValuesToSend(
        allValues.businessLine !== undefined ? allValues.businessLine : [],
        businessLines
      ),
      SubregionId: getValuesToSend(
        allValues.subregion !== undefined ? allValues.subregion : [],
        subRegions
      ),
    };
    if (params.LineId.length === 0) {
      delete params.LineId;
    }
    if (params.SubregionId.length === 0) {
      delete params.SubregionId;
    }
    setParamsFilter(params);
  }

  let count = 0;

  if (value.length > 0) {
    value.forEach((d) => {
      if (
        allValues[`time${d}`] !== undefined &&
        allValues[`time${d}`] !== null &&
        allValues[`month${d}`] !== undefined &&
        allValues[`frecuency${d}`] !== undefined &&
        (allValues[`day${d}`] !== undefined || allValues[`period${d}`] !== undefined)
      ) {
        count++;
      }
    });
  }
  if (
    count === value.length &&
    allValues.copyContact !== undefined &&
    allValues.copyContact !== ''
  ) {
    setIsActiveRegister(false);
  } else {
    setIsActiveRegister(true);
  }
  setFormValues(allValues);
};
const getStatusPeriod = (allValues, periodData, index, nowTime) => {
  let status = true;
  if (periodData[index]) {
    if (
      allValues[`month${index}`] === periodData[index]?.month &&
      allValues[`frecuency${index}`] === periodData[index]?.typePeriodId &&
      allValues[`day${index}`] === periodData[index]?.day &&
      allValues[`period${index}`] === periodData[index]?.periodId &&
      nowTime.minutes() === periodData[index]?.minute &&
      nowTime.hour() === periodData[index]?.hour
    ) {
      status = false;
    }
  } else {
    status = false;
  }
  return status;
};
//register data
export const handleSubmit = (
  form,
  statusData,
  value,
  paramsClient,
  clientData,
  register,
  history
) => {
  const allValues = form.getFieldsValue();
  let { autoPeriodDelivery } = statusData;
  let periodData = autoPeriodDelivery
    .sort((a, b) => a.month - b.month)
    .map((d) => {
      return {
        day: d.day === null ? undefined : d.day,
        deliveryScheduleId: d.deliveryScheduleId === null ? undefined : d.deliveryScheduleId,
        hour: d.hour === null ? undefined : d.hour,
        minute: d.minute === null ? undefined : d.minute,
        month: d.month === null ? undefined : d.month,
        periodId: d.periodId === null ? undefined : d.periodId,
        typePeriodId: d.typePeriodId === null ? undefined : d.typePeriodId,
        update: d.update === null ? undefined : d.update,
      };
    });
  let parameters = {};
  let listPeriods = [];
  if (value.length > 0) {
    value.forEach((d) => {
      let nowTime = moment(allValues[`time${d}`]);
      listPeriods.push({
        year: parseInt(allValues[`month${d}`].split('-')[1]),
        month: parseInt(allValues[`month${d}`].split('-')[0]),
        typePeriod: allValues[`frecuency${d}`],
        day: allValues[`day${d}`],
        periodDeliveryId: allValues[`period${d}`],
        hour: nowTime.hour(),
        ScheduleId: periodData[d]?.deliveryScheduleId,
        minute: nowTime.minutes(),
        update: getStatusPeriod(allValues, periodData, d, nowTime),
      });
    });
  }
  let clientActive = paramsClient?.activeClients?.map((d) => ({ ...d, isAutomatic: true }));
  let clientDesactive = paramsClient?.inactiveClients?.map((d) => ({ ...d, isAutomatic: false }));
  let clientes = clientActive.concat(clientDesactive);
  let clientSend = [];
  clientes.forEach((c) => {
    clientData.forEach((clienteStatus) => {
      if (clienteStatus.clientId === c.clientId && clienteStatus.lineId === c.lineId) {
        if (clienteStatus.isManual === c.isAutomatic) {
          clientSend.push(c);
        }
      }
    });
  });

  parameters.listPeriods = listPeriods;
  parameters.emailCopy = allValues[`copyContact`];
  parameters.clients = clientSend;
  register({ params: parameters, history });
};
