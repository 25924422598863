import {
  ADD_PRODUCT_REQUEST,
  addProductSuccess,
  addProductFail,
} from 'SalesChallengeRefactor/redux/actions/addProduct';
import { takeLatest, cancelled, put, call, delay } from 'redux-saga/effects';
import { responseOK } from 'SalesChallengeRefactor/utils';
import instance from 'services/request';
import { addProduct } from 'SalesChallengeRefactor/services/challenge';
import { openNotification } from 'common/misc/openNotification';

function* workAddProductRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, onSuccess } = payload;
    const response = yield call(addProduct, { params, cancelToken: source.token });

    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess(response.data);
      }
      yield put(addProductSuccess(response.data));
      yield delay(500);
      openNotification('success', response.message);
    } else {
      yield put(addProductFail());
    }
  } catch (e) {
    yield put(addProductFail());
    console.error('Error in saga execution', e);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchAddProductRequest() {
  yield takeLatest(ADD_PRODUCT_REQUEST, workAddProductRequest);
}
