import rootReducer from './redux-saga/reducers/rootReducer';
import rootSagas from './redux-saga/sagas/index';

export default function getRootModule() {
  return {
    id: 'UPDATEPRICES',
    reducerMap: {
      UPDATEPRICES: rootReducer,
    },
    sagas: [rootSagas],
  };
}
