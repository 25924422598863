/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { ConsultShoppingByFamilyRequestTable } from '../redux-saga/actions';
import getConsultShoppingByFamilyModule from '../redux-saga/getModule';
import FilterWrapper from 'common/consults/components/FilterWrapper';
import { BusinessLine, Subregion, Family } from 'RootModule/consultFilters';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import SearchInput from 'common/consults/components/SearchInput';
import ResetButton from 'common/consults/components/ResetButton';
import consultTableSchema from '../schemas/consultTableSchema';
import ConsultTable from 'common/consults/components/Table';
import {
  consultFiltersBusinessLineClearValue,
  consultFiltersSubregionClearValue,
  consultFiltersFamilyClearValue,
  consultFiltersSearchInputClearValue,
} from 'RootModule/redux/actions/consultFilters';
import { useHistory } from 'react-router-dom';
import { layoutContext } from 'context';
import getRootModule from 'RootModule';
import { connect } from 'react-redux';
import { Col, Table } from 'antd';
import './consult.css';
import { formatCommas } from 'utils';

const Consult = (props) => {
  const {
    ConsultShoppingByFamilyRequestTable: requestConsultTable,
    consultFiltersBusinessLineClearValue: clearBusinessLines,
    consultFiltersSubregionClearValue: clearSubregion,
    consultFiltersFamilyClearValue: clearFamily,
    consultFiltersSearchInputClearValue: clearSearchInput,
    consultTable,
    isLoading,
    SearchInputValue,
    BusinessLinesValue,
    SubregionValue,
    FamilyValue,
  } = props;
  const { onChangeActiveComponent } = useContext(layoutContext);
  const history = useHistory();
  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, []);

  useEffect(() => {
    let consultTableParams = {};
    onChangeActiveComponent('BusinessStructureMassiveUpload');
    if (BusinessLinesValue?.length > 0) {
      consultTableParams.Lines = BusinessLinesValue;
    }
    if (SubregionValue?.length > 0) {
      consultTableParams.Subregions = SubregionValue;
    }
    if (FamilyValue?.length > 0) {
      consultTableParams.Families = FamilyValue;
    }
    if (SearchInputValue?.length > 0) {
      consultTableParams.Wildcard = [SearchInputValue];
    }
    requestConsultTable({ params: consultTableParams, history });
  }, [BusinessLinesValue, SubregionValue, FamilyValue, SearchInputValue]);

  const clearFilters = () => {
    clearBusinessLines();
    clearSubregion();
    clearSearchInput();
    clearFamily();
  };
  const dataComplete = () => {
    let newData = [];
    if (consultTable) {
      newData = [...consultTable.data];
    }
    return newData;
  };
  const summaryTable = () => {
    if (consultTable) {
      return (
        <>
          <Table.Summary.Row>
            <Table.Summary.Cell>TOTAL GENERAL</Table.Summary.Cell>
            <Table.Summary.Cell />
            <Table.Summary.Cell />
            <Table.Summary.Cell />
            <Table.Summary.Cell />
            <Table.Summary.Cell>{formatCommas(consultTable.sumVolumeAgreement)}</Table.Summary.Cell>
            <Table.Summary.Cell>{formatCommas(consultTable.sumBrutePurchase)}</Table.Summary.Cell>
            <Table.Summary.Cell />
            <Table.Summary.Cell>{formatCommas(consultTable.sumVolumePurchase)}</Table.Summary.Cell>
            <Table.Summary.Cell />
          </Table.Summary.Row>
        </>
      );
    }
  };
  return (
    <div>
      <FilterWrapper>
        <BusinessLine span={4} />
        <Subregion span={4} />
        <Family span={4} />
        <Col span={5} />
        <SearchInput style={{ width: '100%' }} span={5} floatToRight />
        <ResetButton clearFilters={clearFilters} />
      </FilterWrapper>
      <ConsultTable
        data={dataComplete()}
        columns={consultTableSchema}
        loading={isLoading}
        id="ShoppingByFamilyTableConsult"
        summary={() => (dataComplete().length ? summaryTable() : null)}
        noResultsMessage={
          'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados.'
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    consultTable: state?.CONSULT_SHOPPING_BY_FAMILY?.consultTable,
    isLoading: state?.CONSULT_SHOPPING_BY_FAMILY?.isLoading,
    BusinessLinesValue: state?.APP?.consultFilters.businessLine?.value,
    SearchInputValue: state?.APP?.consultFilters?.searchInput?.value,
    SubregionValue: state?.APP?.consultFilters?.subregion?.value,
    FamilyValue: state?.APP?.consultFilters?.family?.value,
  };
};

const ConnectedConsultShoppingByFamily = connect(mapStateToProps, {
  ConsultShoppingByFamilyRequestTable,
  consultFiltersBusinessLineClearValue,
  consultFiltersSubregionClearValue,
  consultFiltersFamilyClearValue,
  consultFiltersSearchInputClearValue,
})(Consult);

const consultBaseInfo = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getConsultShoppingByFamilyModule()]}>
      <ConnectedConsultShoppingByFamily />
    </DynamicModuleLoader>
  );
};
export default consultBaseInfo;
