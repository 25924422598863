import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getSalesChallengeModule from '../../salesChallengeModule';
import getRootModule from 'RootModule';
import { layoutContext } from '../../../context';
import { setCurrentModule } from '../../redux/actions/module';
import { cleanInfo } from '../../redux/actions/info';
import { queryClean } from '../../redux/actions/query';
import { cleanChallenge } from '../../redux/actions/challenge';
import { graphClean } from '../../redux/actions/graph';
import { cleanIndicators } from '../../redux/actions/indicators';
import { cleanRegisterSale } from '../../redux/actions/registerSale';
import { cleanTable, validateTableRequest } from '../../redux/actions/table';
import { useDispatch } from 'react-redux';
import LayoutSalesChallengeRegister from '../../components/LayoutSalesChallengeRegister/';
import { actionChallengeEnum } from '../../models/enums/';
import DeleteProductModal from 'SalesChallengeRefactor/components/DeleteProductModal/DeleteProductModal';
import '../SalesChallenge/style.css';
function SalesChallengeRegister(props) {
  const { onChangeActiveComponent } = useContext(layoutContext);
  const history = useHistory();

  let pageType = history?.location?.pathname.split('/')?.[2];
  pageType = pageType.toUpperCase();
  pageType = actionChallengeEnum[pageType];
  if (typeof pageType === 'undefined') {
    history.goBack();
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cleanInfo());

    dispatch(cleanChallenge());
    dispatch(graphClean());
    dispatch(cleanIndicators());
    dispatch(cleanChallenge());
    dispatch(cleanRegisterSale());

    dispatch(cleanTable());
    return () => {
      dispatch(queryClean());
      dispatch(validateTableRequest({ state: 'initial', wasSend: false }));
    };
    // dispatch(querySave({ state: 'idle', showBruteValue: true }));
  }, [dispatch]);

  return (
    <>
      <DeleteProductModal />
      <LayoutSalesChallengeRegister
        pageType={pageType.key}
        onChangeActiveComponent={onChangeActiveComponent}
      />
    </>
  );
}

const ConnectedSaleChallenge = connect(null, { setCurrentModule })(SalesChallengeRegister);
const DynamicModule = () => (
  <DynamicModuleLoader modules={[getRootModule(), getSalesChallengeModule()]}>
    <ConnectedSaleChallenge />
  </DynamicModuleLoader>
);

export default DynamicModule;
