import request from 'services/request';
import { paramsSerializer } from './catalogs';

const FIXED_CATALOGS = `/Catalogs/Api/v1/FixedCatalogs/`;
const POGYEARS = '/AccountStatement/Api/v1/Pog/GetPOGYears';
const ACCOUNT_STATEMENT_PURCHASES = `/AccountStatement/Api/v1/Purchases/`;
const OBJECTIVES_INCENTIVES = `AccountStatement/Api/v1/ObjectivesIncentives/`;

export const writeApiServiceHere = () => {
  return { data: {} };
};

// Family
export async function getFamily({ cancelToken, params }) {
  let uri = `AccountStatement/Api/v1/Purchases/CatFamilies`;
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
    params,
  });
}

export async function getBusinessLine({ cancelToken, params }) {
  let uri = `${FIXED_CATALOGS}BusinessLines`;
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
    params,
  });
}

export async function getSubregion({ cancelToken, params }) {
  let uri = `${FIXED_CATALOGS}Subregions`;
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
    params,
  });
}

export async function getSegmentation({ cancelToken }) {
  let uri = `${FIXED_CATALOGS}Segmentation`;
  return request(uri, {
    method: 'GET',
    cancelToken,
  });
}
export async function getPlacement({ cancelToken }) {
  let uri = `${FIXED_CATALOGS}Placements`;
  return request(uri, {
    method: 'GET',
    cancelToken,
  });
}
export async function getYear({ cancelToken, params }) {
  let uri = `${FIXED_CATALOGS}ProcessYears`;
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
    params,
  });
}

export async function getPogYear({ cancelToken, params }) {
  let uri = `${POGYEARS}`;
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
    params,
  });
}

export async function getPogPeriodicity({ cancelToken, params }) {
  let uri = `${FIXED_CATALOGS}Pogs`;
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
    params,
  });
}

export async function getSalesPeriodicity({ cancelToken, params }) {
  let uri = `/AccountStatement/Api/v1/Catalogs/GetSalesIncentivesList`;
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
    params,
  });
}

export async function getTerritories({ cancelToken, params }) {
  let uri = `${FIXED_CATALOGS}Territories`;
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
    params,
  });
}
export async function getMonths({ cancelToken, params }) {
  let uri = `${FIXED_CATALOGS}Territories`;
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
    params,
  });
}

export async function getPurchasesYear({ cancelToken, params }) {
  let uri = `${ACCOUNT_STATEMENT_PURCHASES}GetPurchasesYears`;
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
    params,
  });
}
export async function getObjectivesIncentivesYears({ cancelToken, params }) {
  let uri = `${OBJECTIVES_INCENTIVES}Years`;

  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
    params,
  });
}
