import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BSelect from 'common/components/BSelect';
import { connect } from 'react-redux';
import { getProductsAddRequest, cleanAddProducts } from 'RootModule/redux/actions/catalogs';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';

function AddProducts(props) {
  const {
    products,
    getProductsAddRequest: getProducts,
    cleanAddProducts: cleanProducts,
    askFromService,
    requestParams,
    sourceId,
    productId,
    onSuccess,
    ...other
  } = props;

  useEffect(() => {
    if (askFromService) {
      getProducts({ requestParams, sourceId, onSuccess, id: productId });
    }
  }, [getProducts, askFromService, requestParams, sourceId, onSuccess, productId]);

  useEffect(() => {
    return () => {
      cleanProducts();
    };
  }, [cleanProducts]);

  return (
    <BSelect
      isLoading={products?.[productId]?.isLoading}
      disabled={products?.[productId]?.isLoading}
      // optionsProps={handleOptionsProps(products?.[productId], optionsProps, ruleToClassName)}
      options={products?.[productId]?.data ?? []}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...other}
    />
  );
}

AddProducts.propTypes = {
  productsRequest: PropTypes.func,
  products: PropTypes.object,
  askFromService: PropTypes.bool,
  requestParams: PropTypes.object,
  sourceId: PropTypes.oneOf(['Sales', 'Agreements', 'Products']),
  optionsProps: PropTypes.object,
  ruleToClassName: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
};

AddProducts.defaultProps = {
  productsRequest: null,
  products: {
    0: { isLoading: false, data: [] },
    1: { isLoading: false, data: [] },
    2: { isLoading: false, data: [] },
    3: { isLoading: false, data: [] },
    4: { isLoading: false, data: [] },
  },
  askFromService: true,
  requestParams: {},
  sourceId: 'Sales',
  optionsProps: {},
  ruleToClassName: () => {
    return;
  },
  label: filtersEnum.products.label,
  name: filtersEnum.products.name,
};

function mapStateToProps(state) {
  return {
    products: state.APP?.catalogs?.addProducts,
  };
}

export default connect(mapStateToProps, { getProductsAddRequest, cleanAddProducts })(AddProducts);
