export const UPDATE_TABLE_INPUTS = 'UPDATE_TABLE_INPUTS/TRADE-AGREEMENTS';
export const UPDATE_TABLE_DISPLAYS = 'UPDATE_TABLE_DISPLAYS/TRADE-AGREEMENTS';
export const CREATE_TABLE = 'CREATE_TABLE/TRADE-AGREEMENTS';
export const SET_MULTIPLE_TABLE_DISPLAYS = 'SET_MULTIPLE_TABLE_DISPLAYS/TRADE-AGREEMENTS';
export const UPDATE_PRICE = 'UPDATE_PRICE/TRADE-AGREEMENTS';

export function updatePrice(payload) {
  return {
    type: UPDATE_PRICE,
    payload: payload,
  };
}

export function updateTableInputs(payload) {
  return {
    type: UPDATE_TABLE_INPUTS,
    payload: payload,
  };
}

export function updateTableDisplays(payload) {
  return {
    type: UPDATE_TABLE_DISPLAYS,
    payload: payload,
  };
}

export function createTable(payload) {
  return {
    type: CREATE_TABLE,
    payload: payload,
  };
}

export function setMultipleTableDisplays(payload) {
  return {
    type: SET_MULTIPLE_TABLE_DISPLAYS,
    payload: payload,
  };
}
