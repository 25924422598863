import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';

function DownloadExcel({ size, loading, label, handleOnClick, ...other }) {
  return (
    <Button
      onClick={handleOnClick}
      type="primary"
      loading={loading}
      icon={<DownloadOutlined />}
      size={size}
      {...other}
    >
      {label}
    </Button>
  );
}

DownloadExcel.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  handleOnClick: PropTypes.func,
};

DownloadExcel.defaultProps = {
  label: 'Descargar archivo',
  size: 'middle',
  handleOnClick: () => {
    return;
  },
};

export default DownloadExcel;
