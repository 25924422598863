import React, { Fragment } from 'react';
import { Upload } from 'antd';
import { BButton } from '../../common/components';
import { ActionButtons } from '../../common';

const MasiveExchangeRateUpload = () => {
  return (
    <Fragment>
      <Upload>
        <BButton label="Cargar archivo" loading={false} disabled={false} />
      </Upload>

      <ActionButtons
        disabled={true}
        onSubmit={() => {
          return;
        }}
        onCancel={() => {
          return;
        }}
      />
    </Fragment>
  );
};

export default MasiveExchangeRateUpload;
