export const DELETE_MANAGE_IMAGES_REQUEST = 'DELETE_MANAGE_IMAGES_REQUEST';
export const DELETE_MANAGE_IMAGES_SET = 'DELETE_MANAGE_IMAGES_SET';
export const DELETE_MANAGE_IMAGES_CLEAN = 'DELETE_MANAGE_IMAGES_CLEAN';

export function DeleteManageImages(payload) {
  return {
    type: DELETE_MANAGE_IMAGES_REQUEST,
    payload: payload,
  };
}
export function DeleteManageImagesSet(payload) {
  return {
    type: DELETE_MANAGE_IMAGES_SET,
    payload: payload,
  };
}
export function DeleteManageImagesClean(payload) {
  return {
    type: DELETE_MANAGE_IMAGES_CLEAN,
  };
}
