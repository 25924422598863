export const CLIENT_SELECTED = 'TRADE_AGREEMENTS/CLIENT_SELECTED';
export const TERRITORY_SELECTED = 'TRADE_AGREEMENTS/CLIENT_SELECTED';
export const CLIENT_COMPLETE = 'CLIENT_COMPLETE';
export const TERRITORY_COMPLETE = 'TERRITORY_COMPLETE';
export const SET_AGREEMENT_TYPE = 'SET_AGREEMENT_TYPE';
export const CLEAN_INFO_FOR_AGREEMENT = 'CLEAN_INFO_FOR_AGREEMENT';
export const CLEAN_CLIENT = 'CLEAN_CLIENT';

export function cleanClientOnChangeTerritory() {
  return {
    type: CLEAN_CLIENT,
  };
}

export function cleanInfoForAgreement() {
  return {
    type: CLEAN_INFO_FOR_AGREEMENT,
  };
}

export function setAgreementType(payload) {
  return {
    type: SET_AGREEMENT_TYPE,
    payload,
  };
}

export function clientSelected(payload) {
  return {
    type: CLIENT_SELECTED,
    payload: payload,
  };
}
export function saveClient(payload) {
  return {
    type: CLIENT_COMPLETE,
    payload,
  };
}

export function territorySelected(payload) {
  return {
    type: TERRITORY_COMPLETE,
    payload: payload,
  };
}
export function saveTerritory(payload) {
  return {
    type: TERRITORY_COMPLETE,
    payload,
  };
}
