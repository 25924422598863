export const SALES_CHALLENGE_VALIDATOR_CONFIRM_REQUEST =
  'SALES_CHALLENGE_VALIDATOR_CONFIRM_REQUEST';
export const SALES_CHALLENGE_VALIDATOR_CONFIRM_SUCCESS =
  'SALES_CHALLENGE_VALIDATOR_CONFIRM_SUCCESS';
export const SALES_CHALLENGE_VALIDATOR_CONFIRM_FAIL = 'SALES_CHALLENGE_VALIDATOR_CONFIRM_FAIL';

export function salesChallengeValidatorConfirmRequest(payload) {
  return {
    type: SALES_CHALLENGE_VALIDATOR_CONFIRM_REQUEST,
    payload,
  };
}

export function salesChallengeValidatorConfirmSuccess(payload) {
  return { type: SALES_CHALLENGE_VALIDATOR_CONFIRM_SUCCESS, payload };
}

export function salesChallengeValidatorConfirmFail(payload) {
  return { type: SALES_CHALLENGE_VALIDATOR_CONFIRM_FAIL, payload };
}
