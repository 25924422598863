import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ReviewActions } from 'common/components/ReviewActions';
import { roleIdEnum } from 'common/models/enums';
import { salesChallengeStatus } from 'common';
import { aproveClientModal, rejectClientModal } from './aproveRejectModals';
import {
  approveTradeAgreementRequest,
  rejectTradeAgreementRequest,
  approveTradeAgreementRequestByKAM,
  rejectTradeAgreementRequestByKAM,
} from 'TradeAgreements/redux/actions/agreement';
import { cleanClientOnChangeTerritory } from 'TradeAgreements/redux/actions/infoForAgreement';
import { tradeCommentsRequest } from 'TradeAgreements/redux/actions/registerTrade';
function ReviewActionsContainer(props) {
  const {
    query,
    isNational,
    statusClientId,
    getDaysLeft,
    approveTradeAgreementRequest: approveTradeAgreement,
    rejectTradeAgreementRequest: rejectTradeAgreement,
    approveTradeAgreementRequestByKAM: approveTradeAgreementKAM,
    rejectTradeAgreementRequestByKAM: rejectTradeAgreementKAM,
    tradeCommentsRequest: getHistory,
    cleanClientOnChangeTerritory: cleanClientOnTerritory,
  } = props;
  const { clientId, territoryId, year } = query;
  const localData = JSON.parse(localStorage.getItem('user'));
  const { role } = localData;
  let params = { clientId, year, territoryId };
  if (isNational) {
    params = { clientId, territoryId, year };
  }
  const onValidate = () => {
    if (role.roleId !== roleIdEnum.KAM) {
      approveTradeAgreement({ data: params });
    } else {
      approveTradeAgreementKAM({ data: params });
    }
  };

  const onReject = (comment) => {
    params = { ...params, comment };
    if (role.roleId !== roleIdEnum.KAM) {
      rejectTradeAgreement({ data: params });
      getHistory({ params: { year, clientId, territoryId } });
    } else {
      rejectTradeAgreementKAM({ data: params });
      getHistory({ params: { year, clientId, territoryId } });
    }
  };
  useEffect(() => {
    cleanClientOnTerritory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [territoryId]);

  const showButtons = () => {
    let status = false;
    let roleId = role.roleId;
    let validDate = year && getDaysLeft?.year === parseInt(year, 10);
    if (validDate) {
      if ((isNational && territoryId) || (!isNational && clientId)) {
        if (roleId === roleIdEnum.KAM) {
          if (statusClientId === salesChallengeStatus.ON_SECOND_VALIDATION) {
            status = true;
          }
        } else if (roleId === roleIdEnum.SALES_MANAGER) {
          if (statusClientId === salesChallengeStatus.ON_VALIDATION) {
            status = true;
          }
        } else if (roleId === roleIdEnum.ADMINISTRATOR) {
          if (
            statusClientId === salesChallengeStatus.ON_VALIDATION ||
            statusClientId === salesChallengeStatus.ON_SECOND_VALIDATION
          ) {
            status = true;
          }
        }
      }
    }

    return status;
  };

  if (showButtons()) {
    return (
      <ReviewActions
        className={'sales-challenge'}
        templateLiteralsAprove={aproveClientModal}
        templateLiteralsReject={rejectClientModal}
        onApprove={() => onValidate()}
        onRejected={(comment) => onReject(comment)}
        actions={['approve', 'rejected']}
        showLabels={true}
      />
    );
  } else {
    return null;
  }
}

ReviewActionsContainer.propTypes = {
  /*Name of the the step . Will be used for info request */
  scope: PropTypes.oneOf(['Subregion', 'Territory', 'Zone', 'General']),
  /* Status challenge */
  statusId: PropTypes.number,
  /*Name of the the validation . Will be used for validation request */
  name: PropTypes.oneOf(['ValidateTerritory', 'ValidateZone', 'ValidateClient']),
};

ReviewActionsContainer.defaultProps = {
  statusId: 0,
};

function mapStateToProps(state) {
  return {
    query: state?.TRADE_AGREEMENTS?.query,
    statusClientId: state.TRADE_AGREEMENTS?.infoForAgreement?.clientInfo?.statusId,
    isNational: state?.TRADE_AGREEMENTS?.infoForAgreement?.clientInfo?.isNational,
    getDaysLeft: state?.TRADE_AGREEMENTS?.registerTrade?.daysLeft?.data?.VALIDITY_PROCESS,
    year: state?.TRADE_AGREEMENTS?.query?.year ?? null,
    clientId: state?.TRADE_AGREEMENTS?.query?.clientId,
    territoryId: state?.TRADE_AGREEMENTS?.query?.territoryId,
  };
}

export default connect(mapStateToProps, {
  approveTradeAgreementRequest,
  rejectTradeAgreementRequest,
  approveTradeAgreementRequestByKAM,
  rejectTradeAgreementRequestByKAM,
  tradeCommentsRequest,
  cleanClientOnChangeTerritory,
})(ReviewActionsContainer);
