import { takeLatest, put, call } from 'redux-saga/effects';
import { DAYS_LEFT_REQUEST, daysLeftSuccess, daysLeftFailure } from '../actions/daysLeft';
import instance from '../../../services/request';
import { getDaysLeft } from 'Validations/services/daysLeft';
import { responseOK } from 'utils';

function* workDaysLeftRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const response = yield call(getDaysLeft, source.token);

    if (responseOK(response)) {
      yield put(daysLeftSuccess(response.data.daysLeft));
    }
  } catch (error) {
    console.error({ error });
    yield put(daysLeftFailure());
  }
}

export function* watchDaysLeftRequest() {
  yield takeLatest(DAYS_LEFT_REQUEST, workDaysLeftRequest);
}
