export const INCENTIVE_RESUME_REQUEST = 'INCENTIVE_RESUME_REQUEST';
export const GET_INCENTIVE_RESUME = 'GET_INCENTIVE_RESUME';
export const INCENTIVE_RESUME_FAIL = 'INCENTIVE_RESUME_FAIL';
export const CLEAN_INCENTIVE_RESUME = 'CLEAN_INCENTIVE_RESUME';

export const IR_SUBREGIONS_REQUEST = 'IR_SUBREGIONS_REQUEST';
export const GET_IR_SUBREGIONS = 'GET_IR_SUBREGIONS';
export const IR_SUBREGIONS_FAIL = 'IR_SUBREGIONS_FAIL';
export const CLEAN_IR_SUBREGIONS = 'CLEAN_IR_SUBREGIONS';

export function incentiveResumeRequest(payload) {
  return {
    type: INCENTIVE_RESUME_REQUEST,
    payload: payload,
  };
}

export function irSubRegionsRequest(payload) {
  return {
    type: IR_SUBREGIONS_REQUEST,
    payload: payload,
  };
}

export function getIncentiveResume(payload) {
  return {
    type: GET_INCENTIVE_RESUME,
    payload: payload,
  };
}

export function getIRSubregions(payload) {
  return {
    type: GET_IR_SUBREGIONS,
    payload: payload,
  };
}

export function incentiveResumeFail(payload) {
  return {
    type: INCENTIVE_RESUME_FAIL,
    payload: payload,
  };
}

export function irSubRegionsFail(payload) {
  return {
    type: IR_SUBREGIONS_FAIL,
    payload: payload,
  };
}

export function cleanIncentiveResume(payload) {
  return {
    type: CLEAN_INCENTIVE_RESUME,
    payload: payload,
  };
}

export function cleanIrSubRegions(payload) {
  return {
    type: CLEAN_IR_SUBREGIONS,
    payload: payload,
  };
}
