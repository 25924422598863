import { useState, useEffect } from 'react';

const useSubRegions = (data) => {
  const renderSubRegionsTable = (array) => {
    const newArray = [];
    array.forEach((item, index) => {
      newArray.push({
        key: index,
        subregionId: item.subregionId,
        lineId: item.lineId,
        lineName: item?.line ? item.line : '',
        soldTo: item.soldTo,
        active: item.active,
        title: item.subRegion,
        description: item.subRegion,
      });
    });
    return newArray;
  };

  const [subRegionsData, setSubRegionsData] = useState([]);
  const [activeSubRegions, setActiveSubRegions] = useState([]);
  const [inactiveSubRegions, setInactiveSubRegions] = useState([]);

  useEffect(() => {
    setSubRegionsData(data ? renderSubRegionsTable(data) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setActiveSubRegions(subRegionsData ? subRegionsData : []);
    const subRegionsInact = subRegionsData.filter((item) => item.active === 0);
    setInactiveSubRegions(subRegionsInact.map((d) => d.key));
  }, [subRegionsData]);

  return [activeSubRegions, inactiveSubRegions, setInactiveSubRegions];
};

export default useSubRegions;
