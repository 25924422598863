import React from 'react';
import PropTypes from 'prop-types';

import { Select, Col } from 'antd';

const { Option } = Select;

const labelStyle = {
  maxWidth: '130px',
  margin: '0px',
  color: '#21A0D2',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
const selectStyle = {
  width: '130px',
};
const selectStyleSpan = {
  width: '100%',
};
const colStyle = {
  paddingRight: '5px',
};

const Filter = (props) => {
  const {
    name,
    required,
    value,
    selectChange,
    options,
    disabled,
    keyProp,
    valueProp,
    span,
    mode,
    defaultValue,
  } = props;

  return (
    <Col
      style={colStyle}
      span={span}
      // xs={13} sm={6} md={4}
    >
      <p style={labelStyle} title={name}>
        {required && '*'}
        {name}:
      </p>
      <Select
        disabled={disabled}
        value={value}
        mode={mode}
        onChange={selectChange}
        style={span ? selectStyleSpan : selectStyle}
        maxTagCount={1}
        showArrow={false}
        defaultValue={defaultValue}
      >
        {options.map((item, index) => (
          <Option key={`subregionOption${index}`} value={item[keyProp]}>
            {item[valueProp]}
          </Option>
        ))}
      </Select>
    </Col>
  );
};

Filter.propTypes = {
  keyProp: PropTypes.string,
  valueProp: PropTypes.string,
  value: PropTypes.array,
  required: PropTypes.bool,
  options: PropTypes.array,
  mode: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
};

Filter.defaultProps = {
  keyProp: 'key',
  valueProp: 'value',
  value: [],
  required: false,
  options: [],
  mode: 'multiple',
  defaultValue: [],
};

export default Filter;
