import React from 'react';
import { Row, Col, Input, Form } from 'antd';

const ResumeIncentiveSection = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item name="noteNCInc" label="Nota:">
            <Input.TextArea placeholder="Escribe una nota" maxLength={500} autoSize />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default ResumeIncentiveSection;
