export const DOWNLOAD_TRADE_AGREEMENTS_REPORT_REQUEST = 'DOWNLOAD_TRADE_AGREEMENTS_REPORT_REQUEST';
export const DOWNLOAD_TRADE_AGREEMENTS_REPORT_SUCCESS = 'DOWNLOAD_TRADE_AGREEMENTS_REPORT_SUCCESS';
export const DOWNLOAD_TRADE_AGREEMENTS_REPORT_FAIL = 'DOWNLOAD_TRADE_AGREEMENTS_REPORT_FAIL';

export function downloadTradeAgreementsReportRequest(payload) {
  return {
    type: DOWNLOAD_TRADE_AGREEMENTS_REPORT_REQUEST,
    payload: {
      ...payload,
      fileConfig: {
        extension: 'xlsx',
        contentType:
          'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    },
  };
}

export function downloadTradeAgreementsReportSuccess(payload) {
  return { type: DOWNLOAD_TRADE_AGREEMENTS_REPORT_SUCCESS, payload };
}

export function downloadTradeAgreementsReportFail(payload) {
  return { type: DOWNLOAD_TRADE_AGREEMENTS_REPORT_FAIL, payload };
}
