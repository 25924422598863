export const ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_REQUEST_CONSULT =
  'ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_REQUEST_CONSULT';
export const ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_SET_CONSULT =
  'ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_SET_CONSULT';
export const ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_CLEAR_CONSULT =
  'ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_CLEAR_CONSULT';

export function accountStatusBaseInformationShoppingRequestConsult(payload) {
  return {
    type: ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_REQUEST_CONSULT,
    payload: payload,
  };
}

export function accountStatusBaseInformationShoppingSetConsult(payload) {
  return {
    type: ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_SET_CONSULT,
    payload: payload,
  };
}

export function accountStatusBaseInformationShoppingClearConsult(payload) {
  return {
    type: ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_CLEAR_CONSULT,
  };
}
