/* eslint-disable no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  consultFiltersSalesPeriodicityRequestOptions,
  consultFiltersSalesPeriodicitySetValue,
} from 'RootModule/redux/actions/consultFilters';
import { addAllBtnOnOptions, handleAllBtnClick } from 'common/consults/utils';

import Filter from 'common/consults/components/Filter';

const SalesPeriodicity = (props) => {
  const {
    consultFiltersSalesPeriodicityRequestOptions: requestOptions,
    consultFiltersSalesPeriodicitySetValue: setValue,
    value,
    required,
    options,
    params,
    disableSelectAll,
  } = props;

  const keyProp = 'saleIncentiveId';
  const valueProp = 'name';
  const customProps = { keyProp, valueProp };

  let mappedOptions = [...options];

  let jsonParams = JSON.stringify(params);

  useEffect(() => {
    let sentParams = {};
    if (params) {
      sentParams = { ...params };
    }
    requestOptions(sentParams);
  }, [jsonParams]);

  const selectChange = (selected) => {
    selected = handleAllBtnClick(selected, options, customProps);
    setValue(selected);
  };
  if (!disableSelectAll) {
    mappedOptions = addAllBtnOnOptions(options, customProps);
  }
  return (
    <Filter
      name={'Periodicidad compras'}
      required={required}
      value={value}
      selectChange={selectChange}
      options={mappedOptions}
      keyProp={keyProp}
      valueProp={valueProp}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    options: state?.APP?.consultFilters.salesPeriodicity?.options ?? [],
    value: state?.APP?.consultFilters.salesPeriodicity?.value,
  };
};

SalesPeriodicity.propTypes = {
  consultFiltersSalesPeriodicityRequestOptions: PropTypes.func,
  consultFiltersSalesPeriodicitySetValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  required: PropTypes.bool,
  options: PropTypes.array,
  params: PropTypes.object,
  disableSelectAll: PropTypes.bool,
};

SalesPeriodicity.defaultProps = {
  consultFiltersSalesPeriodicityRequestOptions: () => {},
  consultFiltersSalesPeriodicitySetValue: () => {},
  value: [],
  required: false,
  options: [],
  params: {},
  disableSelectAll: false,
};

export default connect(mapStateToProps, {
  consultFiltersSalesPeriodicityRequestOptions,
  consultFiltersSalesPeriodicitySetValue,
})(SalesPeriodicity);
