export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST/TRADE';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS/TRADE';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL/TRADE';

export const DELETE_PRODUCT_MODAL_OPEN = 'DELETE_PRODUCT_MODAL_OPEN/TRADE';
export const DELETE_PRODUCT_MODAL_CLOSE = 'DELETE_PRODUCT_MODAL_CLOSE/TRADE';

export function deleteProductModalOpen(payload) {
  return {
    type: DELETE_PRODUCT_MODAL_OPEN,
    payload,
  };
}

export function deleteProductModalClose() {
  return {
    type: DELETE_PRODUCT_MODAL_CLOSE,
  };
}

export function deleteProductRequest(payload) {
  return {
    type: DELETE_PRODUCT_REQUEST,
    payload,
  };
}

export function deleteProductSuccess(payload) {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload,
  };
}

export function deleteProductFail() {
  return {
    type: DELETE_PRODUCT_FAIL,
  };
}
