import {
  UPLOAD_TRADE_REQUEST,
  UPLOAD_TRADE_SUCCESS,
  UPLOAD_TRADE_FAIL,
  GET_FILES_REQUEST,
  GET_FILES_SUCCESS,
  GET_FILES_FAIL,
  REMOVE_FILE_REQUEST,
  REMOVE_FILE_SUCCESS,
  REMOVE_FILE_FAIL,
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_FAIL,
  DOWNLOAD_FILE_SUCCESS,
  SEND_TRADE_AGREEMENT_REQUEST,
  SEND_TRADE_AGREEMENT_SUCCESS,
  SEND_TRADE_AGREEMENT_FAIL,
} from '../actions/uploadTradeAgreements';

const uploadTradeAgreement = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_TRADE_REQUEST:
      return {
        ...state,
        upload: {
          isLoading: true,
        },
      };

    case UPLOAD_TRADE_SUCCESS:
      return {
        ...state,
        upload: {
          isLoading: false,
          data: action?.payload,
        },
      };

    case UPLOAD_TRADE_FAIL:
      return {
        ...state,
        upload: {
          isLoading: false,
        },
      };

    case GET_FILES_REQUEST:
      return {
        ...state,
        files: {
          isLoading: true,
        },
      };

    case GET_FILES_SUCCESS:
      return {
        ...state,
        files: {
          isLoading: false,
          data: action?.payload,
        },
      };

    case GET_FILES_FAIL:
      return {
        ...state,
        files: {
          isLoading: false,
        },
      };

    case REMOVE_FILE_REQUEST:
      return {
        ...state,
        removeFile: {
          isLoading: true,
        },
      };

    case REMOVE_FILE_SUCCESS:
      return {
        ...state,
        removeFile: {
          isLoading: false,
        },
        files: {
          isLoading: false,
        },
      };

    case REMOVE_FILE_FAIL:
      return {
        ...state,
        removeFile: {
          isLoading: false,
        },
      };

    case DOWNLOAD_FILE_REQUEST:
      return {
        ...state,
        downloadFile: {
          isLoading: true,
        },
      };

    case DOWNLOAD_FILE_SUCCESS:
    case DOWNLOAD_FILE_FAIL:
      return {
        ...state,
        downloadFile: {
          isLoading: false,
        },
      };

    case SEND_TRADE_AGREEMENT_REQUEST:
      return {
        ...state,
        sendTrade: {
          isLoading: true,
        },
      };

    case SEND_TRADE_AGREEMENT_SUCCESS:
    case SEND_TRADE_AGREEMENT_FAIL:
      return {
        ...state,
        sendTrade: {
          isLoading: false,
        },
      };

    default:
      return state;
  }
};
export default uploadTradeAgreement;
