import {
  GET_TypeElement_REQUEST,
  GET_TypeElement_SUCCESS,
  GET_TypeElement_CLEAN,
  POST_DisplayExcel_Data_REQUEST,
  PUT_TypeElement_REQUEST,
  PUT_TypeElement_SUCCESS,
  PUT_TypeElement_CLEAN,
  POST_DisplayExcel_REQUEST,
  POST_TypeElement_REQUEST,
  POST_TypeElement_SUCCESS,
  POST_TypeElement_CLEAN,
  DELETE_TypeElement_REQUEST,
  DELETE_TypeElement_SUCCESS,
  DELETE_TypeElement_CLEAN,
  GET_EXCEL_REQUEST,
  GET_EXCEL_SUCCESS,
  GET_EXCEL_CLEAN,
  POST_ValidateFiles_REQUEST,
  POST_ValidateFiles_SUCCESS,
  POST_ValidateFiles_CLEAN,
} from '../actions';

function oneCase(state = {}, action) {
  const { type } = action;

  switch (type) {
    //POST Region, Line, Subregion, Territory, Zone
    case POST_ValidateFiles_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_ValidateFiles_SUCCESS:
      return {
        ...state,
        isLoading: false,
        POSTValidateFiles: action.payload,
      };
    case POST_ValidateFiles_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    //GET Region, Line, Subregion, Territory, Zone
    case GET_EXCEL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_EXCEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        Excel: action.payload,
      };
    case GET_EXCEL_CLEAN:
      return {
        ...state,
        isLoading: false,
        Excel: {},
      };
    //DELETE Region, Line, Subregion, Territory, Zone
    case DELETE_TypeElement_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_TypeElement_SUCCESS:
      return {
        ...state,
        isLoading: false,
        DELETETypeElement: action.payload,
      };
    case DELETE_TypeElement_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    //POST Region, Line, Subregion, Territory, Zone
    case POST_TypeElement_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_DisplayExcel_REQUEST:
      return {
        ...state,
        isLoading: true,
        DisplayExcel: action.payload,
      };
    case POST_TypeElement_SUCCESS:
      return {
        ...state,
        isLoading: false,
        POSTTypeElement: action.payload,
      };
    case POST_TypeElement_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    case POST_DisplayExcel_Data_REQUEST:
      return {
        ...state,
        isLoading: false,
        ExcelDataMaps: action.payload,
      };
    //PUT Region, Line, Subregion, Territory, Zone
    case PUT_TypeElement_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PUT_TypeElement_SUCCESS:
      return {
        ...state,
        isLoading: false,
        PUTTypeElement: action.payload,
      };
    case PUT_TypeElement_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    //GET Region, Line, Subregion, Territory, Zone
    case GET_TypeElement_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TypeElement_SUCCESS:
      return {
        ...state,
        isLoading: false,
        TypeElement: action.payload,
      };
    case GET_TypeElement_CLEAN:
      return {
        ...state,
        isLoading: false,
        TypeElement: {},
        ExcelDataMaps: undefined,
      };
    default:
      return state;
  }
}

export default oneCase;
