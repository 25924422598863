import { QUERY_SAVE, QUERY_CLEAN, TOGGLE_TYPE_VALUE } from '../actions/query';

const query = (state = {}, action) => {
  switch (action.type) {
    case QUERY_CLEAN:
      return { ...action.payload };
    case QUERY_SAVE:
      return {
        ...state,
        ...action.payload,
      };
    case TOGGLE_TYPE_VALUE:
      return {
        ...state,
        showBruteValue: !action.payload,
      };
    default:
      return state;
  }
};
export default query;
