import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BSelect from 'common/components/BSelect';
import { connect } from 'react-redux';
import { familyRequest } from 'RootModule/redux/actions/catalogs';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';

function Family(props) {
  const {
    families,
    familyRequest: getFamily,
    askFromService,
    requestParams,
    sourceId,
    onSuccess,
    territory,
    ...other
  } = props;

  useEffect(() => {
    if (askFromService) {
      getFamily({
        requestParams: { ...requestParams, territoryId: territory },
        sourceId,
        onSuccess,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFamily, askFromService, requestParams, sourceId, onSuccess]);

  return (
    <BSelect
      isLoading={families.isLoading}
      options={families.data}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...other}
    />
  );
}

Family.propTypes = {
  familyRequest: PropTypes.func,
  families: PropTypes.object,
  askFromService: PropTypes.bool,
  requestParams: PropTypes.object,
  sourceId: PropTypes.oneOf(['Agreements', 'Sales', 'Products']),
  name: PropTypes.string,
  label: PropTypes.string,
};

Family.defaultProps = {
  familyRequest: null,
  families: { data: [], isLoading: false },
  askFromService: true,
  requestParams: {},
  sourceId: 'Sales',
  label: filtersEnum.family.label,
  name: filtersEnum.family.name,
};

function mapStateToProps(state) {
  return {
    families: state.APP?.catalogs?.families,
    territory: state.TRADE_AGREEMENTS?.query?.territoryId,
  };
}

export default connect(mapStateToProps, { familyRequest })(Family);
