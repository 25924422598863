export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST/SALE';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS/SALE';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL/SALE';

export const DELETE_PRODUCT_SHOW_MODAL_SET = 'DELETE_PRODUCT_SHOW_MODAL_SET';
export const DELETE_PRODUCT_CLOSE_MODAL = 'DELETE_PRODUCT_CLOSE_MODAL';

export function deleteProductRequest(payload) {
  return {
    type: DELETE_PRODUCT_REQUEST,
    payload,
  };
}

export function deleteProductShowModalSet(payload) {
  return {
    type: DELETE_PRODUCT_SHOW_MODAL_SET,
    payload,
  };
}

export function deleteProductCloseModal(payload) {
  return {
    type: DELETE_PRODUCT_CLOSE_MODAL,
    payload,
  };
}

export function deleteProductSuccess(payload) {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload,
  };
}

export function deleteProductFail() {
  return {
    type: DELETE_PRODUCT_FAIL,
  };
}
