import React, { useContext, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getTradeAgreementsModule from '../../tradeModule';
import getRootModule from 'RootModule';
import TopFiltersGroup from '../../components/TopFiltersGroup';
import TopFiltersNationalClients from 'TradeAgreements/components/TopFiltersNationalClients';
import { layoutContext } from '../../../context';
import { setCurrentModule } from '../../redux/actions/module';
import { cleanInfo } from '../../redux/actions/info';
import { queryClean, querySave } from '../../redux/actions/query';
import { cleanRegisterTrade } from '../../redux/actions/registerTrade';
import { cleanInfoForAgreement } from 'TradeAgreements/redux/actions/infoForAgreement';
import { useDispatch } from 'react-redux';
import ViewsManagment from '../../components/LayoutTradeAgreements/ViewsManagment';
import useUser from 'hooks/useUser';
import { roleIdEnum } from 'common/models/enums';
import './style.css';
import { DeleteTradeAgreementProductModal } from 'TradeAgreements/components/DeleteTradeAgreementProductModal';
import { secondValidationValidUserRequest } from 'TradeAgreements/redux/actions/secondValidation';
function TradeAgreement(props) {
  const {
    cleanRegisterTrade: cleanTrade,
    cleanInfoForAgreement: cleanInfoAgreements,
    secondValidationValidUserRequest: getIsSecondValidator,
    clientId,
    territoryId,
  } = props;
  const { onChangeActiveComponent } = useContext(layoutContext);
  const dispatch = useDispatch();
  const user = useUser();
  const filterChanged = useCallback(
    (view) => {
      onChangeActiveComponent(view);
      dispatch(cleanInfo());
      dispatch(queryClean());
      dispatch(querySave({ state: 'idle', showBruteValue: true }));
    },
    [dispatch, onChangeActiveComponent]
  );

  useEffect(() => {
    cleanTrade();
    cleanInfoAgreements();
    dispatch(cleanInfo());
  }, [dispatch, cleanTrade, cleanInfoAgreements]);

  useEffect(() => {
    getIsSecondValidator();
  }, [clientId, getIsSecondValidator, territoryId]);

  return (
    <>
      <div style={{ padding: '2px 0px 0px 8px' }}>
        {user && (
          <>
            {(user?.role?.roleId === roleIdEnum.SALES_AGENT ||
              user?.role?.roleId === roleIdEnum.SALES_MANAGER ||
              user?.role?.roleId === roleIdEnum.AREA_ADMINISTRATOR ||
              user?.role?.roleId === roleIdEnum.KAM) && (
              <TopFiltersNationalClients onChangeActiveComponent={filterChanged} />
            )}
            {(user?.role?.roleId === roleIdEnum.ADMINISTRATOR ||
              user?.role?.roleId === roleIdEnum.CONSULTANT_USER ||
              user?.role?.roleId === roleIdEnum.AP_LEADER) && (
              <TopFiltersGroup onChangeActiveComponent={filterChanged} />
            )}
          </>
        )}
        <div style={{ marginTop: '1rem' }}>
          <ViewsManagment />
        </div>
      </div>
      <DeleteTradeAgreementProductModal />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    clientId: state?.TRADE_AGREEMENTS?.query?.clientId ?? '',
    territoryId: state?.TRADE_AGREEMENTS?.query?.territoryId ?? '',
  };
};

const ConnectedTradeAgreements = connect(mapStateToProps, {
  setCurrentModule,
  cleanRegisterTrade,
  cleanInfoForAgreement,
  secondValidationValidUserRequest,
})(TradeAgreement);

const DynamicModule = () => (
  <DynamicModuleLoader modules={[getRootModule(), getTradeAgreementsModule()]}>
    <ConnectedTradeAgreements />
  </DynamicModuleLoader>
);
export default DynamicModule;
