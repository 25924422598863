import React, { Fragment, useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { connect } from 'react-redux';
import { DownloadOutlined } from '@ant-design/icons';
import { getDMYDateString } from 'Dashboards/Comparative/components/DownloadPDFButton';
import { downloadDomElementAsPng } from '../utils';
import { Row, Col, Button } from 'antd';
import DataChart from './DataChart';
const buttonStyle = {
  width: '210px',
  fontSize: '1.2em',
  height: 'calc(16px + 1.2em)',
  fontWeight: 600,
  marginTop: '15px',
};

const Chart = ({ mainApi, filter, _typeinfo }) => {
  const [chartData, setChartData] = useState({});

  function downloadPng() {
    downloadDomElementAsPng('bar', `Dashboard Comparativo ${getDMYDateString()}`);
  }
  useEffect(() => {
    if (mainApi !== null) {
      const chartInfo = DataChart(mainApi, filter);
      setChartData(chartInfo ? chartInfo : {});
    }
  }, [mainApi, filter]);

  const typeInfo = filter[4]?.typeinformation ?? '';

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value, _index, _values) {
              return typeInfo === 1
                ? `$${Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`
                : Number(value).toLocaleString('en-US');
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          let labelTxt =
            typeInfo === 1
              ? `$${Number(tooltipItem.yLabel).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`
              : Number(tooltipItem.yLabel).toLocaleString('en-US');
          tooltipItem.yLabel = labelTxt;
          return tooltipItem.yLabel;
        },
      },
    },
  };

  return (
    <Fragment>
      <Row>
        <Col xs={24} md={18}>
          <Bar id="bar" data={chartData} options={options} />
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={18}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <Button
              style={buttonStyle}
              type="primary"
              onClick={() => {
                downloadPng();
              }}
            >
              <DownloadOutlined style={{ fontSize: '1.2em' }} />
              Exportar
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    mainApi: state?.COMPARATIVE?.mainApi ?? [],
    filter: state?.COMPARATIVE?.filter ?? [],
  };
}

const ChartWrapped = connect(mapStateToProps)(Chart);

const LogsConsult = () => (
  <DynamicModuleLoader>
    <ChartWrapped />
  </DynamicModuleLoader>
);

export default LogsConsult;
