import React from 'react';
import { Row, Col } from 'antd';
import { BInput } from 'common/components';

const Manager = ({ fullName, territory }) => {
  return (
    <>
      {fullName ? (
        <Row>
          <Col span={8}>
            <BInput
              disabled
              isRequired
              className="form-add-user-input"
              name="territoryManager"
              defaultValue={territory}
              label="Territorios:"
            />
          </Col>
          <Col span={8}>
            <BInput
              disabled
              isRequired
              className="form-add-user-input"
              name="fullNameManager"
              defaultValue={fullName}
              label="Responsable clave de la cuenta:"
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={8}>
            <BInput disabled isRequired className="form-add-user-input" label="Territorios:" />
          </Col>
          <Col span={8}>
            <BInput
              disabled
              isRequired
              className="form-add-user-input"
              label="Responsable clave de la cuenta:"
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default Manager;
