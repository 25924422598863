import React from 'react';
import ProductInfo from 'SalesChallengeRefactor/common/ProductInfo/ProductInfo';
import { formatPriceWithDecimals } from 'SalesChallengeRefactor/utils/index';
import { connect } from 'react-redux';

function ColumnLeft(props) {
  const { showBruteValue, clientId, territoryId, productId, product } = props;

  const onDefaultValuesChange = ({ form }) => {
    let price = formatPriceWithDecimals(
      showBruteValue ? product?.data?.brutePrice : product?.data?.netPrice
    );
    price = price ? price : '';
    form.setFieldsValue({
      moq: product?.data?.moq,
      netPrice: `${price} USD`,
      crop: product?.data?.crop,
      country: product?.data?.countryName ?? null,
    });
  };

  return (
    <ProductInfo
      isLoadingPrices={product.isLoading}
      onDefaultValuesChange={onDefaultValuesChange}
      clientId={clientId}
      productId={productId}
      territoryId={territoryId}
      pageType={'Agreement'}
      showBruteValue={showBruteValue}
      isLoading={product.isLoading}
    />
  );
}

function mapStateToProps(state) {
  return {
    product: state?.TRADE_AGREEMENTS?.registerTrade?.productInfo || { data: [], isLoading: false },
    clientId: state?.TRADE_AGREEMENTS?.query.clientId || false,
    productId: state?.TRADE_AGREEMENTS?.query?.productId || false,
    territoryId: state?.TRADE_AGREEMENTS?.infoForAgreement?.territoryInfo?.territoryId || false,
  };
}

export default connect(mapStateToProps, {})(ColumnLeft);
