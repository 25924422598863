import {
  ReadOutlined,
  UserAddOutlined,
  HomeOutlined,
  LogoutOutlined,
  UploadOutlined,
  AppstoreOutlined,
  BookOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  DollarOutlined,
  TagOutlined,
  ApartmentOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  ClockCircleOutlined,
  AimOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  MinusOutlined,
  ExclamationCircleOutlined,
  SaveOutlined,
  PieChartOutlined,
  PushpinOutlined,
  EnvironmentOutlined,
  TableOutlined,
  BarChartOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { FaHandshake, FaStickyNote, FaRegStickyNote } from 'react-icons/fa';
import { GrNote } from 'react-icons/gr';
import { AiOutlineDollarCircle } from 'react-icons/ai';

const IconsMap = {
  BarChartOutlined: BarChartOutlined,
  ReadOutlined: ReadOutlined,
  UserAddOutlined: UserAddOutlined,
  HomeOutlined: HomeOutlined,
  LogoutOutlined: LogoutOutlined,
  UploadOutlined: UploadOutlined,
  AppstoreOutlined: AppstoreOutlined,
  BookOutlined: BookOutlined,
  UserOutlined: UserOutlined,
  UsergroupAddOutlined: UsergroupAddOutlined,
  DollarOutlined: DollarOutlined,
  TagOutlined: TagOutlined,
  ApartmentOutlined: ApartmentOutlined,
  CaretUpOutlined: CaretUpOutlined,
  CaretDownOutlined: CaretDownOutlined,
  ClockCircleOutlined: ClockCircleOutlined,
  BiTargetLock: AimOutlined,
  CheckOutlined: CheckOutlined,
  CloseOutlined: CloseOutlined,
  FaHandshake: FaHandshake,
  PlusOutlined: PlusOutlined,
  MinusOutlined: MinusOutlined,
  ExclamationCircleOutlined: ExclamationCircleOutlined,
  SaveOutlined: SaveOutlined,
  FaStickyNote: FaStickyNote,
  GrNote: GrNote,
  FaRegStickyNote: FaRegStickyNote,
  PieChartOutlined: PieChartOutlined,
  PushpinOutlined: PushpinOutlined,
  EnvironmentOutlined: EnvironmentOutlined,
  TableOutlined: TableOutlined,
  AiOutlineDollarCircle: AiOutlineDollarCircle,
  ProfileOutlined: ProfileOutlined,
};
export default IconsMap;
