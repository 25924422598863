/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
const validaContacto = (values) => {
  const { email1, email2, email3, email4, email5 } = values;
  const emails = [email1, email2, email3, email4, email5];
  let cont = 0;
  let valor;
  emails.map((mail) => {
    if (mail === undefined || mail === '' || mail === null) {
      cont++;
    } else {
      if (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(mail)) {
        cont++;
      }
    }
  });
  if (cont === 5) {
    valor = true;
  } else {
    valor = false;
  }

  return valor;
};

export const validaForm = (form, setIsActiveRegister) => {
  const allValues = form.getFieldsValue();

  const {
    header,
    lineId,
    name,
    periodoPOGId,
    periodoVentasId,
    placementId,
    segmentationId,
    soldTo,
    subregionId,
    territories,
    tradeAgreementCurrency,
  } = allValues;
  const valuesRequired = [
    header,
    lineId,
    name,
    periodoPOGId,
    periodoVentasId,
    placementId,
    segmentationId,
    soldTo,
    subregionId,
    territories,
    tradeAgreementCurrency,
  ];
  let cont = 0;
  valuesRequired.forEach((v) => {
    if (v !== undefined && v !== '') {
      cont++;
    }
  });

  if (cont === 11 && validaContacto(allValues) === true) {
    setIsActiveRegister(false);
  } else {
    setIsActiveRegister(true);
  }
};

export const formatFunc = (e) => {
  let value = e.toString();
  if (value === '.' || value === '$ .') {
    return '$ 0.';
  }
  if (value && typeof value === 'string') {
    let format = '';
    let decimal = value.indexOf('.');

    if (decimal > 0) {
      let a = new RegExp(/(^([0-9]*\.[0-9][0-9])$)/gi);
      let onlyTwoDecimals = value.match(a, '$1');

      if (!onlyTwoDecimals) {
        let splitDecimals = value.split('.');
        if (splitDecimals[1]) {
          let sub = splitDecimals[1].substring(0, 2);
          value = `${splitDecimals[0]}.${sub}`;
        }
      }
      if (value.length <= 22) {
        format = `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        let val = value.substring(0, 22);
        format = `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    } else {
      if (value.length <= 14) {
        format = `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        let val = value.substring(0, 14);
        format = `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
    return format;
  }
};

export const parserFunc = (value) => {
  let clean = value.replace(/\$\s?|(,*)/g, '');

  if (clean && clean.length > 14) {
    let result = '';
    let decimal = clean.indexOf('.');
    if (decimal > 0 && clean.length <= 22) {
      result = clean.substring(0, 22);
    } else {
      result = clean.substring(0, 14);
    }

    return result;
  } else {
    return clean;
  }
};

export const keyBlock = (e) => {
  if (e.shiftKey || (e.shiftKey && e.which === 51) || e.key === 'Dead') {
    e.preventDefault();
  }
  if (
    e.keyCode === 69 ||
    e.keyCode === 73 ||
    e.keyCode === 186 ||
    e.keyCode === 187 ||
    e.keyCode === 189 ||
    e.keyCode === 40 ||
    e.keyCode === 107 ||
    e.keyCode === 109 ||
    e.keyCode === 191 ||
    e.keyCode === 192 ||
    e.keyCode === 219 ||
    e.keyCode === 220 ||
    e.keyCode === 221 ||
    e.keyCode === 222 ||
    e.keyCode === 38 ||
    e.keyCode === 40 ||
    e.key === '{' ||
    e.key === '}' ||
    e.key === '+' ||
    e.key === '*' ||
    e.key === '[' ||
    e.key === ']' ||
    e.key === '´' ||
    e.key === '/' ||
    e.key === '<' ||
    e.key === '+' ||
    e.key === '´´' ||
    e.key === 'ArrowLeft' ||
    e.key === 'BracketLeft' ||
    e.key === 'BracketRight' ||
    e.key === 'Quote' ||
    e.key === 'Shift' ||
    e.key === 'Dead' ||
    (e.keyCode >= 65 && e.keyCode <= 90) ||
    e.shiftKey ||
    e.key === 'ArrowDown' ||
    e.key === 'ArrowUp'
  ) {
    e.preventDefault();
  }
};
