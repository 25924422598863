export const SAVE_VOLUME_REQUEST = 'SAVE_VOLUME_REQUEST/SALES-CHALLENGE';
export const SAVE_VOLUME_SUCCESS = 'SAVE_VOLUME_SUCCESS/SALES-CHALLENGE';
export const SAVE_VOLUME_FAIL = 'SAVE_VOLUME_FAIL/SALES-CHALLENGE';
export const SAVE_UPDATE_PRICES = 'SAVE_UPDATE_PRICES';
export const VALIDATE_TABLE_COMPLETION_REQUEST =
  'VALIDATE_TABLE_COMPLETION_REQUEST/SALES-CHALLENGE';
export const VALIDATE_TABLE_COMPLETION_SUCCESS =
  'VALIDATE_TABLE_COMPLETION_SUCCESS/SALES-CHALLENGE';
export const VALIDATE_TABLE_COMPLETION_FAIL = 'VALIDATE_TABLE_COMPLETION_FAIL/SALES-CHALLENGE';

export const CLEAN_TABLE = 'CLEAN_TABLE/SALES-CHALLENGE';

export function cleanTable(payload) {
  return {
    type: CLEAN_TABLE,
    payload,
  };
}

export function saveVolume(payload) {
  return {
    type: SAVE_VOLUME_REQUEST,
    payload,
  };
}

export function saveVolumeSuccess(payload) {
  return {
    type: SAVE_VOLUME_SUCCESS,
    payload,
  };
}

export function saveVolumeFail(payload) {
  return {
    type: SAVE_VOLUME_FAIL,
    payload,
  };
}

export function saveUpdatePrices(payload) {
  return {
    type: SAVE_UPDATE_PRICES,
    payload,
  };
}

export function validateTableRequest(payload) {
  return {
    type: VALIDATE_TABLE_COMPLETION_REQUEST,
    payload,
  };
}

export function validateTableSuccess(payload) {
  return {
    type: VALIDATE_TABLE_COMPLETION_SUCCESS,
    payload,
  };
}

export function validateTableFail(payload) {
  return {
    type: VALIDATE_TABLE_COMPLETION_FAIL,
    payload,
  };
}
