import React, { useEffect, useState } from 'react';
import { Transfer, Table, Skeleton } from 'antd';
import difference from 'lodash/difference';

const SectionTransfer = ({
  dataSource,
  targetKeys,
  onChange,
  showSearch,
  pagination,
  leftColumns,
  rightColumns,
  type,
  ...other
}) => {
  const [visibleLoader, setVisibleLoader] = useState(true);

  const changeLoader = () => {
    setVisibleLoader(false);
  };

  useEffect(() => {
    if (dataSource.length > 0 && dataSource !== null && dataSource !== undefined) {
      changeLoader();
    }
  }, [dataSource]);

  const TableTransfer = ({ tableLeftColumns, tableRightColumns, ...restProps }) => (
    <Transfer
      titles={
        type === 1
          ? ['Subregiones con la sección activa', 'Subregiones con la sección inactiva']
          : ['Clientes con la sección activa', 'Clientes con la sección inactiva']
      }
      locale={{
        itemUnit: '',
        itemsUnit: '',
        notFoundContent: 'No encontrado',
        searchPlaceholder: type === 1 ? 'Escribe subregión' : 'Escribe cliente o sold to',
      }}
      {...restProps}
      {...other}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;

        const rowSelection = {
          getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter((item) => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <>
            {visibleLoader === true ? (
              <div style={{ padding: '0 0.75em' }}>
                <Skeleton active />
              </div>
            ) : (
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={filteredItems}
                size="small"
                style={{ pointerEvents: listDisabled ? 'none' : null }}
                onRow={({ key, disabled: itemDisabled }) => ({
                  onClick: () => {
                    if (itemDisabled || listDisabled) return;
                    onItemSelect(key, !listSelectedKeys.includes(key));
                  },
                })}
              />
            )}
          </>
        );
      }}
    </Transfer>
  );
  return (
    <>
      <TableTransfer
        dataSource={dataSource}
        targetKeys={targetKeys}
        onChange={onChange}
        showSearch={showSearch}
        pagination={pagination}
        filterOption={(inputValue, item) => {
          return (
            item.title.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
            item.lineName.toUpperCase().includes(inputValue.toUpperCase()) ||
            item.soldTo.toString().indexOf(inputValue.toString()) !== -1
          );
        }}
        tableLeftColumns={leftColumns}
        tableRightColumns={rightColumns}
      />
    </>
  );
};

export default SectionTransfer;
