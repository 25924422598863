import {
  VALIDATORS_LIST_SALES_CHALLENGE_REQUEST,
  VALIDATORS_LIST_SALES_CHALLENGE_SUCCESS,
  VALIDATORS_LIST_SALES_CHALLENGE_FAILURE,
  VALIDATORS_LIST_TRADE_AGREEMENT_REQUEST,
  VALIDATORS_LIST_TRADE_AGREEMENT_SUCCESS,
  VALIDATORS_LIST_TRADE_AGREEMENT_FAILURE,
} from '../actions/validatorList';

const defaultState = {
  salesChallenge: {
    data: [],
    loading: false,
  },
  tradeAgreement: {
    data: [],
    loading: false,
  },
};

const validatorList = (state = defaultState, action) => {
  switch (action.type) {
    case VALIDATORS_LIST_SALES_CHALLENGE_REQUEST:
      return {
        ...state,
        salesChallenge: {
          list: [],
          loading: true,
        },
      };
    case VALIDATORS_LIST_SALES_CHALLENGE_SUCCESS:
      return {
        ...state,
        salesChallenge: {
          list: action.payload,
          loading: false,
        },
      };
    case VALIDATORS_LIST_SALES_CHALLENGE_FAILURE:
      return {
        ...state,
        salesChallenge: {
          list: [],
          loading: false,
        },
      };
    case VALIDATORS_LIST_TRADE_AGREEMENT_REQUEST:
      return {
        ...state,
        tradeAgreement: {
          list: [],
          loading: true,
        },
      };
    case VALIDATORS_LIST_TRADE_AGREEMENT_SUCCESS:
      return {
        ...state,
        tradeAgreement: {
          list: action.payload,
          loading: false,
        },
      };
    case VALIDATORS_LIST_TRADE_AGREEMENT_FAILURE:
      return {
        ...state,
        tradeAgreement: {
          list: [],
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default validatorList;
