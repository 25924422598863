export const roleIdEnum = Object.freeze({
  ADMINISTRATOR: 1,
  AREA_ADMINISTRATOR: 2,
  SUPPLY_ADMINISTRATOR: 3,
  SALES_MANAGER: 4,
  SALES_AGENT: 5,
  AP_LEADER: 6,
  CONSULTANT_USER: 7,
  KAM: 8,
});

export const processStepIdEnum = Object.freeze({
  UPLOAD_HISTORIC_INFO: 1,
  UPLOAD_SALES_GOALS: 2,
  SALES_CHALLENGE_REGISTER: 3,
  SALES_CHALLENGE_VALIDATE: 4,
  SALES_CHALLENGE_ADJUST: 5,
  UPLOAD_TRADE_AGREEMENT: 6,
  GENERATE_TRADE_AGREEMENT: 7,
  TRADE_AGREEMENT_VALIDATE: 8,
  TRADE_AGREEMENT_ADJUST: 9,
  VALIDITY_PROCESS: 10,
});

export const salesChallengeStatus = Object.freeze({
  ON_PROCESS: 1,
  ON_VALIDATION: 2,
  ON_SECOND_VALIDATION: 3,
  APPROVED: 4,
  REJECTED: 5,
  PARTIALLY_REJECTED: 6,
  ON_SECOND_LEVEL_VALIDATION: 7,
});

export const tradeAgreementsStatus = Object.freeze({
  ON_PROCESS: 1,
  ON_VALIDATION: 2,
  ON_SECOND_VALIDATION: 3,
  APPROVED: 4,
  REJECTED: 5,
  ON_SECOND_LEVEL_VALIDATION: 6,
});

export const semaphoreTradeAgreement = Object.freeze({
  RED: '#e6001d',
  YELLOW: '#ff9600',
  GREEN: '#00c14f',
  BLUE: '#0078b3',
});

export const actionTradeEnum = Object.freeze({
  UPDATE: { label: 'Actualizar', key: 2, path: `/trade-agreements/update` },
  REGISTER: { label: 'Registrar', key: 1, path: `/trade-agreements/register` },
  ADJUSTED: { label: 'Ajustar', key: 3, path: `/trade-agreements/adjusted` },
});

export const nameStatus = Object.freeze({
  WITHOUT_STATUS: `Sin estatus`,
  ON_PROCESS: `En proceso`,
  ON_VALIDATION: `En validación`,
  ON_SECOND_VALIDATION: `En segunda validación`,
  APPROVED: `Aprobado`,
  REJECTED: `Rechazado`,
  PARTIALLY_REJECTED: `Rechazado parcialmente`,
  ON_SECOND_LEVEL_VALIDATION: `Segundo nivel de validación`,
});

export const ruleTypeEnum = Object.freeze({
  //Productos fuera de política
  PRODUCTOS_FUERA_DE_DISPONIBILIDAD: 1,
  //Productos fuera de disponibilidad
  PRODUCTOS_FUERA_DE_POLITICA: 2,
  //Programa de incentivos
  PROGRAMA_DE_INCENTIVOS: 3,
});
