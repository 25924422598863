import reducer from './redux-sagas/reducers/';

import {
  watchTradeAgreementsMassiveUploadSetAllowUpload,
  watchTradeAgreementsMassiveUploadRequestVerify,
  watchTradeAgreementsMassiveUploadRequestUpload,
} from './redux-sagas/sagas';

export default function gettradeAgreementsMassiveUploadModule() {
  return {
    id: 'TRADE_AGREEMENTS_MASSIVE_UPLOAD',
    reducerMap: {
      TRADE_AGREEMENTS_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchTradeAgreementsMassiveUploadSetAllowUpload,
      watchTradeAgreementsMassiveUploadRequestVerify,
      watchTradeAgreementsMassiveUploadRequestUpload,
    ],
  };
}
