import { takeLatest, call, put, cancelled } from 'redux-saga/effects';

import {
  ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_REQUEST_CONSULT,
  accountStatusBaseInformationShoppingSetConsult,
  accountStatusBaseInformationShoppingClearConsult,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';

import { ShoppingConsult, checkIfConsultHasElements } from '../../services';

import { messageError } from 'utils/messages';

function* workAccountStatusBaseInformationShoppingRequestConsult(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const cancelToken = source.token;
  const { params, history } = action.payload;
  let mustRedirect = false;

  try {
    const response = yield call(ShoppingConsult, {
      cancelToken,
      params,
    });
    const existInfoPurchasesResponse = yield call(checkIfConsultHasElements, { cancelToken });
    if (responseOK(existInfoPurchasesResponse)) {
      mustRedirect = !existInfoPurchasesResponse.data;
    } else {
      yield put(accountStatusBaseInformationShoppingClearConsult());
      mustRedirect = true;
    }

    if (responseOK(response) && !mustRedirect) {
      yield put(accountStatusBaseInformationShoppingSetConsult(response.data));
    } else {
      yield put(accountStatusBaseInformationShoppingClearConsult());
    }
  } catch (error) {
    yield accountStatusBaseInformationShoppingClearConsult();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield accountStatusBaseInformationShoppingClearConsult();
      source.cancel('cancelled Request');
    }

    if (mustRedirect) {
      yield call(
        setTimeout,
        () => {
          messageError('No hay registros de compras en el sistema');
        },
        100
      );
      yield call(history.push, '/account-status/base-information');
    }
  }
}

export function* watchAccountStatusBaseInformationShoppingRequestConsult() {
  yield takeLatest(
    ACCOUNT_STATUS_BASE_INFORMATION_SHOPPING_REQUEST_CONSULT,
    workAccountStatusBaseInformationShoppingRequestConsult
  );
}
