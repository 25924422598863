export const PUT_ONE_UPDATEER_REQUEST = 'PUT_ONE_UPDATEER_REQUEST';
export const PUT_ONE_UPDATEER_SUCCESS = 'PUT_ONE_UPDATEER_SUCCESS';
export const PUT_ONE_UPDATEER_CLEAN = 'PUT_ONE_UPDATEER_CLEAN';

export const PUT_ONE_STATUS_REQUEST = 'PUT_ONE_STATUS_REQUEST';
export const PUT_ONE_STATUS_SUCCESS = 'PUT_ONE_STATUS_SUCCESS';
export const PUT_ONE_STATUS_CLEAN = 'PUT_ONE_STATUS_CLEAN';

//PUT
export function putOneUpdateERRequest(payload) {
  return {
    type: PUT_ONE_UPDATEER_REQUEST,
    payload: payload,
  };
}

export function putOneUpdateERSuccess(payload) {
  return {
    type: PUT_ONE_UPDATEER_SUCCESS,
    payload: payload,
  };
}

export function putOneUpdateERClean() {
  return {
    type: PUT_ONE_UPDATEER_CLEAN,
    payload: {},
  };
}
//PUT
export function putOneStatusRequest(payload) {
  return {
    type: PUT_ONE_STATUS_REQUEST,
    payload: payload,
  };
}

export function putOneStatusSuccess(payload) {
  return {
    type: PUT_ONE_STATUS_SUCCESS,
    payload: payload,
  };
}

export function putOneStatusClean() {
  return {
    type: PUT_ONE_STATUS_CLEAN,
  };
}
