import reducer from './redux-saga/reducers';

import {
  watchDiscountsMassiveUploadSetAllowUpload,
  watchDiscountsMassiveUploadRequestVerify,
  watchDiscountsMassiveUploadRequestUpload,
} from './redux-saga/sagas';
export default function getDiscountsMassiveUploadModule() {
  return {
    id: 'DISCOUNTS_MASSIVE_UPLOAD',
    reducerMap: {
      DISCOUNTS_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchDiscountsMassiveUploadSetAllowUpload,
      watchDiscountsMassiveUploadRequestVerify,
      watchDiscountsMassiveUploadRequestUpload,
    ],
  };
}
