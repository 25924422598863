/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-key */
import React from 'react';
import { Row, Col, Input, Form } from 'antd';

const SectionContacts = ({ getArrayContact }) => {
  const { TextArea } = Input;

  const contactsName = getArrayContact?.map((contact) => (
    <Form.Item name="header" label="Nombre:">
      <TextArea placeholder={contact.name} defaultValue={contact.name} disabled={true} autoSize />
    </Form.Item>
  ));

  const contactsMail = getArrayContact?.map((contact) => (
    <Form.Item name="header" label="Correo electrónico:">
      <TextArea placeholder={contact.email} defaultValue={contact.email} disabled={true} autoSize />
    </Form.Item>
  ));

  return (
    <>
      <Row>
        <Col span={12}>{contactsName}</Col>
        <Col span={12}>{contactsMail}</Col>
      </Row>
    </>
  );
};

export default SectionContacts;
