import { request } from '../../../services';
import { formatToPrice } from '../../../utils';
import { openNotification } from '../../../common';
import axios from 'axios';

const API_REPORTS = process.env.REACT_APP_API_REPORTS;
const handleError = () => {
  return undefined;
};

const baseGetCall = (url) => {
  return request
    .get(url)
    .then((response) => response.data)
    .catch(handleError);
};

export const getClientsTypes = async () => {
  return baseGetCall('/Catalogs/Api/v1/FixedCatalogs/Placements');
};

export const getSegmentations = async () => {
  return baseGetCall('/Catalogs/Api/v1/FixedCatalogs/Segmentation');
};

export const getClientsSoldTo = (query) => {
  query = query ? query : '';
  return baseGetCall('/Dashboards/Api/v1/Catalogs/ClientsSoldTo' + query);
};

export const getAgreementStatus = () => {
  return baseGetCall('/Catalogs/Api/v1/FixedCatalogs/AgreementStatus');
};

export const getReportResumeComercialAgreement = (query) => {
  return request.get('/Dashboards/Api/v1/Agreement/Resume' + query).then((res) => {
    res.data.rows.forEach((item) => {
      item.salesChallengeAmount = formatToPrice(item.salesChallengeAmount, true);
      item.salesAmount = formatToPrice(item.salesAmount, true);
      item.agreeementSalesAmount = formatToPrice(item.agreeementSalesAmount, true);
      item.agreementAmount = formatToPrice(item.agreementAmount, true);
      item.agreementSalesChallAmount = formatToPrice(item.agreementSalesChallAmount, true);
      item.agreementSalesChallAmount = formatToPrice(item.agreementSalesChallAmount);
      item.agreeementSalesChallVolume = formatToPrice(item.agreeementSalesChallVolume);
      item.agreementSalesVolume = formatToPrice(item.agreementSalesVolume);
      item.percAgreeementSalesAmount = formatToPrice(item.percAgreeementSalesAmount);
      item.percAgreeementSalesChallAmount = formatToPrice(item.percAgreeementSalesChallAmount);
      item.percAgreeementSalesChallVolume = formatToPrice(item.percAgreeementSalesChallVolume);
      item.percAgreeementSalesVolume = formatToPrice(item.percAgreeementSalesVolume);
      item.salesChallengeVolume = formatToPrice(item.salesChallengeVolume);
      item.salesVolume = formatToPrice(item.salesVolume);
      item.agreeementSalesVolume = formatToPrice(item.agreeementSalesVolume);
      item.agreementVolume = formatToPrice(item.agreementVolume);
      item.agreementSalesChallVolume = formatToPrice(item.agreementSalesChallVolume);
    });
    return res.data;
  });
};

export const getCountComercialAgreement = (query) => {
  return baseGetCall('/Dashboards/Api/v1/Agreement/Count' + query);
};

export const getAgreementResumeSheet = (query) => {
  return baseGetCall('/Dashboards/Api/v1/Agreement/FileResume' + query);
};

export const getAgreementCountSheet = (query) => {
  return baseGetCall('/Dashboards/Api/v1/Agreement/FileCount' + query);
};

export const getAgreementResumePDF = (query) => {
  const arrnew = query.split('&');
  let value;
  arrnew.forEach((item) => {
    if (item.includes('DataView')) {
      value = item[item.length - 1];
    }
  });
  query = query + '&challengeData=' + value;
  // eslint-disable-next-line no-extend-native
  Array.prototype.remove = function () {
    let what,
      a = arguments,
      L = a.length,
      ax;
    while (L && this.length) {
      what = a[--L];
      while ((ax = this.indexOf(what)) !== -1) {
        this.splice(ax, 1);
      }
    }
    return this;
  };
  let instance = axios.create({
    baseURL: `${API_REPORTS}Reports/Api/v1`,
    Accept: 'application/json',
    AccessControlAllowOrigin: '*/*',
  });
  instance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return instance
    .get('/Dashboards/Agreement' + query)
    .then((res) => res.data)
    .catch((e) => {
      openNotification(
        'error',
        'El sistema no pudo generar el reporte, inténtelo de nuevo más tarde'
      );
    });
};
