// hu 2000 2010 2020

import { message002, messageSuccess, messageError } from 'utils/messages';

const baseUrl = process.env.REACT_APP_API_GATEWAY;

const pricesUrl = baseUrl + 'Catalogs/Api/v1/Prices/Delete/';
const productsUrl = baseUrl + 'Catalogs/Api/v1/Products/Delete/';
const clientsUrl = baseUrl + 'Catalogs/Api/v1/Clients/Delete/';

export const baseService = (url, params, afterDeleting, handleStopLoading) => {
  fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    body: JSON.stringify(params),
  })
    .then((response) => response.json())

    .then((responseParsed) => {
      const { message, data } = responseParsed;

      if (!data) {
        messageError(message);
        handleStopLoading();
        return;
      }
      afterDeleting();
      messageSuccess(message);
    })
    .catch((error) => {
      console.error('Error:', error);
      handleStopLoading();
      message002();
    });
};
export const pricesDelete = (afterDeleting, handleStopLoading) => {
  baseService(pricesUrl, { disabled: false, delete: true }, afterDeleting, handleStopLoading);
};

export const pricesDeactivate = (afterDeleting, handleStopLoading) => {
  baseService(pricesUrl, { disabled: true, delete: false }, afterDeleting, handleStopLoading);
};

export const pricesBoth = (afterDeleting, handleStopLoading) => {
  baseService(pricesUrl, { disabled: true, delete: true }, afterDeleting, handleStopLoading);
};

export const productsDelete = (afterDeleting, handleStopLoading) => {
  baseService(productsUrl, { disabled: false, delete: true }, afterDeleting, handleStopLoading);
};
export const productsDeactivate = (afterDeleting, handleStopLoading) => {
  baseService(productsUrl, { disabled: true, delete: false }, afterDeleting, handleStopLoading);
};
export const productsBoth = (afterDeleting, handleStopLoading) => {
  baseService(productsUrl, { disabled: true, delete: true }, afterDeleting, handleStopLoading);
};

export const clientsDelete = (afterDeleting, handleStopLoading) => {
  baseService(clientsUrl, { disabled: false, delete: true }, afterDeleting, handleStopLoading);
};

export const clientsDeactivate = (afterDeleting, handleStopLoading) => {
  baseService(clientsUrl, { disabled: true, delete: false }, afterDeleting, handleStopLoading);
};

export const clientsBoth = (afterDeleting, handleStopLoading) => {
  baseService(clientsUrl, { disabled: true, delete: true }, afterDeleting, handleStopLoading);
};
