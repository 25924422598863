export const $ = (value, key) => {
  Object.create(
    {},
    {
      [key]: {
        value: value,
        writable: true,
        enumerable: true,
        configurable: true,
      },
    }
  );
};

export const notEmptyArray = (arr) => arr.length > 0;

export const notEmpty = (value) => {
  switch (typeof value) {
    case 'boolean':
      return value === true || value === false;
    case 'number':
      return isNaN(value);
    case 'string':
      return !value || 0 === value.length;
    case 'function':
      return true;
    case 'object':
      if (value === null) return false;
      if (Array.isArray(value)) return notEmptyArray(value);
      return Object.keys(value).length;
    case 'undefined':
      return false;
    default:
      return false;
  }
};
