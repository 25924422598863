export const salesChallengeStatus = Object.freeze({
  ON_PROCESS: 1,
  ON_VALIDATION: 2,
  ON_SECOND_VALIDATION: 3,
  APPROVED: 4,
  REJECTED: 5,
  PARTIALLY_REJECTED: 6,
});

export const semaphoreTradeAgreement = Object.freeze({
  RED: '#e6001d',
  YELLOW: '#ff9600',
  GREEN: '#00c14f',
  BLUE: '#0078b3',
});

export const actionTradeEnum = Object.freeze({
  UPDATE: { label: 'Actualizar', key: 2, path: `/trade-agreements/update` },
  REGISTER: { label: 'Registrar', key: 1, path: `/trade-agreements/register` },
  ADJUSTED: { label: 'Ajustar', key: 3, path: `/trade-agreements/adjusted` },
  GENERATE: { label: 'Generar', key: 4 },
  GENERATE_FILE: { label: 'Generar archivo', key: 5, path: `/trade-agreements/generate` },
});
