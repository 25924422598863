import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from '../../../common/ErrorBoundary';
import { tradeAgreementsFilters as enumFilters } from '../../../models/enumFilters';
import BLoading from 'common/components/BLoading';

function ViewsManagment(props) {
  const { view } = props;
  const excludesViews = ['year', 'businessRule'];
  const loadView = () => {
    if (!excludesViews.includes(view)) {
      let enumKeys = Object.keys(enumFilters);

      let a = enumKeys
        // eslint-disable-next-line array-callback-return
        .map((key) => {
          if (enumFilters[key].name === view) {
            return enumFilters[key];
          }
        })
        .filter((el) => typeof el !== 'undefined');
      if (a[0]) {
        let viewName = a[0]?.view;
        const Component = lazy(() => import(`../views/${viewName}`));
        return <Component />;
      }
    }
    return null;
  };

  if (typeof view === 'undefined') return null;
  if (view !== '') {
    return (
      <Suspense fallback={<BLoading />}>
        <ErrorBoundary>{loadView()}</ErrorBoundary>
      </Suspense>
    );
  }
}

function mapStateToProps(state) {
  return {
    view: state?.TRADE_AGREEMENTS?.views?.current || undefined,
  };
}

export default connect(mapStateToProps, {})(ViewsManagment);
