import { request } from '../../services';
import { createParamString } from '../../common';
export const getArrayOfObjects = (res) => {
  let data = res.data;
  let response = [];
  data.forEach((element) => {
    response.push({
      id: element.key,
      name: element.value,
    });
  });
  return response;
};

export async function getBe(param) {
  let URI = '/Catalogs/Api/v1/FixedCatalogs/';
  let paramString = '';
  if (param.element === 'regions') {
    paramString = `regions`;
  }
  if (param.element === 'lobs') {
    let regionsParamString = createParamString(param.region, 'regions');
    paramString = `BusinessLines?${regionsParamString}`;
  }
  if (param.element === 'subregions') {
    let regionsParamString = createParamString(param.region, 'Regions');
    let lobsParamString = createParamString(param.lobs, 'BusinessLines');
    paramString = `Subregions?${regionsParamString}${lobsParamString}`;
  }
  if (param.element === 'territories') {
    let subregionsParamString = createParamString(param.subregions, 'SubRegions');
    let lobsParamString = createParamString(param.lobs, 'BusinessLines');
    paramString = `Territories?${subregionsParamString}${lobsParamString}`;
  }
  if (param.element === 'zones') {
    let territoriesParamString = createParamString(param.territories, 'Territories');
    paramString = `Zones?${territoriesParamString}`;
  }
  if (param.element === 'countries') {
    let zonesParamString = createParamString(param.zones, 'zones');
    paramString = `Countries?${zonesParamString}`;
  }
  if (param.element === 'countriesBySubReg') {
    let zonesParamString = createParamString(param.subRegions, 'SubRegions');
    paramString = `CountriesBySubregion?${zonesParamString}`;
  }
  return request
    .get(`${URI}${paramString}`)
    .then((res) => getArrayOfObjects(res))
    .catch(() => {
      return [];
    });
}
