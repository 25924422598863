const DIGITS = 'dígitos';
const CHARS = 'caracteres';

export const setRequiredField = (fieldName) => {
  return `El campo ${fieldName} es obligatorio`;
};

export const setMinLengthCharacters = (min, isNumber) => {
  let typeMessage = typeof isNumber === 'undefined' ? DIGITS : CHARS;
  return `Este campo debe tener al menos ${min} ${typeMessage}.`;
};

export const setRuleRequired = (nameField, type, withMessage = false) => {
  let objRule = { required: true, message: withMessage ? setRequiredField(nameField) : '' };
  if (typeof type !== 'undefined') {
    return { ...objRule, type };
  } else {
    return { ...objRule };
  }
};

export const setRuleMin = (minLength, isNumber) => {
  let existNumber = typeof isNumber !== 'undefined' ? true : false;
  return { min: minLength, message: setMinLengthCharacters(minLength, existNumber) };
};
