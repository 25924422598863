import { REPORT_REQUEST, REPORT_SUCCESS, REPORT_FAIL } from '../actions/reports';

const report = (state = {}, action) => {
  switch (action.type) {
    case REPORT_REQUEST:
      return {
        isDownloading: true,
      };

    case REPORT_SUCCESS:
    case REPORT_FAIL:
      return {
        ...action.payload,
        isDownloading: false,
      };

    default:
      return state;
  }
};
export default report;
