import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal/modal';
import { Result } from 'antd';
import '../../SalesChallenge/style.css';
import { Loading } from '../../../common';

const StatusTableComponent = ({
  tableId,
  dataSource,
  query,
  fileName,
  loading,
  reportsLenght,
  tableTitle,
}) => {
  return (
    <div className="second-mod">
      <table className="table agreement-table" id={tableId}>
        <Loading loading={loading} />
        <tbody>
          <tr>
            <th className="th" />
            <th className="th">{tableTitle}</th>
            <th className="th" />
          </tr>
          <tr>
            <th className="th2" />
            <th className="th2">Estatus</th>
            <th className="th2">Total </th>
          </tr>
          {dataSource?.length > 0 &&
            reportsLenght > 1 &&
            dataSource.map((item) => {
              return (
                <tr key={item.status} className={item.status === 'Total' ? 'status-total' : ''}>
                  <td className="body">
                    <div className={item.status.toLowerCase().replace(/ /g, '')} />
                  </td>
                  <td className="body2">{item.status}</td>
                  <td className="body3">{item.count}</td>
                </tr>
              );
            })}
          {!(dataSource?.length > 0 && reportsLenght > 1) && (
            <tr>
              <td colSpan={'100%'}>
                <Result
                  className={'result-svg'}
                  status="500"
                  title={
                    'No se encontraron resultados ' +
                    'que coincidan con los ' +
                    'criterios de búsqueda ingresados'
                  }
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <br />
      <Modal tableId={tableId} query={query} nameFile={fileName} />
    </div>
  );
};

StatusTableComponent.propTypes = {
  tableId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  tableTitle: PropTypes.string.isRequired,
  dataSource: PropTypes.array.isRequired,
  query: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  reportsLenght: PropTypes.number.isRequired,
};

export default StatusTableComponent;
