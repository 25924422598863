/* eslint-disable no-console */
import { takeEvery, put, call, all, cancelled } from 'redux-saga/effects';
import {
  PUT_ONE_UPDATEER_REQUEST,
  PUT_ONE_STATUS_REQUEST,
  putOneUpdateERClean,
  putOneStatusClean,
} from '../actions';
import {
  putOneExchangeRate,
  updateOneExchangeRateStatus,
} from '../../Services/updateExchange-rate';
import instance from 'services/request';
import { responseOK } from 'SalesChallengeRefactor/utils';
import { openNotification } from 'common';

/* import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'; */

function* workPutOneExchangeRate(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { exchangeData, datePUT, dates, history } = payload;
    const data = {
      referenceId: exchangeData.referenceId,
      companyId: exchangeData.companyId,
      value: parseFloat(financial(payload.exchangeRateValue)),
      startDate: datePUT ? datePUT[0] : dates[0]._i,
      endDate: datePUT ? datePUT[1] : dates[1]._i,
    };
    const response = yield call(putOneExchangeRate, {
      data: data,
      cancelToken: source.token,
    });
    if (responseOK(response)) {
      //yield put(putOneUserSuccess(response.data));
      yield openNotification('success', response.message);

      setTimeout(() => {
        if (history) history.push('/catalog/exchange-rate');
      }, 300);
    } else {
      yield put(putOneUpdateERClean());
    }
  } catch (error) {
    yield put(putOneUpdateERClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

function* workToggleExchange(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { enabled, userId } = payload;

    const response = yield call(updateOneExchangeRateStatus, {
      enabled,
      userId: userId,
    });
    if (responseOK(response)) {
      openNotification('success', response.message);
    } else {
      yield put(putOneStatusClean());
    }
  } catch (error) {
    yield put(putOneStatusClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

function financial(x) {
  return Number.parseFloat(x).toFixed(2);
}

export function* watchPutOneExchangeRate() {
  yield all([takeEvery(PUT_ONE_UPDATEER_REQUEST, workPutOneExchangeRate)]);
}

export function* watchToggleUser() {
  yield all([takeEvery(PUT_ONE_STATUS_REQUEST, workToggleExchange)]);
}
