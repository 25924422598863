import React, { useEffect, useState, Fragment, useContext } from 'react';
import CatalogCard from './catalogCard';
import { Row } from 'antd';
import { appContext, layoutContext } from 'context';
import { getCatalogsObjects } from './utils';
import { getRemainingDays } from './services';
import { useTranslation } from 'react-i18next';

const ModulesTradeAgreementInfo = () => {
  const { t } = useTranslation(['catalogs']);
  const { onChangeActiveComponent } = useContext(layoutContext);
  const { modules, catalogsPermissions } = useContext(appContext);
  const [catalog, setcatalog] = useState([]);

  useEffect(() => {
    let catalogPermissions = catalogsPermissions.find(
      (permission) => permission.uri === 'trade-agreement-info'
    );
    setcatalog(catalogPermissions.components);
  }, [catalogsPermissions]);

  const [modulesCards, setModules] = useState([]);

  let type = 'catalog/trade-agreement-info';

  useEffect(() => {
    getRemainingDays(type).then((res) => {
      let uploadEnabled = res.daysLeft >= 0;
      let newModules = getCatalogsObjects(catalog, modules, type, uploadEnabled);
      if (newModules.length) {
        setModules(newModules);
      }
    });
    onChangeActiveComponent(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modules, catalog, type]);

  return (
    <>
      <Row gutter={[16, 16]} style={{ width: '100%' }}>
        {modulesCards &&
          modulesCards.map((module, key) => {
            return (
              <Fragment key={key}>
                <CatalogCard
                  title={t(`trade-agreement-info.${module.name}`)}
                  add={module.add}
                  upload={module.upload}
                  consult={module.consult}
                />
              </Fragment>
            );
          })}
      </Row>
    </>
  );
};

export default ModulesTradeAgreementInfo;
