import React from 'react';
import { ActionButtons } from 'common';

export const containerStyle = {
  margin: '16px',
  marginTop: '0px',
  color: 'white',
  borderRadius: '5px',
  height: '35px',
  width: '220px',
  fontSize: '1.1em',
  border: 'none',
  fontWeight: '600',
};

export const actionButtonsStyle = {
  margin: '16px',
  marginTop: '0px',
  color: 'white',
  borderRadius: '5px',
  height: '35px',
  width: '220px',
  fontSize: '1.1em',
  border: 'none',
  fontWeight: '600',
};

const cancelStyle = {
  ...actionButtonsStyle,
  backgroundColor: '#247ba9',
};

const submitStyle = {
  ...actionButtonsStyle,
  backgroundColor: '#004a96',
};

const ActionButtonsExcelUpload = (props) => {
  return (
    <ActionButtons
      {...props}
      style={containerStyle}
      cancelStyle={cancelStyle}
      submitStyle={submitStyle}
    />
  );
};

export default ActionButtonsExcelUpload;
