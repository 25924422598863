import request from '../../services/request';
import { paramsSerializer } from 'RootModule/services/catalogs';

//const URL_API = '/Catalogs/Api/v1/Validators/UserValByZone';

const URL_API = '/Catalogs/Api/v1/Validators/UserChallengeByTerritory';

export async function getValidators({ cancelToken, params }) {
  return request(`${URL_API}`, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
    params,
  });
}
