//TODO Move sortRecords to utils

/**
 * Sort  strings safely with String.prototype.localeCompare()
 * Will return null if the params are falsy or second parameter
 * is not a string
 * Very useful for sorting Antd Table.
 * @param {string} [stringA=''] The first String to compare
 * @param {string} [stringB=''] The second String to compare
 * @return {number}
 */
import React from 'react';
import { RoleAccessControl } from 'common/components/accessControllers';
import { Link } from 'react-router-dom';
import { MdModeEdit } from 'react-icons/md';
import { IconContext } from 'react-icons';
import ErrorColumnTitle from '../../common/components/ErrorColumnTitle';

const sortRecords = (stringA = '', stringB = '') => {
  let result = null;
  if (stringA && stringB && typeof stringA === 'string') {
    result = stringA.localeCompare(stringB);
  }
  return result;
};

const sortByBussinesLines = (recordA, recordB) => {
  return sortRecords(recordA.businessLine, recordB.businessLine);
};

const messageFaseOne = (
  <>
    <p>
      Registros para los cuales se omitió ingresar campos obligatorios, se ingresó un tipo de dato
      que no corresponde al solicitado en el campo o se excedió la longitud máxima.
    </p>
  </>
);
const messageFaseTwo = (
  <>
    <p>
      Registro de reglas de negocio para los cuales se ingresó información de elementos con estatus
      &quot;inactivo&quot;, que no se encuentran registrados en el sistema o se ingresó información
      de estructura comercial que no está relacionada entre si.
    </p>
  </>
);
const messageFaseThree = (
  <>
    <p>Registros que se encuentran repetidos dentro del archivo de carga masiva.</p>
  </>
);
const errorMessages = [
  {
    fase: 'Fase 1',
    message: messageFaseOne,
  },
  {
    fase: 'Fase 2',
    message: messageFaseTwo,
  },
  {
    fase: 'Fase 3',
    message: messageFaseThree,
  },
];

export const productsOutOfAvailabilityTable = [
  /* {
    key: 'errorPhase',
    title: <>
      <ErrorColumnTitle errorMessages={errorMessages} />
    </>,
    dataIndex: 'errorPhase',
    className: 'title-masive',
  }, */

  {
    title: ' ',
    dataIndex: ' ',
    // eslint-disable-next-line react/display-name
    render: (value, record) => {
      return (
        <IconContext.Provider value={{ color: 'blue' }}>
          <Link
            to={{
              pathname: `/catalog/businessRules/update/availability`,
              state: { ...record },
            }}
            style={{ color: 'black' }}
          >
            <MdModeEdit />
          </Link>
        </IconContext.Provider>
      );
    },
  },

  {
    title: 'Número de Producto',
    dataIndex: 'productNumber',
    key: 'productNumber',
    className: 'text-center',
  },
  {
    title: 'Nombre',
    dataIndex: 'productName',
    key: 'productName',
    className: 'text-center',
  },
];

export const productsOutOfAvailabilityTableRegister = [
  {
    key: 'errorPhase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'errorPhase',
    className: 'title-masive',
  },
  {
    title: 'Número de Producto',
    dataIndex: 'productNumber',
    key: 'productNumber',
    className: 'text-center',
  },
  {
    title: 'Nombre',
    dataIndex: 'productName',
    key: 'productName',
    className: 'text-center',
  },
];

export const productsOutOfPolicyTable = [
  {
    title: ' ',
    dataIndex: ' ',
    // eslint-disable-next-line react/display-name
    render: (value, record) => {
      return (
        <IconContext.Provider value={{ color: 'blue' }}>
          <Link
            to={{
              pathname: `/catalog/businessRules/update/policy`,
              state: { ...record },
            }}
            style={{ color: 'black' }}
          >
            <MdModeEdit />
          </Link>
        </IconContext.Provider>
      );
    },
  },
  {
    title: 'Número de Producto',
    dataIndex: 'productNumber',
    key: 'productNumber',
    className: 'text-left',
  },
  {
    title: 'Producto',
    dataIndex: 'productName',
    key: 'productName',
    className: 'text-left',
  },
  {
    title: 'Familia',
    dataIndex: 'family',
    key: 'family',
    className: 'text-left',
  },
  {
    title: 'Línea de negocio',
    dataIndex: 'businessLine',
    key: 'businessLine',
    sortDirections: ['descend', 'ascend', 'descend'],
    sorter: sortByBussinesLines,
  },
  {
    title: 'Subregión',
    dataIndex: 'subRegion',
    key: 'subRegion',
  },
];

export const productsOutOfPolicyTableRegister = [
  {
    key: 'errorPhase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'errorPhase',
    className: 'title-masive',
  },
  {
    title: 'Línea de negocio',
    dataIndex: 'businessLine',
    key: 'businessLine',
  },
  {
    title: 'Subregión',
    dataIndex: 'subRegion',
    key: 'subRegion',
  },
  {
    title: 'Número de Producto',
    dataIndex: 'productNumber',
    key: 'productNumber',
  },
  {
    title: 'Familia',
    dataIndex: 'family',
    key: 'family',
  },
];

export const incentiveProgramTableRegister = [
  {
    key: 'errorPhase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'errorPhase',
    className: 'title-masive',
  },
  {
    title: 'Línea de negocio',
    dataIndex: 'businessLine',
    key: 'businessLine',
    /* sortDirections: ['descend', 'ascend', 'descend'],
    sorter: sortByBussinesLines, */
  },
  {
    title: 'Subregión',
    dataIndex: 'subRegion',
    key: 'subRegion',
  },
  {
    title: 'Segmentación',
    dataIndex: 'segmentation',
    key: 'segmentation',
  },
  {
    title: 'Cumplimiento plan de negocio anual',
    dataIndex: 'anualPlanAchive',
    key: 'anualPlanAchive',
    className: 'anualPlanAchive',
    children: [
      {
        title: 'Objetivo 1',
        dataIndex: 'objectiveOne',
        key: 'objectiveOne',
        className: 'anualPlanAchive',
      },
      {
        title: 'Objetivo 2',
        dataIndex: 'objectiveTwo',
        key: 'objectiveTwo',
        className: 'anualPlanAchive',
      },
      {
        title: 'Objetivo 3',
        dataIndex: 'objectiveThree',
        key: 'objectiveThree',
        className: 'anualPlanAchive',
      },
    ],
  },
  {
    title: 'Cumplimiento de objetivos trimestrales',
    dataIndex: 'trimPlanAchive',
    key: 'trimPlanAchive',
    className: 'trimPlanAchive',
    children: [
      {
        title: 'Descuento 1',
        dataIndex: 'discountOne',
        key: 'discountOne',
        className: 'trimPlanAchive',
      },
      {
        title: 'Descuento 2',
        dataIndex: 'discountTwo',
        key: 'discountTwo',
        className: 'trimPlanAchive',
      },
      {
        title: 'Descuento 3',
        dataIndex: 'discountThree',
        key: 'discountThree',
        className: 'trimPlanAchive',
      },
      {
        title: 'Descuento 4',
        dataIndex: 'discountFour',
        key: 'discountFour',
        className: 'trimPlanAchive',
      },
    ],
  },
  {
    title: 'POG sobre lo desplazado',
    dataIndex: 'pogDisplaced',
    key: 'pogDisplaced',
    className: 'pogDisplaced',
    children: [
      {
        title: 'Descuento 1',
        dataIndex: 'pogDiscountOne',
        key: 'pogDiscountOne',
        className: 'pogDisplaced',
      },
      {
        title: 'Descuento 2',
        dataIndex: 'pogDiscountTwo',
        key: 'pogDiscountTwo',
        className: 'pogDisplaced',
      },
      {
        title: 'Descuento 3',
        dataIndex: 'pogDiscountThree',
        key: 'pogDiscountThree',
        className: 'pogDisplaced',
      },
      {
        title: 'Descuento 4',
        dataIndex: 'pogDiscountFour',
        key: 'pogDiscountFour',
        className: 'pogDisplaced',
      },
    ],
  },
  {
    title: 'Descuento base',
    dataIndex: 'discBase',
    key: 'discBase',
    className: 'discBase',
    children: [
      {
        title: 'Descuento base',
        dataIndex: 'baseDiscount',
        key: 'baseDiscount',
        className: 'discBase',
      },
    ],
  },
];

export const incentiveProgramTable = [
  /*  {
     key: 'errorPhase',
     title: <>
       <ErrorColumnTitle errorMessages={incentiveErrorMessages} />
     </>,
     dataIndex: 'errorPhase',
     className: 'title-masive',
   }, */
  {
    title: ' ',
    dataIndex: ' ',
    // eslint-disable-next-line react/display-name
    render: (value, record) => {
      return (
        <>
          <RoleAccessControl
            moduleKey="catalog"
            page="businessRules"
            action="update_businessRules/:id"
          >
            <Link
              to={{
                pathname: `/catalog/businessRules/update/${record.incentiveId}`,
                state: { ...record, update: true },
              }}
            >
              <IconContext.Provider value={{ color: 'blue' }}>
                <MdModeEdit />
              </IconContext.Provider>
            </Link>
          </RoleAccessControl>
        </>
      );
    },
  },

  {
    title: 'Línea de negocio',
    dataIndex: 'businessLine',
    key: 'businessLine',
    sortDirections: ['descend', 'ascend', 'descend'],
    sorter: sortByBussinesLines,
  },
  {
    title: 'Subregión',
    dataIndex: 'subRegion',
    key: 'subRegion',
  },
  {
    title: 'Segmentación',
    dataIndex: 'segmentation',
    key: 'segmentation',
  },
  {
    title: 'Cumplimiento plan de negocio anual',
    dataIndex: 'anualPlanAchive',
    key: 'anualPlanAchive',
    className: 'anualPlanAchive',
    children: [
      {
        title: 'Objetivo 1',
        dataIndex: 'annualObjetive1',
        key: 'annualObjetive1',
        className: 'anualPlanAchive',
        render: (text, record) => record?.annualObjetiveData?.annualObjetive1,
      },
      {
        title: 'Objetivo 2',
        dataIndex: 'annualObjetive2',
        key: 'annualObjetive2',
        className: 'anualPlanAchive',
        render: (text, record) => record?.annualObjetiveData?.annualObjetive2,
      },
      {
        title: 'Objetivo 3',
        dataIndex: 'annualObjetive3',
        key: 'annualObjetive3',
        className: 'anualPlanAchive',
        render: (text, record) => record?.annualObjetiveData?.annualObjetive3,
      },
    ],
  },
  {
    title: 'Cumplimiento de objetivos trimestrales',
    dataIndex: 'trimPlanAchive',
    key: 'trimPlanAchive',
    className: 'trimPlanAchive',
    children: [
      {
        title: 'Descuento 1',
        dataIndex: 'quarterlyDiscount1',
        key: 'quarterlyDiscount1',
        className: 'trimPlanAchive',
        render: (text, record) => record?.quarterlyObjetiveData?.quarterlyDiscount1,
      },
      {
        title: 'Descuento 2',
        dataIndex: 'quarterlyDiscount2',
        key: 'quarterlyDiscount2',
        className: 'trimPlanAchive',
        render: (text, record) => record?.quarterlyObjetiveData?.quarterlyDiscount2,
      },
      {
        title: 'Descuento 3',
        dataIndex: 'quarterlyDiscount3',
        key: 'quarterlyDiscount3',
        className: 'trimPlanAchive',
        render: (text, record) => record?.quarterlyObjetiveData?.quarterlyDiscount3,
      },
      {
        title: 'Descuento 4',
        dataIndex: 'quarterlyDiscount4',
        key: 'quarterlyDiscount4',
        className: 'trimPlanAchive',
        render: (text, record) => record?.quarterlyObjetiveData?.quarterlyDiscount4,
      },
    ],
  },
  {
    title: 'POG sobre lo desplazado',
    dataIndex: 'pogDisplaced',
    key: 'pogDisplaced',
    className: 'pogDisplaced',
    children: [
      {
        title: 'Descuento 1',
        dataIndex: 'pogDiscount1',
        key: 'pogDiscount1',
        className: 'pogDisplaced',
        render: (text, record) => record?.pogDiscountData?.pogDiscount1,
      },
      {
        title: 'Descuento 2',
        dataIndex: 'pogDiscount2',
        key: 'pogDiscount2',
        className: 'pogDisplaced',
        render: (text, record) => record?.pogDiscountData?.pogDiscount2,
      },
      {
        title: 'Descuento 3',
        dataIndex: 'pogDiscount3',
        key: 'pogDiscount3',
        className: 'pogDisplaced',
        render: (text, record) => record?.pogDiscountData?.pogDiscount3,
      },
      {
        title: 'Descuento 4',
        dataIndex: 'pogDiscount4',
        key: 'pogDiscount4',
        className: 'pogDisplaced',
        render: (text, record) => record?.pogDiscountData?.pogDiscount4,
      },
    ],
  },
  {
    title: 'Descuento base',
    dataIndex: 'discBase',
    key: 'discBase',
    className: 'discBase',
    children: [
      {
        title: 'Descuento base',
        dataIndex: 'baseDiscount',
        key: 'baseDiscount',
        className: 'discBase',
      },
    ],
  },
];

export const productsOutOfPolicyClientTable = [
  {
    title: ' ',
    dataIndex: ' ',
    // eslint-disable-next-line react/display-name
    render: (value, record) => {
      return (
        <IconContext.Provider value={{ color: 'blue' }}>
          <Link
            to={{
              pathname: `/catalog/businessRules/update/policy`,
              state: { ...record },
            }}
            style={{ color: 'black' }}
          >
            <MdModeEdit />
          </Link>
        </IconContext.Provider>
      );
    },
  },
  {
    title: 'Número de Producto',
    dataIndex: 'productNumber',
    key: 'productNumber',
    className: 'text-left',
  },
  {
    title: 'Producto',
    dataIndex: 'productName',
    key: 'productName',
    className: 'text-left',
  },
  {
    title: 'Familia',
    dataIndex: 'family',
    key: 'family',
    className: 'text-left',
  },
  {
    title: 'Línea de negocio',
    dataIndex: 'businessLine',
    key: 'businessLine',
    sortDirections: ['descend', 'ascend', 'descend'],
    sorter: sortByBussinesLines,
  },
  {
    title: 'Cliente',
    dataIndex: 'client',
    key: 'client',
  },
];

export const productsOutOfPolicyClientTableRegister = [
  {
    key: 'errorPhase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'errorPhase',
    className: 'title-masive',
  },

  {
    title: 'Cliente',
    dataIndex: 'client',
    key: 'client',
  },
  {
    title: 'Línea de negocio',
    dataIndex: 'businessLine',
    key: 'businessLine',
  },
  /* {
    title: 'Línea de negocio',
    dataIndex: 'businessLine',
    key: 'businessLine',
    sortDirections: ['descend', 'ascend', 'descend'],
    sorter: sortByBussinesLines,
  }, */
  {
    title: 'Número de Producto',
    dataIndex: 'productNumber',
    key: 'productNumber',
  },
  {
    title: 'Familia',
    dataIndex: 'family',
    key: 'family',
  },
];
