import React, { useState, useContext } from 'react';
import { Input, Button } from 'antd';
import { authentication, recoveryMail, resetPassword } from '../services';
import {
  UserOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  LockOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { appContext } from '../../context';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../common/BASF-Logo_white.svg';
import Background from '../../common/Login_background.jpg';
import Styles from './login.module.css';
import Backdoor from './Backdoor';
import Restore from '../restore';
import { useLocation, useHistory } from 'react-router-dom';
import { openNotification } from '../../common';

export const login = (formUser, formPassword, setDisabled, onLogin) => {
  setDisabled(true);
  authentication(formUser, formPassword)
    .then((value) => {
      onLogin(value.permissions, value.user);
    })
    .catch((_err) => {
      setDisabled(false);
    });
};

export const forgotPassword = (formUser, setDisabled, cleandAfterReset) => {
  setDisabled(true);

  recoveryMail(formUser)
    .then((response) => {
      if (response) {
        cleandAfterReset(response);
      }
    })
    .catch((err) => setDisabled(false));
};

function Login() {
  let location = useLocation();
  let path = location.pathname;
  const actualPath = path.split('/')[1];
  const history = useHistory();
  const { t } = useTranslation('authentication');

  const { onLogin } = useContext(appContext);
  const [formUser, setFormUser] = useState(undefined);
  const [formPassword, setFormPassword] = useState(undefined);
  const [disabled, setDisabled] = useState(undefined);
  const [resetStatus, setResetStatus] = useState(false);
  const [emailValid, setEmailValid] = useState(true);

  const handleResetPassword = () => {
    setResetStatus(!resetStatus);
    if (formUser) {
      validateEmail(formUser);
    }
    setFormPassword('');
    if (resetStatus) {
      setFormUser('');
      setEmailValid(true);
    }
  };

  const cleandAfterReset = (response) => {
    setFormPassword('');
    handleResetPassword();
    openNotification('success', `${response.message ? response.message : ''}`);
    setDisabled(false);
    setTimeout(() => {
      history.push('/');
    }, 300);
  };

  const restorePassword = (value, idTkn, setRequestReset, setResetStatusIn) => {
    resetPassword(value, idTkn)
      .then((response) => {
        if (response.status === 200) {
          setResetStatusIn(false);
          openNotification('success', `${response?.data.message ? response.data.message : ''}`);
          setTimeout(() => {
            history.push('/');
          }, 1000);
        }
        setTimeout(() => {
          setRequestReset(false);
        }, 200);
      })
      .catch((err) => {
        return;
      });
  };

  const validateEmail = (value) => {
    if (value !== '') {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      setEmailValid(re.test(String(value).toLowerCase()));
    } else {
      setEmailValid(true);
    }
  };

  return (
    <div
      data-testid="Login"
      className={Styles[`login-wrapper`]}
      style={{
        background: `url(${Background}) no-repeat center center fixed`,
      }}
    >
      <div className={Styles[`logo`]}>
        <Logo />
      </div>

      {
        // DIV RESET PASSWORD   ######################################
        actualPath === 'reset-password' ? (
          <div
            style={{
              position: 'fixed',
              backgroundColor: '#fefefe',
              right: '10vw',
              height: '450px',
              width: '30vw',
              boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
              paddingTop: '30px',
            }}
          >
            <Restore
              handleRestore={(newForm, idTkn, setRequestReset) => {
                restorePassword(newForm, idTkn, setRequestReset, setResetStatus);
              }}
              setDisabled={setDisabled}
            />
          </div>
        ) : (
          // DIV LOGIN   ######################################
          <div className={Styles[`login-box`]}>
            {resetStatus && (
              <div className={Styles['container-icon-back']}>
                <ArrowLeftOutlined onClick={handleResetPassword} style={{ fontSize: '24px' }} />
              </div>
            )}
            <h1>{t('title')}</h1>
            <h2>{!resetStatus ? t('subtitle') : t('reset_password.subtitle')}</h2>

            {resetStatus && (
              <div className={Styles[`description-reset-password`]}>
                <p> {t('reset_password.first_text')}</p>
                <p> {t('reset_password.second_text')}</p>
              </div>
            )}

            <p> {t('form.username')}:</p>

            <Input
              placeholder={t('form.username_placeholder')}
              prefix={<UserOutlined />}
              data-testid="user"
              type="email"
              value={formUser}
              onChange={(e) => {
                validateEmail(e.target.value);
                setFormUser(e.target.value);
              }}
            />
            {!emailValid && (
              <div
                style={{ color: 'red', fontSize: '10px', marginLeft: '-85px', marginTop: '-16px' }}
              >
                usuario@dominio.extensión
              </div>
            )}

            {!resetStatus && (
              <>
                <p> {t('form.password')}:</p>
                <Input.Password
                  placeholder={t('form.password_placeholder')}
                  prefix={<LockOutlined />}
                  autoComplete="off"
                  value={formPassword}
                  data-testid="password"
                  onChange={(e) => {
                    setFormPassword(e.target.value);
                  }}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </>
            )}

            {!resetStatus && (
              <div className={Styles[`span-reset-password`]}>
                <button onClick={handleResetPassword}> {t('form.forgot_password')}</button>
              </div>
            )}

            {!resetStatus && (
              <button
                type="ant-btn ant-btn-primary primary"
                id="submit-button"
                data-testid="submit"
                className={!formUser || !formPassword || disabled ? 'login-btn_disabled' : ''}
                disabled={!formUser || !formPassword || disabled}
                onClick={() => login(formUser, formPassword, setDisabled, onLogin)}
              >
                {t('form.submit')}
              </button>
            )}
            {resetStatus && (
              <Button
                type="primary"
                id="submit-button"
                data-testid="submit"
                loading={disabled}
                disabled={!formUser || !emailValid}
                className={!formUser || !formPassword || disabled ? 'login-btn_disabled' : ''}
                onClick={(e) => {
                  e.preventDefault();
                  forgotPassword(formUser, setDisabled, cleandAfterReset);
                }}
              >
                {t('reset_password.reset_button')}
              </Button>
            )}
          </div>
        )
      }
      <Backdoor />
    </div>
  );
}
export default Login;
