import React from 'react';
import { Modal } from 'antd';
const actionButtonsStyle = {
  margin: '16px',
  marginTop: '30px',
  color: 'white',
  borderRadius: '5px',
  width: '220px',
  height: '35px',
  fontSize: '1.1em',
  border: 'none',
  fontWeight: '600',
};

function summaryModal(rows, errors, onChange) {
  Modal.info({
    title: 'Resumen de registros leídos',
    content: (
      <div>
        <p>
          Se revisaron {rows} registros, de los cuales {rows - errors} registros son correctos y{' '}
          {errors} tienen errores
        </p>
      </div>
    ),
    okText: 'Aceptar',
    okButtonProps: { size: 'middle', type: 'primary', style: actionButtonsStyle },
    onOk() {
      onChange();
    },
  });
}
export default summaryModal;
