import { fork, all } from 'redux-saga/effects';

import * as TableSagas from './Table';
import * as DrawerAddSagas from './DrawerAdd';
import * as DrawerEditSagas from './DrawerEdit';
import * as DeleteImage from './DeleteImage';
import * as PositionImage from './PositionImage';

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(TableSagas),
      ...Object.values(DrawerAddSagas),
      ...Object.values(DrawerEditSagas),
      ...Object.values(DeleteImage),
      ...Object.values(PositionImage),
    ].map(fork)
  );
}
