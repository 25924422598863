export const REJECT_REQUEST = 'REJECT_REQUEST/SALES-CHALLENGE';
export const REJECT_SUCCESS = 'REJECT_SUCCESS/SALES-CHALLENGE';
export const REJECT_FAIL = 'REJECT_FAIL/SALES-CHALLENGE';

export function rejectRequest(payload) {
  return {
    type: REJECT_REQUEST,
    payload,
  };
}

export function rejectSuccess(payload) {
  return {
    type: REJECT_SUCCESS,
    payload,
  };
}

export function rejectFail(payload) {
  return {
    type: REJECT_FAIL,
    payload,
  };
}
