import React, { Fragment, useContext, useState, useEffect } from 'react';
import TitleIconCard from '../common/components/Title-Icon-Card/titleIconCard';
import { appContext } from 'context';
import { Col, Row } from 'antd';

const DashboardLayout = () => {
  const [modulesCards, setmodulesCards] = useState([]);
  const { dashboardsPermissions } = useContext(appContext);

  const getIcon = (text) => {
    if (text === 'Acuerdo comercial') {
      return 'TableOutlined';
    }
    if (text === 'Comparativo') {
      return 'BarChartOutlined';
    }
    if (text === 'Desafío de ventas') {
      return 'TableOutlined';
    }
    if (text === 'Mapa') {
      return 'EnvironmentOutlined';
    }
  };

  useEffect(() => {
    let cards = dashboardsPermissions.map((item) => {
      return {
        title: item.text,
        icon: getIcon(item.text),
        linkTo: `/dashboards/${item.uri}`,
      };
    });
    setmodulesCards(cards);
  }, [dashboardsPermissions]);

  return (
    <Fragment>
      <Row>
        {modulesCards.map((item, index) => (
          <Col style={{ marginRight: 30, marginTop: 30 }} key={index} span={7}>
            <TitleIconCard
              key={item.linkTo}
              title={item.title}
              icon={item.icon}
              linkTo={item.linkTo}
            />
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};

export default DashboardLayout;
