import React from 'react';
import { Loader } from 'global';
import { Table } from 'antd';
import { AllCorrectSign } from 'common/MassiveUploads/components/index.js';
import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import errorMessages from './errorMessages';
import './massiveUploadTableColumns.css';

const MassiveUploadTable = ({ loading, data, allCorrectMessage }) => {
  const { Column } = Table;

  return (
    <div className="module-card-transparent" data-testid="upload">
      {(!data || data.length === 0) && (
        <div style={{ minHeight: '42vh' }}>
          {allCorrectMessage && <AllCorrectSign message={allCorrectMessage} />}
        </div>
      )}
      {loading && <Loader />}
      {data && data.length > 0 && (
        <Table
          scroll={{ x: 'max-content' }}
          columnWidth={'100px'}
          dataSource={data}
          className="TableMassiveU"
        >
          <Column
            title={
              <>
                <ErrorColumnTitle errorMessages={errorMessages} />
              </>
            }
            dataIndex="phase"
            key="phase"
            className="title-masive"
          />

          <Column title="Línea de negocio" dataIndex="line" key="line" />
          <Column title="Territorio" dataIndex="territory" key="territory" />
          <Column title="Sold to" dataIndex="soldTo" key="soldTo" />
          <Column title="Cliente" dataIndex="nameClient" key="nameClient" />
          <Column title="Año" dataIndex="year" key="address" />
          <Column title="Mes" dataIndex="month" key="address" />
          <Column
            title="Compras brutas de productos dentro de política "
            dataIndex="brutePurchasesProductsWithinPolicy"
            key="brutePurchasesProductsWithinPolicy"
          />
          <Column
            title="Compras brutas de productos fuera de política "
            dataIndex="brutePurchasesProductsOutPolicy"
            key="brutePurchasesProductsOutPolicy"
          />
          <Column title="Meta" dataIndex="goal" key="goal" />
          <Column title="Período" dataIndex="period" key="period" />
        </Table>
      )}
    </div>
  );
};

export default MassiveUploadTable;
