import { combineReducers } from 'redux';
import Table from './Table';
import DrawerEdit from './DrawerEdit';
import DrawerAdd from './DrawerAdd';
import DeleteImage from './DeleteImage';
import PositionImage from './PositionImage';
import SectionImages from './SectionImages';

const ManageImagesReducer = combineReducers({
  DrawerAdd,
  DrawerEdit,
  Table,
  DeleteImage,
  PositionImage,
  SectionImages,
});

export default ManageImagesReducer;
