// *HU 2210
import {
  DELETE_PRODUCT_REQUEST,
  deleteProductSuccess,
  deleteProductFail,
  DELETE_PRODUCT_MODAL_OPEN,
} from 'TradeAgreements/redux/actions/deleteProduct';
import { takeLatest, cancelled, put, call, select } from 'redux-saga/effects';
import { responseOK } from 'SalesChallengeRefactor/utils';
import instance from 'services/request';

import { openNotification } from 'common/misc/openNotification';
import { deleteProduct } from 'TradeAgreements/services/registerTrade';
import { validProductsForDeletingRequest } from '../actions/validProductsForDeleting';

function* workDeleteProductRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const {
      params,
      //afterDeleting
    } = payload;

    const response = yield call(deleteProduct, { params, cancelToken: source.token });

    if (responseOK(response)) {
      setTimeout(() => {
        openNotification('success', response.message);
      }, 500);

      yield put(deleteProductSuccess(response.data));
    } else {
      yield put(deleteProductFail());
    }
  } catch (error) {
    yield put(deleteProductFail());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

function* workDeleteProductModalOpen(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  /*
   validityProcessYear:
      state?.TRADE_AGREEMENTS?.registerTrade?.daysLeft?.data?.VALIDITY_PROCESS?.year ?? false,
    daysLeft: state?.TRADE_AGREEMENTS?.registerTrade?.daysLeft?.data ?? false,
    territoryId: state?.TRADE_AGREEMENTS?.infoForAgreement?.territoryInfo?.territoryId ?? null,
    consultClientKey: state?.TRADE_AGREEMENTS?.infoForAgreement?.clientInfo?.key ?? undefined,
    consultClientIsNational:
      state?.TRADE_AGREEMENTS?.infoForAgreement?.clientInfo?.isNational ?? undefined,
    agreementType: state?.TRADE_AGREEMENTS?.infoForAgreement?.agreementType ?? false,
    clientId: state?.TRADE_AGREEMENTS?.query?.clientId ?? false,
    productId: state?.TRADE_AGREEMENTS?.query?.productId ?? false,
    isAddingProduct: state?.TRADE_AGREEMENTS?.addProduct?.newProduct?.isPosting ?? false,
    tableIsValid: state?.TRADE_AGREEMENTS?.table?.tableIsValid,
    keyClient: state?.TRADE_AGREEMENTS?.clientKey?.clientKey ?? undefined,
    validProductsForDeleting: state?.TRADE_AGREEMENTS?.validProductsForDeleting?.total ?? 0,
    family: state?.TRADE_AGREEMENTS?.query?.family,
    businessRule: state?.TRADE_AGREEMENTS?.query?.businessRule ?? null,
    mustResetFilters: state?.TRADE_AGREEMENTS?.deleteProduct?.mustResetFilters ?? false,
  */

  const validityProcessYear = yield select(
    (state) => state?.TRADE_AGREEMENTS?.registerTrade?.daysLeft?.data?.VALIDITY_PROCESS?.year
  );
  const clientId = yield select((state) => state?.TRADE_AGREEMENTS?.query?.clientId);
  const businessRule = yield select((state) => state?.TRADE_AGREEMENTS?.query?.businessRule);

  try {
    const params = {
      clientId,
      year: validityProcessYear,
      policyTypeId: businessRule,
    };

    /*

 const triggerSerchValidProducts = () => {
    const params = {
      clientId,
      year: validityProcessYear,
      policyTypeId: businessRule,
    };

    if (!clientId || !businessRule || !validityProcessYear) {
      return;
    }
    getValidProductsForDeleting({ params });
  };


    */
    if (clientId && businessRule && validityProcessYear) {
      yield put(validProductsForDeletingRequest({ params }));
    }
  } catch (error) {
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchDeleteProductRequest() {
  yield takeLatest(DELETE_PRODUCT_REQUEST, workDeleteProductRequest);
}
export function* watchDeleteProductModalOpen() {
  yield takeLatest(DELETE_PRODUCT_MODAL_OPEN, workDeleteProductModalOpen);
}
