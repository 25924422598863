import {
  CONSULT_INFO_BASE_REQUEST,
  CONSULT_INFO_BASE_SET,
  CONSULT_INFO_BASE_CLEAN,
} from '../actions';

function accountStatusDynamicElementsReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case CONSULT_INFO_BASE_REQUEST:
      return {
        ...state,
        isLoading: true,
        consultTable: null,
      };

    case CONSULT_INFO_BASE_SET:
      return {
        ...state,
        isLoading: false,
        consultTable: action.payload,
        /*  consultTable: [
          {
            clientId: '1345aaa4-37ec-45e6-a07c-3fd51d3d60fc',
            soldto: 457989,
            client: 'Comercializadora Tajín',
            line: 'crop',
            territory: 'Crop',
            year: '2021',
            month: 'Julio',
            productIntoPolitic: 'Local',
            productOutPolitic: 'Local',
            meta: 'Local',
            period: 'Local',
          },
          {
            clientId: '1345aaa4-37ec-45e6-a07c-3fd51d3d60fc',
            soldto: 457989,
            client: 'Comercializadora Tajín',
            line: 'p&ss',
            territory: 'Crop',
            year: '2020',
            month: 'Enero',
            productIntoPolitic: 'Local',
            productOutPolitic: 'Local',
            meta: 'Local',
            period: 'Local',
          },
        ],*/
      };

    case CONSULT_INFO_BASE_CLEAN:
      return {
        ...state,
        isLoading: false,
        consultTable: null,
      };

    default:
      return state;
  }
}

export default accountStatusDynamicElementsReducer;
