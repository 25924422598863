/* eslint-disable react/no-unescaped-entities */
import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import React from 'react';
import './massiveUploadTableColumns.css';

const messageFaseOne = (
  <>
    <p>
      Registros para los cuales se omitió ingresar campos obligatorios, se ingresó un tipo de dato
      que no corresponde al solicitado en el campo o se excedió la longitud máxima.
    </p>
  </>
);
const messageFaseTwo = (
  <>
    <p>
      Registros de ventas por famlilia para los cuales se registró información de elementos con
      estatus "inactivo" o se ingresó información de un cliente que no se encuentra registrado en el
      sistema.
    </p>
  </>
);
const messageFaseThree = (
  <>
    <p>Registros que se encuentran repetidos dentro del archivo de carga masiva.</p>
  </>
);
const errorMessages = [
  {
    fase: 'Fase 1',
    message: messageFaseOne,
  },
  {
    fase: 'Fase 2',
    message: messageFaseTwo,
  },
  {
    fase: 'Fase 3',
    message: messageFaseThree,
  },
];
const tableColumns = [
  {
    key: 'phase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'phase',
    className: 'title-masive',
  },
  {
    key: 'line',
    title: 'Línea de negocio',
    dataIndex: 'line',
  },
  {
    key: 'soldTo',
    title: 'Sold To',
    dataIndex: 'soldTo',
  },
  {
    key: 'nameClient',
    title: 'Cliente',
    dataIndex: 'nameClient',
  },

  {
    key: 'family',
    title: 'Familia',
    dataIndex: 'family',
  },
  {
    key: 'volumeAgreement',
    title: 'Volumen acuerdo',
    dataIndex: 'volumeAgreement',
  },
  {
    key: 'brutePurchase',
    title: 'Compras brutas',
    dataIndex: 'brutePurchase',
  },
  {
    key: 'volumePurchase',
    title: 'Volumen de compras',
    dataIndex: 'volumePurchase',
  },
];
export default tableColumns;
