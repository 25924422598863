import { combineReducers } from 'redux';
import incentiveResumeDataReducers from './SectionData/incentiveResumeReducers';
import shopSectionDataReducers from './SectionData/shopSectionReducers';
import pogSectionDataReducers from './SectionData/pogSectionReducers';
import incentiveCreditNotesReducers from './SectionData/incentiveCreditNotesReducers';
import salesByFamilySectionDataReducers from './SectionData/salesByFamilySectionReducers';
import othersSectionDataReducers from './SectionData/othersSectionReducers';
import ocupationCreditLinesReducers from './SectionData/ocupationCreditLinesReducers';
import registerDataReducers from './SectionData/registerDataReducers';
import validateElementsReducers from './validateElements/validateElemetsReducers';
const AdminSectionReducer = combineReducers({
  incentiveResumeDataReducers,
  shopSectionDataReducers,
  pogSectionDataReducers,
  incentiveCreditNotesReducers,
  salesByFamilySectionDataReducers,
  othersSectionDataReducers,
  ocupationCreditLinesReducers,
  registerDataReducers,
  validateElementsReducers,
});

export default AdminSectionReducer;
