import React from 'react';
import { Row, Upload, Col } from 'antd';
export const acceptedFiles = '.xlsx';

const UploadExcelButton = (props) => {
  const { titleUpload } = props;
  return (
    <Row style={{ width: '100%', paddingBottom: 30 }}>
      <Col style={{ paddingTop: 30, paddingLeft: 30 }} span={12}>
        <div style={{ margin: 0, color: '#247ba9', fontSize: 20 }}>{titleUpload}</div>
      </Col>
      <Col align="end" span={12}>
        <Upload {...props} accept={acceptedFiles} multiple={false} maxCount={1} fileList={[]}>
          <button
            // eslint-disable-next-line react/destructuring-assignment
            className={` ${props.disabled ? 'disabled-upload-xls-button' : 'upload-xls-button'}`}
          >
            Cargar
          </button>
        </Upload>
      </Col>
    </Row>
  );
};

export default UploadExcelButton;
