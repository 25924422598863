export const FILTER_DATA = 'FILTER_DATA';
export const RESET_FILTER = 'RESET_FILTER';
export const RESET_FILTER_SUCCESS = 'RESET_FILTER_SUCCESS';

export const COMPARATIVE_SET_TYPE_OF_VIEW = 'COMPARATIVE_SET_TYPE_OF_VIEW';
export const COMPARATIVE_CLEAR_TYPE_OF_VIEW = 'COMPARATIVE_CLEAR_TYPE_OF_VIEW';
//get region
export const REGION_REQUEST = 'REGION_REQUEST';
export const REGION_SUCCESS = 'REGION_SUCCESS';
export const REGION_CLEAN = 'REGION_CLEAN';
//get types clients
export const TYPE_CLIENT_REQUEST = 'TYPE_CLIENT_REQUEST';
export const TYPE_CLIENT_SUCCESS = 'TYPE_CLIENT_SUCCESS';
export const TYPE_CLIENT_CLEAN = 'TYPE_CLIENT_CLEAN';
//segmentation
export const SEGMENTATION_REQUEST = 'SEGMENTATION_REQUEST';
export const SEGMENTATION_SUCCESS = 'SEGMENTATION_SUCCESS';
export const SEGMENTATION_CLEAN = 'SEGMENTATION_CLEAN';
//busines line
export const BUSINESS_LINES_REQUEST = 'BUSINESS_LINES_REQUEST';
export const BUSINESS_LINES_SUCCESS = 'BUSINESS_LINES_SUCCESS';
export const BUSINESS_LINE_CLEAN = 'BUSINESS_LINE_CLEAN';
//subregion
export const SUBREGION_REQUEST = 'SUBREGION_REQUEST';
export const SUBREGION_SUCCESS = 'SUBREGION_SUCCESS';
export const SUBREGION_CLEAN = 'SUBREGION_CLEAN';
//territories
export const TERRITORIES_REQUEST = 'TERRITORIES_REQUEST';
export const TERRITORIES_SUCCESS = 'TERRITORIES_SUCCESS';
export const TERRITORIES_CLEAN = 'TERRITORIES_CLEAN';
//zones
export const ZONES_REQUEST = 'ZONES_REQUEST';
export const ZONES_SUCCESS = 'ZONES_SUCCESS';
export const ZONES_CLEAN = 'ZONES_CLEAN';
//indicators
export const INDICATOR_REQUEST = 'INDICATOR_REQUEST';
export const INDICATOR_SUCCESS = 'INDICATOR_SUCCESS';
export const INDICATOR_CLEAN = 'INDICATOR_CLEAN';
//type information
export const TYPEINFO_REQUEST = 'TYPEINFO_REQUEST';
export const TYPEINFO_SUCCESS = 'TYPEINFO_SUCCESS';
export const TYPEINFO_CLEAN = 'TYPEINFO_CLEAN';
//TYPE UNIDAD
export const TYPEUNIDAD_REQUEST = 'TYPEUNIDAD_REQUEST';
export const TYPEUNIDAD_SUCCESS = 'TYPEUNIDAD_SUCCESS';
export const TYPEUNIDAD_CLEAN = 'TYPEUNIDAD_CLEAN';
//periodo
export const PERIODO_REQUEST = 'PERIODO_REQUEST';
export const PERIODO_SUCCESS = 'PERIODO_SUCCESS';
export const PERIODO_CLEAN = 'PERIODO_CLEAN';
//year
export const YEAR_REQUEST = 'YEAR_REQUEST';
export const YEAR_SUCCESS = 'YEAR_SUCCESS';
export const YEAR_CLEAN = 'YEAR_CLEAN';
//month
export const MONTH_REQUEST = 'MONTH_REQUEST';
export const MONTH_SUCCESS = 'MONTH_SUCCESS';
export const MONTH_CLEAN = 'MONTH_CLEAN';
//get client
export const CLIENT_REQUEST = 'CLIENT_REQUEST';
export const CLIENT_SUCCESS = 'CLIENT_SUCCESS';
export const CLIENT_CLEAN = 'CLIENT_CLEAN';
export const GET_CLIENT_BY_SOLD_TO = 'GET_CLIENT_BY_SOLD_TO';
//politica comercial
export const POLITICSPRODUCT_REQUEST = 'POLITICSPRODUCT_REQUEST';
export const POLITICSPRODUCT_SUCCESS = 'POLITICSPRODUCT_SUCCESS';
export const POLITICSPRODUCT_CLEAN = 'POLITICSPRODUCT_CLEAN';
//disponibilidad
export const DIPONIBILIDADPRODUCT_REQUEST = 'DIPONIBILIDADPRODUCT_REQUEST';
export const DISPONIBILIDADPRODUCT_SUCCESS = 'DISPONIBILIDADPRODUCT_SUCCESS';
export const DISPONIBILIDADPRODUCT_CLEAN = 'DISPONIBILIDADPRODUCT_CLEAN';
//get family producto
export const FAMILYPRODUCT_REQUEST = 'FAMILYPRODUCT_REQUEST';
export const FAMILYPRODUCT_SUCCESS = 'FAMILYPRODUCT_SUCCESS';
export const FAMILYPRODUCT_CLEAN = 'FAMILYPRODUCT_CLEAN';
//get product
export const PRODUCT_REQUEST = 'PRODUCT_REQUEST';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
export const PRODUCT_CLEAN = 'PRODUCT_CLEAN';

// get main api
export const COMPARATIVE_DASHBOARD_REQUEST_MAIN_API = 'COMPARATIVE_DASHBOARD_REQUEST_MAIN_API';
export const COMPARATIVE_DASHBOARD_SET_MAIN_API = 'COMPARATIVE_DASHBOARD_SET_MAIN_API';
export const COMPARATIVE_DASHBOARD_CLEAR_MAIN_API = 'COMPARATIVE_DASHBOARD_CLEAR_MAIN_API';

// catalogs load
export const COMPARATIVE_DASHBOARD_REQUEST_CATALOGS_LOAD =
  'COMPARATIVE_DASHBOARD_REQUEST_CATALOGS_LOAD';
export const COMPARATIVE_DASHBOARD_SET_CATALOGS_LOAD = 'COMPARATIVE_DASHBOARD_SET_CATALOGS_LOAD';
export const COMPARATIVE_DASHBOARD_CLEAR_CATALOGS_LOAD =
  'COMPARATIVE_DASHBOARD_CLEAR_CATALOGS_LOAD';
// get excel

export const COMPARATIVE_DASHBOARD_REQUEST_EXCEL = 'COMPARATIVE_DASHBOARD_REQUEST_EXCEL';
export const COMPARATIVE_DASHBOARD_SET_EXCEL = 'COMPARATIVE_DASHBOARD_SET_EXCEL';
export const COMPARATIVE_DASHBOARD_CLEAR_EXCEL = 'COMPARATIVE_DASHBOARD_CLEAR_EXCEL';

//request information existances

export const COMPARATIVE_DASHBOARD_REQUEST_INFORMATION_EXISTANCES =
  'COMPARATIVE_DASHBOARD_REQUEST_INFORMATION_EXISTANCES';
export const COMPARATIVE_DASHBOARD_SET_INFORMATION_EXISTANCES =
  'COMPARATIVE_DASHBOARD_SET_INFORMATION_EXISTANCES';
export const COMPARATIVE_DASHBOARD_CLEAR_INFORMATION_EXISTANCES =
  'COMPARATIVE_DASHBOARD_CLEAR_INFORMATION_EXISTANCES';

//request information existances
export function comparativeDashboardRequestInformationExistances(payload) {
  return {
    type: COMPARATIVE_DASHBOARD_REQUEST_INFORMATION_EXISTANCES,
    payload: payload,
  };
}

export function comparativeDashboardSetInformationExistances(payload) {
  return {
    type: COMPARATIVE_DASHBOARD_SET_INFORMATION_EXISTANCES,
    payload: payload,
  };
}

export function comparativeDashboardClearInformationExistances() {
  return {
    type: COMPARATIVE_DASHBOARD_CLEAR_INFORMATION_EXISTANCES,
  };
}

// get excel
export function comparativeDashboardRequestExcel(payload) {
  return {
    type: COMPARATIVE_DASHBOARD_REQUEST_EXCEL,
    payload: payload,
  };
}

export function comparativeDashboardSetExcel(payload) {
  return {
    type: COMPARATIVE_DASHBOARD_SET_EXCEL,
    payload: payload,
  };
}

export function comparativeDashboardClearExcel() {
  return {
    type: COMPARATIVE_DASHBOARD_CLEAR_EXCEL,
  };
}

// catalogs load
export function comparativeDashboardRequestCatalogsLoad(payload) {
  return {
    type: COMPARATIVE_DASHBOARD_REQUEST_CATALOGS_LOAD,
    payload: payload,
  };
}

export function comparativeDashboardSetCatalogsLoad(payload) {
  return {
    type: COMPARATIVE_DASHBOARD_SET_CATALOGS_LOAD,
    payload: payload,
  };
}

export function comparativeDashboardClearCatalogsLoad() {
  return {
    type: COMPARATIVE_DASHBOARD_CLEAR_CATALOGS_LOAD,
  };
}

// get main api
export function comparativeDashboardRequestMainApi(payload) {
  return {
    type: COMPARATIVE_DASHBOARD_REQUEST_MAIN_API,
    payload: payload,
  };
}

export function comparativeDashboardSetMainApi(payload) {
  return {
    type: COMPARATIVE_DASHBOARD_SET_MAIN_API,
    payload: payload,
  };
}

export function comparativeDashboardClearMainApi() {
  return {
    type: COMPARATIVE_DASHBOARD_CLEAR_MAIN_API,
  };
}

//get product
export function productRequest(payload) {
  return {
    type: PRODUCT_REQUEST,
    payload: payload,
  };
}

export function productSuccess(payload) {
  return {
    type: PRODUCT_SUCCESS,
    payload: payload,
  };
}

export function productClean(payload) {
  return {
    type: PRODUCT_CLEAN,
    payload: payload,
  };
}
//get family
export function familyProductRequest(payload) {
  return {
    type: FAMILYPRODUCT_REQUEST,
    payload: payload,
  };
}

export function familyProductSuccess(payload) {
  return {
    type: FAMILYPRODUCT_SUCCESS,
    payload: payload,
  };
}

export function familyProductClean(payload) {
  return {
    type: FAMILYPRODUCT_CLEAN,
    payload: payload,
  };
}
//get disponibilidad
export function disponibilidadProductRequest(payload) {
  return {
    type: DIPONIBILIDADPRODUCT_REQUEST,
    payload: payload,
  };
}

export function disponibilidadProductSuccess(payload) {
  return {
    type: DISPONIBILIDADPRODUCT_SUCCESS,
    payload: payload,
  };
}

export function disponibilidadProductClean(payload) {
  return {
    type: DISPONIBILIDADPRODUCT_CLEAN,
    payload: payload,
  };
}
//get politics
export function politicsProductRequest(payload) {
  return {
    type: POLITICSPRODUCT_REQUEST,
    payload: payload,
  };
}

export function politicsProductSuccess(payload) {
  return {
    type: POLITICSPRODUCT_SUCCESS,
    payload: payload,
  };
}

export function politicsProductClean(payload) {
  return {
    type: POLITICSPRODUCT_CLEAN,
    payload: payload,
  };
}
//get month
export function clientRequest(payload) {
  return {
    type: CLIENT_REQUEST,
    payload: payload,
  };
}

export function clientSuccess(payload) {
  return {
    type: CLIENT_SUCCESS,
    payload: payload,
  };
}

export function clientClean(payload) {
  return {
    type: CLIENT_CLEAN,
    payload: payload,
  };
}
//get month
export function monthRequest(payload) {
  return {
    type: MONTH_REQUEST,
    payload: payload,
  };
}

export function monthSuccess(payload) {
  return {
    type: MONTH_SUCCESS,
    payload: payload,
  };
}

export function monthClean(payload) {
  return {
    type: MONTH_CLEAN,
    payload: payload,
  };
}
//get year
export function yearRequest(payload) {
  return {
    type: YEAR_REQUEST,
    payload: payload,
  };
}

export function yearSuccess(payload) {
  return {
    type: YEAR_SUCCESS,
    payload: payload,
  };
}

export function yearClean(payload) {
  return {
    type: YEAR_CLEAN,
    payload: payload,
  };
}

//get periodo
export function periodoRequest(payload) {
  return {
    type: PERIODO_REQUEST,
    payload: payload,
  };
}

export function periodoSuccess(payload) {
  return {
    type: PERIODO_SUCCESS,
    payload: payload,
  };
}

export function periodoClean(payload) {
  return {
    type: PERIODO_CLEAN,
    payload: payload,
  };
}

//get type unidad
export function typeUnidadRequest(payload) {
  return {
    type: TYPEUNIDAD_REQUEST,
    payload: payload,
  };
}

export function typeUnidadSuccess(payload) {
  return {
    type: TYPEUNIDAD_SUCCESS,
    payload: payload,
  };
}

export function typeUnidadClean(payload) {
  return {
    type: TYPEUNIDAD_CLEAN,
    payload: payload,
  };
}

//get type information
export function typeInformationRequest(payload) {
  return {
    type: TYPEINFO_REQUEST,
    payload: payload,
  };
}

export function typeInformationSuccess(payload) {
  return {
    type: TYPEINFO_SUCCESS,
    payload: payload,
  };
}

export function typeInformationClean(payload) {
  return {
    type: TYPEINFO_CLEAN,
    payload: payload,
  };
}

//get indicator
export function indicatorRequest(payload) {
  return {
    type: INDICATOR_REQUEST,
    payload: payload,
  };
}

export function indicatorSuccess(payload) {
  return {
    type: INDICATOR_SUCCESS,
    payload: payload,
  };
}

export function indicatorClean(payload) {
  return {
    type: INDICATOR_CLEAN,
    payload: payload,
  };
}
//get zones
export function zoneRequest(payload) {
  return {
    type: ZONES_REQUEST,
    payload: payload,
  };
}

export function zoneSuccess(payload) {
  return {
    type: ZONES_SUCCESS,
    payload: payload,
  };
}

export function zoneClean(payload) {
  return {
    type: ZONES_CLEAN,
    payload: payload,
  };
}
//get bussines line
export function territoryRequest(payload) {
  return {
    type: TERRITORIES_REQUEST,
    payload: payload,
  };
}

export function territorySuccess(payload) {
  return {
    type: TERRITORIES_SUCCESS,
    payload: payload,
  };
}

export function territoryClean(payload) {
  return {
    type: TERRITORIES_CLEAN,
    payload: payload,
  };
}

//get bussines line
export function subRegionRequest(payload) {
  return {
    type: SUBREGION_REQUEST,
    payload: payload,
  };
}

export function subRegionSuccess(payload) {
  return {
    type: SUBREGION_SUCCESS,
    payload: payload,
  };
}

export function subRegionClean(payload) {
  return {
    type: SUBREGION_CLEAN,
    payload: payload,
  };
}

//get bussines line
export function businessLineRequest(payload) {
  return {
    type: BUSINESS_LINES_REQUEST,
    payload: payload,
  };
}

export function businessLineSuccess(payload) {
  return {
    type: BUSINESS_LINES_SUCCESS,
    payload: payload,
  };
}

export function businessLineClean(payload) {
  return {
    type: BUSINESS_LINE_CLEAN,
    payload: payload,
  };
}
//get client list

export function getClientBySoldTo(payload) {
  return {
    type: GET_CLIENT_BY_SOLD_TO,
    payload: payload,
  };
}

//get segmentation
export function segmentationRequest(payload) {
  return {
    type: SEGMENTATION_REQUEST,
    payload: payload,
  };
}

export function segmentationSuccess(payload) {
  return {
    type: SEGMENTATION_SUCCESS,
    payload: payload,
  };
}

export function segmentationClean(payload) {
  return {
    type: SEGMENTATION_CLEAN,
    payload: payload,
  };
}
//get type client
export function typeClientRequest(payload) {
  return {
    type: TYPE_CLIENT_REQUEST,
    payload: payload,
  };
}

export function typeClientSuccess(payload) {
  return {
    type: TYPE_CLIENT_SUCCESS,
    payload: payload,
  };
}

export function typeClientClean(payload) {
  return {
    type: TYPE_CLIENT_CLEAN,
    payload: payload,
  };
}
//get region
export function regionRequest(payload) {
  return {
    type: REGION_REQUEST,
    payload: payload,
  };
}

export function regionSuccess(payload) {
  return {
    type: REGION_SUCCESS,
    payload: payload,
  };
}

export function regionClean(payload) {
  return {
    type: REGION_CLEAN,
    payload: payload,
  };
}
//option filter
export function filterDataRequest(payload) {
  return {
    type: FILTER_DATA,
    payload: payload,
  };
}

export function resetFilter(payload) {
  return {
    type: RESET_FILTER,
    payload: payload,
  };
}

export function resetFilterSuccess(payload) {
  return {
    type: RESET_FILTER_SUCCESS,
    payload: payload,
  };
}

export function comparativeSetTypeOfView(payload) {
  return {
    type: COMPARATIVE_SET_TYPE_OF_VIEW,
    payload: payload,
  };
}

export function comparativeClearTypeOfView(payload) {
  return {
    type: COMPARATIVE_CLEAR_TYPE_OF_VIEW,
    payload: payload,
  };
}
