import { Row } from 'antd';
import React from 'react';

const FilterWrapper = ({ children }) => {
  let rowStyle = { marginTop: '15px' };
  return (
    <div style={rowStyle}>
      <Row>{children}</Row>
    </div>
  );
};

export default FilterWrapper;
