import { combineReducers } from 'redux';
import {
  LOG_REQUEST,
  GET_SYSTEM_USER_LOG,
  GET_LOG_PAGES,
  GET_LOG_FAIL,
  LOG_ENTITY_REQUEST,
  GET_LOG_ENTITY,
  LOG_TYPE_REQUEST,
  GET_LOG_TYPES,
  LOG_USERS_REQUEST,
  GET_LOG_USERS,
  GET_LOG_TOTAL,
} from '../actions';

function logsCase(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case LOG_REQUEST:
      return {
        ...state,
      };
    case LOG_ENTITY_REQUEST:
      return {
        ...state,
      };
    case GET_LOG_ENTITY:
      return {
        ...state,
        entitiesArray: payload,
      };
    case LOG_TYPE_REQUEST:
      return {
        ...state,
      };
    case GET_LOG_TYPES:
      return {
        ...state,
        logTypesArray: payload,
      };
    case GET_SYSTEM_USER_LOG:
      return {
        ...state,
        logArray: payload,
      };
    case GET_LOG_PAGES:
      return {
        ...state,
        logPages: payload,
      };
    case GET_LOG_FAIL:
      return {
        ...state,
        errorMsg: payload,
      };
    case LOG_USERS_REQUEST:
      return {
        ...state,
      };
    case GET_LOG_USERS:
      return {
        ...state,
        logUsersArray: payload,
      };
    case GET_LOG_TOTAL:
      return {
        ...state,
        logTotal: payload,
      };
    default:
      return state;
  }
}

const logsReguder = combineReducers({
  logsCase,
});

export default logsReguder;
