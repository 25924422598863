import html2canvas from 'html2canvas';
import { message001 } from 'utils/messages';

async function downloadDomElementAsPng(elementId, fileToSave) {
  const element = document.getElementById(elementId);

  let canvasOptions = {};

  html2canvas(element, canvasOptions).then(function (canvas) {
    const a = document.createElement('a');
    a.href = canvas.toDataURL();

    a.download = `${fileToSave}.jpg`;
    a.click();
    a.remove();
    message001('La descarga de información del dashboard comparativo');
  });
}

export default downloadDomElementAsPng;
