import request from 'services/request';
import { paramsSerializer } from 'RootModule/services/catalogs';

const URL_CATALOGS = '/Catalogs/Api/v1';
const URL_PRODUCTS = `${URL_CATALOGS}/Products`;

export async function getProductExistence({ cancelToken, params, mode }) {
  let uri = `${URL_PRODUCTS}/CheckExistance`;
  return request(uri, {
    method: 'GET',
    cancelToken,
    paramsSerializer: mode === 'multiple' ? paramsSerializer : undefined,
    params,
  });
}
export async function registerProduct({ cancelToken, data }) {
  let uri = `${URL_PRODUCTS}`;
  return request(uri, {
    method: 'POST',
    cancelToken,
    data,
  });
}
