/* eslint-disable react-hooks/exhaustive-deps */
/* this filter depends on PogPeriodicity filter value to render an option of periods related to Pog periodicity's value */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  consultFiltersPogPeriodRequestOptions,
  consultFiltersPogPeriodSetValue,
} from 'RootModule/redux/actions/consultFilters';
import { addAllBtnOnOptions, getPeriodOptions, handleAllBtnClick } from 'common/consults/utils';

import Filter from 'common/consults/components/Filter';

const PogPeriod = (props) => {
  const {
    consultFiltersPogPeriodSetValue: setValue,
    value,
    pogPeriodicity,
    required,
    disableSelectAll,
  } = props;

  const disableFilter = () => {
    return !(Array.isArray(pogPeriodicity) && pogPeriodicity.length > 0);
  };

  let options = getPeriodOptions(pogPeriodicity);

  let mappedOptions = [...options];

  let periodicityJson = JSON.stringify(pogPeriodicity);

  useEffect(() => {
    if (Array.isArray(pogPeriodicity) && pogPeriodicity.length > 0) {
      setValue([1]);
    } else {
      setValue([]);
    }
  }, [periodicityJson]);

  const selectChange = (selected) => {
    selected = handleAllBtnClick(selected, options);
    setValue(selected);
  };
  if (!disableSelectAll) {
    mappedOptions = addAllBtnOnOptions(options);
  }
  return (
    <Filter
      name={'Periodo POG'}
      required={required}
      value={value}
      selectChange={selectChange}
      options={mappedOptions}
      disabled={disableFilter()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    options: state?.APP?.consultFilters.pogPeriod?.options ?? [],
    value: state?.APP?.consultFilters.pogPeriod?.value,
    pogPeriodicity: state?.APP?.consultFilters.pogPeriodicity?.value,
  };
};

PogPeriod.propTypes = {
  consultFiltersPogPeriodSetValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  required: PropTypes.bool,

  disableSelectAll: PropTypes.bool,
};

PogPeriod.defaultProps = {
  // eslint-disable-next-line no-empty-function
  consultFiltersPogPeriodRequestOptions: () => {},
  // eslint-disable-next-line no-empty-function
  consultFiltersPogPeriodSetValue: () => {},
  value: [],
  required: false,
  options: [],
  params: {},
  disableSelectAll: false,
};

export default connect(mapStateToProps, {
  consultFiltersPogPeriodRequestOptions,
  consultFiltersPogPeriodSetValue,
})(PogPeriod);
