import React, { useEffect, useContext, useState, Fragment } from 'react';
import { appContext } from '../context';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CatalogCard from './catalogCard';
import { getCatalogsObjects } from './utils';
import { layoutContext } from '../context';
import { getRemainingDays } from './services';

function Home({ match }) {
  const { t } = useTranslation(['catalogs']);
  const { onChangeActiveComponent } = useContext(layoutContext);
  const { catalogsPermissions, historicInformationPermissions, modules } = useContext(appContext);
  const [modulesCards, setModules] = useState([]);

  const type = match.path.split('/')[1];
  const catalog = type === 'catalog' ? catalogsPermissions : historicInformationPermissions;

  useEffect(() => {
    getRemainingDays(type).then((res) => {
      let uploadEnabled = res.daysLeft >= 0;
      setModules(getCatalogsObjects(catalog, modules, type, uploadEnabled));
    });
    onChangeActiveComponent(undefined);
  }, [type, modules, catalog, onChangeActiveComponent]);

  return (
    <>
      <Row gutter={[16, 16]} style={{ width: '100%' }}>
        {modulesCards &&
          modulesCards.map((module, key) => {
            if (module.name === 'trade-agreement-info') {
              return (
                <Fragment key={key}>
                  <Link
                    to={{
                      pathname: `/catalog/trade-agreement-info`,
                    }}
                    style={{ width: '33.33%' }}
                  >
                    <CatalogCard title={t(`catalogs.${module.name}`)} span={24} />
                  </Link>
                </Fragment>
              );
            } else {
              return (
                <Fragment key={key}>
                  <CatalogCard
                    title={t(`catalogs.${module.name}`)}
                    add={module.add}
                    upload={module.upload}
                    consult={module.consult}
                  />
                </Fragment>
              );
            }
          })}
      </Row>
    </>
  );
}

export default Home;
