import request from 'services/request';
const serviceURL = '/Catalogs/Api/v1/ExchangeRate';

export async function putOneExchangeRate({ data, cancelToken }) {
  return request(serviceURL, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

export async function updateOneExchangeRateStatus({ cancelToken, userId, enabled }) {
  return request(`${serviceURL}/Enable/${userId}/${enabled}`, {
    method: 'PUT',
    cancelToken,
  });
}
