import React, { useEffect, useState } from 'react';
import { getBe } from '../services';
import BussinesEstructureSelect from './bussinesEstructureSelect';

function ZoneSelect({ territoriesIds, required, multiple, onChange, defaultValue }) {
  const [zones, setZones] = useState([]);

  useEffect(() => {
    if (territoriesIds && (territoriesIds.length > 0 || territoriesIds > 0)) {
      getBe({
        element: 'zones',
        territories: territoriesIds,
      }).then((res) => setZones(res));
    } else {
      setZones([]);
    }
  }, [territoriesIds]);

  return (
    <BussinesEstructureSelect
      tag={'zones'}
      label={'zone'}
      required={required}
      defaultValue={defaultValue}
      options={zones}
      disabled={zones.length <= 0}
      multiple={multiple}
      onChange={onChange}
    />
  );
}
export default ZoneSelect;
