/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = (props) => {
  const { pdf, showPage, pages, columns, className, onClick, onLoadSuccess, style } = props;
  const totalFractions = 24;

  const individualRowSize = Math.floor(totalFractions / columns);

  const pagesNumberArray = Array.from(Array(pages).keys());

  const [colContainer, setColContainer] = useState(null);

  return (
    <div className={className} style={style} onClick={onClick}>
      <Document file={pdf} noData={'Cargando Archivo...'} onLoadSuccess={onLoadSuccess}>
        <Row>
          {pagesNumberArray.map((item) => {
            return (
              <Col
                key={'pageNumberCol' + item}
                span={individualRowSize}
                ref={(col) => {
                  setColContainer(col);
                }}
              >
                <Page
                  pageNumber={showPage + item}
                  className={''}
                  key={`pageNumber` + item}
                  width={colContainer ? colContainer.offsetWidth : 0}
                />
              </Col>
            );
          })}
        </Row>
      </Document>
    </div>
  );
};

PdfViewer.propTypes = {
  pdf: PropTypes.any.isRequired,
  pages: PropTypes.number,
  columns: PropTypes.number,
  showPage: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onLoadSuccess: PropTypes.func,
  style: PropTypes.object,
};
PdfViewer.defaultProps = {
  pages: 1,
  columns: 1,
  showPage: 1,
  style: {},
  onClick: () => undefined,
  onLoadSuccess: () => undefined,
  className: '',
};

export default PdfViewer;
