import {
  ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_REQUEST_CONSULT_TABLE,
  ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_SET_CONSULT_TABLE,
  ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_CLEAR_CONSULT_TABLE,
  ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_REQUEST_DATA_EXISTANCE,
  ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_SET_DATA_EXISTANCE,
  ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_CLEAR_DATA_EXISTANCE,
} from '../actions';

function accountStatusTradeAgreementGoalsReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_REQUEST_CONSULT_TABLE:
      return {
        ...state,
        isLoading: true,
        consultTable: null,
      };

    case ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_SET_CONSULT_TABLE:
      return {
        ...state,
        isLoading: false,
        consultTable: action.payload,
      };

    case ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_CLEAR_CONSULT_TABLE:
      return {
        ...state,
        isLoading: false,
        consultTable: null,
      };

    case ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_REQUEST_DATA_EXISTANCE:
      return {
        ...state,
        isLoading: true,
        dataExistance: null,
      };

    case ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_SET_DATA_EXISTANCE:
      return {
        ...state,
        isLoading: false,
        dataExistance: action.payload,
      };

    case ACCOUNT_STATUS_TRADE_AGREEMENT_GOALS_CLEAR_DATA_EXISTANCE:
      return {
        ...state,
        isLoading: false,
        dataExistance: true,
      };

    default:
      return state;
  }
}

export default accountStatusTradeAgreementGoalsReducer;
