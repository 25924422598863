import React from 'react';
import correctEntries from './correctEntries.png';

export default function AllCorrectSign({ message }) {
  const divStyle = {
    textAlign: 'center',
    backgroundColor: '#fff',
    borderRadius: '5px',
    marginTop: '-3em',
    padding: '2vh',
  };
  const imgStyle = { maxHeight: '40vh', marginBottom: '4vh' };
  const textStyle = { textAlign: 'center' };
  const headerStyle = {
    ...textStyle,
    color: '#00499a',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 1.4,
  };
  const paragraphStyle = { ...textStyle, color: ' rgba(0, 0, 0, 0.85', fontSize: '14px' };
  return (
    <div style={divStyle}>
      <img src={correctEntries} alt={'registros correctos'} style={imgStyle} />
      <p style={headerStyle}>Registros correctos</p>
      <p style={paragraphStyle}>{message}</p>
    </div>
  );
}
