const ApiConstants = {
  subRegions: '/Catalogs/Api/v1/BusinessStructure/Subregions',
  businessLines: '/Catalogs/Api/v1/BusinessStructure/Lines',
  years: '/AccountStatement/Api/v1/CreditNotes/GetPurchasesYears',
  periodicity: '/Catalogs/Api/v1/FixedCatalogs/Pogs',
  incentives: 'AccountStatement/Api/v1/CreditNotes/IncentivesNC',
  creditNotes: '/AccountStatement/Api/v1/CreditNotes',
};

export default ApiConstants;
