export const salesChallengeFilters = Object.freeze({
  years: {
    label: 'Año',
    name: 'year',
    placeholder: 'Seleccione un año',
    filterName: 'years',
    view: 'ViewYears',
  },
  region: {
    label: 'Región',
    name: 'regionId',
    placeholder: 'Seleccione una región',
    filterName: 'region',
    view: 'ViewRegion',
  },
  businessLine: {
    label: 'Línea de negocio',
    name: 'lineId',
    placeholder: 'Seleccione una línea de negocio',
    filterName: 'businessLine',
    view: 'ViewBusinessLine',
  },
  subRegion: {
    label: 'Subregión',
    name: 'subRegionId',
    placeholder: 'Seleccione una subregión',
    filterName: 'subRegion',
    view: 'ViewSubRegion',
  },
  territory: {
    label: 'Territorio',
    name: 'territoryId',
    placeholder: 'Seleccione un territorio',
    filterName: 'territory',
    view: 'ViewTerritory',
  },
  zones: {
    label: 'Zona',
    name: 'zoneId',
    placeholder: 'Seleccione una zona',
    filterName: 'zones',
    view: 'ViewZone',
  },
  clients: {
    label: 'Cliente',
    name: 'clientId',
    placeholder: 'Seleccione un cliente',
    filterName: 'clients',
    view: 'ViewClient',
  },
  family: {
    label: 'Familia',
    name: 'family',
    placeholder: 'Seleccione una familia',
    filterName: 'family',
    view: 'ViewFamily',
  },
  products: {
    label: 'Producto',
    name: 'productId',
    placeholder: 'Seleccione un producto',
    filterName: 'products',
    view: 'ViewProduct',
  },
  netPrice: {
    label: 'Precio neto',
    name: 'netPrice',
    placeholder: 'Seleccione un precio',
    filterName: 'netPrice',
  },
  crop: {
    label: 'AP',
    name: 'crop',
    placeholder: 'Seleccione un AP',
    filterName: 'crop',
  },
  country: {
    label: 'País',
    name: 'country',
    placeholder: 'Seleccione un país',
    filterName: 'country',
  },
});
