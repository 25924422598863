export const ADD_TRADE_AGREEMENT_VALIDATOR_REQUEST = 'ADD_TRADE_AGREEMENT_VALIDATOR_REQUEST';
export const ADD_TRADE_AGREEMENT_VALIDATOR_SUCCESS = 'ADD_TRADE_AGREEMENT_VALIDATOR_SUCCESS';
export const ADD_TRADE_AGREEMENT_VALIDATOR_FAIL = 'ADD_TRADE_AGREEMENT_VALIDATOR_FAIL';

export function addTradeAgreementValidatorRequest(payload) {
  return {
    type: ADD_TRADE_AGREEMENT_VALIDATOR_REQUEST,
    payload,
  };
}

export function addTradeAgreementValidatorSuccess(payload) {
  return { type: ADD_TRADE_AGREEMENT_VALIDATOR_SUCCESS, payload };
}

export function addTradeAgreementValidatorFail(payload) {
  return { type: ADD_TRADE_AGREEMENT_VALIDATOR_FAIL, payload };
}
