import request from '../../services/request';
const URL_SALESCHALLENGE = '/Agreements/Api/v1/Agreements/';

export async function approveTradeAgreement({ data, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}ApproveTradeAgreement`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

export async function rejectTradeAgreement({ data, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}RejectTradeAgreement`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

export async function approveTradeAgreementByKam({ data, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}ApproveTradeAgreementByKam`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

export async function rejectTradeAgreementByKam({ data, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}RejectTradeAgreementByKam`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

export async function generateAgreement({ payload, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}Generate`, {
    method: 'POST',
    cancelToken,
    data: payload,
  });
}

export async function rejectTradeAgreementByAdmin({ params, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}RejectTradeAgreementByAdmin`, {
    method: 'PUT',
    cancelToken,
    data: params,
  });
}
