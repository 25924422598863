import { takeLatest, put, call } from 'redux-saga/effects';
import {
  VALIDATORS_LIST_SALES_CHALLENGE_REQUEST,
  VALIDATORS_LIST_TRADE_AGREEMENT_REQUEST,
  validatorsListSalesChallengeSuccess,
  validatorsListSalesChallengeFailure,
  validatorsListTradeAgreementSuccess,
  validatorsListTradeAgreementFailure,
} from '../actions/validatorList';
import instance from '../../../services/request';
import { getValidatorsChallenge, getValidatorsAgreement } from 'Validations/services/validatorList';
import { responseOK } from 'utils';

function* workValidatorsListSalesChallengeRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  // salesChallengeList: state?.VALIDATIONS?.validatorList?.salesChallenge?.list || [],
  // tradeAgreementList: state?.VALIDATIONS?.validatorList?.tradeAgreement?.list || [],
  //const sCValidatorList = yield select((state) => state?.APP?.catalogs?.territory?.data);
  try {
    const params = action?.payload?.params ? action.payload.params : {};

    const response = yield call(getValidatorsChallenge, params, source.token);

    if (responseOK(response)) {
      yield put(
        validatorsListSalesChallengeSuccess(
          response.data
          /*  response.data.map((item) => {
            item.zone = sCValidatorList[Math.floor(Math.random() * sCValidatorList.length)].value;
            return item;
          }) */
        )
      );
    }
  } catch (error) {
    console.error({ error });
    yield put(validatorsListSalesChallengeFailure());
  }
}

function* workValidatorsListTradeAgreementRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const params = action?.payload?.params ? action.payload.params : {};

    const response = yield call(getValidatorsAgreement, params, source.token);

    if (responseOK(response)) {
      yield put(validatorsListTradeAgreementSuccess(response.data));
    }
  } catch (error) {
    yield put(validatorsListTradeAgreementFailure());
  }
}

export function* watchValidatorsListSalesChallengeRequest() {
  yield takeLatest(
    VALIDATORS_LIST_SALES_CHALLENGE_REQUEST,
    workValidatorsListSalesChallengeRequest
  );
}

export function* watchValidatorsListTradeAgreementRequest() {
  yield takeLatest(
    VALIDATORS_LIST_TRADE_AGREEMENT_REQUEST,
    workValidatorsListTradeAgreementRequest
  );
}
