import {
  BUSINESS_LINES_REQUEST,
  BUSINESS_LINES_SET,
  BUSINESS_LINES_CLEAN,
  SUBREGIONS_REQUEST,
  SUBREGIONS_SET,
  SUBREGIONS_CLEAN,
  MONTH_REQUEST,
  MONTH_SET,
  MONTH_CLEAN,
  PERIOD_REQUEST,
  PERIOD_SET,
  PERIOD_CLEAN,
  CLIENT_REQUEST,
  CLIENT_SET,
  CLIENT_CLEAN,
  STATUSDATA_REQUEST,
  STATUSDATA_SET,
  STATUSDATA_CLEAN,
  REGISTER_REQUEST,
  REGISTER_SET,
  REGISTER_CLEAN,
  FREQUENCY_REQUEST,
  FREQUENCY_SET,
  FREQUENCY_CLEAN,
  CHANGE_STATUS_REQUEST,
  CHANGE_STATUS_SET,
  CHANGE_STATUS_CLEAN,
  DELETE_PERIOD_REQUEST,
  DELETE_PERIOD_SET,
  DELETE_PERIOD_CLEAN,
  STATUS_REQUEST,
  STATUS_SET,
  STATUS_CLEAN,
} from '../actions';

function selectReducers(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case STATUS_REQUEST:
      return {
        ...state,
        status: payload,
      };
    case STATUS_SET:
      return {
        ...state,
        status: payload,
      };
    case STATUS_CLEAN:
      return {
        ...state,
        status: [],
      };
    case DELETE_PERIOD_REQUEST:
      return {
        ...state,
        deletePeriod: payload,
      };
    case DELETE_PERIOD_SET:
      return {
        ...state,
        deletePeriod: payload,
      };
    case DELETE_PERIOD_CLEAN:
      return {
        ...state,
        deletePeriod: [],
      };
    case CHANGE_STATUS_REQUEST:
      return {
        ...state,
        status: payload,
      };
    case CHANGE_STATUS_SET:
      return {
        ...state,
        status: payload,
      };
    case CHANGE_STATUS_CLEAN:
      return {
        ...state,
        status: [],
      };
    case FREQUENCY_REQUEST:
      return {
        ...state,
        frequency: payload,
      };
    case FREQUENCY_SET:
      return {
        ...state,
        frequency: payload,
      };
    case FREQUENCY_CLEAN:
      return {
        ...state,
        frequency: [],
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        register: payload,
      };
    case REGISTER_SET:
      return {
        ...state,
        register: payload,
      };
    case REGISTER_CLEAN:
      return {
        ...state,
        register: payload,
      };
    case STATUSDATA_REQUEST:
      return {
        ...state,
        statusData: payload,
      };
    case STATUSDATA_SET:
      return {
        ...state,
        statusData: payload,
      };
    case STATUSDATA_CLEAN:
      return {
        ...state,
        statusData: payload,
      };
    case CLIENT_REQUEST:
      return {
        ...state,
        clientData: payload,
      };
    case CLIENT_SET:
      return {
        ...state,
        clientData: payload,
      };
    case CLIENT_CLEAN:
      return {
        ...state,
        clientData: payload,
      };
    case PERIOD_REQUEST:
      return {
        ...state,
        periodOptions: payload,
      };
    case PERIOD_SET:
      return {
        ...state,
        periodOptions: payload,
      };
    case PERIOD_CLEAN:
      return {
        ...state,
        periodOptions: payload,
      };
    case MONTH_REQUEST:
      return {
        ...state,
        monthOptions: payload,
      };
    case MONTH_SET:
      return {
        ...state,
        monthOptions: payload,
      };
    case MONTH_CLEAN:
      return {
        ...state,
        monthOptions: payload,
      };
    case BUSINESS_LINES_REQUEST:
      return {
        ...state,
        businessLinesOptions: payload,
      };
    case BUSINESS_LINES_SET:
      return {
        ...state,
        businessLinesOptions: payload,
      };
    case BUSINESS_LINES_CLEAN:
      return {
        ...state,
        businessLinesOptions: payload,
      };
    case SUBREGIONS_REQUEST:
      return {
        ...state,
        subRegionsOptions: payload,
      };
    case SUBREGIONS_SET:
      return {
        ...state,
        subRegionsOptions: payload,
      };
    case SUBREGIONS_CLEAN:
      return {
        ...state,
        subRegionsOptions: payload,
      };
    default:
      return state;
  }
}

export default selectReducers;
