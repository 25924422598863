const downloadPdfUsingString = (pdfString, pdfName) => {
  if (!pdfString) return;

  const downloadAnchor = document.createElement('a');
  downloadAnchor.href = pdfString;
  downloadAnchor.download = `${pdfName ? pdfName : 'undefined'}.pdf`;
  downloadAnchor.target = '_blank';

  downloadAnchor.click();
  downloadAnchor.remove();
  return '';
};

export default downloadPdfUsingString;
