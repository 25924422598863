const datePickerStringToServerString = (datePickerString) => {
  if (datePickerString.indexOf('/') === -1) {
    return datePickerString;
  }
  let splitedDate = datePickerString.split('/');
  let year = splitedDate[2];
  let month = splitedDate[1];
  let day = splitedDate[0];
  return `${year}-${month}-${day}`;
};

export default datePickerStringToServerString;
