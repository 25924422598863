export const filtersEnum = Object.freeze({
  businessLine: {
    label: 'Línea de negocio',
    name: 'lineId',
    placeholder: 'Seleccione una línea de negocio',
    view: 'ViewBusinessLine',
  },
  clients: {
    label: 'Cliente',
    name: 'clientId',
    placeholder: 'Seleccione un cliente',
    view: 'ViewClient',
  },
  region: {
    label: 'Región',
    name: 'regionId',
    placeholder: 'Seleccione una región',
    view: 'ViewRegion',
  },
  subRegion: {
    label: 'Subregión',
    name: 'subRegionId',
    placeholder: 'Seleccione una subregión',
    view: 'ViewSubRegion',
  },
  country: {
    label: 'País',
    name: 'countryId',
    placeholder: 'Seleccione un país',
    view: 'ViewCountry',
  },
  territory: {
    label: 'Territorio',
    name: 'territoryId',
    placeholder: 'Seleccione un territorio',
    view: 'ViewTerritory',
  },
  zones: { label: 'Zona', name: 'zoneId', placeholder: 'Seleccione una zona', view: 'ViewZone' },
  businessRules: {
    label: 'Política comercial-Productos',
    name: 'businessRule',
    placeholder: 'Seleccione política',
    view: 'ViewBusinessRule',
  },
  family: {
    label: 'Familia',
    name: 'family',
    placeholder: 'Seleccione una familia',
    view: 'ViewFamily',
  },
  products: {
    label: 'Producto',
    name: 'productId',
    placeholder: 'Seleccione un producto',
    view: 'ViewProduct',
  },
  years: {
    label: 'Año',
    name: 'year',
    placeholder: 'Seleccione un año',
    view: 'ViewYears',
  },
  brutePrice: {
    label: 'Precio bruto',
    name: 'brutePrice',
    placeholder: 'Seleccione un precio',
  },
  netPrice: {
    label: 'Precio neto',
    name: 'netPrice',
    placeholder: 'Seleccione un precio',
  },
  crop: {
    label: 'AP',
    name: 'crop',
    placeholder: 'Seleccione un AP',
  },
});
