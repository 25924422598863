import { othersSectionActions } from '../../actions';

const {
  OTHERS_CLIENTS_REQUEST,
  GET_OTHERS_CLIENTS,
  OTHERS_CLIENTS_FAIL,
  CLEAN_OTHERS_CLIENTS,
  OTHERS_SUBREGIONS_REQUEST,
  GET_OTHERS_SUBREGIONS,
  OTHERS_SUBREGIONS_FAIL,
  CLEAN_OTHERS_SUBREGIONS,
} = othersSectionActions;

const othersSectionDataReducers = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case OTHERS_CLIENTS_REQUEST:
      return {
        ...state,
        othersClientsData: null,
      };
    case OTHERS_SUBREGIONS_REQUEST:
      return {
        ...state,
        othersSubRegionsData: null,
      };
    case GET_OTHERS_CLIENTS:
      return {
        ...state,
        othersClientsData: payload ?? [],
      };
    case GET_OTHERS_SUBREGIONS:
      return {
        ...state,
        othersSubRegionsData: payload ?? [],
      };
    case OTHERS_CLIENTS_FAIL:
      return {
        ...state,
        otherClientsErrorMsg: payload ?? [],
      };
    case OTHERS_SUBREGIONS_FAIL:
      return {
        ...state,
        otherSubRegionsErrorMsg: payload ?? [],
      };
    case CLEAN_OTHERS_CLIENTS:
      return {
        ...state,
        othersClientsData: [],
      };
    case CLEAN_OTHERS_SUBREGIONS:
      return {
        ...state,
        othersSubRegionsData: [],
      };
    default:
      return state;
  }
};

export default othersSectionDataReducers;
