export default function allProductNumberMap(filter, name, value) {
  const params = {};

  filter.forEach((singleFilter) => {
    const filterKey = Object.keys(singleFilter)[0];
    const filterValue = Object.values(singleFilter)[0];

    if (
      (Array.isArray(filterValue) && filterValue?.length > 0) ||
      typeof filterValue === 'number'
    ) {
      switch (filterKey) {
        case 'politicscp':
          if (filterValue.length !== 2 && filterValue.length !== 0) {
            params.ProductPolicyTypes = filterValue;
          }
          break;
        case 'availavilityproduct':
          if (filterValue.length !== 2 && filterValue.length !== 0) {
            params.ProductAvailabilityType = filterValue;
          }

          break;
        case 'bussinesline':
          params.Lines = filterValue;
          break;
        case 'subregions':
          params.Subregions = filterValue;
          break;
        case 'territory':
          params.Territories = filterValue;
          break;

        default:
          break;
      }
    }
  });

  switch (name) {
    case 'politicscp':
      if (value.length !== 2 && value.length !== 0) {
        params.ProductPolicyTypes = value;
      } else {
        delete params.ProductPolicyTypes;
      }
      break;
    case 'availavilityproduct':
      if (value.length !== 2 && value.length !== 0) {
        params.ProductAvailabilityType = value;
      } else {
        delete params.ProductAvailabilityType;
      }

      break;
    case 'bussinesline':
      params.Lines = value;
      break;
    case 'subregions':
      params.Subregions = value;
      break;
    case 'territory':
      params.Territories = value;
      break;

    default:
      break;
  }
  return params;
}
