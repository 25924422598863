export const Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD = 'Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const Dynamic_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'Dynamic_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY = 'Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const Dynamic_MASSIVE_UPLOAD_SET_VERIFY = 'Dynamic_MASSIVE_UPLOAD_SET_VERIFY';
export const Dynamic_MASSIVE_UPLOAD_CLEAR_VERIFY = 'Dynamic_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD = 'Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const Dynamic_MASSIVE_UPLOAD_SET_UPLOAD = 'Dynamic_MASSIVE_UPLOAD_SET_UPLOAD';
export const Dynamic_MASSIVE_UPLOAD_CLEAR_UPLOAD = 'Dynamic_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export const VALIDATE_ELEMENTS_REQUEST = 'VALIDATE_ELEMENTS_REQUEST';
export const GET_VALIDATE_ELEMENTS = 'GET_VALIDATE_ELEMENTS';
export const VALIDATE_ELEMENTS_FAIL = 'VALIDATE_ELEMENTS_FAIL';
export const CLEAN_VALIDATE_ELEMENTS = 'CLEAN_VALIDATE_ELEMENTS';

export function CreditNotesMassiveUploadSetAllowUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function CreditNotesMassiveUploadClearAllowUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
    payload,
  };
}

export function CreditNotesMassiveUploadRequestVerify(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function CreditNotesMassiveUploadSetVerify(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function CreditNotesMassiveUploadClearVerify(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_CLEAR_VERIFY,
    payload,
  };
}

export function CreditNotesMassiveUploadRequestUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function CreditNotesMassiveUploadSetUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function CreditNotesMassiveUploadClearUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_CLEAR_UPLOAD,
    payload,
  };
}

export function validateElementsRequest(payload) {
  return {
    type: VALIDATE_ELEMENTS_REQUEST,
    payload: payload,
  };
}

export function validateElementsSet(payload) {
  return {
    type: GET_VALIDATE_ELEMENTS,
    payload: payload,
  };
}

export function validateElementsClean(payload) {
  return {
    type: CLEAN_VALIDATE_ELEMENTS,
    payload: payload,
  };
}

export function validateElementsFail(payload) {
  return {
    type: VALIDATE_ELEMENTS_FAIL,
    payload: payload,
  };
}
