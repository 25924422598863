import React from 'react';
import { Row, Col } from 'antd';
import { BInput } from 'common/components';

const Signers = ({ statusSwitch }) => {
  return (
    <>
      <Row>
        <Col span={8}>
          <BInput
            disabled={statusSwitch}
            isRequired
            className="form-add-user-input"
            placeholder="Escribe nombre"
            maxLength={100}
            name="fullNameClient1"
            label="Nombre completo del firmante:"
          />
        </Col>

        <Col span={8}>
          <BInput
            disabled={statusSwitch}
            isRequired
            className="form-add-user-input"
            name="positionClient1"
            maxLength={100}
            placeholder="Escribe posición"
            label="Posición:"
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <BInput
            disabled={statusSwitch}
            className="form-add-user-input"
            name="fullNameClient2"
            maxLength={100}
            placeholder="Escribe nombre"
            label="Nombre completo del firmante:"
          />
        </Col>

        <Col span={8}>
          <BInput
            disabled={statusSwitch}
            className="form-add-user-input"
            name="positionClient2"
            maxLength={100}
            placeholder="Escribe posición"
            label="Posición:"
          />
        </Col>
      </Row>
    </>
  );
};

export default Signers;
