/* eslint-disable no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ValidationHeader from 'Validations/containers/ValidationHeader';
import FiltersGroup from 'Validations/containers/Filters';
import ValidatorsList from 'Validations/containers/ValidatorsList';
import '../css/style.css';
import { connect } from 'react-redux';

import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getRootModule from 'RootModule';
import getValidationsModule from '../getValidationsModule';
import { salesChallengeValidatorsCatalogRequest } from 'RootModule/redux/actions/salesChallengeValidators';
import { tradeAgreementsValidatorsCatalogRequest } from 'RootModule/redux/actions/tradeAgreementsValidators';
import {
  validatorsListSalesChallengeRequest,
  validatorsListTradeAgreementRequest,
} from '../redux-saga/actions/validatorList';
import { addSalesChallengeValidatorRequest } from '../redux-saga/actions/addSalesChallengeValidator';
import { deleteSalesChallengeValidatorRequest } from '../redux-saga/actions/deleteSalesChallengeValidator';
import { addTradeAgreementValidatorRequest } from 'Validations/redux-saga/actions/addTradeAgreementValidator';
import { deleteTradeAgreementValidatorRequest } from 'Validations/redux-saga/actions/deleteTradeAgreementValidator';
import { daysLeftRequest } from 'Validations/redux-saga/actions/daysLeft';

const Validations = (props) => {
  const {
    validatorsListSalesChallengeRequest: getSalesChallengeValidatorList,
    validatorsListTradeAgreementRequest: getTradeAgreementsValidatorsList,
    salesChallengeValidatorsCatalogRequest: getValidators,
    tradeAgreementsValidatorsCatalogRequest: getTradeAgreementsValidatorsCatalog,
    addSalesChallengeValidatorRequest: addSalesChallengeValidator,
    deleteSalesChallengeValidatorRequest: deleteSalesChallengeValidator,
    addTradeAgreementValidatorRequest: addTradeAgreementValidator,
    deleteTradeAgreementValidatorRequest: deleteTradeAgreementValidator,
    daysLeftRequest: getDaysLeft,
    //zoneId,
    clientId,
    territoryId,
    selectedYear,
    salesChallengevalidatorsCatalog,
    tradeAgreementsValidatorsCatalog,
    validationsFields,
    agreementValidatorListLoading,
    challengeValidatorListLoading,
    daysLeft,
  } = props;

  const canModifySalesChallenge = territoryId.length > 0;
  const canModifyTradeAgreement = typeof territoryId === 'number' && clientId.length > 0;

  const handleGetValidatorsCatalog = (params) => {
    getValidators({ params });
  };
  const isVisibleSalesChallenge =
    (validationsFields === 'All' || validationsFields === 'salesChallenge') &&
    canModifySalesChallenge;
  const isVisibleTradeAgreement =
    (validationsFields === 'All' || validationsFields === 'tradeAgreement') &&
    canModifyTradeAgreement;

  const callTradeAgreementList = () => {
    const params = { Territories: [territoryId], clients: clientId };

    getTradeAgreementsValidatorsList({ params });
  };

  const callSalesChallengeList = () => {
    const params = { Territories: territoryId };
    getSalesChallengeValidatorList({ params });
  };

  useEffect(() => {
    if (isVisibleSalesChallenge) {
      callSalesChallengeList();
    }
  }, [isVisibleSalesChallenge, territoryId.length]);

  useEffect(() => {
    if (isVisibleTradeAgreement && !Array.isArray(territoryId) && clientId.length > 0) {
      callTradeAgreementList();
    }
  }, [isVisibleTradeAgreement, territoryId, clientId.length]);

  useEffect(() => {
    if (territoryId !== null && territoryId?.length > 0) {
      let params = {
        Territories: territoryId,
      };
      handleGetValidatorsCatalog(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [territoryId.length, selectedYear]);

  useEffect(() => {
    if (typeof territoryId === 'number') {
      let params = {
        Clients: clientId,
        Territories: [territoryId],
      };
      getTradeAgreementsValidatorsCatalog({ params });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [territoryId, clientId]);

  useEffect(() => {
    getDaysLeft();
  }, []);

  const handleAddSalesChallengeValidator = (id) => {
    let params = {
      //clients: clientId,
      users: id,
      //zones: zoneId,
      territories: territoryId,
    };
    addSalesChallengeValidator(params);
  };

  const handleDeleteSalesChallengeValidator = ({ selectedUser, validatorsToDisable }) => {
    let params = {
      //clients: clientId,
      users: [selectedUser],
      territories: validatorsToDisable,
      //zones: zoneId,
      //validatorsToDisable,
    };
    deleteSalesChallengeValidator(params);
  };

  const handleAddTradeAgreementValidator = (id) => {
    const params = {
      clientId: clientId,
      userId: id,
      territoryId: territoryId,
    };
    addTradeAgreementValidator({ params });
  };
  const handleDeleteTradeAgreementValidator = ({ selectedUser, validatorsToDisable }) => {
    const params = {
      clientId: validatorsToDisable,
      userId: [selectedUser],
      territoryId: territoryId,
    };
    deleteTradeAgreementValidator({ params });
  };

  return (
    <>
      <ValidationHeader />

      <FiltersGroup />

      <section className="validators__holder">
        <ValidatorsList
          options={salesChallengevalidatorsCatalog}
          visible={isVisibleSalesChallenge}
          title={'Agregar validador desafío de ventas'}
          addItem={handleAddSalesChallengeValidator}
          deleteItem={handleDeleteSalesChallengeValidator}
          validatorType={'salesChallenge'}
          canModify={canModifySalesChallenge && !challengeValidatorListLoading && daysLeft > 0}
        />
        <ValidatorsList
          options={tradeAgreementsValidatorsCatalog}
          visible={isVisibleTradeAgreement}
          title={'Agregar validador acuerdo comercial'}
          addItem={handleAddTradeAgreementValidator}
          deleteItem={handleDeleteTradeAgreementValidator}
          validatorType={'tradeAgreement'}
          canModify={canModifyTradeAgreement && !agreementValidatorListLoading && daysLeft > 0}
        />
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    year: state?.VALIDATIONS?.filters?.query?.year ?? '',
    salesChallengevalidatorsCatalog:
      state?.APP?.salesChallengevalidatorsCatalog?.salesChallengeValidators?.data ?? [],
    tradeAgreementsValidatorsCatalog:
      state?.APP?.tradeAgreementsValidatorsCatalog?.tradeAgreementsValidators?.data ?? [],
    validationsFields: state?.VALIDATIONS?.validationFieldsReducer?.validationFields ?? '',
    zoneId: state?.VALIDATIONS?.filters?.query?.zoneId ?? [],
    clientId: state?.VALIDATIONS?.filters?.query?.clientId ?? [],
    territoryId: state?.VALIDATIONS?.filters?.query?.territoryId ?? [],
    agreementValidatorListLoading:
      state?.VALIDATIONS?.addTradeAgreementValidator?.loading ||
      state?.VALIDATIONS?.deleteTradeAgreementValidator?.loading ||
      false,
    challengeValidatorListLoading:
      state?.VALIDATIONS?.addSalesChallengeValidatorReducer?.loading ||
      state?.VALIDATIONS?.deleteSalesChallengeValidatorReducer?.loading ||
      false,
    daysLeft: state?.VALIDATIONS?.daysLeft?.total ?? 0,
  };
};

const ReduxValidations = connect(mapStateToProps, {
  validatorsListSalesChallengeRequest,
  validatorsListTradeAgreementRequest,
  salesChallengeValidatorsCatalogRequest,
  tradeAgreementsValidatorsCatalogRequest,

  addSalesChallengeValidatorRequest,
  deleteSalesChallengeValidatorRequest,

  addTradeAgreementValidatorRequest,
  deleteTradeAgreementValidatorRequest,
  daysLeftRequest,
})(Validations);

const SagasValidations = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getValidationsModule()]}>
      <ReduxValidations />
    </DynamicModuleLoader>
  );
};

export default SagasValidations;
