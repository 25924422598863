export const CLEAN_INFO = 'CLEAN_INFO/SALES-CHALLENGE';

export const INFO_REQUEST = 'INFO_REQUEST/SALES-CHALLENGE';
export const INFO_SUCCESS = 'INFO_SUCCESS/SALES-CHALLENGE';
export const INFO_FAIL = 'INFO_FAIL/SALES-CHALLENGE';

export const KAM_INFO_REQUEST = 'KAM_INFO_REQUEST/SALES-CHALLENGE';
export const KAM_INFO_SUCCESS = 'KAM_INFO_SUCCESS/SALES-CHALLENGE';
export const KAM_INFO_FAIL = 'KAM_INFO_FAIL/SALES-CHALLENGE';

export function infoRequest(payload) {
  return {
    type: INFO_REQUEST,
    payload: payload,
  };
}

export function infoSuccess(payload) {
  return {
    type: INFO_SUCCESS,
    payload: payload,
  };
}

export function infoFail() {
  return {
    type: INFO_FAIL,
  };
}

export function kamInfoRequest(payload) {
  return {
    type: KAM_INFO_REQUEST,
    payload: payload,
  };
}

export function kamInfoSuccess(payload) {
  return {
    type: KAM_INFO_SUCCESS,
    payload: payload,
  };
}

export function kamInfoFail() {
  return {
    type: KAM_INFO_FAIL,
  };
}

export function cleanInfo() {
  return {
    type: CLEAN_INFO,
  };
}
