import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { BusinessLine2, Clients } from 'RootModule/catalogs';

const Client = ({ form }) => {
  const [requestParams, setRequestParams] = useState({
    zoneId: {},
    productNumber: {},
    clientLineId: {},
  });
  const autoSelectOnlyOption = (options) => {
    if (options.length === 1) {
      form.setFieldsValue({ clientLineId: options[0].key });
    }
  };

  const handleLinkedSelect = (linked, value) => {
    if (typeof value !== 'object') {
      form.setFieldsValue({ [linked]: value });
    }
    let dataBL = form.getFieldValue().soldTo;
    setRequestParams({ soldTo: dataBL });
  };
  return (
    <div>
      <Row>
        <Col span={8}>
          <Clients
            label="Sold to"
            isRequired
            optionKey="soldTo"
            valueKey="soldTo"
            labelKey="soldTo"
            sourceId="BySoldTo"
            name="soldTo"
            allowClear={false}
            onChange={(value) => handleLinkedSelect('clientName', value)}
          />
        </Col>
        <Col span={8}>
          <Clients
            label="Nombre"
            isRequired
            optionKey="name"
            valueKey="soldTo"
            labelKey="name"
            sourceId="BySoldTo"
            name="clientName"
            allowClear={false}
            onChange={(value) => handleLinkedSelect('soldTo', value)}
          />
        </Col>
        <Col span={8}>
          <BusinessLine2
            isRequired
            sourceId="Fixed"
            allowClear={false}
            requestParams={{ clientSoldTo: requestParams.soldTo }}
            onSuccess={autoSelectOnlyOption}
            name="clientLineId"
            askFromService={requestParams.soldTo}
            disabled={!requestParams.soldTo}
          />
        </Col>
        <Col span={8}>
          <Clients
            label="Tipo"
            disabled
            optionKey="soldTo"
            valueKey="soldTo"
            labelKey="type"
            sourceId="BySoldTo"
            name="clientName"
            allowClear={false}
            showArrow={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Client;
