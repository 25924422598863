import React from 'react';
import { FormTitleDivider } from 'common';
import { Row, Col } from 'antd';
import { BusinessLine, SubRegion, Country } from 'RootModule/catalogs';

const CommercialStructure = (props) => {
  const {
    gutterValue,
    businessLineId,
    subregionId,
    countryHandleChange,
    countryOnFocus,
    getCurrentOptions,
    businessLineHandleChange,
    subregionHandleChange,
  } = props;

  return (
    <>
      <FormTitleDivider title={'Estructura comercial'} />
      <Row gutter={gutterValue}>
        <Col xs={24} md={8}>
          <BusinessLine
            isRequired
            name="businessLineId"
            className="form-add-user-input"
            label="Línea de negocio"
            askFromService={true}
            sourceId={'Catalogs'}
            onChange={
              typeof businessLineHandleChange === 'function' ? businessLineHandleChange : null
            }
          />
        </Col>
        <Col xs={24} md={8}>
          <SubRegion
            isRequired
            disabled={!businessLineId}
            name="subregionId"
            className="form-add-user-input"
            label={'Subregión'}
            sourceId={'Catalogs'}
            requestParams={businessLineId ? { businessLines: [businessLineId] } : null}
            onChange={typeof subregionHandleChange === 'function' ? subregionHandleChange : null}
          />
        </Col>
        <Col xs={24} md={8}>
          <Country
            mode="multiple"
            disabled={!subregionId || !businessLineId}
            name="countryId"
            className="form-add-user-input"
            label={'País'}
            sourceId={'Subregion'}
            requestParams={
              businessLineId && subregionId
                ? { subregions: subregionId, lines: businessLineId }
                : null
            }
            selectAll={true}
            onSuccess={getCurrentOptions}
            allIsDefault={subregionId ? true : false}
            onChange={countryHandleChange}
            onFocus={countryOnFocus}
            updateTrigger={subregionId}
          />
        </Col>
      </Row>
    </>
  );
};

export default CommercialStructure;
