export const Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD = 'Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const Dynamic_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'Dynamic_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY = 'Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const Dynamic_MASSIVE_UPLOAD_SET_VERIFY = 'Dynamic_MASSIVE_UPLOAD_SET_VERIFY';
export const Dynamic_MASSIVE_UPLOAD_CLEAR_VERIFY = 'Dynamic_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD = 'Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const Dynamic_MASSIVE_UPLOAD_SET_UPLOAD = 'Dynamic_MASSIVE_UPLOAD_SET_UPLOAD';
export const Dynamic_MASSIVE_UPLOAD_CLEAR_UPLOAD = 'Dynamic_MASSIVE_UPLOAD_CLEAR_UPLOAD';
export const SHOPPING_EXIST_STATEMENTS = 'SHOPPING_EXIST_STATEMENTS';

export function ExistsStatements(payload) {
  return {
    type: SHOPPING_EXIST_STATEMENTS,
    payload: payload,
  };
}

export function BaseInformationMassiveUploadSetAllowUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function BaseInformationMassiveUploadClearAllowUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function BaseInformationMassiveUploadRequestVerify(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function BaseInformationMassiveUploadSetVerify(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function BaseInformationMassiveUploadClearVerify(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function BaseInformationMassiveUploadRequestUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function BaseInformationMassiveUploadSetUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function BaseInformationMassiveUploadClearUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
