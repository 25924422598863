import { Tabla1, Tabla2, Tabla3, BSegmentation } from './index';
import { SubRegion, BusinessLine } from 'RootModule/catalogs';
import { formatFunc, parserFunc, keyBlock } from '../utils';
import { Row, Col, Form, InputNumber } from 'antd';
import { FormTitleDivider } from 'common';
import React from 'react';

const CommercialStructure = (props) => {
  const {
    form,
    Switch1,
    setSwitch1,
    setSwitch2,
    Switch2,
    Switch3,
    setSwitch3,
    postOneSegmentation,
    deleteSegment,
    putSegment,
    segmentationFilter,
  } = props;

  return (
    <div>
      <Row>
        <FormTitleDivider title={'Estructura comercial'} />
      </Row>
      <Row>
        {/* Linea de Negocio */}
        <Col span={8}>
          <BusinessLine
            isRequired
            className="form-add-user-input"
            label="Línea de negocio"
            askFromService={true}
          />
        </Col>

        {/* Subregion */}
        <Col span={8}>
          <SubRegion isRequired name="subregionId" />
        </Col>

        {/* Segmentacion CRUD */}
        <Col span={8}>
          <BSegmentation
            isRequired
            postOneSegmentation={postOneSegmentation}
            deleteSegment={deleteSegment}
            putSegment={putSegment}
            name="segmentationId"
            form={form}
            filterOption={segmentationFilter}
          />
        </Col>
      </Row>
      {/* TABLAS */}
      <Tabla1 Switch1={Switch1} setSwitch1={setSwitch1} form={form} />
      <Tabla2 Switch2={Switch2} setSwitch2={setSwitch2} form={form} />
      <Tabla3 Switch3={Switch3} setSwitch3={setSwitch3} form={form} />
      {/* Descuento base */}
      <Row>
        <FormTitleDivider title={'Descuento base'} />
      </Row>
      <Col span={8}>
        <Form.Item name="baseDiscount" label="*Descuento base:">
          <InputNumber
            style={{ border: '1px solid #d9d9d9' }}
            formatter={formatFunc}
            parser={parserFunc}
            max={999.99}
            maxLength={8}
            min={0}
            step="0.01"
            label="Descuento base"
            onKeyDown={keyBlock}
          />
        </Form.Item>
      </Col>
    </div>
  );
};

export default CommercialStructure;
