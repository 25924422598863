import request from 'services/request';
const serviceURL = '/Catalogs/Api/v1/Segmentation';
//AGREGAR SEGMENTACIÓN
export async function postOneSegmentation({ cancelToken, data }) {
  return request(`${serviceURL}`, {
    method: 'POST',
    cancelToken,
    data,
  });
}
//ELIMINAR SEGMENTACIÓN
export async function deleteOneSegmentation({ cancelToken, data }) {
  return request(`${serviceURL}`, {
    method: 'DELETE',
    cancelToken,
    data,
  });
}
//MODIFICAR SEGMENTACIÓN
export async function putOneSegmentation({ cancelToken, data }) {
  return request(`${serviceURL}`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}
//OBTENER CLIENTES
export async function getActiveClients({ cancelToken, params }) {
  return request(`/Catalogs/Api/v1/Clients?Status=true`, {
    method: 'GET',
    cancelToken,
  });
}
//AGREGAR PRODUCTOS POR CLIENTE
export async function postProductClient({ cancelToken, data }) {
  return request(`/Agreements/api/v1/Rules/IncentivesRules/OutPolicyProductClient`, {
    method: 'POST',
    cancelToken,
    data,
  });
}
//Obtiene el Id de cliente en base a la linea de negocio y SoldTo
export async function getClientSingleId({ cancelToken, params }) {
  return request(`/Catalogs/Api/v1/Clients/ClientSingleId`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
//AGREGAR PRODUCTOS POR BL
export async function postProductBL({ cancelToken, data }) {
  return request(`/Agreements/api/v1/Rules/IncentivesRules/OutPolicyProductBL`, {
    method: 'POST',
    cancelToken,
    data,
  });
}
//AGREGAR REGISTRO DE INCENTIVOS
export async function postIncentivesRules({ cancelToken, data }) {
  return request(`/Agreements/api/v1/Rules/IncentivesRules/SaveIncentivesRules`, {
    method: 'POST',
    cancelToken,
    data,
  });
}

//OBTENER PROGRAMA DE INCENTIVOS
export async function getIncentives({ cancelToken, params }) {
  return request(`/Agreements/api/v1/Rules/IncentivesDetail`, {
    method: 'GET',
    cancelToken,
    params,
  });
}

// ##################### POLICY ###################

//Producto fuera de política por linea de negocio
export async function getOutPolicyBL({ cancelToken, params }) {
  return request(`/Agreements/api/v1/Rules/OutPolicyBL`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
//Producto fuera de política por cliente
export async function getOutPolicyClient({ cancelToken, params }) {
  return request(`/Agreements/api/v1/Rules/OutPolicyClient`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
//Obtencion de los productos dentro de política
export async function getInPoliciyProducts({ cancelToken, params }) {
  return request(`/Agreements/api/v1/Rules/IncentivesRules/InPoliciyProducts`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
///Obtencion de los productos fuera de política
export async function getOutPoliciyProducts({ cancelToken, params }) {
  return request(`/Agreements/api/v1/Rules/IncentivesRules/OutPoliciyProducts`, {
    method: 'GET',
    cancelToken,
    params,
  });
}

// ##################### AVAILABILITY ###################
//Obtencion de los productos dentro de política
export async function getOutAvailability({ cancelToken, params }) {
  return request(`/Agreements/api/v1/Rules/OutAvailability`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
///Obtencion de los productos fuera de política
export async function getInAvailability({ cancelToken, params }) {
  return request(`/Agreements/api/v1/Rules/InAvailability`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
// ##################### PUT ###################
//Actualización de incentivos
export async function putIncentivesRules({ cancelToken, data }) {
  return request(`/Agreements/api/v1/Rules/IncentivesRules`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}
///Agreements/api/v1/Rules/OutPolicyProductBL
export async function putOutPolicyProductBL({ cancelToken, data }) {
  return request(`/Agreements/api/v1/Rules/OutPolicyProductBL`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}
//Actualización de productos fuera de politica por cliente
export async function putOutPolicyProductClient({ cancelToken, data }) {
  return request(`/Agreements/api/v1/Rules/OutPolicyProductClient`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}
//Actualización de productos fuera de politica por cliente
export async function putAvailabilityProducts({ cancelToken, data }) {
  return request(`/Agreements/api/v1/Rules/AvailabilityProducts`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

//REVISAR QUE YA EXISTE El REGISTRO
export async function postExistenceIncentives({ cancelToken, data }) {
  return request(
    `/Agreements/api/v1/Rules/CheckExistanceIncentives?LineId=${data.LineId}&SubRegionId=${data.SubRegionId}&SegmentationId=${data.SegmentationId}`,
    {
      method: 'GET',
      cancelToken,
    }
  );
}
