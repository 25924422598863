/* eslint-disable react/display-name */
import { formatCommas } from 'utils';

const consultTableSchema = [
  {
    key: 'soldTo',
    title: 'Sold To',
    dataIndex: 'soldTo',
  },
  {
    key: 'clientName',
    dataIndex: 'clientName',
    title: 'Cliente',
  },
  {
    key: 'line',
    dataIndex: 'line',
    title: 'Línea de negocio',
  },
  {
    key: 'territory',
    dataIndex: 'territory',
    title: 'Territorio',
  },
  {
    key: 'year',
    dataIndex: 'year',
    title: 'Año',
  },
  {
    key: 'month',
    dataIndex: 'month',
    title: 'Mes',
  },
  {
    key: 'productInPolicy',
    dataIndex: 'productInPolicy',
    title: 'POG de productos dentro de política',
    render: (value) => formatCommas(value),
  },
  {
    key: 'productOutPolicy',
    dataIndex: 'productOutPolicy',
    title: 'POG de productos fuera de política',
    render: (value) => formatCommas(value),
  },
  {
    key: 'goal',
    dataIndex: 'goal',
    title: 'Meta',
    render: (value) => formatCommas(value),
  },
  {
    key: 'period',
    dataIndex: 'period',
    title: 'Periodo',
  },
];
export default consultTableSchema;
