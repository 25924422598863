export default function parametersMap(filter) {
  const params = {};

  filter.forEach((singleFilter) => {
    const filterKey = Object.keys(singleFilter)[0];
    const filterValue = Object.values(singleFilter)[0];

    if (
      (Array.isArray(filterValue) && filterValue?.length > 0) ||
      typeof filterValue === 'number'
    ) {
      switch (filterKey) {
        case 'typeunidad':
          params.UnitType = filterValue;
          break;
        case 'periodo':
          params.Timelapse = filterValue;
          break;
        case 'month':
          params.TimelapseDetail = filterValue;
          break;
        case 'year':
          params.Years = filterValue;
          break;
        case 'typeclient':
          params.ClientsType = filterValue;
          break;
        case 'client':
          params.SoldTo = filterValue;
          break;
        case 'segmentation':
          params.Segmentations = filterValue;
          break;
        case 'politicscp':
          params.PolicyType = filterValue;
          break;
        case 'availavilityproduct':
          params.ProductAvailabilityType = filterValue;
          break;
        case 'family':
          params.Families = filterValue;
          break;
        case 'product':
          params.Products = filterValue;
          break;
        case 'typevalue':
          params.ValueType = filterValue;
          break;
        case 'regions':
          params.Regions = filterValue;
          break;
        case 'bussinesline':
          params.Lines = filterValue;
          break;
        case 'subregions':
          params.Subregions = filterValue;
          break;
        case 'territory':
          params.Territories = filterValue;
          break;
        case 'zones':
          params.Zones = filterValue;
          break;
        default:
          break;
      }
    }
  });

  return params;
}
