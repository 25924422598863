import React from 'react';
import { Row, Col, InputNumber, Form } from 'antd';
import { BInput } from 'common/components';

const Prices = ({ handlerOnKeyUp, handlerOnKeyPress, form }) => {
  const formatFunc = (value) => {
    if (value && typeof value === 'string') {
      let format = '';
      let decimal = value.indexOf('.');

      if (decimal > 0) {
        let a = new RegExp(/(^([0-9]*\.[0-9][0-9])$)/gi);
        let onlyTwoDecimals = value.match(a, '$1');

        if (!onlyTwoDecimals) {
          let splitDecimals = value.split('.');
          if (splitDecimals[1]) {
            let sub = splitDecimals[1].substring(0, 2);
            value = `${splitDecimals[0]}.${sub}`;
          }
        }
        if (value.length <= 10) {
          format = `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
          let val = value.substring(0, 10);
          format = `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      } else {
        if (value.length <= 6) {
          format = `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
          let val = value.substring(0, 6);
          format = `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      }
      return format;
    }
  };
  const parserFunc = (value) => {
    let clean = value.replace(/\$\s?|(,*)/g, '');
    if (clean && clean.length > 6) {
      let result = '';
      let decimal = clean.indexOf('.');
      if (decimal > 0 && clean.length <= 10) {
        result = clean.substring(0, 10);
      } else {
        result = clean.substring(0, 6);
      }

      return result;
    } else {
      return clean;
    }
  };
  const keyBlock = (e) => {
    if (e.shiftKey || (e.shiftKey && e.which === 51) || e.key === 'Dead') {
      e.preventDefault();
    }
    if (
      e.keyCode === 69 ||
      e.keyCode === 73 ||
      e.keyCode === 186 ||
      e.keyCode === 187 ||
      e.keyCode === 189 ||
      e.keyCode === 40 ||
      e.keyCode === 107 ||
      e.keyCode === 109 ||
      e.keyCode === 191 ||
      e.keyCode === 192 ||
      e.keyCode === 219 ||
      e.keyCode === 220 ||
      e.keyCode === 221 ||
      e.keyCode === 222 ||
      e.keyCode === 38 ||
      e.keyCode === 40 ||
      e.key === '{' ||
      e.key === '}' ||
      e.key === '+' ||
      e.key === '*' ||
      e.key === '[' ||
      e.key === ']' ||
      e.key === '´' ||
      e.key === '/' ||
      e.key === '<' ||
      e.key === '+' ||
      e.key === '´´' ||
      e.key === 'ArrowLeft' ||
      e.key === 'BracketLeft' ||
      e.key === 'BracketRight' ||
      e.key === 'Quote' ||
      e.key === 'Shift' ||
      e.key === 'Dead' ||
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      e.shiftKey ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowUp'
    ) {
      e.preventDefault();
    }
  };
  return (
    <div>
      <Row>
        <Col span={8}>
          <BInput className="form-add-user-input" name="ap" label="AP" maxlength="100" disabled />
        </Col>
        <Col span={8}>
          <Form.Item name="list" label="*Precio lista">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              formatter={formatFunc}
              parser={parserFunc}
              // onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
              max={999999.99}
              maxLength={13}
              min={0}
              step="0.01"
              label="Precio neto"
              onKeyDown={keyBlock}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="net" label="*Precio neto">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              formatter={formatFunc}
              parser={parserFunc}
              // onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
              max={999999.99}
              maxLength={12}
              min={0}
              step="0.01"
              label="Precio neto"
              stringMode
              onKeyDown={keyBlock}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="brute" label="*Precio bruto">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              formatter={formatFunc}
              parser={parserFunc}
              // onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
              max={999999.99}
              maxLength={12}
              min={0}
              step="0.01"
              label="Precio bruto"
              stringMode
              onKeyDown={keyBlock}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="suggested" label="*Precio sugerido">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              formatter={formatFunc}
              parser={parserFunc}
              // onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
              max={999999.99}
              maxLength={12}
              min={0}
              step="0.01"
              label="Precio sugerido"
              stringMode
              onKeyDown={keyBlock}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="taxes" label="Precio con impuestos">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              formatter={formatFunc}
              parser={parserFunc}
              // onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
              max={999999.99}
              maxLength={12}
              min={0}
              step="0.01"
              label="Precio neto"
              stringMode
              onKeyDown={keyBlock}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Prices;
