import React from 'react';
import { Button, Modal, Form } from 'antd';
import PropTypes from 'prop-types';
import './modal.css';

const TableModal = ({
  children,
  onCancel,
  visible,
  onOk,
  buttonOkDisabled,
  buttonOkLoading,
  setVisible,
  setDataInput,
}) => {
  const [form] = Form.useForm();

  const onReset = () => {
    setDataInput('');
    form.resetFields();
    setVisible(false);
  };

  return (
    <Modal
      width={700}
      footer={[
        <Button
          style={{ backgroundColor: 'rgb(36, 123, 169)', color: 'white' }}
          key="1"
          onClick={onReset}
        >
          Cancelar
        </Button>,
        <Button
          loading={buttonOkLoading}
          disabled={buttonOkDisabled}
          id
          key="2"
          type="primary"
          onClick={() => onOk(form)}
        >
          Aceptar
        </Button>,
      ]}
      visible={visible}
      onCancel={onCancel}
      closable={false}
    >
      <Form form={form}>
        <Form.Item name="name">{children}</Form.Item>
      </Form>
    </Modal>
  );
};

TableModal.propTypes = {
  children: PropTypes.elementType,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  visible: PropTypes.bool,
  buttonOkDisabled: PropTypes.bool,
  buttonOkLoading: PropTypes.bool,
};

TableModal.defaultProps = {
  children: null,
  onCancel: () => {
    return;
  },
  onOk: () => {
    return;
  },
  visible: false,
  buttonOkDisabled: false,
  buttonOkLoading: false,
};

export default TableModal;
