export const SALES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD = 'SALES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const SALES_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD = 'SALES_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const SALES_MASSIVE_UPLOAD_REQUEST_VERIFY = 'SALES_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const SALES_MASSIVE_UPLOAD_SET_VERIFY = 'SALES_MASSIVE_UPLOAD_SET_VERIFY';
export const SALES_MASSIVE_UPLOAD_CLEAR_VERIFY = 'SALES_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const SALES_MASSIVE_UPLOAD_REQUEST_UPLOAD = 'SALES_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const SALES_MASSIVE_UPLOAD_SET_UPLOAD = 'SALES_MASSIVE_UPLOAD_SET_UPLOAD';
export const SALES_MASSIVE_UPLOAD_CLEAR_UPLOAD = 'SALES_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function SalesMassiveUploadSetAllowUpload(payload) {
  return {
    type: SALES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function SalesMassiveUploadClearAllowUpload(payload) {
  return {
    type: SALES_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function SalesMassiveUploadRequestVerify(payload) {
  return {
    type: SALES_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function SalesMassiveUploadSetVerify(payload) {
  return {
    type: SALES_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function SalesMassiveUploadClearVerify(payload) {
  return {
    type: SALES_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function SalesMassiveUploadRequestUpload(payload) {
  return {
    type: SALES_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function SalesMassiveUploadSetUpload(payload) {
  return {
    type: SALES_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function SalesMassiveUploadClearUpload(payload) {
  return {
    type: SALES_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
