import React, { useEffect, useState, useContext } from 'react';
import { Form, Col, Row, Modal } from 'antd';
import { BInput } from 'common/components';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getRootModule from 'RootModule';
import getUserModule from '../userModule';
import { readOneUserRequest, putOneUserRequest, toggleOneUserRequest } from '../redux-saga/actions';
import {
  Region,
  SubRegion,
  Territory,
  Zones,
  Roles,
  BusinessLine,
  Areas,
  Clients,
} from 'RootModule/catalogs';
import { FormTitleDivider, openNotification } from '../../common';
import { ActionButtons } from 'common';
import { useHistory, useParams } from 'react-router-dom';
import { updateUserPassword } from '../../Users/services/updateUser';
import ModalUpdatePassword from '../../Layout/Components/ModalUpdatePassword/ModalUpdatePassword';
import { appContext, layoutContext } from '../../context';

function UserForm(props) {
  const {
    userData,
    readOneUserRequest: getUserData,
    putOneUserRequest: putOneUserReq,
    toggleOneUserRequest: toggleOneUserReq,
  } = props;
  const params = useParams();
  const history = useHistory();
  const { userInfo } = useContext(appContext);
  const userId = params.id ? params.id.slice(1) : '';

  const [formIsDisabled, setFormIsDisabled] = useState(false);
  const [showModalUpdatePassword, setShowModalUpdatePassword] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [completed, setIsCompleted] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(false);

  const { onChangeActiveComponent } = useContext(layoutContext);

  useEffect(() => {
    getUserData({ userId });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [multipleSelect, setMultipleSelect] = useState(true);

  const [requestParamsSubscription, setRequestParamsSubscription] = useState({
    areaId: undefined,
    businessLines: {},
    subRegion: {},
    territories: {},
    zones: {},
    clientsKAM: { placementId: 3 },
  });

  const [disabledFields, setDisbaledFields] = useState({
    region: false,
    businessLines: false,
    subRegion: false,
    territories: false,
    zones: false,
    clientsKAM: false,
  });

  const [statusSwitch, setStatusSwitch] = useState({
    //disabled
    checked: false,
    disabled: false,
  });

  const [allowedRequests, setAllowedRequest] = useState({
    areaId: false,
    roleId: false,
    region: true,
    businessLines: false,
    subRegion: false,
    territories: false,
    zones: false,
    clientsKAM: false,
  });

  const [form] = Form.useForm();

  const resetForRoleChange = () => {
    setRequestParamsSubscription({
      ...requestParamsSubscription,
      subRegion: {},
      territories: {},
      zones: {},
      clientsKAM: { placementId: 3 },
    });
    setAllowedRequest({
      ...allowedRequests,
      businessLines: true,
      subRegion: false,
      territories: false,
      zones: false,
      clientsKAM: false,
    });
    form.setFieldsValue({
      businessLines: undefined,
      subRegion: undefined,
      territories: undefined,
      zones: undefined,
      clientsKAM: undefined,
    });
  };

  const handleRoleChange = (value) => {
    setSelectedProfile(value);
    setDisbaledFields({
      region: false,
      businessLines: false,
      subRegion: true,
      territories: true,
      zones: true,
      clientsKAM: true,
    });
    if (value === 5) {
      resetForRoleChange();
      setMultipleSelect(false);
      //! Cosmetic
      form.setFieldsValue({
        territories: 'Selecciona una opción',
        subRegion: 'Selecciona una opción',
      });
    } else {
      resetForRoleChange();
      setMultipleSelect(true);
    }
    setIsCompleted(checkIfIsCompleted(form.getFieldsValue()));
  };

  useEffect(() => {
    if (userData) {
      setStatusSwitch({
        ...statusSwitch,
        checked: !userData?.enabled,
      });
      setSelectedProfile(userData?.roleId);
      setFormIsDisabled(userData?.enabled ? false : true);
      setMultipleSelect(userData?.roleId === 5 ? false : true);
      const region = userData?.region.length ? userData?.region[0].id : undefined;
      const subRegions = userData?.subRegion.map((sub) => sub.id);
      const businessLines = userData?.bussinesLines.map((line) => line.id);
      const territories = userData?.territories.map((territory) => territory.id);
      const zones = userData?.zones.map((zone) => zone.id);
      const clients = userData?.clients.map((client) => client.clientId);
      setRequestParamsSubscription({
        areaId: userData?.roleId,
        businessLines: {
          regions: region,
        },
        subRegion: {
          regions: region,
          businessLines: businessLines,
        },
        territories: {
          subRegions: subRegions,
          businessLines: businessLines,
        },
        zones: {
          territories: territories,
        },
        clientsKAM: {
          businessLines: businessLines,
          placementId: 3,
        },
      });

      setAllowedRequest({
        areaId: true,
        roleId: true,
        region: true,
        businessLines: true,
        subRegion: true,
        territories: true,
        zones: true,
        clientsKAM: true,
      });

      //Load Initial Safe Values
      form.setFieldsValue({
        email: userData?.email,
        emailLeader: userData?.emailLeader,
        lastName: userData?.lastName,
        name: userData?.name,
        surName: userData?.surName,
        roleId: userData?.roleId,
        region: userData?.region[0]?.id,
        businessLines,
        territories,
        subRegion: subRegions,
        areaId: userData?.areaId,
        zones: zones,
        clientsKAM: clients,
      });
      setIsCompleted(checkIfIsCompleted({ ...userData, businessLines: userData?.bussinesLines }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, form]);

  const checkIfIsCompleted = (values) => {
    const checkMutableValue = (value) => {
      let result;
      if (values?.roleId === 5) {
        result = Boolean(value);
      } else {
        result = Boolean(value?.length > 0);
      }
      return result;
    };
    const completedObject = {
      name: Boolean(values?.name),
      surName: Boolean(values?.surName),
      email: Boolean(values?.email),
      emailLeader: Boolean(values?.emailLeader),
      roleId: Boolean(values?.roleId),
      areaId: values?.roleId === 2 ? Boolean(values?.areaId) : true,
      businessLines: checkMutableValue(values?.businessLines),
      subRegion: checkMutableValue(values?.subRegion),
      territories: checkMutableValue(values?.territories),
      zones: Boolean(values?.zones?.length > 0),
      clientsKAM: selectedProfile === 8 ? Boolean(values?.clientsKAM?.length > 0) : true,
    };

    const isNotCompleted = Object.values(completedObject).includes(false);

    return !isNotCompleted;
  };

  const handleOnFieldsChange = (changedValues) => {
    const allValues = form.getFieldsValue();

    const currentChange = Object.keys(changedValues)[0];
    orchestrateFieldChange(currentChange, allValues);
    setIsCompleted(checkIfIsCompleted(form.getFieldsValue()));
  };

  const orchestrateFieldChange = (currentChange, allValues) => {
    onChangeActiveComponent('Users');
    switch (currentChange) {
      case 'roleId':
        form.setFieldsValue({
          areaId: undefined,
        });
        setRequestParamsSubscription({
          ...requestParamsSubscription,
          areaId: allValues?.roleId,
        });
        setAllowedRequest({
          ...allowedRequests,
          areaId: true,
        });
        break;
      case 'region':
        if (allValues?.region?.length > 0 || typeof allValues?.region === 'number') {
          form.setFieldsValue({
            businessLines: undefined,
            subRegion: undefined,
            territories: undefined,
            zones: undefined,
            clientsKAM: undefined,
          });
          setRequestParamsSubscription({
            ...requestParamsSubscription,
            businessLines: {
              ...requestParamsSubscription.businessLines,
              regions: allValues?.region,
            },
            subRegion: {
              ...requestParamsSubscription.subregion,
              regions: allValues?.region,
            },
            territories: {
              ...requestParamsSubscription.territories,
              regions: allValues?.region,
            },
          });
          setAllowedRequest({
            ...allowedRequests,
            region: false,
            businessLines: true,
            subRegion: false,
            territories: false,
            zones: false,
            clientsKAM: false,
          });
        }

        break;

      case 'businessLines':
        form.setFieldsValue({
          subRegion: undefined,
          territories: undefined,
          zones: undefined,
          clientsKAM: undefined,
        });
        if (allValues?.businessLines?.length > 0 || typeof allValues?.businessLines === 'number') {
          setRequestParamsSubscription({
            ...requestParamsSubscription,
            subRegion: {
              ...requestParamsSubscription.subRegion,
              businessLines: cleanAllOption(allValues.businessLines),
            },
            territories: {
              ...requestParamsSubscription.territories,
              businessLines: cleanAllOption(allValues?.businessLines),
            },
            clientsKAM: {
              ...requestParamsSubscription.clientsKAM,
              businessLines: cleanAllOption(allValues?.businessLines),
            },
          });
          setAllowedRequest({
            ...allowedRequests,
            region: false,
            businessLines: false,
            subRegion: true,
            territories: false,
            zones: false,
            clientsKAM: false,
          });
          setDisbaledFields({
            region: false,
            businessLines: false,
            subRegion: false,
            territories: true,
            zones: true,
            clientsKAM: true,
          });
        } else {
          setDisbaledFields({
            region: false,
            businessLines: false,
            subRegion: true,
            territories: true,
            zones: true,
            clientsKAM: true,
          });
        }
        break;

      case 'subRegion':
        form.setFieldsValue({
          territories: undefined,
          zones: undefined,
          clientsKAM: undefined,
        });
        if (allValues?.subRegion?.length > 0 || typeof allValues?.subRegion === 'number') {
          setRequestParamsSubscription({
            ...requestParamsSubscription,
            territories: {
              ...requestParamsSubscription.territories,
              subRegions: cleanAllOption(allValues.subRegion),
            },
          });
          setAllowedRequest({
            ...allowedRequests,
            region: false,
            businessLines: false,
            subRegion: false,
            territories: true,
            zones: false,
            clientsKAM: false,
          });
          setDisbaledFields({
            region: false,
            businessLines: false,
            subRegion: false,
            territories: false,
            zones: true,
            clientsKAM: true,
          });
        } else {
          setDisbaledFields({
            region: false,
            businessLines: false,
            subRegion: false,
            territories: true,
            zones: true,
            clientsKAM: true,
          });
        }

        break;

      case 'territories':
        form.setFieldsValue({
          zones: undefined,
          clientsKAM: undefined,
        });

        if (allValues?.territories?.length > 0 || typeof allValues?.territories === 'number') {
          setRequestParamsSubscription({
            ...requestParamsSubscription,
            zones: {
              ...requestParamsSubscription.zones,
              territories: cleanAllOption(allValues.territories),
            },
          });
          setAllowedRequest({
            ...allowedRequests,
            region: false,
            businessLines: false,
            subRegion: false,
            territories: false,
            zones: true,
            clientsKAM: false,
          });
          setDisbaledFields({
            region: false,
            businessLines: false,
            subRegion: false,
            territories: false,
            zones: false,
            clientsKAM: true,
          });
        } else {
          setDisbaledFields({
            region: false,
            businessLines: false,
            subRegion: false,
            territories: false,
            zones: true,
            clientsKAM: true,
          });
        }

        break;

      case 'zones':
        form.setFieldsValue({
          clientsKAM: undefined,
        });
        if (allValues?.zones?.length > 0 || typeof allValues?.zones === 'number') {
          setAllowedRequest({
            ...allowedRequests,
            region: false,
            businessLines: false,
            subRegion: false,
            territories: false,
            zones: false,
            clientsKAM: true,
          });
          setDisbaledFields({
            region: false,
            businessLines: false,
            subRegion: false,
            territories: false,
            zones: false,
            clientsKAM: false,
          });
        }
        break;

      default:
        break;
    }
  };

  const cleanAllOption = (array = []) => {
    if (array?.length) {
      return array.filter((item) => item !== 'ALL');
    }
    return [array];
  };

  const onSubmit = () => {
    const values = form.getFieldsValue();

    putOneUserReq({ values: { ...values, clientsKAM: values?.clientsKAM ?? [] }, userId, history });
  };
  const onCancel = () => {
    history.push('/users');
  };

  const handleStatusChange = (value) => {
    const actions = {
      true: 'desactivar',
      false: 'activar',
    };
    const message = {
      default: `¿Esta seguro que desea ${actions[value]} el usuario?`,
      representative: `Este usuario se encuentra asociado como Representante clave de la cuenta,
       para un  cliente registrado en el sistema. ¿Esta seguro que desea desactivarlo?`,
    };

    Modal.confirm({
      title: 'Confirmar acción',
      content:
        value === true && userData?.isSalesRepresentative && userData?.numberClientsSalesRep > 0
          ? message.representative
          : message.default,
      onOk() {
        setStatusSwitch({ ...statusSwitch, checked: value });
        toggleOneUserReq({ userId, enabled: !value });
        setFormIsDisabled(value);
      },
    });
  };

  const handleStatusUpdate = () => {
    // updateStatus(scope.id, !statusSwitch.checked);
  };

  const handleSelectChange = (value, { name, options }) => {
    if (value?.length) {
      if (value.includes('ALL')) {
        if (value.length === options.length + 1) {
          form.setFieldsValue({ [name]: [] });
        } else {
          form.setFieldsValue({ [name]: options.map((option) => option.key) });
        }

        orchestrateFieldChange(name, form.getFieldsValue());
      }
    }
  };

  const handleSelectChangeClients = (value, { name, options }) => {
    if (value.includes('ALL')) {
      form.setFieldsValue({ [name]: options.map((option) => option.clienteId) });
    }
  };

  const hideModal = (values) => {
    setModalLoader(true);
    setShowModalUpdatePassword(false);
    setModalLoader(false);
    updateUserPassword(values, userId)
      .then((message) => {
        setModalLoader(false);
        setShowModalUpdatePassword(false);
        openNotification('success', message);
      })
      .catch((err) => {
        setModalLoader(false);
        setShowModalUpdatePassword(false);
      });
  };

  return (
    <>
      <div className={'module-card'}>
        <Form form={form} onValuesChange={handleOnFieldsChange}>
          <Row>
            <FormTitleDivider title={'Información del usuario'} />
          </Row>
          <Row>
            <Col span={7}>
              <BInput
                disabled={formIsDisabled}
                isRequired
                className="form-add-user-input"
                name="name"
                label="Nombre(s)"
                placeholder={userData?.enabled && 'Escribir primer nombres(s)'}
              />
            </Col>
            <Col span={7}>
              <BInput
                disabled={formIsDisabled}
                isRequired
                className="form-add-user-input"
                name="surName"
                label="Primer apellido"
                placeholder={userData?.enabled && 'Escribir primer apellido'}
              />
            </Col>

            <Col span={7}>
              <BInput
                disabled={formIsDisabled}
                className="form-add-user-input"
                name="lastName"
                label="Segundo apellido"
                placeholder={!statusSwitch.checked ? 'Escribir segundo apellido' : ''}
              />
            </Col>

            <Col span={7}>
              <BInput
                isRequired
                disabled={formIsDisabled}
                className="form-add-user-input"
                name="email"
                label="Correo electrónico:"
                rules={[{ type: 'email', message: 'usuario@dominio.extensión' }]}
              />
            </Col>

            <Col span={7}>
              <BInput
                disabled={formIsDisabled}
                isRequired
                className="form-add-user-input"
                name="emailLeader"
                label="Correo electrónico de líder:"
                rules={[{ type: 'email', message: 'usuario@dominio.extensión' }]}
              />
            </Col>

            <Col span={7}>
              <Roles
                disabled={formIsDisabled}
                isRequired
                className="form-add-user-input"
                name="roleId"
                label="Perfil"
                onChange={handleRoleChange}
                allowClear={false}
              />
            </Col>

            <Col span={7}>
              <Areas
                isRequired
                className="form-add-user-input"
                name="areaId"
                label="Área"
                disabled={formIsDisabled || selectedProfile !== 2}
                requestParams={requestParamsSubscription?.areaId}
              />
            </Col>
          </Row>
          <Row>
            <FormTitleDivider title={'Estructura comercial'} />
          </Row>
          <Row>
            <Col span={7}>
              <Region
                isRequired
                disabled={formIsDisabled || disabledFields.region}
                name="region"
                requestParams={requestParamsSubscription.regions}
                askFromService={allowedRequests.region}
                sourceId="Fixed"
                allowClear={false}
              />
            </Col>

            <Col span={7}>
              <BusinessLine
                isRequired
                disabled={formIsDisabled || disabledFields.businessLines}
                name="businessLines"
                label="Línea de negocio"
                mode={multipleSelect && 'multiple'}
                requestMode="multiple"
                requestParams={requestParamsSubscription.businessLines}
                askFromService={allowedRequests.businessLines}
                sourceId="Fixed"
                allowClear={false}
                maxTagCount={1}
                selectAll={multipleSelect}
                onChange={handleSelectChange}
              />
            </Col>

            <Col span={7}>
              <SubRegion
                disabled={formIsDisabled || disabledFields.subRegion}
                isRequired
                name="subRegion"
                mode={multipleSelect ? 'multiple' : ''}
                requestMode="multiple"
                requestParams={requestParamsSubscription.subRegion}
                askFromService={allowedRequests.subRegion}
                sourceId="Fixed"
                allowClear={false}
                maxTagCount={1}
                selectAll={multipleSelect}
                onChange={handleSelectChange}
              />
            </Col>

            <Col span={7}>
              <Territory
                disabled={formIsDisabled || disabledFields.territories}
                isRequired
                mode={multipleSelect ? 'multiple' : ''}
                requestMode="multiple"
                requestParams={requestParamsSubscription.territories}
                askFromService={allowedRequests.territories}
                sourceId="Fixed"
                name="territories"
                allowClear={false}
                maxTagCount={1}
                selectAll={multipleSelect}
                onChange={handleSelectChange}
              />
            </Col>

            <Col span={7}>
              <Zones
                disabled={formIsDisabled || disabledFields.zones}
                isRequired
                mode="multiple"
                requestParams={requestParamsSubscription.zones}
                askFromService={allowedRequests.zones}
                sourceId="Fixed"
                name="zones"
                allowClear={false}
                maxTagCount={1}
                selectAll
                onChange={handleSelectChange}
              />
            </Col>
          </Row>

          <Row>
            <FormTitleDivider title="Cliente" />
          </Row>

          <Row style={{ justifyContent: 'space-between' }}>
            <Col span={7}>
              <Clients
                placeholder={
                  userData?.enabled && (userData?.areaId === 8 || selectedProfile === 8)
                    ? 'Seleccionar'
                    : ''
                }
                disabled={formIsDisabled || selectedProfile !== 8 || disabledFields.clientsKAM}
                sourceId="Placement"
                isRequired
                name="clientsKAM"
                label="Cliente(s)"
                mode="multiple"
                askFromService={selectedProfile === 8 || allowedRequests.clientsKAM}
                optionKey="clienteId"
                labelKey="name"
                valueKey="clienteId"
                selectAll
                onChange={handleSelectChangeClients}
                maxTagCount={1}
                requestParams={requestParamsSubscription.clientsKAM}
              />
            </Col>
            {userInfo.profile === 'Administrador' && (
              <Col span={3}>
                <button
                  id="updateButton"
                  onClick={() => {
                    setShowModalUpdatePassword(true);
                  }}
                  style={{
                    border: '2px solid rgba(0,0,0,.5)',
                    borderRadius: '4px',
                    padding: '7px 14px',
                    marginTop: '30px',
                    cursor: 'pointer',
                  }}
                >
                  Actualizar Contraseña
                </button>
              </Col>
            )}
          </Row>
        </Form>
      </div>
      <ActionButtons
        onCancel={onCancel}
        onSubmit={onSubmit}
        statusSwitch={statusSwitch}
        onStatusUpdate={handleStatusUpdate}
        statusChange={handleStatusChange}
        disabled={!completed || statusSwitch.checked}
      />
      <ModalUpdatePassword
        showModalUpdatePassword={showModalUpdatePassword}
        hideModal={hideModal}
        loaderState={modalLoader}
        requiredPrevPass={false}
      />
    </>
  );
}

function mapStateToProps(state) {
  return { userData: state?.USER?.users?.data };
}

const ConnectedUserForm = connect(mapStateToProps, {
  readOneUserRequest,
  putOneUserRequest,
  toggleOneUserRequest,
})(UserForm);

const UpdateUser = () => (
  <DynamicModuleLoader modules={[getRootModule(), getUserModule()]}>
    <ConnectedUserForm />
  </DynamicModuleLoader>
);
export default UpdateUser;
