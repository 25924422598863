import { getDatesNumber } from './index';
const checkIfSendMessageByDate = (firstDateString, secondDateString) => {
  if (!firstDateString || !secondDateString) {
    return false;
  }
  let { firstDate, secondDate } = getDatesNumber(firstDateString, secondDateString);

  return firstDate > secondDate;
};

export default checkIfSendMessageByDate;
