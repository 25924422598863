export const CLEAN_INDICATORS = 'TRADE-AGREEMENTS/CLEAN_INDICATORS';

export const INDICATORS_REQUEST = 'TRADE-AGREEMENTS/INDICATORS_REQUEST';
export const INDICATORS_SUCCESS = 'TRADE-AGREEMENTS/INDICATORS_SUCCESS';
export const INDICATORS_FAIL = 'TRADE-AGREEMENTS/INDICATORS_FAIL';
export const INDICATORS_SET = 'INDICATORS_SET/TRADE-AGREEMENTS';
export function indicatorsRequest(payload) {
  return {
    type: INDICATORS_REQUEST,
    payload: payload,
  };
}

export function indicatorsSuccess(payload) {
  return {
    type: INDICATORS_SUCCESS,
    payload: payload,
  };
}

export function indicatorsFail() {
  return {
    type: INDICATORS_SUCCESS,
  };
}

export function indicatorsSet(payload) {
  return {
    type: INDICATORS_SET,
    payload,
  };
}

export function cleanIndicators() {
  return {
    type: CLEAN_INDICATORS,
  };
}
