import React from 'react';
import { Row, Col } from 'antd';
import { BusinessLine, Clients } from 'RootModule/catalogs';

const Client = ({ form, detailPricesData, getOneClient }) => {
  const autoSelectOnlyOption = (options) => {
    if (options.length === 1) {
      form.setFieldsValue({ clientLineId: options[0].key });
    }
  };

  const handleLinkedSelect = (linked, value) => {
    if (typeof value !== 'object') {
      form.setFieldsValue({ [linked]: value });
    }
  };

  return (
    <div>
      <Row>
        <Col span={6}>
          <Clients
            label="*Sold to"
            optionKey="soldTo"
            valueKey="soldTo"
            labelKey="soldTo"
            sourceId="BySoldTo"
            name="soldTo"
            allowClear={false}
            onChange={(value) => handleLinkedSelect('clientName', value)}
            disabled={true}
            placeholder={getOneClient?.soldTo}
          />
        </Col>
        <Col span={12}>
          <Clients
            label="*Nombre"
            optionKey="name"
            valueKey="soldTo"
            labelKey="name"
            sourceId="BySoldTo"
            name="clientName"
            allowClear={false}
            onChange={(value) => handleLinkedSelect('soldTo', value)}
            disabled={true}
            placeholder={getOneClient?.name}
          />
        </Col>
        <Col span={6}>
          <BusinessLine
            label="*Línea de Negocio"
            sourceId="Fixed"
            allowClear={false}
            requestParams={{ clientSoldTo: detailPricesData?.soldTo }}
            onSuccess={autoSelectOnlyOption}
            name="clientLineId"
            askFromService={detailPricesData?.soldTo}
            disabled
          />
        </Col>
      </Row>
    </div>
  );
};

export default Client;
