const API_REPORTS = process.env.REACT_APP_API_REPORTS;

const ApiConstants = {
  GetListManualStatement: '/AccountStatement/Api/v1/ManualStatementSend/GetListManualStatement',
  CheckExistences: '/AccountStatement/Api/v1/ManualStatementSend/CheckExistence',
  urlReport: `${API_REPORTS}Reports/Api/v1/AccountStatement/AccountStatement`,
  sendAccountStatement: '/AccountStatement/Api/v1/ManualStatementSend/SendManualStatement',
};

export default ApiConstants;
