import React from 'react';
import { formatPriceWithDecimals } from 'SalesChallengeRefactor/utils';
import './styles.css';
import Proptypes from 'prop-types';

function Stadistic(props) {
  const { label, value } = props;
  return (
    <div className="stadistics-column">
      <span className="stadistics-label">{label}</span>
      <div className="stadistics-value">
        <span> {typeof value === 'number' ? formatPriceWithDecimals(value) : `$ ${value}`} </span>
      </div>
    </div>
  );
}

Stadistic.propTypes = {
  label: Proptypes.string,
  value: Proptypes.number,
};

Stadistic.defaultProps = {
  label: 'Ventas USD',
  value: 0,
};

export default Stadistic;
