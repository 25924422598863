import React from 'react';
import { FormTitleDivider } from 'common';
import { Row, Col } from 'antd';
import { Currency } from 'RootModule/catalogs';
import NumberInput from 'Products/ProductRegistration/components/NumberInput';
import getFloatParser from 'utils/getFloatParser';

const ProductTaxes = ({
  gutterValue,

  disabled,
}) => {
  const formatter = (val) => {
    if (val.length === 0) {
      return ``;
    }
    return `${val}%`;
  };
  return (
    <>
      <FormTitleDivider title={'Información de Impuestos'} />
      <Row gutter={gutterValue}>
        <Col xs={24} md={6}>
          <Currency
            isRequired
            name="currencyId"
            className="form-add-user-input"
            label={'Moneda'}
            disabled={disabled}
          />
        </Col>
        <Col xs={24} md={6}>
          <NumberInput
            label="IVA"
            name="iva"
            max={99.99}
            min={0}
            step="0.01"
            disabled={disabled}
            formatter={formatter}
            parser={getFloatParser({ integerLength: 2, floatLength: 2, replaceParams: [/%/g, ''] })}
          />
        </Col>

        <Col xs={24} md={6}>
          <NumberInput
            label="IEPS"
            name="ieps"
            max={99.99}
            min={0}
            step="0.01"
            disabled={disabled}
            formatter={formatter}
            parser={getFloatParser({ integerLength: 2, floatLength: 2, replaceParams: [/%/g, ''] })}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProductTaxes;
