import React, { useState } from 'react';
import { Layout as Alayout } from 'antd';
import './layout.css';
import Sidenav from './sidenav';
import Navbar from './navbar';
import { layoutContext } from '../context';
import { useLocation } from 'react-router-dom';
const { Content, Header, Sider } = Alayout;

function Layout({ children }) {
  const [activeComponent, setActiveComponent] = useState(undefined);
  const [collapsed, setCollapse] = useState(true);
  const { pathname } = useLocation();

  return pathname === '/preview' ? (
    <>
      <layoutContext.Provider
        value={{
          activeComponent: activeComponent,
          onChangeActiveComponent: (value) => setActiveComponent(value),
        }}
      >
        <Alayout>
          <Content>
            <div style={{ padding: '15px 20px 15px 58px' }}>{children}</div>
          </Content>
        </Alayout>
      </layoutContext.Provider>
    </>
  ) : (
    <>
      <layoutContext.Provider
        value={{
          activeComponent: activeComponent,
          onChangeActiveComponent: (value) => setActiveComponent(value),
        }}
      >
        <Alayout style={{ height: '100vh' }}>
          <Header>
            <Navbar />
          </Header>
          <Alayout>
            <div className="position-sidebar">
              <Sider
                trigger={null}
                collapsible
                style={{ backgroundColor: '#00499a', height: '100%' }}
                width={collapsed ? 43 : 180}
              >
                <Sidenav
                  collapsed={collapsed}
                  setCollapse={setCollapse}
                  onChange={() => setCollapse(!collapsed)}
                />
              </Sider>
            </div>
            <Alayout>
              <Content>
                <div id={'children-wrapper'} style={{ padding: '15px 20px 15px 58px' }}>
                  {children}
                </div>
              </Content>
            </Alayout>
          </Alayout>
        </Alayout>
      </layoutContext.Provider>
    </>
  );
}
export default Layout;
