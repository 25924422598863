import { openNotification } from 'common';

const ERROR = 'error';
const SUCCESS = 'success';
const WARNING = 'warning';
const INFO = 'info';

//

export function message001(actionName) {
  openNotification(SUCCESS, `${actionName} se realizó correctamente.`);
}

export function message002() {
  openNotification(
    ERROR,
    `El sistema no pudo realizar la operación, inténtelo de nuevo mas tarde.`
  );
}
export function message004() {
  openNotification(WARNING, 'El campo tiene un formato inválido.');
}
export function message046() {
  openNotification(
    WARNING,
    'La información ingresada para el producto ya se encuentra registrada en el sistema.'
  );
}
export function message094() {
  openNotification(
    WARNING,
    'La fecha de inicio de vigencia no debe ser posterior a la fecha fin de vigencia.'
  );
}
export function message119() {
  openNotification(
    INFO,
    'El cambio de nombre del producto se realizará para todos los paises asociados.'
  );
}
export function messageSuccess(message) {
  openNotification(SUCCESS, `${message}`);
}
export function messageError(message) {
  openNotification(ERROR, `${message}`);
}
