import {
  GET_FAMILY_INFORMATION_REQUEST,
  getFamilyInformationSuccess,
  getFamilyInformationFail,
} from 'SalesChallengeRefactor/redux/actions/getFamilyInformation';
import { takeLatest, cancelled, put, call, select } from 'redux-saga/effects';
import { responseOK } from 'SalesChallengeRefactor/utils';
import instance from 'services/request';
import { getInfo } from 'SalesChallengeRefactor/services/info';

function* workGetFamilyInformationRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const query = yield select((state) => state?.SALES_CHALLENGE?.query);
  const { zoneId, clientId, client, year, family } = query;
  /*{
    "state": "changed",
    "showBruteValue": true,
    "year": 2024,
    "regionId": 1,
    "lineId": 1,
    "subRegionId": 1,
    "territoryId": 1,
    "zoneId": 1,
    "clientId": "6030ea5c-fe3f-401e-83a7-4560c7105df2",
    "family": "LEGASUS 600 WG"
  }*/

  /* query en registrar {
    "state": "changed",
    "showBruteValue": true,
    "year": 2024,
    "regionId": 1,
    "lineId": 1,
    "subRegionId": 1,
    "territoryId": 1,
    "zoneId": 3,
    "family": "ACROBAT CT 600 SC",
    "years": 2024,
    "zone": 3,
    "client": "914ac1a0-9c24-4692-840e-81b11c244c0e",
    "product": "c78aad86-9867-469d-91e4-3d772e30e14b"
} */

  let moreThanTwoProducts = false;

  try {
    const familyParams = {
      zoneId,
      clientId: clientId ? clientId : client,
      year,
      family,
    };

    const clientsParams = {
      zoneId,
      clientId: clientId ? clientId : client,
      year,
    };

    const zoneParams = {
      zoneId,
      year,
    };

    const familiesResponse = yield call(getInfo, 'Family', familyParams, source.token);

    if (responseOK(familiesResponse)) {
      const totalProductsInFamily = familiesResponse.data.filter((item) => item.volume > 0).length;
      if (totalProductsInFamily > 1) {
        moreThanTwoProducts = true;
      }
    }

    if (!moreThanTwoProducts) {
      const clientsResponse = yield call(getInfo, 'Client', clientsParams, source.token);

      if (responseOK(clientsResponse)) {
        const totalFamiliesInClients = clientsResponse.data.filter(
          (item) => item.volume > 0
        ).length;
        if (totalFamiliesInClients > 1) {
          moreThanTwoProducts = true;
        }
      }
    }

    if (!moreThanTwoProducts) {
      const zoneResponse = yield call(getInfo, 'Zone', zoneParams, source.token);

      if (responseOK(zoneResponse)) {
        const totalClientsInZone = zoneResponse.data.filter((item) => item.volume > 0).length;
        if (totalClientsInZone > 1) {
          moreThanTwoProducts = true;
        }
      }
    }

    yield put(getFamilyInformationSuccess(moreThanTwoProducts ? 2 : 1));

    /* const { payload } = action;
    const { params } = payload;
    const productsFromFamilies = [];

    const zoneParams = {
      zoneId: params.zoneId,
      year: params.year,
    };

    const zoneResponse = yield call(getInfo, 'Zone', zoneParams, source.token);
    let clientsFromZone = [];
    let familiesFromClient = [];

    if (responseOK(zoneResponse)) {
      zoneResponse.data
        .filter((item) => item.volume > 0)
        .forEach((client) => clientsFromZone.push(client.clientId));
    }

    for (let clientIds = 0; clientIds < clientsFromZone.length; clientIds++) {
      const clientsParams = {
        zoneId: params.zoneId,
        clientId: clientsFromZone[clientIds],
        year: params.year,
      };
      const clientsResponse = yield call(getInfo, 'Client', clientsParams, source.token);
      if (responseOK(clientsResponse)) {
        clientsResponse.data
          .filter((item) => item.volume > 0)
          .forEach((family) =>
            familiesFromClient.push({ ...family, clientId: clientsParams.clientId })
          );
      }
    }

    for (let familyNames = 0; familyNames < familiesFromClient.length; familyNames++) {
      const familyParams = {
        zoneId: params.zoneId,
        clientId: familiesFromClient[familyNames].clientId,
        year: params.year,
        family: familiesFromClient[familyNames].family,
      };
      const familiesResponse = yield call(getInfo, 'Family', familyParams, source.token);
      if (responseOK(familiesResponse)) {
        familiesResponse.data
          .filter((item) => item.volume > 0)
          .forEach((product) => productsFromFamilies.push(product));
      }
    }
    if (productsFromFamilies.length > -1) {
      yield put(getFamilyInformationSuccess(productsFromFamilies.length));
    } else {
      yield put(getFamilyInformationFail());
    } */
  } catch (error) {
    yield put(getFamilyInformationFail());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchGetFamilyInformationRequest() {
  yield takeLatest(GET_FAMILY_INFORMATION_REQUEST, workGetFamilyInformationRequest);
}
