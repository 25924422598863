export const responseOK = (response) => {
  return typeof response !== 'undefined' && typeof response.data !== 'undefined';
};

export const filterObject = (object, fnFilter) => {
  if (typeof object !== 'undefined') {
    let objReturn = {};
    Object.keys(object).forEach((key) => {
      if (fnFilter(object[key])) {
        objReturn = {
          ...objReturn,
          [key]: object[key],
        };
      }
    });
    return objReturn;
  }
};

export const capitalizeString = (string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatToPrice = (value) => {
  let format = '';
  const exp = new RegExp(/(\d)(?=(\d\d\d)+(?!\d))/gi);

  if (typeof value !== 'undefined' && value !== null) {
    if (typeof value === 'number') {
      format = parseFloat(Math.round(value * 100) / 100).toFixed(2);
      format = `$ ${format}`.replace(exp, '$1,');
    } else {
      format = `$ ${value}`.replace(exp, '$1,');
    }
  }

  return format;
};

export const formatNumber = (value) => {
  let format = '';
  const exp = new RegExp(/(\d)(?=(\d\d\d)+(?!\d))/gi);
  if (typeof value !== 'undefined' && value !== null) {
    format = `${value}`.replace(exp, '$1,');
  }
  return format;
};

export const formatPriceWithDecimals = (number, removeSign) => {
  if (typeof number !== 'undefined' && typeof number === 'number' && !isNaN(number)) {
    if (removeSign) {
      let a = formatToPrice(number.toFixed(2)).toLocaleString();

      return a.substring(2, a.length);
    } else {
      return formatToPrice(number.toFixed(2)).toLocaleString();
    }
  }
  return false;
};

export const onlyBase64 = (string) => {
  if (string && string.length > 0) {
    let file64 = string.split('data:application/pdf;base64,');
    if (file64.length > 0 && file64[1]) {
      return file64?.[1];
    }
  }
  return false;
};

export { default as getPageType } from './getPageType';
