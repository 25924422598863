import React from 'react';
import { Row, Col, Divider } from 'antd';
import PropTypes from 'prop-types';
import './formTitleDivider.css';

const BDivider = ({ title, style }) => (
  <Row>
    <Col span={24}>
      <Divider orientation="left" style={style} className="BDivider">
        {title}
      </Divider>
    </Col>
  </Row>
);

BDivider.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
};

BDivider.defaultProps = {
  title: 'BDivider_Insert Title ',
  style: {
    fontSize: '17px',
    color: 'rgb(33,160,210)',
    borderTopColor: 'rgb(33,160,210)',
    fontWeight: '500',
  },
};

export default BDivider;
