import reducer from './redux-saga/reducers/';

import {
  watchBusinessStructureMassiveUploadSetAllowUpload,
  watchBusinessStructureMassiveUploadRequestVerify,
  watchBusinessStructureMassiveUploadRequestUpload,
} from './redux-saga/sagas';

export default function getBusinessStructureMassiveUploadModule() {
  return {
    id: 'BUSINESS_STRUCTURE_MASSIVE_UPLOAD',
    reducerMap: {
      BUSINESS_STRUCTURE_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchBusinessStructureMassiveUploadSetAllowUpload,
      watchBusinessStructureMassiveUploadRequestVerify,
      watchBusinessStructureMassiveUploadRequestUpload,
    ],
  };
}
