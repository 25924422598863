import React from 'react';
import { Row, Col, Input, Form } from 'antd';

const OthersSections = ({ getOtherSection }) => {
  const { TextArea } = Input;
  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item name="header" label="Encabezado:">
            <TextArea
              placeholder={getOtherSection?.header}
              defaultValue={getOtherSection?.header}
              disabled={true}
              autoSize
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="comments" label="Comentarios:">
            <TextArea
              placeholder={getOtherSection?.comments}
              defaultValue={getOtherSection?.comments}
              disabled={true}
              autoSize
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default OthersSections;
