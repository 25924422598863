export const CLEAN_FILTERS = 'TRADE-AGREEMENTS/CLEAN_FILTERS';

export const REGIONS_REQUEST = 'TRADE-AGREEMENTS/REGIONS_REQUEST';
export const REGIONS_SUCCESS = 'TRADE-AGREEMENTS/REGIONS_SUCCESS';
export const REGIONS_FAIL = 'TRADE-AGREEMENTS/SUBREGIONS_FAIL';

export function regionRequest(payload) {
  return {
    type: REGIONS_REQUEST,
    payload: payload,
  };
}

export function regionSuccess(payload) {
  return {
    type: REGIONS_SUCCESS,
    payload: payload,
  };
}

export function regionFail() {
  return {
    type: REGIONS_FAIL,
  };
}

export const SUBREGIONS_REQUEST = 'TRADE-AGREEMENTS/SUBREGIONS_REQUEST';
export const SUBREGIONS_SUCCESS = 'TRADE-AGREEMENTS/SUBREGIONS_SUCCESS';
export const SUBREGIONS_FAIL = 'TRADE-AGREEMENTS/SUBREGIONS__FAIL';

export function subregionsRequest(payload) {
  return {
    type: SUBREGIONS_REQUEST,
    payload: payload,
  };
}

export function subregionsSuccess(payload) {
  return {
    type: SUBREGIONS_SUCCESS,
    payload: payload,
  };
}

export function subregionsFail(payload) {
  return {
    type: SUBREGIONS_FAIL,
    payload: payload,
  };
}

export const YEARS_REQUEST = 'TRADE-AGREEMENTS/YEARS_REQUEST';
export const YEARS_SUCCESS = 'TRADE-AGREEMENTS/YEARS_SUCCESS';
export const YEARS_FAIL = 'TRADE-AGREEMENTS/YEARS_FAIL';

export function yearsRequest(payload) {
  return {
    type: YEARS_REQUEST,
    payload: payload,
  };
}

export function yearsSuccess(payload) {
  return {
    type: YEARS_SUCCESS,
    payload: payload,
  };
}

export function yearsFail() {
  return {
    type: YEARS_FAIL,
  };
}

export const BUSINESSLINE_REQUEST = 'TRADE-AGREEMENTS/BUSINESSLINE_REQUEST';
export const BUSINESSLINE_SUCCESS = 'TRADE-AGREEMENTS/BUSINESSLINE_SUCCESS';
export const BUSINESSLINE_FAIL = 'TRADE-AGREEMENTS/BUSINESSLINE_FAIL';

export function businessLineRequest(payload) {
  return {
    type: BUSINESSLINE_REQUEST,
    payload: payload,
  };
}

export function businessLineSuccess(payload) {
  return {
    type: BUSINESSLINE_SUCCESS,
    payload: payload,
  };
}

export function businessLineFail() {
  return {
    type: BUSINESSLINE_FAIL,
  };
}

export const SEGMENTS_REQUEST = 'TRADE-AGREEMENTS/SEGMENTS_REQUEST';
export const SEGMENTS_SUCCESS = 'TRADE-AGREEMENTS/SEGMENTS_SUCCESS';
export const SEGMENTS_FAIL = 'TRADE-AGREEMENTS/SEGMENTS_FAIL';

export function segmentsRequest(payload) {
  return {
    type: SEGMENTS_REQUEST,
    payload: payload,
  };
}

export function segmentsSuccess(payload) {
  return {
    type: SEGMENTS_SUCCESS,
    payload: payload,
  };
}

export function segmentsFail() {
  return {
    type: SEGMENTS_FAIL,
  };
}

export const BUSINESSRULES_REQUEST = 'TRADE-AGREEMENTS/BUSINESSRULES_REQUEST';
export const BUSINESSRULES_SUCCESS = 'TRADE-AGREEMENTS/BUSINESSRULES_SUCCESS';
export const BUSINESSRULES_FAIL = 'TRADE-AGREEMENTS/BUSINESSRULES_FAIL';

export function businessRulesRequest(payload) {
  return {
    type: BUSINESSRULES_REQUEST,
    payload: payload,
  };
}

export function businessRulesSuccess(payload) {
  return {
    type: BUSINESSRULES_SUCCESS,
    payload: payload,
  };
}

export function businessRulesFail() {
  return {
    type: BUSINESSRULES_FAIL,
  };
}

export const TERRITORY_REQUEST = 'TRADE-AGREEMENTS/TERRITORY_REQUEST';
export const TERRITORY_SUCCESS = 'TRADE-AGREEMENTS/TERRITORY_SUCCESS';
export const TERRITORY_FAIL = 'TRADE-AGREEMENTS/TERRITORY_FAIL';

export function territoryRequest(payload) {
  return {
    type: TERRITORY_REQUEST,
    payload: payload,
  };
}

export function territorySuccess(payload) {
  return {
    type: TERRITORY_SUCCESS,
    payload: payload,
  };
}

export function territoryFail() {
  return {
    type: TERRITORY_FAIL,
  };
}

export const ZONES_REQUEST = 'TRADE-AGREEMENTS/ZONES_REQUEST';
export const ZONES_SUCCESS = 'TRADE-AGREEMENTS/ZONES_SUCCESS';
export const ZONES_FAIL = 'TRADE-AGREEMENTS/ZONES_FAIL';

export function zonesRequest(payload) {
  return {
    type: ZONES_REQUEST,
    payload: payload,
  };
}

export function zonesSuccess(payload) {
  return {
    type: ZONES_SUCCESS,
    payload: payload,
  };
}

export function zonesFail() {
  return {
    type: ZONES_FAIL,
  };
}

export const CLIENTS_REQUEST = 'TRADE-AGREEMENTS/CLIENTS_REQUEST';
export const CLIENTS_SUCCESS = 'TRADE-AGREEMENTS/CLIENTS_SUCCESS';
export const CLIENTS_FAIL = 'TRADE-AGREEMENTS/CLIENTS_FAIL';

export function clientsRequest(payload) {
  return {
    type: CLIENTS_REQUEST,
    payload: payload,
  };
}

export function clientsSuccess(payload) {
  return {
    type: CLIENTS_SUCCESS,
    payload: payload,
  };
}

export function clientsFail() {
  return {
    type: CLIENTS_FAIL,
  };
}

export const FAMILY_REQUEST = 'TRADE-AGREEMENTS/FAMILY_REQUEST';
export const FAMILY_SUCCESS = 'TRADE-AGREEMENTS/FAMILY_SUCCESS';
export const FAMILY_FAIL = 'TRADE-AGREEMENTS/FAMILY_FAIL';

export function familyRequest(payload) {
  return {
    type: FAMILY_REQUEST,
    payload: payload,
  };
}

export function familySuccess(payload) {
  return {
    type: FAMILY_SUCCESS,
    payload: payload,
  };
}

export function familyFail() {
  return {
    type: FAMILY_FAIL,
  };
}

export const PRODUCTS_REQUEST = 'TRADE-AGREEMENTS/PRODUCTS_REQUEST';
export const PRODUCTS_SUCCESS = 'TRADE-AGREEMENTS/PRODUCTS_SUCCESS';
export const PRODUCTS_FAIL = 'TRADE-AGREEMENTS/PRODUCTS_FAIL';

export function productsRequest(payload) {
  return {
    type: PRODUCTS_REQUEST,
    payload: payload,
  };
}

export function productsSuccess(payload) {
  return {
    type: PRODUCTS_SUCCESS,
    payload: payload,
  };
}

export function productsFail() {
  return {
    type: PRODUCTS_FAIL,
  };
}

export function cleanTopFilters() {
  return {
    type: CLEAN_FILTERS,
  };
}
