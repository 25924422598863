import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import instance from '../../../services/request';
import { REPORT_REQUEST, reportSuccess, reportFail } from '../actions/reports';
import { getReport } from '../../services/reports';
import { downloadFile } from 'helpers/downloadHelper';

function* workReportRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { sourceId, onSuccess, params } = payload;

    const response = yield call(getReport, sourceId, params, source.token);

    if (response) {
      if (typeof response !== 'undefined') {
        yield onSuccess();
        yield put(reportSuccess());

        yield downloadFile(response, `Desafío ${params.name}.xlsx`);

        return response;
      }
    } else {
      yield put(reportFail());
    }
  } catch (e) {
    console.error('Error', e);
    yield put(reportFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}
export function* watchReportRequest() {
  yield takeLatest(REPORT_REQUEST, workReportRequest);
}
