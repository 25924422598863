export const POST_ONE_REGISTERPRICES_REQUEST = 'POST_ONE_REGISTERPRICES_REQUEST';
export const POST_ONE_REGISTERPRICES_SUCCESS = 'POST_ONE_REGISTERPRICES_SUCCESS';
export const POST_ONE_REGISTERPRICES_CLEAN = 'POST_ONE_REGISTERPRICES_CLEAN';

export const GET_CHECK_EXISTENCE_REQUEST = 'GET_CHECK_EXISTENCE_REQUEST';
export const GET_CHECK_EXISTENCE_SUCCESS = 'GET_CHECK_EXISTENCE_SUCCESS';
export const GET_CHECK_EXISTENCE_CLEAN = 'GET_CHECK_EXISTENCE_CLEAN';

//POST_ONE_REGISTERPRICES_REQUEST
export function postOneRegisterPricesRequest(payload) {
  return {
    type: POST_ONE_REGISTERPRICES_REQUEST,
    payload: payload,
  };
}
export function postOneRegisterPricesSuccess(payload) {
  return {
    type: POST_ONE_REGISTERPRICES_SUCCESS,
    payload: payload,
  };
}

export function postOneRegisterPricesClean() {
  return {
    type: POST_ONE_REGISTERPRICES_CLEAN,
  };
}

//GET_CHECK_EXISTENCE_REQUEST
export function getCheckExistenceRequest(payload) {
  return {
    type: GET_CHECK_EXISTENCE_REQUEST,
    payload: payload,
  };
}

export function getCheckExistenceSuccess(payload) {
  return {
    type: GET_CHECK_EXISTENCE_SUCCESS,
    payload: payload,
  };
}

export function getCheckExistenceClean() {
  return {
    type: GET_CHECK_EXISTENCE_CLEAN,
  };
}
