import React, { useEffect, useRef, useState } from 'react';
import { Button, Select, Modal } from 'antd';
import { connect } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';

const ValidatorsList = ({
  visible,
  title,
  addItem,
  options,
  deleteItem,
  validatorType,
  salesChallengeList,
  tradeAgreementList,
  //salesChallengeListLoading,
  //tradeAgreementListLoading,
  canModify,
  validationFields,

  regionId,
  lineId,
  subRegionId,
  clientId,
  zoneId,
}) => {
  const selected = useRef('');
  const [dropdownLength, setDropdownLength] = useState(0);

  const [selectedUser, setSelectedUser] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const [validatorsToDisable, setValidatorsToDisable] = useState([]);

  const [deletionModal, setDeletionModal] = useState({ visible: false, user: {} });
  const modalOptions =
    validatorType === 'salesChallenge'
      ? deletionModal.user?.territories
      : deletionModal.user?.clients;
  const list = validatorType === 'salesChallenge' ? salesChallengeList : tradeAgreementList;

  const reducedList = list.reduce((lista, actual) => {
    if (!lista.find((item) => item.userId === actual.userId)) {
      lista.push({ ...actual, zones: [actual.zone] });
      return lista;
    } else {
      const element = lista[lista.findIndex((item) => item.userId === actual.userId)];
      element.zones.push(actual.zone);
      return lista;
    }
  }, []);

  const hasAprovedSalesChallenges = (item) => {
    let enableToDelete = false;

    let hasApprovedChallenge = item.territories.some(
      (territory) => territory.hasApprovedChallenge === false
    );
    if (hasApprovedChallenge === true) {
      enableToDelete = true;
    }

    return enableToDelete;
  };

  // const isLoading =
  //   validatorType === 'salesChallenge' ? salesChallengeListLoading : tradeAgreementListLoading;
  const handleDeleteItem = (item) => {
    deleteItem(item);
  };

  const { Option } = Select;

  const handleSelect = (value) => {
    selected.current = value.map((item) => JSON.parse(item).userId);
    setDropdownLength(value.length);
    setSelectedOption(value);
  };

  useEffect(() => {
    //reset dropdown when filters change
    const mustResetClient =
      clientId.length === 0 && (validatorType === 'tradeAgreement' || validatorType === 'All');
    const mustResetZone =
      zoneId.length === 0 && (validatorType === 'salesChallenge' || validatorType === 'All');
    if (mustResetClient || mustResetZone) {
      setSelectedOption([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionId, lineId, subRegionId, clientId.length, zoneId.length]);

  return (
    <>
      {visible && (
        <article className="validatorItem">
          <h5> {title} </h5>

          <div className="validatorItem__wrap">
            <div className="validator-add-selecter">
              <p> Validador </p>
              <Select
                disabled={!canModify}
                mode="multiple"
                onChange={handleSelect}
                maxTagCount={1}
                allowClear
                value={selectedOption}
              >
                {options.map((item) => (
                  <Option key={item.userId} value={JSON.stringify(item)}>
                    {item.userName} <br />
                    <b>{item.rol}</b>
                  </Option>
                ))}
              </Select>
            </div>

            <Button
              disabled={!canModify || dropdownLength < 1 || selectedOption.length === 0}
              onClick={() => {
                addItem(selected.current);
                setSelectedOption([]);
              }}
            >
              + Agregar validador{' '}
            </Button>
          </div>

          <div className="already-added-wrapper">
            {reducedList.map((item) => {
              const { userId, userName, rol, territories, clients } = item;
              return (
                <div key={userId} className="validators-list-wrapper">
                  <p className="validator-name-and-position-label">
                    {userName}{' '}
                    {canModify && hasAprovedSalesChallenges(item) && (
                      <CloseOutlined
                        className="validators-delete"
                        onClick={() => {
                          //handleDeleteItem(userId);
                          setSelectedUser(userId);
                          //setVisibleModal(true);
                          setDeletionModal({ visible: true, user: item });

                          setValidatorsToDisable(
                            validatorType === 'salesChallenge'
                              ? territories.map((territory) => territory.territoryId)
                              : clients.map((client) => client.clientId)
                          );
                        }}
                      />
                    )}{' '}
                    <br /> <b>{rol}</b> <br />
                    <div className="validator-scope-wrapper">
                      {(validatorType === 'salesChallenge' ? territories : clients).map(
                        ({ name, territoryId }, index) => (
                          <>
                            <span key={name} className="validator-scope-element">
                              {name}
                              {/* {index === zones.length - 1 ? ' ' : ', '} */}
                            </span>
                            &nbsp;
                          </>
                        )
                      )}
                    </div>
                  </p>
                </div>
              );
            })}
          </div>

          <Modal
            visible={deletionModal.visible}
            onCancel={() => {
              setDeletionModal({ visible: false, user: {} });
            }}
            onOk={() => {
              handleDeleteItem({ selectedUser, validatorsToDisable });
              setDeletionModal({ visible: false, user: {} });
            }}
            okButtonProps={{ disabled: validatorsToDisable.length === 0 }}
          >
            {' '}
            <h1 className="modal-title">
              Eliminar Validadores de{' '}
              {validatorType === 'tradeAgreement' ? 'Acuerdo Comercial' : 'Desafío de Ventas'}
            </h1>
            Seleccione los permisos de validador que desea eliminar para el usuario{' '}
            <b>{deletionModal.user.userName}</b> <br />
            <Select
              style={{ width: '80%', margin: '1em auto' }}
              mode="multiple"
              maxTagCount={2}
              placeholder="Seleccione los permisos de validación"
              allowClear
              value={validatorsToDisable}
              onChange={(value) => {
                setValidatorsToDisable(value);
              }}
            >
              {modalOptions?.map((item) => (
                <Option
                  key={item.name}
                  value={validatorType === 'salesChallenge' ? item.territoryId : item.clientId}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </Modal>
        </article>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    query: state?.VALIDATIONS?.filters?.query,
    year: state?.VALIDATIONS?.filters?.query?.year ?? '',
    regionId: state?.VALIDATIONS?.filters?.query?.regionId ?? 0,
    lineId: state?.VALIDATIONS?.filters?.query?.lineId ?? 0,
    subRegionId: state?.VALIDATIONS?.filters?.query?.subRegionId ?? 0,
    clientId: state?.VALIDATIONS?.filters?.query?.clientId ?? [],
    zoneId: state?.VALIDATIONS?.filters?.query?.zoneId ?? [],
    territoryId: state?.VALIDATIONS?.filters?.query?.territoryId ?? [],
    salesChallengeList: state?.VALIDATIONS?.validatorList?.salesChallenge?.list || [],
    tradeAgreementList: state?.VALIDATIONS?.validatorList?.tradeAgreement?.list || [],
    salesChallengeListLoading: state?.VALIDATIONS?.validatorList?.salesChallenge?.loading || false,
    tradeAgreementListLoading: state?.VALIDATIONS?.validatorList?.tradeAgreement?.loading || false,
    validationFields: state?.VALIDATIONS?.validationFieldsReducer?.validationFields ?? null,
  };
};

const ValidatorsListConected = connect(mapStateToProps, null)(ValidatorsList);

export default ValidatorsListConected;
