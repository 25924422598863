/* eslint-disable no-constant-condition */
import React from 'react';
import { InputNumber, Form } from 'antd';
import { setRuleRequired } from 'utils/validations';

const formatFunc = (e) => {
  let value = e.toString();
  if (value && typeof value === 'string') {
    let format = '';
    let decimal = value.indexOf('.');

    if (decimal > 0) {
      let a = new RegExp(/(^([0-9]*\.[0-9][0-9][0-9])$)/gi);
      let onlyTwoDecimals = value.match(a, '$1');

      if (!onlyTwoDecimals) {
        let splitDecimals = value.split('.');
        if (splitDecimals[1]) {
          let sub = splitDecimals[1].substring(0, 3);
          value = `${splitDecimals[0]}.${sub}`;
        }
      }
      if (value.length <= 14) {
        format = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        let val = value.substring(0, 14);
        format = `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    } else {
      if (value.length <= 10) {
        format = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        let val = value.substring(0, 10);
        format = `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
    return format;
  }
};
const parserFunc = (value) => {
  const clean = value.replace(/,/g, ''),
    intLen = 10,
    floatLen = 3,
    dotIndex = clean.indexOf('.'),
    noDot = dotIndex === -1,
    totalDots = (clean.match(/\./g) || []).length;

  if (noDot) {
    if (clean.length <= intLen) {
      return clean;
    }
    return clean.substr(0, intLen);
  }

  if (dotIndex === 0 && totalDots === 1) {
    return clean.substr(0, floatLen + 1);
  }

  if (totalDots === 1 && dotIndex === clean.length - 1) {
    if (clean.length <= intLen + 1) {
      return clean;
    }
    return `${clean.substring(0, intLen)}.`;
  }

  if (true) {
    const cleanArray = clean.split('.');
    let integer, float;

    if (cleanArray[0].length > intLen) {
      integer = (cleanArray[0] ?? '').substring(0, intLen);
    } else {
      integer = `${cleanArray[0]}`;
    }

    if (cleanArray[1].length > floatLen) {
      float = cleanArray[1].substring(0, floatLen);
    } else {
      float = `${cleanArray[1]}`;
    }

    return `${integer}.${float}`;
  }

  return clean;
};
const keyBlock = (e) => {
  if (e.shiftKey || (e.shiftKey && e.which === 51) || e.key === 'Dead') {
    e.preventDefault();
  }
  if (
    e.keyCode === 69 ||
    e.keyCode === 73 ||
    e.keyCode === 186 ||
    e.keyCode === 187 ||
    e.keyCode === 189 ||
    e.keyCode === 40 ||
    e.keyCode === 107 ||
    e.keyCode === 109 ||
    e.keyCode === 191 ||
    e.keyCode === 192 ||
    e.keyCode === 219 ||
    e.keyCode === 220 ||
    e.keyCode === 221 ||
    e.keyCode === 222 ||
    e.keyCode === 38 ||
    e.keyCode === 40 ||
    e.key === '{' ||
    e.key === '}' ||
    e.key === '+' ||
    e.key === '*' ||
    e.key === '[' ||
    e.key === ']' ||
    e.key === '´' ||
    e.key === '/' ||
    e.key === '<' ||
    e.key === '+' ||
    e.key === '´´' ||
    e.key === 'ArrowLeft' ||
    e.key === 'BracketLeft' ||
    e.key === 'BracketRight' ||
    e.key === 'Quote' ||
    e.key === 'Shift' ||
    e.key === 'Dead' ||
    (e.keyCode >= 65 && e.keyCode <= 90) ||
    e.shiftKey ||
    e.key === 'ArrowDown' ||
    e.key === 'ArrowUp'
  ) {
    e.preventDefault();
  }
};

function NumberInput(props) {
  const { name, isRequired, label, rules = [], ...other } = props;
  const requiredRule = isRequired ? [setRuleRequired(label)] : [];

  return (
    <Form.Item rules={[...requiredRule, ...rules]} name={name} label={label}>
      <InputNumber style={{ border: '1px solid #d9d9d9' }} {...other} />
    </Form.Item>
  );
}
NumberInput.displayName = 'NumberInput';
NumberInput.defaultProps = {
  formatter: formatFunc,
  parser: parserFunc,
  onKeyDown: keyBlock,
};
export default NumberInput;
