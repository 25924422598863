export const GET_MASSIVE_UPLOAD_REQUEST = 'TRADE-AGREEMENTS/GET_MASSIVE_UPLOAD_REQUEST';
export const GET_MASSIVE_UPLOAD_SUCCESS = 'TRADE-AGREEMENTS/GET_MASSIVE_UPLOAD_SUCCESS';
export const GET_MASSIVE_UPLOAD_FAIL = 'TRADE-AGREEMENTS/GET_MASSIVE_UPLOAD_FAIL';

export function getMassiveUploadRequest(payload) {
  return {
    type: GET_MASSIVE_UPLOAD_REQUEST,
    payload,
  };
}

export function getMassiveUploadSuccess(payload) {
  return {
    type: GET_MASSIVE_UPLOAD_SUCCESS,
    payload,
  };
}

export function getMAsiveUploadFail(payload) {
  return {
    type: GET_MASSIVE_UPLOAD_FAIL,
    payload,
  };
}
