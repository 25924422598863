export default function userIs(...arrUsers) {
  const userData = JSON.parse(localStorage.getItem('user'));
  const role = userData.role;

  let userType = '';

  if (role.roleId !== 2) {
    userType = role.roleName;
  } else {
    userType = `Administrador de ${userData.area}`;
  }
  return arrUsers.includes(userType);
}
