export * from './helpers';

export const getTextSize = (size) => {
  switch (size) {
    case 'large':
      return '2em';
    case 'medium':
      return '1.5em';
    case 'small':
      return '.8em';
    case undefined:
      return '1em';
    default:
      return size;
  }
};

export const getThemeColor = (color) => {
  if (color) {
    return color;
  } else {
    return '#383838';
  }
};

export const getIconSize = (size) => {
  switch (size) {
    case 'large':
      return '100px';
    case 'medium':
      return '60px';
    case 'small':
      return '30px';
    case undefined:
      return '30px';
    default:
      return size;
  }
};

export const processDatesQuery = (dates) => {
  let parsedDates = [];
  if (dates !== null) {
    dates.forEach((element) => {
      let date = element.format('YYYY-MM-DD');
      parsedDates.push(date);
    });
  }
  return parsedDates;
};
