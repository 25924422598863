import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import getInventoriesMassiveUploadModule from '../getInventoriesMassiveUploadModule';
import { layoutContext } from 'context';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import {
  MassiveUploadTable,
  UploadExcelButton,
  ActionButtonsExcelUpload,
} from 'common/MassiveUploads/components';
import { triggerModalForDuplicates } from 'common/MassiveUploads/utils';
//cambiar sagas
import {
  InventoriesMassiveUploadSetAllowUpload,
  InventoriesMassiveUploadRequestVerify,
  InventoriesMassiveUploadRequestUpload,
  InventoriesMassiveUploadClearUpload,
  InventoriesMassiveUploadClearVerify,
  InventoriesMassiveUploadClearAllowUpload,
} from '../redux-saga/actions';
import tableColumns from '../schemas/massiveUploadTableColumns'; //cambiar columna
import { ModalSelector } from '../Components';

const InventoriesMassiveUpload = (props) => {
  const {
    InventoriesMassiveUploadSetAllowUpload: setAllowUpload,
    InventoriesMassiveUploadRequestVerify: requestVerify,
    InventoriesMassiveUploadRequestUpload: requestUpload,
    InventoriesMassiveUploadClearUpload: clearUpload,
    InventoriesMassiveUploadClearVerify: clearVerify,
    InventoriesMassiveUploadClearAllowUpload: clearAllowUpload,
    allowUpload,
    verify,
  } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [fileString, setFileString] = useState('');
  const [selectSubregion, setselectSubregion] = useState(0);
  const correct = verify?.correct;
  const entriesList = verify?.objectList;
  const totalDuplicatedEntries = verify?.replaced?.length;
  const allCorrectMessage = verify?.allCorrectMessage;

  useEffect(() => {
    clearAllowUpload();
    return () => {
      clearAllowUpload();
      clearUpload();
      clearVerify();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    onChangeActiveComponent('activo');
    if (correct > 0) {
      setAllowUpload(true);
    } else {
      setAllowUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correct]);

  function customRequest({ file }) {
    requestVerify({ file, setFileString, subregion: selectSubregion });
  }
  function requestReplace() {
    requestUpload({ history, fileString, overwrite: true, subregion: selectSubregion });
  }
  function requestKeep() {
    requestUpload({ history, fileString, overwrite: false, subregion: selectSubregion });
  }
  function onSubmit() {
    if (totalDuplicatedEntries > 0) {
      triggerModalForDuplicates(totalDuplicatedEntries, requestReplace, requestKeep, 'Ventas');
      return;
    }

    requestReplace();
  }
  function onCancel() {
    history.push(`/catalog/trade-agreement-info`);
  }

  return (
    <>
      <ModalSelector setselectSubregion={setselectSubregion} selectSubregion={selectSubregion} />
      <Form form={form}>
        <UploadExcelButton customRequest={customRequest} />
        <MassiveUploadTable
          data={entriesList}
          columns={tableColumns}
          allCorrectMessage={allCorrectMessage}
        />
        <ActionButtonsExcelUpload
          onSubmit={onSubmit}
          onCancel={onCancel}
          disabled={!allowUpload}
          buttons={['cancel', 'submit']}
        />
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allowUpload: state?.Inventories_MASSIVE_UPLOAD?.allowUpload,
    verify: state?.Inventories_MASSIVE_UPLOAD?.verify,
  };
};

const ConnectedInventoriesMassiveUpload = connect(mapStateToProps, {
  InventoriesMassiveUploadSetAllowUpload,
  InventoriesMassiveUploadRequestVerify,
  InventoriesMassiveUploadRequestUpload,
  InventoriesMassiveUploadClearUpload,
  InventoriesMassiveUploadClearVerify,
  InventoriesMassiveUploadClearAllowUpload,
})(InventoriesMassiveUpload);

const InventoriesMassiveUploadWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getInventoriesMassiveUploadModule()]}>
      <ConnectedInventoriesMassiveUpload />
    </DynamicModuleLoader>
  );
};
export default InventoriesMassiveUploadWithModules;
