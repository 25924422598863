export const READ_ONE_CLIENT_REQUEST = 'READ_ONE_CLIENT_REQUEST';
export const READ_ONE_CLIENT_SUCCESS = 'READ_ONE_CLIENT_SUCCESS';
export const READ_ONE_CLIENT_CLEAN = 'READ_ONE_CLIENT_CLEAN';

//READ
export function readOneClientRequest(payload) {
  return {
    type: READ_ONE_CLIENT_REQUEST,
    payload: payload,
  };
}

export function readOneClientSuccess(payload) {
  return {
    type: READ_ONE_CLIENT_SUCCESS,
    payload: payload,
  };
}

export function readOneClientClean() {
  return {
    type: READ_ONE_CLIENT_CLEAN,
    payload: {},
  };
}
