import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import React from 'react';
import './massiveUploadTableColumns.css';

const messageFaseOne = (
  <>
    <p>
      Corresponde a todos los registros para los cuales se identificaron errores relacionados con
      campos obligatorios faltantes, formato de datos incorrecto o que sobrepasan la longitud máxima
      definida.
    </p>
  </>
);
const messageFaseTwo = (
  <>
    <p>
      Registros de clientes para los cuales se registró información de elementos con estatus
      &quot;Inactivo&quot; o se ingresó información de estructura comercial que no está relacionada
      con el cliente.
    </p>
  </>
);
const messageFaseThree = (
  <>
    <p>
      Corresponde a todos los registros para los cuales se identificó información duplicada dentro
      del archivo de carga masiva.
    </p>
  </>
);
const errorMessages = [
  {
    fase: 'Fase 1',
    message: messageFaseOne,
  },
  {
    fase: 'Fase 2',
    message: messageFaseTwo,
  },
  {
    fase: 'Fase 3',
    message: messageFaseThree,
  },
];
const tableColumns = [
  {
    key: 'phase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'phase',
    className: 'title-masive',
  },
  {
    key: 'line',
    title: 'Línea de negocio',
    dataIndex: 'line',
  },
  {
    key: 'territory',
    title: 'Territorio',
    dataIndex: 'territory',
  },
  {
    key: 'soldTo',
    title: 'Sold To',
    dataIndex: 'soldTo',
  },
  {
    key: 'client',
    title: 'Cliente',
    dataIndex: 'client',
  },
  {
    key: 'year',
    title: 'Año',
    dataIndex: 'year',
  },
  {
    key: 'month',
    title: 'Mes',
    dataIndex: 'month',
  },
  {
    key: 'productPOGPolicy',
    title: 'POG de productos dentro de política',
    dataIndex: 'productPOGPolicy',
  },
  {
    key: 'productPOGPolicyOut',
    title: 'POG de productos fuera de política',
    dataIndex: 'productPOGPolicyOut',
  },
  {
    key: 'goal',
    title: 'Meta',
    dataIndex: 'goal',
  },
  {
    key: 'period',
    title: 'Periodo',
    dataIndex: 'period',
  },
];
export default tableColumns;
