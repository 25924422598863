import { takeLatest, put, call, cancelled, select } from 'redux-saga/effects';
import { INDICATORS_REQUEST, indicatorsFail, indicatorsSuccess } from '../actions/indicators';

import instance from '../../../services/request';
import { getIndicator } from '../../services/indicators';
import { responseOK } from '../../utils';
import { getFromStore } from './selectors';
import { saveClient } from '../actions/infoForAgreement';

function* workerIndicatorRequest(action) {
  const currentTradeAgreementClients = yield select((state) =>
    getFromStore(state, 'TRADE_AGREEMENTS', 'infoForAgreement', 'clientInfo')
  );
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, name } = payload;

    const response = yield call(getIndicator, name, params, source.token);

    if (responseOK(response)) {
      yield put(indicatorsSuccess(response.data));
      let data = response.data;
      let formatVolume = `${Number(response?.data?.totalVolume).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
      let indicators = {
        ...data,
        totalVolume: formatVolume,
      };
      yield put(indicatorsSuccess(indicators));
      let newStatusId = data.statusClientId;
      if (data.statusClientId !== undefined) {
        yield put(
          saveClient({
            ...currentTradeAgreementClients,
            statusId: newStatusId,
          })
        );
      }
      if (data.updatable) {
        let newUpdatable = data.updatable;
        yield put(
          saveClient({
            ...currentTradeAgreementClients,
            updatable: newUpdatable,
            statusId: newStatusId,
          })
        );
      }
    } else {
      yield put(indicatorsFail());
    }
  } catch (e) {
    yield put(indicatorsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchIndicatorRequest() {
  yield takeLatest(INDICATORS_REQUEST, workerIndicatorRequest);
}
