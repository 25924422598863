/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Table } from 'antd';

import './styles.css';

const Periodicity = ({ comparativeState }) => {
  let mainApi = comparativeState.mainApi ? comparativeState.mainApi : [];
  let filter = comparativeState.filter ? comparativeState.filter : [];
  let year = comparativeState.filter[7]?.year ? comparativeState.filter[7].year : '';

  let thereAreResults = mainApi.length > 0;
  let boolAnio = false;

  if (!isNaN(year) || year.length === 1) {
    boolAnio = true;
  } else {
    boolAnio = false;
  }
  const thingsToCompare = [
    filter[1]?.indicator1 ?? -1,
    filter[2]?.indicator2 ?? -1,
    filter[3]?.indicator3 ?? -1,
  ];

  const informationType = filter[4]?.typeinformation ?? 1;

  const columns = [
    {
      title: 'Periodicidad',
      dataIndex: 'structure',
      key: 'structure',
      className: 'periodicity-cell',
    },
  ];
  thingsToCompare.forEach((item) => {
    if (item !== -1) {
      const dataIndex = item + (informationType === 1 ? '' : 'Volume');
      const comparativeHeaderColumn = {
        title: item,
        dataIndex: dataIndex,
        key: dataIndex,
        className: 'periodicity-cell',
        render: (value) => {
          let digitNumber = 2;
          let prefix = '$';
          if (informationType === 2) {
            prefix = '';
          }
          return `${prefix}${Number(value).toLocaleString('en-US', {
            minimumFractionDigits: digitNumber,
            maximumFractionDigits: digitNumber,
          })}`;
        },
      };
      switch (item) {
        case 'salesChallenge':
          comparativeHeaderColumn.title = boolAnio
            ? `Desafío de ventas ${year}`
            : 'Desafío de ventas';
          break;
        case 'agreement':
          comparativeHeaderColumn.title = boolAnio
            ? `Acuerdo Comercial ${year}`
            : 'Acuerdo Comercial';
          break;
        case 'pog':
          comparativeHeaderColumn.title = 'POG (Año anterior)';
          break;
        case 'salesGoals':
          comparativeHeaderColumn.title = boolAnio ? `Metas de ventas ${year}` : 'Metas de ventas';
          break;
        case 'sales':
          comparativeHeaderColumn.title = 'Ventas históricas (Año anterior)';
          break;
        default:
          comparativeHeaderColumn.title = '-';

          break;
      }
      columns.push(comparativeHeaderColumn);
    }
  });

  const dataSource = mainApi.map((period, index) => {
    let datoAnio =
      period.structure.substring(period.structure.length - 4, period.structure.length) - 1;

    return {
      ...period,
      key: `${period.structure}${index}`,
    };
  });
  function reduceValues(id) {
    // mainApi
    return mainApi.reduce((acumulative, object) => {
      return acumulative + object[id];
    }, 0);
  }

  if (thereAreResults) {
    dataSource.push({
      key: 'totalRow',
      structure: 'Total',
      agreement: reduceValues('agreement'),
      agreementVolume: reduceValues('agreementVolume'),
      pog: reduceValues('pog'),
      pogVolume: reduceValues('pogVolume'),
      sales: reduceValues('sales'),
      salesChallenge: reduceValues('salesChallenge'),
      salesChallengeVolume: reduceValues('salesChallengeVolume'),
      salesGoals: reduceValues('salesGoals'),
      salesGoalsVolume: reduceValues('salesGoalsVolume'),
      salesVolume: reduceValues('salesVolume'),
    });
  }

  function rowClassName(record, index) {
    const reminder = index % 2;

    if (index === mainApi.length) {
      return 'comparative-last-row';
    }
    if (reminder === 0) {
      return 'comparative-pair-row';
    }
    if (reminder === 1) {
      return 'comparative-odd-row';
    }
  }

  function commercialAggrementSelected() {
    const indicatorsList = [filter[1].indicator1, filter[2].indicator2, filter[3].indicator3];
    return indicatorsList.includes('pog') || indicatorsList.includes('sales');
  }

  return (
    <Row className={'periodicity-container'}>
      <Col xs={24} md={12}>
        <div id="periodicity-table">
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            rowClassName={rowClassName}
          />
          {commercialAggrementSelected() && (
            <p style={{ color: '#E6001D' }}>
              * Los datos históricos que aparecen, hacen referencia al año anterior al periodo que
              aparece seleccionado.
            </p>
          )}
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    comparativeState: state.COMPARATIVE,
  };
};

export default connect(mapStateToProps)(Periodicity);
