export const USERS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD = 'USERS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const USERS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD = 'USERS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const USERS_MASSIVE_UPLOAD_REQUEST_VERIFY = 'USERS_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const USERS_MASSIVE_UPLOAD_SET_VERIFY = 'USERS_MASSIVE_UPLOAD_SET_VERIFY';
export const USERS_MASSIVE_UPLOAD_CLEAR_VERIFY = 'USERS_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const USERS_MASSIVE_UPLOAD_REQUEST_UPLOAD = 'USERS_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const USERS_MASSIVE_UPLOAD_SET_UPLOAD = 'USERS_MASSIVE_UPLOAD_SET_UPLOAD';
export const USERS_MASSIVE_UPLOAD_CLEAR_UPLOAD = 'USERS_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function usersMassiveUploadSetAllowUpload(payload) {
  return {
    type: USERS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function usersMassiveUploadClearAllowUpload(payload) {
  return {
    type: USERS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function usersMassiveUploadRequestVerify(payload) {
  return {
    type: USERS_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function usersMassiveUploadSetVerify(payload) {
  return {
    type: USERS_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function usersMassiveUploadClearVerify(payload) {
  return {
    type: USERS_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function usersMassiveUploadRequestUpload(payload) {
  return {
    type: USERS_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function usersMassiveUploadSetUpload(payload) {
  return {
    type: USERS_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function usersMassiveUploadClearUpload(payload) {
  return {
    type: USERS_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
