import { ADD_MESSAGE_VALUE } from '../actions/messages';

const messages = (state = {}, action) => {
  switch (action.type) {
    case ADD_MESSAGE_VALUE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
export default messages;
