import React from 'react';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import BButton from 'common/components/BButton';
import PropTypes from 'prop-types';

function ResetButton({ style, title, onClick }) {
  return (
    <BButton
      type="primary"
      title={title}
      style={{
        outline: 'none',
        height: '32px',
        ...style,
      }}
      icon={<BsArrowCounterclockwise />}
      onClick={onClick}
    />
  );
}

export default ResetButton;

ResetButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
};

ResetButton.defaultProps = {
  onClick: () => {
    return;
  },
  title: 'Limpiar filtros',
};
