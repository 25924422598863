export const REJECT_TRADE_AGREEMENT_REQUEST = 'REJECT_TRADE_AGREEMENT_REQUEST';
export const REJECT_TRADE_AGREEMENT_SUCCESS = 'REJECT_TRADE_AGREEMENT_SUCCESS';
export const REJECT_TRADE_AGREEMENT_FAIL = 'REJECT_TRADE_AGREEMENT_FAIL';

export function rejectTradeAgreementRequest(payload) {
  return {
    type: REJECT_TRADE_AGREEMENT_REQUEST,
    payload,
  };
}

export function rejectTradeAgreementSuccess(payload) {
  return {
    type: REJECT_TRADE_AGREEMENT_SUCCESS,
    payload,
  };
}

export function rejectTradeAgreementFail() {
  return {
    type: REJECT_TRADE_AGREEMENT_FAIL,
  };
}
