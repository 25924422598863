export const removeParam = (uri) => {
  let indexOfParam = uri.indexOf(':');
  return uri.substring(0, indexOfParam - 1);
};

export async function mainRoutes(permissions) {
  function getUri(routesArray, permissionsArray) {
    let routesNewArray = routesArray.length <= 0 ? [] : routesArray;
    let routeParentObject = routesArray.length > 0 ? routesArray[routesArray.length - 1] : '';
    let routeParent = routeParentObject.path !== undefined ? routeParentObject.path : '';
    permissionsArray.forEach((permission) => {
      const removeParentFromString = (uri) => {
        let indexOfUnderscore = permission.uri.indexOf('_');
        let indexOfParam = permission.uri.indexOf(':');
        let param =
          indexOfParam > 0 ? permission.uri.substring(indexOfParam, permission.uri.length) : '';
        let component = uri.substring(0, indexOfUnderscore);
        return `${component}/${param}`;
      };
      let route = `${routeParent}${routeParent[routeParent.length - 1] === '/' ? '' : '/'}${
        permission.uri.includes('_') ? removeParentFromString(permission.uri) : permission.uri
      }`;
      let module = permission.uri.includes(':') ? removeParam(permission.uri) : permission.uri;
      routesNewArray.push({ path: route, module: module });
      if (permission.components.length > 0) {
        getUri(routesNewArray, permission.components);
      }
    });
    return routesNewArray;
  }

  let rootModules = getUri([], permissions);
  return rootModules;
}

export async function userModules(permissions, allSubregionsAccess) {
  function stackModules(array) {
    let modules = allSubregionsAccess ? ['allSubregionsAccess'] : [];
    array.forEach((component) => {
      let module = component.uri.includes(':') ? removeParam(component.uri) : component.uri;

      modules.push(module);
      if (component.components.length > 0) {
        modules = modules.concat(stackModules(component.components));
      }
    });
    return modules;
  }
  return stackModules(permissions);
}
