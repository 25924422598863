export const VALID_PRODUCTS_FOR_DELETING_PREV_QUERY =
  'VALID_PRODUCTS_FOR_DELETING_PREV_QUERY/TRADE';
export const VALID_PRODUCTS_FOR_DELETING_REQUEST = 'VALID_PRODUCTS_FOR_DELETING_REQUEST/TRADE';
export const VALID_PRODUCTS_FOR_DELETING_SUCCESS = 'VALID_PRODUCTS_FOR_DELETING_SUCCESS/TRADE';
export const VALID_PRODUCTS_FOR_DELETING_FAIL = 'VALID_PRODUCTS_FOR_DELETING_FAIL/TRADE';

export function validProductsForDeletingPrevQuerySet(payload) {
  return {
    type: VALID_PRODUCTS_FOR_DELETING_PREV_QUERY,
    payload,
  };
}
export function validProductsForDeletingRequest(payload) {
  return {
    type: VALID_PRODUCTS_FOR_DELETING_REQUEST,
    payload,
  };
}

export function validProductsForDeletingSuccess(payload) {
  return {
    type: VALID_PRODUCTS_FOR_DELETING_SUCCESS,
    payload,
  };
}

export function validProductsForDeletingFail() {
  return {
    type: VALID_PRODUCTS_FOR_DELETING_FAIL,
  };
}
