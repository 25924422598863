import {
  TYPE_CLIENT_REQUEST,
  TYPE_CLIENT_SUCCESS,
  TYPE_CLIENT_CLEAN,
  BUSINESS_LINES_REQUEST,
  BUSINESS_LINES_SUCCESS,
  BUSINESS_LINES_CLEAN,
  SEGMENTATION_REQUEST,
  SEGMENTATION_SUCCESS,
  SEGMENTATION_CLEAN,
  COMPANY_REQUEST,
  COMPANY_SUCCESS,
  COMPANY_CLEAN,
  TERRITORIES_REQUEST,
  TERRITORIES_SUCCESS,
  TERRITORIES_CLEAN,
  POGS_REQUEST,
  POGS_SUCCESS,
  POGS_CLEAN,
  CURRENCIES_REQUEST,
  CURRENCIES_SUCCESS,
  CURRENCIES_CLEAN,
  PlACEMENTS_REQUEST,
  PlACEMENTS_SUCCESS,
  PlACEMENTS_CLEAN,
  REGISTER_DYNAMIC_ELEMENT_REQUEST,
  REGISTER_DYNAMIC_ELEMENT_SUCCESS,
  REGISTER_DYNAMIC_ELEMENT_CLEAN,
  CLIENTID_REQUEST,
  CLIENTID_SUCCESS,
  CLIENTID_CLEAN,
  VALIDATE_CLIENT_REQUEST,
  VALIDATE_CLIENT_SUCCESS,
  VALIDATE_CLIENT_CLEAN,
  INCENTIVE_PERIOD_REQUEST,
  INCENTIVE_PERIOD_SUCCESS,
  INCENTIVE_PERIOD_CLEAN,
  DETAIL_DYNAMIC_ELEMENTS_REQUEST,
  DETAIL_DYNAMIC_ELEMENTS_SUCCESS,
  DETAIL_DYNAMIC_ELEMENTS_CLEAN,
  STATUS_REQUEST,
  STATUS_SUCCESS,
  STATUS_CLEAN,
} from '../actions';

function DynamicElementsReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    // status send

    case STATUS_REQUEST:
      return {
        ...state,
        status: true,
      };

    case STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };

    case STATUS_CLEAN:
      return {
        ...state,
        status: action.payload,
      };

    case VALIDATE_CLIENT_REQUEST:
      return {
        ...state,
        isValid: true,
      };

    case VALIDATE_CLIENT_SUCCESS:
      return {
        ...state,
        isValid: action.payload,
      };

    case VALIDATE_CLIENT_CLEAN:
      return {
        ...state,
        isValid: action.payload,
      };
    //get Placements
    case CLIENTID_REQUEST:
      return {
        ...state,
        client: null,
      };
    case CLIENTID_SUCCESS:
      return {
        ...state,
        client: action.payload,
      };
    case CLIENTID_CLEAN:
      return {
        ...state,
        client: null,
      };

    //get Placements
    case PlACEMENTS_REQUEST:
      return {
        ...state,
        placements: null,
      };
    case PlACEMENTS_SUCCESS:
      return {
        ...state,
        placements: action.payload,
      };
    case PlACEMENTS_CLEAN:
      return {
        ...state,
        placements: null,
      };
    //get type clients
    case TYPE_CLIENT_REQUEST:
      return {
        ...state,
        typeClient: null,
      };
    case TYPE_CLIENT_SUCCESS:
      return {
        ...state,
        typeClient: action.payload,
      };
    case TYPE_CLIENT_CLEAN:
      return {
        ...state,
        typeClient: null,
      };
    //get businesLines
    case BUSINESS_LINES_REQUEST:
      return {
        ...state,
        businessLine: null,
      };
    case BUSINESS_LINES_SUCCESS:
      return {
        ...state,
        businessLine: action.payload,
      };
    case BUSINESS_LINES_CLEAN:
      return {
        ...state,
        businessLine: null,
      };
    //get segmentation
    case SEGMENTATION_REQUEST:
      return {
        ...state,
        segmentation: null,
      };
    case SEGMENTATION_SUCCESS:
      return {
        ...state,
        segmentation: action.payload,
      };
    case SEGMENTATION_CLEAN:
      return {
        ...state,
        segmentation: null,
      };
    //get company
    case COMPANY_REQUEST:
      return {
        ...state,
        company: null,
      };
    case COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
      };
    case COMPANY_CLEAN:
      return {
        ...state,
        company: null,
      };
    //get Territoires
    case TERRITORIES_REQUEST:
      return {
        ...state,
        territories: null,
      };
    case TERRITORIES_SUCCESS:
      return {
        ...state,
        territories: action.payload,
      };
    case TERRITORIES_CLEAN:
      return {
        ...state,
        territories: null,
      };
    //get incentive period
    case INCENTIVE_PERIOD_REQUEST:
      return {
        ...state,
        incentivePeriod: null,
      };
    case INCENTIVE_PERIOD_SUCCESS:
      return {
        ...state,
        incentivePeriod: action.payload.map((d) => ({ key: d.saleIncentiveId, value: d.name })),
      };
    case INCENTIVE_PERIOD_CLEAN:
      return {
        ...state,
        incentivePeriod: null,
      };
    //get Pogs
    case POGS_REQUEST:
      return {
        ...state,
        pogs: null,
      };
    case POGS_SUCCESS:
      return {
        ...state,
        pogs: action.payload,
      };
    case POGS_CLEAN:
      return {
        ...state,
        pogs: null,
      };
    //get Currencies
    case CURRENCIES_REQUEST:
      return {
        ...state,
        currencies: null,
      };
    case CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.payload,
      };
    case CURRENCIES_CLEAN:
      return {
        ...state,
        currencies: null,
      };
    //get DetailDynamicElements
    case DETAIL_DYNAMIC_ELEMENTS_REQUEST:
      return {
        ...state,
        detail: null,
      };
    case DETAIL_DYNAMIC_ELEMENTS_SUCCESS:
      return {
        ...state,
        detail: action.payload,
      };
    case DETAIL_DYNAMIC_ELEMENTS_CLEAN:
      return {
        ...state,
        detail: null,
      };
    //get Currencies
    case REGISTER_DYNAMIC_ELEMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        registerDynamicElement: action.payload,
      };
    case REGISTER_DYNAMIC_ELEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        registerDynamicElement: action.payload,
      };
    case REGISTER_DYNAMIC_ELEMENT_CLEAN:
      return {
        ...state,
        isLoading: false,
        registerDynamicElement: action.payload,
      };

    default:
      return state;
  }
}

export default DynamicElementsReducer;
