import React from 'react';
import { BInput, FormTitleDivider, BInputNumber } from 'common';
import { Row, Col } from 'antd';
import NumberInput from 'Products/ProductRegistration/components/NumberInput';

const ProductInformation = ({
  gutterValue,
  onChangeName,
  onFocusNumber,
  onBlurNumber,
  disabled,
}) => {
  const onFocusNum =
    typeof onFocusNumber === 'function'
      ? onFocusNumber
      : () => {
          return;
        };
  const onBlurNum =
    typeof onBlurNumber === 'function'
      ? onBlurNumber
      : () => {
          return;
        };
  const onChangeNameFunction =
    typeof onChangeName === 'function'
      ? onChangeName
      : () => {
          return;
        };
  return (
    <>
      <FormTitleDivider title={'Información de Producto'} />
      <Row gutter={gutterValue}>
        <Col xs={24} md={6}>
          <BInputNumber
            label="Número"
            name="productNumber"
            max={9999999999}
            min={0}
            isRequired={true}
            disabled={true}
            onFocus={onFocusNum}
            onBlur={onBlurNum}
          />
          {/* <BInput
            type="number"
            label="Número"
            name="productNumber"
            max={9999999999}
            min={0}
            isRequired={true}
            disabled={false}
            // formatter={(value) => `{value}`}
            onFocus={onFocusNum}
            onBlur={onBlurNum}
          /> */}
        </Col>
        <Col xs={24} md={12}>
          <BInput
            label="Nombre"
            name="name"
            isRequired
            disabled={disabled}
            maxLength={100}
            onChange={onChangeNameFunction}
          />
        </Col>
        <Col xs={24} md={6}>
          <BInput label="Familia" name="family" isRequired disabled={disabled} maxLength={100} />
        </Col>
        <Col xs={24} md={6}>
          <BInput
            label="Clasificación"
            name="clasification"
            isRequired
            disabled={disabled}
            maxLength={100}
          />
        </Col>
        <Col xs={24} md={6}>
          <BInput
            label="Presentación"
            name="packaging"
            isRequired
            disabled={disabled}
            maxLength={100}
          />
        </Col>
        <Col xs={24} md={6}>
          <BInput
            label="Unidad de medida"
            name="measurementUnit"
            isRequired
            disabled={disabled}
            maxLength={20}
          />
        </Col>
        <Col xs={24} md={6}>
          <BInput
            label="Unidades por empaque"
            name="unitsPerPackage"
            isRequired
            disabled={disabled}
            maxLength={20}
          />
        </Col>
        <Col xs={24} md={6}>
          <NumberInput
            label="Pedido mínimo requerido"
            name="minimum"
            max={9999999999.999}
            min={0}
            step="0.001"
            isRequired={true}
            disabled={disabled}
          />

          {/* <BInput
            step="0.001"
            type="number"
            label="Pedido mínimo requerido"
            name="minimumOrderRequired"
            isRequired
            min={0}
            onFocus={onFocusNum}
            onBlur={onBlurNum}
          /> */}
        </Col>
        <Col xs={24} md={6}>
          <NumberInput
            step="0.001"
            label="MOQ"
            name="moq"
            max={9999999999.999}
            min={0}
            isRequired={true}
            disabled={disabled}
          />

          {/* <BInput
            step="0.001"
            type="number"
            label="MOQ"
            name="moq"
            isRequired
            min={0}
            onFocus={onFocusNum}
            onBlur={onBlurNum}
          /> */}
        </Col>
      </Row>
    </>
  );
};

export default ProductInformation;
