import {
  /* Changing Switch import*/
  TABLE_COMPONENTS_CHANGING_SWITCH_REQUEST_SET_STATUS,
  TABLE_COMPONENTS_CHANGING_SWITCH_SET_SET_STATUS,
  TABLE_COMPONENTS_CHANGING_SWITCH_CLEAR_SET_STATUS,
} from '../actions/tableComponents';

const tableComponents = (state = {}, action) => {
  const { type } = action;

  switch (type) {
    case TABLE_COMPONENTS_CHANGING_SWITCH_REQUEST_SET_STATUS:
      return { ...state, isLoading: true, status: null };

    case TABLE_COMPONENTS_CHANGING_SWITCH_SET_SET_STATUS:
      return { ...state, status: action.payload, isLoading: false };

    case TABLE_COMPONENTS_CHANGING_SWITCH_CLEAR_SET_STATUS:
      return { ...state, status: null, isLoading: false };

    default:
      return state;
  }
};

export default tableComponents;
