import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import getBusinessStructureMassiveUploadModule from '../getBusinessStructureMassiveUploadModule';
import { layoutContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import {
  MassiveUploadTable,
  UploadExcelButton,
  ActionButtonsExcelUpload,
} from 'common/MassiveUploads/components';
// import { triggerModalForDuplicates } from 'common/MassiveUploads/utils';

import {
  businessStructureMassiveUploadSetAllowUpload,
  businessStructureMassiveUploadRequestVerify,
  businessStructureMassiveUploadRequestUpload,
  businessStructureMassiveUploadClearUpload,
  businessStructureMassiveUploadClearVerify,
  businessStructureMassiveUploadClearAllowUpload,
} from '../redux-saga/actions';
import tableColumns from '../schemas/massiveUploadTableColumns';

const BusinessStructureMassiveUpload = (props) => {
  const {
    businessStructureMassiveUploadSetAllowUpload: setAllowUpload,
    businessStructureMassiveUploadRequestVerify: requestVerify,
    businessStructureMassiveUploadRequestUpload: requestUpload,
    businessStructureMassiveUploadClearUpload: clearUpload,
    businessStructureMassiveUploadClearVerify: clearVerify,
    businessStructureMassiveUploadClearAllowUpload: clearAllowUpload,
    allowUpload,
    verify,
  } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [fileString, setFileString] = useState('');

  const errorEntriesList = verify?.objectList;
  const correct = verify?.correct;
  // const totalDuplicatedEntries = verify?.duplicated?.length;
  const allCorrectMessage = verify?.allCorrectMessage;
  useEffect(() => {
    clearAllowUpload();
    return () => {
      clearAllowUpload();
      clearUpload();
      clearVerify();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (correct > 0) {
      setAllowUpload(true);
    } else {
      setAllowUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correct]);

  function customRequest({ file }) {
    onChangeActiveComponent('BusinessStructureMassiveUpload');
    requestVerify({ file, setFileString });
  }

  // function requestReplace() {
  //   requestUpload({ history, fileString, overwrite: true });
  // }
  function requestKeep() {
    requestUpload({ history, fileString, overwrite: false });
  }
  function onSubmit() {
    // if (totalDuplicatedEntries > 0) {
    //   triggerModalForDuplicates(totalDuplicatedEntries, requestReplace, requestKeep);
    //   return;
    // }
    requestKeep();
  }
  function onCancel() {
    history.push(`/catalog`);
  }

  return (
    <Form form={form}>
      <UploadExcelButton customRequest={customRequest} />
      <MassiveUploadTable
        data={errorEntriesList}
        columns={tableColumns}
        allCorrectMessage={allCorrectMessage}
      />
      <ActionButtonsExcelUpload
        onSubmit={onSubmit}
        onCancel={onCancel}
        disabled={!allowUpload}
        buttons={['cancel', 'submit']}
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    allowUpload: state?.BUSINESS_STRUCTURE_MASSIVE_UPLOAD?.allowUpload,
    verify: state?.BUSINESS_STRUCTURE_MASSIVE_UPLOAD?.verify,
  };
};

const ConnectedBusinessStructureMassiveUpload = connect(mapStateToProps, {
  businessStructureMassiveUploadSetAllowUpload,
  businessStructureMassiveUploadRequestVerify,
  businessStructureMassiveUploadRequestUpload,
  businessStructureMassiveUploadClearUpload,
  businessStructureMassiveUploadClearVerify,
  businessStructureMassiveUploadClearAllowUpload,
})(BusinessStructureMassiveUpload);

const BusinessStructureMassiveUploadWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getBusinessStructureMassiveUploadModule()]}>
      <ConnectedBusinessStructureMassiveUpload />
    </DynamicModuleLoader>
  );
};
export default BusinessStructureMassiveUploadWithModules;
