import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  PROCESS__DATES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  PROCESS__DATES_MASSIVE_UPLOAD_REQUEST_VERIFY,
  PROCESS__DATES_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  processDatesMassiveUploadSetAllowUpload,
  processDatesMassiveUploadClearAllowUpload,
  processDatesMassiveUploadSetVerify,
  processDatesMassiveUploadClearVerify,
  processDatesMassiveUploadClearUpload,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';

import { processDatesMassiveUpload } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workProcessDatesMassiveUploadSetAllowUpload(action) {
  try {
    yield processDatesMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield processDatesMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield processDatesMassiveUploadClearAllowUpload();
    }
  }
}

function* workProcessDatesMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de Fechas de Proceso debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(processDatesMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
      },
    });
    if (responseOK(response)) {
      const { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(processDatesMassiveUploadSetVerify(data));
      yield call(summaryModal, message);
    } else {
      yield put(processDatesMassiveUploadClearVerify());
    }
  } catch (error) {
    yield processDatesMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield processDatesMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workProcessDatesMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, overwrite } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(processDatesMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite,
      },
    });
    if (responseOK(response)) {
      yield call(summaryEndModal, response.data, history, '/catalog/process-dates');
    } else {
      yield put(processDatesMassiveUploadClearUpload());
    }
  } catch (error) {
    yield processDatesMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield processDatesMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchProcessDatesMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(
      PROCESS__DATES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
      workProcessDatesMassiveUploadSetAllowUpload
    ),
  ]);
}

export function* watchProcessDatesMassiveUploadRequestVerify() {
  yield all([
    takeEvery(
      PROCESS__DATES_MASSIVE_UPLOAD_REQUEST_VERIFY,
      workProcessDatesMassiveUploadRequestVerify
    ),
  ]);
}

export function* watchProcessDatesMassiveUploadRequestUpload() {
  yield all([
    takeEvery(
      PROCESS__DATES_MASSIVE_UPLOAD_REQUEST_UPLOAD,
      workProcessDatesMassiveUploadRequestUpload
    ),
  ]);
}
