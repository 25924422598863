import React from 'react';
import { Row, Col, InputNumber, Modal, Form } from 'antd';
import { BInput } from 'common/components';
import { FormTitleDivider, ActionButtons } from 'common';
import { formatFunc, parserFunc, keyBlock } from '../utils';

const Tabla1 = ({ Switch3, setSwitch3, form }) => {
  const changeSwitch = (value) => {
    const actions = {
      true: 'desactivar',
      false: 'activar',
    };
    const actions2 = {
      true: 'Desactivar',
      false: 'Activar',
    };
    const message = {
      default: `¿Está seguro que desea ${actions[value]} la tabla?`,
      representative: `¿Está seguro que desea ${actions[value]} la tabla?`,
    };
    Modal.confirm({
      title: `${actions2[value]} tabla`,
      content: (
        <>
          <p>{`${value === true ? message.representative : message.default}`} </p>
          {`Al realizar esta acción
          la tabla ${value === true ? 'no ' : ''}se visualizará en el acuerdo comercial`}
        </>
      ),
      onOk() {
        setSwitch3({
          checked: value,
          disabled: false,
        });
        // if (Switch3.checked === true) {
        //   form.resetFields([
        //     'namePOG',
        //     'POGdiscount1',
        //     'POGdiscount2',
        //     'POGdiscount3',
        //     'POGdiscount4',
        //   ]);
        // }
      },
    });
  };
  return (
    <div>
      <Row>
        <Col span={3}>
          <FormTitleDivider title={'Tabla 3'} />
        </Col>
        <Col span={2}>
          <h3
            style={{
              textAlign: 'left',
              fontSize: '14px',
              color: 'rgb(33,160,210)',
              fontWeight: '400',
              height: '25px',
            }}
          >
            {Switch3.checked === false ? '*' : ''}Título de tabla:
          </h3>
        </Col>
        <Col span={8}>
          <BInput
            className="form-add-user-input"
            name="namePOG"
            maxlength="50"
            disabled={Switch3.checked === true}
          />
        </Col>

        {/* SWITCH */}
        <Col span={3}>
          <ActionButtons
            disabled={true}
            statusChange={changeSwitch}
            statusSwitch={Switch3}
            buttons={[]}
          />
        </Col>
      </Row>

      {/* DESCUENTOS */}
      {/* 1 */}
      <Row>
        <Col span={6}>
          <Form.Item name="POGdiscount1" label="Descuento 1:">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              formatter={formatFunc}
              parser={parserFunc}
              max={999.99}
              maxLength={6}
              min={0}
              step="0.01"
              label="Precio neto"
              onKeyDown={keyBlock}
              disabled={Switch3.checked === true}
            />
          </Form.Item>
        </Col>

        {/* 2 */}
        <Col span={6}>
          <Form.Item name="POGdiscount2" label="Descuento 2:">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              formatter={formatFunc}
              parser={parserFunc}
              max={999.99}
              maxLength={6}
              min={0}
              step="0.01"
              label="Precio neto"
              stringMode
              onKeyDown={keyBlock}
              disabled={Switch3.checked === true}
            />
          </Form.Item>
        </Col>

        {/* 3 */}
        <Col span={6}>
          <Form.Item name="POGdiscount3" label="Descuento 3:">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              formatter={formatFunc}
              parser={parserFunc}
              max={999.99}
              maxLength={6}
              min={0}
              step="0.01"
              label="Precio bruto"
              stringMode
              onKeyDown={keyBlock}
              disabled={Switch3.checked === true}
            />
          </Form.Item>
        </Col>

        {/* 4 */}
        <Col span={6}>
          <Form.Item name="POGdiscount4" label="Descuento 4:">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              formatter={formatFunc}
              parser={parserFunc}
              max={999.99}
              maxLength={6}
              min={0}
              step="0.01"
              label="Precio bruto"
              stringMode
              onKeyDown={keyBlock}
              disabled={Switch3.checked === true}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Tabla1;
