import React from 'react';
import { BInput, FormTitleDivider } from 'common';
import { Row, Col } from 'antd';
import { Currency } from 'RootModule/catalogs';

const ProductTaxes = ({ gutterValue, onFocusNumber, onBlurNumber }) => {
  const onFocusNum =
    typeof onFocusNumber === 'function'
      ? onFocusNumber
      : () => {
          return;
        };
  const onBlurNum =
    typeof onBlurNumber === 'function'
      ? onBlurNumber
      : () => {
          return;
        };

  return (
    <>
      <FormTitleDivider title={'Información de Impuestos'} />
      <Row gutter={gutterValue}>
        <Col xs={24} md={6}>
          {/* <BInput label="Moneda" name="currency" isRequired={true} disabled={false} maxLength={3} /> */}
          <Currency isRequired name="currency" className="form-add-user-input" label={'Moneda'} />
        </Col>
        <Col xs={24} md={6}>
          <BInput
            type="number"
            label="IVA"
            name="iva"
            step="0.01"
            max={99.99}
            min={0}
            suffix={<>%</>}
            onFocus={onFocusNum}
            onBlur={onBlurNum}
          />
        </Col>
        <Col xs={24} md={6}>
          <BInput
            type="number"
            label="IEPS"
            name="ieps"
            step="0.01"
            max={99.99}
            min={0}
            suffix={<>%</>}
            onFocus={onFocusNum}
            onBlur={onBlurNum}
          />
        </Col>
        {/* <NewInput>Moneda</NewInput>
        <NumberInput>IVA</NumberInput>
        <NumberInput>IEPS</NumberInput> */}
      </Row>
    </>
  );
};

export default ProductTaxes;
