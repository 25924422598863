export const REACH_REQUEST = 'REACH_REQUEST/SALES-CHALLENGE';
export const REACH_SUCCESS = 'REACH_SUCCESS/SALES-CHALLENGE';
export const REACH_FAIL = 'REACH_FAIL/SALES-CHALLENGE';

export const VALIDATE_REQUEST = 'VALIDATE_REQUEST/SALES-CHALLENGE';
export const VALIDATE_SUCCESS = 'VALIDATE_SUCCESS/SALES-CHALLENGE';
export const VALIDATE_FAIL = 'VALIDATE_FAIL/SALES-CHALLENGE';

export const SEND_TO_VALIDATE_REQUEST = 'SEND_TO_VALIDATE_REQUEST/SALES-CHALLENGE';
export const SEND_TO_VALIDATE_SUCCESS = 'SEND_TO_VALIDATE_SUCCESS/SALES-CHALLENGE';
export const SEND_TO_VALIDATE_FAIL = 'SEND_TO_VALIDATE_FAIL/SALES-CHALLENGE';

export const CHALLENGE_REQUEST = 'CHALLENGE_REQUEST/SALES-CHALLENGE';
export const CHALLENGE_SUCCESS = 'CHALLENGE_SUCCESS/SALES-CHALLENGE';
export const CHALLENGE_FAIL = 'CHALLENGE_FAIL/SALES-CHALLENGE';

export const CHALLENGE_COMMENTS_REQUEST = 'CHALLENGE_COMMENTS_REQUEST';
export const CHALLENGE_COMMENTS_SUCCESS = 'CHALLENGE_COMMENTS_SUCCESS';
export const CHALLENGE_COMMENTS_FAIL = 'CHALLENGE_COMMENTS_FAIL';

export const CLEAN_CHALLENGE = 'CLEAN_CHALLENGE';

export function cleanChallenge() {
  return {
    type: CLEAN_CHALLENGE,
  };
}

export function validateRequest(payload) {
  return {
    type: VALIDATE_REQUEST,
    payload,
  };
}

export function validateSuccess(payload) {
  return {
    type: VALIDATE_SUCCESS,
    payload,
  };
}

export function validateFail() {
  return {
    type: VALIDATE_FAIL,
  };
}

export function challengeRequest(payload) {
  return {
    type: CHALLENGE_REQUEST,
    payload,
  };
}

export function challengeSuccess(payload) {
  return {
    type: CHALLENGE_SUCCESS,
    payload,
  };
}

export function challengeFail() {
  return {
    type: CHALLENGE_FAIL,
  };
}

export function reachRequest(payload) {
  return {
    type: REACH_REQUEST,
    payload,
  };
}

export function reachSuccess(payload) {
  return {
    type: REACH_SUCCESS,
    payload: payload,
  };
}

export function reachFail() {
  return {
    type: REACH_FAIL,
  };
}

export function sendToValidateRequest(payload) {
  return {
    type: SEND_TO_VALIDATE_REQUEST,
    payload,
  };
}

export function sendToValidateSuccess(payload) {
  return {
    type: SEND_TO_VALIDATE_SUCCESS,
    payload,
  };
}

export function sendToValidateFail() {
  return {
    type: SEND_TO_VALIDATE_FAIL,
  };
}

export function challengeCommentsRequest(payload) {
  return {
    type: CHALLENGE_COMMENTS_REQUEST,
    payload,
  };
}

export function challengeCommentsSuccess(payload) {
  return {
    type: CHALLENGE_COMMENTS_SUCCESS,
    payload,
  };
}

export function challengeCommentsFail() {
  return {
    type: CHALLENGE_COMMENTS_FAIL,
  };
}
