import { all, put, takeLatest } from 'redux-saga/effects';

import { SET_CURRENT_VALIDATOR, setCurrentValidator } from '../actions/validationType';

function* workSetCurrentValidatorSaga(action) {
  yield put(setCurrentValidator(action.payload));
}

export function* watchSetCurrentValidatorSaga() {
  yield all([takeLatest(SET_CURRENT_VALIDATOR, workSetCurrentValidatorSaga)]);
}
