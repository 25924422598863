import CreditNotesReducers from './redux-saga/reducers';
import rootSagas from './redux-saga/sagas/rootSagas';
export default function creditNotesModule() {
  return {
    id: 'CREDITNOTESSECTION',
    reducerMap: {
      CREDITNOTESSECTION: CreditNotesReducers,
    },
    sagas: [rootSagas],
  };
}
