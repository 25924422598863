/* eslint-disable no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  consultFiltersSalesPeriodRequestOptions,
  consultFiltersSalesPeriodSetValue,
} from 'RootModule/redux/actions/consultFilters';
import { addAllBtnOnOptions, handleAllBtnClick, getPeriodOptions } from 'common/consults/utils';

import Filter from 'common/consults/components/Filter';

const SalesPeriod = (props) => {
  const {
    consultFiltersSalesPeriodSetValue: setValue,
    value,
    required,
    salesPeriodicity,

    disableSelectAll,
  } = props;
  const disableFilter = () => {
    return !(Array.isArray(salesPeriodicity) && salesPeriodicity.length > 0);
  };

  let options = getPeriodOptions(salesPeriodicity);

  let mappedOptions = [...options];

  let periodicityJson = JSON.stringify(salesPeriodicity);

  useEffect(() => {
    if (Array.isArray(salesPeriodicity) && salesPeriodicity.length > 0) {
      setValue([1]);
    } else {
      setValue([]);
    }
  }, [periodicityJson]);

  const selectChange = (selected) => {
    selected = handleAllBtnClick(selected, options);
    setValue(selected);
  };
  if (!disableSelectAll) {
    mappedOptions = addAllBtnOnOptions(options);
  }
  return (
    <Filter
      name={'Periodo compras'}
      required={required}
      value={value}
      selectChange={selectChange}
      options={mappedOptions}
      disabled={disableFilter()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    options: state?.APP?.consultFilters.salesPeriod?.options ?? [],
    value: state?.APP?.consultFilters.salesPeriod?.value,
    salesPeriodicity: state?.APP?.consultFilters.salesPeriodicity?.value,
  };
};

SalesPeriod.propTypes = {
  consultFiltersSalesPeriodSetValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  required: PropTypes.bool,

  disableSelectAll: PropTypes.bool,
};

SalesPeriod.defaultProps = {
  consultFiltersSalesPeriodRequestOptions: () => {},
  consultFiltersSalesPeriodSetValue: () => {},
  value: [],
  required: false,
  options: [],
  params: {},
  disableSelectAll: false,
};

export default connect(mapStateToProps, {
  consultFiltersSalesPeriodRequestOptions,
  consultFiltersSalesPeriodSetValue,
})(SalesPeriod);
