import React, { useState, useEffect } from 'react';
import { BusinessEstructureFilters } from '../../../common';

function BusinessForm({ props, handleAction, tag }) {
  const { selectors, multipleSelectors, requiredSelectors, update } = props;
  const [beForm, setBeForm] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [defaultValues, setDefaultValues] = useState(false);

  // useEffect(() => {
  //   setBeForm({});
  //   if (profile === 3) {
  //     setBsFilters(['subregions', 'territories', 'zones']);
  //   } else if (profile === 5) {
  //     setBsFilters(['zones']);
  //   }
  // }, [profile]);
  // useEffect(() => {
  //   if (beForm.lobs && profile === 8 && beForm.lobs.length > 0) {
  //     setDisabledClientSelect(false);
  //   } else {
  //     setDisabledClientSelect(true);
  //   }
  // }, [beForm.lobs, profile]);

  useEffect(() => {
    // if (userInfo) {
    //   let userProfile = userInfo.role;
    //   let multipleLobs = userProfile !== 3 || userProfile !== 5;
    //   let multipleSubregions = userProfile !== 5;
    //   let multipleTerritories = userProfile !== 5;
    //   setDefaultValues({
    //     region: JSON.stringify(userInfo?.region[0]),
    //     lobs: multipleLobs
    //       ? userInfo?.bussinesLines.map((el) => JSON.stringify(el))
    //       : JSON.stringify(userInfo?.bussinesLines[0]),
    //     subregions: multipleSubregions
    //       ? userInfo?.subRegion.map((el) => JSON.stringify(el))
    //       : JSON.stringify(userInfo?.subRegion[0]),
    //     territories: multipleTerritories
    //       ? userInfo?.territories.map((el) => JSON.stringify(el))
    //       : JSON.stringify(userInfo?.territories[0]),
    //     zones: userInfo?.zones.map((el) => JSON.stringify(el)),
    //     countries: JSON.stringify(userInfo?.country[0]),
    //   });
    // }
  }, []);

  const handleOnChange = (values) => {
    Object.keys(values).forEach((key) => {
      const value = values[key];
      handleAction({ name: key, value: value });
    });
  };

  return (
    <>
      <BusinessEstructureFilters
        selectors={selectors}
        requiredSelectors={requiredSelectors}
        parentTag={tag}
        update={update}
        value={beForm}
        defaultValues={defaultValues}
        onChange={(value) => {
          setBeForm(value);
          handleOnChange(value);
        }}
        multipleSelectors={multipleSelectors}
      />
    </>
  );
}
export default BusinessForm;
