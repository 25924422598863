import request from 'services/request';
import { paramsSerializer } from 'RootModule/services/catalogs';

export async function PUTImageLine({ cancelToken, data, endpoint }) {
  let uri = endpoint;
  return request(uri, {
    method: 'POST',
    cancelToken,
    data,
    paramsSerializer,
  });
}
