/* eslint-disable no-nested-ternary */
/**ALL UTILITIES  */
export const responseOK = (response) => {
  return typeof response !== 'undefined' && typeof response.data !== 'undefined';
};

export const filterObject = (object, fnFilter) => {
  if (typeof object !== 'undefined') {
    let objReturn = {};
    Object.keys(object).forEach((key) => {
      if (fnFilter(object[key])) {
        objReturn = {
          ...objReturn,
          [key]: object[key],
        };
      }
    });
    return objReturn;
  }
};

export const capitalizeString = (string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatToPrice = (value) => {
  let format = '';
  const exp = new RegExp(/(\d)(?=(\d{3})+\.)/g);
  if (typeof value !== 'undefined' && value !== null) {
    let price = parseFloat(`${value}`).toFixed(2).replace(exp, '$1,');
    format = `$ ${price}`;
  }
  return format;
};

export const formatNumber = (value) => {
  let format = '';
  const exp = new RegExp(/(\d)(?=(\d{3})+\.)/g);
  if (typeof value !== 'undefined' && value !== null) {
    format = parseFloat(`${value}`).toFixed(2).replace(exp, '$1,');
  }
  return format;
};

// export const formatPriceWithDecimals = (number) => {
//   if (typeof number !== 'undefined' && typeof number === 'number' && !isNaN(number)) {
//     return formatToPrice(number.toFixed(2)).toLocaleString();
//   }
//   return false;
// };

export const formatPriceWithDecimals = (number, removeSign) => {
  if (typeof number !== 'undefined' && typeof number === 'number' && !isNaN(number)) {
    if (removeSign) {
      let a = formatToPrice(number.toFixed(2)).toLocaleString();
      return a.substring(2, a.length);
    } else {
      return formatToPrice(number.toFixed(2)).toLocaleString();
    }
  }
  return false;
};

export const orderArrayObjects = (property, desc) => {
  const orderUp = desc ? false : true;
  return function (obj1, obj2) {
    if (orderUp) {
      return obj1[property] > obj2[property] ? 1 : obj1[property] < obj2[property] ? -1 : 0;
    } else {
      return obj1[property] < obj2[property] ? 1 : obj1[property] > obj2[property] ? -1 : 0;
    }
  };
};
