import React from 'react';
import { Transfer, Table, Empty } from 'antd';
import difference from 'lodash/difference';

const SectionTransfer = ({
  dataSource,
  targetKeys,
  onChange,
  showSearch,
  pagination,
  leftColumns,
  rightColumns,
  ...other
}) => {
  const TableTransfer = ({ tableLeftColumns, tableRightColumns, ...restProps }) => (
    <Transfer
      titles={['clientes con envío automático', 'clientes con envío manual']}
      locale={{
        itemUnit: '',
        itemsUnit: '',
        notFoundContent: 'No encontrado',
        searchPlaceholder: 'Escribe cliente o sold to',
      }}
      {...restProps}
      {...other}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;

        const rowSelection = {
          getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter((item) => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <>
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredItems}
              locale={{
                emptyText:
                  filteredItems.length > 0 ? null : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados'
                      }
                    />
                  ),
              }}
              size="small"
              style={{ pointerEvents: listDisabled ? 'none' : null }}
              onRow={({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) return;
                  onItemSelect(key, !listSelectedKeys.includes(key));
                },
              })}
            />
          </>
        );
      }}
    </Transfer>
  );
  return (
    <>
      <TableTransfer
        dataSource={dataSource}
        targetKeys={targetKeys}
        onChange={onChange}
        showSearch={showSearch}
        pagination={pagination}
        filterOption={(inputValue, item) =>
          item.name.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
          item.soldTo.toString().indexOf(inputValue) !== -1
        }
        tableLeftColumns={leftColumns}
        tableRightColumns={rightColumns}
      />
    </>
  );
};

export default SectionTransfer;
