import reducer from './redux-saga/reducers/';

import {
  watchProcessDatesConsultRequestConsult,
  watchProcessDatesConsultSetDateId,
  watchProcessDatesConsultSetStartDate,
  watchProcessDatesConsultSetEndDate,
  watchProcessDatesConsultRequestUpdate,
} from './redux-saga/sagas';

export default function getProcessDatesConsultModule() {
  return {
    id: 'PROCESS_DATES_CONSULT',
    reducerMap: {
      PROCESS_DATES_CONSULT: reducer,
    },
    sagas: [
      watchProcessDatesConsultRequestConsult,
      watchProcessDatesConsultSetDateId,
      watchProcessDatesConsultSetStartDate,
      watchProcessDatesConsultSetEndDate,
      watchProcessDatesConsultRequestUpdate,
    ],
  };
}
