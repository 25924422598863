import {
  PUT_ONE_UPDATEER_REQUEST,
  PUT_ONE_UPDATEER_SUCCESS,
  PUT_ONE_UPDATEER_CLEAN,
  PUT_ONE_STATUS_REQUEST,
  PUT_ONE_STATUS_SUCCESS,
  PUT_ONE_STATUS_CLEAN,
} from '../actions';

function updateERReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case PUT_ONE_UPDATEER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case PUT_ONE_UPDATEER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };

    case PUT_ONE_UPDATEER_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    //STATUS
    case PUT_ONE_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case PUT_ONE_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };

    case PUT_ONE_STATUS_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}

export default updateERReducer;
