import logsReguder from './redux-saga/reducers';
import rootSagas from './redux-saga/sagas/rootSagas';
export default function getLogsModule() {
  return {
    id: 'CONSULTLOG',
    reducerMap: {
      CONSULTLOG: logsReguder,
    },
    sagas: [rootSagas],
  };
}
