import { SubRegion, BusinessLine, BusinessLine2 } from 'RootModule/catalogs';
import { TableTransferSubregion, TableTransferClient } from './index';
import { getInPoliciyProductsRequest } from '../redux-saga/actions';
import React, { useState } from 'react';
import { FormTitleDivider } from 'common';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import Clients from './Clients';

const ProductosSelect = (props) => {
  const { selectSubTipo, IPPRData, setproductsPolicy, BRData } = props;
  const [dataBR] = useState([]);

  //Tablas dinamicas de Cliente, Estructura o Null
  const transfers = () => {
    if (selectSubTipo === 'estructura') {
      return (
        <TableTransferSubregion
          IPPRData={IPPRData}
          setproductsPolicy={setproductsPolicy}
          OutPoliciyProducts={BRData?.OutPoliciyProducts?.data}
          InPoliciyProducts={BRData?.InPoliciyProducts?.data}
        />
      );
    } else if (selectSubTipo === 'cliente') {
      return (
        <TableTransferClient
          IPPRData={IPPRData}
          setproductsPolicy={setproductsPolicy}
          OutPoliciyProducts={BRData?.OutPoliciyProducts?.data}
          InPoliciyProducts={BRData?.InPoliciyProducts?.data}
        />
      );
    } else {
      /* Retorna tabla vacia */
      return <TableTransferSubregion />;
    }
  };

  return (
    <div>
      <Row>
        <FormTitleDivider title={'Estructura comercial'} />
      </Row>
      <Row>
        {selectSubTipo === 'estructura' ? (
          /*  Linea de negocio ESTRUCTURA */
          <Col span={8}>
            <BusinessLine
              disabled
              isRequired
              className="form-add-user-input"
              label="Línea de negocio"
              askFromService={true}
            />
          </Col>
        ) : (
          /*  Linea de negocio CLIENTE */
          <Col span={8}>
            <BusinessLine2
              disabled
              name="lineIdClient"
              isRequired
              className="form-add-user-input"
              label="Línea de negocio"
              sourceId="Fixed"
              askFromService={dataBR.clientId ? true : false}
              requestParams={dataBR.clientId ? { ClientIds: dataBR.clientId } : null}
            />
          </Col>
        )}
        {selectSubTipo === 'estructura' ? (
          /* SUBREGION */
          <Col span={8}>
            <SubRegion
              isRequired
              name="subregionId"
              disabled={dataBR?.lineId > 0 ? false : true}
              requestParams={dataBR.lineId ? { BusinessLines: dataBR.lineId } : null}
            />
          </Col>
        ) : (
          /* CLIENTES */
          <Col span={8}>
            <Clients
              label="Cliente"
              isRequired
              optionKey="name"
              valueKey="clientId"
              labelKey="name"
              name="clientId"
              disabled
              askFromService={false}
            />
          </Col>
        )}
      </Row>
      {/* Tabla de tranferencia */}
      <Row>
        <FormTitleDivider title={'Información de producto'} />
      </Row>
      {transfers()}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    IPPRData: state?.UPDATE_BUSSINES_RULES?.updateBussinesRules?.data,
  };
}
export default connect(mapStateToProps, { getInPoliciyProductsRequest })(ProductosSelect);
