import rootReducer from './redux/reducers/catalogs';
import rootSagas from './redux/sagas/rootSagas';
export default function getNotificationsModule() {
  return {
    id: 'CATALOGS',
    reducerMap: {
      CATALOGS: rootReducer,
    },
    sagas: [rootSagas],
  };
}
