import React from 'react';
import { downloadExcelRequest } from 'RootModule/redux/actions/downloadExcel';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { DownloadOutlined } from '@ant-design/icons';
import getRootModule from 'RootModule';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'antd';

function BtnDownloadExcel({
  downloadExcelRequest: downloadExcelReq,
  loading,
  label,
  urlExcel,
  docName,
  ...other
}) {
  const handleOnClick = () => {
    downloadExcelReq({ urlExcel, docName });
  };

  return (
    <Button
      icon={<DownloadOutlined />}
      onClick={handleOnClick}
      loading={loading}
      type="primary"
      size="middle"
      {...other}
    >
      {label}
    </Button>
  );
}

BtnDownloadExcel.propTypes = {
  label: PropTypes.string,
};
BtnDownloadExcel.defaultProps = {
  label: 'Descargar Excel',
};

const mapStateToProps = (state) => {
  return {
    loading: state.APP?.downloadExcel?.loading,
  };
};
const ConnectedDownloadExcel = connect(mapStateToProps, {
  downloadExcelRequest,
})(BtnDownloadExcel);
const DownloadExcel = ({ urlExcel, docName }) => (
  <DynamicModuleLoader modules={[getRootModule()]}>
    <ConnectedDownloadExcel urlExcel={urlExcel} docName={docName} />
  </DynamicModuleLoader>
);
export default DownloadExcel;
