const integerFloat = (e) => {
  let inputId = Object.keys(e) ? Object.keys(e) : null;
  let keyId = inputId[0].toString() ? inputId[0].toString() : null;
  let inputValue = null;
  let Value = null;
  let floatTF = null;
  let intTF = null;
  let valueFloat = null;
  let valueInt = null;
  let alphabet = null;
  let patternFloat = /^\d{0,6}(\.\d{0,2})?$/;
  let patternInt = /^\d{0,6}$/;
  let pattern = /[^0-9.,]/;
  try {
    inputValue = Object.values(e);
    Value = inputValue[0].toString();
    valueFloat = Value.substr(0, 9);
    valueFloat = Number.parseFloat(valueFloat).toFixed(2);
    valueInt = Value.substr(0, 6);
    floatTF = valueFloat.match(patternFloat);
    intTF = valueInt.match(patternInt);
    alphabet = Value.match(pattern);
  } catch (error) {
    valueInt = null;
  }

  if (alphabet) {
    return { keyId, valueIF: null };
  } else if (floatTF) {
    return {
      keyId,
      valueIF: parseFloat(valueFloat),
    };
  } else if (intTF) {
    return { keyId, valueIF: valueInt };
  }
};
export default integerFloat;
