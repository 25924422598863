import reducer from './redux-sagas/reducers/';

import {
  watchSalesChallengeMassiveUploadSetAllowUpload,
  watchSalesChallengeMassiveUploadRequestVerify,
  watchSalesChallengeMassiveUploadRequestUpload,
} from './redux-sagas/sagas';

export default function getSalesChallengeMassiveUploadModule() {
  return {
    id: 'SALES_CHALLENGE_MASSIVE_UPLOAD',
    reducerMap: {
      SALES_CHALLENGE_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchSalesChallengeMassiveUploadSetAllowUpload,
      watchSalesChallengeMassiveUploadRequestVerify,
      watchSalesChallengeMassiveUploadRequestUpload,
    ],
  };
}
