import React from 'react';
import { Row, Col, Input, Form } from 'antd';

const ResumeIncentiveSection = ({ statusSwitch }) => {
  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item name="noteNCInc" label="Nota:">
            <Input.TextArea
              disabled={statusSwitch}
              placeholder="Escribe una nota"
              maxLength={500}
              autoSize
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default ResumeIncentiveSection;
