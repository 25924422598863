import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import Layout from '../Layout';
import { RootModulesMap } from './utils';
import { PrivatePdfViewer } from 'PdfViewer';

function PrivateRoutes({ mainRoutes }) {
  return (
    <Router>
      <Layout>
        <Switch>
          {mainRoutes &&
            mainRoutes.map((route, key) => {
              let Tagname = RootModulesMap[`${route.module}`];
              return <Route key={key} exact={true} path={`${route.path}`} component={Tagname} />;
            })}
          <Route
            key="pdfViewer"
            exact={true}
            path={'/pdf-viewer/:id'}
            component={PrivatePdfViewer}
          />

          <Redirect from="/*" to="/dashboard" />
        </Switch>
      </Layout>
    </Router>
  );
}

export default PrivateRoutes;
