export const ShoppingByFamily_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD =
  'ShoppingByFamily_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const ShoppingByFamily_MASSIVE_UPLOAD_REQUEST_VERIFY =
  'ShoppingByFamily_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const ShoppingByFamily_MASSIVE_UPLOAD_SET_VERIFY =
  'ShoppingByFamily_MASSIVE_UPLOAD_SET_VERIFY';
export const ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_VERIFY =
  'ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const ShoppingByFamily_MASSIVE_UPLOAD_REQUEST_UPLOAD =
  'ShoppingByFamily_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const ShoppingByFamily_MASSIVE_UPLOAD_SET_UPLOAD =
  'ShoppingByFamily_MASSIVE_UPLOAD_SET_UPLOAD';
export const ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_UPLOAD =
  'ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export const ShoppingByFamily_EXIST_STATEMENTS = 'ShoppingByFamily_EXIST_STATEMENTS';

export function ExistsStatements(payload) {
  return {
    type: ShoppingByFamily_EXIST_STATEMENTS,
    payload: payload,
  };
}
export function ShoppingByFamilyMassiveUploadSetAllowUpload(payload) {
  return {
    type: ShoppingByFamily_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function ShoppingByFamilyMassiveUploadClearAllowUpload(payload) {
  return {
    type: ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function ShoppingByFamilyMassiveUploadRequestVerify(payload) {
  return {
    type: ShoppingByFamily_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function ShoppingByFamilyMassiveUploadSetVerify(payload) {
  return {
    type: ShoppingByFamily_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function ShoppingByFamilyMassiveUploadClearVerify(payload) {
  return {
    type: ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function ShoppingByFamilyMassiveUploadRequestUpload(payload) {
  return {
    type: ShoppingByFamily_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function ShoppingByFamilyMassiveUploadSetUpload(payload) {
  return {
    type: ShoppingByFamily_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function ShoppingByFamilyMassiveUploadClearUpload(payload) {
  return {
    type: ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
