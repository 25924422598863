/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';
import { MdModeEdit } from 'react-icons/md';
import { IconContext } from 'react-icons';
import { ActiveSwitch } from 'RootModule/tableComponents';
const soldToSpanStyle = {
  color: '#000',
};

const consultTableSchema = [
  {
    key: 'clientIdUpdate',
    title: '',
    dataIndex: 'clientId',

    render: (item, record) => {
      return (
        <>
          <Link
            to={{
              pathname: `/account-status/dynamic-items/update/${item}`,
              state: { ...record },
            }}
          >
            <IconContext.Provider value={{ color: 'blue' }}>
              <MdModeEdit />
            </IconContext.Provider>
          </Link>
        </>
      );
    },
  },
  {
    key: 'status',
    dataIndex: 'status',
    title: '',
    render: (status, record) => {
      return (
        <ActiveSwitch
          originalStatus={status}
          id={record.clientId}
          extraParams={{ LineId: record.lineId, SubRegionId: record.subRegionId }}
          key={record.clientId + record.line + record.subregion + record.placement}
        />
      );
    },
  },
  {
    key: 'soldto',
    title: 'Sold To',
    dataIndex: 'soldto',
    render: (item, record) => {
      return (
        <Link
          to={{
            pathname: `/account-status/dynamic-items/detail/${record.clientId}`,
            state: { ...record },
          }}
        >
          <span style={soldToSpanStyle}>{item}</span>
        </Link>
      );
    },
  },
  {
    key: 'client',
    dataIndex: 'client',
    title: 'Cliente',
    render: (item, record) => {
      return (
        <Link
          to={{
            pathname: `/account-status/dynamic-items/detail/${record.clientId}`,
            state: { ...record },
          }}
        >
          <span style={soldToSpanStyle}>{item}</span>
        </Link>
      );
    },
  },
  {
    key: 'line',
    dataIndex: 'line',
    title: 'Línea de negocio',
  },
  {
    key: 'subregion',
    dataIndex: 'subregion',
    title: 'Subregión',
  },
  {
    key: 'segmentation',
    dataIndex: 'segmentation',
    title: 'Segmentación',
  },
  {
    key: 'placement',
    dataIndex: 'placement',
    title: 'Presencia',
  },
];
export default consultTableSchema;
