import React from 'react';
import ErrorColumnTitle from 'common/components/ErrorColumnTitle';

const massiveUploadTableColumns = [
  {
    key: 'errorPhase',
    title: (
      <ErrorColumnTitle
        errorMessages={[
          {
            fase: 'Fase 1',
            message: (
              <p>
                Registros para los cuales se omitió ingresar campos obligatorios, se ingresó un tipo
                de dato que no corresponde al solicitado en el campo o se excedió la longitud
                máxima.
              </p>
            ),
          },
          {
            fase: 'Fase 2',
            message: (
              <p>
                Registros de estructura comercial para los cuales se ingresó información de
                elementos que no se encuentran registrados en el sistema
              </p>
            ),
          },
          {
            fase: 'Fase 3',
            message: (
              <p>Registros que se encuentran repetidos dentro del archivo de carga masiva.</p>
            ),
          },
        ]}
      />
    ),
    dataIndex: 'errorPhase',
  },
  {
    key: 'region',
    title: 'Región',
    dataIndex: 'region',
  },
  {
    key: 'line',
    title: 'Línea de negocio',
    dataIndex: 'line',
  },
  {
    key: 'subRegion',
    title: 'Subregión',
    dataIndex: 'subRegion',
  },
  {
    key: 'territory',
    title: 'Territorio',
    dataIndex: 'territory',
  },
  {
    key: 'zone',
    title: 'Zona',
    dataIndex: 'zone',
  },
  {
    key: 'country',
    title: 'País',
    dataIndex: 'country',
  },
];
export default massiveUploadTableColumns;
