import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { IconsMap } from 'Layout/utils';
import 'common/components/DeleteProductModal/DeleteProductModal.css';
const ExclamationIcon = IconsMap.ExclamationCircleOutlined;

const RejectModal = ({ onOk, onCancel, isVisible }) => {
  const { TextArea } = Input;
  const [rejectNote, setRejectNote] = useState('');
  const handleOk = () => {
    onOk(rejectNote);
    setRejectNote('');
  };
  const handleCancel = () => {
    if (onCancel) {
      onCancel(rejectNote);
    }

    setRejectNote('');
  };
  return (
    <Modal
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: rejectNote === '',
      }}
      destroyOnClose={true}
    >
      <div className="header-modal">
        <ExclamationIcon style={{ fontSize: '1.5rem', alignSelf: 'center' }} />
        <p>Rechazar desafío de ventas</p>
      </div>
      <div className="body-modal" style={{ marginBottom: '1rem' }}>
        <p> ¿Está seguro que desea rechazar el desafío de ventas para la zona? </p>
        <TextArea
          rows={4}
          onChange={(e) => {
            setRejectNote(e.target.value);
          }}
          value={rejectNote}
          maxLength={300}
        />
      </div>
    </Modal>
  );
};

export default RejectModal;
