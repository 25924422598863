/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Maps, Filterbar, StructureCommercial /* PDFMapsButtons */ } from '../components';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import MapsModule from '../MapsModule';
import { layoutContext } from 'context';
import { useHistory, /*useParams,  */ withRouter } from 'react-router-dom';
import { Form, Row, Col, Skeleton } from 'antd';
import getRootModule from 'RootModule';
import { connect } from 'react-redux';
import {
  getGetRequest,
  getFilterYearRequest,
  getfileRequest,
  getDaysLeftRequest,
  getGetClean,
  getAgreementExistsRequest,
  getChallengeExistsRequest,
} from '../redux-saga/actions';
import { FormTitleDivider } from 'common';
import { FILTERS } from '../utils/constants';

const MapsForm = (props) => {
  //Props
  const {
    getGetRequest: getGet,
    getFilterYearRequest: getFilterYear,
    getfileRequest: getfile,
    getDaysLeftRequest: getDaysLeft,
    getGetClean: getGetCln,
    getAgreementExistsRequest: getAgreementExists,
    // getChallengeExistsRequest: getChallengeExists,
    mapsState,
  } = props;
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [filters, setfilters] = useState(null);
  const [yearProcess, setYearProcess] = useState(null);
  const [form] = Form.useForm();
  let history = useHistory();
  const nameRoles = {
    admin: 'Administrador',
    salesManager: 'Gerente de ventas',
    salesRepresentative: 'Representante de ventas',
  };

  //Mensaje advertencia de salida
  useEffect(() => {
    getAgreementExists({ history });
    getGetCln();
    onChangeActiveComponent('Maps');
    getFilterYear(history);
    getDaysLeft(history);
    form.setFieldsValue({ yearOptions: [yearProcess] });
    // seguda prueba getChallengeExists({ history });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Peticion por filtros
  useEffect(() => {
    let Year = filters?.yearOptions?.length > 0 ? filters.yearOptions : [yearProcess];
    if (filters?.zones?.length) {
      getGet({ Zones: filters?.zones, Year, history });
    } else if (filters?.areas?.length) {
      getGet({ Territories: filters?.areas, Year, history });
    } else if (filters?.subregions?.length) {
      getGet({ Subregions: filters?.subregions, Year, history });
    } else if (filters?.bussinesLine?.length) {
      getGet({ Lines: filters?.bussinesLine, Year, history });
    } else if (filters?.regions?.length) {
      getGet({ Regions: filters?.regions, Year, history });
    }
  }, [filters]);
  useEffect(() => {
    if (mapsState?.DaysLeft?.desafio === true) {
      form.setFieldsValue({ agreementChallenge: 2 });
    } else {
      if (mapsState?.DaysLeft?.desafio === false) {
        form.setFieldsValue({ agreementChallenge: 1 });
      }
    }
  }, [mapsState?.DaysLeft?.desafio]);

  useEffect(() => {
    if (mapsState) {
      setYearProcess(mapsState?.getFilterYear?.data?.yearProcess);
    }
  }, [mapsState]);

  useEffect(() => {
    form.setFieldsValue({ yearOptions: [yearProcess] });
  }, [yearProcess]);

  const OnValuesChange = (value, options, type) => {
    const { regions, bussinesLine, subregions, areas, zones } = form.getFieldsValue();
    if (
      regions?.length === 0 &&
      bussinesLine?.length === 0 &&
      (!subregions || subregions?.length === 0) &&
      (!areas || areas?.length === 0) &&
      (!zones || zones?.length === 0)
    ) {
      getGet({ Regions: [], Year: 2021, history });
      return;
    }

    if (type === FILTERS.yearOptions) {
      if (value.length === 0) {
        form.setFieldsValue({
          yearOptions: yearProcess,
        });
        const filtForm = form.getFieldsValue();
        setfilters(filtForm);
        return;
      }
    }
    if (Array.isArray(value)) {
      if (value?.length) {
        if (value.includes('ALL')) {
          if (value.length === options.length + 1) {
            form.setFieldsValue({ [type]: [] });
            const filtForm = form.getFieldsValue();
            setfilters(filtForm);
          } else {
            if (type === FILTERS.agreementChallenge) {
              if (mapsState?.DaysLeft?.desafio === true) {
                form.setFieldsValue({ agreementChallenge: 2 });
              } else {
                if (mapsState?.DaysLeft?.desafio === false) {
                  form.setFieldsValue({ agreementChallenge: 1 });
                }
              }
              const filtForm = form.getFieldsValue();
              setfilters(filtForm);
            } else {
              form.setFieldsValue({
                [type]: options.map((option) => {
                  return option.key;
                }),
              });
              const filtForm = form.getFieldsValue();
              const newAllValues = options.map((option) => option.key);
              const newFilterForm = {
                ...filtForm,
                [type]: newAllValues,
              };
              setfilters(newFilterForm);
            }
          }
        } else {
          const filtForm = form.getFieldsValue();
          setfilters(filtForm);
        }
      } else {
        const filtForm = form.getFieldsValue();
        setfilters(filtForm);
      }
    }
  };

  const onClearTerritories = (role, options) => {
    if (role.roleName === nameRoles.salesRepresentative) {
      const filtForm = form.getFieldsValue();
      const { zones } = filtForm;
      if (!zones) {
        setfilters(filtForm);
      } else {
        if (zones.length <= 0) {
          setfilters(filtForm);
        }
      }
    }
  };

  return (
    <>
      <div className={'module-card'}>
        <Form form={form}>
          {mapsState?.isLoading === true || filters === null ? (
            <Skeleton active />
          ) : (
            <Row>
              <Col xs={24} sm={24} md={11} lg={11}>
                <Maps mapsState={mapsState} filters={filters} />
                {filters?.agreementChallenge === 2 ? (
                  <FormTitleDivider title={'Desafío de ventas'} />
                ) : (
                  <FormTitleDivider title={'Acuerdo comercial'} />
                )}
              </Col>
              <Col xs={24} sm={24} md={13} lg={13}>
                <StructureCommercial mapsState={mapsState} filters={filters} getfile={getfile} />
              </Col>
            </Row>
          )}
          <Filterbar
            form={form}
            mapsState={mapsState}
            onChange={OnValuesChange}
            setfilters={setfilters}
            onClear={onClearTerritories}
            getGet={getGet}
          />
        </Form>
      </div>
    </>
  );
};
function mapStateToProps(state) {
  return {
    mapsState: state?.MAPS?.maps,
  };
}
const ConnectedBusinessRules = withRouter(
  connect(mapStateToProps, {
    getGetRequest,
    getFilterYearRequest,
    getfileRequest,
    getDaysLeftRequest,
    getGetClean,
    getAgreementExistsRequest,
    getChallengeExistsRequest,
  })(MapsForm)
);
const BusinessRules = () => (
  <DynamicModuleLoader modules={[getRootModule(), MapsModule()]}>
    <ConnectedBusinessRules />
  </DynamicModuleLoader>
);

export default BusinessRules;
