import { fork, all } from 'redux-saga/effects';

import * as filters from './filters';
import * as indicatorsSagas from './indicators';
import * as infoSagas from './info';
import * as reportSagas from './reports';
import * as registerSalesChallenge from './registerSalesChallenge';
import * as table from './table';
import * as graph from './graph';
import * as challenge from './challenge';
import * as addProduct from './addProduct';
import * as deleteProduct from './deleteProducts';
import * as rejectSalesChallenge from './rejectSalesChallenge';
import * as getFamilyInformation from './getFamilyInformation';

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(filters),
      ...Object.values(indicatorsSagas),
      ...Object.values(infoSagas),
      ...Object.values(reportSagas),
      ...Object.values(registerSalesChallenge),
      ...Object.values(table),
      ...Object.values(graph),
      ...Object.values(challenge),
      ...Object.values(addProduct),
      ...Object.values(deleteProduct),
      ...Object.values(rejectSalesChallenge),
      ...Object.values(getFamilyInformation),
    ].map(fork)
  );
}
