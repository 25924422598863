/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { addAllBtnOnOptions, handleAllBtnClick } from 'common/consults/utils';

import {
  consultFiltersSubregionRequestOptions,
  consultFiltersSubregionSetValue,
} from 'RootModule/redux/actions/consultFilters';

import Filter from 'common/consults/components/Filter';

const Subregion = (props) => {
  const {
    consultFiltersSubregionRequestOptions: requestOptions,
    consultFiltersSubregionSetValue: setValue,
    options,
    value,
    required,
    params,
    disabled,
    disableSelectAll,
    span,
  } = props;

  let mappedOptions = [...options];

  let jsonParams = JSON.stringify(params);

  useEffect(() => {
    let sentParams = {};
    if (params) {
      sentParams = { ...params };
    }
    requestOptions(sentParams);
  }, [jsonParams]);

  const selectChange = (selected) => {
    selected = handleAllBtnClick(selected, options);
    setValue(selected);
  };

  if (!disableSelectAll) {
    mappedOptions = addAllBtnOnOptions(options);
  }

  return (
    <Filter
      name={'Subregión'}
      required={required}
      value={value}
      selectChange={selectChange}
      options={mappedOptions}
      disabled={disabled}
      span={span}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    options: state?.APP?.consultFilters.subregion?.options ?? [],
    value: state?.APP?.consultFilters.subregion?.value,
  };
};

export default connect(mapStateToProps, {
  consultFiltersSubregionRequestOptions,
  consultFiltersSubregionSetValue,
})(Subregion);
