import { takeLatest, put, cancelled, call } from 'redux-saga/effects';
import instance from 'services/request';
import { responseOK } from 'SalesChallengeRefactor/utils';
import { IncentiveResumeSectionActions } from '../../actions';
import { requestSectionData } from '../../../services';

const {
  getIncentiveResume,
  incentiveResumeFail,
  cleanIncentiveResume,
  getIRSubregions,
  irSubRegionsFail,
  cleanIrSubRegions,
  INCENTIVE_RESUME_REQUEST,
  GET_INCENTIVE_RESUME,
  INCENTIVE_RESUME_FAIL,
  CLEAN_INCENTIVE_RESUME,
  IR_SUBREGIONS_REQUEST,
  GET_IR_SUBREGIONS,
  IR_SUBREGIONS_FAIL,
  CLEAN_IR_SUBREGIONS,
} = IncentiveResumeSectionActions;

function* reqSectionData(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSectionData, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getIncentiveResume(response.data ?? []));
    } else {
      yield put(incentiveResumeFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(incentiveResumeFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaRequestSectionData() {
  yield takeLatest(INCENTIVE_RESUME_REQUEST, reqSectionData);
}

export function* SagaGetSectionData() {
  yield takeLatest(GET_INCENTIVE_RESUME, getIncentiveResume);
}

export function* SagaSectionDataFail() {
  yield takeLatest(INCENTIVE_RESUME_FAIL, incentiveResumeFail);
}

export function* SagaSectionDataClean() {
  yield takeLatest(CLEAN_INCENTIVE_RESUME, cleanIncentiveResume);
}

function* reqIncentiveResumeSubRegions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSectionData, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getIRSubregions(response.data ?? []));
    } else {
      yield put(irSubRegionsFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(irSubRegionsFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaRequestIRSubRegions() {
  yield takeLatest(IR_SUBREGIONS_REQUEST, reqIncentiveResumeSubRegions);
}

export function* SagaGetIRSubregionsData() {
  yield takeLatest(GET_IR_SUBREGIONS, getIRSubregions);
}

export function* SagaIRSubRegionsFail() {
  yield takeLatest(IR_SUBREGIONS_FAIL, irSubRegionsFail);
}

export function* SagaIRSubregionsClean() {
  yield takeLatest(CLEAN_IR_SUBREGIONS, cleanIrSubRegions);
}
