/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from 'react';
import { Select } from 'antd';
import { PositionImageActions, TableActions } from '../../Redux-saga/actions';
import { openNotification } from 'common';
// Redux-Saga
import getRootModule from 'RootModule';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getManageImagesModule from '../../Redux-saga/getModule';
const { EditManageImagesPosition } = PositionImageActions;
const { GETImageHDClean, GETImageHDRequest } = TableActions;
const { Option } = Select;

const SelectEditPosition = (props) => {
  const {
    propsFather,
    EditManageImagesPosition: editPosition,
    //Obtiene imagen HD
    imageHD,
    GETImageHDRequest: GETImageHD,
    GETImageHDClean: ImageHDClean,
  } = props;
  const { file, dataRow, GETListImages, imagesGET } = propsFather;
  const [newPosition, setnewPosition] = useState(null);

  const comercialStructure = {
    LineId: { LineId: dataRow.lineId, Type: 1 },
    SubRegionId: { LineId: dataRow.lineId, SubregionId: dataRow.subregionId, Type: 2 },
    ClientId: {
      ClientId: dataRow.clientId,
      SubregionId: dataRow.subRegionId,
      LineId: dataRow.lineId,
      Type: 4,
    },
    TerritoryId: {
      TerritoryId: dataRow.territoryId,
      LineId: dataRow.lineId,
      SubregionId: dataRow.subRegionId,
      Type: 3,
    },
  };
  const okPUT = async () => {
    ImageHDClean();
    //Cambiar texto
    openNotification('success', `La asignación de prioridad a la imagen se realizó correctamente`);
    // Recargar imagenes de Estructura comercial especifica
    let dataGet = { listImages: comercialStructure[dataRow.type] };
    await GETListImages(dataGet);
  };

  //Get endpoint
  let endpoints = {
    LineId: {
      endpoint: '/AccountStatement/Api/v1/Files/ImageLine',
    },
    SubRegionId: {
      endpoint: '/AccountStatement/Api/v1/Files/ImageSubregion',
    },
    ClientId: {
      endpoint: '/AccountStatement/Api/v1/Files/ImageClient',
    },
    TerritoryId: {
      endpoint: '/AccountStatement/Api/v1/Files/ImageTerritory',
    },
  };
  useEffect(() => {
    if (imageHD?.image && newPosition > 0) {
      let dataEndpoint = endpoints[dataRow.type];
      // New file and position
      const { description, month, fileId, fileName } = file;

      let newPositionFile = {
        fileId,
        image: imageHD.image,
        fileName,
        month,
        period: newPosition,
        description,
        ...comercialStructure[dataRow.type],
      };
      editPosition({
        newPositionFile,
        dataEndpoint,
        Type: { ...comercialStructure[dataRow.type] },
        okPUT,
      });
    }
  }, [imageHD]);

  useEffect(() => {
    if (newPosition > 0) {
      GETImageHD({ FileId: file.fileId });
    }
  }, [newPosition]);
  return (
    <Select
      value={file.order}
      style={{ width: '100%', marginTop: '10px' }}
      onSelect={(position) => {
        if (position !== file.order) {
          setnewPosition(position);
        }
      }}
      key={`select${file.order}`}
    >
      {imagesGET.map((item, index) => {
        return (
          <Option value={index + 1} key={`order${index}`}>
            {index + 1}
          </Option>
        );
      })}
    </Select>
  );
};

const mapStateToProps = (state) => {
  return { imageHD: state?.MANAGE_IMAGES?.Table?.imageHD };
};

const ConnectedManageImages = connect(mapStateToProps, {
  EditManageImagesPosition,
  GETImageHDClean,
  GETImageHDRequest,
})(SelectEditPosition);

const SelectEdit = (propsFather) => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getManageImagesModule()]}>
      <ConnectedManageImages propsFather={propsFather} />
    </DynamicModuleLoader>
  );
};
const memoSelectEdit = memo(SelectEdit);

export default memoSelectEdit;
