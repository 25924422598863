/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../Sidebar/styles.css';
import { Button, Col, Collapse, Drawer, Form, Row } from 'antd';
import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import BSelect from '../../../common/components/BSelect';
import {
  policyTypesOps,
  salesVolume,
  valueTypeOps,
  viewCommercialAgreement,
} from '../../Sidebar/selectOptions';
import { FILTERS, nameRoles } from '../../Sidebar/sidebar';
import { getBusinessLines, getRegions, getSubregions, getTerritories } from '../../../services';
import { getUserRol } from '../../../services/dashboard/sales-challenge';
import {
  getAgreementStatus,
  getClientsSoldTo,
  getClientsTypes,
  getSegmentations,
} from '../Services/ComercialAgrementDashService';

const SidebarComercialAgreement = ({ onFiltersChanged, isLoading }) => {
  const { Panel } = Collapse;
  const [visible, setVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [loadingOps, setLoadingOps] = useState(false);
  const [regions, setRegions] = useState([]);
  const [lines, setLines] = useState([]);
  const [subRegions, setSubRegions] = useState([]);
  const [clientsTypes, setClientsTypes] = useState([]);
  const [role, setRole] = useState({});
  const [territories, setTerritories] = useState([]);
  const [segmentations, setSegmentations] = useState([]);
  const [clients, setClients] = useState([]);
  const [initialObj, setInitialObj] = useState({
    Lines: [],
    Regions: [],
    Subregions: [],
    Territories: [],
    View: '0',
    ProductPolicyTypes: [FILTERS.all],
    Status: [FILTERS.all],
    DataView: [1],
    ValueType: 0,
    ClientTypeIds: [],
    Segmentation: [],
    Clients: [],
  });
  const [status, setStatus] = useState([]);
  const [clientQuery, setCLientQuery] = useState('');
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();

  const refStates = {
    Regions: regions,
    Lines: lines,
    Territories: territories,
    Subregions: subRegions,
    ClientTypeIds: clientsTypes,
    Segmentation: segmentations,
    Clients: clients,
    ProductPolicyTypes: policyTypesOps,
    Status: status,
    DataView: salesVolume,
    ValueType: valueTypeOps,
  };

  useEffect(() => {
    setLoadingOps(true);
    getData()
      .then(() => {
        setLoadingOps(false);
      })
      .catch(() => {
        setLoadingOps(false);
      });
  }, []);

  useEffect(() => {
    if (clientQuery !== null && clientQuery !== undefined) {
      getClientsSoldTo(clientQuery).then((r) => {
        if (r) {
          setClients(r);
          handleChange([FILTERS.all], FILTERS.clients, renderSelectOptions(clients));
          initialObj.Clients = [];
          r.forEach((client) => {
            initialObj.Clients.push(client.soldTo);
          });
          handleChanges(initialObj);
        }
      });
    }
  }, [clientQuery]);

  useEffect(() => {
    handleChange([FILTERS.all], FILTERS.clients, renderSelectOptions(clients));
  }, [clients]);

  useEffect(() => {
    setInitialData().then();
  }, [regions, lines, role, status, segmentations, clientsTypes]);

  const getData = async () => {
    setRegions(await getRegions());
    setLines(await getBusinessLines());
    setSubRegions(await getSubregions());
    setTerritories(await getTerritories());
    setClientsTypes(await getClientsTypes());
    setSegmentations(await getSegmentations());
    setClients(await getClientsSoldTo());
    setStatus(await getAgreementStatus());
    setRole(getUserRol());
  };

  const firstRolesConditions = () => {
    return (
      role.roleId === nameRoles.salesManager ||
      role.roleId === nameRoles.liderAp ||
      role.roleId === nameRoles.userCons ||
      role.roleId === nameRoles.admin ||
      role.roleId === nameRoles.kam ||
      role.roleId === nameRoles.area
    );
  };

  const salesManagerRule = () => {
    return role.roleId === nameRoles.salesManager;
  };

  const representativeRule = () => {
    return role.roleId === nameRoles.salesRepresentative;
  };

  const setInitialData = async (reset) => {
    if (
      regions.length > 0 &&
      lines.length > 0 &&
      role?.roleId &&
      status.length > 0 &&
      clientsTypes.length > 0 &&
      segmentations.length > 0 &&
      clients.length > 0 &&
      subRegions.length > 0
    ) {
      handleChange(
        firstRolesConditions() || salesManagerRule() || representativeRule() ? [FILTERS.all] : [],
        FILTERS.regions,
        renderSelectOptions(regions)
      );
      handleChange(
        firstRolesConditions() || salesManagerRule() || representativeRule() ? [FILTERS.all] : [],
        FILTERS['bussines-line'],
        renderSelectOptions(lines)
      );
      handleChange(
        salesManagerRule() || representativeRule() ? [FILTERS.all] : [],
        FILTERS.subregions,
        renderSelectOptions(subRegions)
      );
      handleChange([FILTERS.all], FILTERS.productPolicyTypes, renderSelectOptions(policyTypesOps));
      handleChange(
        representativeRule() ? [FILTERS.all] : [],
        FILTERS.territories,
        renderSelectOptions(territories)
      );
      handleChange([FILTERS.all], FILTERS.agreementStatus, renderSelectOptions(status));

      handleChange([FILTERS.all], FILTERS.clientTypes, renderSelectOptions(clientsTypes));

      handleChange([FILTERS.all], FILTERS.clients, renderSelectOptions(clients));

      const allObj = {
        Lines:
          firstRolesConditions() || salesManagerRule() || representativeRule() ? [FILTERS.all] : [],
        Regions:
          firstRolesConditions() || salesManagerRule() || representativeRule() ? [FILTERS.all] : [],
        Subregions:
          salesManagerRule() || representativeRule() ? [FILTERS.all] : initialObj.Subregions,
        Territories: representativeRule() ? [FILTERS.all] : initialObj.Territories,
        View: initialObj.View,
        ProductPolicyTypes: [FILTERS.all],
        Status: [FILTERS.all],
        DataView: initialObj.DataView,
        ValueType: [0],
        ClientTypeIds: [],
        Segmentation: [],
        Clients: [],
      };

      if (reset) {
        allObj.Subregions = salesManagerRule() || representativeRule() ? [FILTERS.all] : [];
        allObj.Territories = representativeRule() ? [FILTERS.all] : [];
        allObj.DataView = '1';
        allObj.View = '0';
      }

      setInitialObj(allObj);

      if (firstRolesConditions() || salesManagerRule() || representativeRule()) {
        await regions.forEach((region) => {
          allObj.Regions.push(region.key);
        });
        await lines.forEach((line) => {
          allObj.Lines.push(line.key);
        });
      }
      if (salesManagerRule() || representativeRule()) {
        await subRegions.forEach((subregion) => {
          allObj.Subregions.push(subregion);
        });
      }
      if (representativeRule()) {
        await territories.forEach((territory) => {
          allObj.Territories.push(territory);
        });
      }
      await status.forEach((statusItem) => {
        allObj.Status.push(statusItem);
      });
      await clientsTypes.forEach((clientType) => {
        allObj.ClientTypeIds.push(clientType.key);
      });
      await clients.forEach((client) => {
        allObj.Clients.push(client.soldTo);
      });
      handleChanges(allObj);
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const hideDrawer = () => {
    setVisible(false);
  };

  const callbackTabOnChange = (key) => {
    setActiveTab(key);
  };

  const resetForm = () => {
    form.resetFields();
    setInitialData(true).then();
  };

  const renderSelectOptions = (arr) => {
    if (arr) {
      return arr.map((current) => {
        return {
          value: current.value ? current.value : current.name,
          key: current.key ? current.key : current.soldTo,
        };
      });
    }
  };

  const handleChange = (value, filter, options) => {
    if (!options) {
      return;
    }
    if (!Array.isArray(value)) {
      return;
    }
    if (value.length && value.includes('ALL')) {
      if (value.length === options.length + 1) {
        form.setFieldsValue({ [filter]: [] });
      } else {
        form.setFieldsValue({
          [filter]: options.map((option) => {
            return option.key ? option.key : option.soldTo;
          }),
        });
      }
    }
  };

  // this renders all selects

  const selectsMap = [
    {
      title: 'Región',
      name: FILTERS.regions,
      options: regions,
      selectAll: true,
      allowClear: true,
    },
    {
      title: 'Línea de negocio',
      name: FILTERS['bussines-line'],
      options: lines,
      selectAll: true,
      allowClear: true,
    },
    {
      title: 'Subregión',
      name: FILTERS.subregions,
      options: subRegions,
      selectAll: true,
      allowClear: true,
    },
    {
      title: 'Territorio',
      name: FILTERS.territories,
      options: territories,
      selectAll: true,
      allowClear: true,
    },
    {
      title: 'Vista',
      name: FILTERS.view,
      options: viewCommercialAgreement,
      initialValue: '0',
      selectAll: false,
      allowClear: false,
      unique: true,
    },
  ];

  const clientsSlectMap = [
    {
      title: 'Tipo de cliente',
      name: FILTERS.clientTypes,
      options: clientsTypes,
      selectAll: true,
      allowClear: true,
    },
    {
      title: 'Cliente',
      name: FILTERS.clients,
      options: clients,
      selectAll: true,
      allowClear: true,
    },
    {
      title: 'Segmentación',
      name: FILTERS.segmentations,
      options: segmentations,
      selectAll: true,
      allowClear: true,
    },
  ];

  const comAgreeSelectsMap = [
    {
      title: 'Política comercial - Productos',
      name: FILTERS.productPolicyTypes,
      options: policyTypesOps,
      selectAll: true,
      allowClear: true,
    },
    {
      title: 'Estatus de acuerdo comercial',
      name: FILTERS.agreementStatus,
      options: status,
      selectAll: true,
      allowClear: true,
    },
    {
      title: 'Ventas/Volumen',
      name: FILTERS.dataView,
      options: salesVolume,
      selectAll: false,
      allowClear: false,
      initialValue: '1',
      unique: true,
    },
    {
      title: 'Tipo de valor',
      name: FILTERS.valueType,
      options: valueTypeOps,
      selectAll: false,
      allowClear: false,
      initialValue: '0',
      unique: true,
    },
  ];

  const renderSelect = (item) => {
    if (formValues.DataView === '2' && item.name === FILTERS.valueType) {
      return <div />;
    } else {
      return (
        <div key={item.title}>
          <p className={'title'}>{item.title}</p>
          <BSelect
            disabled={isLoading}
            mode={item.unique ? '' : 'multiple'}
            name={item.name}
            options={renderSelectOptions(item.options)}
            label={''}
            loading={loadingOps}
            selectAll={item.selectAll}
            onChange={(data) => handleChange(data, item.name, renderSelectOptions(item.options))}
            allowClear={item.allowClear}
            initialValue={item.initialValue}
            maxTagCount={5}
          />
          {item.name === FILTERS.valueType &&
            (initialObj.ValueType === '0' || Array.isArray(initialObj.ValueType)) && (
              <span className={'danger-color'}>
                Los valores relacionados al desafío de ventas no se mostrarán, ya que el tipo de
                valor seleccionado es: Bruto
              </span>
            )}
        </div>
      );
    }
  };

  const handleChanges = (allValues, changed) => {
    setFormValues(allValues);
    if (changed) {
      const key = Object.keys(changed)[0];
      if (key && allValues[key] && Array.isArray(allValues[key])) {
        let newDataFilter;
        if (
          allValues[key][0] === 'ALL' ||
          allValues[key][allValues[key].length - 1] === FILTERS.all
        ) {
          newDataFilter = {
            [key]: refStates[key].map((value) => (value.key ? value.key : value.soldTo)),
          };
        } else if (
          allValues[key].includes('ALL') &&
          allValues[key].length === refStates[key].length + 1
        ) {
          newDataFilter = { [key]: [] };
        } else if (
          allValues[key].includes('ALL') &&
          allValues[key].length < refStates[key].length
        ) {
          newDataFilter = {
            [key]: refStates[key].map((value) => value.key ?? value.soldTo),
          };
        } else {
          newDataFilter = { [key]: allValues[key].filter((value) => value !== 'ALL') };
        }
        allValues[key] = newDataFilter[key];
      }
    }

    let currentFilters = '';
    for (const prop in allValues) {
      if (allValues[prop]) {
        const myValues = Array.from(allValues[prop]);
        if (!myValues.includes(FILTERS.all)) {
          myValues.forEach((values) => {
            const valueToAdd = () => {
              if (!currentFilters.length) {
                return `?${prop}=${values}`;
              }
              return `&${prop}=${values}`;
            };
            currentFilters = currentFilters + valueToAdd();
          });
        }

        if (myValues.includes(FILTERS.all)) {
          refStates[prop].forEach((item) => {
            const valueToAdd = () => {
              if (!currentFilters.length) {
                return `?${prop}=${item.key}`;
              }
              return `&${prop}=${item.key}`;
            };
            currentFilters = currentFilters + valueToAdd();
          });
        }
      }
    }

    if (currentFilters.includes('ProductPolicyTypes=2&ProductPolicyTypes=1')) {
      currentFilters = currentFilters.replace('ProductPolicyTypes=2&ProductPolicyTypes=1', '');
    }
    if (currentFilters.includes('ProductPolicyTypes=1&ProductPolicyTypes=2')) {
      currentFilters = currentFilters.replace('ProductPolicyTypes=1&ProductPolicyTypes=2', '');
    }

    const myParams = {
      queryString: currentFilters,
      challengeData: getChallengeData(allValues[FILTERS.dataView]),
      currentFilters: allValues,
    };
    let filtersQueriesClients = '';

    if (myParams.currentFilters.ClientTypeIds) {
      myParams.currentFilters.ClientTypeIds.forEach((item) => {
        filtersQueriesClients += `${filtersQueriesClients.length > 0 ? '&' : '?'}Placement=${item}`;
      });
    }
    if (myParams.currentFilters.Segmentation) {
      myParams.currentFilters.Segmentation.forEach((item) => {
        filtersQueriesClients += `${
          filtersQueriesClients.length > 0 ? '&' : '?'
        }Segmentation=${item}`;
      });
    }
    if (myParams.currentFilters.Lines) {
      myParams.currentFilters.Lines.forEach((item) => {
        if (item === FILTERS.all) {
          return;
        }
        filtersQueriesClients += `${
          filtersQueriesClients.length > 0 ? '&' : '?'
        }BusinessLines=${item}`;
      });
    }
    if (myParams.currentFilters.Subregions?.length > 0) {
      myParams.currentFilters.Subregions.forEach((item) => {
        if (item === FILTERS.all) {
          return;
        }
        filtersQueriesClients += `${filtersQueriesClients.length > 0 ? '&' : '?'}Subregions=${
          typeof item === 'number' ? item : item.key
        }`;
      });
    }
    if (myParams.currentFilters.Territories?.length > 0) {
      myParams.currentFilters.Territories.forEach((item) => {
        if (item === FILTERS.all) {
          return;
        }
        filtersQueriesClients += `${filtersQueriesClients.length > 0 ? '&' : '?'}Territories=${
          typeof item === 'number' ? item : item.key
        }`;
      });
    }
    setCLientQuery(filtersQueriesClients);

    if (myParams.currentFilters.Segmentation) {
      if (myParams.currentFilters.Segmentation.length === segmentations.length) {
        myParams.currentFilters.Segmentation = [];
      }
    }
    onFiltersChanged(myParams);

    function getChallengeData(value) {
      if (value) {
        return `${value}`;
      } else {
        return '';
      }
    }
  };

  return (
    <>
      <div className={'sidebar-mc-btn'}>
        <Button type="primary" onClick={showDrawer}>
          <FilterOutlined />
        </Button>
      </div>
      <div>
        <Drawer closable={false} mask={false} onClose={onClose} visible={visible}>
          <Row className="drawer-header">
            <Col span={1}>
              <FilterOutlined className="hand-cursor" onClick={hideDrawer} />
            </Col>
            <Col span={6} offset={4}>
              <p>Filtros</p>
            </Col>
            <Col span={1} offset={12}>
              <ReloadOutlined className="hand-cursor" onClick={resetForm} />
            </Col>
          </Row>
          <Form
            form={form}
            onValuesChange={(changedValues, allValues) => {
              const merged = { ...initialObj, ...allValues };
              setInitialObj(merged);
              handleChanges(merged, changedValues);
            }}
          >
            <Collapse
              accordion
              defaultActiveKey={['1']}
              activeKey={activeTab}
              onChange={callbackTabOnChange}
            >
              <Panel header="Estructura comercial" key="1" showArrow={false}>
                {selectsMap &&
                  selectsMap.map((item) => {
                    return renderSelect(item);
                  })}
              </Panel>
              <Panel header="Clientes" key="2" showArrow={false}>
                {clientsSlectMap &&
                  clientsSlectMap.map((item) => {
                    return renderSelect(item);
                  })}
              </Panel>
              <Panel header={'Acuerdo comercial'} key={3} showArrow={false}>
                {comAgreeSelectsMap &&
                  comAgreeSelectsMap.map((item) => {
                    return renderSelect(item);
                  })}
              </Panel>
            </Collapse>
          </Form>
        </Drawer>
      </div>
    </>
  );
};

SidebarComercialAgreement.propTypes = {
  onFiltersChanged: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SidebarComercialAgreement;
