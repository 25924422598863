import React, { useEffect, useState } from 'react';
import CreditNotesTable from './CreditNotesTable';
import { Row, Col } from 'antd';
import TopForm from './TopForm';
import { useHistory } from 'react-router';
import creditNotesModule from '../index';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { connect } from 'react-redux';
import { creditNotesRequest, cleanCreditNotes } from '../redux-saga/actions';

const CreditNotesIndex = ({
  creditNotesRequest: getCreditNotes,
  creditNotes,
  cleanCreditNotes: cleanNotes,
}) => {
  const [creditNotesData, setCreditNotesData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    let params = {};
    getCreditNotes({ params, history });
    return () => {
      cleanNotes();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCreditNotesData(creditNotes);
  }, [creditNotes]);

  return (
    <div>
      <>
        <Row>
          <Col span={24}>
            <TopForm />

            <CreditNotesTable
              creditNotesData={creditNotesData}
              noResultMessage={
                'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados.'
              }
            />
          </Col>
        </Row>
      </>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    creditNotes: state?.CREDITNOTESSECTION?.creditNotes ?? [],
  };
}

const LogWrapped = connect(mapStateToProps, {
  creditNotesRequest,
  cleanCreditNotes,
})(CreditNotesIndex);

const ConsultCreditNotes = () => (
  <DynamicModuleLoader modules={[creditNotesModule()]}>
    <LogWrapped />
  </DynamicModuleLoader>
);

export default ConsultCreditNotes;
