export const addKeyToResponse = (response) => {
  let data = [];
  if (response.data && response.data.length > 0) {
    response.data.forEach((element, key) => {
      data.push({
        key: key,
        ...element,
      });
    });
  }
  return data;
};
export const createParamString = (param, paramUriString) => {
  let element = param;
  let paramString = '';
  if (typeof element === 'string' || typeof element === 'number') {
    paramString = `${paramUriString}=${element}&`;
  } else if (typeof element === 'boolean') {
    paramString = `${paramUriString}=${element}&`;
  } else {
    element.forEach((item) => {
      paramString = `${paramString}${paramUriString}=${item}&`;
    });
  }
  return paramString;
};

export const queryBuilder = (query) => {
  let string = '?';
  if (query) {
    if (query.beSelects) {
      Object.keys(query.beSelects).forEach((filter) => {
        let param = '';
        if (filter === 'regions') param = 'Regions';
        if (filter === 'lobs') param = 'BusinessLines';
        if (filter === 'subregions') param = 'Subregions';
        if (filter === 'territories') param = 'Territories';
        if (filter === 'zones') param = 'Zones';
        if (filter === 'countries') param = 'Countries';
        if (filter === 'Enabled') param = 'Enabled';
        if (filter === 'ClientStatus') param = 'ClientStatus';
        if (filter === 'Status') param = 'Status';
        string = `${string}${createParamString(query.beSelects[filter], param)}`;
      });
    }
    if (query.yearReference) {
      string = `${string}${createParamString(query.yearReference, 'Year')}`;
    }
    if (query.datesSearch) {
      string = `${string}startDate=${query.datesSearch[0] ?? ''}&endDate=${
        query.datesSearch[1] ?? ''
      }&`;
    }
    if (query.searchInput) {
      let wildCardString = encodeURIComponent(query.searchInput);
      string = `${string}Wildcard=${wildCardString}`;
    }
  }
  return string;
};
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export const imagesToBlob = (file) => {
  let blobImages = file.map((input) => {
    let urlImage = URL.createObjectURL(input);
    return urlImage;
  });
  return blobImages;
};
