export const QUERY_SAVE = 'QUERY_SAVE/SALES-CHALLENGE';
export const QUERY_CLEAN = 'QUERY_CLEAN/SALES-CHALLENGE';

export function queryClean(payload) {
  return {
    type: QUERY_CLEAN,
    payload,
  };
}

export function querySave(payload) {
  return {
    type: QUERY_SAVE,
    payload,
  };
}

export const TOGGLE_TYPE_VALUE = 'TOGGLE_TYPE_VALUE/SALES-CHALLENGE';

export function toggleTypeValue(payload) {
  return {
    type: TOGGLE_TYPE_VALUE,
    payload: payload,
  };
}
