import request from 'services/request';

// /AccountStatement/Api/v1/Catalogs/Enabled/{clientId}/{status}
export async function accountStatementSetEnabledClient({
  cancelToken,
  clientId,
  status,
  extraParams,
}) {
  return request(
    `AccountStatement/Api/v1/Catalogs/Enabled/${clientId}/${status}/${extraParams.LineId}/${extraParams.SubRegionId}`,
    {
      method: 'PUT',
      cancelToken,
    }
  );
}
