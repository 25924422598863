import {
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_SHOW_MODAL_SET,
  DELETE_PRODUCT_CLOSE_MODAL,
} from '../actions/deleteProduct';

const deleteProduct = (state = { deleteProduct: { showModal: false } }, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_SHOW_MODAL_SET: {
      return {
        ...state,
        deleteProduct: {
          ...state.deleteProduct,
          showModal: action.payload.showModal,
        },
        productToDelete: action.payload.productToDelete,
      };
    }

    case DELETE_PRODUCT_CLOSE_MODAL: {
      return {
        ...state,
        deleteProduct: {
          ...state.deleteProduct,
          showModal: false,
        },
      };
    }

    case DELETE_PRODUCT_REQUEST: {
      return {
        ...state,
        deleteProduct: {
          loading: true,
        },
        resetFilters: false,
      };
    }

    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        deleteProduct: {
          loading: false,
        },
        resetFilters: false,
        query: {
          ...state.query,
          productId: false,
        },
      };
    case DELETE_PRODUCT_SUCCESS: {
      return {
        ...state,
        deleteProduct: {
          loading: false,
        },
        resetFilters: true,
        query: {
          ...state.query,
          productId: false,
        },
      };
    }

    default:
      return state;
  }
};

export default deleteProduct;
