import {
  ShoppingByFamily_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  ShoppingByFamily_MASSIVE_UPLOAD_REQUEST_VERIFY,
  ShoppingByFamily_MASSIVE_UPLOAD_SET_VERIFY,
  ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_VERIFY,
  ShoppingByFamily_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  ShoppingByFamily_MASSIVE_UPLOAD_SET_UPLOAD,
  ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  ShoppingByFamily_EXIST_STATEMENTS,
} from '../actions';

function ShoppingByFamilyMassiveUploadReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case ShoppingByFamily_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD:
      return {
        ...state,
        isLoading: false,
        allowUpload: action.payload,
      };

    case ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD:
      return {
        ...state,
        isLoading: false,
        allowUpload: null,
      };

    case ShoppingByFamily_MASSIVE_UPLOAD_REQUEST_VERIFY:
      return {
        ...state,
        isLoading: true,
        verify: null,
      };

    case ShoppingByFamily_MASSIVE_UPLOAD_SET_VERIFY:
      return {
        ...state,
        isLoading: false,
        verify: action.payload,
      };

    case ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_VERIFY:
      return {
        ...state,
        isLoading: false,
        verify: null,
      };

    case ShoppingByFamily_MASSIVE_UPLOAD_REQUEST_UPLOAD:
      return {
        ...state,
        isLoading: true,
        upload: null,
      };

    case ShoppingByFamily_MASSIVE_UPLOAD_SET_UPLOAD:
      return {
        ...state,
        isLoading: false,
        upload: action.payload,
      };

    case ShoppingByFamily_MASSIVE_UPLOAD_CLEAR_UPLOAD:
      return {
        ...state,
        isLoading: false,
        upload: null,
      };

    case ShoppingByFamily_EXIST_STATEMENTS:
      return {
        ExistsStatements: null,
      };

    default:
      return state;
  }
}

export default ShoppingByFamilyMassiveUploadReducer;
