import {
  SECOND_VALIDATION_VALID_USER_REQUEST,
  SECOND_VALIDATION_VALID_USER_SUCCESS,
  SECOND_VALIDATION_VALID_USER_FAIL,
} from '../actions/secondValidation';

const initialState = {
  loading: false,
  valid: false,
};

const secondValidation = (state = initialState, action) => {
  switch (action.type) {
    case SECOND_VALIDATION_VALID_USER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case SECOND_VALIDATION_VALID_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        valid: action.payload,
      };
    }

    case SECOND_VALIDATION_VALID_USER_FAIL: {
      return {
        ...state,
        loading: false,
        valid: false,
      };
    }

    default:
      return state;
  }
};

export default secondValidation;
