/* eslint-disable no-empty */
const integerFloat = (e) => {
  let inputId = Object.keys(e) ? Object.keys(e) : null;
  let keyId = inputId[0].toString() ? inputId[0].toString() : null;
  let inputValue = null;
  let Value = null;
  let floatTF = null;
  let intTF = null;
  let valueFloat = null;
  let valueInt = null;
  let alphabet = null;
  let hasAnE = null;
  let patternFloat = /^\d{0,2}(\.\d{1,2})?$/;
  let patternInt = /^\d{0,2}$/;
  let pattern = /[A-z]/g;
  let searchE = /[e]/g;
  try {
    inputValue = Object.values(e);

    Value = inputValue[0];

    hasAnE = Value.match(searchE);
    if (hasAnE) {
      let arr = Value.split('e');
      Value = arr.join('');
    }

    valueFloat = Value.substr(0, 5);
    valueFloat = Number.parseFloat(valueFloat).toFixed(2);
    valueInt = Value.substr(0, 2);

    floatTF = valueFloat.match(patternFloat);
    intTF = valueInt.match(patternInt);
    alphabet = Value.match(pattern);
  } catch (error) {}

  if (alphabet) {
    return { keyId, valueIF: null };
  } else if (floatTF) {
    return {
      keyId,
      valueIF: parseFloat(valueFloat),
    };
  } else if (intTF) {
    return { keyId, valueIF: parseInt(valueInt) };
  }
};
export default integerFloat;
