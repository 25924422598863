import {
  ACCOUNT_STATUS_PREVIEW_REQUEST_CONSULT,
  ACCOUNT_STATUS_PREVIEW_SET_CONSULT,
  ACCOUNT_STATUS_PREVIEW_CLEAR_CONSULT,
  ACCOUNT_STATUS_PREVIEW_REQUEST_PDF,
  ACCOUNT_STATUS_PREVIEW_SET_PDF,
  ACCOUNT_STATUS_PREVIEW_CLEAR_PDF,
  ACCOUNT_STATUS_PREVIEW_REQUEST_PERIOD_LIST,
  ACCOUNT_STATUS_PREVIEW_SET_PERIOD_LIST,
  ACCOUNT_STATUS_PREVIEW_CLEAR_PERIOD_LIST,
  ACCOUNT_STATUS_PREVIEW_SET_PDF_PAGE,
  ACCOUNT_STATUS_PREVIEW_CLEAR_PDF_PAGE,
  ACCOUNT_STATUS_PREVIEW_SET_PERIOD_VALUE,
  ACCOUNT_STATUS_PREVIEW_CLEAR_PERIOD_VALUE,
  ACCOUNT_STATUS_PREVIEW_REQUEST_GET_EXISTANCES,
  ACCOUNT_STATUS_PREVIEW_SET_GET_EXISTANCES,
  ACCOUNT_STATUS_PREVIEW_CLEAR_GET_EXISTANCES,
  ACCOUNT_STATUS_PREVIEW_SET_CURRENT_CLIENT_DATA,
  ACCOUNT_STATUS_PREVIEW_CLEAR_CURRENT_CLIENT_DATA,
  ACCOUNT_STATUS_PREVIEW_REQUEST_REGISTER_INTO_LOG,
  ACCOUNT_STATUS_PREVIEW_SET_REGISTER_INTO_LOG,
  ACCOUNT_STATUS_PREVIEW_CLEAR_REGISTER_INTO_LOG,
} from '../actions';

function accountStatusPreviewReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case 'INCORRECTO':
      state.nombreDePais = 'México';
      return state;
    case ACCOUNT_STATUS_PREVIEW_REQUEST_CONSULT:
      return {
        ...state,
        isLoading: true,
        consult: null,
      };

    case ACCOUNT_STATUS_PREVIEW_SET_CONSULT:
      return {
        ...state,
        isLoading: false,
        consult: action.payload,
      };

    case ACCOUNT_STATUS_PREVIEW_CLEAR_CONSULT:
      return {
        ...state,
        isLoading: false,
        consult: null,
      };

    case ACCOUNT_STATUS_PREVIEW_REQUEST_PDF:
      return {
        ...state,
        pdfIsLoading: true,
        pdf: null,
      };

    case ACCOUNT_STATUS_PREVIEW_SET_PDF:
      return {
        ...state,
        pdfIsLoading: false,
        pdf: action.payload,
      };

    case ACCOUNT_STATUS_PREVIEW_CLEAR_PDF:
      return {
        ...state,
        pdfIsLoading: false,
        pdf: null,
      };

    case ACCOUNT_STATUS_PREVIEW_REQUEST_PERIOD_LIST:
      return {
        ...state,
        isLoading: true,
        periodList: null,
      };

    case ACCOUNT_STATUS_PREVIEW_SET_PERIOD_LIST:
      return {
        ...state,
        isLoading: false,
        periodList: action.payload,
      };

    case ACCOUNT_STATUS_PREVIEW_CLEAR_PERIOD_LIST:
      return {
        ...state,
        isLoading: false,
        periodList: null,
      };

    case ACCOUNT_STATUS_PREVIEW_SET_PDF_PAGE:
      return {
        ...state,

        pdfPage: action.payload,
      };

    case ACCOUNT_STATUS_PREVIEW_CLEAR_PDF_PAGE:
      return {
        ...state,

        pdfPage: null,
      };

    case ACCOUNT_STATUS_PREVIEW_SET_PERIOD_VALUE:
      return {
        ...state,
        isLoading: false,
        periodValue: action.payload,
      };

    case ACCOUNT_STATUS_PREVIEW_CLEAR_PERIOD_VALUE:
      return {
        ...state,
        isLoading: false,
        periodValue: null,
      };

    case ACCOUNT_STATUS_PREVIEW_REQUEST_GET_EXISTANCES:
      return {
        ...state,
        isLoading: true,
        getExistances: null,
      };

    case ACCOUNT_STATUS_PREVIEW_SET_GET_EXISTANCES:
      return {
        ...state,
        isLoading: false,
        getExistances: action.payload,
      };

    case ACCOUNT_STATUS_PREVIEW_CLEAR_GET_EXISTANCES:
      return {
        ...state,
        isLoading: false,
        getExistances: null,
      };

    case ACCOUNT_STATUS_PREVIEW_SET_CURRENT_CLIENT_DATA:
      return {
        ...state,
        isLoading: false,
        currentClientData: action.payload,
      };

    case ACCOUNT_STATUS_PREVIEW_CLEAR_CURRENT_CLIENT_DATA:
      return {
        ...state,
        isLoading: false,
        currentClientData: null,
      };

    case ACCOUNT_STATUS_PREVIEW_REQUEST_REGISTER_INTO_LOG:
      return {
        ...state,
        isLoading: true,
        registerIntoLog: null,
      };

    case ACCOUNT_STATUS_PREVIEW_SET_REGISTER_INTO_LOG:
      return {
        ...state,
        isLoading: false,
        registerIntoLog: action.payload,
      };

    case ACCOUNT_STATUS_PREVIEW_CLEAR_REGISTER_INTO_LOG:
      return {
        ...state,
        isLoading: false,
        registerIntoLog: null,
      };

    default:
      return state;
  }
}

export default accountStatusPreviewReducer;
