import request from 'services/request';
const serviceURL = '/Catalogs/Api/v1/ProcessDates';

export async function consultProcessDates({ params, cancelToken }) {
  return request(serviceURL, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function updateProcessDate({ data, cancelToken }) {
  return request(serviceURL, {
    method: 'PUT',
    data,
    cancelToken,
  });
}
