import React from 'react';
import { Row, Col, Input, Form } from 'antd';

const SectionPog = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item name="introPog" label="Introducción:">
            <Input.TextArea placeholder="Escribe Introducción" maxLength={750} autoSize />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name="subt3" label="Subtítulo 3:">
            <Input.TextArea maxLength={250} placeholder="Escribe Subtítulo 3" autoSize />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name="notePog" label="Nota:">
            <Input.TextArea maxLength={500} placeholder="Escribe Nota" autoSize />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default SectionPog;
