import React from 'react';
import { Row, Col, Form, Input, Switch, Modal } from 'antd';

import '../css/style.css';

const SendInformationSection = ({ switchChecked, setSwitchChecked, changeStatus, statusData }) => {
  const { Item } = Form;
  const { confirm } = Modal;

  const showConfirm = () => {
    let { infoDeliverySchedule, autoPeriodDelivery } = statusData;
    confirm({
      title: 'Envío automático',
      content: switchChecked
        ? '¿Está seguro que desea asignar el estatus "En espera" al proceso de envío automático de estado de cuenta?'
        : '¿Está seguro que desea asignar el estatus "Listo para enviar" al proceso de envío automático de estado de cuenta?',

      onOk() {
        if (!(autoPeriodDelivery.length === 0 && switchChecked === false)) {
          setSwitchChecked(!switchChecked);
        }
        changeStatus({
          params: { status: !switchChecked, scheduleId: infoDeliverySchedule.scheduleDeliveryId },
        });
      },
      onCancel() {
        return;
      },
    });
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <Item name="date" label="Próximo envío programado">
            <Input placeholder="" disabled={true} style={{ width: '100%' }} />
          </Item>
        </Col>
        <Col span={4}>
          <Item label="Estatus de envío">
            <Switch
              checked={switchChecked}
              unCheckedChildren="En espera"
              checkedChildren="Listo para enviar"
              onClick={() => {
                showConfirm();
              }}
              className={'switchModal'}
            />
          </Item>
        </Col>
        <Col span={6}>
          <Item
            name="copyContact"
            label="*Contacto copia"
            maxLength={80}
            rules={[{ type: 'email', message: 'usuario@dominio.extensión' }]}
          >
            <Input />
          </Item>
        </Col>
      </Row>
    </>
  );
};

export default SendInformationSection;
