export const SET_CLIENT_KEY = 'SET_CLIENT_KEY';
export const CLEAN_CLIENT_KEY = 'CLEAN_CLIENT_KEY';

export const SET_CURRENT_CLIENT_DATA = 'SET_CURRENT_CLIENT_DATA';
export const CLEAN_CURRENT_CLIENT_DATA = 'CLEAN_CURRENT_CLIENT_DATA';

export function setCurrentClientData(payload) {
  return {
    type: SET_CURRENT_CLIENT_DATA,
    payload,
  };
}

export function cleanCurrentClientData() {
  return {
    type: CLEAN_CURRENT_CLIENT_DATA,
  };
}

export function setClientKey(payload) {
  return {
    type: SET_CLIENT_KEY,
    payload,
  };
}

export function cleanClientKey() {
  return {
    type: CLEAN_CLIENT_KEY,
  };
}
