import React from 'react';

const handleComments = (comment) => {
  return <div className={`container-comments`}>{comment}</div>;
};

export const defaultColumns = [
  {
    align: 'center',
    title: 'Estatus',
    key: 'status',
    alig: 'left',
    dataIndex: 'status',
    render: (_text, record) => record.status,
  },
  {
    align: 'center',
    title: 'Fecha',
    key: 'date',
    alig: 'left',
    dataIndex: 'date',
    /* render: (_text) => new Date(Date.parse(_text)).toLocaleDateString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) */
    render: (_text, record) =>
      record.registered.search('NaN') >= 0 ? record.date : record.registered,
  },
  {
    align: 'center',
    title: 'Comentarios',
    alig: 'center',
    key: 'comment',
    dataIndex: 'comment',
    width: 200,
    render: (text) => handleComments(text),
  },
  {
    align: 'center',
    title: 'Usuario',
    alig: 'left',
    key: 'user',
    dataIndex: 'user',
    render: (_text, record) => record.user,
  },
];
