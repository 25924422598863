/* eslint-disable no-empty-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classes from './modalBackgorund.module.css';
import { CloseOutlined } from '@ant-design/icons';

const ModalBackground = ({ mustShow, children, functionToClose, extraElement }) => {
  const wrapperStyles = {
    display: mustShow ? 'block' : 'none',
  };
  const onContentClick = (e) => {
    e.preventDefault();
  };
  return (
    <div style={wrapperStyles} className={classes.wrapper}>
      <CloseOutlined className={classes.closeX} onClick={functionToClose} />
      <div className={classes.content} onClick={onContentClick}>
        {children}
      </div>
      {extraElement}
    </div>
  );
};

export default ModalBackground;

ModalBackground.propTypes = {
  functionToClose: PropTypes.func,

  mustShow: PropTypes.bool,

  extraElement: PropTypes.func,
};

ModalBackground.defaultProps = {
  functionToClose: () => {},

  mustShow: false,
  extraElement: <></>,
};
