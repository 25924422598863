import { ExternalPageWrapper } from 'common';
import React from 'react';
import PrivatePdfViewer from '../Private';

const PublicPdfViewer = () => {
  return (
    <ExternalPageWrapper title={'Estado de cuenta'}>
      <PrivatePdfViewer />
    </ExternalPageWrapper>
  );
};

export default PublicPdfViewer;
