import request from 'services/request';
import apiUrls from '../utils/apiConstants';

export async function getLogs({ cancelToken, data = {} }) {
  const { logsUrl } = apiUrls;

  const { From, To, LogEntity, UserIds, Actions, PageNum, PageSize, Order } = data;

  let auxUserIds = '';
  let auxActions = '';

  if (UserIds && UserIds.length > 0) {
    if (UserIds.length > 1) {
      UserIds.map((p) => {
        if (p) auxUserIds += `UserIds=${p}&`;
        return null;
        //  else console.info('No data found');
      });
    } else {
      auxUserIds = `UserIds=${UserIds}&`;
    }
  }

  if (Actions && Actions.length > 0) {
    if (Actions.length > 1) {
      Actions.map((p) => {
        if (p) auxActions += `Actions=${p}&`;
        return null;
        //  else console.info('No data found');
      });
    } else {
      auxActions = `Actions=${Actions}&`;
    }
  }

  const cleanParams = () => {
    if (auxUserIds === '' && auxActions === '') {
      return '';
    } else {
      return `&${auxUserIds}${auxActions}`;
    }
  };

  let clean = cleanParams();

  const queryUrl = `${logsUrl}?From=${From}&To=${To}&PageNum=${PageNum}&PageSize=${PageSize}&Order=${Order}&LogEntity=${LogEntity}${clean}`;

  return request(queryUrl, {
    method: 'GET',
    cancelToken,
  });
}

export async function requestLogEntities({ cancelToken }) {
  const { logsEntities } = apiUrls;
  return request(logsEntities, {
    method: 'GET',
    cancelToken,
  });
}

export async function requestLogTypes({ cancelToken }) {
  const { logTypes } = apiUrls;
  return request(logTypes, {
    method: 'GET',
    cancelToken,
  });
}

export async function requestLogUsers({ cancelToken }) {
  const { logUsers } = apiUrls;
  return request(logUsers, {
    method: 'GET',
    cancelToken,
  });
}
