/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import React, { useState, useContext, useEffect } from 'react';
//UI Components

import { Row, Upload } from 'antd';
import { BButton } from 'common/components';
import RuleSelector from 'TradeAgreements/components/RuleSelector';
import RuleTypeTable from 'TradeAgreements/components/RuleTypeTable';
//Comon logic
import { validateTypeAndSize, handleSummaryOk, actionButtonsStyle } from 'Catalog/upload64';
import { openNotification, ruleTypeEnum, summaryModal, ActionButtons } from 'common';
import { toBase64 } from 'Catalog/services/upload64';
//Redux Modules
import { connect } from 'react-redux';
import getRootModule from 'RootModule';
import getTradeAgreementsModule from 'TradeAgreements/tradeModule';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import {
  massiveUploadRulesRequest,
  massiveUploadRulesClean,
  setRuleType,
  cleanRuleType,
  setRuleSubType,
  cleanRuleSubType,
} from 'TradeAgreements/redux/actions/rules';
//Context
import { layoutContext } from 'context';
//Router
import { useHistory } from 'react-router-dom';

const ruleTypesNames = Object.freeze({
  [ruleTypeEnum.PRODUCTOS_FUERA_DE_DISPONIBILIDAD]: 'Productos fuera de disponibilidad',
  [ruleTypeEnum.PROGRAMA_DE_INCENTIVOS]: 'Programa de incentivos',
  [ruleTypeEnum.PRODUCTOS_FUERA_DE_POLITICA]: 'Productos fuera de Politica',
  [ruleTypeEnum.PRODUCTOS_FUERA_DE_POLITICA_CLIENTE]: 'Productos fuera de Politica cliente',
});

const UploadMassiveRulesUnConnected = (props) => {
  const {
    massiveUploadRulesRequest: massiveUploadRulesReq,
    isUploading,
    dataSource,
    massiveUploadRulesClean: cleanMassive,
    uploadMessage,
    correctRegisters,
    totalErrors,
    setRuleType: setRule,
    cleanRuleType: cleanRule,
    setRuleSubType: setSubType,
    cleanRuleSubType: cleanSubType,
  } = props;
  const [selectedRule, setSelectedRule] = useState(null);
  const [duplicated, setDuplicated] = useState([]);
  const [replaced, setReplaced] = useState([]);
  const [stateFile, setStateFile] = useState(null);
  const [selectedSubType, setSelectedSubType] = useState('');
  const { onChangeActiveComponent } = useContext(layoutContext);
  const { push } = useHistory();
  const empty = () => {
    return;
  };
  const history = useHistory();
  useEffect(() => {
    cleanMassive();
    cleanRule();
    cleanSubType();
  }, [cleanMassive, cleanRule, cleanSubType]);

  useEffect(() => {
    onChangeActiveComponent('activo');
  }, []);

  const handleRuleChange = (ruleTypeId) => {
    setSelectedRule(ruleTypeId);
    setRule(ruleTypeId);
  };

  const handleSubRuleChange = (subTypeId) => {
    setSubType(subTypeId);
  };

  const handleCustomRequest = async (obj) => {
    const { file } = obj;
    const base64 = await toBase64(file);
    setStateFile(base64);
    if (validateTypeAndSize(file)) {
      massiveUploadRulesReq({
        intention: 'VALIDATION',
        requestParams: { overwrite: false, save: false, content: base64 },
        endpoint: selectedRule,
        //history,
        onSuccess: (data) => {
          summaryModal(data?.total, data?.totalErrors, empty);
          setDuplicated(data?.duplicated ?? []);
          setReplaced(data?.replaced ?? []);
        },
      });
    } else {
      openNotification(
        'error',
        `El formato del archivo de carga masiva de ${ruleTypesNames[selectedRule]} debe ser .xlsx y tener un tamaño máximo de 20 MB.`
      );
    }
  };

  const handleBeforeUpload = (obj) => {
    onChangeActiveComponent('businessRules');
  };

  const handleCancel = () => {
    push('/catalog');
  };

  const handleSubmit = () => {
    const templateLiterals = {
      paramName2: 'paramName2',
      title: 'Carga masiva de reglas de acuerdo comercial',
      param: '',
      paramName: '',
    };

    const onSuccess = () => {
      push('/catalog/businessRules');
    };

    const mantainAll = () => {
      massiveUploadRulesReq({
        intention: 'SAVING',
        requestParams: { overwrite: false, save: true, content: stateFile },
        endpoint: selectedRule,
        //history,
        onSuccess,
      });
    };
    const replaceAll = () => {
      massiveUploadRulesReq({
        intention: 'SAVING',
        requestParams: { overwrite: true, save: true, content: stateFile },
        endpoint: selectedRule,
        history,
        //onSuccess,
      });
    };

    if (duplicated.length > 0 || replaced.length > 0 || totalErrors > 0) {
      if (selectedRule === 3) {
        handleSummaryOk(
          selectedRule === 'cliente'
            ? 'Productos fuera de Politica cliente'
            : ruleTypesNames[selectedRule],
          replaced,
          templateLiterals,
          replaceAll,
          mantainAll
        );
      }

      if (selectedRule !== 3) {
        replaceAll();
      }
    } else {
      replaceAll();
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'space-around' }}>
      <RuleSelector
        onChange={handleRuleChange}
        setSelectedSubType={setSelectedSubType}
        setSelectedRule={setSelectedRule}
        handleSubRuleChange={handleSubRuleChange}
      />
      <Row justify="end">
        <Upload
          beforeUpload={handleBeforeUpload}
          showUploadList={false}
          customRequest={handleCustomRequest}
        >
          <BButton style={actionButtonsStyle} label="Cargar archivo" loading={isUploading} />
        </Upload>
      </Row>
      <Row>
        <div
          style={{
            background: 'white',
            minHeight: 450,
            border: '1px solid white',
            width: '100%',
            borderRadius: 12,
            margin: '6.5em 0 22px',
          }}
        >
          <RuleTypeTable
            ruleType={selectedRule}
            dataSource={dataSource}
            selectedSubType={selectedSubType}
            uploadMessage={uploadMessage}
            isRegister
          />
        </div>
      </Row>
      <Row justify="end" gutter={8}>
        <ActionButtons
          style={actionButtonsStyle}
          cancelStyle={{ ...actionButtonsStyle, backgroundColor: '#247ba9' }}
          submitStyle={{ ...actionButtonsStyle, backgroundColor: '#004a96' }}
          disabled={correctRegisters === 0}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isUploading: state.TRADE_AGREEMENTS?.consultRules?.massiveUpload?.isLoading ?? false,
    dataSource: state.TRADE_AGREEMENTS?.consultRules?.massiveUpload?.data ?? [],
    uploadMessage: state.TRADE_AGREEMENTS?.consultRules?.massiveUpload?.uploadMessage ?? '',
    correctRegisters: state.TRADE_AGREEMENTS?.consultRules?.massiveUpload?.correctRegisters ?? null,
    totalErrors: state.TRADE_AGREEMENTS?.consultRules?.massiveUpload?.totalErrors ?? null,
  };
}

const UploadMassiveRulesUnWrapped = connect(mapStateToProps, {
  massiveUploadRulesRequest,
  massiveUploadRulesClean,
  setRuleType,
  cleanRuleType,
  setRuleSubType,
  cleanRuleSubType,
})(UploadMassiveRulesUnConnected);

const UploadMassiveRules = () => (
  <DynamicModuleLoader modules={[getTradeAgreementsModule(), getRootModule()]}>
    <UploadMassiveRulesUnWrapped />
  </DynamicModuleLoader>
);

export default UploadMassiveRules;
