import request from '../../services/request';
const URL_SALESCHALLENGE = '/Sales/Api/v1/Challenge/';
const SALESCHALLENGE_PRODUCTS = `${URL_SALESCHALLENGE}Products/`;

export async function getProductsInfo({ params, cancelToken }) {
  return request(`${SALESCHALLENGE_PRODUCTS}Info/`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function getProductsPrices({ params, cancelToken }) {
  return request(`${SALESCHALLENGE_PRODUCTS}Prices/`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function postProducts({ data, cancelToken }) {
  return request(`${SALESCHALLENGE_PRODUCTS}Add/`, {
    method: 'POST',
    data,
    cancelToken,
  });
}

export async function getChallengePog({ params, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}Pog/`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function postChallenge({ data, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}`, {
    method: 'POST',
    data,
    cancelToken,
  });
}
