/* this function is related to the Pog periodicity, sales periodicity and pog period, sales period. 
you send the periodicity value and get the period options related to it */

const findOption = (array) => {
  if (array.find((item) => item === 3)) return 'Mensual';
  if (array.find((item) => item === 4)) return 'Bimestral';
  if (array.find((item) => item === 5)) return 'Trimestral';
  if (array.find((item) => item === 6)) return 'Cuatrimestral';
  if (array.find((item) => item === 2)) return 'Semestral';
  if (array.find((item) => item === 1)) return 'Anual';
};

const getPeriodOptions = (value) => {
  let option;
  let defaultVal = [];
  if (!value) return defaultVal;

  option = findOption(value);
  if (!option) return defaultVal;

  switch (option) {
    case 'Mensual':
      return [
        { key: 1, value: '01' },
        { key: 2, value: '02' },
        { key: 3, value: '03' },
        { key: 4, value: '04' },
        { key: 5, value: '05' },
        { key: 6, value: '06' },
        { key: 7, value: '07' },
        { key: 8, value: '08' },
        { key: 9, value: '09' },
        { key: 10, value: '10' },
        { key: 11, value: '11' },
        { key: 12, value: '12' },
      ];
    case 'Bimestral':
      return [
        { key: 1, value: '01' },
        { key: 2, value: '02' },
        { key: 3, value: '03' },
        { key: 4, value: '04' },
        { key: 5, value: '05' },
        { key: 6, value: '06' },
      ];
    case 'Trimestral':
      return [
        { key: 1, value: '01' },
        { key: 2, value: '02' },
        { key: 3, value: '03' },
        { key: 4, value: '04' },
      ];
    case 'Cuatrimestral':
      return [
        { key: 1, value: '01' },
        { key: 2, value: '02' },
        { key: 3, value: '03' },
      ];
    case 'Semestral':
      return [
        { key: 1, value: '01' },
        { key: 2, value: '02' },
      ];

    case 'Anual':
    default:
      return [{ key: 1, value: '01' }];
  }
};

export default getPeriodOptions;
