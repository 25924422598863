function ReducersTable(state = {}, action) {
  const { type, payload } = action;

  const REDUCER = {
    // Consulta ManageImages
    CONSULT_MANAGE_IMAGES_REQUEST: {
      isLoading: true,
    },
    CONSULT_MANAGE_IMAGES_SET: {
      consultTable: payload,
    },
    CONSULT_MANAGE_IMAGES_CLEAN: {},
    // Row Click Data
    ROW_CLICK_SET: {
      rowClick: payload,
    },
    // Input Files Camera
    INPUT_FILES_CAMERA_SET: {
      inputFilesCamera: payload,
    },
    //Obtiene la lista de imagenes por estructura comercial
    GET_LIST_IMAGES_REQUEST: {
      loadingImage: true,
      dataSection: payload,
      requestOk: false,
    },
    GET_LIST_IMAGES_SET: {
      listImagesGET: payload,
      loadingImage: false,
      requestOk: true,
    },
    GET_LIST_IMAGES_CLEAN: {
      listImagesGET: [],
      loadingImage: false,
    },
    //Obtiene imagen HD
    GET_IMAGE_HD_REQUEST: {
      loadImageHD: true,
    },
    GET_IMAGE_HD_SET: {
      imageHD: payload,
      loadImageHD: false,
    },
    GET_IMAGE_HD_CLEAN: {
      imageHD: [],
      loadImageHD: false,
    },
    // Change Image data
    CHANGE_IMAGE_SET: {
      changeImage: payload,
    },
    // Are multiple Files
    ARE_MULTIPLE_FILES_SET: {
      areMultipleFiles: payload,
    },
  };

  return { ...state, isLoading: false, ...REDUCER[type] };
}
export default ReducersTable;
