import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCatalog, DownloadCatalog } from './services';
import { catalogPermissions } from './utils';
import { Archive, openNotification } from '../common';
import { useTranslation } from 'react-i18next';
import { appContext, layoutContext } from '../context';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getUpdateProductModule from 'Products/ProductUpdate/getProductUpdateModule';
import getCatalogModule from 'Catalog/getCatalogModule';

function Catalogs() {
  const editedProduct = useSelector((state) => state.CATALOGS.processDates.editedProduct);
  const { t } = useTranslation(['catalogs']);
  const { modules } = useContext(appContext);
  const { onChangeActiveComponent } = useContext(layoutContext);
  let location = useLocation();
  let path = location.pathname;
  const catalogName = path.substring(path.lastIndexOf('/') + 1);
  const catalogType = location.pathname.includes('historic') ? 'historic-information' : 'catalogs';
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState(undefined);
  const [noResultsMessage, setNoResultsMessage] = useState(
    `No hay registros de ${t(`catalogs.${catalogName}`)} en el sistema`
  );
  const [columnSum, setColumnSum] = useState(undefined);
  const [columnSumValue, setColumnSumValue] = useState(undefined);
  const [years, setYears] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [showDownloadCatalogModal, setShowDownloadCatalogModal] = useState(false);
  const [downloadType, setDownloadType] = useState('Null');
  const [deleteType, setDeleteType] = useState('Null');
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  useEffect(() => {
    import(`./schemas/${catalogName}`).then((schema) => {
      typeof schema.columns === 'function'
        ? setColumns(schema.columns(data))
        : setColumns(schema.columns);
    });
    setPermissions(catalogPermissions(modules, catalogName));
  }, [catalogName, modules, data]);

  useEffect(() => {
    if (data) {
      const products = data.slice();
      if (products) {
        const productFound = products.find((product) => product.productId === editedProduct.id);
        if (productFound) {
          productFound.enabled = editedProduct.enabled;
          setData([...products]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedProduct]);

  const obtainCatalogData = () => {
    if (query) {
      setLoading(true);
      getCatalog(catalogName, query).then((response) => {
        setData(response.data);
        setColumnSum(response.sum ? response.sum : undefined);
        setColumnSumValue(response.sumValue ? '$' + response.sumValue : undefined);
        setYears(response.years ? response.years : undefined);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    obtainCatalogData();
  }, [query, catalogName]);

  const handleArchiveChange = (achiveQuery) => {
    if (Object.keys(achiveQuery).length > 0) {
      setNoResultsMessage(
        'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados'
      );
      onChangeActiveComponent(catalogType);
    }
    if (!loading) setQuery(achiveQuery);
  };

  const FilterHeaderChange = (pagination, filters, sorter, extra) => {
    if (extra.currentDataSource.length < data.length) {
      onChangeActiveComponent(catalogType);
    } else {
      onChangeActiveComponent(undefined);
    }
  };

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const toggleDownloadCatalogModal = () => {
    setShowDownloadCatalogModal(!showDownloadCatalogModal);
  };

  const handleDownloadCatalog = (archive) => {
    // If you want to download file automatically using link attribute.
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${archive}`;
    link.download = 'reporteCatalogoProd.xlsx';
    link.click();
  };

  const handleGetCatalog = async () => {
    let newObject;

    switch (catalogName) {
      case 'products':
        newObject = { ...query.beSelects, Enabled: downloadType };
        break;
      case 'clients':
      case 'prices':
        newObject = { ...query.beSelects, Status: downloadType };
        break;
      default:
        break;
    }
    query.beSelects = newObject;

    try {
      const response = await DownloadCatalog(query, catalogName);

      if (response) {
        if (response === null) {
          openNotification('error', 'Error al generar el archivo');
        }

        handleDownloadCatalog(response);
        openNotification('success', 'Catálogo generado correctamente');
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Archive
        onChange={handleArchiveChange}
        selectors={catalogName === 'clients' && ['region', 'lob']}
        beFilters={
          catalogName === 'business-estructure' ||
          catalogName === 'selling-goals' ||
          catalogName === 'products' ||
          catalogName === 'prices' ||
          catalogName === 'clients' ||
          catalogName === 'pog' ||
          catalogName === 'sales'
        }
        searchInput={
          catalogName === 'selling-goals' ||
          catalogName === 'clients' ||
          catalogName === 'products' ||
          catalogName === 'prices' ||
          catalogName === 'pog' ||
          catalogName === 'sales'
        }
        noResultsMessage={noResultsMessage}
        columnSum={columnSum}
        years={
          (catalogName === 'selling-goals' || catalogName === 'pog' || catalogName === 'sales') &&
          years
        }
        multipeYears={catalogName === 'pog' || catalogName === 'sales'}
        columnSumValue={columnSumValue}
        name={catalogName}
        columns={columns}
        data={data}
        dateRange={catalogName === 'exchange-rate' || catalogName === 'process-dates'}
        loading={loading}
        permissions={permissions}
        onFilterHeaderChange={FilterHeaderChange}
        showModal={showModal}
        toggleShowModal={toggleShowModal}
        showDownloadCatalogModal={showDownloadCatalogModal}
        toggleDownloadCatalogModal={toggleDownloadCatalogModal}
        downloadType={downloadType}
        setDownloadType={setDownloadType}
        deleteType={deleteType}
        setDeleteType={setDeleteType}
        handleGetCatalog={handleGetCatalog}
        deleteIsLoading={deleteIsLoading}
        setDeleteIsLoading={setDeleteIsLoading}
        obtainCatalogData={obtainCatalogData}
      />
    </>
  );
}

const CatalogsWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getUpdateProductModule(), getCatalogModule()]}>
      <Catalogs />
    </DynamicModuleLoader>
  );
};
export default CatalogsWithModules;
