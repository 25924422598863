import React from 'react';
import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
const LOCAL_NUMBER_OPTIONS = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};
const MEXICAN_MONEY_STYLE = { style: 'currency', currency: 'MXN' };

const renderColumnValue = (value) => {
  if (typeof value === 'string') {
    return value;
  } else if (value && value !== null && value !== undefined) {
    return `${Number(value).toLocaleString('es-MX', LOCAL_NUMBER_OPTIONS)}`;
  } else return '';
};

const tableColumns = [
  {
    key: 'phase',
    title: (
      <>
        <ErrorColumnTitle
          errorMessages={[
            {
              fase: 'Fase 1',
              message:
                'Registros para los cuales se omitió ingresar campos obligatorios, se ingresó un tipo de dato que no corresponde al solicitado en el campo o se excedió la longitud máxima.',
            },
            {
              fase: 'Fase 2',
              message:
                'Registros de clientes para los cuales se ingresó información de elementos con estatus "Inactivo", que no están registrados en el sistema o se ingresó información de estructura comercial que no está relacionada entre sí.',
            },
            {
              fase: 'Fase 3',
              message: 'Registros que se encuentran repetidos dentro del archivo de carga masiva.',
            },
          ]}
        />
      </>
    ),
    dataIndex: 'phase',
  },
  {
    key: 'soldTo',
    title: 'Sold to',
    dataIndex: 'soldTo',
  },
  {
    key: 'name',
    title: 'Nombre',
    dataIndex: 'clientName',
  },
  {
    key: 'termTime',
    title: 'Plazo en sistema',
    dataIndex: 'time',
  },
  {
    key: 'creditLmit',
    title: 'Limite de crédito',
    dataIndex: 'creditLimit',
    render: (value) => {
      if (typeof value === 'string') {
        return value;
      } else if (value && value !== null && value !== undefined) {
        return `${Number(value).toLocaleString('es-MX', MEXICAN_MONEY_STYLE)}`;
      } else return '';
    },
  },
  {
    key: 'presence',
    title: 'Presencia',
    dataIndex: 'presence',
  },
  {
    key: 'type',
    title: 'Tipo',
    dataIndex: 'type',
  },
  {
    key: 'businessLine',
    title: 'Línea de nogocio',
    dataIndex: 'line',
  },
  {
    key: 'segmentation',
    title: 'Segmentación',
    dataIndex: 'segmentation',
  },
  {
    key: 'commercialAgreement',
    title: '¿Requiere acuerdo comercial?',
    dataIndex: 'agreement',
  },
  {
    key: 'address',
    title: 'Dirección',
    dataIndex: 'address',
  },
  {
    key: 'territories',
    title: 'Territorio',
    dataIndex: 'territories',
  },
  {
    key: 'keyAccountPerson',
    title: 'Responsable clave de la cuenta',
    dataIndex: 'responsivePeople',
  },
  {
    key: 'company',
    title: 'Compañía',
    dataIndex: 'company',
  },
  {
    key: 'ownerName',
    title: 'Nombre del propietario o gerente',
    dataIndex: 'owner',
  },

  {
    key: 'signingName1',
    title: 'Nombre completo de firmante',
    dataIndex: 'signingName1',
  },
  {
    key: 'position1',
    title: 'Posición',
    dataIndex: 'position1',
  },
  {
    key: 'signingName2',
    title: 'Nombre completo de firmante',
    dataIndex: 'signingName2',
  },
  {
    key: 'position2',
    title: 'Posición',
    dataIndex: 'position2',
  },
  {
    key: 'signingName3',
    title: 'Nombre completo de firmante',
    dataIndex: 'signingName3',
  },
  {
    key: 'position3',
    title: 'Posición',
    dataIndex: 'position3',
  },
  {
    key: 'signingName4',
    title: 'Nombre completo de firmante',
    dataIndex: 'signingName4',
  },
  {
    key: 'position4',
    title: 'Posición',
    dataIndex: 'position4',
  },
  {
    key: 'signingName5',
    title: 'Nombre completo de firmante',
    dataIndex: 'signingName5',
  },
  {
    key: 'position5',
    title: 'Posición',
    dataIndex: 'position5',
  },
  {
    key: 'signingName6',
    title: 'Nombre completo de firmante',
    dataIndex: 'signingName6',
  },
  {
    key: 'position6',
    title: 'Posición',
    dataIndex: 'position6',
  },

  {
    key: 'contactName',
    title: 'Nombre completo',
    dataIndex: 'contactName',
  },
  {
    key: 'department',
    title: 'Departamento',
    dataIndex: 'department',
  },
  {
    key: 'email',
    title: 'Correo Electrónico',
    dataIndex: 'email',
  },
  {
    key: 'branches',
    title: 'Número de sucursales',
    dataIndex: 'branches',
    render: (value) => renderColumnValue(value),
  },
  {
    key: 'clientNum',
    title: 'Número de clientes',
    dataIndex: 'clientNum',
    render: (value) => renderColumnValue(value),
  },
  {
    key: 'managerNum',
    title: 'Número de representantes de venta',
    dataIndex: 'managerNum',
    render: (value) => renderColumnValue(value),
  },
  {
    key: 'techNum',
    title: 'Número de representantes técnicos',
    dataIndex: 'techNum',
    render: (value) => renderColumnValue(value),
  },
  {
    key: 'invoicing',
    title: 'Facturación total de agroquímicos del año anterior',
    dataIndex: 'invoicing',
    render: (value) => renderColumnValue(value),
  },
  {
    key: 'currency',
    title: 'Moneda de facturación',
    dataIndex: 'currency',
  },
  {
    key: 'inconterm',
    title: 'Tipo de incoterm',
    dataIndex: 'inconterm',
  },
  {
    key: 'pogType',
    title: 'Tipo de selección de POG',
    dataIndex: 'pogType',
  },
  {
    key: 'agreementCurrency',
    title: 'Moneda de acuerdo comercial',
    dataIndex: 'agreementCurrency',
  },
];
export default tableColumns;
