export const CONSULT_RULES_REQUEST = 'CONSULT_RULES_REQUEST/TRADE-AGREEMENTS';
export const CONSULT_RULES_SUCCESS = 'CONSULT_RULES_SUCCESS/TRADE-AGREEMENTS';
export const CONSULT_RULES_FAIL = 'CONSULT_RULES_FAIL/TRADE-AGREEMENTS';
export const CONSULT_RULES_CLEAN = 'CONSULT_RULES_CLEAN/TRADE-AGREEMENTS';

export function consultRulesRequest(payload) {
  return {
    type: CONSULT_RULES_REQUEST,
    payload,
  };
}

export function consultRulesSuccess(payload) {
  return {
    type: CONSULT_RULES_SUCCESS,
    payload,
  };
}

export function consultRulesFail(payload) {
  return {
    type: CONSULT_RULES_FAIL,
    payload,
  };
}

export function consultRulesClean(payload) {
  return {
    type: CONSULT_RULES_CLEAN,
    payload,
  };
}

export const MASSIVE_UPLOAD_RULES_REQUEST = 'MASSIVE_UPLOAD_RULES_REQUEST/TRADE-AGREEMENTS';
export const MASSIVE_UPLOAD_RULES_SUCCESS = 'MASSIVE_UPLOAD_RULES_SUCCESS/TRADE-AGREEMENTS';
export const MASSIVE_UPLOAD_RULES_FAIL = 'MASSIVE_UPLOAD_RULES_FAIL/TRADE-AGREEMENTS';
export const MASSIVE_UPLOAD_RULES_CLEAN = 'MASSIVE_UPLOAD_RULES_CLEAN/TRADE-AGREEMENTS';

export function massiveUploadRulesRequest(payload) {
  return {
    type: MASSIVE_UPLOAD_RULES_REQUEST,
    payload,
  };
}

export function massiveUploadRulesSuccess(
  payload,
  uploadMessage = '',
  correctRegisters,
  totalErrors = 0
) {
  return {
    type: MASSIVE_UPLOAD_RULES_SUCCESS,
    payload,
    uploadMessage,
    correctRegisters,
    totalErrors,
  };
}

export function massiveUploadRulesFail(payload) {
  return {
    type: MASSIVE_UPLOAD_RULES_FAIL,
    payload,
  };
}

export function massiveUploadRulesClean(payload) {
  return {
    type: MASSIVE_UPLOAD_RULES_CLEAN,
    payload,
  };
}

export const SET_RULE_TYPE = 'SET_RULE_TYPE';
export const CLEAN_RULE_TYPE = 'CLEAN_RULE_TYPE';

export function setRuleType(payload) {
  return {
    type: SET_RULE_TYPE,
    payload,
  };
}

export function cleanRuleType(payload) {
  return {
    type: CLEAN_RULE_TYPE,
    payload,
  };
}

export const SET_RULE_SUB_TYPE = 'SET_RULE_SUB_TYPE';
export const CLEAN_RULE_SUB_TYPE = 'CLEAN_RULE_SUB_TYPE';

export function setRuleSubType(payload) {
  return {
    type: SET_RULE_SUB_TYPE,
    payload,
  };
}

export function cleanRuleSubType(payload) {
  return {
    type: CLEAN_RULE_SUB_TYPE,
    payload,
  };
}
