import React from 'react';
import { Card, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import './BCards.css';
function BCards(props) {
  const { title, children, headerType, align, loading, className, ...other } = props;
  const setHeaderCardType = () => {
    let classType = '';
    switch (headerType) {
      case 'default':
        classType = 'bcard bcard-default-header';
        break;
      case 'info':
        classType = 'bcard bcard-info-header';
        break;
      case 'initial':
        classType = 'bcard bcard-initial-header';
        break;
      case 'right':
        classType = 'bcard-right-column-header';
        break;
      default:
        break;
    }
    return `${classType} ${className}`;
  };
  const generateTitle = () => {
    let applyTitlePopUp;
    if (title) applyTitlePopUp = title.length > 28 ? { title } : {};
    return (
      <h3 {...applyTitlePopUp} className={`text-${align}`}>
        {title}
      </h3>
    );
  };
  return (
    <Card
      align={align}
      title={generateTitle()}
      {...other}
      bordered={false}
      hoverable
      className={setHeaderCardType()}
    >
      {!loading && children}
      {loading && <Skeleton loading={loading} size="small" paragraph={{ rows: 2 }} active />}
    </Card>
  );
}

BCards.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  align: PropTypes.oneOf(['center', 'right', 'left']),
  loading: PropTypes.bool,
};

BCards.defaultProps = {
  title: '',
  children: null,
  headerType: 'default',
  align: 'left',
  loading: false,
};

export default BCards;
