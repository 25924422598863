/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Drawer, Spin } from 'antd';
import ChildrenDrawer from 'AccountStatus/ManageImages/utils/ChildrenDrawer';
import { ActionButtons, openNotification } from 'common';
import './drawer.css';
import getRootModule from 'RootModule';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getManageImagesModule from '../../Redux-saga/getModule';
import { DrawerEditActions, TableActions } from '../../Redux-saga/actions';
const { InputEditImagesSet, PUTImageLineRequest, DataDrawerEditSet } = DrawerEditActions;
const { InputFilesCameraSet, GETImageHDClean, GETListImagesRequest } = TableActions;
const DrawerEditComponent = (props) => {
  const {
    //Loading Back
    isLoading,
    // Imagenes Input en base64
    InputEditImagesSet: setInputEditImages,
    //Input Files Camera
    InputFilesCameraSet: setinputFiles,
    //PUT Image Line
    PUTImageLineRequest: PUTImageLine,
    //Datos de la fila que se le hizo Click
    rowClickData,
    //Obtiene imagen HD
    GETImageHDClean: ImageHDClean,
    imageHD,
    // Props desde SectionImages
    // Datos previos de la tabla al Drawer Edit
    DataDrawerEditSet: setdataDrawerEdit,
    dataDrawerEdit,
    // Obtiene imagenes por estructura comercial
    GETListImagesRequest: GETListImages,
  } = props;
  const [valueMonth, setvalueMonth] = useState([]);
  const [valuePosition, setvaluePosition] = useState(0);
  const [valueDescription, setvalueDescription] = useState('');
  const [valueImage, setvalueImage] = useState('');
  const [valueName, setvalueName] = useState('');
  const [valueFileId, setvalueFileId] = useState('');
  const [btnDisable, setbtnDisable] = useState(true);

  useEffect(() => {
    // Destructuring data
    if (dataDrawerEdit.length) {
      let { month, description, order, image, fileName, fileId } = dataDrawerEdit[0];
      setvalueImage(image);
      setvalueMonth(month);
      setvalueDescription(description);
      setvaluePosition(order);
      setvalueName(fileName);
      setvalueFileId(fileId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDrawerEdit]);

  useEffect(() => {
    // Sustituir imagen si hay HD
    if (imageHD?.image) {
      setvalueImage(imageHD?.image);
    }
  }, [imageHD]);

  const onChangeTextArea = (e) => {
    setvalueDescription(e.target.value);
  };

  // Habilitar botones
  const onChangeMonth = (list) => {
    setvalueMonth(list);
  };
  useEffect(() => {
    if (valueMonth?.length) {
      setbtnDisable(false);
    } else {
      setbtnDisable(true);
    }
  }, [valueMonth]);

  const comercialStructure = {
    LineId: { LineId: rowClickData.lineId, Type: 1 },
    SubRegionId: { SubregionId: rowClickData.subregionId, LineId: rowClickData.lineId, Type: 2 },
    ClientId: {
      ClientId: rowClickData.clientId,
      SubregionId: rowClickData.subRegionId,
      LineId: rowClickData.lineId,
      Type: 4,
    },
    TerritoryId: {
      TerritoryId: rowClickData.territoryId,
      LineId: rowClickData.lineId,
      SubregionId: rowClickData.subRegionId,
      Type: 3,
    },
  };
  const endpoints = {
    LineId: {
      endpoint: '/AccountStatement/Api/v1/Files/ImageLine',
    },
    SubRegionId: {
      endpoint: '/AccountStatement/Api/v1/Files/ImageSubregion',
    },
    ClientId: {
      endpoint: '/AccountStatement/Api/v1/Files/ImageClient',
    },
    TerritoryId: {
      endpoint: '/AccountStatement/Api/v1/Files/ImageTerritory',
    },
  };
  const okPUT = async () => {
    setvalueMonth([]);
    setvaluePosition(0);
    setvalueDescription('');
    setInputEditImages([]);
    openNotification(
      'success',
      `La modificación de la información de la imagen de estado de cuenta se realizó correctamente`
    );

    // Recargar imagenes de Estructura comercial especifica
    let dataGet = { listImages: comercialStructure[rowClickData.type] };
    await GETListImages(dataGet);

    setdataDrawerEdit([]);
  };
  //Acciones de Botones
  const onSubmit = () => {
    let dataEndpoint = endpoints[rowClickData.type];
    let imageInfo = {
      fileId: valueFileId,
      image: valueImage,
      fileName: valueName,
      month: valueMonth,
      period: valuePosition,
      description: valueDescription,
      ...comercialStructure[rowClickData.type],
    };

    PUTImageLine({ imageInfo, dataEndpoint, okPUT });
  };
  const onCancel = () => {
    ImageHDClean();
    setdataDrawerEdit([]);
    setInputEditImages([]);
    setinputFiles([]);
    setvalueMonth([]);
    setvaluePosition(0);
    setvalueDescription('');
  };
  return (
    <>
      <Drawer
        title={
          <div style={{ color: '#00499a', display: 'flex', height: '25px' }}>
            <InfoCircleOutlined style={{ fontSize: '22px', margin: '0 15px 0 0' }} />
            <h3 style={{ color: '#00499a', margin: 0 }}> Editar imagen</h3>
          </div>
        }
        placement="right"
        closable={false}
        visible={dataDrawerEdit?.length}
        className="drawerM"
        id="drawerManageImages"
      >
        <Spin size="large" spinning={isLoading}>
          <ChildrenDrawer
            onChangeMonth={onChangeMonth}
            valueMonth={valueMonth}
            valueDescription={valueDescription}
            onChangeTextArea={onChangeTextArea}
          />
        </Spin>
        <ActionButtons
          buttons={['cancel', 'submit']}
          onCancel={onCancel}
          onSubmit={onSubmit}
          disabled={btnDisable}
          isLoading={isLoading}
          style={{ width: '120px', marginTop: 0 }}
        />

        {/* Imagen render */}
        <img
          // src={valueImage}
          src={`data:image/jpeg;base64,${valueImage}`}
          alt="Images Upload"
          className="imgDrawerManageImage"
        />
      </Drawer>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    rowClickData: state?.MANAGE_IMAGES?.Table?.rowClick,
    isLoading: state?.MANAGE_IMAGES?.DrawerEdit?.isLoading,
    imageHD: state?.MANAGE_IMAGES?.Table?.imageHD,
    dataDrawerEdit: state?.MANAGE_IMAGES?.DrawerEdit?.dataDrawerEdit,
  };
};

const ConnectedManageImages = connect(mapStateToProps, {
  InputEditImagesSet,
  InputFilesCameraSet,
  PUTImageLineRequest,
  GETImageHDClean,
  GETListImagesRequest,
  DataDrawerEditSet,
})(DrawerEditComponent);
const DrawerEdit = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getManageImagesModule()]}>
      <ConnectedManageImages />
    </DynamicModuleLoader>
  );
};
export default DrawerEdit;
