import { combineReducers } from 'redux';
import addProduct from './addProduct';
import challenge from './challenge';
import filters from './filters';
import graph from './graph';
import indicators from './indicators';
import messages from './messages';
import module from './module';
import query from './query';
import registerSales from './registerSales';
import report from './reports';
import subscriptions from './subscriptions';
import table from './table';
import views from './views';
import getFamilyInformation from './getFamilyInformation';
import deleteProduct from './deleteProduct';

export default combineReducers({
  addProduct,
  challenge,
  filters,
  graph,
  indicators,
  messages,
  module,
  query,
  registerSales,
  report,
  subscriptions,
  table,
  views,
  getFamilyInformation,
  deleteProduct,
});
