import {
  READ_ONE_GOALS_REQUEST,
  READ_ONE_GOALS_SUCCESS,
  READ_ONE_GOALS_CLEAN,
  UPDATE_GOALS_DATA,
  SAVE_GOALS_DATA,
  UPDATE_GOALS_REQUEST,
  UPDATE_GOALS_SUCCESS,
  UPDATE_GOALS_CLEAN,
  DELETE_GOALS_REQUEST,
  DELETE_GOALS_SUCCESS,
  DELETE_GOALS_CLEAN,
} from '../actions';

function Goals(state = {}, action) {
  const { type } = action;

  switch (type) {
    case READ_ONE_GOALS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case READ_ONE_GOALS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        dataUpdate: action.payload.saleMonth.map((month, index) => {
          if (month === null) {
            return { month: index + 1, volume: 0, usdAmount: 0 };
          }
          return month;
        }),
      };
    case DELETE_GOALS_REQUEST:
      return {
        ...state,
        delete: false,
      };
    case DELETE_GOALS_SUCCESS:
      return {
        ...state,
        delete: true,
        result: action.payload,
      };
    case DELETE_GOALS_CLEAN:
      return {
        ...state,
        delete: false,
      };

    case UPDATE_GOALS_REQUEST:
      return {
        ...state,
        sendData: true,
      };
    case UPDATE_GOALS_SUCCESS:
      return {
        ...state,
        dataRes: action.payload,
      };
    case UPDATE_GOALS_CLEAN:
      return {
        ...state,
        dataRes: {},
      };
    case UPDATE_GOALS_DATA:
      return {
        ...state,
        isLoading: false,
      };
    case SAVE_GOALS_DATA:
      // eslint-disable-next-line no-case-declarations
      let newdataUpdate = [];
      if (state.dataUpdate !== undefined) {
        newdataUpdate = state.dataUpdate.map((month, index) => {
          if (index + 1 === parseInt(action.payload.month)) {
            return {
              ...month,
              [action.payload.type]: action.payload.value,
            };
          }
          return month;
        });
      }
      return {
        ...state,
        dataUpdate: newdataUpdate,
      };

    case READ_ONE_GOALS_CLEAN:
      return {
        ...state,
        isLoading: false,
        data: {},
      };

    default:
      return state;
  }
}

export default Goals;
