export const POST_ONE_SEGMENTATION_REQUEST = 'POST_ONE_SEGMENTATION_REQUEST';
export const POST_ONE_SEGMENTATION_SUCCESS = 'POST_ONE_SEGMENTATION_SUCCESS';
export const POST_ONE_SEGMENTATION_CLEAN = 'POST_ONE_SEGMENTATION_CLEAN';

export const DELETE_ONE_SEGMENTATION_REQUEST = 'DELETE_ONE_SEGMENTATION_REQUEST';
export const DELETE_ONE_SEGMENTATION_SUCCESS = 'DELETE_ONE_SEGMENTATION_SUCCESS';
export const DELETE_ONE_SEGMENTATION_CLEAN = 'DELETE_ONE_SEGMENTATION_CLEAN';

export const PUT_ONE_SEGMENTATION_REQUEST = 'PUT_ONE_SEGMENTATION_REQUEST';
export const PUT_ONE_SEGMENTATION_SUCCESS = 'PUT_ONE_SEGMENTATION_SUCCESS';
export const PUT_ONE_SEGMENTATION_CLEAN = 'PUT_ONE_SEGMENTATION_CLEAN';

export const GET_IN_POLICIY_PRODUCTS_REQUEST = 'GET_IN_POLICIY_PRODUCTS_REQUEST';
export const GET_IN_POLICIY_PRODUCTS_SUCCESS = 'GET_IN_POLICIY_PRODUCTS_SUCCESS';
export const GET_IN_POLICIY_PRODUCTS_CLEAN = 'GET_IN_POLICIY_PRODUCTS_CLEAN';

export const GET_ACTIVE_CLIENTS_REQUEST = 'GET_ACTIVE_CLIENTS_REQUEST';
export const GET_ACTIVE_CLIENTS_SUCCESS = 'GET_ACTIVE_CLIENTS_SUCCESS';
export const GET_ACTIVE_CLIENTS_CLEAN = 'GET_ACTIVE_CLIENTS_CLEAN';

export const POST_PRODUCT_CLIENT_REQUEST = 'POST_PRODUCT_CLIENT_REQUEST';
export const POST_PRODUCT_CLIENT_SUCCESS = 'POST_PRODUCT_CLIENT_SUCCESS';
export const POST_PRODUCT_CLIENT_CLEAN = 'POST_PRODUCT_CLIENT_CLEAN';

export const POST_PRODUCT_BL_REQUEST = 'POST_PRODUCT_BL_REQUEST';
export const POST_PRODUCT_BL_SUCCESS = 'POST_PRODUCT_BL_SUCCESS';
export const POST_PRODUCT_BL_CLEAN = 'POST_PRODUCT_BL_CLEAN';

export const POST_INCENTIVES_RULES_REQUEST = 'POST_INCENTIVES_RULES_REQUEST';
export const POST_INCENTIVES_RULES_SUCCESS = 'POST_INCENTIVES_RULES_SUCCESS';
export const POST_INCENTIVES_RULES_CLEAN = 'POST_INCENTIVES_RULES_CLEAN';

export const EXISTENCE_INCENTIVES_REQUEST = 'EXISTENCE_INCENTIVES_REQUEST';
export const EXISTENCE_INCENTIVES_SUCCESS = 'EXISTENCE_INCENTIVES_SUCCESS';
export const EXISTENCE_INCENTIVES_CLEAN = 'EXISTENCE_INCENTIVES_CLEAN';

export const GET_ClientSingleId_REQUEST = 'GET_ClientSingleId_REQUEST';
export const GET_ClientSingleId_SUCCESS = 'GET_ClientSingleId_SUCCESS';
export const GET_ClientSingleId_CLEAN = 'GET_ClientSingleId_CLEAN';

//EXISTENCE_INCENTIVES_REQUEST
export function checkExistanceIncentivesRequest(payload) {
  return {
    type: EXISTENCE_INCENTIVES_REQUEST,
    payload: payload,
  };
}
export function checkExistanceIncentivesSuccess(payload) {
  return {
    type: EXISTENCE_INCENTIVES_SUCCESS,
    payload: payload,
  };
}

export function checkExistanceIncentivesClean() {
  return {
    type: EXISTENCE_INCENTIVES_CLEAN,
  };
}

//GET_ClientSingleId_REQUEST
export function getClientSingleIdRequest(payload) {
  return {
    type: GET_ClientSingleId_REQUEST,
    payload: payload,
  };
}
export function getClientSingleIdSuccess(payload) {
  return {
    type: GET_ClientSingleId_SUCCESS,
    payload: payload,
  };
}
export function getClientSingleIdClean() {
  return {
    type: GET_ClientSingleId_CLEAN,
  };
}

//POST_INCENTIVES_RULES_REQUEST
export function postIncentivesRulesRequest(payload) {
  return {
    type: POST_INCENTIVES_RULES_REQUEST,
    payload: payload,
  };
}
export function postIncentivesRulesSuccess(payload) {
  return {
    type: POST_INCENTIVES_RULES_SUCCESS,
    payload: payload,
  };
}

export function postIncentivesRulesClean() {
  return {
    type: POST_INCENTIVES_RULES_CLEAN,
  };
}

//POST_PRODUCT_BL_REQUEST
export function postProductBLRequest(payload) {
  return {
    type: POST_PRODUCT_BL_REQUEST,
    payload: payload,
  };
}
export function postProductBLSuccess(payload) {
  return {
    type: POST_PRODUCT_BL_SUCCESS,
    payload: payload,
  };
}

export function postProductBLClean() {
  return {
    type: POST_PRODUCT_BL_CLEAN,
  };
}

//POST_PRODUCT_CLIENT_REQUEST
export function postProductClientRequest(payload) {
  return {
    type: POST_PRODUCT_CLIENT_REQUEST,
    payload: payload,
  };
}
export function postProductClientSuccess(payload) {
  return {
    type: POST_PRODUCT_CLIENT_SUCCESS,
    payload: payload,
  };
}

export function postProductClientClean() {
  return {
    type: POST_PRODUCT_CLIENT_CLEAN,
  };
}

//GET_ACTIVE_CLIENTS_REQUEST
export function getActiveClientsRequest(payload) {
  return {
    type: GET_ACTIVE_CLIENTS_REQUEST,
    payload: payload,
  };
}
export function getActiveClientsSuccess(payload) {
  return {
    type: GET_ACTIVE_CLIENTS_SUCCESS,
    payload: payload,
  };
}
export function getActiveClientsClean() {
  return {
    type: GET_ACTIVE_CLIENTS_CLEAN,
  };
}

//GET_IN_POLICIY_PRODUCTS_REQUEST
export function getInPoliciyProductsRequest(payload) {
  return {
    type: GET_IN_POLICIY_PRODUCTS_REQUEST,
    payload: payload,
  };
}
export function getInPoliciyProductsSuccess(payload) {
  return {
    type: GET_IN_POLICIY_PRODUCTS_SUCCESS,
    payload: payload,
  };
}
export function getInPoliciyProductsClean() {
  return {
    type: GET_IN_POLICIY_PRODUCTS_CLEAN,
  };
}

//PUT_ONE_SEGMENTATION_REQUEST
export function putOneSegmentationRequest(payload) {
  return {
    type: PUT_ONE_SEGMENTATION_REQUEST,
    payload: payload,
  };
}
export function putOneSegmentationSuccess(payload) {
  return {
    type: PUT_ONE_SEGMENTATION_SUCCESS,
    payload: payload,
  };
}

export function putOneSegmentationClean() {
  return {
    type: PUT_ONE_SEGMENTATION_CLEAN,
  };
}

//DELETE_ONE_SEGMENTATION_REQUEST
export function deleteOneSegmentationRequest(payload) {
  return {
    type: DELETE_ONE_SEGMENTATION_REQUEST,
    payload: payload,
  };
}
export function deleteOneSegmentationSuccess(payload) {
  return {
    type: DELETE_ONE_SEGMENTATION_SUCCESS,
    payload: payload,
  };
}

export function deleteOneSegmentationClean() {
  return {
    type: DELETE_ONE_SEGMENTATION_CLEAN,
  };
}

//POST_ONE_SEGMENTATION_REQUEST
export function postOneSegmentationRequest(payload) {
  return {
    type: POST_ONE_SEGMENTATION_REQUEST,
    payload: payload,
  };
}
export function postOneSegmentationSuccess(payload) {
  return {
    type: POST_ONE_SEGMENTATION_SUCCESS,
    payload: payload,
  };
}

export function postOneSegmentationClean() {
  return {
    type: POST_ONE_SEGMENTATION_CLEAN,
  };
}
