import React, { useEffect, useState, Fragment, useContext } from 'react';
import CatalogCard from './catalogCard';
import { Row } from 'antd';
import { appContext, layoutContext } from 'context';
import { getCatalogsObjects } from './utils';
import { getRemainingDays } from './services';

const ModulesTradeAgreementInfo = () => {
  const { onChangeActiveComponent } = useContext(layoutContext);
  const { modules, accountStatusPermissions } = useContext(appContext);
  const [catalog, setcatalog] = useState([]);

  useEffect(() => {
    let permissions = accountStatusPermissions.find(
      (permission) => permission.uri === 'base-information'
    );
    setcatalog(permissions.components);
  }, [accountStatusPermissions]);

  const [modulesCards, setModules] = useState([]);

  let type = 'account-status/base-information';

  useEffect(() => {
    if (modulesCards.length === 0) {
      getRemainingDays(type).then((res) => {
        let uploadEnabled = res.daysLeft >= 0;
        let newModules = getCatalogsObjects(catalog, modules, type, uploadEnabled);
        if (newModules.length) {
          setModules(newModules);
        }
      });
      onChangeActiveComponent(undefined);
    }
  }, [modules, catalog, type, modulesCards, onChangeActiveComponent]);

  return (
    <>
      <Row gutter={[16, 16]} style={{ width: '100%' }}>
        {modulesCards &&
          modulesCards.map((module, key) => {
            return (
              <Fragment key={key}>
                <CatalogCard
                  title={module.name}
                  add={module.add}
                  upload={module.upload}
                  consult={module.consult}
                />
              </Fragment>
            );
          })}
      </Row>
    </>
  );
};

export default ModulesTradeAgreementInfo;
