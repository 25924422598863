import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import {
  GRAPH_REQUEST,
  graphSuccess,
  graphFail,
  SALES_GOAL_REQUEST,
  salesGoalSuccess,
  salesGoalFail,
} from '../actions/graph';
import instance from '../../../services/request';
import { getSalesGraph, getSalesGoals } from '../../services/graphs';
import { responseOK } from '../../utils';

function* workerGraphRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, onSuccess } = payload;
    const response = yield call(getSalesGraph, params, source.token);

    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess(response.data);
      }
      yield put(graphSuccess(response.data));
    } else {
      yield put(graphFail());
    }
  } catch (e) {
    yield put(graphFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchGraphRequest() {
  yield takeLatest(GRAPH_REQUEST, workerGraphRequest);
}

function* workerSalesGoalRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, onSuccess } = payload;
    const response = yield call(getSalesGoals, params, source.token);

    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess(response.data);
      }
      yield put(salesGoalSuccess(response.data));
    } else {
      yield put(salesGoalFail());
    }
  } catch (e) {
    yield put(salesGoalFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchSalesGoalRequest() {
  yield takeLatest(SALES_GOAL_REQUEST, workerSalesGoalRequest);
}
