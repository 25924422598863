import React from 'react';
import { Button, Modal } from 'antd';

const BothModal = (props) => {
  const { activate, deactivate, onCancel, ...other } = props;
  return (
    <Modal
      visible={true}
      footer={[
        <Button key="1" onClick={() => onCancel()}>
          Cancelar
        </Button>,
        <Button key="2" type="primary" onClick={activate}>
          Activar
        </Button>,
        <Button key="3" type="primary" onClick={deactivate}>
          Desactivar
        </Button>,
      ]}
      onCancel={onCancel}
      {...other}
    >
      <p>
        <b>¿Qué operación desea realizar con el producto?</b>
        <br /> Recuerde que la acción seleccionada aplicará para todos los países a los que se
        encuentra asociado el producto{' '}
      </p>
    </Modal>
  );
};

export default BothModal;
