export const OTHERS_CLIENTS_REQUEST = 'OTHERS_CLIENTS_REQUEST';
export const GET_OTHERS_CLIENTS = 'GET_OTHERS_CLIENTS';
export const OTHERS_CLIENTS_FAIL = 'OTHERS_CLIENTS_FAIL';
export const CLEAN_OTHERS_CLIENTS = 'CLEAN_OTHERS_CLIENTS';

export const OTHERS_SUBREGIONS_REQUEST = 'OTHERS_SUBREGIONS_REQUEST';
export const GET_OTHERS_SUBREGIONS = 'GET_OTHERS_SUBREGIONS';
export const OTHERS_SUBREGIONS_FAIL = 'OTHERS_SUBREGIONS_FAIL';
export const CLEAN_OTHERS_SUBREGIONS = 'CLEAN_OTHERS_SUBREGIONS';

export function othersClientsRequest(payload) {
  return {
    type: OTHERS_CLIENTS_REQUEST,
    payload: payload,
  };
}

export function othersSubregionsReguest(payload) {
  return {
    type: OTHERS_SUBREGIONS_REQUEST,
    payload: payload,
  };
}

export function getOthersClients(payload) {
  return {
    type: GET_OTHERS_CLIENTS,
    payload: payload,
  };
}

export function getOthersSubRegions(payload) {
  return {
    type: GET_OTHERS_SUBREGIONS,
    payload: payload,
  };
}

export function othersClientsFail(payload) {
  return {
    type: OTHERS_CLIENTS_FAIL,
    payload: payload,
  };
}

export function othersSubRegionsFail(payload) {
  return {
    type: OTHERS_SUBREGIONS_FAIL,
    payload: payload,
  };
}

export function cleanOthersClients(payload) {
  return {
    type: CLEAN_OTHERS_CLIENTS,
    payload: payload,
  };
}

export function cleanOthersSubRegions(payload) {
  return {
    type: CLEAN_OTHERS_SUBREGIONS,
    payload: payload,
  };
}
