export const SUB_REGIONS_REQUEST = 'SUB_REGIONS_REQUEST';
export const GET_SUB_REGIONS = 'GET_SUB_REGIONS';

export const BUSINESS_LINES_REQUEST = 'BUSINESS_LINES_REQUEST';
export const GET_BUSINESS_LINES = 'GET_BUSINESS_LINES';

export const YEARS_REQUEST = 'YEARS_REQUEST';
export const GET_YEARS = 'GET_YEARS';

export const PERIODICITY_REQUEST = 'PERIODICITY_REQUEST';
export const GET_PERIODICITY = 'GET_PERIODICITY';

export const INCENTIVES_REQUEST = 'INCENTIVES_REQUEST';
export const GET_INCENTIVES = 'GET_INCENTIVES';

export const CREDIT_NOTES_REQUEST = 'CREDIT_NOTES_REQUEST';
export const GET_CREDIT_NOTES = 'GET_CREDIT_NOTES';

export const CLEAN_SUB_REGIONS = 'CLEAN_SUB_REGIONS';
export const CLEAN_BUSINESS_LINES = 'CLEAN_BUSINESS_LINES';
export const CLEAN_YEARS = 'CLEAN_YEARS';
export const CLEAN_PERIODICITY = 'CLEAN_PERIODICITY';

export const CLEAN_INCENTIVES = 'CLEAN_INCENTIVES';
export const CLEAN_CREDIT_NOTES = 'CLEAN_CREDIT_NOTES';

export const SUB_REGIONS_FAIL = 'SUB_REGIONS_FAIL';
export const BUSINESS_LINES_FAIL = 'BUSINESS_LINES_FAIL';
export const YEARS_FAIL = 'YEARS_FAIL';
export const PERIODICITY_FAIL = 'PERIODICITY_FAIL';
export const INCENTIVES_FAIL = 'INCENTIVES_FAIL';
export const CREDIT_NOTES_FAIL = 'CREDIT_NOTES_FAIL';

export function subRegionsRequest(payload) {
  return {
    type: SUB_REGIONS_REQUEST,
    payload: payload,
  };
}

export function getSubRegions(payload) {
  return {
    type: GET_SUB_REGIONS,
    payload: payload,
  };
}

export function businessLinesRequest(payload) {
  return {
    type: BUSINESS_LINES_REQUEST,
    payload: payload,
  };
}

export function getBusinessLines(payload) {
  return {
    type: GET_BUSINESS_LINES,
    payload: payload,
  };
}

export function yearsRequest(payload) {
  return {
    type: YEARS_REQUEST,
    payload: payload,
  };
}

export function PeriodicityRequest(payload) {
  return {
    type: PERIODICITY_REQUEST,
    payload: payload,
  };
}

export function IncentivesRequest(payload) {
  return {
    type: INCENTIVES_REQUEST,
    payload: payload,
  };
}

export function creditNotesRequest(payload = {}) {
  return {
    type: CREDIT_NOTES_REQUEST,
    payload: payload,
  };
}

export function getYears(payload) {
  return {
    type: GET_YEARS,
    payload: payload,
  };
}

export function getPeriodicity(payload) {
  return {
    type: GET_PERIODICITY,
    payload: payload,
  };
}

export function getIncentives(payload) {
  return {
    type: GET_INCENTIVES,
    payload: payload,
  };
}

export function getCreditNotes(payload) {
  return {
    type: GET_CREDIT_NOTES,
    payload: payload,
  };
}

export function cleanSubRegions(payload) {
  return {
    type: CLEAN_SUB_REGIONS,
    payload: payload,
  };
}

export function cleanBusinessLines(payload) {
  return {
    type: CLEAN_BUSINESS_LINES,
    payload: payload,
  };
}

export function cleanYears(payload) {
  return {
    type: CLEAN_YEARS,
    payload: payload,
  };
}

export function cleanPeriodicity(payload) {
  return {
    type: CLEAN_PERIODICITY,
    payload: payload,
  };
}

export function cleanIncentives(payload) {
  return {
    type: CLEAN_INCENTIVES,
    payload: payload,
  };
}

export function cleanCreditNotes(payload) {
  return {
    type: CLEAN_CREDIT_NOTES,
    payload: payload,
  };
}

export function subRegionsFail(payload) {
  return {
    type: SUB_REGIONS_FAIL,
    payload: payload,
  };
}

export function BusinessLinesFail(payload) {
  return {
    type: BUSINESS_LINES_FAIL,
    payload: payload,
  };
}

export function yearsFail(payload) {
  return {
    type: YEARS_FAIL,
    payload: payload,
  };
}

export function periodicityFail(payload) {
  return {
    type: PERIODICITY_FAIL,
    payload: payload,
  };
}

export function incentivesFail(payload) {
  return {
    type: INCENTIVES_FAIL,
    payload: payload,
  };
}

export function creditNotesFail(payload) {
  return {
    type: CREDIT_NOTES_FAIL,
    payload: payload,
  };
}
