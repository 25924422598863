import React from 'react';
import { Row, Col, Input, Form } from 'antd';

const SectionInitial = ({ getInitialSection }) => {
  const { TextArea } = Input;
  return (
    <Row>
      <Col span={24}>
        <Form.Item name="encabezado" label="*Encabezado:">
          <TextArea
            placeholder={getInitialSection?.header}
            defaultValue={getInitialSection?.header}
            disabled={true}
            autoSize
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="saludo1" label="Saludo 1:">
          <TextArea
            placeholder={getInitialSection?.greeting1}
            defaultValue={getInitialSection?.greeting1}
            disabled={true}
            autoSize
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="saludo2" label="Saludo 2:">
          <TextArea
            placeholder={getInitialSection?.greeting2}
            defaultValue={getInitialSection?.greeting2}
            disabled={true}
            autoSize
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="saludo3" label="Saludo 3:">
          <TextArea
            placeholder={getInitialSection?.greeting3}
            defaultValue={getInitialSection?.greeting3}
            disabled={true}
            autoSize
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SectionInitial;
