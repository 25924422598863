import { Users } from '../../Users';
import UsersMassiveUpload from 'Users/MassiveUpload/containers/UsersMassiveUpload';
import UpdateUser from 'User';
import { DetailUser, CreateUser } from 'User';

export const USERS_MAP = {
  users: Users,
  add_users: CreateUser,
  add_user: CreateUser,
  consult_users: Users,
  detail_user: DetailUser,
  edit_user: UpdateUser,
  detail_users: DetailUser,
  edit_users: UpdateUser,
  update_users: UpdateUser,
  upload_users: UsersMassiveUpload,
};
