import React from 'react';
import Table from './table';
import BSFiltersRow from './bsFiltersRow';
import DeleteProductCatalogModal from './modal/containers/DeleteProductCatalogModal';
import DownloadCatalogModal from './modal/containers/DownloadCatalogModal';

function Archive({
  onFilterHeaderChange,
  columns,
  searchInput,
  beFilters,
  selectors,
  dateRange,
  multipeYears,
  columnSum,
  columnSumValue,
  noResultsMessage,
  loading,
  setLoading,
  years,
  data,
  name,
  onChange,
  showModal,
  toggleShowModal,
  showDownloadCatalogModal,
  toggleDownloadCatalogModal,
  handleGetCatalog,
  downloadType,
  setDownloadType,
  deleteType,
  setDeleteType,
  deleteIsLoading,
  setDeleteIsLoading,
  obtainCatalogData,
}) {
  return (
    <>
      <BSFiltersRow
        beFilters={beFilters}
        setLoading={setLoading}
        search={searchInput}
        yearSearch={years}
        multipeYears={multipeYears}
        selectors={selectors}
        dateRange={dateRange}
        onChange={onChange}
        toggleShowModal={toggleShowModal}
        toggleDownloadCatalogModal={toggleDownloadCatalogModal}
        name={name}
        deleteIsLoading={deleteIsLoading}
      />
      {data && (
        <Table
          onChange={onFilterHeaderChange}
          loading={loading}
          columnSumValue={columnSumValue}
          noResultsMessage={noResultsMessage}
          columnSum={columnSum}
          columns={columns}
          data={data}
          name={name}
        />
      )}
      {(name === 'products' || name === 'clients' || name === 'prices') && (
        <>
          <DeleteProductCatalogModal
            showModal={showModal}
            toggleShowModal={toggleShowModal}
            name={name}
            deleteType={deleteType}
            setDeleteType={setDeleteType}
            setDeleteIsLoading={setDeleteIsLoading}
            obtainCatalogData={obtainCatalogData}
          />
          <DownloadCatalogModal
            showDownloadCatalogModal={showDownloadCatalogModal}
            toggleDownloadCatalogModal={toggleDownloadCatalogModal}
            name={name}
            downloadType={downloadType}
            setDownloadType={setDownloadType}
            handleGetCatalog={handleGetCatalog}
            beFilters={beFilters}
          />
        </>
      )}
    </>
  );
}

export default Archive;
