import request from 'services/request';
import { paramsSerializer } from 'RootModule/services/catalogs';
const serviceURL = '/Users/Api/v1/Users';

export async function getOneUser({ cancelToken, userId }) {
  return request(`${serviceURL}/Detail/${userId}`, {
    method: 'GET',
    cancelToken,
  });
}

export async function putOneUser({ cancelToken, userId, data }) {
  return request(`${serviceURL}/${userId}`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

export async function postOneUser({ cancelToken, data }) {
  return request(`${serviceURL}/`, {
    method: 'POST',
    cancelToken,
    data,
  });
}

export async function updateOneUserStatus({ cancelToken, userId, enabled }) {
  return request(`${serviceURL}/Enable/${userId}/${enabled}`, {
    method: 'PUT',
    cancelToken,
  });
}

export async function getCatalogUser({ cancelToken, params, mode }) {
  return request(`/Catalogs/Api/v1/Clients/ByPlacement`, {
    method: 'GET',
    cancelToken,
    params,
    paramsSerializer: mode === 'multiple' ? paramsSerializer : undefined,
  });
}
