import React from 'react';
import { Modal } from 'antd';

export const actionButtonsStyle = {
  color: 'white',
  borderRadius: '5px',
  height: '35px',
  width: 'auto',
  fontSize: '1.1em',
  border: 'none',
  fontWeight: '600',
};

const cancelButton = {
  ...actionButtonsStyle,
  backgroundColor: '#247ba9',
};

const submitButton = {
  ...actionButtonsStyle,
  backgroundColor: '#004a96',
};

const triggerModalForDuplicates = (duplicated, replaceEntries, keepEntries, module) => {
  let message = (
    <p>
      Existen {duplicated} registros repetidos de {module}, ¿desea remplazarlos o ignorarlos?
    </p>
  );
  Modal.confirm({
    title: `Registros repetidos`,
    content: message,
    okText: 'Remplazar todos',
    className: 'sumary-duplicated',
    cancelText: 'Mantener todos',
    okButtonProps: { size: 'middle', type: 'primary', style: { ...submitButton } },
    onOk: replaceEntries,
    cancelButtonProps: {
      size: 'middle',
      type: 'default',
      style: { ...cancelButton },
    },
    onCancel: keepEntries,
    width: 500,
  });
};
export default triggerModalForDuplicates;
