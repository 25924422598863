import React, { useContext, useEffect, useState } from 'react';
import { triggerModalForDuplicates } from 'common/MassiveUploads/utils';
import getShoppingByFamilyMassiveUploadModule from '../getShoppingByFamilyMassiveUploadModule';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import { useHistory } from 'react-router-dom';
import { layoutContext } from 'context';
import getRootModule from 'RootModule';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
  MassiveUploadTable,
  UploadExcelButton,
  ActionButtonsExcelUpload,
} from 'common/MassiveUploads/components';
//cambiar sagas
import {
  ShoppingByFamilyMassiveUploadSetAllowUpload,
  ShoppingByFamilyMassiveUploadRequestVerify,
  ShoppingByFamilyMassiveUploadClearUpload,
  ShoppingByFamilyMassiveUploadClearVerify,
  ShoppingByFamilyMassiveUploadRequestUpload,
  ShoppingByFamilyMassiveUploadClearAllowUpload,
  ExistsStatements,
} from '../redux-saga/actions';
import tableColumns from '../schemas/massiveUploadTableColumns'; //cambiar columna

const ShoppingByFamilyMassiveUpload = (props) => {
  const {
    ShoppingByFamilyMassiveUploadSetAllowUpload: setAllowUpload,
    ShoppingByFamilyMassiveUploadRequestVerify: requestVerify,
    ShoppingByFamilyMassiveUploadClearUpload: clearUpload,
    ShoppingByFamilyMassiveUploadClearVerify: clearVerify,
    ShoppingByFamilyMassiveUploadRequestUpload: requestUpload,
    ShoppingByFamilyMassiveUploadClearAllowUpload: clearAllowUpload,
    ExistsStatements: ExistsStatementsGet,
    allowUpload,
    verify,
  } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const correct = verify?.correct;
  const entriesList = verify?.objectList;
  const allCorrectMessage = verify?.allCorrectMessage;
  const totalDuplicatedEntries = verify?.replaced?.length;
  const [fileString, setFileString] = useState('');

  useEffect(() => {
    clearAllowUpload();
    ExistsStatementsGet({ history });
    return () => {
      clearAllowUpload();
      clearUpload();
      clearVerify();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    onChangeActiveComponent('activo');
    if (correct > 0) {
      setAllowUpload(true);
    } else {
      setAllowUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correct]);

  function customRequest({ file }) {
    requestVerify({ file, setFileString });
  }

  function requestReplace() {
    requestUpload({ history, fileString, overwrite: true });
  }

  function requestKeep() {
    requestUpload({ history, fileString, overwrite: true });
  }

  function onSubmit() {
    if (totalDuplicatedEntries > 0) {
      triggerModalForDuplicates(
        totalDuplicatedEntries,
        requestReplace,
        requestKeep,
        'Compras por familia'
      );
      return;
    }

    requestReplace();
  }
  function onCancel() {
    history.push(`/account-status/base-information`);
  }

  return (
    <Form form={form}>
      <UploadExcelButton customRequest={customRequest} />
      <MassiveUploadTable
        data={entriesList}
        columns={tableColumns}
        allCorrectMessage={allCorrectMessage}
      />
      <ActionButtonsExcelUpload
        onSubmit={onSubmit}
        onCancel={onCancel}
        disabled={!allowUpload}
        buttons={['cancel', 'submit']}
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    allowUpload: state?.ShoppingByFamily_MASSIVE_UPLOAD?.allowUpload,
    verify: state?.ShoppingByFamily_MASSIVE_UPLOAD?.verify,
  };
};

const ConnectedShoppingByFamilyMassiveUpload = connect(mapStateToProps, {
  ShoppingByFamilyMassiveUploadSetAllowUpload,
  ShoppingByFamilyMassiveUploadRequestVerify,
  ShoppingByFamilyMassiveUploadRequestUpload,
  ShoppingByFamilyMassiveUploadClearUpload,
  ShoppingByFamilyMassiveUploadClearVerify,
  ShoppingByFamilyMassiveUploadClearAllowUpload,
  ExistsStatements,
})(ShoppingByFamilyMassiveUpload);

const ShoppingByFamilyMassiveUploadWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getShoppingByFamilyMassiveUploadModule()]}>
      <ConnectedShoppingByFamilyMassiveUpload />
    </DynamicModuleLoader>
  );
};
export default ShoppingByFamilyMassiveUploadWithModules;
