import React from 'react';
import { Row, Col } from 'antd';
import { BInput } from 'common/components';

const SignerBASF = () => {
  return (
    <>
      {[1, 2, 3, 4, 5].map((d) => {
        return (
          <Row key={d}>
            <Col span={12}>
              <BInput
                type="text"
                className="form-add-user-input"
                placeholder="Escribe contacto"
                maxLength={100}
                name={`contact${d}`}
                label={'Contacto:'}
              />
            </Col>

            <Col span={12}>
              <BInput
                type="text"
                className="form-add-user-input"
                maxLength={80}
                placeholder="Escribe correo electrónico"
                name={`email${d}`}
                label={'Correo electrónico:'}
                rules={[{ type: 'email', message: 'usuario@dominio.extensión' }]}
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default SignerBASF;
