const integerFloat = (e) => {
  let inputId = Object.keys(e) ? Object.keys(e) : null;
  let keyId = inputId[0].toString() ? inputId[0].toString() : null;
  let inputValue = null;
  let Value = null;
  let floatTF = null;
  let intTF = null;
  let valueFloat = null;
  let valueInt = null;
  let alphabet = null;
  let patternFloat = /^\d{0,10}(\.\d{1,2})?$/;
  let patternInt = /^\d{0,10}$/;
  let pattern = /[A-z_-]/g;

  inputValue = Object.values(e);

  Value = inputValue[0];

  valueFloat = Value.substr(0, 13);
  valueFloat = Number.parseFloat(valueFloat).toFixed(2);
  valueInt = Value.substr(0, 10);

  floatTF = valueFloat.match(patternFloat);
  intTF = valueInt.match(patternInt);
  alphabet = Value.match(pattern);

  if (alphabet) {
    return { keyId, valueIF: null };
    /*  form.setFieldsValue({ [keyId]: null }); */
  } else if (floatTF) {
    return {
      keyId,
      valueIF: parseFloat(valueFloat),
    };
  } else if (intTF) {
    return { keyId, valueIF: valueInt };
  }

  /*  if (alphabet) {
      form.setFieldsValue({ [keyId]: null });
    } else if (floatTF) {
      form.setFieldsValue({ [keyId]: parseFloat(valueFloat) });
    } else if (intTF) {
      form.setFieldsValue({ [keyId]: valueInt });
    } */
};
export default integerFloat;
