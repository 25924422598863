// # ESTADO DE CUENTA #
// Modulos
import AcountStatus from 'AccountStatus/Modules/Modules';
import BaseInformationModules from 'AccountStatus/BaseInformation/Modules/Modules';
// Registro
import { RegisterDynamicElements } from 'AccountStatus/DynamicElements/Register';
import AutomaticSendingStatement from 'AccountStatus/SendingAccountStatement/AutomaticSend/containers';
import ManualSendingStatement from 'AccountStatus/SendingAccountStatement/ManualSend/containers/ManualSendingStatement';
// Administrar imágenes
import ManageImages from 'AccountStatus/ManageImages';
// Cargas masivas
import MassiveUploadDynamicItems from 'AccountStatus/DynamicElements/MassiveUpload/containers/DynamicMassiveUpload';
import MassiveUploadShopping from 'AccountStatus/BaseInformation/Shopping/MassiveUpload/containers/ShoppingMassiveUpload';
import MassiveUploadBaseInformation from 'AccountStatus/BaseInformation/TradeAgreementGoals/MassiveUpload/containers/DynamicMassiveUpload';
import MassiveUploadPOGInfo from 'AccountStatus/BaseInformation/POG/MassiveUpload';
import MassiveUploadCreditNotes from 'AccountStatus/BaseInformation/CreditNotes/MassiveUpload';
import MassiveUploadShoppingByFamily from 'AccountStatus/BaseInformation/ShoppingByFamily/MassiveUpload';
// Consultas
import ConsultTradeAgreementGoals from 'AccountStatus/BaseInformation/TradeAgreementGoals/Consult';
import ConsultShopping from 'AccountStatus/BaseInformation/Shopping/Consult';
import { DynamicElementsMainTable } from 'AccountStatus/DynamicElements/Consults/MainTable';
import DynamicSectionsLogs from 'AccountStatus/DynamicElements/Consults/AdminSections/pages';
import { UpdateDynamicElements } from 'AccountStatus/DynamicElements/Consults/Update';
import { consultBaseInfo } from 'AccountStatus/BaseInformation/POG/Consult';
import ConsultShoppingByFamily from 'AccountStatus/BaseInformation/ShoppingByFamily/Consult';
// Detalles
import { DetailDynamicElements } from 'AccountStatus/DynamicElements/Consults/Detail';
import ConsultCreditNotes from 'AccountStatus/BaseInformation/CreditNotes/Consult/containers';
import PreviewAccountStatement from 'AccountStatus/PreviewAccountStatement';

export const accountStatusModules = {
  // Home Estado de cuenta
  'account-status': AcountStatus,

  // Elementos dinamicos de estado de cuenta
  'register_dynamic-items': RegisterDynamicElements,
  'update_dynamic-items': UpdateDynamicElements,
  'detail_dynamic-items': DetailDynamicElements,
  'admin_dynamic-items': DynamicSectionsLogs,
  'upload_dynamic-items': MassiveUploadDynamicItems,
  'consult_dynamic-items': DynamicElementsMainTable,

  // Información base
  'base-information': BaseInformationModules,
  'upload_trade-agreement-goals': MassiveUploadBaseInformation,
  'consult_trade-agreement-goals': ConsultTradeAgreementGoals,
  upload_shopping: MassiveUploadShopping,
  consult_shopping: ConsultShopping,
  'upload_POG-acount': MassiveUploadPOGInfo,
  'consult_POG-acount': consultBaseInfo,
  'upload_credit-notes': MassiveUploadCreditNotes,
  'consult_shopping-by-family': ConsultShoppingByFamily,
  'clock_sending-account-statement': AutomaticSendingStatement,
  'folder_sending-account-statement': ManualSendingStatement,
  'upload_shopping-by-family': MassiveUploadShoppingByFamily,
  'consult_credit-notes': ConsultCreditNotes,
  'account-statement-preview': PreviewAccountStatement,
  // Administrar imágenes
  'manage-images': ManageImages,
};
