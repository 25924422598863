import React, { useState, useEffect } from 'react';
import { Select, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { getIdsOfArrayOfStrings } from './utils';
import './businessEstructureSelect.css';
const { Option } = Select;
const selectStyle = {
  minWidth: '100%',
};
export const handleChange = (option, tag, options, multiple, setSelectedOptions, onChange) => {
  let selection = option;
  if (!multiple) {
    let object = JSON.parse(option);
    onChange({ [tag]: object.id });
  } else {
    if (option.includes('"select all"')) {
      let ids;
      if (option.length > 1) {
        ids = [];
        selection = [];
      } else {
        let stringOptions = options.map((el) => JSON.stringify(el));
        ids = getIdsOfArrayOfStrings(stringOptions);
        selection = stringOptions;
      }
      onChange({ [tag]: ids });
    } else {
      onChange({ [tag]: getIdsOfArrayOfStrings(option) });
    }
  }
  setSelectedOptions(selection);
};
function BussinesEstructureSelect({
  tag,
  label,
  required,
  options,
  disabled,
  multiple,
  onChange,
  defaultValue,
}) {
  const { t } = useTranslation(['common']);
  const [selectedOptions, setSelectedOptions] = useState(undefined);

  useEffect(() => {
    setSelectedOptions(undefined);
  }, [options]);

  return (
    <Col id={`${tag}Select`} data-testid={`${tag}Select`}>
      <p style={{ margin: '0px', color: '#21A0D2' }}>
        {required && '*'}
        {t(label)}:
      </p>
      <Select
        className="BusinessEstructureSelect"
        virtual={false}
        maxTagCount={1}
        maxTagTextLength={6}
        style={selectStyle}
        placeholder="Seleccionar"
        defaultValue={defaultValue}
        value={selectedOptions}
        mode={multiple && 'multiple'}
        disabled={disabled}
        showSearch={false}
        getPopupContainer={(trigger) => trigger.parentNode}
        onChange={(value) =>
          handleChange(value, tag, options, multiple, setSelectedOptions, onChange)
        }
      >
        {multiple && (
          <Option value={JSON.stringify('select all')} key={99999}>
            {t('selectAll')}
          </Option>
        )}
        {options &&
          options.length > 0 &&
          options.map((option, key) => (
            <Option value={JSON.stringify(option)} key={key}>
              {option.name}
            </Option>
          ))}
      </Select>
    </Col>
  );
}
export default BussinesEstructureSelect;
