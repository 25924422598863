import reducer from './redux-saga/reducers';

import {
  watchProductMasterMassiveUploadSetAllowUpload,
  watchProductMasterMassiveUploadRequestVerify,
  watchProductMasterMassiveUploadRequestUpload,
} from './redux-saga/sagas';

export default function getProductMasterMassiveUploadModule() {
  return {
    id: 'ProductMaster_MASSIVE_UPLOAD',
    reducerMap: {
      ProductMaster_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchProductMasterMassiveUploadSetAllowUpload,
      watchProductMasterMassiveUploadRequestVerify,
      watchProductMasterMassiveUploadRequestUpload,
    ],
  };
}
