import { SET_CURRENT_MODULE } from '../actions/module';

const filters = (state = {}, action) => {
  if (action.type === SET_CURRENT_MODULE) {
    return {
      ...state,
      currentModule: action.payload,
    };
  } else {
    return state;
  }
};

export default filters;
