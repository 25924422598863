export const threeColumns = Object.freeze({
  leftColumn: {
    xs: 24,
    md: 24,
    lg: 5,
    xlg: 4,
  },
  mainColumn: {
    xs: 24,
    md: 24,
    lg: 10,
    xlg: 4,
  },
  rightColumn: {
    xs: 24,
    md: 24,
    lg: 9,
    xlg: 5,
  },
});

export const twoColumns = Object.freeze({
  leftColumn: {
    xs: 24,
    md: 24,
    lg: 4,
    xlg: 4,
  },
  rightColumn: {
    xs: 24,
    md: 24,
    lg: 20,
    xlg: 20,
  },
});
