/* FAMILY filter */
export const CONSULT_FILTERS_FAMILY_REQUEST_OPTIONS = 'CONSULT_FILTERS_FAMILY_REQUEST_OPTIONS';
export const CONSULT_FILTERS_FAMILY_SET_OPTIONS = 'CONSULT_FILTERS_FAMILY_SET_OPTIONS';
export const CONSULT_FILTERS_FAMILY_CLEAR_OPTIONS = 'CONSULT_FILTERS_FAMILY_CLEAR_OPTIONS';

export const CONSULT_FILTERS_FAMILY_SET_VALUE = 'CONSULT_FILTERS_FAMILY_SET_VALUE';
export const CONSULT_FILTERS_FAMILY_CLEAR_VALUE = 'CONSULT_FILTERS_FAMILY_CLEAR_VALUE';
// FAMILY
export function consultFiltersFamilyRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_FAMILY_REQUEST_OPTIONS,
    payload: payload,
  };
}
export function consultFiltersFamilySetOptions(payload) {
  return {
    type: CONSULT_FILTERS_FAMILY_SET_OPTIONS,
    payload: payload,
  };
}
export function consultFiltersFamilyClearOptions() {
  return {
    type: CONSULT_FILTERS_FAMILY_CLEAR_OPTIONS,
  };
}
export function consultFiltersFamilySetValue(payload) {
  return {
    type: CONSULT_FILTERS_FAMILY_SET_VALUE,
    payload: payload,
  };
}
export function consultFiltersFamilyClearValue() {
  return {
    type: CONSULT_FILTERS_FAMILY_CLEAR_VALUE,
  };
}
/* business line filter */
export const CONSULT_FILTERS_BUSINESS_LINE_REQUEST_OPTIONS =
  'CONSULT_FILTERS_BUSINESS_LINE_REQUEST_OPTIONS';
export const CONSULT_FILTERS_BUSINESS_LINE_SET_OPTIONS =
  'CONSULT_FILTERS_BUSINESS_LINE_SET_OPTIONS';
export const CONSULT_FILTERS_BUSINESS_LINE_CLEAR_OPTIONS =
  'CONSULT_FILTERS_BUSINESS_LINE_CLEAR_OPTIONS';

export const CONSULT_FILTERS_BUSINESS_LINE_SET_VALUE = 'CONSULT_FILTERS_BUSINESS_LINE_SET_VALUE';
export const CONSULT_FILTERS_BUSINESS_LINE_CLEAR_VALUE =
  'CONSULT_FILTERS_BUSINESS_LINE_CLEAR_VALUE';
// BusinessLine
export function consultFiltersBusinessLineRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_BUSINESS_LINE_REQUEST_OPTIONS,
    payload: payload,
  };
}
export function consultFiltersBusinessLineSetOptions(payload) {
  return {
    type: CONSULT_FILTERS_BUSINESS_LINE_SET_OPTIONS,
    payload: payload,
  };
}
export function consultFiltersBusinessLineClearOptions() {
  return {
    type: CONSULT_FILTERS_BUSINESS_LINE_CLEAR_OPTIONS,
  };
}
export function consultFiltersBusinessLineSetValue(payload) {
  return {
    type: CONSULT_FILTERS_BUSINESS_LINE_SET_VALUE,
    payload: payload,
  };
}
export function consultFiltersBusinessLineClearValue() {
  return {
    type: CONSULT_FILTERS_BUSINESS_LINE_CLEAR_VALUE,
  };
}

/* SubRegion Filter */
export const CONSULT_FILTERS_SUBREGION_REQUEST_OPTIONS =
  'CONSULT_FILTERS_SUBREGION_REQUEST_OPTIONS';
export const CONSULT_FILTERS_SUBREGION_SET_OPTIONS = 'CONSULT_FILTERS_SUBREGION_SET_OPTIONS';
export const CONSULT_FILTERS_SUBREGION_CLEAR_OPTIONS = 'CONSULT_FILTERS_SUBREGION_CLEAR_OPTIONS';

export const CONSULT_FILTERS_SUBREGION_SET_VALUE = 'CONSULT_FILTERS_SUBREGION_SET_VALUE';
export const CONSULT_FILTERS_SUBREGION_CLEAR_VALUE = 'CONSULT_FILTERS_SUBREGION_CLEAR_VALUE';

export function consultFiltersSubregionRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_SUBREGION_REQUEST_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersSubregionSetOptions(payload) {
  return {
    type: CONSULT_FILTERS_SUBREGION_SET_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersSubregionClearOptions() {
  return {
    type: CONSULT_FILTERS_SUBREGION_CLEAR_OPTIONS,
  };
}

export function consultFiltersSubregionSetValue(payload) {
  return {
    type: CONSULT_FILTERS_SUBREGION_SET_VALUE,
    payload: payload,
  };
}

export function consultFiltersSubregionClearValue() {
  return {
    type: CONSULT_FILTERS_SUBREGION_CLEAR_VALUE,
  };
}

/* Segmentation */

export const CONSULT_FILTERS_SEGMENTATION_REQUEST_OPTIONS =
  'CONSULT_FILTERS_SEGMENTATION_REQUEST_OPTIONS';
export const CONSULT_FILTERS_SEGMENTATION_SET_OPTIONS = 'CONSULT_FILTERS_SEGMENTATION_SET_OPTIONS';
export const CONSULT_FILTERS_SEGMENTATION_CLEAR_OPTIONS =
  'CONSULT_FILTERS_SEGMENTATION_CLEAR_OPTIONS';

export const CONSULT_FILTERS_SEGMENTATION_SET_VALUE = 'CONSULT_FILTERS_SEGMENTATION_SET_VALUE';
export const CONSULT_FILTERS_SEGMENTATION_CLEAR_VALUE = 'CONSULT_FILTERS_SEGMENTATION_CLEAR_VALUE';

export function consultFiltersSegmentationRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_SEGMENTATION_REQUEST_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersSegmentationSetOptions(payload) {
  return {
    type: CONSULT_FILTERS_SEGMENTATION_SET_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersSegmentationClearOptions() {
  return {
    type: CONSULT_FILTERS_SEGMENTATION_CLEAR_OPTIONS,
  };
}

export function consultFiltersSegmentationSetValue(payload) {
  return {
    type: CONSULT_FILTERS_SEGMENTATION_SET_VALUE,
    payload: payload,
  };
}

export function consultFiltersSegmentationClearValue() {
  return {
    type: CONSULT_FILTERS_SEGMENTATION_CLEAR_VALUE,
  };
}

/* placement */

export const CONSULT_FILTERS_PLACEMENT_REQUEST_OPTIONS =
  'CONSULT_FILTERS_PLACEMENT_REQUEST_OPTIONS';
export const CONSULT_FILTERS_PLACEMENT_SET_OPTIONS = 'CONSULT_FILTERS_PLACEMENT_SET_OPTIONS';
export const CONSULT_FILTERS_PLACEMENT_CLEAR_OPTIONS = 'CONSULT_FILTERS_PLACEMENT_CLEAR_OPTIONS';

export const CONSULT_FILTERS_PLACEMENT_SET_VALUE = 'CONSULT_FILTERS_PLACEMENT_SET_VALUE';
export const CONSULT_FILTERS_PLACEMENT_CLEAR_VALUE = 'CONSULT_FILTERS_PLACEMENT_CLEAR_VALUE';

export function consultFiltersPlacementRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_PLACEMENT_REQUEST_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersPlacementSetOptions(payload) {
  return {
    type: CONSULT_FILTERS_PLACEMENT_SET_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersPlacementClearOptions() {
  return {
    type: CONSULT_FILTERS_PLACEMENT_CLEAR_OPTIONS,
  };
}

export function consultFiltersPlacementSetValue(payload) {
  return {
    type: CONSULT_FILTERS_PLACEMENT_SET_VALUE,
    payload: payload,
  };
}

export function consultFiltersPlacementClearValue() {
  return {
    type: CONSULT_FILTERS_PLACEMENT_CLEAR_VALUE,
  };
}

/* search input */

export const CONSULT_FILTERS_SEARCH_INPUT_SET_VALUE = 'CONSULT_FILTERS_SEARCH_INPUT_SET_VALUE';
export const CONSULT_FILTERS_SEARCH_INPUT_CLEAR_VALUE = 'CONSULT_FILTERS_SEARCH_INPUT_CLEAR_VALUE';

export function consultFiltersSearchInputSetValue(payload) {
  return {
    type: CONSULT_FILTERS_SEARCH_INPUT_SET_VALUE,
    payload: payload,
  };
}

export function consultFiltersSearchInputClearValue() {
  return {
    type: CONSULT_FILTERS_SEARCH_INPUT_CLEAR_VALUE,
  };
}
/* year */
export const CONSULT_FILTERS_YEAR_REQUEST_OPTIONS = 'CONSULT_FILTERS_YEAR_REQUEST_OPTIONS';
export const CONSULT_FILTERS_YEAR_SET_OPTIONS = 'CONSULT_FILTERSYEARE_SET_OPTIONS';
export const CONSULT_FILTERS_YEAR_CLEAR_OPTIONS = 'CONSULT_FILTERS_YEAR_CLEAR_OPTIONS';

export const CONSULT_FILTERS_YEAR_SET_VALUE = 'CONSULT_FILTERS_YEAR_SET_VALUE';
export const CONSULT_FILTERS_YEAR_CLEAR_VALUE = 'CONSULT_FILTERS_YEAR_CLEAR_VALUE';

export function consultFiltersYearRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_YEAR_REQUEST_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersYearSetOptions(payload) {
  return {
    type: CONSULT_FILTERS_YEAR_SET_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersYearClearOptions() {
  return {
    type: CONSULT_FILTERS_YEAR_CLEAR_OPTIONS,
  };
}

export function consultFiltersYearSetValue(payload) {
  return {
    type: CONSULT_FILTERS_YEAR_SET_VALUE,
    payload: payload,
  };
}

export function consultFiltersYearClearValue() {
  return {
    type: CONSULT_FILTERS_YEAR_CLEAR_VALUE,
  };
}
/* pogPeriodicity */
export const CONSULT_FILTERS_POG_PERIODICITY_REQUEST_OPTIONS =
  'CONSULT_FILTERS_POG_PERIODICITY_REQUEST_OPTIONS';
export const CONSULT_FILTERS_POG_PERIODICITY_SET_OPTIONS =
  'CONSULT_FILTERSPOG_PERIODICITYE_SET_OPTIONS';
export const CONSULT_FILTERS_POG_PERIODICITY_CLEAR_OPTIONS =
  'CONSULT_FILTERS_POG_PERIODICITY_CLEAR_OPTIONS';

export const CONSULT_FILTERS_POG_PERIODICITY_SET_VALUE =
  'CONSULT_FILTERS_POG_PERIODICITY_SET_VALUE';
export const CONSULT_FILTERS_POG_PERIODICITY_CLEAR_VALUE =
  'CONSULT_FILTERS_POG_PERIODICITY_CLEAR_VALUE';

export function consultFiltersPogPeriodicityRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_POG_PERIODICITY_REQUEST_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersPogPeriodicitySetOptions(payload) {
  return {
    type: CONSULT_FILTERS_POG_PERIODICITY_SET_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersPogPeriodicityClearOptions() {
  return {
    type: CONSULT_FILTERS_POG_PERIODICITY_CLEAR_OPTIONS,
  };
}

export function consultFiltersPogPeriodicitySetValue(payload) {
  return {
    type: CONSULT_FILTERS_POG_PERIODICITY_SET_VALUE,
    payload: payload,
  };
}

export function consultFiltersPogPeriodicityClearValue() {
  return {
    type: CONSULT_FILTERS_POG_PERIODICITY_CLEAR_VALUE,
  };
}

/* pogPeriod */
export const CONSULT_FILTERS_POG_PERIOD_REQUEST_OPTIONS =
  'CONSULT_FILTERS_POG_PERIOD_REQUEST_OPTIONS';
export const CONSULT_FILTERS_POG_PERIOD_SET_OPTIONS = 'CONSULT_FILTERSPOG_PERIODE_SET_OPTIONS';
export const CONSULT_FILTERS_POG_PERIOD_CLEAR_OPTIONS = 'CONSULT_FILTERS_POG_PERIOD_CLEAR_OPTIONS';

export const CONSULT_FILTERS_POG_PERIOD_SET_VALUE = 'CONSULT_FILTERS_POG_PERIOD_SET_VALUE';
export const CONSULT_FILTERS_POG_PERIOD_CLEAR_VALUE = 'CONSULT_FILTERS_POG_PERIOD_CLEAR_VALUE';

export function consultFiltersPogPeriodRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_POG_PERIOD_REQUEST_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersPogPeriodSetOptions(payload) {
  return {
    type: CONSULT_FILTERS_POG_PERIOD_SET_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersPogPeriodClearOptions() {
  return {
    type: CONSULT_FILTERS_POG_PERIOD_CLEAR_OPTIONS,
  };
}

export function consultFiltersPogPeriodSetValue(payload) {
  return {
    type: CONSULT_FILTERS_POG_PERIOD_SET_VALUE,
    payload: payload,
  };
}

export function consultFiltersPogPeriodClearValue() {
  return {
    type: CONSULT_FILTERS_POG_PERIOD_CLEAR_VALUE,
  };
}

/* salesPeriodicity */
export const CONSULT_FILTERS_SALES_PERIODICITY_REQUEST_OPTIONS =
  'CONSULT_FILTERS_SALES_PERIODICITY_REQUEST_OPTIONS';
export const CONSULT_FILTERS_SALES_PERIODICITY_SET_OPTIONS =
  'CONSULT_FILTERSSALES_PERIODICITYE_SET_OPTIONS';
export const CONSULT_FILTERS_SALES_PERIODICITY_CLEAR_OPTIONS =
  'CONSULT_FILTERS_SALES_PERIODICITY_CLEAR_OPTIONS';

export const CONSULT_FILTERS_SALES_PERIODICITY_SET_VALUE =
  'CONSULT_FILTERS_SALES_PERIODICITY_SET_VALUE';
export const CONSULT_FILTERS_SALES_PERIODICITY_CLEAR_VALUE =
  'CONSULT_FILTERS_SALES_PERIODICITY_CLEAR_VALUE';

export function consultFiltersSalesPeriodicityRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_SALES_PERIODICITY_REQUEST_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersSalesPeriodicitySetOptions(payload) {
  return {
    type: CONSULT_FILTERS_SALES_PERIODICITY_SET_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersSalesPeriodicityClearOptions() {
  return {
    type: CONSULT_FILTERS_SALES_PERIODICITY_CLEAR_OPTIONS,
  };
}

export function consultFiltersSalesPeriodicitySetValue(payload) {
  return {
    type: CONSULT_FILTERS_SALES_PERIODICITY_SET_VALUE,
    payload: payload,
  };
}

export function consultFiltersSalesPeriodicityClearValue() {
  return {
    type: CONSULT_FILTERS_SALES_PERIODICITY_CLEAR_VALUE,
  };
}

/* salesPeriod */
export const CONSULT_FILTERS_SALES_PERIOD_REQUEST_OPTIONS =
  'CONSULT_FILTERS_SALES_PERIOD_REQUEST_OPTIONS';
export const CONSULT_FILTERS_SALES_PERIOD_SET_OPTIONS = 'CONSULT_FILTERSSALES_PERIODE_SET_OPTIONS';
export const CONSULT_FILTERS_SALES_PERIOD_CLEAR_OPTIONS =
  'CONSULT_FILTERS_SALES_PERIOD_CLEAR_OPTIONS';

export const CONSULT_FILTERS_SALES_PERIOD_SET_VALUE = 'CONSULT_FILTERS_SALES_PERIOD_SET_VALUE';
export const CONSULT_FILTERS_SALES_PERIOD_CLEAR_VALUE = 'CONSULT_FILTERS_SALES_PERIOD_CLEAR_VALUE';

export function consultFiltersSalesPeriodRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_SALES_PERIOD_REQUEST_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersSalesPeriodSetOptions(payload) {
  return {
    type: CONSULT_FILTERS_SALES_PERIOD_SET_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersSalesPeriodClearOptions() {
  return {
    type: CONSULT_FILTERS_SALES_PERIOD_CLEAR_OPTIONS,
  };
}

export function consultFiltersSalesPeriodSetValue(payload) {
  return {
    type: CONSULT_FILTERS_SALES_PERIOD_SET_VALUE,
    payload: payload,
  };
}

export function consultFiltersSalesPeriodClearValue() {
  return {
    type: CONSULT_FILTERS_SALES_PERIOD_CLEAR_VALUE,
  };
}

/* territories */
export const CONSULT_FILTERS_TERRITORIES_REQUEST_OPTIONS =
  'CONSULT_FILTERS_TERRITORIES_REQUEST_OPTIONS';
export const CONSULT_FILTERS_TERRITORIES_SET_OPTIONS = 'CONSULT_FILTERSTERRITORIESE_SET_OPTIONS';
export const CONSULT_FILTERS_TERRITORIES_CLEAR_OPTIONS =
  'CONSULT_FILTERS_TERRITORIES_CLEAR_OPTIONS';

export const CONSULT_FILTERS_TERRITORIES_SET_VALUE = 'CONSULT_FILTERS_TERRITORIES_SET_VALUE';
export const CONSULT_FILTERS_TERRITORIES_CLEAR_VALUE = 'CONSULT_FILTERS_TERRITORIES_CLEAR_VALUE';

export function consultFiltersTerritoriesRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_TERRITORIES_REQUEST_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersTerritoriesSetOptions(payload) {
  return {
    type: CONSULT_FILTERS_TERRITORIES_SET_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersTerritoriesClearOptions() {
  return {
    type: CONSULT_FILTERS_TERRITORIES_CLEAR_OPTIONS,
  };
}

export function consultFiltersTerritoriesSetValue(payload) {
  return {
    type: CONSULT_FILTERS_TERRITORIES_SET_VALUE,
    payload: payload,
  };
}

export function consultFiltersTerritoriesClearValue() {
  return {
    type: CONSULT_FILTERS_TERRITORIES_CLEAR_VALUE,
  };
}

/* Months */
export const CONSULT_FILTERS_MONTHS_REQUEST_OPTIONS = 'CONSULT_FILTERS_MONTHS_REQUEST_OPTIONS';
export const CONSULT_FILTERS_MONTHS_SET_OPTIONS = 'CONSULT_FILTERS_MONTHS_SET_OPTIONS';
export const CONSULT_FILTERS_MONTHS_CLEAR_OPTIONS = 'CONSULT_FILTERS_MONTHS_CLEAR_OPTIONS';

export const CONSULT_FILTERS_MONTHS_SET_VALUE = 'CONSULT_FILTERS_MONTHS_SET_VALUE';
export const CONSULT_FILTERS_MONTHS_CLEAR_VALUE = 'CONSULT_FILTERS_MONTHS_CLEAR_VALUE';

export function consultFiltersMonthsRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_MONTHS_REQUEST_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersMonthsSetOptions(payload) {
  return {
    type: CONSULT_FILTERS_MONTHS_SET_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersMonthsClearOptions() {
  return {
    type: CONSULT_FILTERS_MONTHS_CLEAR_OPTIONS,
  };
}

export function consultFiltersMonthsSetValue(payload) {
  return {
    type: CONSULT_FILTERS_MONTHS_SET_VALUE,
    payload: payload,
  };
}

export function consultFiltersMonthsClearValue() {
  return {
    type: CONSULT_FILTERS_MONTHS_CLEAR_VALUE,
  };
}

/* dateRange */
export const CONSULT_FILTERS_DATE_RANGE_REQUEST_OPTIONS =
  'CONSULT_FILTERS_DATE_RANGE_REQUEST_OPTIONS';
export const CONSULT_FILTERS_DATE_RANGE_SET_OPTIONS = 'CONSULT_FILTERSDATE_RANGEE_SET_OPTIONS';
export const CONSULT_FILTERS_DATE_RANGE_CLEAR_OPTIONS = 'CONSULT_FILTERS_DATE_RANGE_CLEAR_OPTIONS';

export const CONSULT_FILTERS_DATE_RANGE_SET_VALUE = 'CONSULT_FILTERS_DATE_RANGE_SET_VALUE';
export const CONSULT_FILTERS_DATE_RANGE_CLEAR_VALUE = 'CONSULT_FILTERS_DATE_RANGE_CLEAR_VALUE';

export function consultFiltersDateRangeRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_DATE_RANGE_REQUEST_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersDateRangeSetOptions(payload) {
  return {
    type: CONSULT_FILTERS_DATE_RANGE_SET_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersDateRangeClearOptions() {
  return {
    type: CONSULT_FILTERS_DATE_RANGE_CLEAR_OPTIONS,
  };
}

export function consultFiltersDateRangeSetValue(payload) {
  return {
    type: CONSULT_FILTERS_DATE_RANGE_SET_VALUE,
    payload: payload,
  };
}

export function consultFiltersDateRangeClearValue() {
  return {
    type: CONSULT_FILTERS_DATE_RANGE_CLEAR_VALUE,
  };
}

/* pog year */
export const CONSULT_FILTERS_POGYEAR_REQUEST_OPTIONS = 'CONSULT_FILTERS_POGYEAR_REQUEST_OPTIONS';
export const CONSULT_FILTERS_POGYEAR_SET_OPTIONS = 'CONSULT_FILTERS_POGYEAR_SET_OPTIONS';
export const CONSULT_FILTERS_POGYEAR_CLEAR_OPTIONS = 'CONSULT_FILTERS_POGYEAR_CLEAR_OPTIONS';

export const CONSULT_FILTERS_POGYEAR_SET_VALUE = 'CONSULT_FILTERS_POGYEAR_SET_VALUE';
export const CONSULT_FILTERS_POGYEAR_CLEAR_VALUE = 'CONSULT_FILTERS_POGYEAR_CLEAR_VALUE';

export function consultFiltersPogYearRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_POGYEAR_REQUEST_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersPogYearSetOptions(payload) {
  return {
    type: CONSULT_FILTERS_POGYEAR_SET_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersPogYearClearOptions() {
  return {
    type: CONSULT_FILTERS_POGYEAR_CLEAR_OPTIONS,
  };
}

export function consultFiltersPogYearSetValue(payload) {
  return {
    type: CONSULT_FILTERS_POGYEAR_SET_VALUE,
    payload: payload,
  };
}

export function consultFiltersPogYearClearValue() {
  return {
    type: CONSULT_FILTERS_POGYEAR_CLEAR_VALUE,
  };
}

/* objectivesAndIncentives */
export const CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_REQUEST_OPTIONS =
  'CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_REQUEST_OPTIONS';
export const CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_SET_OPTIONS =
  'CONSULT_FILTERSOBJECTIVES_AND_INCENTIVESE_SET_OPTIONS';
export const CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_CLEAR_OPTIONS =
  'CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_CLEAR_OPTIONS';

export const CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_SET_VALUE =
  'CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_SET_VALUE';
export const CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_CLEAR_VALUE =
  'CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_CLEAR_VALUE';

export function consultFiltersObjectivesAndIncentivesRequestOptions(payload) {
  return {
    type: CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_REQUEST_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersObjectivesAndIncentivesSetOptions(payload) {
  return {
    type: CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_SET_OPTIONS,
    payload: payload,
  };
}

export function consultFiltersObjectivesAndIncentivesClearOptions() {
  return {
    type: CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_CLEAR_OPTIONS,
  };
}

export function consultFiltersObjectivesAndIncentivesSetValue(payload) {
  return {
    type: CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_SET_VALUE,
    payload: payload,
  };
}

export function consultFiltersObjectivesAndIncentivesClearValue() {
  return {
    type: CONSULT_FILTERS_OBJECTIVES_AND_INCENTIVES_CLEAR_VALUE,
  };
}
