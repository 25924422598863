import reducer from './redux-saga/reducers';

import {
  watchInventoriesMassiveUploadSetAllowUpload,
  watchInventoriesMassiveUploadRequestVerify,
  watchInventoriesMassiveUploadRequestUpload,
} from './redux-saga/sagas';

export default function getInventoriesMassiveUploadModule() {
  return {
    id: 'Inventories_MASSIVE_UPLOAD',
    reducerMap: {
      Inventories_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchInventoriesMassiveUploadSetAllowUpload,
      watchInventoriesMassiveUploadRequestVerify,
      watchInventoriesMassiveUploadRequestUpload,
    ],
  };
}
