import rootReducer from './redux/reducers/rootReducers';
import rootSagas from './redux/sagas/index';

export default function getRootModule() {
  return {
    id: 'APP',
    reducerMap: {
      APP: rootReducer,
    },
    sagas: [rootSagas],
  };
}
