export const CLEAN_REGISTER_TRADE = 'TRADE-AGREEMENTS/CLEAN_REGISTER_TRADE';
export function cleanRegisterTrade() {
  return {
    type: CLEAN_REGISTER_TRADE,
  };
}

export const GET_DAYS_LEFT_REQUEST = 'GET_DAYS_LEFT_REQUEST';
export const GET_DAYS_LEFT_SUCCESS = 'GET_DAYS_LEFT_SUCCESS';
export const GET_DAYS_LEFT_FAIL = 'GET_DAYS_LEFT_FAIL';
export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';
export const GET_PRODUCTS_PRICES_REQUEST = 'GET_PRODUCTS_PRICES_REQUEST';
export const GET_PRODUCTS_PRICES_SUCCESS = 'GET_PRODUCTS_PRICES_SUCCESS';
export const GET_PRODUCTS_PRICES_FAIL = 'GET_PRODUCTS_PRICES_FAIL';

export const GET_AGREEMENT_REQUEST = 'GET_AGREEMENT_REQUEST';
export const GET_AGREEMENT_SUCCESS = 'GET_AGREEMENT_SUCCESS';
export const GET_AGREEMENT_FAIL = 'GET_AGREEMENT_FAIL';

export const GET_REACH_REQUEST = 'GET_REACH_REQUEST';
export const GET_REACH_SUCCESS = 'GET_REACH_SUCCESS';
export const GET_REACH_FAIL = 'GET_REACH_FAIL';

export const ARE_VOLUMES_VALIDS = 'ARE_VOLUMES_VALIDS/TRADE';

export const SEND_TO_VALIDATE_REQUEST = 'SEND_TO_VALIDATE_REQUEST/TRADE';
export const SEND_TO_VALIDATE_SUCCESS = 'SEND_TO_VALIDATE_SUCCESS/TRADE';
export const SEND_TO_VALIDATE_FAIL = 'SEND_TO_VALIDATE_FAIL/TRADE';

export const SAVE_AGREEMENT_REQUEST = 'SAVE_AGREEMENT_REQUEST';
export const SAVE_AGREEMENT_SUCCESS = 'SAVE_AGREEMENT_SUCCESS';
export const SAVE_AGREEMENT_FAIL = 'SAVE_AGREEMENT_FAIL';

export const UPDATE_CURRENT_AGREEMENT = 'UPDATE_CURRENT_AGREEMENT';
export const POST_AGREEMENT_REQUEST = 'POST_AGREEMENT_REQUEST';
export const POST_AGREEMENT_SUCCESS = 'POST_AGREEMENT_SUCCESS';
export const POST_AGREEMENT_FAIL = 'POST_AGREEMENT_FAIL';

export const UPDATE_REACH = 'UPDATE_REACH';

export const TRADE_COMMENTS_REQUEST = 'TRADE_COMMENTS_REQUEST';
export const TRADE_COMMENTS_SUCCESS = 'TRADE_COMMENTS_SUCCESS';
export const TRADE_COMMENTS_FAIL = 'TRADE_COMMENTS_FAIL';

export function areVolumesValids(payload) {
  return {
    type: ARE_VOLUMES_VALIDS,
    payload,
  };
}

export function sendToValidateRequest(payload) {
  return {
    type: SEND_TO_VALIDATE_REQUEST,
    payload,
  };
}

export function sendToValidateSuccess(payload) {
  return { type: SEND_TO_VALIDATE_SUCCESS, payload };
}

export function sendToValidateFail(payload) {
  return { type: SEND_TO_VALIDATE_FAIL, payload };
}

export function getAgreementRequest(payload) {
  return {
    type: GET_AGREEMENT_REQUEST,
    payload,
  };
}

export function getAgreementSuccess(payload) {
  return { type: GET_AGREEMENT_SUCCESS, payload };
}

export function getAgreementFail(payload) {
  return { type: GET_AGREEMENT_FAIL, payload };
}

export function getDaysLeftRequest(payload) {
  return {
    type: GET_DAYS_LEFT_REQUEST,
    payload,
  };
}

export function getDayLeftSuccess(payload) {
  return {
    type: GET_DAYS_LEFT_SUCCESS,
    payload,
  };
}

export function getDayLeftFail() {
  return {
    type: GET_DAYS_LEFT_FAIL,
  };
}

export function getProductsRequest(payload) {
  return {
    type: GET_PRODUCTS_REQUEST,
    payload,
  };
}

export function getProductsSuccess(payload) {
  return {
    type: GET_PRODUCTS_SUCCESS,
    payload,
  };
}

export function getProductsFail() {
  return {
    type: GET_PRODUCTS_FAIL,
  };
}

export function getProductsPricesRequest(payload) {
  return {
    type: GET_PRODUCTS_PRICES_REQUEST,
    payload,
  };
}

export function getProductsPricesSuccess(payload) {
  return {
    type: GET_PRODUCTS_PRICES_SUCCESS,
    payload,
  };
}

export function getProductsPricesFail() {
  return {
    type: GET_PRODUCTS_PRICES_FAIL,
  };
}

export function getReachRequest(payload) {
  return {
    type: GET_REACH_REQUEST,
    payload,
  };
}

export function getReachSuccess(payload) {
  return {
    type: GET_REACH_SUCCESS,
    payload,
  };
}

export function getReachFail() {
  return {
    type: GET_REACH_FAIL,
  };
}

export function saveAgreenmentRequest(payload) {
  return {
    type: SAVE_AGREEMENT_REQUEST,
    payload,
  };
}

export function saveAgreenmentSuccess(payload) {
  return {
    type: SAVE_AGREEMENT_SUCCESS,
    payload,
  };
}

export function saveAgreenmentFail() {
  return {
    type: SAVE_AGREEMENT_FAIL,
  };
}

export function updateCurrentAgreement(payload) {
  return {
    type: UPDATE_CURRENT_AGREEMENT,
    payload,
  };
}

export function updateReach(payload) {
  return {
    type: UPDATE_REACH,
    payload,
  };
}

export function tradeCommentsRequest(payload) {
  return {
    type: TRADE_COMMENTS_REQUEST,
    payload,
  };
}

export function tradeCommentsSuccess(payload) {
  return {
    type: TRADE_COMMENTS_SUCCESS,
    payload,
  };
}

export function tradeCommentsFail() {
  return {
    type: TRADE_COMMENTS_FAIL,
  };
}

export function postAgreementRequest(payload) {
  return {
    type: POST_AGREEMENT_REQUEST,
    payload,
  };
}

export function postAgreementSuccess(payload) {
  return {
    type: POST_AGREEMENT_SUCCESS,
    payload,
  };
}
export function postAgreemenFail(payload) {
  return {
    type: POST_AGREEMENT_FAIL,
    payload,
  };
}
