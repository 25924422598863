import AutomaticSendReducer from './redux-saga/reducers/rootReducer';
import rootSagas from './redux-saga/sagas/rootSagas';
export default function getRootModule() {
  return {
    id: 'AUTOMATICSEND',
    reducerMap: {
      AUTOMATICSEND: AutomaticSendReducer,
    },
    sagas: [rootSagas],
  };
}
