import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ReviewActions } from 'common/components/ReviewActions';
import { salesChallengeStatus } from 'common';
import { validateRequest } from 'SalesChallengeRefactor/redux/actions/challenge';
import { getDaysLeftRequest } from '../../redux/actions/registerSale';
import {
  aproveTerritoryModal,
  rejectTerritoryModal,
  aproveZoneModal,
  rejectZoneModal,
  aproveClientModal,
  rejectClientModal,
} from './aproveRejectModals';
import moment from 'moment';

let generalScope = '';
function ReviewActionsContainer(props) {
  const {
    scope,
    name,
    statusId,
    clientsRejected,
    requestParams,
    validateRequest: validateReq,
    rejectedClients,
    subRegionId,
    territoryId,
    zoneId,
    clientId,
    familyId,
    productId,
    year,
    getDaysLeftRequest: getDaysLeft,
    daysLeftValidateData,
  } = props;
  const [dateEndValidation, setDateEndalidation] = useState(null);

  useEffect(() => {
    if (
      subRegionId !== undefined ||
      territoryId !== undefined ||
      zoneId !== undefined ||
      clientId !== undefined ||
      familyId !== undefined ||
      productId !== undefined
    ) {
      getDaysLeft({ params: { processId: 4 } });
    }
  }, [getDaysLeft, subRegionId, territoryId, zoneId, clientId, familyId, productId]);

  useEffect(() => {
    if (daysLeftValidateData) {
      let endDate = moment(daysLeftValidateData.end);
      let date = moment(new Date());
      let daysDiff = endDate.diff(date, 'seconds');
      setDateEndalidation(daysDiff);
    }
  }, [daysLeftValidateData]);

  const infoRender = () => {
    let renderPayload = {};

    switch (scope) {
      case 'General':
        if (
          territoryId !== undefined &&
          zoneId !== undefined &&
          clientId !== undefined &&
          familyId !== undefined
        ) {
          generalScope = 'Family';
          renderPayload = {
            family: familyId,
            zoneId,
            clientId,
            year,
          };
        } else if (
          territoryId !== undefined &&
          zoneId !== undefined &&
          clientId !== undefined &&
          familyId === undefined
        ) {
          generalScope = 'Client';
          renderPayload = {
            zoneId,
            clientId,
            year,
          };
        } else if (
          territoryId !== undefined &&
          zoneId !== undefined &&
          clientId === undefined &&
          familyId === undefined
        ) {
          generalScope = 'Zone';
          renderPayload = {
            zoneId,
            year,
          };
        } else if (
          territoryId !== undefined &&
          zoneId === undefined &&
          clientId === undefined &&
          familyId === undefined
        ) {
          generalScope = 'Territory';
          renderPayload = {
            territoryId,
            year,
          };
        }
        break;

      case 'Territory':
        renderPayload = {
          territoryId,
          year,
        };
        break;
      case 'Subregion':
        renderPayload = {
          subRegionId,
          year,
        };
        break;

      case 'Zone':
        renderPayload = {
          zoneId,
          year,
        };
        break;
      default:
        break;
    }
    return renderPayload;
  };

  const onValidate = (postStatus, comment) => {
    //TODO CREATE SWITCH FOR INFOPARAMS DEPENDS ON SCOPE
    //requestParams FOR VALIDATION SERVICE
    //infoParams TO SECOND TIME INFO AND INDICATORS RENDER
    const infoParams = infoRender();
    let zoneFinal = typeof requestParams.zoneId === 'undefined' ? zoneId : requestParams.zoneId;
    const params = {
      ...requestParams,
      zoneId: zoneFinal,
      comment,
    };

    if (scope !== 'General') {
      validateReq({ name: name, infoParams, scope, data: { status: postStatus, ...params } });
    } else {
      validateReq({
        name: name,
        infoParams,
        scope: generalScope,
        data: { status: postStatus, ...params },
      });
    }
  };

  if (
    (statusId === salesChallengeStatus.ON_SECOND_VALIDATION ||
      statusId === salesChallengeStatus.ON_VALIDATION) &&
    (scope === 'Subregion' || scope === 'Territory') &&
    dateEndValidation > 0
  ) {
    return (
      <ReviewActions
        className={'sales-challenge'}
        //templateLiteralsAprove={aproveTerritoryModal(rejectedClients)}
        templateLiteralsAprove={aproveTerritoryModal(clientsRejected)}
        templateLiteralsReject={rejectTerritoryModal}
        onApprove={() => onValidate(true)}
        onRejected={(comment) => onValidate(false, comment)}
        actions={['approve', 'rejected']}
        showLabels={false}
      />
    );
  } else if (
    statusId === salesChallengeStatus.ON_VALIDATION &&
    scope === 'Territory' &&
    dateEndValidation > 0
  ) {
    return (
      <ReviewActions
        className={'sales-challenge'}
        templateLiteralsAprove={aproveZoneModal(clientsRejected)}
        templateLiteralsReject={rejectZoneModal}
        onApprove={() => onValidate(true)}
        onRejected={(comment) => onValidate(false, comment)}
        actions={['approve', 'rejected']}
        showLabels={false}
      />
    );
  } else if (
    statusId === salesChallengeStatus.ON_VALIDATION &&
    scope === 'Zone' &&
    dateEndValidation > 0
  ) {
    return (
      <ReviewActions
        className={'sales-challenge'}
        templateLiteralsAprove={aproveClientModal}
        templateLiteralsReject={rejectClientModal}
        onApprove={() => onValidate(true)}
        onRejected={(comment) => onValidate(false, comment)}
        actions={['approve', 'rejected']}
        showLabels={false}
      />
    );
  } else if (
    statusId === salesChallengeStatus.ON_SECOND_VALIDATION &&
    scope === 'General' &&
    dateEndValidation > 0
  ) {
    return (
      <>
        <span> segunda validación </span>
        <ReviewActions
          className={'sales-challenge'}
          templateLiteralsAprove={aproveTerritoryModal(rejectedClients)}
          templateLiteralsReject={rejectTerritoryModal}
          onApprove={() => onValidate(true)}
          onRejected={(comment) => onValidate(false, comment)}
          actions={['approve', 'rejected']}
          showLabels={false}
        />
      </>
    );
  } else {
    return null;
  }
}

ReviewActionsContainer.propTypes = {
  /*Name of the the step . Will be used for info request */
  scope: PropTypes.oneOf(['Subregion', 'Territory', 'Zone', 'General']),
  /* Status challenge */
  statusId: PropTypes.number,
  /*Name of the the validation . Will be used for validation request */
  name: PropTypes.oneOf(['ValidateTerritory', 'ValidateZone', 'ValidateClient']),
};

ReviewActionsContainer.defaultProps = {
  statusId: 0,
};

function mapStateToProps(state) {
  return {
    year: state?.SALES_CHALLENGE?.query?.year,
    subRegionId: state?.SALES_CHALLENGE?.query?.subRegionId,
    territoryId: state?.SALES_CHALLENGE?.query?.territoryId,
    zoneId: state?.SALES_CHALLENGE?.query?.zoneId,
    clientId: state?.SALES_CHALLENGE?.query?.clientId,
    familyId: state?.SALES_CHALLENGE?.query?.family,
    productId: state?.SALES_CHALLENGE?.query?.productId,
    daysLeftValidateData: state?.SALES_CHALLENGE?.registerSales?.daysLeft?.data,
    approvedClients: state?.SALES_CHALLENGE?.indicators?.territoryData?.approved,
    rejectedClients: state?.SALES_CHALLENGE?.indicators?.territoryData?.rejected,
    approvedZoneClients: state?.SALES_CHALLENGE?.indicators?.zoneData?.approved,
    rejectedZoneClients: state?.SALES_CHALLENGE?.indicators?.zoneData?.rejected,
  };
}

export default connect(mapStateToProps, { validateRequest, getDaysLeftRequest })(
  ReviewActionsContainer
);
