export const GET_MAPS_REQUEST = 'GET_MAPS_REQUEST';
export const GET_MAPS_SUCCESS = 'GET_MAPS_SUCCESS';
export const GET_MAPS_CLEAN = 'GET_MAPS_CLEAN';

export const GET_GET_REQUEST = 'GET_GET_REQUEST';
export const GET_GET_SUCCESS = 'GET_GET_SUCCESS';
export const GET_GET_CLEAN = 'GET_GET_CLEAN';

export const GET_getFilterYear_REQUEST = 'GET_getFilterYear_REQUEST';
export const GET_getFilterYear_SUCCESS = 'GET_getFilterYear_SUCCESS';
export const GET_getFilterYear_CLEAN = 'GET_getFilterYear_CLEAN';

export const GET_file_REQUEST = 'GET_file_REQUEST';
export const GET_file_SUCCESS = 'GET_file_SUCCESS';
export const GET_file_CLEAN = 'GET_file_CLEAN';

export const GET_DaysLeft_REQUEST = 'GET_DaysLeft_REQUEST';
export const GET_DaysLeft_SUCCESS = 'GET_DaysLeft_SUCCESS';
export const GET_DaysLeft_CLEAN = 'GET_DaysLeft_CLEAN';

export const GET_AgreementExists_REQUEST = 'GET_AgreementExists_REQUEST';
export const GET_AgreementExists_SUCCESS = 'GET_AgreementExists_SUCCESS';
export const GET_AgreementExists_CLEAN = 'GET_AgreementExists_CLEAN';

export const GET_ChallengeExists_REQUEST = 'GET_ChallengeExists_REQUEST';
export const GET_ChallengeExists_SUCCESS = 'GET_ChallengeExists_SUCCESS';
export const GET_ChallengeExists_CLEAN = 'GET_ChallengeExists_CLEAN';

//GET_ChallengeExists_REQUEST
export function getChallengeExistsRequest(payload) {
  return {
    type: GET_ChallengeExists_REQUEST,
    payload: payload,
  };
}
export function getChallengeExistsSuccess(payload) {
  return {
    type: GET_ChallengeExists_SUCCESS,
    payload: payload,
  };
}
export function getChallengeExistsClean() {
  return {
    type: GET_ChallengeExists_CLEAN,
  };
}

//GET_AgreementExists_REQUEST
export function getAgreementExistsRequest(payload) {
  return {
    type: GET_AgreementExists_REQUEST,
    payload: payload,
  };
}
export function getAgreementExistsSuccess(payload) {
  return {
    type: GET_AgreementExists_SUCCESS,
    payload: payload,
  };
}
export function getAgreementExistsClean() {
  return {
    type: GET_AgreementExists_CLEAN,
  };
}

//GET_DaysLeft_REQUEST
export function getDaysLeftRequest(payload) {
  return {
    type: GET_DaysLeft_REQUEST,
    payload: payload,
  };
}
export function getDaysLeftSuccess(payload) {
  return {
    type: GET_DaysLeft_SUCCESS,
    payload: payload,
  };
}
export function getDaysLeftClean() {
  return {
    type: GET_DaysLeft_CLEAN,
  };
}

//GET_file_REQUEST
export function getfileRequest(payload) {
  return {
    type: GET_file_REQUEST,
    payload: payload,
  };
}
export function getfileSuccess(payload) {
  return {
    type: GET_file_SUCCESS,
    payload: payload,
  };
}
export function getfileClean() {
  return {
    type: GET_file_CLEAN,
  };
}

//GET_getFilterYear_REQUEST
export function getFilterYearRequest(payload) {
  return {
    type: GET_getFilterYear_REQUEST,
    payload: payload,
  };
}
export function getFilterYearSuccess(payload) {
  return {
    type: GET_getFilterYear_SUCCESS,
    payload: payload,
  };
}
export function getFilterYearClean() {
  return {
    type: GET_getFilterYear_CLEAN,
  };
}

//GET_GET_REQUEST
export function getGetRequest(payload) {
  return {
    type: GET_GET_REQUEST,
    payload: payload,
  };
}
export function getGetSuccess(payload) {
  return {
    type: GET_GET_SUCCESS,
    payload: payload,
  };
}
export function getGetClean() {
  return {
    type: GET_GET_CLEAN,
  };
}

//GET_MAPS_REQUEST
export function getMapsRequest(payload) {
  return {
    type: GET_MAPS_REQUEST,
    payload: payload,
  };
}
export function getMapsSuccess(payload) {
  return {
    type: GET_MAPS_SUCCESS,
    payload: payload,
  };
}
export function getMapsClean() {
  return {
    type: GET_MAPS_CLEAN,
  };
}
