import reducer from './redux-saga/reducers';

import {
  watchCreditNotesMassiveUploadSetAllowUpload,
  watchCreditNotesMassiveUploadRequestVerify,
  watchCreditNotesMassiveUploadRequestUpload,
  SagaRequestSectionData,
  SagaGetSectionData,
  SagaSectionDataFail,
} from './redux-saga/sagas';

export default function getCreditNotesMassiveUploadModule() {
  return {
    id: 'Dynamic_MASSIVE_UPLOAD',
    reducerMap: {
      Dynamic_MASSIVE_UPLOAD: reducer,
    },
    sagas: [
      watchCreditNotesMassiveUploadSetAllowUpload,
      watchCreditNotesMassiveUploadRequestVerify,
      watchCreditNotesMassiveUploadRequestUpload,
      SagaRequestSectionData,
      SagaGetSectionData,
      SagaSectionDataFail,
    ],
  };
}
