export const ICN_CLIENTS_REQUEST = 'ICN_CLIENTS_REQUEST';
export const GET_ICN_CLIENTS = 'GET_ICN_CLIENTS';
export const ICN_CLIENTS_FAIL = 'ICN_CLIENTS_FAIL';
export const CLEAN_ICN_CLIENTS = 'CLEAN_ICN_CLIENTS';
export const INC_SUBREGIONS_REQUEST = 'INC_SUBREGIONS_REQUEST';
export const INC_SUBREGIONS_SET = 'INC_SUBREGIONS_SET';
export const INC_SUBREGIONS_CLEAN = 'INC_SUBREGIONS_CLEAN';
export function incSubregionsRequest(payload) {
  return {
    type: INC_SUBREGIONS_REQUEST,
    payload: payload,
  };
}

export function incSubregionsSet(payload) {
  return {
    type: INC_SUBREGIONS_SET,
    payload: payload,
  };
}

export function incSubregionsClean(payload) {
  return {
    type: INC_SUBREGIONS_CLEAN,
    payload: payload,
  };
}

export function icnClientsRequest(payload) {
  return {
    type: ICN_CLIENTS_REQUEST,
    payload: payload,
  };
}

export function getIcnClients(payload) {
  return {
    type: GET_ICN_CLIENTS,
    payload: payload,
  };
}

export function icnClientsFail(payload) {
  return {
    type: ICN_CLIENTS_FAIL,
    payload: payload,
  };
}

export function cleanIcnClients(payload) {
  return {
    type: CLEAN_ICN_CLIENTS,
    payload: payload,
  };
}
