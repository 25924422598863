import reducer from './redux-saga/reducers/';
import { fork, all } from 'redux-saga/effects';
import * as sagas from './redux-saga/sagas';

function* rootSaga() {
  yield all([...Object.values(sagas)].map(fork));
}

export default function getComparativeModule() {
  return {
    id: 'COMPARATIVE',
    reducerMap: {
      COMPARATIVE: reducer,
    },
    sagas: [rootSaga],
  };
}
