import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import { PDF_VIEWER_REQUEST_GET, pdfViewerSetGet, pdfViewerClearGet } from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';
import { getAccountStatementPdf } from 'PdfViewer/services';
import { fixPdfName } from 'PdfViewer/utils';
import { messageError } from 'utils/messages';

function* workPdfViewerRequestGet(action) {
  const { params, setFile } = action.payload;
  const CancelToken = instance.CancelToken;

  const source = CancelToken.source();
  try {
    const response = yield call(getAccountStatementPdf, {
      cancelToken: source.token,
      params,
    });
    if (responseOK(response)) {
      const pdfFile = fixPdfName(response.data);
      if (!pdfFile) {
        yield call(messageError, 'El estado de cuenta solicitado no puede ser consultado');
      }
      yield call(setFile, pdfFile);
      yield put(pdfViewerSetGet(true));
    } else {
      yield put(pdfViewerClearGet());
    }
  } catch (error) {
    yield pdfViewerClearGet();
    yield call(messageError, 'El estado de cuenta solicitado no puede ser consultado');
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield pdfViewerClearGet();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchPdfViewerRequestGet() {
  yield all([takeEvery(PDF_VIEWER_REQUEST_GET, workPdfViewerRequestGet)]);
}
