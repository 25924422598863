import React, { useState, useEffect } from 'react';
import { Modal, Radio } from 'antd';

const DownloadCatalogModal = ({
  showDownloadCatalogModal,
  toggleDownloadCatalogModal,
  name,
  downloadType,
  setDownloadType,
  handleGetCatalog,
  beFilters,
}) => {
  const [modalTitle, setModalTitle] = useState('');
  const [headline, setHeadLine] = useState('');
  const downloadOptions = [
    {
      label: 'Todos',
      value: 'Null',
    },
    {
      label: 'Activos',
      value: true,
    },
    {
      label: 'Inactivos',
      value: false,
    },
  ];

  const getModalTitle = (moduleName) => {
    let title = '';
    let headLinaQuestion = '';
    switch (moduleName) {
      case 'products':
        title = 'Descargar catálogo de Productos';
        headLinaQuestion = '¿Qué registros del catálogo de Productos deseas descargar?';
        break;
      case 'prices':
        title = 'Descargar catálogo de Precios';
        headLinaQuestion = '¿Qué registros del catálogo de Precios deseas descargar?';
        break;
      case 'clients':
        title = 'Descargar catálogo de Clientes';
        headLinaQuestion = '¿Qué registros del catálogo de Clientes deseas descargar?';
        break;
      default:
        title = '';
        break;
    }
    setModalTitle(title);
    setHeadLine(headLinaQuestion);
  };

  const HandleChangeDownloadOption = ({ target: { value } }) => {
    const newObject = { ...beFilters, Enabled: value };
    beFilters = newObject;
    setDownloadType(value);
  };

  useEffect(() => {
    getModalTitle(name);
  }, [name]);

  return (
    <Modal
      title={modalTitle}
      visible={showDownloadCatalogModal}
      onOk={() => {
        handleGetCatalog(downloadType);
        toggleDownloadCatalogModal();
      }}
      onCancel={() => {
        setDownloadType('Null');
        toggleDownloadCatalogModal();
      }}
    >
      <section className="radio-wraper">
        <h4> {headline} </h4>

        <Radio.Group
          options={downloadOptions}
          onChange={HandleChangeDownloadOption}
          className={'download-types'}
          value={downloadType}
        />
      </section>
    </Modal>
  );
};

export default DownloadCatalogModal;
