export const DELETE_TRADE_AGREEMENT_VALIDATOR_REQUEST = 'DELETE_TRADE_AGREEMENT_VALIDATOR_REQUEST';
export const DELETE_TRADE_AGREEMENT_VALIDATOR_SUCCESS = 'DELETE_TRADE_AGREEMENT_VALIDATOR_SUCCESS';
export const DELETE_TRADE_AGREEMENT_VALIDATOR_FAIL = 'DELETE_TRADE_AGREEMENT_VALIDATOR_FAIL';

export function deleteTradeAgreementValidatorRequest(payload) {
  return {
    type: DELETE_TRADE_AGREEMENT_VALIDATOR_REQUEST,
    payload,
  };
}

export function deleteTradeAgreementValidatorSuccess(payload) {
  return { type: DELETE_TRADE_AGREEMENT_VALIDATOR_SUCCESS, payload };
}

export function deleteTradeAgreementValidatorFail(payload) {
  return { type: DELETE_TRADE_AGREEMENT_VALIDATOR_FAIL, payload };
}
