import React from 'react';
import Styles from './progress-percentage.module.css';

export const ProgressPercentage = ({ title, percentage }) => {
  return (
    <div className={`${Styles[`container`]} progress-container-animate`}>
      <p>{title}</p>
      <div className={Styles[`progress-container`]}>
        <div
          className={Styles[`progress`]}
          style={
            percentage >= 10
              ? { backgroundColor: 'rgb(0, 73, 154)' }
              : { backgroundColor: '#ffffff' }
          }
        />
        <div
          className={Styles[`progress`]}
          style={
            percentage >= 20
              ? { backgroundColor: 'rgb(0, 73, 154)' }
              : { backgroundColor: '#ffffff' }
          }
        />
        <div
          className={Styles[`progress`]}
          style={
            percentage >= 30
              ? { backgroundColor: 'rgb(0, 73, 154)' }
              : { backgroundColor: '#ffffff' }
          }
        />
        <div
          className={Styles[`progress`]}
          style={
            percentage >= 40
              ? { backgroundColor: 'rgb(0, 73, 154)' }
              : { backgroundColor: '#ffffff' }
          }
        />
        <div
          className={Styles[`progress`]}
          style={
            percentage >= 50
              ? { backgroundColor: 'rgb(0, 73, 154)' }
              : { backgroundColor: '#ffffff' }
          }
        />
        <div
          className={Styles[`progress`]}
          style={
            percentage >= 60
              ? { backgroundColor: 'rgb(0, 73, 154)' }
              : { backgroundColor: '#ffffff' }
          }
        />
        <div
          className={Styles[`progress`]}
          style={
            percentage >= 70
              ? { backgroundColor: 'rgb(0, 73, 154)' }
              : { backgroundColor: '#ffffff' }
          }
        />
        <div
          className={Styles[`progress`]}
          style={
            percentage >= 80
              ? { backgroundColor: 'rgb(0, 73, 154)' }
              : { backgroundColor: '#ffffff' }
          }
        />
        <div
          className={Styles[`progress`]}
          style={
            percentage >= 90
              ? { backgroundColor: 'rgb(0, 73, 154)' }
              : { backgroundColor: '#ffffff' }
          }
        />
        <div
          className={Styles[`progress`]}
          style={
            percentage === 100
              ? { backgroundColor: 'rgb(0, 73, 154)' }
              : { backgroundColor: '#ffffff' }
          }
        />
      </div>
    </div>
  );
};
