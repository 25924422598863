import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { getTextSize, getThemeColor, getIconSize } from '../utils';
import '../main.css';

function Loader({ color, text, size }) {
  const loaderSize = getIconSize(size);
  const textSize = getTextSize(size);
  const themeColor = getThemeColor(color);
  return (
    <div className="loader-container">
      <LoadingOutlined
        style={{ display: 'block', marginBottom: '1.2em', fontSize: loaderSize, color: themeColor }}
      />
      <p className="text-center text-secondary" style={{ color: themeColor, fontSize: textSize }}>
        {text === undefined ? 'Loading' : text}
      </p>
    </div>
  );
}

export default Loader;
