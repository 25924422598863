import React, { useEffect, useContext } from 'react';
import { Sidebar, Chart, Periodicity, PDFExportButtons } from './index';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import { useHistory } from 'react-router-dom';
import getRootModule from 'RootModule';
import getComparativeModule from '../getComparativeModule';

import { comparativeDashboardRequestInformationExistances } from '../redux-saga/actions';
import { layoutContext } from 'context';

const Comparative = (props) => {
  const { comparativeDashboardRequestInformationExistances: getInfoExistance } = props;
  const { onChangeActiveComponent } = useContext(layoutContext);
  const history = useHistory();
  useEffect(() => {
    onChangeActiveComponent('Users');
    getInfoExistance({ redirectToDasboardsScreen });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function redirectToDasboardsScreen() {
    onChangeActiveComponent(undefined);
    history.push('/dashboards');
  }
  return (
    <>
      <div id="pngCapture">
        <Chart />

        <Periodicity />
      </div>
      <PDFExportButtons />
      <Sidebar />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    typeOfView: state?.COMPARATIVE?.typeOfView,
  };
};

const ConnectedComparative = connect(mapStateToProps, {
  comparativeDashboardRequestInformationExistances,
})(Comparative);

const ComparativeWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getComparativeModule()]}>
      <ConnectedComparative />
    </DynamicModuleLoader>
  );
};

export default ComparativeWithModules;
