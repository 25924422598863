import {
  TYPE_CLIENT_REQUEST,
  TYPE_CLIENT_SUCCESS,
  TYPE_CLIENT_CLEAN,
  BUSINESS_LINES_REQUEST,
  BUSINESS_LINES_SUCCESS,
  BUSINESS_LINES_CLEAN,
  SEGMENTATION_REQUEST,
  SEGMENTATION_SUCCESS,
  SEGMENTATION_CLEAN,
  COMPANY_REQUEST,
  COMPANY_SUCCESS,
  COMPANY_CLEAN,
  TERRITORIES_REQUEST,
  TERRITORIES_SUCCESS,
  TERRITORIES_CLEAN,
  POGS_REQUEST,
  POGS_SUCCESS,
  POGS_CLEAN,
  CURRENCIES_REQUEST,
  CURRENCIES_SUCCESS,
  CURRENCIES_CLEAN,
  PlACEMENTS_REQUEST,
  PlACEMENTS_SUCCESS,
  PlACEMENTS_CLEAN,
  REGISTER_CLIENT_REQUEST,
  REGISTER_CLIENT_SUCCESS,
  REGISTER_CLIENT_CLEAN,
  RESPONSABLE_REQUEST,
  DETAIL_DYNAMIC_ELEMENTS_REQUEST,
  DETAIL_DYNAMIC_ELEMENTS_SUCCESS,
  DETAIL_DYNAMIC_ELEMENTS_CLEAN,
  GET_ONE_CLIENT_REQUEST,
  GET_ONE_CLIENT_SUCCESS,
  GET_ONE_CLIENT_CLEAN,
  GET_PRESENCE_REQUEST,
  GET_PRESENCE_SUCCESS,
  GET_PRESENCE_CLEAN,
  GET_POG_REQUEST,
  GET_POG_SUCCESS,
  GET_POG_CLEAN,
  RESPONSABLE_SUCCESS,
  RESPONSABLE_CLEAN,
  RESPONSABLE_POSITION_REQUEST,
  RESPONSABLE_POSITION_SUCCESS,
  RESPONSABLE_POSITION_CLEAN,
  VALIDATE_CLIENT_REQUEST,
  VALIDATE_CLIENT_SUCCESS,
  VALIDATE_CLIENT_CLEAN,
} from '../actions';

function clientReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    //get data cliente
    case VALIDATE_CLIENT_REQUEST:
      return {
        ...state,
        isValid: true,
      };

    case VALIDATE_CLIENT_SUCCESS:
      return {
        ...state,
        isValid: action.payload,
      };

    case VALIDATE_CLIENT_CLEAN:
      return {
        ...state,
        isValid: action.payload,
      };
    //get Responsable position
    case RESPONSABLE_POSITION_REQUEST:
      return {
        ...state,
        responsablePosition: null,
      };
    case RESPONSABLE_POSITION_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { position, data } = action.payload;
      // eslint-disable-next-line no-case-declarations
      let newdataRv = JSON.stringify(data);

      if (typeof Storage !== 'undefined') {
        localStorage.setItem(position, newdataRv);
      }
      return {
        ...state,
        responsablePosition: [...state.responsablePosition, action.payload],
      };
    case RESPONSABLE_POSITION_CLEAN:
      return {
        ...state,
        responsablePosition: null,
      };
    //get Responsable
    case RESPONSABLE_REQUEST:
      return {
        ...state,
        responsable: null,
      };
    case RESPONSABLE_SUCCESS:
      return {
        ...state,
        responsable: action.payload,
      };
    case RESPONSABLE_CLEAN:
      return {
        ...state,
        responsable: null,
      };
    //get DetailDynamicElements
    case DETAIL_DYNAMIC_ELEMENTS_REQUEST:
      return {
        ...state,
        detail: null,
      };
    case DETAIL_DYNAMIC_ELEMENTS_SUCCESS:
      return {
        ...state,
        detail: action.payload,
      };
    case DETAIL_DYNAMIC_ELEMENTS_CLEAN:
      return {
        ...state,
        detail: null,
      };

    // get One Client

    case GET_ONE_CLIENT_REQUEST:
      return {
        ...state,
        oneClient: null,
      };
    case GET_ONE_CLIENT_SUCCESS:
      return {
        ...state,
        oneClient: action.payload,
      };
    case GET_ONE_CLIENT_CLEAN:
      return {
        ...state,
        oneClient: null,
      };
    // get Presence

    case GET_PRESENCE_REQUEST:
      return {
        ...state,
        presence: null,
      };
    case GET_PRESENCE_SUCCESS:
      return {
        ...state,
        presence: action.payload,
      };
    case GET_PRESENCE_CLEAN:
      return {
        ...state,
        presence: null,
      };
    // get POG

    case GET_POG_REQUEST:
      return {
        ...state,
        pog: null,
      };
    case GET_POG_SUCCESS:
      return {
        ...state,
        pog: action.payload,
      };
    case GET_POG_CLEAN:
      return {
        ...state,
        pog: null,
      };
    //get Placements
    case PlACEMENTS_REQUEST:
      return {
        ...state,
        placements: null,
      };
    case PlACEMENTS_SUCCESS:
      return {
        ...state,
        placements: action.payload,
      };
    case PlACEMENTS_CLEAN:
      return {
        ...state,
        placements: null,
      };
    //get type clients
    case TYPE_CLIENT_REQUEST:
      return {
        ...state,
        typeClient: null,
      };
    case TYPE_CLIENT_SUCCESS:
      return {
        ...state,
        typeClient: action.payload,
      };
    case TYPE_CLIENT_CLEAN:
      return {
        ...state,
        typeClient: null,
      };
    //get businesLines
    case BUSINESS_LINES_REQUEST:
      return {
        ...state,
        businessLine: null,
      };
    case BUSINESS_LINES_SUCCESS:
      return {
        ...state,
        businessLine: action.payload,
      };
    case BUSINESS_LINES_CLEAN:
      return {
        ...state,
        businessLine: null,
      };
    //get segmentation
    case SEGMENTATION_REQUEST:
      return {
        ...state,
        segmentation: null,
      };
    case SEGMENTATION_SUCCESS:
      return {
        ...state,
        segmentation: action.payload,
      };
    case SEGMENTATION_CLEAN:
      return {
        ...state,
        segmentation: null,
      };
    //get company
    case COMPANY_REQUEST:
      return {
        ...state,
        company: null,
      };
    case COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
      };
    case COMPANY_CLEAN:
      return {
        ...state,
        company: null,
      };
    //get Territoires
    case TERRITORIES_REQUEST:
      return {
        ...state,
        territories: null,
      };
    case TERRITORIES_SUCCESS:
      return {
        ...state,
        territories: action.payload,
      };
    case TERRITORIES_CLEAN:
      return {
        ...state,
        territories: null,
      };
    //get Pogs
    case POGS_REQUEST:
      return {
        ...state,
        pogs: null,
      };
    case POGS_SUCCESS:
      return {
        ...state,
        pogs: action.payload,
      };
    case POGS_CLEAN:
      return {
        ...state,
        pogs: null,
      };
    //get Currencies
    case CURRENCIES_REQUEST:
      return {
        ...state,
        currencies: null,
      };
    case CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.payload,
      };
    case CURRENCIES_CLEAN:
      return {
        ...state,
        currencies: null,
      };
    //get Currencies
    case REGISTER_CLIENT_REQUEST:
      return {
        ...state,
        registerClient: action.payload,
      };
    case REGISTER_CLIENT_SUCCESS:
      return {
        ...state,
        registerClient: action.payload,
      };
    case REGISTER_CLIENT_CLEAN:
      return {
        ...state,
        registerClient: action.payload,
      };

    default:
      return state;
  }
}

export default clientReducer;
