import {
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  PRODUCTS_SELECTED_CLEAN,
  PRODUCTS_SELECTED,
} from '../actions/addProduct';

const addProduct = (state = {}, action) => {
  switch (action.type) {
    case ADD_PRODUCT_REQUEST: {
      return {
        ...state,
        newProduct: {
          isPosting: true,
        },
      };
    }

    case ADD_PRODUCT_FAIL:
    case ADD_PRODUCT_SUCCESS: {
      return {
        ...state,
        newProduct: {
          isPosting: false,
        },
      };
    }
    case PRODUCTS_SELECTED: {
      let hasData = state?.selected;
      if (hasData && hasData.length > 0) {
        return {
          ...state,

          selected: [...state?.selected, action.payload],
        };
      } else {
        return {
          ...state,

          selected: [action.payload],
        };
      }
    }
    case PRODUCTS_SELECTED_CLEAN: {
      return {
        ...state,
        selected: [],
      };
    }

    default:
      return state;
  }
};

export default addProduct;
