import React from 'react';

function usePersisData(key, defaultValue) {
  const prevKeyRef = React.useRef(key);
  const [state, setState] = React.useState(() => {
    try {
      const existValue = window.localStorage.getItem(key);
      if (existValue) {
        return JSON.parse(existValue);
      }
    } catch (error) {
      console.error('Error trying to read localStorage', error);
    }
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
  });

  React.useEffect(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      window.localStorage.removeItem(prevKey);
    }
    prevKeyRef.current = key;
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

export default usePersisData;
