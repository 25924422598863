/* Historic Information */
import { Home as CatalogHome, Catalogs, Catalog } from '../../Catalog';
import SalesMassiveUpload from 'Catalog/HistoricInformation/Sales/MassiveUpload/containers/SalesMassiveUpload';
import POGMassiveUpload from 'Catalog/HistoricInformation/POG/MassiveUpload';

export const historicInformation = {
  'historic-information': CatalogHome,
  pog: Catalogs,
  consult_pog: Catalog,
  sales: Catalogs,
  consult_sales: Catalog,
  upload_sales: SalesMassiveUpload,
  upload_pog: POGMassiveUpload,
};
