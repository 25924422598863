import {
  VALIDATE_REQUEST,
  VALIDATE_SUCCESS,
  VALIDATE_FAIL,
  CHALLENGE_REQUEST,
  CHALLENGE_SUCCESS,
  CHALLENGE_FAIL,
  REACH_REQUEST,
  REACH_SUCCESS,
  REACH_FAIL,
  SEND_TO_VALIDATE_REQUEST,
  SEND_TO_VALIDATE_SUCCESS,
  SEND_TO_VALIDATE_FAIL,
  CLEAN_CHALLENGE,
  CHALLENGE_COMMENTS_REQUEST,
  CHALLENGE_COMMENTS_SUCCESS,
  CHALLENGE_COMMENTS_FAIL,
} from '../actions/challenge';

const challenge = (state = {}, action) => {
  switch (action.type) {
    case VALIDATE_REQUEST: {
      return {
        ...state,
        validateStatus: {
          isLoading: true,
          data: [],
        },
      };
    }

    case VALIDATE_SUCCESS: {
      return {
        ...state,
        validateStatus: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case VALIDATE_FAIL: {
      return {
        ...state,
        validateStatus: {
          isLoading: false,
          data: [],
        },
      };
    }

    case CHALLENGE_REQUEST: {
      return {
        ...state,
        lastVersion: {
          isLoading: true,
          data: [],
        },
      };
    }

    case CHALLENGE_SUCCESS: {
      return {
        ...state,
        lastVersion: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case CHALLENGE_FAIL: {
      return {
        ...state,
        lastVersion: {
          isLoading: false,
          data: [],
        },
      };
    }

    case SEND_TO_VALIDATE_REQUEST: {
      return {
        ...state,
        isSendingToValidate: true,
      };
    }

    case SEND_TO_VALIDATE_SUCCESS: {
      return {
        ...state,
        isSendingToValidate: false,
      };
    }

    case SEND_TO_VALIDATE_FAIL: {
      return {
        ...state,
        isSendingToValidate: false,
      };
    }

    case REACH_REQUEST: {
      return {
        ...state,
        reach: {
          isLoading: true,
          data: [],
        },
      };
    }

    case REACH_SUCCESS: {
      return {
        ...state,
        reach: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case REACH_FAIL: {
      return {
        ...state,
        reach: {
          isLoading: false,
          data: [],
        },
      };
    }

    case CLEAN_CHALLENGE: {
      return {};
    }

    case CHALLENGE_COMMENTS_REQUEST: {
      return {
        ...state,
        historyChallenge: {
          isLoading: true,
          data: [],
        },
      };
    }

    case CHALLENGE_COMMENTS_SUCCESS: {
      return {
        ...state,
        historyChallenge: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case CHALLENGE_COMMENTS_FAIL: {
      return {
        ...state,
        historyChallenge: {
          isLoading: false,
          data: [],
        },
      };
    }

    default:
      return state;
  }
};

export default challenge;
