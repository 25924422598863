import React from 'react';

function Divider({ title, tag }) {
  return (
    <h3 id={tag} style={{ margin: '5px 0px' }}>
      {title}
    </h3>
  );
}
export default Divider;
