import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { openNotification } from 'common';
import { downloadSalesChallengeReportRequest } from '../redux-saga/actions/downloadSalesChallengeReport';
import { downloadTradeAgreementsReportRequest } from '../redux-saga/actions/downloadTradeAgreement';
import { connect } from 'react-redux';
import getRootModule from 'RootModule';
import getValidationsModule from 'Validations/getValidationsModule';
import { DynamicModuleLoader } from 'redux-dynamic-modules';

const DownloadReport = ({
  downloadSalesChallengeReportRequest: downloadSalesChallenge,
  downloadTradeAgreementsReportRequest: downloadTradeAgreements,
}) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const generateReport = () => {
    setIsVisibleModal(!isVisibleModal);
  };

  const handleGenerateReport = async (type) => {
    if (type === 'tradeAgreement') {
      downloadTradeAgreements({
        onSuccess: () =>
          openNotification(
            'success',
            'La descarga del archivo de reporte de validadores de acuerdo comercial se generó exitosamente'
          ),
      });
    } else {
      downloadSalesChallenge({
        onSuccess: () =>
          openNotification(
            'success',
            'La descarga del archivo de reporte de validadores de desafío de ventas se generó exitosamente'
          ),
      });
    }
  };

  return (
    <>
      <Button className="reportBtn" onClick={generateReport}>
        Generar Reporte
      </Button>

      <Modal
        className={'reportModal'}
        onOk={generateReport}
        onCancel={generateReport}
        visible={isVisibleModal}
        footer={null}
      >
        <div className="reportModal__wrapper">
          <h4> Descargar Reporte validadores </h4>

          <p> ¿Qué reporte desea descargar? </p>
          <section className="reportModal__wrapper__buttons">
            <Button
              onClick={() => {
                handleGenerateReport('salesChallenge');
              }}
            >
              Desafío de ventas
            </Button>
            <Button
              onClick={() => {
                handleGenerateReport('tradeAgreement');
              }}
            >
              Acuerdo comercial
            </Button>
          </section>
        </div>
      </Modal>
    </>
  );
};

const ConnectedDownloadReport = connect(null, {
  downloadSalesChallengeReportRequest,
  downloadTradeAgreementsReportRequest,
})(DownloadReport);

const DynamicGetReportModule = () => (
  <DynamicModuleLoader modules={[getRootModule(), getValidationsModule()]}>
    <ConnectedDownloadReport />
  </DynamicModuleLoader>
);

export default DynamicGetReportModule;
