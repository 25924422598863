export const acuerdoDesafio = [
  {
    value: 'Ventas de acuerdo comercial',
    key: 1,
  },
  {
    value: 'Ventas de desafío de ventas',
    key: 2,
  },
];
