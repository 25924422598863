import { validateElementsActions } from '../../actions';

const {
  VALIDATE_ELEMENTS_REQUEST,
  GET_VALIDATE_ELEMENTS,
  VALIDATE_ELEMENTS_FAIL,
  CLEAN_VALIDATE_ELEMENTS,
} = validateElementsActions;

const validateElementsReducers = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case VALIDATE_ELEMENTS_REQUEST:
    case CLEAN_VALIDATE_ELEMENTS:
      return {
        ...state,
        elementsValidation: null,
      };
    case GET_VALIDATE_ELEMENTS:
      return {
        ...state,
        elementsValidation: payload,
      };
    case VALIDATE_ELEMENTS_FAIL:
      return {
        ...state,
        validateElementsErrorMsg: payload ?? [],
      };
    default:
      return state;
  }
};

export default validateElementsReducers;
