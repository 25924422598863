import React, { useCallback, useEffect, Suspense, useState } from 'react';
import { Col, Row, Form, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FaUpload } from 'react-icons/fa';
import { connect } from 'react-redux';
import { querySave, queryClean } from '../../redux/actions/query';
import { cleanTopFilters } from '../../redux/actions/filters';
import { setCurrentView } from '../../redux/actions/views';
import useWasRendered from '../../hooks/useWasRendered';
import useGetFilters from '../../hooks/useGetFilters';
import useFilters from '../../hooks/useFilters';
import ErrorBoundary from '../../common/ErrorBoundary';
import { ResetButton } from '../../common';
import { cleanInfo } from '../../redux/actions/info';
import { clientSelected, territorySelected } from '../../redux/actions/infoForSales';
import { setSubscriptionValue4Register } from '../../redux/actions/subscriptions';
import { rejectRequest } from '../../redux/actions/rejectSalesChallenge';
import { validateRequest } from 'SalesChallengeRefactor/redux/actions/challenge';
import { getFamilyInformationSuccess } from 'SalesChallengeRefactor/redux/actions/getFamilyInformation';
import { userIs } from 'utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {
  daysLeftRequest,
  cleanIndicators,
  daysLeftTypesRequest,
} from 'SalesChallengeRefactor/redux/actions/indicators';
import {
  getProductsRequest,
  getChallengePogRequest,
} from 'SalesChallengeRefactor/redux/actions/registerSale';
import ActionsGroup from '../ActionsGroup';
import RejectModal from '../RejectModal/RejectModal';

//import dynamicSectionsModule from 'AccountStatus/DynamicElements/Consults/AdminSections';
/* import { ReviewActions } from 'common/components/ReviewActions';
import { aproveClientModal } from '../ReviewActionsContainer/aproveRejectModals'; */

const layoutFilters = {
  xs: 24,
  sm: 12,
  md: 6,
  lg: 4,
  xlg: 5,
};

function TopFiltersGroup(props) {
  const {
    queryClean: cleanQuery,
    querySave: saveQuery,
    cleanTopFilters: cleanFilters,
    setCurrentView: setView,
    onChangeActiveComponent,
    cleanInfo: infoClean,
    clientSelected: setClientSelected,
    territorySelected: setTerritorySelected,
    setSubscriptionValue4Register: setSubRegister,
    getChallengePogRequest: getPog,
    getProductsRequest: getProduct,
    daysLeftRequest: daysLeftReq,
    daysLeftTypesRequest: processTypes,
    cleanIndicators: indicatorsClean,
    clientId: client,
    zoneId: zone,
    isLoading,
    statusId,
    currentChallengeYear,
    selectedYear,
    daysLeft,
    //validateRequest: validateReq,
    rejectRequest: rejectSalesChallenge,
    // territoryId,
    resetFilters,
    validProducts,
    getFamilyInformationSuccess: setFamilyTotal,
  } = props;
  const [form] = Form.useForm();
  const [formFiltersSalesChallenge, setformFiltersSalesChallenge] = useState({});
  const { wasRendered, setWasRendered } = useWasRendered();
  const [disabledFilters, setDisabledFilters] = useState({});
  const [allowRequest, setAllowRequests] = useState({
    year: false,
    regionId: false,
    lineId: false,
    subRegionId: false,
    territoryId: false,
    zoneId: false,
    clientId: false,
    businessRule: false,
    family: false,
    productId: false,
  });
  const [requestParamsSubscription, setRequestParamsSubscription] = useState({});
  const [isVisibleRejectModal, setIsVisibleRejectModal] = useState(false);

  const {
    orchestrateFiltersChange,
    orchestrateFiltersReset,
    initialDisabledFilters,
    initialAllowRequest,
    initialRequestParamsSubscription,
  } = useFilters();
  useEffect(() => {
    if (initialDisabledFilters && initialAllowRequest && initialRequestParamsSubscription) {
      setDisabledFilters({ ...initialDisabledFilters });
      setAllowRequests({ ...initialAllowRequest });
      setRequestParamsSubscription({ ...initialRequestParamsSubscription });
      setWasRendered(true);
    }
  }, [
    initialAllowRequest,
    initialDisabledFilters,
    initialRequestParamsSubscription,
    wasRendered,
    setWasRendered,
  ]);

  const getFilters = useGetFilters();
  const loadComponent = (componentName) => {
    const Component = React.lazy(() => import(`RootModule/catalogs/${componentName}`));
    return Component;
  };

  const cleanAllFilters = useCallback(() => {
    cleanFilters();
  }, [cleanFilters]);

  useEffect(() => {
    if (!wasRendered.current) {
      saveQuery({ state: 'idle', showBruteValue: true });
      cleanAllFilters();
      setWasRendered(true);
      daysLeftReq({ params: { processId: 3 } });
      processTypes({ params: { processId: 3 }, type: 'REGISTER' });
      processTypes({ params: { processId: 4 }, type: 'VALIDATE' });
      processTypes({ params: { processId: 5 }, type: 'ADJUST' });
    }
  }, [saveQuery, wasRendered, daysLeftReq, setWasRendered, cleanAllFilters, processTypes]);

  const handleOnFormValuesChange = ({ changedValues }) => {
    const currentChange = Object.keys(changedValues)[0];
    const formValues = form.getFieldsValue();
    setformFiltersSalesChallenge(formValues);
    const { clientId, productId, year, zoneId } = formValues;
    onChangeActiveComponent('sales-challenge');

    orchestrateFiltersChange({
      changedValues,
      currentChange,
      form,
      formValues,
      requestParamsSubscription,
      setAllowRequests,
      setClientSelected,
      setDisabledFilters,
      setRequestParamsSubscription,
      setSubRegister,
      daysLeftReq,
      setTerritorySelected,
    });

    //TODO: MOVE THIS INTO ORCHESTRATOR
    if (currentChange === 'businessRule') {
      setView('clientId');
    } else {
      setView(currentChange);
    }
    //get pog and product Info
    if (currentChange === 'productId') {
      getProduct({
        params: {
          zoneId,
          clientId,
          productId,
          year,
        },
      });
      getPog({
        params: {
          zoneId,
          clientId,
          productId,
          year,
        },
        isConsult: true,
      });
    }
    if (currentChange === 'zoneId') {
      daysLeftReq({ params: { processId: 5 } });
    }
    const queryValues = form.getFieldsValue();
    saveQuery({ ...queryValues, state: 'changed' });
    // unitl herae move to orchestarto
  };

  const generateFilters = () => {
    //TODO: Call Handle Changes Value insteas of repeating code
    const setAutomaticallyFirstOption = (options, filterName = 'nonde') => {
      if (options?.length > 0) {
        //* Set the value
        form.setFieldsValue({
          [filterName]: options[0]?.key,
        });
        const formValues = form.getFieldsValue();
        const currentChange = filterName;
        orchestrateFiltersChange({
          changedValues: { [filterName]: options[0]?.key },
          currentChange,
          form,
          formValues,
          requestParamsSubscription,
          setAllowRequests,
          setClientSelected,
          setDisabledFilters,
          setRequestParamsSubscription,
          setSubRegister,
          setTerritorySelected,
        });
        if (currentChange === 'businessRule') {
          setView('clientId');
        } else {
          setView(currentChange);
        }
        //get pog and product Info
        if (currentChange === 'productId') {
          getProduct({
            params: {
              zoneId: formValues?.zoneId,
              clientId: formValues?.clientId,
              productId: formValues?.productId,
            },
          });
          getPog({
            params: {
              zoneId: formValues?.zoneId,
              clientId: formValues?.clientId,
              productId: formValues?.productId,
              year: formValues?.year,
            },
            isConsult: true,
          });
        }
        if (currentChange === 'zoneId') {
          daysLeftReq({ params: { processId: 5 } });
        }
        const queryValues = form.getFieldsValue();
        saveQuery({ ...queryValues, state: 'changed' });
      }
    };

    const handleOnZoneChange = (_value, option) => {
      setSubRegister({ zoneLabel: option?.children });
    };

    const handleOnClientChange = (_value, option) => {
      setSubRegister({ clientId: option.key });
    };

    const handleOnYearChange = (_value, option) => {
      setSubRegister({ year: option.key });
    };

    const specificFilterProps = {
      year: {
        onChange: handleOnYearChange,
        onSuccess: (options) => setAutomaticallyFirstOption(options, 'year'),
      },
      zoneId: {
        onChange: handleOnZoneChange,
      },
      clientId: {
        onChange: handleOnClientChange,
      },
    };
    if (getFilters && getFilters.length > 0) {
      return getFilters.map((filter) => {
        let FilterComponent = loadComponent(filter.component);
        const filterName = filter?.enum?.name;
        return (
          <Col {...layoutFilters} key={filterName}>
            <FilterComponent
              askFromService={allowRequest?.[filterName]}
              name={filterName}
              disabled={disabledFilters?.[filterName]}
              requestParams={requestParamsSubscription?.[filterName]}
              allowClear={false}
              sourceId="Sales"
              {...specificFilterProps[filterName]}
            />
          </Col>
        );
      });
    }
    return null;
  };

  const handleResetButton = () => {
    form.resetFields();
    orchestrateFiltersReset({
      setDisabledFilters,
      setRequestParamsSubscription,
      setAllowRequests,
      form,
    });
    const formValues = form.getFieldsValue();
    cleanQuery({ state: 'idle', year: formValues.year, showBruteValue: true });
    cleanAllFilters();
    infoClean();
    indicatorsClean();
    daysLeftReq({ params: { processId: 3 } });
    processTypes({ params: { processId: 3 }, type: 'REGISTER' });
    processTypes({ params: { processId: 4 }, type: 'VALIDATE' });
    processTypes({ params: { processId: 5 }, type: 'ADJUST' });
  };

  const allowRejectChallenge = useCallback(() => {
    return (
      !isLoading &&
      userIs('Administrador', 'Administrador de Acceso al Mercado') &&
      zone &&
      statusId === 4 &&
      currentChallengeYear === selectedYear &&
      daysLeft > 0
    );
  }, [statusId, currentChallengeYear, selectedYear, daysLeft, isLoading, zone]);

  /* const allowSentToValidateClient = useCallback(() => {
    return (
      !isLoading &&
      userIs('Administrador', 'Administrador de Acceso al Mercado') &&
      (statusId === 2 || statusId === 3) &&
      client &&
      currentChallengeYear === selectedYear &&
      daysLeft > 0
    );
  }, [statusId, currentChallengeYear, selectedYear, daysLeft, client]); */

  /* const allowSentToValidateZone = useCallback(() => {
    return (
      !isLoading &&
      userIs('Administrador', 'Administrador de Acceso al Mercado') &&
      statusId === 3 &&
      zone &&
      !client &&
      currentChallengeYear === selectedYear &&
      daysLeft > 0
    );
  }, [statusId, currentChallengeYear, selectedYear, daysLeft, client, zone]); */

  /*  const onValidateClient = () => {
    let scope = 'General';
    let name = 'ValidateClient';
    const params = {
      zoneId: zone,
      client,
      status: true,
    };

    validateReq({ name, data: params, scope });
  }; */

  /* const onValidateZone = () => {
    let scope = 'General';
    let name = 'ValidateZone';

    const params = {
      zoneId: zone,
      status: true,
    };

    validateReq({ name, data: params, scope });
  }; */

  const handleOkReject = (comment) => {
    let formValues = form.getFieldsValue();
    let selectedZone = formValues.zoneId;
    let year = formValues.year;
    let params = { comment, zoneId: selectedZone, year };
    rejectSalesChallenge({ params });
    setIsVisibleRejectModal(false);
  };

  const handleCancelReject = () => {
    setIsVisibleRejectModal(!isVisibleRejectModal);
  };

  useEffect(() => {
    if (resetFilters === true && validProducts === 1) {
      handleResetButton();
      setFamilyTotal(0);
    }
    if (resetFilters === true && validProducts > 1) {
      /*  handleOnFormValuesChange({
        changedValues: {
          clientId: form.getFieldValue('clientId'),
        },
      }); */
      form.setFieldsValue({ client: client, family: undefined, product: undefined });

      setDisabledFilters({
        businessRule: false,
        clientId: false,
        family: true,
        lineId: false,
        productId: true,
        regionId: false,
        subRegionId: false,
        territoryId: false,
        year: false,
        zoneId: false,
      });
    }
  }, [resetFilters]);

  return (
    <>
      <Form
        name="validate_other"
        onValuesChange={(changedValues, allValues) =>
          handleOnFormValuesChange({ changedValues, allValues })
        }
        form={form}
        layout="horizontal"
        style={{ position: 'relative' }}
      >
        <Row gutter={{ xs: 8, sm: 8, md: 10, lg: 12 }}>
          <Suspense fallback={<div>Loading...</div>}>
            <ErrorBoundary>{generateFilters()}</ErrorBoundary>
          </Suspense>
          <Col style={{ marginTop: '36px' }}>
            <ResetButton onClick={handleResetButton} />
          </Col>
          <Col style={{ marginTop: '32px' }}>
            <Link to={'/sales-challenge/register/sales-challenge/'}>
              <span style={{ fontSize: '1.8rem', color: '#00499A' }}>
                <FaUpload />
              </span>
            </Link>
          </Col>

          <Col style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <ActionsGroup
              scope={'General'}
              form={form}
              formFiltersSalesChallenge={formFiltersSalesChallenge}
            />
          </Col>
          {allowRejectChallenge() && (
            <Col className="reject-holder">
              <span className="reject-holder__label"> Rechazar </span>
              <Button
                icon={<CloseOutlined />}
                onClick={() => {
                  setIsVisibleRejectModal(true);
                }}
              />
            </Col>
          )}

          {/*  {allowSentToValidateZone() && (
            <Col>
              <ReviewActions
                className={'sales-challenge'}
                onApprove={() => onValidateZone(true)}
                actions={['approve']}
                templateLiteralsAprove={aproveClientModal}
              />
            </Col>
          )} */}

          {/* {allowSentToValidateClient() && (
            <Col>
              <ReviewActions
                className={'sales-challenge'}
                onApprove={() => onValidateClient(true)}
                actions={['approve']}
                templateLiteralsAprove={aproveClientModal}
              />
            </Col>
          )} */}
        </Row>
      </Form>

      <RejectModal
        onOk={handleOkReject}
        onCancel={handleCancelReject}
        isVisible={isVisibleRejectModal}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    statusId: state?.SALES_CHALLENGE?.indicators?.zoneData?.statusId,
    currentChallengeYear: state?.SALES_CHALLENGE?.indicators?.daysLeft?.data?.year,
    daysLeft: state?.SALES_CHALLENGE?.indicators?.daysLeft?.data?.daysLeft,
    selectedYear: state?.SALES_CHALLENGE?.query?.year,
    zoneId: state?.SALES_CHALLENGE?.query?.zoneId,
    clientId: state?.SALES_CHALLENGE?.query?.clientId,
    productId: state?.SALES_CHALLENGE?.query?.productId,
    familyId: state?.SALES_CHALLENGE?.query?.familyId,
    isLoading: state?.SALES_CHALLENGE?.challenge?.historyChallenge?.isLoading,
    territoryId: state?.SALES_CHALLENGE?.query?.territoryId,
    resetFilters: state?.SALES_CHALLENGE?.deleteProduct?.resetFilters ?? false,
    validProducts: state?.SALES_CHALLENGE?.getFamilyInformation?.validProducts ?? 0,
  };
};

export default connect(mapStateToProps, {
  queryClean,
  querySave,
  cleanTopFilters,
  setCurrentView,
  cleanInfo,
  clientSelected,
  territorySelected,
  setSubscriptionValue4Register,
  daysLeftRequest,
  getProductsRequest,
  getChallengePogRequest,
  cleanIndicators,
  daysLeftTypesRequest,
  validateRequest,
  rejectRequest,
  getFamilyInformationSuccess,
})(TopFiltersGroup);

TopFiltersGroup.propTypes = {};

TopFiltersGroup.defaultProps = {};
