/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext } from 'react';
import { getPopupContainer as helperGetPopupContainer } from 'SalesChallengeRefactor/helpers/helperForms';
import { Table as AntdTable, Popover, Switch, Input, Result, Select } from 'antd';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { MdModeEdit, MdDelete, MdAddLocation } from 'react-icons/md';
import { IoMdAddCircle } from 'react-icons/io';
import { BsArrowUpDown } from 'react-icons/bs';
import { LeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import './table.css';
import { ModalAdd } from '../Modal/';
import { Transfer } from '../Transfer/';
import {
  getCountriesNotInZone,
  updateCountries,
  updateBusinessStructure,
  updateTerritoryEnable,
  updateZoneEnable,
  updateCountryEnable,
  updateElementBusinessStructure,
  updateReAsignZone,
  getTerritorys,
  deleteElementBusinessStructure,
} from '../../services/businessStructure';
import { layoutContext } from 'context';
import { responseOK } from 'SalesChallengeRefactor/utils';
import { openNotification } from 'common';
import PropTypes from 'prop-types';

export const Table = ({ tableData, isLoading, setTableData, setIsLoading, handleOpenModalMap }) => {
  const [visible, setVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [targetKeys, setTargetKeys] = useState([]);
  const [transferData, setTransferData] = useState([]);
  const [dataInput, setDataInput] = useState('');
  const [infoParent, setInfoParent] = useState({});
  const [updateZoneId, setZoneId] = useState(null);
  const [requestZoneInfo, setRequestZoneInfo] = useState(null);
  const [requestTerritoryId, setRequestTerritoryId] = useState(null);
  const [isLoadingButtonOk, setIsLoadingButtonOk] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [typeModal, setTypeModal] = useState('');
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [dataModalEdit, setDataModalEdit] = useState({ id: null, type: '' });
  const [discardedCountries, setDiscardedCountries] = useState([]);
  const [inputDataEdit, setInputDataEdit] = useState('');

  const onChangeTransfer = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys);
    setIsDisabled(false);
    if (direction === 'left') {
      setDiscardedCountries([...discardedCountries, ...moveKeys]);
      const newSelectedCountries = [...selectedCountries];
      moveKeys.forEach((key) => {
        const found = newSelectedCountries.find((country) => country === key);
        if (found) {
          const index = newSelectedCountries.indexOf(found);
          newSelectedCountries.splice(index, 1, found);
        }
      });
      setSelectedCountries(newSelectedCountries);
    } else {
      setSelectedCountries([...selectedCountries, ...moveKeys]);
      //Remove elements from discardedCountries array
      const newDiscardCountries = [...discardedCountries];
      moveKeys.forEach((key) => {
        const found = newDiscardCountries.find((country) => country === key);
        if (found) {
          const index = newDiscardCountries.indexOf(found);
          newDiscardCountries.splice(index, 1, found);
        }
      });
      setDiscardedCountries(newDiscardCountries);
    }
  };

  const findKeyAndCreateRow = (objectKey, dataObject, newObject) => {
    for (const key in dataObject) {
      if (key === objectKey) {
        let keyId = infoParent;

        //Conditions to add a subregion
        if (typeof infoParent === 'object') {
          keyId = infoParent.subregionKey;
        }
        //Insert subregion
        if (dataObject.key === keyId) {
          dataObject.children.push(newObject);
        }
        if (dataObject.id === keyId) {
          //Condition to add multpliue countries
          if (Array.isArray(newObject)) {
            //Adding new countries from transfer
            for (const obj of newObject) {
              dataObject.children.push({
                ...obj,
                zoneId: keyId,
              });
            }
            //Deleting countries from transfer
            for (const discardedCountry of discardedCountries) {
              const newChildren = dataObject.children.filter(
                (country) => country.id !== discardedCountry
              );
              dataObject.children = [...newChildren];
            }
          } else {
            //Add other elements
            dataObject.children.push(newObject);
          }
        }
        return;
      }
      if (typeof dataObject[key] === 'object') {
        findKeyAndCreateRow(objectKey, dataObject[key], newObject);
      }
    }
  };

  const findKeyAndUpdateName = (objectKey, dataObject) => {
    for (const key in dataObject) {
      if (key === objectKey) {
        if (dataObject.id === dataModalEdit.id) {
          dataObject[objectKey] = inputDataEdit;
        }
        return;
      }
      if (typeof dataObject[key] === 'object') {
        findKeyAndUpdateName(objectKey, dataObject[key]);
      }
    }
  };

  const findKeyAndDeleteRow = (objectKey, dataObject) => {
    for (const key in dataObject) {
      if (key === objectKey) {
        //Delete territory
        if (objectKey === 'subregion') {
          const { children } = dataObject;
          const territoryKey = children.find((territory) => territory.key === infoParent.key);
          if (territoryKey) {
            const newChildren = children.filter((territory) => territory.id !== infoParent.id);
            dataObject.children = [...newChildren];
          }
        }
        if (objectKey === 'zone') {
          const { children } = dataObject;
          const foundCountry = children.find((country) => country.id === infoParent.id);
          if (foundCountry) {
            const newChildren = children.filter((country) => country.id !== infoParent.id);
            dataObject.children = [...newChildren];
          }
        }
        //Delete other elements
        if (dataObject.id === infoParent.parentId) {
          const { children } = dataObject;
          const newChildren = children.filter((child) => child.id !== infoParent.id);
          dataObject.children = [...newChildren];
        }
        return;
      }
      if (typeof dataObject[key] === 'object') {
        findKeyAndDeleteRow(objectKey, dataObject[key]);
      }
    }
  };

  const findKeyAndUpdateStatus = (objectKey, dataObject) => {
    for (const key in dataObject) {
      if (key === objectKey) {
        //Delete territory
        if (objectKey === 'subregion') {
          const { children } = dataObject;
          const resultTerritory = children.find((territory) => territory.key === infoParent.key);
          if (resultTerritory) {
            resultTerritory.enabled = infoParent.enabled;
          }
        }

        if (dataObject.id === infoParent.id) {
          dataObject.enabled = infoParent.enabled;
        }
        return;
      }
      if (typeof dataObject[key] === 'object') {
        findKeyAndUpdateStatus(objectKey, dataObject[key]);
      }
    }
  };

  const findTerritoryAndReassignZone = (dataObject, data) => {
    for (const key in dataObject) {
      if (key === 'territory') {
        const { zone, toTerritoryId, fromTerritoryId } = data;
        //Delete zone from current territory
        if (dataObject.id === fromTerritoryId) {
          const { children } = dataObject;
          const foundZone = children.find((child) => child.id === zone.id);
          if (foundZone) {
            const newChildren = children.filter((child) => child.id !== foundZone.id);
            dataObject.children = [...newChildren];
          }
        }

        if (dataObject.id === toTerritoryId) {
          const { children } = dataObject;
          zone.territoryId = toTerritoryId;
          children.push(zone);
        }
        return;
      }
      if (typeof dataObject[key] === 'object') {
        findTerritoryAndReassignZone(dataObject[key], data);
      }
    }
  };

  useEffect(() => {
    setModalContent(
      <Transfer
        dataSource={transferData}
        targetKeys={targetKeys}
        listStyle={{
          width: 350,
          height: 300,
          display: 'flex',
          justifyContent: 'center',
        }}
        onChange={onChangeTransfer}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetKeys]);

  useEffect(() => {
    onChangeActiveComponent('BussinessStructure');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCountriesNotInZone = async (zoneId, zoneCountries) => {
    const responseCountries = await getCountriesNotInZone(zoneId);
    const countries = responseCountries.data;
    if (countries) {
      const modifiedCountries = countries.map((country) => ({
        id: country.key,
        key: country.key,
        value: country.country,
        ...country,
      }));
      const modifiedZoneCountries = zoneCountries.map((country) => ({
        ...country,
        id: country.id,
        key: country.id,
        value: country.country,
      }));
      const allCountries = [...modifiedCountries, ...modifiedZoneCountries];
      const targetCountriesIds = zoneCountries.map((zoneCountry) => zoneCountry.id);

      return {
        allCountries,
        targetCountriesIds,
      };
    }
  };

  const handleOpenModalEdit = async (content, record) => {
    setModalContent(content);
    setVisible(true);
    setTypeModal('Edit');
    switch (record.type) {
      case 'Region':
        setDataModalEdit({
          id: record.id,
          type: record.type,
          key: record.type,
          subtitle: record.subtitle,
        });
        break;
      case 'BusinessLine':
        setDataModalEdit({
          id: record.id,
          type: record.type,
          key: 'line',
          subtitle: record.subtitle,
        });
        break;
      case 'Subregion':
        setDataModalEdit({
          id: record.id,
          type: record.type,
          key: record.type,
          subtitle: record.subtitle,
        });
        break;
      case 'Territory':
        setDataModalEdit({
          id: record.id,
          type: record.type,
          key: record.type,
          subtitle: record.subtitle,
        });
        break;
      case 'Zone':
        setDataModalEdit({
          id: record.id,
          type: record.type,
          key: record.type,
          subtitle: record.subtitle,
        });
        break;
      case 'Country':
        setDataModalEdit({
          id: record.id,
          type: record.type,
          key: record.type,
          subtitle: record.subtitle,
        });
        break;
      default:
        setDataModalEdit({ id: null, type: '' });
        break;
    }

    setModalContent(content);
  };

  const updateElement = async (requestData, requestInput) => {
    try {
      let data = `'${requestInput}'`;
      setIsLoadingButtonOk(true);
      let responseEditElementBusinessStructure = await updateElementBusinessStructure(
        requestData.type,
        requestData.id,
        data
      );
      if (responseOK(responseEditElementBusinessStructure)) {
        openNotification('success', responseEditElementBusinessStructure.message);
        setIsLoadingButtonOk(false);
        setVisible(false);
        return {
          success: true,
          data: responseEditElementBusinessStructure.data,
        };
      } else if (!responseEditElementBusinessStructure.data) {
        setIsLoadingButtonOk(false);
        openNotification('error', responseEditElementBusinessStructure.message);
      }
    } catch (error) {
      setIsLoadingButtonOk(false);
      return {
        success: false,
        data: null,
      };
    }
  };

  const getTerritoriesNotInZone = async (recordId) => {
    let responseTerritoriesNotInZone = await getTerritorys(recordId);
    return responseTerritoriesNotInZone.data;
  };

  const handleOpenModalReAsignZone = async (record) => {
    const responseTerritories = await getTerritoriesNotInZone(record.id);
    if (responseTerritories) {
      const bodyModalReAssign = (
        <>
          <div className="modal-header">
            <InfoCircleOutlined className="modal-icon" />
            <p className="modal-title-blue">Reasignar zona</p>
          </div>
          <p className="modal-subtitle">Seleccione el territorio al que desea reasignarla</p>
          <div className="modal-flex-container">
            <div className="modal-input-container">
              <p className="modal-text-blue">*Territorio</p>

              <Select onChange={(e) => handleChangeSelect(e)}>
                {responseTerritories.map((item) => (
                  <Select.Option key={item.key} value={item.key}>
                    {item.value}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </>
      );
      setIsDisabled(true);
      setVisible(true);
      setModalContent(bodyModalReAssign);
      setTypeModal('ReAsign');
      setRequestZoneInfo(record);
    }
  };

  const handleOpenModalConfirmReAssignZone = () => {
    const bodyModalReAssign = (
      <>
        <div className="modal-header">
          <InfoCircleOutlined className="modal-icon" />
          <p className="modal-title-blue">Reasignar zona</p>
        </div>
        <p className="modal-subtitle">
          ¿Esta seguro que desea reasignar la zona? Todos los elementos asociados a la zona se
          asignarán al nuevo territorio seleccionado.
        </p>
      </>
    );
    setVisible(true);
    setModalContent(bodyModalReAssign);
    setTypeModal('ReAsignConfirm');
  };

  const reAsignZone = async (reAsignzoneId, reAsignTerritoryId) => {
    setIsLoadingButtonOk(true);
    try {
      let responseUpdateReAsignZone = await updateReAsignZone(reAsignzoneId, reAsignTerritoryId);

      if (responseOK(responseUpdateReAsignZone)) {
        openNotification('success', responseUpdateReAsignZone.message);
        setIsLoadingButtonOk(false);
        setVisible(false);
        return {
          success: true,
          data: responseUpdateReAsignZone.data,
        };
      } else if (!responseUpdateReAsignZone.data) {
        setIsLoadingButtonOk(false);
        openNotification('error', responseUpdateReAsignZone.message);
      }
    } catch (error) {
      setIsLoadingButtonOk(false);
      return {
        success: false,
        data: null,
      };
    }
  };

  const handleOpenModalDelete = async (content, record) => {
    setIsDisabled(false);
    setVisible(true);
    setModalContent(content);
    setTypeModal('Delete');
    setInfoParent(record);
  };

  const handleOpenModalSwitch = async (type, contentEnabled, contentDisabled, checked, record) => {
    setIsDisabled(false);
    setTypeModal(type);
    setInfoParent({
      ...record,
      enabled: !checked,
    });
    if (checked) {
      setVisible(true);
      setModalContent(contentDisabled);
    } else {
      setVisible(true);
      setModalContent(contentEnabled);
    }
  };

  const handleOpenModal = async (content, type, infoParentData) => {
    setVisible(true);
    setModalContent(content);
    setInfoParent(infoParentData);
    setIsDisabled(true);
    setDataInput('');

    switch (type) {
      case 'Territory':
        setTypeModal('Territory');
        break;
      case 'Zone':
        setTypeModal('Zone');
        break;
      case 'Country':
        setTypeModal('Country');
        break;
      default:
        setTypeModal('');
        break;
    }
  };

  const handleOpenTransferModal = async (zoneId, zoneCountries, infoParentData) => {
    setVisible(true);
    setZoneId(zoneId);
    setIsDisabled(true);
    setModalContent(<Transfer isLoadingTransfer={true} />);
    setInfoParent(infoParentData);
    setTypeModal('');
    const responseCountries = await fetchCountriesNotInZone(zoneId, zoneCountries);
    if (responseCountries) {
      const { allCountries, targetCountriesIds } = responseCountries;
      setTransferData(allCountries);
      setModalContent(
        <Transfer
          dataSource={allCountries}
          targetKeys={targetCountriesIds}
          listStyle={{
            width: 350,
            height: 300,
            display: 'flex',
            justifyContent: 'center',
          }}
          onChange={onChangeTransfer}
          isLoadingTransfer={false}
        />
      );
    }
  };

  const handleChangeSelect = (value) => {
    const territoryId = value;

    if (territoryId > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    setRequestTerritoryId(territoryId);
  };

  const postZone = async (zoneName) => {
    setIsLoadingButtonOk(true);
    try {
      const responsePostZone = await updateBusinessStructure('Zone', {
        territoryId: infoParent,
        name: zoneName,
      });
      if (responseOK(responsePostZone)) {
        openNotification('success', responsePostZone.message);
        setIsLoadingButtonOk(false);
        setVisible(false);
        return {
          success: true,
          data: responsePostZone.data,
        };
      } else {
        setIsLoadingButtonOk(false);
        openNotification('error', responsePostZone.message);
        return {
          success: false,
          data: null,
        };
      }
    } catch (error) {
      setIsLoadingButtonOk(false);
      return {
        success: false,
        data: null,
      };
    }
  };

  const postCountry = async (countryName) => {
    setIsLoadingButtonOk(true);
    try {
      const responsePostCountry = await updateBusinessStructure('Country', {
        zoneId: infoParent,
        name: countryName,
      });
      if (responseOK(responsePostCountry)) {
        openNotification('success', responsePostCountry.message);
        setIsLoadingButtonOk(false);
        setVisible(false);
        return {
          success: true,
          data: responsePostCountry.data,
        };
      } else if (!responsePostCountry.data) {
        setIsLoadingButtonOk(false);
        openNotification('error', responsePostCountry.message);
      }
      return {
        success: false,
        data: null,
      };
    } catch (error) {
      setIsLoadingButtonOk(false);
      return {
        success: false,
        data: null,
      };
    }
  };

  const postTerritory = async (countryName) => {
    setIsLoadingButtonOk(true);
    try {
      const responsePostTerritory = await updateBusinessStructure('Territory', {
        ...infoParent,
        name: countryName,
      });
      if (responseOK(responsePostTerritory)) {
        openNotification('success', responsePostTerritory.message);
        setIsLoadingButtonOk(false);
        setVisible(false);
        return {
          success: true,
          data: responsePostTerritory.data,
        };
      } else if (!responsePostTerritory.data) {
        setIsLoadingButtonOk(false);
        openNotification('error', responsePostTerritory.message);
      }
      return {
        success: false,
        data: null,
      };
    } catch (error) {
      setIsLoadingButtonOk(false);
      return {
        success: false,
        data: null,
      };
    }
  };

  const updateCountriesInZone = async (zoneId, data) => {
    setIsLoadingButtonOk(true);
    try {
      let putResponse = await updateCountries(zoneId, data);
      if (responseOK(putResponse)) {
        openNotification('success', putResponse.message);
        setSelectedCountries([]);
        setVisible(false);
        setIsDisabled(false);
        setIsLoadingButtonOk(false);
        return true;
      } else if (putResponse.data) {
        setIsLoadingButtonOk(false);
        openNotification('error', putResponse.message);
        return false;
      }
    } catch (error) {
      setIsLoadingButtonOk(false);
      openNotification('error', error.message);
      return false;
    }
  };

  const updateTerritoryEnableStatus = async (data) => {
    setIsLoadingButtonOk(true);
    try {
      let updateEnabledResponse = await updateTerritoryEnable(data);
      if (responseOK(updateEnabledResponse)) {
        openNotification('success', updateEnabledResponse.message);
        setVisible(false);
        setIsDisabled(false);
        setIsLoadingButtonOk(false);
        return true;
      } else if (updateEnabledResponse.data) {
        setIsLoadingButtonOk(false);
        openNotification('error', updateEnabledResponse.message);
        return false;
      }
    } catch (error) {
      setIsLoadingButtonOk(false);
      return false;
    }
  };

  const updateZoneEnableStatus = async (data) => {
    setIsLoadingButtonOk(true);
    try {
      let updateEnabledResponse = await updateZoneEnable(data);
      if (responseOK(updateEnabledResponse)) {
        openNotification('success', updateEnabledResponse.message);
        setVisible(false);
        setIsDisabled(false);
        setIsLoadingButtonOk(false);
        return true;
      } else if (updateEnabledResponse.data) {
        setIsLoadingButtonOk(false);
        openNotification('error', updateEnabledResponse.message);
        return false;
      }
    } catch (error) {
      setIsLoadingButtonOk(false);
      return false;
    }
  };

  const updateCountryEnableStatus = async (data) => {
    setIsLoadingButtonOk(true);
    try {
      let updateEnabledResponse = await updateCountryEnable(data);
      if (responseOK(updateEnabledResponse)) {
        openNotification('success', updateEnabledResponse.message);
        setVisible(false);
        setIsDisabled(false);
        setIsLoadingButtonOk(false);
        return true;
      } else if (updateEnabledResponse.data) {
        setIsLoadingButtonOk(false);
        openNotification('error', updateEnabledResponse.message);
        return false;
      }
    } catch (error) {
      setIsLoadingButtonOk(false);
      return false;
    }
  };

  const deleteElement = async (requestType, data) => {
    setIsLoadingButtonOk(true);
    try {
      const responseDeleteElement = await deleteElementBusinessStructure(requestType, data);
      if (responseOK(responseDeleteElement)) {
        openNotification('success', responseDeleteElement.message);
        setVisible(false);
        setIsDisabled(false);
        setIsLoadingButtonOk(false);
        return true;
      } else if (responseDeleteElement.data) {
        setIsLoadingButtonOk(false);
        openNotification('error', responseDeleteElement.message);
        return false;
      }
    } catch (error) {
      setIsLoadingButtonOk(false);
      return false;
    }
  };

  const handleOk = async (formReset) => {
    switch (typeModal) {
      case 'Territory': {
        const responsePostTerritory = await postTerritory(dataInput);
        if (responsePostTerritory.success) {
          const newTerritory = {
            ...responsePostTerritory.data,
          };
          findKeyAndCreateRow('subregion', tableData, newTerritory);
          const newData = [...tableData];
          setTableData(newData);
        }
        formReset.resetFields();
        setIsDisabled(true);
        break;
      }
      case 'Zone': {
        const responsePostZone = await postZone(dataInput);
        if (responsePostZone.success) {
          const newZone = {
            ...responsePostZone.data,
          };
          findKeyAndCreateRow('territory', tableData, newZone);
          const newData = [...tableData];
          setTableData(newData);
        }
        formReset.resetFields();
        setIsDisabled(true);
        break;
      }
      case 'Country': {
        const responsePostCountry = await postCountry(dataInput);
        if (responsePostCountry.success) {
          const newCountry = {
            ...responsePostCountry.data,
          };
          findKeyAndCreateRow('zone', tableData, newCountry);
          const newData = [...tableData];
          setTableData(newData);
        }
        formReset.resetFields();
        setIsDisabled(true);
        break;
      }
      case 'Delete': {
        const key = infoParent.parentKey;
        const { type, id } = infoParent;
        let data = {};
        if (type === 'Territory') {
          data = {
            TerritoryId: id,
          };
        } else if (type === 'Country') {
          data = {
            CountryId: id,
          };
        } else {
          data = {
            ZoneId: id,
          };
        }
        const responseDeleteElement = await deleteElement(type, data);
        if (responseDeleteElement) {
          const newData = [...tableData];
          setTableData(newData);
          findKeyAndDeleteRow(key, tableData);
        }
        setVisible(false);
        setIsDisabled(true);
        break;
      }
      case 'Edit': {
        const responseEditElement = await updateElement(dataModalEdit, inputDataEdit);
        if (responseEditElement.success) {
          const key = dataModalEdit.key.toLocaleLowerCase();
          findKeyAndUpdateName(key, tableData);
          const newData = [...tableData];
          setTableData(newData);
        }
        formReset.resetFields();
        setIsDisabled(true);
        break;
      }
      case 'ReAsign': {
        handleOpenModalConfirmReAssignZone();
        break;
      }
      case 'ReAsignConfirm': {
        const responseReAsignZone = await reAsignZone(requestZoneInfo.id, requestTerritoryId);
        const data = {
          zone: requestZoneInfo,
          fromTerritoryId: requestZoneInfo.territoryId,
          toTerritoryId: requestTerritoryId,
        };
        if (responseReAsignZone.success) {
          findTerritoryAndReassignZone(tableData, data);
          const newData = [...tableData];
          setTableData(newData);
          setIsDisabled(true);
        }
        formReset.resetFields();
        break;
      }
      case 'Switch-Territory': {
        const { enabled, territoryId } = infoParent;
        const data = {
          territoryId,
          enabled,
        };
        const resposeUpdateEnabled = await updateTerritoryEnableStatus(data);
        if (resposeUpdateEnabled) {
          setVisible(false);
          findKeyAndUpdateStatus('subregion', tableData);
          const newData = [...tableData];
          setTableData(newData);
        }
        break;
      }
      case 'Switch-Zone': {
        const { enabled, zoneId } = infoParent;
        const data = {
          zoneId,
          enabled,
        };
        const responseUpdateEnabled = await updateZoneEnableStatus(data);
        if (responseUpdateEnabled) {
          setVisible(false);
          findKeyAndUpdateStatus('zone', tableData);
          const newData = [...tableData];
          setTableData(newData);
        }
        break;
      }
      case 'Switch-Country': {
        const { enabled, countryId, zoneId } = infoParent;
        const data = {
          countryId,
          enabled,
          zoneId,
        };
        const responseUpdateEnabled = await updateCountryEnableStatus(data);
        if (responseUpdateEnabled) {
          setVisible(false);
          findKeyAndUpdateStatus('country', tableData);
          const newData = [...tableData];
          setTableData(newData);
        }
        break;
      }
      default: {
        const responseUpdateCountriesInZone = await updateCountriesInZone(updateZoneId, targetKeys);
        if (responseUpdateCountriesInZone) {
          const newCountries = [];
          const numberCountries = transferData.length;
          const numberNewCountries = selectedCountries.length;
          for (let i = 0; i < numberCountries; i++) {
            for (let j = 0; j < numberNewCountries; j++) {
              if (transferData[i].id === selectedCountries[j]) {
                const name = transferData[i].value;
                const position = name.indexOf('- (Inactivo)');
                let newName = name;
                let enabled = transferData[i].enabled;
                if (position !== -1) {
                  newName = name.substr(0, position);
                  enabled = false;
                }
                newCountries.push({
                  ...transferData[i],
                  country: newName,
                  type: 'Country',
                  value: newName,
                  enabled,
                });
              }
            }
          }
          findKeyAndCreateRow('zone', tableData, newCountries);
          const newData = [...tableData];
          setTableData(newData);
          setDiscardedCountries([]);
        }
        formReset.resetFields();
        setIsDisabled(true);
        break;
      }
    }
  };

  const handleInputChange = (event) => {
    const targetValue = event.target.value;
    if (targetValue.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    setDataInput(event.target.value);
  };

  const handleInputEditChange = (event) => {
    const targetValue = event.target.value;
    if (targetValue.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    setInputDataEdit(event.target.value);
  };

  useEffect(() => {
    const bodyModal = (
      <>
        <div className="modal-header">
          <InfoCircleOutlined className="modal-icon" />
          <p className="modal-title-blue">Editar estructura comercial</p>
        </div>
        <p className="modal-subtitle">Escriba el nombre que desea asignar</p>
        <div className="modal-flex-container">
          <div className="modal-input-container">
            <p className="modal-text-blue">{dataModalEdit.subtitle}</p>
            <Input
              type="text"
              value={inputDataEdit}
              onChange={handleInputEditChange}
              name="editBusinessStructure"
            />
          </div>
        </div>
      </>
    );
    setModalContent(bodyModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDataEdit]);

  const columns = [
    {
      title: (
        <div>
          <Link
            to={{
              pathname: `/catalog/business-estructure`,
            }}
            className="arrow-before"
          >
            <LeftOutlined />
          </Link>
        </div>
      ),
      className: 'bg-blue',
    },
    {
      className: 'bg-blue ant-table-column',
      dataIndex: 'map',
      key: 'action',

      // eslint-disable-next-line react/display-name
      render: (_text, record) => {
        let dataModal = { id: null, type: '', element: '', subtitle: '' };
        switch (record.type) {
          case 'Region':
            dataModal = {
              id: record.id,
              type: record.type,
              element: record.region,
              typeElement: 'Región',
              enabled: record.enabled,
            };

            break;
          case 'Line':
            dataModal = {
              id: record.id,
              type: `Business${record.type}`,
              element: record.line,
              typeElement: 'Línea de negocio',
              enabled: record.enabled,
            };

            break;
          case 'Subregion':
            dataModal = {
              id: record.id,
              type: record.type,
              element: record.subregion,
              typeElement: 'Subregión',
              enabled: record.enabled,
            };

            break;
          case 'Territory':
            dataModal = {
              id: record.id,
              type: record.type,
              element: record.territory,
              typeElement: 'Territorio',
              enabled: record.enabled,
            };

            break;
          case 'Zone':
            dataModal = {
              id: record.id,
              type: record.type,
              element: record.zone,
              typeElement: 'Zona',
              enabled: record.enabled,
            };

            break;
          case 'Country':
            dataModal = {
              id: record.id,
              type: record.type,
              element: record.country,
              typeElement: 'País',
              enabled: record.enabled,
            };

            break;
          default:
            dataModal = {
              id: record.id,
              type: record.type,
              element: record.country,
              typeElement: '',
              enabled: record.enabled,
            };
            break;
        }
        /* Coment UAT */

        if (record.showMap) {
          if (record.hasMap && !record.enabled) {
            return (
              <IconContext.Provider style={{ textAlign: 'center' }} value={{ color: '#00499A' }}>
                <MdAddLocation
                  className={`ant-table-icons ${record.enabled ? '' : 'icon-disabled'}`}
                />
              </IconContext.Provider>
            );
          }
          if (!record.hasMap && record.enabled) {
            return (
              <IconContext.Provider style={{ textAlign: 'center' }} value={{ color: '#00499A' }}>
                <MdAddLocation
                  onClick={() => handleOpenModalMap(record.hasMap, dataModal)}
                  className={`ant-table-icons ${record.hasMap ? '' : 'icon-color'}`}
                />
              </IconContext.Provider>
            );
          } else {
            return (
              <IconContext.Provider style={{ textAlign: 'center' }} value={{ color: '#00499A' }}>
                <MdAddLocation
                  onClick={() => handleOpenModalMap(record.hasMap, dataModal)}
                  className="ant-table-icons"
                />
              </IconContext.Provider>
            );
          }
        }
      },
    },
    {
      className: 'bg-blue ant-table-column',
      dataIndex: 'edit',
      key: 'action',

      // eslint-disable-next-line react/display-name
      render: (_text, record) => {
        let inputEdit = { id: null, type: '', element: '', subtitle: '' };
        switch (record.type) {
          case 'Region':
            inputEdit = {
              id: record.id,
              type: record.type,
              element: record.region,
              subtitle: 'Región',
            };

            break;
          case 'Line':
            inputEdit = {
              id: record.id,
              type: `Business${record.type}`,
              element: record.line,
              subtitle: 'Línea de negocio',
            };

            break;
          case 'Subregion':
            inputEdit = {
              id: record.id,
              type: record.type,
              element: record.subregion,
              subtitle: 'Subregión',
            };

            break;
          case 'Territory':
            inputEdit = {
              id: record.id,
              type: record.type,
              element: record.territory,
              subtitle: 'Territorio',
            };

            break;
          case 'Zone':
            inputEdit = {
              id: record.id,
              type: record.type,
              element: record.zone,
              subtitle: 'Zona',
            };

            break;
          case 'Country':
            inputEdit = {
              id: record.id,
              type: record.type,
              element: record.country,
              subtitle: 'País',
            };

            break;
          default:
            inputEdit = {
              id: record.id,
              type: record.type,
              element: record.country,
              subtitle: '',
            };
            break;
        }

        const bodyModal = (
          <>
            <div className="modal-header">
              <InfoCircleOutlined className="modal-icon" />
              <p className="modal-title-blue">Editar estructura comercial</p>
            </div>
            <p className="modal-subtitle">Escriba el nombre que desea asignar</p>
            <div className="modal-flex-container">
              <div className="modal-input-container">
                <p className="modal-text-blue">{inputEdit.subtitle}</p>
                <Input
                  type="text"
                  value={inputEdit.element}
                  onChange={handleInputEditChange}
                  name="editBusinessStructure"
                />
              </div>
            </div>
          </>
        );

        return (
          <IconContext.Provider style={{ textAlign: 'center' }} value={{ color: '#00499A' }}>
            <MdModeEdit
              className={`ant-table-icons ${record.enabled ? '' : 'icon-disabled'}`}
              onClick={() => handleOpenModalEdit(bodyModal, inputEdit)}
            />
          </IconContext.Provider>
        );
      },
    },
    {
      title: '',
      dataIndex: 'delete',
      className: 'bg-blue',
      render: (text, record) => {
        const bodyModal = (
          <>
            <div className="modal-header">
              <InfoCircleOutlined className="modal-icon" />
              <p className="modal-title-blue">Eliminar estructura comercial</p>
            </div>
            <p className="modal-subtitle">
              ¿Está seguro que desea eliminar elemento de la estructura comercial?
            </p>
          </>
        );
        let newRecord = {};
        switch (record.type) {
          case 'Territory':
            newRecord = {
              ...record,
              parentId: record.subregionId,
              parentKey: 'subregion',
            };
            break;
          case 'Zone':
            newRecord = {
              ...record,
              parentId: record.territoryId,
              parentKey: 'territory',
            };
            break;
          case 'Country':
            newRecord = {
              ...record,
              parentId: record.zoneId,
              parentKey: 'zone',
            };
            break;
          default:
            newRecord = {
              ...record,
            };
            break;
        }
        if (record.canDelete) {
          return (
            <IconContext.Provider value={{ color: '#00499A' }}>
              <MdDelete
                className="ant-table-icons"
                onClick={() => handleOpenModalDelete(bodyModal, newRecord)}
              />
            </IconContext.Provider>
          );
        }
      },
    },
    {
      title: '',
      dataIndex: 'switch',
      className: 'bg-blue',
      render: (text, record) => {
        const bodyModalDisabled = (
          <>
            <div className="modal-header">
              <InfoCircleOutlined className="modal-icon" />
              <p className="modal-title-blue">Desactivar estructura comercial</p>
            </div>
            <p className="modal-subtitle">
              ¿Está seguro que desea desactivar el elemento de la estructura comercial?
            </p>
          </>
        );
        const bodyModalEnabled = (
          <>
            <div className="modal-header">
              <InfoCircleOutlined className="modal-icon" />
              <p className="modal-title-blue">Activar estructura comercial</p>
            </div>
            <p className="modal-subtitle">
              ¿Está seguro que desea activar el elemento de la estructura comercial?
            </p>
          </>
        );
        if (record.canUpdateStatus) {
          let newRecord = {};
          let type = '';
          switch (record.type) {
            case 'Territory':
              newRecord = {
                territoryId: record.id,
                enabled: record.enabled,
                subregionId: record.subregionId,
                key: record.key,
                id: record.id,
              };
              type = 'Switch-Territory';
              break;

            case 'Zone':
              newRecord = {
                zoneId: record.id,
                enabled: record.enabled,
                id: record.id,
              };
              type = 'Switch-Zone';
              break;
            case 'Country':
              newRecord = {
                countryId: record.id,
                enabled: record.enabled,
                id: record.id,
                zoneId: record.zoneId,
              };
              type = 'Switch-Country';
              break;
            default:
              newRecord = {
                ...record,
              };
              break;
          }
          return (
            <Switch
              onClick={(checked, event) =>
                handleOpenModalSwitch(type, bodyModalEnabled, bodyModalDisabled, checked, newRecord)
              }
              checkedChildren="Desactivado"
              unCheckedChildren="Activado"
              className={record.enabled ? 'switch-active' : 'switch-inactive'}
              checked={!record.enabled}
            />
          );
        }
      },
    },

    {
      title: 'Región',
      dataIndex: 'region',
      className: 'bg-blue',
    },
    {
      title: 'Línea de negocio',
      dataIndex: 'line',
      className: 'bg-blue',
    },
    {
      title: 'Subregión',
      dataIndex: 'subregion',
      className: 'bg-blue',
    },
    {
      title: 'Territorio',
      dataIndex: 'territory',
      className: 'bg-blue',

      render: (_text, record) => {
        const bodyModal = (
          <>
            <div className="modal-header">
              <InfoCircleOutlined className="modal-icon" />
              <p className="modal-title-blue">Nueva estructura comercial</p>
            </div>
            <p className="modal-subtitle">Escriba el territorio que desea agregar</p>
            <div className="modal-flex-container">
              <div className="modal-input-container">
                <p className="modal-text-blue">*Territorio</p>
                <Input
                  type="text"
                  placeholder="Escriba Territorio"
                  onChange={handleInputChange}
                  name="territory"
                />
              </div>
            </div>
          </>
        );

        if (!record.canAdd && record.type === 'Subregion') {
          const lineId = record.children[0].lineId;
          const subregionKey = record.key;
          const subregionId = record.id;
          const dataParent = {
            lineId,
            subregionKey,
            subregionId,
          };
          return (
            <IconContext.Provider value={{ color: '#00499A' }}>
              {
                <Popover
                  placement="right"
                  getPopupContainer={helperGetPopupContainer}
                  content={
                    <p
                      className="ant-table-popover"
                      onClick={() => handleOpenModal(bodyModal, 'Territory', dataParent)}
                    >
                      Agregar Nuevo
                    </p>
                  }
                  trigger="click"
                  className="ant-table-popover"
                >
                  <IoMdAddCircle className="ant-table-icons" />
                </Popover>
              }
            </IconContext.Provider>
          );
        }

        return record.territory;
      },
    },
    {
      title: 'Zona',
      dataIndex: 'zone',
      className: 'bg-blue',
      render: (_text, record) => {
        const bodyModal = (
          <>
            <div className="modal-header">
              <InfoCircleOutlined className="modal-icon" />
              <p className="modal-title-blue">Nueva estructura comercial</p>
            </div>
            <p className="modal-subtitle">Escriba la zona que desea agregar</p>
            <div className="modal-flex-container">
              <div className="modal-input-container">
                <p className="modal-text-blue">*Zona</p>
                <Input
                  type="text"
                  placeholder="Escriba zona"
                  onChange={handleInputChange}
                  name="zone"
                />
              </div>
            </div>
          </>
        );

        if (record.canAdd && record.type === 'Territory') {
          return (
            <IconContext.Provider value={{ color: '#00499A' }}>
              <Popover
                placement="right"
                getPopupContainer={helperGetPopupContainer}
                content={
                  <p
                    className="ant-table-popover"
                    onClick={() => handleOpenModal(bodyModal, 'Zone', record.id)}
                  >
                    Agregar nuevo
                  </p>
                }
                trigger="click"
              >
                <IoMdAddCircle className="ant-table-icons" />
              </Popover>
            </IconContext.Provider>
          );
        }
        if (record.type === 'Zone' && record.canReassign) {
          return (
            <>
              <IconContext.Provider value={{ color: '#00499A' }}>
                <BsArrowUpDown
                  className="ant-table-icons"
                  onClick={() => handleOpenModalReAsignZone(record)}
                />
              </IconContext.Provider>
              <span className="ant-table-reassign-icon">{record.zone}</span>
            </>
          );
        }

        if (record.type === 'Zone' && !record.canReassign) {
          return <span className="ant-table-reassign-icon">{record.zone}</span>;
        }
      },
    },
    {
      title: 'País',
      dataIndex: 'Country',
      className: 'bg-blue',
      render: (_text, record) => {
        //GET ZONE'S COUNTRIES
        let zoneCountries = [];
        let zoneId;
        if (record.type === 'Zone') {
          zoneCountries = record.children.map((country) => ({
            id: country.id,
            key: country.key,
            ...country,
            isTargetKey: true,
          }));
          zoneId = record.id;
        }

        const bodyModal = (
          <>
            <div className="modal-header">
              <InfoCircleOutlined className="modal-icon" />
              <p className="modal-title-blue">Nueva estructura comercial</p>
            </div>
            <p className="modal-subtitle">Escriba el país que desea agregar</p>
            <div className="modal-flex-container">
              <div className="modal-input-container">
                <p className="modal-text-blue">*País</p>
                <Input
                  type="text"
                  placeholder="Escriba país"
                  onChange={handleInputChange}
                  name="country"
                />
              </div>
            </div>
          </>
        );
        if (record.canAdd && record.type === 'Zone') {
          return (
            <IconContext.Provider value={{ color: '#00499A' }}>
              <Popover
                placement="right"
                getPopupContainer={helperGetPopupContainer}
                content={
                  <>
                    <p
                      className="ant-table-popover"
                      onClick={() => handleOpenModal(bodyModal, 'Country', record.id)}
                    >
                      Agregar nuevo
                    </p>
                    <p
                      className="ant-table-popover ant-table-popover-second"
                      onClick={() => handleOpenTransferModal(zoneId, zoneCountries, record.id)}
                    >
                      Agregar existente
                    </p>
                  </>
                }
                trigger="click"
              >
                <IoMdAddCircle className="ant-table-icons" />
              </Popover>
            </IconContext.Provider>
          );
        }

        return record.country;
      },
    },
  ];

  return (
    <>
      <AntdTable
        locale={{ emptyText: <Result status="500" /> }}
        columns={columns}
        loading={isLoading}
        className="ant-table-style"
        dataSource={tableData}
        indentSize={0}
        pagination={false}
      />
      <ModalAdd
        closable={false}
        visible={visible}
        onOk={(formReset) => handleOk(formReset)}
        buttonOkDisabled={isDisabled}
        buttonOkLoading={isLoadingButtonOk}
        setVisible={setVisible}
        setDataInput={setDataInput}
      >
        {modalContent}
      </ModalAdd>
    </>
  );
};

Table.propTypes = {
  tableData: PropTypes.array,
  isLoading: PropTypes.bool,
};

Table.defaultProps = {
  tableData: [],
  isLoading: false,
};

export default Table;
