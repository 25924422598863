import {
  VALID_PRODUCTS_FOR_DELETING_REQUEST,
  VALID_PRODUCTS_FOR_DELETING_SUCCESS,
  VALID_PRODUCTS_FOR_DELETING_FAIL,
} from '../actions/validProductsForDeleting';

const validProductsForDeleting = (state = {}, action) => {
  switch (action.type) {
    case VALID_PRODUCTS_FOR_DELETING_REQUEST: {
      return {
        ...state,
        total: 0,
        loading: true,
      };
    }

    case VALID_PRODUCTS_FOR_DELETING_FAIL:
    case VALID_PRODUCTS_FOR_DELETING_SUCCESS: {
      return {
        ...state,
        total: action.payload,

        loading: false,
      };
    }

    default:
      return state;
  }
};

export default validProductsForDeleting;
