import React from 'react';
import { Col, Row, List, Skeleton, Pagination } from 'antd';
import PropTypes from 'prop-types';
import BButton from 'common/components/BButton';
import NoticationList from 'Notifications/containers/NotificationsList';
import { CheckOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import {
  markAsReadRequest,
  markAllAsReadRequest,
  handlePageRequest,
  handlePageTrueRequest,
} from 'Notifications/redux/actions/notifications';
import { useHistory } from 'react-router-dom';
import './styles.css';

function NotificationContainer(props) {
  const {
    hasNotifications,
    totalTrue,
    totalFalse,
    markAsReadRequest: markAsRead,
    markAllAsReadRequest: markAllAsRead,
    handlePageRequest: handleChangePageFalse,
    handlePageTrueRequest: handleChangePageTrue,
    notifications,
    loading,
    notificationsTrue,
    loadingTrue,
  } = props;

  let dataSourceReadTrue = notificationsTrue;

  const history = useHistory();
  const handleOnShowAll = () => {
    history.push('/notifications');
  };

  return (
    <div className="notification-menu">
      <h2>Notificaciones</h2>

      <Row>
        <Col md={10} className="text-left">
          <h3 className="text-left title-style">Nuevas</h3>
        </Col>
        {hasNotifications && (
          <Col className="text-right button-style" md={12}>
            <CheckOutlined />
            <BButton
              style={{ float: 'right', marginRight: '-25px', color: 'black' }}
              type="link"
              onClick={markAllAsRead}
              label="Marcar todo como leído"
            />
          </Col>
        )}
      </Row>

      <NoticationList
        totalFalse={totalFalse}
        handleChangePageTrue={handleChangePageTrue}
        handleChangePageFalse={handleChangePageFalse}
        markAsRead={markAsRead}
        dataSource={notifications}
        loading={loading}
      />

      <Row>
        <Col className="text-right" md={24}>
          <BButton
            block
            className="show-all color-button "
            type="link"
            onClick={handleOnShowAll}
            label="Mostrar todos"
          />
        </Col>
      </Row>

      {loadingTrue && (
        <div className="notifications-list notification-loading">
          <Skeleton className="notification-content" loadingTrue={loadingTrue} active avatar>
            <List.Item.Meta title={<a href="aa">a</a>} description={'dsdkds'} />
          </Skeleton>
          <Skeleton className="notification-content" loadingTrue={loadingTrue} active avatar>
            <List.Item.Meta title={<a href="aa">a</a>} description={'dsdkds'} />
          </Skeleton>
          <Skeleton className="notification-content" loadingTrue={loadingTrue} active avatar>
            <List.Item.Meta title={<a href="aa">a</a>} description={'dsdkds'} />
          </Skeleton>
          <Skeleton className="notification-content" loadingTrue={loadingTrue} active avatar>
            <List.Item.Meta title={<a href="aa">a</a>} description={'dsdkds'} />
          </Skeleton>
        </div>
      )}

      {!loadingTrue && (
        <Col className="notifications-read">
          <h3 className="text-left title-style">Anteriores</h3>
          <List
            itemLayout="vertical"
            size="large"
            pagination={false}
            tabIndex="0"
            className="notifications-list"
            dataSource={dataSourceReadTrue}
            renderItem={(item, index) => (
              <List.Item key={item.title}>
                {!loadingTrue && (
                  <div className="notification-content">
                    <Row>
                      <Col xs={1} sm={1} md={1} lg={1} xl={1} />
                      <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                        <div className="notification-date-two">{item.registered}</div>
                        <div className="notification-date-two">{item.registredHour}</div>
                      </Col>
                      <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                        <a className="link-style-before" href={item.link}>
                          <span>{item.message}</span>
                        </a>
                      </Col>
                    </Row>
                  </div>
                )}
              </List.Item>
            )}
          />
        </Col>
      )}

      <Pagination
        pageSize={5}
        className="pagination-button"
        defaultCurrent={1}
        onChange={handleChangePageTrue}
        total={totalTrue}
        style={{ bottom: '0px' }}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    loading: state?.NOTIFICATIONS?.notifications?.notificationsFalse?.isLoading ?? true,
    loadingTrue: state?.NOTIFICATIONS?.notifications?.notificationsTrue?.isLoading ?? true,
    notifications: state?.NOTIFICATIONS?.notifications?.notificationsFalse?.current ?? [],
    totalFalse: state?.NOTIFICATIONS?.notifications?.notificationsFalse?.total,
    hasNotifications:
      state?.NOTIFICATIONS?.notifications?.notificationsFalse?.current?.length || false,
    totalTrue: state?.NOTIFICATIONS?.notifications?.notificationsTrue?.total,
    notificationsTrue: state?.NOTIFICATIONS?.notifications?.notificationsTrue?.data ?? [],
  };
}

export default connect(mapStateToProps, {
  markAsReadRequest,
  markAllAsReadRequest,
  handlePageRequest,
  handlePageTrueRequest,
})(NotificationContainer);

NotificationContainer.propTypes = {
  loadingTrue: PropTypes.bool,
};

NotificationContainer.defaultProps = {
  loadingTrue: false,
};
