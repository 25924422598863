/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import { useHistory } from 'react-router-dom';

import { BusinessLine, Subregion, Segmentation, Placement } from 'RootModule/consultFilters/';

import ConsultTable from 'common/consults/components/Table';
import FilterWrapper from 'common/consults/components/FilterWrapper';
import SearchInput from 'common/consults/components/SearchInput';
import ResetButton from 'common/consults/components/ResetButton';
import { PdfPreviewModal, PeriodSelectModal } from '../components';

import getAccountStatusPreviewModule from '../getAccountStatusPreviewModule';

import {
  accountStatusPreviewRequestConsult,
  accountStatusPreviewSetPdf,
  accountStatusPreviewRequestPeriodList,
  accountStatusPreviewSetPdfPage,
  accountStatusPreviewSetPeriodValue,
  accountStatusPreviewRequestRegisterIntoLog,
} from '../redux-saga/actions';

import {
  consultFiltersBusinessLineClearValue,
  consultFiltersSubregionClearValue,
  consultFiltersSegmentationClearValue,
  consultFiltersPlacementClearValue,
  consultFiltersSearchInputClearValue,
} from 'RootModule/redux/actions/consultFilters';

import getConsultSchema from '../schemas/getConsultSchema';

import { layoutContext } from 'context';

import { downloadPdfUsingString } from '../utils';
import { messageError } from 'utils/messages';

const AccountStatusPreview = (props) => {
  const {
    accountStatusPreviewRequestConsult: requestConsult,
    accountStatusPreviewRequestPeriodList: requestPeriodList,
    accountStatusPreviewSetPdf: setPdf,
    accountStatusPreviewSetPdfPage: setPage,
    accountStatusPreviewSetPeriodValue: setPeriod,
    accountStatusPreviewRequestRegisterIntoLog: requestRegisterIntoLog,

    consultFiltersBusinessLineClearValue: clearBusinessLines,
    consultFiltersSubregionClearValue: clearSubregion,
    consultFiltersSegmentationClearValue: clearSegmentation,
    consultFiltersPlacementClearValue: clearPlacement,
    consultFiltersSearchInputClearValue: clearSearchInput,

    consult,
    isLoading,
    pdf,
    pdfIsLoading,
    pdfPage,
    periodList,
    period,
    existancesList,
    currentClientData,

    BusinessLinesValue,
    SubregionValue,
    SegmentationValue,
    PlacementValue,

    SearchInputValue,
  } = props;

  const [pdfModalVisiblility, setPdfModalVisibility] = useState(false);
  const [periodSelectionModalVisibility, setPeriodSelectionModalVisibility] = useState(false);

  const { onChangeActiveComponent } = useContext(layoutContext);
  const history = useHistory();

  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, []);

  useEffect(() => {
    let consultTableParams = {};

    onChangeActiveComponent('BusinessStructureMassiveUpload');

    if (BusinessLinesValue?.length > 0) {
      consultTableParams.Line = BusinessLinesValue;
    }
    if (SubregionValue?.length > 0) {
      consultTableParams.Subregion = SubregionValue;
    }
    if (SegmentationValue?.length > 0) {
      consultTableParams.Segmentation = SegmentationValue;
    }
    if (PlacementValue?.length > 0) {
      consultTableParams.Presence = PlacementValue;
    }

    if (SearchInputValue?.length > 0) {
      consultTableParams.Wildcard = [SearchInputValue];
    }

    requestConsult({ params: consultTableParams, history });
  }, [BusinessLinesValue, SubregionValue, SegmentationValue, PlacementValue, SearchInputValue]);

  const handleExistanceErrors = () => {
    let errorMessagesList = existancesList.filter(({ value }) => value);

    errorMessagesList.forEach(({ message }, index) => {
      setTimeout(() => {
        messageError(message);
      }, 2000 * index);
    });
  };

  const triggerRequestPdf = () => {
    let pdfString = getPeriodDataPdfById(period);

    hideSelectionModal();

    if (!pdfString) {
      messageError(
        'La consulta de la vista previa del archivo de estado de cuenta no puede realizarse porque el cliente seleccionado no cuenta con contactos de estado de cuenta registrados'
      );
      return;
    }

    if (period === 0) {
      handleExistanceErrors();
    }

    setPdfModalVisibility(true);

    setPdf(pdfString);
  };
  const getPeriodDataPdfById = (id) => {
    if (periodList.length === 0) return '';
    let pdfData = periodList[id].accountStatement;
    return pdfData ? 'data:application/pdf;base64,' + pdfData : '';
  };

  const clearFilters = () => {
    clearBusinessLines();
    clearSubregion();
    clearSegmentation();
    clearPlacement();
    clearSearchInput();
  };

  const hidePdfModal = () => {
    setPdfModalVisibility(false);
    setPage(1);
  };

  const hideSelectionModal = () => {
    setPeriodSelectionModalVisibility(false);
  };
  const showSelectionModal = ({ clientData }) => {
    setPeriod(0);
    requestPeriodList({ clientData, hideSelectionModal });
    setPeriodSelectionModalVisibility(true);
  };
  const getLogParams = () => {
    const periodString = periodList[period].period;
    const { ClientId, LineId } = currentClientData;

    return { ClientId, LineId, Period: periodString };
  };

  const getDateForAccountStatusPdfName = () => {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}${month}${year}`;
  };

  const getPdfName = () => {
    let name = `estado-de-cuenta-${getDateForAccountStatusPdfName()}`;

    return name.length < 253 ? name : 'estado-de-cuenta';
  };
  const handlePdfDownload = () => {
    if (!pdf) return;
    const downloadPdf = () => {
      const name = getPdfName();
      downloadPdfUsingString(pdf, name);
    };
    requestRegisterIntoLog({ params: getLogParams(), downloadPdf });
  };

  return (
    <div>
      <FilterWrapper>
        <BusinessLine />
        <Subregion />
        <Segmentation />
        <Placement />

        <SearchInput floatToRight />
        <ResetButton clearFilters={clearFilters} />
      </FilterWrapper>
      <ConsultTable
        data={consult}
        columns={getConsultSchema({ showSelectionModal })}
        loading={isLoading}
        noResultsMessage={
          'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados.'
        }
      />
      <PeriodSelectModal
        periodList={periodList}
        mustShow={periodSelectionModalVisibility}
        triggerRequestPdf={triggerRequestPdf}
        hideSelectionModal={hideSelectionModal}
        isLoading={isLoading}
        period={period}
        setPeriod={setPeriod}
      />
      <PdfPreviewModal
        mustShow={pdfModalVisiblility}
        hide={hidePdfModal}
        downloadPdf={handlePdfDownload}
        pdf={pdf}
        page={pdfPage}
        setPage={setPage}
        isLoading={pdfIsLoading}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    consult: state?.ACCOUNT_STATUS_PREVIEW?.consult,
    isLoading: state?.ACCOUNT_STATUS_PREVIEW?.isLoading,
    pdf: state?.ACCOUNT_STATUS_PREVIEW?.pdf ?? '',
    pdfIsLoading: state?.ACCOUNT_STATUS_PREVIEW?.pdfIsLoading,
    pdfPage: state?.ACCOUNT_STATUS_PREVIEW?.pdfPage ?? 1,
    periodList: state?.ACCOUNT_STATUS_PREVIEW?.periodList ?? [],
    period: state?.ACCOUNT_STATUS_PREVIEW?.periodValue ?? 0,
    existancesList: state?.ACCOUNT_STATUS_PREVIEW?.getExistances,
    currentClientData: state?.ACCOUNT_STATUS_PREVIEW?.currentClientData,

    BusinessLinesValue: state?.APP?.consultFilters?.businessLine?.value,
    SubregionValue: state?.APP?.consultFilters?.subregion?.value,
    SegmentationValue: state?.APP?.consultFilters?.segmentation?.value,
    PlacementValue: state?.APP?.consultFilters?.placement?.value,

    SearchInputValue: state?.APP?.consultFilters?.searchInput?.value,
  };
};

const ConnectedAccountStatusPreview = connect(mapStateToProps, {
  accountStatusPreviewRequestConsult,
  accountStatusPreviewSetPdf,
  accountStatusPreviewRequestPeriodList,
  accountStatusPreviewSetPeriodValue,
  accountStatusPreviewSetPdfPage,
  accountStatusPreviewRequestRegisterIntoLog,

  consultFiltersBusinessLineClearValue,
  consultFiltersSubregionClearValue,
  consultFiltersSegmentationClearValue,
  consultFiltersPlacementClearValue,
  consultFiltersSearchInputClearValue,
})(AccountStatusPreview);

const AccountStatusPreviewWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getAccountStatusPreviewModule()]}>
      <ConnectedAccountStatusPreview />
    </DynamicModuleLoader>
  );
};
export default AccountStatusPreviewWithModules;
