import request from 'services/request';
const makeConsultRuleSerializedUrl = (params = {}, baseUrl = 'URL') => {
  let composedURL = '';
  let serializedYear;
  let serializedZones;
  let serializedTerritories;
  let serializedSubregions;
  let serializedLines;
  let serializedRegions;
  /* dividir array */
  if (params?.Year?.length) {
    serializedYear = serializeArrayParams(params?.Year, 'Years');
    delete params.Year;
  }
  if (params?.Zones?.length) {
    serializedZones = serializeArrayParams(params?.Zones, 'Zones');
    delete params.Zones;
  }
  if (params?.Territories?.length) {
    serializedTerritories = serializeArrayParams(params?.Territories, 'Territories');
    delete params.Territories;
  }
  if (params?.Subregions?.length) {
    serializedSubregions = serializeArrayParams(params?.Subregions, 'Subregions');
    delete params.Subregions;
  }
  if (params?.Lines?.length) {
    serializedLines = serializeArrayParams(params?.Lines, 'Lines');
    delete params.Lines;
  }
  if (params?.Regions?.length) {
    serializedRegions = serializeArrayParams(params?.Regions, 'Regions');
    delete params.Regions;
  }
  /* Crear URL */
  if (serializedZones) {
    composedURL = `${baseUrl}?${serializedZones}&${serializedYear}`;
  } else if (serializedTerritories) {
    composedURL = `${baseUrl}?${serializedTerritories}&${serializedYear}`;
  } else if (serializedSubregions) {
    composedURL = `${baseUrl}?${serializedSubregions}&${serializedYear}`;
  } else if (serializedLines) {
    composedURL = `${baseUrl}?${serializedLines}&${serializedYear}`;
  } else if (serializedRegions) {
    composedURL = `${baseUrl}?${serializedRegions}&${serializedYear}`;
  } else {
    composedURL = `${baseUrl}`;
  }
  return composedURL;
};
const serializeArrayParams = (params = [], paramName = 'paramNameIds') => {
  let resultantString = '';
  const paramsLength = params?.length ?? 0;
  if (paramsLength > 0) {
    const stringsArray = params.map((parameter, index) => {
      const lastChar = index + 1 === paramsLength ? '' : '&';
      return `${paramName}=${parameter}${lastChar}`;
    });
    resultantString = stringsArray.reduce((accumulatedString, currentValue) => {
      return `${accumulatedString}${currentValue}`;
    });
  }
  return resultantString;
};
//Obtiene una muestra de coordenadas de un CSV almacenado
export async function getMaps({ cancelToken, params }) {
  return request(
    `http://abf6dd7dae4e9437ea5df3605761c1a6-2054679519.us-west-2.elb.amazonaws.com/Dashboards/Api/v1/Maps`,
    {
      method: 'GET',
      cancelToken,
    }
  );
}

//obtiene mapas
export async function getGet({ cancelToken, params }) {
  const baseUrl = `/Dashboards/Api/v1/Maps/Get`;
  const composedURL = makeConsultRuleSerializedUrl(params, baseUrl);
  return request(composedURL, {
    params,
    method: 'GET',
    cancelToken,
  });
}

//obtiene años
export async function getFilterYear({ cancelToken, params }) {
  const baseUrl = `/Dashboards/Api/v1/Maps/GetFilterYear`;
  const composedURL = makeConsultRuleSerializedUrl(params, baseUrl);
  return request(composedURL, { params, method: 'GET', cancelToken });
}

//obtiene excel
export async function getFile({ cancelToken, params }) {
  const baseUrl = `/Dashboards/Api/v1/Maps/File`;
  return request(baseUrl, { method: 'GET', params, cancelToken });
}

//obtiene desafio/acuerdo
export async function getDaysLeft({ cancelToken, params }) {
  const baseUrl = `/Catalogs/Api/v1/ProcessDates/DaysLeft`;
  return request(baseUrl, { params, method: 'GET', cancelToken });
}

/* Validaciones HU-760: 2.1, 2.2 */

//Obtiene la validación si ya hay acuerdos comerciales para el año de proceso
export async function getAgreementExists({ cancelToken, params }) {
  const baseUrl = `/Dashboards/Api/v1/Catalogs/SalesAgreementExist`;
  return request(baseUrl, { params, method: 'GET', cancelToken });
}

//Obtiene la validación si ya han sido cargados desafíos de venta
export async function getChallengeExists({ cancelToken, params }) {
  const baseUrl = `/Dashboards/Api/v1/Catalogs/SalesAgreementExist`;
  return request(baseUrl, { params, method: 'GET', cancelToken });
}
