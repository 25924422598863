import difference from 'lodash/difference';
import React, { useState, useEffect } from 'react';
import { Transfer, Table } from 'antd';
import tableTransferFilter from '../utils/tableTransferFilter';

export default function BTableTransfer({
  IPPRData,
  setproductsPolicy,
  OutPoliciyProducts,
  InPoliciyProducts,
}) {
  const originTargetKeys = [];
  const leftTableColumns = [
    {
      dataIndex: 'title',
      title: 'Productos dentro de política',
    },
    {
      dataIndex: 'description',
      title: 'Familia',
    },
  ];
  const rightTableColumns = [
    {
      dataIndex: 'title',
      title: 'Productos fuera de política',
    },
    {
      dataIndex: 'description',
      title: 'Familia',
    },
  ];
  const [state, setState] = useState({
    targetKeys: originTargetKeys,
    disabled: false,
    showSearch: true,
  });

  const onChange = (nextTargetKeys) => {
    setState({
      targetKeys: nextTargetKeys,
      disabled: false,
      showSearch: true,
    });
    //Obtener datos DERECHA
    setproductsPolicy(nextTargetKeys);
  };
  const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    <Transfer {...restProps} showSelectAll={true}>
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;

        const rowSelection = {
          getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter((item) => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{ pointerEvents: listDisabled ? 'none' : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
          />
        );
      }}
    </Transfer>
  );
  let mockData = [];
  let mockData1 = InPoliciyProducts?.length > 0 ? InPoliciyProducts : [];
  let mockData2 = OutPoliciyProducts?.length > 0 ? OutPoliciyProducts : [];
  for (var mock of mockData1) {
    mockData.push({
      key: mock.productNumber,
      title: mock.name,
      description: mock.family,
      disabled: false,
      tag: mock.name,
    });
  }

  for (var mock2 of mockData2) {
    mockData.push({
      key: mock2.productNumber,
      title: mock2.name,
      description: mock2.family,
      disabled: false,
      tag: mock2.name,
      chosen: true,
    });
  }

  const defTargetKeys = mockData
    .filter((item) => {
      return item.chosen === true;
    })
    .map((item) => {
      return item.key;
    });

  useEffect(() => {
    setState({
      targetKeys: defTargetKeys,
      disabled: false,
      showSearch: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [InPoliciyProducts, OutPoliciyProducts]);

  return (
    <div>
      <TableTransfer
        dataSource={mockData}
        targetKeys={state.targetKeys}
        disabled={state.disabled}
        showSearch={state.showSearch}
        onChange={onChange}
        filterOption={tableTransferFilter}
        leftColumns={leftTableColumns}
        rightColumns={rightTableColumns}
      />
    </div>
  );
}
