/* eslint-disable no-unused-vars */
import userIs from '../../../../utils/userIs';

export function changeState(form, dataState, changedValues, filterData) {
  const currentChange = Object.keys(changedValues)[0];
  const allValues = form.getFieldsValue();
  function countIndicators() {
    const indicatorsList = [
      dataState.filter[1].indicator1,
      dataState.filter[2].indicator2,
      dataState.filter[3].indicator3,
    ];
    let result = 0;

    indicatorsList.forEach((item) => {
      if (item !== undefined) {
        result++;
      }
    });
    return result;
  }
  let newDataFilter = {};
  switch (currentChange) {
    case 'typeview':
      newDataFilter = {
        typeview: allValues.typeview,
      };
      break;
    case 'indicator1':
      newDataFilter = {
        indicator1: allValues.indicator1,
      };

      break;
    case 'indicator2':
      newDataFilter = {
        indicator2: allValues.indicator2,
      };
      if (countIndicators() === 1) {
        filterData({ year: dataState.year[0].key });
        form.setFieldsValue({ year: dataState.year[0].key });
      }
      break;
    case 'indicator3':
      newDataFilter = {
        indicator3: allValues.indicator3,
      };
      if (countIndicators() === 1) {
        filterData({ year: dataState.year[0].key });
        form.setFieldsValue({ year: dataState.year[0].key });
      }
      break;
    case 'typeinformation':
      newDataFilter = {
        typeinformation: allValues.typeinformation,
      };

      break;
    case 'typeunidad':
      newDataFilter = {
        typeunidad: allValues.typeunidad,
      };
      break;
    case 'typevalue':
      newDataFilter = {
        typevalue: allValues.typevalue,
      };
      break;
    case 'periodo':
      newDataFilter = {
        periodo: allValues.periodo,
      };
      break;
    case 'year':
      if (allValues.year.length === 0) {
        newDataFilter = { year: dataState.year[0].key };
      } else {
        newDataFilter = { year: allValues.year };
      }
      break;
    case 'month':
      newDataFilter = getValueOptions(newDataFilter, allValues.month, dataState.month, 'month');
      break;
    case 'regions':
      newDataFilter = getValueOptions(
        newDataFilter,
        allValues.regions,
        dataState.region,
        'regions'
      );

      break;
    case 'bussinesline':
      newDataFilter = getValueOptions(
        newDataFilter,
        allValues.bussinesline,
        dataState.businessline,
        'bussinesline'
      );
      break;
    case 'subregions':
      newDataFilter = getValueOptions(
        newDataFilter,
        allValues.subregions,
        dataState.subregion,
        'subregions'
      );
      break;
    case 'territory':
      newDataFilter = getValueOptions(
        newDataFilter,
        allValues.territory,
        dataState.territory,
        'territory'
      );
      break;
    case 'zones':
      newDataFilter = getValueOptions(newDataFilter, allValues.zones, dataState.zone, 'zones');
      break;
    case 'typeclient':
      newDataFilter = getValueOptions(
        newDataFilter,
        allValues.typeclient,
        dataState.typeclient,
        'typeclient'
      );

      break;
    case 'client':
      newDataFilter = getValueOptions(newDataFilter, allValues.client, dataState.client, 'client');
      break;
    case 'segmentation':
      newDataFilter = getValueOptions(
        newDataFilter,
        allValues.segmentation,
        dataState.segmentation,
        'segmentation'
      );
      if (newDataFilter.segmentation.length === dataState.segmentation.length) {
        newDataFilter.segmentation = [];
      }
      break;
    case 'politicscp':
      // if (
      //   allValues.politicscp.includes('ALL') ||
      //   allValues.politicscp.length === 0 ||
      //   allValues.politicscp.length === dataState.politicsproduct.length
      // ) {
      //   newDataFilter = { politicscp: null };
      // } else {
      newDataFilter = getValueOptions(
        newDataFilter,
        allValues.politicscp,
        dataState.politicsproduct,
        'politicscp'
      );
      // }

      break;
    case 'availavilityproduct':
      newDataFilter = getValueOptions(
        newDataFilter,
        allValues.availavilityproduct,
        dataState.disponibilidadproduct,
        'availavilityproduct'
      );
      break;
    case 'family':
      newDataFilter = getValueOptions(
        newDataFilter,
        allValues.family,
        dataState.familyproduct,
        'family'
      );

      break;
    case 'product':
      newDataFilter = getValueOptions(
        newDataFilter,
        allValues.product,
        dataState.product,
        'product'
      );
      break;
    case 'tradeAgreement':
      break;
    default:
      break;
  }

  return newDataFilter;
}

function getValueOptions(newDataFilter, allValues, dataState, name) {
  if (allValues[0] === 'ALL') {
    newDataFilter = { [name]: dataState.map((value) => value.key) };
  } else if (allValues.includes('ALL') && allValues.length === dataState.length + 1) {
    newDataFilter = { [name]: [] };
  } else if (allValues.includes('ALL') && allValues.length < dataState.length + 1) {
    newDataFilter = { [name]: dataState.map((value) => value.key) };
  } else {
    newDataFilter = { [name]: allValues.filter((value) => value !== 'ALL') };
  }
  return newDataFilter;
}

export const valuesFilter = (stateData) => {
  let typeUserToRegions = userIs('Gerente de ventas', 'Representante de ventas');
  let typeUserToTerritores = userIs('Representante de ventas');
  const {
    typeclient,
    segmentation,
    region,
    businessline,
    subregion,
    territory,
    zone,
    indicator,
    typeinfo,
    typeUnidad,
    periodo,
    year,
    month,
    client,
    politicsproduct,
    disponibilidadproduct,
    familyproduct,
    product,
  } = stateData;

  return {
    typeview: 1,
    indicator1: 'salesChallenge',
    indicator2: 'agreement',
    indicator3: undefined,
    typeinformation: 1,
    typeunidad: 0,
    periodo: 1,
    year: year[0].key,
    month: [],
    regions: region.map((value) => value.key),
    bussinesline: businessline.map((value) => value.key),
    subregions: typeUserToRegions ? subregion.map((value) => value.key) : [],
    territory: typeUserToTerritores ? territory.map((value) => value.key) : [],
    zones: [],
    typeclient: typeclient.map((value) => value.key),
    client: client.map((value) => value.key),
    clientList: [],
    segmentation: [],
    politicscp: politicsproduct.map((value) => value.key),
    availavilityproduct: disponibilidadproduct.map((value) => value.key),
    family: familyproduct.map((value) => value.key),
    product: product.map((value) => value.key),
    typevalue: 0,
  };
};

export const resetData = (stateData) => {
  let typeUserToRegions = userIs('Gerente de ventas', 'Representante de ventas');
  let typeUserToTerritores = userIs('Representante de ventas');
  const {
    typeclient,
    segmentation,
    region,
    businessline,
    subregion,
    territory,
    zone,
    indicator,
    typeinfo,
    typeUnidad,
    periodo,
    year,
    month,
    client,
    politicsproduct,
    disponibilidadproduct,
    familyproduct,
    product,
  } = stateData;

  return [
    { typeview: 1 },
    { indicator1: 'salesChallenge' },
    { indicator2: 'agreement' },
    { indicator3: undefined },
    { typeinformation: 1 },
    { typeunidad: 0 },
    { periodo: 1 },
    { year: year[0].key },
    { month: [] },
    { regions: region.map((value) => value.key) },
    { bussinesline: businessline.map((value) => value.key) },
    { subregions: typeUserToRegions ? subregion.map((value) => value.key) : [] },
    { territory: typeUserToTerritores ? territory.map((value) => value.key) : [] },
    { zones: [] },
    { typeclient: typeclient.map((value) => value.key) },
    { client: client.map((value) => value.key) },
    { clientList: [] },
    { segmentation: [] },
    { politicscp: null },
    { availavilityproduct: disponibilidadproduct.map((value) => value.key) },
    { family: familyproduct.map((value) => value.key) },
    { product: product.map((value) => value.key) },
    { typevalue: 0 },
  ];
};
