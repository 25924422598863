import {
  SALES_CHALLENGE_VALIDATOR_CONFIRM_REQUEST,
  SALES_CHALLENGE_VALIDATOR_CONFIRM_SUCCESS,
  SALES_CHALLENGE_VALIDATOR_CONFIRM_FAIL,
} from '../actions/salesChallengeValidatorConfirm';

const initialState = {
  isSalesChallengeValidator: false,
  isLoading: false,
};

const salesChallengeValidatorConfirm = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SALES_CHALLENGE_VALIDATOR_CONFIRM_REQUEST:
      return {
        ...state,
        isSalesChallengeValidator: false,
        isLoading: true,
      };

    case SALES_CHALLENGE_VALIDATOR_CONFIRM_SUCCESS:
      return {
        ...state,
        isSalesChallengeValidator: payload,
        isLoading: false,
      };
    case SALES_CHALLENGE_VALIDATOR_CONFIRM_FAIL:
      return {
        ...state,
        isSalesChallengeValidator: false,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default salesChallengeValidatorConfirm;
