export const DISCOUNTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD =
  'DISCOUNTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const DISCOUNTS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'DISCOUNTS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const DISCOUNTS_MASSIVE_UPLOAD_REQUEST_VERIFY = 'DISCOUNTS_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const DISCOUNTS_MASSIVE_UPLOAD_SET_VERIFY = 'DISCOUNTS_MASSIVE_UPLOAD_SET_VERIFY';
export const DISCOUNTS_MASSIVE_UPLOAD_CLEAR_VERIFY = 'DISCOUNTS_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const DISCOUNTS_MASSIVE_UPLOAD_REQUEST_UPLOAD = 'DISCOUNTS_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const DISCOUNTS_MASSIVE_UPLOAD_SET_UPLOAD = 'DISCOUNTS_MASSIVE_UPLOAD_SET_UPLOAD';
export const DISCOUNTS_MASSIVE_UPLOAD_CLEAR_UPLOAD = 'DISCOUNTS_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function discountsMassiveUploadSetAllowUpload(payload) {
  return {
    type: DISCOUNTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function discountsMassiveUploadClearAllowUpload(payload) {
  return {
    type: DISCOUNTS_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function discountsMassiveUploadRequestVerify(payload) {
  return {
    type: DISCOUNTS_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function discountsMassiveUploadSetVerify(payload) {
  return {
    type: DISCOUNTS_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function discountsMassiveUploadClearVerify(payload) {
  return {
    type: DISCOUNTS_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function discountsMassiveUploadRequestUpload(payload) {
  return {
    type: DISCOUNTS_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function discountsMassiveUploadSetUpload(payload) {
  return {
    type: DISCOUNTS_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function discountsMassiveUploadClearUpload(payload) {
  return {
    type: DISCOUNTS_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
