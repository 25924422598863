import React, { useState } from 'react';
import BButton from 'common/components/BButton';
import { Row, Col, Modal } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { saveVolume } from 'SalesChallengeRefactor/redux/actions/table';
import { sendToValidateRequest } from 'SalesChallengeRefactor/redux/actions/challenge';
import { MSG_036 } from 'SalesChallengeRefactor/utils/messages';
import HistoryAction from 'SalesChallengeRefactor/components/HistoryAction';
import './styles.css';

function FooterActions(props) {
  const {
    subscriptions,
    productLabel,
    isPosting,
    goal,
    itCanSave,
    saveVolume: volumeSave,
    sendToValidateRequest: sendToValidate,
    query,
    dataMesagge,
  } = props;
  const history = useHistory();
  const { zone, client, product } = query;
  const [showMessage, setShowMessage] = useState(false);

  const handleOnMouseEnter = () => {
    setShowMessage(true);
  };

  const handleOnMouseFalse = () => {
    setShowMessage(false);
  };

  const redirectToConsult = () => {
    history.push('/sales-challenge');
  };

  const handleOnSave = () => {
    const onSuccess = () => {
      redirectToConsult();
    };
    let clientData = dataMesagge?.clients?.data.find((item) => item.key === query.client);
    let zoneData = dataMesagge?.zones?.data.find((item) => item.key === query.zoneId);

    const message = MSG_036({
      zone: zoneData.value,
      client: clientData.value,
      product: productLabel,
    });
    volumeSave({ data: { whoSaveChallenge: 'SAVE_BUTTON' }, onSuccess: onSuccess, message });
  };

  const onSuccessValidate = () => {
    redirectToConsult();
  };

  const handleSendToValidate = () => {
    if (query) {
      let params = {
        zoneId: zone,
        clientId: client,
        productId: product,
      };

      Modal.confirm({
        title: 'Enviar desafio de ventas',
        content: '¿Está seguro que desea enviar el desafío de ventas a validación?',
        okText: 'Aceptar',
        cancelText: 'Cancelar',
        centered: true,
        okButtonProps: { type: 'info', style: { backgroundColor: '#003d81', color: '#ffffff' } },
        cancelButtonProps: {
          type: 'default',
          style: { backgroundColor: '#0078B3', color: '#ffffff' },
        },
        onOk() {
          sendToValidate({ params, onSuccess: onSuccessValidate });
        },
      });
    }
  };

  const handleCancelButton = () => {
    redirectToConsult();
  };

  return (
    <Row gutter={6} justify="end" style={{ padding: 5 }}>
      <Col span={2}>
        <HistoryAction year={subscriptions?.year} />
      </Col>
      <Col span={2}>
        <BButton
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 40,
            margin: '0 auto',
          }}
          loading={isPosting}
          icon={<SaveOutlined style={{ color: itCanSave ? 'white' : '#00000040' }} />}
          block
          disabled={!itCanSave}
          onClick={handleOnSave}
        />
      </Col>

      <Col span={10}>
        <BButton block label="Cancelar" onClick={handleCancelButton} style={{ height: 40 }} />
      </Col>

      <Col span={10}>
        {showMessage && goal < 90 ? (
          <div onMouseLeave={handleOnMouseFalse} className="button-message transition">
            Activado al llenar el 90%
          </div>
        ) : (
          <div onMouseEnter={handleOnMouseEnter}>
            <BButton
              className="button-send transition"
              type="primary"
              block
              label="Enviar"
              onClick={handleSendToValidate}
              disabled={goal < 90 || !itCanSave}
              style={{ height: 40 }}
            />
          </div>
        )}
      </Col>
    </Row>
  );
}

function mapStateToProps(state) {
  return {
    isPosting: state?.SALES_CHALLENGE?.registerSales?.savingChallenge,
    goal: state?.SALES_CHALLENGE?.graph?.salesGoal?.data ?? 0,
    itCanSave: state?.SALES_CHALLENGE?.registerSales?.volumesValidsToSave ?? false,
    query: state?.SALES_CHALLENGE?.query ?? false,
    clientLabel: state?.SALES_CHALLENGE?.messages?.clientLabel,
    productLabel: state?.SALES_CHALLENGE?.messages?.productLabel,
    subscriptions: state?.SALES_CHALLENGE?.subscriptions?.register,
    dataMesagge: state?.APP?.catalogs,
  };
}

export default connect(mapStateToProps, { saveVolume, sendToValidateRequest })(FooterActions);
