import request from 'services/request';
const serviceURL = '/Catalogs/Api/v1/Prices/Detail/';

export async function postOneDetailPrices({ cancelToken, data }) {
  return request(`${serviceURL}`, {
    method: 'POST',
    cancelToken,
    data,
  });
}

export async function getCheckExistence({ cancelToken, priceId }) {
  return request(`${serviceURL}${priceId}`, {
    method: 'GET',
    cancelToken,
  });
}
