export const Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD = 'Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD';
export const Dynamic_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD =
  'Dynamic_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD';

export const Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY = 'Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY';
export const Dynamic_MASSIVE_UPLOAD_SET_VERIFY = 'Dynamic_MASSIVE_UPLOAD_SET_VERIFY';
export const Dynamic_MASSIVE_UPLOAD_CLEAR_VERIFY = 'Dynamic_MASSIVE_UPLOAD_CLEAR_VERIFY';

export const Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD = 'Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD';
export const Dynamic_MASSIVE_UPLOAD_SET_UPLOAD = 'Dynamic_MASSIVE_UPLOAD_SET_UPLOAD';
export const Dynamic_MASSIVE_UPLOAD_CLEAR_UPLOAD = 'Dynamic_MASSIVE_UPLOAD_CLEAR_UPLOAD';

export function DynamicMassiveUploadSetAllowUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
    payload: payload,
  };
}

export function DynamicMassiveUploadClearAllowUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_CLEAR_ALLOW_UPLOAD,
  };
}

export function DynamicMassiveUploadRequestVerify(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_REQUEST_VERIFY,
    payload: payload,
  };
}

export function DynamicMassiveUploadSetVerify(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_SET_VERIFY,
    payload: payload,
  };
}

export function DynamicMassiveUploadClearVerify(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_CLEAR_VERIFY,
  };
}

export function DynamicMassiveUploadRequestUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_REQUEST_UPLOAD,
    payload: payload,
  };
}

export function DynamicMassiveUploadSetUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_SET_UPLOAD,
    payload: payload,
  };
}

export function DynamicMassiveUploadClearUpload(payload) {
  return {
    type: Dynamic_MASSIVE_UPLOAD_CLEAR_UPLOAD,
  };
}
