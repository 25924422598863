import React, { useState } from 'react';
import { Modal } from 'antd';
import { IconsMap } from 'Layout/utils';
import { useHistory } from 'react-router-dom';
import { SubRegion } from 'RootModule/catalogs';
const ExclamationIcon = IconsMap.ExclamationCircleOutlined;

const ModalSelector = ({ setselectSubregion, selectSubregion }) => {
  const history = useHistory();
  const [modalIsVisible, setModalIsVisible] = useState(true);

  const handleModalOnOK = () => {
    setModalIsVisible(false);
  };

  // #Cambiar URL
  const cancelModal = () => {
    history.push('/catalog/trade-agreement-info');
  };

  const changeSubRegion = (e, i) => {
    setselectSubregion(e);
  };
  return (
    <Modal
      closable={() => cancelModal()}
      visible={modalIsVisible}
      destroyOnClose
      okButtonProps={{ disabled: !selectSubregion }}
      onCancel={() => cancelModal()}
      maskClosable={false}
      onOk={handleModalOnOK}
    >
      <div className="header-modal">
        <ExclamationIcon style={{ fontSize: '1.5rem', alignSelf: 'center' }} />
        <p>Seleccionar subregión</p>
      </div>
      <p>Antes de realizar la carga masiva favor de seleccionar la subregión</p>
      <SubRegion
        isRequired
        name="subregionId"
        onChange={(e, i) => changeSubRegion(e, i)}
        // requestParams={dataCE.lineId ? { BusinessLines: dataCE.lineId } : null}
      />
    </Modal>
  );
};

export { ModalSelector };
