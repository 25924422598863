import React from 'react';
import { Modal } from 'antd';

const actionButtonsStyle = {
  margin: '30px -30% 16px',
  color: 'white',
  borderRadius: '5px',
  width: '220px',
  height: '35px',
  fontSize: '1.1em',
  border: 'none',
  fontWeight: '600',
};

function summaryEndModal(response, link, url, message) {
  const onOkButton = (history, urlToRedirect) => {
    const { listErrors } = response;
    let downloadLink = '';
    setTimeout(() => {
      if ((url === '/trade-agreements' || url === '/sales-challenge') && listErrors.length > 0) {
        if (url === '/trade-agreements') {
          downloadLink =
            'MASM_Agroplan_AN-110 Registro masivo de acuerdos comerciales_Descripción de errores';
        } else if (url === '/sales-challenge') {
          downloadLink =
            'MASM_Agroplan_AN-110 Registro masivo de desafio de ventas_Descripción de errores';
        }

        if (listErrors.length > 0) {
          const { objectList } = response;
          const anchor = document.createElement('a');
          anchor.href = `data:application/pdf;base64,${objectList}`;
          anchor.download = `${downloadLink}.xlsx`;
          anchor.click();
        }
      }
    }, 1000);

    history.push(urlToRedirect);
  };
  Modal.info({
    title: 'Resumen de registros realizados',
    content: (
      <div>
        {!message ? (
          <p>
            Se realizaron {response.correct} registros exitosamente y se rechazaron{' '}
            {response.totalErrors} registros
          </p>
        ) : (
          <p> {message} </p>
        )}
      </div>
    ),
    okText: 'Aceptar',
    className: 'summaryModal',
    onOk() {
      onOkButton(link, url);
    },
    okButtonProps: { size: 'middle', type: 'primary', style: actionButtonsStyle },
  });
}
export default summaryEndModal;
