import { takeLatest, put, call, cancelled, select, delay } from 'redux-saga/effects';
import instance from 'services/request';

import {
  UPLOAD_TRADE_REQUEST,
  GET_FILES_REQUEST,
  getFilesRequest,
  getFilesSuccess,
  getFilesFail,
  REMOVE_FILE_REQUEST,
  removeFileSuccess,
  removeFileFail,
  DOWNLOAD_FILE_REQUEST,
  downloadFileFail,
  downloadFileSuccess,
  SEND_TRADE_AGREEMENT_REQUEST,
  sendTradeFail,
  sendTradeSuccess,
} from 'TradeAgreements/redux/actions/uploadTradeAgreements';
import {
  getFilesTradeAgreements,
  uploadTradeAgreementPDF,
  getDownloadTrade,
  deleteTradeAgreementPDF,
  sendTrade,
} from 'TradeAgreements/services/uploadTradeAgreement';
import { responseOK } from 'TradeAgreements/utils';
import { downloadFile } from 'helpers/downloadHelper';

function* workerGetFiles(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const { params, onSuccess } = payload;
    const response = yield call(getFilesTradeAgreements, { params, cancelToken: source.token });
    if (responseOK(response)) {
      yield put(getFilesSuccess(response.data));
      if (onSuccess) {
        yield onSuccess();
      }
    } else {
      yield put(getFilesFail());
    }
  } catch (_e) {
    console.error('SAGA CATCH ', _e);
    yield put(getFilesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchPostAgreements() {
  yield takeLatest(GET_FILES_REQUEST, workerGetFiles);
}

function* workerUploadFile(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;

    const { data, onSuccess } = payload;
    const response = yield call(uploadTradeAgreementPDF, {
      data,
      cancelToken: source.token,
    });
    if (responseOK(response)) {
      yield put(getFilesSuccess(response.data));
      //
      let query = yield select((state) => state?.TRADE_AGREEMENTS?.query);
      const { clientId, territoryId, year } = query;
      let paramsFiles = {
        clientId,
        territoryId,
        year,
      };
      yield put(
        getFilesRequest({
          params: paramsFiles,
        })
      );

      if (onSuccess) {
        yield delay(1000);
        yield onSuccess(response.message);
      }
    } else {
      yield put(getFilesFail());
    }
  } catch (_e) {
    console.error('SAGA CATCH ', _e);
    yield put(getFilesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchUploadFile() {
  yield takeLatest(UPLOAD_TRADE_REQUEST, workerUploadFile);
}

function* workerDownloadFile(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { onSuccess, params } = payload;

    const response = yield call(getDownloadTrade, { params, cancelToken: source.token });

    if (response) {
      if (typeof response !== 'undefined') {
        if (onSuccess) {
          yield onSuccess('La descarga sé realizó correctamente');
        }
        yield put(downloadFileSuccess());
        yield downloadFile(response, `${params.name}`);
        return response;
      }
    } else {
      yield put(downloadFileFail());
    }
  } catch (error) {
    console.error('Error', error);
    yield put(downloadFileFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchDownloadFile() {
  yield takeLatest(DOWNLOAD_FILE_REQUEST, workerDownloadFile);
}

function* workerRemoveFile(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { onSuccess, params } = payload;

    const response = yield call(deleteTradeAgreementPDF, { params, cancelToken: source.token });

    if (response) {
      if (typeof response !== 'undefined') {
        if (onSuccess) {
          yield onSuccess(response.message);
        }
        yield put(removeFileSuccess());

        return response;
      }
    } else {
      yield put(removeFileFail());
    }
  } catch (e) {
    console.error('Error', e);
    yield put(removeFileFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchRemoveFile() {
  yield takeLatest(REMOVE_FILE_REQUEST, workerRemoveFile);
}

function* workerSendTrade(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { onSuccess, params } = payload;
    const response = yield call(sendTrade, { params, cancelToken: source.token });
    if (response) {
      if (typeof response !== 'undefined') {
        if (onSuccess) {
          yield onSuccess(response.message);
        }
        yield put(sendTradeSuccess());
        return response;
      }
    } else {
      yield put(sendTradeFail());
    }
  } catch (e) {
    console.error('Error', e);
    yield put(sendTradeFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchSendTrade() {
  yield takeLatest(SEND_TRADE_AGREEMENT_REQUEST, workerSendTrade);
}
