import React, { useEffect } from 'react';
import { BSelect } from 'common';
import { Row, Col, Form, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import '../css/style.css';
import SectionTransfer from './SectionTransfer';

const ClientsInformation = ({
  businessLines,
  subRegions,
  form,
  clientData,
  getClient,
  setTargetKeys,
  targetKeys,
  setParamsClient,
  paramsClient,
}) => {
  const { Item } = Form;

  useEffect(() => {
    if (Array.isArray(clientData)) {
      const initialTargetKeys = clientData
        ?.filter((item) => item.isManual === true)
        .map((item) => item.key);
      setTargetKeys(initialTargetKeys);
    }
  }, [clientData, setTargetKeys]);

  const handleChange = (value, name, options) => {
    if (value?.length) {
      if (value.includes('ALL')) {
        if (value.length === options.length + 1) {
          form.setFieldsValue({ [name]: [] });
        } else {
          value = options.map((option) => {
            return option.key;
          });
          form.setFieldsValue({
            [name]: value,
          });
        }
      }
    }
  };

  const handleReset = () => {
    form.setFieldsValue({
      businessLine: undefined,
      subregion: undefined,
    });
    getClient({ params: {} });
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <Item label="Línea de negocio" name="businessLine">
            <BSelect
              mode={'multiple'}
              options={businessLines}
              placeholder="Selecionar"
              name={'businessLine'}
              label={''}
              onChange={(data) => handleChange(data, 'businessLine', businessLines)}
              selectAll={true}
              maxTagCount={3}
              allowClear={false}
            />
          </Item>
        </Col>

        <Col span={6}>
          <Item label="Subregión" name="subregion">
            <BSelect
              mode={'multiple'}
              options={subRegions}
              placeholder="Selecionar"
              onChange={(data) => handleChange(data, 'subregion', subRegions)}
              name={'subregion'}
              label={''}
              selectAll={true}
              maxTagCount={3}
              allowClear={false}
            />
          </Item>
        </Col>
        <Col span={6}>
          <Button
            icon={<ReloadOutlined />}
            className="reset-btn"
            onClick={() => {
              handleReset();
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <SectionTransfer
            dataSource={Array.isArray(clientData) ? clientData : []}
            targetKeys={targetKeys}
            onChange={(nextTargetKeys) => {
              setTargetKeys(nextTargetKeys);
              let inactiveClient = clientData
                .filter((d, index) => nextTargetKeys.includes(index))
                .map((d) => ({
                  clientId: d.clientId,
                  subRegionId: d.subRegionId,
                  lineId: d.lineId,
                }));
              let activeClient = clientData
                .filter((d, index) => !nextTargetKeys.includes(index))
                .map((d) => ({
                  clientId: d.clientId,
                  subRegionId: d.subRegionId,
                  lineId: d.lineId,
                }));

              setParamsClient({
                ...paramsClient,
                inactiveClients: inactiveClient,
                activeClients: activeClient,
              });
            }}
            showSearch={true}
            pagination={true}
            // listStyle={{ width: '42%' }}
            leftColumns={[
              {
                dataIndex: 'name',
                title: 'Clientes',
                // width: 250,
              },
              {
                dataIndex: 'line',
                title: 'Línea',
                //width: 380,
              },
            ]}
            rightColumns={[
              {
                dataIndex: 'name',
                title: 'Clientes',
                // with: 250,
              },
              {
                dataIndex: 'line',
                title: 'Línea',
                // width: 380,
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default ClientsInformation;
