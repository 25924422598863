import React from 'react';
import PropTypes from 'prop-types';

function RemainingDays(props) {
  const { days } = props;

  return (
    <>
      <h4 className="text-right">
        Quedan <span style={{ color: 'var(--error-color)', fontWeight: 700 }}> {days} </span> días
      </h4>
    </>
  );
}

RemainingDays.propTypes = {
  days: PropTypes.number,
};

RemainingDays.defaultProps = {
  days: '',
};

export default RemainingDays;
