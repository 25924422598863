/* eslint-disable no-unused-vars */
import { request } from '../index';
import axios from 'axios';
import { formatToPrice } from '../../utils';
import { openNotification } from '../../common';

const API_REPORTS = process.env.REACT_APP_API_REPORTS;

// docs https://developapi.agroplanap.com/swagger/index.html?urls.primaryName=Microservices%20CatalogService
export async function getRegions() {
  const url = '/Dashboards/Api/v1/Catalogs/Regions';
  const errorMessage = 'Error en la operación';
  return request
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      openNotification('error', errorMessage);
    });
}

// docs https://developapi.agroplanap.com/swagger/index.html?urls.primaryName=Microservices%20CatalogService
export async function getBusinessLines() {
  const url = '/Dashboards/Api/v1/Catalogs/Lines';
  const errorMessage = 'Error en la operación';
  return request
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      openNotification('error', errorMessage);
    });
}

// docs https://developapi.agroplanap.com/swagger/index.html?urls.primaryName=Microservices%20CatalogService
export async function getSubregions() {
  const url = '/Dashboards/Api/v1/Catalogs/Subregions';
  const errorMessage = 'Error en la operación';
  return request
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      openNotification('error', errorMessage);
    });
}

// docs https://developapi.agroplanap.com/swagger/index.html?urls.primaryName=Microservices%20CatalogService
export async function getAreas() {
  const url = '/Dashboards/Api/v1/Catalogs/Zones';
  const errorMessage = 'Error en la operación';
  return request
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      openNotification('error', errorMessage);
    });
}

// docs https://developapi.agroplanap.com/swagger/index.html?urls.primaryName=Microservices%20CatalogService
export async function getTerritories() {
  const url = '/Dashboards/Api/v1/Catalogs/Territories';
  const errorMessage = 'Error en la operación';
  return request
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      openNotification('error', errorMessage);
    });
}

// docs https://developapi.agroplanap.com/swagger/index.html?urls.primaryName=Microservices%20CatalogService
export async function getZones() {
  const url = '/Dashboards/Api/v1/Catalogs/Zones';
  const errorMessage = 'Error en la operación';
  return request
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      openNotification('error', errorMessage);
    });
}

// docs https://developapi.agroplanap.com/swagger/index.html?urls.primaryName=Microservices%20CatalogService
export async function getSalesStatus() {
  const url = '/Catalogs/Api/v1/FixedCatalogs/SalesStatus';
  const errorMessage = 'Error en la operación';
  return request
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      openNotification('error', errorMessage);
    });
}

// docs https://developapi.agroplanap.com/swagger/index.html?urls.primaryName=Microservices%20DashboardsService
export const getStatusCount = async (query) => {
  query = query.replace('?challengeData=1', '');
  query = query.replace('?challengeData=2', '');
  query = query.replace('challengeData=1', '');
  query = query.replace('challengeData=2', '');
  query = query ? query : '';
  if (query === '') {
    return [];
  }
  const url = '/Dashboards/Api/v1/Challenge/Count';
  const errorMessage = 'Error en la operación';
  return request
    .get(url + query)
    .then((res) => res.data)
    .catch((e) => {
      openNotification('error', errorMessage);
    });
};

export const getFileCount = async (query) => {
  query = query ? query : '';
  const url = '/Dashboards/Api/v1/Challenge/FileCount' + query;
  const errorMessage = 'Error en la operación';
  return request
    .get(url)
    .then((res) => {
      if (res.data.length > 0) {
        return res.data;
      }
      openNotification('error', errorMessage);
      return undefined;
    })
    .catch((e) => {
      openNotification('error', errorMessage);
    });
};

// docs https://developapi.agroplanap.com/swagger/index.html?urls.primaryName=Microservices%20DashboardsService
export const getStatusReport = async (query) => {
  query = query.replace('?challengeData=1', '');
  query = query.replace('?challengeData=2', '');
  query = query.replace('challengeData=1', '');
  query = query.replace('challengeData=2', '');

  const emptyNotification = () => {
    openNotification(
      'error',
      'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados.'
    );
  };
  // query = query ? query : '';
  if (query === '') {
    return [];
  }
  const url = '/Dashboards/Api/v1/Challenge/Resume';
  return request
    .get(url + query)
    .then((res) => {
      res.data.rows.forEach((item) => {
        item.challengeAmount = formatToPrice(item.challengeAmount, true);
        item.salesGoalAmount = formatToPrice(item.salesGoalAmount, true);
        item.salesGoalVolume = formatToPrice(item.salesGoalVolume);
        item.challengeSalesGoalAmount = formatToPrice(item.challengeSalesGoalAmount, true);
        item.challengeSalesGoalVolume = formatToPrice(item.challengeSalesGoalVolume);
        item.percChallengeSalesAmount = formatToPrice(item.percChallengeSalesAmount);
        item.percChallengeSalesGoalAmount = formatToPrice(item.percChallengeSalesGoalAmount);
        item.percChallengeSalesGoalVolume = formatToPrice(item.percChallengeSalesGoalVolume);
        item.percChallengeSalesVolume = formatToPrice(item.percChallengeSalesVolume);
        item.salesAmount = formatToPrice(item.salesAmount, true);
        item.salesVolume = formatToPrice(item.salesVolume);
        item.challengeVolume = formatToPrice(item.challengeVolume);
        item.challengeSalesAmount = formatToPrice(item.challengeSalesAmount, true);
        item.challengeSalesVolume = formatToPrice(item.challengeSalesVolume);
      });
      return res.data;
    })
    .catch(() => {
      return [];
    });
};

export const getFileResume = async (query) => {
  query = query ? query : '';
  const url = `/Dashboards/Api/v1/Challenge/FileResume${query}`;
  return request
    .get(url)
    .then((res) => res.data)
    .catch((e) => {
      openNotification(
        'error',
        'El sistema no pudo generar el reporte, inténtelo de nuevo más tarde'
      );
    });
};

export const downloadResumePDF = async (query) => {
  // eslint-disable-next-line no-extend-native
  Array.prototype.remove = function () {
    let what,
      a = arguments,
      L = a.length,
      ax;
    while (L && this.length) {
      what = a[--L];
      while ((ax = this.indexOf(what)) !== -1) {
        this.splice(ax, 1);
      }
    }
    return this;
  };
  let callInstance = axios.create({
    baseURL: `${API_REPORTS}Reports/Api/v1`,
    Accept: 'application/json',
    AccessControlAllowOrigin: '*/*',
  });
  callInstance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  /*const requestBody = {
    status: payload.Status ? payload.Status.remove(FILTERS.all) : [],
        regions: payload.Regions ? payload.Regions.remove(FILTERS.all) : [],
        lines: payload.Lines ? payload.Lines.remove(FILTERS.all) : [],
        subregions: payload.Subregions ? payload.Subregions.remove(FILTERS.all) : [],
        territories: payload.Territories ? payload.Territories.remove(FILTERS.all) : [],
        zones: payload.Zones ? payload.Zones.remove(FILTERS.all) : [],
        challengeData: payload.challengeData[0] ? payload.challengeData[0] : ''
      };*/
  return callInstance
    .get('/Dashboards/Challenge' + query)
    .then((res) => res.data)
    .catch((e) => {
      openNotification(
        'error',
        'El sistema no pudo generar el reporte, inténtelo de nuevo más tarde'
      );
    });
};

export const base64ToFile = (base64, fileType, filename) => {
  const linkSource = `${
    fileType === catalogFiles.pdf
      ? 'data:application/octet-stream;base64'
      : 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
  },${base64}`;
  const downloadLink = document.createElement('a');
  const date = new Date();
  const dateFormatted =
    date.getDate() +
    '_' +
    (parseInt(date.getMonth().toString()) + 1 > 9 ? '' : '0') +
    (parseInt(date.getMonth().toString()) + 1) +
    '_' +
    date.getFullYear();
  const fileName = `${filename} ${dateFormatted}.${fileType}`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  openNotification(
    'success',
    'La descarga de información de tabla dashboard se realizó correctamente'
  );
};

export const catalogFiles = {
  excel: 'xlsx',
  pdf: 'pdf',
};

export const getExistance = async (type) => {
  const url = `/Dashboards/Api/v1/Catalogs/${type}`;
  const errorMessage = 'Error en la operación';
  return request
    .get(url)
    .then((res) => {
      if (res) {
        return res.data;
      }
      openNotification('error', errorMessage);
      return undefined;
    })
    .catch((e) => {
      openNotification('error', errorMessage);
    });
};

export const getUserRol = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user.role;
};
