import React from 'react';
import { Row } from 'antd';
import { BInput } from 'common/components';

const Signer = ({ name, jobTitle, rowProps }) => {
  return (
    <Row {...rowProps}>
      <BInput label="Nombre completo del firmante" disabled value={name} />
      <BInput label="Posición" disabled value={jobTitle} />
    </Row>
  );
};

export default Signer;
