import React from 'react';
import { Row, Col, DatePicker, InputNumber, Form, Input } from 'antd';
import { formatFunc, parserFunc } from '../../Update/helpers';

const LineCreditOccupancySection = ({ getDetailDynamic, getCreditLineSection }) => {
  let date = getCreditLineSection?.date;

  const { TextArea } = Input;

  const currencyFormat = (val) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    });
    return `${formatter.format(val)}`;
  };

  return (
    <div>
      <Row>
        <Col span={12}>
          <Form.Item name="creditLineDate" label="Fecha">
            <DatePicker
              className="form-add-user-input"
              label={'Fecha'}
              disabled={true}
              defaultValue={date}
              placeholder={date}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="introduction" label="Introducción:">
            <TextArea
              placeholder={getCreditLineSection?.introduction}
              defaultValue={getCreditLineSection?.introduction}
              disabled={true}
              autoSize
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Form.Item name="creditLine" label="Línea de crédito:">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              className="form-add-user-input"
              max={999999.99}
              min={0}
              step="0.01"
              maxLength={14}
              formatter={formatFunc}
              parser={parserFunc}
              disabled={true}
              placeholder={
                getCreditLineSection?.creditLine
                  ? currencyFormat(getCreditLineSection.creditLine)
                  : ''
              }
              defaultValue={getCreditLineSection?.creditLine ? getCreditLineSection.creditLine : ''}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="balanceDue" label="Saldo Vencido">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              className="form-add-user-input"
              name="balanceDue"
              max={999999.99}
              min={0}
              step="0.01"
              maxLength={14}
              formatter={formatFunc}
              parser={parserFunc}
              disabled={true}
              placeholder={
                getCreditLineSection?.expiredAmount
                  ? currencyFormat(getCreditLineSection.expiredAmount)
                  : ''
              }
              defaultValue={getCreditLineSection?.expiredAmount}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Saldo no vencido">
            <InputNumber
              style={{ border: '1px solid #d9d9d9' }}
              className="form-add-user-input"
              name="balanceNotDue"
              max={999999.99}
              min={0}
              step="0.01"
              maxLength={14}
              placeholder={
                getCreditLineSection?.validAmount
                  ? currencyFormat(getCreditLineSection.validAmount)
                  : ''
              }
              formatter={formatFunc}
              parser={parserFunc}
              disabled={true}
              defaultValue={getCreditLineSection?.validAmount}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="denomination" label="Denominación:">
            <TextArea
              placeholder={getCreditLineSection?.currencyName}
              defaultValue={getCreditLineSection?.currencyName}
              disabled={true}
              autoSize
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="note" label="Nota:">
            <TextArea
              placeholder={getCreditLineSection?.note}
              defaultValue={getCreditLineSection?.note}
              disabled={true}
              autoSize
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default LineCreditOccupancySection;
