import { takeEvery, takeLatest, all, call, put, cancelled } from 'redux-saga/effects';

import {
  USERS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  USERS_MASSIVE_UPLOAD_REQUEST_VERIFY,
  USERS_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  usersMassiveUploadSetAllowUpload,
  usersMassiveUploadClearAllowUpload,
  usersMassiveUploadSetVerify,
  usersMassiveUploadClearVerify,
  usersMassiveUploadClearUpload,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'SalesChallengeRefactor/utils';

import { usersMassiveUpload } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workUsersMassiveUploadSetAllowUpload(action) {
  try {
    yield usersMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield usersMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield usersMassiveUploadClearAllowUpload();
    }
  }
}

function* workUsersMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de Usuarios debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(usersMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;

      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(usersMassiveUploadSetVerify(data));
      yield call(summaryModal, message);
    } else {
      yield put(usersMassiveUploadClearVerify());
    }
  } catch (error) {
    yield usersMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield usersMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workUsersMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, overwrite } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(usersMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite,
      },
    });
    if (responseOK(response)) {
      yield call(summaryEndModal, response.data, history, '/users');
    } else {
      yield put(usersMassiveUploadClearUpload());
    }
  } catch (error) {
    yield usersMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield usersMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchUsersMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(USERS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD, workUsersMassiveUploadSetAllowUpload),
  ]);
}

export function* watchUsersMassiveUploadRequestVerify() {
  yield all([takeEvery(USERS_MASSIVE_UPLOAD_REQUEST_VERIFY, workUsersMassiveUploadRequestVerify)]);
}

export function* watchUsersMassiveUploadRequestUpload() {
  yield takeLatest(USERS_MASSIVE_UPLOAD_REQUEST_UPLOAD, workUsersMassiveUploadRequestUpload);
}
