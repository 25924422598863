const ApiConstants = {
  subRegions: '/Catalogs/Api/v1/FixedCatalogs/Subregions',
  businessLines: '/Catalogs/Api/v1/FixedCatalogs/BusinessLines',
  urlMonth: '/AccountStatement/Api/v1/Catalogs/Months',
  urlFrequency: '/AccountStatement/Api/v1/Catalogs/TypePeriod',
  urlPeriodDelivery: '/AccountStatement/Api/v1/Catalogs/PeriodDelivery',
  urlStatus: '/AccountStatement/Api/v1/ScheduleStatements',
  urlRegister: '/AccountStatement/Api/v1/ScheduleStatements',
  urlClient: '/AccountStatement/Api/v1/ScheduleStatements/Clients',
  urlChangeStatus: '/AccountStatement/Api/v1/ScheduleStatements',
  urlDeletePeriod: '/AccountStatement/Api/v1/ScheduleStatements',
  urlStatusDynamicsElment: '/AccountStatement/Api/v1/ScheduleStatements/ExistsStatementsActives',
};

export default ApiConstants;
