import React from 'react';
import { Button } from 'antd';
import './PDFMapsButton.css';

const buttonStyle = {
  width: '24%',
  fontSize: '1.2em',
  height: 'calc(16px + 1.2em)',
  fontWeight: 600,
  marginTop: '15px',
};

const PDFMapsButton = () => {
  function printClick() {
    const titleList = document.getElementsByTagName('title');
    const title = titleList[0];
    const originalTitle = `${title.innerText}`;
    const date = new Date();
    let formatted_date =
      date.getDate().toString().padStart(2, '0') +
      '/' +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '/' +
      date.getFullYear();

    title.innerText = `Dashboard Mapa ${formatted_date}`;
    window.print();
    title.innerText = originalTitle;
  }

  return (
    <>
      <Button style={buttonStyle} type="primary" onClick={printClick}>
        Imprimir PDF
      </Button>
    </>
  );
};

export default PDFMapsButton;
