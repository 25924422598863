import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import React from 'react';
import './massiveUploadTableColumns.css';

const messageFaseOne = (
  <>
    <p>
      Registros para los cuales se omitió ingresar campos obligatorios,
      <br />
      se ingresó un tipo de dato que no corresponde al solicitado
      <br />
      en el campo o se excedió la longitud máxima.
    </p>
  </>
);
const messageFaseTwo = (
  <>
    <p>
      Registros de lista de precios para los cuales se ingresó información
      <br />
      de elementos con estatus &quot;Inactivo&quot;, que no se encuentran
      <br />
      registrados en el sistema, se ingresó información de estructura comercial que no está
      relacionada entre sí o con el resto de los elementos.
      <br />
    </p>
  </>
);
const messageFaseThree = (
  <>
    <p>
      Registros que se encuentran repetidos dentro del archivo
      <br />
      de carga masiva.
    </p>
  </>
);
const errorMessages = [
  {
    fase: 'Fase 1',
    message: messageFaseOne,
  },
  {
    fase: 'Fase 2',
    message: messageFaseTwo,
  },
  {
    fase: 'Fase 3',
    message: messageFaseThree,
  },
];

export const tableColumnsCAC = [
  {
    key: 'phase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'phase',
    className: 'title-masive',
  },
  {
    key: 'businessLine',
    title: 'Línea de negocio',
    dataIndex: 'line',
  },
  {
    title: 'Sold to',
    dataIndex: 'soldTo',
  },
  {
    title: 'Cliente',
    dataIndex: 'clientName',
  },
  {
    title: 'Nombre de producto',
    dataIndex: 'productName',
  },
  {
    title: 'Presentación',
    dataIndex: 'presentation',
  },
  {
    title: 'Precio sugerido',
    dataIndex: 'suggestedPrice',
  },
  {
    title: 'Precio facturación',
    dataIndex: 'invoicePrice',
  },
];

export const tableColumnsMX = [
  {
    key: 'phase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'phase',
    className: 'title-masive',
  },
  {
    key: 'businessLine',
    title: 'Línea de negocio',
    dataIndex: 'line',
  },
  {
    title: 'Nombre de producto',
    dataIndex: 'productName',
  },
  {
    title: 'K/L por empaque',
    dataIndex: 'packing',
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
  },
  {
    title: 'IEPS',
    dataIndex: 'ieps',
  },
  {
    title: 'IVA',
    dataIndex: 'iva',
  },
  {
    title: 'Precio Público',
    dataIndex: 'publicPrice',
  },
  {
    title: 'Moneda',
    dataIndex: 'currency',
  },
];
