import React, { useState } from 'react';
import { Modal, Row, Col } from 'antd';
import { IconsMap } from 'Layout/utils';
import { useHistory } from 'react-router-dom';
import { SubRegion, BusinessLine } from 'RootModule/catalogs';

const ExclamationIcon = IconsMap.ExclamationCircleOutlined;

const ModalSelector = ({
  setselectSubregion,
  selectSubregion,
  setselectBusinessLine,
  selectBusinessLine,
  selectEndpoint,
}) => {
  const history = useHistory();
  const [modalIsVisible, setModalIsVisible] = useState(true);

  const handleModalOnOK = () => {
    selectEndpoint();
    setModalIsVisible(false);
  };

  // #Cambiar URL
  const cancelModal = () => {
    history.push('/catalog/trade-agreement-info');
  };

  const changeSubRegion = (e, i) => {
    const valueKey = i.options[e - 1].key;
    const nameSub = i.options[e - 1].value.toUpperCase();
    setselectSubregion({ key: valueKey, name: nameSub });
  };

  const changeBusinessLine = (e, i) => {
    const valueKey = i.options[e - 1].key;
    const nameLine = i.options[e - 1].value.toUpperCase();
    setselectBusinessLine({ key: valueKey, name: nameLine });
  };

  return (
    <Modal
      closable={() => cancelModal()}
      visible={modalIsVisible}
      destroyOnClose
      okButtonProps={{ disabled: !selectSubregion || !selectBusinessLine }}
      onCancel={() => cancelModal()}
      maskClosable={false}
      onOk={handleModalOnOK}
    >
      <div className="header-modal">
        <ExclamationIcon style={{ fontSize: '1.5rem', alignSelf: 'center' }} />
        <p>Seleccionar subregión y línea de negocio.</p>
      </div>
      <p>Antes de realizar la carga masiva favor de seleccionar la subregión y línea de negocio.</p>

      <Row>
        <Col span={12}>
          <SubRegion isRequired onChange={(e, i) => changeSubRegion(e, i)} />
        </Col>
        <Col span={12}>
          <BusinessLine isRequired onChange={(e, i) => changeBusinessLine(e, i)} />
        </Col>
      </Row>
    </Modal>
  );
};

export { ModalSelector };
