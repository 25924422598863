import { SubRegion, BusinessLine, BusinessLine2 } from 'RootModule/catalogs';
import { TableTransferSubregion, TableTransferClient } from './index';
import { getInPoliciyProductsRequest } from '../redux-saga/actions';
import React, { useState, useEffect } from 'react';
import { FormTitleDivider } from 'common';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import Clients from './Clients';

const ProductosSelect = (props) => {
  const { form, selectSubTipo, getIPPR, IPPRData, setproductsPolicy } = props;
  const [dataBR, setdataBR] = useState([]);
  const [lineIdClientstate, setlineIdClient] = useState(0);

  //Obtener Data por Cliente o Estructura
  const getDataIPPR = (data) => {
    let { lineId, lineIdClient, subregionId, clientId } = data;
    if (lineId && selectSubTipo === 'estructura' && subregionId > 0) {
      getIPPR({ lineId, subregionId });
    }
    if (lineIdClient > 0 && selectSubTipo === 'cliente' && clientId) {
      getIPPR({ lineIdClient, clientId });
    }
  };

  //Detectar limpieza de Inputs
  useEffect(() => {
    resetInputsLine();
    setproductsPolicy([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectSubTipo]);

  //Vaciar todos los Inputs
  const resetInputsLine = () => {
    form.resetFields(['lineId', 'lineIdClient', 'clientId', 'subregionId']);
  };

  //Vaciar Inputs secundarios
  const resetSubInputs = () => {
    form.resetFields(['clientId', 'subregionId']);
  };
  const resetSubInputsClient = () => {
    form.resetFields(['lineIdClient', 'subregionId']);
  };

  //Pasar datos usados en getDataIPPR
  const changeLineStructure = (e) => {
    resetSubInputs();
    const data = form.getFieldValue();
    setdataBR(data);
  };
  const handleChangeClient = (e) => {
    resetSubInputsClient();
    const data = form.getFieldValue();
    setdataBR(data);
  };

  //Obtener Productos de tabla
  const handleSubChange = (e) => {
    const data = form.getFieldValue();
    setdataBR(data);
    getDataIPPR(data);
    setlineIdClient(data.lineIdClient);
  };

  //Tablas dinamicas de Cliente, Estructura o Null
  const transferTableRender = () => {
    if (dataBR.lineId && dataBR.subregionId > 0 && selectSubTipo === 'estructura') {
      return <TableTransferSubregion IPPRData={IPPRData} setproductsPolicy={setproductsPolicy} />;
    } else if (dataBR.lineIdClient && dataBR.clientId && selectSubTipo === 'cliente') {
      return <TableTransferClient IPPRData={IPPRData} setproductsPolicy={setproductsPolicy} />;
    } else {
      /* Retorna tabla vacia */
      return <TableTransferSubregion />;
    }
  };
  const autoSelectOnlyOption = (options) => {
    const data = form.getFieldValue();

    if (options.length === 1) {
      form.setFieldsValue({ lineIdClient: options[0].key });
      if (data.lineIdClient !== lineIdClientstate) {
        handleSubChange();
      }
    }
  };
  return (
    <div>
      <FormTitleDivider title={'Estructura comercial'} />

      <Row>
        {selectSubTipo === 'estructura' ? (
          /*  Linea de negocio ESTRUCTURA */
          <Col span={8}>
            <BusinessLine
              isRequired
              className="form-add-user-input"
              label="Línea de negocio"
              askFromService={true}
              onChange={changeLineStructure}
            />
          </Col>
        ) : (
          /*  Linea de negocio CLIENTE */
          <Col span={8}>
            <BusinessLine2
              name="lineIdClient"
              isRequired
              className="form-add-user-input"
              label="Línea de negocio"
              sourceId={'soldTo'}
              askFromService={dataBR.clientId ? true : false}
              onChange={handleSubChange}
              disabled={dataBR.clientId ? false : true}
              requestParams={dataBR.clientId ? { ClientSoldTo: dataBR.clientId } : null}
              onSuccess={autoSelectOnlyOption}
            />
          </Col>
        )}
        {selectSubTipo === 'estructura' ? (
          /* SUBREGION */
          <Col span={8}>
            <SubRegion
              isRequired
              name="subregionId"
              onChange={handleSubChange}
              disabled={dataBR?.lineId > 0 ? false : true}
              requestParams={dataBR.lineId ? { lineId: dataBR.lineId } : null}
            />
          </Col>
        ) : (
          /* CLIENTES */
          <Col span={8}>
            <Clients
              label="Cliente"
              isRequired
              optionKey="soldTo"
              valueKey="soldTo"
              labelKey="name"
              name="clientId"
              disabled={false}
              onChange={handleChangeClient}
            />
          </Col>
        )}
      </Row>
      {/* Tabla de tranferencia */}
      <Row>
        <FormTitleDivider title={'Información de producto'} />
      </Row>
      {transferTableRender()}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    IPPRData: state?.REGISTER_BUSSINES_RULES?.registerBussinesRules?.productInformation,
  };
}
export default connect(mapStateToProps, { getInPoliciyProductsRequest })(ProductosSelect);
