import React from 'react';
import { Row, Col, Form, Input } from 'antd';

const ShopByFamilySection = ({ getFamilySalesSection }) => {
  const { TextArea } = Input;

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form.Item name="shopPeriod" label="Periodo:">
            <TextArea
              placeholder={getFamilySalesSection?.period}
              defaultValue={getFamilySalesSection?.period}
              disabled={true}
              autoSize
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default ShopByFamilySection;
