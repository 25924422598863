import { IncentiveResumeSectionActions } from '../../actions';

const {
  INCENTIVE_RESUME_REQUEST,
  GET_INCENTIVE_RESUME,
  INCENTIVE_RESUME_FAIL,
  CLEAN_INCENTIVE_RESUME,
  IR_SUBREGIONS_REQUEST,
  GET_IR_SUBREGIONS,
  IR_SUBREGIONS_FAIL,
  CLEAN_IR_SUBREGIONS,
} = IncentiveResumeSectionActions;

const incentiveResumeDataReducers = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case INCENTIVE_RESUME_REQUEST:
      return {
        ...state,
        IncentiveResumeData: null,
      };
    case IR_SUBREGIONS_REQUEST:
      return {
        ...state,
        IrSubregionsData: null,
      };
    case GET_INCENTIVE_RESUME:
      return {
        ...state,
        IncentiveResumeData: payload ?? [],
      };
    case GET_IR_SUBREGIONS:
      return {
        ...state,
        IrSubregionsData: payload ?? [],
      };
    case INCENTIVE_RESUME_FAIL:
      return {
        ...state,
        IncentiveResumeErrorMsg: payload ?? [],
      };
    case IR_SUBREGIONS_FAIL:
      return {
        ...state,
        IrSubRegionsErrorMsg: payload ?? [],
      };
    case CLEAN_INCENTIVE_RESUME:
      return {
        ...state,
        IncentiveResumeData: [],
      };
    case CLEAN_IR_SUBREGIONS:
      return {
        ...state,
        IrSubregionsData: [],
      };
    default:
      return state;
  }
};

export default incentiveResumeDataReducers;
