import {
  POST_ONE_REGISTERPRICES_REQUEST,
  POST_ONE_REGISTERPRICES_SUCCESS,
  POST_ONE_REGISTERPRICES_CLEAN,
  GET_CHECK_EXISTENCE_REQUEST,
  GET_CHECK_EXISTENCE_SUCCESS,
  GET_CHECK_EXISTENCE_CLEAN,
} from '../actions';

function oneCase(state = {}, action) {
  const { type } = action;

  switch (type) {
    //GET_CHECK_EXISTENCE_REQUEST
    case GET_CHECK_EXISTENCE_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case GET_CHECK_EXISTENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case GET_CHECK_EXISTENCE_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //POST_ONE_REGISTERPRICES_REQUEST
    case POST_ONE_REGISTERPRICES_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case POST_ONE_REGISTERPRICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case POST_ONE_REGISTERPRICES_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}

export default oneCase;
