import { combineReducers } from 'redux';
import catalogs from './catalogs';
import consultFilters from './consultFilters';
import tableComponents from './tableComponents';
import downloadExcel from './downloadExcel';
import salesChallengevalidatorsCatalog from './salesChallengeValidators';
import tradeAgreementsValidatorsCatalog from './tradeAgreementsValidators';

const rootReducers = combineReducers({
  catalogs: catalogs,
  consultFilters: consultFilters,
  downloadExcel: downloadExcel,
  tableComponents: tableComponents,
  salesChallengevalidatorsCatalog: salesChallengevalidatorsCatalog,
  tradeAgreementsValidatorsCatalog: tradeAgreementsValidatorsCatalog,
});

export default rootReducers;
