export const GET_SIGNERS_REQUEST = 'GET_SIGNERS_REQUEST/TRADE-AGREEMENTS';
export const GET_SIGNERS_SUCCESS = 'GET_SIGNERS_SUCCESS/TRADE-AGREEMENTS';
export const GET_SIGNERS_FAIL = 'GET_SIGNERS_FAIL/TRADE-AGREEMENTS';

/**
 * Gets the list of signers on the selected client
 * @export
 * @param {Object} payload - Data avibale redux and middlewares
 * @param {Object} payload.params - Data for API request passed by params
 * @param {string} payload.params.clientId - The client id of the client slected in the consult view
 * @param {Array} payload.params.clients - Additional clients selected in the screen
 * @return {*}
 */
export function getSignersRequest(payload) {
  return {
    type: GET_SIGNERS_REQUEST,
    payload: payload,
  };
}

/**
 * Passes response data to the reducer
 * @export
 * @param {Object} payload - Data that is going to passed to reducer
 * @return {*}
 */
export function getSignersSuccess(payload) {
  return {
    type: GET_SIGNERS_SUCCESS,
    payload: payload,
  };
}

export function getSignersFail() {
  return {
    type: GET_SIGNERS_FAIL,
  };
}

export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST/TRADE-AGREEMENTS';
export const GET_REPORT_FAIL = 'GET_REPORT_FAIL/TRADE-AGREEMENTS';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS/TRADE-AGREEMENTS';

/**
 * Gets the list of signers on the selected client
 * @export
 * @param {Object} payload - Data avibale redux and middlewares
 * @param {Object} payload.params - Data for API request passed by params
 * @param {string} payload.params.clientId - The client id of the client slected in the consult view
 * @param {Array} payload.params.clientIds - Additional clients selected in the screen
 * @param {String} payload.params.lineId - Selected lineId
 * @param {String} payload.params.subRegionId - Selected subRegionId
 * @param {String} payload.params.pogType -Selected pogType
 * @param {String} payload.params.name -Name of the report
 * @return {Object}
 */
export function getReportRequest(payload) {
  return {
    type: GET_REPORT_REQUEST,
    payload: {
      ...payload,
      sourceId: 'DevReports',
      params: {
        ...payload.params,
      },
      fileConfig: {
        extension: 'pdf',
        contentType: 'application/pdf',
      },
    },
  };
}

export function getReportFail() {
  return {
    type: GET_REPORT_FAIL,
  };
}

export function getReportSuccess(payload) {
  return {
    type: GET_REPORT_SUCCESS,
    payload,
  };
}
