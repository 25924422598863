import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getCatalogModule from '../../getCatalogModule';
import { getProcessDatesRequest } from 'Catalog/redux/actions/catalogs';
import FormRegisterProcessDates from 'Catalog/containers/FormRegisterDates';
function RegisterProcessDate({ getProcessDatesRequest: getProcessDates }) {
  useEffect(() => {
    getProcessDates();
  }, [getProcessDates]);
  return (
    <>
      <FormRegisterProcessDates />
    </>
  );
}

const ConnectedRegisterProcessDate = connect(null, { getProcessDatesRequest })(RegisterProcessDate);

const CatalogModule = () => (
  <DynamicModuleLoader modules={[getCatalogModule()]}>
    <ConnectedRegisterProcessDate />
  </DynamicModuleLoader>
);
export default CatalogModule;
