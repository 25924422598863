/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form } from 'antd';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getRootModule from 'RootModule';
import { ActionButtons, BDivider } from 'common';
import { ClientsInformation, SendInformationSection, SendScheduleInfoSection } from '../components';
import automaticSendModule from '../index';
import { layoutContext } from 'context';
import { getDaysInMonth, onValuesChange, handleSubmit } from '../utils/helpers';
import moment from 'moment';

import {
  businessLinesRequest,
  subRegionsRequest,
  monthRequest,
  periodRequest,
  clientRequest,
  statusDataRequest,
  registerRequest,
  frequencyRequest,
  changeStatusRequest,
  deletePeriodRequest,
  statusDynamicsElementsRequest,
} from '../redux-saga/actions';
import '../css/style.css';
import { useHistory } from 'react-router-dom';

const AutomaticSend = (props) => {
  const {
    businessLinesRequest: getBussinesLines,
    subRegionsRequest: getRegions,
    monthRequest: getMonths,
    periodRequest: getPeriod,
    clientRequest: getClient,
    statusDataRequest: getStatusData,
    registerRequest: register,
    frequencyRequest: getFrequency,
    changeStatusRequest: changeStatus,
    deletePeriodRequest: deletePeriod,
    businessLines,
    subRegions,
    monthOptions,
    periodOptions,
    frequencyData,
    clientData,
    statusData,
    statusDynamicsElementsRequest: getStatusDynamicsElement,
    // registerData,
  } = props;
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [switchChecked, setSwitchChecked] = useState(true);
  const [value, SetValue] = useState([0]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [form] = Form.useForm();
  const history = useHistory();
  const [paramsFilter, setParamsFilter] = useState();
  const [paramsClient, setParamsClient] = useState();
  const [isActiveRegister, setIsActiveRegister] = useState(true);

  const [periodDays, setperiodDays] = useState([]);
  useEffect(() => {
    getStatusDynamicsElement({ history });
    onChangeActiveComponent('Users');
    getMonths();
    getBussinesLines();
    getRegions();
    getPeriod();
    getFrequency();
    getClient();
    getStatusData();
  }, []);

  useEffect(() => {
    if (statusData) {
      let { infoDeliverySchedule, autoPeriodDelivery } = statusData;
      let periodData = autoPeriodDelivery.sort((a, b) => a.month - b.month);
      let infoDeliveryScheduleValues = {};
      let arrayValue = [];
      let arrayDays = [];

      if (periodData.length > 0 && infoDeliverySchedule.email) {
        setIsActiveRegister(false);
      }
      if (periodData.length > 0 && monthOptions?.length > 0) {
        // eslint-disable-next-line array-callback-return
        periodData.map((d, index) => {
          infoDeliveryScheduleValues[`month${index}`] = `${d.month}-${d.year}`;
          infoDeliveryScheduleValues[`frecuency${index}`] = d.typePeriodId;
          infoDeliveryScheduleValues[`period${index}`] = d.periodId;
          infoDeliveryScheduleValues[`day${index}`] = d.day;
          infoDeliveryScheduleValues[`time${index}`] = moment(`${d.hour}:${d.minute}`, 'HH:mm');
          arrayValue.push(index);
          let daysOptios = getDaysInMonth(d.month, d.year);
          arrayDays.push(daysOptios);
        });
        setperiodDays(arrayDays);
        SetValue(arrayValue);
        form.setFieldsValue(infoDeliveryScheduleValues);
        setFormValues(infoDeliveryScheduleValues);
      }
      setSwitchChecked(infoDeliverySchedule.enabled);
      let dateSend = infoDeliverySchedule.nextScheduledDelivery?.split(' ')[0];
      form.setFieldsValue({
        date: dateSend?.split('-').reverse().join('/'),
        copyContact: infoDeliverySchedule.email,
      });
    }
  }, [statusData, monthOptions]);

  useEffect(() => {
    if (clientData.length > 0)
      setParamsClient({
        inactiveClients: clientData
          ?.filter((d) => d.isManual === true)
          .map((d) => ({
            clientId: d.clientId,
            subRegionId: d.subRegionId,
            lineId: d.lineId,
          })),
        activeClients: clientData
          ?.filter((d) => d.isManual === false)
          .map((d) => ({
            clientId: d.clientId,
            subRegionId: d.subRegionId,
            lineId: d.lineId,
          })),
      });
  }, [clientData]);

  useEffect(() => {
    if (paramsFilter !== null) getClient({ params: paramsFilter });
  }, [paramsFilter]);

  const onCancel = () => {
    history.push('/account-status');
  };

  const validateTimeout = () => {
    onValuesChange(
      {},
      form,
      businessLines,
      subRegions,
      setParamsFilter,
      setFormValues,
      value,
      setIsActiveRegister
    );
  };

  const validateRemoveDate = (valueArray) => {
    onValuesChange(
      {},
      form,
      businessLines,
      subRegions,
      setParamsFilter,
      setFormValues,
      valueArray,
      setIsActiveRegister
    );
  };

  return (
    <>
      <div className="container">
        <Form
          name="dynamic_form_nest_item"
          form={form}
          autoComplete="off"
          onValuesChange={(e) =>
            onValuesChange(
              e,
              form,
              businessLines,
              subRegions,
              setParamsFilter,
              setFormValues,
              value,
              setIsActiveRegister
            )
          }
        >
          <Row>
            <Col span={24}>
              <BDivider title="Información de envío" />
              <SendInformationSection
                setSwitchChecked={setSwitchChecked}
                switchChecked={switchChecked}
                changeStatus={changeStatus}
                statusData={statusData}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <BDivider title="Información de Programación de envío" />
              <SendScheduleInfoSection
                form={form}
                formValues={formValues}
                monthOptions={monthOptions}
                periodOptions={periodOptions}
                frequencyData={frequencyData}
                value={value}
                SetValue={SetValue}
                deletePeriod={deletePeriod}
                statusData={statusData}
                setperiodDays={setperiodDays}
                periodDays={periodDays}
                setIsActiveRegister={setIsActiveRegister}
                validateTimeout={validateTimeout}
                validateRemoveDate={validateRemoveDate}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <BDivider title={'Información de Clientes'} />

              <ClientsInformation
                businessLines={businessLines}
                setParamsClient={setParamsClient}
                paramsClient={paramsClient}
                subRegions={subRegions}
                clientData={clientData}
                form={form}
                getClient={getClient}
                setTargetKeys={setTargetKeys}
                targetKeys={targetKeys}
              />
            </Col>
          </Row>
          <ActionButtons
            onCancel={onCancel}
            onSubmit={() =>
              handleSubmit(form, statusData, value, paramsClient, clientData, register, history)
            }
            disabled={isActiveRegister}
            buttons={['cancel', 'submit']}
          />
        </Form>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    adminSectionsSubRegions:
      state?.AUTOMATICSEND?.incentiveResumeReducers?.adminSectionsSubRegions ?? [],
    adminSectionsClients: state?.AUTOMATICSEND?.incentiveResumeReducers?.adminSectionsClients ?? [],
    adminSectionsSubRegionsErrorMsg: state?.AUTOMATICSEND?.adminSectionsSubRegionsErrorMsg ?? '',
    adminSectionsClientsErrorMsg: state?.AUTOMATICSEND?.adminSectionsSectionClientsErrorMsg ?? '',
    businessLines: state.AUTOMATICSEND.selectReducers.businessLinesOptions,
    subRegions: state.AUTOMATICSEND.selectReducers.subRegionsOptions,
    monthOptions: state.AUTOMATICSEND.selectReducers.monthOptions,
    periodOptions: state.AUTOMATICSEND.selectReducers.periodOptions,
    clientData: state.AUTOMATICSEND?.selectReducers?.clientData || [],
    statusData: state.AUTOMATICSEND?.selectReducers?.statusData,
    frequencyData: state.AUTOMATICSEND?.selectReducers?.frequency || [],
    registerData: state.AUTOMATICSEND?.selectReducers?.register,
    statusState: state.AUTOMATICSEND?.selectReducers?.status,
  };
}

const LogWrapped = connect(mapStateToProps, {
  businessLinesRequest,
  subRegionsRequest,
  monthRequest,
  periodRequest,
  clientRequest,
  statusDataRequest,
  registerRequest,
  frequencyRequest,
  changeStatusRequest,
  deletePeriodRequest,
  statusDynamicsElementsRequest,
})(AutomaticSend);

const AutomaticSendingStatement = () => (
  <DynamicModuleLoader modules={[getRootModule(), automaticSendModule()]}>
    <LogWrapped />
  </DynamicModuleLoader>
);

export default AutomaticSendingStatement;
