import React from 'react';
import { connect } from 'react-redux';
import { Radio } from 'antd';
import DownloadReport from './DownloadReport';
import { setValidationsFieldsRequest } from 'Validations/redux-saga/actions/validationFields';

const validationOptions = [
  {
    label: 'Desafío de ventas',
    value: 'salesChallenge',
  },
  {
    label: 'Acuerdo comercial',
    value: 'tradeAgreement',
  },
  /* {
    label: 'Todos',
    value: 'All',
  }, */
];
const ValidationHeader = ({
  setValidationsFieldsRequest: setValidationsFieldType,
  validationsFields,
}) => {
  return (
    <>
      <section className="validation-type__wrapper">
        <h4> Agregar validador </h4>
        <Radio.Group
          options={validationOptions}
          onChange={(e) => {
            setValidationsFieldType(e.target.value);
          }}
          value={validationsFields}
        />
        <DownloadReport />
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    query: state?.VALIDATIONS?.filters?.query,
    zoneId: state?.VALIDATIONS?.filters?.query?.zoneId ?? '',
    territoryId: state?.VALIDATIONS?.filters?.query?.territoryId ?? '',
    subRegionId: state?.VALIDATIONS?.filters?.query?.subRegionId ?? '',
    regionId: state?.VALIDATIONS?.filters?.query?.regionId ?? '',
    lineId: state?.VALIDATIONS?.filters?.query?.lineId ?? '',
    clientId: state?.VALIDATIONS?.filters?.query?.clientId ?? '',
    depending: state?.VALIDATIONS?.filters?.disabledDepending?.depending ?? '',
    disabled: state?.VALIDATIONS?.filters?.disabledDepending?.disabled ?? [],
    defaultYear: state?.APP?.catalogs?.years?.data[0]?.value ?? '',
    validationsFields: state?.VALIDATIONS?.validationFieldsReducer?.validationFields ?? '',
  };
};
const validationHeaderConected = connect(mapStateToProps, {
  setValidationsFieldsRequest,
})(ValidationHeader);

export default validationHeaderConected;
