import request from 'services/request';
const serviceURL = '/Catalogs/Api/v1/Prices';

export async function putOneUpdatePrices({ cancelToken, data }) {
  return request(`${serviceURL}`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

export async function getUpdatePricesRequest({ cancelToken, priceId }) {
  return request(`${serviceURL}/Detail/${priceId}`, {
    method: 'GET',
    cancelToken,
  });
}

export async function updateOneExchangeRateStatus({ cancelToken, priceId, enabled }) {
  return request(`${serviceURL}/${priceId}/${enabled}`, {
    method: 'PUT',
    cancelToken,
  });
}
