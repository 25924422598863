import React, { useState, useEffect, useRef } from 'react';
import { Table, Result } from 'antd';

import PropTypes from 'prop-types';
import './style.css';
// import SkeletonTable from 'components/common/SkeletonTable';
// import SEmpty from 'components/common/SEmpty';

function BTable(props) {
  const {
    columns,
    dataSource,
    dataIndex,
    size,
    className,
    rowKey,
    customPageSize,
    withRowNumbers,
    laoding,
    description,
    currentPage,
    totalPage,
    paginationProps,
    noDataFeedbackMessage,
    noDataFeedback,
    ...other
  } = props;

  /*  Pagination will restart on index page 1 every time de dataSource changes  */
  const [page, setPage] = useState(1);
  const pageSize = useRef([]);

  useEffect(() => {
    if (currentPage) {
      setPage(currentPage);
    } else {
      setPage(1);
    }
  }, [dataSource, currentPage]);

  const setColumns = () => {
    if (withRowNumbers) {
      return [
        {
          align: 'center',
          title: 'No. de fila',
          key: 'index',
          dataIndex: 'index',
          render: (_value, _item, index) => (page - 1) * customPageSize + index + 1,
        },
        ...columns,
      ];
    } else {
      return columns;
    }
  };
  const setPageSize = () => {
    if (typeof customPageSize !== 'undefined' && customPageSize !== 20) {
      pageSize.current = [`${customPageSize}`];
      pageSize.current = dataSource.length > 20 ? [...pageSize.current] : pageSize.current;
    } else {
      pageSize.current = ['20'];
    }

    pageSize.current = dataSource.length > 30 ? [...pageSize.current, '30'] : pageSize.current;
    pageSize.current = dataSource.length > 40 ? [...pageSize.current, '40'] : pageSize.current;
    return pageSize.current;
  };

  const setCurrentPage = (current) => {
    setPage(current);
  };

  const getTotalPage = () => {
    if (totalPage) {
      let sizePages = setPageSize();
      return { total: totalPage * sizePages[0] };
    } else {
      return {};
    }
  };

  getTotalPage();

  const setPagination = () => {
    if (typeof paginationProps !== 'undefined') {
      return { pagination: paginationProps };
    } else {
      return {
        pagination: {
          current: page,
          defaultPageSize: customPageSize,
          //  showSizeChanger: true,
          pageSizeOptions: setPageSize(),
          onChange: setCurrentPage,
          ...getTotalPage(),
        },
      };
    }
  };

  const locale = {
    emptyText: noDataFeedback ? <Result status="500" title={noDataFeedbackMessage} /> : ' ',
  };

  return (
    <>
      <Table
        locale={locale}
        className={`${className} bg`}
        size={size}
        data-testid="BTable"
        {...setPagination()}
        columns={setColumns()}
        rowKey={rowKey}
        dataIndex={dataIndex}
        dataSource={dataSource}
        {...other}
      />
    </>
  );
}

BTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  customPageSize: PropTypes.number,
  dataIndex: PropTypes.string,
  dataSource: PropTypes.arrayOf(PropTypes.object),
  rowKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  size: PropTypes.oneOf(['default', 'middle', 'small', 'default']),
  withRowNumbers: PropTypes.bool,
  laoding: PropTypes.bool,
  description: PropTypes.string,
  noDataFeedbackMessage: PropTypes.string,
  noDataFeedback: PropTypes.bool,
};

BTable.defaultProps = {
  className: 'data-table',
  columns: [],
  customPageSize: 20,
  dataIndex: null,
  dataSource: [],
  others: null,
  rowKey: 'id',
  size: 'small',
  withRowNumbers: false,
  laoding: false,
  description: '',
  noDataFeedbackMessage:
    'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados',
  noDataFeedback: true,
};

export default BTable;
