import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import useSubRegions from '../../hooks/useSubRegions';
import '../../css/styles.css';
import SectionTransfer from '../../commons/SectionTransfer';
import useClients from '../../hooks/useClients';

const ResumeIncentiveSection = ({
  incentiveResumeData,
  incentiveResumeSubRegions,
  sendParams,
  updateSendParams,
}) => {
  const [activeClients, inactiveClients, setInactiveClients] = useClients(incentiveResumeData);

  const [activeSubRegions, inactiveSubRegions, setInactiveSubRegions] =
    useSubRegions(incentiveResumeSubRegions);

  const [disabledClients, setDisabledClients] = useState(false);
  const [disabledSubRegions, setDisabledSubRegions] = useState(false);

  useEffect(() => {
    setDisabledClients(inactiveSubRegions.length > 0 ? true : false);
    setDisabledSubRegions(inactiveClients.length > 0 ? true : false);
  }, [inactiveClients, inactiveSubRegions]);

  return (
    <>
      <Row>
        <h4> Por estructura comercial </h4>
      </Row>
      <Row>
        <Col span={24}>
          <SectionTransfer
            dataSource={activeSubRegions}
            targetKeys={inactiveSubRegions}
            onChange={(nextTargetKeys) => {
              setInactiveSubRegions(nextTargetKeys);
              let inactiveSubregions = activeSubRegions
                .filter((d, index) => nextTargetKeys.includes(index))
                .map((d) => ({ lineIdInact: d.lineId, subRegionIdInact: d.subregionId }));
              let activeSubregions = activeSubRegions
                .filter((d, index) => !nextTargetKeys.includes(index))
                .map((d) => ({ lineIdAct: d.lineId, subRegionIdAct: d.subregionId }));
              updateSendParams({
                ...sendParams,
                resumeIncentives: {
                  ...sendParams.resumeIncentives,
                  activeSubRegions: activeSubregions,
                  inactiveSubRegions: inactiveSubregions,
                },
              });
            }}
            type={1}
            showSearch={true}
            pagination={true}
            disabled={disabledSubRegions}
            leftColumns={[
              {
                dataIndex: 'title',
                title: 'Subregiones',
                className: 'firstColumn',
              },
              {
                dataIndex: 'lineName',
                title: '',
              },
            ]}
            rightColumns={[
              {
                dataIndex: 'title',
                title: 'Subregiones',
                className: 'firstColumn',
              },
              {
                dataIndex: 'lineName',
                title: '',
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <h4> Por clientes </h4>
      </Row>

      <Row>
        <Col span={24}>
          <SectionTransfer
            dataSource={activeClients}
            targetKeys={inactiveClients}
            onChange={(nextTargetKeys) => {
              setInactiveClients(nextTargetKeys);
              let inactiveClient = activeClients
                .filter((d, index) => nextTargetKeys.includes(index))
                .map((d) => ({ lineIdCInact: d.lineId, clientIdInact: d.clientId }));
              let activeClient = activeClients
                .filter((d, index) => !nextTargetKeys.includes(index))
                .map((d) => ({ lineIdCAct: d.lineId, clientIdAct: d.clientId }));
              updateSendParams({
                ...sendParams,
                resumeIncentives: {
                  ...sendParams.resumeIncentives,
                  inactiveClients: inactiveClient,
                  activeClients: activeClient,
                },
              });
            }}
            showSearch={true}
            pagination={true}
            disabled={disabledClients}
            leftColumns={[
              {
                dataIndex: 'title',
                title: 'Clientes',
              },
              {
                dataIndex: 'lineName',
                title: '',
              },
            ]}
            rightColumns={[
              {
                dataIndex: 'title',
                title: 'Clientes',
              },
              {
                dataIndex: 'lineName',
                title: '',
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default ResumeIncentiveSection;
