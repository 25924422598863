/* eslint-disable no-empty-function */
import React, { useRef } from 'react';
import { ModalBackground } from 'common/components/modal';
import PdfViewer from 'common/PdfViewer';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';

const PdfPreviewModal = ({ mustShow, hide, page, setPage, pdf, downloadPdf }) => {
  const totalPagesRef = useRef(12);
  const enableLeftButton = page > 1;
  const enableRightButton = page <= totalPagesRef.current - 1;

  const buttonDistance = '-4em';
  const btnStyle = {
    top: 'calc(40% + 6px)',
  };
  const rightBtnStyle = {
    ...btnStyle,
    right: buttonDistance,
  };
  const leftBtnStyle = {
    ...btnStyle,
    left: buttonDistance,
  };
  const downloadButtonStyle = {
    minWidth: '113px',
  };
  const getIconStyle = (enabled) => {
    const iconStyle = {};
    const enabledColor = 'brightness(0.13) saturate(100%)';

    if (enabled) {
      iconStyle.filter = enabledColor;
    }
    return iconStyle;
  };

  const containerClick = () => {};

  const onLoadSuccess = (proxy) => {
    const totalPages = proxy?._pdfInfo?.numPages;
    totalPagesRef.current = totalPages;
  };

  const nextPage = () => {
    if (enableRightButton) {
      setPage(page + 1);
    }
  };
  const previousPage = () => {
    if (enableLeftButton) {
      setPage(page - 1);
    }
  };

  return (
    <ModalBackground
      mustShow={mustShow}
      functionToClose={hide}
      extraElement={
        <Button
          key="send"
          className="sendAccountBtn"
          style={downloadButtonStyle}
          onClick={downloadPdf}
        >
          Descargar archivo
        </Button>
      }
    >
      <button
        disabled={!enableLeftButton}
        className="pdfBtn prev"
        style={leftBtnStyle}
        onClick={previousPage}
      >
        <LeftCircleFilled style={getIconStyle(enableLeftButton)} />
      </button>
      <button
        disabled={!enableRightButton}
        className="pdfBtn next"
        style={rightBtnStyle}
        onClick={nextPage}
      >
        <RightCircleFilled style={getIconStyle(enableRightButton)} />
      </button>
      <PdfViewer
        style={{ width: '100%' }}
        onClick={containerClick}
        showPage={page}
        onLoadSuccess={onLoadSuccess}
        pdf={pdf}
      />
    </ModalBackground>
  );
};

export default PdfPreviewModal;
