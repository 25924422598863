import React, { useEffect, useState } from 'react';
import { Input } from '../../global';
import { AiOutlineSearch } from 'react-icons/ai';
var timerOut;

function SearchInput({ onChange, clear, className, name }) {
  const [searchInput, setSeachInput] = useState('');
  useEffect(() => {
    if (clear) {
      setSeachInput('');
    }
  }, [clear]);
  /* If searchInput in filterquery is undefined or empty, serchInput local state should be undefined  */
  const handleUserInput = (e) => {
    const waitingTime = 1000;
    let value = e.currentTarget.value;

    /* Setear valor de estado */
    // TODO: teclas especiales
    setSeachInput(value);

    /* Limpiar timer */
    window.clearTimeout(timerOut);

    /* Si el timer concluye se manda a llamar el onChange */
    timerOut = window.setTimeout(() => onChange(value), waitingTime);
  };
  return (
    <Input
      type="text"
      name={name}
      autoComplete={'off'}
      className={className}
      value={searchInput}
      suffix={
        <button
          style={{
            outline: 'none',
            height: '30px',
            border: 'none',
          }}
        >
          <AiOutlineSearch />
        </button>
      }
      onChange={handleUserInput}
    />
  );
}
export default SearchInput;
