/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useState, useEffect } from 'react';
import getLogsModule from '../index';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { connect } from 'react-redux';
import {
  logRequest,
  getLogFail,
  logEntityRequest,
  logTypesRequest,
  logUsersRequest,
} from '../redux-saga/actions';
import { Table, Select, DatePicker, Pagination, Result } from 'antd';
import moment from 'moment';
import { ResetButton } from '../../common/components';
import 'antd/dist/antd.css';
import '../css/styles.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ConsultLog = ({
  logArray,
  errorMsg,
  logRequest: requestLog,
  logEntityRequest: requestEntityLog,
  logTypesRequest: requestTypesLog,
  entitiesArray,
  logTypesArray,
  logUsersRequest: actionLogUsersRequest,
  logUsersArray,
  logPages,
  logTotal,
}) => {
  const [logCollection, setLogCollection] = useState([]);
  const [beginDate, setBeginDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [logEntity, setLogEntity] = useState('');
  const [userIds, setUserIds] = useState([]);
  const [actions, setActions] = useState([]);
  const [pageNum, setPageNum] = useState('1');
  const pageSize = '10';
  const [typesColection, setTypesColection] = useState([]);
  const [usersColection, setUsersColection] = useState([]);
  const [currentPage, setCurrentPage] = useState('1');
  const [filteredInfo, setFilteredInfo] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [sortedInfo, setSortedInfo] = useState(null);
  const [sort, setSort] = useState(1);

  const onChangeDate = (dateStrings) => {
    setPageNum('1');
    setBeginDate(
      dateStrings !== undefined &&
        dateStrings !== null &&
        dateStrings[0] !== undefined &&
        dateStrings[0] !== undefined
        ? dateStrings[0]
        : ''
    );
    setEndDate(
      dateStrings !== undefined &&
        dateStrings !== null &&
        dateStrings[1] !== undefined &&
        dateStrings[1] !== undefined
        ? dateStrings[1]
        : ''
    );
    let data = {};

    if (dateStrings !== null && dateStrings !== undefined) {
      const dateOne =
        dateStrings[0] !== null && dateStrings[0] !== undefined
          ? moment(dateStrings[0]).format('YYYY-MM-DD')
          : '';
      const dateTwo =
        dateStrings[1] !== null && dateStrings[1] !== undefined
          ? moment(dateStrings[1]).format('YYYY-MM-DD')
          : '';
      data = {
        From: dateOne,
        To: dateTwo,
        LogEntity: logEntity,
        UserIds: userIds,
        Actions: actions,
        PageNum: '1',
        PageSize: pageSize,
      };
    } else {
      data = {
        From: '',
        To: '',
        LogEntity: logEntity,
        UserIds: userIds,
        Actions: actions,
        PageNum: pageNum,
        PageSize: pageSize,
      };
    }

    logRequest(data);
  };

  useEffect(() => {
    const data = {
      From:
        beginDate !== null && beginDate !== undefined && beginDate !== ''
          ? moment(beginDate).format('YYYY-MM-DD')
          : '',
      To:
        endDate !== null && endDate !== undefined && endDate !== ''
          ? moment(endDate).format('YYYY-MM-DD')
          : '',
      LogEntity: logEntity,
      UserIds: userIds,
      Actions: actions,
      PageNum: pageNum,
      PageSize: pageSize,
      Order: sort,
    };

    requestLog(data);
    requestEntityLog();
    requestTypesLog();
    actionLogUsersRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, beginDate, endDate, logEntity, pageNum, pageSize, userIds, sort]);

  useEffect(() => {
    setLogCollection(logArray ?? []);
  }, [logArray]);

  useEffect(() => {
    if (logTypesArray !== null && logTypesArray !== undefined && logTypesArray.length > 0) {
      let colection = [];
      logTypesArray.map((element) => {
        colection.push({
          text: element.value,
          value: element.key,
        });
      });
      setTypesColection(colection);
    }
  }, [logTypesArray]);

  useEffect(() => {
    if (logUsersArray !== null && logUsersArray !== undefined && logUsersArray.length > 0) {
      let colection = [];
      logUsersArray.forEach((element) => {
        colection.push({
          text: element.user,
          value: element.userId,
        });
      });
      setUsersColection(colection);
    }
  }, [logUsersArray]);

  const columns = [
    {
      title: 'Usuario',
      dataIndex: 'user',
      key: 'user',
      filters: usersColection,
      width: 200,
      filteredValue: filteredInfo?.user ? filteredInfo.user : null,
    },
    {
      title: 'Acción realizada',
      dataIndex: 'action',
      key: 'action',
      filters: typesColection,
      width: 180,
      filteredValue: filteredInfo?.action ? filteredInfo.action : null,
    },
    {
      title: 'Fecha / Hora',
      dataIndex: 'registered',
      key: 'registered',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.registered) - new Date(b.registered),
      render: (registered) => moment(registered).format('DD/MM/YYYY - HH:mm'),
      width: 150,
    },
    {
      title: 'Entidades modificadas',
      dataIndex: 'operation',
      key: 'operation',
    },
  ];

  const onChangePag = (page) => {
    setPageNum(page);
    setCurrentPage(page);
    const data = {
      From: beginDate !== null && beginDate !== '' ? moment(beginDate).format('YYYY-MM-DD') : '',
      To: endDate !== null && endDate !== '' ? moment(endDate).format('YYYY-MM-DD') : '',
      LogEntity: logEntity,
      UserIds: userIds,
      Actions: actions,
      PageNum: page,
      PageSize: pageSize,
      Order: 1,
    };
    logRequest(data);
  };

  const handleChangeEntity = (value) => {
    setLogEntity(value);
    setCurrentPage('1');
    setPageNum('1');
    const data = {
      From: beginDate !== null && beginDate !== '' ? moment(beginDate).format('YYYY-MM-DD') : '',
      To: endDate !== null && endDate !== '' ? moment(endDate).format('YYYY-MM-DD') : '',
      LogEntity: value,
      UserIds: userIds,
      Actions: actions,
      PageNum: '1',
      PageSize: pageSize,
      Order: 1,
    };
    logRequest(data);
  };

  function handleChangeTable(pagination, filters, sorter, extra) {
    const { user, action } = filters;
    const order = sorter.order === 'ascend' ? 0 : 1;

    setActions(action);
    setUserIds(user);
    setFilteredInfo(filters);
    setSortedInfo(sorter);

    setSort(order);
    const data = {
      From: beginDate !== null && beginDate !== '' ? moment(beginDate).format('YYYY-MM-DD') : '',
      To: endDate !== null && endDate !== '' ? moment(endDate).format('YYYY-MM-DD') : '',
      LogEntity: logEntity,
      UserIds: user !== undefined && user !== null ? user : [],
      Actions: action !== undefined && action !== null ? action : [],
      //PageNum: '1',
      PageSize: pageSize,
      Order: order,
    };
    logRequest(data);
  }

  const locale = {
    emptyText:
      logCollection.length > 0 ? (
        ''
      ) : (
        <Result
          status="500"
          title={
            'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados.'
          }
        />
      ),
  };

  return (
    <Fragment>
      <div className="logsHolder">
        <header className="logs-header">
          <div className="logs-header__side">
            <label className="selectHader"> Entidad </label>
            <Select placeholder={'Selecionar'} value={logEntity} onChange={handleChangeEntity}>
              {entitiesArray.map((option) => {
                return (
                  <Option title={option.value} value={option.key} key={option.key}>
                    {' '}
                    {option.value}{' '}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="logs-header__side">
            <RangePicker value={[beginDate, endDate]} format="DD/MM/YYYY" onChange={onChangeDate} />
          </div>

          <div className="logs-header__side last-btn">
            <ResetButton
              title="Limpiar filtros"
              onClick={() => {
                setBeginDate('');
                setEndDate('');
                setLogEntity('');
                setUserIds([]);
                setActions([]);
                setFilteredInfo(null);
                setSortedInfo(null);
                const data = {
                  From: '',
                  To: '',
                  LogEntity: '',
                  UserIds: [],
                  Actions: [],
                  PageNum: pageNum,
                  PageSize: pageSize,
                  Order: 1,
                };
                logRequest(data);
              }}
            />
          </div>
        </header>
        <div className="tableHolder">
          <Table
            locale={locale}
            columns={columns}
            dataSource={logCollection}
            pagination={false}
            onChange={handleChangeTable}
          />
          <Pagination onChange={onChangePag} current={currentPage} total={logTotal} />
        </div>
      </div>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    logArray: state?.CONSULTLOG?.logsCase?.logArray ?? [],
    entitiesArray: state?.CONSULTLOG?.logsCase?.entitiesArray ?? [],
    logTypesArray: state?.CONSULTLOG?.logsCase?.logTypesArray ?? [],
    logUsersArray: state?.CONSULTLOG?.logsCase?.logUsersArray ?? [],
    logPages: state?.CONSULTLOG?.logsCase?.logPages ?? [],
    logTotal: state?.CONSULTLOG?.logsCase?.logTotal ?? [],
    errorMsg: state?.CONSULTLOG?.logsCase?.errorMsg ?? '',
  };
}

const LogWrapped = connect(mapStateToProps, {
  logRequest,
  logEntityRequest,
  logTypesRequest,
  logUsersRequest,
  getLogFail,
})(ConsultLog);

const LogsConsult = () => (
  <DynamicModuleLoader modules={[getLogsModule()]}>
    <LogWrapped />
  </DynamicModuleLoader>
);

export default LogsConsult;
