import React from 'react';
import { Row, Col } from 'antd';
import { BInput } from 'common/components';

const Contact = () => {
  return (
    <div>
      <Row>
        <Col span={8}>
          <BInput
            className="form-add-user-input"
            name="fullName"
            maxLength={100}
            placeholder="Escribe nombre"
            label="*Nombre completo:"
          />
        </Col>

        <Col span={8}>
          <BInput
            className="form-add-user-input"
            name="email"
            maxLength={80}
            placeholder="Escribe correo electrónico"
            label="*Correo electrónico:"
            rules={[{ type: 'email', message: 'usuario@dominio.extensión' }]}
          />
        </Col>

        <Col span={8}>
          <BInput
            className="form-add-user-input"
            placeholder="Escribe departamento"
            maxLength={100}
            name="departament"
            label="*Departamento:"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Contact;
