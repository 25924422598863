import React from 'react';
import { Row, Col } from 'antd';
import { BInput } from 'common/components';

const Contact = () => {
  return (
    <div>
      <Row>
        <Col span={8}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="contactFullName"
            label="Nombre completo:"
          />
        </Col>

        <Col span={8}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="contactEmail"
            label="Correo electrónico:"
          />
        </Col>

        <Col span={8}>
          <BInput
            disabled
            isRequired
            className="form-add-user-input"
            name="contactDepartment"
            label="Departamento:"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Contact;
