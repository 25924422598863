import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import getUsersMassiveUploadModule from '../getUsersMassiveUploadModule';
import { layoutContext } from 'context';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import {
  MassiveUploadTable,
  UploadExcelButton,
  ActionButtonsExcelUpload,
} from 'common/MassiveUploads/components';
import { triggerModalForDuplicates } from 'common/MassiveUploads/utils';
//cambiar sagas
import {
  usersMassiveUploadSetAllowUpload,
  usersMassiveUploadRequestVerify,
  usersMassiveUploadRequestUpload,
  usersMassiveUploadClearUpload,
  usersMassiveUploadClearVerify,
  usersMassiveUploadClearAllowUpload,
} from '../redux-saga/actions';
import tableColumns from '../schemas/massiveUploadTableColumns'; //cambiar columna

const UsersMassiveUpload = (props) => {
  const {
    usersMassiveUploadSetAllowUpload: setAllowUpload,
    usersMassiveUploadRequestVerify: requestVerify,
    usersMassiveUploadRequestUpload: requestUpload,
    usersMassiveUploadClearUpload: clearUpload,
    usersMassiveUploadClearVerify: clearVerify,
    usersMassiveUploadClearAllowUpload: clearAllowUpload,
    allowUpload,
    verify,
  } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [fileString, setFileString] = useState('');

  const correct = verify?.correct;
  const entriesList = verify?.objectList;
  const totalDuplicatedEntries = verify?.replaced?.length;
  const allCorrectMessage = verify?.allCorrectMessage;

  useEffect(() => {
    clearAllowUpload();
    return () => {
      clearAllowUpload();
      clearUpload();
      clearVerify();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (correct > 0) {
      setAllowUpload(true);
    } else {
      setAllowUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correct]);

  function customRequest({ file }) {
    onChangeActiveComponent('BusinessStructureMassiveUpload');
    requestVerify({ file, setFileString });
  }

  function requestReplace() {
    requestUpload({ history, fileString, overwrite: true });
  }
  function requestKeep() {
    requestUpload({ history, fileString, overwrite: false });
  }
  function onSubmit() {
    if (totalDuplicatedEntries > 0) {
      triggerModalForDuplicates(totalDuplicatedEntries, requestReplace, requestKeep, 'Usuarios');
      return;
    }
    requestReplace();
  }
  function onCancel() {
    history.push(`/dashboard`);
  }

  return (
    <Form form={form}>
      <UploadExcelButton customRequest={customRequest} />
      <MassiveUploadTable
        data={entriesList}
        columns={tableColumns}
        allCorrectMessage={allCorrectMessage}
      />
      <ActionButtonsExcelUpload
        onSubmit={onSubmit}
        onCancel={onCancel}
        disabled={!allowUpload}
        buttons={['cancel', 'submit']}
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    allowUpload: state?.USERS_MASSIVE_UPLOAD?.allowUpload,
    verify: state?.USERS_MASSIVE_UPLOAD?.verify,
  };
};

const ConnectedUsersMassiveUpload = connect(mapStateToProps, {
  usersMassiveUploadSetAllowUpload,
  usersMassiveUploadRequestVerify,
  usersMassiveUploadRequestUpload,
  usersMassiveUploadClearUpload,
  usersMassiveUploadClearVerify,
  usersMassiveUploadClearAllowUpload,
})(UsersMassiveUpload);

const UsersMassiveUploadWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getUsersMassiveUploadModule()]}>
      <ConnectedUsersMassiveUpload />
    </DynamicModuleLoader>
  );
};
export default UsersMassiveUploadWithModules;
