import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import instance from '../../../services/request';
import {
  DOWNLOAD_TRADE_AGREEMENTS_REPORT_REQUEST,
  downloadTradeAgreementsReportSuccess,
  downloadTradeAgreementsReportFail,
} from '../actions/downloadTradeAgreement';
import { getTradeAgreementsValidatorsReport } from '../../services/reports';
import { openNotification } from 'common/misc/openNotification';

function* workDownloadTradeAgreementsReportRequest(action) {
  const { payload } = action;
  const { onSuccess, fileConfig } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  let date = new Date().toISOString().split('T')[0];
  try {
    const response = yield call(getTradeAgreementsValidatorsReport, source.token);
    if (response.Blob === null) {
      openNotification(
        'error',
        response.message
          ? response.message
          : 'El sistema no pudo realizar la operación, inténtelo de nuevo mas tarde'
      );
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response);
    link.download = `REPORTE VALIDADORES ACUERDO_${date}.${
      fileConfig ? fileConfig.extension : 'xlsx'
    }`;
    link.click();
    onSuccess();
    yield put(downloadTradeAgreementsReportSuccess(response));
  } catch (error) {
    console.error('[Redux-Saga-Basf]', error);
    yield put(downloadTradeAgreementsReportFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchReportRequest() {
  yield takeLatest(
    DOWNLOAD_TRADE_AGREEMENTS_REPORT_REQUEST,
    workDownloadTradeAgreementsReportRequest
  );
}
