import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { FormTitleDivider } from 'common';
import { Client } from '../Components';
import {
  Territory,
  SubRegion,
  Zones,
  BusinessLine,
  Country,
  Companies,
  Products,
} from 'RootModule/catalogs';

const CommercialStructure = ({ form, dataForm, checkExistence }) => {
  const [dataCE, setdataCE] = useState([]);
  //Sincronizar input productos
  const handleLinkedSelect = (linked, value) => {
    if (typeof value !== 'object') {
      form.setFieldsValue({ [linked]: value });
      checkExistence(value);
    }
  };
  //Delete sub Inputs
  const fieldsLineId = () => {
    if (dataCE.subregionId) {
      form.setFieldsValue({
        subregionId: undefined,
        territoryId: undefined,
        zoneId: undefined,
        countryId: undefined,
        ProductsName: undefined,
        productNumber: undefined,
      });
    }
  };
  const fieldsSubRegionId = () => {
    if (dataCE.territoryId) {
      form.setFieldsValue({
        territoryId: undefined,
        zoneId: undefined,
        countryId: undefined,
        ProductsName: undefined,
        productNumber: undefined,
      });
    }
  };
  const fieldsTerritoryId = () => {
    if (dataCE.territoryId) {
      form.setFieldsValue({
        zoneId: undefined,
        countryId: undefined,
        ProductsName: undefined,
        productNumber: undefined,
      });
    }
  };
  const fieldsZoneId = () => {
    if (dataCE.zoneId) {
      form.setFieldsValue({
        countryId: undefined,
        ProductsName: undefined,
        productNumber: undefined,
      });
    }
  };
  const fieldsCountryId = () => {
    if (dataCE.zoneId) {
      form.setFieldsValue({
        ProductsName: undefined,
        productNumber: undefined,
      });
    }
  };

  const deleteInputs = (data) => {
    if (!data.lineId || dataCE.lineId !== data.lineId) {
      fieldsLineId();
    }
    if (!data.subregionId || dataCE.subregionId !== data.subregionId) {
      fieldsSubRegionId();
    }
    if (!data.territoryId || dataCE.territoryId !== data.territoryId) {
      fieldsTerritoryId();
    }
    if (!data.zoneId || dataCE.zoneId !== data.zoneId) {
      fieldsZoneId();
    }
    if (!data.countryId || dataCE.countryId !== data.countryId) {
      fieldsCountryId();
    }
  };

  const handleChange = (e) => {
    let data = form.getFieldValue();
    deleteInputs(data);
    data = form.getFieldValue();
    setdataCE(data);
  };

  return (
    <div>
      <Row>
        <Col span={8}>
          <BusinessLine
            isRequired
            className="form-add-user-input"
            label="Línea de negocio"
            askFromService={true}
            onChange={handleChange}
            /* onSelect={fieldsLineId} */
          />
        </Col>

        <Col span={8}>
          <SubRegion
            isRequired
            name="subregionId"
            disabled={dataCE.lineId ? false : true}
            onChange={handleChange}
            requestParams={dataCE.lineId ? { BusinessLines: dataCE.lineId } : null}
          />
        </Col>

        <Col span={8}>
          <Territory
            isRequired
            disabled={dataCE.subregionId && dataCE.lineId ? false : true}
            onChange={handleChange}
            requestParams={
              dataCE.subregionId && dataCE.lineId
                ? {
                    SubregionId: dataCE.subregionId,
                    LineId: dataCE.lineId,
                  }
                : null
            }
          />
        </Col>

        <Col span={8}>
          <Zones
            name="zoneId"
            isRequired
            disabled={dataCE.territoryId && dataCE.subregionId && dataCE.lineId ? false : true}
            onChange={handleChange}
            requestParams={dataCE.territoryId ? { territoryId: dataCE.territoryId } : null}
          />
        </Col>

        <Col span={8}>
          <Country
            isRequired
            name="countryId"
            label="País"
            disabled={
              dataCE.territoryId && dataCE.subregionId && dataCE.lineId && dataCE.zoneId
                ? false
                : true
            }
            onChange={handleChange}
            requestParams={dataCE.zoneId ? { zones: dataCE.zoneId } : null}
            selectAll={false}
          />
        </Col>

        <Col span={8}>
          <Companies name="companyId" label="Compañía" isRequired onChange={handleChange} />
        </Col>
      </Row>

      {/* INFORMACION CLIENTE  */}
      <Row>
        <FormTitleDivider title={'Información de cliente'} />
      </Row>
      <Client form={form} />

      {/* INFORMACION PRODUCTO  */}
      <Row>
        <FormTitleDivider title={'Información de producto'} />
      </Row>
      <Row>
        <Col span={8}>
          <Products
            isRequired
            label="Número"
            name="productNumber"
            allowClear={false}
            sourceId="ByProductNumber"
            onChange={(value) => handleLinkedSelect('ProductsName', value)}
            optionKey="productNumber"
            valueKey="productNumber"
            labelKey="productNumber"
            disabled={
              dataCE.territoryId &&
              dataCE.subregionId &&
              dataCE.lineId &&
              dataCE.zoneId &&
              dataCE.countryId
                ? false
                : true
            }
            requestParams={
              dataCE.countryId
                ? {
                    lines: dataCE.lineId,
                    subregions: dataCE.subregionId,
                    countries: dataCE.countryId,
                  }
                : null
            }
          />
        </Col>
        <Col span={8}>
          <Products
            isRequired
            label="Nombre"
            name="ProductsName"
            allowClear={false}
            sourceId="ByProductNumber"
            onChange={(value) => handleLinkedSelect('productNumber', value)}
            optionKey="productNumber"
            valueKey="productNumber"
            labelKey="name"
            disabled={
              dataCE.territoryId &&
              dataCE.subregionId &&
              dataCE.lineId &&
              dataCE.zoneId &&
              dataCE.countryId
                ? false
                : true
            }
            requestParams={
              dataCE.countryId
                ? {
                    lines: dataCE.lineId,
                    subregions: dataCE.subregionId,
                    countries: dataCE.countryId,
                  }
                : null
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default CommercialStructure;
