export const GRAPH_REQUEST = 'GRAPH_REQUEST/SALES-CHALLENGE';
export const GRAPH_SUCCESS = 'GRAPH_SUCCESS/SALES-CHALLENGE';
export const GRAPH_FAIL = 'GRAPH_FAIL/SALES-CHALLENGE';
export const SALES_GOAL_REQUEST = 'SALES_GOAL_REQUEST/SALES-CHALLENGE';
export const SALES_GOAL_SUCCESS = 'SALES_GOAL_SUCCESS/SALES-CHALLENGE';
export const SALES_GOAL_FAIL = 'SALES_GOAL_FAIL/SALES-CHALLENGE';
export const CLEAN_GRAPH = 'CLEAN_GRAPH';

export function graphClean() {
  return {
    type: CLEAN_GRAPH,
  };
}

export function graphRequest(payload) {
  return {
    type: GRAPH_REQUEST,
    payload: payload,
  };
}

export function graphSuccess(payload) {
  return {
    type: GRAPH_SUCCESS,
    payload: payload,
  };
}

export function graphFail() {
  return {
    type: GRAPH_FAIL,
  };
}

export function salesGoalRequest(payload) {
  return {
    type: SALES_GOAL_REQUEST,
    payload: payload,
  };
}

export function salesGoalSuccess(payload) {
  return {
    type: SALES_GOAL_SUCCESS,
    payload: payload,
  };
}

export function salesGoalFail() {
  return {
    type: SALES_GOAL_FAIL,
  };
}
