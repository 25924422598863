import {
  ACCOUNT_STATUS_DYNAMIC_ELEMENTS_REQUEST_CONSULT_TABLE,
  ACCOUNT_STATUS_DYNAMIC_ELEMENTS_SET_CONSULT_TABLE,
  ACCOUNT_STATUS_DYNAMIC_ELEMENTS_CLEAR_CONSULT_TABLE,
} from '../actions';

function accountStatusDynamicElementsReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case ACCOUNT_STATUS_DYNAMIC_ELEMENTS_REQUEST_CONSULT_TABLE:
      return {
        ...state,
        isLoading: true,
        consultTable: null,
      };

    case ACCOUNT_STATUS_DYNAMIC_ELEMENTS_SET_CONSULT_TABLE:
      return {
        ...state,
        isLoading: false,
        consultTable: action.payload,
      };

    case ACCOUNT_STATUS_DYNAMIC_ELEMENTS_CLEAR_CONSULT_TABLE:
      return {
        ...state,
        isLoading: false,
        consultTable: null,
      };

    default:
      return state;
  }
}

export default accountStatusDynamicElementsReducer;
