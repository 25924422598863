import request from 'services/request';

//DELETE IMAGE
export async function ManageImagesDelete({ cancelToken, params, endpoint }) {
  let uri = endpoint;
  return request(uri, {
    method: 'DELETE',
    cancelToken,
    params,
  });
}
