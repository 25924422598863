import React, { useEffect, useState, useContext } from 'react';
import { Form, Row, Modal } from 'antd';
import { FormTitleDivider, ActionButtons } from 'common';
import { CommercialStructure, Client, Product, Prices } from '../Components';
import { connect } from 'react-redux';
import integerFloat from '../integerFloat';
import {
  getUpdatePricesRequest,
  putOneUpdatePricesRequest,
  putOneStatusRequest,
} from '../redux-saga/actions';
import getRegisterPricesModule from '../updatePricesModule';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { layoutContext } from '../../../context';
import getRootModule from 'RootModule';
import { useHistory, useParams } from 'react-router-dom';

const RegisterPricesForm = (props) => {
  const {
    detailPricesData,
    getUpdatePricesRequest: getUpdatePrices,
    putOneUpdatePricesRequest: putOneRPR,
    putOneStatusRequest: putOneStatus,
  } = props;
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [btnTF, setbtnTF] = useState(false);
  const history = useHistory();
  const [statusSwitch, setStatusSwitch] = useState({
    checked: false,
    disabled: true,
  });

  const params = useParams();
  const priceId = params.id;
  const intFloat = (e) => {
    try {
      let { keyId, valueIF } = integerFloat(e);
      if (keyId === 'taxes') {
        form.setFieldsValue({ taxes: valueIF });
      } else if (keyId === 'suggested') {
        form.setFieldsValue({ suggested: valueIF });
      } else if (keyId === 'brute') {
        form.setFieldsValue({ brute: valueIF });
      } else if (keyId === 'net') {
        form.setFieldsValue({ net: valueIF });
      } else if (keyId === 'list') {
        form.setFieldsValue({ list: valueIF });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const switchBtn = () => {
    let dataForm = form.getFieldsValue();
    if (
      dataForm.zoneId &&
      dataForm.lineId &&
      dataForm.subregionId &&
      dataForm.countryId &&
      dataForm.productNumber &&
      dataForm.soldTo &&
      dataForm.clientLineId &&
      dataForm.companyId &&
      dataForm.list &&
      dataForm.net &&
      dataForm.brute &&
      dataForm.suggested >= 0 &&
      dataForm.suggested !== undefined &&
      dataForm.suggested !== '' &&
      dataForm.suggested !== null
    ) {
      setbtnTF(false);
    } else {
      setbtnTF(true);
    }
  };
  useEffect(() => {
    getUpdatePrices({ priceId: priceId });
    onChangeActiveComponent('Users');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatusChange = (value) => {
    const actions = {
      true: 'desactivar',
      false: 'activar',
    };
    const message = {
      default: `¿Está seguro que desea ${actions[value]} los precios?`,
      representative: `¿Está seguro que desea ${actions[value]} los precios?`,
    };

    Modal.confirm({
      title: 'Confirmar acción',
      content: value === true ? message.representative : message.default,
      onOk() {
        putOneStatus({ priceId, enabled: statusSwitch.checked, history, setStatusSwitch });

        switchBtn();
      },
    });
  };

  useEffect(() => {
    if (detailPricesData) {
      form.setFieldsValue({
        lineId: detailPricesData.lineId,
        clientLineId: detailPricesData.clientLineId,
        subregionId: detailPricesData.subregionId,
        territoryId: detailPricesData.territoryId,
        zoneId: detailPricesData.zoneId,
        countryId: detailPricesData.countryId,
        companyId: detailPricesData.companyId,
        clientName: detailPricesData.client,
        soldTo: detailPricesData.soldTo,
        type: detailPricesData.type,
        productNumber: detailPricesData.productNumber,
        ProductsName: detailPricesData.productName,
        ap: detailPricesData.ap,
        list: detailPricesData.list,
        net: detailPricesData.net,
        brute: detailPricesData.brute,
        suggested: detailPricesData.suggested,
        taxes: detailPricesData.taxes,
      });

      if (detailPricesData.inUse === true && detailPricesData.status === true) {
        setStatusSwitch({
          disabled: true,
          checked: !detailPricesData.status,
        });
      } else {
        setStatusSwitch({
          disabled: false,
          checked: !detailPricesData.status,
        });
      }
      switchBtn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailPricesData]);

  const OnValuesChange = (e) => {
    intFloat(e);
    switchBtn();
  };

  const onSubmit = () => {
    const values = form.getFieldsValue();
    putOneRPR({
      values: { ...values, priceId },
      history,
    });
  };
  const onCancel = () => {
    history.push('/catalog/prices');
  };

  const handlerOnKeyDown = (e) => {
    if (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 109) {
      e.preventDefault();
    }
  };
  return (
    <>
      <div className={'module-card'}>
        <Form form={form} onValuesChange={OnValuesChange}>
          <Row>
            <FormTitleDivider title={'Estructura comercial'} />
          </Row>
          <CommercialStructure form={form} />
          <Row>
            <FormTitleDivider title={'Información de cliente'} />
          </Row>
          <Client form={form} detailPricesData={detailPricesData} />
          <Row>
            <FormTitleDivider title={'Información de producto'} />
          </Row>
          <Product form={form} />
          <Row>
            <FormTitleDivider title={'Información de precios'} />
          </Row>
          <Prices handlerOnKeyDown={handlerOnKeyDown} statusSwitch={statusSwitch} />
        </Form>
      </div>
      <ActionButtons
        onCancel={onCancel}
        onSubmit={onSubmit}
        disabled={btnTF}
        statusChange={handleStatusChange}
        statusSwitch={statusSwitch}
        buttons={['cancel', 'submit']}
      />
    </>
  );
};
function mapStateToProps(state) {
  return { detailPricesData: state?.UPDATEPRICES?.detailPrices?.data };
}
const ConnectedRegisterPricesForm = connect(mapStateToProps, {
  getUpdatePricesRequest,
  putOneUpdatePricesRequest,
  putOneStatusRequest,
})(RegisterPricesForm);

const RegisterPrices = () => (
  <DynamicModuleLoader modules={[getRootModule(), getRegisterPricesModule()]}>
    <ConnectedRegisterPricesForm />
  </DynamicModuleLoader>
);

export default RegisterPrices;
