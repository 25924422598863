import React from 'react';
import { Row, Col, Input, Form } from 'antd';

const ResumeIncentiveSection = ({ getCreditNotesSection }) => {
  const { TextArea } = Input;
  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item name="IncentiveResumeNote" label="Nota:">
            <TextArea
              placeholder={getCreditNotesSection?.notes}
              defaultValue={getCreditNotesSection?.notes}
              disabled={true}
              autoSize
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default ResumeIncentiveSection;
