import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import 'Catalog/HistoricInformation/Sales/MassiveUpload/schemas/massiveUploadTableColumns.css';

import React from 'react';
const tableColumns = [
  {
    key: 'phase',
    title: (
      <ErrorColumnTitle
        errorMessages={[
          {
            fase: 'Fase 1',
            message: (
              <>
                <p>
                  Registros para los cuales se omitió ingresar campos obligatorios, se ingresó un
                  tipo de dato que no corresponde al solicitado en el campo o se excedió la longitud
                  máxima.
                </p>
              </>
            ),
          },
          {
            fase: 'Fase 2',
            message: (
              <>
                <p>
                  Registros de usuarios para los cuales se ingresó información de elementos con
                  estatus &quot;Inactivo &quot;, se ingresó información de estructura comercial que
                  no está relacionada entre sí, se asociaron clientes con presencia distinta a
                  &quot;Nacional &quot; a un usuario KAM o que no se encuentran asociados a la línea
                  de negocio seleccionada para el usuario, se intentó actualizar información de
                  usuarios representantes clave de la cuenta de uno o mas clientes.
                </p>
              </>
            ),
          },
          {
            fase: 'Fase 3',
            message: (
              <>
                <p>Registros que se encuentran repetidos dentro del archivo de carga masiva.</p>
              </>
            ),
          },
        ]}
      />
    ),
    dataIndex: 'phase',
  },
  {
    key: 'name',
    title: 'Nombre(s)',
    dataIndex: 'name',
  },
  {
    key: 'surname',
    title: 'Primer apellido',
    dataIndex: 'surname',
  },
  {
    key: 'lastName',
    title: 'Segundo apellido',
    dataIndex: 'lastName',
  },
  {
    key: 'email',
    title: 'Correo electrónico',
    dataIndex: 'email',
  },
  {
    key: 'emailLeader',
    title: 'Correo electrónico de líder',
    dataIndex: 'emailLeader',
  },
  {
    key: 'profile',
    title: 'Perfil',
    dataIndex: 'profile',
  },
  {
    key: 'region',
    title: 'Región',
    dataIndex: 'region',
  },
  {
    key: 'line',
    title: 'Línea de negocio',
    dataIndex: 'line',
  },
  {
    key: 'subRegion',
    title: 'Subregión',
    dataIndex: 'subRegion',
  },
  {
    key: 'terrytory',
    title: 'Territorio',
    dataIndex: 'terrytory',
  },
  {
    key: 'zone',
    title: 'Zona',
    dataIndex: 'zone',
  },
];
export default tableColumns;
