import React, { useState } from 'react';
import { Tooltip } from 'antd';
import BButton from '../BButton';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';

function BToggleModalControl(props) {
  const {
    onOpen,
    onOk,
    onCancel,
    labelButton,
    iconButton,
    intialState,
    children,
    tooltipText,
    conditionalClose,
    ref,
    ...other
  } = props;
  const [showModal, setShowModal] = useState(intialState);

  const localOnOk = () => {
    if (!conditionalClose) {
      if (onOk) onOk();
      setShowModal(false);
    } else {
      if (onOk) {
        onOk(setShowModal);
      }
    }
  };

  const localOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setShowModal(false);
  };

  const localOnOpen = () => {
    if (onOpen) {
      onOpen();
    }
    setShowModal(true);
  };

  return (
    <>
      {React.cloneElement(children, {
        onCancel: localOnCancel,
        onOk: localOnOk,
        show: showModal,
        visible: showModal,
        setShowModal,
        ref,
      })}
      <Tooltip title={tooltipText}>
        <BButton
          style={{ marginBottom: '.5rem' }}
          label={labelButton}
          icon={iconButton}
          onClick={localOnOpen}
          {...other}
        />
      </Tooltip>
    </>
  );
}

BToggleModalControl.propTypes = {
  labelButton: PropTypes.string,
  iconButton: PropTypes.string,
  intialState: PropTypes.bool,
  children: PropTypes.node,
  onOpen: PropTypes.func,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  handleShow: PropTypes.func,
  tooltipText: PropTypes.string,
  conditionalClose: PropTypes.bool,
};

BToggleModalControl.defaultProps = {
  labelButton: null,
  iconButton: <PlusOutlined />,
  intialState: false,
  children: <div />,
  onOpen: null,
  onOk: null,
  onCancel: null,
  handleShow: null,
  tooltipText: null,
  conditionalClose: false,
};

export default BToggleModalControl;
