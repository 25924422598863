import { ShopSectionActions } from '../../actions';

const {
  SHOP_SECTION_REQUEST,
  GET_SHOP_SECTION,
  SHOP_SECTION_FAIL,
  CLEAN_SHOP_SECTION,
  SHOP_SECTION_REQUEST_SUB_REGIONS,
  GET_SHOP_SUB_REGIONS,
  SHOP_FAIL_SUB_REGIONS,
  CLEAN_SHOP_SUB_REGIONS,
} = ShopSectionActions;

const shopSectionDataReducers = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SHOP_SECTION_REQUEST:
      return {
        ...state,
        shopSectionData: null,
      };
    case SHOP_SECTION_REQUEST_SUB_REGIONS:
      return {
        ...state,
        shopSectionSubRegions: null,
      };
    case GET_SHOP_SECTION:
      return {
        ...state,
        shopSectionData: payload ?? [],
      };
    case GET_SHOP_SUB_REGIONS:
      return {
        ...state,
        shopSectionSubRegions: payload ?? [],
      };
    case SHOP_SECTION_FAIL:
      return {
        ...state,
        shopSectionErrorMsg: payload ?? [],
      };
    case SHOP_FAIL_SUB_REGIONS:
      return {
        ...state,
        shopSubRegionsMsg: payload ?? [],
      };
    case CLEAN_SHOP_SECTION:
      return {
        ...state,
        shopSectionData: [],
      };
    case CLEAN_SHOP_SUB_REGIONS:
      return {
        ...state,
        shopSectionSubRegions: [],
      };
    default:
      return state;
  }
};

export default shopSectionDataReducers;
