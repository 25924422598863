import React from 'react';
import { Row, Col } from 'antd';
import { BInput } from 'common/components';

const SignerBASF = ({ fullName, position, numSignerBASF }) => {
  return (
    <>
      {fullName ? (
        <Row>
          <Col span={8}>
            <BInput
              disabled
              isRequired={numSignerBASF < 2}
              className="form-add-user-input"
              name="fullName"
              defaultValue={fullName}
              label="Nombre completo del firmante:"
            />
          </Col>

          <Col span={8}>
            <BInput
              disabled
              isRequired={numSignerBASF < 2}
              className="form-add-user-input"
              name="position"
              defaultValue={position}
              label="Posición:"
            />
          </Col>
        </Row>
      ) : (
        ''
      )}
    </>
  );
};

export default SignerBASF;
