import rootReducer from './redux/reducers';
import rootSagas from './redux/sagas';
export default function getSalesChallengeModule() {
  return {
    id: 'sales-challenge',
    reducerMap: {
      SALES_CHALLENGE: rootReducer,
    },
    sagas: [rootSagas],
  };
}
