import React, { useCallback } from 'react';
import { Dropdown, Menu, Badge } from 'antd';
import BButton from 'common/components/BButton';
import { BellOutlined } from '@ant-design/icons';
import NotificationContainer from 'Notifications/containers/NotificationContainer';
import './styles.css';
import { connect } from 'react-redux';

function NotificationAction(props) {
  const { notifications, totalFalse } = props;

  const menu = useCallback(() => {
    let hasNotifications = notifications ? notifications.length > 0 : false;

    return (
      <Menu className="notifications-navbar">
        <NotificationContainer hasNotifications={hasNotifications} />
      </Menu>
    );
  }, [notifications]);

  return (
    <Dropdown overlay={menu} trigger={['click']} arrow>
      <span className="container-notification-button">
        <Badge count={totalFalse} overflowCount={30}>
          <BButton
            label=""
            type="link"
            style={{ color: '#fff' }}
            block
            title="Notificaciones"
            onClick={() => {
              return;
            }}
            icon={<BellOutlined />}
          />
        </Badge>
      </span>
    </Dropdown>
  );
}

function mapStateToProps(state) {
  return {
    notifications: state?.NOTIFICATIONS?.notifications?.notificationsFalse?.current ?? [],
    totalFalse: state?.NOTIFICATIONS?.notifications?.notificationsFalse?.total,
  };
}

export default connect(mapStateToProps, {})(NotificationAction);
