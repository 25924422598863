import React from 'react';
import { openNotification } from 'common';

const ValidationMessage = (filesError) => {
  let numFiles = filesError.length;
  let messaggeByNum = (
    <>
      Las imágenes que se mostrarán en el estado de cuenta deben estar en formato JPG, tener un
      tamaño máximo de 5 MB y dimensiones de 649x331.
    </>
  );
  if (filesError[0]?.multiplesFiles === true) {
    messaggeByNum = (
      <>
        Se seleccionaron <b>{numFiles}</b> archivos que no cumplen con las características
        establecidas para las imágenes de estado de cuenta:
        <br />
        {filesError.map((item) => {
          return (
            <>
              <b>{item.name}: </b>
              {item.typeError}

              <br />
            </>
          );
        })}
      </>
    );
  }
  openNotification(
    'error',
    <>
      {messaggeByNum}
      <br />
    </>
  );
};
export default ValidationMessage;
