//Imagenes en base64
export const INPUT_IMAGES_SET = 'INPUT_IMAGES_SET';

export function InputImagesSet(payload) {
  return {
    type: INPUT_IMAGES_SET,
    payload: payload,
  };
}

//POST ImageLine
export const POST_IMAGE_LINE_REQUEST = 'POST_IMAGE_LINE_REQUEST';
export const POST_IMAGE_LINE_SET = 'POST_IMAGE_LINE_SET';
export const POST_IMAGE_LINE_CLEAN = 'POST_IMAGE_LINE_CLEAN';

export function POSTImageLineRequest(payload) {
  return {
    type: POST_IMAGE_LINE_REQUEST,
    payload: payload,
  };
}
export function POSTImageLineSet(payload) {
  return {
    type: POST_IMAGE_LINE_SET,
    payload: payload,
  };
}
export function POSTImageLineClean(payload) {
  return {
    type: POST_IMAGE_LINE_CLEAN,
  };
}
