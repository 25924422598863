import {
  POST_ONE_SEGMENTATION_REQUEST,
  POST_ONE_SEGMENTATION_SUCCESS,
  POST_ONE_SEGMENTATION_CLEAN,
  DELETE_ONE_SEGMENTATION_REQUEST,
  DELETE_ONE_SEGMENTATION_SUCCESS,
  DELETE_ONE_SEGMENTATION_CLEAN,
  PUT_ONE_SEGMENTATION_REQUEST,
  PUT_ONE_SEGMENTATION_SUCCESS,
  PUT_ONE_SEGMENTATION_CLEAN,
  GET_IN_POLICIY_PRODUCTS_REQUEST,
  GET_IN_POLICIY_PRODUCTS_SUCCESS,
  GET_IN_POLICIY_PRODUCTS_CLEAN,
  GET_ACTIVE_CLIENTS_REQUEST,
  GET_ACTIVE_CLIENTS_SUCCESS,
  GET_ACTIVE_CLIENTS_CLEAN,
  POST_PRODUCT_CLIENT_REQUEST,
  POST_PRODUCT_CLIENT_SUCCESS,
  POST_PRODUCT_CLIENT_CLEAN,
  POST_PRODUCT_BL_REQUEST,
  POST_PRODUCT_BL_SUCCESS,
  POST_PRODUCT_BL_CLEAN,
  POST_INCENTIVES_RULES_REQUEST,
  POST_INCENTIVES_RULES_SUCCESS,
  POST_INCENTIVES_RULES_CLEAN,
  GET_INCENTIVES_REQUEST,
  GET_INCENTIVES_SUCCESS,
  GET_INCENTIVES_CLEAN,
  GET_OutPolicyBL_REQUEST,
  GET_OutPolicyBL_SUCCESS,
  GET_OutPolicyBL_CLEAN,
  GET_OutPolicyClient_REQUEST,
  GET_OutPolicyClient_SUCCESS,
  GET_OutPolicyClient_CLEAN,
  GET_InPoliciyProducts_REQUEST,
  GET_InPoliciyProducts_SUCCESS,
  GET_InPoliciyProducts_CLEAN,
  GET_OutPoliciyProducts_REQUEST,
  GET_OutPoliciyProducts_SUCCESS,
  GET_OutPoliciyProducts_CLEAN,
  GET_OutAvailability_REQUEST,
  GET_OutAvailability_SUCCESS,
  GET_OutAvailability_CLEAN,
  GET_InAvailability_REQUEST,
  GET_InAvailability_SUCCESS,
  GET_InAvailability_CLEAN,
  GET_ClientSingleId_REQUEST,
  GET_ClientSingleId_SUCCESS,
  GET_ClientSingleId_CLEAN,
  PUT_IncentivesRules_REQUEST,
  PUT_IncentivesRules_SUCCESS,
  PUT_IncentivesRules_CLEAN,
  PUT_OutPolicyProductBL_REQUEST,
  PUT_OutPolicyProductBL_SUCCESS,
  PUT_OutPolicyProductBL_CLEAN,
  PUT_OutPolicyProductClient_REQUEST,
  PUT_OutPolicyProductClient_SUCCESS,
  PUT_OutPolicyProductClient_CLEAN,
  PUT_AvailabilityProducts_REQUEST,
  PUT_AvailabilityProducts_SUCCESS,
  PUT_AvailabilityProducts_CLEAN,
  EXISTENCE_INCENTIVES_REQUEST,
  EXISTENCE_INCENTIVES_SUCCESS,
  EXISTENCE_INCENTIVES_CLEAN,
} from '../actions';

function oneCase(state = {}, action) {
  const { type } = action;

  switch (type) {
    //Valida_INCENTIVES
    case EXISTENCE_INCENTIVES_REQUEST:
      return {
        ...state,
        isValid: true,
      };
    case EXISTENCE_INCENTIVES_SUCCESS:
      return {
        ...state,
        isValid: false,
        data: action.payload,
      };
    case EXISTENCE_INCENTIVES_CLEAN:
      return {
        ...state,
        isValid: false,
      };

    //PUT_AvailabilityProducts
    case PUT_AvailabilityProducts_REQUEST:
      return {
        ...state,
        isPUTing: true,
      };
    case PUT_AvailabilityProducts_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case PUT_AvailabilityProducts_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    //PUT_OutPolicyProductClient
    case PUT_OutPolicyProductClient_REQUEST:
      return {
        ...state,
        isPUTing: true,
      };
    case PUT_OutPolicyProductClient_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case PUT_OutPolicyProductClient_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    //PUT_OutPolicyProductBL
    case PUT_OutPolicyProductBL_REQUEST:
      return {
        ...state,
        isPUTing: true,
      };
    case PUT_OutPolicyProductBL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case PUT_OutPolicyProductBL_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    //PUT_IncentivesRules
    case PUT_IncentivesRules_REQUEST:
      return {
        ...state,
        isPUTing: true,
      };
    case PUT_IncentivesRules_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case PUT_IncentivesRules_CLEAN:
      return {
        isLoading: false,
      };

    //GET_ClientSingleId
    case GET_ClientSingleId_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case GET_ClientSingleId_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ClientSingleId: action.payload,
      };
    case GET_ClientSingleId_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    //GET_OutAvailability_RULES
    case GET_OutAvailability_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: action.payload,
      };
    case GET_OutAvailability_SUCCESS:
      return {
        ...state,
        isLoading: false,
        OutAvailability: action.payload,
      };
    case GET_OutAvailability_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    //GET_InAvailability_RULES
    case GET_InAvailability_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: action.payload,
      };
    case GET_InAvailability_SUCCESS:
      return {
        ...state,
        isLoading: false,
        InAvailability: action.payload,
      };
    case GET_InAvailability_CLEAN:
      return {
        ...state,
        isLoading: false,
      };
    //GET_OutPoliciyProducts_RULES
    case GET_OutPoliciyProducts_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: action.payload,
      };
    case GET_OutPoliciyProducts_SUCCESS:
      return {
        ...state,
        isLoading: false,
        OutPoliciyProducts: action.payload,
      };
    case GET_OutPoliciyProducts_CLEAN:
      return {
        ...state,
        isLoading: false,
        OutPoliciyProducts: {},
      };

    //GET_InPoliciyProducts_RULES
    case GET_InPoliciyProducts_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: action.payload,
      };
    case GET_InPoliciyProducts_SUCCESS:
      return {
        ...state,
        isLoading: false,
        InPoliciyProducts: action.payload,
      };
    case GET_InPoliciyProducts_CLEAN:
      return {
        ...state,
        isLoading: false,
        InPoliciyProducts: {},
      };

    //GET_OutPolicyClient_RULES
    case GET_OutPolicyClient_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_OutPolicyClient_SUCCESS:
      return {
        ...state,
        isLoading: false,
        OutPolicyClient: action.payload,
      };
    case GET_OutPolicyClient_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //GET_OutPolicyBL_RULES
    case GET_OutPolicyBL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_OutPolicyBL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        OutPolicyBL: action.payload,
      };
    case GET_OutPolicyBL_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //GET_INCENTIVES_RULES
    case GET_INCENTIVES_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: action.payload,
      };
    case GET_INCENTIVES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        INCENTIVES: action.payload,
      };
    case GET_INCENTIVES_CLEAN:
      return {
        ...state,
        isLoading: false,
        INCENTIVES: {},
      };

    //POST_INCENTIVES_RULES
    case POST_INCENTIVES_RULES_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case POST_INCENTIVES_RULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case POST_INCENTIVES_RULES_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //POST_ONE_SEGMENTATION
    case POST_ONE_SEGMENTATION_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case POST_ONE_SEGMENTATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case POST_ONE_SEGMENTATION_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //DELETE_ONE_SEGMENTATION
    case DELETE_ONE_SEGMENTATION_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case DELETE_ONE_SEGMENTATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case DELETE_ONE_SEGMENTATION_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //PUT_ONE_SEGMENTATION
    case PUT_ONE_SEGMENTATION_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case PUT_ONE_SEGMENTATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case PUT_ONE_SEGMENTATION_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //GET_IN_POLICIY_PRODUCTS
    case GET_IN_POLICIY_PRODUCTS_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case GET_IN_POLICIY_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case GET_IN_POLICIY_PRODUCTS_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //GET_ACTIVE_CLIENTS
    case GET_ACTIVE_CLIENTS_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case GET_ACTIVE_CLIENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activeClients: action.payload,
      };
    case GET_ACTIVE_CLIENTS_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //POST_PRODUCT_CLIENT
    case POST_PRODUCT_CLIENT_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case POST_PRODUCT_CLIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case POST_PRODUCT_CLIENT_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    //POST_PRODUCT_BL
    case POST_PRODUCT_BL_REQUEST:
      return {
        ...state,
        isPosting: true,
      };
    case POST_PRODUCT_BL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case POST_PRODUCT_BL_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}

export default oneCase;
