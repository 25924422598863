export const GET_FAMILY_INFORMATION_REQUEST = 'GET_FAMILY_INFORMATION_REQUEST/SALE';
export const GET_FAMILY_INFORMATION_SUCCESS = 'GET_FAMILY_INFORMATION_SUCCESS/SALE';
export const GET_FAMILY_INFORMATION_FAIL = 'GET_FAMILY_INFORMATION_FAIL/SALE';

export function getFamilyInformationRequest(payload) {
  return {
    type: GET_FAMILY_INFORMATION_REQUEST,
    payload,
  };
}

export function getFamilyInformationSuccess(payload) {
  return {
    type: GET_FAMILY_INFORMATION_SUCCESS,
    payload,
  };
}

export function getFamilyInformationFail() {
  return {
    type: GET_FAMILY_INFORMATION_FAIL,
  };
}
