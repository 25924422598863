export const REPORT_REQUEST = 'REPORT_REQUEST';
export const REPORT_SET = 'REPORT_SET';
export const REPORT_CLEAN = 'REPORT_CLEAN';

export function reportRequest(payload) {
  return {
    type: REPORT_REQUEST,
    payload: payload,
  };
}
export function reportSet(payload) {
  return {
    type: REPORT_SET,
    payload: payload,
  };
}
export function reportClean(payload) {
  return {
    type: REPORT_CLEAN,
    payload: payload,
  };
}

export const CONSULT_INFO_BASE_REQUEST = 'CONSULT_INFO_BASE_REQUEST';
export const CONSULT_INFO_BASE_SET = 'CONSULT_INFO_BASE_SET';
export const CONSULT_INFO_BASE_CLEAN = 'CONSULT_INFO_BASE_CLEAN';

export function consultInfoBaseRequestTable(payload) {
  return {
    type: CONSULT_INFO_BASE_REQUEST,
    payload: payload,
  };
}
export function consultInfoBaseSetTable(payload) {
  return {
    type: CONSULT_INFO_BASE_SET,
    payload: payload,
  };
}
export function consultInfoBaseCleanTable(payload) {
  return {
    type: CONSULT_INFO_BASE_CLEAN,
  };
}

export const CHECK_EXISTENCES_STATEMENT_REQUEST = 'CHECK_EXISTENCES_STATEMENT_REQUEST';
export const CHECK_EXISTENCES_STATEMENT_SET = 'CHECK_EXISTENCES_STATEMENT_SET';
export const CHECK_EXISTENCES_STATEMENT_CLEAN = 'CHECK_EXISTENCES_STATEMENT_CLEAN';

export function checkExistencesStatementRequest(payload = {}) {
  return {
    type: CHECK_EXISTENCES_STATEMENT_REQUEST,
    payload: payload,
  };
}

export function checkExistencesStatementSet(payload = {}) {
  return {
    type: CHECK_EXISTENCES_STATEMENT_SET,
    payload: payload,
  };
}

export function checkExistencesStatementClean(payload = {}) {
  return {
    type: CHECK_EXISTENCES_STATEMENT_CLEAN,
    payload: payload,
  };
}

export const SEND_ACCOUNT_STATEMENT_REQUEST = 'SEND_ACCOUNT_STATEMENT_REQUEST';
export const SEND_ACCOUNT_STATEMENT_SET = 'SEND_ACCOUNT_STATEMENT_SET';
export const SEND_ACCOUNT_STATEMENT_CLEAN = 'SEND_ACCOUNT_STATEMENT_CLEAN';

export function sendAccountStatementRequest(payload) {
  return {
    type: SEND_ACCOUNT_STATEMENT_REQUEST,
    payload: payload,
  };
}
export function sendAccountStatementSet(payload) {
  return {
    type: SEND_ACCOUNT_STATEMENT_SET,
    payload: payload,
  };
}
export function sendAccountStatementClean(payload) {
  return {
    type: SEND_ACCOUNT_STATEMENT_CLEAN,
  };
}
