export const SALESBYFAMILY_CLIENTS_REQUEST = 'SALESBYFAMILY_CLIENTS_REQUEST';
export const SALESBYFAMILY_CLIENTS_SET = 'SALESBYFAMILY_CLIENTS_SET';
export const SALESBYFAMILY_CLIENTS_CLEAN = 'SALESBYFAMILY_CLIENTS_CLEAN';

export const SALESBYFAMILY_SUBREGIONS_REQUEST = 'SALESBYFAMILY_SUBREGIONS_REQUEST';
export const SALESBYFAMILY_SUBREGIONS_SET = 'SALESBYFAMILY_SUBREGIONS_SET';
export const SALESBYFAMILY_SUBREGIONS_CLEAN = 'SALESBYFAMILY_SUBREGIONS_CLEAN';

export function salesByFamilySubgregionsRequest(payload) {
  return {
    type: SALESBYFAMILY_SUBREGIONS_REQUEST,
    payload: payload,
  };
}

export function salesByFamilySubregionsSet(payload) {
  return {
    type: SALESBYFAMILY_SUBREGIONS_SET,
    payload: payload,
  };
}

export function salesByFamilySubregionsClean(payload) {
  return {
    type: SALESBYFAMILY_SUBREGIONS_CLEAN,
    payload: payload,
  };
}

export function salesByFamilyClientRequest(payload) {
  return {
    type: SALESBYFAMILY_CLIENTS_REQUEST,
    payload: payload,
  };
}

export function salesByFamilyClientSet(payload) {
  return {
    type: SALESBYFAMILY_CLIENTS_SET,
    payload: payload,
  };
}

export function salesByFamilyClientClean(payload) {
  return {
    type: SALESBYFAMILY_CLIENTS_CLEAN,
    payload: payload,
  };
}
