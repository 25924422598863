import {
  ADD_SALES_CHALLENGE_VALIDATOR_REQUEST,
  ADD_SALES_CHALLENGE_VALIDATOR_SUCCESS,
  ADD_SALES_CHALLENGE_VALIDATOR_FAIL,
} from '../actions/addSalesChallengeValidator';

const initialState = {
  loading: false,
};

const addSalesChallengeValidatorReducer = (state = initialState, action) => {
  const { type } = action;
  //const { validators, counter } = state;
  switch (type) {
    case ADD_SALES_CHALLENGE_VALIDATOR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ADD_SALES_CHALLENGE_VALIDATOR_SUCCESS:
    case ADD_SALES_CHALLENGE_VALIDATOR_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default addSalesChallengeValidatorReducer;
