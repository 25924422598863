export const stateInitialParams = {
  resumeIncentives: {
    sectionId: 4,
    activeClients: [],
    inactiveClients: [],
    activeSubRegions: [],
    inactiveSubRegions: [],
  },
  shopSections: {
    sectionId: 2,
    activeClients: [],
    inactiveClients: [],
    activeSubRegions: [],
    inactiveSubRegions: [],
  },
  pogSections: {
    sectionId: 3,
    activeClients: [],
    inactiveClients: [],
    activeSubRegions: [],
    inactiveSubRegions: [],
  },
  creditNotesSections: {
    sectionId: 8,
    activeClients: [],
    inactiveClients: [],
    activeSubRegions: [],
    inactiveSubRegions: [],
  },
  salesFamiySections: {
    sectionId: 6,
    activeClients: [],
    inactiveClients: [],
    activeSubRegions: [],
    inactiveSubRegions: [],
  },
  creditLineSections: {
    sectionId: 5,
    activeClients: [],
    inactiveClients: [],
    activeSubRegions: [],
    inactiveSubRegions: [],
  },
  otherSections: {
    sectionId: 7,
    activeClients: [],
    inactiveClients: [],
    activeSubRegions: [],
    inactiveSubRegions: [],
  },
};

export function setStateParams(updateSendParams, sendParams, state) {
  const [
    incentiveResumeData,
    incentiveResumeSubRegions,
    shopSectionData,
    shopSectionSubRegions,
    pogSectionData,
    pogsubregionData,
    incentiveCreditNotesData,
    incSubregionsData,
    salesByFamilyClient,
    salesByFamilySubregions,
    oclClientsData,
    oclSubregionsData,
    othersSectionClients,
    othersSectionSubRegions,
  ] = state;

  updateSendParams({
    ...sendParams,
    resumeIncentives: {
      ...sendParams.resumeIncentives,
      activeClients: incentiveResumeData
        .filter((d) => d.active === 1)
        .map((d) => ({ lineIdCAct: d.lineId.toString(), clientIdAct: d.clientId })),
      inactiveClients: incentiveResumeData
        .filter((d) => d.active === 0)
        .map((d) => ({ lineIdCInact: d.lineId.toString(), clientIdInact: d.clientId })),
      activeSubRegions: incentiveResumeSubRegions
        .filter((d) => d.active === 1)
        .map((d) => ({ lineIdAct: d.lineId, subRegionIdAct: d.subregionId })),
      inactiveSubRegions: incentiveResumeSubRegions
        .filter((d) => d.active === 0)
        .map((d) => ({ lineIdInact: d.lineId, subRegionIdInact: d.subregionId })),
    },
    shopSections: {
      ...sendParams.shopSections,
      activeClients: shopSectionData
        .filter((d) => d.active === 1)
        .map((d) => ({ lineIdCAct: d.lineId.toString(), clientIdAct: d.clientId })),
      inactiveClients: shopSectionData
        .filter((d) => d.active === 0)
        .map((d) => ({ lineIdCInact: d.lineId.toString(), clientIdInact: d.clientId })),
      activeSubRegions: shopSectionSubRegions
        .filter((d) => d.active === 1)
        .map((d) => ({ lineIdAct: d.lineId, subRegionIdAct: d.subregionId })),
      inactiveSubRegions: shopSectionSubRegions
        .filter((d) => d.active === 0)
        .map((d) => ({ lineIdInact: d.lineId, subRegionIdInact: d.subregionId })),
    },
    pogSections: {
      ...sendParams.pogSections,
      activeClients: pogSectionData
        .filter((d) => d.active === 1)
        .map((d) => ({ lineIdCAct: d.lineId.toString(), clientIdAct: d.clientId })),
      inactiveClients: pogSectionData
        .filter((d) => d.active === 0)
        .map((d) => ({ lineIdCInact: d.lineId.toString(), clientIdInact: d.clientId })),
      activeSubRegions: pogsubregionData
        .filter((d) => d.active === 1)
        .map((d) => ({ lineIdAct: d.lineId, subRegionIdAct: d.subregionId })),
      inactiveSubRegions: pogsubregionData
        .filter((d) => d.active === 0)
        .map((d) => ({ lineIdInact: d.lineId, subRegionIdInact: d.subregionId })),
    },
    creditNotesSections: {
      ...sendParams.creditNotesSections,
      activeClients: incentiveCreditNotesData
        .filter((d) => d.active === 1)
        .map((d) => ({ lineIdCAct: d.lineId.toString(), clientIdAct: d.clientId })),
      inactiveClients: incentiveCreditNotesData
        .filter((d) => d.active === 0)
        .map((d) => ({ lineIdCInact: d.lineId.toString(), clientIdInact: d.clientId })),
      activeSubRegions: incSubregionsData
        .filter((d) => d.active === 1)
        .map((d) => ({ lineIdAct: d.lineId, subRegionIdAct: d.subregionId })),
      inactiveSubRegions: incSubregionsData
        .filter((d) => d.active === 0)
        .map((d) => ({ lineIdInact: d.lineId, subRegionIdInact: d.subregionId })),
    },
    salesFamiySections: {
      ...sendParams.salesFamiySections,
      activeClients: salesByFamilyClient
        .filter((d) => d.active === 1)
        .map((d) => ({ lineIdCAct: d.lineId.toString(), clientIdAct: d.clientId })),
      inactiveClients: salesByFamilyClient
        .filter((d) => d.active === 0)
        .map((d) => ({ lineIdCInact: d.lineId.toString(), clientIdInact: d.clientId })),
      activeSubRegions: salesByFamilySubregions
        .filter((d) => d.active === 1)
        .map((d) => ({ lineIdAct: d.lineId, subRegionIdAct: d.subregionId })),
      inactiveSubRegions: salesByFamilySubregions
        .filter((d) => d.active === 0)
        .map((d) => ({ lineIdInact: d.lineId, subRegionIdInact: d.subregionId })),
    },
    creditLineSections: {
      ...sendParams.creditLineSections,
      activeClients: oclClientsData
        .filter((d) => d.active === 1)
        .map((d) => ({ lineIdCAct: d.lineId.toString(), clientIdAct: d.clientId })),
      inactiveClients: oclClientsData
        .filter((d) => d.active === 0)
        .map((d) => ({ lineIdCInact: d.lineId.toString(), clientIdInact: d.clientId })),
      activeSubRegions: oclSubregionsData
        .filter((d) => d.active === 1)
        .map((d) => ({ lineIdAct: d.lineId, subRegionIdAct: d.subregionId })),
      inactiveSubRegions: oclSubregionsData
        .filter((d) => d.active === 0)
        .map((d) => ({ lineIdInact: d.lineId, subRegionIdInact: d.subregionId })),
    },
    otherSections: {
      ...sendParams.otherSections,
      activeClients: othersSectionClients
        .filter((d) => d.active === 1)
        .map((d) => ({ lineIdCAct: d.lineId.toString(), clientIdAct: d.clientId })),
      inactiveClients: othersSectionClients
        .filter((d) => d.active === 0)
        .map((d) => ({ lineIdCInact: d.lineId.toString(), clientIdInact: d.clientId })),
      activeSubRegions: othersSectionSubRegions
        .filter((d) => d.active === 1)
        .map((d) => ({ lineIdAct: d.lineId, subRegionIdAct: d.subregionId })),
      inactiveSubRegions: othersSectionSubRegions
        .filter((d) => d.active === 0)
        .map((d) => ({ lineIdInact: d.lineId, subRegionIdInact: d.subregionId })),
    },
  });
}
