import { toBase64, openNotification } from 'common';
import ValidationMessage from 'AccountStatus/ManageImages/utils/validationMessage';

// Función de validaciones de archivos
const validations = async ({
  inputFiles,
  setInputImages,
  setinputFiles,
  multiplesFiles,
  rowClick,
  changeImageData,
  GETListImages,
  PUTImageLine,
}) => {
  // Validation ranges
  let maxWidth = 649;
  let maxHeigth = 331;
  let maxSize = 5242880;
  let typeFile = 'image/jpeg';

  // Local data validation
  let arrayImgBase64 = [];
  let arrayImgHtml = [];
  let breakValidation = [];

  // Validar Tamaño y Tipo de archivo
  for await (const file of inputFiles) {
    if (file.size < maxSize && file.type === typeFile) {
      let imageBase64 = await toBase64(file);
      arrayImgBase64.push(imageBase64);
    } else {
      if (file.size >= maxSize) {
        breakValidation.push({ name: file.name, typeError: 'Tamaño mayor a 5 MB', multiplesFiles });
      }
      if (file.type !== typeFile) {
        breakValidation.push({
          name: file.name,
          typeError: 'Formato distinto a JPG',
          multiplesFiles,
        });
      }
    }
  }

  // Pasar de Base64 a img html para obtener valores whidth y height
  for await (const imgB64 of arrayImgBase64) {
    let newImg = new Image();
    newImg.src = imgB64;
    arrayImgHtml.push(newImg);
  }
  // Validar Dimensiones de imagenes
  for (let key in arrayImgHtml) {
    let imgSizes = arrayImgHtml[key];
    if (imgSizes.width !== maxWidth && imgSizes.height !== maxHeigth && inputFiles[key]) {
      breakValidation.push({
        name: inputFiles[key].name,
        typeError: 'Dimensiones distintas a Ancho=649, Alto=331',
        multiplesFiles,
      });
      setInputImages([]);
    }
  }
  //Si hay errores, se muestran y se detiene el flujo
  if (breakValidation.length > 0) {
    ValidationMessage(breakValidation);
    setInputImages([]);
    setinputFiles([]);
    return;
  }

  //Add Images, DrawerAdd
  if (multiplesFiles === true) {
    setInputImages(arrayImgBase64);
  }

  //Change one image
  if (multiplesFiles === false) {
    let endpoints = {
      LineId: {
        endpoint: '/AccountStatement/Api/v1/Files/ImageLine',
      },
      SubRegionId: {
        endpoint: '/AccountStatement/Api/v1/Files/ImageSubregion',
      },
      ClientId: {
        endpoint: '/AccountStatement/Api/v1/Files/ImageClient',
      },
      TerritoryId: {
        endpoint: '/AccountStatement/Api/v1/Files/ImageTerritory',
      },
    };
    let comercialStructure = {
      LineId: { LineId: rowClick.lineId, Type: 1 },
      SubRegionId: { LineId: rowClick.lineId, SubregionId: rowClick.subregionId, Type: 2 },
      ClientId: {
        ClientId: rowClick.clientId,
        LineId: rowClick.lineId,
        SubregionId: rowClick.subRegionId,
        Type: 4,
      },
      TerritoryId: {
        TerritoryId: rowClick.territoryId,
        LineId: rowClick.lineId,
        SubregionId: rowClick.subRegionId,
        Type: 3,
      },
    };
    let dataEndpoint = endpoints[rowClick.type];
    let newImage = arrayImgBase64[0].substr(23);

    //Datos para Back
    let imageInfo = {
      fileId: changeImageData.fileId,
      image: newImage,
      fileName: changeImageData.fileName,
      month: changeImageData.month,
      period: changeImageData.order,
      description: changeImageData.description,
      replaced: 1,
      ...comercialStructure[rowClick.type],
    };

    //Si se carga correctamente la imagen
    const okPUT = async () => {
      openNotification(
        'success',
        `El reemplazo de la imagen de estado de cuenta se realizó correctamente`
      );

      // Recarga imagenes de Estructura comercial especifica
      let dataGet = { listImages: comercialStructure[rowClick.type] };
      await GETListImages(dataGet);
    };

    //Ejecuta la carga de imagen a Back
    PUTImageLine({ imageInfo, dataEndpoint, okPUT });
  }
};
export default validations;
