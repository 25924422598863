/* eslint-disable no-constant-condition */
export default function getFloatParser(parameters) {
  const { integerLength, floatLength, replaceParams } = parameters;

  const parserFunc = (value) => {
    //   const clean = value.replace(/,/g, '');
    const clean = value.replace(replaceParams[0], replaceParams[1]),
      dotIndex = clean.indexOf('.'),
      noDot = dotIndex === -1,
      totalDots = (clean.match(/\./g) || []).length;

    if (noDot) {
      if (clean.length <= integerLength) {
        return clean;
      }
      return clean.substr(0, integerLength);
    }

    if (dotIndex === 0 && totalDots === 1) {
      return clean.substr(0, floatLength + 1);
    }

    if (totalDots === 1 && dotIndex === clean.length - 1) {
      if (clean.length <= integerLength + 1) {
        return clean;
      }
      return `${clean.substring(0, integerLength)}.`;
    }

    if (true) {
      const cleanArray = clean.split('.');
      let integer, float;

      if (cleanArray[0].length > integerLength) {
        integer = (cleanArray[0] ?? '').substring(0, integerLength);
      } else {
        integer = `${cleanArray[0]}`;
      }

      if (cleanArray[1].length > floatLength) {
        float = cleanArray[1].substring(0, floatLength);
      } else {
        float = `${cleanArray[1]}`;
      }

      return `${integer}.${float}`;
    }

    return clean;
  };

  return parserFunc;
}
