/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// antd
import { Modal, Button, Spin } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';

//Styles
import './modal.css';

//Icons
import { IconsMap } from 'Layout/utils';

import html2canvas from 'html2canvas';
import { base64ToFile, downloadResumePDF, getFileCount, getFileResume } from '../../services';
import { openNotification } from '../../common';
import { catalogFiles } from '../../services/dashboard/sales-challenge';
import {
  getAgreementCountSheet,
  getAgreementResumePDF,
  getAgreementResumeSheet,
} from '../CommercialAgreement/Services/ComercialAgrementDashService';

export const nameFiles = {
  salesChallenge: 'Dashboard Desafío',
  statusChallenge: 'Tabla de estatus de desafío de ventas',
  commercialAgreement: 'Dashboard Acuerdo',
};

const ModalSC = ({ tableId, filters, query, disable, nameFile, queryForPDF }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pdfSpin, setPdfSpin] = useState(false);
  const [sheetSpin, setSheetSpin] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const downloadPDF = () => {
    setPdfSpin(true);
    if (tableId === tableRef.salesChallenge) {
      downloadResumePDF(query)
        .then((res) => {
          base64ToFile(res.Data, catalogFiles.pdf, nameFile);
          setPdfSpin(false);
          handleCancel();
        })
        .catch(() => {
          setPdfSpin(false);
          handleCancel();
        });
    } else if (tableId === tableRef.agreementReportTable) {
      getAgreementResumePDF(queryForPDF)
        .then((r) => {
          base64ToFile(r.Data, catalogFiles.pdf, nameFile);
          setPdfSpin(false);
          handleCancel();
        })
        .catch((e) => {
          setPdfSpin(false);
          handleCancel();
        });
    }
  };

  const downloadExcel = () => {
    setSheetSpin(true);
    if (tableId === tableRef.salesChallenge) {
      getFileResume(query)
        .then((res) => {
          setSheetSpin(false);
          if (res.length > 0) {
            base64ToFile(res, catalogFiles.excel, nameFiles.salesChallenge);
          }
          handleCancel();
        })
        .catch(() => {
          setSheetSpin(false);
          handleCancel();
        });
    } else if (tableId === tableRef.salesStatus) {
      getFileCount(query)
        .then((res) => {
          setSheetSpin(false);
          if (res) {
            base64ToFile(res, catalogFiles.excel, nameFiles.salesChallenge);
          }
          handleCancel();
        })
        .catch(() => {
          setSheetSpin(false);
          handleCancel();
        });
    } else if (tableId === tableRef.agreementReportTable) {
      getAgreementResumeSheet(query)
        .then((res) => {
          setSheetSpin(false);
          base64ToFile(res, catalogFiles.excel, nameFiles.commercialAgreement);
          handleCancel();
        })
        .catch(handleCancel);
    } else if (tableId === tableRef.agreementStatusTAble) {
      getAgreementCountSheet(query)
        .then((res) => {
          setSheetSpin(false);
          base64ToFile(res, catalogFiles.excel, nameFiles.commercialAgreement);
          handleCancel();
        })
        .catch(handleCancel);
    }
  };

  const doCapture = () => {
    html2canvas(document.getElementById(tableId), { allowTaint: true }).then(function (canvas) {
      handleCancel();
      window.scrollTo(0, 0);
      const date = new Date();
      const dateFormatted =
        date.getDate() +
        '_' +
        (parseInt(date.getMonth().toString()) + 1 > 9 ? '' : '0') +
        (parseInt(date.getMonth().toString()) + 1) +
        '_' +
        date.getFullYear();
      const a = document.createElement('a');
      document.body.appendChild(canvas);
      a.href = canvas.toDataURL();
      a.download = `${nameFile} ${dateFormatted}.jpg`;
      a.click();
      a.onclose = () => setIsModalVisible(false);
      openNotification(
        'success',
        'La descarga de información de tabla dashboard se realizó correctamente'
      );
    });
  };

  const Icon = IconsMap['ExclamationCircleOutlined'];

  return (
    <>
      <Button
        className="margin ant-btn-info"
        disabled={disable}
        onClick={showModal}
        icon={<ArrowDownOutlined />}
      >
        Exportar
      </Button>
      <Modal
        title={
          // El código de implementación principal puede pasar en un componente de estructura html aquí también es posible
          [
            <div key={tableId} className="center-title">
              <Icon className="center-title-Ic" />
              Formato de exportación
            </div>,
          ]
        }
        maskClosable={true}
        centered
        visible={isModalVisible}
        onCancel={handleCancel}
        cancelButtonProps={{
          type: 'default',
          style: { backgroundColor: '#0078B3', color: '#ffffff' },
        }}
        okButtonProps={{ style: { display: 'none' } }}
        className="center"
      >
        <p className="center">Seleccione el formato en el que desea exportar el archivo</p>

        <div className="modal-dash-Btn-c">
          <Button className="excel-Btn" onClick={downloadExcel}>
            {sheetSpin === true && <Spin />}
            {sheetSpin === false && 'Excel'}
          </Button>
          <Button className="img-Btn mr" onClick={doCapture}>
            Imagen
          </Button>
          {filters && (
            <Button className="img-Btn" onClick={downloadPDF}>
              {pdfSpin === true && <Spin />}
              {pdfSpin === false && 'PDF'}
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

export const tableRef = {
  salesChallenge: 'table1',
  salesStatus: 'table2',
  agreementReportTable: 'agreementReport',
  agreementStatusTAble: 'agreementStatus',
};

ModalSC.propTypes = {
  tableId: PropTypes.string.isRequired,
  nameFile: PropTypes.string.isRequired,
  filters: PropTypes.object,
  query: PropTypes.string,
  queryForPDF: PropTypes.string,
  disable: PropTypes.bool,
};

export default ModalSC;
