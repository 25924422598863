import request from 'services/request';

const URL_CATALOGS = '/Catalogs/Api/v1';

export async function getBusinessStructure() {
  const CancelToken = await request.CancelToken.source();
  const source = CancelToken.token;
  return request(`${URL_CATALOGS}/BusinessStructure`, {
    method: 'GET',
    cancelToken: source.token,
  });
}

export async function getCountriesNotInZone(zoneId) {
  const CancelToken = await request.CancelToken.source();
  const source = CancelToken.token;
  return request(`${URL_CATALOGS}/BusinessStructure/CountriesNotInZone/${zoneId}`, {
    method: 'GET',
    cancelToken: source.token,
  });
}

//GET All Territorys
export async function getTerritorys(zoneId) {
  const CancelToken = await request.CancelToken.source();
  const source = CancelToken.token;
  return request(`${URL_CATALOGS}/BusinessStructure/TerritoriesNotInZone/${zoneId}`, {
    method: 'GET',
    cancelToken: source.token,
  });
}

export async function updateBusinessStructure(updateStructure, data) {
  const CancelToken = await request.CancelToken.source();
  const source = CancelToken.token;

  return request(`${URL_CATALOGS}/BusinessStructure/${updateStructure}/`, {
    method: 'POST',
    cancelToken: source.token,
    data,
  });
}

export async function getCountries() {
  const CancelToken = await request.CancelToken.source();
  const source = CancelToken.token;

  return request(`${URL_CATALOGS}//`, {
    method: 'GET',
    cancelToken: source.token,
  });
}

export async function updateCountries(zoneId, data) {
  const CancelToken = await request.CancelToken.source();
  const source = CancelToken.token;
  return request(`${URL_CATALOGS}/BusinessStructure/CountriesToZone/${zoneId}/`, {
    method: 'PUT',
    cancelToken: source.token,
    data,
  });
}

export async function updateTerritoryEnable(data) {
  const { territoryId, enabled } = data;
  const CancelToken = await request.CancelToken.source();
  const source = CancelToken.token;
  return request(`${URL_CATALOGS}/BusinessStructure/Territory/Enable/${territoryId}/${enabled}`, {
    method: 'PUT',
    cancelToken: source.token,
    data,
  });
}

export async function updateZoneEnable(data) {
  const { zoneId, enabled } = data;
  const CancelToken = await request.CancelToken.source();
  const source = CancelToken.token;
  return request(`${URL_CATALOGS}/BusinessStructure/Zone/Enable/${zoneId}/${enabled}`, {
    method: 'PUT',
    cancelToken: source.token,
    data,
  });
}

export async function updateCountryEnable(data) {
  const { countryId, enabled } = data;
  const CancelToken = await request.CancelToken.source();
  const source = CancelToken.token;
  return request(`${URL_CATALOGS}/BusinessStructure/Country/Enable/${countryId}/${enabled}`, {
    method: 'PUT',
    cancelToken: source.token,
    data,
  });
}

export async function updateElementBusinessStructure(requestType, requestId, data) {
  const CancelToken = await request.CancelToken.source();
  const source = CancelToken.token;

  return request(`${URL_CATALOGS}/BusinessStructure/${requestType}/${requestId}`, {
    method: 'PUT',
    cancelToken: source.token,
    data,
  });
}

export async function updateReAsignZone(requestId, territoryId) {
  const CancelToken = await request.CancelToken.source();
  const source = CancelToken.token;

  return request(`${URL_CATALOGS}/BusinessStructure/Zone/Reasign/${requestId}/${territoryId}`, {
    method: 'PUT',
    cancelToken: source.token,
  });
}

export async function deleteElementBusinessStructure(requestType, data) {
  const CancelToken = await request.CancelToken.source();
  const source = CancelToken.token;

  return request(`${URL_CATALOGS}/BusinessStructure/${requestType}`, {
    method: 'DELETE',
    cancelToken: source.token,
    params: data,
  });
}
