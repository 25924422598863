import request from 'services/request';
const serviceURL = '/Catalogs/Api/v1/Segmentation';
//AGREGAR SEGMENTACIÓN
export async function postOneSegmentation({ cancelToken, data }) {
  return request(`${serviceURL}`, {
    method: 'POST',
    cancelToken,
    data,
  });
}
//ELIMINAR SEGMENTACIÓN
export async function deleteOneSegmentation({ cancelToken, data }) {
  return request(`${serviceURL}`, {
    method: 'DELETE',
    cancelToken,
    data,
  });
}
//MODIFICAR SEGMENTACIÓN
export async function putOneSegmentation({ cancelToken, data }) {
  return request(`${serviceURL}`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}
//OBTENER LISTA DE SEGMENTACIONES
export async function getInPoliciyProducts({ cancelToken, params }) {
  return request(`/Agreements/api/v1/Rules/IncentivesRules/InPoliciyProducts`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
//OBTENER CLIENTES
export async function getActiveClients({ cancelToken, params }) {
  return request(`/Catalogs/Api/v1/Clients/BySoldTo`, {
    method: 'GET',
    cancelToken,
  });
}
//AGREGAR PRODUCTOS POR CLIENTE
export async function postProductClient({ cancelToken, data }) {
  return request(`/Agreements/api/v1/Rules/OutPolicyProductClient`, {
    method: 'POST',
    cancelToken,
    data,
  });
}
//Obtiene el Id de cliente en base a la linea de negocio y SoldTo
export async function getClientSingleId({ cancelToken, params }) {
  return request(`/Catalogs/Api/v1/Clients/ClientSingleId`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
//AGREGAR PRODUCTOS POR BL
export async function postProductBL({ cancelToken, data }) {
  return request(`/Agreements/api/v1/Rules/OutPolicyProductBL`, {
    method: 'POST',
    cancelToken,
    data,
  });
}
//AGREGAR REGISTRO DE INCENTIVOS
export async function postIncentivesRules({ cancelToken, data }) {
  return request(`/Agreements/api/v1/Rules/IncentivesRules`, {
    method: 'POST',
    cancelToken,
    data,
  });
}

//REVISAR QUE YA EXISTE El REGISTRO
export async function postExistenceIncentives({ cancelToken, data }) {
  return request(
    `/Agreements/api/v1/Rules/CheckExistanceIncentives?LineId=${data.LineId}&SubRegionId=${data.SubRegionId}&SegmentationId=${data.SegmentationId}`,
    {
      method: 'GET',
      cancelToken,
    }
  );
}
