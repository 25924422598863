import React from 'react';
import { Col } from 'antd';
import { IconContext } from 'react-icons';
import { BsArrowCounterclockwise } from 'react-icons/bs';

const ResetButton = (props) => {
  const { clearFilters, floatToRight, span } = props;
  const colStyle = { marginRight: '10px' };
  const buttonStyle = {
    outline: 'none',
    height: '30px',
    marginTop: '1.62em',
  };
  const IconContextValue = { size: '1em', color: '#fbfbfb' };

  if (floatToRight) {
    colStyle.marginLeft = 'auto';
  }

  return (
    <Col style={colStyle} span={span}>
      <button className="ant-btn ant-btn-info" onClick={clearFilters} style={buttonStyle}>
        <IconContext.Provider value={IconContextValue}>
          <BsArrowCounterclockwise />
        </IconContext.Provider>
      </button>
    </Col>
  );
};

export default ResetButton;
