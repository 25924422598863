/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import { useHistory } from 'react-router-dom';

import { Subregion, BusinessLine, Segmentation, Placement } from 'RootModule/consultFilters/';

import ConsultTable from 'common/consults/components/Table';
import FilterWrapper from 'common/consults/components/FilterWrapper';
import SearchInput from 'common/consults/components/SearchInput';
import ResetButton from 'common/consults/components/ResetButton';
import FilterLink from 'common/consults/components/FilterLink';

import getAccountStatusDynamicElementsModule from '../getAccountStatusDynamicElementsModule';

import { accountStatusDynamicElementsRequestConsultTable } from '../redux-saga/actions';
import {
  consultFiltersBusinessLineClearValue,
  consultFiltersSubregionClearValue,
  consultFiltersSegmentationClearValue,
  consultFiltersPlacementClearValue,
  consultFiltersSearchInputClearValue,
} from 'RootModule/redux/actions/consultFilters';
import consultTableSchema from '../schemas/consultTableSchema';

import { layoutContext } from 'context';
// import RenderPermissions from 'common/components/RenderPermissions/RenderPermissions';

const AccountStatusDynamicElements = (props) => {
  const {
    accountStatusDynamicElementsRequestConsultTable: requestConsultTable,
    consultFiltersBusinessLineClearValue: clearBusinessLines,
    consultFiltersSubregionClearValue: clearSubregions,
    consultFiltersSegmentationClearValue: clearSegmentation,
    consultFiltersPlacementClearValue: clearPlacement,
    consultFiltersSearchInputClearValue: clearSearchInput,
    consultTable,
    isLoading,
    BusinessLinesValue,
    SubRegionsValue,
    SegmentationValue,
    PlacementValue,
    SearchInputValue,
  } = props;

  const { onChangeActiveComponent } = useContext(layoutContext);
  const history = useHistory();

  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, []);

  useEffect(() => {
    let consultTableParams = {};

    onChangeActiveComponent('BusinessStructureMassiveUpload');

    if (BusinessLinesValue?.length > 0) {
      consultTableParams.Line = BusinessLinesValue;
    }
    if (SubRegionsValue?.length > 0) {
      consultTableParams.Subregion = SubRegionsValue;
    }
    if (SegmentationValue?.length > 0) {
      consultTableParams.Segmentation = SegmentationValue;
    }
    if (PlacementValue?.length > 0) {
      consultTableParams.Placement = PlacementValue;
    }
    if (SearchInputValue?.length > 0) {
      consultTableParams.Wildcard = [SearchInputValue];
    }

    // Wildcard: '',

    requestConsultTable({ params: consultTableParams, history });
  }, [BusinessLinesValue, SubRegionsValue, SegmentationValue, PlacementValue, SearchInputValue]);

  const clearFilters = () => {
    clearBusinessLines();
    clearSubregions();
    clearSegmentation();
    clearPlacement();
    clearSearchInput();
  };

  return (
    <div>
      <FilterWrapper>
        <BusinessLine />
        <Subregion />
        <Segmentation />
        <Placement />

        {/* <RenderPermissions
          permission={`admin_dynamic-items`}
          components={ */}
        <FilterLink
          uri={'/account-status/dynamic-items/admin'}
          label={'Administrar Secciones'}
          floatToRight={true}
        />
        {/* }
        /> */}

        <SearchInput />
        <ResetButton clearFilters={clearFilters} />
      </FilterWrapper>
      <ConsultTable
        data={consultTable}
        columns={consultTableSchema}
        loading={isLoading}
        noResultsMessage={
          'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados.'
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    consultTable: state?.ACCOUNT_STATUS_DYNAMIC_ELEMENTS?.consultTable,
    isLoading: state?.ACCOUNT_STATUS_DYNAMIC_ELEMENTS?.isLoading,
    BusinessLinesValue: state?.APP?.consultFilters.businessLine?.value,
    SubRegionsValue: state?.APP?.consultFilters?.subregion?.value,
    SegmentationValue: state?.APP?.consultFilters?.segmentation?.value,
    PlacementValue: state?.APP?.consultFilters?.placement?.value,
    SearchInputValue: state?.APP?.consultFilters?.searchInput?.value,
  };
};

const ConnectedAccountStatusDynamicElements = connect(mapStateToProps, {
  accountStatusDynamicElementsRequestConsultTable,
  consultFiltersBusinessLineClearValue,
  consultFiltersSubregionClearValue,
  consultFiltersSegmentationClearValue,
  consultFiltersPlacementClearValue,
  consultFiltersSearchInputClearValue,
})(AccountStatusDynamicElements);

const AccountStatusDynamicElementsWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getAccountStatusDynamicElementsModule()]}>
      <ConnectedAccountStatusDynamicElements />
    </DynamicModuleLoader>
  );
};
export default AccountStatusDynamicElementsWithModules;
