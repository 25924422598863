import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  permissions: false,
};
function useFindPermissions(moduleKey, subKey) {
  const [stateRequest, setStateRequest] = useState(initialState);
  const wasRendered = useRef(false);

  useEffect(() => {
    const getPermissions = async () => {
      try {
        let permissions = localStorage.getItem('permissions');

        if (permissions && permissions.length > 0 && moduleKey) {
          permissions = JSON.parse(permissions);
          let moduleFound = permissions.filter((m) => m.uri === moduleKey);

          if (moduleFound.length > 0) {
            if (subKey) {
              let subFound = moduleFound[0].components.filter((sub) => sub.uri === subKey);
              if (subFound.length > 0) {
                setStateRequest({
                  permissions: subFound[0].components,
                });
              } else {
                setStateRequest({
                  permissions: false,
                });
              }
            } else {
              setStateRequest({
                permissions: moduleFound,
              });
            }
          }
        }
      } catch (error) {
        if (wasRendered.current) setStateRequest(initialState);
      }
    };
    if (!wasRendered.current) getPermissions();
    return () => {
      wasRendered.current = false;
    };
  }, [moduleKey, subKey]);
  return stateRequest;
}

useFindPermissions.propTypes = {
  subkey: PropTypes.any,
  module: PropTypes.any,
};

useFindPermissions.defaultProps = {
  subkey: false,
  module: false,
};

export default useFindPermissions;
