export const ACCOUNT_STATUS_DYNAMIC_ELEMENTS_REQUEST_CONSULT_TABLE =
  'ACCOUNT_STATUS_DYNAMIC_ELEMENTS_REQUEST_CONSULT_TABLE';
export const ACCOUNT_STATUS_DYNAMIC_ELEMENTS_SET_CONSULT_TABLE =
  'ACCOUNT_STATUS_DYNAMIC_ELEMENTS_SET_CONSULT_TABLE';
export const ACCOUNT_STATUS_DYNAMIC_ELEMENTS_CLEAR_CONSULT_TABLE =
  'ACCOUNT_STATUS_DYNAMIC_ELEMENTS_CLEAR_CONSULT_TABLE';

export function accountStatusDynamicElementsRequestConsultTable(payload) {
  return {
    type: ACCOUNT_STATUS_DYNAMIC_ELEMENTS_REQUEST_CONSULT_TABLE,
    payload: payload,
  };
}

export function accountStatusDynamicElementsSetConsultTable(payload) {
  return {
    type: ACCOUNT_STATUS_DYNAMIC_ELEMENTS_SET_CONSULT_TABLE,
    payload: payload,
  };
}

export function accountStatusDynamicElementsClearConsultTable(payload) {
  return {
    type: ACCOUNT_STATUS_DYNAMIC_ELEMENTS_CLEAR_CONSULT_TABLE,
  };
}
