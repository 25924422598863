import reducer from './redux-saga/reducers/';

import { watchAccountStatusDynamicElementsRequestConsultTable } from './redux-saga/sagas';

export default function getAccountStatusDynamicElementsModule() {
  return {
    id: 'ACCOUNT_STATUS_DYNAMIC_ELEMENTS',
    reducerMap: {
      ACCOUNT_STATUS_DYNAMIC_ELEMENTS: reducer,
    },
    sagas: [watchAccountStatusDynamicElementsRequestConsultTable],
  };
}
