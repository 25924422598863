import React from 'react';
import { Row, Col, Form, Select, Button } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { getResponsablePositionRequest } from '../redux-saga/actions';
import { getPopupContainer } from 'SalesChallengeRefactor/helpers/helperForms';
import { validaForm } from '../utils';
const Manager = ({ getClientData, value, SetValue, isNacional, form, setIsActiveRegister }) => {
  const dispatch = useDispatch();

  const { Option } = Select;
  const onChangeValue = () => {
    let newArray = [...value];
    let finalValue = 0;
    if (newArray.length > 0) {
      finalValue = newArray[newArray.length - 1];
      newArray.push(finalValue + 1);
    } else newArray.push(0);
    SetValue(newArray);
    setIsActiveRegister(true);
  };
  const onChangeDelete = (v) => {
    let newArray = value.filter((val) => val !== v);
    SetValue(newArray);
    localStorage.removeItem(v);
    let respoRV = {};
    respoRV[`territoryId${v}`] = undefined;
    respoRV[`respresentativeId${v}`] = undefined;
    form.setFieldsValue(respoRV);
    validaForm(1, form, value, setIsActiveRegister);
  };
  return (
    <>
      {value.length > 0 &&
        value.map((val) => {
          let dataRv = JSON.parse(localStorage.getItem(val));
          return (
            <>
              <br />
              <Row>
                <Col span={8}>
                  <Form.Item
                    name={`territoryId${val}`}
                    label="*Territorios:"
                    style={{ height: 20 }}
                  >
                    <Select
                      getPopupContainer={getPopupContainer}
                      loading={false}
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Seleccionar"
                      onSelect={(e) => {
                        let respoRV = {};
                        respoRV[`respresentativeId${val}`] = undefined;
                        form.setFieldsValue(respoRV);
                        dispatch(getResponsablePositionRequest({ TerritoryId: e, position: val }));
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {getClientData.territories &&
                        getClientData.territories.map((d) => {
                          return (
                            <Option key={d.key} value={d.key}>
                              {d.value}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`respresentativeId${val}`}
                    label="*Responsable clave de la cuenta:"
                    style={{ height: 20 }}
                  >
                    <Select
                      getPopupContainer={getPopupContainer}
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Seleccionar"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataRv &&
                        dataRv.map((d, index) => {
                          return (
                            <Option key={index} value={d.userId}>
                              {d.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Button
                    shape="circle"
                    style={{ backgroundColor: 'red', marginTop: '36px', marginLeft: '64px' }}
                    icon={<MinusOutlined style={{ color: 'white' }} />}
                    size={24}
                    onClick={() => onChangeDelete(val)}
                    danger
                  />
                </Col>
              </Row>
            </>
          );
        })}
      <br />
      <Row>
        <Col span={8}>
          <Form.Item name="territoryId" label="*Territorios:" style={{ height: 20 }}>
            <Select
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.territories &&
                getClientData.territories.map((d) => {
                  return (
                    <Option key={d.key} value={d.key}>
                      {d.value}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="respresentativeId"
            label="*Responsable clave de la cuenta:"
            style={{ height: 20 }}
          >
            <Select
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.responsable &&
                getClientData.responsable.map((d, index) => {
                  return (
                    <Option key={index} value={d.userId}>
                      {d.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Button
            disabled={isNacional}
            type="primary"
            shape="circle"
            style={{ marginTop: '36px', marginLeft: '64px' }}
            onClick={onChangeValue}
            icon={<PlusOutlined />}
            size={24}
          />
        </Col>
      </Row>
      <br />
    </>
  );
};

export default Manager;
