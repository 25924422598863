import request from 'services/request';
const serviceURL = '/Catalogs/Api/v1/SalesGoals/Detail';
const ServiceURLGoals = '/Catalogs/Api/v1/SalesGoals';
//get goals
export async function getOneGoals({ cancelToken, payload }) {
  return request(
    `${serviceURL}?ZoneId=${payload.ZoneId}&ClientId=${payload.ClientId}&ProductNumber=${payload.ProductNumber}&Year=${payload.Year}`,
    {
      method: 'GET',
      cancelToken,
    }
  );
}
//update Goals
export async function updateOneGoals({ cancelToken, data }) {
  return request(ServiceURLGoals, {
    method: 'PUT',
    cancelToken,
    data,
  });
}
//Delete Goals
export async function deleteOneGoals({ cancelToken, data }) {
  return request(
    `${ServiceURLGoals}?LineId=${data.lineId}&ZoneId=${data.zoneId}&SoldTo=${data.soldTo}&ProductNumber=${data.productNumber}&Year=${data.year}`,
    {
      method: 'DELETE',
      cancelToken,
    }
  );
}
