import React from 'react';
import ErrorColumnTitle from '../../../common/components/ErrorColumnTitle';
import './massiveUploadTableColumns.css';

const messageFaseOne = (
  <p>
    Registros para los cuales se omitió ingresar campos obligatorios, se ingresó un tipo de dato que
    no corresponde al solicitado en el campo o se excedió la longitud máxima.
  </p>
);
const messageFaseTwo = (
  <p>
    Registros de productos para las cuales se ingresó información de elementos con estatus
    &quot;Inactivo&quot;, que no están registrados en el sistema o se ingresó información de
    estructura comercial que no está relacionada entre si.
  </p>
);
const messageFaseThree = (
  <p>Registros que se encuentran repetidos dentro del archivo de carga masiva.</p>
);
const errorMessages = [
  {
    fase: 'Fase 1',
    message: messageFaseOne,
  },
  {
    fase: 'Fase 2',
    message: messageFaseTwo,
  },
  {
    fase: 'Fase 3',
    message: messageFaseThree,
  },
];

const tableColumns = [
  {
    key: 'phase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'phase',
    className: 'title-masive',
  },
  {
    key: 'line',
    title: 'Línea de negocio',
    dataIndex: 'line',
    className: 'title-masive',
  },
  {
    key: 'subRegion',
    title: 'Subregión',
    dataIndex: 'subRegion',
    className: 'title-masive',
  },
  {
    key: 'country',
    title: 'País',
    dataIndex: 'country',
    className: 'title-masive',
  },
  {
    key: 'productNumber',
    title: 'Número',
    dataIndex: 'productNumber',
    className: 'title-masive',
  },
  {
    key: 'name',
    title: 'Nombre',
    dataIndex: 'name',
    className: 'title-masive',
  },
  {
    key: 'family',
    title: 'Familia',
    dataIndex: 'family',
    className: 'title-masive',
  },
  {
    key: 'clasification',
    title: 'Clasificación',
    dataIndex: 'clasification',
    className: 'title-masive',
  },
  {
    key: 'presentation',
    title: 'Presentación',
    dataIndex: 'presentation',
    className: 'title-masive',
  },
  {
    key: 'measurementUnit',
    title: 'Unidad de medida',
    dataIndex: 'measurementUnit',
    className: 'title-masive',
  },
  {
    key: 'unitsPerPackage',
    title: 'Unidades por empaque',
    dataIndex: 'unitsPerPackage',
    className: 'title-masive',
  },
  {
    key: 'minimum',
    title: 'Pedido mínimo requerido',
    dataIndex: 'minimum',
    className: 'title-masive',
  },
  {
    key: 'moq',
    title: 'MOQ',
    dataIndex: 'moq',
    className: 'title-masive',
  },
  {
    key: 'currency',
    title: 'Moneda',
    dataIndex: 'currency',
    className: 'title-masive',
  },
  {
    key: 'iva',
    title: 'IVA',
    dataIndex: 'iva',
    className: 'title-masive',
  },
  {
    key: 'ieps',
    title: 'IEPS',
    dataIndex: 'ieps',
    className: 'title-masive',
  },
];
export default tableColumns;
