import rootReducer from './redux-saga/reducers/rootReducer';
import rootSagas from './redux-saga/sagas/index';

export default function getRootModule() {
  return {
    id: 'MAPS',
    reducerMap: {
      MAPS: rootReducer,
    },
    sagas: [rootSagas],
  };
}
