import React from 'react';
import { Row, Col, Input, Form } from 'antd';

const SectionShop = () => {
  const { TextArea } = Input;
  return (
    <Row>
      <Col span={24}>
        <Form.Item name="introBuy" label="Introducción:">
          <TextArea placeholder="Escribe Introducción" maxLength={750} autoSize />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="subt1" label="Subtítulo 1:">
          <TextArea placeholder="Escribe Subtítulo 1" maxLength={250} autoSize />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="noteBuy" label="Nota:">
          <TextArea placeholder="Escribe  Nota" maxLength={750} autoSize />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="objectExt" label="Objetivo extra:">
          <TextArea placeholder="Escribe Objetivo extra" maxLength={500} autoSize />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="subt2" label="Subtítulo 2:">
          <TextArea placeholder="Escribe Subtítulo 2" maxLength={250} name="subt2" autoSize />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SectionShop;
