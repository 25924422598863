/* eslint-disable no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  consultFiltersObjectivesAndIncentivesSetOptions,
  consultFiltersObjectivesAndIncentivesSetValue,
} from 'RootModule/redux/actions/consultFilters';

import Filter from 'common/consults/components/Filter';

const ObjectivesAndIncentives = (props) => {
  const {
    consultFiltersObjectivesAndIncentivesSetOptions: setOptions,
    consultFiltersObjectivesAndIncentivesSetValue: setValue,
    value,
    required,
    options,
  } = props;

  let mappedOptions = [...options];

  useEffect(() => {
    setOptions([
      {
        key: 'annual',
        value: 'Anuales',
      },
      {
        key: 'sales',
        value: 'Compras',
      },
      {
        key: 'pog',
        value: 'POG',
      },
    ]);
  }, []);

  const selectChange = (selected) => {
    setValue(selected);
  };

  return (
    <Filter
      name={'Objetivos e Incentivos'}
      required={required}
      value={value}
      selectChange={selectChange}
      options={mappedOptions}
      mode={'none'}
      removeIcon
    />
  );
};

const mapStateToProps = (state) => {
  return {
    options: state?.APP?.consultFilters.objectivesAndIncentives?.options ?? [],
    value: state?.APP?.consultFilters.objectivesAndIncentives?.value,
  };
};

ObjectivesAndIncentives.propTypes = {
  consultFiltersObjectivesAndIncentivesSetOptions: PropTypes.func,
  consultFiltersObjectivesAndIncentivesSetValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  required: PropTypes.bool,
  options: PropTypes.array,
};

ObjectivesAndIncentives.defaultProps = {
  consultFiltersObjectivesAndIncentivesSetOptions: () => {},
  consultFiltersObjectivesAndIncentivesSetValue: () => {},
  value: [],
  required: false,
  options: [],
};

export default connect(mapStateToProps, {
  consultFiltersObjectivesAndIncentivesSetOptions,
  consultFiltersObjectivesAndIncentivesSetValue,
})(ObjectivesAndIncentives);
