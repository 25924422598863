import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { Table } from '../components/Table';
import { getBusinessStructure } from '../services/businessStructure';

import ModalRegister from 'BusinessStructure/UpdateBusinessStructure/Maps/Modals/Container/ModalRegister';
import { connect } from 'react-redux';
import {
  getTypeElementRequest,
  postTypeElementRequest,
  postDisplayExcelRequest,
  putTypeElementRequest,
  deleteTypeElementRequest,
  getTypeElementClean,
  getExcelRequest,
  postValidateFilesRequest,
} from 'BusinessStructure/UpdateBusinessStructure/Maps/redux-saga/actions';
import MapsModalsModule from 'BusinessStructure/UpdateBusinessStructure/Maps/MapsModalsModule';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getRootModule from 'RootModule';
import { withRouter } from 'react-router-dom';

const UpdateBusinessStructure = (props) => {
  const {
    getTypeElementRequest: getTypeElement,
    postTypeElementRequest: postTypeElement,
    postDisplayExcelRequest: postDisplayExcel,
    putTypeElementRequest: putTypeElement,
    deleteTypeElementRequest: deleteTypeElement,
    getExcelRequest: getExcel,
    getTypeElementClean: TypeElementClean,
    postValidateFilesRequest: postValidateFiles,
    mapsState,
    FileMap,
  } = props;

  const [tableData, setTableData] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stateModal, setstateModal] = useState({});
  const [colorPicker, setcolorPicker] = useState({ hex: '#00499a' });

  const fetchBusinessStructure = async () => {
    setIsLoading(true);
    const responseBusinessStructure = await getBusinessStructure();
    setTableData(responseBusinessStructure.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBusinessStructure();
  }, []);

  const handleVisibleModal = (modal, data) => {
    let modalName = '';
    if (modal) {
      modalName = 'Consultar';
    } else {
      modalName = 'Registrar';
    }
    setVisibleModal(true);
    setstateModal({
      modal: modalName,
      typeElement: data.typeElement,
      type: data.type,
      element: data.element,
      id: data.id,
      enabled: data.enabled,
    });
  };

  return (
    <>
      <Row style={{ marginTop: '20px' }} data-testid={'archiveTable'}>
        <Col span={24}>
          <Table
            tableData={tableData}
            isLoading={isLoading}
            setTableData={setTableData}
            setIsLoading={setIsLoading}
            handleOpenModalMap={(modal, data) => handleVisibleModal(modal, data)}
          />
        </Col>
      </Row>
      <ModalRegister
        stateModal={stateModal}
        setstateModal={setstateModal}
        getExcel={getExcel}
        getTypeElement={getTypeElement}
        getTypeElementClean={TypeElementClean}
        postTypeElement={postTypeElement}
        postDisplayExcel={postDisplayExcel}
        putTypeElement={putTypeElement}
        deleteTypeElement={deleteTypeElement}
        setcolorPicker={setcolorPicker}
        colorPicker={colorPicker}
        mapsState={mapsState}
        FileMap={FileMap}
        openModal={visibleModal}
        setVisibleModal={(closeModal) => setVisibleModal(closeModal)}
        fetchBusinessStructure={fetchBusinessStructure}
        postValidateFiles={postValidateFiles}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    mapsState: state?.MAPS_MODALS?.maps_modals,
    FileMap: state?.MAPS_MODALS?.maps_modals?.ExcelDataMaps,
  };
}
const ConnectedUpdateBusinessStructure = withRouter(
  connect(mapStateToProps, {
    getTypeElementRequest,
    postTypeElementRequest,
    postDisplayExcelRequest,
    putTypeElementRequest,
    deleteTypeElementRequest,
    getTypeElementClean,
    getExcelRequest,
    postValidateFilesRequest,
  })(UpdateBusinessStructure)
);

const UpdateBusinessStructureForm = () => (
  <DynamicModuleLoader modules={[getRootModule(), MapsModalsModule()]}>
    <ConnectedUpdateBusinessStructure />
  </DynamicModuleLoader>
);

export default UpdateBusinessStructureForm;
