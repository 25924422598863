import { takeEvery, put, call, all, cancelled } from 'redux-saga/effects';
import {
  POST_ONE_SEGMENTATION_REQUEST,
  postOneSegmentationClean,
  postOneSegmentationSuccess,
  DELETE_ONE_SEGMENTATION_REQUEST,
  deleteOneSegmentationClean,
  deleteOneSegmentationSuccess,
  PUT_ONE_SEGMENTATION_REQUEST,
  putOneSegmentationClean,
  putOneSegmentationSuccess,
  GET_IN_POLICIY_PRODUCTS_REQUEST,
  getInPoliciyProductsSuccess,
  getInPoliciyProductsClean,
  GET_ACTIVE_CLIENTS_REQUEST,
  getActiveClientsSuccess,
  getActiveClientsClean,
  POST_PRODUCT_CLIENT_REQUEST,
  postProductClientClean,
  // postProductClientSuccess,
  POST_PRODUCT_BL_REQUEST,
  postProductBLClean,
  // postProductBLSuccess,
  POST_INCENTIVES_RULES_REQUEST,
  postIncentivesRulesClean,
  // postIncentivesRulesSuccess,
  GET_ClientSingleId_REQUEST,
  getClientSingleIdSuccess,
  getClientSingleIdClean,
  EXISTENCE_INCENTIVES_REQUEST,
  checkExistanceIncentivesSuccess,
  checkExistanceIncentivesClean,
} from '../actions';
import instance from 'services/request';

import {
  postOneSegmentation,
  deleteOneSegmentation,
  putOneSegmentation,
  getInPoliciyProducts,
  getActiveClients,
  postProductClient,
  postProductBL,
  postIncentivesRules,
  getClientSingleId,
  postExistenceIncentives,
} from '../../services/BRregister';
import { responseOK } from 'SalesChallengeRefactor/utils';
import { openNotification } from 'common';

//Segmentation POST
function* workPostOneSegmentationRequest(action) {
  try {
    const { payload } = action;
    const response = yield call(postOneSegmentation, {
      data: {
        segmentation: payload,
      },
    });
    if (responseOK(response)) {
      yield put(postOneSegmentationSuccess(response.data));
      yield openNotification('success', response.message);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(postOneSegmentationClean());
  }
}
//Segmentation DELETE
function* workDeleteOneSegmentationRequest(action) {
  try {
    const { payload } = action;
    const response = yield call(deleteOneSegmentation, {
      data: {
        id: payload,
      },
    });
    if (responseOK(response)) {
      yield put(deleteOneSegmentationSuccess(response.data));
      yield openNotification('success', response.message);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(deleteOneSegmentationClean());
  }
}
//Segmentation PUT
function* workPutOneSegmentationRequest(action) {
  try {
    const { payload } = action;
    const { id, value } = payload;
    const response = yield call(putOneSegmentation, {
      data: {
        id: id,
        segmentation: value,
      },
    });
    if (responseOK(response)) {
      yield put(putOneSegmentationSuccess(response.data));
      yield openNotification('success', response.message);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(putOneSegmentationClean());
  }
}

//InPoliciyProducts GET
function* workGetInPoliciyProductsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { lineId, lineIdClient, subregionId, clientId } = payload;
    let params = {};
    if (subregionId) {
      params = {
        LineId: lineId,
        SubregionId: subregionId,
      };
    } else if (clientId) {
      const responseClientSingleId = yield call(getClientSingleId, {
        params: { LineId: lineIdClient, SoldTo: clientId },
      });
      params = {
        LineId: lineIdClient,
        ClientId: responseClientSingleId.data.clientId,
      };
    }
    let response = yield call(getInPoliciyProducts, {
      params,
    });
    if (responseOK(response)) {
      yield put(getInPoliciyProductsSuccess(response.data));

      if (response.data === true) {
        yield openNotification('error', response.message);
      }
    } else {
      yield put(getInPoliciyProductsClean());
    }
  } catch (error) {
    yield put(getInPoliciyProductsClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

///InPoliciyProducts GET CLIENTE
function* workGetActiveClientsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { BussinessLines } = payload;
    let response = yield call(getActiveClients, { data: { BussinessLines } });
    if (responseOK(response)) {
      yield put(getActiveClientsSuccess(response.data));

      if (response.data === true) {
        yield openNotification('error', response.message);
      }
    } else {
      yield put(getActiveClientsClean());
    }
  } catch (error) {
    yield put(getActiveClientsClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

//PORDUCT CLIENT POST
function* workPostProductClientRequest(action) {
  try {
    const { payload } = action;
    const { products, lineIdClient, clientId, history } = payload;
    let responseClient = yield call(getClientSingleId, {
      params: {
        lineId: lineIdClient,
        soldTo: clientId,
      },
    });
    if (responseClient) {
      const response = yield call(postProductClient, {
        data: {
          products,
          lineId: lineIdClient,
          clientId: responseClient.data.clientId,
        },
      });
      if (responseOK(response)) {
        yield openNotification('success', response.message);
        setTimeout(() => {
          history.push({
            pathname: '/catalog/businessRules',
            state: { ruleType: 2 },
          });
        }, 300);
      } else if (response.data) {
        yield openNotification('error', response.message);
      }
    }
  } catch (error) {
    yield put(postProductClientClean());
  }
}
//PRODUCT BL POST
function* workPostProductBLRequest(action) {
  try {
    const { payload } = action;
    const { products, lineId, subregionId, history } = payload;
    const response = yield call(postProductBL, {
      data: {
        products,
        lineId,
        subregionId,
      },
    });
    if (responseOK(response)) {
      yield openNotification('success', response.message);
      setTimeout(() => {
        history.push({
          pathname: '/catalog/businessRules',
          state: { ruleType: 2 },
        });
      }, 300);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(postProductBLClean());
  }
}

//INCENTIVES_RULES POST
function* workPostIncentivesRulesRequest(action) {
  try {
    const { payload } = action;
    const { data, history } = payload;
    const response = yield call(postIncentivesRules, {
      data,
    });
    if (responseOK(response)) {
      yield openNotification('success', response.message);
      setTimeout(() => {
        history.push({
          pathname: '/catalog/businessRules',
          state: { ruleType: 3 },
        });
      }, 300);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(postIncentivesRulesClean());
  }
}

/// GET ClientSingleId
function* workGetClientSingleIdRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { BussinessLines } = payload;
    let response = yield call(getClientSingleId, { data: { BussinessLines } });
    if (responseOK(response)) {
      yield put(getClientSingleIdSuccess(response.data));

      if (response.data === true) {
        yield openNotification('error', response.message);
      }
    } else {
      yield put(getClientSingleIdClean());
    }
  } catch (error) {
    yield put(getClientSingleIdClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}
/// GET ClientSingleId
function* workGetExistanceIncentivesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { data } = payload;

    let response = yield call(postExistenceIncentives, { data });
    if (responseOK(response)) {
      if (response.data === true) {
        yield openNotification(
          'error',
          'La regla de negocio de programa de incentivos ya se encuentra registrada en el sistema'
        );
      }
      yield put(checkExistanceIncentivesSuccess(response.data));
    } else {
      yield put(checkExistanceIncentivesClean());
    }
  } catch (error) {
    yield put(checkExistanceIncentivesClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}
//WATCHERS
export function* watchPostOneSegmentationRequest() {
  yield all([takeEvery(POST_ONE_SEGMENTATION_REQUEST, workPostOneSegmentationRequest)]);
}

export function* watchDeleteOneSegmentationRequest() {
  yield all([takeEvery(DELETE_ONE_SEGMENTATION_REQUEST, workDeleteOneSegmentationRequest)]);
}

export function* watchPutOneSegmentationRequest() {
  yield all([takeEvery(PUT_ONE_SEGMENTATION_REQUEST, workPutOneSegmentationRequest)]);
}

export function* watchGetInPoliciyProductsRequest() {
  yield all([takeEvery(GET_IN_POLICIY_PRODUCTS_REQUEST, workGetInPoliciyProductsRequest)]);
}

export function* watchGetActiveClientsRequest() {
  yield all([takeEvery(GET_ACTIVE_CLIENTS_REQUEST, workGetActiveClientsRequest)]);
}

export function* watchPostProductClientRequest() {
  yield all([takeEvery(POST_PRODUCT_CLIENT_REQUEST, workPostProductClientRequest)]);
}

export function* watchPostProductBLRequest() {
  yield all([takeEvery(POST_PRODUCT_BL_REQUEST, workPostProductBLRequest)]);
}

export function* watchPostIncentivesRulesRequest() {
  yield all([takeEvery(POST_INCENTIVES_RULES_REQUEST, workPostIncentivesRulesRequest)]);
}

export function* watchGetClientSingleIdRequest() {
  yield all([takeEvery(GET_ClientSingleId_REQUEST, workGetClientSingleIdRequest)]);
}
export function* watchExistanceIncentivesRequest() {
  yield all([takeEvery(EXISTENCE_INCENTIVES_REQUEST, workGetExistanceIncentivesRequest)]);
}
