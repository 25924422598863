export const EDIT_MANAGE_IMAGES_POSITION_REQUEST = 'EDIT_MANAGE_IMAGES_POSITION_REQUEST';
export const EDIT_MANAGE_IMAGES_POSITION_SET = 'EDIT_MANAGE_IMAGES_POSITION_SET';
export const EDIT_MANAGE_IMAGES_POSITION_CLEAN = 'EDIT_MANAGE_IMAGES_POSITION_CLEAN';

export function EditManageImagesPosition(payload) {
  return {
    type: EDIT_MANAGE_IMAGES_POSITION_REQUEST,
    payload: payload,
  };
}
export function EditManageImagesSetPosition(payload) {
  return {
    type: EDIT_MANAGE_IMAGES_POSITION_SET,
    payload: payload,
  };
}
export function EditManageImagesCleanPosition(payload) {
  return {
    type: EDIT_MANAGE_IMAGES_POSITION_CLEAN,
  };
}
