/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Modal, Radio } from 'antd';

import {
  pricesDelete,
  pricesDeactivate,
  pricesBoth,
  productsDelete,
  productsDeactivate,
  productsBoth,
  clientsDelete,
  clientsDeactivate,
  clientsBoth,
} from './services';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const deleteOptions = [
  {
    //label: 'Eliminar registros no utilizados en acuerdos/desafíos',
    label: 'Eliminar registros no utilizados',
    value: 'Eliminar',
  },
  {
    label: 'Desactivar registros "Activos"',
    value: 'Desactivar',
  },
  {
    label: 'Eliminar y Desactivar registros',
    value: 'EliminarDesactivar',
  },
];

const DeleteProductCatalogModal = ({
  showModal,
  toggleShowModal,
  name,
  setDeleteIsLoading,
  obtainCatalogData,
}) => {
  const [selected, setSelected] = useState(deleteOptions[0].value);
  const [modalTitle, setModalTitle] = useState('');
  const [modalQuestion, setModalQuestion] = useState('');

  const history = useHistory();
  const { location } = history;
  const { pathname } = location;
  const isPrices = pathname === '/catalog/prices';
  const isProducts = pathname === '/catalog/products';
  const isClients = pathname === '/catalog/clients';

  const setTitles = (moduleName) => {
    let modalHeadline;
    let question;
    switch (moduleName) {
      case 'products':
        modalHeadline = 'Limpiar catálogo de Productos';
        question = '¿Cómo desea realizar la limpieza del catálogo de Productos?';
        break;
      case 'prices':
        modalHeadline = 'Limpiar catálogo de Precios';
        question = '¿Cómo desea realizar la limpieza del catálogo de Precios?';
        break;
      case 'clients':
        modalHeadline = 'Limpiar catálogo de Clientes';
        question = '¿Cómo desea realizar la limpieza del catálogo de Clientes?';
        break;
      default:
        modalHeadline = '';
        question = '';
        break;
    }
    setModalTitle(modalHeadline);
    setModalQuestion(question);
  };

  const handleChange = ({ target: { value } }) => setSelected(value);

  const afterDeleting = () => {
    obtainCatalogData();
    setDeleteIsLoading(false);
  };

  const handleStopLoading = () => {
    setDeleteIsLoading(false);
  };

  const chooseAproppiatePatch = () => {
    setDeleteIsLoading(true);
    switch (selected) {
      case 'Desactivar':
        if (isPrices) {
          pricesDeactivate(afterDeleting, handleStopLoading);
        }
        if (isProducts) {
          productsDeactivate(afterDeleting, handleStopLoading);
        }
        if (isClients) {
          clientsDeactivate(afterDeleting, handleStopLoading);
        }

        break;
      case 'Eliminar':
        if (isPrices) {
          pricesDelete(afterDeleting, handleStopLoading);
        }
        if (isProducts) {
          productsDelete(afterDeleting, handleStopLoading);
        }
        if (isClients) {
          clientsDelete(afterDeleting, handleStopLoading);
        }
        break;
      case 'EliminarDesactivar':
        if (isPrices) {
          pricesBoth(afterDeleting, handleStopLoading);
        }
        if (isProducts) {
          productsBoth(afterDeleting, handleStopLoading);
        }
        if (isClients) {
          clientsBoth(afterDeleting, handleStopLoading);
        }
        break;
      default:
        break;
    }
    toggleShowModal();
  };
  const onCancel = () => {
    toggleShowModal();
  };

  useEffect(() => {
    setTitles(name);
  }, []);

  return (
    <Modal title={modalTitle} visible={showModal} onOk={chooseAproppiatePatch} onCancel={onCancel}>
      <section className="radio-wraper">
        <h4> {modalQuestion} </h4>
        <Radio.Group
          options={deleteOptions}
          onChange={handleChange}
          className={'download-types'}
          value={selected}
        />
      </section>
    </Modal>
  );
};

export default DeleteProductCatalogModal;
