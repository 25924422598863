/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { appContext } from './context';
import { PrivateRoutes, PublicRoutes } from './routes';
import {
  userModules as getUserModules,
  mainRoutes as mainRoutesService,
  logout as logoutService,
} from './services';
import { Loader } from './global';
import './i18n';
import './index.css';
import jwtDecode from 'jwt-decode';

function App({ token }) {
  const [modulesContext, setModulesContext] = useState(token ? 'loading' : 'public');
  const [userModules, setUserModules] = useState(undefined);
  const [mainRoutes, setMainRoutes] = useState(undefined);
  const [userInfo, setUserInfo] = useState({});
  const [catalogsPermissions, setCatalogsPermissions] = useState(undefined);
  const [accountStatusPermissions, setAccountStatusPermissions] = useState(undefined);
  const [dashboardsPermissions, setDashboardsPermissions] = useState(undefined);
  const [historicInformationPermissions, setHistoricInformationPermissions] = useState(undefined);
  const [salesChallengePermissions, setSalesChallengePermissions] = useState(undefined);

  const setupApp = (permissions, user, allSubregionsAccess) => {
    setModulesContext('loading');

    setUserInfo({
      name: user && user.name && user.name,
      lastName: user && user.lastName && user.lastName,
      email: user && user.email && user.email,
      profile: user && user.role && user.role.roleName,
      area: user && user.area && user.area,
      role: user?.role,
    });

    let catalogPermission = permissions.find((permission) => permission.uri === 'catalog');

    let historicInformationPermission = permissions.find(
      (permission) => permission.uri === 'historic-information'
    );

    let salesChallengePermission = permissions.find(
      (permission) => permission.uri === 'sales-challenge'
    );
    let accountStatusPermission = permissions.find(
      (permission) => permission.uri === 'account-status'
    );
    let dashboardsPermission = permissions.find((permission) => permission.uri === 'dashboards');
    setCatalogsPermissions(catalogPermission ? catalogPermission.components : []);

    setHistoricInformationPermissions(
      historicInformationPermission ? historicInformationPermission.components : []
    );
    setSalesChallengePermissions(salesChallengePermission);
    setAccountStatusPermissions(accountStatusPermission?.components ?? []);
    setDashboardsPermissions(dashboardsPermission?.components ?? []);

    mainRoutesService(permissions).then((res) => {
      setMainRoutes(res);
      getUserModules(permissions, allSubregionsAccess).then((resp) => {
        setUserModules(resp);
        setModulesContext('private');
      });
    });
  };

  useEffect(() => {
    if (token) {
      setupApp(
        JSON.parse(localStorage.permissions),
        JSON.parse(localStorage.user),
        JSON.parse(localStorage.allSubregionsAccess)
      );
    }
  }, [token]);

  const logout = async () => {
    setUserModules(undefined);
    setMainRoutes(undefined);
    setModulesContext('public');
    await logoutService().catch((err) => console.error(err));
    localStorage.clear();
  };

  const timeLogout = () => {
    let tokenEncode = localStorage.token;
    if (tokenEncode) {
      let tokenDecode = jwtDecode(tokenEncode);
      let timeNow = Math.round(Date.now() / 1000);
      if (tokenDecode.exp < timeNow) {
        logout();
      }
    }
  };

  let AppComponent = null;
  if (modulesContext === 'loading') {
    return <Loader color="#00499a" size="large" />;
  }

  if (modulesContext === 'public')
    AppComponent = (
      <appContext.Provider
        value={{
          onLogin: setupApp,
        }}
      >
        <PublicRoutes />
      </appContext.Provider>
    );

  if (modulesContext === 'private') {
    AppComponent = (
      <appContext.Provider
        value={{
          userInfo: userInfo,
          modules: userModules,
          catalogsPermissions: catalogsPermissions,
          historicInformationPermissions: historicInformationPermissions,
          salesChallengePermissions: salesChallengePermissions,
          accountStatusPermissions: accountStatusPermissions,
          dashboardsPermissions: dashboardsPermissions,
          onLogout: logout,
          timeLogout,
        }}
      >
        <PrivateRoutes mainRoutes={mainRoutes} />
      </appContext.Provider>
    );
    timeLogout();
  }
  return <Provider store={store}>{AppComponent}</Provider>;
}
export default App;
