import React from 'react';
import { Modal } from 'antd';

export function ContentModal({ type, row, param, paramName, paramName2 }) {
  if (type === 27) {
    return (
      <div>
        <p>
          El precio para el producto {row[`productName`]}, cliente {row[`clientName`]} , zona{' '}
          {row[`zone`]} y AP {row[`crop`]} ya se encuentra registrado en el sistema, ¿desea
          reemplazar la información del registro o ignorarlo?
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <p>
          {paramName}
          {paramName2} {param} ya se encuentra registrado en el sistema, ¿desea mantener la
          información del registro o remplazarla?{' '}
        </p>
      </div>
    );
  }
}

export function duplicateModal({ title, param, paramName, paramName2, type, onOk, row, onCancel }) {
  if (row && param)
    return Modal.confirm({
      mask: true,
      maskStyle: { background: 'rgba(0, 0, 0, 0.01)' },
      title: title,
      content: (
        <ContentModal
          row={row}
          type={type}
          key={row[`${paramName}`]}
          param={row[`${param}`]}
          paramName={paramName}
          paramName2={paramName2}
        />
      ),
      okText: 'Remplazar',
      cancelText: 'Mantener',
      onOk() {
        onOk(row.rowNumber);
      },
      onCancel() {
        onCancel();
      },
    });
}
function duplicatedModal(
  duplicatedRows,
  title,
  param,
  paramName,
  paramName2,
  type,
  onOk,
  onCancel
) {
  if (duplicatedRows.length > 0) {
    duplicatedRows.forEach((row) =>
      duplicateModal({ title, param, paramName, paramName2, type, onOk, row, onCancel })
    );
  }
}
export default duplicatedModal;
