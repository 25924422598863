export const OCL_SUBREGIONS_REQUEST = 'OCL_SUBREGIONS_REQUEST';
export const OCL_SUBREGIONS_SET = 'OCL_SUBREGIONS_SET';
export const OCL_SUBREGIONS_CLEAN = 'OCL_SUBREGIONS_CLEAN';

export const OCL_CLIENTS_REQUEST = 'OCL_CLIENTS_REQUEST';
export const OCL_CLIENTS_SET = 'OCL_CLIENTS_SET';
export const OCL_CLIENTS_CLEAN = 'OCL_CLIENTS_CLEAN';

export function creditLinesSubregionsRequest(payload) {
  return {
    type: OCL_SUBREGIONS_REQUEST,
    payload: payload,
  };
}

export function creditLinesSubregionsSet(payload) {
  return {
    type: OCL_SUBREGIONS_SET,
    payload: payload,
  };
}

export function creditLinesSubregionsClean(payload) {
  return {
    type: OCL_SUBREGIONS_CLEAN,
    payload: payload,
  };
}

export function creditLinesClientsRequest(payload) {
  return {
    type: OCL_CLIENTS_REQUEST,
    payload: payload,
  };
}

export function creditLinesClientsSet(payload) {
  return {
    type: OCL_CLIENTS_SET,
    payload: payload,
  };
}

export function creditLinesClientsClean(payload) {
  return {
    type: OCL_CLIENTS_CLEAN,
    payload: payload,
  };
}
