import {
  READ_ONE_USER_REQUEST,
  READ_ONE_USER_SUCCESS,
  READ_ONE_USER_CLEAN,
  PUT_ONE_USER_CLEAN,
  PUT_ONE_USER_REQUEST,
  PUT_ONE_USER_SUCCESS,
  POST_ONE_USER_REQUEST,
  SET_IS_POSTING,
} from '../actions';

function oneCase(state = {}, action) {
  const { type } = action;

  switch (type) {
    case READ_ONE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case READ_ONE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };

    case READ_ONE_USER_CLEAN:
      return {
        ...state,
        isLoading: false,
      };

    case PUT_ONE_USER_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };

    case PUT_ONE_USER_CLEAN:
    case PUT_ONE_USER_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      };

    case POST_ONE_USER_REQUEST:
      return {
        ...state,
        isPosting: true,
      };

    case SET_IS_POSTING:
      return {
        ...state,
        isPosting: action.payload,
      };

    default:
      return state;
  }
}

export default oneCase;
